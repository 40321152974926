import React from 'react';
import { Outlet } from 'react-router-dom';
import {Options} from './Options/Options';

import './spoilage-index.css';

export const SpoilageIndex = ()=>{
    return(
        <div id="spoilage-container">
            <Options/>
            <Outlet/>
        </div>
    )
}