import React from 'react';
import { IngredientCard } from '../IngredientCard/IngredientCard';

import './prep-list.css';

export const PrepList = ({data})=>{
    const sampleData = [
        {
            name: "Pickles",
            par: 4,
            unit: "qt",
            minutesToPrepForPar: 20,
            recipeNumber: "1234"
        },
        {
            name: "Garlic Bread",
            par: 20,
            unit: "pans",
            minutesToPrepForPar: 30,
            recipeNumber: "4321"
        }
    ]
    return(
        <div id="prep-tracker-page-container">
            {sampleData.map(data=>{
                return <IngredientCard data={data} key={data['recipeNumber']}/>
            })}
        </div>
    )
}