import React from 'react';
import styles from './home.module.css';

const Home = () => {
  return (
    <div id={styles['home-container']}>
      <h1>Home</h1>
      <p>Welcome! This website is in development. Updates are made weekly.</p>
    </div>
  );
};

export default Home;