import React, { useEffect, useState } from 'react';
import {db} from '../../../firebase';
import { collection, getDocs, doc, query, where } from "firebase/firestore"; 
import SearchSpinner from '../../General/SearchSpinner/SearchSpinner.js';
import { DailySpoilageCard } from './DailySpoilageCard/DailySpoilageCard.js';

import style from './daily-spoilage.module.css';
const collectionInUse = 'testSpoilage';

export const DailySpoilage = ()=>{
    const sampleData = [
        {
            "timestamp": {
                "seconds": 1700926229,
                "nanoseconds": 690000000
            },
            "name": "banana",
            "sku": "11223344",
            "locationId": "123456",
            "unit": "OZ",
            "type": "masterList",
            "id": "EiYq2iQKcsqhPBvLV1dD"
        },
        {
            "timestamp": {
                "seconds": 1700926776,
                "nanoseconds": 807000000
            },
            "type": "masterList",
            "sku": "111111",
            "name": "Tomato",
            "locationId": "123456",
            "unit": "OZ",
            "id": "UF1ksnQgy0HPQFfYk5lo"
        },
        {
            "sku": "44444",
            "name": "Lettuce",
            "timestamp": {
                "seconds": 1694015418,
                "nanoseconds": 684000000
            },
            "unit": "LB",
            "type": "masterList",
            "amount": "22",
            "locationId": "123456",
            "id": "VCATHzAXfIS6iIwtbeWB"
        },
        {
            "sku": "555667",
            "unit": "EA",
            "amount": "1",
            "name": "Potato",
            "locationId": "123456",
            "type": "masterList",
            "id": "ZN9ay8azEpSu6HGqSubv"
        },
        {
            "sku": "533235",
            "type": "masterList",
            "amount": "38",
            "unit": "LB",
            "locationId": "123456",
            "name": "Grapes",
            "id": "bjfO6qivmTFKdrj8OyXT"
        },
        {
            "locationId": "123456",
            "timestamp": {
                "seconds": 1694014275,
                "nanoseconds": 211000000
            },
            "type": "masterList",
            "amount": "9",
            "sku": "1111",
            "unit": "OZ",
            "name": "Sugar",
            "id": "n7Gaa00qdimR39ArlVQj"
        }
    ]
    const [newDaily, setNewDaily] = useState(sampleData); //remove sampleData when pulling from db
    const [spoiledAmounts, setSpoiledAmounts] = useState([]);

    // const getSpoilage = async()=>{
    //     const q = query(collection(db, collectionInUse), where('locationId', '==', '123456'));
     
    //     const querySnapshot = await getDocs(q);
   
    //     const data = querySnapshot.docs.map(doc=>({...doc.data(), id:doc.id}))
    //     setNewDaily(data);
    //     console.log(newDaily)

    // }
    // useEffect(()=>{
    // getSpoilage()
    // }, [])
    const handleAmountChange = (e)=>{
        spoiledAmounts[e.target.getAttribute('data-item-id')]=e.target.value;
        setSpoiledAmounts(spoiledAmounts)
    }
    const submitClicked = ()=>{
        console.log(spoiledAmounts)
    }
    return(
    <div id={style['daily-spoilage-container']}>
        {newDaily?(
            <>
                {newDaily.map((item)=>
                    <DailySpoilageCard key={item.id} data={item} handleAmountChange={handleAmountChange}/>
                )}
            </>
        ):<SearchSpinner/>}
        <div className={style.submitButton} onClick={()=>submitClicked()}>
            Submit
        </div>
    </div>
    )
}