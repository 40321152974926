import React from 'react';
import { Outlet } from 'react-router-dom';
import {Options} from './Options/Options';

// import './spoilage-index.css';

export const RecipeCreatorIndex = ()=>{
    return(
        <div id="recipe-creator-container">
            <Options/>
            <Outlet/>
        </div>
    )
}