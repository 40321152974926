export const testData = [
    {
        "Root": "10008263",
        "LV": "2",
        "Item Description": "PINOT NOIR LA CREMA 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008263001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "604127",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008263",
        "Product Status": "Active"
    },
    {
        "Root": "10031808",
        "LV": "1",
        "Item Description": "HONEY WILDFLOWER GRADE A 6/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3737301",
        "Last Price Paid": "$20.45",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031808",
        "Product Status": "Active"
    },
    {
        "Root": "10012601",
        "LV": "1",
        "Item Description": "BEEF RIBEYE STEAK BNLS AUS HALAL 10Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012601001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190400",
        "Last Price Paid": "$18.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012601",
        "Product Status": "Active"
    },
    {
        "Root": "10003841",
        "LV": "1",
        "Item Description": "BAR CHC CHP CK DOUGH LARA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "409417",
        "Last Price Paid": "$1.52",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003841",
        "Product Status": "Active"
    },
    {
        "Root": "10044476",
        "LV": "1",
        "Item Description": "BREADER CHICKEN 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044476001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9216177",
        "Last Price Paid": "$26.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044476",
        "Product Status": "Active"
    },
    {
        "Root": "10107495",
        "LV": "1",
        "Item Description": "BEER STONE BUENAVESA 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "12052",
        "Last Price Paid": "$162.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107495",
        "Product Status": "Active"
    },
    {
        "Root": "10109529",
        "LV": "1",
        "Item Description": "BEER KEG BLUE MOON BELGIAN WHEAT 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109529",
        "Product Status": "Active"
    },
    {
        "Root": "10091391",
        "LV": "1",
        "Item Description": "SPIRTS VODKA HELIX 7 1LITER 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091391",
        "Product Status": "Active"
    },
    {
        "Root": "10091391",
        "LV": "1",
        "Item Description": "SPIRTS VODKA HELIX 7 1LITER 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "199762",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091391",
        "Product Status": "Active"
    },
    {
        "Root": "10012563",
        "LV": "1",
        "Item Description": "NERO D'AVOLA VERSACE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012563001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561597",
        "Last Price Paid": "$20.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012563",
        "Product Status": "Active"
    },
    {
        "Root": "10086404",
        "LV": "1",
        "Item Description": "CHICKEN NUGGETS BREADED GLUTEN FREE B&E 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CHI260",
        "Last Price Paid": "$59.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086404",
        "Product Status": "Active"
    },
    {
        "Root": "10006645",
        "LV": "1",
        "Item Description": "ICE CRUSHED 16LB BG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000006360",
        "Vendor": "REDDY ICE CORPORATION",
        "Vendor Item Nb": "100033",
        "Last Price Paid": "$2.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006645",
        "Product Status": "Active"
    },
    {
        "Root": "10006612",
        "LV": "1",
        "Item Description": "CIRCLE PIZZA CAKE 10IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9602801",
        "Last Price Paid": "$0.10",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006612",
        "Product Status": "Active"
    },
    {
        "Root": "10003920",
        "LV": "1",
        "Item Description": "PEAR HALVES LS 35/40",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003920001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5328133",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003920",
        "Product Status": "Active"
    },
    {
        "Root": "10006173",
        "LV": "1",
        "Item Description": "NAPKIN BEV TRADER SAMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006173001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "41233700",
        "Last Price Paid": "",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006173",
        "Product Status": "Active"
    },
    {
        "Root": "10009481",
        "LV": "1",
        "Item Description": "SCOTCH J&B RARE BLEND LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "18676",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009481",
        "Product Status": "Active"
    },
    {
        "Root": "10036045",
        "LV": "2",
        "Item Description": "SUGAR FOOTBALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036045002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "25476",
        "Last Price Paid": "",
        "Case Pack": "264",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036045",
        "Product Status": "Active"
    },
    {
        "Root": "10002411",
        "LV": "1",
        "Item Description": "CHEESE FRESH MOZZARELLA LOG UNSLICED 8/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002411",
        "Product Status": "Active"
    },
    {
        "Root": "10101150",
        "LV": "1",
        "Item Description": "WINE CHARDONNAY CARMEL ROAD MONTEREY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561457",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101150",
        "Product Status": "Active"
    },
    {
        "Root": "10027209",
        "LV": "1",
        "Item Description": "PEPPER RED DICED 1/2 IN 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "803405",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027209",
        "Product Status": "Active"
    },
    {
        "Root": "10040277",
        "LV": "1",
        "Item Description": "CHAMP EGLY OURIET TRADITION BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "233611",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040277",
        "Product Status": "Active"
    },
    {
        "Root": "10110209",
        "LV": "1",
        "Item Description": "BUCKET POP PIXAR APP TRUCK 32OZ 16/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110209",
        "Product Status": "Active"
    },
    {
        "Root": "10036996",
        "LV": "1",
        "Item Description": "CORN CHULPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "493734",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036996",
        "Product Status": "Active"
    },
    {
        "Root": "10037281",
        "LV": "1",
        "Item Description": "SWEET PEA FILLERS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037281001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SWP",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037281",
        "Product Status": "Active"
    },
    {
        "Root": "10105139",
        "LV": "1",
        "Item Description": "CLAM BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "54-03094",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105139",
        "Product Status": "Active"
    },
    {
        "Root": "10003426",
        "LV": "1",
        "Item Description": "ORANGE FANTA 2 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003426",
        "Product Status": "Active"
    },
    {
        "Root": "10024497",
        "LV": "1",
        "Item Description": "CHOC DRK MICKEY FACE LOLLIPOP IN CELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024497001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3167",
        "Last Price Paid": "$3.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024497",
        "Product Status": "Active"
    },
    {
        "Root": "10032455",
        "LV": "2",
        "Item Description": "ESCOLOR AAA SAKU KOREA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032455002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000313711",
        "Vendor": "TRUE WORLD FOODS ORLANDO LLC",
        "Vendor Item Nb": "ESC0607",
        "Last Price Paid": "$11.95",
        "Case Pack": "22",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032455",
        "Product Status": "Active"
    },
    {
        "Root": "10034198",
        "LV": "1",
        "Item Description": "MELON HEIRLOOM S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034198001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598500",
        "Last Price Paid": "$4.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034198",
        "Product Status": "Active"
    },
    {
        "Root": "10003733",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE CHOC 20",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$64.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003733",
        "Product Status": "Active"
    },
    {
        "Root": "10003067",
        "LV": "1",
        "Item Description": "MARMALADE ORANGE .5OZ 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003067",
        "Product Status": "Active"
    },
    {
        "Root": "10000381",
        "LV": "1",
        "Item Description": "MELON WATERMELON CUBED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481231",
        "Last Price Paid": "$8.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000381",
        "Product Status": "Active"
    },
    {
        "Root": "10000381",
        "LV": "1",
        "Item Description": "MELON WATERMELON CUBED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-030D-1",
        "Last Price Paid": "$8.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000381",
        "Product Status": "Active"
    },
    {
        "Root": "10001375",
        "LV": "1",
        "Item Description": "ROMANESCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "407885",
        "Last Price Paid": "$13.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001375",
        "Product Status": "Active"
    },
    {
        "Root": "10040831",
        "LV": "4",
        "Item Description": "CUP HOT PT 12 OZ MARY BLAIR 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040831001004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$73.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "001",
        "NALA": "10040831",
        "Product Status": "Active"
    },
    {
        "Root": "10003813",
        "LV": "1",
        "Item Description": "PUREE BLOOD ORANGE 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "540-A",
        "Last Price Paid": "$13.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003813",
        "Product Status": "Active"
    },
    {
        "Root": "10009510",
        "LV": "1",
        "Item Description": "BOURBON JACK DAN SNGL BBL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "23341",
        "Last Price Paid": "$39.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009510",
        "Product Status": "Active"
    },
    {
        "Root": "10001243",
        "LV": "1",
        "Item Description": "LAMB BONES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001243001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240504",
        "Last Price Paid": "$1.91",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001243",
        "Product Status": "Active"
    },
    {
        "Root": "10010529",
        "LV": "2",
        "Item Description": "MASCHIO M PROSECCO BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010529002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10010529",
        "Product Status": "Active"
    },
    {
        "Root": "10008165",
        "LV": "2",
        "Item Description": "BARBERA DASTI CHIARLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008165001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "275214",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008165",
        "Product Status": "Active"
    },
    {
        "Root": "10001952",
        "LV": "1",
        "Item Description": "BULLS BLOOD MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04BB-33-S",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001952",
        "Product Status": "Active"
    },
    {
        "Root": "10009071",
        "LV": "1",
        "Item Description": "CAB HEITZ TRAILSIDE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550254",
        "Last Price Paid": "$96.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009071",
        "Product Status": "Active"
    },
    {
        "Root": "10002856",
        "LV": "1",
        "Item Description": "MAYONNAISE IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002856",
        "Product Status": "Active"
    },
    {
        "Root": "10022455",
        "LV": "1",
        "Item Description": "PORK BELLY AN BNLS CKD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022455001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2771384",
        "Last Price Paid": "$6.27",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022455",
        "Product Status": "Active"
    },
    {
        "Root": "10100589",
        "LV": "2",
        "Item Description": "COOKIE BAKED PLANT BASED CULTURE (200) EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100589002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1000-200",
        "Last Price Paid": "$1.47",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100589",
        "Product Status": "Active"
    },
    {
        "Root": "10029171",
        "LV": "1",
        "Item Description": "TEA EXOTIC MANGO & GINGER GREEN 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029171001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13116",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029171",
        "Product Status": "Active"
    },
    {
        "Root": "10104600",
        "LV": "1",
        "Item Description": "PASSOVER BSC CHICKEN TENDERS 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104600",
        "Product Status": "Active"
    },
    {
        "Root": "10033799",
        "LV": "1",
        "Item Description": "HIBISCUS WHITE SINGLE HBS WH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "HBS WH",
        "Last Price Paid": "$5.09",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033799",
        "Product Status": "Active"
    },
    {
        "Root": "10045216",
        "LV": "1",
        "Item Description": "BAG GIFT MEDIUM AMORETTES 10X10X10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013620",
        "Last Price Paid": "$1.47",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045216",
        "Product Status": "Active"
    },
    {
        "Root": "10082878",
        "LV": "1",
        "Item Description": "RUM CRUZAN GUAVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "973753",
        "Last Price Paid": "$8.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082878",
        "Product Status": "Active"
    },
    {
        "Root": "10003320",
        "LV": "1",
        "Item Description": "SYRUP 2.5 POWER MTN BLST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "104239",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003320",
        "Product Status": "Active"
    },
    {
        "Root": "10005734",
        "LV": "1",
        "Item Description": "CREPE SHELL PLAIN .75 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7045011",
        "Last Price Paid": "$0.29",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005734",
        "Product Status": "Active"
    },
    {
        "Root": "10043055",
        "LV": "1",
        "Item Description": "INSERT IBOX 2 COMPARTMENT PP 7.75X5.5X1.81",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51377048",
        "Last Price Paid": "$0.19",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043055",
        "Product Status": "Active"
    },
    {
        "Root": "10035027",
        "LV": "1",
        "Item Description": "FLOUR CORNMEAL ENRICHED BAG 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4525893",
        "Last Price Paid": "$18.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035027",
        "Product Status": "Active"
    },
    {
        "Root": "10039704",
        "LV": "1",
        "Item Description": "SHEET TRANSFER DOUBLE R MONOGRAM 100/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65607",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039704",
        "Product Status": "Active"
    },
    {
        "Root": "10013105",
        "LV": "1",
        "Item Description": "BEEF STRIPLOIN WAGYU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013105001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4501802",
        "Last Price Paid": "$26.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013105",
        "Product Status": "Active"
    },
    {
        "Root": "10001756",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "450005",
        "Last Price Paid": "$1.00",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001756",
        "Product Status": "Active"
    },
    {
        "Root": "10001756",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450005",
        "Last Price Paid": "$1.00",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001756",
        "Product Status": "Active"
    },
    {
        "Root": "10001756",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001756",
        "Product Status": "Active"
    },
    {
        "Root": "10000462",
        "LV": "1",
        "Item Description": "MUFFIN BLUEBERRY PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "805:D",
        "Last Price Paid": "$0.41",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000462",
        "Product Status": "Active"
    },
    {
        "Root": "10010035",
        "LV": "1",
        "Item Description": "BEER SCHFFRHF GRPFRT 16Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010035",
        "Product Status": "Active"
    },
    {
        "Root": "10010096",
        "LV": "1",
        "Item Description": "BEER KRONENBOURG 1664 12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.30",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010096",
        "Product Status": "Active"
    },
    {
        "Root": "10110635",
        "LV": "1",
        "Item Description": "CEREAL COCOA PUFFS BOWL 96/1.06OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.68",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110635",
        "Product Status": "Active"
    },
    {
        "Root": "10001325",
        "LV": "1",
        "Item Description": "POTATO CHIP FROZEN RAW NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.45",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001325",
        "Product Status": "Active"
    },
    {
        "Root": "10004163",
        "LV": "1",
        "Item Description": "POWDER AGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004163001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87416",
        "Last Price Paid": "$42.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004163",
        "Product Status": "Active"
    },
    {
        "Root": "10019152",
        "LV": "1",
        "Item Description": "SYRUP MONIN HONEY MANGO LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019152",
        "Product Status": "Active"
    },
    {
        "Root": "10003732",
        "LV": "1",
        "Item Description": "MIX PECAN 2LB/10EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "MIXPEC",
        "Last Price Paid": "$12.75",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003732",
        "Product Status": "Active"
    },
    {
        "Root": "10003408",
        "LV": "1",
        "Item Description": "SYRUP SNO CONE CHERRY4/1G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1223H",
        "Last Price Paid": "$10.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003408",
        "Product Status": "Active"
    },
    {
        "Root": "10008306",
        "LV": "1",
        "Item Description": "COOLER SMIRNOFF ICE 12OZ BT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008306",
        "Product Status": "Active"
    },
    {
        "Root": "10104443",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS PASSION FRUIT 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76399",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104443",
        "Product Status": "Active"
    },
    {
        "Root": "10104443",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS PASSION FRUIT 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104443",
        "Product Status": "Active"
    },
    {
        "Root": "10033157",
        "LV": "1",
        "Item Description": "CAKE BAND PLASTIC 3 500 FT ROLL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033157001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U300",
        "Last Price Paid": "$34.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033157",
        "Product Status": "Active"
    },
    {
        "Root": "10000371",
        "LV": "1",
        "Item Description": "ONION RED RINGS 1/8 INCH CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "614061",
        "Last Price Paid": "$12.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000371",
        "Product Status": "Active"
    },
    {
        "Root": "10000371",
        "LV": "1",
        "Item Description": "ONION RED RINGS 1/8 INCH CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-007",
        "Last Price Paid": "$12.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000371",
        "Product Status": "Active"
    },
    {
        "Root": "10045256",
        "LV": "1",
        "Item Description": "SB COFFEE BLEND ODYSSEY 5LB 1EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11120892",
        "Last Price Paid": "$59.60",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045256",
        "Product Status": "Active"
    },
    {
        "Root": "10003996",
        "LV": "1",
        "Item Description": "SPICE CURRY POWDR SUN BRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "72538",
        "Last Price Paid": "$6.90",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003996",
        "Product Status": "Active"
    },
    {
        "Root": "10035173",
        "LV": "2",
        "Item Description": "HERRING, NORWEGIAN 5-8 20KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035173002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6369604",
        "Last Price Paid": "$1.00",
        "Case Pack": "44.09",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035173",
        "Product Status": "Active"
    },
    {
        "Root": "10035173",
        "LV": "2",
        "Item Description": "HERRING, NORWEGIAN 5-8 20KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035173002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50024",
        "Last Price Paid": "$1.00",
        "Case Pack": "44.09",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035173",
        "Product Status": "Active"
    },
    {
        "Root": "10002266",
        "LV": "1",
        "Item Description": "CHEESE SPREAD GARLIC AND HERB RONDELE 5 LB TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "92900",
        "Last Price Paid": "$42.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002266",
        "Product Status": "Active"
    },
    {
        "Root": "10015258",
        "LV": "2",
        "Item Description": "SYRUP MAPLE BOURBON BLIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015258002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015258",
        "Product Status": "Active"
    },
    {
        "Root": "10002207",
        "LV": "2",
        "Item Description": "YOGURT GREEK STRAW LITE AND FIT 12/5.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002207001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002207",
        "Product Status": "Active"
    },
    {
        "Root": "10034908",
        "LV": "1",
        "Item Description": "SPRAY COCOA BUTTER RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586003",
        "Last Price Paid": "$52.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034908",
        "Product Status": "Active"
    },
    {
        "Root": "10022399",
        "LV": "1",
        "Item Description": "CHOC WHT SQ 2 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63205W",
        "Last Price Paid": "$1.25",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022399",
        "Product Status": "Active"
    },
    {
        "Root": "10012739",
        "LV": "1",
        "Item Description": "FENNEL BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VG020",
        "Last Price Paid": "$3.72",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012739",
        "Product Status": "Active"
    },
    {
        "Root": "10006906",
        "LV": "1",
        "Item Description": "CHARD WILLIAM HILL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9100890",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006906",
        "Product Status": "Active"
    },
    {
        "Root": "10029046",
        "LV": "1",
        "Item Description": "COTTON CANDY CONES 1M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029046001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3021M",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029046",
        "Product Status": "Active"
    },
    {
        "Root": "10009836",
        "LV": "1",
        "Item Description": "COGNAC REMY MARTIN VSOP LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294003",
        "Last Price Paid": "$62.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009836",
        "Product Status": "Active"
    },
    {
        "Root": "10009507",
        "LV": "1",
        "Item Description": "BOURBON WILLET POT STILL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "112204",
        "Last Price Paid": "$45.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009507",
        "Product Status": "Active"
    },
    {
        "Root": "10002868",
        "LV": "1",
        "Item Description": "COKE DIET CFFR 20OZ BTL 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "102579",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002868",
        "Product Status": "Active"
    },
    {
        "Root": "10008691",
        "LV": "1",
        "Item Description": "CHAMP N FEUILLA PALM DOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "948780",
        "Last Price Paid": "$112.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008691",
        "Product Status": "Active"
    },
    {
        "Root": "10021451",
        "LV": "2",
        "Item Description": "PRESERVE FIG 11.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021451002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "JAM004",
        "Last Price Paid": "$38.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021451",
        "Product Status": "Active"
    },
    {
        "Root": "10001683",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20637",
        "Last Price Paid": "$16.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001683",
        "Product Status": "Active"
    },
    {
        "Root": "10001683",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001683",
        "Product Status": "Active"
    },
    {
        "Root": "10001683",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407930",
        "Last Price Paid": "$16.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001683",
        "Product Status": "Active"
    },
    {
        "Root": "10016960",
        "LV": "1",
        "Item Description": "SPICE SAGE RUBBED 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760603",
        "Last Price Paid": "$12.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016960",
        "Product Status": "Active"
    },
    {
        "Root": "10000483",
        "LV": "2",
        "Item Description": "PASTRY CROISSANT LARGE BAKED 3.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000483002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000483",
        "Product Status": "Active"
    },
    {
        "Root": "10084119",
        "LV": "2",
        "Item Description": "DONUT ORIGINAL GLAZED DZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084119002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS101",
        "Last Price Paid": "$0.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084119",
        "Product Status": "Active"
    },
    {
        "Root": "10010076",
        "LV": "1",
        "Item Description": "BEER STELLA ARTOIS 12OZ BTL 24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010076001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010076",
        "Product Status": "Active"
    },
    {
        "Root": "10026180",
        "LV": "1",
        "Item Description": "CHIC TENDERLOIN ALLERGY FREE CKD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2759728",
        "Last Price Paid": "$27.70",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026180",
        "Product Status": "Active"
    },
    {
        "Root": "10012450",
        "LV": "1",
        "Item Description": "DEC PLUMERIA 1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "13435",
        "Last Price Paid": "$0.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012450",
        "Product Status": "Active"
    },
    {
        "Root": "10022353",
        "LV": "1",
        "Item Description": "TEMPRANILLO MARQUES MURRIETA RIOJA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561817",
        "Last Price Paid": "$18.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022353",
        "Product Status": "Active"
    },
    {
        "Root": "10001576",
        "LV": "1",
        "Item Description": "PEPPER YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598002",
        "Last Price Paid": "$10.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001576",
        "Product Status": "Active"
    },
    {
        "Root": "10001576",
        "LV": "1",
        "Item Description": "PEPPER YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20740",
        "Last Price Paid": "$10.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001576",
        "Product Status": "Active"
    },
    {
        "Root": "10001576",
        "LV": "1",
        "Item Description": "PEPPER YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "598002",
        "Last Price Paid": "$10.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001576",
        "Product Status": "Active"
    },
    {
        "Root": "10025666",
        "LV": "3",
        "Item Description": "BUFFALO STRIP LOIN 119F",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025666003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "BUFABF010",
        "Last Price Paid": "$19.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10025666",
        "Product Status": "Active"
    },
    {
        "Root": "10095616",
        "LV": "1",
        "Item Description": "NOODLE, RAMEN RAW FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9170197",
        "Last Price Paid": "$0.81",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095616",
        "Product Status": "Active"
    },
    {
        "Root": "10102329",
        "LV": "1",
        "Item Description": "GELATO PLANT BASED VANILLA 4 PACK HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "P-VANB-08-CS4",
        "Last Price Paid": "$19.13",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102329",
        "Product Status": "Active"
    },
    {
        "Root": "10001568",
        "LV": "1",
        "Item Description": "SQUASH CALABAZA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480871",
        "Last Price Paid": "$10.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001568",
        "Product Status": "Active"
    },
    {
        "Root": "10000949",
        "LV": "1",
        "Item Description": "SALMON BIAS CUT 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000949001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "0647818",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000949",
        "Product Status": "Active"
    },
    {
        "Root": "10009955",
        "LV": "1",
        "Item Description": "LIQ CRM DE CASS MASSENEZ LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009955",
        "Product Status": "Active"
    },
    {
        "Root": "10003814",
        "LV": "1",
        "Item Description": "BASE LEMONADE 46%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5462307",
        "Last Price Paid": "$13.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003814",
        "Product Status": "Active"
    },
    {
        "Root": "10014160",
        "LV": "1",
        "Item Description": "PARSNIPS YOUNG 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$125.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014160",
        "Product Status": "Active"
    },
    {
        "Root": "10010155",
        "LV": "2",
        "Item Description": "BITTERS PEYCHAUD AROM 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010155001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9031536",
        "Last Price Paid": "$5.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010155",
        "Product Status": "Active"
    },
    {
        "Root": "10092050",
        "LV": "1",
        "Item Description": "POPCORN BAG WERTHER'S 5X3X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510598",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092050",
        "Product Status": "Active"
    },
    {
        "Root": "10016980",
        "LV": "2",
        "Item Description": "SPICE CURRY POWDER 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016980002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760223",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016980",
        "Product Status": "Active"
    },
    {
        "Root": "10022058",
        "LV": "1",
        "Item Description": "LOAF CONTAINER 3.75X2X2 POLYCOAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51408042",
        "Last Price Paid": "",
        "Case Pack": "1050",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022058",
        "Product Status": "Active"
    },
    {
        "Root": "10037231",
        "LV": "1",
        "Item Description": "WEDDING CAKE TIERED 4 PLUS 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10005785",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037231",
        "Product Status": "Active"
    },
    {
        "Root": "10032526",
        "LV": "1",
        "Item Description": "CHENIN EDGEBASTON CAMINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "EDGECAMCHBL",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032526",
        "Product Status": "Active"
    },
    {
        "Root": "10004852",
        "LV": "1",
        "Item Description": "MIX, BATTER PKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004852",
        "Product Status": "Active"
    },
    {
        "Root": "10002369",
        "LV": "1",
        "Item Description": "CHEESE HARD TETE DE MONDE SWITZERLAND 2 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002369001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "45124",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002369",
        "Product Status": "Active"
    },
    {
        "Root": "10002442",
        "LV": "2",
        "Item Description": "ICE CREAM BUTTER PECAN NO SUGAR ADDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002442002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002442",
        "Product Status": "Active"
    },
    {
        "Root": "10037946",
        "LV": "1",
        "Item Description": "CRACKED FREEKAH BOBS RED MILL 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037946001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "7011B25",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037946",
        "Product Status": "Active"
    },
    {
        "Root": "10014635",
        "LV": "1",
        "Item Description": "CHARD CHAVY PULIGNY MONT CLAV - PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "521521",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014635",
        "Product Status": "Active"
    },
    {
        "Root": "10039984",
        "LV": "1",
        "Item Description": "BOX PLAIN KRAFT CRDBRD 10X10X10 ROLLED DENIM LINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039984001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51078005",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039984",
        "Product Status": "Active"
    },
    {
        "Root": "10021339",
        "LV": "2",
        "Item Description": "BLND RED FATTORIA POGGIO GIMIGNANO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021339001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "803326718010",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10021339",
        "Product Status": "Active"
    },
    {
        "Root": "10003068",
        "LV": "1",
        "Item Description": "MAYONNAISE DICKENSON JAR 1.2OZ 72CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003068",
        "Product Status": "Active"
    },
    {
        "Root": "10005604",
        "LV": "1",
        "Item Description": "PRETZEL JUMBO 7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7245152",
        "Last Price Paid": "$0.70",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005604",
        "Product Status": "Active"
    },
    {
        "Root": "10026756",
        "LV": "1",
        "Item Description": "LID CLEAR FOR FIBER TRAY 10 X 7.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11400022",
        "Last Price Paid": "$0.30",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026756",
        "Product Status": "Active"
    },
    {
        "Root": "10018731",
        "LV": "1",
        "Item Description": "CANDY TOPPING CHOC DROP SEMI 4000CT HERSHEY 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582610",
        "Last Price Paid": "$117.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018731",
        "Product Status": "Active"
    },
    {
        "Root": "10008184",
        "LV": "1",
        "Item Description": "CAB SAUV MONDAVI RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008184001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "523343",
        "Last Price Paid": "$113.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008184",
        "Product Status": "Active"
    },
    {
        "Root": "10007001",
        "LV": "1",
        "Item Description": "PINOT NOIR LA CREMA SON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "604124",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007001",
        "Product Status": "Active"
    },
    {
        "Root": "10044501",
        "LV": "1",
        "Item Description": "MACARONI & CHEESE CHEDDAR AMERICAN FROZEN 6/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7321048",
        "Last Price Paid": "$8.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044501",
        "Product Status": "Active"
    },
    {
        "Root": "10004027",
        "LV": "1",
        "Item Description": "CO2 TNK 20# HIGH PRSSURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004027",
        "Product Status": "Active"
    },
    {
        "Root": "10021131",
        "LV": "1",
        "Item Description": "RELISH HELLISH RELLISH 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "HR-G",
        "Last Price Paid": "$29.82",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021131",
        "Product Status": "Active"
    },
    {
        "Root": "10005670",
        "LV": "1",
        "Item Description": "PUFF PASTRY DOUGH BUTTER LECOQ 16 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "90024",
        "Last Price Paid": "$9.37",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005670",
        "Product Status": "Active"
    },
    {
        "Root": "10002594",
        "LV": "1",
        "Item Description": "PEPPER ANAHIEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002594",
        "Product Status": "Active"
    },
    {
        "Root": "10002594",
        "LV": "1",
        "Item Description": "PEPPER ANAHIEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407921",
        "Last Price Paid": "$24.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002594",
        "Product Status": "Active"
    },
    {
        "Root": "10010419",
        "LV": "2",
        "Item Description": "BEER SIERRA NEVADA PALE 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010419001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10487",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010419",
        "Product Status": "Active"
    },
    {
        "Root": "10037950",
        "LV": "1",
        "Item Description": "DOMAINE PALLUS CHINON MESSANGES ROUGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "000390",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037950",
        "Product Status": "Active"
    },
    {
        "Root": "10012623",
        "LV": "1",
        "Item Description": "CHIVES MEMO GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04GCI-33-H",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012623",
        "Product Status": "Active"
    },
    {
        "Root": "10014285",
        "LV": "1",
        "Item Description": "WATER SAN PELLGN LTR GLS SPARKLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014285001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6310338",
        "Last Price Paid": "$2.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014285",
        "Product Status": "Active"
    },
    {
        "Root": "10003033",
        "LV": "1",
        "Item Description": "JAM STRAWBERRY .5Z 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003033",
        "Product Status": "Active"
    },
    {
        "Root": "10002453",
        "LV": "2",
        "Item Description": "SORBET RASPBERRY DAIRY FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002453002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002453",
        "Product Status": "Active"
    },
    {
        "Root": "10000379",
        "LV": "1",
        "Item Description": "ASIAN BLEND BABY 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000379001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480068",
        "Last Price Paid": "$48.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000379",
        "Product Status": "Active"
    },
    {
        "Root": "10000379",
        "LV": "1",
        "Item Description": "ASIAN BLEND BABY 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000379001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09BA-9A",
        "Last Price Paid": "$48.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000379",
        "Product Status": "Active"
    },
    {
        "Root": "10000379",
        "LV": "1",
        "Item Description": "ASIAN BLEND BABY 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000379001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000379",
        "Product Status": "Active"
    },
    {
        "Root": "10000538",
        "LV": "1",
        "Item Description": "BREAD FOCACCIA PLAIN SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000538",
        "Product Status": "Active"
    },
    {
        "Root": "10014361",
        "LV": "1",
        "Item Description": "MINNIE BOW RED/WHITE DOTS 2 1/8'' X 1 1/8\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW32290",
        "Last Price Paid": "$0.80",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014361",
        "Product Status": "Active"
    },
    {
        "Root": "10001585",
        "LV": "1",
        "Item Description": "SPROUT BRUSSEL REG FNCY 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$45.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001585",
        "Product Status": "Active"
    },
    {
        "Root": "10001585",
        "LV": "1",
        "Item Description": "SPROUT BRUSSEL REG FNCY 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482010",
        "Last Price Paid": "$45.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001585",
        "Product Status": "Active"
    },
    {
        "Root": "10108335",
        "LV": "1",
        "Item Description": "DEC CHOC SQUARE 1.5 W/WISH GOAT 432/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "1454CP",
        "Last Price Paid": "$0.47",
        "Case Pack": "432",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108335",
        "Product Status": "Active"
    },
    {
        "Root": "10021082",
        "LV": "2",
        "Item Description": "PINOT NOIR FESS PARKER SANTA RITA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021082002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9167992",
        "Last Price Paid": "$19.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021082",
        "Product Status": "Active"
    },
    {
        "Root": "10000341",
        "LV": "1",
        "Item Description": "TURNIP 3/4 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-011-34",
        "Last Price Paid": "$11.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000341",
        "Product Status": "Active"
    },
    {
        "Root": "10000341",
        "LV": "1",
        "Item Description": "TURNIP 3/4 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481618",
        "Last Price Paid": "$11.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000341",
        "Product Status": "Active"
    },
    {
        "Root": "10009497",
        "LV": "1",
        "Item Description": "BOURBON WILD TURKEY 101* LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009497001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009497",
        "Product Status": "Active"
    },
    {
        "Root": "10002030",
        "LV": "1",
        "Item Description": "VEG ROOT MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MBRT-26",
        "Last Price Paid": "$57.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002030",
        "Product Status": "Active"
    },
    {
        "Root": "10002545",
        "LV": "1",
        "Item Description": "CARROT TOP MICRO 4.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MCT-33-L",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002545",
        "Product Status": "Active"
    },
    {
        "Root": "10007675",
        "LV": "2",
        "Item Description": "BLND CAB ARGIANO SOLENGO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007675001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9260891",
        "Last Price Paid": "$53.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007675",
        "Product Status": "Active"
    },
    {
        "Root": "10013428",
        "LV": "1",
        "Item Description": "SQUASH YELLOW STRAIGHTNECK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "409058",
        "Last Price Paid": "$18.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013428",
        "Product Status": "Active"
    },
    {
        "Root": "10013428",
        "LV": "1",
        "Item Description": "SQUASH YELLOW STRAIGHTNECK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013428",
        "Product Status": "Active"
    },
    {
        "Root": "10082069",
        "LV": "1",
        "Item Description": "WINE ZINFANDEL 4 VIRTUES BOURBON BARREL 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082069001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "28617",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082069",
        "Product Status": "Active"
    },
    {
        "Root": "10027602",
        "LV": "1",
        "Item Description": "CANDY EASTER EGG SUGAR COATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "800",
        "Last Price Paid": "$0.06",
        "Case Pack": "3393",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027602",
        "Product Status": "Active"
    },
    {
        "Root": "10002575",
        "LV": "1",
        "Item Description": "PEA-RIFFIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04PF-33-L",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002575",
        "Product Status": "Active"
    },
    {
        "Root": "10109556",
        "LV": "1",
        "Item Description": "Q MIXER HIBISCUS GIINGER BEER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9447838",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109556",
        "Product Status": "Active"
    },
    {
        "Root": "10087731",
        "LV": "1",
        "Item Description": "GARNISH DEHYDRATED LEMON WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "LC-1",
        "Last Price Paid": "$0.12",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087731",
        "Product Status": "Active"
    },
    {
        "Root": "10012760",
        "LV": "1",
        "Item Description": "LETTUCE PETITE MIXED CRYSTAL 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012760001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09CLQ-33-S",
        "Last Price Paid": "$33.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012760",
        "Product Status": "Active"
    },
    {
        "Root": "10000087",
        "LV": "1",
        "Item Description": "BEEF RIBEYE STEAK 10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000087001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190052",
        "Last Price Paid": "$17.55",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000087",
        "Product Status": "Active"
    },
    {
        "Root": "10004905",
        "LV": "1",
        "Item Description": "CANDY JELLY BEAN TROPICAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "574920",
        "Last Price Paid": "$71.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004905",
        "Product Status": "Active"
    },
    {
        "Root": "10025111",
        "LV": "1",
        "Item Description": "DRAFT ELYSIAN SPACE DUST IPA 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "44302",
        "Last Price Paid": "$231.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025111",
        "Product Status": "Active"
    },
    {
        "Root": "10005126",
        "LV": "1",
        "Item Description": "GRAVY TURKEY RTU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "847400",
        "Last Price Paid": "$4.76",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005126",
        "Product Status": "Active"
    },
    {
        "Root": "10006282",
        "LV": "1",
        "Item Description": "BOARD CAKE 14 INCH X 1/2 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1412...{ENJAY{",
        "Last Price Paid": "$3.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006282",
        "Product Status": "Active"
    },
    {
        "Root": "10003194",
        "LV": "1",
        "Item Description": "COKE CHERRY CAN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003194",
        "Product Status": "Active"
    },
    {
        "Root": "10038085",
        "LV": "3",
        "Item Description": "BEEF HOT DOG 8-1 2/5LB 6INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038085002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.38",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10038085",
        "Product Status": "Active"
    },
    {
        "Root": "10004341",
        "LV": "1",
        "Item Description": "SEASON REDFISH BLKND 24OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8496432",
        "Last Price Paid": "$8.83",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004341",
        "Product Status": "Active"
    },
    {
        "Root": "10001363",
        "LV": "1",
        "Item Description": "CURRY DURBAN 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001363",
        "Product Status": "Active"
    },
    {
        "Root": "10032764",
        "LV": "2",
        "Item Description": "AMORETTE'S DIS CORNER TRAY120 8X8X4 BOX 2PMS 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032764001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091267",
        "Last Price Paid": "$0.82",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10032764",
        "Product Status": "Active"
    },
    {
        "Root": "10004632",
        "LV": "1",
        "Item Description": "NUTRI GRAIN BAR APPLE 1.3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5067145",
        "Last Price Paid": "$0.66",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004632",
        "Product Status": "Active"
    },
    {
        "Root": "10006123",
        "LV": "1",
        "Item Description": "CUP CLEAR PLASTIC TUMBLER SQUAT 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006123001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006123",
        "Product Status": "Active"
    },
    {
        "Root": "10039017",
        "LV": "1",
        "Item Description": "TWINNING COLD INFUSED PASS FRUIT MANGO BLOOD ORANG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039017001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039017",
        "Product Status": "Active"
    },
    {
        "Root": "10005939",
        "LV": "1",
        "Item Description": "RICE CRISPY TREAT CHOCOLATE MICKEY WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.55",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005939",
        "Product Status": "Active"
    },
    {
        "Root": "10037560",
        "LV": "1",
        "Item Description": "CUP PAPER TULIP COCOA 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003746",
        "Last Price Paid": "$0.07",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037560",
        "Product Status": "Active"
    },
    {
        "Root": "10012385",
        "LV": "1",
        "Item Description": "SHISO RED FARMERS BOUNTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14FBSR-2",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012385",
        "Product Status": "Active"
    },
    {
        "Root": "10012231",
        "LV": "1",
        "Item Description": "LIQ TIPPLERS ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "849994",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012231",
        "Product Status": "Active"
    },
    {
        "Root": "10012231",
        "LV": "1",
        "Item Description": "LIQ TIPPLERS ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "849994",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012231",
        "Product Status": "Active"
    },
    {
        "Root": "10022533",
        "LV": "1",
        "Item Description": "ORANGE SLICED 2/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "690005",
        "Last Price Paid": "$16.73",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022533",
        "Product Status": "Active"
    },
    {
        "Root": "10022822",
        "LV": "1",
        "Item Description": "RED BLND TABLAS CREEK COTE DE TABLAS ROUGE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2278646",
        "Last Price Paid": "$29.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022822",
        "Product Status": "Active"
    },
    {
        "Root": "10014810",
        "LV": "1",
        "Item Description": "FORGET ME NOT FILLERS (MIX) FMN1MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "FMN1 MIX",
        "Last Price Paid": "$9.53",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014810",
        "Product Status": "Active"
    },
    {
        "Root": "10006975",
        "LV": "1",
        "Item Description": "SHIRAZ PENFOLD BIN 28 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9654094",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006975",
        "Product Status": "Active"
    },
    {
        "Root": "10006975",
        "LV": "1",
        "Item Description": "SHIRAZ PENFOLD BIN 28 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "9654094",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006975",
        "Product Status": "Active"
    },
    {
        "Root": "10006975",
        "LV": "1",
        "Item Description": "SHIRAZ PENFOLD BIN 28 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9654094",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006975",
        "Product Status": "Active"
    },
    {
        "Root": "10012389",
        "LV": "2",
        "Item Description": "DEC ORCHID 4\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012389002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7072",
        "Last Price Paid": "$2.94",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012389",
        "Product Status": "Active"
    },
    {
        "Root": "10003151",
        "LV": "2",
        "Item Description": "QUINS BUTTERFLY BRIGHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003151002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5816",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003151",
        "Product Status": "Active"
    },
    {
        "Root": "10004568",
        "LV": "1",
        "Item Description": "PUDDING CHOCOLATE INSTANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8370652",
        "Last Price Paid": "$3.78",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004568",
        "Product Status": "Active"
    },
    {
        "Root": "10108334",
        "LV": "1",
        "Item Description": "VODKA VUSA 6/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "111260",
        "Last Price Paid": "$18.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108334",
        "Product Status": "Active"
    },
    {
        "Root": "10000749",
        "LV": "1",
        "Item Description": "RABBIT WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000749001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4518400",
        "Last Price Paid": "$12.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000749",
        "Product Status": "Active"
    },
    {
        "Root": "10094762",
        "LV": "1",
        "Item Description": "MILK CCNUT, UNSTN IMP TLAND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7522615",
        "Last Price Paid": "$9.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094762",
        "Product Status": "Active"
    },
    {
        "Root": "10039800",
        "LV": "1",
        "Item Description": "CHOC CHRISTMAS LIGHTS LARGE 144/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039800001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1408",
        "Last Price Paid": "$0.23",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039800",
        "Product Status": "Active"
    },
    {
        "Root": "10003612",
        "LV": "1",
        "Item Description": "SNACK PRETZEL CHOCOLATE 15# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "520540",
        "Last Price Paid": "$57.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003612",
        "Product Status": "Active"
    },
    {
        "Root": "10039989",
        "LV": "1",
        "Item Description": "BEEF RIBEYE STEAK 16Z 1\" TAIL PRIME ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039989001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2194998",
        "Last Price Paid": "$22.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039989",
        "Product Status": "Active"
    },
    {
        "Root": "10033661",
        "LV": "2",
        "Item Description": "AMORETTES BOX 1 COLOR FLOOD COATED 4X4X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033661002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51855021",
        "Last Price Paid": "$0.47",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033661",
        "Product Status": "Active"
    },
    {
        "Root": "10090244",
        "LV": "1",
        "Item Description": "OLIVE KALAMATA PITTED EXTRA LARGE 4/4.4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8336190",
        "Last Price Paid": "$14.90",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090244",
        "Product Status": "Active"
    },
    {
        "Root": "10009421",
        "LV": "2",
        "Item Description": "MEAD HONEY BUNRATTY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009421001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "98151",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009421",
        "Product Status": "Active"
    },
    {
        "Root": "10023581",
        "LV": "1",
        "Item Description": "COOKIE OREO THIN 12/10.1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302254",
        "Last Price Paid": "$5.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023581",
        "Product Status": "Active"
    },
    {
        "Root": "10074170",
        "LV": "1",
        "Item Description": "PORK TASSO HICKORY SMKD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10074170001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3309010",
        "Last Price Paid": "$7.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10074170",
        "Product Status": "Active"
    },
    {
        "Root": "10005182",
        "LV": "1",
        "Item Description": "BEAN COCOA GRUE 100% ROAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005182001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C200",
        "Last Price Paid": "$24.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005182",
        "Product Status": "Active"
    },
    {
        "Root": "10009987",
        "LV": "1",
        "Item Description": "LIQ GRAND MARNIER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009987001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "471640",
        "Last Price Paid": "$35.78",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009987",
        "Product Status": "Active"
    },
    {
        "Root": "10003666",
        "LV": "1",
        "Item Description": "VINEGAR BANYULS 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "SPVIN010",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003666",
        "Product Status": "Active"
    },
    {
        "Root": "10003666",
        "LV": "1",
        "Item Description": "VINEGAR BANYULS 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-00113",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003666",
        "Product Status": "Active"
    },
    {
        "Root": "10109276",
        "LV": "1",
        "Item Description": "DISNEY HOLIDAY ORNAMT MINNIE 3.5X3.5CM 3000 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175950000",
        "Last Price Paid": "$0.12",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109276",
        "Product Status": "Active"
    },
    {
        "Root": "10095908",
        "LV": "1",
        "Item Description": "CUP DISPOSABLE PF16 FROST FLEX TUMBLER16OZ/500CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51178229",
        "Last Price Paid": "$0.23",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095908",
        "Product Status": "Active"
    },
    {
        "Root": "10002022",
        "LV": "1",
        "Item Description": "PINEAPPLE DCD 1/2IN 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-007D-12",
        "Last Price Paid": "$18.60",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002022",
        "Product Status": "Active"
    },
    {
        "Root": "10002022",
        "LV": "1",
        "Item Description": "PINEAPPLE DCD 1/2IN 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481831",
        "Last Price Paid": "$18.60",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002022",
        "Product Status": "Active"
    },
    {
        "Root": "10094810",
        "LV": "1",
        "Item Description": "TEA TWININGS COLD INFUSE WATERMLN MINT 22CT/4CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F16319",
        "Last Price Paid": "$5.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094810",
        "Product Status": "Active"
    },
    {
        "Root": "10001839",
        "LV": "1",
        "Item Description": "BONIATO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001839001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001839",
        "Product Status": "Active"
    },
    {
        "Root": "10090634",
        "LV": "1",
        "Item Description": "BEER COLLECTIVE ARTS GUAVA GOSE 12OZ CAN 24PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10228",
        "Last Price Paid": "$2.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090634",
        "Product Status": "Active"
    },
    {
        "Root": "10033667",
        "LV": "1",
        "Item Description": "EDIBLE GLITTERN MULTI 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "39207",
        "Last Price Paid": "$12.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033667",
        "Product Status": "Active"
    },
    {
        "Root": "10037833",
        "LV": "1",
        "Item Description": "MIX DRAGON FRUIT BLUE SWGE 6/4.43#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$108.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037833",
        "Product Status": "Active"
    },
    {
        "Root": "10021452",
        "LV": "1",
        "Item Description": "SPICE PEPPERCORN LONG DRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI900",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021452",
        "Product Status": "Active"
    },
    {
        "Root": "10035051",
        "LV": "1",
        "Item Description": "HAM SWEDISH STYLE 6-9LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035051001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "296A",
        "Last Price Paid": "",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035051",
        "Product Status": "Active"
    },
    {
        "Root": "10035573",
        "LV": "1",
        "Item Description": "CHAMPAGNE HENRIOT CUVEE HEMERA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "916156",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035573",
        "Product Status": "Active"
    },
    {
        "Root": "10017769",
        "LV": "3",
        "Item Description": "FONDANT BLUE VANILLA 2# PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017769003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "15402",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10017769",
        "Product Status": "Active"
    },
    {
        "Root": "10038888",
        "LV": "1",
        "Item Description": "TRAY 30 OUNCE BLACK 9X6.5 78130B300",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "303125",
        "Last Price Paid": "",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038888",
        "Product Status": "Active"
    },
    {
        "Root": "10110888",
        "LV": "2",
        "Item Description": "TRAY FOOD #200 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110888001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10110888",
        "Product Status": "Active"
    },
    {
        "Root": "10040901",
        "LV": "2",
        "Item Description": "EXTRACT PINEAPPLE OIL SOLUBLE 1.9LB/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040901002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1017",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040901",
        "Product Status": "Active"
    },
    {
        "Root": "10002238",
        "LV": "2",
        "Item Description": "ICE CREAM CUP CHOCOLATE 6Z/12C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002238002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002238",
        "Product Status": "Active"
    },
    {
        "Root": "10090552",
        "LV": "1",
        "Item Description": "LIQUEUR BYEJOE RED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090552001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "342249",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090552",
        "Product Status": "Active"
    },
    {
        "Root": "10009701",
        "LV": "2",
        "Item Description": "TEQ DON JULIO BLANCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009701001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009701",
        "Product Status": "Active"
    },
    {
        "Root": "10040884",
        "LV": "2",
        "Item Description": "SELTZER BUD LIGHT MANGO 12 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040884002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "10432",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040884",
        "Product Status": "Active"
    },
    {
        "Root": "10038972",
        "LV": "1",
        "Item Description": "CHICKEN WING COOKED BREADED 1&2 JOINT 6-10 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9209727",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038972",
        "Product Status": "Active"
    },
    {
        "Root": "10018155",
        "LV": "1",
        "Item Description": "POWDER DÉCOR GOLD LUSTER NON EDIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11047",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018155",
        "Product Status": "Active"
    },
    {
        "Root": "10040262",
        "LV": "2",
        "Item Description": "CHICKEN BREAST STRIP FRITTER 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040262002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040262",
        "Product Status": "Active"
    },
    {
        "Root": "10016092",
        "LV": "2",
        "Item Description": "PORK BUTT BONELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016092001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.34",
        "Case Pack": "37",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016092",
        "Product Status": "Active"
    },
    {
        "Root": "10005271",
        "LV": "1",
        "Item Description": "CEREAL CREAM OF WHEAT 28Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6000525",
        "Last Price Paid": "$2.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005271",
        "Product Status": "Active"
    },
    {
        "Root": "10090631",
        "LV": "1",
        "Item Description": "HABANERO HERB CRYSTALS 6/16 OZ FRESH ORIGINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CRY000",
        "Last Price Paid": "$30.48",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090631",
        "Product Status": "Active"
    },
    {
        "Root": "10008786",
        "LV": "1",
        "Item Description": "BLEND GLENNELLY GR VIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "445855",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008786",
        "Product Status": "Active"
    },
    {
        "Root": "10000871",
        "LV": "1",
        "Item Description": "CHICKEN ROTISSERIE COOKED DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000871001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4200982",
        "Last Price Paid": "$5.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000871",
        "Product Status": "Active"
    },
    {
        "Root": "10036434",
        "LV": "3",
        "Item Description": "PAPADOM PLAIN PRIDE OF INDIA 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036434003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "345130",
        "Last Price Paid": "$0.54",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10036434",
        "Product Status": "Active"
    },
    {
        "Root": "10106445",
        "LV": "1",
        "Item Description": "WINE BLEND STELLA ROSA PINK RTD CAN 250ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "243851",
        "Last Price Paid": "$3.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106445",
        "Product Status": "Active"
    },
    {
        "Root": "10023534",
        "LV": "1",
        "Item Description": "BAG HEAT SEAL 1.2 MIL 5 X 7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51682007",
        "Last Price Paid": "$0.09",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023534",
        "Product Status": "Active"
    },
    {
        "Root": "10005290",
        "LV": "1",
        "Item Description": "JUICE KEY LIME 4-GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8054793",
        "Last Price Paid": "$15.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005290",
        "Product Status": "Active"
    },
    {
        "Root": "10003149",
        "LV": "1",
        "Item Description": "QUIN MICKEY CELEBRATION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12508",
        "Last Price Paid": "$14.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003149",
        "Product Status": "Active"
    },
    {
        "Root": "10005610",
        "LV": "1",
        "Item Description": "BREAD MULTIGRAIN SUNFLOWER PANINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.89",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005610",
        "Product Status": "Active"
    },
    {
        "Root": "10002924",
        "LV": "1",
        "Item Description": "ORANGE FANTA CAN 12Z 12 COUNT FRIDGE PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.62",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002924",
        "Product Status": "Active"
    },
    {
        "Root": "10088729",
        "LV": "1",
        "Item Description": "SYRUP MONIN TIKI BLEND 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5218",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088729",
        "Product Status": "Active"
    },
    {
        "Root": "10008088",
        "LV": "1",
        "Item Description": "CHENIN BLC RAATS ORIGINAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "313892",
        "Last Price Paid": "$10.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008088",
        "Product Status": "Active"
    },
    {
        "Root": "10005076",
        "LV": "2",
        "Item Description": "SPICE POULTRY SEASONING 6/1.375LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005076002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.10",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005076",
        "Product Status": "Active"
    },
    {
        "Root": "10004714",
        "LV": "1",
        "Item Description": "PRETZEL ROLD GOLD TINY 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004714001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6513410",
        "Last Price Paid": "$0.34",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004714",
        "Product Status": "Active"
    },
    {
        "Root": "10030022",
        "LV": "1",
        "Item Description": "LID CLEAR 8.25X6 PET 520608D300",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51303077",
        "Last Price Paid": "$0.48",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030022",
        "Product Status": "Active"
    },
    {
        "Root": "10089479",
        "LV": "2",
        "Item Description": "SEEDS CHIA BLACK 50 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089479002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "320403",
        "Last Price Paid": "$240.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10089479",
        "Product Status": "Active"
    },
    {
        "Root": "10003289",
        "LV": "1",
        "Item Description": "COFFEE LE CELLIER ESPRESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.66",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003289",
        "Product Status": "Active"
    },
    {
        "Root": "10029435",
        "LV": "1",
        "Item Description": "CHENIN BLANC BELLINGHAM BERNARD SERIES OLDVINE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "238981",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029435",
        "Product Status": "Active"
    },
    {
        "Root": "10001187",
        "LV": "1",
        "Item Description": "CHICK BREAST SUB, VEGETARIAN GARDEIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "7426700016",
        "Last Price Paid": "$1.25",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001187",
        "Product Status": "Active"
    },
    {
        "Root": "10009863",
        "LV": "2",
        "Item Description": "VERMOUTH MARTINI SWT LTR RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009863001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "442533",
        "Last Price Paid": "$7.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009863",
        "Product Status": "Active"
    },
    {
        "Root": "10100372",
        "LV": "1",
        "Item Description": "WRAP PLAIN GREASE RESISTANT ECOCRAFT ND 14X14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008407",
        "Last Price Paid": "$0.03",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100372",
        "Product Status": "Active"
    },
    {
        "Root": "10088613",
        "LV": "1",
        "Item Description": "DEC DARK CHOCOLATE COVERED CROQUANT 1.8 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088613001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "2042610000",
        "Last Price Paid": "$106.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088613",
        "Product Status": "Active"
    },
    {
        "Root": "10110030",
        "LV": "1",
        "Item Description": "CHAMPAGNE POL ROGER BLANC D BLANC 6/750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "584587",
        "Last Price Paid": "$118.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110030",
        "Product Status": "Active"
    },
    {
        "Root": "10107879",
        "LV": "2",
        "Item Description": "BUCKET POP PREM HOL23 TOY SOLDIER MM 48OZ 8/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107879002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.63",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107879",
        "Product Status": "Active"
    },
    {
        "Root": "10004460",
        "LV": "1",
        "Item Description": "MIX FREESTYLE MM LEMONADE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3835945",
        "Last Price Paid": "$27.02",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004460",
        "Product Status": "Active"
    },
    {
        "Root": "10009908",
        "LV": "1",
        "Item Description": "LIQ KAMORA COFFEE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "577924",
        "Last Price Paid": "$11.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009908",
        "Product Status": "Active"
    },
    {
        "Root": "10029761",
        "LV": "1",
        "Item Description": "BREAD METHI PARATHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043458",
        "Last Price Paid": "$2.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029761",
        "Product Status": "Active"
    },
    {
        "Root": "10001762",
        "LV": "1",
        "Item Description": "LEEKS BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480092",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001762",
        "Product Status": "Active"
    },
    {
        "Root": "10001762",
        "LV": "1",
        "Item Description": "LEEKS BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001762",
        "Product Status": "Active"
    },
    {
        "Root": "10012745",
        "LV": "1",
        "Item Description": "RADISH PURPLE NINJA 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PNR-26",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012745",
        "Product Status": "Active"
    },
    {
        "Root": "10020400",
        "LV": "1",
        "Item Description": "HERICOT TARBAIS 17.65OZ 20 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "PREBEA101",
        "Last Price Paid": "$13.99",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020400",
        "Product Status": "Active"
    },
    {
        "Root": "10030676",
        "LV": "2",
        "Item Description": "SPRITE CRANBERRY 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030676002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "049000181166",
        "Last Price Paid": "$1.13",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030676",
        "Product Status": "Active"
    },
    {
        "Root": "10006458",
        "LV": "1",
        "Item Description": "GRILL BRICK BLACK WRPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2948859",
        "Last Price Paid": "$2.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006458",
        "Product Status": "Active"
    },
    {
        "Root": "10037914",
        "LV": "1",
        "Item Description": "AMAFRUITS UNSWEET ACAI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "855738002003",
        "Last Price Paid": "$0.76",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037914",
        "Product Status": "Active"
    },
    {
        "Root": "10008301",
        "LV": "2",
        "Item Description": "PINOT NOIR SPATB KESS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008301001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9338439",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008301",
        "Product Status": "Active"
    },
    {
        "Root": "10027151",
        "LV": "1",
        "Item Description": "ARUGULA MICRO V&A PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04VAR-33",
        "Last Price Paid": "$25.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027151",
        "Product Status": "Active"
    },
    {
        "Root": "10030007",
        "LV": "1",
        "Item Description": "CURLS BLOSSOM DUO CHRISTMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "48835",
        "Last Price Paid": "$69.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030007",
        "Product Status": "Active"
    },
    {
        "Root": "10003928",
        "LV": "1",
        "Item Description": "SB SYRUP CINNAMN DOLCE LT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108266",
        "Last Price Paid": "$10.93",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003928",
        "Product Status": "Active"
    },
    {
        "Root": "10095763",
        "LV": "1",
        "Item Description": "LIQUOR DRAMBUIE 750ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095763001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "92049",
        "Last Price Paid": "$36.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095763",
        "Product Status": "Active"
    },
    {
        "Root": "10040008",
        "LV": "1",
        "Item Description": "CIDER HARD DOMAINE DUPONT BOUCHE BRUT BTL 12.7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "6872",
        "Last Price Paid": "$5.38",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040008",
        "Product Status": "Active"
    },
    {
        "Root": "10000131",
        "LV": "2",
        "Item Description": "CHICKEN WING JUMBO 2 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000131001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.99",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000131",
        "Product Status": "Active"
    },
    {
        "Root": "10100971",
        "LV": "1",
        "Item Description": "COCONUT MILK SWEET CONDENSED 7.4OZ/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "V205",
        "Last Price Paid": "$2.85",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100971",
        "Product Status": "Active"
    },
    {
        "Root": "10106577",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER RED 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313001E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106577",
        "Product Status": "Active"
    },
    {
        "Root": "10003279",
        "LV": "1",
        "Item Description": "SYRUP BEVERLY 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003279",
        "Product Status": "Active"
    },
    {
        "Root": "10002147",
        "LV": "1",
        "Item Description": "CREAMER NON-DAIRY DRY INDIVIDUAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8009953",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002147",
        "Product Status": "Active"
    },
    {
        "Root": "10017159",
        "LV": "1",
        "Item Description": "DECOR SUGAR BUMBLE BEES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12418",
        "Last Price Paid": "$0.10",
        "Case Pack": "176",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017159",
        "Product Status": "Active"
    },
    {
        "Root": "10017908",
        "LV": "2",
        "Item Description": "SEASONING GARLIC HERB 18OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017908002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7494529",
        "Last Price Paid": "$19.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10017908",
        "Product Status": "Active"
    },
    {
        "Root": "10006736",
        "LV": "1",
        "Item Description": "BOARD CAKE 16 INCH X 1/2 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1612...{ENJAY{",
        "Last Price Paid": "$3.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006736",
        "Product Status": "Active"
    },
    {
        "Root": "10004168",
        "LV": "1",
        "Item Description": "POWDER RED BEET ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "83560",
        "Last Price Paid": "$17.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004168",
        "Product Status": "Active"
    },
    {
        "Root": "10022726",
        "LV": "1",
        "Item Description": "VERMOUTH NOUILLY PRAT EXTRA DRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "960234",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022726",
        "Product Status": "Active"
    },
    {
        "Root": "10034280",
        "LV": "1",
        "Item Description": "WINE SPARKLING PEACH CANELLA BELLINI 250ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034280",
        "Product Status": "Active"
    },
    {
        "Root": "10002655",
        "LV": "2",
        "Item Description": "SPICE BASIL LEAF WHOLE 4/24 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002655002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00621-1",
        "Last Price Paid": "$6.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002655",
        "Product Status": "Active"
    },
    {
        "Root": "10002655",
        "LV": "2",
        "Item Description": "SPICE BASIL LEAF WHOLE 4/24 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002655002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002655",
        "Product Status": "Active"
    },
    {
        "Root": "10040215",
        "LV": "1",
        "Item Description": "BACON SLAB SMK APPL DUROC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040215001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3432147",
        "Last Price Paid": "$5.70",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040215",
        "Product Status": "Active"
    },
    {
        "Root": "10106580",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER BERRY PINK 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313031E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106580",
        "Product Status": "Active"
    },
    {
        "Root": "10009580",
        "LV": "1",
        "Item Description": "GIN TANQUERAY 94.6 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009580",
        "Product Status": "Active"
    },
    {
        "Root": "10037547",
        "LV": "1",
        "Item Description": "BLEND WHITE MUGA RIOJA BLANCO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001062",
        "Last Price Paid": "$13.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037547",
        "Product Status": "Active"
    },
    {
        "Root": "10094038",
        "LV": "2",
        "Item Description": "SAUSAGE PORTGUGESE RAW SHEEP CASING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094038002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.18",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094038",
        "Product Status": "Active"
    },
    {
        "Root": "10032344",
        "LV": "1",
        "Item Description": "FOOD GRADE ARGON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "AR FG40",
        "Last Price Paid": "$21.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032344",
        "Product Status": "Active"
    },
    {
        "Root": "10106576",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER GOLD 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313071E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106576",
        "Product Status": "Active"
    },
    {
        "Root": "10023617",
        "LV": "1",
        "Item Description": "EGG WHITE CF HIGH WHIP FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023617",
        "Product Status": "Active"
    },
    {
        "Root": "10003619",
        "LV": "1",
        "Item Description": "CANDY M&M PEANUT BUTTER 1.63Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534601",
        "Last Price Paid": "$1.48",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003619",
        "Product Status": "Active"
    },
    {
        "Root": "10034862",
        "LV": "1",
        "Item Description": "INNISKILLIN ICEWINE RIESLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "552146",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034862",
        "Product Status": "Active"
    },
    {
        "Root": "10034862",
        "LV": "1",
        "Item Description": "INNISKILLIN ICEWINE RIESLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "552146",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034862",
        "Product Status": "Active"
    },
    {
        "Root": "10002793",
        "LV": "1",
        "Item Description": "BAKING SODA 24/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002793001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.07",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002793",
        "Product Status": "Active"
    },
    {
        "Root": "10007709",
        "LV": "2",
        "Item Description": "PINOT NOIR HITCHPST DANCR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007709001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007709",
        "Product Status": "Active"
    },
    {
        "Root": "10027516",
        "LV": "1",
        "Item Description": "COASTER 4\" ROUND 40PT CM CALIFORNIA GRILL 44R4DB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731045",
        "Last Price Paid": "",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027516",
        "Product Status": "Active"
    },
    {
        "Root": "10004256",
        "LV": "1",
        "Item Description": "SB LOAF PUMPKIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004256",
        "Product Status": "Active"
    },
    {
        "Root": "10031740",
        "LV": "1",
        "Item Description": "MASON JAR PLASTIC 24OZ NO HANDLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000071765",
        "Vendor": "PROGRESSIVE SPECIALTY GLASS COMPANY",
        "Vendor Item Nb": "35229-5330-10",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031740",
        "Product Status": "Active"
    },
    {
        "Root": "10006131",
        "LV": "1",
        "Item Description": "TAG RFID 21Z WDW-P091",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006131",
        "Product Status": "Active"
    },
    {
        "Root": "10104108",
        "LV": "1",
        "Item Description": "SAUCE SWT & SOUR 6/.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4279501",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104108",
        "Product Status": "Active"
    },
    {
        "Root": "10004712",
        "LV": "2",
        "Item Description": "CHIP POTATO BBQ 1.375Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004712002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9315094",
        "Last Price Paid": "",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004712",
        "Product Status": "Active"
    },
    {
        "Root": "10102337",
        "LV": "1",
        "Item Description": "WINE RED BLEND PALACIO QUEMADO LOS ACILATES 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102337",
        "Product Status": "Active"
    },
    {
        "Root": "10095948",
        "LV": "1",
        "Item Description": "BONBON, ASSORTMENT HOLLYWOOD 4/48 CT.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NOE780",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095948",
        "Product Status": "Active"
    },
    {
        "Root": "10001999",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01WAR-26",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001999",
        "Product Status": "Active"
    },
    {
        "Root": "10035448",
        "LV": "1",
        "Item Description": "STARCH ARROWROOT BOBS RED MILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101012",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035448",
        "Product Status": "Active"
    },
    {
        "Root": "10019102",
        "LV": "1",
        "Item Description": "SPARK,PIPER,SONOMA,BRUT,750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019102001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9400440",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019102",
        "Product Status": "Active"
    },
    {
        "Root": "10004498",
        "LV": "1",
        "Item Description": "CHOC PETIT FOUR TULIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "N..{MONA LISA{",
        "Last Price Paid": "",
        "Case Pack": "152",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004498",
        "Product Status": "Active"
    },
    {
        "Root": "10042288",
        "LV": "1",
        "Item Description": "CUP COLD CLEAR 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "3229",
        "Last Price Paid": "",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042288",
        "Product Status": "Active"
    },
    {
        "Root": "10000114",
        "LV": "1",
        "Item Description": "PORK BRATWURST FRESH 4X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000114001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "300",
        "Last Price Paid": "$3.15",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000114",
        "Product Status": "Active"
    },
    {
        "Root": "10005651",
        "LV": "2",
        "Item Description": "SHELL CONE BLACK SESAME SEED COATED 83CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005651001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LRN8090458",
        "Last Price Paid": "$0.53",
        "Case Pack": "83",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005651",
        "Product Status": "Active"
    },
    {
        "Root": "10000853",
        "LV": "1",
        "Item Description": "DUCK BRST BNLS SKIN ON 6-7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000853001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4301205",
        "Last Price Paid": "$13.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000853",
        "Product Status": "Active"
    },
    {
        "Root": "10000113",
        "LV": "1",
        "Item Description": "PORK SHANK OSSO BUCCO 14Z - 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000113001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2422424",
        "Last Price Paid": "$4.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000113",
        "Product Status": "Active"
    },
    {
        "Root": "10010410",
        "LV": "1",
        "Item Description": "BEER UNIBREW TRIOS PISTOLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "866176",
        "Last Price Paid": "$2.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010410",
        "Product Status": "Active"
    },
    {
        "Root": "10013882",
        "LV": "1",
        "Item Description": "PLUM BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407103",
        "Last Price Paid": "$43.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013882",
        "Product Status": "Active"
    },
    {
        "Root": "10102924",
        "LV": "1",
        "Item Description": "KOSHER BSC CHOCOLATE CAKE 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102924",
        "Product Status": "Active"
    },
    {
        "Root": "10000289",
        "LV": "1",
        "Item Description": "KALE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403500",
        "Last Price Paid": "$28.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000289",
        "Product Status": "Active"
    },
    {
        "Root": "10005792",
        "LV": "1",
        "Item Description": "PASTRY DANISH APPLE LATTICE MINI PRE-PROOF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005792001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061013",
        "Last Price Paid": "$0.34",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005792",
        "Product Status": "Active"
    },
    {
        "Root": "10004724",
        "LV": "1",
        "Item Description": "CHIP POTATO REG BAKED .87",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004724001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4551388",
        "Last Price Paid": "$0.32",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004724",
        "Product Status": "Active"
    },
    {
        "Root": "10095893",
        "LV": "2",
        "Item Description": "FISH DANISH HIRAMASA (2-3KG)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095893002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZFISHSP006",
        "Last Price Paid": "$23.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10095893",
        "Product Status": "Active"
    },
    {
        "Root": "10095893",
        "LV": "2",
        "Item Description": "FISH DANISH HIRAMASA (2-3KG)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095893002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "16060008",
        "Last Price Paid": "$23.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10095893",
        "Product Status": "Active"
    },
    {
        "Root": "10002707",
        "LV": "1",
        "Item Description": "FLOUR CAKE 8% WHEAT BLEACHED AMERICAN BEAUTY 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2495699",
        "Last Price Paid": "$22.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002707",
        "Product Status": "Active"
    },
    {
        "Root": "10044381",
        "LV": "2",
        "Item Description": "PEPPER CHIPOTLE WHOLE 6# CAN IN ADOBO SAUCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044381002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "PEP028",
        "Last Price Paid": "$4.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10044381",
        "Product Status": "Active"
    },
    {
        "Root": "10007105",
        "LV": "2",
        "Item Description": "CHAMP BILLE SLMN BRUT ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007105001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9166624",
        "Last Price Paid": "$65.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007105",
        "Product Status": "Active"
    },
    {
        "Root": "10003686",
        "LV": "1",
        "Item Description": "CANDY SKITTLES KS 4Z 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003686001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534420",
        "Last Price Paid": "$2.59",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003686",
        "Product Status": "Active"
    },
    {
        "Root": "10040273",
        "LV": "1",
        "Item Description": "CAB SAUV PRELIUS TUSCANY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9129625",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040273",
        "Product Status": "Active"
    },
    {
        "Root": "10010002",
        "LV": "1",
        "Item Description": "LIQ JAGERMEISTER LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010002001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "31393",
        "Last Price Paid": "$24.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010002",
        "Product Status": "Active"
    },
    {
        "Root": "10002374",
        "LV": "1",
        "Item Description": "GELATO TRAY CAPPUCCINO IT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "GT-046",
        "Last Price Paid": "$33.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002374",
        "Product Status": "Active"
    },
    {
        "Root": "10006965",
        "LV": "1",
        "Item Description": "MACMURRAY RANCH PINOT NOIR CENTRAL COAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9018737",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006965",
        "Product Status": "Active"
    },
    {
        "Root": "10006965",
        "LV": "1",
        "Item Description": "MACMURRAY RANCH PINOT NOIR CENTRAL COAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9018737",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006965",
        "Product Status": "Active"
    },
    {
        "Root": "10029829",
        "LV": "2",
        "Item Description": "TEQUILA MILAGRO SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029829002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001700",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029829",
        "Product Status": "Active"
    },
    {
        "Root": "10002776",
        "LV": "1",
        "Item Description": "SYRUP FCB BLUE RASPBERRY 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002776001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$109.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002776",
        "Product Status": "Active"
    },
    {
        "Root": "10109272",
        "LV": "1",
        "Item Description": "DISNEY HOLIDAY ORNAMT PLUTO 3.5X3.5CM 3000 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175910000",
        "Last Price Paid": "$0.14",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109272",
        "Product Status": "Active"
    },
    {
        "Root": "10016229",
        "LV": "1",
        "Item Description": "RED BLEND MAS DAUMAS GASSAC ROUGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9185647",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016229",
        "Product Status": "Active"
    },
    {
        "Root": "10012117",
        "LV": "1",
        "Item Description": "SEABASS FILLET SKIN ON WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012117001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3633716",
        "Last Price Paid": "$31.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012117",
        "Product Status": "Active"
    },
    {
        "Root": "10012117",
        "LV": "1",
        "Item Description": "SEABASS FILLET SKIN ON WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012117001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00766",
        "Last Price Paid": "$31.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012117",
        "Product Status": "Active"
    },
    {
        "Root": "10016702",
        "LV": "1",
        "Item Description": "BOX HAT RND 2PC AMORETTES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016702001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51700028",
        "Last Price Paid": "$6.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016702",
        "Product Status": "Active"
    },
    {
        "Root": "10016362",
        "LV": "1",
        "Item Description": "SQUID INK 17.6 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016362001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-80000",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016362",
        "Product Status": "Active"
    },
    {
        "Root": "10006284",
        "LV": "1",
        "Item Description": "BOARD CAKE HALF X 1/2 INCH GO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ES12...{ENJAY{",
        "Last Price Paid": "$3.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006284",
        "Product Status": "Active"
    },
    {
        "Root": "10014521",
        "LV": "1",
        "Item Description": "TORTILLA FLOUR 4.5\" PRESSED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014521001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2644748",
        "Last Price Paid": "$0.12",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014521",
        "Product Status": "Active"
    },
    {
        "Root": "10001665",
        "LV": "1",
        "Item Description": "CORN YELLOW - 48 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401105",
        "Last Price Paid": "$0.62",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001665",
        "Product Status": "Active"
    },
    {
        "Root": "10001665",
        "LV": "1",
        "Item Description": "CORN YELLOW - 48 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.62",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001665",
        "Product Status": "Active"
    },
    {
        "Root": "10001665",
        "LV": "1",
        "Item Description": "CORN YELLOW - 48 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20400",
        "Last Price Paid": "$0.62",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001665",
        "Product Status": "Active"
    },
    {
        "Root": "10004109",
        "LV": "1",
        "Item Description": "CANDY SNICKERS 48/1.86OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534503",
        "Last Price Paid": "$1.48",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004109",
        "Product Status": "Active"
    },
    {
        "Root": "10020402",
        "LV": "2",
        "Item Description": "MUSHROOM CHEFS MIX ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020402002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSXOT011",
        "Last Price Paid": "$59.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10020402",
        "Product Status": "Active"
    },
    {
        "Root": "10007039",
        "LV": "1",
        "Item Description": "BORD CH BATAILLEY PAUILLAC 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007039001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "203284",
        "Last Price Paid": "$85.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007039",
        "Product Status": "Active"
    },
    {
        "Root": "10002004",
        "LV": "1",
        "Item Description": "CORN SHUCKED W/HANDLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511023",
        "Last Price Paid": "$1.09",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002004",
        "Product Status": "Active"
    },
    {
        "Root": "10029151",
        "LV": "1",
        "Item Description": "CABBAGE SHREDDED ANGEL HAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481219",
        "Last Price Paid": "$9.91",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029151",
        "Product Status": "Active"
    },
    {
        "Root": "10014366",
        "LV": "1",
        "Item Description": "SORBET LEMON NSA ITALIAN 3 GALLON TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "NSASOR3-001",
        "Last Price Paid": "$63.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014366",
        "Product Status": "Active"
    },
    {
        "Root": "10002082",
        "LV": "1",
        "Item Description": "LEAVES BANANA FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002082001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450114",
        "Last Price Paid": "$41.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002082",
        "Product Status": "Active"
    },
    {
        "Root": "10105044",
        "LV": "1",
        "Item Description": "NORI GOLD HALF SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35249",
        "Last Price Paid": "$9.00",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105044",
        "Product Status": "Active"
    },
    {
        "Root": "10028875",
        "LV": "1",
        "Item Description": "BREAD MULTIGRAIN 3.25# NO PAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "09023NPD",
        "Last Price Paid": "$6.53",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028875",
        "Product Status": "Active"
    },
    {
        "Root": "10038275",
        "LV": "1",
        "Item Description": "MILK SUB UNSWEETENED COCONUT 1QT 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8435355",
        "Last Price Paid": "$2.97",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038275",
        "Product Status": "Active"
    },
    {
        "Root": "10027614",
        "LV": "1",
        "Item Description": "SAUCE KENS BBQ CANNONBALL 5 GALLON PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027614",
        "Product Status": "Active"
    },
    {
        "Root": "10003161",
        "LV": "1",
        "Item Description": "FILLING LEMON PIE 35# TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003161001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "2405224",
        "Last Price Paid": "$54.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003161",
        "Product Status": "Active"
    },
    {
        "Root": "10083722",
        "LV": "2",
        "Item Description": "CANDY PINEAPPLE GUMMY RING 2.2# BAG 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083722002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "39536",
        "Last Price Paid": "$5.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083722",
        "Product Status": "Active"
    },
    {
        "Root": "10002891",
        "LV": "1",
        "Item Description": "DRESSING VINAIGRETTE BALSAMIC 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.62",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002891",
        "Product Status": "Active"
    },
    {
        "Root": "10001618",
        "LV": "1",
        "Item Description": "EDAMAME IN POD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001618001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "18202",
        "Last Price Paid": "$49.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001618",
        "Product Status": "Active"
    },
    {
        "Root": "10001618",
        "LV": "1",
        "Item Description": "EDAMAME IN POD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001618001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401627",
        "Last Price Paid": "$49.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001618",
        "Product Status": "Active"
    },
    {
        "Root": "10007977",
        "LV": "1",
        "Item Description": "BLEND CHOC BLK BOEKNHTSKLF 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2305340",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007977",
        "Product Status": "Active"
    },
    {
        "Root": "10014769",
        "LV": "1",
        "Item Description": "SPICE SALT SEA HIMALYN FINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014769001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO91",
        "Last Price Paid": "$44.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014769",
        "Product Status": "Active"
    },
    {
        "Root": "10011320",
        "LV": "2",
        "Item Description": "CHEESE HARD SOTTOCENERE TRUFFLE ITALY 2/5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011320002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19111",
        "Last Price Paid": "$17.15",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011320",
        "Product Status": "Active"
    },
    {
        "Root": "10001854",
        "LV": "1",
        "Item Description": "GINGER ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001854001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001854",
        "Product Status": "Active"
    },
    {
        "Root": "10003078",
        "LV": "1",
        "Item Description": "JAM GRAPE TIN .5OZ 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003078001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9843319",
        "Last Price Paid": "$0.10",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003078",
        "Product Status": "Active"
    },
    {
        "Root": "10016325",
        "LV": "1",
        "Item Description": "CUPCAKE MED CHOC UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1635",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016325",
        "Product Status": "Active"
    },
    {
        "Root": "10088606",
        "LV": "1",
        "Item Description": "SYRUP RED HOT FLAVORED 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000534944",
        "Vendor": "SENSIENT TECHNOLOGIES CORP FLAVORS",
        "Vendor Item Nb": "SN300004406",
        "Last Price Paid": "$20.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088606",
        "Product Status": "Active"
    },
    {
        "Root": "10004369",
        "LV": "1",
        "Item Description": "MIX CREME BRULEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "64004",
        "Last Price Paid": "$7.30",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004369",
        "Product Status": "Active"
    },
    {
        "Root": "10007284",
        "LV": "3",
        "Item Description": "CAB SAUV OBERON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007284002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1477348",
        "Last Price Paid": "$17.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007284",
        "Product Status": "Active"
    },
    {
        "Root": "10010979",
        "LV": "1",
        "Item Description": "SB COFFEE COLD BREW BLEND 5 POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11048714",
        "Last Price Paid": "$52.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010979",
        "Product Status": "Active"
    },
    {
        "Root": "10010827",
        "LV": "2",
        "Item Description": "BEER NW BLGM FAT TIRE 12Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010827001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10684",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010827",
        "Product Status": "Active"
    },
    {
        "Root": "10027339",
        "LV": "1",
        "Item Description": "OCA MIXED ULTRA 50 CT PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01UMXOC-33",
        "Last Price Paid": "$95.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027339",
        "Product Status": "Active"
    },
    {
        "Root": "10017270",
        "LV": "2",
        "Item Description": "CARPANO ANTICA FORMULA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017270002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10017270",
        "Product Status": "Active"
    },
    {
        "Root": "10019712",
        "LV": "1",
        "Item Description": "OIL GRAPESEED BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OIL300",
        "Last Price Paid": "$37.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019712",
        "Product Status": "Active"
    },
    {
        "Root": "10107798",
        "LV": "1",
        "Item Description": "PLANTAIN TOSTONE HAWAIIAN SLICED IQF 3/6 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1254161",
        "Last Price Paid": "$13.72",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107798",
        "Product Status": "Active"
    },
    {
        "Root": "10031362",
        "LV": "1",
        "Item Description": "CLEMENTINES CUTIES 69/3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031362001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410054",
        "Last Price Paid": "$1.01",
        "Case Pack": "69",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031362",
        "Product Status": "Active"
    },
    {
        "Root": "10040599",
        "LV": "1",
        "Item Description": "CHEESE PANEER 4/5 LB LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "634638",
        "Last Price Paid": "$25.90",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040599",
        "Product Status": "Active"
    },
    {
        "Root": "10030376",
        "LV": "1",
        "Item Description": "MICKEY SANTA HAT W/OUT EARS RED/WHT 2 5/8X2 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW319702",
        "Last Price Paid": "$0.90",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030376",
        "Product Status": "Active"
    },
    {
        "Root": "10003560",
        "LV": "1",
        "Item Description": "SPICE CLOVES WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003560",
        "Product Status": "Active"
    },
    {
        "Root": "10036374",
        "LV": "2",
        "Item Description": "MASON JAR PLASTIC CLEAR 16Z EPCOT F&G TWMA0130CCA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036374001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001971",
        "Last Price Paid": "$1.01",
        "Case Pack": "196",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10036374",
        "Product Status": "Active"
    },
    {
        "Root": "10094690",
        "LV": "2",
        "Item Description": "SB_BASE REFRESHER PINEAPPLE PASSIONFRUIT 4X - 6-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094690002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11134132",
        "Last Price Paid": "$10.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094690",
        "Product Status": "Active"
    },
    {
        "Root": "10001342",
        "LV": "1",
        "Item Description": "SAUCE SUND TOM PESTO DIP 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$177.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001342",
        "Product Status": "Active"
    },
    {
        "Root": "10035749",
        "LV": "2",
        "Item Description": "GUMPASTE PETITE FLEUR ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035749001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7688",
        "Last Price Paid": "$1.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10035749",
        "Product Status": "Active"
    },
    {
        "Root": "10001824",
        "LV": "1",
        "Item Description": "EGGPLANT BABY PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001824001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001824",
        "Product Status": "Active"
    },
    {
        "Root": "10017947",
        "LV": "1",
        "Item Description": "DRAFT KUNGALOOSH CONCRETE BEACH SPICED BROWN ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "31779",
        "Last Price Paid": "$245.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017947",
        "Product Status": "Active"
    },
    {
        "Root": "10016707",
        "LV": "1",
        "Item Description": "BOARD CAKE ROUND 9\" BRONZE SRT EDGE 080-9RP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51775011",
        "Last Price Paid": "$0.41",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016707",
        "Product Status": "Active"
    },
    {
        "Root": "10037407",
        "LV": "2",
        "Item Description": "CHEESE SLICES DAIRY FREE PROVOLONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037407002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "21027",
        "Last Price Paid": "$3.75",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037407",
        "Product Status": "Active"
    },
    {
        "Root": "10037631",
        "LV": "1",
        "Item Description": "RADICCHIO CASTLEFRANCO S/O 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450196",
        "Last Price Paid": "$6.76",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037631",
        "Product Status": "Active"
    },
    {
        "Root": "10008192",
        "LV": "1",
        "Item Description": "WINE RED BLEND ANTINORI TIGNANELLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008192001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "592264",
        "Last Price Paid": "$131.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008192",
        "Product Status": "Active"
    },
    {
        "Root": "10006953",
        "LV": "1",
        "Item Description": "CHARD VERAMONTE 750ML CHILE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "589606",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006953",
        "Product Status": "Active"
    },
    {
        "Root": "10033770",
        "LV": "2",
        "Item Description": "THE JACKFRUIT CO 5 X 30.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033770002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1000",
        "Last Price Paid": "$8.86",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033770",
        "Product Status": "Active"
    },
    {
        "Root": "10010350",
        "LV": "2",
        "Item Description": "BEER LFT HND BRWG MLK ST 12Z BT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010350001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2914",
        "Last Price Paid": "$1.80",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010350",
        "Product Status": "Active"
    },
    {
        "Root": "10018513",
        "LV": "2",
        "Item Description": "BEEF PATTY 7 OZ AMKOBE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018513001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1000084",
        "Last Price Paid": "",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10018513",
        "Product Status": "Active"
    },
    {
        "Root": "10034592",
        "LV": "1",
        "Item Description": "SUGAR MUSCOVADO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "ZZZ039",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034592",
        "Product Status": "Active"
    },
    {
        "Root": "10036974",
        "LV": "1",
        "Item Description": "CHARD HARTFORD COURT FOUR HEARTS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "537955",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036974",
        "Product Status": "Active"
    },
    {
        "Root": "10027639",
        "LV": "1",
        "Item Description": "SHRIMP RAW HD/ON ARG 10/20 MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027639001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6400018",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027639",
        "Product Status": "Active"
    },
    {
        "Root": "10014754",
        "LV": "1",
        "Item Description": "TURKEY BRST ROASTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014754001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4100010",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014754",
        "Product Status": "Active"
    },
    {
        "Root": "10102632",
        "LV": "1",
        "Item Description": "URCHIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102632",
        "Product Status": "Active"
    },
    {
        "Root": "10034160",
        "LV": "1",
        "Item Description": "LABEL YACHT & BEACH CLUB OVAL GLOSS 4C 2.5X1.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51864687",
        "Last Price Paid": "",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034160",
        "Product Status": "Active"
    },
    {
        "Root": "10005279",
        "LV": "1",
        "Item Description": "JELLO LIME 24Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6370662",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005279",
        "Product Status": "Active"
    },
    {
        "Root": "10034373",
        "LV": "1",
        "Item Description": "RTD MOJITO BLUE MARBLE 200ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "BMP-Mojito-200",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034373",
        "Product Status": "Active"
    },
    {
        "Root": "10039986",
        "LV": "1",
        "Item Description": "LINER 1INCH ROLLED INSULATED DENIM 10X10X10 20/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "005-856",
        "Last Price Paid": "",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039986",
        "Product Status": "Active"
    },
    {
        "Root": "10008853",
        "LV": "2",
        "Item Description": "CAB SAUV FOLEY JOHNS NAPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008853001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "549766",
        "Last Price Paid": "$25.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008853",
        "Product Status": "Active"
    },
    {
        "Root": "10010181",
        "LV": "1",
        "Item Description": "WATER EVIAN GLS 330ML 20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010181001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.61",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010181",
        "Product Status": "Active"
    },
    {
        "Root": "10105438",
        "LV": "1",
        "Item Description": "PROSECCO CAVICCHIOLI 1928 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105438001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "446138",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105438",
        "Product Status": "Active"
    },
    {
        "Root": "10018675",
        "LV": "1",
        "Item Description": "CORVINA,ZENATO,RIPASSO,750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "318489",
        "Last Price Paid": "$22.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018675",
        "Product Status": "Active"
    },
    {
        "Root": "10013782",
        "LV": "1",
        "Item Description": "VEAL TOP RND DENDED CP IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013782001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1305053",
        "Last Price Paid": "$19.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013782",
        "Product Status": "Active"
    },
    {
        "Root": "10088807",
        "LV": "1",
        "Item Description": "BOWL CLEAR 16OZ CLASSIC 5.5IN X 2.6IN DEEP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001103",
        "Last Price Paid": "$0.39",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088807",
        "Product Status": "Active"
    },
    {
        "Root": "10001138",
        "LV": "1",
        "Item Description": "KONBU SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "10030",
        "Last Price Paid": "$6.58",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001138",
        "Product Status": "Active"
    },
    {
        "Root": "10042389",
        "LV": "1",
        "Item Description": "BITTERS HELLA SMOKED CHILI 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "945609",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042389",
        "Product Status": "Active"
    },
    {
        "Root": "10008512",
        "LV": "1",
        "Item Description": "BLND KRACHER BEEREN 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "592327",
        "Last Price Paid": "$28.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008512",
        "Product Status": "Active"
    },
    {
        "Root": "10031965",
        "LV": "2",
        "Item Description": "DRIED PLANTAIN CHIP 36-2.25OZ/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031965002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5287668",
        "Last Price Paid": "$1.01",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031965",
        "Product Status": "Active"
    },
    {
        "Root": "10018794",
        "LV": "2",
        "Item Description": "DÉCOR NON EDIBLE DISCO SPARKLES GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018794002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11175",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018794",
        "Product Status": "Active"
    },
    {
        "Root": "10022775",
        "LV": "1",
        "Item Description": "SPICE ZA'TAR 4 OZ TEMP F&W SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI908",
        "Last Price Paid": "$17.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022775",
        "Product Status": "Active"
    },
    {
        "Root": "10107503",
        "LV": "1",
        "Item Description": "HARD CIDER BLAKES PEACH PARTY 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14951",
        "Last Price Paid": "$199.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107503",
        "Product Status": "Active"
    },
    {
        "Root": "10001707",
        "LV": "1",
        "Item Description": "MUSHROOM CRIMINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001707",
        "Product Status": "Active"
    },
    {
        "Root": "10003029",
        "LV": "2",
        "Item Description": "SUGAR RAW 1200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003029002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.01",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003029",
        "Product Status": "Active"
    },
    {
        "Root": "10001028",
        "LV": "1",
        "Item Description": "COD FILET UK CUT 3.5 OZ SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001028001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6496415",
        "Last Price Paid": "$10.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001028",
        "Product Status": "Active"
    },
    {
        "Root": "10001028",
        "LV": "1",
        "Item Description": "COD FILET UK CUT 3.5 OZ SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001028001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01095",
        "Last Price Paid": "$10.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001028",
        "Product Status": "Active"
    },
    {
        "Root": "10001672",
        "LV": "1",
        "Item Description": "POTATO BAKER 80 CT/50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001672",
        "Product Status": "Active"
    },
    {
        "Root": "10003415",
        "LV": "1",
        "Item Description": "SYRUP FLAVOR RAGE CHERRY 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003415001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003415",
        "Product Status": "Active"
    },
    {
        "Root": "10041277",
        "LV": "1",
        "Item Description": "SPOON  TEA PLASTIC BLACK ULTRA SMART STOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "960",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041277",
        "Product Status": "Active"
    },
    {
        "Root": "10025683",
        "LV": "1",
        "Item Description": "ROSE BIBI GRAETZ ROSATO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9151835",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025683",
        "Product Status": "Active"
    },
    {
        "Root": "10025099",
        "LV": "1",
        "Item Description": "TROUT STELLHEAD FILLET SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025099001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-50077",
        "Last Price Paid": "$10.95",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025099",
        "Product Status": "Active"
    },
    {
        "Root": "10005778",
        "LV": "1",
        "Item Description": "CAKE FIG ALMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005778001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "23007",
        "Last Price Paid": "$6.45",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005778",
        "Product Status": "Active"
    },
    {
        "Root": "10005676",
        "LV": "1",
        "Item Description": "BREAD POTATO CHIVE LOAF 1.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "1.5 LB",
        "Last Price Paid": "$3.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005676",
        "Product Status": "Active"
    },
    {
        "Root": "10001226",
        "LV": "1",
        "Item Description": "SNAPPER MUTTON FILET S/O 6OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001226001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "1486502",
        "Last Price Paid": "$19.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001226",
        "Product Status": "Active"
    },
    {
        "Root": "10001226",
        "LV": "1",
        "Item Description": "SNAPPER MUTTON FILET S/O 6OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001226001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001226",
        "Product Status": "Active"
    },
    {
        "Root": "10001226",
        "LV": "1",
        "Item Description": "SNAPPER MUTTON FILET S/O 6OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001226001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00842",
        "Last Price Paid": "$19.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001226",
        "Product Status": "Active"
    },
    {
        "Root": "10019055",
        "LV": "1",
        "Item Description": "PINOT NOIR GRAN MORAINE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587286",
        "Last Price Paid": "$40.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019055",
        "Product Status": "Active"
    },
    {
        "Root": "10087588",
        "LV": "1",
        "Item Description": "COOKIE MINI DBL CHOC CHIP HOMEFREE ALLERGEN FRIEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "LGFMDC30",
        "Last Price Paid": "$1.01",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087588",
        "Product Status": "Active"
    },
    {
        "Root": "10040254",
        "LV": "1",
        "Item Description": "GRECO DI TUFO MATILDE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "430029",
        "Last Price Paid": "$13.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040254",
        "Product Status": "Active"
    },
    {
        "Root": "10006651",
        "LV": "1",
        "Item Description": "FILM PVC CLING 10 INCH X10 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7113566",
        "Last Price Paid": "$14.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006651",
        "Product Status": "Active"
    },
    {
        "Root": "10085636",
        "LV": "1",
        "Item Description": "DISPERSION COSTUME BLUE 50TH ANNIVERSARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "045531501",
        "Last Price Paid": "$119.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085636",
        "Product Status": "Active"
    },
    {
        "Root": "10002002",
        "LV": "1",
        "Item Description": "CARROT/CELERY JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002002001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511535",
        "Last Price Paid": "$14.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002002",
        "Product Status": "Active"
    },
    {
        "Root": "10031943",
        "LV": "2",
        "Item Description": "SUGAR-BUNNY TAILS AND TOES ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031943002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48588",
        "Last Price Paid": "$0.15",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031943",
        "Product Status": "Active"
    },
    {
        "Root": "10021375",
        "LV": "1",
        "Item Description": "SEA BASS CHILEAN PORTION 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021375001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01510",
        "Last Price Paid": "$44.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021375",
        "Product Status": "Active"
    },
    {
        "Root": "10021375",
        "LV": "1",
        "Item Description": "SEA BASS CHILEAN PORTION 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021375001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "63-01510",
        "Last Price Paid": "$44.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021375",
        "Product Status": "Active"
    },
    {
        "Root": "10010210",
        "LV": "4",
        "Item Description": "BEER KEG FUNKY BUDDHA FLORIDIAN 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010210003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32736",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10010210",
        "Product Status": "Active"
    },
    {
        "Root": "10036325",
        "LV": "1",
        "Item Description": "CHERRY CORN TREAT MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2307",
        "Last Price Paid": "$22.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036325",
        "Product Status": "Active"
    },
    {
        "Root": "10010289",
        "LV": "2",
        "Item Description": "BEER SIERRA NVD PL ALE CN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010289001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "17845",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010289",
        "Product Status": "Active"
    },
    {
        "Root": "10004748",
        "LV": "1",
        "Item Description": "PEA BLACK EYED DRY BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1327592",
        "Last Price Paid": "$40.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004748",
        "Product Status": "Active"
    },
    {
        "Root": "10004333",
        "LV": "1",
        "Item Description": "POWDER ESPELLETTE PEPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "HB051",
        "Last Price Paid": "$24.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004333",
        "Product Status": "Active"
    },
    {
        "Root": "10037605",
        "LV": "1",
        "Item Description": "SUGAR FLOSS JOLLY BERRY BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2792",
        "Last Price Paid": "$30.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037605",
        "Product Status": "Active"
    },
    {
        "Root": "10036391",
        "LV": "2",
        "Item Description": "STRAW PAPER  RED STRIPE 7.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036391002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "485UPJ7RDSTR",
        "Last Price Paid": "$0.01",
        "Case Pack": "4800",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036391",
        "Product Status": "Active"
    },
    {
        "Root": "10011940",
        "LV": "2",
        "Item Description": "BEER CAN SIERRA NEVADA PALE ALE 16OZ 18CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011940001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "35580",
        "Last Price Paid": "$1.61",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10011940",
        "Product Status": "Active"
    },
    {
        "Root": "10001577",
        "LV": "1",
        "Item Description": "SQUASH ZUCHINNI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "597027",
        "Last Price Paid": "$10.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001577",
        "Product Status": "Active"
    },
    {
        "Root": "10034763",
        "LV": "1",
        "Item Description": "RICE JUPITER WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034763001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-65515",
        "Last Price Paid": "$6.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034763",
        "Product Status": "Active"
    },
    {
        "Root": "10001717",
        "LV": "1",
        "Item Description": "LOTUS ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "28042",
        "Last Price Paid": "$80.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001717",
        "Product Status": "Active"
    },
    {
        "Root": "10001717",
        "LV": "1",
        "Item Description": "LOTUS ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460501",
        "Last Price Paid": "$80.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001717",
        "Product Status": "Active"
    },
    {
        "Root": "10012142",
        "LV": "1",
        "Item Description": "MONKFISH SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012142001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00725",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012142",
        "Product Status": "Active"
    },
    {
        "Root": "10012142",
        "LV": "1",
        "Item Description": "MONKFISH SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012142001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "MIN130100",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012142",
        "Product Status": "Active"
    },
    {
        "Root": "10012142",
        "LV": "1",
        "Item Description": "MONKFISH SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012142001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3271048",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012142",
        "Product Status": "Active"
    },
    {
        "Root": "10012142",
        "LV": "1",
        "Item Description": "MONKFISH SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012142001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "11019400",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012142",
        "Product Status": "Active"
    },
    {
        "Root": "10107890",
        "LV": "1",
        "Item Description": "BREAKFAST HURRICANE BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073577",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107890",
        "Product Status": "Active"
    },
    {
        "Root": "10003357",
        "LV": "2",
        "Item Description": "CORE POWER VANILLA 11.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003357002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "156182",
        "Last Price Paid": "$2.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003357",
        "Product Status": "Active"
    },
    {
        "Root": "10007793",
        "LV": "2",
        "Item Description": "MERLOT EMMOLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007793001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597479",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007793",
        "Product Status": "Active"
    },
    {
        "Root": "10016307",
        "LV": "1",
        "Item Description": "PEPPERS MINI SWT MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490035",
        "Last Price Paid": "$3.05",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016307",
        "Product Status": "Active"
    },
    {
        "Root": "10004716",
        "LV": "1",
        "Item Description": "CHIP POTATO REG LAYS 1 Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9315128",
        "Last Price Paid": "$0.35",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004716",
        "Product Status": "Active"
    },
    {
        "Root": "10033722",
        "LV": "1",
        "Item Description": "BURRITO BOWL LID BOL CS UBB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11400034",
        "Last Price Paid": "$0.23",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033722",
        "Product Status": "Active"
    },
    {
        "Root": "10029855",
        "LV": "2",
        "Item Description": "CORN HUSK 12 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029855002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404016",
        "Last Price Paid": "$8.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029855",
        "Product Status": "Active"
    },
    {
        "Root": "10103815",
        "LV": "1",
        "Item Description": "CUP 12OZ CLEAR CAN FOTA FIGMENT PRINTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51093344",
        "Last Price Paid": "$0.97",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103815",
        "Product Status": "Active"
    },
    {
        "Root": "10033879",
        "LV": "1",
        "Item Description": "ENGLISH MUFFIN WHOLE WHEAT BAKED 6/12/2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6686984",
        "Last Price Paid": "$0.39",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033879",
        "Product Status": "Active"
    },
    {
        "Root": "10005258",
        "LV": "1",
        "Item Description": "JACKFRUIT 15Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "46010",
        "Last Price Paid": "$7.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005258",
        "Product Status": "Active"
    },
    {
        "Root": "10003937",
        "LV": "2",
        "Item Description": "WATER CORTAS ROSE WATER 10OZ/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003937001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-10130",
        "Last Price Paid": "$5.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003937",
        "Product Status": "Active"
    },
    {
        "Root": "10012972",
        "LV": "1",
        "Item Description": "CHIVE BLOSSOMS\\",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05CB-24",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012972",
        "Product Status": "Active"
    },
    {
        "Root": "10009980",
        "LV": "1",
        "Item Description": "LIQ PIMMS CUP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009980001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32752",
        "Last Price Paid": "$19.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009980",
        "Product Status": "Active"
    },
    {
        "Root": "10004398",
        "LV": "1",
        "Item Description": "FLOUR RICE 6% LONG GRAIL 50# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "153753",
        "Last Price Paid": "$40.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004398",
        "Product Status": "Active"
    },
    {
        "Root": "10045002",
        "LV": "1",
        "Item Description": "SYRUP VANILLA SHAKE FOUNTAIN RTU 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045002001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6016596",
        "Last Price Paid": "$11.44",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045002",
        "Product Status": "Active"
    },
    {
        "Root": "10004955",
        "LV": "1",
        "Item Description": "FLAVORING VANILLA MAURITI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "101...{SIEBEN{",
        "Last Price Paid": "$16.20",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004955",
        "Product Status": "Active"
    },
    {
        "Root": "10110130",
        "LV": "1",
        "Item Description": "COFFEE JOFFREYS RTD SHAKIN JAMAICAN 11OZ/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110130",
        "Product Status": "Active"
    },
    {
        "Root": "10023544",
        "LV": "1",
        "Item Description": "SORREL AMETHYST 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023544001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14AS-33",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023544",
        "Product Status": "Active"
    },
    {
        "Root": "10110488",
        "LV": "1",
        "Item Description": "WRAPPED BEEF HOT DOG 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "42",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110488",
        "Product Status": "Active"
    },
    {
        "Root": "10036539",
        "LV": "1",
        "Item Description": "VIOGNIER FESS PARKER RODNEYS VINEYARD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9318304",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036539",
        "Product Status": "Active"
    },
    {
        "Root": "10022838",
        "LV": "1",
        "Item Description": "CHOC WHT 3 X 1 RECT LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2170W",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022838",
        "Product Status": "Active"
    },
    {
        "Root": "10106450",
        "LV": "1",
        "Item Description": "BEEF CORNED ROUND SLICED 20 CT / 1.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106450001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106450",
        "Product Status": "Active"
    },
    {
        "Root": "10035173",
        "LV": "1",
        "Item Description": "HERRING, NORWEGIAN 5-8 20KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035173001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035173",
        "Product Status": "Active"
    },
    {
        "Root": "10027254",
        "LV": "1",
        "Item Description": "LIQUOR BAILEYS IRISH ESPRESSO CREME 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "394397",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027254",
        "Product Status": "Active"
    },
    {
        "Root": "10101148",
        "LV": "1",
        "Item Description": "RUM APPLETON EST 8YR RESERVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "964130",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101148",
        "Product Status": "Active"
    },
    {
        "Root": "10008692",
        "LV": "1",
        "Item Description": "CAB STAGS LEAP CASK 23 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008692001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600022",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008692",
        "Product Status": "Active"
    },
    {
        "Root": "10040698",
        "LV": "1",
        "Item Description": "CHEESE MOUNT VESUVIUS BLACK ASH MIYOKOS VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "177448",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040698",
        "Product Status": "Active"
    },
    {
        "Root": "10015520",
        "LV": "1",
        "Item Description": "POWDER GREEN TEA MATCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015520",
        "Product Status": "Active"
    },
    {
        "Root": "10011122",
        "LV": "1",
        "Item Description": "CORN ZELLWOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.98",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011122",
        "Product Status": "Active"
    },
    {
        "Root": "10011122",
        "LV": "1",
        "Item Description": "CORN ZELLWOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401108",
        "Last Price Paid": "$0.98",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011122",
        "Product Status": "Active"
    },
    {
        "Root": "10091335",
        "LV": "1",
        "Item Description": "WINE RED BLEND SAVAGE SOUTH AFRICA 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "772024",
        "Last Price Paid": "$36.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091335",
        "Product Status": "Active"
    },
    {
        "Root": "10042844",
        "LV": "1",
        "Item Description": "WINE WHITE SAUVIGNON BLANC MCBRIDE SISTERS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042844001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "580044",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042844",
        "Product Status": "Active"
    },
    {
        "Root": "10002608",
        "LV": "1",
        "Item Description": "RHUBARB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002608001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002608",
        "Product Status": "Active"
    },
    {
        "Root": "10004761",
        "LV": "1",
        "Item Description": "YEAST DRY INSTANT 20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7372048",
        "Last Price Paid": "$4.37",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004761",
        "Product Status": "Active"
    },
    {
        "Root": "10042388",
        "LV": "1",
        "Item Description": "BITTERS HELLA GINGER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "945604",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042388",
        "Product Status": "Active"
    },
    {
        "Root": "10105092",
        "LV": "1",
        "Item Description": "COCOA MIX INDIVIDUAL DUTCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105092001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.27",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105092",
        "Product Status": "Active"
    },
    {
        "Root": "10027987",
        "LV": "1",
        "Item Description": "LIQUOR ARVERO LIMONCELLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027987001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "356774",
        "Last Price Paid": "$17.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027987",
        "Product Status": "Active"
    },
    {
        "Root": "10016088",
        "LV": "1",
        "Item Description": "CHICK WHOLE WOG 2.25-2.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016088001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.57",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016088",
        "Product Status": "Active"
    },
    {
        "Root": "10081331",
        "LV": "1",
        "Item Description": "MIX MOUSSE NEUTRAL / VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P4107486",
        "Last Price Paid": "$179.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081331",
        "Product Status": "Active"
    },
    {
        "Root": "10044416",
        "LV": "1",
        "Item Description": "SPICE ARROWROOT POWDER 18 OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760561",
        "Last Price Paid": "$11.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044416",
        "Product Status": "Active"
    },
    {
        "Root": "10003707",
        "LV": "1",
        "Item Description": "MARMALADE RASPBERRY HERO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "...{ANNABELLS{",
        "Last Price Paid": "$144.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003707",
        "Product Status": "Active"
    },
    {
        "Root": "10015521",
        "LV": "1",
        "Item Description": "HERB SORRELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015521001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015521",
        "Product Status": "Active"
    },
    {
        "Root": "10015521",
        "LV": "1",
        "Item Description": "HERB SORRELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015521001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450077",
        "Last Price Paid": "$19.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015521",
        "Product Status": "Active"
    },
    {
        "Root": "10103322",
        "LV": "1",
        "Item Description": "BUCKET POP PREM SKYLINER GOTG 64OZ 18/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.44",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103322",
        "Product Status": "Active"
    },
    {
        "Root": "10004923",
        "LV": "1",
        "Item Description": "CHOC SHAVING DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "...{MONA LISA",
        "Last Price Paid": "$104.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004923",
        "Product Status": "Active"
    },
    {
        "Root": "10088030",
        "LV": "1",
        "Item Description": "PIZZA CRUST GLUTEN FREE BOBS 25 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088030001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101722",
        "Last Price Paid": "$2.34",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088030",
        "Product Status": "Active"
    },
    {
        "Root": "10007003",
        "LV": "1",
        "Item Description": "SPARKLING CAVA P CHENEAU LADY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007003001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007003",
        "Product Status": "Active"
    },
    {
        "Root": "10032618",
        "LV": "2",
        "Item Description": "MUSHROOM TRUFFLE BLACK FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032618002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032618",
        "Product Status": "Active"
    },
    {
        "Root": "10032618",
        "LV": "2",
        "Item Description": "MUSHROOM TRUFFLE BLACK FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032618002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-55105",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032618",
        "Product Status": "Active"
    },
    {
        "Root": "10008077",
        "LV": "1",
        "Item Description": "CHAMP PERRIER LAURENT BRT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "571168",
        "Last Price Paid": "$34.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008077",
        "Product Status": "Active"
    },
    {
        "Root": "10001190",
        "LV": "1",
        "Item Description": "SEAWEED HIYASHI WAKAME SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001190001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001190",
        "Product Status": "Active"
    },
    {
        "Root": "10001718",
        "LV": "1",
        "Item Description": "ORANGE BLOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$68.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001718",
        "Product Status": "Active"
    },
    {
        "Root": "10001718",
        "LV": "1",
        "Item Description": "ORANGE BLOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "212081",
        "Last Price Paid": "$68.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001718",
        "Product Status": "Active"
    },
    {
        "Root": "10001718",
        "LV": "1",
        "Item Description": "ORANGE BLOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$68.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001718",
        "Product Status": "Active"
    },
    {
        "Root": "10107870",
        "LV": "1",
        "Item Description": "HAM & CHEESE SLIDERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073430",
        "Last Price Paid": "$4.00",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107870",
        "Product Status": "Active"
    },
    {
        "Root": "10002415",
        "LV": "1",
        "Item Description": "CHEESE ST.ANDRE 4.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002415001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1410",
        "Last Price Paid": "$13.00",
        "Case Pack": "4.5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002415",
        "Product Status": "Active"
    },
    {
        "Root": "10004575",
        "LV": "1",
        "Item Description": "RICE WILD LONG GRAIN 36Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5637962",
        "Last Price Paid": "$8.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004575",
        "Product Status": "Active"
    },
    {
        "Root": "10012701",
        "LV": "1",
        "Item Description": "FLOWERS MARIGOLD 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05FB-24",
        "Last Price Paid": "$12.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012701",
        "Product Status": "Active"
    },
    {
        "Root": "10012701",
        "LV": "1",
        "Item Description": "FLOWERS MARIGOLD 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "467539",
        "Last Price Paid": "$12.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012701",
        "Product Status": "Active"
    },
    {
        "Root": "10032786",
        "LV": "1",
        "Item Description": "SAFFRON 1 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$43.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032786",
        "Product Status": "Active"
    },
    {
        "Root": "10032786",
        "LV": "1",
        "Item Description": "SAFFRON 1 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "51870",
        "Last Price Paid": "$43.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032786",
        "Product Status": "Active"
    },
    {
        "Root": "10004782",
        "LV": "1",
        "Item Description": "OIL LEMON MEYER 8.8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004782",
        "Product Status": "Active"
    },
    {
        "Root": "10037557",
        "LV": "1",
        "Item Description": "BEET BADGER FLAME BABY 10 CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01BBFBT-26",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037557",
        "Product Status": "Active"
    },
    {
        "Root": "10030808",
        "LV": "1",
        "Item Description": "EGGNOG COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6900070000",
        "Last Price Paid": "$57.80",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030808",
        "Product Status": "Active"
    },
    {
        "Root": "10003850",
        "LV": "1",
        "Item Description": "CHIP RICE BBQ LUNDBERG 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "35335",
        "Last Price Paid": "$3.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003850",
        "Product Status": "Active"
    },
    {
        "Root": "10002424",
        "LV": "2",
        "Item Description": "ICE CREAM VANILLA BEAN EDY 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002424002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002424",
        "Product Status": "Active"
    },
    {
        "Root": "10013101",
        "LV": "1",
        "Item Description": "CORN SMUT FROZEN 2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013101001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VB082",
        "Last Price Paid": "$89.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013101",
        "Product Status": "Active"
    },
    {
        "Root": "10005635",
        "LV": "1",
        "Item Description": "BISCUIT TRANS SENSE 1.4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005635",
        "Product Status": "Active"
    },
    {
        "Root": "10000292",
        "LV": "1",
        "Item Description": "POTATO SWT DICED DRY 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000292001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482511",
        "Last Price Paid": "$14.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000292",
        "Product Status": "Active"
    },
    {
        "Root": "10012350",
        "LV": "1",
        "Item Description": "PECTINEX ULTRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87397",
        "Last Price Paid": "$27.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012350",
        "Product Status": "Active"
    },
    {
        "Root": "10000781",
        "LV": "1",
        "Item Description": "BEEF PIPE BONES WITH MARROW 4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000781001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1100030",
        "Last Price Paid": "$2.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000781",
        "Product Status": "Active"
    },
    {
        "Root": "10007153",
        "LV": "1",
        "Item Description": "CAB MERLOT JUSTIN JUSTIFICATION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "535200",
        "Last Price Paid": "$51.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007153",
        "Product Status": "Active"
    },
    {
        "Root": "10090084",
        "LV": "1",
        "Item Description": "MEATBALL PORK BEEF BLEND ITALIAN COOKED 160/1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6795306",
        "Last Price Paid": "$0.23",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090084",
        "Product Status": "Active"
    },
    {
        "Root": "10003482",
        "LV": "2",
        "Item Description": "SAUCE CHAR SIU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003482002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7920143",
        "Last Price Paid": "$8.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003482",
        "Product Status": "Active"
    },
    {
        "Root": "10090500",
        "LV": "1",
        "Item Description": "BEER 81BAY VANILLA PORTER 15.5G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14623",
        "Last Price Paid": "$258.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090500",
        "Product Status": "Active"
    },
    {
        "Root": "10016985",
        "LV": "1",
        "Item Description": "SAUCE CARAMEL MONIN 4/64 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016985",
        "Product Status": "Active"
    },
    {
        "Root": "10000788",
        "LV": "1",
        "Item Description": "CASING HOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1423510",
        "Last Price Paid": "$22.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000788",
        "Product Status": "Active"
    },
    {
        "Root": "10012915",
        "LV": "1",
        "Item Description": "COOKIE FORTUNE SS IW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2014959",
        "Last Price Paid": "$0.19",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012915",
        "Product Status": "Active"
    },
    {
        "Root": "10029927",
        "LV": "1",
        "Item Description": "GEL PASTE ROYAL BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC ROYAL BLUE",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029927",
        "Product Status": "Active"
    },
    {
        "Root": "10087504",
        "LV": "3",
        "Item Description": "SPREAD FIG ORIGINAL PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087504002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "26100",
        "Last Price Paid": "$14.07",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10087504",
        "Product Status": "Active"
    },
    {
        "Root": "10018864",
        "LV": "1",
        "Item Description": "DECOR FLASHY SPARKLES DARK PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "315216B",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018864",
        "Product Status": "Active"
    },
    {
        "Root": "10033800",
        "LV": "1",
        "Item Description": "HIBISCUS PINK SINGLE HBS PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033800001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "HBS PK",
        "Last Price Paid": "$5.09",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033800",
        "Product Status": "Active"
    },
    {
        "Root": "10037151",
        "LV": "1",
        "Item Description": "CHOC BEACH SHELLS WHITE 1.25-2.75IN METALLIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW15096",
        "Last Price Paid": "$0.70",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037151",
        "Product Status": "Active"
    },
    {
        "Root": "10001533",
        "LV": "1",
        "Item Description": "ROOT YUCCA CASSAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482003",
        "Last Price Paid": "$23.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001533",
        "Product Status": "Active"
    },
    {
        "Root": "10007221",
        "LV": "2",
        "Item Description": "CHARD HARTFORD COURT RUS RIV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007221001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570895",
        "Last Price Paid": "$17.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007221",
        "Product Status": "Active"
    },
    {
        "Root": "10023959",
        "LV": "1",
        "Item Description": "COD FILET 6 OZ IQF SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6360510",
        "Last Price Paid": "$2.45",
        "Case Pack": "26",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023959",
        "Product Status": "Active"
    },
    {
        "Root": "10010430",
        "LV": "1",
        "Item Description": "BEER CAN ICELANDIC WHEAT ALE 11.2OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14990",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010430",
        "Product Status": "Active"
    },
    {
        "Root": "10000168",
        "LV": "2",
        "Item Description": "ORANGE 56 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000168002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000168",
        "Product Status": "Active"
    },
    {
        "Root": "10037367",
        "LV": "1",
        "Item Description": "MINNIE E+T ROUND LABEL 2.5\" FULL BLEED 2000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45003323",
        "Last Price Paid": "",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037367",
        "Product Status": "Active"
    },
    {
        "Root": "10083979",
        "LV": "1",
        "Item Description": "KIT CUTLERY 7 PIECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510370",
        "Last Price Paid": "",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083979",
        "Product Status": "Active"
    },
    {
        "Root": "10025561",
        "LV": "1",
        "Item Description": "CONTAINER DELI COMPOSTABLE 16 OZ CLEAR 6X5X3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51400006",
        "Last Price Paid": "",
        "Case Pack": "900",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025561",
        "Product Status": "Active"
    },
    {
        "Root": "10013921",
        "LV": "1",
        "Item Description": "DEC AZALEA WHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013921",
        "Product Status": "Active"
    },
    {
        "Root": "10034610",
        "LV": "1",
        "Item Description": "RASPBERRY CURRANT MARMALADE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "505083",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034610",
        "Product Status": "Active"
    },
    {
        "Root": "10110988",
        "LV": "1",
        "Item Description": "PASTE ORANGE COMPOUND 3.3 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AMI715",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110988",
        "Product Status": "Active"
    },
    {
        "Root": "10040039",
        "LV": "1",
        "Item Description": "JUICE GRAPEFRUIT NATALIES 32OZ 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040039001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480985",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040039",
        "Product Status": "Active"
    },
    {
        "Root": "10005815",
        "LV": "1",
        "Item Description": "PASTRY DANISH APPLE SWIRL MINI 3IN 12CT BAKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005815",
        "Product Status": "Active"
    },
    {
        "Root": "10095789",
        "LV": "1",
        "Item Description": "BBQBEEF BOWL LIFE KOREAN STYLE 8X10OZ US",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095789",
        "Product Status": "Active"
    },
    {
        "Root": "10044033",
        "LV": "1",
        "Item Description": "WINE WHITE CHARD MARTIN RAY SONOMA KEG 1/19.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "119294",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044033",
        "Product Status": "Active"
    },
    {
        "Root": "10016466",
        "LV": "1",
        "Item Description": "CAB SAUV M ETAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016466001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9185871",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016466",
        "Product Status": "Active"
    },
    {
        "Root": "10001751",
        "LV": "1",
        "Item Description": "TANGERINE MINNEOLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "225101",
        "Last Price Paid": "$29.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001751",
        "Product Status": "Active"
    },
    {
        "Root": "10017268",
        "LV": "1",
        "Item Description": "BEER WRZBRGR HOFRAU PILSNR 11.2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "868014",
        "Last Price Paid": "$1.58",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017268",
        "Product Status": "Active"
    },
    {
        "Root": "10004034",
        "LV": "1",
        "Item Description": "RAISIN SEEDLESS IND 1.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004034001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8693418",
        "Last Price Paid": "$0.42",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004034",
        "Product Status": "Active"
    },
    {
        "Root": "10000390",
        "LV": "1",
        "Item Description": "BASIL MIX MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MBS-33-L",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000390",
        "Product Status": "Active"
    },
    {
        "Root": "10000390",
        "LV": "1",
        "Item Description": "BASIL MIX MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481302",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000390",
        "Product Status": "Active"
    },
    {
        "Root": "10003587",
        "LV": "1",
        "Item Description": "WRAPPER GYOZA 2-3 INCH ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "528",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003587",
        "Product Status": "Active"
    },
    {
        "Root": "10007561",
        "LV": "2",
        "Item Description": "PROSECCO RUFFINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007561001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "305805",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007561",
        "Product Status": "Active"
    },
    {
        "Root": "10000693",
        "LV": "1",
        "Item Description": "LAMB BELLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000693001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240512",
        "Last Price Paid": "$9.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000693",
        "Product Status": "Active"
    },
    {
        "Root": "10005873",
        "LV": "1",
        "Item Description": "CANDY CHOCOLATE PLAIN MINI M&M 25 POUND CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1240902",
        "Last Price Paid": "$103.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005873",
        "Product Status": "Active"
    },
    {
        "Root": "10005873",
        "LV": "1",
        "Item Description": "CANDY CHOCOLATE PLAIN MINI M&M 25 POUND CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "529970",
        "Last Price Paid": "$103.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005873",
        "Product Status": "Active"
    },
    {
        "Root": "10031918",
        "LV": "2",
        "Item Description": "SB NITRO COLD BREW LID 12OZ 510-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031918002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11071343",
        "Last Price Paid": "$0.06",
        "Case Pack": "510",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031918",
        "Product Status": "Active"
    },
    {
        "Root": "10001595",
        "LV": "1",
        "Item Description": "ONION SPRING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001595",
        "Product Status": "Active"
    },
    {
        "Root": "10001595",
        "LV": "1",
        "Item Description": "ONION SPRING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406046",
        "Last Price Paid": "$27.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001595",
        "Product Status": "Active"
    },
    {
        "Root": "10107732",
        "LV": "1",
        "Item Description": "CANDY 3 MUSKETEERS MINI 8/8.41 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530162",
        "Last Price Paid": "$0.15",
        "Case Pack": "315",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107732",
        "Product Status": "Active"
    },
    {
        "Root": "10038082",
        "LV": "2",
        "Item Description": "BEEF HOT DOG 4-1 2/5LB 6INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038082002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1330083",
        "Last Price Paid": "$0.84",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038082",
        "Product Status": "Active"
    },
    {
        "Root": "10007641",
        "LV": "1",
        "Item Description": "VERDICHIO GAROFONI MARCRINA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "119390",
        "Last Price Paid": "$12.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007641",
        "Product Status": "Active"
    },
    {
        "Root": "10107797",
        "LV": "1",
        "Item Description": "CRAB LEGS NORWEGIAN KING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107797001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "33589032",
        "Last Price Paid": "$72.00",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107797",
        "Product Status": "Active"
    },
    {
        "Root": "10000136",
        "LV": "1",
        "Item Description": "COD 5.5OZ UK CUT SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000136001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6496410",
        "Last Price Paid": "$10.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000136",
        "Product Status": "Active"
    },
    {
        "Root": "10006608",
        "LV": "1",
        "Item Description": "TRAY DRINK CARRIER 4 CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1499680",
        "Last Price Paid": "$0.18",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006608",
        "Product Status": "Active"
    },
    {
        "Root": "10001748",
        "LV": "1",
        "Item Description": "BEET RED BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001748",
        "Product Status": "Active"
    },
    {
        "Root": "10001748",
        "LV": "1",
        "Item Description": "BEET RED BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480023",
        "Last Price Paid": "$48.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001748",
        "Product Status": "Active"
    },
    {
        "Root": "10010842",
        "LV": "2",
        "Item Description": "BEER TAMPA BRW RF DNKY 16Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010842001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "83563",
        "Last Price Paid": "$2.04",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010842",
        "Product Status": "Active"
    },
    {
        "Root": "10014282",
        "LV": "1",
        "Item Description": "WATER ACQUAFINA PANNA PET 16.9 STL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9525965",
        "Last Price Paid": "$1.12",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014282",
        "Product Status": "Active"
    },
    {
        "Root": "10031005",
        "LV": "1",
        "Item Description": "MUG EMI CLEAR SQUARE SM4C 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11313067",
        "Last Price Paid": "$0.83",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031005",
        "Product Status": "Active"
    },
    {
        "Root": "10036211",
        "LV": "1",
        "Item Description": "COCOA BUTTER CORAL ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302020C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036211",
        "Product Status": "Active"
    },
    {
        "Root": "10006943",
        "LV": "1",
        "Item Description": "SPARK IRON HORSE FAIRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9680966",
        "Last Price Paid": "$50.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006943",
        "Product Status": "Active"
    },
    {
        "Root": "10025235",
        "LV": "1",
        "Item Description": "BOWL SQUARE BLACK 32 OZ 300/CASE 5DD032BK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.19",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025235",
        "Product Status": "Active"
    },
    {
        "Root": "10002119",
        "LV": "1",
        "Item Description": "STRAWBERRY DICED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002119001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5776919",
        "Last Price Paid": "$13.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002119",
        "Product Status": "Active"
    },
    {
        "Root": "10015262",
        "LV": "1",
        "Item Description": "LOMO IBERICO DE BELLOTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015262001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7026360",
        "Last Price Paid": "$60.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015262",
        "Product Status": "Active"
    },
    {
        "Root": "10010364",
        "LV": "1",
        "Item Description": "BEER KROENBOURG BLANC 11.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "697257",
        "Last Price Paid": "$1.30",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010364",
        "Product Status": "Active"
    },
    {
        "Root": "10010364",
        "LV": "1",
        "Item Description": "BEER KROENBOURG BLANC 11.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "697257",
        "Last Price Paid": "$1.30",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010364",
        "Product Status": "Active"
    },
    {
        "Root": "10007757",
        "LV": "2",
        "Item Description": "SAKE SHO CHIKU BAI NIGORI 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007757001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "413",
        "Last Price Paid": "$4.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007757",
        "Product Status": "Active"
    },
    {
        "Root": "10031015",
        "LV": "1",
        "Item Description": "PASTA DRY ORECCHIETTE 2/10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808007944",
        "Last Price Paid": "$9.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031015",
        "Product Status": "Active"
    },
    {
        "Root": "10005342",
        "LV": "1",
        "Item Description": "DEC FLOWER ROSE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7090",
        "Last Price Paid": "$5.29",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005342",
        "Product Status": "Active"
    },
    {
        "Root": "10003667",
        "LV": "1",
        "Item Description": "VINEGAR BALSAMIC 2/10LT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4915716",
        "Last Price Paid": "$46.81",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003667",
        "Product Status": "Active"
    },
    {
        "Root": "10016041",
        "LV": "1",
        "Item Description": "BREAD SQUARE FOCACCIA HERB 4X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "10:D",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016041",
        "Product Status": "Active"
    },
    {
        "Root": "10027924",
        "LV": "1",
        "Item Description": "BOURBON BASIL HAYDEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "524892",
        "Last Price Paid": "$36.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027924",
        "Product Status": "Active"
    },
    {
        "Root": "10014106",
        "LV": "1",
        "Item Description": "POTATO MARBLE 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408826",
        "Last Price Paid": "$13.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014106",
        "Product Status": "Active"
    },
    {
        "Root": "10033671",
        "LV": "1",
        "Item Description": "EDIBLE GLITTER-WHITE-4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "38134",
        "Last Price Paid": "$12.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033671",
        "Product Status": "Active"
    },
    {
        "Root": "10086112",
        "LV": "1",
        "Item Description": "DEC CHOC WHITE FILM STRIP 4 IMAGES 5X2.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW23117",
        "Last Price Paid": "$1.25",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086112",
        "Product Status": "Active"
    },
    {
        "Root": "10040434",
        "LV": "1",
        "Item Description": "CLEAR COLD CUP PLA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.19",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040434",
        "Product Status": "Active"
    },
    {
        "Root": "10002140",
        "LV": "1",
        "Item Description": "SOUR CREAM TETRA-PAK 1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002140001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8480154",
        "Last Price Paid": "$0.13",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002140",
        "Product Status": "Active"
    },
    {
        "Root": "10000367",
        "LV": "1",
        "Item Description": "ONION RED DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511154",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000367",
        "Product Status": "Active"
    },
    {
        "Root": "10000367",
        "LV": "1",
        "Item Description": "ONION RED DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-002-14",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000367",
        "Product Status": "Active"
    },
    {
        "Root": "10029901",
        "LV": "1",
        "Item Description": "MUSHROOM LOGS SHIITAKE S/O DISPLAY ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405200",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029901",
        "Product Status": "Active"
    },
    {
        "Root": "10000058",
        "LV": "1",
        "Item Description": "PORK TENDERLOIN BERKSHIRE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000058001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000058",
        "Product Status": "Active"
    },
    {
        "Root": "10000219",
        "LV": "1",
        "Item Description": "LETTUCE ICEBERG SHRED 5 COUNT 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000219001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.81",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000219",
        "Product Status": "Active"
    },
    {
        "Root": "10001571",
        "LV": "1",
        "Item Description": "CABBAGE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598078",
        "Last Price Paid": "$12.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001571",
        "Product Status": "Active"
    },
    {
        "Root": "10013437",
        "LV": "1",
        "Item Description": "EGG REPLACER VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "85051-1",
        "Last Price Paid": "$10.15",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013437",
        "Product Status": "Active"
    },
    {
        "Root": "10090526",
        "LV": "1",
        "Item Description": "PINOT NOIR LANGE WV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "1531748",
        "Last Price Paid": "$17.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090526",
        "Product Status": "Active"
    },
    {
        "Root": "10000883",
        "LV": "1",
        "Item Description": "DUCK WHOLE L/I 4.5-5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000883001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000883",
        "Product Status": "Active"
    },
    {
        "Root": "10003143",
        "LV": "1",
        "Item Description": "SAUCE BERBERI MARN 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003143",
        "Product Status": "Active"
    },
    {
        "Root": "10106584",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER  YELLOW 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313010E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106584",
        "Product Status": "Active"
    },
    {
        "Root": "10000427",
        "LV": "1",
        "Item Description": "CHEESECAKE INDIVIDUAL NO CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1302",
        "Last Price Paid": "$1.50",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000427",
        "Product Status": "Active"
    },
    {
        "Root": "10003804",
        "LV": "1",
        "Item Description": "MIX HAPPY HOUR 10 LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "41950",
        "Last Price Paid": "$3.84",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003804",
        "Product Status": "Active"
    },
    {
        "Root": "10028727",
        "LV": "1",
        "Item Description": "NORI CHIPS WASABI SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "15668",
        "Last Price Paid": "$3.15",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028727",
        "Product Status": "Active"
    },
    {
        "Root": "10007752",
        "LV": "1",
        "Item Description": "SPARK GRUET BLC NOIR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9216249",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007752",
        "Product Status": "Active"
    },
    {
        "Root": "10006870",
        "LV": "1",
        "Item Description": "RIESLING CH STE MICHELLE VINTNER'S SELECTION 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006870",
        "Product Status": "Active"
    },
    {
        "Root": "10003062",
        "LV": "1",
        "Item Description": "PASTA DRY ELBOW PLUS MLTI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.85",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003062",
        "Product Status": "Active"
    },
    {
        "Root": "10103328",
        "LV": "1",
        "Item Description": "SHRIMP 91-110 CKD TAIL-OFF 5X2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103328001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6254495",
        "Last Price Paid": "$5.10",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103328",
        "Product Status": "Active"
    },
    {
        "Root": "10031088",
        "LV": "1",
        "Item Description": "MERLOT DUCKHORN THREE PALMS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587226",
        "Last Price Paid": "$87.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031088",
        "Product Status": "Active"
    },
    {
        "Root": "10019241",
        "LV": "1",
        "Item Description": "BLD WHITE ALEXAKIS ASSYATIKO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019241001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9441646",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019241",
        "Product Status": "Active"
    },
    {
        "Root": "10005101",
        "LV": "2",
        "Item Description": "COTTON CANDY MIX O JAY ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005101002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3205",
        "Last Price Paid": "$4.19",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005101",
        "Product Status": "Active"
    },
    {
        "Root": "10001859",
        "LV": "1",
        "Item Description": "HERB CHIVES 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001859001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598015",
        "Last Price Paid": "$7.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001859",
        "Product Status": "Active"
    },
    {
        "Root": "10001859",
        "LV": "1",
        "Item Description": "HERB CHIVES 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001859001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001859",
        "Product Status": "Active"
    },
    {
        "Root": "10001910",
        "LV": "1",
        "Item Description": "HORSERADISH PURE 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460520",
        "Last Price Paid": "$14.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001910",
        "Product Status": "Active"
    },
    {
        "Root": "10001910",
        "LV": "1",
        "Item Description": "HORSERADISH PURE 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "50015",
        "Last Price Paid": "$14.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001910",
        "Product Status": "Active"
    },
    {
        "Root": "10001910",
        "LV": "1",
        "Item Description": "HORSERADISH PURE 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "460520",
        "Last Price Paid": "$14.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001910",
        "Product Status": "Active"
    },
    {
        "Root": "10001280",
        "LV": "1",
        "Item Description": "JUICE MM LEMON CONCENTRATE FRZ 12/30OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006436",
        "Last Price Paid": "$1.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001280",
        "Product Status": "Active"
    },
    {
        "Root": "10027755",
        "LV": "1",
        "Item Description": "LAMB RACKS 18-22 OZ FRNH FZ AUSS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027755001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1236220",
        "Last Price Paid": "$12.19",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027755",
        "Product Status": "Active"
    },
    {
        "Root": "10040095",
        "LV": "3",
        "Item Description": "PIZZA 4 CHEESE DIGIORNO TDCR 6.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040095002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.16",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10040095",
        "Product Status": "Active"
    },
    {
        "Root": "10011005",
        "LV": "1",
        "Item Description": "CHOC WHITE TINK WINGS 1 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW6544",
        "Last Price Paid": "$0.50",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011005",
        "Product Status": "Active"
    },
    {
        "Root": "10028915",
        "LV": "1",
        "Item Description": "EXTRACT VANILLA CLEAR IMITATION 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "835165",
        "Last Price Paid": "$7.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028915",
        "Product Status": "Active"
    },
    {
        "Root": "10003967",
        "LV": "1",
        "Item Description": "OIL VENTA DEL BARON EV SP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01210",
        "Last Price Paid": "$31.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003967",
        "Product Status": "Active"
    },
    {
        "Root": "10000547",
        "LV": "1",
        "Item Description": "DOUGH BALL NAAAN BREAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.43",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000547",
        "Product Status": "Active"
    },
    {
        "Root": "10001853",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BULBS W/TOPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001853",
        "Product Status": "Active"
    },
    {
        "Root": "10009765",
        "LV": "2",
        "Item Description": "TEQ BLANCO EL MAYOR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009765001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99327",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009765",
        "Product Status": "Active"
    },
    {
        "Root": "10033941",
        "LV": "1",
        "Item Description": "GC18139 200ML 310ML TRANS LID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11097015",
        "Last Price Paid": "$0.07",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033941",
        "Product Status": "Active"
    },
    {
        "Root": "10025562",
        "LV": "1",
        "Item Description": "LID COMPOSTABLE CLEAR FOR 8-16 OZ DELI CONTAINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51400007",
        "Last Price Paid": "$0.11",
        "Case Pack": "900",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025562",
        "Product Status": "Active"
    },
    {
        "Root": "10001808",
        "LV": "1",
        "Item Description": "LIMES BY THE DOZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490135",
        "Last Price Paid": "$0.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001808",
        "Product Status": "Active"
    },
    {
        "Root": "10001808",
        "LV": "1",
        "Item Description": "LIMES BY THE DOZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001808",
        "Product Status": "Active"
    },
    {
        "Root": "10001808",
        "LV": "1",
        "Item Description": "LIMES BY THE DOZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "490135",
        "Last Price Paid": "$0.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001808",
        "Product Status": "Active"
    },
    {
        "Root": "10032147",
        "LV": "1",
        "Item Description": "CLEAR DOME W/BLACK BASE COMBO PS40805 6.9X6.7X4.6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51097003",
        "Last Price Paid": "$2.57",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032147",
        "Product Status": "Active"
    },
    {
        "Root": "10033793",
        "LV": "1",
        "Item Description": "MALBEC ANTIGAL UNO 12/ 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033793001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "535362",
        "Last Price Paid": "$5.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033793",
        "Product Status": "Active"
    },
    {
        "Root": "10009469",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN 15YR FINE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "112421",
        "Last Price Paid": "$114.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009469",
        "Product Status": "Active"
    },
    {
        "Root": "10016140",
        "LV": "1",
        "Item Description": "WHOLE WILD SOCKEYE SALMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016140001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-00880",
        "Last Price Paid": "$34.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016140",
        "Product Status": "Active"
    },
    {
        "Root": "10016140",
        "LV": "1",
        "Item Description": "WHOLE WILD SOCKEYE SALMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016140001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF207",
        "Last Price Paid": "$34.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016140",
        "Product Status": "Active"
    },
    {
        "Root": "10023537",
        "LV": "1",
        "Item Description": "COCOA BUTTER PEARL GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "630137",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023537",
        "Product Status": "Active"
    },
    {
        "Root": "10001950",
        "LV": "1",
        "Item Description": "BEET GOLD BABY CG 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01BLBTT-26",
        "Last Price Paid": "$119.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001950",
        "Product Status": "Active"
    },
    {
        "Root": "10040596",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED CHEDDAR WHITE 4/5 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040596001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "13041",
        "Last Price Paid": "$3.22",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040596",
        "Product Status": "Active"
    },
    {
        "Root": "10034456",
        "LV": "2",
        "Item Description": "CABERNET SAUVIGNON BORNE OF FIRE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034456002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "981855",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034456",
        "Product Status": "Active"
    },
    {
        "Root": "10034670",
        "LV": "1",
        "Item Description": "ORANGE CANDY & CAKE POWDER FOOD COLORING 18 GRAMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW168291",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034670",
        "Product Status": "Active"
    },
    {
        "Root": "10001407",
        "LV": "1",
        "Item Description": "SPROUT BRUSSEL GRN BABY F",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07BGBS-11",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001407",
        "Product Status": "Active"
    },
    {
        "Root": "10082043",
        "LV": "1",
        "Item Description": "DRAFT IVANHOE PARK APPLE EVER AFTER SOUR ALE 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99693",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082043",
        "Product Status": "Active"
    },
    {
        "Root": "10018521",
        "LV": "1",
        "Item Description": "TEQ,MAESTRO,DOBEL,SILVER, 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018521001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "594762",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018521",
        "Product Status": "Active"
    },
    {
        "Root": "10022113",
        "LV": "1",
        "Item Description": "CHIANTI CLASS CASTELLO DI VINSANTO 375 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9158106",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022113",
        "Product Status": "Active"
    },
    {
        "Root": "10029774",
        "LV": "1",
        "Item Description": "PUREE MANGO S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480891",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029774",
        "Product Status": "Active"
    },
    {
        "Root": "10012743",
        "LV": "1",
        "Item Description": "COASTER OBIWAN HANGAR 4\" 64O4DB2S OCTAGON CMYK 2S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-023",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012743",
        "Product Status": "Active"
    },
    {
        "Root": "10101419",
        "LV": "1",
        "Item Description": "WAFFLE CONES MATCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "MT-M",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101419",
        "Product Status": "Active"
    },
    {
        "Root": "10091614",
        "LV": "1",
        "Item Description": "GLAZE COATING WHITE RONDO, ULTRA, 6KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7501480000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091614",
        "Product Status": "Active"
    },
    {
        "Root": "10040884",
        "LV": "2",
        "Item Description": "SELTZER BUD LIGHT MANGO 12 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040884002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10432",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040884",
        "Product Status": "Active"
    },
    {
        "Root": "10017646",
        "LV": "1",
        "Item Description": "KRILL PACIFICA SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017646001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6374022",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017646",
        "Product Status": "Active"
    },
    {
        "Root": "10045876",
        "LV": "1",
        "Item Description": "PASTRY FONDANT WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "4102828",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045876",
        "Product Status": "Active"
    },
    {
        "Root": "10018849",
        "LV": "1",
        "Item Description": "POTATO PURPLE SWEET 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482533",
        "Last Price Paid": "$58.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018849",
        "Product Status": "Active"
    },
    {
        "Root": "10007538",
        "LV": "1",
        "Item Description": "RIES PACIFIC RIM SWT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "231281",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007538",
        "Product Status": "Active"
    },
    {
        "Root": "10017353",
        "LV": "1",
        "Item Description": "GLOVE VINYL POW FREE X-LG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.75",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017353",
        "Product Status": "Active"
    },
    {
        "Root": "10007380",
        "LV": "1",
        "Item Description": "CAB DMZ ROSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "603402",
        "Last Price Paid": "$10.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007380",
        "Product Status": "Active"
    },
    {
        "Root": "10035646",
        "LV": "1",
        "Item Description": "PHOTOCAKE INK CARTRIDGE BLACK XP430 T288XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22671",
        "Last Price Paid": "$16.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035646",
        "Product Status": "Active"
    },
    {
        "Root": "10040900",
        "LV": "1",
        "Item Description": "EXTRACT COTTON CANDY OIL SOLUBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1407",
        "Last Price Paid": "$62.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040900",
        "Product Status": "Active"
    },
    {
        "Root": "10045064",
        "LV": "1",
        "Item Description": "MELON HONEYDEW CUBED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511625",
        "Last Price Paid": "$14.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045064",
        "Product Status": "Active"
    },
    {
        "Root": "10045064",
        "LV": "1",
        "Item Description": "MELON HONEYDEW CUBED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-002D",
        "Last Price Paid": "$14.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045064",
        "Product Status": "Active"
    },
    {
        "Root": "10102132",
        "LV": "1",
        "Item Description": "BEER KEG 81 BAY BEW CO PB BANANA PORTER 15.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15474",
        "Last Price Paid": "$192.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102132",
        "Product Status": "Active"
    },
    {
        "Root": "10001416",
        "LV": "1",
        "Item Description": "LETTUCE PET EMERLD CRYSTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09PECL-33",
        "Last Price Paid": "$0.67",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001416",
        "Product Status": "Active"
    },
    {
        "Root": "10039524",
        "LV": "1",
        "Item Description": "LINER 1.7IN BAKE-IN MINNIE OVEN SAFE MINI CK8370",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005136",
        "Last Price Paid": "$0.15",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039524",
        "Product Status": "Active"
    },
    {
        "Root": "10016331",
        "LV": "2",
        "Item Description": "CUPCAKE MINI CHOC ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016331002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1641",
        "Last Price Paid": "$0.49",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016331",
        "Product Status": "Active"
    },
    {
        "Root": "10004495",
        "LV": "1",
        "Item Description": "CHOC PARALLEL ANNIVERSARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "02 ANNIVERSARY",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004495",
        "Product Status": "Active"
    },
    {
        "Root": "10039960",
        "LV": "1",
        "Item Description": "GELATO VANILLA CHOC CHUNK 5Z CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-CHCP-02-CS24",
        "Last Price Paid": "$1.38",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039960",
        "Product Status": "Active"
    },
    {
        "Root": "10015483",
        "LV": "1",
        "Item Description": "SPINACH LEAF FZN GRADE A DOMESTIC 12/3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7899610",
        "Last Price Paid": "$5.11",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015483",
        "Product Status": "Active"
    },
    {
        "Root": "10036407",
        "LV": "1",
        "Item Description": "HASHBROWN TRIANGLE PATTIESLAMB WESTON 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036407",
        "Product Status": "Active"
    },
    {
        "Root": "10081684",
        "LV": "1",
        "Item Description": "PAPER WALL DISNEY 50TH ANNIV TS 40X25CM 125SH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081684001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6172720000",
        "Last Price Paid": "$1.40",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081684",
        "Product Status": "Active"
    },
    {
        "Root": "10012614",
        "LV": "1",
        "Item Description": "SPRAY VELVET VIOLET (6 EA)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV107V",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012614",
        "Product Status": "Active"
    },
    {
        "Root": "10001320",
        "LV": "1",
        "Item Description": "BEAN GREEN WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001320",
        "Product Status": "Active"
    },
    {
        "Root": "10087115",
        "LV": "1",
        "Item Description": "BREAD SESAME FOOD FOR LIFE EZEKIAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087115001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "123C6",
        "Last Price Paid": "$4.46",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087115",
        "Product Status": "Active"
    },
    {
        "Root": "10000208",
        "LV": "1",
        "Item Description": "CAULIFLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000208",
        "Product Status": "Active"
    },
    {
        "Root": "10000208",
        "LV": "1",
        "Item Description": "CAULIFLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20320",
        "Last Price Paid": "$2.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000208",
        "Product Status": "Active"
    },
    {
        "Root": "10104068",
        "LV": "1",
        "Item Description": "SPIRIT VODKA SKYY INFUSED CHERRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992457",
        "Last Price Paid": "$9.34",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104068",
        "Product Status": "Active"
    },
    {
        "Root": "10094931",
        "LV": "1",
        "Item Description": "DOUGH, DNSH ASST MINI PRE-PRFD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4440",
        "Last Price Paid": "$0.36",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094931",
        "Product Status": "Active"
    },
    {
        "Root": "10100489",
        "LV": "1",
        "Item Description": "CANDY SD YUMEARTH GUMMY WORMS 2.5OZ BAG 6X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1555",
        "Last Price Paid": "$1.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100489",
        "Product Status": "Active"
    },
    {
        "Root": "10089228",
        "LV": "1",
        "Item Description": "CUP FOOD BAKING 2OZ ALUMINUM DISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51044847",
        "Last Price Paid": "$0.13",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089228",
        "Product Status": "Active"
    },
    {
        "Root": "10038861",
        "LV": "1",
        "Item Description": "BAG SWGE RONTO WRAP PINCH BOTTOM OPEN SIDE 2000 CO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4444349",
        "Last Price Paid": "$0.02",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038861",
        "Product Status": "Active"
    },
    {
        "Root": "10000194",
        "LV": "1",
        "Item Description": "SPINACH CURL CELLO PK 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482068",
        "Last Price Paid": "$21.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000194",
        "Product Status": "Active"
    },
    {
        "Root": "10011020",
        "LV": "1",
        "Item Description": "BREAD ROLL SESAME SEED DINNER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011020001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "13:S",
        "Last Price Paid": "$0.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011020",
        "Product Status": "Active"
    },
    {
        "Root": "10102270",
        "LV": "1",
        "Item Description": "PRESERVED LEMONS 4.4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30555",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102270",
        "Product Status": "Active"
    },
    {
        "Root": "10032243",
        "LV": "1",
        "Item Description": "LETTUCE BABY ARTISAN ROMAINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032243001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21272",
        "Last Price Paid": "$1.20",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032243",
        "Product Status": "Active"
    },
    {
        "Root": "10032243",
        "LV": "1",
        "Item Description": "LETTUCE BABY ARTISAN ROMAINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032243001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032243",
        "Product Status": "Active"
    },
    {
        "Root": "10033243",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK GLAZE COINS ORCHID 1/11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033243001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "540093",
        "Last Price Paid": "$43.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033243",
        "Product Status": "Active"
    },
    {
        "Root": "10016349",
        "LV": "1",
        "Item Description": "MALBEC NIETO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "540593",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016349",
        "Product Status": "Active"
    },
    {
        "Root": "10002361",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRIE 2/2 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "67868",
        "Last Price Paid": "$14.07",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002361",
        "Product Status": "Active"
    },
    {
        "Root": "10036274",
        "LV": "2",
        "Item Description": "SHERRY PX EMILIO HIDALGO 500 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036274002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "176610",
        "Last Price Paid": "$21.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036274",
        "Product Status": "Active"
    },
    {
        "Root": "10001052",
        "LV": "1",
        "Item Description": "CRAB CAKE SHAWS 70% 3 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6339606",
        "Last Price Paid": "$2.02",
        "Case Pack": "213",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001052",
        "Product Status": "Active"
    },
    {
        "Root": "10004774",
        "LV": "1",
        "Item Description": "JUICE TOMATO 5.5Z IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3020559",
        "Last Price Paid": "$0.46",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004774",
        "Product Status": "Active"
    },
    {
        "Root": "10085367",
        "LV": "1",
        "Item Description": "ICING CHOCOLATE FUDGE RTU 2/11LB PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1008267",
        "Last Price Paid": "$34.13",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085367",
        "Product Status": "Active"
    },
    {
        "Root": "10004309",
        "LV": "1",
        "Item Description": "HORS SATAY CHICKEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300360",
        "Last Price Paid": "$1.01",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004309",
        "Product Status": "Active"
    },
    {
        "Root": "10001596",
        "LV": "1",
        "Item Description": "APPLE GALA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "102113",
        "Last Price Paid": "$0.51",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001596",
        "Product Status": "Active"
    },
    {
        "Root": "10004479",
        "LV": "1",
        "Item Description": "ROLL SUSHI CALIFORNIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004479",
        "Product Status": "Active"
    },
    {
        "Root": "10093058",
        "LV": "1",
        "Item Description": "CHEESE FONTINA RED WAX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093058001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "11541",
        "Last Price Paid": "$8.19",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093058",
        "Product Status": "Active"
    },
    {
        "Root": "10011561",
        "LV": "1",
        "Item Description": "PEACH RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270655",
        "Last Price Paid": "$65.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011561",
        "Product Status": "Active"
    },
    {
        "Root": "10030868",
        "LV": "1",
        "Item Description": "210CCLAIR 5.9X2X2\" WHITE ECLAIR BOX W/ WINDOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51210066",
        "Last Price Paid": "$0.37",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030868",
        "Product Status": "Active"
    },
    {
        "Root": "10100329",
        "LV": "1",
        "Item Description": "LIQUEUR FRANGELICO 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100329",
        "Product Status": "Active"
    },
    {
        "Root": "10025103",
        "LV": "1",
        "Item Description": "VODKA BLUEBEERY THREE OLIVES LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025103001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "328340",
        "Last Price Paid": "$10.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025103",
        "Product Status": "Active"
    },
    {
        "Root": "10025103",
        "LV": "1",
        "Item Description": "VODKA BLUEBEERY THREE OLIVES LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025103001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "328340",
        "Last Price Paid": "$10.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025103",
        "Product Status": "Active"
    },
    {
        "Root": "10008572",
        "LV": "2",
        "Item Description": "PINOT NOIR MAGGY HAWK JOLIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008572001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "575048",
        "Last Price Paid": "$55.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008572",
        "Product Status": "Active"
    },
    {
        "Root": "10003479",
        "LV": "1",
        "Item Description": "SAUCE FISH 3 CRABS BRAND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3392899",
        "Last Price Paid": "$2.55",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003479",
        "Product Status": "Active"
    },
    {
        "Root": "10009862",
        "LV": "3",
        "Item Description": "VERMOUTH MARTINI DRY WHT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009862001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "442542",
        "Last Price Paid": "$7.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10009862",
        "Product Status": "Active"
    },
    {
        "Root": "10036266",
        "LV": "2",
        "Item Description": "SUGAR LARGE EYES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036266002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "47121",
        "Last Price Paid": "$0.10",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036266",
        "Product Status": "Active"
    },
    {
        "Root": "10001418",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT 40LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001418",
        "Product Status": "Active"
    },
    {
        "Root": "10001418",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT 40LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "409005",
        "Last Price Paid": "$27.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001418",
        "Product Status": "Active"
    },
    {
        "Root": "10001418",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT 40LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001418",
        "Product Status": "Active"
    },
    {
        "Root": "10005455",
        "LV": "1",
        "Item Description": "BARLEY BLACK 6CT 2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8647703",
        "Last Price Paid": "$8.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005455",
        "Product Status": "Active"
    },
    {
        "Root": "10035872",
        "LV": "1",
        "Item Description": "CHEESE MANCHEGO 12 MONTH SHEEP SPAIN 2/6 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035872001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6973",
        "Last Price Paid": "$17.35",
        "Case Pack": "25.52",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035872",
        "Product Status": "Active"
    },
    {
        "Root": "10006882",
        "LV": "1",
        "Item Description": "MERLOT COPPOLA DIAMOND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "91320",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006882",
        "Product Status": "Active"
    },
    {
        "Root": "10000517",
        "LV": "1",
        "Item Description": "CAKE YELLOW SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000517001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000517",
        "Product Status": "Active"
    },
    {
        "Root": "10011960",
        "LV": "1",
        "Item Description": "TOMATO TOYBOX HEIRLOOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17TB-44",
        "Last Price Paid": "$86.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011960",
        "Product Status": "Active"
    },
    {
        "Root": "10013593",
        "LV": "2",
        "Item Description": "SB SYRUP CHESTNUT PRALINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013593001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11057899",
        "Last Price Paid": "$14.27",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10013593",
        "Product Status": "Active"
    },
    {
        "Root": "10022961",
        "LV": "1",
        "Item Description": "KOMBU DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY916",
        "Last Price Paid": "$23.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022961",
        "Product Status": "Active"
    },
    {
        "Root": "10003568",
        "LV": "2",
        "Item Description": "SPICE ALLSPICE GRD 6/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003568002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.32",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003568",
        "Product Status": "Active"
    },
    {
        "Root": "10083812",
        "LV": "1",
        "Item Description": "TUNA YF POKE CUBE 1.5CM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083812001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "4542290",
        "Last Price Paid": "$6.40",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083812",
        "Product Status": "Active"
    },
    {
        "Root": "10024495",
        "LV": "1",
        "Item Description": "CHOC MILK GINGERBREAD MAN 2 2/3 X 3 1/4 IN CELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW240509",
        "Last Price Paid": "$3.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024495",
        "Product Status": "Active"
    },
    {
        "Root": "10108217",
        "LV": "1",
        "Item Description": "DÉCOR CARAMELIA CRUNCHY PEARL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA8425",
        "Last Price Paid": "$78.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108217",
        "Product Status": "Active"
    },
    {
        "Root": "10041063",
        "LV": "1",
        "Item Description": "PROSCUITTO SAN DANIELE SALUMI ITALIANI 16 MONTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041063001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "50121",
        "Last Price Paid": "$14.44",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041063",
        "Product Status": "Active"
    },
    {
        "Root": "10000252",
        "LV": "1",
        "Item Description": "LETTUCE FRISSE CIRCUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480064",
        "Last Price Paid": "$38.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000252",
        "Product Status": "Active"
    },
    {
        "Root": "10000252",
        "LV": "1",
        "Item Description": "LETTUCE FRISSE CIRCUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21290",
        "Last Price Paid": "$38.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000252",
        "Product Status": "Active"
    },
    {
        "Root": "10000252",
        "LV": "1",
        "Item Description": "LETTUCE FRISSE CIRCUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETFRICIR",
        "Last Price Paid": "$38.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000252",
        "Product Status": "Active"
    },
    {
        "Root": "10004636",
        "LV": "1",
        "Item Description": "SYRUP CORN DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9006727",
        "Last Price Paid": "$24.32",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004636",
        "Product Status": "Active"
    },
    {
        "Root": "10000626",
        "LV": "1",
        "Item Description": "VEAL BONE LOWER FEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000626001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1380005",
        "Last Price Paid": "$4.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000626",
        "Product Status": "Active"
    },
    {
        "Root": "10004913",
        "LV": "1",
        "Item Description": "OLIVE GREEK MEDLEY MIX 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004913001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-15055",
        "Last Price Paid": "$6.75",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004913",
        "Product Status": "Active"
    },
    {
        "Root": "10034547",
        "LV": "1",
        "Item Description": "TUMBLER CLEAR 12Z SQUARE EMI-ST12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11313189",
        "Last Price Paid": "$0.36",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034547",
        "Product Status": "Active"
    },
    {
        "Root": "10009505",
        "LV": "2",
        "Item Description": "BOURBON WOODFORD RES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009505002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009505",
        "Product Status": "Active"
    },
    {
        "Root": "10044801",
        "LV": "2",
        "Item Description": "WATER SPARKLING AHA BLUEBERRY POMEGRANATE 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044801002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "049000532685",
        "Last Price Paid": "$1.12",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10044801",
        "Product Status": "Active"
    },
    {
        "Root": "10005469",
        "LV": "1",
        "Item Description": "COCOA POWDER DK DUTCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C700",
        "Last Price Paid": "$15.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005469",
        "Product Status": "Active"
    },
    {
        "Root": "10041095",
        "LV": "1",
        "Item Description": "RICE CRISPY TREAT EARTH DAY 36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041095001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041095",
        "Product Status": "Active"
    },
    {
        "Root": "10086893",
        "LV": "1",
        "Item Description": "CLAM SURF WHOLE MEAT AP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086893001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "6403005",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086893",
        "Product Status": "Active"
    },
    {
        "Root": "10108670",
        "LV": "2",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108670001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10108670",
        "Product Status": "Active"
    },
    {
        "Root": "10038212",
        "LV": "1",
        "Item Description": "ROUND DARK CHOCOLATE DISK 1.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038212",
        "Product Status": "Active"
    },
    {
        "Root": "10040935",
        "LV": "1",
        "Item Description": "SELTZER HARD BUD LIGHT STRAWBERRY 16OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "10436",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040935",
        "Product Status": "Active"
    },
    {
        "Root": "10041515",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT LARGE BUTTER 2.8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041515001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041515",
        "Product Status": "Active"
    },
    {
        "Root": "10039593",
        "LV": "1",
        "Item Description": "BAG CANVAS SPORK SINGLES SWGE 500/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039593",
        "Product Status": "Active"
    },
    {
        "Root": "10091372",
        "LV": "1",
        "Item Description": "LIQ DEKUYPER BLUE CURACAO 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "157604",
        "Last Price Paid": "$9.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091372",
        "Product Status": "Active"
    },
    {
        "Root": "10008993",
        "LV": "1",
        "Item Description": "SAUV BLC M EDWARD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008993001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008993",
        "Product Status": "Active"
    },
    {
        "Root": "10008993",
        "LV": "1",
        "Item Description": "SAUV BLC M EDWARD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008993001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008993",
        "Product Status": "Active"
    },
    {
        "Root": "10004829",
        "LV": "1",
        "Item Description": "PASTA, PENNE PKU, APROTEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "E FOODS(17 OZ)",
        "Last Price Paid": "$18.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004829",
        "Product Status": "Active"
    },
    {
        "Root": "10007138",
        "LV": "1",
        "Item Description": "TUSCAN ROSSO SASSICAIA 2015 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "150777",
        "Last Price Paid": "$178.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007138",
        "Product Status": "Active"
    },
    {
        "Root": "10012151",
        "LV": "1",
        "Item Description": "BEER KIRIN ICHIBAN 12 OUNCE BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40118",
        "Last Price Paid": "$1.35",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012151",
        "Product Status": "Active"
    },
    {
        "Root": "10005708",
        "LV": "1",
        "Item Description": "DOUGHNUT FRENCH MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "200FDS",
        "Last Price Paid": "$0.46",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005708",
        "Product Status": "Active"
    },
    {
        "Root": "10002542",
        "LV": "1",
        "Item Description": "BOK CHOY BABY LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482224",
        "Last Price Paid": "$58.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002542",
        "Product Status": "Active"
    },
    {
        "Root": "10032956",
        "LV": "1",
        "Item Description": "ASPARAGUS WHITE HOLLAND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032956001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSVEG001",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032956",
        "Product Status": "Active"
    },
    {
        "Root": "10101399",
        "LV": "1",
        "Item Description": "SOUP SHRIMP BISQUE 4/8LB RTU POUCH BOIL IN BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8344294",
        "Last Price Paid": "$29.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101399",
        "Product Status": "Active"
    },
    {
        "Root": "10003623",
        "LV": "1",
        "Item Description": "SHEET TRANS ANNIVERSARY 16X20",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003623",
        "Product Status": "Active"
    },
    {
        "Root": "10029195",
        "LV": "1",
        "Item Description": "CHEESE BLUE ASHER SWEET GRASS 6LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029195001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19058",
        "Last Price Paid": "$14.69",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029195",
        "Product Status": "Active"
    },
    {
        "Root": "10003868",
        "LV": "1",
        "Item Description": "SPRINKLES CHOC JIMMIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3119774",
        "Last Price Paid": "$19.37",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003868",
        "Product Status": "Active"
    },
    {
        "Root": "10107869",
        "LV": "1",
        "Item Description": "BITE SIZED CHICKEN & CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073423",
        "Last Price Paid": "$4.06",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107869",
        "Product Status": "Active"
    },
    {
        "Root": "10012752",
        "LV": "1",
        "Item Description": "MUSTARD PETITE MIXED 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04PMM-33",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012752",
        "Product Status": "Active"
    },
    {
        "Root": "10002796",
        "LV": "2",
        "Item Description": "COKE DIET CAN 12OZ/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002796002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "100281",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002796",
        "Product Status": "Active"
    },
    {
        "Root": "10001703",
        "LV": "1",
        "Item Description": "HERB THYME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001703001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482260",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001703",
        "Product Status": "Active"
    },
    {
        "Root": "10001703",
        "LV": "1",
        "Item Description": "HERB THYME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001703001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "482260",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001703",
        "Product Status": "Active"
    },
    {
        "Root": "10001703",
        "LV": "1",
        "Item Description": "HERB THYME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001703001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24190",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001703",
        "Product Status": "Active"
    },
    {
        "Root": "10040085",
        "LV": "1",
        "Item Description": "ICE CREAM DRUMSTICK NESTLE 8/14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040085001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12331016",
        "Last Price Paid": "$1.58",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040085",
        "Product Status": "Active"
    },
    {
        "Root": "10005999",
        "LV": "1",
        "Item Description": "STIRRER COFFEE WOOD 4.5 IN 10/1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "515230",
        "Last Price Paid": "$0.00",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005999",
        "Product Status": "Active"
    },
    {
        "Root": "10004626",
        "LV": "1",
        "Item Description": "LASAGNA VEGETARIAN 5.75#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7326309",
        "Last Price Paid": "$21.33",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004626",
        "Product Status": "Active"
    },
    {
        "Root": "10003326",
        "LV": "1",
        "Item Description": "COLOR RED LIQUID GEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22497",
        "Last Price Paid": "$5.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003326",
        "Product Status": "Active"
    },
    {
        "Root": "10000321",
        "LV": "1",
        "Item Description": "SLAW BROC W/O RED CABBAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481985",
        "Last Price Paid": "$14.15",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000321",
        "Product Status": "Active"
    },
    {
        "Root": "10030916",
        "LV": "1",
        "Item Description": "SHELL TART VANILLA ECLAIR LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8095142",
        "Last Price Paid": "$0.76",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030916",
        "Product Status": "Active"
    },
    {
        "Root": "10001258",
        "LV": "3",
        "Item Description": "GARLIC PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001258002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401097",
        "Last Price Paid": "$14.60",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10001258",
        "Product Status": "Active"
    },
    {
        "Root": "10004574",
        "LV": "1",
        "Item Description": "SAUCE COCKTAIL 1.5Z IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2647618",
        "Last Price Paid": "$0.27",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004574",
        "Product Status": "Active"
    },
    {
        "Root": "10002677",
        "LV": "2",
        "Item Description": "SAUCE CHILI #10 POUCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002677002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002677",
        "Product Status": "Active"
    },
    {
        "Root": "10034916",
        "LV": "1",
        "Item Description": "PORTER MAUI BREWING COCONUT HIWA 12Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1371",
        "Last Price Paid": "$2.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034916",
        "Product Status": "Active"
    },
    {
        "Root": "10029619",
        "LV": "1",
        "Item Description": "CHOC HAUNTED HOUSE TIGHTROPE 4\"X1 1/4\" RECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW25632",
        "Last Price Paid": "$0.50",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029619",
        "Product Status": "Active"
    },
    {
        "Root": "10031229",
        "LV": "1",
        "Item Description": "VINEGAR BALSAMIC GUIUSTI 1OO YEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN060",
        "Last Price Paid": "$695.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031229",
        "Product Status": "Active"
    },
    {
        "Root": "10034186",
        "LV": "2",
        "Item Description": "RASPBERRIES STANDARD 12/6 OZ FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034186002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250030",
        "Last Price Paid": "$2.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034186",
        "Product Status": "Active"
    },
    {
        "Root": "10007256",
        "LV": "2",
        "Item Description": "CAB SAUV STONESTREET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007256001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587282",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007256",
        "Product Status": "Active"
    },
    {
        "Root": "10034304",
        "LV": "1",
        "Item Description": "GARAM MASALA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "162800",
        "Last Price Paid": "$9.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034304",
        "Product Status": "Active"
    },
    {
        "Root": "10001531",
        "LV": "1",
        "Item Description": "LETTUCE CRYSTAL MIXED BLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09MXCLB-33",
        "Last Price Paid": "$1.02",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001531",
        "Product Status": "Active"
    },
    {
        "Root": "10004500",
        "LV": "1",
        "Item Description": "ISOMALT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "02...{ISOMALT{",
        "Last Price Paid": "$149.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004500",
        "Product Status": "Active"
    },
    {
        "Root": "10005758",
        "LV": "1",
        "Item Description": "BAGEL 8 GRAIN 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005758",
        "Product Status": "Active"
    },
    {
        "Root": "10003443",
        "LV": "1",
        "Item Description": "COFFEE KENYA AA 18/1LB CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.93",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003443",
        "Product Status": "Active"
    },
    {
        "Root": "10102589",
        "LV": "1",
        "Item Description": "WINE FRUIT MANISCHEWITZ BLACKBERRY 750ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9044808",
        "Last Price Paid": "$5.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102589",
        "Product Status": "Active"
    },
    {
        "Root": "10001936",
        "LV": "1",
        "Item Description": "PARSNIP DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-012-14",
        "Last Price Paid": "$15.61",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001936",
        "Product Status": "Active"
    },
    {
        "Root": "10001936",
        "LV": "1",
        "Item Description": "PARSNIP DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "599512",
        "Last Price Paid": "$15.61",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001936",
        "Product Status": "Active"
    },
    {
        "Root": "10003962",
        "LV": "2",
        "Item Description": "VINEGAR MINUS 8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003962002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$537.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003962",
        "Product Status": "Active"
    },
    {
        "Root": "10031324",
        "LV": "1",
        "Item Description": "RUM BARBANCOURT 8YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11283",
        "Last Price Paid": "$22.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031324",
        "Product Status": "Active"
    },
    {
        "Root": "10006366",
        "LV": "1",
        "Item Description": "BAG PLASTIC 100 NP W/TIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1702992",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006366",
        "Product Status": "Active"
    },
    {
        "Root": "10006008",
        "LV": "1",
        "Item Description": "TRAY FOOD #300 NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006008",
        "Product Status": "Active"
    },
    {
        "Root": "10016322",
        "LV": "1",
        "Item Description": "CUPCAKE MINI CHOC UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1632",
        "Last Price Paid": "$0.28",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016322",
        "Product Status": "Active"
    },
    {
        "Root": "10036269",
        "LV": "1",
        "Item Description": "WINE ROSE VINA BORGIA BAG-IN-BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "436111",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036269",
        "Product Status": "Active"
    },
    {
        "Root": "10036269",
        "LV": "1",
        "Item Description": "WINE ROSE VINA BORGIA BAG-IN-BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "436111",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036269",
        "Product Status": "Active"
    },
    {
        "Root": "10034488",
        "LV": "1",
        "Item Description": "POWDER BEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "511001B",
        "Last Price Paid": "$26.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034488",
        "Product Status": "Active"
    },
    {
        "Root": "10006270",
        "LV": "3",
        "Item Description": "PLATE/TRAY MINI 3 COMPT P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006270003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510300",
        "Last Price Paid": "$0.23",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10006270",
        "Product Status": "Active"
    },
    {
        "Root": "10023521",
        "LV": "1",
        "Item Description": "SPRINKLES BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023521001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9742",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023521",
        "Product Status": "Active"
    },
    {
        "Root": "10091074",
        "LV": "1",
        "Item Description": "DOUGH PUFF PASTRY TRI FZN HELLAS BAKERY 144/1.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091074001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7635341",
        "Last Price Paid": "$0.61",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091074",
        "Product Status": "Active"
    },
    {
        "Root": "10009966",
        "LV": "1",
        "Item Description": "LIQ BAILEYS IRISH CRM LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "332775",
        "Last Price Paid": "$32.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009966",
        "Product Status": "Active"
    },
    {
        "Root": "10009966",
        "LV": "1",
        "Item Description": "LIQ BAILEYS IRISH CRM LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "332775",
        "Last Price Paid": "$32.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009966",
        "Product Status": "Active"
    },
    {
        "Root": "10045617",
        "LV": "2",
        "Item Description": "KNIFE MED WT POLYSTYRENE WRAPPED BULK 1000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045617002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10045617",
        "Product Status": "Active"
    },
    {
        "Root": "10007808",
        "LV": "1",
        "Item Description": "SAV BLANC INFAMOUS GOOSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273897",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007808",
        "Product Status": "Active"
    },
    {
        "Root": "10011662",
        "LV": "1",
        "Item Description": "SYRUP MONIN SALTED CARAMEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011662",
        "Product Status": "Active"
    },
    {
        "Root": "10015183",
        "LV": "1",
        "Item Description": "SHEET GUITAR PLASTIC 24\"X17\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "902222",
        "Last Price Paid": "$0.67",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015183",
        "Product Status": "Active"
    },
    {
        "Root": "10017588",
        "LV": "1",
        "Item Description": "PASTRY DANISH CHEESE LRGE 6Z WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017588",
        "Product Status": "Active"
    },
    {
        "Root": "10002656",
        "LV": "3",
        "Item Description": "SPICE PEPPER BLACK WHOLE 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002656003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002656",
        "Product Status": "Active"
    },
    {
        "Root": "10041565",
        "LV": "1",
        "Item Description": "POTATO MARBLE TRI-COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041565001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "POTMAR5",
        "Last Price Paid": "$88.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041565",
        "Product Status": "Active"
    },
    {
        "Root": "10026035",
        "LV": "3",
        "Item Description": "CUP CHAMP FLUTE CLR 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026035001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11092104",
        "Last Price Paid": "$0.20",
        "Case Pack": "360",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10026035",
        "Product Status": "Active"
    },
    {
        "Root": "10037239",
        "LV": "1",
        "Item Description": "Q TONIC WATER 6.7OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037239001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "128848",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037239",
        "Product Status": "Active"
    },
    {
        "Root": "10001623",
        "LV": "2",
        "Item Description": "OKRA CUT FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001623002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8328304",
        "Last Price Paid": "$5.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001623",
        "Product Status": "Active"
    },
    {
        "Root": "10006125",
        "LV": "1",
        "Item Description": "PLATE 6 INCH PAPER WHITE PLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006125",
        "Product Status": "Active"
    },
    {
        "Root": "10001897",
        "LV": "1",
        "Item Description": "TURNIP WHOLE PEELED 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-004",
        "Last Price Paid": "$21.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001897",
        "Product Status": "Active"
    },
    {
        "Root": "10001897",
        "LV": "1",
        "Item Description": "TURNIP WHOLE PEELED 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510520",
        "Last Price Paid": "$21.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001897",
        "Product Status": "Active"
    },
    {
        "Root": "10037522",
        "LV": "1",
        "Item Description": "GELATO VANILLA BEAN VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037522001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "IGV-001",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037522",
        "Product Status": "Active"
    },
    {
        "Root": "10001673",
        "LV": "1",
        "Item Description": "AVOCADO 48 CT 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16005",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001673",
        "Product Status": "Active"
    },
    {
        "Root": "10001673",
        "LV": "1",
        "Item Description": "AVOCADO 48 CT 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "AVO48",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001673",
        "Product Status": "Active"
    },
    {
        "Root": "10001673",
        "LV": "1",
        "Item Description": "AVOCADO 48 CT 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "301220",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001673",
        "Product Status": "Active"
    },
    {
        "Root": "10003139",
        "LV": "1",
        "Item Description": "POPCORN 8OZ PKG MEGAPOP K",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2838",
        "Last Price Paid": "$1.19",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003139",
        "Product Status": "Active"
    },
    {
        "Root": "10000481",
        "LV": "1",
        "Item Description": "BREAD MOROCCAN MULTIGRAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "9:L",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000481",
        "Product Status": "Active"
    },
    {
        "Root": "10004385",
        "LV": "1",
        "Item Description": "WATER SAN PELLG BLD ORNG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "BM59",
        "Last Price Paid": "$1.00",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004385",
        "Product Status": "Active"
    },
    {
        "Root": "10028186",
        "LV": "1",
        "Item Description": "BAKE CUP TULIP BRIGHT GREEN MB74 2.4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391017",
        "Last Price Paid": "$0.18",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028186",
        "Product Status": "Active"
    },
    {
        "Root": "10006880",
        "LV": "1",
        "Item Description": "CHIANTI ANTINORI RES VILLA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578876",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006880",
        "Product Status": "Active"
    },
    {
        "Root": "10028211",
        "LV": "1",
        "Item Description": "POPCORN CARAMEL CORN BULK 20# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3729",
        "Last Price Paid": "$65.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028211",
        "Product Status": "Active"
    },
    {
        "Root": "10004043",
        "LV": "1",
        "Item Description": "DEC PEARLS CRSPY ORNG CHO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118589",
        "Last Price Paid": "$7.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004043",
        "Product Status": "Active"
    },
    {
        "Root": "10034676",
        "LV": "1",
        "Item Description": "TURKEY BREAST SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034676001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.60",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034676",
        "Product Status": "Active"
    },
    {
        "Root": "10094069",
        "LV": "1",
        "Item Description": "CAULIFLOWER MULTI-COLOR FLORETS 12# CS CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094069001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481802",
        "Last Price Paid": "$21.74",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094069",
        "Product Status": "Active"
    },
    {
        "Root": "10000630",
        "LV": "1",
        "Item Description": "BEEF CHUCK SHORT RIB BONELESS CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000630001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0000021",
        "Last Price Paid": "$12.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000630",
        "Product Status": "Active"
    },
    {
        "Root": "10091474",
        "LV": "1",
        "Item Description": "CHOCOLATE DISC WHITE  3\" DISC 1/16\"  W/1 1/2\" HOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091474001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2099",
        "Last Price Paid": "$1.05",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091474",
        "Product Status": "Active"
    },
    {
        "Root": "10029167",
        "LV": "1",
        "Item Description": "TEA PEPPERMINT 6/25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F09179",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029167",
        "Product Status": "Active"
    },
    {
        "Root": "10001597",
        "LV": "1",
        "Item Description": "CORN ON COB SHUCKED 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "36-001H",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001597",
        "Product Status": "Active"
    },
    {
        "Root": "10001597",
        "LV": "1",
        "Item Description": "CORN ON COB SHUCKED 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511027",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001597",
        "Product Status": "Active"
    },
    {
        "Root": "10000773",
        "LV": "1",
        "Item Description": "BACON ENDS & PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3400501",
        "Last Price Paid": "$25.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000773",
        "Product Status": "Active"
    },
    {
        "Root": "10014222",
        "LV": "1",
        "Item Description": "CHOC DRK RECT HAPPY ANNIVERSARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW240104R",
        "Last Price Paid": "$29.00",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014222",
        "Product Status": "Active"
    },
    {
        "Root": "10036282",
        "LV": "1",
        "Item Description": "SAUCE PONZU LIME PLASTIC JUG 6.5 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3579687",
        "Last Price Paid": "$7.39",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036282",
        "Product Status": "Active"
    },
    {
        "Root": "10014627",
        "LV": "1",
        "Item Description": "SEED AMARANTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480463",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014627",
        "Product Status": "Active"
    },
    {
        "Root": "10030337",
        "LV": "1",
        "Item Description": "BEER ELYSIAN AVATAR JASMINE 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40706",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030337",
        "Product Status": "Active"
    },
    {
        "Root": "10042345",
        "LV": "1",
        "Item Description": "COOKIE HOMEFREE VANILLA MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "GFMVC",
        "Last Price Paid": "",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042345",
        "Product Status": "Active"
    },
    {
        "Root": "10096009",
        "LV": "1",
        "Item Description": "TEA LOOSE LEAF TEA LEAVES CHERRYBLOSSOM 14.1OZ/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10096009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W7417",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10096009",
        "Product Status": "Active"
    },
    {
        "Root": "10039532",
        "LV": "1",
        "Item Description": "SCOTCH LOCH LOMOND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039532",
        "Product Status": "Active"
    },
    {
        "Root": "10007626",
        "LV": "1",
        "Item Description": "NEB VIETTI CASTIGLIO BAROLO 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "274307",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007626",
        "Product Status": "Active"
    },
    {
        "Root": "10005237",
        "LV": "2",
        "Item Description": "DECOR DISCO SPARKLES BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005237002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11179",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005237",
        "Product Status": "Active"
    },
    {
        "Root": "10037085",
        "LV": "1",
        "Item Description": "TEMPRANILLO VEGA SICILIA UNICO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037085001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "995089",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037085",
        "Product Status": "Active"
    },
    {
        "Root": "10008199",
        "LV": "2",
        "Item Description": "PINOT NOIR CAMBRIA JULIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008199001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "937817",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008199",
        "Product Status": "Active"
    },
    {
        "Root": "10037080",
        "LV": "1",
        "Item Description": "TEMPRANILLO RIOJA VINA ARANA RESERVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "938321",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037080",
        "Product Status": "Active"
    },
    {
        "Root": "10015340",
        "LV": "1",
        "Item Description": "FLOWER NASTURTIUM 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015340",
        "Product Status": "Active"
    },
    {
        "Root": "10002057",
        "LV": "1",
        "Item Description": "LETTUCE ESCAROLE 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002057",
        "Product Status": "Active"
    },
    {
        "Root": "10010099",
        "LV": "1",
        "Item Description": "BEER COORS LIGHT LNNR 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010099001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.17",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010099",
        "Product Status": "Active"
    },
    {
        "Root": "10032897",
        "LV": "1",
        "Item Description": "CHIP CORN FRITOS BULK 8 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7271778",
        "Last Price Paid": "$2.39",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032897",
        "Product Status": "Active"
    },
    {
        "Root": "10007904",
        "LV": "1",
        "Item Description": "PINOT BENTON LANE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600841",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007904",
        "Product Status": "Active"
    },
    {
        "Root": "10000678",
        "LV": "1",
        "Item Description": "BEEF CORNED ROUND SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000678001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000678",
        "Product Status": "Active"
    },
    {
        "Root": "10001661",
        "LV": "1",
        "Item Description": "APPLE RED DEL 64 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001661001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.47",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001661",
        "Product Status": "Active"
    },
    {
        "Root": "10001661",
        "LV": "1",
        "Item Description": "APPLE RED DEL 64 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001661001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "112064",
        "Last Price Paid": "$0.47",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001661",
        "Product Status": "Active"
    },
    {
        "Root": "10001661",
        "LV": "1",
        "Item Description": "APPLE RED DEL 64 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001661001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "11050",
        "Last Price Paid": "$0.47",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001661",
        "Product Status": "Active"
    },
    {
        "Root": "10002908",
        "LV": "1",
        "Item Description": "BURGER, VEGETARIAN, BLACK BEAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4708509",
        "Last Price Paid": "$1.59",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002908",
        "Product Status": "Active"
    },
    {
        "Root": "10030633",
        "LV": "2",
        "Item Description": "GROOT PINOTAGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030633002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2166",
        "Last Price Paid": "$23.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030633",
        "Product Status": "Active"
    },
    {
        "Root": "10020057",
        "LV": "1",
        "Item Description": "COOKIE XMAS GINGERBRD JAM FILLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LB010",
        "Last Price Paid": "$3.42",
        "Case Pack": "39",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020057",
        "Product Status": "Active"
    },
    {
        "Root": "10095632",
        "LV": "1",
        "Item Description": "WINE SPARK HENRI CHAMPLIAU CREMANT BRUT 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "700713",
        "Last Price Paid": "$18.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095632",
        "Product Status": "Active"
    },
    {
        "Root": "10003536",
        "LV": "1",
        "Item Description": "COFFEE VIC&ALB DCF 5/1LB/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.30",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003536",
        "Product Status": "Active"
    },
    {
        "Root": "10002047",
        "LV": "1",
        "Item Description": "FLOWER MIXED/ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482064",
        "Last Price Paid": "$0.26",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002047",
        "Product Status": "Active"
    },
    {
        "Root": "10002047",
        "LV": "1",
        "Item Description": "FLOWER MIXED/ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002047",
        "Product Status": "Active"
    },
    {
        "Root": "10091375",
        "LV": "1",
        "Item Description": "FUDGE BROWNIE MIX ADD WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7316490",
        "Last Price Paid": "$5.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091375",
        "Product Status": "Active"
    },
    {
        "Root": "10012384",
        "LV": "1",
        "Item Description": "SHISO MIXED FARMERS BOUNTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14FBSM-2",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012384",
        "Product Status": "Active"
    },
    {
        "Root": "10039976",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE SUGAR FREE 36% ALBA 1/22 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500810000",
        "Last Price Paid": "$261.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039976",
        "Product Status": "Active"
    },
    {
        "Root": "10087510",
        "LV": "1",
        "Item Description": "GLAZE MIRROR SILVER 5KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6460010000",
        "Last Price Paid": "$59.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087510",
        "Product Status": "Active"
    },
    {
        "Root": "10092547",
        "LV": "1",
        "Item Description": "ICING CHOCOLATE SWIRL N FROST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P300",
        "Last Price Paid": "$65.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092547",
        "Product Status": "Active"
    },
    {
        "Root": "10003287",
        "LV": "1",
        "Item Description": "PASTE GUAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.99",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003287",
        "Product Status": "Active"
    },
    {
        "Root": "10027704",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "12305",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027704",
        "Product Status": "Active"
    },
    {
        "Root": "10027506",
        "LV": "1",
        "Item Description": "TRUFFLES WINTER BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSTRU004",
        "Last Price Paid": "$56.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027506",
        "Product Status": "Active"
    },
    {
        "Root": "10005066",
        "LV": "1",
        "Item Description": "GEL LIQUID LEMON YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22503",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005066",
        "Product Status": "Active"
    },
    {
        "Root": "10032427",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC TOKARA ELGIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "156928",
        "Last Price Paid": "$18.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032427",
        "Product Status": "Active"
    },
    {
        "Root": "10026927",
        "LV": "1",
        "Item Description": "TRUFFLE AUSTRALIAN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$58.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026927",
        "Product Status": "Active"
    },
    {
        "Root": "10016321",
        "LV": "1",
        "Item Description": "CUPCAKE MINI RD VEL UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1631",
        "Last Price Paid": "$0.28",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016321",
        "Product Status": "Active"
    },
    {
        "Root": "10001803",
        "LV": "1",
        "Item Description": "PAPAYA RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "801070",
        "Last Price Paid": "$3.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001803",
        "Product Status": "Active"
    },
    {
        "Root": "10001803",
        "LV": "1",
        "Item Description": "PAPAYA RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001803",
        "Product Status": "Active"
    },
    {
        "Root": "10038917",
        "LV": "2",
        "Item Description": "DECOR CHOCOLATE 1.5X3 INCH RECTANGLE ART REQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038917001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "5068CP",
        "Last Price Paid": "$0.73",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10038917",
        "Product Status": "Active"
    },
    {
        "Root": "10003414",
        "LV": "1",
        "Item Description": "SYRUP ORANGE FNTA PM 2.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003414",
        "Product Status": "Active"
    },
    {
        "Root": "10031873",
        "LV": "2",
        "Item Description": "CANDY TOPPING MARSHMALLOW BITS DRY WHITE 20LB/BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031873002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY203",
        "Last Price Paid": "$114.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031873",
        "Product Status": "Active"
    },
    {
        "Root": "10004846",
        "LV": "1",
        "Item Description": "APPLESAUCE FANCY #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6328363",
        "Last Price Paid": "$8.65",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004846",
        "Product Status": "Active"
    },
    {
        "Root": "10005890",
        "LV": "1",
        "Item Description": "BREAD CINNAMON RAISIN 2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005890",
        "Product Status": "Active"
    },
    {
        "Root": "10010100",
        "LV": "1",
        "Item Description": "BEER BOSTN SAM ADAM 12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010100001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010100",
        "Product Status": "Active"
    },
    {
        "Root": "10040894",
        "LV": "2",
        "Item Description": "CUP HOT PT MARY BLAIR 16 OZ 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040894001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$88.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10040894",
        "Product Status": "Active"
    },
    {
        "Root": "10004852",
        "LV": "3",
        "Item Description": "MIX, BATTER PKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004852003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10201",
        "Last Price Paid": "$26.32",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10004852",
        "Product Status": "Active"
    },
    {
        "Root": "10088923",
        "LV": "1",
        "Item Description": "SAUCE CHEESE CHEDDAR POUCH 106OZ / 6 COUNT CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8838058",
        "Last Price Paid": "$8.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088923",
        "Product Status": "Active"
    },
    {
        "Root": "10002627",
        "LV": "3",
        "Item Description": "SPICE BAYLEAF WHOLE 6/1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002627003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00504-7",
        "Last Price Paid": "$2.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002627",
        "Product Status": "Active"
    },
    {
        "Root": "10002627",
        "LV": "3",
        "Item Description": "SPICE BAYLEAF WHOLE 6/1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002627003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002627",
        "Product Status": "Active"
    },
    {
        "Root": "10007775",
        "LV": "1",
        "Item Description": "BLND GUNDLCH BUND MT CUV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9006818",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007775",
        "Product Status": "Active"
    },
    {
        "Root": "10036236",
        "LV": "1",
        "Item Description": "LID CUP DOME PLASTIC WHITE SIP HOLE 8Z 10/100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5167499",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036236",
        "Product Status": "Active"
    },
    {
        "Root": "10102590",
        "LV": "1",
        "Item Description": "BOWL 12OZ SPHERE PLASTIC W/LID 500 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9369694",
        "Last Price Paid": "$0.39",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102590",
        "Product Status": "Active"
    },
    {
        "Root": "10027128",
        "LV": "1",
        "Item Description": "PEPPER SHISHETO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480287",
        "Last Price Paid": "$41.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027128",
        "Product Status": "Active"
    },
    {
        "Root": "10001489",
        "LV": "1",
        "Item Description": "LETTUCE MACHE EPIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$40.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001489",
        "Product Status": "Active"
    },
    {
        "Root": "10001489",
        "LV": "1",
        "Item Description": "LETTUCE MACHE EPIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480415",
        "Last Price Paid": "$40.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001489",
        "Product Status": "Active"
    },
    {
        "Root": "10008302",
        "LV": "1",
        "Item Description": "COOLER SEAGRAM STRW DAIQ 355ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008302",
        "Product Status": "Active"
    },
    {
        "Root": "10041079",
        "LV": "1",
        "Item Description": "BUCKET BASE SENSATIONAL SIX 64OZ 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041079001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041079",
        "Product Status": "Active"
    },
    {
        "Root": "10013628",
        "LV": "1",
        "Item Description": "SALMON VERLASSO 6 OZ CENTER CUT PORTION SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013628001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6485309",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013628",
        "Product Status": "Active"
    },
    {
        "Root": "10004111",
        "LV": "2",
        "Item Description": "COOKIE OREO MINNIES 48/1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004111002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302436",
        "Last Price Paid": "$0.70",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004111",
        "Product Status": "Active"
    },
    {
        "Root": "10010102",
        "LV": "1",
        "Item Description": "BEER MORETTI LAGER 24/CASE.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010102001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "878106",
        "Last Price Paid": "$0.91",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010102",
        "Product Status": "Active"
    },
    {
        "Root": "10004366",
        "LV": "1",
        "Item Description": "SPRAY VELVET DK CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV015C",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004366",
        "Product Status": "Active"
    },
    {
        "Root": "10024639",
        "LV": "1",
        "Item Description": "CALIFORNIA PREMIUM MIXERS LIME MARGARITA BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "168868",
        "Last Price Paid": "$56.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024639",
        "Product Status": "Active"
    },
    {
        "Root": "10003064",
        "LV": "2",
        "Item Description": "WATER VITAMIN ENERGY 20Z 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003064002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003064",
        "Product Status": "Active"
    },
    {
        "Root": "10007081",
        "LV": "1",
        "Item Description": "BORD CH PAPE VIEUX TELEG 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$83.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007081",
        "Product Status": "Active"
    },
    {
        "Root": "10013972",
        "LV": "1",
        "Item Description": "CHARDONNAY MEERLUST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "545888",
        "Last Price Paid": "$20.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013972",
        "Product Status": "Active"
    },
    {
        "Root": "10001079",
        "LV": "1",
        "Item Description": "SEAWEED FRESH 25 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001079001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001079",
        "Product Status": "Active"
    },
    {
        "Root": "10006863",
        "LV": "2",
        "Item Description": "CHARD FRANZIA 3LTR BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006863002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006863",
        "Product Status": "Active"
    },
    {
        "Root": "10004795",
        "LV": "1",
        "Item Description": "NUT ALMOND SLIVERS 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "111100",
        "Last Price Paid": "$105.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004795",
        "Product Status": "Active"
    },
    {
        "Root": "10002089",
        "LV": "1",
        "Item Description": "PAPAYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002089",
        "Product Status": "Active"
    },
    {
        "Root": "10004571",
        "LV": "1",
        "Item Description": "SHELL CANNOLI 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "8490",
        "Last Price Paid": "$0.74",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004571",
        "Product Status": "Active"
    },
    {
        "Root": "10004571",
        "LV": "1",
        "Item Description": "SHELL CANNOLI 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4072674",
        "Last Price Paid": "$0.74",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004571",
        "Product Status": "Active"
    },
    {
        "Root": "10000089",
        "LV": "1",
        "Item Description": "BEEF PORTERHOUSE STEAK 28 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000089001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190085",
        "Last Price Paid": "$19.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000089",
        "Product Status": "Active"
    },
    {
        "Root": "10002487",
        "LV": "1",
        "Item Description": "BEAN VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002487001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450008",
        "Last Price Paid": "$71.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002487",
        "Product Status": "Active"
    },
    {
        "Root": "10002487",
        "LV": "1",
        "Item Description": "BEAN VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002487001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "450008",
        "Last Price Paid": "$71.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002487",
        "Product Status": "Active"
    },
    {
        "Root": "10004733",
        "LV": "1",
        "Item Description": "ANCHOVY IN OIL 28Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8333775",
        "Last Price Paid": "$19.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004733",
        "Product Status": "Active"
    },
    {
        "Root": "10017546",
        "LV": "1",
        "Item Description": "APPLE HONEYCRISP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017546001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "112296",
        "Last Price Paid": "$0.45",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017546",
        "Product Status": "Active"
    },
    {
        "Root": "10091310",
        "LV": "1",
        "Item Description": "WINE RED TESSELAARSDAL PINOT NOIR 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9615301",
        "Last Price Paid": "$37.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091310",
        "Product Status": "Active"
    },
    {
        "Root": "10011565",
        "LV": "1",
        "Item Description": "CROUTON SEASONED CLASSIC 4/2.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011565001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011565",
        "Product Status": "Active"
    },
    {
        "Root": "10091855",
        "LV": "1",
        "Item Description": "SHRIMP 6-8CT GH SHELL ON 6X4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091855",
        "Product Status": "Active"
    },
    {
        "Root": "10085634",
        "LV": "1",
        "Item Description": "DISPERSION MASTER GOLD 50TH ANNIVERSARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "045431501",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085634",
        "Product Status": "Active"
    },
    {
        "Root": "10105108",
        "LV": "1",
        "Item Description": "CUP PINT JR 12OZ FOTH HOL REDEMPTION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510384",
        "Last Price Paid": "",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105108",
        "Product Status": "Active"
    },
    {
        "Root": "10035629",
        "LV": "1",
        "Item Description": "ADRIATIC FIG TAPENADE 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035629",
        "Product Status": "Active"
    },
    {
        "Root": "10016891",
        "LV": "3",
        "Item Description": "BACON SMKD APPLEWOOD SLCD 13/15",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016891001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "420",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10016891",
        "Product Status": "Active"
    },
    {
        "Root": "10018846",
        "LV": "1",
        "Item Description": "DECOR PUMPKIN SUGAR SHIMMER ASST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48167",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018846",
        "Product Status": "Active"
    },
    {
        "Root": "10021842",
        "LV": "1",
        "Item Description": "BOW PRE TIED RED SATIN MK ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52425006",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021842",
        "Product Status": "Active"
    },
    {
        "Root": "10034279",
        "LV": "1",
        "Item Description": "PROSECCO RUFFINO 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034279",
        "Product Status": "Active"
    },
    {
        "Root": "10017367",
        "LV": "1",
        "Item Description": "LETTUCE OAK GREEN BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "482371",
        "Last Price Paid": "$0.69",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017367",
        "Product Status": "Active"
    },
    {
        "Root": "10040956",
        "LV": "2",
        "Item Description": "PUREE PASSIONFRUIT 6/1KG ANDROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040956002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "708137",
        "Last Price Paid": "$11.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040956",
        "Product Status": "Active"
    },
    {
        "Root": "10009885",
        "LV": "1",
        "Item Description": "APERITIF CAMPARI BITR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "980581",
        "Last Price Paid": "$36.59",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009885",
        "Product Status": "Active"
    },
    {
        "Root": "10041076",
        "LV": "1",
        "Item Description": "CHEESE BLUE SMOKEY ROGUE CREAMERY 5.5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041076001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "18084",
        "Last Price Paid": "$17.83",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041076",
        "Product Status": "Active"
    },
    {
        "Root": "10044502",
        "LV": "1",
        "Item Description": "CONTAINER PAPER 6.5IN CATERING BOX INGENERO LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4254020",
        "Last Price Paid": "$1.16",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044502",
        "Product Status": "Active"
    },
    {
        "Root": "10006231",
        "LV": "1",
        "Item Description": "SB LID 16-26 OZ COLD DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11092052",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006231",
        "Product Status": "Active"
    },
    {
        "Root": "10004470",
        "LV": "1",
        "Item Description": "SPAETZLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000316955",
        "Vendor": "GOURMET INTERNATIONAL INC",
        "Vendor Item Nb": "158946",
        "Last Price Paid": "$16.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004470",
        "Product Status": "Active"
    },
    {
        "Root": "10004470",
        "LV": "1",
        "Item Description": "SPAETZLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "508",
        "Last Price Paid": "$16.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004470",
        "Product Status": "Active"
    },
    {
        "Root": "10091475",
        "LV": "1",
        "Item Description": "CHOCOLATE DISC 3\" THIN DARK 1/16\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2098",
        "Last Price Paid": "$0.80",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091475",
        "Product Status": "Active"
    },
    {
        "Root": "10007562",
        "LV": "2",
        "Item Description": "SYRAH PET OLD SOUL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007562001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "309965",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007562",
        "Product Status": "Active"
    },
    {
        "Root": "10086327",
        "LV": "1",
        "Item Description": "COMPOUND PINEAPPLE 1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6900240000",
        "Last Price Paid": "$64.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086327",
        "Product Status": "Active"
    },
    {
        "Root": "10001700",
        "LV": "1",
        "Item Description": "HERB MINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001700001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24110",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001700",
        "Product Status": "Active"
    },
    {
        "Root": "10001700",
        "LV": "1",
        "Item Description": "HERB MINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001700001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482255",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001700",
        "Product Status": "Active"
    },
    {
        "Root": "10001700",
        "LV": "1",
        "Item Description": "HERB MINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001700001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001700",
        "Product Status": "Active"
    },
    {
        "Root": "10039454",
        "LV": "1",
        "Item Description": "CEREAL FROSTED FLAKES BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.35",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039454",
        "Product Status": "Active"
    },
    {
        "Root": "10100613",
        "LV": "1",
        "Item Description": "JUICE LIME NATURAL COLD PRESSED PIUROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100613001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "430700",
        "Last Price Paid": "$7.11",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100613",
        "Product Status": "Active"
    },
    {
        "Root": "10028975",
        "LV": "1",
        "Item Description": "MEAT GOAT WHL 6-WAY CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028975001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1200055",
        "Last Price Paid": "$2.93",
        "Case Pack": "39.996",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028975",
        "Product Status": "Active"
    },
    {
        "Root": "10004639",
        "LV": "1",
        "Item Description": "TORTILLA CORN YLW 6.25 INCH 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4878344",
        "Last Price Paid": "$2.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004639",
        "Product Status": "Active"
    },
    {
        "Root": "10039013",
        "LV": "1",
        "Item Description": "SNACK PEAS GREEN WASABI 22# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "300642",
        "Last Price Paid": "$52.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039013",
        "Product Status": "Active"
    },
    {
        "Root": "10003338",
        "LV": "1",
        "Item Description": "COFFEE GLDN OAK BLND REGU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.32",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003338",
        "Product Status": "Active"
    },
    {
        "Root": "10042501",
        "LV": "1",
        "Item Description": "WINE MAISON NOIR IN SHEEPS CLOTHING CAB SAUV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9350682",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042501",
        "Product Status": "Active"
    },
    {
        "Root": "10002532",
        "LV": "1",
        "Item Description": "YOGURT MIX SOFT SERVE VANILLA FAT FREE 6/.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4022265",
        "Last Price Paid": "$6.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002532",
        "Product Status": "Active"
    },
    {
        "Root": "10001815",
        "LV": "1",
        "Item Description": "FLOWER CONFETTI PETALS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450071",
        "Last Price Paid": "$14.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001815",
        "Product Status": "Active"
    },
    {
        "Root": "10104029",
        "LV": "1",
        "Item Description": "CANDY SKITTLES FREEZE DRIED 10/7.94 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "50961",
        "Last Price Paid": "$4.51",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104029",
        "Product Status": "Active"
    },
    {
        "Root": "10006331",
        "LV": "1",
        "Item Description": "BOX BAKERY HALF SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6177216",
        "Last Price Paid": "$1.32",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006331",
        "Product Status": "Active"
    },
    {
        "Root": "10001387",
        "LV": "1",
        "Item Description": "PASTE HARISSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598795",
        "Last Price Paid": "$20.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001387",
        "Product Status": "Active"
    },
    {
        "Root": "10001387",
        "LV": "1",
        "Item Description": "PASTE HARISSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "598795",
        "Last Price Paid": "$20.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001387",
        "Product Status": "Active"
    },
    {
        "Root": "10091392",
        "LV": "1",
        "Item Description": "SPIRTS GIN ASKUR 45   1LITER 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091392",
        "Product Status": "Active"
    },
    {
        "Root": "10107339",
        "LV": "1",
        "Item Description": "BEER 3 DAUGHTERS OLD FASHIONED 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76544",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107339",
        "Product Status": "Active"
    },
    {
        "Root": "10028706",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON LINE 39 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9193016",
        "Last Price Paid": "$215.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028706",
        "Product Status": "Active"
    },
    {
        "Root": "10021995",
        "LV": "1",
        "Item Description": "MAYO DUKES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9189275",
        "Last Price Paid": "$19.24",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021995",
        "Product Status": "Active"
    },
    {
        "Root": "10000364",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI 1/2 MOON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "17-003",
        "Last Price Paid": "$9.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000364",
        "Product Status": "Active"
    },
    {
        "Root": "10000364",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI 1/2 MOON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490073",
        "Last Price Paid": "$9.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000364",
        "Product Status": "Active"
    },
    {
        "Root": "10006188",
        "LV": "1",
        "Item Description": "CONTAINER CREME BRULE 135",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "1350",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006188",
        "Product Status": "Active"
    },
    {
        "Root": "10036618",
        "LV": "1",
        "Item Description": "MIX SOFT SERVE PUMPKIN 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036618001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036618",
        "Product Status": "Active"
    },
    {
        "Root": "10011760",
        "LV": "1",
        "Item Description": "BEAN ROMANO MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011760001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "20MRAM-26",
        "Last Price Paid": "$82.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011760",
        "Product Status": "Active"
    },
    {
        "Root": "10001923",
        "LV": "1",
        "Item Description": "GRAPE GREEN PREMIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260240",
        "Last Price Paid": "$41.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001923",
        "Product Status": "Active"
    },
    {
        "Root": "10001923",
        "LV": "1",
        "Item Description": "GRAPE GREEN PREMIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$41.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001923",
        "Product Status": "Active"
    },
    {
        "Root": "10016507",
        "LV": "1",
        "Item Description": "PIZZA PEPPERONI PERSONAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "10608",
        "Last Price Paid": "$1.50",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016507",
        "Product Status": "Active"
    },
    {
        "Root": "10023225",
        "LV": "1",
        "Item Description": "BEEF RIB EXPORT 2X2 CAB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023225001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0111812",
        "Last Price Paid": "$11.71",
        "Case Pack": "68",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023225",
        "Product Status": "Active"
    },
    {
        "Root": "10017083",
        "LV": "1",
        "Item Description": "CUPCAKE XTRA LG YLW UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1653",
        "Last Price Paid": "$0.72",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017083",
        "Product Status": "Active"
    },
    {
        "Root": "10016335",
        "LV": "2",
        "Item Description": "CUPCAKE LG YELLOW ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016335001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.08",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016335",
        "Product Status": "Active"
    },
    {
        "Root": "10002727",
        "LV": "1",
        "Item Description": "RICE BASMATI WHITE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002727",
        "Product Status": "Active"
    },
    {
        "Root": "10088150",
        "LV": "1",
        "Item Description": "PASTE HAZELNUT PRALINE 50%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NOE998",
        "Last Price Paid": "$111.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088150",
        "Product Status": "Active"
    },
    {
        "Root": "10100490",
        "LV": "1",
        "Item Description": "BAG W/HANDLE MATTE BLACK 8X4X10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091159",
        "Last Price Paid": "$0.61",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100490",
        "Product Status": "Active"
    },
    {
        "Root": "10038387",
        "LV": "1",
        "Item Description": "BETTER BUTTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "BBUTTER-PB16",
        "Last Price Paid": "$39.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038387",
        "Product Status": "Active"
    },
    {
        "Root": "10003333",
        "LV": "2",
        "Item Description": "SYRUP SNO PASS MANGO 4/1G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003333001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1270H",
        "Last Price Paid": "$10.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003333",
        "Product Status": "Active"
    },
    {
        "Root": "10004179",
        "LV": "1",
        "Item Description": "VINEGAR POWDER 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004179001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "270640",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004179",
        "Product Status": "Active"
    },
    {
        "Root": "10000236",
        "LV": "1",
        "Item Description": "BLUEBERRIES PREMIUM 12/6 OZ FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481534",
        "Last Price Paid": "$7.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000236",
        "Product Status": "Active"
    },
    {
        "Root": "10031428",
        "LV": "2",
        "Item Description": "CHEESE POWDER BLUE HEAVEN ROGUE CREAMERY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031428002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "410111",
        "Last Price Paid": "$86.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031428",
        "Product Status": "Active"
    },
    {
        "Root": "10010146",
        "LV": "2",
        "Item Description": "BEER CLAUSTHALER 12Z NA LNNR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010146001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.16",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010146",
        "Product Status": "Active"
    },
    {
        "Root": "10035708",
        "LV": "1",
        "Item Description": "MONIN YUZU PUREE LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-8248",
        "Last Price Paid": "$12.56",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035708",
        "Product Status": "Active"
    },
    {
        "Root": "10003803",
        "LV": "1",
        "Item Description": "FLOUR AREPA GOYA 6CT 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.11",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003803",
        "Product Status": "Active"
    },
    {
        "Root": "10003798",
        "LV": "1",
        "Item Description": "SPICE SALT SEA HAWAIIAN B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481996",
        "Last Price Paid": "$3.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003798",
        "Product Status": "Active"
    },
    {
        "Root": "10003798",
        "LV": "1",
        "Item Description": "SPICE SALT SEA HAWAIIAN B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "481996",
        "Last Price Paid": "$3.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003798",
        "Product Status": "Active"
    },
    {
        "Root": "10034749",
        "LV": "1",
        "Item Description": "BEEF CLOD HEART SKINNED 2'X2' CHUNK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034749001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2090085",
        "Last Price Paid": "$7.36",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034749",
        "Product Status": "Active"
    },
    {
        "Root": "10014542",
        "LV": "1",
        "Item Description": "TINY TURNIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482561",
        "Last Price Paid": "$0.32",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014542",
        "Product Status": "Active"
    },
    {
        "Root": "10013909",
        "LV": "2",
        "Item Description": "DRAFT TAMPA BAY OLD ELEPHANT FOOT IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013909001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "83602",
        "Last Price Paid": "$191.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10013909",
        "Product Status": "Active"
    },
    {
        "Root": "10013909",
        "LV": "2",
        "Item Description": "DRAFT TAMPA BAY OLD ELEPHANT FOOT IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013909001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "83602",
        "Last Price Paid": "$191.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10013909",
        "Product Status": "Active"
    },
    {
        "Root": "10103290",
        "LV": "1",
        "Item Description": "CIDER WOODCHUCK WINTER CHILL 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "38002",
        "Last Price Paid": "$185.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103290",
        "Product Status": "Active"
    },
    {
        "Root": "10102131",
        "LV": "1",
        "Item Description": "BEER KEG 81 BAY BREW CO HORCHATA 1/2 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15475",
        "Last Price Paid": "$192.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102131",
        "Product Status": "Active"
    },
    {
        "Root": "10038385",
        "LV": "1",
        "Item Description": "POPCORN WHITE CHEDDAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "WCHEDDAR-PB16",
        "Last Price Paid": "$78.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038385",
        "Product Status": "Active"
    },
    {
        "Root": "10023875",
        "LV": "1",
        "Item Description": "APPLE GRANNY SMITH DICED PEELED 2/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3765021",
        "Last Price Paid": "$14.06",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023875",
        "Product Status": "Active"
    },
    {
        "Root": "10017369",
        "LV": "1",
        "Item Description": "MILK COCONUT CHOC SO DELICIOUS DF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "994756",
        "Last Price Paid": "$5.38",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017369",
        "Product Status": "Active"
    },
    {
        "Root": "10031044",
        "LV": "1",
        "Item Description": "CHARDONNAY ZUCCARDI 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "773074",
        "Last Price Paid": "$14.07",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031044",
        "Product Status": "Active"
    },
    {
        "Root": "10033324",
        "LV": "1",
        "Item Description": "CANDY MELLOCREME PUMPKINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "520950",
        "Last Price Paid": "$75.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033324",
        "Product Status": "Active"
    },
    {
        "Root": "10020185",
        "LV": "1",
        "Item Description": "MIX GINGERBREAD COOKIE XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "62063 03",
        "Last Price Paid": "$58.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020185",
        "Product Status": "Active"
    },
    {
        "Root": "10009934",
        "LV": "1",
        "Item Description": "LIQ AMARO MONTENEGRO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9166583",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009934",
        "Product Status": "Active"
    },
    {
        "Root": "10005667",
        "LV": "3",
        "Item Description": "BREAD WHITE SANDWICH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005667001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10005667",
        "Product Status": "Active"
    },
    {
        "Root": "10110014",
        "LV": "1",
        "Item Description": "AMBIANTE TOPPING PB UHT 12/1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110014001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P4100282",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110014",
        "Product Status": "Active"
    },
    {
        "Root": "10109429",
        "LV": "1",
        "Item Description": "FLIGHT DECKONLY RANCOR SWGE 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109429",
        "Product Status": "Active"
    },
    {
        "Root": "10110633",
        "LV": "1",
        "Item Description": "BEER GULF STREAM HOLIDAY SNAKE BITE 1/2 BBL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16628",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110633",
        "Product Status": "Active"
    },
    {
        "Root": "10004914",
        "LV": "1",
        "Item Description": "VINEGAR BLSMC 8YR 8.45OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-00050",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004914",
        "Product Status": "Active"
    },
    {
        "Root": "10032627",
        "LV": "1",
        "Item Description": "GELATO GUANABANA SORBET HALF GALLON TUBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-GUAN-08-CS",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032627",
        "Product Status": "Active"
    },
    {
        "Root": "10087639",
        "LV": "1",
        "Item Description": "PORT COCKBURNS TAWNY 20YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "967090",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087639",
        "Product Status": "Active"
    },
    {
        "Root": "10102343",
        "LV": "1",
        "Item Description": "WINE FATTORIA POGGIO ALLORO VIN SANTO DEL 375 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102343",
        "Product Status": "Active"
    },
    {
        "Root": "10004828",
        "LV": "3",
        "Item Description": "EGG REPLACEMENT POWDER 12/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004828003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "{ENERG'",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10004828",
        "Product Status": "Active"
    },
    {
        "Root": "GENE004",
        "LV": "1",
        "Item Description": "GENERIC ARTICLE BEARING THE PACKING VARIANCES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "GENE004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "GENE004",
        "Product Status": "Active"
    },
    {
        "Root": "10084335",
        "LV": "1",
        "Item Description": "CANDY MY M&MS LIGHT GREEN 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "10466",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084335",
        "Product Status": "Active"
    },
    {
        "Root": "10033596",
        "LV": "1",
        "Item Description": "CHARDONNAY CAKEBREAD RESERVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "203589",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033596",
        "Product Status": "Active"
    },
    {
        "Root": "10007889",
        "LV": "1",
        "Item Description": "SHIRAZ JAM JAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "445443",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007889",
        "Product Status": "Active"
    },
    {
        "Root": "10007889",
        "LV": "1",
        "Item Description": "SHIRAZ JAM JAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "445443",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007889",
        "Product Status": "Active"
    },
    {
        "Root": "10015346",
        "LV": "1",
        "Item Description": "CANDY CORN BULK 30LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550405",
        "Last Price Paid": "$75.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015346",
        "Product Status": "Active"
    },
    {
        "Root": "10018473",
        "LV": "1",
        "Item Description": "CUITLACOCHE (CORN SMUT) 7.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018473001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "188140",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018473",
        "Product Status": "Active"
    },
    {
        "Root": "10039777",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN STEAK READY 1\" BLOCK TAIL - AUS ST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039777001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195053",
        "Last Price Paid": "$19.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039777",
        "Product Status": "Active"
    },
    {
        "Root": "10107808",
        "LV": "1",
        "Item Description": "GELATO SOFT SERVE PICKLE 6/0.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-PICK-11-CS6",
        "Last Price Paid": "$12.07",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107808",
        "Product Status": "Active"
    },
    {
        "Root": "10000230",
        "LV": "1",
        "Item Description": "MANGO SPEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000230001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-015-SP",
        "Last Price Paid": "$3.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000230",
        "Product Status": "Active"
    },
    {
        "Root": "10000668",
        "LV": "1",
        "Item Description": "BEEF STRIPLOIN 0X1 CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000668001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0000017",
        "Last Price Paid": "$7.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000668",
        "Product Status": "Active"
    },
    {
        "Root": "10005589",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT CHOCOLATINE 2.8Z PRE-PROOFED 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.70",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005589",
        "Product Status": "Active"
    },
    {
        "Root": "10025067",
        "LV": "1",
        "Item Description": "BEER ELYSIAN SPACE DUCT IPA 12 OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "44318",
        "Last Price Paid": "$1.71",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025067",
        "Product Status": "Active"
    },
    {
        "Root": "10007644",
        "LV": "1",
        "Item Description": "GARGANEGA INAMA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007644001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "156584",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007644",
        "Product Status": "Active"
    },
    {
        "Root": "10039136",
        "LV": "1",
        "Item Description": "PUREE MANGO 100% FROZEN 12/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6551290000",
        "Last Price Paid": "$12.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039136",
        "Product Status": "Active"
    },
    {
        "Root": "10041051",
        "LV": "1",
        "Item Description": "CHEESE GOUDA LAMB CHOPPER SHEEP CYPRESS GROVE 9 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041051001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "39499",
        "Last Price Paid": "$19.23",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041051",
        "Product Status": "Active"
    },
    {
        "Root": "10085631",
        "LV": "1",
        "Item Description": "DEC CHOC WHITE 2IN DISC W/EDIBLE ART",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW0350",
        "Last Price Paid": "$0.75",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085631",
        "Product Status": "Active"
    },
    {
        "Root": "10005853",
        "LV": "1",
        "Item Description": "CAKE CHOC INDIVIDUAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005853",
        "Product Status": "Active"
    },
    {
        "Root": "10033994",
        "LV": "1",
        "Item Description": "CAULILINI SWEET STEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480993",
        "Last Price Paid": "$9.79",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033994",
        "Product Status": "Active"
    },
    {
        "Root": "10001759",
        "LV": "1",
        "Item Description": "TOMATO GRAPE YELLOW 8#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001759001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410421",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001759",
        "Product Status": "Active"
    },
    {
        "Root": "10001759",
        "LV": "1",
        "Item Description": "TOMATO GRAPE YELLOW 8#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001759001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001759",
        "Product Status": "Active"
    },
    {
        "Root": "10016563",
        "LV": "1",
        "Item Description": "SAUCE FISH BLIS AGED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016563001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO195",
        "Last Price Paid": "$18.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016563",
        "Product Status": "Active"
    },
    {
        "Root": "10032255",
        "LV": "1",
        "Item Description": "BARILLA LASAGNA OVEN-READY 399 12/9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "BR399",
        "Last Price Paid": "$2.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032255",
        "Product Status": "Active"
    },
    {
        "Root": "10033488",
        "LV": "1",
        "Item Description": "SPRAY WHITE COCOA BUTTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586001",
        "Last Price Paid": "$51.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033488",
        "Product Status": "Active"
    },
    {
        "Root": "10015265",
        "LV": "1",
        "Item Description": "SAUSAGE SUB, VEGETARIAN FIELD ROAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1161884",
        "Last Price Paid": "$58.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015265",
        "Product Status": "Active"
    },
    {
        "Root": "10003792",
        "LV": "1",
        "Item Description": "GIARDINIERA 2 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003792001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5309026",
        "Last Price Paid": "$24.99",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003792",
        "Product Status": "Active"
    },
    {
        "Root": "10014066",
        "LV": "2",
        "Item Description": "POWDER DEC COPPER SATIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014066002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "314005E",
        "Last Price Paid": "$22.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014066",
        "Product Status": "Active"
    },
    {
        "Root": "10014066",
        "LV": "2",
        "Item Description": "POWDER DEC COPPER SATIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014066002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586037",
        "Last Price Paid": "$22.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014066",
        "Product Status": "Active"
    },
    {
        "Root": "10002401",
        "LV": "1",
        "Item Description": "CHEESE GRUYERE SWITZERLAND 6 LB PIECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002401001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "3920",
        "Last Price Paid": "$10.63",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002401",
        "Product Status": "Active"
    },
    {
        "Root": "10012004",
        "LV": "1",
        "Item Description": "BEEF RIBEYE LIP-ON 15.5# DN CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012004001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0000011",
        "Last Price Paid": "$13.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012004",
        "Product Status": "Active"
    },
    {
        "Root": "10082058",
        "LV": "1",
        "Item Description": "SPIRIT LIQUEUR BOYD & BLAIR POMEGRANATE LTR 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "593634",
        "Last Price Paid": "$22.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082058",
        "Product Status": "Active"
    },
    {
        "Root": "10012959",
        "LV": "1",
        "Item Description": "HERB,PETITE,FINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04PFH-33-S",
        "Last Price Paid": "$30.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012959",
        "Product Status": "Active"
    },
    {
        "Root": "10016954",
        "LV": "1",
        "Item Description": "SPICE CARDAMON GRND 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016954001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3807137",
        "Last Price Paid": "$44.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016954",
        "Product Status": "Active"
    },
    {
        "Root": "10044396",
        "LV": "1",
        "Item Description": "PASTRY, EPCOT, KARIDOPITA 40/4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8651931",
        "Last Price Paid": "$1.04",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044396",
        "Product Status": "Active"
    },
    {
        "Root": "10003201",
        "LV": "1",
        "Item Description": "SYRUP COFF ROSE MONIN 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003201001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003201",
        "Product Status": "Active"
    },
    {
        "Root": "10107891",
        "LV": "1",
        "Item Description": "ROAST BEEF HOAGIE HURRICANE BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073560",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107891",
        "Product Status": "Active"
    },
    {
        "Root": "10003830",
        "LV": "1",
        "Item Description": "SALT CYPRUSS BLACK 1# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD153",
        "Last Price Paid": "$35.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003830",
        "Product Status": "Active"
    },
    {
        "Root": "10001370",
        "LV": "1",
        "Item Description": "SEA CRESS MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MSC-33-E",
        "Last Price Paid": "$37.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001370",
        "Product Status": "Active"
    },
    {
        "Root": "10004611",
        "LV": "1",
        "Item Description": "MUSTARD DIJON 5KILO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4537593",
        "Last Price Paid": "$18.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004611",
        "Product Status": "Active"
    },
    {
        "Root": "10000246",
        "LV": "1",
        "Item Description": "ONION SPRING BBQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "27050",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000246",
        "Product Status": "Active"
    },
    {
        "Root": "10000246",
        "LV": "1",
        "Item Description": "ONION SPRING BBQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VO111",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000246",
        "Product Status": "Active"
    },
    {
        "Root": "10001911",
        "LV": "1",
        "Item Description": "PASSION FRUIT 36/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16181",
        "Last Price Paid": "$2.33",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001911",
        "Product Status": "Active"
    },
    {
        "Root": "10001911",
        "LV": "1",
        "Item Description": "PASSION FRUIT 36/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.33",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001911",
        "Product Status": "Active"
    },
    {
        "Root": "10001911",
        "LV": "1",
        "Item Description": "PASSION FRUIT 36/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390000",
        "Last Price Paid": "$2.33",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001911",
        "Product Status": "Active"
    },
    {
        "Root": "10100772",
        "LV": "1",
        "Item Description": "CUCUMBER, DEMI 20 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05DCU-33",
        "Last Price Paid": "$1.13",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100772",
        "Product Status": "Active"
    },
    {
        "Root": "10033913",
        "LV": "1",
        "Item Description": "QUINS-CONFETTI-AUTUMN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48293",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033913",
        "Product Status": "Active"
    },
    {
        "Root": "10001855",
        "LV": "1",
        "Item Description": "HORSERADISH ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001855001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001855",
        "Product Status": "Active"
    },
    {
        "Root": "10040126",
        "LV": "1",
        "Item Description": "TRANSFER SHEET MAGICAL TREE 10/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "901030C",
        "Last Price Paid": "$6.38",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040126",
        "Product Status": "Active"
    },
    {
        "Root": "10000294",
        "LV": "1",
        "Item Description": "LETTUCE CHOP W/COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403522",
        "Last Price Paid": "$6.70",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000294",
        "Product Status": "Active"
    },
    {
        "Root": "10110138",
        "LV": "1",
        "Item Description": "LIQUID EGG CAGE FREE 2/20LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.60",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110138",
        "Product Status": "Active"
    },
    {
        "Root": "10004477",
        "LV": "1",
        "Item Description": "SUSHI NIGIRI TUNA 1OZ/EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004477001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004477",
        "Product Status": "Active"
    },
    {
        "Root": "10000895",
        "LV": "1",
        "Item Description": "DUCK LEG & THIGH 8Z-12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000895001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4301312",
        "Last Price Paid": "$7.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000895",
        "Product Status": "Active"
    },
    {
        "Root": "10038817",
        "LV": "1",
        "Item Description": "MUFFIN DOUBLE CHOCOLATE LARGE 6.5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038817",
        "Product Status": "Active"
    },
    {
        "Root": "10039997",
        "LV": "1",
        "Item Description": "SALT POPCORN BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2422",
        "Last Price Paid": "$22.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039997",
        "Product Status": "Active"
    },
    {
        "Root": "10076281",
        "LV": "1",
        "Item Description": "COTTON CANDY FUN SWEETS 2 OZ TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076281001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "9762",
        "Last Price Paid": "$1.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076281",
        "Product Status": "Active"
    },
    {
        "Root": "10034784",
        "LV": "1",
        "Item Description": "SQUASH HONEYNUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034784001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "474717",
        "Last Price Paid": "$45.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034784",
        "Product Status": "Active"
    },
    {
        "Root": "10033599",
        "LV": "2",
        "Item Description": "SUGAR CUBES MICKEY WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033599001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "49740",
        "Last Price Paid": "$0.05",
        "Case Pack": "2016",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10033599",
        "Product Status": "Active"
    },
    {
        "Root": "10001230",
        "LV": "1",
        "Item Description": "CRAB CLAW STONE JUMBO SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001230001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "4208161",
        "Last Price Paid": "$31.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001230",
        "Product Status": "Active"
    },
    {
        "Root": "10013453",
        "LV": "1",
        "Item Description": "SALMON ALASKA WHOLE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013453001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-00869",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013453",
        "Product Status": "Active"
    },
    {
        "Root": "10032647",
        "LV": "1",
        "Item Description": "HAZELNUT BUTTER NUTELLA CHOCOLATE POUCH 6/35.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032647001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5724603",
        "Last Price Paid": "$7.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032647",
        "Product Status": "Active"
    },
    {
        "Root": "10028109",
        "LV": "1",
        "Item Description": "DRAFT KONA HANALEI ISLAND IPA 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27506",
        "Last Price Paid": "$64.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028109",
        "Product Status": "Active"
    },
    {
        "Root": "10029951",
        "LV": "2",
        "Item Description": "SUGAR SNOWFLAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029951002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "19068",
        "Last Price Paid": "$0.13",
        "Case Pack": "135",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029951",
        "Product Status": "Active"
    },
    {
        "Root": "10107873",
        "LV": "1",
        "Item Description": "YOGURT DIPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073461",
        "Last Price Paid": "$3.56",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107873",
        "Product Status": "Active"
    },
    {
        "Root": "10091900",
        "LV": "1",
        "Item Description": "HONEY MUSTARD CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.25",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091900",
        "Product Status": "Active"
    },
    {
        "Root": "10007842",
        "LV": "2",
        "Item Description": "PINOT NOIR EDNA VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007842001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007842",
        "Product Status": "Active"
    },
    {
        "Root": "10000827",
        "LV": "2",
        "Item Description": "CHICK CUT MARN 8 PC 2.25-2.5 #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000827001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000827",
        "Product Status": "Active"
    },
    {
        "Root": "10003496",
        "LV": "2",
        "Item Description": "TEA IMPRL ORNG PEKOE 14.1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003496001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3550",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003496",
        "Product Status": "Active"
    },
    {
        "Root": "10100765",
        "LV": "1",
        "Item Description": "BEAN FRESH CRANBERRY 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100765001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100765",
        "Product Status": "Active"
    },
    {
        "Root": "10038542",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN EDITION NO 5 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038542",
        "Product Status": "Active"
    },
    {
        "Root": "10006155",
        "LV": "2",
        "Item Description": "STRAW/NOZZLE NESPRESSO MA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006155001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006155",
        "Product Status": "Active"
    },
    {
        "Root": "10110919",
        "LV": "1",
        "Item Description": "WINE MASSOLINO BARALO 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9684758",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110919",
        "Product Status": "Active"
    },
    {
        "Root": "10036951",
        "LV": "1",
        "Item Description": "SB TOPPING CARAMEL RIBBON CRUNCH 6OZ SHAKER 8 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036951",
        "Product Status": "Active"
    },
    {
        "Root": "10011300",
        "LV": "1",
        "Item Description": "DRAFT ALE SEADOG SUNFISH 1/2 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "92602",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011300",
        "Product Status": "Active"
    },
    {
        "Root": "10084119",
        "LV": "1",
        "Item Description": "DONUT ORIGINAL GLAZED DZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084119001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084119",
        "Product Status": "Active"
    },
    {
        "Root": "10004943",
        "LV": "1",
        "Item Description": "GLAZE READY BRIANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "9    {PURATOS{",
        "Last Price Paid": "$54.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004943",
        "Product Status": "Active"
    },
    {
        "Root": "10002779",
        "LV": "1",
        "Item Description": "SYRUP COKE ZERO 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$96.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002779",
        "Product Status": "Active"
    },
    {
        "Root": "10092220",
        "LV": "1",
        "Item Description": "CUP  2OZ CLEAR MARTINI GLASS (PS) 120 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092220001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092220",
        "Product Status": "Active"
    },
    {
        "Root": "10000356",
        "LV": "1",
        "Item Description": "CELERY STICKS 4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-006-B",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000356",
        "Product Status": "Active"
    },
    {
        "Root": "10000356",
        "LV": "1",
        "Item Description": "CELERY STICKS 4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "442356",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000356",
        "Product Status": "Active"
    },
    {
        "Root": "10005962",
        "LV": "1",
        "Item Description": "PORK GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005962001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1425345",
        "Last Price Paid": "$3.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005962",
        "Product Status": "Active"
    },
    {
        "Root": "10001275",
        "LV": "2",
        "Item Description": "JUICE MM LOW PULP 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001275002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001275",
        "Product Status": "Active"
    },
    {
        "Root": "10021800",
        "LV": "1",
        "Item Description": "CAKE SHEET SPONGE VANILLA 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021800001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1662",
        "Last Price Paid": "$10.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021800",
        "Product Status": "Active"
    },
    {
        "Root": "10012484",
        "LV": "2",
        "Item Description": "BUTTERFLIES ASST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012484001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "13517",
        "Last Price Paid": "$0.63",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10012484",
        "Product Status": "Active"
    },
    {
        "Root": "10036193",
        "LV": "1",
        "Item Description": "DRAFT 3 DAUGHTERS HARD ROSE CIDER 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "61702",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036193",
        "Product Status": "Active"
    },
    {
        "Root": "10036193",
        "LV": "1",
        "Item Description": "DRAFT 3 DAUGHTERS HARD ROSE CIDER 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "61702",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036193",
        "Product Status": "Active"
    },
    {
        "Root": "10015454",
        "LV": "1",
        "Item Description": "FLAVORING WHT CHEDDAR CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2383",
        "Last Price Paid": "$34.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015454",
        "Product Status": "Active"
    },
    {
        "Root": "10006789",
        "LV": "1",
        "Item Description": "TOWEL INDIVIDUALLY WRAPPED PRE MOISTENEDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51385003",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006789",
        "Product Status": "Active"
    },
    {
        "Root": "10109549",
        "LV": "1",
        "Item Description": "BEER KEG MOOSEHEAD LAGER 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$149.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109549",
        "Product Status": "Active"
    },
    {
        "Root": "10010027",
        "LV": "2",
        "Item Description": "DRAFT MILLER LITE HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010027001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10030",
        "Last Price Paid": "$93.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010027",
        "Product Status": "Active"
    },
    {
        "Root": "10012498",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-RED-1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22904",
        "Last Price Paid": "$0.23",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012498",
        "Product Status": "Active"
    },
    {
        "Root": "10022347",
        "LV": "1",
        "Item Description": "TURKEY BLK WHL HERITAGE 16 LB JOYCE FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022347001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4129111",
        "Last Price Paid": "$6.12",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022347",
        "Product Status": "Active"
    },
    {
        "Root": "10090542",
        "LV": "1",
        "Item Description": "MIX SMOOTHIE MM PRM PINA COLADA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "2500008220",
        "Last Price Paid": "$5.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090542",
        "Product Status": "Active"
    },
    {
        "Root": "10010609",
        "LV": "3",
        "Item Description": "FIREBALL CINNAMON WHISKY 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010609001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9031408",
        "Last Price Paid": "$5.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10010609",
        "Product Status": "Active"
    },
    {
        "Root": "10106372",
        "LV": "1",
        "Item Description": "BUTTER, UNSALTED, AOC, ISIGNY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "BUT007",
        "Last Price Paid": "$4.67",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106372",
        "Product Status": "Active"
    },
    {
        "Root": "10027315",
        "LV": "1",
        "Item Description": "DRAFT MO ARA HIGH COUNTRY ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14069",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027315",
        "Product Status": "Active"
    },
    {
        "Root": "10000634",
        "LV": "1",
        "Item Description": "HAM SMOKED BLACK FOREST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000634001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "130",
        "Last Price Paid": "$4.58",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000634",
        "Product Status": "Active"
    },
    {
        "Root": "10031757",
        "LV": "1",
        "Item Description": "CHARDONNAY EOS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "576346",
        "Last Price Paid": "$6.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031757",
        "Product Status": "Active"
    },
    {
        "Root": "10005865",
        "LV": "1",
        "Item Description": "MUFFIN LEMON POPPY LARGE (6.5 OZ) INDV WRAP 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005865",
        "Product Status": "Active"
    },
    {
        "Root": "10108142",
        "LV": "1",
        "Item Description": "SAUCE HAMBURGER MILD PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1020520",
        "Last Price Paid": "$115.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108142",
        "Product Status": "Active"
    },
    {
        "Root": "10108289",
        "LV": "1",
        "Item Description": "ONION RED SLCD PICKLED 3/6.61 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1024244",
        "Last Price Paid": "$15.34",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108289",
        "Product Status": "Active"
    },
    {
        "Root": "10004467",
        "LV": "1",
        "Item Description": "MIX FREESTYLE STRAWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3555873",
        "Last Price Paid": "$16.65",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004467",
        "Product Status": "Active"
    },
    {
        "Root": "10041507",
        "LV": "1",
        "Item Description": "POLENTA COARSE ITALIAN STYLE YELLO CORNMEAL 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8544355",
        "Last Price Paid": "$19.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041507",
        "Product Status": "Active"
    },
    {
        "Root": "10009894",
        "LV": "3",
        "Item Description": "PORT TAYLOR 1ST EST FLADGATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009894002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273704-A",
        "Last Price Paid": "$1.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009894",
        "Product Status": "Active"
    },
    {
        "Root": "10014105",
        "LV": "1",
        "Item Description": "CHEESE, CHEDDAR VEGAN SLCD DAIYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014105001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3DFU20-112011",
        "Last Price Paid": "$3.45",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014105",
        "Product Status": "Active"
    },
    {
        "Root": "10029168",
        "LV": "1",
        "Item Description": "TEA CHAMOMILE 6/25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F09178",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029168",
        "Product Status": "Active"
    },
    {
        "Root": "10110135",
        "LV": "1",
        "Item Description": "CONTAINER HINGED 4 SECT HINGED TAMPER EVIDENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51357095",
        "Last Price Paid": "$0.34",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110135",
        "Product Status": "Active"
    },
    {
        "Root": "10000837",
        "LV": "1",
        "Item Description": "TURKEY BRST SKIN ON RAW FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000837001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "100761",
        "Last Price Paid": "$3.58",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000837",
        "Product Status": "Active"
    },
    {
        "Root": "10040002",
        "LV": "1",
        "Item Description": "BREAD TEXAS TOAST GARLIC 1IN SLICED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040002001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1936536",
        "Last Price Paid": "$0.36",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040002",
        "Product Status": "Active"
    },
    {
        "Root": "10000189",
        "LV": "1",
        "Item Description": "LETTUCE ROMAINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482054",
        "Last Price Paid": "$1.70",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000189",
        "Product Status": "Active"
    },
    {
        "Root": "10039299",
        "LV": "1",
        "Item Description": "SHEET TRANSFER DALMATION BLACK PRINT 185/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039299001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65603",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039299",
        "Product Status": "Active"
    },
    {
        "Root": "10032646",
        "LV": "1",
        "Item Description": "SEASONING TAJIN CHILI BLEND GROUND 12/5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5808222",
        "Last Price Paid": "$2.55",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032646",
        "Product Status": "Active"
    },
    {
        "Root": "10014065",
        "LV": "2",
        "Item Description": "COCOA BUTTER SUNRISE ORNG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014065002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302025C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014065",
        "Product Status": "Active"
    },
    {
        "Root": "10014065",
        "LV": "2",
        "Item Description": "COCOA BUTTER SUNRISE ORNG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014065002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5863090000",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014065",
        "Product Status": "Active"
    },
    {
        "Root": "10039824",
        "LV": "1",
        "Item Description": "ICE CUBES 2 INCH 200/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609904",
        "Vendor": "ICE PROFESSIONALS OF FLORIDA LLC",
        "Vendor Item Nb": "DWCB-2-200",
        "Last Price Paid": "$0.70",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039824",
        "Product Status": "Active"
    },
    {
        "Root": "10104088",
        "LV": "1",
        "Item Description": "COLOR CB VLV SPRAY AMETHYST 13.5 FL OZ (400 ML)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860720000",
        "Last Price Paid": "$53.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104088",
        "Product Status": "Active"
    },
    {
        "Root": "10032928",
        "LV": "1",
        "Item Description": "FLOUR RICE 24/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "118089",
        "Last Price Paid": "$3.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032928",
        "Product Status": "Active"
    },
    {
        "Root": "10000672",
        "LV": "1",
        "Item Description": "BEEF PATTY RETAIL 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1070134",
        "Last Price Paid": "$1.03",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000672",
        "Product Status": "Active"
    },
    {
        "Root": "10093508",
        "LV": "1",
        "Item Description": "BOOK DAK RESTO KIDS SO COOL DINO 240/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.38",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093508",
        "Product Status": "Active"
    },
    {
        "Root": "10003361",
        "LV": "2",
        "Item Description": "LENTILS BLCK BELG 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003361002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5958616",
        "Last Price Paid": "$78.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003361",
        "Product Status": "Active"
    },
    {
        "Root": "10107173",
        "LV": "2",
        "Item Description": "CUP DISNEY APC16 16OZ PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107173001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$78.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10107173",
        "Product Status": "Active"
    },
    {
        "Root": "10001697",
        "LV": "1",
        "Item Description": "HERB CILANTRO/CORIANDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001697001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21010",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001697",
        "Product Status": "Active"
    },
    {
        "Root": "10001697",
        "LV": "1",
        "Item Description": "HERB CILANTRO/CORIANDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001697001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598199",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001697",
        "Product Status": "Active"
    },
    {
        "Root": "10001697",
        "LV": "1",
        "Item Description": "HERB CILANTRO/CORIANDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001697001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "598199",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001697",
        "Product Status": "Active"
    },
    {
        "Root": "10034303",
        "LV": "1",
        "Item Description": "SUMAC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "163884",
        "Last Price Paid": "$5.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034303",
        "Product Status": "Active"
    },
    {
        "Root": "10000487",
        "LV": "1",
        "Item Description": "BISCUIT SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000487001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.64",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000487",
        "Product Status": "Active"
    },
    {
        "Root": "10008159",
        "LV": "1",
        "Item Description": "ROSE TAVEL CHAT DAQUERIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "223049",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008159",
        "Product Status": "Active"
    },
    {
        "Root": "10032388",
        "LV": "1",
        "Item Description": "DRAFT BECKS 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "42502",
        "Last Price Paid": "$136.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032388",
        "Product Status": "Active"
    },
    {
        "Root": "10004818",
        "LV": "1",
        "Item Description": "OLIVE NICOISE BARNIERS 6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090310",
        "Last Price Paid": "$31.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004818",
        "Product Status": "Active"
    },
    {
        "Root": "10108288",
        "LV": "1",
        "Item Description": "SCALLOPS U-10 DRY N. ATLANTIC IQF 10LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108288001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6500052",
        "Last Price Paid": "$26.09",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108288",
        "Product Status": "Active"
    },
    {
        "Root": "10094782",
        "LV": "1",
        "Item Description": "CRAB CLUSTERS ALASKAN GOLDEN KING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094782001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "78-30155",
        "Last Price Paid": "$43.75",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094782",
        "Product Status": "Active"
    },
    {
        "Root": "10002708",
        "LV": "1",
        "Item Description": "MIX YELLOW CAKE 50 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$89.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002708",
        "Product Status": "Active"
    },
    {
        "Root": "10007146",
        "LV": "1",
        "Item Description": "SAKE WILDFLOWERS JUNMAI 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "493932",
        "Last Price Paid": "$18.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007146",
        "Product Status": "Active"
    },
    {
        "Root": "10104649",
        "LV": "1",
        "Item Description": "CHOPSTICK WOOD 8\" WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8375396",
        "Last Price Paid": "$0.02",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104649",
        "Product Status": "Active"
    },
    {
        "Root": "10029327",
        "LV": "1",
        "Item Description": "SOUP MINESTRONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4633186",
        "Last Price Paid": "$46.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029327",
        "Product Status": "Active"
    },
    {
        "Root": "10104593",
        "LV": "1",
        "Item Description": "PASSOVER BSC PLAIN OMELET WITH POTATO 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104593",
        "Product Status": "Active"
    },
    {
        "Root": "10042535",
        "LV": "1",
        "Item Description": "WINE WHITE SPARKLING ROSE MCBRIDE SISTERS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "980708",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042535",
        "Product Status": "Active"
    },
    {
        "Root": "10042535",
        "LV": "1",
        "Item Description": "WINE WHITE SPARKLING ROSE MCBRIDE SISTERS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "980708",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042535",
        "Product Status": "Active"
    },
    {
        "Root": "10101929",
        "LV": "1",
        "Item Description": "WHISKEY CROWN ROYAL PEACH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "930569",
        "Last Price Paid": "$24.62",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101929",
        "Product Status": "Active"
    },
    {
        "Root": "10100710",
        "LV": "1",
        "Item Description": "SAUCE TUTTOROSSO MARINARA FRESH-PACK 6/#10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100710",
        "Product Status": "Active"
    },
    {
        "Root": "10088462",
        "LV": "1",
        "Item Description": "CRACKER GRAHAM 2PK IW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2007540",
        "Last Price Paid": "$22.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088462",
        "Product Status": "Active"
    },
    {
        "Root": "10013665",
        "LV": "2",
        "Item Description": "POWERADE PWDR MTN BLAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013665002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "151969",
        "Last Price Paid": "$6.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013665",
        "Product Status": "Active"
    },
    {
        "Root": "10019726",
        "LV": "1",
        "Item Description": "VINEGAR BALSAMIC 12 YR AFFINATO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN053",
        "Last Price Paid": "$73.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019726",
        "Product Status": "Active"
    },
    {
        "Root": "10019905",
        "LV": "1",
        "Item Description": "SANTA W/YELLOW BELL XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "YO-1050",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019905",
        "Product Status": "Active"
    },
    {
        "Root": "10038393",
        "LV": "1",
        "Item Description": "ARROWHEAD MILLS PUFFED RICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "26640",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038393",
        "Product Status": "Active"
    },
    {
        "Root": "10089291",
        "LV": "1",
        "Item Description": "TEQUILA CASA NOBLE ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992947",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089291",
        "Product Status": "Active"
    },
    {
        "Root": "10105949",
        "LV": "1",
        "Item Description": "CANDY M&M'S MILK CHOC. EASTER 12/38 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "31294",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105949",
        "Product Status": "Active"
    },
    {
        "Root": "10018856",
        "LV": "1",
        "Item Description": "POWDER DECOR PEARL PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11066",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018856",
        "Product Status": "Active"
    },
    {
        "Root": "10093051",
        "LV": "1",
        "Item Description": "GLAZE APRIGEL FIRM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "HE1006",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093051",
        "Product Status": "Active"
    },
    {
        "Root": "10034298",
        "LV": "2",
        "Item Description": "RTD BLOODY MARY BLUE MARBLE 200ML 24PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034298002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "BMP-BLOODY-200",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034298",
        "Product Status": "Active"
    },
    {
        "Root": "10007329",
        "LV": "2",
        "Item Description": "CAB SAUV CADE HWL MTN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007329001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "57613",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007329",
        "Product Status": "Active"
    },
    {
        "Root": "10110889",
        "LV": "1",
        "Item Description": "TRAY FOOD #300 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110889",
        "Product Status": "Active"
    },
    {
        "Root": "10004993",
        "LV": "1",
        "Item Description": "OIL OLIVE E/V 32OZ/1EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004993001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004993",
        "Product Status": "Active"
    },
    {
        "Root": "10110075",
        "LV": "1",
        "Item Description": "SB SNOWMAN COOKIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110075001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108104",
        "Last Price Paid": "$1.50",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110075",
        "Product Status": "Active"
    },
    {
        "Root": "10109277",
        "LV": "1",
        "Item Description": "DISNEY HOLIDAY ORNAMT MICKEY 3.5X3.5CM 3000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175960000",
        "Last Price Paid": "$0.14",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109277",
        "Product Status": "Active"
    },
    {
        "Root": "10100910",
        "LV": "1",
        "Item Description": "GARLIC FLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22002",
        "Last Price Paid": "$0.40",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100910",
        "Product Status": "Active"
    },
    {
        "Root": "10011280",
        "LV": "1",
        "Item Description": "WHEAT FARRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8647323",
        "Last Price Paid": "$8.37",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011280",
        "Product Status": "Active"
    },
    {
        "Root": "10038927",
        "LV": "3",
        "Item Description": "DECOR CHOCOLATE 2.5 INCH DISC ART REQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038927002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "228CP",
        "Last Price Paid": "$0.96",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10038927",
        "Product Status": "Active"
    },
    {
        "Root": "10015208",
        "LV": "1",
        "Item Description": "CRACKER GF MULTIGRAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "40004",
        "Last Price Paid": "$2.81",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015208",
        "Product Status": "Active"
    },
    {
        "Root": "10005780",
        "LV": "1",
        "Item Description": "CAKE ROUND 6IN, VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "7",
        "Last Price Paid": "$58.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005780",
        "Product Status": "Active"
    },
    {
        "Root": "10032026",
        "LV": "1",
        "Item Description": "BREAD WHITE GLUTEN FREE 14 SLICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8574943",
        "Last Price Paid": "$8.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032026",
        "Product Status": "Active"
    },
    {
        "Root": "10007523",
        "LV": "2",
        "Item Description": "SATINELLA MARQ CACER RIOJA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007523001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2265742",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007523",
        "Product Status": "Active"
    },
    {
        "Root": "10002105",
        "LV": "1",
        "Item Description": "HERB BASIL FRZ CHPD 4/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002105001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3500212",
        "Last Price Paid": "$4.27",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002105",
        "Product Status": "Active"
    },
    {
        "Root": "10039840",
        "LV": "1",
        "Item Description": "MIX SOFT SERVE PISTACHIO 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.85",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039840",
        "Product Status": "Active"
    },
    {
        "Root": "10004891",
        "LV": "1",
        "Item Description": "LASAGNA MEAT FRZN STOUFFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6326300",
        "Last Price Paid": "$24.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004891",
        "Product Status": "Active"
    },
    {
        "Root": "10001765",
        "LV": "1",
        "Item Description": "PEAR ASIAN/CHINESE APPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "299915",
        "Last Price Paid": "$30.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001765",
        "Product Status": "Active"
    },
    {
        "Root": "10001765",
        "LV": "1",
        "Item Description": "PEAR ASIAN/CHINESE APPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "401266",
        "Last Price Paid": "$30.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001765",
        "Product Status": "Active"
    },
    {
        "Root": "10001765",
        "LV": "1",
        "Item Description": "PEAR ASIAN/CHINESE APPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "11100",
        "Last Price Paid": "$30.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001765",
        "Product Status": "Active"
    },
    {
        "Root": "10004597",
        "LV": "1",
        "Item Description": "VINEGAR CAB SAU 2/5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090550",
        "Last Price Paid": "$14.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004597",
        "Product Status": "Active"
    },
    {
        "Root": "10004796",
        "LV": "1",
        "Item Description": "SAUCE TABASCO 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004796001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7003932",
        "Last Price Paid": "$5.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004796",
        "Product Status": "Active"
    },
    {
        "Root": "10012807",
        "LV": "1",
        "Item Description": "EGGPLANT YOUNG 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "03MYE-26E",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012807",
        "Product Status": "Active"
    },
    {
        "Root": "10012921",
        "LV": "1",
        "Item Description": "BREADSTICK GRISSINI 9\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8348344",
        "Last Price Paid": "$3.61",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012921",
        "Product Status": "Active"
    },
    {
        "Root": "10001802",
        "LV": "1",
        "Item Description": "MIX PICO DE GALLO DRY 2/5# TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001802001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512514",
        "Last Price Paid": "$33.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001802",
        "Product Status": "Active"
    },
    {
        "Root": "10105988",
        "LV": "1",
        "Item Description": "PASTRY MINI CHOCOLATINE RTB 240CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.28",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105988",
        "Product Status": "Active"
    },
    {
        "Root": "10037531",
        "LV": "1",
        "Item Description": "CANDIED ORANGE BATONETTES 4KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5821190000",
        "Last Price Paid": "$135.92",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037531",
        "Product Status": "Active"
    },
    {
        "Root": "10034853",
        "LV": "1",
        "Item Description": "CBR DL61020 1.5 ROUND BLU SEMI GLOSS LOGO STICKER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45864742",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034853",
        "Product Status": "Active"
    },
    {
        "Root": "10091231",
        "LV": "2",
        "Item Description": "MOCHI MY 12 X 6 PK. STRAWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091231002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "9936FS",
        "Last Price Paid": "$0.77",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10091231",
        "Product Status": "Active"
    },
    {
        "Root": "10008071",
        "LV": "1",
        "Item Description": "PINOT NOIR HAMILTON RUSS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9685733",
        "Last Price Paid": "$39.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008071",
        "Product Status": "Active"
    },
    {
        "Root": "10040823",
        "LV": "1",
        "Item Description": "SYRUP MAPLE PURE PANCAKE 1 GAL 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040823",
        "Product Status": "Active"
    },
    {
        "Root": "10013350",
        "LV": "1",
        "Item Description": "SHRIMP 6/8 P&D TAIL ON COX SHRIMP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013350001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "74-08065",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013350",
        "Product Status": "Active"
    },
    {
        "Root": "10107811",
        "LV": "1",
        "Item Description": "JELLY GRAPE BULK 8.25LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107811",
        "Product Status": "Active"
    },
    {
        "Root": "10094783",
        "LV": "1",
        "Item Description": "DEC DISC 1.5IN PROGRESSIVE RAINBOW MICKEY HEAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094783001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW20465",
        "Last Price Paid": "$0.65",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094783",
        "Product Status": "Active"
    },
    {
        "Root": "10017035",
        "LV": "1",
        "Item Description": "CAKE SHEET CHOCOLATE FLOURLESS UNFINISHED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "127",
        "Last Price Paid": "$95.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017035",
        "Product Status": "Active"
    },
    {
        "Root": "10040680",
        "LV": "1",
        "Item Description": "ALMOND PASTE CA 14LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040680001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500640000",
        "Last Price Paid": "$110.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040680",
        "Product Status": "Active"
    },
    {
        "Root": "10094640",
        "LV": "1",
        "Item Description": "CANDY YUMEARTH ORG. POPS BULK CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094640001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "0103A",
        "Last Price Paid": "$0.08",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094640",
        "Product Status": "Active"
    },
    {
        "Root": "10001991",
        "LV": "1",
        "Item Description": "CARROT DEMI MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01DMC-33",
        "Last Price Paid": "$45.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001991",
        "Product Status": "Active"
    },
    {
        "Root": "10002441",
        "LV": "2",
        "Item Description": "ICE CREAM CHOCOLATE HDAZ 14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002441002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002441",
        "Product Status": "Active"
    },
    {
        "Root": "10012172",
        "LV": "1",
        "Item Description": "MALBEC BODGA NORTON RESERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012172001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "685267",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012172",
        "Product Status": "Active"
    },
    {
        "Root": "10003245",
        "LV": "2",
        "Item Description": "RICE SUSHI KOKURU 15LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003245002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3952529",
        "Last Price Paid": "$34.57",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003245",
        "Product Status": "Active"
    },
    {
        "Root": "10044627",
        "LV": "1",
        "Item Description": "BEER 81 BAY ROSE BLONDE 15.5G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13171",
        "Last Price Paid": "$192.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044627",
        "Product Status": "Active"
    },
    {
        "Root": "10045000",
        "LV": "1",
        "Item Description": "BAG MERCHANDISE KRAFT 7.5X10.5 S6916",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045000001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013846",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045000",
        "Product Status": "Active"
    },
    {
        "Root": "10032437",
        "LV": "1",
        "Item Description": "CANDY ROCK STICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4174900",
        "Last Price Paid": "$21.28",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032437",
        "Product Status": "Active"
    },
    {
        "Root": "10005832",
        "LV": "1",
        "Item Description": "CAKE CHOCOLATE SPONGE ROUND 10 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005832",
        "Product Status": "Active"
    },
    {
        "Root": "10007957",
        "LV": "1",
        "Item Description": "SPARK GRAHAM BRT ROSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "657631",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007957",
        "Product Status": "Active"
    },
    {
        "Root": "10034439",
        "LV": "2",
        "Item Description": "CABERNET SAUVIGNON GOLDSCHMIDT VINEYARD CRAZY CREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034439002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558344",
        "Last Price Paid": "$13.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034439",
        "Product Status": "Active"
    },
    {
        "Root": "10021032",
        "LV": "2",
        "Item Description": "BEER CROOKED CAN HIGH STEP AMER 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021032002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28277",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021032",
        "Product Status": "Active"
    },
    {
        "Root": "10091304",
        "LV": "1",
        "Item Description": "CANDY RED HOTS .9OZ BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "18640",
        "Last Price Paid": "$89.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091304",
        "Product Status": "Active"
    },
    {
        "Root": "10005065",
        "LV": "1",
        "Item Description": "HOMINY WHITE FANCY BRINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005065",
        "Product Status": "Active"
    },
    {
        "Root": "10023754",
        "LV": "1",
        "Item Description": "CITRIC ACID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6721710000",
        "Last Price Paid": "$15.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023754",
        "Product Status": "Active"
    },
    {
        "Root": "10031052",
        "LV": "1",
        "Item Description": "BLANC BEAUMONT CHENIN 750 MLT M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "469681",
        "Last Price Paid": "$16.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031052",
        "Product Status": "Active"
    },
    {
        "Root": "10038375",
        "LV": "1",
        "Item Description": "BAKING CUP BLUE SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "25309",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038375",
        "Product Status": "Active"
    },
    {
        "Root": "10003738",
        "LV": "1",
        "Item Description": "PASTA FRZ TORTELLINI CHEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3737441",
        "Last Price Paid": "$45.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003738",
        "Product Status": "Active"
    },
    {
        "Root": "10003738",
        "LV": "1",
        "Item Description": "PASTA FRZ TORTELLINI CHEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070575",
        "Last Price Paid": "$45.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003738",
        "Product Status": "Active"
    },
    {
        "Root": "10001174",
        "LV": "1",
        "Item Description": "SALMON BIAS CUT 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001174001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6501975",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001174",
        "Product Status": "Active"
    },
    {
        "Root": "10107333",
        "LV": "1",
        "Item Description": "BEER ROGUE DREAMLAND 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "62123",
        "Last Price Paid": "$200.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107333",
        "Product Status": "Active"
    },
    {
        "Root": "10087992",
        "LV": "1",
        "Item Description": "STABILIZER ICE CREAM/SORBET UNO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CT1093",
        "Last Price Paid": "$93.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087992",
        "Product Status": "Active"
    },
    {
        "Root": "10027716",
        "LV": "1",
        "Item Description": "BREAD BUN  SOFT BRIOCHE SLICED 4\" 60 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000404845",
        "Vendor": "CAMPAGNA-TURANO BAKERY INC",
        "Vendor Item Nb": "35713",
        "Last Price Paid": "$0.18",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027716",
        "Product Status": "Active"
    },
    {
        "Root": "10012143",
        "LV": "1",
        "Item Description": "SEABASS FILLET SKIN OFF WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012143001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00767",
        "Last Price Paid": "$27.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012143",
        "Product Status": "Active"
    },
    {
        "Root": "10040263",
        "LV": "2",
        "Item Description": "CHICKEN BREAST CHUNK BONELESS WING FRITTER 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040263002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.96",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040263",
        "Product Status": "Active"
    },
    {
        "Root": "10001584",
        "LV": "1",
        "Item Description": "PEPPER RED MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001584001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20650",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001584",
        "Product Status": "Active"
    },
    {
        "Root": "10001584",
        "LV": "1",
        "Item Description": "PEPPER RED MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001584001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001584",
        "Product Status": "Active"
    },
    {
        "Root": "10000328",
        "LV": "1",
        "Item Description": "STICK CARROT/CELERY 5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "894002",
        "Last Price Paid": "$1.48",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000328",
        "Product Status": "Active"
    },
    {
        "Root": "10001471",
        "LV": "1",
        "Item Description": "POTATO FINGERLING SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "122003",
        "Last Price Paid": "$30.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001471",
        "Product Status": "Active"
    },
    {
        "Root": "10012951",
        "LV": "2",
        "Item Description": "MARINADE FLORIDA JERK 12/34 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012951002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012951",
        "Product Status": "Active"
    },
    {
        "Root": "10001454",
        "LV": "1",
        "Item Description": "TOMATOES ROMA DISNEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "161097",
        "Last Price Paid": "$0.26",
        "Case Pack": "130",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001454",
        "Product Status": "Active"
    },
    {
        "Root": "10001454",
        "LV": "1",
        "Item Description": "TOMATOES ROMA DISNEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23075",
        "Last Price Paid": "$0.26",
        "Case Pack": "130",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001454",
        "Product Status": "Active"
    },
    {
        "Root": "10103359",
        "LV": "1",
        "Item Description": "WINE RED CRANBERRY PASEK 12/750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000528548",
        "Vendor": "FWS DISTRIBUTORS / PROGRESS WINE",
        "Vendor Item Nb": "016703",
        "Last Price Paid": "$8.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103359",
        "Product Status": "Active"
    },
    {
        "Root": "10004599",
        "LV": "1",
        "Item Description": "SAUCE SOY KIKKOMAN GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8002164",
        "Last Price Paid": "$12.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004599",
        "Product Status": "Active"
    },
    {
        "Root": "10007208",
        "LV": "1",
        "Item Description": "WINE WHITE SAUV BLANC GRGICH HILLS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9687162",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007208",
        "Product Status": "Active"
    },
    {
        "Root": "10033914",
        "LV": "1",
        "Item Description": "SUGAR-SPOOKTACULAR ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033914",
        "Product Status": "Active"
    },
    {
        "Root": "10091442",
        "LV": "1",
        "Item Description": "LIQUEUR EDINBURGH ELDERFLOWER 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "898902",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091442",
        "Product Status": "Active"
    },
    {
        "Root": "10035409",
        "LV": "1",
        "Item Description": "BLACKBERRY COMPUOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "351",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035409",
        "Product Status": "Active"
    },
    {
        "Root": "10023739",
        "LV": "1",
        "Item Description": "CHOC WHT CANDY CANE 8 INCH WHT WITH RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW16793",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023739",
        "Product Status": "Active"
    },
    {
        "Root": "10019596",
        "LV": "2",
        "Item Description": "BOURBON ELIJAH CRAIG SM BTCH 12 YR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019596001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10019596",
        "Product Status": "Active"
    },
    {
        "Root": "10004879",
        "LV": "2",
        "Item Description": "OLIVE KALAMATA SLICED 6/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004879002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004879",
        "Product Status": "Active"
    },
    {
        "Root": "10032947",
        "LV": "1",
        "Item Description": "ROSES TINY UNWIRED RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "TR RD",
        "Last Price Paid": "",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032947",
        "Product Status": "Active"
    },
    {
        "Root": "10013641",
        "LV": "1",
        "Item Description": "CHENIN BLC CEDERBURG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013641",
        "Product Status": "Active"
    },
    {
        "Root": "10007952",
        "LV": "1",
        "Item Description": "SHERRY SOLERA LUSTAU EAST INDIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12156",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007952",
        "Product Status": "Active"
    },
    {
        "Root": "10036381",
        "LV": "1",
        "Item Description": "PROPER 12 IRISH WHISKEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "53522",
        "Last Price Paid": "$20.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036381",
        "Product Status": "Active"
    },
    {
        "Root": "10002630",
        "LV": "3",
        "Item Description": "SPICE PEPPER RED CAYENE 6/16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002630003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.65",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002630",
        "Product Status": "Active"
    },
    {
        "Root": "10034212",
        "LV": "2",
        "Item Description": "BROADSIDE CABERNET SAUVIGNON KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034212002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9269163",
        "Last Price Paid": "$266.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034212",
        "Product Status": "Active"
    },
    {
        "Root": "10034447",
        "LV": "2",
        "Item Description": "PINOT GRIGIO TORRE DI LUNA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034447002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "543436",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034447",
        "Product Status": "Active"
    },
    {
        "Root": "10012390",
        "LV": "2",
        "Item Description": "DEC ORCHID TROPICAL 3.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012390001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "10911",
        "Last Price Paid": "$2.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10012390",
        "Product Status": "Active"
    },
    {
        "Root": "10004659",
        "LV": "2",
        "Item Description": "DRIED FRUIT COCONUT SHREDDED UNSWEET 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004659002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "281401",
        "Last Price Paid": "$3.62",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004659",
        "Product Status": "Active"
    },
    {
        "Root": "10004217",
        "LV": "1",
        "Item Description": "FILLING LEMON 38LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P112113",
        "Last Price Paid": "$56.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004217",
        "Product Status": "Active"
    },
    {
        "Root": "10088429",
        "LV": "1",
        "Item Description": "FLAVOURING UBE BUTTERFLY 1948 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "UBE000",
        "Last Price Paid": "$37.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088429",
        "Product Status": "Active"
    },
    {
        "Root": "10000812",
        "LV": "1",
        "Item Description": "CHICK FRTR BRST SPCY 5.2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5091612",
        "Last Price Paid": "$0.60",
        "Case Pack": "62",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000812",
        "Product Status": "Active"
    },
    {
        "Root": "10000812",
        "LV": "1",
        "Item Description": "CHICK FRTR BRST SPCY 5.2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "5091612",
        "Last Price Paid": "$0.60",
        "Case Pack": "62",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000812",
        "Product Status": "Active"
    },
    {
        "Root": "10107410",
        "LV": "1",
        "Item Description": "CUP GLASS SHOT 1 OZ CLEAR PLAST 2500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107410",
        "Product Status": "Active"
    },
    {
        "Root": "10007548",
        "LV": "3",
        "Item Description": "BLND PALACIOS MONTESA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007548002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9030238",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007548",
        "Product Status": "Active"
    },
    {
        "Root": "10009650",
        "LV": "3",
        "Item Description": "TEQ CORAZON AGAVE REP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009650002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9211667",
        "Last Price Paid": "$18.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009650",
        "Product Status": "Active"
    },
    {
        "Root": "10006907",
        "LV": "1",
        "Item Description": "CHIANTI BANFI SUPER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006907",
        "Product Status": "Active"
    },
    {
        "Root": "10008101",
        "LV": "1",
        "Item Description": "CAB SAUV SEGLA MRGUX 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "465419",
        "Last Price Paid": "$69.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008101",
        "Product Status": "Active"
    },
    {
        "Root": "10006613",
        "LV": "1",
        "Item Description": "BAG SANDWICH FLIP TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006613001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8004574",
        "Last Price Paid": "$0.01",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006613",
        "Product Status": "Active"
    },
    {
        "Root": "10000291",
        "LV": "1",
        "Item Description": "KALE CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480793",
        "Last Price Paid": "$5.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000291",
        "Product Status": "Active"
    },
    {
        "Root": "10000291",
        "LV": "1",
        "Item Description": "KALE CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000291",
        "Product Status": "Active"
    },
    {
        "Root": "10003940",
        "LV": "2",
        "Item Description": "HORS POPPER CHEDDAR JALAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003940002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.69",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003940",
        "Product Status": "Active"
    },
    {
        "Root": "10036957",
        "LV": "1",
        "Item Description": "ONION GREEN ICELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ONIGRIEA",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036957",
        "Product Status": "Active"
    },
    {
        "Root": "10023763",
        "LV": "1",
        "Item Description": "DOUGH BALL 13 OZ PIZZA CRUST 36/13 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023763001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7072622",
        "Last Price Paid": "$1.13",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023763",
        "Product Status": "Active"
    },
    {
        "Root": "10026855",
        "LV": "1",
        "Item Description": "COTTON CANDY BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "E310",
        "Last Price Paid": "$2.02",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026855",
        "Product Status": "Active"
    },
    {
        "Root": "10100350",
        "LV": "1",
        "Item Description": "SUNFLOWER LECITHIN POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "2314",
        "Last Price Paid": "$22.74",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100350",
        "Product Status": "Active"
    },
    {
        "Root": "10014422",
        "LV": "1",
        "Item Description": "SPANISH COLOSSAL ONIONS (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014422001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406010",
        "Last Price Paid": "$28.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014422",
        "Product Status": "Active"
    },
    {
        "Root": "10010075",
        "LV": "2",
        "Item Description": "BEER BLUE MOON 12OZ BTL 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010075001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010075",
        "Product Status": "Active"
    },
    {
        "Root": "10003784",
        "LV": "1",
        "Item Description": "BAR GRANOLA HNY ALMND FLX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003784001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4862405",
        "Last Price Paid": "$0.54",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003784",
        "Product Status": "Active"
    },
    {
        "Root": "10000934",
        "LV": "1",
        "Item Description": "HARI NORI SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "10024",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000934",
        "Product Status": "Active"
    },
    {
        "Root": "10000583",
        "LV": "2",
        "Item Description": "PORK SAUSAGE LINK 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000583001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000583",
        "Product Status": "Active"
    },
    {
        "Root": "10015926",
        "LV": "2",
        "Item Description": "EGG HALF HOLLOW 5.75\" WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015926002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015926",
        "Product Status": "Active"
    },
    {
        "Root": "10010348",
        "LV": "2",
        "Item Description": "BEER ESTRELLA DAM DAURA BT 11.2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010348001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32424",
        "Last Price Paid": "$1.44",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010348",
        "Product Status": "Active"
    },
    {
        "Root": "10027670",
        "LV": "1",
        "Item Description": "LID FOR TACO TRAY PETE TPLT3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51296002",
        "Last Price Paid": "$0.26",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027670",
        "Product Status": "Active"
    },
    {
        "Root": "10010363",
        "LV": "2",
        "Item Description": "BEER FLORIDA LAGER BTL 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010363001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76146",
        "Last Price Paid": "$1.47",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010363",
        "Product Status": "Active"
    },
    {
        "Root": "10010476",
        "LV": "2",
        "Item Description": "MOSCATO CAPOSALDO PEACH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010476001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "277417",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010476",
        "Product Status": "Active"
    },
    {
        "Root": "10006444",
        "LV": "1",
        "Item Description": "PAN HOTEL HALF 2.5 INCH DEEP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9357567",
        "Last Price Paid": "$0.98",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006444",
        "Product Status": "Active"
    },
    {
        "Root": "10089286",
        "LV": "1",
        "Item Description": "CHICKEN SPLIT WOG CITRUS MARINATED 1.20-1.375 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089286001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.17",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089286",
        "Product Status": "Active"
    },
    {
        "Root": "10001968",
        "LV": "1",
        "Item Description": "MACHE PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09PMA-33",
        "Last Price Paid": "$36.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001968",
        "Product Status": "Active"
    },
    {
        "Root": "10021611",
        "LV": "1",
        "Item Description": "BEER ALE LEX KENT BOURBON 12 OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "110",
        "Last Price Paid": "$2.69",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021611",
        "Product Status": "Active"
    },
    {
        "Root": "10000152",
        "LV": "1",
        "Item Description": "CRAB BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000152001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50017",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000152",
        "Product Status": "Active"
    },
    {
        "Root": "10000152",
        "LV": "1",
        "Item Description": "CRAB BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000152001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "78-00001",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000152",
        "Product Status": "Active"
    },
    {
        "Root": "10000152",
        "LV": "1",
        "Item Description": "CRAB BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000152001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6516002",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000152",
        "Product Status": "Active"
    },
    {
        "Root": "10000152",
        "LV": "1",
        "Item Description": "CRAB BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000152001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000152",
        "Product Status": "Active"
    },
    {
        "Root": "10000152",
        "LV": "1",
        "Item Description": "CRAB BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000152001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000152",
        "Product Status": "Active"
    },
    {
        "Root": "10002128",
        "LV": "1",
        "Item Description": "CHEESE CREAM LITE PC CUP .75Z/100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.32",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002128",
        "Product Status": "Active"
    },
    {
        "Root": "10004708",
        "LV": "1",
        "Item Description": "COUS COUS ISRAELI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9239187",
        "Last Price Paid": "$38.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004708",
        "Product Status": "Active"
    },
    {
        "Root": "10037814",
        "LV": "2",
        "Item Description": "CUP COLD 32OZ GALAXYS EDGE RMP32 480/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037814001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$59.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10037814",
        "Product Status": "Active"
    },
    {
        "Root": "10001389",
        "LV": "1",
        "Item Description": "LEAF CURRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481520",
        "Last Price Paid": "$16.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001389",
        "Product Status": "Active"
    },
    {
        "Root": "10001848",
        "LV": "1",
        "Item Description": "LOTUS ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001848001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001848",
        "Product Status": "Active"
    },
    {
        "Root": "10007037",
        "LV": "1",
        "Item Description": "PINOT GRIS VILLA WOLF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007037001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "715422",
        "Last Price Paid": "$9.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007037",
        "Product Status": "Active"
    },
    {
        "Root": "10002617",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRIE TRIPLE CREME 6.6 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002617001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "67876",
        "Last Price Paid": "$8.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002617",
        "Product Status": "Active"
    },
    {
        "Root": "10033916",
        "LV": "1",
        "Item Description": "QUINS-LOLLIPOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "15974",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033916",
        "Product Status": "Active"
    },
    {
        "Root": "10040810",
        "LV": "1",
        "Item Description": "COCOA BUTTER PURPLE NOBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302098C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040810",
        "Product Status": "Active"
    },
    {
        "Root": "10044417",
        "LV": "1",
        "Item Description": "WINE OPICI VINEYARDS PORT 3L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "173037",
        "Last Price Paid": "$16.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044417",
        "Product Status": "Active"
    },
    {
        "Root": "10004097",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE SQUARE YELLOW BOG 1IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004097001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2404574",
        "Last Price Paid": "$0.10",
        "Case Pack": "1540",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004097",
        "Product Status": "Active"
    },
    {
        "Root": "10013109",
        "LV": "1",
        "Item Description": "BISON RIBEYE LIPON 2/ 8-11# FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013109001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "7040434",
        "Last Price Paid": "$30.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013109",
        "Product Status": "Active"
    },
    {
        "Root": "10013109",
        "LV": "1",
        "Item Description": "BISON RIBEYE LIPON 2/ 8-11# FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013109001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4504101",
        "Last Price Paid": "$30.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013109",
        "Product Status": "Active"
    },
    {
        "Root": "10035649",
        "LV": "1",
        "Item Description": "PHOTOCAKE INK CARTRIDGE CYAN XP430 T288XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22670",
        "Last Price Paid": "$16.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035649",
        "Product Status": "Active"
    },
    {
        "Root": "10017768",
        "LV": "1",
        "Item Description": "MOLD WOODEN BAKING TOM POUCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017768001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "TOM POUCE",
        "Last Price Paid": "$0.59",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017768",
        "Product Status": "Active"
    },
    {
        "Root": "10033801",
        "LV": "1",
        "Item Description": "HIBISCUS SPRAY WHITE ETM WH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ETM WH",
        "Last Price Paid": "$10.31",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033801",
        "Product Status": "Active"
    },
    {
        "Root": "10015370",
        "LV": "1",
        "Item Description": "DEC ROSE TEA LG LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKB LV",
        "Last Price Paid": "$2.42",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015370",
        "Product Status": "Active"
    },
    {
        "Root": "10000202",
        "LV": "1",
        "Item Description": "CARROT SHREDDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482019",
        "Last Price Paid": "$5.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000202",
        "Product Status": "Active"
    },
    {
        "Root": "10102153",
        "LV": "1",
        "Item Description": "COOKIE GREEN RND INDIV WRAP 1.3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102153",
        "Product Status": "Active"
    },
    {
        "Root": "10001667",
        "LV": "1",
        "Item Description": "ONION RED SLCD END TO END",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-007-14-5",
        "Last Price Paid": "$13.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001667",
        "Product Status": "Active"
    },
    {
        "Root": "10001667",
        "LV": "1",
        "Item Description": "ONION RED SLCD END TO END",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514075",
        "Last Price Paid": "$13.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001667",
        "Product Status": "Active"
    },
    {
        "Root": "10004865",
        "LV": "2",
        "Item Description": "CANDY RAISIN YOGURT COVERED 30# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004865002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "503390",
        "Last Price Paid": "$119.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004865",
        "Product Status": "Active"
    },
    {
        "Root": "10007699",
        "LV": "3",
        "Item Description": "PINOT GRIS LA CREMA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007699002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "584975",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007699",
        "Product Status": "Active"
    },
    {
        "Root": "10001365",
        "LV": "1",
        "Item Description": "PUREE GARLIC GINGER 6/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001365",
        "Product Status": "Active"
    },
    {
        "Root": "10009760",
        "LV": "2",
        "Item Description": "TEQUILA HERRADURA REPOSADO LTR GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009760002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9245832",
        "Last Price Paid": "$26.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009760",
        "Product Status": "Active"
    },
    {
        "Root": "10105403",
        "LV": "1",
        "Item Description": "GELATO PLANT BASED CHOCOLATE 4 PACK, 1/2 GALLON EP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "P-CHOC-08-CS4",
        "Last Price Paid": "$19.13",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105403",
        "Product Status": "Active"
    },
    {
        "Root": "10037529",
        "LV": "1",
        "Item Description": "ARABIC COFFEE COMPOUND 1.2KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6720670000",
        "Last Price Paid": "$42.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037529",
        "Product Status": "Active"
    },
    {
        "Root": "10100451",
        "LV": "3",
        "Item Description": "COOKIE SUGAR 1.5 OZ FORKY FONDANT OVERLAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100451002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "FORKY",
        "Last Price Paid": "$1.25",
        "Case Pack": "136",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10100451",
        "Product Status": "Active"
    },
    {
        "Root": "10017391",
        "LV": "1",
        "Item Description": "LETTUCE PETITE GEM LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489967",
        "Last Price Paid": "$32.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017391",
        "Product Status": "Active"
    },
    {
        "Root": "10017391",
        "LV": "1",
        "Item Description": "LETTUCE PETITE GEM LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETLG",
        "Last Price Paid": "$32.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017391",
        "Product Status": "Active"
    },
    {
        "Root": "10004858",
        "LV": "1",
        "Item Description": "PASTA DRY ANGEL HAIR-CAPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7327836",
        "Last Price Paid": "$26.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004858",
        "Product Status": "Active"
    },
    {
        "Root": "10001887",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI DICED 3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001887001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "17-002-34",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001887",
        "Product Status": "Active"
    },
    {
        "Root": "10001887",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI DICED 3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001887001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515533",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001887",
        "Product Status": "Active"
    },
    {
        "Root": "10010077",
        "LV": "1",
        "Item Description": "BEER SAM ADAM SEASONAL BTL 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010077",
        "Product Status": "Active"
    },
    {
        "Root": "10100278",
        "LV": "1",
        "Item Description": "KOSHER BSC CIABATTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.39",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100278",
        "Product Status": "Active"
    },
    {
        "Root": "10031010",
        "LV": "1",
        "Item Description": "SYRUP MONIN POMEGRANATE 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "M-FR075F",
        "Last Price Paid": "$6.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031010",
        "Product Status": "Active"
    },
    {
        "Root": "10031010",
        "LV": "1",
        "Item Description": "SYRUP MONIN POMEGRANATE 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031010",
        "Product Status": "Active"
    },
    {
        "Root": "10045259",
        "LV": "2",
        "Item Description": "SB CHOCOLATE MALT POWDER 400G 3EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045259002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011124515",
        "Last Price Paid": "$13.22",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10045259",
        "Product Status": "Active"
    },
    {
        "Root": "10000378",
        "LV": "1",
        "Item Description": "TOMATO GRAPE FRESH REF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4731741",
        "Last Price Paid": "$22.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000378",
        "Product Status": "Active"
    },
    {
        "Root": "10000378",
        "LV": "1",
        "Item Description": "TOMATO GRAPE FRESH REF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23040",
        "Last Price Paid": "$22.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000378",
        "Product Status": "Active"
    },
    {
        "Root": "10000378",
        "LV": "1",
        "Item Description": "TOMATO GRAPE FRESH REF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "TOMRDG",
        "Last Price Paid": "$22.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000378",
        "Product Status": "Active"
    },
    {
        "Root": "10038265",
        "LV": "3",
        "Item Description": "CAN CUP 12OZ AP FLOWER F&G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038265001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11014682",
        "Last Price Paid": "$0.76",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10038265",
        "Product Status": "Active"
    },
    {
        "Root": "10023528",
        "LV": "1",
        "Item Description": "COCOA BUTTER BLUE REGAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023528001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "620010",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023528",
        "Product Status": "Active"
    },
    {
        "Root": "10027476",
        "LV": "1",
        "Item Description": "VEGAN MAYO, HAMPTON CREEK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027476001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4838689",
        "Last Price Paid": "$27.81",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027476",
        "Product Status": "Active"
    },
    {
        "Root": "10027945",
        "LV": "1",
        "Item Description": "BOUCHARD PULIGNY MONTRACHET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027945001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9175386",
        "Last Price Paid": "$76.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027945",
        "Product Status": "Active"
    },
    {
        "Root": "10100876",
        "LV": "1",
        "Item Description": "BEEF/LAMB  GYRO CONE LG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7110826",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100876",
        "Product Status": "Active"
    },
    {
        "Root": "10031449",
        "LV": "1",
        "Item Description": "MIXER RUBY ELDERFLOWER 503ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "993689",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031449",
        "Product Status": "Active"
    },
    {
        "Root": "10037368",
        "LV": "1",
        "Item Description": "MINNIE E+T WHITE SHOPPER 10X6.75X12 250/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003307",
        "Last Price Paid": "",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037368",
        "Product Status": "Active"
    },
    {
        "Root": "10034782",
        "LV": "1",
        "Item Description": "BEEF STEAK CHOPPED  BREADED FRITTER WESTERN STYLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8060178",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034782",
        "Product Status": "Active"
    },
    {
        "Root": "10007407",
        "LV": "2",
        "Item Description": "PINOT N NAPA 10 COPAIN TOUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007407001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "905343",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007407",
        "Product Status": "Active"
    },
    {
        "Root": "10035050",
        "LV": "2",
        "Item Description": "CHEESE BLEND SHREDDED PARM ASIAGO FONTIN & PROVOLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035050001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10035050",
        "Product Status": "Active"
    },
    {
        "Root": "10035008",
        "LV": "1",
        "Item Description": "AVANCIA GODELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035008",
        "Product Status": "Active"
    },
    {
        "Root": "10035071",
        "LV": "1",
        "Item Description": "TOPPING CHOCOLATE LIQUID SUGAR FREE U-BET 12/18OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3749298",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035071",
        "Product Status": "Active"
    },
    {
        "Root": "10012740",
        "LV": "1",
        "Item Description": "COASTER HANGAR LELAPIN 4\" SQUARE,60PT,CMYK,2S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-021",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012740",
        "Product Status": "Active"
    },
    {
        "Root": "10029618",
        "LV": "1",
        "Item Description": "CHOC HAUNTED HOUSE GRAVESTONE 4\"X1 1/4\" RECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029618001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW25634",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029618",
        "Product Status": "Active"
    },
    {
        "Root": "10107689",
        "LV": "1",
        "Item Description": "BITE SIZED CHICKEN & CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107689",
        "Product Status": "Active"
    },
    {
        "Root": "10107707",
        "LV": "1",
        "Item Description": "POPCORN GLAZE CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2523",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107707",
        "Product Status": "Active"
    },
    {
        "Root": "10004648",
        "LV": "1",
        "Item Description": "BASE HAM MINORS NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4333464",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004648",
        "Product Status": "Active"
    },
    {
        "Root": "10104594",
        "LV": "1",
        "Item Description": "PASSOVER BSC EGGPLANT PARMIGINA VEG 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104594",
        "Product Status": "Active"
    },
    {
        "Root": "10008038",
        "LV": "1",
        "Item Description": "WINE RED SILVERADO SANGIOVESE FANTASIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008038001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "543865",
        "Last Price Paid": "$41.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008038",
        "Product Status": "Active"
    },
    {
        "Root": "10022495",
        "LV": "1",
        "Item Description": "BRUT ROSE VEUVE DU VERNAY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "374319",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022495",
        "Product Status": "Active"
    },
    {
        "Root": "10012975",
        "LV": "1",
        "Item Description": "GLASS, REGGIE'S REVENGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "HANREG",
        "Last Price Paid": "$4.20",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012975",
        "Product Status": "Active"
    },
    {
        "Root": "10012975",
        "LV": "1",
        "Item Description": "GLASS, REGGIE'S REVENGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.20",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012975",
        "Product Status": "Active"
    },
    {
        "Root": "10004824",
        "LV": "1",
        "Item Description": "HONEY ORANGE BLOSSOM 17Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004824",
        "Product Status": "Active"
    },
    {
        "Root": "10002392",
        "LV": "2",
        "Item Description": "CHEESE ASIAGO WHEEL 24#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002392002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6261861",
        "Last Price Paid": "$4.26",
        "Case Pack": "26.67",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002392",
        "Product Status": "Active"
    },
    {
        "Root": "10009817",
        "LV": "1",
        "Item Description": "RUM MALIBU COCONUT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "33451",
        "Last Price Paid": "$17.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009817",
        "Product Status": "Active"
    },
    {
        "Root": "10007866",
        "LV": "2",
        "Item Description": "MOSCATO VILLA ROSA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007866001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "424864",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007866",
        "Product Status": "Active"
    },
    {
        "Root": "10001122",
        "LV": "1",
        "Item Description": "SCALLOP BAY FRZ 40/60",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001122001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6460400",
        "Last Price Paid": "$6.49",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001122",
        "Product Status": "Active"
    },
    {
        "Root": "10026159",
        "LV": "1",
        "Item Description": "BEER KEG ROGUE DEAD GUY ALE 1/2BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "57326",
        "Last Price Paid": "$216.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026159",
        "Product Status": "Active"
    },
    {
        "Root": "10106588",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER  PURPLE 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313040E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106588",
        "Product Status": "Active"
    },
    {
        "Root": "10010605",
        "LV": "2",
        "Item Description": "FIREFLY MS WHT LIGHTNING 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010605001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9184257",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010605",
        "Product Status": "Active"
    },
    {
        "Root": "10001622",
        "LV": "1",
        "Item Description": "ONION PEARKL FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4327631",
        "Last Price Paid": "$51.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001622",
        "Product Status": "Active"
    },
    {
        "Root": "10032878",
        "LV": "1",
        "Item Description": "SB SALT PACKETS 1/2G 3000-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011039052",
        "Last Price Paid": "$0.00",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032878",
        "Product Status": "Active"
    },
    {
        "Root": "10095539",
        "LV": "1",
        "Item Description": "FRY PRIME RESERVE  3/8 SKIN ON 6/5# BAG EPCOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095539",
        "Product Status": "Active"
    },
    {
        "Root": "10103938",
        "LV": "1",
        "Item Description": "COCO FIESTA CREAM OF COCONUT 15OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.79",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103938",
        "Product Status": "Active"
    },
    {
        "Root": "10002182",
        "LV": "1",
        "Item Description": "MILK HOMO HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002182001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002182",
        "Product Status": "Active"
    },
    {
        "Root": "10000685",
        "LV": "1",
        "Item Description": "HAM SERRANO SPANISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000685001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3302032",
        "Last Price Paid": "$13.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000685",
        "Product Status": "Active"
    },
    {
        "Root": "10032125",
        "LV": "1",
        "Item Description": "MIX FREESTYLE SPRITE ZERO 21OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7036701",
        "Last Price Paid": "$91.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032125",
        "Product Status": "Active"
    },
    {
        "Root": "10034748",
        "LV": "3",
        "Item Description": "LAMB VOLCANO SHANK DOMESTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034748003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "LAMDOM021",
        "Last Price Paid": "$11.99",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10034748",
        "Product Status": "Active"
    },
    {
        "Root": "10000975",
        "LV": "1",
        "Item Description": "KRILL LIVING SEAS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000975001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6374020",
        "Last Price Paid": "$2.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000975",
        "Product Status": "Active"
    },
    {
        "Root": "10000975",
        "LV": "1",
        "Item Description": "KRILL LIVING SEAS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000975001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50029",
        "Last Price Paid": "$2.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000975",
        "Product Status": "Active"
    },
    {
        "Root": "10025281",
        "LV": "1",
        "Item Description": "TOMATO CULL UBU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025281001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410285",
        "Last Price Paid": "$17.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025281",
        "Product Status": "Active"
    },
    {
        "Root": "10086441",
        "LV": "1",
        "Item Description": "OLIVE KALAMATA WHOLE PITTED IMPORTED GREECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3159431",
        "Last Price Paid": "$17.03",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086441",
        "Product Status": "Active"
    },
    {
        "Root": "10018064",
        "LV": "1",
        "Item Description": "WINE RED MATROT T&P BLAGNY 1ER CRU SOUS BOIS 2014",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9243454",
        "Last Price Paid": "$44.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018064",
        "Product Status": "Active"
    },
    {
        "Root": "10019390",
        "LV": "2",
        "Item Description": "AO TOSAKA, GARNISH FOR SASHIMI (20/1.1LB) SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019390002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019390",
        "Product Status": "Active"
    },
    {
        "Root": "10039523",
        "LV": "1",
        "Item Description": "LINER 1.7IN BAKE-IN MICKEY OVEN SAFE MINI CK8369",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005135",
        "Last Price Paid": "$0.15",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039523",
        "Product Status": "Active"
    },
    {
        "Root": "10004488",
        "LV": "1",
        "Item Description": "EXTRACT COFFEE 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "L100U",
        "Last Price Paid": "$19.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004488",
        "Product Status": "Active"
    },
    {
        "Root": "10018126",
        "LV": "1",
        "Item Description": "CAB BLEND MEERLUST RUBICON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558668",
        "Last Price Paid": "$28.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018126",
        "Product Status": "Active"
    },
    {
        "Root": "10002155",
        "LV": "1",
        "Item Description": "CREAM HALF AND HALF 12 QTY / PINT SIZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002155",
        "Product Status": "Active"
    },
    {
        "Root": "10031763",
        "LV": "1",
        "Item Description": "SPRINKLES YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031763001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9464",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031763",
        "Product Status": "Active"
    },
    {
        "Root": "10037947",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON HUNDRED ACRE 3 PACK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "567839",
        "Last Price Paid": "$497.50",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037947",
        "Product Status": "Active"
    },
    {
        "Root": "10004742",
        "LV": "1",
        "Item Description": "CRACKER OYSTER SMALL BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6023840",
        "Last Price Paid": "$41.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004742",
        "Product Status": "Active"
    },
    {
        "Root": "10001468",
        "LV": "1",
        "Item Description": "GINGER ROOT FRESH 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490127",
        "Last Price Paid": "$18.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001468",
        "Product Status": "Active"
    },
    {
        "Root": "10001468",
        "LV": "1",
        "Item Description": "GINGER ROOT FRESH 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001468",
        "Product Status": "Active"
    },
    {
        "Root": "10083951",
        "LV": "1",
        "Item Description": "SB BASE MANGO DRAGONFRUIT 4X REFRESHER 1 LITER 6 C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011125332",
        "Last Price Paid": "$11.82",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083951",
        "Product Status": "Active"
    },
    {
        "Root": "10001548",
        "LV": "1",
        "Item Description": "GREEN COLLARD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404060",
        "Last Price Paid": "$20.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001548",
        "Product Status": "Active"
    },
    {
        "Root": "10039775",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN PRIME ANGUS STEAK READY 1\" TAIL BL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039775001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195003",
        "Last Price Paid": "$42.87",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039775",
        "Product Status": "Active"
    },
    {
        "Root": "10001892",
        "LV": "1",
        "Item Description": "PEPPER RED JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001892001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490077",
        "Last Price Paid": "$17.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001892",
        "Product Status": "Active"
    },
    {
        "Root": "10001892",
        "LV": "1",
        "Item Description": "PEPPER RED JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001892001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-005-A",
        "Last Price Paid": "$17.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001892",
        "Product Status": "Active"
    },
    {
        "Root": "10030417",
        "LV": "2",
        "Item Description": "FROSTING DAWN CHOCOLATE ZGTF 28#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030417002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$70.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030417",
        "Product Status": "Active"
    },
    {
        "Root": "10106583",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER BLUSHING ROSE 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313033E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106583",
        "Product Status": "Active"
    },
    {
        "Root": "10018118",
        "LV": "1",
        "Item Description": "CAB FRANC ENEMIGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018118001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "646836",
        "Last Price Paid": "$20.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018118",
        "Product Status": "Active"
    },
    {
        "Root": "10013983",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013983001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$106.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013983",
        "Product Status": "Active"
    },
    {
        "Root": "10018392",
        "LV": "1",
        "Item Description": "SHRIMP TIGER 7-9 CT HEAD ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018392001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125407",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018392",
        "Product Status": "Active"
    },
    {
        "Root": "10001097",
        "LV": "1",
        "Item Description": "SHRIMP HEAD ON SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001097001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001097",
        "Product Status": "Active"
    },
    {
        "Root": "10001097",
        "LV": "1",
        "Item Description": "SHRIMP HEAD ON SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001097001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "75-50152",
        "Last Price Paid": "$8.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001097",
        "Product Status": "Active"
    },
    {
        "Root": "10001097",
        "LV": "1",
        "Item Description": "SHRIMP HEAD ON SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001097001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125403",
        "Last Price Paid": "$8.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001097",
        "Product Status": "Active"
    },
    {
        "Root": "10009474",
        "LV": "2",
        "Item Description": "SCOTCH SNGL GLENMRG NECT DOR750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009474001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "934004",
        "Last Price Paid": "$81.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009474",
        "Product Status": "Active"
    },
    {
        "Root": "10003664",
        "LV": "1",
        "Item Description": "SB 1LB DCF PIKE PLACE ROA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11135844",
        "Last Price Paid": "$9.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003664",
        "Product Status": "Active"
    },
    {
        "Root": "10003534",
        "LV": "1",
        "Item Description": "ESPRESSO BEAN REG 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.90",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003534",
        "Product Status": "Active"
    },
    {
        "Root": "10027211",
        "LV": "2",
        "Item Description": "SORBET APPLE CIDER 5 OZ CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027211001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-APCD-02-CS",
        "Last Price Paid": "$1.38",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10027211",
        "Product Status": "Active"
    },
    {
        "Root": "10008316",
        "LV": "1",
        "Item Description": "MOSCATO GRAPPA POLI 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "659457",
        "Last Price Paid": "$53.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008316",
        "Product Status": "Active"
    },
    {
        "Root": "10027966",
        "LV": "1",
        "Item Description": "FILLING APPLE CUBED EXCEPTIONAL 35 POUNDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "02407402",
        "Last Price Paid": "$86.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027966",
        "Product Status": "Active"
    },
    {
        "Root": "10006303",
        "LV": "1",
        "Item Description": "BAG PLASTIC CLEAR 12X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7387095",
        "Last Price Paid": "$0.01",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006303",
        "Product Status": "Active"
    },
    {
        "Root": "10015155",
        "LV": "1",
        "Item Description": "CRYSTAL HIBISCUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "165HIBISCUS",
        "Last Price Paid": "$7.44",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015155",
        "Product Status": "Active"
    },
    {
        "Root": "10011957",
        "LV": "1",
        "Item Description": "TURKEY BREAST SMOKED SLICED LOW SODIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011957001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.28",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011957",
        "Product Status": "Active"
    },
    {
        "Root": "10004670",
        "LV": "1",
        "Item Description": "MIX CALIFORNIA MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "100450",
        "Last Price Paid": "$105.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004670",
        "Product Status": "Active"
    },
    {
        "Root": "10010124",
        "LV": "2",
        "Item Description": "WATER HILDON STILL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010124001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010124",
        "Product Status": "Active"
    },
    {
        "Root": "10028472",
        "LV": "2",
        "Item Description": "BEER PRESTIGE 12 OUNCE BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028472002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "15632",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028472",
        "Product Status": "Active"
    },
    {
        "Root": "10001973",
        "LV": "1",
        "Item Description": "PEA TENDRILS GREEN PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04PT-33-L",
        "Last Price Paid": "$25.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001973",
        "Product Status": "Active"
    },
    {
        "Root": "10016326",
        "LV": "1",
        "Item Description": "CUPCAKE LG YELL UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1636",
        "Last Price Paid": "$0.53",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016326",
        "Product Status": "Active"
    },
    {
        "Root": "10034224",
        "LV": "1",
        "Item Description": "LID CLEAR DOME WITH HOLE ADL626",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11262555",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034224",
        "Product Status": "Active"
    },
    {
        "Root": "10002475",
        "LV": "2",
        "Item Description": "PISTOU PROVINCIAL 12/34 O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002475001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.96",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002475",
        "Product Status": "Active"
    },
    {
        "Root": "10001587",
        "LV": "1",
        "Item Description": "ARTICHOKE 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ART",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001587",
        "Product Status": "Active"
    },
    {
        "Root": "10001587",
        "LV": "1",
        "Item Description": "ARTICHOKE 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401024",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001587",
        "Product Status": "Active"
    },
    {
        "Root": "10101130",
        "LV": "1",
        "Item Description": "ALE CIDER CAN KOPPARBERG PEAR 11.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "55448",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101130",
        "Product Status": "Active"
    },
    {
        "Root": "10003258",
        "LV": "1",
        "Item Description": "SYRUP CARAMEL LTR PET MON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003258",
        "Product Status": "Active"
    },
    {
        "Root": "10031009",
        "LV": "1",
        "Item Description": "PINOT NOIR GOGI 3 BINGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "482164",
        "Last Price Paid": "$55.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031009",
        "Product Status": "Active"
    },
    {
        "Root": "10002981",
        "LV": "1",
        "Item Description": "SYRUP MARSHMLLW TSTD LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002981",
        "Product Status": "Active"
    },
    {
        "Root": "10091839",
        "LV": "1",
        "Item Description": "RICE, WHITE LONG GRAIN 4% BROKEN BEN'S ORIGINAL PA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091839001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8087338",
        "Last Price Paid": "$25.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091839",
        "Product Status": "Active"
    },
    {
        "Root": "10012844",
        "LV": "1",
        "Item Description": "LAMB LOIN CHOPS 1\" THICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012844001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1290100",
        "Last Price Paid": "$8.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012844",
        "Product Status": "Active"
    },
    {
        "Root": "10034306",
        "LV": "1",
        "Item Description": "SWEETY DROPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "602852",
        "Last Price Paid": "$27.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034306",
        "Product Status": "Active"
    },
    {
        "Root": "10040255",
        "LV": "1",
        "Item Description": "NERO D AVOLA MACCARI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "120074",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040255",
        "Product Status": "Active"
    },
    {
        "Root": "10002660",
        "LV": "2",
        "Item Description": "BASE BEEF NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002660002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002660",
        "Product Status": "Active"
    },
    {
        "Root": "10002660",
        "LV": "2",
        "Item Description": "BASE BEEF NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002660002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6746321",
        "Last Price Paid": "$3.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002660",
        "Product Status": "Active"
    },
    {
        "Root": "10015027",
        "LV": "2",
        "Item Description": "MIX FIRECRACKER HOT SPICY INDIV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015027002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203004",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015027",
        "Product Status": "Active"
    },
    {
        "Root": "10044831",
        "LV": "2",
        "Item Description": "GELATO BANANA SOFT SERVE MIX 6 HALF GAL JUGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044831002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-BANA-11-MX-C",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10044831",
        "Product Status": "Active"
    },
    {
        "Root": "10034815",
        "LV": "1",
        "Item Description": "CHRISTMAS TREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1431",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034815",
        "Product Status": "Active"
    },
    {
        "Root": "10002459",
        "LV": "1",
        "Item Description": "CHEESE SEMI HARD BUTTERKASE GERMAN 6 LB PIECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002459001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1520",
        "Last Price Paid": "",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002459",
        "Product Status": "Active"
    },
    {
        "Root": "10027428",
        "LV": "1",
        "Item Description": "CUP ESPNWWS SODA WHITE 22 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G22-WHITE",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027428",
        "Product Status": "Active"
    },
    {
        "Root": "10014266",
        "LV": "1",
        "Item Description": "COASTER HANGER ATOMIC CAFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-029",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014266",
        "Product Status": "Active"
    },
    {
        "Root": "10015544",
        "LV": "1",
        "Item Description": "GINGERBREAD MEN GF VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015544001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10015644",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015544",
        "Product Status": "Active"
    },
    {
        "Root": "10020596",
        "LV": "1",
        "Item Description": "VODKA VAN GOGH 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "113408",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020596",
        "Product Status": "Active"
    },
    {
        "Root": "10023124",
        "LV": "1",
        "Item Description": "PUMPKIN WEE BEE LITTLE 10/3 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598735",
        "Last Price Paid": "",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023124",
        "Product Status": "Active"
    },
    {
        "Root": "10002725",
        "LV": "2",
        "Item Description": "RICE PARBOILED WHITE 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002725002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002725",
        "Product Status": "Active"
    },
    {
        "Root": "10041500",
        "LV": "1",
        "Item Description": "ODWALLA CRAFTED POMEGRANATE LIMEADE (NC)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041500",
        "Product Status": "Active"
    },
    {
        "Root": "10001714",
        "LV": "2",
        "Item Description": "SQUASH CHAYOTE 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001714002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23090",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001714",
        "Product Status": "Active"
    },
    {
        "Root": "10013365",
        "LV": "1",
        "Item Description": "DRAFT TAMPA BAY OLD ELEPHANT FOOT IPA 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "83606",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013365",
        "Product Status": "Active"
    },
    {
        "Root": "10009817",
        "LV": "2",
        "Item Description": "RUM MALIBU COCONUT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009817001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009817",
        "Product Status": "Active"
    },
    {
        "Root": "10007326",
        "LV": "1",
        "Item Description": "MALBEC ZOLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16765",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007326",
        "Product Status": "Active"
    },
    {
        "Root": "10101489",
        "LV": "1",
        "Item Description": "SYRUP ICE CREAM FLAVOR BURST STRWBRY 1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "002-SEA",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101489",
        "Product Status": "Active"
    },
    {
        "Root": "10002821",
        "LV": "2",
        "Item Description": "VINEGAR APPLE CIDER 4% 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002821002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9023805",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002821",
        "Product Status": "Active"
    },
    {
        "Root": "10034102",
        "LV": "2",
        "Item Description": "BANANA COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034102002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011107",
        "Last Price Paid": "$80.59",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034102",
        "Product Status": "Active"
    },
    {
        "Root": "10004074",
        "LV": "1",
        "Item Description": "FLOUR HAZELNUT 5# BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004074001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MERICAN ALMOND",
        "Last Price Paid": "$7.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004074",
        "Product Status": "Active"
    },
    {
        "Root": "10109552",
        "LV": "1",
        "Item Description": "CIDER HARD KEG STRONGBOW 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109552001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109552",
        "Product Status": "Active"
    },
    {
        "Root": "10016371",
        "LV": "1",
        "Item Description": "CAB SAUV BEAU SITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "752132",
        "Last Price Paid": "$37.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016371",
        "Product Status": "Active"
    },
    {
        "Root": "10003530",
        "LV": "1",
        "Item Description": "COFFEE ANNIVERSARY DCF 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.54",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003530",
        "Product Status": "Active"
    },
    {
        "Root": "10002629",
        "LV": "3",
        "Item Description": "SPICE NUTMEG GROUND 6/1.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002629003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002629",
        "Product Status": "Active"
    },
    {
        "Root": "10002629",
        "LV": "3",
        "Item Description": "SPICE NUTMEG GROUND 6/1.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002629003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00534-4",
        "Last Price Paid": "$5.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002629",
        "Product Status": "Active"
    },
    {
        "Root": "10003654",
        "LV": "1",
        "Item Description": "SB APPLE JUICE 3 BLND 64O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003654001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11007002",
        "Last Price Paid": "$5.82",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003654",
        "Product Status": "Active"
    },
    {
        "Root": "10107690",
        "LV": "1",
        "Item Description": "MINI APPLE FRITTER 63 COUNT TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS901",
        "Last Price Paid": "$0.41",
        "Case Pack": "63",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107690",
        "Product Status": "Active"
    },
    {
        "Root": "10095590",
        "LV": "1",
        "Item Description": "CINNAMON BUN SMALL RTB 2.25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.27",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095590",
        "Product Status": "Active"
    },
    {
        "Root": "10028108",
        "LV": "1",
        "Item Description": "DRAFT KONA HANALEI ISLAND IPA 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "27502",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028108",
        "Product Status": "Active"
    },
    {
        "Root": "10028108",
        "LV": "1",
        "Item Description": "DRAFT KONA HANALEI ISLAND IPA 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27502",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028108",
        "Product Status": "Active"
    },
    {
        "Root": "10014411",
        "LV": "1",
        "Item Description": "LEMONS 200 CT (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014411",
        "Product Status": "Active"
    },
    {
        "Root": "10001666",
        "LV": "1",
        "Item Description": "KALE FLOWERING RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403514",
        "Last Price Paid": "$36.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001666",
        "Product Status": "Active"
    },
    {
        "Root": "10001666",
        "LV": "1",
        "Item Description": "KALE FLOWERING RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001666",
        "Product Status": "Active"
    },
    {
        "Root": "10003542",
        "LV": "2",
        "Item Description": "TEA SLVR LEAF WHT 100GRMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003542001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3053",
        "Last Price Paid": "$48.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003542",
        "Product Status": "Active"
    },
    {
        "Root": "10039529",
        "LV": "2",
        "Item Description": "BEER GOOSE ISLAND IPA 16OZ 24 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039529002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039529",
        "Product Status": "Active"
    },
    {
        "Root": "10103851",
        "LV": "1",
        "Item Description": "CHIP TERRA VEGETABLE POTATO BLUE 1OZ/24CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3449238",
        "Last Price Paid": "$0.90",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103851",
        "Product Status": "Active"
    },
    {
        "Root": "10010524",
        "LV": "1",
        "Item Description": "ROSE DOMAINE OTT PROVENCE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "582345",
        "Last Price Paid": "$36.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010524",
        "Product Status": "Active"
    },
    {
        "Root": "10034613",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK SO 68% VALRHONA NYANGBO 3/3KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034613001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6085",
        "Last Price Paid": "$72.69",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034613",
        "Product Status": "Active"
    },
    {
        "Root": "10026261",
        "LV": "1",
        "Item Description": "COOKIE OREO SMALL PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302270",
        "Last Price Paid": "$142.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026261",
        "Product Status": "Active"
    },
    {
        "Root": "10004851",
        "LV": "1",
        "Item Description": "CRACKER GOLDFISH CHS 1Z B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.28",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004851",
        "Product Status": "Active"
    },
    {
        "Root": "10032390",
        "LV": "1",
        "Item Description": "PINOTAGE SOUTHERN RIGHT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2773042",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032390",
        "Product Status": "Active"
    },
    {
        "Root": "10019849",
        "LV": "1",
        "Item Description": "EGG YOLK CF 15/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.75",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019849",
        "Product Status": "Active"
    },
    {
        "Root": "10034828",
        "LV": "2",
        "Item Description": "YOGURT GREEK STRAWBERRY OIKOS 12/5.3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034828001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10034828",
        "Product Status": "Active"
    },
    {
        "Root": "10029962",
        "LV": "1",
        "Item Description": "SUGAR CHRISTMAS CHARMS ASSORTMENTD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "13442",
        "Last Price Paid": "$0.06",
        "Case Pack": "508",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029962",
        "Product Status": "Active"
    },
    {
        "Root": "10002349",
        "LV": "1",
        "Item Description": "SORBET STRAWBERRY BASIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-SBAS-08-HP",
        "Last Price Paid": "$9.48",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002349",
        "Product Status": "Active"
    },
    {
        "Root": "10000112",
        "LV": "1",
        "Item Description": "LAMB CHOP AUSTRALIAN 2.5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000112001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2290100",
        "Last Price Paid": "$15.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000112",
        "Product Status": "Active"
    },
    {
        "Root": "10029009",
        "LV": "1",
        "Item Description": "DELI CONTAINER(PET) RD16 CLEAR 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51174056",
        "Last Price Paid": "$0.17",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029009",
        "Product Status": "Active"
    },
    {
        "Root": "10019392",
        "LV": "1",
        "Item Description": "HERB TARRAGON MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482592",
        "Last Price Paid": "$13.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019392",
        "Product Status": "Active"
    },
    {
        "Root": "10001173",
        "LV": "1",
        "Item Description": "SALMON BIAS CUT 3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001173001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6501973",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001173",
        "Product Status": "Active"
    },
    {
        "Root": "10042409",
        "LV": "1",
        "Item Description": "BITTERS HELLA APPLE BLOSSOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "945600",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042409",
        "Product Status": "Active"
    },
    {
        "Root": "10008207",
        "LV": "1",
        "Item Description": "CHAMPAGNE CRISTAL LOUIS ROEDERER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "541136",
        "Last Price Paid": "$295.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008207",
        "Product Status": "Active"
    },
    {
        "Root": "10036517",
        "LV": "1",
        "Item Description": "FIG JAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036517001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-10135",
        "Last Price Paid": "$6.75",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036517",
        "Product Status": "Active"
    },
    {
        "Root": "10029305",
        "LV": "1",
        "Item Description": "FRESH WHOLE VEGETABLE TOMATO 4X5 18",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "TOM4518",
        "Last Price Paid": "$0.62",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029305",
        "Product Status": "Active"
    },
    {
        "Root": "10002618",
        "LV": "1",
        "Item Description": "CHEESE BLUE BAY BLUE POINT REYES 6LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002618001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "62100",
        "Last Price Paid": "$13.59",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002618",
        "Product Status": "Active"
    },
    {
        "Root": "10031529",
        "LV": "1",
        "Item Description": "CRACKER WATER CARRS ROUND MINI BOX 24/2.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8072340",
        "Last Price Paid": "$1.89",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031529",
        "Product Status": "Active"
    },
    {
        "Root": "10006772",
        "LV": "1",
        "Item Description": "BANANA CHOCOLATE CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006772",
        "Product Status": "Active"
    },
    {
        "Root": "10004994",
        "LV": "1",
        "Item Description": "GLAZE MIX ALMOND 10 EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004994",
        "Product Status": "Active"
    },
    {
        "Root": "10004469",
        "LV": "1",
        "Item Description": "PICKLE GHERKIN 75-80 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "506",
        "Last Price Paid": "$34.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004469",
        "Product Status": "Active"
    },
    {
        "Root": "10004310",
        "LV": "1",
        "Item Description": "HORS SPANAKOPITA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300326",
        "Last Price Paid": "$1.31",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004310",
        "Product Status": "Active"
    },
    {
        "Root": "10100275",
        "LV": "1",
        "Item Description": "KOSHER BSC CHOCOLATE CHIP COOKIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.90",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100275",
        "Product Status": "Active"
    },
    {
        "Root": "10015498",
        "LV": "1",
        "Item Description": "PASTA, ELBOW GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3475136",
        "Last Price Paid": "$2.58",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015498",
        "Product Status": "Active"
    },
    {
        "Root": "10032669",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE NAVY BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10602",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032669",
        "Product Status": "Active"
    },
    {
        "Root": "10003818",
        "LV": "1",
        "Item Description": "CHERRY BING BLK PITTD #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7019433",
        "Last Price Paid": "$19.84",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003818",
        "Product Status": "Active"
    },
    {
        "Root": "10001456",
        "LV": "1",
        "Item Description": "TOMATOES 6X6 25# VR DISNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598992",
        "Last Price Paid": "$0.26",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001456",
        "Product Status": "Active"
    },
    {
        "Root": "10001456",
        "LV": "1",
        "Item Description": "TOMATOES 6X6 25# VR DISNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23015",
        "Last Price Paid": "$0.26",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001456",
        "Product Status": "Active"
    },
    {
        "Root": "10102553",
        "LV": "1",
        "Item Description": "CUP STEIN ACRYLIC EPCOT GERMANY 22OZ 50/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.65",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102553",
        "Product Status": "Active"
    },
    {
        "Root": "10001780",
        "LV": "1",
        "Item Description": "RADISH 14/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001780",
        "Product Status": "Active"
    },
    {
        "Root": "10001780",
        "LV": "1",
        "Item Description": "RADISH 14/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20514",
        "Last Price Paid": "$1.36",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001780",
        "Product Status": "Active"
    },
    {
        "Root": "10001780",
        "LV": "1",
        "Item Description": "RADISH 14/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401314",
        "Last Price Paid": "$1.36",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001780",
        "Product Status": "Active"
    },
    {
        "Root": "10044331",
        "LV": "1",
        "Item Description": "CANDY M&M GREEN 2/5# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "10472",
        "Last Price Paid": "$42.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044331",
        "Product Status": "Active"
    },
    {
        "Root": "10000262",
        "LV": "1",
        "Item Description": "FLOWER ORCHID 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "482058",
        "Last Price Paid": "$0.32",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000262",
        "Product Status": "Active"
    },
    {
        "Root": "10000262",
        "LV": "1",
        "Item Description": "FLOWER ORCHID 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482058",
        "Last Price Paid": "$0.32",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000262",
        "Product Status": "Active"
    },
    {
        "Root": "10010447",
        "LV": "2",
        "Item Description": "WINE CHAMPAGANE ROSE MOET CHANDN BRUT IMPER 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010447002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "137714",
        "Last Price Paid": "$42.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10010447",
        "Product Status": "Active"
    },
    {
        "Root": "10032165",
        "LV": "1",
        "Item Description": "VINEGAR L8 HARVEST  200ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032165001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-00189",
        "Last Price Paid": "$27.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032165",
        "Product Status": "Active"
    },
    {
        "Root": "10037122",
        "LV": "2",
        "Item Description": "COKE DIET DELOS 12/13.5OZ STARWARS GALAXYEDGE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037122002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037122",
        "Product Status": "Active"
    },
    {
        "Root": "10003381",
        "LV": "2",
        "Item Description": "SPICE ITALIAN CHOPPED 6/0.3125LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003381002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003381",
        "Product Status": "Active"
    },
    {
        "Root": "10041319",
        "LV": "1",
        "Item Description": "TEQUILA CINCORO REPOSADO 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041319001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9382938",
        "Last Price Paid": "$70.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041319",
        "Product Status": "Active"
    },
    {
        "Root": "10001444",
        "LV": "1",
        "Item Description": "TOMATO HEIRLOOM WK LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "487749",
        "Last Price Paid": "$36.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001444",
        "Product Status": "Active"
    },
    {
        "Root": "10029949",
        "LV": "1",
        "Item Description": "SUGAR WINTER BUDDIES ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48118",
        "Last Price Paid": "$0.27",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029949",
        "Product Status": "Active"
    },
    {
        "Root": "10004823",
        "LV": "1",
        "Item Description": "HONEYCOMB 10Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004823",
        "Product Status": "Active"
    },
    {
        "Root": "10004823",
        "LV": "1",
        "Item Description": "HONEYCOMB 10Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "202166",
        "Last Price Paid": "$18.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004823",
        "Product Status": "Active"
    },
    {
        "Root": "10003330",
        "LV": "1",
        "Item Description": "GEL LIQUID BROWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22514",
        "Last Price Paid": "$4.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003330",
        "Product Status": "Active"
    },
    {
        "Root": "10045067",
        "LV": "2",
        "Item Description": "PUREE BOIRON PASSION FRUIT TEMP SUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045067002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "720000",
        "Last Price Paid": "$14.58",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10045067",
        "Product Status": "Active"
    },
    {
        "Root": "10005231",
        "LV": "1",
        "Item Description": "POWDER PEPPERMINT CRUSHED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2090",
        "Last Price Paid": "$59.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005231",
        "Product Status": "Active"
    },
    {
        "Root": "10006325",
        "LV": "1",
        "Item Description": "PAPER PATTY 5.5 INCH X5.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6139364",
        "Last Price Paid": "$0.01",
        "Case Pack": "24000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006325",
        "Product Status": "Active"
    },
    {
        "Root": "10003028",
        "LV": "1",
        "Item Description": "SUGAR SUB SPLENDA 2000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.01",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003028",
        "Product Status": "Active"
    },
    {
        "Root": "10029734",
        "LV": "2",
        "Item Description": "UGNI MONTAND BRUT 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029734002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "571497",
        "Last Price Paid": "$4.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029734",
        "Product Status": "Active"
    },
    {
        "Root": "10034391",
        "LV": "2",
        "Item Description": "BAR BLUEBERRY VANILLA CASHEW KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034391002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "303675",
        "Last Price Paid": "$2.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034391",
        "Product Status": "Active"
    },
    {
        "Root": "10109431",
        "LV": "1",
        "Item Description": "FLOUR DARK RYE BOB'S RED MILL ORGANIC 25LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101341",
        "Last Price Paid": "$41.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109431",
        "Product Status": "Active"
    },
    {
        "Root": "10104948",
        "LV": "1",
        "Item Description": "SPICE TOGARASHI POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35279",
        "Last Price Paid": "$25.95",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104948",
        "Product Status": "Active"
    },
    {
        "Root": "10038589",
        "LV": "1",
        "Item Description": "INSERT TAKE & GO AI626",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11262014",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038589",
        "Product Status": "Active"
    },
    {
        "Root": "10009953",
        "LV": "1",
        "Item Description": "LIQ BOLS APRICOT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001681",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009953",
        "Product Status": "Active"
    },
    {
        "Root": "10040177",
        "LV": "1",
        "Item Description": "PRETZEL STICK ZEPPELIN BAVARIAN 5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040177",
        "Product Status": "Active"
    },
    {
        "Root": "10003373",
        "LV": "2",
        "Item Description": "FLOUR BRAN 14% 25# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003373001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003373",
        "Product Status": "Active"
    },
    {
        "Root": "10004596",
        "LV": "1",
        "Item Description": "WATERCHESTNUT SLICED #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004596",
        "Product Status": "Active"
    },
    {
        "Root": "10015180",
        "LV": "1",
        "Item Description": "SPICE SALT SEA SMOKED FUMME DE SEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015180",
        "Product Status": "Active"
    },
    {
        "Root": "10010765",
        "LV": "3",
        "Item Description": "WATER TYNANT SPARK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010765002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "TYN6",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10010765",
        "Product Status": "Active"
    },
    {
        "Root": "10107908",
        "LV": "1",
        "Item Description": "SYRUP BLK CURRANT RIBENA 12/850 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107908",
        "Product Status": "Active"
    },
    {
        "Root": "10012107",
        "LV": "1",
        "Item Description": "PEA ENGLISH SHELLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012107001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "CS 20SEP-2",
        "Last Price Paid": "$42.00",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012107",
        "Product Status": "Active"
    },
    {
        "Root": "10021875",
        "LV": "1",
        "Item Description": "GIN AVIATION 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "314106",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021875",
        "Product Status": "Active"
    },
    {
        "Root": "10040931",
        "LV": "1",
        "Item Description": "SYRUP PINA COLADA FANTA FCB 2.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "52011",
        "Last Price Paid": "$57.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040931",
        "Product Status": "Active"
    },
    {
        "Root": "10013250",
        "LV": "2",
        "Item Description": "TEA TWNG PURE SENCHA GRN TEA 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013250002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013250",
        "Product Status": "Active"
    },
    {
        "Root": "10002884",
        "LV": "1",
        "Item Description": "OLIVE RIPE EXTRA LARGE PITTED 51 OZ 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002884001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.04",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002884",
        "Product Status": "Active"
    },
    {
        "Root": "10102923",
        "LV": "1",
        "Item Description": "KOSHER BSC VEGETABLE LASAGNA 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102923",
        "Product Status": "Active"
    },
    {
        "Root": "10027731",
        "LV": "1",
        "Item Description": "BAG GLASSINE GUSSETED MEDIUM 3.75X6.25 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51171003",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027731",
        "Product Status": "Active"
    },
    {
        "Root": "10019762",
        "LV": "1",
        "Item Description": "MIX FREESTYLE VIT WATER MD 11.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4263549",
        "Last Price Paid": "$46.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019762",
        "Product Status": "Active"
    },
    {
        "Root": "10008148",
        "LV": "1",
        "Item Description": "CAB SAUV BENZIGER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578025",
        "Last Price Paid": "$90.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008148",
        "Product Status": "Active"
    },
    {
        "Root": "10004514",
        "LV": "1",
        "Item Description": "SPICE MUSTARD SEED BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "108040",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004514",
        "Product Status": "Active"
    },
    {
        "Root": "10006297",
        "LV": "1",
        "Item Description": "CONTAINER GELATO GREY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006297001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "PAN475",
        "Last Price Paid": "$1.76",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006297",
        "Product Status": "Active"
    },
    {
        "Root": "10008989",
        "LV": "2",
        "Item Description": "BLEND CH DU PAPE BEAUCASTEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008989001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9631553",
        "Last Price Paid": "$81.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008989",
        "Product Status": "Active"
    },
    {
        "Root": "10002170",
        "LV": "1",
        "Item Description": "LEMONADE FROZEN 12OZ STRWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002170",
        "Product Status": "Active"
    },
    {
        "Root": "10008055",
        "LV": "1",
        "Item Description": "RIES VALCKBRG MADONNA SPAT 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388256",
        "Last Price Paid": "$8.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008055",
        "Product Status": "Active"
    },
    {
        "Root": "10044600",
        "LV": "1",
        "Item Description": "BEEF STOCK MINOR'S CLASSIC REDUCTION 4/3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1076737",
        "Last Price Paid": "$32.37",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044600",
        "Product Status": "Active"
    },
    {
        "Root": "10016414",
        "LV": "1",
        "Item Description": "CUP BAKE BRN 1X1.25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51408036",
        "Last Price Paid": "$0.01",
        "Case Pack": "17000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016414",
        "Product Status": "Active"
    },
    {
        "Root": "10003427",
        "LV": "1",
        "Item Description": "SYRUP COFF IRSH CRM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1024",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003427",
        "Product Status": "Active"
    },
    {
        "Root": "10102918",
        "LV": "1",
        "Item Description": "KOSHER BSC MATZO BALL SOUP 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102918",
        "Product Status": "Active"
    },
    {
        "Root": "10006355",
        "LV": "1",
        "Item Description": "CONTAINER PAPER 16Z W/LID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6807986",
        "Last Price Paid": "$0.12",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006355",
        "Product Status": "Active"
    },
    {
        "Root": "10001485",
        "LV": "1",
        "Item Description": "ARTICHOKE BOTTOM IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7050020",
        "Last Price Paid": "$9.43",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001485",
        "Product Status": "Active"
    },
    {
        "Root": "10002258",
        "LV": "1",
        "Item Description": "CHEESE HARD TOMA POINT REYES 10 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002258001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "27616",
        "Last Price Paid": "$12.03",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002258",
        "Product Status": "Active"
    },
    {
        "Root": "10009792",
        "LV": "1",
        "Item Description": "RUM BACARDI LIMON LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009792001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28736",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009792",
        "Product Status": "Active"
    },
    {
        "Root": "10038805",
        "LV": "1",
        "Item Description": "CROISSANT BACON AND EGG 45/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "90037",
        "Last Price Paid": "$1.76",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038805",
        "Product Status": "Active"
    },
    {
        "Root": "10015282",
        "LV": "1",
        "Item Description": "FLOWER HIBISCUS DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015282001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO388",
        "Last Price Paid": "$23.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015282",
        "Product Status": "Active"
    },
    {
        "Root": "10108189",
        "LV": "1",
        "Item Description": "CHEESE CUBE PEPPER JACK .75\" REFRIGERATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "29801",
        "Last Price Paid": "$14.37",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108189",
        "Product Status": "Active"
    },
    {
        "Root": "10041424",
        "LV": "1",
        "Item Description": "TEQUILA CINCORO EXTRA ANEJO 750ML 4PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9386563",
        "Last Price Paid": "$1,083.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041424",
        "Product Status": "Active"
    },
    {
        "Root": "10027131",
        "LV": "1",
        "Item Description": "DRAFT CIDERBOYS STRAWBERRY MAGIC 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1677",
        "Last Price Paid": "$149.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027131",
        "Product Status": "Active"
    },
    {
        "Root": "10017868",
        "LV": "1",
        "Item Description": "DOME LID PET FITS 10019686",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11262496",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017868",
        "Product Status": "Active"
    },
    {
        "Root": "10003785",
        "LV": "1",
        "Item Description": "TUNA ALBACORE SOLID PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6333777",
        "Last Price Paid": "$19.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003785",
        "Product Status": "Active"
    },
    {
        "Root": "10022787",
        "LV": "1",
        "Item Description": "TEA GUNPOWDER GREEN 5/1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "T1700-16T",
        "Last Price Paid": "$21.06",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022787",
        "Product Status": "Active"
    },
    {
        "Root": "10002029",
        "LV": "1",
        "Item Description": "LETTUCE ULTRA MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09UMX-2",
        "Last Price Paid": "$41.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002029",
        "Product Status": "Active"
    },
    {
        "Root": "10002748",
        "LV": "2",
        "Item Description": "SYRUP PANCAKE 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002748002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002748",
        "Product Status": "Active"
    },
    {
        "Root": "10009617",
        "LV": "1",
        "Item Description": "VODKA VAN GOGH BLUEBERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "113521",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009617",
        "Product Status": "Active"
    },
    {
        "Root": "10002670",
        "LV": "1",
        "Item Description": "PINEAPPLE TIDBIT/JUCE #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.26",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002670",
        "Product Status": "Active"
    },
    {
        "Root": "10003125",
        "LV": "1",
        "Item Description": "SYRUP VIOLET 750ML MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003125",
        "Product Status": "Active"
    },
    {
        "Root": "10008202",
        "LV": "1",
        "Item Description": "SHERRY COLOMBO SWT MARSALA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001478",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008202",
        "Product Status": "Active"
    },
    {
        "Root": "10009163",
        "LV": "1",
        "Item Description": "WINE RED PINOT NOIR, WILLAKENZIE, 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009163001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "586236",
        "Last Price Paid": "$30.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009163",
        "Product Status": "Active"
    },
    {
        "Root": "10044288",
        "LV": "3",
        "Item Description": "PASTRY BOARD BRONZE ROUND WITH TAB 3.625IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044288003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51775012",
        "Last Price Paid": "$0.10",
        "Case Pack": "650",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10044288",
        "Product Status": "Active"
    },
    {
        "Root": "10018859",
        "LV": "1",
        "Item Description": "POWDER DECOR PEARL GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018859001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11060",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018859",
        "Product Status": "Active"
    },
    {
        "Root": "10000088",
        "LV": "1",
        "Item Description": "BEEF TERRES MAJOR MEDALION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000088001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2110345",
        "Last Price Paid": "$12.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000088",
        "Product Status": "Active"
    },
    {
        "Root": "10028127",
        "LV": "2",
        "Item Description": "BURGER, BEYOND, VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028127002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103310",
        "Last Price Paid": "$1.99",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028127",
        "Product Status": "Active"
    },
    {
        "Root": "10008010",
        "LV": "1",
        "Item Description": "CAB SAUV FRANK FAMILY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "1460245",
        "Last Price Paid": "$50.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008010",
        "Product Status": "Active"
    },
    {
        "Root": "10008010",
        "LV": "1",
        "Item Description": "CAB SAUV FRANK FAMILY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1460245",
        "Last Price Paid": "$50.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008010",
        "Product Status": "Active"
    },
    {
        "Root": "10027503",
        "LV": "1",
        "Item Description": "BASIL SAMPLER DEMI 75 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08BSD-33",
        "Last Price Paid": "$0.63",
        "Case Pack": "75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027503",
        "Product Status": "Active"
    },
    {
        "Root": "10017872",
        "LV": "1",
        "Item Description": "GREENS COLLARD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "GRNCOL",
        "Last Price Paid": "$3.17",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017872",
        "Product Status": "Active"
    },
    {
        "Root": "10018430",
        "LV": "1",
        "Item Description": "LID CONT FLAT NO HOLE 5-12 OZ PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6704969",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018430",
        "Product Status": "Active"
    },
    {
        "Root": "10094639",
        "LV": "1",
        "Item Description": "CHOC DARK SUNFLOWER BUTTR CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "FF01D010",
        "Last Price Paid": "$0.82",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094639",
        "Product Status": "Active"
    },
    {
        "Root": "10007654",
        "LV": "1",
        "Item Description": "BLEND RED DELICATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007654001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "202070",
        "Last Price Paid": "$17.49",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007654",
        "Product Status": "Active"
    },
    {
        "Root": "10023538",
        "LV": "1",
        "Item Description": "COCOA BUTTER AUREOLIN YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11035",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023538",
        "Product Status": "Active"
    },
    {
        "Root": "10004005",
        "LV": "1",
        "Item Description": "FONDANT ROLLED IVORY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10006",
        "Last Price Paid": "$64.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004005",
        "Product Status": "Active"
    },
    {
        "Root": "10100315",
        "LV": "2",
        "Item Description": "CONTAINER  DISPSOABLE PET 12OZ PARFAIT 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100315002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$106.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100315",
        "Product Status": "Active"
    },
    {
        "Root": "10018146",
        "LV": "1",
        "Item Description": "COOKIE VANILLA WAFER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8637470",
        "Last Price Paid": "$1.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018146",
        "Product Status": "Active"
    },
    {
        "Root": "10031869",
        "LV": "1",
        "Item Description": "MALBEC MAAL BIUTIFUL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9169374",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031869",
        "Product Status": "Active"
    },
    {
        "Root": "10024591",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT ALMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024591001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "52387",
        "Last Price Paid": "$1.17",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024591",
        "Product Status": "Active"
    },
    {
        "Root": "10012575",
        "LV": "1",
        "Item Description": "HERB CITRUS LACE 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05CMLV-33",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012575",
        "Product Status": "Active"
    },
    {
        "Root": "10005400",
        "LV": "1",
        "Item Description": "CLAM OCEAN CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35356",
        "Last Price Paid": "$13.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005400",
        "Product Status": "Active"
    },
    {
        "Root": "10005400",
        "LV": "1",
        "Item Description": "CLAM OCEAN CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5664354",
        "Last Price Paid": "$13.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005400",
        "Product Status": "Active"
    },
    {
        "Root": "10001642",
        "LV": "1",
        "Item Description": "MUSHROOM FANCY 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001642",
        "Product Status": "Active"
    },
    {
        "Root": "10001642",
        "LV": "1",
        "Item Description": "MUSHROOM FANCY 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405025",
        "Last Price Paid": "$23.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001642",
        "Product Status": "Active"
    },
    {
        "Root": "10007778",
        "LV": "1",
        "Item Description": "CHENIN BLC FAIR VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007778001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007778",
        "Product Status": "Active"
    },
    {
        "Root": "10086893",
        "LV": "1",
        "Item Description": "CLAM SURF WHOLE MEAT AP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086893001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6403005",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086893",
        "Product Status": "Active"
    },
    {
        "Root": "10110669",
        "LV": "1",
        "Item Description": "KANPACHI FROZEN 10 LBS IVP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "70-01010",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110669",
        "Product Status": "Active"
    },
    {
        "Root": "10037978",
        "LV": "1",
        "Item Description": "KNIFE COMPOSTABLE BLACK 6.5 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11003834",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037978",
        "Product Status": "Active"
    },
    {
        "Root": "10024502",
        "LV": "1",
        "Item Description": "CHOC WRESTH WHT WITH COLOR 2 X 2.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3201",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024502",
        "Product Status": "Active"
    },
    {
        "Root": "10092150",
        "LV": "1",
        "Item Description": "CANDY MEDIUM EYES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "27747",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092150",
        "Product Status": "Active"
    },
    {
        "Root": "10110629",
        "LV": "1",
        "Item Description": "BEER KEG CROOKED CAN BAH HOP 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "63331",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110629",
        "Product Status": "Active"
    },
    {
        "Root": "10091622",
        "LV": "1",
        "Item Description": "MIX BROWNIE DOUBLE DARK CHOCOLATE ADD EGG OIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5839238",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091622",
        "Product Status": "Active"
    },
    {
        "Root": "10034783",
        "LV": "1",
        "Item Description": "JAM GUAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034783001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U294",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034783",
        "Product Status": "Active"
    },
    {
        "Root": "10094291",
        "LV": "1",
        "Item Description": "CAKE YELLOW IN CUPS BUTTERCREAM ICING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "2202",
        "Last Price Paid": "$1.65",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094291",
        "Product Status": "Active"
    },
    {
        "Root": "10000488",
        "LV": "1",
        "Item Description": "SHEET BISCUIT CHOCOLATE THICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.46",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000488",
        "Product Status": "Active"
    },
    {
        "Root": "10016577",
        "LV": "1",
        "Item Description": "DRESSING RANCH KENS DISP PKSJ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.49",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016577",
        "Product Status": "Active"
    },
    {
        "Root": "10008147",
        "LV": "1",
        "Item Description": "CAB SAUV B R COHN SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "317971",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008147",
        "Product Status": "Active"
    },
    {
        "Root": "10015134",
        "LV": "1",
        "Item Description": "CARROT DRAGON #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015134001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015134",
        "Product Status": "Active"
    },
    {
        "Root": "10012729",
        "LV": "1",
        "Item Description": "LEAVES ROOTBEER 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08RBL-33",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012729",
        "Product Status": "Active"
    },
    {
        "Root": "10092232",
        "LV": "1",
        "Item Description": "BEEF BURGER ANGUS CHUCK 3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092232001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1090003",
        "Last Price Paid": "$4.10",
        "Case Pack": "21.6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092232",
        "Product Status": "Active"
    },
    {
        "Root": "10030380",
        "LV": "1",
        "Item Description": "BOX CLEAR CANDY APPLE PET HOLE ON TOP 4X4X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51682003",
        "Last Price Paid": "$0.42",
        "Case Pack": "450",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030380",
        "Product Status": "Active"
    },
    {
        "Root": "10008244",
        "LV": "1",
        "Item Description": "CHARD CHALK HILL SONO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "594987",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008244",
        "Product Status": "Active"
    },
    {
        "Root": "10034607",
        "LV": "4",
        "Item Description": "LOBSTER TAIL WW 7OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034607003004",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6475121",
        "Last Price Paid": "$24.99",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10034607",
        "Product Status": "Active"
    },
    {
        "Root": "10002181",
        "LV": "1",
        "Item Description": "MILK 1% HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002181001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.37",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002181",
        "Product Status": "Active"
    },
    {
        "Root": "10002267",
        "LV": "1",
        "Item Description": "BUTTER CULTURED UNSALTED VERMONT CREAMERY 12/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "80064",
        "Last Price Paid": "$8.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002267",
        "Product Status": "Active"
    },
    {
        "Root": "10023122",
        "LV": "1",
        "Item Description": "PUMPKIN PIE COOKING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482110",
        "Last Price Paid": "$23.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023122",
        "Product Status": "Active"
    },
    {
        "Root": "10006411",
        "LV": "1",
        "Item Description": "LINER PAN 16X24 SILICON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "367805",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006411",
        "Product Status": "Active"
    },
    {
        "Root": "10005888",
        "LV": "1",
        "Item Description": "BREAD SOURDOUGH ROUND 2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005888",
        "Product Status": "Active"
    },
    {
        "Root": "10002674",
        "LV": "1",
        "Item Description": "BEAN KIDNEY DARK RED #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.68",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002674",
        "Product Status": "Active"
    },
    {
        "Root": "10003044",
        "LV": "1",
        "Item Description": "FILLING CHERRY PIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7330996",
        "Last Price Paid": "$96.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003044",
        "Product Status": "Active"
    },
    {
        "Root": "10004468",
        "LV": "1",
        "Item Description": "MIX FREESTYLE VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3555880",
        "Last Price Paid": "$16.65",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004468",
        "Product Status": "Active"
    },
    {
        "Root": "10005724",
        "LV": "1",
        "Item Description": "PASTRY DANISH WILD BLUEBERRY 1.25Z RTB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005724001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061023",
        "Last Price Paid": "$0.50",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005724",
        "Product Status": "Active"
    },
    {
        "Root": "10104430",
        "LV": "1",
        "Item Description": "BEER DRAFT LEFTHAND WOOK BAIT 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13929",
        "Last Price Paid": "$269.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104430",
        "Product Status": "Active"
    },
    {
        "Root": "10007095",
        "LV": "1",
        "Item Description": "PINOT NOIR FLOWRS SON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007095001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600202",
        "Last Price Paid": "$30.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007095",
        "Product Status": "Active"
    },
    {
        "Root": "10018431",
        "LV": "1",
        "Item Description": "CUP COLD 12 OZ CLR SQUAT PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8809600",
        "Last Price Paid": "$0.10",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018431",
        "Product Status": "Active"
    },
    {
        "Root": "10040868",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP WATERMELON 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.58",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040868",
        "Product Status": "Active"
    },
    {
        "Root": "10004484",
        "LV": "1",
        "Item Description": "SUSHI NIGIRI SALMON 1OZ/E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004484001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004484",
        "Product Status": "Active"
    },
    {
        "Root": "10103329",
        "LV": "1",
        "Item Description": "PLANTAIN CHIPS TROPICON LIGHTLY SALTED BULK 22LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "PC-01-22",
        "Last Price Paid": "$54.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103329",
        "Product Status": "Active"
    },
    {
        "Root": "10010646",
        "LV": "1",
        "Item Description": "GIN TANQUERAY RANGPUR 1LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "912712",
        "Last Price Paid": "$28.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010646",
        "Product Status": "Active"
    },
    {
        "Root": "10036698",
        "LV": "1",
        "Item Description": "FOLLOW YOUR HEART VEGANAISE ORIGINAL 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "101032",
        "Last Price Paid": "$6.09",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036698",
        "Product Status": "Active"
    },
    {
        "Root": "10039064",
        "LV": "1",
        "Item Description": "SYRUP HONEY MONIN 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5084S",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039064",
        "Product Status": "Active"
    },
    {
        "Root": "10000957",
        "LV": "1",
        "Item Description": "SWORDFSH LOIN FRESH BNLS SKN ON SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000957001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000957",
        "Product Status": "Active"
    },
    {
        "Root": "10000957",
        "LV": "1",
        "Item Description": "SWORDFSH LOIN FRESH BNLS SKN ON SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000957001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "7915572",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000957",
        "Product Status": "Active"
    },
    {
        "Root": "10000957",
        "LV": "1",
        "Item Description": "SWORDFSH LOIN FRESH BNLS SKN ON SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000957001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00291",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000957",
        "Product Status": "Active"
    },
    {
        "Root": "10001651",
        "LV": "1",
        "Item Description": "HONEYDEW PLD/HLVD 8CT TRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-002H",
        "Last Price Paid": "$3.63",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001651",
        "Product Status": "Active"
    },
    {
        "Root": "10001651",
        "LV": "1",
        "Item Description": "HONEYDEW PLD/HLVD 8CT TRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "597002",
        "Last Price Paid": "$3.63",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001651",
        "Product Status": "Active"
    },
    {
        "Root": "10008125",
        "LV": "1",
        "Item Description": "SAUV BLC VERAMONTE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "535360",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008125",
        "Product Status": "Active"
    },
    {
        "Root": "10019487",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019487001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20512",
        "Last Price Paid": "$3.15",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019487",
        "Product Status": "Active"
    },
    {
        "Root": "10019487",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019487001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "RADWM",
        "Last Price Paid": "$3.15",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019487",
        "Product Status": "Active"
    },
    {
        "Root": "10019487",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019487001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.15",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019487",
        "Product Status": "Active"
    },
    {
        "Root": "10000343",
        "LV": "1",
        "Item Description": "CELERY BUFFALO STICKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-011",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000343",
        "Product Status": "Active"
    },
    {
        "Root": "10000343",
        "LV": "1",
        "Item Description": "CELERY BUFFALO STICKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512548",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000343",
        "Product Status": "Active"
    },
    {
        "Root": "10005735",
        "LV": "1",
        "Item Description": "DOUGH PHYLLO 1# ATHENS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9047887",
        "Last Price Paid": "$3.11",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005735",
        "Product Status": "Active"
    },
    {
        "Root": "10002822",
        "LV": "1",
        "Item Description": "TEA EARL GREY INROOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002822",
        "Product Status": "Active"
    },
    {
        "Root": "10039771",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 7Z - AUS STEER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039771001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195050",
        "Last Price Paid": "$19.72",
        "Case Pack": "8.75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039771",
        "Product Status": "Active"
    },
    {
        "Root": "10107572",
        "LV": "1",
        "Item Description": "RTD ON THE ROCKS EFFEN VODKA COSMOPOLITAN 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "918515",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107572",
        "Product Status": "Active"
    },
    {
        "Root": "10010624",
        "LV": "2",
        "Item Description": "BOURBON GENTLEMAN JACK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010624001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "23440",
        "Last Price Paid": "$24.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010624",
        "Product Status": "Active"
    },
    {
        "Root": "10036327",
        "LV": "1",
        "Item Description": "CHICKEN DL521028 2.5 ROUND LABEL BLACK ON KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001086",
        "Last Price Paid": "$0.06",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036327",
        "Product Status": "Active"
    },
    {
        "Root": "10001377",
        "LV": "1",
        "Item Description": "POWDER PORCINI MUSHROOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "MP102",
        "Last Price Paid": "$25.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001377",
        "Product Status": "Active"
    },
    {
        "Root": "10040088",
        "LV": "1",
        "Item Description": "ICE CREAM BUTTER PECAN HGDZS 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040088",
        "Product Status": "Active"
    },
    {
        "Root": "10040088",
        "LV": "1",
        "Item Description": "ICE CREAM BUTTER PECAN HGDZS 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12196910",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040088",
        "Product Status": "Active"
    },
    {
        "Root": "10028981",
        "LV": "1",
        "Item Description": "POTSTICKER CHIX LEMONGRASS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "66231",
        "Last Price Paid": "$0.92",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028981",
        "Product Status": "Active"
    },
    {
        "Root": "10101128",
        "LV": "1",
        "Item Description": "BREAD PITA SD GLUTEN FREE AGAINST THE GRAIN 6/13.8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "UNFI-1643048",
        "Last Price Paid": "$7.17",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101128",
        "Product Status": "Active"
    },
    {
        "Root": "10040278",
        "LV": "1",
        "Item Description": "RHONE RED BRUNIER CDP PIEDLONG 2016 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "511742",
        "Last Price Paid": "$42.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040278",
        "Product Status": "Active"
    },
    {
        "Root": "10008844",
        "LV": "2",
        "Item Description": "SPARK RAVENTOS DE NIT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008844001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9170427",
        "Last Price Paid": "$15.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008844",
        "Product Status": "Active"
    },
    {
        "Root": "10030067",
        "LV": "1",
        "Item Description": "BAR MIX SOUR BLOOD ORANGE PLASTIC BOTTLE 12/1 LT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4912175",
        "Last Price Paid": "$3.56",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030067",
        "Product Status": "Active"
    },
    {
        "Root": "10007269",
        "LV": "4",
        "Item Description": "WINE WHITE PINOT GIRGIO ATTEMS 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007269003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9056208",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10007269",
        "Product Status": "Active"
    },
    {
        "Root": "10003831",
        "LV": "1",
        "Item Description": "BREADCRUMB 6/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003831001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9440207",
        "Last Price Paid": "$5.69",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003831",
        "Product Status": "Active"
    },
    {
        "Root": "10016323",
        "LV": "1",
        "Item Description": "CUPCAKE MED YELL UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1633",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016323",
        "Product Status": "Active"
    },
    {
        "Root": "10004944",
        "LV": "1",
        "Item Description": "HAZELNUT PRALINE PASTE 1/11 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004944001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P311",
        "Last Price Paid": "$107.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004944",
        "Product Status": "Active"
    },
    {
        "Root": "10107428",
        "LV": "1",
        "Item Description": "COCKTAIL RTD KNOB CREEK OLD FASHIONED 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "918254",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107428",
        "Product Status": "Active"
    },
    {
        "Root": "10007080",
        "LV": "1",
        "Item Description": "ROSE BANFI CENTINE BLEND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "286648",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007080",
        "Product Status": "Active"
    },
    {
        "Root": "10005813",
        "LV": "2",
        "Item Description": "MACARONS PARISIAN ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005813002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "128811",
        "Last Price Paid": "$0.64",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005813",
        "Product Status": "Active"
    },
    {
        "Root": "10003154",
        "LV": "1",
        "Item Description": "PRINTABLE FROSTING SHEET NON HYDROGENATED 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8836",
        "Last Price Paid": "$1.40",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003154",
        "Product Status": "Active"
    },
    {
        "Root": "10002600",
        "LV": "1",
        "Item Description": "PINEAPPLE PLD/HLVD GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592045",
        "Last Price Paid": "$14.45",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002600",
        "Product Status": "Active"
    },
    {
        "Root": "10002600",
        "LV": "1",
        "Item Description": "PINEAPPLE PLD/HLVD GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-007H",
        "Last Price Paid": "$14.45",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002600",
        "Product Status": "Active"
    },
    {
        "Root": "10003902",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE WHITE BELLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003902001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "1428",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003902",
        "Product Status": "Active"
    },
    {
        "Root": "10033871",
        "LV": "1",
        "Item Description": "SB COFFEE CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11091207",
        "Last Price Paid": "$1.22",
        "Case Pack": "21",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033871",
        "Product Status": "Active"
    },
    {
        "Root": "10035693",
        "LV": "1",
        "Item Description": "SCHNAPPS PEPPERMINT LLORDS 1 L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035693001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "246637",
        "Last Price Paid": "$6.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035693",
        "Product Status": "Active"
    },
    {
        "Root": "10001867",
        "LV": "1",
        "Item Description": "MUSHROOM PORCINI DRIED IT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001867001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001867",
        "Product Status": "Active"
    },
    {
        "Root": "10001867",
        "LV": "1",
        "Item Description": "MUSHROOM PORCINI DRIED IT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001867001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405048",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001867",
        "Product Status": "Active"
    },
    {
        "Root": "10095638",
        "LV": "1",
        "Item Description": "WINE WHITE RIESLINE HILLICK HOBBS 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "787667",
        "Last Price Paid": "$29.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095638",
        "Product Status": "Active"
    },
    {
        "Root": "10083499",
        "LV": "2",
        "Item Description": "MIX SOFT SERVE CHEESECAKE GELATO 6 JUG 1/2 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083499002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-CHEC-11-MX-C",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083499",
        "Product Status": "Active"
    },
    {
        "Root": "10037592",
        "LV": "2",
        "Item Description": "HAM DICED 1/4 IN FROZEN 4/2.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037592002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037592",
        "Product Status": "Active"
    },
    {
        "Root": "10006278",
        "LV": "1",
        "Item Description": "BOARD CAKE 8 INCH X 1/4 INCH GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "E814...{ENJAY{",
        "Last Price Paid": "$0.79",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006278",
        "Product Status": "Active"
    },
    {
        "Root": "10031497",
        "LV": "3",
        "Item Description": "POLLOCK BEER BATTERED 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031497003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6380308",
        "Last Price Paid": "$5.60",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10031497",
        "Product Status": "Active"
    },
    {
        "Root": "10030059",
        "LV": "1",
        "Item Description": "GELATO BLACK RASPBERRY CHOCOLATE CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-BRCP-08-CS4",
        "Last Price Paid": "$10.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030059",
        "Product Status": "Active"
    },
    {
        "Root": "10014360",
        "LV": "1",
        "Item Description": "QUINCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "274111",
        "Last Price Paid": "$55.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014360",
        "Product Status": "Active"
    },
    {
        "Root": "10014360",
        "LV": "1",
        "Item Description": "QUINCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "QUINCE",
        "Last Price Paid": "$55.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014360",
        "Product Status": "Active"
    },
    {
        "Root": "10001674",
        "LV": "1",
        "Item Description": "BEAN GREEN 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401625",
        "Last Price Paid": "$53.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001674",
        "Product Status": "Active"
    },
    {
        "Root": "10001674",
        "LV": "1",
        "Item Description": "BEAN GREEN 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BEANGR",
        "Last Price Paid": "$53.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001674",
        "Product Status": "Active"
    },
    {
        "Root": "10008223",
        "LV": "1",
        "Item Description": "VOUVRAY CHAMPALOU BRT LOIRE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008223",
        "Product Status": "Active"
    },
    {
        "Root": "10005762",
        "LV": "1",
        "Item Description": "BAGEL SESAME SEED MINI 2.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005762",
        "Product Status": "Active"
    },
    {
        "Root": "10105608",
        "LV": "1",
        "Item Description": "BOWL BAGASSE WHITE 16OZ SQUARE BOTTOM COMPOSTABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51004216",
        "Last Price Paid": "$0.11",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105608",
        "Product Status": "Active"
    },
    {
        "Root": "10107875",
        "LV": "1",
        "Item Description": "PEPPER CHILE CALABRIAN IN VIN 6/22.9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1674326",
        "Last Price Paid": "$12.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107875",
        "Product Status": "Active"
    },
    {
        "Root": "10040214",
        "LV": "1",
        "Item Description": "PORK BELLY SKNLS SQR CUT THK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040214001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1455569",
        "Last Price Paid": "$4.17",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040214",
        "Product Status": "Active"
    },
    {
        "Root": "10008217",
        "LV": "1",
        "Item Description": "CHARD LE COLE 41 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008217",
        "Product Status": "Active"
    },
    {
        "Root": "10104857",
        "LV": "2",
        "Item Description": "LID CUP 12/21OZ PLASTIC SLOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104857002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104857",
        "Product Status": "Active"
    },
    {
        "Root": "10004439",
        "LV": "1",
        "Item Description": "CHOC DCOR CINDERELLA SLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004439001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2408B",
        "Last Price Paid": "$0.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004439",
        "Product Status": "Active"
    },
    {
        "Root": "10009667",
        "LV": "3",
        "Item Description": "TEQ CASA NOBLE REPO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009667002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992946",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009667",
        "Product Status": "Active"
    },
    {
        "Root": "10102390",
        "LV": "1",
        "Item Description": "TEQUILA CLASE AZUL ANEJO DIA MUERTOS 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "527280",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102390",
        "Product Status": "Active"
    },
    {
        "Root": "10032838",
        "LV": "2",
        "Item Description": "SUGAR TIE ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032838002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48135",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032838",
        "Product Status": "Active"
    },
    {
        "Root": "10082105",
        "LV": "2",
        "Item Description": "PASTE DESSERT CHERRY 1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082105002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6333110000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10082105",
        "Product Status": "Active"
    },
    {
        "Root": "10041165",
        "LV": "3",
        "Item Description": "SB CAKE POP UNICORN 36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041165002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11125392",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10041165",
        "Product Status": "Active"
    },
    {
        "Root": "10036295",
        "LV": "1",
        "Item Description": "HEDGEHOG MUSHROOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036295001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036295",
        "Product Status": "Active"
    },
    {
        "Root": "10109328",
        "LV": "1",
        "Item Description": "KIDS BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073768",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109328",
        "Product Status": "Active"
    },
    {
        "Root": "10014514",
        "LV": "1",
        "Item Description": "WATER ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "23702",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014514",
        "Product Status": "Active"
    },
    {
        "Root": "10035749",
        "LV": "1",
        "Item Description": "GUMPASTE PETITE FLEUR ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035749",
        "Product Status": "Active"
    },
    {
        "Root": "10029731",
        "LV": "1",
        "Item Description": "FLAX SEED MEAL GOLDEN BOBS RED MILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101940",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029731",
        "Product Status": "Active"
    },
    {
        "Root": "10103935",
        "LV": "1",
        "Item Description": "IMPOSSIBLE CHICKEN PATTIES 5CT/8CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3000000036",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103935",
        "Product Status": "Active"
    },
    {
        "Root": "10013100",
        "LV": "1",
        "Item Description": "LINER 2.4OZ BAKE IN CUP CK63 OVEN SAFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013100001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005689",
        "Last Price Paid": "",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013100",
        "Product Status": "Active"
    },
    {
        "Root": "10003082",
        "LV": "1",
        "Item Description": "SAUCE PIZZA WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003082001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003082",
        "Product Status": "Active"
    },
    {
        "Root": "10020385",
        "LV": "1",
        "Item Description": "CHICKEN GREEN CIRCLE 10 EA 3-3.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020385001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "CHIGRE008",
        "Last Price Paid": "$3.49",
        "Case Pack": "34",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020385",
        "Product Status": "Active"
    },
    {
        "Root": "10037000",
        "LV": "3",
        "Item Description": "SOFT SERVE CHOCOLATE FROSTLINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037000003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.93",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10037000",
        "Product Status": "Active"
    },
    {
        "Root": "10003011",
        "LV": "1",
        "Item Description": "SPICE PEPPER IND 6000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6358832",
        "Last Price Paid": "$0.01",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003011",
        "Product Status": "Active"
    },
    {
        "Root": "10005766",
        "LV": "1",
        "Item Description": "PASTRY DANISH CHERRY LARGE RND WRAPPED 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005766",
        "Product Status": "Active"
    },
    {
        "Root": "10014531",
        "LV": "1",
        "Item Description": "CHESTNUTS FRESH 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403003",
        "Last Price Paid": "$140.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014531",
        "Product Status": "Active"
    },
    {
        "Root": "10000276",
        "LV": "1",
        "Item Description": "ASPARAGUS WHITE HOLLAND BY POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000276001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VA007",
        "Last Price Paid": "$21.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000276",
        "Product Status": "Active"
    },
    {
        "Root": "10004630",
        "LV": "2",
        "Item Description": "SEASONING VEGETABLE MAGIC 21OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004630002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4935862",
        "Last Price Paid": "$15.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004630",
        "Product Status": "Active"
    },
    {
        "Root": "10008030",
        "LV": "1",
        "Item Description": "SPARK GRAHAM BECK BLC BLC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "704482",
        "Last Price Paid": "$21.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008030",
        "Product Status": "Active"
    },
    {
        "Root": "10001856",
        "LV": "1",
        "Item Description": "HERB THYME 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598070",
        "Last Price Paid": "$3.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001856",
        "Product Status": "Active"
    },
    {
        "Root": "10001856",
        "LV": "1",
        "Item Description": "HERB THYME 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001856",
        "Product Status": "Active"
    },
    {
        "Root": "10002426",
        "LV": "2",
        "Item Description": "ICE CREAM STRAWBERRY 3 GAL TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002426002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002426",
        "Product Status": "Active"
    },
    {
        "Root": "10107932",
        "LV": "1",
        "Item Description": "TUNA FZ YF SAKU AAA22#/CS ISL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107932001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000313711",
        "Vendor": "TRUE WORLD FOODS ORLANDO LLC",
        "Vendor Item Nb": "SMT0310003",
        "Last Price Paid": "$20.95",
        "Case Pack": "22",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107932",
        "Product Status": "Active"
    },
    {
        "Root": "10020218",
        "LV": "1",
        "Item Description": "CHARD SKYWALKER SOMMITA VINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "909550",
        "Last Price Paid": "$31.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020218",
        "Product Status": "Active"
    },
    {
        "Root": "10027986",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK COATING PURATOS CARAT 1/25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P4108",
        "Last Price Paid": "$94.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027986",
        "Product Status": "Active"
    },
    {
        "Root": "10003740",
        "LV": "1",
        "Item Description": "SB VERANDA 5LB WB 1-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11019754",
        "Last Price Paid": "$43.35",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003740",
        "Product Status": "Active"
    },
    {
        "Root": "10004583",
        "LV": "1",
        "Item Description": "COLORING FOOD RED 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "761353",
        "Last Price Paid": "$13.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004583",
        "Product Status": "Active"
    },
    {
        "Root": "10092220",
        "LV": "2",
        "Item Description": "CUP  2OZ CLEAR MARTINI GLASS (PS) 120 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092220002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510359",
        "Last Price Paid": "$0.37",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092220",
        "Product Status": "Active"
    },
    {
        "Root": "10002737",
        "LV": "1",
        "Item Description": "SYRUP LEMONADE PINK HI-C 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002737001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "004900098199",
        "Last Price Paid": "$99.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002737",
        "Product Status": "Active"
    },
    {
        "Root": "10000545",
        "LV": "1",
        "Item Description": "BREAD FOCACCIA ROUND 12 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000545",
        "Product Status": "Active"
    },
    {
        "Root": "10106571",
        "LV": "1",
        "Item Description": "COLOR GLITTER EDIBLE BLACK 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "403002B",
        "Last Price Paid": "$8.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106571",
        "Product Status": "Active"
    },
    {
        "Root": "10108448",
        "LV": "1",
        "Item Description": "OYSTER, GULF HALF SHELL IQF 144CT RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8544596",
        "Last Price Paid": "$0.85",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108448",
        "Product Status": "Active"
    },
    {
        "Root": "10001610",
        "LV": "1",
        "Item Description": "HONEYDEW SML DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001610001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-002D-12",
        "Last Price Paid": "$2.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001610",
        "Product Status": "Active"
    },
    {
        "Root": "10001610",
        "LV": "1",
        "Item Description": "HONEYDEW SML DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001610001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "811011",
        "Last Price Paid": "$2.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001610",
        "Product Status": "Active"
    },
    {
        "Root": "10002612",
        "LV": "1",
        "Item Description": "LIME WEDGES 6 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "31-005",
        "Last Price Paid": "$16.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002612",
        "Product Status": "Active"
    },
    {
        "Root": "10002612",
        "LV": "1",
        "Item Description": "LIME WEDGES 6 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "602099",
        "Last Price Paid": "$16.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002612",
        "Product Status": "Active"
    },
    {
        "Root": "10036403",
        "LV": "1",
        "Item Description": "MILK ALMOND ORIGINAL DF BLUE DIAMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "93398",
        "Last Price Paid": "$2.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036403",
        "Product Status": "Active"
    },
    {
        "Root": "10009214",
        "LV": "1",
        "Item Description": "RED PAHLMEYER JAYSON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9420981",
        "Last Price Paid": "$43.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009214",
        "Product Status": "Active"
    },
    {
        "Root": "10005164",
        "LV": "1",
        "Item Description": "CUP ECLAIR OVAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005164001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005164",
        "Product Status": "Active"
    },
    {
        "Root": "10031446",
        "LV": "1",
        "Item Description": "MUSHROOM OYSTER LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480951",
        "Last Price Paid": "$25.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031446",
        "Product Status": "Active"
    },
    {
        "Root": "10006976",
        "LV": "1",
        "Item Description": "CHARD HOGUE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2172047",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006976",
        "Product Status": "Active"
    },
    {
        "Root": "10011582",
        "LV": "1",
        "Item Description": "DAIKON JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "40-006J",
        "Last Price Paid": "$16.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011582",
        "Product Status": "Active"
    },
    {
        "Root": "10011582",
        "LV": "1",
        "Item Description": "DAIKON JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510532",
        "Last Price Paid": "$16.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011582",
        "Product Status": "Active"
    },
    {
        "Root": "10106150",
        "LV": "2",
        "Item Description": "CONTAINER COLD-HOT 6OZ WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106150002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106150",
        "Product Status": "Active"
    },
    {
        "Root": "10003505",
        "LV": "2",
        "Item Description": "TEA VAN ROOIBOS 14.1OZ LS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003505001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W1111",
        "Last Price Paid": "$34.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003505",
        "Product Status": "Active"
    },
    {
        "Root": "10101374",
        "LV": "1",
        "Item Description": "PASTRY CHOCOLATE TORSADE TWIST RTB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.81",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101374",
        "Product Status": "Active"
    },
    {
        "Root": "10039632",
        "LV": "1",
        "Item Description": "BOX ORANGE 5 PC W/ CLEAR LID 25/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51425007",
        "Last Price Paid": "$1.01",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039632",
        "Product Status": "Active"
    },
    {
        "Root": "10003304",
        "LV": "2",
        "Item Description": "QUIN HEART MINI 8461 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003304002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8461",
        "Last Price Paid": "$10.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003304",
        "Product Status": "Active"
    },
    {
        "Root": "10010220",
        "LV": "2",
        "Item Description": "DRAFT ABITA PURPLE HAZE HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010220001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14161",
        "Last Price Paid": "$170.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010220",
        "Product Status": "Active"
    },
    {
        "Root": "10005161",
        "LV": "1",
        "Item Description": "IRIGOMA (WHITE)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005161001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "11011",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005161",
        "Product Status": "Active"
    },
    {
        "Root": "10102655",
        "LV": "1",
        "Item Description": "KETCHUP TOMATO FANCY SS FOIL PACKET SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1572874",
        "Last Price Paid": "$27.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102655",
        "Product Status": "Active"
    },
    {
        "Root": "10001931",
        "LV": "1",
        "Item Description": "EGGPLANT DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "39-005-34",
        "Last Price Paid": "$10.33",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001931",
        "Product Status": "Active"
    },
    {
        "Root": "10001931",
        "LV": "1",
        "Item Description": "EGGPLANT DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511045",
        "Last Price Paid": "$10.33",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001931",
        "Product Status": "Active"
    },
    {
        "Root": "10006868",
        "LV": "1",
        "Item Description": "CHAMP MOET CHND IMP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006868",
        "Product Status": "Active"
    },
    {
        "Root": "10006868",
        "LV": "1",
        "Item Description": "CHAMP MOET CHND IMP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "248843",
        "Last Price Paid": "$31.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006868",
        "Product Status": "Active"
    },
    {
        "Root": "10086533",
        "LV": "2",
        "Item Description": "TEST TUBE DRINK STARWARS 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086533002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.35",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10086533",
        "Product Status": "Active"
    },
    {
        "Root": "10002308",
        "LV": "1",
        "Item Description": "CHEESE, VEGAN CHEDD SHRD DAIYA 8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3DFU01-101011",
        "Last Price Paid": "$3.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002308",
        "Product Status": "Active"
    },
    {
        "Root": "10012622",
        "LV": "1",
        "Item Description": "FLOWERS CITRUS BEGONIA 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05CBG-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012622",
        "Product Status": "Active"
    },
    {
        "Root": "10102926",
        "LV": "1",
        "Item Description": "KOSHER BSC FORK & KNIFE SILVER 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.36",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102926",
        "Product Status": "Active"
    },
    {
        "Root": "10001746",
        "LV": "1",
        "Item Description": "RHUBARB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "482184",
        "Last Price Paid": "$130.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001746",
        "Product Status": "Active"
    },
    {
        "Root": "10001746",
        "LV": "1",
        "Item Description": "RHUBARB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482184",
        "Last Price Paid": "$130.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001746",
        "Product Status": "Active"
    },
    {
        "Root": "10034065",
        "LV": "1",
        "Item Description": "ICE CREAM DF CHOC CHIP COOKIE DOUGH HALO TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "224832",
        "Last Price Paid": "$7.02",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034065",
        "Product Status": "Active"
    },
    {
        "Root": "10029767",
        "LV": "1",
        "Item Description": "BLACK GRAPES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260100",
        "Last Price Paid": "$49.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029767",
        "Product Status": "Active"
    },
    {
        "Root": "10033585",
        "LV": "1",
        "Item Description": "LEMONGRASS PESTO 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "LGP12/34",
        "Last Price Paid": "$14.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033585",
        "Product Status": "Active"
    },
    {
        "Root": "10008137",
        "LV": "1",
        "Item Description": "CAB SAUV HEITZ NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008137001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "567480",
        "Last Price Paid": "$57.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008137",
        "Product Status": "Active"
    },
    {
        "Root": "10000974",
        "LV": "1",
        "Item Description": "MACKERAL TINKER 170/200 SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000974001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50035",
        "Last Price Paid": "$1.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000974",
        "Product Status": "Active"
    },
    {
        "Root": "10000974",
        "LV": "1",
        "Item Description": "MACKERAL TINKER 170/200 SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000974001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6374120",
        "Last Price Paid": "$1.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000974",
        "Product Status": "Active"
    },
    {
        "Root": "10026005",
        "LV": "1",
        "Item Description": "POTATO SWEET CROWN JEWEL LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026005001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01CJP-2",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026005",
        "Product Status": "Active"
    },
    {
        "Root": "10003780",
        "LV": "1",
        "Item Description": "MIX, ALFREDO SAUCE PKU 5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AMBROOKE FOODS",
        "Last Price Paid": "$19.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003780",
        "Product Status": "Active"
    },
    {
        "Root": "10106573",
        "LV": "1",
        "Item Description": "COLOR GLITTER EDIBLE CLEAR 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "403005B",
        "Last Price Paid": "$8.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106573",
        "Product Status": "Active"
    },
    {
        "Root": "10106169",
        "LV": "1",
        "Item Description": "WHOLE REDFISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106169001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "64-83301",
        "Last Price Paid": "$7.91",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106169",
        "Product Status": "Active"
    },
    {
        "Root": "10045318",
        "LV": "1",
        "Item Description": "BEER KEG SIERRA NEVADA SEASONAL 15.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "51556",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045318",
        "Product Status": "Active"
    },
    {
        "Root": "10041055",
        "LV": "1",
        "Item Description": "CHEESE GOUDA REYPENEAR 2 YEAR VSOP 6 LB 1/4 WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041055001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "39518",
        "Last Price Paid": "$16.41",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041055",
        "Product Status": "Active"
    },
    {
        "Root": "10022695",
        "LV": "2",
        "Item Description": "SB BAG WARMING DISNEY NEW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022695002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$102.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10022695",
        "Product Status": "Active"
    },
    {
        "Root": "10005302",
        "LV": "1",
        "Item Description": "DOUGH EMPANADA SHELL 7 INCH 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5572425",
        "Last Price Paid": "$0.17",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005302",
        "Product Status": "Active"
    },
    {
        "Root": "10002346",
        "LV": "1",
        "Item Description": "GELATO HAZELNUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-HZNT-08-HP",
        "Last Price Paid": "$10.87",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002346",
        "Product Status": "Active"
    },
    {
        "Root": "10104149",
        "LV": "1",
        "Item Description": "CHICKEN BREAST BONELESS SKINLESS 6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104149001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.97",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104149",
        "Product Status": "Active"
    },
    {
        "Root": "10003680",
        "LV": "1",
        "Item Description": "CANDY PLAIN M&M KS 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003680001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534100",
        "Last Price Paid": "$2.59",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003680",
        "Product Status": "Active"
    },
    {
        "Root": "10003328",
        "LV": "1",
        "Item Description": "GEL LIQUID SKY BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22510",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003328",
        "Product Status": "Active"
    },
    {
        "Root": "10014636",
        "LV": "1",
        "Item Description": "PINOT NOIR CLOUDY BAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555351",
        "Last Price Paid": "$34.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014636",
        "Product Status": "Active"
    },
    {
        "Root": "10004604",
        "LV": "1",
        "Item Description": "FILLING PUMPKIN SOLID PAC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9009986",
        "Last Price Paid": "$7.81",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004604",
        "Product Status": "Active"
    },
    {
        "Root": "10006212",
        "LV": "1",
        "Item Description": "PAPER BUTCHER WHITE 30 INCH RO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5362231",
        "Last Price Paid": "$38.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006212",
        "Product Status": "Active"
    },
    {
        "Root": "10002244",
        "LV": "1",
        "Item Description": "CHEESE CRM PLN LOAF 10/3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8340861",
        "Last Price Paid": "$8.51",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002244",
        "Product Status": "Active"
    },
    {
        "Root": "10089246",
        "LV": "2",
        "Item Description": "CHICKEN WOG CITRUS MARINATED 2.25-2.50 LB10089246",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089246001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.91",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10089246",
        "Product Status": "Active"
    },
    {
        "Root": "10000192",
        "LV": "1",
        "Item Description": "POTATO RED B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000192001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481586",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000192",
        "Product Status": "Active"
    },
    {
        "Root": "10012459",
        "LV": "1",
        "Item Description": "DEC CHERRY BLOSSOM ASST. 1\"-1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012459001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12359",
        "Last Price Paid": "$0.76",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012459",
        "Product Status": "Active"
    },
    {
        "Root": "10035059",
        "LV": "1",
        "Item Description": "DELI INTERFOLD PAPERS 016008 NK8 ECOCRAFT 8X10.7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "8559",
        "Last Price Paid": "",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035059",
        "Product Status": "Active"
    },
    {
        "Root": "10006181",
        "LV": "1",
        "Item Description": "CUBE GLOW BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006181001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006181",
        "Product Status": "Active"
    },
    {
        "Root": "10014264",
        "LV": "1",
        "Item Description": "COASTER HANGER DRK HORSE OVAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-027",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014264",
        "Product Status": "Active"
    },
    {
        "Root": "10083985",
        "LV": "1",
        "Item Description": "LID DOME FOR 42-85OZ BUCKETS ANTI-FOG VENTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "15501",
        "Last Price Paid": "",
        "Case Pack": "360",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083985",
        "Product Status": "Active"
    },
    {
        "Root": "10003726",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE MICKEY/MINNIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3010",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003726",
        "Product Status": "Active"
    },
    {
        "Root": "10027426",
        "LV": "1",
        "Item Description": "CUP ESPNWWS BEER LEMON YELLOW 22 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G22-LEMON YEL",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027426",
        "Product Status": "Active"
    },
    {
        "Root": "10108748",
        "LV": "1",
        "Item Description": "STARFRUIT CHIP 100 COUNT DRESS THE DRINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "STRG-01",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108748",
        "Product Status": "Active"
    },
    {
        "Root": "10004577",
        "LV": "1",
        "Item Description": "JELLO RED ASST SF 2.75Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1371590",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004577",
        "Product Status": "Active"
    },
    {
        "Root": "10038873",
        "LV": "1",
        "Item Description": "FOIL SMOOTH PEACH 12X12 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51425027",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038873",
        "Product Status": "Active"
    },
    {
        "Root": "10013085",
        "LV": "3",
        "Item Description": "GUM PASTE ELEGANT HIGH HEEL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013085002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "18519",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10013085",
        "Product Status": "Active"
    },
    {
        "Root": "10088236",
        "LV": "1",
        "Item Description": "STEAMED BUN FILLED W/CHICKEN AND VEGETABLES ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.43",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088236",
        "Product Status": "Active"
    },
    {
        "Root": "10013427",
        "LV": "1",
        "Item Description": "SQUASH YELLOW #5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598685",
        "Last Price Paid": "$11.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013427",
        "Product Status": "Active"
    },
    {
        "Root": "10105041",
        "LV": "1",
        "Item Description": "WINE WHITE CHAR EVENING LANDING 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "206183",
        "Last Price Paid": "$27.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105041",
        "Product Status": "Active"
    },
    {
        "Root": "10105041",
        "LV": "1",
        "Item Description": "WINE WHITE CHAR EVENING LANDING 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "206183",
        "Last Price Paid": "$27.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105041",
        "Product Status": "Active"
    },
    {
        "Root": "10004320",
        "LV": "1",
        "Item Description": "HORS CHIC WELLINGTON MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300309",
        "Last Price Paid": "$1.39",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004320",
        "Product Status": "Active"
    },
    {
        "Root": "10003000",
        "LV": "1",
        "Item Description": "POTATO FLAKES INSTANT LOW SODIUM 5LB 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003000001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "366877",
        "Last Price Paid": "$15.30",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003000",
        "Product Status": "Active"
    },
    {
        "Root": "10005574",
        "LV": "3",
        "Item Description": "PIZZA CRUST PERSONAL 5.5Z/7INCH 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005574003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4364600",
        "Last Price Paid": "$0.89",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10005574",
        "Product Status": "Active"
    },
    {
        "Root": "10004520",
        "LV": "1",
        "Item Description": "VINEGAR WHITE BALSAMIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "V304...{VIGO{",
        "Last Price Paid": "$4.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004520",
        "Product Status": "Active"
    },
    {
        "Root": "10005452",
        "LV": "1",
        "Item Description": "SUSHI NIGIRI YELLOWTAIL 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005452",
        "Product Status": "Active"
    },
    {
        "Root": "10092223",
        "LV": "1",
        "Item Description": "TUNA FILLETS PLANT-BASED KULEANA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "703-001250A",
        "Last Price Paid": "$6.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092223",
        "Product Status": "Active"
    },
    {
        "Root": "10006189",
        "LV": "1",
        "Item Description": "STRAW PAPER JUMBO 7.75 UN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2713980",
        "Last Price Paid": "$0.01",
        "Case Pack": "14400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006189",
        "Product Status": "Active"
    },
    {
        "Root": "10021516",
        "LV": "1",
        "Item Description": "NUT BRAZIL WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "180201",
        "Last Price Paid": "$79.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021516",
        "Product Status": "Active"
    },
    {
        "Root": "10002791",
        "LV": "1",
        "Item Description": "COFFEE INROOM DECAF 500CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002791",
        "Product Status": "Active"
    },
    {
        "Root": "10080958",
        "LV": "1",
        "Item Description": "SYRUP MM STRAWBERRY HIBISCUS 2.5GAL BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10080958",
        "Product Status": "Active"
    },
    {
        "Root": "10008062",
        "LV": "1",
        "Item Description": "CHARD AU BON CLIMAT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "159428",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008062",
        "Product Status": "Active"
    },
    {
        "Root": "10027475",
        "LV": "1",
        "Item Description": "YUCCA CHIPS 1/8 2/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510521",
        "Last Price Paid": "$22.73",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027475",
        "Product Status": "Active"
    },
    {
        "Root": "10033720",
        "LV": "1",
        "Item Description": "BURRITO BOWL BO SC UBB 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033720001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11400033",
        "Last Price Paid": "$0.20",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033720",
        "Product Status": "Active"
    },
    {
        "Root": "10013483",
        "LV": "1",
        "Item Description": "GARNACHA TORRES SANGRE DE TORO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "927513",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013483",
        "Product Status": "Active"
    },
    {
        "Root": "10031766",
        "LV": "1",
        "Item Description": "ASIAN STEPHANOTIS FILLERS LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AGP101 LV",
        "Last Price Paid": "$2.96",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031766",
        "Product Status": "Active"
    },
    {
        "Root": "10093213",
        "LV": "1",
        "Item Description": "WHISKEY COCKTAIL RTD SOCIAL HR MULE 250ML/24CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1000",
        "Last Price Paid": "$3.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093213",
        "Product Status": "Active"
    },
    {
        "Root": "10002869",
        "LV": "1",
        "Item Description": "COKE CHERRY 20OZ BTL 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002869",
        "Product Status": "Active"
    },
    {
        "Root": "10023728",
        "LV": "1",
        "Item Description": "CHOC WHT CINDERELLA CARRIAGE DESSERT SHELL 3 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1749",
        "Last Price Paid": "$2.35",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023728",
        "Product Status": "Active"
    },
    {
        "Root": "10000877",
        "LV": "1",
        "Item Description": "DUCK LIVER RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000877001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000877",
        "Product Status": "Active"
    },
    {
        "Root": "10002667",
        "LV": "1",
        "Item Description": "SPICE BBQ BULK 4-6LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002667",
        "Product Status": "Active"
    },
    {
        "Root": "10027877",
        "LV": "1",
        "Item Description": "LIQ COMBIER CASSIS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9211240",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027877",
        "Product Status": "Active"
    },
    {
        "Root": "10022395",
        "LV": "1",
        "Item Description": "CHOC WHT SQ 1.25 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63203W",
        "Last Price Paid": "$0.95",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022395",
        "Product Status": "Active"
    },
    {
        "Root": "10018674",
        "LV": "1",
        "Item Description": "RICE VIALONE NANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "RI021",
        "Last Price Paid": "$11.30",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018674",
        "Product Status": "Active"
    },
    {
        "Root": "10005627",
        "LV": "2",
        "Item Description": "BREAD SANDWICH LUAU 4.5 OZ 45 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005627002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.55",
        "Case Pack": "52",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005627",
        "Product Status": "Active"
    },
    {
        "Root": "10003022",
        "LV": "1",
        "Item Description": "DRESSING HONEY MUSTARD 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003022",
        "Product Status": "Active"
    },
    {
        "Root": "10006376",
        "LV": "1",
        "Item Description": "FOIL 24 INCH X1000 HVY WT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8007049",
        "Last Price Paid": "$207.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006376",
        "Product Status": "Active"
    },
    {
        "Root": "10002872",
        "LV": "1",
        "Item Description": "POWERADE FRUIT PUNCH 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002872",
        "Product Status": "Active"
    },
    {
        "Root": "10000761",
        "LV": "1",
        "Item Description": "VEAL TENDERLOIN PSMO 1.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000761001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1347215",
        "Last Price Paid": "$17.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000761",
        "Product Status": "Active"
    },
    {
        "Root": "10003578",
        "LV": "2",
        "Item Description": "TEA ORGC HEALTH GRN 400G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003578001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W6003",
        "Last Price Paid": "$33.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003578",
        "Product Status": "Active"
    },
    {
        "Root": "10093649",
        "LV": "1",
        "Item Description": "CAKE BALLS CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.75",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093649",
        "Product Status": "Active"
    },
    {
        "Root": "10005291",
        "LV": "1",
        "Item Description": "AMMONIUM CARBONATE 5# CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "3...{KEYSTONE{",
        "Last Price Paid": "$56.31",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005291",
        "Product Status": "Active"
    },
    {
        "Root": "10091613",
        "LV": "1",
        "Item Description": "BOWL WHITE COUPE BG-BL16-C 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091613001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001475",
        "Last Price Paid": "$0.14",
        "Case Pack": "800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091613",
        "Product Status": "Active"
    },
    {
        "Root": "10103510",
        "LV": "1",
        "Item Description": "FLOUR HIGH GLUTEN BLEACHED KYROL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6685689",
        "Last Price Paid": "$24.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103510",
        "Product Status": "Active"
    },
    {
        "Root": "10004726",
        "LV": "1",
        "Item Description": "CHIP CORN FRITOS 2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5597968",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004726",
        "Product Status": "Active"
    },
    {
        "Root": "10000633",
        "LV": "1",
        "Item Description": "BEEF PASTRAMI NAVEL COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000633001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3101055",
        "Last Price Paid": "$11.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000633",
        "Product Status": "Active"
    },
    {
        "Root": "10005273",
        "LV": "1",
        "Item Description": "JELLO STRAWBERRY 24Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9370669",
        "Last Price Paid": "$3.61",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005273",
        "Product Status": "Active"
    },
    {
        "Root": "10102328",
        "LV": "1",
        "Item Description": "GHIRARDELLI WHITE CHOCOLATE SAUCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "707530",
        "Last Price Paid": "$14.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102328",
        "Product Status": "Active"
    },
    {
        "Root": "10086392",
        "LV": "1",
        "Item Description": "VINEGAR WHITE BALSAMIC IMPORTED FROM ITALY 2/5LT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3179454",
        "Last Price Paid": "$20.21",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086392",
        "Product Status": "Active"
    },
    {
        "Root": "10035389",
        "LV": "1",
        "Item Description": "VEAL HIND SHANK CENTER CUT 1 INCH OSSO BUCCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035389001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1338226",
        "Last Price Paid": "$9.72",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035389",
        "Product Status": "Active"
    },
    {
        "Root": "10003564",
        "LV": "2",
        "Item Description": "SPICE POPPY SEED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003564002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.30",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003564",
        "Product Status": "Active"
    },
    {
        "Root": "10028503",
        "LV": "1",
        "Item Description": "LIQUEUR ANCHO REYES VERDE POBLANO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9238522",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028503",
        "Product Status": "Active"
    },
    {
        "Root": "10036106",
        "LV": "1",
        "Item Description": "SPARKLING PATA NEGRA ORGANIC BRUT CAVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "446987",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036106",
        "Product Status": "Active"
    },
    {
        "Root": "10037818",
        "LV": "1",
        "Item Description": "PEPPERS MIX W RED ONION DICED 1.4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037818001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-017-2",
        "Last Price Paid": "$2.30",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037818",
        "Product Status": "Active"
    },
    {
        "Root": "10109273",
        "LV": "1",
        "Item Description": "DISNEY HOLIDAY ORNAMT DONALD 3.5X3.5CM 3000 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175920000",
        "Last Price Paid": "$0.14",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109273",
        "Product Status": "Active"
    },
    {
        "Root": "10010176",
        "LV": "1",
        "Item Description": "WATER ZEPHRHIL SPRING 6/GAL/PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1186881",
        "Last Price Paid": "$1.94",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010176",
        "Product Status": "Active"
    },
    {
        "Root": "10001090",
        "LV": "1",
        "Item Description": "LOBSTER TAIL C/W 6-7 OZ SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001090001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "LWW421106",
        "Last Price Paid": "$28.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001090",
        "Product Status": "Active"
    },
    {
        "Root": "10001090",
        "LV": "1",
        "Item Description": "LOBSTER TAIL C/W 6-7 OZ SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001090001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "79-02007",
        "Last Price Paid": "$28.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001090",
        "Product Status": "Active"
    },
    {
        "Root": "10001090",
        "LV": "1",
        "Item Description": "LOBSTER TAIL C/W 6-7 OZ SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001090001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6474402",
        "Last Price Paid": "$28.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001090",
        "Product Status": "Active"
    },
    {
        "Root": "10010112",
        "LV": "2",
        "Item Description": "WATER SARATOGA SPRK 355ML 24BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010112001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010112",
        "Product Status": "Active"
    },
    {
        "Root": "10003144",
        "LV": "1",
        "Item Description": "SYRUP WHT CHOC MONIN 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1063",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003144",
        "Product Status": "Active"
    },
    {
        "Root": "10036563",
        "LV": "1",
        "Item Description": "14ECOKRAFT 8.5X12 KRAFT LINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036563001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.01",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036563",
        "Product Status": "Active"
    },
    {
        "Root": "10009628",
        "LV": "2",
        "Item Description": "TEQ AZUL REPOSADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009628002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "122928",
        "Last Price Paid": "$117.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009628",
        "Product Status": "Active"
    },
    {
        "Root": "10019384",
        "LV": "1",
        "Item Description": "TEA LOOSE GOLDEN TIPPED ENGLISH BF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13702",
        "Last Price Paid": "$31.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019384",
        "Product Status": "Active"
    },
    {
        "Root": "10106148",
        "LV": "1",
        "Item Description": "SORBITOL POWDER 1.1 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "501616D",
        "Last Price Paid": "$15.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106148",
        "Product Status": "Active"
    },
    {
        "Root": "10029362",
        "LV": "2",
        "Item Description": "CHARDONNAY PLANETA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029362002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "565602",
        "Last Price Paid": "$28.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029362",
        "Product Status": "Active"
    },
    {
        "Root": "10000661",
        "LV": "1",
        "Item Description": "PORK BELLY BERKSHIRE SKINLESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000661001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1421400",
        "Last Price Paid": "$8.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000661",
        "Product Status": "Active"
    },
    {
        "Root": "10036793",
        "LV": "1",
        "Item Description": "DRIED BLUEBERRIES NATIERRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036793001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "114966",
        "Last Price Paid": "$4.86",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036793",
        "Product Status": "Active"
    },
    {
        "Root": "10002151",
        "LV": "1",
        "Item Description": "BUTTER BALL UNSALTED .25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "1152",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002151",
        "Product Status": "Active"
    },
    {
        "Root": "10002093",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002093001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "M110",
        "Last Price Paid": "$13.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002093",
        "Product Status": "Active"
    },
    {
        "Root": "10002093",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002093001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50070",
        "Last Price Paid": "$13.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002093",
        "Product Status": "Active"
    },
    {
        "Root": "10004847",
        "LV": "1",
        "Item Description": "VINEGAR CHAMPAGNE 17.6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "J LEBLANC",
        "Last Price Paid": "$16.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004847",
        "Product Status": "Active"
    },
    {
        "Root": "10000131",
        "LV": "1",
        "Item Description": "CHICKEN WING JUMBO 2 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000131001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.93",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000131",
        "Product Status": "Active"
    },
    {
        "Root": "10104015",
        "LV": "1",
        "Item Description": "SB TOPPING CARAMEL RIBBON CRUNCH 6OZ SHAKER 8 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011130179",
        "Last Price Paid": "$6.00",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104015",
        "Product Status": "Active"
    },
    {
        "Root": "10040222",
        "LV": "2",
        "Item Description": "MOSCATO FIERA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040222002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040222",
        "Product Status": "Active"
    },
    {
        "Root": "10002127",
        "LV": "1",
        "Item Description": "CHEESE FETA 2/9#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002127001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.51",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002127",
        "Product Status": "Active"
    },
    {
        "Root": "10002071",
        "LV": "1",
        "Item Description": "CABBAGE RED-POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002071001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002071",
        "Product Status": "Active"
    },
    {
        "Root": "10003661",
        "LV": "1",
        "Item Description": "CREAM PASTRY HOT PROCESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003661001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA5326",
        "Last Price Paid": "$95.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003661",
        "Product Status": "Active"
    },
    {
        "Root": "10082856",
        "LV": "1",
        "Item Description": "BAG GANACHERY HANDLES BROWN 7.5X8X6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51014246",
        "Last Price Paid": "$1.12",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082856",
        "Product Status": "Active"
    },
    {
        "Root": "10010665",
        "LV": "2",
        "Item Description": "TEQ CASAMIGOS REPO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010665001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "446086",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010665",
        "Product Status": "Active"
    },
    {
        "Root": "10091688",
        "LV": "1",
        "Item Description": "LIQUOR SOMRUS MANGO CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9256326",
        "Last Price Paid": "$19.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091688",
        "Product Status": "Active"
    },
    {
        "Root": "10003339",
        "LV": "1",
        "Item Description": "TEA TWNG WNTR SPICE 120CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003339",
        "Product Status": "Active"
    },
    {
        "Root": "10010058",
        "LV": "1",
        "Item Description": "BEER BL MOON BELGM WHT CN 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010058",
        "Product Status": "Active"
    },
    {
        "Root": "10035831",
        "LV": "1",
        "Item Description": "TONIC WATER FEVER TREE MEDITERRANEAN 200ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035831001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "598153",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035831",
        "Product Status": "Active"
    },
    {
        "Root": "10030143",
        "LV": "1",
        "Item Description": "CHAMPAGNE RUINART BLANC DE BLANC  375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "956801",
        "Last Price Paid": "$46.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030143",
        "Product Status": "Active"
    },
    {
        "Root": "10009731",
        "LV": "2",
        "Item Description": "VODKA DEEP EDDY RD GRPFRT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009731001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "415305",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009731",
        "Product Status": "Active"
    },
    {
        "Root": "10019218",
        "LV": "1",
        "Item Description": "WHITE BLEND SARTORI MARANI VERONESE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "993568",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019218",
        "Product Status": "Active"
    },
    {
        "Root": "10029818",
        "LV": "1",
        "Item Description": "LIQUEUR LAZZARONI MARASCHINO CHERRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "303971",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029818",
        "Product Status": "Active"
    },
    {
        "Root": "10030529",
        "LV": "1",
        "Item Description": "LINER CARNIVAL PRINT 12X12 FDP12CARN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51126010",
        "Last Price Paid": "",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030529",
        "Product Status": "Active"
    },
    {
        "Root": "10104608",
        "LV": "1",
        "Item Description": "PASSOVER KIT - TSU WITH OMELET CASSEROLE 3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104608",
        "Product Status": "Active"
    },
    {
        "Root": "10039917",
        "LV": "1",
        "Item Description": "BEER BUDWEISER COPPER LAGER 12Z BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039917",
        "Product Status": "Active"
    },
    {
        "Root": "10017646",
        "LV": "1",
        "Item Description": "KRILL PACIFICA SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017646001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50028",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017646",
        "Product Status": "Active"
    },
    {
        "Root": "10003537",
        "LV": "1",
        "Item Description": "COFFEE POD DOUBLE 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003537",
        "Product Status": "Active"
    },
    {
        "Root": "10002942",
        "LV": "1",
        "Item Description": "SPRAY CANOLA FD RELSE 17Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002942",
        "Product Status": "Active"
    },
    {
        "Root": "10040811",
        "LV": "1",
        "Item Description": "BOWL MING SUGARCANE 23.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11009949",
        "Last Price Paid": "$0.19",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040811",
        "Product Status": "Active"
    },
    {
        "Root": "10044386",
        "LV": "1",
        "Item Description": "COOKIE, EPCOT, ALMOND CHERRY TOPPED 53/3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4940500",
        "Last Price Paid": "$1.59",
        "Case Pack": "53",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044386",
        "Product Status": "Active"
    },
    {
        "Root": "10038815",
        "LV": "1",
        "Item Description": "MUFFIN BANANA LARGE 6.5 OUNCE INDV WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038815",
        "Product Status": "Active"
    },
    {
        "Root": "10028166",
        "LV": "2",
        "Item Description": "BEER SAPPORO BLACK LAGER 22 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028166001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "33980",
        "Last Price Paid": "$3.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10028166",
        "Product Status": "Active"
    },
    {
        "Root": "10035218",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861980000",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035218",
        "Product Status": "Active"
    },
    {
        "Root": "10008008",
        "LV": "1",
        "Item Description": "DESSERT CONSTANCE KLEIN VIN 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "537045",
        "Last Price Paid": "$91.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008008",
        "Product Status": "Active"
    },
    {
        "Root": "10000237",
        "LV": "3",
        "Item Description": "FRUIT CUP RED GRAPE 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000237003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489758",
        "Last Price Paid": "$2.14",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10000237",
        "Product Status": "Active"
    },
    {
        "Root": "10036007",
        "LV": "2",
        "Item Description": "GIGANDES BEANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036007002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "508311",
        "Last Price Paid": "$11.79",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036007",
        "Product Status": "Active"
    },
    {
        "Root": "10016982",
        "LV": "1",
        "Item Description": "SAUCE CHOC DRK MONIN 4/64 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.60",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016982",
        "Product Status": "Active"
    },
    {
        "Root": "10003458",
        "LV": "1",
        "Item Description": "BAMBOO SHOOT SLCD #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7330194",
        "Last Price Paid": "$8.37",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003458",
        "Product Status": "Active"
    },
    {
        "Root": "10000214",
        "LV": "1",
        "Item Description": "SQUASH YELLOW CNCK SMALL 16# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "409050",
        "Last Price Paid": "$20.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000214",
        "Product Status": "Active"
    },
    {
        "Root": "10005705",
        "LV": "1",
        "Item Description": "DOUGHNUT FRENCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005705001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "200FD",
        "Last Price Paid": "$0.87",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005705",
        "Product Status": "Active"
    },
    {
        "Root": "10007016",
        "LV": "1",
        "Item Description": "SAUV BLC LOST SLIPPR OUAV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007016",
        "Product Status": "Active"
    },
    {
        "Root": "10004822",
        "LV": "1",
        "Item Description": "SAUCE TABASCO 1/8Z 144CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6049985",
        "Last Price Paid": "$0.38",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004822",
        "Product Status": "Active"
    },
    {
        "Root": "10003184",
        "LV": "1",
        "Item Description": "QUIN MICKEY BLACK RED AND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003184001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9481",
        "Last Price Paid": "$14.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003184",
        "Product Status": "Active"
    },
    {
        "Root": "10027612",
        "LV": "1",
        "Item Description": "KENS MAYO GOURMET 4/1 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "KE0925",
        "Last Price Paid": "$10.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027612",
        "Product Status": "Active"
    },
    {
        "Root": "10002144",
        "LV": "1",
        "Item Description": "MARGARINE CUP 5GRAM SMART BALANCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4996260",
        "Last Price Paid": "$0.04",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002144",
        "Product Status": "Active"
    },
    {
        "Root": "10001699",
        "LV": "1",
        "Item Description": "HERB MARJORAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001699001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450050",
        "Last Price Paid": "$5.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001699",
        "Product Status": "Active"
    },
    {
        "Root": "10001699",
        "LV": "1",
        "Item Description": "HERB MARJORAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001699001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001699",
        "Product Status": "Active"
    },
    {
        "Root": "10001699",
        "LV": "1",
        "Item Description": "HERB MARJORAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001699001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24100",
        "Last Price Paid": "$5.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001699",
        "Product Status": "Active"
    },
    {
        "Root": "10004154",
        "LV": "1",
        "Item Description": "BEAN VANILLA 8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "T900",
        "Last Price Paid": "$100.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004154",
        "Product Status": "Active"
    },
    {
        "Root": "10036709",
        "LV": "1",
        "Item Description": "LINER CRAFT BASKET 12X12 1C1S SPOT PRINTED 35# PPR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45002767",
        "Last Price Paid": "$0.10",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036709",
        "Product Status": "Active"
    },
    {
        "Root": "10004148",
        "LV": "1",
        "Item Description": "TRUFFLE KEY LIME 108/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "13136",
        "Last Price Paid": "$1.08",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004148",
        "Product Status": "Active"
    },
    {
        "Root": "10012116",
        "LV": "1",
        "Item Description": "SNAPPER RED FILLET WILD SUSTAINABL SKIN ON SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012116001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "SNA120006",
        "Last Price Paid": "$21.75",
        "Case Pack": "1.08",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012116",
        "Product Status": "Active"
    },
    {
        "Root": "10012116",
        "LV": "1",
        "Item Description": "SNAPPER RED FILLET WILD SUSTAINABL SKIN ON SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012116001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00832",
        "Last Price Paid": "$21.75",
        "Case Pack": "1.08",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012116",
        "Product Status": "Active"
    },
    {
        "Root": "10012116",
        "LV": "1",
        "Item Description": "SNAPPER RED FILLET WILD SUSTAINABL SKIN ON SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012116001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6985509",
        "Last Price Paid": "$21.75",
        "Case Pack": "1.08",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012116",
        "Product Status": "Active"
    },
    {
        "Root": "10016836",
        "LV": "1",
        "Item Description": "SHEET ACETATE CLR 16X24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U624",
        "Last Price Paid": "$1.02",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016836",
        "Product Status": "Active"
    },
    {
        "Root": "10032958",
        "LV": "1",
        "Item Description": "SAUSAGE CHICKEN BREAKFAST 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3650020",
        "Last Price Paid": "$0.47",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032958",
        "Product Status": "Active"
    },
    {
        "Root": "10102705",
        "LV": "1",
        "Item Description": "BAR PEANUT BUTTER CLIF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102705001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302472",
        "Last Price Paid": "$1.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102705",
        "Product Status": "Active"
    },
    {
        "Root": "10008081",
        "LV": "1",
        "Item Description": "CAB SAUV JOS PHELPS NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "582158",
        "Last Price Paid": "$65.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008081",
        "Product Status": "Active"
    },
    {
        "Root": "10005208",
        "LV": "1",
        "Item Description": "SB PUMPKIN TOPPING 16OZ 1-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "183929",
        "Last Price Paid": "$14.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005208",
        "Product Status": "Active"
    },
    {
        "Root": "10030237",
        "LV": "1",
        "Item Description": "PUMPKIN HEIRLOOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030237001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481843",
        "Last Price Paid": "$7.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030237",
        "Product Status": "Active"
    },
    {
        "Root": "10002764",
        "LV": "1",
        "Item Description": "COFFEE ESPRESSO W BEAN 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002764",
        "Product Status": "Active"
    },
    {
        "Root": "10004419",
        "LV": "1",
        "Item Description": "SYRUP CINNAMON BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "803206",
        "Last Price Paid": "$16.57",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004419",
        "Product Status": "Active"
    },
    {
        "Root": "10027844",
        "LV": "2",
        "Item Description": "CHEESE GRUYERE GRAND CRU USA 2/6 LB LOGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027844002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "24610",
        "Last Price Paid": "$10.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027844",
        "Product Status": "Active"
    },
    {
        "Root": "10002893",
        "LV": "1",
        "Item Description": "DRESSING BLUE CHEESE CHUN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.08",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002893",
        "Product Status": "Active"
    },
    {
        "Root": "10036126",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP RASPBERRY 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D551-A6120",
        "Last Price Paid": "$17.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036126",
        "Product Status": "Active"
    },
    {
        "Root": "10078310",
        "LV": "1",
        "Item Description": "BANANA FROZEN CHOCOLATE WITH NUT 48 CASE COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10078310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.94",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10078310",
        "Product Status": "Active"
    },
    {
        "Root": "10043716",
        "LV": "1",
        "Item Description": "CANDY M&M PLAIN BULK 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6172720",
        "Last Price Paid": "$92.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043716",
        "Product Status": "Active"
    },
    {
        "Root": "10085512",
        "LV": "1",
        "Item Description": "GLASS ORB STAR WARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.82",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085512",
        "Product Status": "Active"
    },
    {
        "Root": "10009861",
        "LV": "1",
        "Item Description": "SHERRY MARSALA PELGRINO DRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009861",
        "Product Status": "Active"
    },
    {
        "Root": "10001860",
        "LV": "1",
        "Item Description": "HERB OREGANO 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24124",
        "Last Price Paid": "$3.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001860",
        "Product Status": "Active"
    },
    {
        "Root": "10001860",
        "LV": "1",
        "Item Description": "HERB OREGANO 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001860",
        "Product Status": "Active"
    },
    {
        "Root": "10001860",
        "LV": "1",
        "Item Description": "HERB OREGANO 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598617",
        "Last Price Paid": "$3.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001860",
        "Product Status": "Active"
    },
    {
        "Root": "10003721",
        "LV": "1",
        "Item Description": "BEAN MOCHA COFFEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003721001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C900",
        "Last Price Paid": "$40.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003721",
        "Product Status": "Active"
    },
    {
        "Root": "10002809",
        "LV": "1",
        "Item Description": "SAUCE WORCHESTERSHIRE 5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.35",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002809",
        "Product Status": "Active"
    },
    {
        "Root": "10012542",
        "LV": "2",
        "Item Description": "BOURBON KNOB CREEK DISNEY SELECT 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012542002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012542",
        "Product Status": "Active"
    },
    {
        "Root": "10002422",
        "LV": "1",
        "Item Description": "SORBET MANGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002422001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002422",
        "Product Status": "Active"
    },
    {
        "Root": "10007421",
        "LV": "1",
        "Item Description": "ZINF ROMBAUER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007421001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1433044",
        "Last Price Paid": "$29.92",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007421",
        "Product Status": "Active"
    },
    {
        "Root": "10038004",
        "LV": "1",
        "Item Description": "ALBARINO LA CANA NAVIA ALBARINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001133",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038004",
        "Product Status": "Active"
    },
    {
        "Root": "10004543",
        "LV": "1",
        "Item Description": "RICE CRISPY TREAT .78Z 96 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4635918",
        "Last Price Paid": "$0.33",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004543",
        "Product Status": "Active"
    },
    {
        "Root": "10000157",
        "LV": "1",
        "Item Description": "MUSSEL PRINCE EDWARD ISLAND 10 POUNDS SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000157001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.70",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000157",
        "Product Status": "Active"
    },
    {
        "Root": "10000157",
        "LV": "1",
        "Item Description": "MUSSEL PRINCE EDWARD ISLAND 10 POUNDS SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000157001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "67-70005",
        "Last Price Paid": "$2.70",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000157",
        "Product Status": "Active"
    },
    {
        "Root": "10000157",
        "LV": "1",
        "Item Description": "MUSSEL PRINCE EDWARD ISLAND 10 POUNDS SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000157001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "2920536",
        "Last Price Paid": "$2.70",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000157",
        "Product Status": "Active"
    },
    {
        "Root": "10000157",
        "LV": "1",
        "Item Description": "MUSSEL PRINCE EDWARD ISLAND 10 POUNDS SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000157001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.70",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000157",
        "Product Status": "Active"
    },
    {
        "Root": "10028580",
        "LV": "2",
        "Item Description": "BEER CAN BLUE POINT TOASTED LAGER 12 CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028580002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38754",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028580",
        "Product Status": "Active"
    },
    {
        "Root": "10031299",
        "LV": "1",
        "Item Description": "PEARLS ORANGE BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031299001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ORPOPP",
        "Last Price Paid": "$34.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031299",
        "Product Status": "Active"
    },
    {
        "Root": "10005707",
        "LV": "1",
        "Item Description": "MUFFIN ENGLISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5002126",
        "Last Price Paid": "$0.34",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005707",
        "Product Status": "Active"
    },
    {
        "Root": "10037011",
        "LV": "1",
        "Item Description": "TEMPRANILLO CVNE CRIANZA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9652234",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037011",
        "Product Status": "Active"
    },
    {
        "Root": "10001616",
        "LV": "1",
        "Item Description": "BERRY HUCKLEBERRY FRZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250050",
        "Last Price Paid": "$160.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001616",
        "Product Status": "Active"
    },
    {
        "Root": "10001616",
        "LV": "1",
        "Item Description": "BERRY HUCKLEBERRY FRZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "250050",
        "Last Price Paid": "$160.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001616",
        "Product Status": "Active"
    },
    {
        "Root": "10034105",
        "LV": "2",
        "Item Description": "COCONUT COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034105002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011115",
        "Last Price Paid": "$80.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034105",
        "Product Status": "Active"
    },
    {
        "Root": "10108108",
        "LV": "1",
        "Item Description": "SANDWICH PB GRAPE J WHEAT UNCRUSTABLE 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.79",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108108",
        "Product Status": "Active"
    },
    {
        "Root": "10005399",
        "LV": "1",
        "Item Description": "JUICE CLAM OCEAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5591854",
        "Last Price Paid": "$2.89",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005399",
        "Product Status": "Active"
    },
    {
        "Root": "10005399",
        "LV": "1",
        "Item Description": "JUICE CLAM OCEAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "5591854",
        "Last Price Paid": "$2.89",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005399",
        "Product Status": "Active"
    },
    {
        "Root": "10031818",
        "LV": "1",
        "Item Description": "WRAP FOIL FLAT PACK THERMO SHEET ALUMINUM 10.5X14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "778597",
        "Last Price Paid": "$0.06",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031818",
        "Product Status": "Active"
    },
    {
        "Root": "10009588",
        "LV": "2",
        "Item Description": "VODKA ABSOLUT 80 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009588001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13223",
        "Last Price Paid": "$9.10",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009588",
        "Product Status": "Active"
    },
    {
        "Root": "10004421",
        "LV": "1",
        "Item Description": "SYRUP HIBISCUS GRENADINE BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004421001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.57",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004421",
        "Product Status": "Active"
    },
    {
        "Root": "10002146",
        "LV": "1",
        "Item Description": "BUTTER MARGARINE BLEND UNSALTED BLOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4715660",
        "Last Price Paid": "$1.70",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002146",
        "Product Status": "Active"
    },
    {
        "Root": "10011709",
        "LV": "1",
        "Item Description": "SYRUP KIWI MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5027",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011709",
        "Product Status": "Active"
    },
    {
        "Root": "10001254",
        "LV": "2",
        "Item Description": "HONEYDEW MELON 6 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001254002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "241006",
        "Last Price Paid": "$4.36",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001254",
        "Product Status": "Active"
    },
    {
        "Root": "10103488",
        "LV": "1",
        "Item Description": "CANDY MARSHMALLOW SHEET GREEN 5/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000272365",
        "Vendor": "MELVILLE CANDY CORPORATION",
        "Vendor Item Nb": "2209027",
        "Last Price Paid": "$9.80",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103488",
        "Product Status": "Active"
    },
    {
        "Root": "10095008",
        "LV": "1",
        "Item Description": "CARDBOARD WHITE TRAY DOLE WHIP FLIGHT 3 8IN X 7IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51301120",
        "Last Price Paid": "$0.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095008",
        "Product Status": "Active"
    },
    {
        "Root": "10031927",
        "LV": "1",
        "Item Description": "BEANS DRIED CRANBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401061",
        "Last Price Paid": "$48.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031927",
        "Product Status": "Active"
    },
    {
        "Root": "10036095",
        "LV": "1",
        "Item Description": "TWININGS SOOTHE TURMERIC WITH ORANGE & STAR ANISE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036095001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F15007",
        "Last Price Paid": "$2.54",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036095",
        "Product Status": "Active"
    },
    {
        "Root": "10100875",
        "LV": "1",
        "Item Description": "PEPPER POBLANO 1/4 JULIENNE 10LB CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-012",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100875",
        "Product Status": "Active"
    },
    {
        "Root": "10012233",
        "LV": "2",
        "Item Description": "PISCO PRIMERO BARSOL QUEBRANTA 700ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012233002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600660",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012233",
        "Product Status": "Active"
    },
    {
        "Root": "10092219",
        "LV": "1",
        "Item Description": "CUP SWGE 5OZ CLEARWARE TUMBLER TASTING 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092219001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11313048",
        "Last Price Paid": "$0.11",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092219",
        "Product Status": "Active"
    },
    {
        "Root": "10105402",
        "LV": "1",
        "Item Description": "KOSHER, ENTRÉE SALMON W/ WILD RICE & GREEN BEANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105402001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105402",
        "Product Status": "Active"
    },
    {
        "Root": "10006440",
        "LV": "2",
        "Item Description": "CANDLE BIRTHDAY 432CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006440002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4600896",
        "Last Price Paid": "$0.77",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006440",
        "Product Status": "Active"
    },
    {
        "Root": "10033244",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE GLAZE COINS 1/ 11 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "540091",
        "Last Price Paid": "$41.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033244",
        "Product Status": "Active"
    },
    {
        "Root": "10030270",
        "LV": "1",
        "Item Description": "COLOR SUPER BLACK GEL PASTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC SUPER BLACK",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030270",
        "Product Status": "Active"
    },
    {
        "Root": "10012550",
        "LV": "1",
        "Item Description": "BASIL MICRO THAI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MTB-33-S",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012550",
        "Product Status": "Active"
    },
    {
        "Root": "10088610",
        "LV": "1",
        "Item Description": "DEC WHITE CHOCOLATE COVERED CROQUANT 1.8 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "2042630000",
        "Last Price Paid": "$109.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088610",
        "Product Status": "Active"
    },
    {
        "Root": "10001127",
        "LV": "1",
        "Item Description": "SQUID RING & TENTACLE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001127001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6410411",
        "Last Price Paid": "$7.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001127",
        "Product Status": "Active"
    },
    {
        "Root": "10003472",
        "LV": "1",
        "Item Description": "COTTON CANDY MIX STRAWBER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003472001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3206",
        "Last Price Paid": "$4.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003472",
        "Product Status": "Active"
    },
    {
        "Root": "10018313",
        "LV": "1",
        "Item Description": "TORTILLA FLOUR 12\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7804770",
        "Last Price Paid": "$0.25",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018313",
        "Product Status": "Active"
    },
    {
        "Root": "10002588",
        "LV": "1",
        "Item Description": "SPINACH TROPICAL PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PTS-33",
        "Last Price Paid": "$0.55",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002588",
        "Product Status": "Active"
    },
    {
        "Root": "10041305",
        "LV": "1",
        "Item Description": "JUICE NAKED TROPICAL MANGO 8/15.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5787510",
        "Last Price Paid": "$2.81",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041305",
        "Product Status": "Active"
    },
    {
        "Root": "10002846",
        "LV": "2",
        "Item Description": "BASE WHIP TOPPING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002846002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4162871",
        "Last Price Paid": "$16.79",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002846",
        "Product Status": "Active"
    },
    {
        "Root": "10110609",
        "LV": "1",
        "Item Description": "BEER WICKED WEED JACK FROST 15.5GAL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76756",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110609",
        "Product Status": "Active"
    },
    {
        "Root": "10005033",
        "LV": "2",
        "Item Description": "TEA NUTCRCKR BLK 14.1Z.LS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005033002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W9978",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005033",
        "Product Status": "Active"
    },
    {
        "Root": "10024095",
        "LV": "1",
        "Item Description": "VEAL BRATWURST COOKED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024095001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024095",
        "Product Status": "Active"
    },
    {
        "Root": "10093652",
        "LV": "1",
        "Item Description": "VERSAWHIP 10OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CT1033",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093652",
        "Product Status": "Active"
    },
    {
        "Root": "10008036",
        "LV": "1",
        "Item Description": "SAKE SPARK ZIPANG 250ML GEKKEIK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "451723",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008036",
        "Product Status": "Active"
    },
    {
        "Root": "10022578",
        "LV": "1",
        "Item Description": "PEPSI CAN 24/12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022578",
        "Product Status": "Active"
    },
    {
        "Root": "10102340",
        "LV": "1",
        "Item Description": "WINE WHITE BLEND TWIN VINES VINHO VERDE 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102340",
        "Product Status": "Active"
    },
    {
        "Root": "10088028",
        "LV": "1",
        "Item Description": "CANDY GUMMY WORMS 20# BULK CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088028",
        "Product Status": "Active"
    },
    {
        "Root": "10041077",
        "LV": "1",
        "Item Description": "CUP STEIN LED RED POISON APPLE 21OZ 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041077",
        "Product Status": "Active"
    },
    {
        "Root": "10027139",
        "LV": "1",
        "Item Description": "RED BLEND FLORIOGRAPY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027139",
        "Product Status": "Active"
    },
    {
        "Root": "10095491",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN 12 DBL CSK 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "999837",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095491",
        "Product Status": "Active"
    },
    {
        "Root": "10009847",
        "LV": "1",
        "Item Description": "BRANDY CALVADOS BUSNEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11417",
        "Last Price Paid": "$33.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009847",
        "Product Status": "Active"
    },
    {
        "Root": "10006944",
        "LV": "1",
        "Item Description": "CHARD KEND JACK VNTR RES 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006944001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "602233",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006944",
        "Product Status": "Active"
    },
    {
        "Root": "10038591",
        "LV": "1",
        "Item Description": "BOX WHITE GLOSS SET UP 9X9X4-1/2X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038591001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "450763",
        "Last Price Paid": "$6.92",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038591",
        "Product Status": "Active"
    },
    {
        "Root": "10033258",
        "LV": "1",
        "Item Description": "ORIGINAL SIN ROSE 1/6 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "4831",
        "Last Price Paid": "$102.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033258",
        "Product Status": "Active"
    },
    {
        "Root": "10004185",
        "LV": "1",
        "Item Description": "SPICE PEPPERBERRY RUB 22#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068816",
        "Vendor": "SYSCO",
        "Vendor Item Nb": "9560376",
        "Last Price Paid": "$618.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004185",
        "Product Status": "Active"
    },
    {
        "Root": "10029968",
        "LV": "1",
        "Item Description": "PORK WHOLE PIG SUCKLING 20-30",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029968001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1400301",
        "Last Price Paid": "$5.38",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029968",
        "Product Status": "Active"
    },
    {
        "Root": "10022899",
        "LV": "1",
        "Item Description": "DRAFT GOOSE ISLAND 312 1/2 KEG URBAN WHEAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022899001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21502",
        "Last Price Paid": "$157.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022899",
        "Product Status": "Active"
    },
    {
        "Root": "10023554",
        "LV": "1",
        "Item Description": "COCOA BUTTER TAN SAHARA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023554001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "620026",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023554",
        "Product Status": "Active"
    },
    {
        "Root": "10002659",
        "LV": "1",
        "Item Description": "BASE LOBSTER NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002659",
        "Product Status": "Active"
    },
    {
        "Root": "10033242",
        "LV": "1",
        "Item Description": "NICKEL & NICKEL MERLOT SUSCOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033242001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "933953",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033242",
        "Product Status": "Active"
    },
    {
        "Root": "10089021",
        "LV": "1",
        "Item Description": "SAUV BLANC MATUA KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9266469",
        "Last Price Paid": "$208.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089021",
        "Product Status": "Active"
    },
    {
        "Root": "10003059",
        "LV": "1",
        "Item Description": "PASTA DRY CAMPANELLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "00076808002867",
        "Last Price Paid": "$1.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003059",
        "Product Status": "Active"
    },
    {
        "Root": "10001368",
        "LV": "1",
        "Item Description": "WATERCRESS MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MWA-33-L",
        "Last Price Paid": "$27.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001368",
        "Product Status": "Active"
    },
    {
        "Root": "10013102",
        "LV": "1",
        "Item Description": "BRIX RED VERJUS MINUS 8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013102001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO355",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013102",
        "Product Status": "Active"
    },
    {
        "Root": "10002547",
        "LV": "1",
        "Item Description": "BEET GOLD FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489950",
        "Last Price Paid": "$14.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002547",
        "Product Status": "Active"
    },
    {
        "Root": "10002132",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR MILD SLICED .5OZ 4/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "73437",
        "Last Price Paid": "$5.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002132",
        "Product Status": "Active"
    },
    {
        "Root": "10002132",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR MILD SLICED .5OZ 4/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002132",
        "Product Status": "Active"
    },
    {
        "Root": "10002591",
        "LV": "2",
        "Item Description": "PEPPER GREEN DICED 1 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002591001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514515",
        "Last Price Paid": "$16.15",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002591",
        "Product Status": "Active"
    },
    {
        "Root": "10107949",
        "LV": "1",
        "Item Description": "SEASONING, ADOBO W/PEPPER 6/2 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.85",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107949",
        "Product Status": "Active"
    },
    {
        "Root": "10000093",
        "LV": "1",
        "Item Description": "BEEF GROUND 82/18 BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000093001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1072026",
        "Last Price Paid": "$3.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000093",
        "Product Status": "Active"
    },
    {
        "Root": "10003491",
        "LV": "2",
        "Item Description": "TEA PEAR TREE LSE 14.1OZ3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003491001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W5018",
        "Last Price Paid": "$30.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003491",
        "Product Status": "Active"
    },
    {
        "Root": "10101411",
        "LV": "1",
        "Item Description": "WAFFLE CONES RED VELVET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "RV-M",
        "Last Price Paid": "$0.82",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101411",
        "Product Status": "Active"
    },
    {
        "Root": "10009807",
        "LV": "2",
        "Item Description": "RUM BACARD DRGN BERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009807001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "240792",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009807",
        "Product Status": "Active"
    },
    {
        "Root": "10004808",
        "LV": "2",
        "Item Description": "DIAMALT 50LB. BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004808002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U403",
        "Last Price Paid": "$150.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004808",
        "Product Status": "Active"
    },
    {
        "Root": "10003263",
        "LV": "1",
        "Item Description": "SAUCE SOY LESS SODIUM 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003263001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4105375",
        "Last Price Paid": "$2.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003263",
        "Product Status": "Active"
    },
    {
        "Root": "10001891",
        "LV": "1",
        "Item Description": "ONION RED SLIVERED 3/16 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-016-18",
        "Last Price Paid": "$9.93",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001891",
        "Product Status": "Active"
    },
    {
        "Root": "10001891",
        "LV": "1",
        "Item Description": "ONION RED SLIVERED 3/16 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "442383",
        "Last Price Paid": "$9.93",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001891",
        "Product Status": "Active"
    },
    {
        "Root": "10016837",
        "LV": "2",
        "Item Description": "QUINS SPRINKLE MIX TREES AND DOTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016837002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5841",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016837",
        "Product Status": "Active"
    },
    {
        "Root": "10005582",
        "LV": "2",
        "Item Description": "PASTRY CROISSANT MULTIGRAIN 3.5Z PRE-PROOFED 54CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005582002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.41",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005582",
        "Product Status": "Active"
    },
    {
        "Root": "10005582",
        "LV": "2",
        "Item Description": "PASTRY CROISSANT MULTIGRAIN 3.5Z PRE-PROOFED 54CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005582002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "52386",
        "Last Price Paid": "$1.41",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005582",
        "Product Status": "Active"
    },
    {
        "Root": "10102651",
        "LV": "1",
        "Item Description": "POPCHIPS - SEA SALT .8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "968010",
        "Last Price Paid": "$1.02",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102651",
        "Product Status": "Active"
    },
    {
        "Root": "10042187",
        "LV": "1",
        "Item Description": "TRAY FOOD KRAFT #100 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11175013",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042187",
        "Product Status": "Active"
    },
    {
        "Root": "10042187",
        "LV": "1",
        "Item Description": "TRAY FOOD KRAFT #100 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042187",
        "Product Status": "Active"
    },
    {
        "Root": "10009577",
        "LV": "1",
        "Item Description": "VODKA SKYY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992254",
        "Last Price Paid": "$12.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009577",
        "Product Status": "Active"
    },
    {
        "Root": "10029248",
        "LV": "1",
        "Item Description": "ROLL SUSHI VOLCANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029248001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029248",
        "Product Status": "Active"
    },
    {
        "Root": "10004608",
        "LV": "1",
        "Item Description": "PEA PIGEON GREEN CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "965830",
        "Last Price Paid": "$1.49",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004608",
        "Product Status": "Active"
    },
    {
        "Root": "10005601",
        "LV": "1",
        "Item Description": "PIZZA CRUST PARBAKED 12INCH 32/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.03",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005601",
        "Product Status": "Active"
    },
    {
        "Root": "10030355",
        "LV": "2",
        "Item Description": "CHAMPAGNE LOUIS ROEDERER NATURE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030355002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "551466",
        "Last Price Paid": "$68.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030355",
        "Product Status": "Active"
    },
    {
        "Root": "10004101",
        "LV": "1",
        "Item Description": "PATE CAMPAGNE 8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7045510",
        "Last Price Paid": "$7.02",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004101",
        "Product Status": "Active"
    },
    {
        "Root": "10004794",
        "LV": "1",
        "Item Description": "PUDDING VANILLA INSTANT 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1370667",
        "Last Price Paid": "$3.78",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004794",
        "Product Status": "Active"
    },
    {
        "Root": "10044744",
        "LV": "1",
        "Item Description": "MARKER WOOD STEAK MEDIUM 3.5IN 5000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044744001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "406558",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044744",
        "Product Status": "Active"
    },
    {
        "Root": "10014925",
        "LV": "1",
        "Item Description": "TEA TWNG BUDDING MEADOW CAMOMILE 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014925001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014925",
        "Product Status": "Active"
    },
    {
        "Root": "10000750",
        "LV": "1",
        "Item Description": "PROSCIUTTO SLICED BUSSETT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000750001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3302033",
        "Last Price Paid": "$10.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000750",
        "Product Status": "Active"
    },
    {
        "Root": "10000798",
        "LV": "1",
        "Item Description": "KIELBASA ROPE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3600011",
        "Last Price Paid": "$35.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000798",
        "Product Status": "Active"
    },
    {
        "Root": "10035930",
        "LV": "1",
        "Item Description": "BEER FUNKY BUDDHA HEFEWEIZEN 16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40585",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035930",
        "Product Status": "Active"
    },
    {
        "Root": "10035817",
        "LV": "1",
        "Item Description": "GC18650 6.1OZ BODEGA CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11001863",
        "Last Price Paid": "$0.35",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035817",
        "Product Status": "Active"
    },
    {
        "Root": "10108811",
        "LV": "1",
        "Item Description": "SCARPETTA PROSECCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9244067",
        "Last Price Paid": "$10.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108811",
        "Product Status": "Active"
    },
    {
        "Root": "10040958",
        "LV": "2",
        "Item Description": "PUREE WHITE PEACH 6/1KG ANDROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040958002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "817017",
        "Last Price Paid": "$11.28",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040958",
        "Product Status": "Active"
    },
    {
        "Root": "10108141",
        "LV": "1",
        "Item Description": "TRUFFLE TRADITION ASST 700 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NLC-TRAD",
        "Last Price Paid": "$1.96",
        "Case Pack": "700",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108141",
        "Product Status": "Active"
    },
    {
        "Root": "10003350",
        "LV": "1",
        "Item Description": "ESPRS OR CITY RG BN 1LB R",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.66",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003350",
        "Product Status": "Active"
    },
    {
        "Root": "10003528",
        "LV": "1",
        "Item Description": "SYRUP COF RASPBERRY LTR 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003528001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003528",
        "Product Status": "Active"
    },
    {
        "Root": "10034136",
        "LV": "1",
        "Item Description": "WHIPPED CREAM STABILIZER LIQUID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA650",
        "Last Price Paid": "$37.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034136",
        "Product Status": "Active"
    },
    {
        "Root": "10001556",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489948",
        "Last Price Paid": "$1.74",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001556",
        "Product Status": "Active"
    },
    {
        "Root": "10001556",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "489948",
        "Last Price Paid": "$1.74",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001556",
        "Product Status": "Active"
    },
    {
        "Root": "10002333",
        "LV": "1",
        "Item Description": "CHEESE FRESH BURRATA 4 OZ PORTION 6/2/4 OZ TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7590417",
        "Last Price Paid": "$0.82",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002333",
        "Product Status": "Active"
    },
    {
        "Root": "10031995",
        "LV": "1",
        "Item Description": "MONIN BLACK CHERRY PUREE L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-8142",
        "Last Price Paid": "$10.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031995",
        "Product Status": "Active"
    },
    {
        "Root": "10010285",
        "LV": "2",
        "Item Description": "BEER MADURO CAN 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010285001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32554",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010285",
        "Product Status": "Active"
    },
    {
        "Root": "10002877",
        "LV": "1",
        "Item Description": "TEA IRISH DCF IND 6X20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002877",
        "Product Status": "Active"
    },
    {
        "Root": "10020478",
        "LV": "1",
        "Item Description": "MOSCATO BLUEBERRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020478001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "245657",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020478",
        "Product Status": "Active"
    },
    {
        "Root": "10042490",
        "LV": "1",
        "Item Description": "WINE WHITE PINOT GRIGIO SUN GODDESS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "548317",
        "Last Price Paid": "$1.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042490",
        "Product Status": "Active"
    },
    {
        "Root": "10004963",
        "LV": "2",
        "Item Description": "CHOCOLATE GIANDUJA MILK23% CALLEBAUT HAZLNUT 5/11#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004963001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "101U...{CARMA{",
        "Last Price Paid": "$88.45",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004963",
        "Product Status": "Active"
    },
    {
        "Root": "10014151",
        "LV": "1",
        "Item Description": "CAKE SPONGE 12IN VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1308",
        "Last Price Paid": "$9.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014151",
        "Product Status": "Active"
    },
    {
        "Root": "10088364",
        "LV": "1",
        "Item Description": "BOWL WHITE COUPE COMPOSTABLE 24Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "511864",
        "Last Price Paid": "$0.27",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088364",
        "Product Status": "Active"
    },
    {
        "Root": "10006939",
        "LV": "1",
        "Item Description": "CAB SAUV CAYMUS NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "595260",
        "Last Price Paid": "$70.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006939",
        "Product Status": "Active"
    },
    {
        "Root": "10003498",
        "LV": "2",
        "Item Description": "TEA EARL GRY IMPRL 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003498001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3503",
        "Last Price Paid": "$33.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003498",
        "Product Status": "Active"
    },
    {
        "Root": "10007767",
        "LV": "1",
        "Item Description": "MERLOT MEEKER HANDPRINT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "241777",
        "Last Price Paid": "$29.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007767",
        "Product Status": "Active"
    },
    {
        "Root": "10007767",
        "LV": "1",
        "Item Description": "MERLOT MEEKER HANDPRINT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "241777",
        "Last Price Paid": "$29.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007767",
        "Product Status": "Active"
    },
    {
        "Root": "10029320",
        "LV": "1",
        "Item Description": "BEVERAGE BAR MIX KELVIN SLUSH FROSE 4/64 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9842572",
        "Last Price Paid": "$30.85",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029320",
        "Product Status": "Active"
    },
    {
        "Root": "10001941",
        "LV": "1",
        "Item Description": "ARTICHOKE GRILLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30500",
        "Last Price Paid": "$24.70",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001941",
        "Product Status": "Active"
    },
    {
        "Root": "10001941",
        "LV": "1",
        "Item Description": "ARTICHOKE GRILLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "592000",
        "Last Price Paid": "$24.70",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001941",
        "Product Status": "Active"
    },
    {
        "Root": "10001941",
        "LV": "1",
        "Item Description": "ARTICHOKE GRILLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592000",
        "Last Price Paid": "$24.70",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001941",
        "Product Status": "Active"
    },
    {
        "Root": "10007133",
        "LV": "1",
        "Item Description": "BLND RD BENMARCO EXPRSIVO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9189156",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007133",
        "Product Status": "Active"
    },
    {
        "Root": "10005390",
        "LV": "1",
        "Item Description": "DECOR PEARLS PASSIONFRUIT BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "POPPSNKJ",
        "Last Price Paid": "$24.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005390",
        "Product Status": "Active"
    },
    {
        "Root": "10039049",
        "LV": "1",
        "Item Description": "BREAD SOURDOUGH PULLMAN UNSLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "32:S",
        "Last Price Paid": "$4.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039049",
        "Product Status": "Active"
    },
    {
        "Root": "10012869",
        "LV": "1",
        "Item Description": "MIX PINA COLADA FINEST CALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "3671245",
        "Last Price Paid": "$3.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012869",
        "Product Status": "Active"
    },
    {
        "Root": "10034814",
        "LV": "1",
        "Item Description": "CHRISTMAS LIGHTS MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1409",
        "Last Price Paid": "$40.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034814",
        "Product Status": "Active"
    },
    {
        "Root": "10029569",
        "LV": "1",
        "Item Description": "BAG RECLOSABLE PSB551 5X5+1.5LP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51805629",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029569",
        "Product Status": "Active"
    },
    {
        "Root": "10003614",
        "LV": "1",
        "Item Description": "SB 5LB SUMATRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7336",
        "Last Price Paid": "$49.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003614",
        "Product Status": "Active"
    },
    {
        "Root": "10106388",
        "LV": "1",
        "Item Description": "WINE WHITE CH HAUT BRION BLANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "995898",
        "Last Price Paid": "$990.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106388",
        "Product Status": "Active"
    },
    {
        "Root": "10043852",
        "LV": "1",
        "Item Description": "DOUGH PUFF PASTRY VEGAN FRZN SQ FOTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3122264",
        "Last Price Paid": "$0.47",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043852",
        "Product Status": "Active"
    },
    {
        "Root": "10036203",
        "LV": "1",
        "Item Description": "COCOA BUTTER BREAKFAST BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302075C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036203",
        "Product Status": "Active"
    },
    {
        "Root": "10082203",
        "LV": "1",
        "Item Description": "BEER SAG BONFIRE BROWN 15.5 G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13849",
        "Last Price Paid": "$214.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082203",
        "Product Status": "Active"
    },
    {
        "Root": "10092954",
        "LV": "1",
        "Item Description": "CREPE HOLDER DISNEY LG PERF WHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092954001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091206",
        "Last Price Paid": "$0.17",
        "Case Pack": "1600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092954",
        "Product Status": "Active"
    },
    {
        "Root": "10005117",
        "LV": "1",
        "Item Description": "SPICE ROSEMARY LEAF 6/8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005117001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005117",
        "Product Status": "Active"
    },
    {
        "Root": "10109335",
        "LV": "1",
        "Item Description": "FISH KANPACHI SKIN OFF PORTIONS 3-4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109335001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01381",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109335",
        "Product Status": "Active"
    },
    {
        "Root": "10034627",
        "LV": "1",
        "Item Description": "MELON LANDRON MUSCADET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9176904",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034627",
        "Product Status": "Active"
    },
    {
        "Root": "10036128",
        "LV": "1",
        "Item Description": "BEER FLORIDA LAGER 12OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "31754",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036128",
        "Product Status": "Active"
    },
    {
        "Root": "10022595",
        "LV": "1",
        "Item Description": "LAVENDER HONEY 12/8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022595",
        "Product Status": "Active"
    },
    {
        "Root": "10039269",
        "LV": "1",
        "Item Description": "BOW CANDY CORN YELLOW ORANGE WHITE 2INX1IN 100/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1681",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039269",
        "Product Status": "Active"
    },
    {
        "Root": "10104148",
        "LV": "2",
        "Item Description": "CHICKEN BREAST BONELESS SKINLESS 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104148002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104148",
        "Product Status": "Active"
    },
    {
        "Root": "10029955",
        "LV": "1",
        "Item Description": "SUGAR CHRISTMAS PRESENTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48150",
        "Last Price Paid": "",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029955",
        "Product Status": "Active"
    },
    {
        "Root": "10040657",
        "LV": "1",
        "Item Description": "SPICE GARLIC POWDER BLACK 23OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040657001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2500246",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040657",
        "Product Status": "Active"
    },
    {
        "Root": "10016584",
        "LV": "1",
        "Item Description": "PAD CHOC SOFT CELL 16CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016584",
        "Product Status": "Active"
    },
    {
        "Root": "10104607",
        "LV": "1",
        "Item Description": "PASSOVER KIT - TSU WITH BEEF CASSEROLE 3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.03",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104607",
        "Product Status": "Active"
    },
    {
        "Root": "10004569",
        "LV": "1",
        "Item Description": "MUSTARD CREOLA GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5038948",
        "Last Price Paid": "$14.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004569",
        "Product Status": "Active"
    },
    {
        "Root": "10106788",
        "LV": "1",
        "Item Description": "SANDWICH ROLL SOURDOUGH SLICED 42/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.67",
        "Case Pack": "42",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106788",
        "Product Status": "Active"
    },
    {
        "Root": "10028041",
        "LV": "3",
        "Item Description": "BAYOU SPICED RUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028041003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9204907",
        "Last Price Paid": "$15.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10028041",
        "Product Status": "Active"
    },
    {
        "Root": "10028041",
        "LV": "3",
        "Item Description": "BAYOU SPICED RUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028041003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9204907",
        "Last Price Paid": "$15.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10028041",
        "Product Status": "Active"
    },
    {
        "Root": "10007280",
        "LV": "1",
        "Item Description": "CAB PURPLE CWBY TRL BOSS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "417212",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007280",
        "Product Status": "Active"
    },
    {
        "Root": "10002245",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED MOSES SLEEPER 4/1LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002245001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "34038",
        "Last Price Paid": "$14.44",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002245",
        "Product Status": "Active"
    },
    {
        "Root": "10044822",
        "LV": "1",
        "Item Description": "CAKE BOARD MARBLE/WOOD 8 IN DIE CUT 60 PT .60 GAUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013603",
        "Last Price Paid": "$0.28",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044822",
        "Product Status": "Active"
    },
    {
        "Root": "10034817",
        "LV": "1",
        "Item Description": "POINSETTIA SET SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1399",
        "Last Price Paid": "$53.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034817",
        "Product Status": "Active"
    },
    {
        "Root": "10002915",
        "LV": "1",
        "Item Description": "SODA COKE ZERO 12Z CAN 12 COUNT FRIDGE PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002915",
        "Product Status": "Active"
    },
    {
        "Root": "10040240",
        "LV": "1",
        "Item Description": "GLUE DOTS .5 INCH 2000/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001507",
        "Last Price Paid": "$0.03",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040240",
        "Product Status": "Active"
    },
    {
        "Root": "10038376",
        "LV": "1",
        "Item Description": "BAKING CUP LIVING CORAL SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "25310",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038376",
        "Product Status": "Active"
    },
    {
        "Root": "10029188",
        "LV": "1",
        "Item Description": "MIX OLD FASHION FUNNEL CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "5115",
        "Last Price Paid": "$6.78",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029188",
        "Product Status": "Active"
    },
    {
        "Root": "10004581",
        "LV": "1",
        "Item Description": "SHELL CANNOLI MINI 288CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6085633",
        "Last Price Paid": "$0.50",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004581",
        "Product Status": "Active"
    },
    {
        "Root": "10040836",
        "LV": "1",
        "Item Description": "BEER ALE BLUE POINT IMPERIAL SUNSHINE 16Z CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "74163",
        "Last Price Paid": "$1.74",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040836",
        "Product Status": "Active"
    },
    {
        "Root": "10017997",
        "LV": "1",
        "Item Description": "LOUIS MICHEL CHABLIS 1ER CRU MONTMAINS 21",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9672812",
        "Last Price Paid": "$32.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017997",
        "Product Status": "Active"
    },
    {
        "Root": "10000926",
        "LV": "1",
        "Item Description": "TILAPIA FILET FRZN 3-5 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000926001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "70-60037A",
        "Last Price Paid": "$3.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000926",
        "Product Status": "Active"
    },
    {
        "Root": "10000926",
        "LV": "1",
        "Item Description": "TILAPIA FILET FRZN 3-5 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000926001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6200127",
        "Last Price Paid": "$3.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000926",
        "Product Status": "Active"
    },
    {
        "Root": "10000926",
        "LV": "1",
        "Item Description": "TILAPIA FILET FRZN 3-5 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000926001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000926",
        "Product Status": "Active"
    },
    {
        "Root": "10033241",
        "LV": "1",
        "Item Description": "ILLUMINATION SAUVIGNON BLANC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033241001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600842",
        "Last Price Paid": "$24.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033241",
        "Product Status": "Active"
    },
    {
        "Root": "10012905",
        "LV": "1",
        "Item Description": "CAKE SPONGE FULL SHEET VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1404",
        "Last Price Paid": "$11.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012905",
        "Product Status": "Active"
    },
    {
        "Root": "10001655",
        "LV": "1",
        "Item Description": "APPLE DICED 3/8 INCH GRNY SMT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069667",
        "Vendor": "GARDEN GOURMET SPECIALTIES",
        "Vendor Item Nb": "481218",
        "Last Price Paid": "$22.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001655",
        "Product Status": "Active"
    },
    {
        "Root": "10001655",
        "LV": "1",
        "Item Description": "APPLE DICED 3/8 INCH GRNY SMT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481218",
        "Last Price Paid": "$22.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001655",
        "Product Status": "Active"
    },
    {
        "Root": "10001655",
        "LV": "1",
        "Item Description": "APPLE DICED 3/8 INCH GRNY SMT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-028-38",
        "Last Price Paid": "$22.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001655",
        "Product Status": "Active"
    },
    {
        "Root": "10000689",
        "LV": "1",
        "Item Description": "BEEF CHUCK CLOD HEART CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000689001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0116823",
        "Last Price Paid": "$4.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000689",
        "Product Status": "Active"
    },
    {
        "Root": "10004788",
        "LV": "1",
        "Item Description": "EXTRACT PEPPERMINT 30Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1000069825",
        "Last Price Paid": "$259.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004788",
        "Product Status": "Active"
    },
    {
        "Root": "10018729",
        "LV": "1",
        "Item Description": "SKEWER MED ANTIPASTO NO MEAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "4060",
        "Last Price Paid": "$1.78",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018729",
        "Product Status": "Active"
    },
    {
        "Root": "10003736",
        "LV": "1",
        "Item Description": "SB VANILLA SYRUP 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108260",
        "Last Price Paid": "$9.22",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003736",
        "Product Status": "Active"
    },
    {
        "Root": "10003917",
        "LV": "1",
        "Item Description": "CAPERBERRIES 13.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "100220",
        "Last Price Paid": "$14.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003917",
        "Product Status": "Active"
    },
    {
        "Root": "10030324",
        "LV": "1",
        "Item Description": "REDDY APPLE MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "4146",
        "Last Price Paid": "$6.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030324",
        "Product Status": "Active"
    },
    {
        "Root": "10002010",
        "LV": "1",
        "Item Description": "POTATO YUKON SLCD 1/8 INCH S/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481220",
        "Last Price Paid": "$50.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002010",
        "Product Status": "Active"
    },
    {
        "Root": "10009830",
        "LV": "1",
        "Item Description": "RUM RON ZACAPA 23YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "401463",
        "Last Price Paid": "$33.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009830",
        "Product Status": "Active"
    },
    {
        "Root": "10083665",
        "LV": "1",
        "Item Description": "DECOR CHOC GREEN GOOFY FLAT 6 X3.2 CM 1000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234160000",
        "Last Price Paid": "$7.93",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083665",
        "Product Status": "Active"
    },
    {
        "Root": "10001572",
        "LV": "1",
        "Item Description": "CABBAGE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598611",
        "Last Price Paid": "$12.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001572",
        "Product Status": "Active"
    },
    {
        "Root": "10010432",
        "LV": "2",
        "Item Description": "BEER ST BERNARDUS ABT 12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010432001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "5241",
        "Last Price Paid": "$4.12",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010432",
        "Product Status": "Active"
    },
    {
        "Root": "10015499",
        "LV": "1",
        "Item Description": "PASTA, PENNE GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2243893",
        "Last Price Paid": "$2.58",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015499",
        "Product Status": "Active"
    },
    {
        "Root": "10090751",
        "LV": "1",
        "Item Description": "BOURBON MAKERS MARK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9999",
        "Last Price Paid": "$24.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090751",
        "Product Status": "Active"
    },
    {
        "Root": "10102702",
        "LV": "1",
        "Item Description": "BUCKET PREM POP DAK PUMBAA 64OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102702001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102702",
        "Product Status": "Active"
    },
    {
        "Root": "10002214",
        "LV": "1",
        "Item Description": "CREAM HALF & HALF 2.5 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002214",
        "Product Status": "Active"
    },
    {
        "Root": "10019302",
        "LV": "1",
        "Item Description": "BOURBON FOUR ROSES SMALL BATCH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "199649",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019302",
        "Product Status": "Active"
    },
    {
        "Root": "10108285",
        "LV": "1",
        "Item Description": "MONTS FOURNOIS COTE CRAMANT GC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108285001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9662038",
        "Last Price Paid": "$99.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108285",
        "Product Status": "Active"
    },
    {
        "Root": "10024344",
        "LV": "1",
        "Item Description": "COFFEE KENYA AA REG BEAN 1 LB RETAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "C220B-21J",
        "Last Price Paid": "$6.93",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024344",
        "Product Status": "Active"
    },
    {
        "Root": "10045258",
        "LV": "1",
        "Item Description": "SB BROWN SUGAR SYRUP 1L 6EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011124514",
        "Last Price Paid": "$11.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045258",
        "Product Status": "Active"
    },
    {
        "Root": "10002394",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR SHARP WHITE NEW YORK 10 LB PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002394001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5270",
        "Last Price Paid": "$4.79",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002394",
        "Product Status": "Active"
    },
    {
        "Root": "10002358",
        "LV": "1",
        "Item Description": "CHEESE BRIE WHEELS 12/4.5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "230",
        "Last Price Paid": "$3.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002358",
        "Product Status": "Active"
    },
    {
        "Root": "10109275",
        "LV": "1",
        "Item Description": "DISNEY HOLIDAY ORNAMT DAISY 3.5X3.5CM 3000 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175940000",
        "Last Price Paid": "$0.14",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109275",
        "Product Status": "Active"
    },
    {
        "Root": "10032751",
        "LV": "1",
        "Item Description": "TRUFFLES BLACK SUMMER FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "TRU004",
        "Last Price Paid": "$16.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032751",
        "Product Status": "Active"
    },
    {
        "Root": "10002731",
        "LV": "1",
        "Item Description": "CEREAL CORN FLAKES BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002731",
        "Product Status": "Active"
    },
    {
        "Root": "10040196",
        "LV": "1",
        "Item Description": "LIQ ITALICUS ROSOLIO BERGAMOTTO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "477136",
        "Last Price Paid": "$28.14",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040196",
        "Product Status": "Active"
    },
    {
        "Root": "10029420",
        "LV": "1",
        "Item Description": "WHISKEY IRISH JAMESON CASKMATE STOUT 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029420001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "383876",
        "Last Price Paid": "$41.66",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029420",
        "Product Status": "Active"
    },
    {
        "Root": "10009521",
        "LV": "1",
        "Item Description": "WHSKY PENDELTN BLND CANADIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009521001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "79231",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009521",
        "Product Status": "Active"
    },
    {
        "Root": "10034673",
        "LV": "1",
        "Item Description": "CLEANING KIT TABLET RANCILIO EGRO 1 COFFEE 2 MILK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.33",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034673",
        "Product Status": "Active"
    },
    {
        "Root": "10020056",
        "LV": "1",
        "Item Description": "COOKIE XMAS ZIMTSTERNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SH001",
        "Last Price Paid": "$103.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020056",
        "Product Status": "Active"
    },
    {
        "Root": "10022840",
        "LV": "1",
        "Item Description": "CHOC WHT 3 X 1.5 RECT LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2171W",
        "Last Price Paid": "$1.25",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022840",
        "Product Status": "Active"
    },
    {
        "Root": "10028646",
        "LV": "1",
        "Item Description": "SNACKS PPCRN BMCHICAPOP SWT SLTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "158556",
        "Last Price Paid": "$1.27",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028646",
        "Product Status": "Active"
    },
    {
        "Root": "10023113",
        "LV": "1",
        "Item Description": "CORN INDIAN MINI BUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482032",
        "Last Price Paid": "$2.49",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023113",
        "Product Status": "Active"
    },
    {
        "Root": "10014109",
        "LV": "1",
        "Item Description": "LABEL GANACHERY RND MATTE 1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51285006",
        "Last Price Paid": "$0.04",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014109",
        "Product Status": "Active"
    },
    {
        "Root": "10005679",
        "LV": "1",
        "Item Description": "BUN STICKY PECAN 2.5 INCH ROUND 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005679",
        "Product Status": "Active"
    },
    {
        "Root": "10033160",
        "LV": "1",
        "Item Description": "SPIRIT VELVET FALERUM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677560",
        "Last Price Paid": "$15.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033160",
        "Product Status": "Active"
    },
    {
        "Root": "10002381",
        "LV": "2",
        "Item Description": "CHEESE SWISS CUBE 6/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002381002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "153361",
        "Last Price Paid": "$8.04",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002381",
        "Product Status": "Active"
    },
    {
        "Root": "10000845",
        "LV": "1",
        "Item Description": "TURKEY GIZZARDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000845001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000845",
        "Product Status": "Active"
    },
    {
        "Root": "10001962",
        "LV": "1",
        "Item Description": "FLOWERS MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MF-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001962",
        "Product Status": "Active"
    },
    {
        "Root": "10101169",
        "LV": "1",
        "Item Description": "VEGAN BLUE CHZ CRUMBLE FOLLOW YOUR HEART 6X2.2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "39022",
        "Last Price Paid": "$12.07",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101169",
        "Product Status": "Active"
    },
    {
        "Root": "10014424",
        "LV": "1",
        "Item Description": "LEEKS (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406085",
        "Last Price Paid": "$39.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014424",
        "Product Status": "Active"
    },
    {
        "Root": "10006328",
        "LV": "1",
        "Item Description": "BOX BAKERY 10X10X5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4177242",
        "Last Price Paid": "$0.62",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006328",
        "Product Status": "Active"
    },
    {
        "Root": "10002428",
        "LV": "2",
        "Item Description": "ICE CREAM COOKIE CREAM EDI 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002428002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.57",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002428",
        "Product Status": "Active"
    },
    {
        "Root": "10041373",
        "LV": "1",
        "Item Description": "CRANBERRIES ORGANIC DRIED 12/4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041373",
        "Product Status": "Active"
    },
    {
        "Root": "10100753",
        "LV": "1",
        "Item Description": "SPOTTSWOODE ESTATE CAB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9654805",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100753",
        "Product Status": "Active"
    },
    {
        "Root": "10009811",
        "LV": "1",
        "Item Description": "RUM PIRAS 51 CACHACA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9202448",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009811",
        "Product Status": "Active"
    },
    {
        "Root": "10035221",
        "LV": "1",
        "Item Description": "MB71 WINE 2.4OZ TULIP BAKE CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391013",
        "Last Price Paid": "",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035221",
        "Product Status": "Active"
    },
    {
        "Root": "10038008",
        "LV": "1",
        "Item Description": "WHITE BLEND RIBERA DEL DUERO VINA SASTRE FLAVU 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "523982",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038008",
        "Product Status": "Active"
    },
    {
        "Root": "10036333",
        "LV": "1",
        "Item Description": "EL DIABLO DL521028 2.5 ROUND LABEL BLACK ON KRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001093",
        "Last Price Paid": "",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036333",
        "Product Status": "Active"
    },
    {
        "Root": "10038480",
        "LV": "1",
        "Item Description": "CUP CLEAR SMALL HEART 3.1X2.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "4627",
        "Last Price Paid": "",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038480",
        "Product Status": "Active"
    },
    {
        "Root": "10037980",
        "LV": "1",
        "Item Description": "CUP SUGARCANE HIGH IMAGINE 16Z 100/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037980001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11004251",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037980",
        "Product Status": "Active"
    },
    {
        "Root": "10081598",
        "LV": "1",
        "Item Description": "LIQ KAMORA COFFEE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30383",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081598",
        "Product Status": "Active"
    },
    {
        "Root": "10032626",
        "LV": "1",
        "Item Description": "GELATO GUAVA SORBET HALF GALLON TUBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-GUAV-08-CS",
        "Last Price Paid": "$10.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032626",
        "Product Status": "Active"
    },
    {
        "Root": "10004904",
        "LV": "1",
        "Item Description": "CANDY SNICKERS BITE SIZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530130",
        "Last Price Paid": "$137.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004904",
        "Product Status": "Active"
    },
    {
        "Root": "10083674",
        "LV": "1",
        "Item Description": "BUCKET POPCORN ANIMAL KINGDOM KITE 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.19",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083674",
        "Product Status": "Active"
    },
    {
        "Root": "10101989",
        "LV": "1",
        "Item Description": "CAVIAR SIBERIAN SUPRME 30G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60901020",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101989",
        "Product Status": "Active"
    },
    {
        "Root": "10006869",
        "LV": "1",
        "Item Description": "CHAMP DOM PERIGNON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "580836",
        "Last Price Paid": "$220.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006869",
        "Product Status": "Active"
    },
    {
        "Root": "10006869",
        "LV": "1",
        "Item Description": "CHAMP DOM PERIGNON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "580836",
        "Last Price Paid": "$220.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006869",
        "Product Status": "Active"
    },
    {
        "Root": "10019850",
        "LV": "3",
        "Item Description": "EGG WHITE CF 15/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019850002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.90",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10019850",
        "Product Status": "Active"
    },
    {
        "Root": "10103330",
        "LV": "1",
        "Item Description": "INK PC-CTRG-YELLOW-EPSON U SERIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28006",
        "Last Price Paid": "$17.82",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103330",
        "Product Status": "Active"
    },
    {
        "Root": "10016147",
        "LV": "1",
        "Item Description": "NECTARINE RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270560",
        "Last Price Paid": "$1.10",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016147",
        "Product Status": "Active"
    },
    {
        "Root": "10009449",
        "LV": "1",
        "Item Description": "WHISKEY JAMESON IRISH LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009449",
        "Product Status": "Active"
    },
    {
        "Root": "10015455",
        "LV": "1",
        "Item Description": "FLAVORING RANCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2384",
        "Last Price Paid": "$33.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015455",
        "Product Status": "Active"
    },
    {
        "Root": "10104597",
        "LV": "1",
        "Item Description": "PASSOVER BSC ROAST CHICKEN POTATO KUGEL VEG 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104597",
        "Product Status": "Active"
    },
    {
        "Root": "10000134",
        "LV": "1",
        "Item Description": "CHICKEN BROILERS BELL & EVANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000134001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CX009",
        "Last Price Paid": "$2.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000134",
        "Product Status": "Active"
    },
    {
        "Root": "10088446",
        "LV": "1",
        "Item Description": "MIX GELATO COCONUT MILK MANGO GS 1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "GS13203",
        "Last Price Paid": "$19.01",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088446",
        "Product Status": "Active"
    },
    {
        "Root": "10006169",
        "LV": "2",
        "Item Description": "CUP SOUV 16Z SPORT SCI-FI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006169001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.84",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006169",
        "Product Status": "Active"
    },
    {
        "Root": "10007443",
        "LV": "1",
        "Item Description": "CAB SYRAH ZOLO RED SIGNTURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16766",
        "Last Price Paid": "$8.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007443",
        "Product Status": "Active"
    },
    {
        "Root": "10007185",
        "LV": "1",
        "Item Description": "CHAMP PIPER HEIDSIECK 1785",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9611846",
        "Last Price Paid": "$32.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007185",
        "Product Status": "Active"
    },
    {
        "Root": "10094128",
        "LV": "1",
        "Item Description": "CANDY LACES LICORICE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "35465",
        "Last Price Paid": "$102.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094128",
        "Product Status": "Active"
    },
    {
        "Root": "10107351",
        "LV": "1",
        "Item Description": "CROISSANT SHEET 10X15 RTU, 16 SHEETS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "8550030000",
        "Last Price Paid": "$9.88",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107351",
        "Product Status": "Active"
    },
    {
        "Root": "10101530",
        "LV": "1",
        "Item Description": "COOKIE RAISIN OATMEAL DOUGH 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101530",
        "Product Status": "Active"
    },
    {
        "Root": "10007112",
        "LV": "2",
        "Item Description": "RED BLEND PRISONER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007112002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570868",
        "Last Price Paid": "$28.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10007112",
        "Product Status": "Active"
    },
    {
        "Root": "10014747",
        "LV": "1",
        "Item Description": "ORANGE BY THE DOZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598065",
        "Last Price Paid": "$1.22",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014747",
        "Product Status": "Active"
    },
    {
        "Root": "10012553",
        "LV": "1",
        "Item Description": "PLATE 6\" BGW-06 NATURAL BRWN COMPOSTABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11851114",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012553",
        "Product Status": "Active"
    },
    {
        "Root": "10094997",
        "LV": "1",
        "Item Description": "COCKTAIL RTD LIVEWIRE SHISO MOJITO 355ML/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9647945",
        "Last Price Paid": "$3.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094997",
        "Product Status": "Active"
    },
    {
        "Root": "10025560",
        "LV": "1",
        "Item Description": "CONT DELI CLR 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51400005",
        "Last Price Paid": "$0.12",
        "Case Pack": "900",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025560",
        "Product Status": "Active"
    },
    {
        "Root": "10001862",
        "LV": "1",
        "Item Description": "HERB TARRAGON 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001862",
        "Product Status": "Active"
    },
    {
        "Root": "10001862",
        "LV": "1",
        "Item Description": "HERB TARRAGON 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598355",
        "Last Price Paid": "$8.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001862",
        "Product Status": "Active"
    },
    {
        "Root": "10035132",
        "LV": "1",
        "Item Description": "SYRUP MONIN CHIPOTLE PINEAPPLE L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5123",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035132",
        "Product Status": "Active"
    },
    {
        "Root": "10041602",
        "LV": "1",
        "Item Description": "SMOOTHIE ORCHARD BERRY NON DAIRY REFRIG 12/11.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4386069",
        "Last Price Paid": "$2.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041602",
        "Product Status": "Active"
    },
    {
        "Root": "10001509",
        "LV": "1",
        "Item Description": "RADISH BLACK 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001509001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01BR-2",
        "Last Price Paid": "$10.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001509",
        "Product Status": "Active"
    },
    {
        "Root": "10002268",
        "LV": "2",
        "Item Description": "CHEESE CREME FRAICHE VERMONT CREAMERY 2/4.75 LB TU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002268002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5010",
        "Last Price Paid": "$32.10",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002268",
        "Product Status": "Active"
    },
    {
        "Root": "10006672",
        "LV": "1",
        "Item Description": "BAGEL CINNAMON RAISIN GF UDIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "162174",
        "Last Price Paid": "$6.04",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006672",
        "Product Status": "Active"
    },
    {
        "Root": "10102699",
        "LV": "1",
        "Item Description": "OATS SD BOBS RED MILL GF OLD FASHIONED ROLLED 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102699001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101709",
        "Last Price Paid": "$62.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102699",
        "Product Status": "Active"
    },
    {
        "Root": "10001983",
        "LV": "1",
        "Item Description": "SALAD SENSATION PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001983001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SALP-2",
        "Last Price Paid": "$48.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001983",
        "Product Status": "Active"
    },
    {
        "Root": "10100690",
        "LV": "1",
        "Item Description": "PASTA DITALINI SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7328016",
        "Last Price Paid": "$15.49",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100690",
        "Product Status": "Active"
    },
    {
        "Root": "10009881",
        "LV": "2",
        "Item Description": "PORT DOW 20 YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009881002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9360344",
        "Last Price Paid": "$45.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009881",
        "Product Status": "Active"
    },
    {
        "Root": "10042533",
        "LV": "1",
        "Item Description": "WINE RIESLING MCBRIDE SISTERS BLACK GIRL MAGIC 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "594982",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042533",
        "Product Status": "Active"
    },
    {
        "Root": "10001430",
        "LV": "1",
        "Item Description": "TOMATO LOCAL HRLOOM CHERR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482243",
        "Last Price Paid": "$21.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001430",
        "Product Status": "Active"
    },
    {
        "Root": "10001430",
        "LV": "1",
        "Item Description": "TOMATO LOCAL HRLOOM CHERR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001430",
        "Product Status": "Active"
    },
    {
        "Root": "10006271",
        "LV": "1",
        "Item Description": "PAPER WAX WRAP 12X12 NATU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4818803",
        "Last Price Paid": "$0.03",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006271",
        "Product Status": "Active"
    },
    {
        "Root": "10105045",
        "LV": "1",
        "Item Description": "TAPIOCA PEARLS SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35200",
        "Last Price Paid": "$2.75",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105045",
        "Product Status": "Active"
    },
    {
        "Root": "10088663",
        "LV": "1",
        "Item Description": "EGGNOG DAIRY 9/.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.81",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088663",
        "Product Status": "Active"
    },
    {
        "Root": "10004425",
        "LV": "1",
        "Item Description": "FLAVOR EXTRACT MAPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "L1002",
        "Last Price Paid": "$20.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004425",
        "Product Status": "Active"
    },
    {
        "Root": "10006878",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON BERINGER FOUNDER¿S ESTATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "397020",
        "Last Price Paid": "$7.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006878",
        "Product Status": "Active"
    },
    {
        "Root": "10091230",
        "LV": "2",
        "Item Description": "CHOC DK CIRCLE XTRA THIN WHITE PRINT 8CM, 225PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091230002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.62",
        "Case Pack": "195",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10091230",
        "Product Status": "Active"
    },
    {
        "Root": "10003998",
        "LV": "2",
        "Item Description": "CHOCOLATE MILK 40% JIVARA VALRHONA LACTEE 6.6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003998002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "4658",
        "Last Price Paid": "$66.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003998",
        "Product Status": "Active"
    },
    {
        "Root": "10004455",
        "LV": "1",
        "Item Description": "MIX FREESTYLE FRUIT PUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7025783",
        "Last Price Paid": "$16.65",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004455",
        "Product Status": "Active"
    },
    {
        "Root": "10007349",
        "LV": "2",
        "Item Description": "MERLOT BLEND IL FAUNO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007349002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587064",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10007349",
        "Product Status": "Active"
    },
    {
        "Root": "10005883",
        "LV": "1",
        "Item Description": "BAGELS TAPIOCA RICE NY STATE GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1707",
        "Last Price Paid": "$3.71",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005883",
        "Product Status": "Active"
    },
    {
        "Root": "10001770",
        "LV": "1",
        "Item Description": "TOMATO PLUM RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "161097",
        "Last Price Paid": "$26.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001770",
        "Product Status": "Active"
    },
    {
        "Root": "10008791",
        "LV": "1",
        "Item Description": "CAB GLENELLY LADY MAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "313159",
        "Last Price Paid": "$37.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008791",
        "Product Status": "Active"
    },
    {
        "Root": "10104611",
        "LV": "1",
        "Item Description": "MILK CHOCOLATE WHOLE 20/16 OZ TRUMOO CHUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.75",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104611",
        "Product Status": "Active"
    },
    {
        "Root": "10017631",
        "LV": "1",
        "Item Description": "BOX,LUNCH,PREMIER,SP,SIDE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "456",
        "Last Price Paid": "$15.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017631",
        "Product Status": "Active"
    },
    {
        "Root": "10038266",
        "LV": "1",
        "Item Description": "COASTER ROUND 4 INCH 60PT CHRONOS 2500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51004701",
        "Last Price Paid": "$0.09",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038266",
        "Product Status": "Active"
    },
    {
        "Root": "10003346",
        "LV": "1",
        "Item Description": "SYRUP SEAGRAMS TONIC WATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "145888",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003346",
        "Product Status": "Active"
    },
    {
        "Root": "10001511",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001511001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01WAR-2",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001511",
        "Product Status": "Active"
    },
    {
        "Root": "10033808",
        "LV": "2",
        "Item Description": "POWDER THAI COCONUT GREEN CURRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033808002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "801122",
        "Last Price Paid": "$20.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033808",
        "Product Status": "Active"
    },
    {
        "Root": "10040539",
        "LV": "1",
        "Item Description": "COOKIE RIVIERA IND WRAP MICKEY/MINN/DON/DAIS 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "SGRIVIW",
        "Last Price Paid": "$2.29",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040539",
        "Product Status": "Active"
    },
    {
        "Root": "10009938",
        "LV": "2",
        "Item Description": "LIQ NONINO AMARO QUINTS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009938001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16569",
        "Last Price Paid": "$36.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009938",
        "Product Status": "Active"
    },
    {
        "Root": "10009877",
        "LV": "3",
        "Item Description": "PORT GRAHAM 20 YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009877002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "325629",
        "Last Price Paid": "$24.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009877",
        "Product Status": "Active"
    },
    {
        "Root": "10002649",
        "LV": "2",
        "Item Description": "SPICE PEPPER BLACK BUTCHER 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002649002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00814-7",
        "Last Price Paid": "$16.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002649",
        "Product Status": "Active"
    },
    {
        "Root": "10002649",
        "LV": "2",
        "Item Description": "SPICE PEPPER BLACK BUTCHER 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002649002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002649",
        "Product Status": "Active"
    },
    {
        "Root": "10001809",
        "LV": "1",
        "Item Description": "SUNCHOKES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001809001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001809",
        "Product Status": "Active"
    },
    {
        "Root": "10001809",
        "LV": "1",
        "Item Description": "SUNCHOKES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001809001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001809",
        "Product Status": "Active"
    },
    {
        "Root": "10010025",
        "LV": "3",
        "Item Description": "DRAFT CARLSBERG HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010025001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$162.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10010025",
        "Product Status": "Active"
    },
    {
        "Root": "10107449",
        "LV": "1",
        "Item Description": "CHIP PLANTAIN STRIP BULK 6.6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7802162",
        "Last Price Paid": "$23.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107449",
        "Product Status": "Active"
    },
    {
        "Root": "10042391",
        "LV": "2",
        "Item Description": "BITTERS HELLA ORANGE 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042391002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "945611",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10042391",
        "Product Status": "Active"
    },
    {
        "Root": "10002759",
        "LV": "1",
        "Item Description": "VANILLA IMITATION GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002759001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002759",
        "Product Status": "Active"
    },
    {
        "Root": "10003559",
        "LV": "1",
        "Item Description": "SPICE CORIANDER WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.80",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003559",
        "Product Status": "Active"
    },
    {
        "Root": "10082448",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL SMART SOUR PLUM 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "55580010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082448",
        "Product Status": "Active"
    },
    {
        "Root": "10001386",
        "LV": "2",
        "Item Description": "AVOCADO DICED IQF FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001386001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8951444",
        "Last Price Paid": "$7.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10001386",
        "Product Status": "Active"
    },
    {
        "Root": "10084342",
        "LV": "1",
        "Item Description": "GIN EMPRESS 1908 ORIGINAL INDIGO 750ML 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084342",
        "Product Status": "Active"
    },
    {
        "Root": "10003056",
        "LV": "1",
        "Item Description": "PASTA DRY RIGATONI 160OZ 2CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808039280",
        "Last Price Paid": "$14.39",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003056",
        "Product Status": "Active"
    },
    {
        "Root": "10029010",
        "LV": "1",
        "Item Description": "CLEAR DELI LID PET LRD-EP ALUR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51174152",
        "Last Price Paid": "$0.17",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029010",
        "Product Status": "Active"
    },
    {
        "Root": "10007082",
        "LV": "1",
        "Item Description": "CHIAN NIPPZ RSV FRESCBD 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007082001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "560185",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007082",
        "Product Status": "Active"
    },
    {
        "Root": "10030739",
        "LV": "1",
        "Item Description": "GEL COLOR PASTE CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC CHOC BROWN",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030739",
        "Product Status": "Active"
    },
    {
        "Root": "10004331",
        "LV": "1",
        "Item Description": "SORBET SLIPPER 36EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000384112",
        "Vendor": "QUEST EVENTS LLC DBA TOTALLY MOD",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004331",
        "Product Status": "Active"
    },
    {
        "Root": "10084124",
        "LV": "1",
        "Item Description": "DONUT HOLES GLAZED 35CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084124",
        "Product Status": "Active"
    },
    {
        "Root": "10013085",
        "LV": "2",
        "Item Description": "GUM PASTE ELEGANT HIGH HEEL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013085002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013085",
        "Product Status": "Active"
    },
    {
        "Root": "10039923",
        "LV": "1",
        "Item Description": "BEER BLUE POINT SHORE THING 12Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039923",
        "Product Status": "Active"
    },
    {
        "Root": "10038774",
        "LV": "1",
        "Item Description": "CANDIES HARD MIXED SUGAR FREE EDA 800/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000546229",
        "Vendor": "G&J HOLDINGS LLC DBA CANDY.COM",
        "Vendor Item Nb": "209-02468",
        "Last Price Paid": "",
        "Case Pack": "800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038774",
        "Product Status": "Active"
    },
    {
        "Root": "10002829",
        "LV": "1",
        "Item Description": "TUNA CHUNK LT POUCH CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2902906",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002829",
        "Product Status": "Active"
    },
    {
        "Root": "10007795",
        "LV": "2",
        "Item Description": "PINOT NOIR ROCHIOLI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007795001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "510697",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007795",
        "Product Status": "Active"
    },
    {
        "Root": "10007927",
        "LV": "2",
        "Item Description": "SPARKLING VINA ESMERALDA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007927001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "963640",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007927",
        "Product Status": "Active"
    },
    {
        "Root": "10001749",
        "LV": "1",
        "Item Description": "STRAWBERRY LONG STEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001749",
        "Product Status": "Active"
    },
    {
        "Root": "10001749",
        "LV": "1",
        "Item Description": "STRAWBERRY LONG STEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250060",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001749",
        "Product Status": "Active"
    },
    {
        "Root": "10009952",
        "LV": "1",
        "Item Description": "LIQ APPLE JACK LAIRD LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "112112-1L",
        "Last Price Paid": "$20.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009952",
        "Product Status": "Active"
    },
    {
        "Root": "10005914",
        "LV": "1",
        "Item Description": "BREAD FRENCH 2.25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005914",
        "Product Status": "Active"
    },
    {
        "Root": "10011282",
        "LV": "1",
        "Item Description": "WINE GRAYSON CABERNET SAUVIGNON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "426752",
        "Last Price Paid": "$8.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011282",
        "Product Status": "Active"
    },
    {
        "Root": "10000991",
        "LV": "1",
        "Item Description": "CORVINA FILET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000991001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000991",
        "Product Status": "Active"
    },
    {
        "Root": "10000991",
        "LV": "1",
        "Item Description": "CORVINA FILET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000991001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000991",
        "Product Status": "Active"
    },
    {
        "Root": "10000991",
        "LV": "1",
        "Item Description": "CORVINA FILET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000991001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00080",
        "Last Price Paid": "$15.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000991",
        "Product Status": "Active"
    },
    {
        "Root": "10000991",
        "LV": "1",
        "Item Description": "CORVINA FILET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000991001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "7495211",
        "Last Price Paid": "$15.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000991",
        "Product Status": "Active"
    },
    {
        "Root": "10007915",
        "LV": "1",
        "Item Description": "CAB SAUV CHAPPELLET SIGNTRE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "241003",
        "Last Price Paid": "$65.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007915",
        "Product Status": "Active"
    },
    {
        "Root": "10004703",
        "LV": "1",
        "Item Description": "DRIED FRUIT BLUEBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004703001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "230961",
        "Last Price Paid": "$114.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004703",
        "Product Status": "Active"
    },
    {
        "Root": "10081124",
        "LV": "1",
        "Item Description": "CRUST PIZZA CAULIFLOWER FE NATIONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.54",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081124",
        "Product Status": "Active"
    },
    {
        "Root": "10005583",
        "LV": "1",
        "Item Description": "PASTRY CINNAMON ROLL MINI 1.6 OZ RAW SPIRAL 120CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.25",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005583",
        "Product Status": "Active"
    },
    {
        "Root": "10016704",
        "LV": "1",
        "Item Description": "PAPER TISSUE 20X30 AMORETTES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52042065",
        "Last Price Paid": "$0.10",
        "Case Pack": "480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016704",
        "Product Status": "Active"
    },
    {
        "Root": "10043051",
        "LV": "1",
        "Item Description": "LABEL 2.75X3.5 ALLERGY CMYK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52014623",
        "Last Price Paid": "$0.05",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043051",
        "Product Status": "Active"
    },
    {
        "Root": "10091714",
        "LV": "2",
        "Item Description": "CONTAINER FOOD BLK 6\"X6\" CF6611 CRISP TECH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091714001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006247",
        "Last Price Paid": "$0.33",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10091714",
        "Product Status": "Active"
    },
    {
        "Root": "10002339",
        "LV": "1",
        "Item Description": "CHEESE MUENSTER SLICED .75OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4996963",
        "Last Price Paid": "$8.97",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002339",
        "Product Status": "Active"
    },
    {
        "Root": "10015363",
        "LV": "1",
        "Item Description": "DEC ROSE TEA SMALL LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKK LV",
        "Last Price Paid": "$1.12",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015363",
        "Product Status": "Active"
    },
    {
        "Root": "10006117",
        "LV": "1",
        "Item Description": "MUG KRATATOA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006117001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.45",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006117",
        "Product Status": "Active"
    },
    {
        "Root": "10019194",
        "LV": "1",
        "Item Description": "CUP BAKING 3.9X1.2 RED W/WHITE DOTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391024",
        "Last Price Paid": "$0.10",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019194",
        "Product Status": "Active"
    },
    {
        "Root": "10028193",
        "LV": "1",
        "Item Description": "BEYOND SAUSAGE ITALIAN CRUMBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103313",
        "Last Price Paid": "$30.23",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028193",
        "Product Status": "Active"
    },
    {
        "Root": "10003939",
        "LV": "2",
        "Item Description": "CRACKER GOLDFISH CHDR 6/26.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003939002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1019062",
        "Last Price Paid": "$8.28",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003939",
        "Product Status": "Active"
    },
    {
        "Root": "10107231",
        "LV": "2",
        "Item Description": "CHOCOLATE CHIFFON CAKE (29 OZ)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107231001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "CHF29",
        "Last Price Paid": "$13.40",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10107231",
        "Product Status": "Active"
    },
    {
        "Root": "10002832",
        "LV": "1",
        "Item Description": "PUDDING CHOC SNACK PAK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4258687",
        "Last Price Paid": "$0.51",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002832",
        "Product Status": "Active"
    },
    {
        "Root": "10001675",
        "LV": "1",
        "Item Description": "BEET RED 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401038",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001675",
        "Product Status": "Active"
    },
    {
        "Root": "10001675",
        "LV": "1",
        "Item Description": "BEET RED 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001675",
        "Product Status": "Active"
    },
    {
        "Root": "10012809",
        "LV": "1",
        "Item Description": "CHOC COMB CURL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DROO7W",
        "Last Price Paid": "$8.74",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012809",
        "Product Status": "Active"
    },
    {
        "Root": "10039929",
        "LV": "1",
        "Item Description": "SYRUP SIMPLE NO HFCS 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039929",
        "Product Status": "Active"
    },
    {
        "Root": "10008750",
        "LV": "2",
        "Item Description": "CHARD DARIOUSH NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008750001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "94864",
        "Last Price Paid": "$42.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008750",
        "Product Status": "Active"
    },
    {
        "Root": "10001811",
        "LV": "1",
        "Item Description": "PEPPER FRESNO RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001811001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001811",
        "Product Status": "Active"
    },
    {
        "Root": "10035997",
        "LV": "1",
        "Item Description": "RICE DREAM ORIGINAL MILK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "M92213",
        "Last Price Paid": "$3.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035997",
        "Product Status": "Active"
    },
    {
        "Root": "10003618",
        "LV": "1",
        "Item Description": "COOKIE CHIPS AHOY 48/1.4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003618001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302415",
        "Last Price Paid": "$0.83",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003618",
        "Product Status": "Active"
    },
    {
        "Root": "10009177",
        "LV": "2",
        "Item Description": "CAB SAUV SHAFER NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009177001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9695893",
        "Last Price Paid": "$84.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009177",
        "Product Status": "Active"
    },
    {
        "Root": "10088805",
        "LV": "1",
        "Item Description": "BOX CAKE FULL SHEET BOTTOM CORRUGATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51009041",
        "Last Price Paid": "$2.42",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088805",
        "Product Status": "Active"
    },
    {
        "Root": "10004204",
        "LV": "1",
        "Item Description": "JUICE TOMATO 100% 46Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5327788",
        "Last Price Paid": "$2.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004204",
        "Product Status": "Active"
    },
    {
        "Root": "10038862",
        "LV": "1",
        "Item Description": "BAG POPCORN SWGE 1000 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6682596",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038862",
        "Product Status": "Active"
    },
    {
        "Root": "10010875",
        "LV": "2",
        "Item Description": "TEQ CASAMIGOS BLANCO 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010875001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "446131",
        "Last Price Paid": "$38.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010875",
        "Product Status": "Active"
    },
    {
        "Root": "10002675",
        "LV": "1",
        "Item Description": "BEAN GARBANZO #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.74",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002675",
        "Product Status": "Active"
    },
    {
        "Root": "10009648",
        "LV": "1",
        "Item Description": "VODKA PEARL BLK LABEL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "840587",
        "Last Price Paid": "$9.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009648",
        "Product Status": "Active"
    },
    {
        "Root": "10104011",
        "LV": "1",
        "Item Description": "POPCORN HOLIDAY WINTER MIX 16 GAL BAG 4 MDSE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$90.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104011",
        "Product Status": "Active"
    },
    {
        "Root": "10110077",
        "LV": "1",
        "Item Description": "SB GINGERBREAD LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11149767",
        "Last Price Paid": "$1.47",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110077",
        "Product Status": "Active"
    },
    {
        "Root": "10103650",
        "LV": "1",
        "Item Description": "FLOUR HIGH GLUTEN KYROL 50LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103650",
        "Product Status": "Active"
    },
    {
        "Root": "10002647",
        "LV": "4",
        "Item Description": "SPICE CHILI POWDER 4/5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002647004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00278-7",
        "Last Price Paid": "$17.57",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10002647",
        "Product Status": "Active"
    },
    {
        "Root": "10002647",
        "LV": "4",
        "Item Description": "SPICE CHILI POWDER 4/5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002647004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.57",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10002647",
        "Product Status": "Active"
    },
    {
        "Root": "10019126",
        "LV": "1",
        "Item Description": "EXTRACT BUTTERFLY PEA FLOWER B'LURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "EXTBLU100",
        "Last Price Paid": "$6.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019126",
        "Product Status": "Active"
    },
    {
        "Root": "10007820",
        "LV": "2",
        "Item Description": "ALBARINO MARTIN CODAX 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007820001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9018443",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007820",
        "Product Status": "Active"
    },
    {
        "Root": "10019569",
        "LV": "1",
        "Item Description": "CHAMP BOLLINGER BRUT SPEC CUVEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9010455",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019569",
        "Product Status": "Active"
    },
    {
        "Root": "10025337",
        "LV": "1",
        "Item Description": "WHISKEY SONOMA RYE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "908303",
        "Last Price Paid": "$32.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025337",
        "Product Status": "Active"
    },
    {
        "Root": "10002200",
        "LV": "2",
        "Item Description": "YOGURT PLAIN GREEK NONFAT 6/32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002200001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.40",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002200",
        "Product Status": "Active"
    },
    {
        "Root": "10006288",
        "LV": "2",
        "Item Description": "PICK BAMBOO W/KNOT 3.5 INCH 12/100EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006288002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6398995",
        "Last Price Paid": "$0.03",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006288",
        "Product Status": "Active"
    },
    {
        "Root": "10007996",
        "LV": "1",
        "Item Description": "SAUV BLC CONCHA TERRUNYO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "329338",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007996",
        "Product Status": "Active"
    },
    {
        "Root": "10007996",
        "LV": "1",
        "Item Description": "SAUV BLC CONCHA TERRUNYO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "329338",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007996",
        "Product Status": "Active"
    },
    {
        "Root": "10087955",
        "LV": "1",
        "Item Description": "PASTE SESAME BLACK 12/16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30030",
        "Last Price Paid": "$7.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087955",
        "Product Status": "Active"
    },
    {
        "Root": "10002837",
        "LV": "1",
        "Item Description": "SUGAR POWDERED 10X 50#/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$43.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002837",
        "Product Status": "Active"
    },
    {
        "Root": "10033750",
        "LV": "2",
        "Item Description": "SUGAR DELUXE HOLLY JOLLY ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033750002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48784",
        "Last Price Paid": "$0.23",
        "Case Pack": "91",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033750",
        "Product Status": "Active"
    },
    {
        "Root": "10003158",
        "LV": "2",
        "Item Description": "TEA MASALA BLK CHAI LS 14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003158001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W1000",
        "Last Price Paid": "$35.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003158",
        "Product Status": "Active"
    },
    {
        "Root": "10001225",
        "LV": "1",
        "Item Description": "TROUT RNBW FLT BNLS BTRFLY 8 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001225001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001225",
        "Product Status": "Active"
    },
    {
        "Root": "10044409",
        "LV": "1",
        "Item Description": "SB BROWN BUTTER TOPPING 70 GRAMS 4 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11121797",
        "Last Price Paid": "$4.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044409",
        "Product Status": "Active"
    },
    {
        "Root": "10026120",
        "LV": "1",
        "Item Description": "CUP BAKING COPPER CRM BRULEE ALUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51491002",
        "Last Price Paid": "$0.21",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026120",
        "Product Status": "Active"
    },
    {
        "Root": "10008106",
        "LV": "1",
        "Item Description": "CHARD RAMEY RUS RVR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9062083",
        "Last Price Paid": "$24.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008106",
        "Product Status": "Active"
    },
    {
        "Root": "10044234",
        "LV": "1",
        "Item Description": "CEREAL COCOA PUFFS BULKPACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4017836",
        "Last Price Paid": "$13.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044234",
        "Product Status": "Active"
    },
    {
        "Root": "10002770",
        "LV": "1",
        "Item Description": "SYRUP COKE HIGH YIELD 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$111.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002770",
        "Product Status": "Active"
    },
    {
        "Root": "10004736",
        "LV": "1",
        "Item Description": "BASE CLAM SOUP 6/1# MINOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6108575",
        "Last Price Paid": "$11.04",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004736",
        "Product Status": "Active"
    },
    {
        "Root": "10015009",
        "LV": "1",
        "Item Description": "PINOT NOIR KING ESTATE 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "204194",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015009",
        "Product Status": "Active"
    },
    {
        "Root": "10092153",
        "LV": "1",
        "Item Description": "CANDY SKITTLES 12/3.5OZ THEATER BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "50047",
        "Last Price Paid": "$19.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092153",
        "Product Status": "Active"
    },
    {
        "Root": "10035878",
        "LV": "2",
        "Item Description": "SPICE RIB RUB DELOS 4-5LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035878002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035878",
        "Product Status": "Active"
    },
    {
        "Root": "10037084",
        "LV": "1",
        "Item Description": "MANUEL RAVENTOS RAVENTOS I BLANC 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9179772",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037084",
        "Product Status": "Active"
    },
    {
        "Root": "10032833",
        "LV": "1",
        "Item Description": "SUGAR-SMALL DAISIES ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032833",
        "Product Status": "Active"
    },
    {
        "Root": "10076482",
        "LV": "1",
        "Item Description": "CUPCAKE UNICED MINI CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "M-953",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076482",
        "Product Status": "Active"
    },
    {
        "Root": "10036013",
        "LV": "1",
        "Item Description": "ESTRELLA GALICIA 1906 RED 11.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "5854",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036013",
        "Product Status": "Active"
    },
    {
        "Root": "10039906",
        "LV": "1",
        "Item Description": "BEER CIDERBOYS BLACKBERRY WILD 12 OZ BTL 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039906",
        "Product Status": "Active"
    },
    {
        "Root": "10034358",
        "LV": "2",
        "Item Description": "RTD MARBLE-RITA BLUE MARBLE 200ML 24PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034358002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "BMP-Marble2-20",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034358",
        "Product Status": "Active"
    },
    {
        "Root": "10023805",
        "LV": "1",
        "Item Description": "CAULIFLOWER ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "40428",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023805",
        "Product Status": "Active"
    },
    {
        "Root": "10030248",
        "LV": "1",
        "Item Description": "SERVICE FEE KEG RENTAL CHARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030248001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030248",
        "Product Status": "Active"
    },
    {
        "Root": "10038007",
        "LV": "1",
        "Item Description": "TEMPRANILLO TORO ALVAR DE DIOS ACIANO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "963201",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038007",
        "Product Status": "Active"
    },
    {
        "Root": "10014771",
        "LV": "1",
        "Item Description": "DOUBLE PETAL DAISY SMALL WHITE DS2K",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DS2K",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014771",
        "Product Status": "Active"
    },
    {
        "Root": "10039916",
        "LV": "1",
        "Item Description": "BEER BUDWEISER BLACK LAGER 12Z BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039916",
        "Product Status": "Active"
    },
    {
        "Root": "10110078",
        "LV": "1",
        "Item Description": "SB MAGNETIC FILTER ZEPHYR FOOD CASE 1-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110078001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11066487",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110078",
        "Product Status": "Active"
    },
    {
        "Root": "10016364",
        "LV": "1",
        "Item Description": "DRAFT DOGFISH ROMANTIC CHEMISTRY 1/6 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30556",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016364",
        "Product Status": "Active"
    },
    {
        "Root": "10009794",
        "LV": "1",
        "Item Description": "RUM BACARDI SILVER LT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009794",
        "Product Status": "Active"
    },
    {
        "Root": "10010229",
        "LV": "2",
        "Item Description": "DRAFT ABITA AMBER HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010229001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14464",
        "Last Price Paid": "$170.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010229",
        "Product Status": "Active"
    },
    {
        "Root": "10000698",
        "LV": "1",
        "Item Description": "PORK NECK BONES RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000698001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1480035",
        "Last Price Paid": "$1.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000698",
        "Product Status": "Active"
    },
    {
        "Root": "10040337",
        "LV": "1",
        "Item Description": "CUP FOOD 6OZ PAPER WHITE 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040337",
        "Product Status": "Active"
    },
    {
        "Root": "10100689",
        "LV": "1",
        "Item Description": "LIQUEUR BOLS TRIPLE SEC  750ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9006166",
        "Last Price Paid": "$5.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100689",
        "Product Status": "Active"
    },
    {
        "Root": "10023795",
        "LV": "1",
        "Item Description": "COFFEE KCUP REG 120CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023795",
        "Product Status": "Active"
    },
    {
        "Root": "10007625",
        "LV": "2",
        "Item Description": "SANGIO SELVA BUCHERCHIALE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007625001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "941442",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007625",
        "Product Status": "Active"
    },
    {
        "Root": "10015007",
        "LV": "1",
        "Item Description": "PINOT GRIS KING ESTATE 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "357125",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015007",
        "Product Status": "Active"
    },
    {
        "Root": "10030382",
        "LV": "1",
        "Item Description": "BOX KRAFT AUTOMATIC WITH WINDOW 6X6X3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51159024",
        "Last Price Paid": "$0.60",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030382",
        "Product Status": "Active"
    },
    {
        "Root": "10017893",
        "LV": "1",
        "Item Description": "CHICKEN TUCKER 7 INCH 5000 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$140.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017893",
        "Product Status": "Active"
    },
    {
        "Root": "10017893",
        "LV": "1",
        "Item Description": "CHICKEN TUCKER 7 INCH 5000 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$140.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017893",
        "Product Status": "Active"
    },
    {
        "Root": "10015401",
        "LV": "2",
        "Item Description": "CHEESE FRESH MOZZARELLA LOG 16 SLICES 8/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015401002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "0864959",
        "Last Price Paid": "$3.91",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015401",
        "Product Status": "Active"
    },
    {
        "Root": "10000162",
        "LV": "1",
        "Item Description": "SALMON E CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000162001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6501498",
        "Last Price Paid": "$8.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000162",
        "Product Status": "Active"
    },
    {
        "Root": "10033250",
        "LV": "1",
        "Item Description": "POTATO MASHED GARLIC RED SKIN ON 4/6LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1253111",
        "Last Price Paid": "$7.17",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033250",
        "Product Status": "Active"
    },
    {
        "Root": "10105048",
        "LV": "1",
        "Item Description": "RED MISO PASTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35240",
        "Last Price Paid": "$7.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105048",
        "Product Status": "Active"
    },
    {
        "Root": "10039868",
        "LV": "2",
        "Item Description": "BEER CAN MODELO ESPECIAL 6 PACK 12OZ 4/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039868002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10171",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039868",
        "Product Status": "Active"
    },
    {
        "Root": "10016356",
        "LV": "1",
        "Item Description": "BOX WEDDING DELIVERY 14,16,18,20\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7303",
        "Last Price Paid": "$11.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016356",
        "Product Status": "Active"
    },
    {
        "Root": "10039781",
        "LV": "1",
        "Item Description": "OIL OLIVE/CANOLA BLEND FILIPPO BERIO 1 GAL 3/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.03",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039781",
        "Product Status": "Active"
    },
    {
        "Root": "10036308",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE 35.5% CALLEBAUT ZEPHYR 1/11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CN34ZEPH",
        "Last Price Paid": "$91.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036308",
        "Product Status": "Active"
    },
    {
        "Root": "10003624",
        "LV": "1",
        "Item Description": "SB TAZO CHAI BEV CNCNTRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003624001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "162670",
        "Last Price Paid": "$7.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003624",
        "Product Status": "Active"
    },
    {
        "Root": "10010043",
        "LV": "1",
        "Item Description": "BEER CAN MODELO ESPECIAL 16OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "62430",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010043",
        "Product Status": "Active"
    },
    {
        "Root": "10093211",
        "LV": "1",
        "Item Description": "ICING FONDANT O'FASH WHITE TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "20214",
        "Last Price Paid": "$44.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093211",
        "Product Status": "Active"
    },
    {
        "Root": "10004598",
        "LV": "1",
        "Item Description": "VINEGAR CHARD 2/5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090545",
        "Last Price Paid": "$15.20",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004598",
        "Product Status": "Active"
    },
    {
        "Root": "10032453",
        "LV": "2",
        "Item Description": "TUNA TOSHIMA SAKU AAA YF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032453002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000313711",
        "Vendor": "TRUE WORLD FOODS ORLANDO LLC",
        "Vendor Item Nb": "SMT03100T",
        "Last Price Paid": "$24.95",
        "Case Pack": "22",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032453",
        "Product Status": "Active"
    },
    {
        "Root": "10000359",
        "LV": "1",
        "Item Description": "POTATO DICED 1/2 INCH PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "06-005-12",
        "Last Price Paid": "$47.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000359",
        "Product Status": "Active"
    },
    {
        "Root": "10000359",
        "LV": "1",
        "Item Description": "POTATO DICED 1/2 INCH PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511501",
        "Last Price Paid": "$47.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000359",
        "Product Status": "Active"
    },
    {
        "Root": "10028111",
        "LV": "1",
        "Item Description": "BEER KONA HANALEI ISLAND IPA 12 OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27518",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028111",
        "Product Status": "Active"
    },
    {
        "Root": "10000430",
        "LV": "1",
        "Item Description": "BREAD CIABATTA LOAF 22 INCH X 6 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000430",
        "Product Status": "Active"
    },
    {
        "Root": "10100848",
        "LV": "1",
        "Item Description": "SPRING ROLL W/BEEF BURGER & CHEESE 2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100848",
        "Product Status": "Active"
    },
    {
        "Root": "10003509",
        "LV": "2",
        "Item Description": "TEA NOBO WHL FRUIT 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003509001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W2070",
        "Last Price Paid": "$33.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003509",
        "Product Status": "Active"
    },
    {
        "Root": "10020067",
        "LV": "1",
        "Item Description": "COOKIE XMAS SPITZ-KUCHEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LB204",
        "Last Price Paid": "$99.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020067",
        "Product Status": "Active"
    },
    {
        "Root": "10001864",
        "LV": "1",
        "Item Description": "MUSHROOM MIXED DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001864001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405110",
        "Last Price Paid": "$38.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001864",
        "Product Status": "Active"
    },
    {
        "Root": "10020073",
        "LV": "1",
        "Item Description": "COOKIE XMAS PRETZ SHPD GINGERBREAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BA003",
        "Last Price Paid": "$5.11",
        "Case Pack": "21",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020073",
        "Product Status": "Active"
    },
    {
        "Root": "10094628",
        "LV": "3",
        "Item Description": "MIX BAR FRZ TROPICS ORANGE PINEAPPLE CON 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094628002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8139728",
        "Last Price Paid": "$5.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10094628",
        "Product Status": "Active"
    },
    {
        "Root": "10104452",
        "LV": "1",
        "Item Description": "TEA ICED BLACK RITUALS 3GAL YIELD 32/3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.71",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104452",
        "Product Status": "Active"
    },
    {
        "Root": "10012300",
        "LV": "1",
        "Item Description": "SOUR SMALL BITES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14SBSB-33-R",
        "Last Price Paid": "$66.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012300",
        "Product Status": "Active"
    },
    {
        "Root": "10003805",
        "LV": "1",
        "Item Description": "LINGONBERRY WILD TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4523809",
        "Last Price Paid": "$44.11",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003805",
        "Product Status": "Active"
    },
    {
        "Root": "10006987",
        "LV": "1",
        "Item Description": "GAMAY BEAUJOL DUBOEUF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006987001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16635",
        "Last Price Paid": "$7.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006987",
        "Product Status": "Active"
    },
    {
        "Root": "10001163",
        "LV": "1",
        "Item Description": "SALMON PASTRAMI SLCD FSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001163001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6430901",
        "Last Price Paid": "$14.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001163",
        "Product Status": "Active"
    },
    {
        "Root": "10002348",
        "LV": "1",
        "Item Description": "GELATO CHOCOLATE DOUBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-DBCH-08-HP",
        "Last Price Paid": "$9.34",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002348",
        "Product Status": "Active"
    },
    {
        "Root": "10010008",
        "LV": "2",
        "Item Description": "DRAFT MODELO ESPECIAL HBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010008001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14015",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010008",
        "Product Status": "Active"
    },
    {
        "Root": "10003927",
        "LV": "1",
        "Item Description": "SB SYRUP TOFFEE NUT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108265",
        "Last Price Paid": "$7.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003927",
        "Product Status": "Active"
    },
    {
        "Root": "10013219",
        "LV": "1",
        "Item Description": "PINOT NOIR RAINSTORM SILVER LINING ROSE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013219001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "231265",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013219",
        "Product Status": "Active"
    },
    {
        "Root": "10004000",
        "LV": "1",
        "Item Description": "SPICE GUMBO FILE 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004000001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164029",
        "Last Price Paid": "$8.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004000",
        "Product Status": "Active"
    },
    {
        "Root": "10085280",
        "LV": "2",
        "Item Description": "TEASPOON CPLA HW 24/40 SMARTSTOCK TT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085280001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$63.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10085280",
        "Product Status": "Active"
    },
    {
        "Root": "10000195",
        "LV": "1",
        "Item Description": "CARROT JUMBO 50# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482020",
        "Last Price Paid": "$37.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000195",
        "Product Status": "Active"
    },
    {
        "Root": "10000195",
        "LV": "1",
        "Item Description": "CARROT JUMBO 50# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20302",
        "Last Price Paid": "$37.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000195",
        "Product Status": "Active"
    },
    {
        "Root": "10010185",
        "LV": "1",
        "Item Description": "TONIC SEAGRAMS CAN 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010185",
        "Product Status": "Active"
    },
    {
        "Root": "10042407",
        "LV": "1",
        "Item Description": "BITTERS HELLA EUCALYPTUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "945605",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042407",
        "Product Status": "Active"
    },
    {
        "Root": "10014149",
        "LV": "1",
        "Item Description": "HERB VERBENA LEMON 3\" SPRIGS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08V-33",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014149",
        "Product Status": "Active"
    },
    {
        "Root": "10000755",
        "LV": "1",
        "Item Description": "BISON TENDERLOIN PSMO 5UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000755001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000755",
        "Product Status": "Active"
    },
    {
        "Root": "10002340",
        "LV": "1",
        "Item Description": "CHEESE PEPPER JACK SLICED .5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.17",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002340",
        "Product Status": "Active"
    },
    {
        "Root": "10016161",
        "LV": "1",
        "Item Description": "CHOC CIGARETTE DRK/WHT MIKADO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016161001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "91163",
        "Last Price Paid": "$34.66",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016161",
        "Product Status": "Active"
    },
    {
        "Root": "10014238",
        "LV": "2",
        "Item Description": "BEET OF THE NIGHT MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014238002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MBN-33-E",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014238",
        "Product Status": "Active"
    },
    {
        "Root": "10003367",
        "LV": "1",
        "Item Description": "SUGAR IN THE RAW 25# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$40.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003367",
        "Product Status": "Active"
    },
    {
        "Root": "10033920",
        "LV": "2",
        "Item Description": "SYRAH CHT STE MICHELLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033920002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "559049",
        "Last Price Paid": "$11.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033920",
        "Product Status": "Active"
    },
    {
        "Root": "10001886",
        "LV": "1",
        "Item Description": "SQUASH YELLOW DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001886001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "16-002-34",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001886",
        "Product Status": "Active"
    },
    {
        "Root": "10001886",
        "LV": "1",
        "Item Description": "SQUASH YELLOW DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001886001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515528",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001886",
        "Product Status": "Active"
    },
    {
        "Root": "10009520",
        "LV": "1",
        "Item Description": "WHISKEY BULLEIT RYE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "443649",
        "Last Price Paid": "$31.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009520",
        "Product Status": "Active"
    },
    {
        "Root": "10027883",
        "LV": "1",
        "Item Description": "LIQ COMBIER MURE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9320905",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027883",
        "Product Status": "Active"
    },
    {
        "Root": "10018787",
        "LV": "1",
        "Item Description": "CHERRY WHT RANIER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270224",
        "Last Price Paid": "$119.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018787",
        "Product Status": "Active"
    },
    {
        "Root": "10004072",
        "LV": "1",
        "Item Description": "CHOC GLAZE DARK BRILLIANC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C0002",
        "Last Price Paid": "$42.78",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004072",
        "Product Status": "Active"
    },
    {
        "Root": "10028653",
        "LV": "1",
        "Item Description": "EGG CARTON PETE CLEAR OVOTHERM OVP-6SP-1 W/O LABEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028653001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51951001",
        "Last Price Paid": "$0.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028653",
        "Product Status": "Active"
    },
    {
        "Root": "10106152",
        "LV": "2",
        "Item Description": "CUP COLD 4OZ WDW COKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106152002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "104387011",
        "Last Price Paid": "$35.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106152",
        "Product Status": "Active"
    },
    {
        "Root": "10103570",
        "LV": "1",
        "Item Description": "TURKEY HERITAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103570001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "TURHER002",
        "Last Price Paid": "$9.99",
        "Case Pack": "13",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103570",
        "Product Status": "Active"
    },
    {
        "Root": "10012453",
        "LV": "1",
        "Item Description": "DEC HYDRANGEA BUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012453001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8986",
        "Last Price Paid": "$4.45",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012453",
        "Product Status": "Active"
    },
    {
        "Root": "10103327",
        "LV": "1",
        "Item Description": "BUCKET POP PREM PICNIC BASKET MMRR 76OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.91",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103327",
        "Product Status": "Active"
    },
    {
        "Root": "10022050",
        "LV": "2",
        "Item Description": "CHARD CHABLIS DAUVISSAT 1ER CRU FOREST 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022050002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9113029",
        "Last Price Paid": "$64.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10022050",
        "Product Status": "Active"
    },
    {
        "Root": "10082446",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL ROYAL WATTAMELON 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "56430010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082446",
        "Product Status": "Active"
    },
    {
        "Root": "10001689",
        "LV": "1",
        "Item Description": "PEAR RED LRG #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "273000",
        "Last Price Paid": "$0.48",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001689",
        "Product Status": "Active"
    },
    {
        "Root": "10001689",
        "LV": "1",
        "Item Description": "PEAR RED LRG #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.48",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001689",
        "Product Status": "Active"
    },
    {
        "Root": "10001850",
        "LV": "2",
        "Item Description": "FLOWER SQUASH BLOSSOMS 25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001850002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480374",
        "Last Price Paid": "$0.72",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001850",
        "Product Status": "Active"
    },
    {
        "Root": "10001499",
        "LV": "1",
        "Item Description": "SHISO LEAF SPECIFY COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.45",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001499",
        "Product Status": "Active"
    },
    {
        "Root": "10005978",
        "LV": "1",
        "Item Description": "SB BAG WHITE SNAK PAK 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005978",
        "Product Status": "Active"
    },
    {
        "Root": "10034723",
        "LV": "1",
        "Item Description": "SALMON VERLASSO WHOLE 10-12 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034723001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "2684556",
        "Last Price Paid": "$6.99",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034723",
        "Product Status": "Active"
    },
    {
        "Root": "10002202",
        "LV": "2",
        "Item Description": "YOGURT GREEK BLUEBERRY OIKOS 12/5.3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002202001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002202",
        "Product Status": "Active"
    },
    {
        "Root": "10002528",
        "LV": "1",
        "Item Description": "CHEESE ROGUE RIVER BLUE RESTRICTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002528001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "41187",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002528",
        "Product Status": "Active"
    },
    {
        "Root": "10016891",
        "LV": "2",
        "Item Description": "BACON SMKD APPLEWOOD SLCD 13/15",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016891002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016891",
        "Product Status": "Active"
    },
    {
        "Root": "INGRED001",
        "LV": "1",
        "Item Description": "DEFAULT INGREDIENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "INGRED001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "INGRED001",
        "Product Status": "Active"
    },
    {
        "Root": "10105110",
        "LV": "1",
        "Item Description": "CUP CAN 12OZ CLEAR FOTH MICKEY & MINNIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510383",
        "Last Price Paid": "",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105110",
        "Product Status": "Active"
    },
    {
        "Root": "10025628",
        "LV": "1",
        "Item Description": "MOLD BAKING PAPER 3 3/4 X 2 PM95",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PM95",
        "Last Price Paid": "",
        "Case Pack": "1050",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025628",
        "Product Status": "Active"
    },
    {
        "Root": "10042388",
        "LV": "2",
        "Item Description": "BITTERS HELLA GINGER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042388002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10042388",
        "Product Status": "Active"
    },
    {
        "Root": "10084337",
        "LV": "1",
        "Item Description": "CANDY MY M&MS ORANGE 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "10467",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084337",
        "Product Status": "Active"
    },
    {
        "Root": "10004074",
        "LV": "2",
        "Item Description": "FLOUR HAZELNUT 5# BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004074002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004074",
        "Product Status": "Active"
    },
    {
        "Root": "10024623",
        "LV": "1",
        "Item Description": "BRACHETTO MARENCO D AQUI 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "963502",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024623",
        "Product Status": "Active"
    },
    {
        "Root": "10102310",
        "LV": "1",
        "Item Description": "WINE FORTIFIED PORT CHURCHILLS LBV 6/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102310",
        "Product Status": "Active"
    },
    {
        "Root": "10004991",
        "LV": "1",
        "Item Description": "CRACKER BREADSTICK SESAME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SB101...{VIGO{",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004991",
        "Product Status": "Active"
    },
    {
        "Root": "10001449",
        "LV": "1",
        "Item Description": "JUICE LEMON PAST 16/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "652075",
        "Last Price Paid": "$3.33",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001449",
        "Product Status": "Active"
    },
    {
        "Root": "10008294",
        "LV": "1",
        "Item Description": "MERLOT ST DAVID BCH CHARME 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "2530749",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008294",
        "Product Status": "Active"
    },
    {
        "Root": "10008294",
        "LV": "1",
        "Item Description": "MERLOT ST DAVID BCH CHARME 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2530749",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008294",
        "Product Status": "Active"
    },
    {
        "Root": "10003548",
        "LV": "1",
        "Item Description": "SPICE LEMON PEPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003548",
        "Product Status": "Active"
    },
    {
        "Root": "10007246",
        "LV": "1",
        "Item Description": "SPRK PROSCECCO ZONIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "855624",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007246",
        "Product Status": "Active"
    },
    {
        "Root": "10007246",
        "LV": "1",
        "Item Description": "SPRK PROSCECCO ZONIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "855624",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007246",
        "Product Status": "Active"
    },
    {
        "Root": "10025959",
        "LV": "1",
        "Item Description": "CONTAINER CLAMSHELL FIBER 8X8X3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51080803",
        "Last Price Paid": "$0.45",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025959",
        "Product Status": "Active"
    },
    {
        "Root": "10005577",
        "LV": "1",
        "Item Description": "PASTRY DANISH CHEESE MINI 1.6Z BUTTER RTB 80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005577",
        "Product Status": "Active"
    },
    {
        "Root": "10011421",
        "LV": "1",
        "Item Description": "SUGAR MAPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011421001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "112370",
        "Last Price Paid": "$269.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011421",
        "Product Status": "Active"
    },
    {
        "Root": "10023037",
        "LV": "1",
        "Item Description": "BEAN VANILLA MADAGASGAR GRADE A TEMP F&W SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023037001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$304.07",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023037",
        "Product Status": "Active"
    },
    {
        "Root": "10015036",
        "LV": "1",
        "Item Description": "MENABREA BLONDE BEER 330 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "281564",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015036",
        "Product Status": "Active"
    },
    {
        "Root": "10041074",
        "LV": "1",
        "Item Description": "CHEESE JARLSBURG NORWAY 2/12 LB LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041074001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "16992",
        "Last Price Paid": "$8.89",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041074",
        "Product Status": "Active"
    },
    {
        "Root": "10019159",
        "LV": "1",
        "Item Description": "CHOC WHT I'M CELEBRATING PARALLELOGRAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW40901",
        "Last Price Paid": "$0.50",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019159",
        "Product Status": "Active"
    },
    {
        "Root": "10045377",
        "LV": "1",
        "Item Description": "DECOR SUGAR FLAMINGO LG DAK 72/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "49894",
        "Last Price Paid": "$0.44",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045377",
        "Product Status": "Active"
    },
    {
        "Root": "10033279",
        "LV": "1",
        "Item Description": "PUREE REAL BANANA 16.9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9291910",
        "Last Price Paid": "$4.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033279",
        "Product Status": "Active"
    },
    {
        "Root": "10006742",
        "LV": "1",
        "Item Description": "SKEWER BAMBOO 10 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3538170",
        "Last Price Paid": "$0.01",
        "Case Pack": "12000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006742",
        "Product Status": "Active"
    },
    {
        "Root": "10005848",
        "LV": "1",
        "Item Description": "CAKE VANILLA SPONGE ROUND 10 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005848",
        "Product Status": "Active"
    },
    {
        "Root": "10012867",
        "LV": "1",
        "Item Description": "MIX CITRUS SOUR FINEST CALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9157042",
        "Last Price Paid": "$4.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012867",
        "Product Status": "Active"
    },
    {
        "Root": "10028592",
        "LV": "1",
        "Item Description": "SPICE ALEPPO PEPPER FLAKES 1.75 OZ JANSAL VALLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "324260-BC",
        "Last Price Paid": "$5.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028592",
        "Product Status": "Active"
    },
    {
        "Root": "10040264",
        "LV": "2",
        "Item Description": "CHICKEN BREAST FILLETS BREADED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040264002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6576672",
        "Last Price Paid": "$25.15",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040264",
        "Product Status": "Active"
    },
    {
        "Root": "10006337",
        "LV": "2",
        "Item Description": "MEAL KIT FK KN SP S&P NAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006337001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7071797",
        "Last Price Paid": "$0.16",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006337",
        "Product Status": "Active"
    },
    {
        "Root": "10100711",
        "LV": "1",
        "Item Description": "LIQUEUR CHARTEUSE GREEN VEP 1L/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100711001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "275017",
        "Last Price Paid": "$143.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100711",
        "Product Status": "Active"
    },
    {
        "Root": "10001905",
        "LV": "1",
        "Item Description": "PEAR ANJOU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001905",
        "Product Status": "Active"
    },
    {
        "Root": "10001905",
        "LV": "1",
        "Item Description": "PEAR ANJOU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "275060",
        "Last Price Paid": "$42.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001905",
        "Product Status": "Active"
    },
    {
        "Root": "10037235",
        "LV": "2",
        "Item Description": "OLIVE OIL JEAN RENO 6/1 PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037235002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "OILOLI007",
        "Last Price Paid": "$18.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037235",
        "Product Status": "Active"
    },
    {
        "Root": "10038130",
        "LV": "1",
        "Item Description": "DRAFT DELIRIUM RED 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "7766",
        "Last Price Paid": "$409.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038130",
        "Product Status": "Active"
    },
    {
        "Root": "10009457",
        "LV": "1",
        "Item Description": "SCOTCH LAGAVULIN 16YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009457001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28279",
        "Last Price Paid": "$90.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009457",
        "Product Status": "Active"
    },
    {
        "Root": "10109535",
        "LV": "1",
        "Item Description": "BEER KEG PERONI NASTRO AZZURO PALE LAGER 15.5GA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109535",
        "Product Status": "Active"
    },
    {
        "Root": "10035967",
        "LV": "2",
        "Item Description": "POPCORN SALT RED DELOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035967002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2788",
        "Last Price Paid": "$109.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035967",
        "Product Status": "Active"
    },
    {
        "Root": "10030415",
        "LV": "1",
        "Item Description": "CHARDONNAY TRAMIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030415001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "443720",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030415",
        "Product Status": "Active"
    },
    {
        "Root": "10002758",
        "LV": "2",
        "Item Description": "JUICE BOX MM APPLE 40/6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002758002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "002500008097",
        "Last Price Paid": "$0.43",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002758",
        "Product Status": "Active"
    },
    {
        "Root": "10039822",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP CHERRY 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039822",
        "Product Status": "Active"
    },
    {
        "Root": "10108332",
        "LV": "1",
        "Item Description": "PUMPKIN SUPER-PREMIUM ICE CREAM MNSSHP EVENT ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108332",
        "Product Status": "Active"
    },
    {
        "Root": "10031016",
        "LV": "1",
        "Item Description": "CLUB33 3.5\" 60PT SQUARE COASTER 63S2DN2SCLUB33 GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11014806",
        "Last Price Paid": "$0.23",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031016",
        "Product Status": "Active"
    },
    {
        "Root": "10104188",
        "LV": "1",
        "Item Description": "PEPPER CHIPOTLE WHOLE IN SAUCE 6/#10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "PEP000",
        "Last Price Paid": "$18.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104188",
        "Product Status": "Active"
    },
    {
        "Root": "10029205",
        "LV": "1",
        "Item Description": "SANDWICH TURKEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029205001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1704093",
        "Last Price Paid": "$4.10",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029205",
        "Product Status": "Active"
    },
    {
        "Root": "10034991",
        "LV": "1",
        "Item Description": "PRETZEL ROD SALTED 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6941652",
        "Last Price Paid": "$2.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034991",
        "Product Status": "Active"
    },
    {
        "Root": "10006385",
        "LV": "1",
        "Item Description": "FILTER BUNN (20109) U3 3 GAL 252CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006385",
        "Product Status": "Active"
    },
    {
        "Root": "10035478",
        "LV": "2",
        "Item Description": "LES MILLES FEUILLES SMALL 125CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035478002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8967015",
        "Last Price Paid": "$0.54",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035478",
        "Product Status": "Active"
    },
    {
        "Root": "10002227",
        "LV": "1",
        "Item Description": "CHEESE, AMERICAN PKU SLICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002227001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CAMBROOKE FOOD",
        "Last Price Paid": "$0.69",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002227",
        "Product Status": "Active"
    },
    {
        "Root": "10045074",
        "LV": "1",
        "Item Description": "COOKIE CHOC CHIP MINI HOMEFREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045074001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "LGFMCC30",
        "Last Price Paid": "$1.03",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045074",
        "Product Status": "Active"
    },
    {
        "Root": "10009961",
        "LV": "1",
        "Item Description": "LIQ TIA MARIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "92544",
        "Last Price Paid": "$21.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009961",
        "Product Status": "Active"
    },
    {
        "Root": "10108733",
        "LV": "1",
        "Item Description": "BAR NON ALC SPIRIT LYRES RUM SPICED CANE 700ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9672237",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108733",
        "Product Status": "Active"
    },
    {
        "Root": "10007046",
        "LV": "1",
        "Item Description": "PINOT NOIR ACROBAT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007046001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "559328",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007046",
        "Product Status": "Active"
    },
    {
        "Root": "10015406",
        "LV": "1",
        "Item Description": "SPICE CARDAMOM WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015406001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "163958",
        "Last Price Paid": "$27.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015406",
        "Product Status": "Active"
    },
    {
        "Root": "10002123",
        "LV": "1",
        "Item Description": "CHEESE SWISS LOAF 2/8#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002123001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5377023",
        "Last Price Paid": "$3.24",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002123",
        "Product Status": "Active"
    },
    {
        "Root": "10014560",
        "LV": "1",
        "Item Description": "TINY ONION PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482713",
        "Last Price Paid": "$0.39",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014560",
        "Product Status": "Active"
    },
    {
        "Root": "10000768",
        "LV": "1",
        "Item Description": "ELK TENDERLOIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000768001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "EL021",
        "Last Price Paid": "$37.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000768",
        "Product Status": "Active"
    },
    {
        "Root": "10034086",
        "LV": "2",
        "Item Description": "JAR PINT CLEAR IRELAND 12OZ TWPJ0102TCA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034086002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51954001",
        "Last Price Paid": "$0.92",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034086",
        "Product Status": "Active"
    },
    {
        "Root": "10105091",
        "LV": "1",
        "Item Description": "CARTON FOOD #4 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105091",
        "Product Status": "Active"
    },
    {
        "Root": "10102692",
        "LV": "1",
        "Item Description": "HAM BONE IN JAMON IBERICO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102692001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7026420",
        "Last Price Paid": "$65.80",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102692",
        "Product Status": "Active"
    },
    {
        "Root": "10007417",
        "LV": "1",
        "Item Description": "CAB SAUV TOM EDDY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9023356",
        "Last Price Paid": "$79.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007417",
        "Product Status": "Active"
    },
    {
        "Root": "10094039",
        "LV": "1",
        "Item Description": "BEER KEG BRECKENRIDGE SAFARI AMBER ALE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094039001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$117.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094039",
        "Product Status": "Active"
    },
    {
        "Root": "10001949",
        "LV": "1",
        "Item Description": "BASIL MICRO GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MBA-33-S",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001949",
        "Product Status": "Active"
    },
    {
        "Root": "10001949",
        "LV": "1",
        "Item Description": "BASIL MICRO GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403521",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001949",
        "Product Status": "Active"
    },
    {
        "Root": "10001949",
        "LV": "1",
        "Item Description": "BASIL MICRO GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001949",
        "Product Status": "Active"
    },
    {
        "Root": "10003380",
        "LV": "2",
        "Item Description": "SPICE CARAWAY SEED WHOLE 6/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003380002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00576-4",
        "Last Price Paid": "$2.84",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003380",
        "Product Status": "Active"
    },
    {
        "Root": "10000326",
        "LV": "1",
        "Item Description": "LETTUCE DELUXE BLND LRG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489997",
        "Last Price Paid": "$30.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000326",
        "Product Status": "Active"
    },
    {
        "Root": "10095771",
        "LV": "1",
        "Item Description": "ICE CREAM HONEY SALTED CARAMEL ALMOND HAAGEN-DAZS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12352602",
        "Last Price Paid": "$1.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095771",
        "Product Status": "Active"
    },
    {
        "Root": "10032648",
        "LV": "1",
        "Item Description": "GREEN TINY CUBE 6411-GRN 2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11417119",
        "Last Price Paid": "$0.21",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032648",
        "Product Status": "Active"
    },
    {
        "Root": "10041234",
        "LV": "1",
        "Item Description": "FORK POLYSTYRENE PLASTIC BLACK ULTRA SMART STOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "960",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041234",
        "Product Status": "Active"
    },
    {
        "Root": "10000240",
        "LV": "1",
        "Item Description": "MANGO DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30244",
        "Last Price Paid": "$42.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000240",
        "Product Status": "Active"
    },
    {
        "Root": "10000240",
        "LV": "1",
        "Item Description": "MANGO DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510513",
        "Last Price Paid": "$42.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000240",
        "Product Status": "Active"
    },
    {
        "Root": "10000240",
        "LV": "1",
        "Item Description": "MANGO DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-015-12",
        "Last Price Paid": "$42.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000240",
        "Product Status": "Active"
    },
    {
        "Root": "10019146",
        "LV": "1",
        "Item Description": "SHELL TART LG RND GRAHAM CRACKER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8095068",
        "Last Price Paid": "$1.01",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019146",
        "Product Status": "Active"
    },
    {
        "Root": "10011021",
        "LV": "1",
        "Item Description": "CHEESECAKE MINI STRAWBERRY 1.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1501-A",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011021",
        "Product Status": "Active"
    },
    {
        "Root": "10033780",
        "LV": "1",
        "Item Description": "DRESSING ASIAN SESAME 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6133060",
        "Last Price Paid": "$17.91",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033780",
        "Product Status": "Active"
    },
    {
        "Root": "10028974",
        "LV": "1",
        "Item Description": "WINE ROSE MEIOMI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "544628",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028974",
        "Product Status": "Active"
    },
    {
        "Root": "10107209",
        "LV": "1",
        "Item Description": "SPRING ROLL -CORDON BLEU W/SMK CHICKEN, HAM & SWIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107209",
        "Product Status": "Active"
    },
    {
        "Root": "10001694",
        "LV": "1",
        "Item Description": "POTATO YUKON DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001694001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515038",
        "Last Price Paid": "$10.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001694",
        "Product Status": "Active"
    },
    {
        "Root": "10001694",
        "LV": "1",
        "Item Description": "POTATO YUKON DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001694001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "30-018-12",
        "Last Price Paid": "$10.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001694",
        "Product Status": "Active"
    },
    {
        "Root": "10030367",
        "LV": "1",
        "Item Description": "CAKEPOP RED VELVET FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "700890",
        "Last Price Paid": "$1.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030367",
        "Product Status": "Active"
    },
    {
        "Root": "10016085",
        "LV": "1",
        "Item Description": "VEAL BONES FEMUR AND LEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016085001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.15",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016085",
        "Product Status": "Active"
    },
    {
        "Root": "10030468",
        "LV": "1",
        "Item Description": "SPRINKLES-RAINBOW SHIMMER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21058",
        "Last Price Paid": "$4.92",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030468",
        "Product Status": "Active"
    },
    {
        "Root": "10044733",
        "LV": "1",
        "Item Description": "SPICE CUMIN GROUND 16OZ SHAKER BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760629",
        "Last Price Paid": "$13.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044733",
        "Product Status": "Active"
    },
    {
        "Root": "10035960",
        "LV": "3",
        "Item Description": "POPCORN GLAZE RED CHILI LIME DELOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035960002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2787",
        "Last Price Paid": "$2.88",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10035960",
        "Product Status": "Active"
    },
    {
        "Root": "10003754",
        "LV": "1",
        "Item Description": "MIX CAKE SPICE CARROT 26Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "75375",
        "Last Price Paid": "$7.09",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003754",
        "Product Status": "Active"
    },
    {
        "Root": "10000836",
        "LV": "1",
        "Item Description": "TURKEY BRST OIL BROWNED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000836001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000836",
        "Product Status": "Active"
    },
    {
        "Root": "10083139",
        "LV": "1",
        "Item Description": "DEC DRINK ME ELIXIR BLACK BOW CHOC 4.6X2.8CM 400PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234510000",
        "Last Price Paid": "",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083139",
        "Product Status": "Active"
    },
    {
        "Root": "10010213",
        "LV": "2",
        "Item Description": "DRAFT DOGFISH HD 60 MIN 5.16G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010213001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12401",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010213",
        "Product Status": "Active"
    },
    {
        "Root": "10110210",
        "LV": "2",
        "Item Description": "PLATE PAPER OVAL 7X9\" WDW MARY BLAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110210001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10110210",
        "Product Status": "Active"
    },
    {
        "Root": "10034816",
        "LV": "1",
        "Item Description": "CANDY CANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y2311",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034816",
        "Product Status": "Active"
    },
    {
        "Root": "10102311",
        "LV": "1",
        "Item Description": "WINE RED TEMPRANILLO PECINA GRAN RESERVA 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102311",
        "Product Status": "Active"
    },
    {
        "Root": "10037823",
        "LV": "1",
        "Item Description": "BUCKET BASE POP HOLIDAY FIREPLCE 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037823",
        "Product Status": "Active"
    },
    {
        "Root": "10000591",
        "LV": "2",
        "Item Description": "PORK BACON LAYOUT 14-18CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000591001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "225",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000591",
        "Product Status": "Active"
    },
    {
        "Root": "10085546",
        "LV": "1",
        "Item Description": "BAR MIX PINK GUAVA 2 TO 1 FZN CONCENTRATE 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085546001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9685309",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085546",
        "Product Status": "Active"
    },
    {
        "Root": "10024592",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT CHOC HAZELNUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "37657",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024592",
        "Product Status": "Active"
    },
    {
        "Root": "10005209",
        "LV": "1",
        "Item Description": "SB COFFEE ANNVERSARY BLEND 5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7356",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005209",
        "Product Status": "Active"
    },
    {
        "Root": "10037535",
        "LV": "1",
        "Item Description": "LEMON SQUARES FULL SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "SH801",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037535",
        "Product Status": "Active"
    },
    {
        "Root": "10001710",
        "LV": "1",
        "Item Description": "ONION CHIPOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001710",
        "Product Status": "Active"
    },
    {
        "Root": "10001710",
        "LV": "1",
        "Item Description": "ONION CHIPOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001710",
        "Product Status": "Active"
    },
    {
        "Root": "10001710",
        "LV": "1",
        "Item Description": "ONION CHIPOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406103",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001710",
        "Product Status": "Active"
    },
    {
        "Root": "10028202",
        "LV": "1",
        "Item Description": "TRAY SQUARE COMBO BLACK 14 C9614",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303281",
        "Last Price Paid": "$4.16",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028202",
        "Product Status": "Active"
    },
    {
        "Root": "10024517",
        "LV": "2",
        "Item Description": "VINEGAR IP8 BEER MINUS 8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024517002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN004",
        "Last Price Paid": "$20.05",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10024517",
        "Product Status": "Active"
    },
    {
        "Root": "10040971",
        "LV": "2",
        "Item Description": "WHISKEY SKREWBALL PEANUT BUTTER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040971002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561798",
        "Last Price Paid": "$21.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040971",
        "Product Status": "Active"
    },
    {
        "Root": "10037615",
        "LV": "1",
        "Item Description": "CAKE BOX SQUARE AMORETTES 8.875X8.875X5.5 434654-0",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003132",
        "Last Price Paid": "$4.46",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037615",
        "Product Status": "Active"
    },
    {
        "Root": "10106751",
        "LV": "1",
        "Item Description": "MUSTARD CHINESE HOT 12/7.25OZ KA' ME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3719283",
        "Last Price Paid": "$3.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106751",
        "Product Status": "Active"
    },
    {
        "Root": "10038081",
        "LV": "2",
        "Item Description": "BEEF HOT DOG 2-1 1/10LB 12INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038081002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.68",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038081",
        "Product Status": "Active"
    },
    {
        "Root": "10001937",
        "LV": "1",
        "Item Description": "RUTABAGA DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511022",
        "Last Price Paid": "$11.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001937",
        "Product Status": "Active"
    },
    {
        "Root": "10001937",
        "LV": "1",
        "Item Description": "RUTABAGA DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-010-14",
        "Last Price Paid": "$11.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001937",
        "Product Status": "Active"
    },
    {
        "Root": "10009843",
        "LV": "1",
        "Item Description": "COGNAC COURVOISIER XO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "407346",
        "Last Price Paid": "$142.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009843",
        "Product Status": "Active"
    },
    {
        "Root": "10002191",
        "LV": "1",
        "Item Description": "MILK 2% QUART",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.96",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002191",
        "Product Status": "Active"
    },
    {
        "Root": "10035908",
        "LV": "1",
        "Item Description": "PLATE BAGASSE OVAL SUGARCANE 10X8 BE-FOP108",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11851038",
        "Last Price Paid": "$0.15",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035908",
        "Product Status": "Active"
    },
    {
        "Root": "10043197",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN MEDALLION 3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043197001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2119386",
        "Last Price Paid": "$15.26",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043197",
        "Product Status": "Active"
    },
    {
        "Root": "10002046",
        "LV": "1",
        "Item Description": "SQUASH SPAGHETTI LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002046001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002046",
        "Product Status": "Active"
    },
    {
        "Root": "10018689",
        "LV": "1",
        "Item Description": "RAMP 10# CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01RA-26",
        "Last Price Paid": "$159.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018689",
        "Product Status": "Active"
    },
    {
        "Root": "10003342",
        "LV": "1",
        "Item Description": "SYRUP FUZE RASPBERRY TEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003342",
        "Product Status": "Active"
    },
    {
        "Root": "10013787",
        "LV": "1",
        "Item Description": "BAGUETTE FARMHOUSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "2:FB",
        "Last Price Paid": "$2.64",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013787",
        "Product Status": "Active"
    },
    {
        "Root": "10012419",
        "LV": "1",
        "Item Description": "CHEESE ROLL SAMBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "81163",
        "Last Price Paid": "$11.37",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012419",
        "Product Status": "Active"
    },
    {
        "Root": "10003321",
        "LV": "1",
        "Item Description": "SYRUP 2.5 BARQ RT BEER BI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "104235",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003321",
        "Product Status": "Active"
    },
    {
        "Root": "10104149",
        "LV": "2",
        "Item Description": "CHICKEN BREAST BONELESS SKINLESS 6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104149002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.94",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104149",
        "Product Status": "Active"
    },
    {
        "Root": "10003903",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE CINDERELLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003903001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 1755",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003903",
        "Product Status": "Active"
    },
    {
        "Root": "10044783",
        "LV": "1",
        "Item Description": "CHOCOLATE DECOR NAIL EPCOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044783001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3575",
        "Last Price Paid": "$0.75",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044783",
        "Product Status": "Active"
    },
    {
        "Root": "10003169",
        "LV": "2",
        "Item Description": "TEA ORGANIC PEPPERM LSE 7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003169001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W1235",
        "Last Price Paid": "$17.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003169",
        "Product Status": "Active"
    },
    {
        "Root": "10003016",
        "LV": "1",
        "Item Description": "DRESSING RANCH FF 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003016",
        "Product Status": "Active"
    },
    {
        "Root": "10095614",
        "LV": "1",
        "Item Description": "SHERRY GONZALES BYASS NECTAR PX 375ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "525338",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095614",
        "Product Status": "Active"
    },
    {
        "Root": "10004086",
        "LV": "1",
        "Item Description": "PANCAKE ORGNL HEAT&SERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004086001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7439026",
        "Last Price Paid": "$0.21",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004086",
        "Product Status": "Active"
    },
    {
        "Root": "10027278",
        "LV": "1",
        "Item Description": "CANDY SOUR BRITE WORMS 6/9.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891896",
        "Last Price Paid": "$3.37",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027278",
        "Product Status": "Active"
    },
    {
        "Root": "10015623",
        "LV": "1",
        "Item Description": "SAUV BLANC LAIL BLUEPRINT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "000842",
        "Last Price Paid": "$45.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015623",
        "Product Status": "Active"
    },
    {
        "Root": "10006110",
        "LV": "1",
        "Item Description": "BOWL UH OA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.45",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006110",
        "Product Status": "Active"
    },
    {
        "Root": "10006418",
        "LV": "1",
        "Item Description": "CLIP STICK Z411 25M/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "13500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006418",
        "Product Status": "Active"
    },
    {
        "Root": "10028266",
        "LV": "1",
        "Item Description": "MALBEC CROCUS 2014 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "714246",
        "Last Price Paid": "$14.19",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028266",
        "Product Status": "Active"
    },
    {
        "Root": "10012420",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR DUBLINER IRISH KERRYGOLD 2/5 LB LOA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012420001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "25",
        "Last Price Paid": "$9.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012420",
        "Product Status": "Active"
    },
    {
        "Root": "10000594",
        "LV": "1",
        "Item Description": "PORK BELLY BONELESS SKIN OFF 11 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000594001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.52",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000594",
        "Product Status": "Active"
    },
    {
        "Root": "10045196",
        "LV": "1",
        "Item Description": "SOUP LOBSTER BISQUE W/SHERRY RTU 4/4LB FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8845752",
        "Last Price Paid": "$12.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045196",
        "Product Status": "Active"
    },
    {
        "Root": "10001906",
        "LV": "1",
        "Item Description": "CHERRY DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270221",
        "Last Price Paid": "$46.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001906",
        "Product Status": "Active"
    },
    {
        "Root": "10001906",
        "LV": "1",
        "Item Description": "CHERRY DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$46.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001906",
        "Product Status": "Active"
    },
    {
        "Root": "10010174",
        "LV": "1",
        "Item Description": "WATER SMART 20 OZ 24EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010174",
        "Product Status": "Active"
    },
    {
        "Root": "10010174",
        "LV": "1",
        "Item Description": "WATER SMART 20 OZ 24EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "29254",
        "Last Price Paid": "$1.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010174",
        "Product Status": "Active"
    },
    {
        "Root": "10010174",
        "LV": "1",
        "Item Description": "WATER SMART 20 OZ 24EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "129254",
        "Last Price Paid": "$1.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010174",
        "Product Status": "Active"
    },
    {
        "Root": "10009512",
        "LV": "1",
        "Item Description": "WHISKY FIREBALL CINNMN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9031406",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009512",
        "Product Status": "Active"
    },
    {
        "Root": "10000215",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI 18# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20544",
        "Last Price Paid": "$20.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000215",
        "Product Status": "Active"
    },
    {
        "Root": "10000215",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI 18# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "409060",
        "Last Price Paid": "$20.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000215",
        "Product Status": "Active"
    },
    {
        "Root": "10001912",
        "LV": "1",
        "Item Description": "PAPAYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001912",
        "Product Status": "Active"
    },
    {
        "Root": "10001912",
        "LV": "1",
        "Item Description": "PAPAYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16151",
        "Last Price Paid": "$28.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001912",
        "Product Status": "Active"
    },
    {
        "Root": "10001912",
        "LV": "1",
        "Item Description": "PAPAYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "303012",
        "Last Price Paid": "$28.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001912",
        "Product Status": "Active"
    },
    {
        "Root": "10041313",
        "LV": "1",
        "Item Description": "JUICE ORANGE TROPICANA PURE PREMIUM 12/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4605416",
        "Last Price Paid": "$1.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041313",
        "Product Status": "Active"
    },
    {
        "Root": "10012483",
        "LV": "1",
        "Item Description": "FLOWER CHRYSANTHEMUM FARMERS BOUNTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04FBCH-2",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012483",
        "Product Status": "Active"
    },
    {
        "Root": "10035449",
        "LV": "1",
        "Item Description": "GLOVE SHOULDER LENGTH CLEAR POLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "79100008",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035449",
        "Product Status": "Active"
    },
    {
        "Root": "10004144",
        "LV": "1",
        "Item Description": "TRUFFLE PASSION FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.08",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004144",
        "Product Status": "Active"
    },
    {
        "Root": "10003204",
        "LV": "1",
        "Item Description": "COFFEE SUMATRA EXP ORGANI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.04",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003204",
        "Product Status": "Active"
    },
    {
        "Root": "10042188",
        "LV": "1",
        "Item Description": "CUP PAPER COLD 16OZ NO TREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042188",
        "Product Status": "Active"
    },
    {
        "Root": "10042188",
        "LV": "1",
        "Item Description": "CUP PAPER COLD 16OZ NO TREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11012728",
        "Last Price Paid": "$0.14",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042188",
        "Product Status": "Active"
    },
    {
        "Root": "10001841",
        "LV": "1",
        "Item Description": "MUSHROOM HEN OF WOODS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001841001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50030",
        "Last Price Paid": "$19.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001841",
        "Product Status": "Active"
    },
    {
        "Root": "10001841",
        "LV": "1",
        "Item Description": "MUSHROOM HEN OF WOODS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001841001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001841",
        "Product Status": "Active"
    },
    {
        "Root": "10003307",
        "LV": "1",
        "Item Description": "SODA FANTA PINEAPPLE 20Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003307",
        "Product Status": "Active"
    },
    {
        "Root": "10013927",
        "LV": "1",
        "Item Description": "PEAR BARTLETT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "275100",
        "Last Price Paid": "$45.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013927",
        "Product Status": "Active"
    },
    {
        "Root": "10106568",
        "LV": "1",
        "Item Description": "LABEL 1.5\" ROUND PERM NP 3\"CORE AMORETTE'S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45370395",
        "Last Price Paid": "$0.09",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106568",
        "Product Status": "Active"
    },
    {
        "Root": "10036233",
        "LV": "1",
        "Item Description": "SB SLEEVE HOT CUP CORE FOR 12/16/20Z CUP 1380/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036233001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11120832",
        "Last Price Paid": "$0.02",
        "Case Pack": "1380",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036233",
        "Product Status": "Active"
    },
    {
        "Root": "10021615",
        "LV": "1",
        "Item Description": "BEER MAUI MANA WHEAT 12 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1369",
        "Last Price Paid": "$1.62",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021615",
        "Product Status": "Active"
    },
    {
        "Root": "10003286",
        "LV": "1",
        "Item Description": "SYRUP MONIN HICK SMKE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003286",
        "Product Status": "Active"
    },
    {
        "Root": "10040139",
        "LV": "1",
        "Item Description": "VODKA WHIPPED CREAM TAAKA 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "855343",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040139",
        "Product Status": "Active"
    },
    {
        "Root": "10018286",
        "LV": "1",
        "Item Description": "MUFFIN BLUEBERRY MINI (2.5 OZ) INDV WRAP 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018286",
        "Product Status": "Active"
    },
    {
        "Root": "10001624",
        "LV": "2",
        "Item Description": "RASPBERRY RED WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001624002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.52",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001624",
        "Product Status": "Active"
    },
    {
        "Root": "10027846",
        "LV": "1",
        "Item Description": "CHIPS PRETZELS,YOGURT COVERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "304025",
        "Last Price Paid": "$3.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027846",
        "Product Status": "Active"
    },
    {
        "Root": "10101950",
        "LV": "1",
        "Item Description": "WINE RED SCHOONER TRANSIT RED BLEND 750ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "542428",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101950",
        "Product Status": "Active"
    },
    {
        "Root": "10000585",
        "LV": "2",
        "Item Description": "PORK SAUSAGE LINK 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000585001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000585",
        "Product Status": "Active"
    },
    {
        "Root": "10032602",
        "LV": "1",
        "Item Description": "MEATLESS BEYOND SAUSAGE HOT ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103318",
        "Last Price Paid": "$1.81",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032602",
        "Product Status": "Active"
    },
    {
        "Root": "10010800",
        "LV": "2",
        "Item Description": "BEER EINSTOK TSTD PRTR 11.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010800001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "33256",
        "Last Price Paid": "$1.59",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010800",
        "Product Status": "Active"
    },
    {
        "Root": "10002746",
        "LV": "1",
        "Item Description": "SYRUP ORANGE FANTA 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$95.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002746",
        "Product Status": "Active"
    },
    {
        "Root": "10017486",
        "LV": "1",
        "Item Description": "SEED HEMP HEART DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017486001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480521",
        "Last Price Paid": "$83.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017486",
        "Product Status": "Active"
    },
    {
        "Root": "10008441",
        "LV": "1",
        "Item Description": "CAB SAUV MONTES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "71375",
        "Last Price Paid": "$70.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008441",
        "Product Status": "Active"
    },
    {
        "Root": "10012773",
        "LV": "1",
        "Item Description": "BOATWRIGHTS BLEND REGULAR FRENCH PRESS 1# RETAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.84",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012773",
        "Product Status": "Active"
    },
    {
        "Root": "10004436",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE COATING VANILLA NH 1/25 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$108.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004436",
        "Product Status": "Active"
    },
    {
        "Root": "10000940",
        "LV": "1",
        "Item Description": "SHRIMP 91/110 RAW P&D T/OFF PACKED 1/10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000940001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000940",
        "Product Status": "Active"
    },
    {
        "Root": "10000940",
        "LV": "1",
        "Item Description": "SHRIMP 91/110 RAW P&D T/OFF PACKED 1/10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000940001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000940",
        "Product Status": "Active"
    },
    {
        "Root": "10000940",
        "LV": "1",
        "Item Description": "SHRIMP 91/110 RAW P&D T/OFF PACKED 1/10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000940001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125626",
        "Last Price Paid": "$4.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000940",
        "Product Status": "Active"
    },
    {
        "Root": "10000180",
        "LV": "1",
        "Item Description": "LIME MEXI 175 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "175",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000180",
        "Product Status": "Active"
    },
    {
        "Root": "10001792",
        "LV": "1",
        "Item Description": "MUSHROOM MASUTAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001792001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50035",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001792",
        "Product Status": "Active"
    },
    {
        "Root": "10100762",
        "LV": "1",
        "Item Description": "HONEY CREAMED CHEFS GARDEN  8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "02CH-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100762",
        "Product Status": "Active"
    },
    {
        "Root": "10018446",
        "LV": "1",
        "Item Description": "BEET GOLD DICED 3/4\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "550041",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018446",
        "Product Status": "Active"
    },
    {
        "Root": "10019158",
        "LV": "1",
        "Item Description": "CHOC DRK MICKET HAT W/EARS 6X3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3172",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019158",
        "Product Status": "Active"
    },
    {
        "Root": "10106828",
        "LV": "1",
        "Item Description": "VEAL OSSO BUCCO CENTER CUT 16-OZ HINDSHANK FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106828001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZVEAFRA011",
        "Last Price Paid": "",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106828",
        "Product Status": "Active"
    },
    {
        "Root": "10107770",
        "LV": "1",
        "Item Description": "TRIPLE BOTTLE BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107770",
        "Product Status": "Active"
    },
    {
        "Root": "10004515",
        "LV": "2",
        "Item Description": "NUT PEANUT R&S NO SHELL 12/3.25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004515002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "200670",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004515",
        "Product Status": "Active"
    },
    {
        "Root": "10041157",
        "LV": "1",
        "Item Description": "DRAFT SIERRA NEVADA HAZY LITTLE 5.16 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041157001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38545",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041157",
        "Product Status": "Active"
    },
    {
        "Root": "10030246",
        "LV": "1",
        "Item Description": "SERVICE FEE FREIGHT CHARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030246",
        "Product Status": "Active"
    },
    {
        "Root": "10022866",
        "LV": "1",
        "Item Description": "CHOC WHT 3.5 X 2 RECT LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2178W",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022866",
        "Product Status": "Active"
    },
    {
        "Root": "10002836",
        "LV": "1",
        "Item Description": "SAUCE SOY 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002836",
        "Product Status": "Active"
    },
    {
        "Root": "10015142",
        "LV": "2",
        "Item Description": "SPICE AJI AMARILLO POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015142002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "HB086",
        "Last Price Paid": "$37.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015142",
        "Product Status": "Active"
    },
    {
        "Root": "10084170",
        "LV": "1",
        "Item Description": "LINER BASKET 12X12 HEAVY WEIGHT 4 COLORS 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51015079",
        "Last Price Paid": "$0.11",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084170",
        "Product Status": "Active"
    },
    {
        "Root": "10012229",
        "LV": "1",
        "Item Description": "SCOTCH HIGHLAND PARK 12 YEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21119",
        "Last Price Paid": "$48.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012229",
        "Product Status": "Active"
    },
    {
        "Root": "10031810",
        "LV": "1",
        "Item Description": "FLORIDA BEER CARIBE PINEAPPLE CIDER 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60302",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031810",
        "Product Status": "Active"
    },
    {
        "Root": "10004644",
        "LV": "1",
        "Item Description": "SHELL PASTRY PHYLLO 1.75 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004644001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2138766",
        "Last Price Paid": "$0.18",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004644",
        "Product Status": "Active"
    },
    {
        "Root": "10010828",
        "LV": "2",
        "Item Description": "BEER DUVEL BLGN GLD 12Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010828001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13556",
        "Last Price Paid": "$2.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010828",
        "Product Status": "Active"
    },
    {
        "Root": "10013883",
        "LV": "1",
        "Item Description": "BASIL PETITE HERB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482299",
        "Last Price Paid": "$22.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013883",
        "Product Status": "Active"
    },
    {
        "Root": "10003833",
        "LV": "1",
        "Item Description": "BAR WHL BERRY BLAST PRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "393413",
        "Last Price Paid": "$2.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003833",
        "Product Status": "Active"
    },
    {
        "Root": "10040896",
        "LV": "1",
        "Item Description": "BUTTER SALTED MICKEY POP OUT .23 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "750",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040896",
        "Product Status": "Active"
    },
    {
        "Root": "10004718",
        "LV": "1",
        "Item Description": "CHIP POTATO SOUR CRM/ONIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1315134",
        "Last Price Paid": "$0.35",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004718",
        "Product Status": "Active"
    },
    {
        "Root": "10038357",
        "LV": "1",
        "Item Description": "WHISKEY MICHTERS SINGLE BARREL STRAIGHT RYE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9013046",
        "Last Price Paid": "$30.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038357",
        "Product Status": "Active"
    },
    {
        "Root": "10013260",
        "LV": "1",
        "Item Description": "TEA TWNG GOLDEN CARAMEL ROOIBOS 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013260",
        "Product Status": "Active"
    },
    {
        "Root": "10007270",
        "LV": "2",
        "Item Description": "BRUNELLO CASTIGLION DEL BOSCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007270001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "581794",
        "Last Price Paid": "$44.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007270",
        "Product Status": "Active"
    },
    {
        "Root": "10001696",
        "LV": "1",
        "Item Description": "HERB CHERVIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001696001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24040",
        "Last Price Paid": "$21.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001696",
        "Product Status": "Active"
    },
    {
        "Root": "10001696",
        "LV": "1",
        "Item Description": "HERB CHERVIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001696001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001696",
        "Product Status": "Active"
    },
    {
        "Root": "10001696",
        "LV": "1",
        "Item Description": "HERB CHERVIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001696001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450032",
        "Last Price Paid": "$21.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001696",
        "Product Status": "Active"
    },
    {
        "Root": "10002602",
        "LV": "1",
        "Item Description": "CUCUMBER BLOOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05CU-33-A",
        "Last Price Paid": "$20.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002602",
        "Product Status": "Active"
    },
    {
        "Root": "10000543",
        "LV": "1",
        "Item Description": "BREAD ROLL SUB TOP-CUT 6 INCH FRENCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000543",
        "Product Status": "Active"
    },
    {
        "Root": "10006450",
        "LV": "1",
        "Item Description": "BOARD CAKE SILVER 10 INCH X1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006450",
        "Product Status": "Active"
    },
    {
        "Root": "10001830",
        "LV": "1",
        "Item Description": "WATERCRESS ICELESS 1.5# B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21695",
        "Last Price Paid": "$12.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001830",
        "Product Status": "Active"
    },
    {
        "Root": "10001830",
        "LV": "1",
        "Item Description": "WATERCRESS ICELESS 1.5# B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450093",
        "Last Price Paid": "$12.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001830",
        "Product Status": "Active"
    },
    {
        "Root": "10005010",
        "LV": "2",
        "Item Description": "CHOCOLATE DARK WORKHORSE 50% SICAO 2/11#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005010001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C515A",
        "Last Price Paid": "$44.70",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005010",
        "Product Status": "Active"
    },
    {
        "Root": "10041254",
        "LV": "1",
        "Item Description": "LETTUCE SPRING MIX GOURMET 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514036",
        "Last Price Paid": "$9.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041254",
        "Product Status": "Active"
    },
    {
        "Root": "10041254",
        "LV": "1",
        "Item Description": "LETTUCE SPRING MIX GOURMET 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041254",
        "Product Status": "Active"
    },
    {
        "Root": "10010360",
        "LV": "1",
        "Item Description": "BEER GOOSE ISL 312 WHT URBAN BT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21518",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010360",
        "Product Status": "Active"
    },
    {
        "Root": "10010253",
        "LV": "2",
        "Item Description": "BEER KEG BELLS OBERON SEASONAL 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010253001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "58266",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010253",
        "Product Status": "Active"
    },
    {
        "Root": "10004924",
        "LV": "1",
        "Item Description": "CHOC SHAVING WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "..{MONA LISA",
        "Last Price Paid": "$130.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004924",
        "Product Status": "Active"
    },
    {
        "Root": "10101508",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101508001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "7076364",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101508",
        "Product Status": "Active"
    },
    {
        "Root": "10101508",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101508001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00420",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101508",
        "Product Status": "Active"
    },
    {
        "Root": "10032553",
        "LV": "1",
        "Item Description": "COLOR LUSTER SPRAY COBALT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586019",
        "Last Price Paid": "$28.34",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032553",
        "Product Status": "Active"
    },
    {
        "Root": "10004475",
        "LV": "1",
        "Item Description": "CANDY M&M PLAIN FUN SIZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "823",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004475",
        "Product Status": "Active"
    },
    {
        "Root": "10004475",
        "LV": "1",
        "Item Description": "CANDY M&M PLAIN FUN SIZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "529981",
        "Last Price Paid": "$0.14",
        "Case Pack": "823",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004475",
        "Product Status": "Active"
    },
    {
        "Root": "10101571",
        "LV": "1",
        "Item Description": "BABY FENNEL 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22316",
        "Last Price Paid": "$1.33",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101571",
        "Product Status": "Active"
    },
    {
        "Root": "10091312",
        "LV": "1",
        "Item Description": "WINE RED LIEVLAND CABERNET SAUVIGNON 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9353776",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091312",
        "Product Status": "Active"
    },
    {
        "Root": "10010804",
        "LV": "2",
        "Item Description": "WHISKY CROWN ROYAL XO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010804001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "352379",
        "Last Price Paid": "$38.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010804",
        "Product Status": "Active"
    },
    {
        "Root": "10001574",
        "LV": "1",
        "Item Description": "PEPPER GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598779",
        "Last Price Paid": "$11.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001574",
        "Product Status": "Active"
    },
    {
        "Root": "10001601",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI PLANKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "17-008",
        "Last Price Paid": "$11.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001601",
        "Product Status": "Active"
    },
    {
        "Root": "10001601",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI PLANKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490072",
        "Last Price Paid": "$11.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001601",
        "Product Status": "Active"
    },
    {
        "Root": "10032894",
        "LV": "1",
        "Item Description": "STRAWBERRY WET PROOF CRISPY 400G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6720970000",
        "Last Price Paid": "$43.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032894",
        "Product Status": "Active"
    },
    {
        "Root": "10028951",
        "LV": "1",
        "Item Description": "BEVERAGE BAR MIX KELVIN SLUSH CITRUS 4/64OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8796021",
        "Last Price Paid": "$30.85",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028951",
        "Product Status": "Active"
    },
    {
        "Root": "10040893",
        "LV": "1",
        "Item Description": "OYSTERS GULF HPP 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "4242170",
        "Last Price Paid": "$0.78",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040893",
        "Product Status": "Active"
    },
    {
        "Root": "10104595",
        "LV": "1",
        "Item Description": "PASSOVER BSC CHEESE BLINTZES W/ FRUIT APPLE  12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104595",
        "Product Status": "Active"
    },
    {
        "Root": "10037564",
        "LV": "1",
        "Item Description": "EGG OMELET WHOLE CF OVEN BAKED 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037564001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.87",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037564",
        "Product Status": "Active"
    },
    {
        "Root": "10001693",
        "LV": "1",
        "Item Description": "GRAPEFRUIT SEGMENTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001693001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "200185",
        "Last Price Paid": "$31.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001693",
        "Product Status": "Active"
    },
    {
        "Root": "10001693",
        "LV": "1",
        "Item Description": "GRAPEFRUIT SEGMENTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001693001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-008S",
        "Last Price Paid": "$31.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001693",
        "Product Status": "Active"
    },
    {
        "Root": "10088240",
        "LV": "1",
        "Item Description": "RICE CRISPIES RAINBOW 3MM 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "4780",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088240",
        "Product Status": "Active"
    },
    {
        "Root": "10013691",
        "LV": "1",
        "Item Description": "DEC ROSE LEAF FILLER GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.68",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013691",
        "Product Status": "Active"
    },
    {
        "Root": "10004633",
        "LV": "1",
        "Item Description": "SAUCE CRANBERRY WHOLE 6#5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7851822",
        "Last Price Paid": "$9.34",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004633",
        "Product Status": "Active"
    },
    {
        "Root": "10001608",
        "LV": "1",
        "Item Description": "LEEK DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "19-003-12",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001608",
        "Product Status": "Active"
    },
    {
        "Root": "10001608",
        "LV": "1",
        "Item Description": "LEEK DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "881529",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001608",
        "Product Status": "Active"
    },
    {
        "Root": "10001939",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA SHREDDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "599015",
        "Last Price Paid": "$12.36",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001939",
        "Product Status": "Active"
    },
    {
        "Root": "10001939",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA SHREDDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "18-002S",
        "Last Price Paid": "$12.36",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001939",
        "Product Status": "Active"
    },
    {
        "Root": "10100809",
        "LV": "1",
        "Item Description": "CHICKEN CKD 60/40 DICED 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100809001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4200983",
        "Last Price Paid": "$3.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100809",
        "Product Status": "Active"
    },
    {
        "Root": "10001828",
        "LV": "1",
        "Item Description": "FLOWER PANSY 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482061",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001828",
        "Product Status": "Active"
    },
    {
        "Root": "10001828",
        "LV": "1",
        "Item Description": "FLOWER PANSY 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001828",
        "Product Status": "Active"
    },
    {
        "Root": "10102135",
        "LV": "1",
        "Item Description": "BEER KEG ANCHORAGE GULF STREAM WHITEOUT WIT 1/2 BB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15561",
        "Last Price Paid": "$252.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102135",
        "Product Status": "Active"
    },
    {
        "Root": "10009704",
        "LV": "2",
        "Item Description": "TEQ MAGUEY VIDA MEZCAL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009704001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "291742",
        "Last Price Paid": "$32.41",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009704",
        "Product Status": "Active"
    },
    {
        "Root": "10004587",
        "LV": "1",
        "Item Description": "CONE CAKE FLAT BOTTOM #25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8301699",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004587",
        "Product Status": "Active"
    },
    {
        "Root": "10107348",
        "LV": "2",
        "Item Description": "CHICKEN BRST GRILLED SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107348002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7028553",
        "Last Price Paid": "$6.34",
        "Case Pack": "17",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107348",
        "Product Status": "Active"
    },
    {
        "Root": "10004260",
        "LV": "1",
        "Item Description": "SB LB CHOCOLATE CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11039085",
        "Last Price Paid": "$0.68",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004260",
        "Product Status": "Active"
    },
    {
        "Root": "10034445",
        "LV": "2",
        "Item Description": "NEBBIOLO RIZZI LANGHE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034445002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547707",
        "Last Price Paid": "$1.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034445",
        "Product Status": "Active"
    },
    {
        "Root": "10009171",
        "LV": "1",
        "Item Description": "RUSTENBURG PETER BARLOW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009171001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2183",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009171",
        "Product Status": "Active"
    },
    {
        "Root": "10002097",
        "LV": "1",
        "Item Description": "PUREE APRICOT 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002097001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "512...{BOIRON:",
        "Last Price Paid": "$10.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002097",
        "Product Status": "Active"
    },
    {
        "Root": "10003349",
        "LV": "1",
        "Item Description": "ESPRS LAT REG BN 1LB PRPL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.73",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003349",
        "Product Status": "Active"
    },
    {
        "Root": "10000574",
        "LV": "1",
        "Item Description": "PORK LOIN BONELESS CC STRP/OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000574001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "29065",
        "Last Price Paid": "$1.97",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000574",
        "Product Status": "Active"
    },
    {
        "Root": "10039011",
        "LV": "1",
        "Item Description": "SPOON WHITE SODA 8IN PP P1006 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "111566",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039011",
        "Product Status": "Active"
    },
    {
        "Root": "10006120",
        "LV": "1",
        "Item Description": "CUP 5Z PLAS WINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.15",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006120",
        "Product Status": "Active"
    },
    {
        "Root": "10032294",
        "LV": "1",
        "Item Description": "FRUIT, FC LEMONS SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "690006",
        "Last Price Paid": "$18.90",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032294",
        "Product Status": "Active"
    },
    {
        "Root": "10027543",
        "LV": "1",
        "Item Description": "DRINK MONSTER ULTRA ZERO 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "158281",
        "Last Price Paid": "$2.04",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027543",
        "Product Status": "Active"
    },
    {
        "Root": "10037081",
        "LV": "1",
        "Item Description": "CARRO BARAHONDA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9093515",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037081",
        "Product Status": "Active"
    },
    {
        "Root": "10042856",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE MICKEY EAR BLANK SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330W",
        "Last Price Paid": "$2.52",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042856",
        "Product Status": "Active"
    },
    {
        "Root": "10002582",
        "LV": "2",
        "Item Description": "BLUEBERRY LOCAL BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002582001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002582",
        "Product Status": "Active"
    },
    {
        "Root": "10106810",
        "LV": "1",
        "Item Description": "PASTRY CINN ROLL MICKEY LARGE RTB 4.6OZ/40CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.91",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106810",
        "Product Status": "Active"
    },
    {
        "Root": "10001685",
        "LV": "1",
        "Item Description": "TOMATO SUNDRIED HALVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001685001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23080",
        "Last Price Paid": "$17.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001685",
        "Product Status": "Active"
    },
    {
        "Root": "10001685",
        "LV": "1",
        "Item Description": "TOMATO SUNDRIED HALVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001685001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410419",
        "Last Price Paid": "$17.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001685",
        "Product Status": "Active"
    },
    {
        "Root": "10009540",
        "LV": "1",
        "Item Description": "VODKA GREY GOOSE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "17098",
        "Last Price Paid": "$34.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009540",
        "Product Status": "Active"
    },
    {
        "Root": "10004938",
        "LV": "1",
        "Item Description": "DOUGH CONDITIONER SUPERAC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BEL & SCHAFE",
        "Last Price Paid": "$125.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004938",
        "Product Status": "Active"
    },
    {
        "Root": "10033297",
        "LV": "1",
        "Item Description": "RUM BACARDI GRAN RSV DIEZ 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033297001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "899308",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033297",
        "Product Status": "Active"
    },
    {
        "Root": "10005586",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT MINI 1.5Z RAW BUTTER 340CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "340",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005586",
        "Product Status": "Active"
    },
    {
        "Root": "10005586",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT MINI 1.5Z RAW BUTTER 340CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "52375",
        "Last Price Paid": "$0.23",
        "Case Pack": "340",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005586",
        "Product Status": "Active"
    },
    {
        "Root": "10036622",
        "LV": "1",
        "Item Description": "NON PAREILS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12462",
        "Last Price Paid": "$24.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036622",
        "Product Status": "Active"
    },
    {
        "Root": "10006302",
        "LV": "2",
        "Item Description": "WRAP SANDWICH FOIL RED 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006302002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8358418",
        "Last Price Paid": "$0.13",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006302",
        "Product Status": "Active"
    },
    {
        "Root": "10031820",
        "LV": "1",
        "Item Description": "TURKEY DRUMSTICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031820001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.00",
        "Case Pack": "38",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031820",
        "Product Status": "Active"
    },
    {
        "Root": "10001513",
        "LV": "1",
        "Item Description": "BEET CANDY STRIPE BABY TR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001513001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01CSBT-26",
        "Last Price Paid": "$119.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001513",
        "Product Status": "Active"
    },
    {
        "Root": "10021775",
        "LV": "1",
        "Item Description": "CUP PORTION 2 OZ WHT PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11064207",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021775",
        "Product Status": "Active"
    },
    {
        "Root": "10036042",
        "LV": "1",
        "Item Description": "LUSTAU SOLERA RSV BRANDY JEREZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "360604",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036042",
        "Product Status": "Active"
    },
    {
        "Root": "10038883",
        "LV": "2",
        "Item Description": "PLATE BAGASSE SQUARE SUGARCANE 6X6 BE-FSP6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038883002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038883",
        "Product Status": "Active"
    },
    {
        "Root": "10036982",
        "LV": "1",
        "Item Description": "GARNACHA CASTILLO MONJARDIN 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036982",
        "Product Status": "Active"
    },
    {
        "Root": "10003423",
        "LV": "1",
        "Item Description": "WHEAT KUMAT BRAND PURE 25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3607338",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003423",
        "Product Status": "Active"
    },
    {
        "Root": "10005829",
        "LV": "1",
        "Item Description": "PASTRY DANISH BLUEBERRY SWIRL MINI 3IN 12CT BAKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005829",
        "Product Status": "Active"
    },
    {
        "Root": "GENE003",
        "LV": "1",
        "Item Description": "GENERIC ARTICLE BEARING THE AMOUNTS OF REBATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "GENE003001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "GENE003",
        "Product Status": "Active"
    },
    {
        "Root": "10036253",
        "LV": "1",
        "Item Description": "POTATO GERMAN BUTTERBALL D#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036253001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01GBPD-2",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036253",
        "Product Status": "Active"
    },
    {
        "Root": "10010989",
        "LV": "2",
        "Item Description": "BREAD SUB ROLL DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010989002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "4705",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10010989",
        "Product Status": "Active"
    },
    {
        "Root": "GENE005",
        "LV": "1",
        "Item Description": "GENERIC ARTICLE TOLERATING THE TNS VARIANCES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "GENE005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "GENE005",
        "Product Status": "Active"
    },
    {
        "Root": "10089165",
        "LV": "1",
        "Item Description": "SAUCE SOY PACKET 450CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089165001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "450",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089165",
        "Product Status": "Active"
    },
    {
        "Root": "10031043",
        "LV": "1",
        "Item Description": "CHARDONNAY CYT GR RSV 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "345786",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031043",
        "Product Status": "Active"
    },
    {
        "Root": "10104636",
        "LV": "3",
        "Item Description": "CRABMEAT LUMP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104636003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "9801015",
        "Last Price Paid": "$19.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10104636",
        "Product Status": "Active"
    },
    {
        "Root": "10003781",
        "LV": "1",
        "Item Description": "OLIVE SLICE MANZILLA 4/GA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1333707",
        "Last Price Paid": "$14.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003781",
        "Product Status": "Active"
    },
    {
        "Root": "10083723",
        "LV": "1",
        "Item Description": "CHIP APPLE ORIGINAL 24/0.7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083723001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4707754",
        "Last Price Paid": "$0.47",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083723",
        "Product Status": "Active"
    },
    {
        "Root": "10015405",
        "LV": "1",
        "Item Description": "DEC ROSE MED LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKT LV",
        "Last Price Paid": "$1.80",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015405",
        "Product Status": "Active"
    },
    {
        "Root": "10010499",
        "LV": "1",
        "Item Description": "VLKNBRG GEURZTRMNR QBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388344",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010499",
        "Product Status": "Active"
    },
    {
        "Root": "10002021",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI DICED 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "17-002-12",
        "Last Price Paid": "$17.10",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002021",
        "Product Status": "Active"
    },
    {
        "Root": "10002021",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI DICED 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515537",
        "Last Price Paid": "$17.10",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002021",
        "Product Status": "Active"
    },
    {
        "Root": "10090954",
        "LV": "1",
        "Item Description": "FILLING BAZO DULCE DE LECHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090954001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$54.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090954",
        "Product Status": "Active"
    },
    {
        "Root": "10004970",
        "LV": "1",
        "Item Description": "PUREE BLACKBERRY 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004970001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "701...{BOIRON{",
        "Last Price Paid": "$17.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004970",
        "Product Status": "Active"
    },
    {
        "Root": "10034712",
        "LV": "1",
        "Item Description": "SB DOUBLE SMOKED BACON CHEDDAR EGG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11096094",
        "Last Price Paid": "$2.36",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034712",
        "Product Status": "Active"
    },
    {
        "Root": "10001875",
        "LV": "1",
        "Item Description": "HERB ROSEMARY 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598035",
        "Last Price Paid": "$3.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001875",
        "Product Status": "Active"
    },
    {
        "Root": "10001875",
        "LV": "1",
        "Item Description": "HERB ROSEMARY 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001875",
        "Product Status": "Active"
    },
    {
        "Root": "10104434",
        "LV": "1",
        "Item Description": "BEER DRAFT OMMEGANG 3 PHILOSOPHERS 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13520",
        "Last Price Paid": "$311.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104434",
        "Product Status": "Active"
    },
    {
        "Root": "10002486",
        "LV": "1",
        "Item Description": "ASPARATION/BROCCOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002486001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002486",
        "Product Status": "Active"
    },
    {
        "Root": "10000520",
        "LV": "1",
        "Item Description": "MUFFIN PETITE BLUEBERRY (1 OZ) 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000520",
        "Product Status": "Active"
    },
    {
        "Root": "10000946",
        "LV": "1",
        "Item Description": "CAVIAR ISRAEL GALILEE 125 GRM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "61710040",
        "Last Price Paid": "$154.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000946",
        "Product Status": "Active"
    },
    {
        "Root": "10015034",
        "LV": "1",
        "Item Description": "OIL OLIVE EV CALIFORNIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015034001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01102",
        "Last Price Paid": "$16.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015034",
        "Product Status": "Active"
    },
    {
        "Root": "10100271",
        "LV": "1",
        "Item Description": "TEQUILA CODIGO 1530 EX ANEJO 14YR. 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "569635",
        "Last Price Paid": "$2,765.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100271",
        "Product Status": "Active"
    },
    {
        "Root": "10010411",
        "LV": "1",
        "Item Description": "BEER UNIBREW BLANC DE CHAMPBLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "866103",
        "Last Price Paid": "$1.08",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010411",
        "Product Status": "Active"
    },
    {
        "Root": "10010074",
        "LV": "1",
        "Item Description": "BEER BTL GLS MICHELOB ULTRA 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010074001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.12",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010074",
        "Product Status": "Active"
    },
    {
        "Root": "10085876",
        "LV": "1",
        "Item Description": "LID PORTION CUP 2 OZ CLEAR PLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "2010",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085876",
        "Product Status": "Active"
    },
    {
        "Root": "10001520",
        "LV": "1",
        "Item Description": "HERB MINT SAMPLER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08MS-33",
        "Last Price Paid": "$0.55",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001520",
        "Product Status": "Active"
    },
    {
        "Root": "10003049",
        "LV": "1",
        "Item Description": "ICING CHOCOLATE SPREAD &",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$94.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003049",
        "Product Status": "Active"
    },
    {
        "Root": "10021562",
        "LV": "1",
        "Item Description": "SAUCE CARAMEL PC 200 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593532",
        "Last Price Paid": "$0.19",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021562",
        "Product Status": "Active"
    },
    {
        "Root": "10044329",
        "LV": "1",
        "Item Description": "CANDY M&M RED 2/5# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "10465",
        "Last Price Paid": "$42.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044329",
        "Product Status": "Active"
    },
    {
        "Root": "10001851",
        "LV": "1",
        "Item Description": "DATE MEDJOOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001851001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001851",
        "Product Status": "Active"
    },
    {
        "Root": "10007830",
        "LV": "1",
        "Item Description": "SPRK GRAHAM BECK BLISS DEMI SEC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "484615",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007830",
        "Product Status": "Active"
    },
    {
        "Root": "10039743",
        "LV": "1",
        "Item Description": "COOKIE SUGAR 3IN RND WH FONDANT CUST PRNT IW 50/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "3:CUSTIW",
        "Last Price Paid": "$2.88",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039743",
        "Product Status": "Active"
    },
    {
        "Root": "10005759",
        "LV": "1",
        "Item Description": "BAGEL WHEAT 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005759001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005759",
        "Product Status": "Active"
    },
    {
        "Root": "10107331",
        "LV": "2",
        "Item Description": "BEER BREW HUB FRENCH CONNECTION 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107331002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "62129",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107331",
        "Product Status": "Active"
    },
    {
        "Root": "10004103",
        "LV": "3",
        "Item Description": "SHELL CHOCOLATE 1.7 INCH ROUN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004103001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD201",
        "Last Price Paid": "$0.28",
        "Case Pack": "378",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10004103",
        "Product Status": "Active"
    },
    {
        "Root": "10002347",
        "LV": "1",
        "Item Description": "GELATO PISTACHIO-STRACCIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-PSTR-08-HP",
        "Last Price Paid": "$10.87",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002347",
        "Product Status": "Active"
    },
    {
        "Root": "10004962",
        "LV": "1",
        "Item Description": "SUGAR COARSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "003...{CLAROD{",
        "Last Price Paid": "$64.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004962",
        "Product Status": "Active"
    },
    {
        "Root": "10034365",
        "LV": "1",
        "Item Description": "LEAVES GREEN/DARK METALLIC TINY 3/4 X 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW265172",
        "Last Price Paid": "$0.40",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034365",
        "Product Status": "Active"
    },
    {
        "Root": "10003901",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE STITCH W/BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 63630",
        "Last Price Paid": "$6.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003901",
        "Product Status": "Active"
    },
    {
        "Root": "10040383",
        "LV": "1",
        "Item Description": "COLESLAW MIX SHRED CABBAGE W/ CARROT 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3332830",
        "Last Price Paid": "$5.17",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040383",
        "Product Status": "Active"
    },
    {
        "Root": "10104588",
        "LV": "1",
        "Item Description": "PASSOVER BSC VEGETABLE SOUP 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104588",
        "Product Status": "Active"
    },
    {
        "Root": "10018153",
        "LV": "1",
        "Item Description": "POWDER DECOR WHT SATIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11014",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018153",
        "Product Status": "Active"
    },
    {
        "Root": "10088238",
        "LV": "1",
        "Item Description": "STEAMED BUN FILLED W/VEGETABLES CURRY SAUCE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088238001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.43",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088238",
        "Product Status": "Active"
    },
    {
        "Root": "10005793",
        "LV": "2",
        "Item Description": "PASTRY DANISH CINNAMON SWIRL 180CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005793001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061205",
        "Last Price Paid": "$0.50",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005793",
        "Product Status": "Active"
    },
    {
        "Root": "10035526",
        "LV": "1",
        "Item Description": "OLIVE OIL MERULA EXTRA VIRGIN 5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OIL906",
        "Last Price Paid": "$95.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035526",
        "Product Status": "Active"
    },
    {
        "Root": "10031849",
        "LV": "1",
        "Item Description": "PAINTED WOLF DEN CHENIN BLANC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "PAIWOLDENCHBL",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031849",
        "Product Status": "Active"
    },
    {
        "Root": "10045621",
        "LV": "1",
        "Item Description": "HAM PIT APPLEWOOD SMOKED BONELESS DUROC 2/10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045621001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3303132",
        "Last Price Paid": "$4.35",
        "Case Pack": "21",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045621",
        "Product Status": "Active"
    },
    {
        "Root": "10017808",
        "LV": "1",
        "Item Description": "GALANGAL ROOT(GALANGA) LB FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017808001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401112",
        "Last Price Paid": "$8.55",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017808",
        "Product Status": "Active"
    },
    {
        "Root": "10029277",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED GREEN HILL SWEET GRASS 8/7.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "18999",
        "Last Price Paid": "$9.01",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029277",
        "Product Status": "Active"
    },
    {
        "Root": "10095450",
        "LV": "1",
        "Item Description": "PORK ST LOUIS STYLE SPARERIB SKIRT OFF 2.5 P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095450001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.60",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095450",
        "Product Status": "Active"
    },
    {
        "Root": "10006646",
        "LV": "2",
        "Item Description": "ICE CRUSHED 7LB BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006646002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000006360",
        "Vendor": "REDDY ICE CORPORATION",
        "Vendor Item Nb": "860006114916",
        "Last Price Paid": "$1.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006646",
        "Product Status": "Active"
    },
    {
        "Root": "10035830",
        "LV": "1",
        "Item Description": "TONIC WATER FEVER TREE LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "546805",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035830",
        "Product Status": "Active"
    },
    {
        "Root": "10001804",
        "LV": "1",
        "Item Description": "TOFU SILKEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401021",
        "Last Price Paid": "$21.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001804",
        "Product Status": "Active"
    },
    {
        "Root": "10090588",
        "LV": "1",
        "Item Description": "FLOUR CHICKPEA BOBS RED MILL 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090588001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101160",
        "Last Price Paid": "$1.73",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090588",
        "Product Status": "Active"
    },
    {
        "Root": "10006454",
        "LV": "1",
        "Item Description": "BOARD CAKE SILVER 18 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.85",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006454",
        "Product Status": "Active"
    },
    {
        "Root": "10003071",
        "LV": "1",
        "Item Description": "PEPPER RED CRUSHED IND 1G 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003071",
        "Product Status": "Active"
    },
    {
        "Root": "10004909",
        "LV": "1",
        "Item Description": "DRESSING GREEK W/OLIVE 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.32",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004909",
        "Product Status": "Active"
    },
    {
        "Root": "10034753",
        "LV": "1",
        "Item Description": "TRANSFER SHEET CHRISTMAS PARTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "902331C",
        "Last Price Paid": "$4.59",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034753",
        "Product Status": "Active"
    },
    {
        "Root": "10012618",
        "LV": "1",
        "Item Description": "FLOWERS MINI FLORETS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012618001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MFLO-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012618",
        "Product Status": "Active"
    },
    {
        "Root": "10014029",
        "LV": "1",
        "Item Description": "PAD CHOC SOFT CELL 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51149001",
        "Last Price Paid": "$0.08",
        "Case Pack": "2200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014029",
        "Product Status": "Active"
    },
    {
        "Root": "10027604",
        "LV": "1",
        "Item Description": "CANDY EASTER EGG GRAN CRU ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "11638",
        "Last Price Paid": "$0.26",
        "Case Pack": "667",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027604",
        "Product Status": "Active"
    },
    {
        "Root": "10000179",
        "LV": "1",
        "Item Description": "GRAPE 2OZ BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000179001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480937",
        "Last Price Paid": "$0.44",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000179",
        "Product Status": "Active"
    },
    {
        "Root": "10012941",
        "LV": "1",
        "Item Description": "LAMB GROUND AUS HALAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012941001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1590401",
        "Last Price Paid": "$5.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012941",
        "Product Status": "Active"
    },
    {
        "Root": "10013028",
        "LV": "1",
        "Item Description": "FILLING CUSTARD GOURMET PASTRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$60.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013028",
        "Product Status": "Active"
    },
    {
        "Root": "10004289",
        "LV": "2",
        "Item Description": "SB SANDWICH BREAKFAST BACON GOUDA EGG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004289002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11096091",
        "Last Price Paid": "$1.70",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004289",
        "Product Status": "Active"
    },
    {
        "Root": "10015973",
        "LV": "2",
        "Item Description": "CLAM FL CEDAR KEY LITTLENECKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015973002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015973",
        "Product Status": "Active"
    },
    {
        "Root": "10023807",
        "LV": "1",
        "Item Description": "CARROT ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "704814",
        "Last Price Paid": "$1.13",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023807",
        "Product Status": "Active"
    },
    {
        "Root": "10028465",
        "LV": "1",
        "Item Description": "BEER DRAGON STOUT 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028465001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12192",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028465",
        "Product Status": "Active"
    },
    {
        "Root": "10035252",
        "LV": "2",
        "Item Description": "PORK ST. LOUIS RIBS SPLIT SKIRT OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035252002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.82",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035252",
        "Product Status": "Active"
    },
    {
        "Root": "10009482",
        "LV": "1",
        "Item Description": "WHISKEY IRISH BUSHMILL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "6353",
        "Last Price Paid": "$23.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009482",
        "Product Status": "Active"
    },
    {
        "Root": "10015035",
        "LV": "1",
        "Item Description": "ZINF VILLAINOUS OUAV 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015035",
        "Product Status": "Active"
    },
    {
        "Root": "10015035",
        "LV": "1",
        "Item Description": "ZINF VILLAINOUS OUAV 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388954",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015035",
        "Product Status": "Active"
    },
    {
        "Root": "10019925",
        "LV": "1",
        "Item Description": "TOY SOLDIER 3 IN A ROW XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019925001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VO-2200",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019925",
        "Product Status": "Active"
    },
    {
        "Root": "10022341",
        "LV": "1",
        "Item Description": "TURKEY BRST NATURAL 10-12 LB JOYCE FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022341001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4129113",
        "Last Price Paid": "",
        "Case Pack": "22",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022341",
        "Product Status": "Active"
    },
    {
        "Root": "10012027",
        "LV": "1",
        "Item Description": "MUSTARD KARATE PETITE 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09PKM-33-S",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012027",
        "Product Status": "Active"
    },
    {
        "Root": "10025422",
        "LV": "1",
        "Item Description": "GEL PIPING VANILLA FLAVOR 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025422001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025422",
        "Product Status": "Active"
    },
    {
        "Root": "10100709",
        "LV": "1",
        "Item Description": "CHOCOLATE RECT DECOR YOUNG &BEAUTIFUL 2-1/2 X 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW55740",
        "Last Price Paid": "",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100709",
        "Product Status": "Active"
    },
    {
        "Root": "10032441",
        "LV": "1",
        "Item Description": "COLOR CANDY LIQUID GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "304013B",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032441",
        "Product Status": "Active"
    },
    {
        "Root": "10003148",
        "LV": "1",
        "Item Description": "PASTA RUSTICHELLA TROFIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003148",
        "Product Status": "Active"
    },
    {
        "Root": "10000746",
        "LV": "1",
        "Item Description": "CHARGE LATE ORDERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "9700028-A",
        "Last Price Paid": "$75.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000746",
        "Product Status": "Active"
    },
    {
        "Root": "10004747",
        "LV": "1",
        "Item Description": "RICE BROWN 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004747",
        "Product Status": "Active"
    },
    {
        "Root": "10105440",
        "LV": "1",
        "Item Description": "GIFFARD RHUBARB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105440001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9331333",
        "Last Price Paid": "$21.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105440",
        "Product Status": "Active"
    },
    {
        "Root": "10001669",
        "LV": "1",
        "Item Description": "PEA SNOW 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001669",
        "Product Status": "Active"
    },
    {
        "Root": "10001669",
        "LV": "1",
        "Item Description": "PEA SNOW 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401262",
        "Last Price Paid": "$34.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001669",
        "Product Status": "Active"
    },
    {
        "Root": "10002199",
        "LV": "2",
        "Item Description": "YOGURT PLAIN LOW FAT 6/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002199001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002199",
        "Product Status": "Active"
    },
    {
        "Root": "10014533",
        "LV": "1",
        "Item Description": "PEPPER YELLOW JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-007",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014533",
        "Product Status": "Active"
    },
    {
        "Root": "10014533",
        "LV": "1",
        "Item Description": "PEPPER YELLOW JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514538",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014533",
        "Product Status": "Active"
    },
    {
        "Root": "10036994",
        "LV": "1",
        "Item Description": "COTE DU RHONE CHAT DELAS PEGASUS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "538269",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036994",
        "Product Status": "Active"
    },
    {
        "Root": "10002249",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRILLIANT SAVARIN 4/17.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "45400",
        "Last Price Paid": "$18.53",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002249",
        "Product Status": "Active"
    },
    {
        "Root": "10025976",
        "LV": "1",
        "Item Description": "SB COFFEE CASI CIELO 5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7341",
        "Last Price Paid": "$57.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025976",
        "Product Status": "Active"
    },
    {
        "Root": "10010123",
        "LV": "2",
        "Item Description": "WATER HILDON SPARK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010123001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010123",
        "Product Status": "Active"
    },
    {
        "Root": "10031506",
        "LV": "1",
        "Item Description": "FISH FILLET SKIN KANPACHI HAWAIIAN FOREVER OCEANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031506001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00490",
        "Last Price Paid": "$17.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031506",
        "Product Status": "Active"
    },
    {
        "Root": "10002054",
        "LV": "1",
        "Item Description": "YUCCA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002054001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002054",
        "Product Status": "Active"
    },
    {
        "Root": "10023477",
        "LV": "1",
        "Item Description": "SAUSAGE ITALIAN SWEET 4X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023477001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.67",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023477",
        "Product Status": "Active"
    },
    {
        "Root": "10004329",
        "LV": "2",
        "Item Description": "HORS CHICKEN DURBAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004329001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7302223",
        "Last Price Paid": "$1.23",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004329",
        "Product Status": "Active"
    },
    {
        "Root": "10001266",
        "LV": "1",
        "Item Description": "PICKLE SPEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001266",
        "Product Status": "Active"
    },
    {
        "Root": "10013584",
        "LV": "1",
        "Item Description": "POINSETTA LG RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NTBRD",
        "Last Price Paid": "$10.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013584",
        "Product Status": "Active"
    },
    {
        "Root": "10035128",
        "LV": "1",
        "Item Description": "SYRUP HONEY MIX BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BG104",
        "Last Price Paid": "$16.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035128",
        "Product Status": "Active"
    },
    {
        "Root": "10001510",
        "LV": "1",
        "Item Description": "HERBAL SENSATION SMALL BI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14SBHS-33-R",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001510",
        "Product Status": "Active"
    },
    {
        "Root": "10015028",
        "LV": "1",
        "Item Description": "ORANGE PEEL STRIP AMIFRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AM625",
        "Last Price Paid": "$22.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015028",
        "Product Status": "Active"
    },
    {
        "Root": "10032226",
        "LV": "1",
        "Item Description": "MINI VANILLA CORNET COATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032226001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "589023",
        "Last Price Paid": "$0.33",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032226",
        "Product Status": "Active"
    },
    {
        "Root": "10084766",
        "LV": "1",
        "Item Description": "TRAY KRAFT SAUSAGE 12 X 3 X 1.5 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51120315",
        "Last Price Paid": "$0.15",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084766",
        "Product Status": "Active"
    },
    {
        "Root": "10018546",
        "LV": "1",
        "Item Description": "LIQ ANCHO REYES CHILE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018546001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018546",
        "Product Status": "Active"
    },
    {
        "Root": "10007009",
        "LV": "1",
        "Item Description": "SPARK BANFI ROSA REGA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "346475",
        "Last Price Paid": "$13.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007009",
        "Product Status": "Active"
    },
    {
        "Root": "10007009",
        "LV": "1",
        "Item Description": "SPARK BANFI ROSA REGA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007009",
        "Product Status": "Active"
    },
    {
        "Root": "10001524",
        "LV": "1",
        "Item Description": "FLOWER BACHELOR BUTTON 50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05BB-33",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001524",
        "Product Status": "Active"
    },
    {
        "Root": "10001524",
        "LV": "1",
        "Item Description": "FLOWER BACHELOR BUTTON 50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480498",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001524",
        "Product Status": "Active"
    },
    {
        "Root": "10009992",
        "LV": "1",
        "Item Description": "LIQ AMARETTO BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009992",
        "Product Status": "Active"
    },
    {
        "Root": "10035626",
        "LV": "1",
        "Item Description": "SUCRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87412",
        "Last Price Paid": "$67.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035626",
        "Product Status": "Active"
    },
    {
        "Root": "10003916",
        "LV": "1",
        "Item Description": "VERJUS WHITE FUSION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003916",
        "Product Status": "Active"
    },
    {
        "Root": "10001404",
        "LV": "1",
        "Item Description": "DRAGON FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16242",
        "Last Price Paid": "$27.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001404",
        "Product Status": "Active"
    },
    {
        "Root": "10001404",
        "LV": "1",
        "Item Description": "DRAGON FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410436",
        "Last Price Paid": "$27.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001404",
        "Product Status": "Active"
    },
    {
        "Root": "10001404",
        "LV": "1",
        "Item Description": "DRAGON FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001404",
        "Product Status": "Active"
    },
    {
        "Root": "10103289",
        "LV": "1",
        "Item Description": "BEER WICKED WEED MILK & COOKIES STOUT 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76304",
        "Last Price Paid": "$217.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103289",
        "Product Status": "Active"
    },
    {
        "Root": "10003416",
        "LV": "1",
        "Item Description": "TEA ROOIBOS HERB TWINNING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003416",
        "Product Status": "Active"
    },
    {
        "Root": "10015126",
        "LV": "1",
        "Item Description": "FLOWER DIANTHUS FRILLED 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05FDIA-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015126",
        "Product Status": "Active"
    },
    {
        "Root": "10001992",
        "LV": "1",
        "Item Description": "BEET RED LG PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514017",
        "Last Price Paid": "$10.98",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001992",
        "Product Status": "Active"
    },
    {
        "Root": "10001992",
        "LV": "1",
        "Item Description": "BEET RED LG PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "36-003",
        "Last Price Paid": "$10.98",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001992",
        "Product Status": "Active"
    },
    {
        "Root": "10000992",
        "LV": "1",
        "Item Description": "FISH BONES SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000992001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-60001",
        "Last Price Paid": "$1.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000992",
        "Product Status": "Active"
    },
    {
        "Root": "10000992",
        "LV": "1",
        "Item Description": "FISH BONES SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000992001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3875838",
        "Last Price Paid": "$1.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000992",
        "Product Status": "Active"
    },
    {
        "Root": "10000992",
        "LV": "1",
        "Item Description": "FISH BONES SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000992001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000992",
        "Product Status": "Active"
    },
    {
        "Root": "10012566",
        "LV": "1",
        "Item Description": "TWIN 24CT ENG BREAKFAST K-CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012566",
        "Product Status": "Active"
    },
    {
        "Root": "10006871",
        "LV": "1",
        "Item Description": "PINOT GRIS KING ESTATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006871",
        "Product Status": "Active"
    },
    {
        "Root": "10000981",
        "LV": "1",
        "Item Description": "FLOUNDER FLT SKN OFF FRSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000981001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00108",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000981",
        "Product Status": "Active"
    },
    {
        "Root": "10000981",
        "LV": "1",
        "Item Description": "FLOUNDER FLT SKN OFF FRSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000981001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000981",
        "Product Status": "Active"
    },
    {
        "Root": "10038568",
        "LV": "2",
        "Item Description": "GELATO STRAWBERRY SOFT SERVE MIX 1.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038568002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-STWB-11-MX-C",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038568",
        "Product Status": "Active"
    },
    {
        "Root": "10005987",
        "LV": "1",
        "Item Description": "CUP 15Z PLAS SUNDAE CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005987001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005987",
        "Product Status": "Active"
    },
    {
        "Root": "10104436",
        "LV": "1",
        "Item Description": "BEER DRAFT BOULEVARD QUIRK BLUBRY LMN LAVENDER 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15771",
        "Last Price Paid": "$183.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104436",
        "Product Status": "Active"
    },
    {
        "Root": "10031019",
        "LV": "1",
        "Item Description": "MASSEL FLAVOR CENTER CHICKEN FLAVOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031019001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "00144",
        "Last Price Paid": "$22.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031019",
        "Product Status": "Active"
    },
    {
        "Root": "10001678",
        "LV": "1",
        "Item Description": "FENNEL 24CT 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001678001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001678",
        "Product Status": "Active"
    },
    {
        "Root": "10001678",
        "LV": "1",
        "Item Description": "FENNEL 24CT 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001678001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001678",
        "Product Status": "Active"
    },
    {
        "Root": "10104439",
        "LV": "1",
        "Item Description": "BEER WICKED WEED BLANK CANVAS 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104439001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76325",
        "Last Price Paid": "$203.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104439",
        "Product Status": "Active"
    },
    {
        "Root": "10102749",
        "LV": "1",
        "Item Description": "BEER BOULEVARD UNFILTERED WHEAT 1/2 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13553",
        "Last Price Paid": "$173.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102749",
        "Product Status": "Active"
    },
    {
        "Root": "10008287",
        "LV": "1",
        "Item Description": "SAUV BLC ALLAN SCOTT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "841877",
        "Last Price Paid": "$12.07",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008287",
        "Product Status": "Active"
    },
    {
        "Root": "10009539",
        "LV": "1",
        "Item Description": "TEQ LUNAZUL RESPOSADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009539",
        "Product Status": "Active"
    },
    {
        "Root": "10025619",
        "LV": "1",
        "Item Description": "FLOWER MICRO STAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480270",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025619",
        "Product Status": "Active"
    },
    {
        "Root": "10102701",
        "LV": "1",
        "Item Description": "BAR WHITE CHOCOLATE MACADAMIA CLIF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302460",
        "Last Price Paid": "$1.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102701",
        "Product Status": "Active"
    },
    {
        "Root": "10001998",
        "LV": "1",
        "Item Description": "MINT SAMPLER DEMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08MSB-33",
        "Last Price Paid": "$47.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001998",
        "Product Status": "Active"
    },
    {
        "Root": "10007264",
        "LV": "1",
        "Item Description": "LAMBRUSCO CHIARLI CENTENARIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597913",
        "Last Price Paid": "$11.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007264",
        "Product Status": "Active"
    },
    {
        "Root": "10029814",
        "LV": "2",
        "Item Description": "GIN PLYMOUTH NAVY STRENGTH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029814002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "319199",
        "Last Price Paid": "$31.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029814",
        "Product Status": "Active"
    },
    {
        "Root": "10014240",
        "LV": "1",
        "Item Description": "LETTUCE ROSETTES PETITE 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09MPLRO-24",
        "Last Price Paid": "$24.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014240",
        "Product Status": "Active"
    },
    {
        "Root": "10026960",
        "LV": "1",
        "Item Description": "LIQ TUACA 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38331",
        "Last Price Paid": "$20.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026960",
        "Product Status": "Active"
    },
    {
        "Root": "10005898",
        "LV": "1",
        "Item Description": "BREAD MARBLE RYE OVAL 3.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005898",
        "Product Status": "Active"
    },
    {
        "Root": "10093074",
        "LV": "2",
        "Item Description": "CHICKEN BREAST BONELESS BREADED KIEV RTC 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093074002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.70",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10093074",
        "Product Status": "Active"
    },
    {
        "Root": "10032428",
        "LV": "1",
        "Item Description": "WHITE BLEND TOKARA DIRECTORS RESERVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "460645",
        "Last Price Paid": "$29.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032428",
        "Product Status": "Active"
    },
    {
        "Root": "10094821",
        "LV": "2",
        "Item Description": "CHIP CUP OVAL WHITE CHOC 4.2X3CM,1980PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094821002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6235180000",
        "Last Price Paid": "$679.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094821",
        "Product Status": "Active"
    },
    {
        "Root": "10005024",
        "LV": "1",
        "Item Description": "SYRUP.GRNNY SMTH LTR MONI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005024001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005024",
        "Product Status": "Active"
    },
    {
        "Root": "10108734",
        "LV": "1",
        "Item Description": "BEER CAN LABATT BLUE LIGHT 12OZ 12PK 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "49312",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108734",
        "Product Status": "Active"
    },
    {
        "Root": "10002138",
        "LV": "1",
        "Item Description": "CHEESE VELVEETA SHREDDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002138",
        "Product Status": "Active"
    },
    {
        "Root": "10032063",
        "LV": "2",
        "Item Description": "DRINK MOUNTAIN DEW CANS 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032063002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6993257",
        "Last Price Paid": "$0.64",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032063",
        "Product Status": "Active"
    },
    {
        "Root": "10009515",
        "LV": "1",
        "Item Description": "WHISKEY CROWN ROYAL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009515001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009515",
        "Product Status": "Active"
    },
    {
        "Root": "10001722",
        "LV": "1",
        "Item Description": "PEPPER RED DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-001-34",
        "Last Price Paid": "$18.28",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001722",
        "Product Status": "Active"
    },
    {
        "Root": "10001722",
        "LV": "1",
        "Item Description": "PEPPER RED DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514513",
        "Last Price Paid": "$18.28",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001722",
        "Product Status": "Active"
    },
    {
        "Root": "10000756",
        "LV": "1",
        "Item Description": "VENISON LEG CERVENA FRESH 7 DAY LEAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000756001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000756",
        "Product Status": "Active"
    },
    {
        "Root": "10000756",
        "LV": "1",
        "Item Description": "VENISON LEG CERVENA FRESH 7 DAY LEAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000756001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4530004",
        "Last Price Paid": "$9.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000756",
        "Product Status": "Active"
    },
    {
        "Root": "10002194",
        "LV": "1",
        "Item Description": "CREAM HEAVY WHIP 36.5% HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.65",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002194",
        "Product Status": "Active"
    },
    {
        "Root": "10096004",
        "LV": "1",
        "Item Description": "SORBET LIME HALF GAL CASE OF 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10096004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-LIME-08-CS",
        "Last Price Paid": "$9.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10096004",
        "Product Status": "Active"
    },
    {
        "Root": "10007943",
        "LV": "1",
        "Item Description": "CAB SAUV JOHN X MERRIMN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2181",
        "Last Price Paid": "$21.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007943",
        "Product Status": "Active"
    },
    {
        "Root": "10006312",
        "LV": "1",
        "Item Description": "LINER PAN HALF SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2702066",
        "Last Price Paid": "$0.03",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006312",
        "Product Status": "Active"
    },
    {
        "Root": "10019393",
        "LV": "3",
        "Item Description": "SPARKLING ROSE ROEDERER EST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019393002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "947429",
        "Last Price Paid": "$23.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10019393",
        "Product Status": "Active"
    },
    {
        "Root": "10000942",
        "LV": "1",
        "Item Description": "FISH FOOD GLASS MINNOWS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000942001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50021",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000942",
        "Product Status": "Active"
    },
    {
        "Root": "10005669",
        "LV": "1",
        "Item Description": "CAKE FUNNEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005669",
        "Product Status": "Active"
    },
    {
        "Root": "10035193",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON NEIL ELLIS JONKERSHOEK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "470210",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035193",
        "Product Status": "Active"
    },
    {
        "Root": "10034391",
        "LV": "1",
        "Item Description": "BAR BLUEBERRY VANILLA CASHEW KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034391",
        "Product Status": "Active"
    },
    {
        "Root": "10012484",
        "LV": "1",
        "Item Description": "BUTTERFLIES ASST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012484001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012484",
        "Product Status": "Active"
    },
    {
        "Root": "10035990",
        "LV": "1",
        "Item Description": "STAMP MILK CHOCOLATE BLACK SPIRE 3CM 440 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6232920000",
        "Last Price Paid": "",
        "Case Pack": "440",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035990",
        "Product Status": "Active"
    },
    {
        "Root": "10034549",
        "LV": "2",
        "Item Description": "SAUVIGNON BLANC BOURGEOIS FUME 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034549002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "837826",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034549",
        "Product Status": "Active"
    },
    {
        "Root": "10031464",
        "LV": "2",
        "Item Description": "LIQ HOODOO CATHEAD CHICKORY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031464002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "526139",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031464",
        "Product Status": "Active"
    },
    {
        "Root": "10104935",
        "LV": "1",
        "Item Description": "MUG BASE NIGHT FIREWORKS  PINK LID 16OZ 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104935",
        "Product Status": "Active"
    },
    {
        "Root": "10094130",
        "LV": "1",
        "Item Description": "CANDY FRUIT SLICE - CHERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "708",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094130",
        "Product Status": "Active"
    },
    {
        "Root": "10003302",
        "LV": "1",
        "Item Description": "ESPRESSO RISTRETTO NESPRESSO 200 CT - ORIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.41",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003302",
        "Product Status": "Active"
    },
    {
        "Root": "10035686",
        "LV": "1",
        "Item Description": "SAUCE GHIRARDELLI CHOCOLATE GOLD 6/87.3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035686001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582760",
        "Last Price Paid": "$24.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035686",
        "Product Status": "Active"
    },
    {
        "Root": "10044250",
        "LV": "1",
        "Item Description": "COOKIE MINNIE PEPPERMINT FOTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "PP",
        "Last Price Paid": "$2.00",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044250",
        "Product Status": "Active"
    },
    {
        "Root": "10013139",
        "LV": "1",
        "Item Description": "TEA TWNG LONDON STRAND EARL GRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013139",
        "Product Status": "Active"
    },
    {
        "Root": "10086597",
        "LV": "2",
        "Item Description": "NUT MIX CASHEW GLAZE 10 EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086597002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "MXCAS",
        "Last Price Paid": "$11.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10086597",
        "Product Status": "Active"
    },
    {
        "Root": "10006380",
        "LV": "1",
        "Item Description": "BAG PAPER 6# WHITE 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "450002",
        "Last Price Paid": "$0.06",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006380",
        "Product Status": "Active"
    },
    {
        "Root": "10006380",
        "LV": "1",
        "Item Description": "BAG PAPER 6# WHITE 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "450002",
        "Last Price Paid": "$0.06",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006380",
        "Product Status": "Active"
    },
    {
        "Root": "10092222",
        "LV": "1",
        "Item Description": "CONE, ICE CREAM SUGAR 400 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8978208",
        "Last Price Paid": "$0.13",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092222",
        "Product Status": "Active"
    },
    {
        "Root": "10036255",
        "LV": "1",
        "Item Description": "TOMATO FRIED GREEN CORNMEAL BREADED 3/3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5593858",
        "Last Price Paid": "$14.23",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036255",
        "Product Status": "Active"
    },
    {
        "Root": "10001957",
        "LV": "1",
        "Item Description": "CILANTRO MICRO PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04CL-33-L",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001957",
        "Product Status": "Active"
    },
    {
        "Root": "10035891",
        "LV": "3",
        "Item Description": "DECOR CHOC FEATHER SYNERGY DEFINE COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035891002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW23106",
        "Last Price Paid": "$0.65",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10035891",
        "Product Status": "Active"
    },
    {
        "Root": "10003538",
        "LV": "1",
        "Item Description": "COFFEE POD DOUBLE DCF 100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003538",
        "Product Status": "Active"
    },
    {
        "Root": "10100770",
        "LV": "1",
        "Item Description": "PEPPER MIXED SWEET 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100770001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "10MXS-2",
        "Last Price Paid": "$5.00",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100770",
        "Product Status": "Active"
    },
    {
        "Root": "10045278",
        "LV": "1",
        "Item Description": "SYRUP FCB FANTA WILD CHERRY 2.5 GAL SHELF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "64584",
        "Last Price Paid": "$57.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045278",
        "Product Status": "Active"
    },
    {
        "Root": "10000790",
        "LV": "1",
        "Item Description": "PORK CAPACOLLA BNLS 5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000790001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3313005",
        "Last Price Paid": "$4.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000790",
        "Product Status": "Active"
    },
    {
        "Root": "10007782",
        "LV": "2",
        "Item Description": "PINOT N SERENE YAMHILL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007782001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9681759",
        "Last Price Paid": "$48.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007782",
        "Product Status": "Active"
    },
    {
        "Root": "10012226",
        "LV": "1",
        "Item Description": "SCOTCH MONKEY SHOULDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012226001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9157494",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012226",
        "Product Status": "Active"
    },
    {
        "Root": "10030368",
        "LV": "1",
        "Item Description": "MINNIE MOUSE BOW RED SMALL 1 1/2X 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW322925",
        "Last Price Paid": "$0.40",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030368",
        "Product Status": "Active"
    },
    {
        "Root": "10009531",
        "LV": "2",
        "Item Description": "WHSKY SMOKY APPLE PIE MOONSHI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009531001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "398418",
        "Last Price Paid": "$18.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009531",
        "Product Status": "Active"
    },
    {
        "Root": "10032825",
        "LV": "1",
        "Item Description": "WHITE CITRUS FLOSSUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3715",
        "Last Price Paid": "$5.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032825",
        "Product Status": "Active"
    },
    {
        "Root": "10001413",
        "LV": "1",
        "Item Description": "GREEN MUSTARD RFLD RD PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09PRRFM-33",
        "Last Price Paid": "$0.58",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001413",
        "Product Status": "Active"
    },
    {
        "Root": "10018312",
        "LV": "1",
        "Item Description": "TORTILLA WHEAT 12\" WRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3712718",
        "Last Price Paid": "$0.31",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018312",
        "Product Status": "Active"
    },
    {
        "Root": "10007887",
        "LV": "1",
        "Item Description": "CAB SAUV CHAPPE MTN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007887001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "241163",
        "Last Price Paid": "$28.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007887",
        "Product Status": "Active"
    },
    {
        "Root": "10019385",
        "LV": "1",
        "Item Description": "TEA LOOSE MANGO & GINGER GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13708",
        "Last Price Paid": "$31.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019385",
        "Product Status": "Active"
    },
    {
        "Root": "10025664",
        "LV": "1",
        "Item Description": "COOLER SEAGRAMS JAMAICAN ME HAPPY 12 OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "63430",
        "Last Price Paid": "$0.94",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025664",
        "Product Status": "Active"
    },
    {
        "Root": "10000387",
        "LV": "1",
        "Item Description": "LETTUCE GRN LEAF FILET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000387",
        "Product Status": "Active"
    },
    {
        "Root": "10000387",
        "LV": "1",
        "Item Description": "LETTUCE GRN LEAF FILET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510061",
        "Last Price Paid": "$28.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000387",
        "Product Status": "Active"
    },
    {
        "Root": "10036054",
        "LV": "1",
        "Item Description": "DOS MADERAS PX 5+5 RUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294559",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036054",
        "Product Status": "Active"
    },
    {
        "Root": "10025481",
        "LV": "1",
        "Item Description": "DRAFT AASS PILSNER 1/4 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "3727",
        "Last Price Paid": "$101.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025481",
        "Product Status": "Active"
    },
    {
        "Root": "10014051",
        "LV": "2",
        "Item Description": "COCOA BUTTER AURELOLIN YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014051002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5863100000",
        "Last Price Paid": "$19.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014051",
        "Product Status": "Active"
    },
    {
        "Root": "10014051",
        "LV": "2",
        "Item Description": "COCOA BUTTER AURELOLIN YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014051002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302005C",
        "Last Price Paid": "$19.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014051",
        "Product Status": "Active"
    },
    {
        "Root": "10027805",
        "LV": "1",
        "Item Description": "BUN STEAMED FILLED W/ BEEF & CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "10141-DW",
        "Last Price Paid": "$1.56",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027805",
        "Product Status": "Active"
    },
    {
        "Root": "10038157",
        "LV": "1",
        "Item Description": "SAUCE KENYA COFFEE BBQ 5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038157001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$145.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038157",
        "Product Status": "Active"
    },
    {
        "Root": "10036356",
        "LV": "1",
        "Item Description": "PTC10030S BROWN 3.9X1.2 BAKING CUP QUICHE/TART B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391056",
        "Last Price Paid": "$0.10",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036356",
        "Product Status": "Active"
    },
    {
        "Root": "10003034",
        "LV": "1",
        "Item Description": "PEANUT BUTTER .75Z 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003034001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.18",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003034",
        "Product Status": "Active"
    },
    {
        "Root": "10009983",
        "LV": "3",
        "Item Description": "LIQ AMARETTO DI SARONNO LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009983001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10009983",
        "Product Status": "Active"
    },
    {
        "Root": "10019224",
        "LV": "1",
        "Item Description": "CHARD DRAPPIER CARTE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "700184",
        "Last Price Paid": "$37.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019224",
        "Product Status": "Active"
    },
    {
        "Root": "10004894",
        "LV": "1",
        "Item Description": "CHIP TORTILLA WHT TRIANGL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5911201",
        "Last Price Paid": "$2.14",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004894",
        "Product Status": "Active"
    },
    {
        "Root": "10004690",
        "LV": "1",
        "Item Description": "CANDY TAFFY SALT WATER WR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "577670",
        "Last Price Paid": "$81.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004690",
        "Product Status": "Active"
    },
    {
        "Root": "10033425",
        "LV": "1",
        "Item Description": "FOIL I/FOLD SHEET 600246SM 12X10.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51600246",
        "Last Price Paid": "$0.03",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033425",
        "Product Status": "Active"
    },
    {
        "Root": "10027598",
        "LV": "1",
        "Item Description": "DRESSING KENS COLE SLAW 4/1 GALLONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5222815",
        "Last Price Paid": "$15.37",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027598",
        "Product Status": "Active"
    },
    {
        "Root": "10019229",
        "LV": "1",
        "Item Description": "MARINE PHYTOPLANKTON 50 GRAMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "50010050",
        "Last Price Paid": "$250.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019229",
        "Product Status": "Active"
    },
    {
        "Root": "10000094",
        "LV": "1",
        "Item Description": "BEEF PATTY 2.5Z KIDS BURGER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000094001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1072021",
        "Last Price Paid": "$3.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000094",
        "Product Status": "Active"
    },
    {
        "Root": "10000100",
        "LV": "1",
        "Item Description": "BEEF ANGUS CHUCK PUB BURGER 7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000100001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1090012",
        "Last Price Paid": "$4.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000100",
        "Product Status": "Active"
    },
    {
        "Root": "10001495",
        "LV": "1",
        "Item Description": "PAPAYA GRN ASIAN THIN CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001495",
        "Product Status": "Active"
    },
    {
        "Root": "10001495",
        "LV": "1",
        "Item Description": "PAPAYA GRN ASIAN THIN CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481607",
        "Last Price Paid": "$16.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001495",
        "Product Status": "Active"
    },
    {
        "Root": "10031452",
        "LV": "2",
        "Item Description": "VODKA HONEYSUCKLE CATHEAD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031452002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "943834",
        "Last Price Paid": "$17.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031452",
        "Product Status": "Active"
    },
    {
        "Root": "10004959",
        "LV": "1",
        "Item Description": "CREAM PASTRY FINESSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P500...{",
        "Last Price Paid": "$93.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004959",
        "Product Status": "Active"
    },
    {
        "Root": "10012227",
        "LV": "1",
        "Item Description": "SCOTCH BOWMORE SINGLE MALT 12 YEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012227001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "24733",
        "Last Price Paid": "$56.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012227",
        "Product Status": "Active"
    },
    {
        "Root": "10007910",
        "LV": "1",
        "Item Description": "PINOT NOIR MEIOMI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "993969",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007910",
        "Product Status": "Active"
    },
    {
        "Root": "10007910",
        "LV": "1",
        "Item Description": "PINOT NOIR MEIOMI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "993969",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007910",
        "Product Status": "Active"
    },
    {
        "Root": "10004187",
        "LV": "1",
        "Item Description": "SPICE LEMON MYRTLE GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068816",
        "Vendor": "SYSCO",
        "Vendor Item Nb": "5389331",
        "Last Price Paid": "$53.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004187",
        "Product Status": "Active"
    },
    {
        "Root": "10106789",
        "LV": "1",
        "Item Description": "IMPOSSIBLE CHICKEN TENDERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3000000094",
        "Last Price Paid": "$35.92",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106789",
        "Product Status": "Active"
    },
    {
        "Root": "10006888",
        "LV": "1",
        "Item Description": "SPARK MICHELLE BRT VS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006888",
        "Product Status": "Active"
    },
    {
        "Root": "10006888",
        "LV": "1",
        "Item Description": "SPARK MICHELLE BRT VS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006888",
        "Product Status": "Active"
    },
    {
        "Root": "10105648",
        "LV": "1",
        "Item Description": "BUCKET POPCORN 54OZ WHITE POLY COATED MDSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "515101",
        "Last Price Paid": "$0.44",
        "Case Pack": "165",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105648",
        "Product Status": "Active"
    },
    {
        "Root": "10003995",
        "LV": "1",
        "Item Description": "TOPPING STRAWBERRY 6/#5 C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8327652",
        "Last Price Paid": "$13.71",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003995",
        "Product Status": "Active"
    },
    {
        "Root": "10039712",
        "LV": "1",
        "Item Description": "RUM DIPLOMATICO PLANAS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9256791",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039712",
        "Product Status": "Active"
    },
    {
        "Root": "10007686",
        "LV": "2",
        "Item Description": "MOSCATO BELLAFINA NV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007686001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "632245",
        "Last Price Paid": "$8.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007686",
        "Product Status": "Active"
    },
    {
        "Root": "10034619",
        "LV": "1",
        "Item Description": "WINE RED BORD CH LA GAZIN POMEROL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "539053",
        "Last Price Paid": "$58.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034619",
        "Product Status": "Active"
    },
    {
        "Root": "10009396",
        "LV": "1",
        "Item Description": "BLND RIB SHACK RED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "642640",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009396",
        "Product Status": "Active"
    },
    {
        "Root": "10021834",
        "LV": "1",
        "Item Description": "MIX SNACK CHEX TRAD 60/1.75 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6207583",
        "Last Price Paid": "$0.54",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021834",
        "Product Status": "Active"
    },
    {
        "Root": "10005403",
        "LV": "1",
        "Item Description": "SB POUND CAKE LEMON ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11079227",
        "Last Price Paid": "$1.19",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005403",
        "Product Status": "Active"
    },
    {
        "Root": "10040515",
        "LV": "1",
        "Item Description": "RIESLING SPARKLING SEKT FITZ RITTER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040515001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "443442",
        "Last Price Paid": "$20.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040515",
        "Product Status": "Active"
    },
    {
        "Root": "10004056",
        "LV": "1",
        "Item Description": "DECOR CHOC MAPLE LEAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "D-155CC",
        "Last Price Paid": "$0.12",
        "Case Pack": "640",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004056",
        "Product Status": "Active"
    },
    {
        "Root": "10007035",
        "LV": "1",
        "Item Description": "CAB BV GORG LATOUR PRVT RES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9660606",
        "Last Price Paid": "$115.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007035",
        "Product Status": "Active"
    },
    {
        "Root": "10002799",
        "LV": "1",
        "Item Description": "BUTTER MARGN BLND WHPD 20# TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "974667",
        "Last Price Paid": "$44.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002799",
        "Product Status": "Active"
    },
    {
        "Root": "10012160",
        "LV": "1",
        "Item Description": "DRAFT MICHELOB ULTRA 1/6 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "19206",
        "Last Price Paid": "$51.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012160",
        "Product Status": "Active"
    },
    {
        "Root": "10007856",
        "LV": "2",
        "Item Description": "PINOT GRIG BENVOLIO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007856001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "196165",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007856",
        "Product Status": "Active"
    },
    {
        "Root": "10012788",
        "LV": "1",
        "Item Description": "AVOCADO HALF IW 48 CT FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5740220",
        "Last Price Paid": "$43.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012788",
        "Product Status": "Active"
    },
    {
        "Root": "10037907",
        "LV": "1",
        "Item Description": "BAR CHOC RICEMILK CRUNCH ENJOY 1.12Z 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F00719W",
        "Last Price Paid": "$1.68",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037907",
        "Product Status": "Active"
    },
    {
        "Root": "10005253",
        "LV": "1",
        "Item Description": "CANDIED LEMON PEEL DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005253001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "9...{PARADISE{",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005253",
        "Product Status": "Active"
    },
    {
        "Root": "10095968",
        "LV": "1",
        "Item Description": "SAUCE, CHEESE QUESO BLANCO 6/#10 CAN CHEF MATE SUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8707663",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095968",
        "Product Status": "Active"
    },
    {
        "Root": "10018135",
        "LV": "1",
        "Item Description": "CABERNET FRANC REVOLVER THE FURY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30065916",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018135",
        "Product Status": "Active"
    },
    {
        "Root": "10086435",
        "LV": "2",
        "Item Description": "POPCORN GLAZE PINEAPPLE COCONUT 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086435002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10086435",
        "Product Status": "Active"
    },
    {
        "Root": "10005668",
        "LV": "2",
        "Item Description": "BREAD WHEAT SANDWICH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005668001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005668",
        "Product Status": "Active"
    },
    {
        "Root": "10036229",
        "LV": "1",
        "Item Description": "GOLDSCHMIDT CHARDONNAY SINGING TREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547220",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036229",
        "Product Status": "Active"
    },
    {
        "Root": "10108250",
        "LV": "1",
        "Item Description": "MUG BASE NIGHT FIREWORKS ORANGE LID 16OZ 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108250",
        "Product Status": "Active"
    },
    {
        "Root": "10014803",
        "LV": "1",
        "Item Description": "JASMINE FILLERS (WHITE/GREEN)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SKR",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014803",
        "Product Status": "Active"
    },
    {
        "Root": "10110912",
        "LV": "1",
        "Item Description": "WINE RED BLEND CH HAUT NOUCHET 2012 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "56639",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110912",
        "Product Status": "Active"
    },
    {
        "Root": "10038804",
        "LV": "1",
        "Item Description": "CABERNET MR MICHEL ROLLAND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038804",
        "Product Status": "Active"
    },
    {
        "Root": "10022578",
        "LV": "1",
        "Item Description": "PEPSI CAN 24/12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9030222",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022578",
        "Product Status": "Active"
    },
    {
        "Root": "10003090",
        "LV": "1",
        "Item Description": "SAUCE CHILI THAI SWEET 24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003090001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5311345",
        "Last Price Paid": "$3.38",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003090",
        "Product Status": "Active"
    },
    {
        "Root": "10004065",
        "LV": "1",
        "Item Description": "SAUERKRAUT W/WN GERMAN 33",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "500-A",
        "Last Price Paid": "$28.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004065",
        "Product Status": "Active"
    },
    {
        "Root": "10091711",
        "LV": "1",
        "Item Description": "VODKA COCKTAIL SUNKISS FIZZ RTD SOC HR 250ML/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091711001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "SHC-SUNKISS-10",
        "Last Price Paid": "$3.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091711",
        "Product Status": "Active"
    },
    {
        "Root": "10030470",
        "LV": "1",
        "Item Description": "TRUFFLE ZEST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AG102",
        "Last Price Paid": "$9.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030470",
        "Product Status": "Active"
    },
    {
        "Root": "10031489",
        "LV": "1",
        "Item Description": "SNACK TOASTED CORN NUTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "300300",
        "Last Price Paid": "$3.15",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031489",
        "Product Status": "Active"
    },
    {
        "Root": "10001259",
        "LV": "2",
        "Item Description": "SHALLOTS PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001259001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "27300",
        "Last Price Paid": "$15.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10001259",
        "Product Status": "Active"
    },
    {
        "Root": "10020222",
        "LV": "1",
        "Item Description": "PINOT NOIR SKYWALKER ROSE VINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "573606",
        "Last Price Paid": "$27.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020222",
        "Product Status": "Active"
    },
    {
        "Root": "10000591",
        "LV": "1",
        "Item Description": "PORK BACON LAYOUT 14-18CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000591001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "225",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000591",
        "Product Status": "Active"
    },
    {
        "Root": "10013986",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "96399",
        "Last Price Paid": "$114.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013986",
        "Product Status": "Active"
    },
    {
        "Root": "10002462",
        "LV": "1",
        "Item Description": "CHEESE SPREAD BOURSIN GARLIC HERB FRANCE 12/5.2 OU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "170",
        "Last Price Paid": "$52.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002462",
        "Product Status": "Active"
    },
    {
        "Root": "10036279",
        "LV": "1",
        "Item Description": "BE-FRB12EB 12OZ ECO-BAMBOO FIBER BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11002200",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036279",
        "Product Status": "Active"
    },
    {
        "Root": "10003787",
        "LV": "1",
        "Item Description": "MAC AND CHEESE PKU 7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BROOKE FOODS-A",
        "Last Price Paid": "$16.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003787",
        "Product Status": "Active"
    },
    {
        "Root": "10018146",
        "LV": "2",
        "Item Description": "COOKIE VANILLA WAFER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018146002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4007548",
        "Last Price Paid": "$7.04",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018146",
        "Product Status": "Active"
    },
    {
        "Root": "10002976",
        "LV": "1",
        "Item Description": "KETCHUP 2/1.5 DISP RD GLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4198719",
        "Last Price Paid": "$8.45",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002976",
        "Product Status": "Active"
    },
    {
        "Root": "10002976",
        "LV": "1",
        "Item Description": "KETCHUP 2/1.5 DISP RD GLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.45",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002976",
        "Product Status": "Active"
    },
    {
        "Root": "10003974",
        "LV": "1",
        "Item Description": "VINEGAR 8 BRIX RED VERJUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-00265",
        "Last Price Paid": "$42.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003974",
        "Product Status": "Active"
    },
    {
        "Root": "10036038",
        "LV": "1",
        "Item Description": "GIN MONKEY 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036038001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "898141",
        "Last Price Paid": "$57.65",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036038",
        "Product Status": "Active"
    },
    {
        "Root": "10011361",
        "LV": "1",
        "Item Description": "QUINOA RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8646762",
        "Last Price Paid": "$50.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011361",
        "Product Status": "Active"
    },
    {
        "Root": "10108810",
        "LV": "1",
        "Item Description": "SCARPETTA PINOT GRIGIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9341883",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108810",
        "Product Status": "Active"
    },
    {
        "Root": "10000392",
        "LV": "1",
        "Item Description": "FENNEL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481565",
        "Last Price Paid": "$13.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000392",
        "Product Status": "Active"
    },
    {
        "Root": "10000392",
        "LV": "1",
        "Item Description": "FENNEL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04FEN-33-L",
        "Last Price Paid": "$13.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000392",
        "Product Status": "Active"
    },
    {
        "Root": "10000392",
        "LV": "1",
        "Item Description": "FENNEL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22115",
        "Last Price Paid": "$13.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000392",
        "Product Status": "Active"
    },
    {
        "Root": "10025237",
        "LV": "2",
        "Item Description": "SHIRAZ CEDERBERG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025237002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "872887000257",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025237",
        "Product Status": "Active"
    },
    {
        "Root": "10008107",
        "LV": "2",
        "Item Description": "CAB SAUV PLUMP JACK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008107001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "150718",
        "Last Price Paid": "$126.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008107",
        "Product Status": "Active"
    },
    {
        "Root": "10107588",
        "LV": "1",
        "Item Description": "COOKIES PARTAKE CHOCOLATE CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "00700-5",
        "Last Price Paid": "$3.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107588",
        "Product Status": "Active"
    },
    {
        "Root": "10012945",
        "LV": "1",
        "Item Description": "CARROT ORANGE LONG BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012945001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01BLCN-2",
        "Last Price Paid": "$16.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012945",
        "Product Status": "Active"
    },
    {
        "Root": "10090529",
        "LV": "1",
        "Item Description": "ROSE MARIETTA OV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9445678",
        "Last Price Paid": "$10.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090529",
        "Product Status": "Active"
    },
    {
        "Root": "10106070",
        "LV": "1",
        "Item Description": "CONTAINER FULL SLAB RIB W/LID PP M1670PP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106070001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51002672",
        "Last Price Paid": "$0.80",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106070",
        "Product Status": "Active"
    },
    {
        "Root": "10102808",
        "LV": "1",
        "Item Description": "BEER CAN STAR AFRICAN LAGER 24/11.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.65",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102808",
        "Product Status": "Active"
    },
    {
        "Root": "10003060",
        "LV": "1",
        "Item Description": "PASTA DRY SPAGHETTI WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9018332",
        "Last Price Paid": "$18.26",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003060",
        "Product Status": "Active"
    },
    {
        "Root": "10003955",
        "LV": "1",
        "Item Description": "COMPOUND MOCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "FABBRI",
        "Last Price Paid": "$41.26",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003955",
        "Product Status": "Active"
    },
    {
        "Root": "10013438",
        "LV": "1",
        "Item Description": "SHRIMP 13/15 P&D TAIL ON SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013438001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "74-13108",
        "Last Price Paid": "$7.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013438",
        "Product Status": "Active"
    },
    {
        "Root": "10102700",
        "LV": "2",
        "Item Description": "SIPPER PREM DAK TIMON 20OZ 20/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102700002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.18",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102700",
        "Product Status": "Active"
    },
    {
        "Root": "10100273",
        "LV": "1",
        "Item Description": "KOSHER BSC CHALLAH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.92",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100273",
        "Product Status": "Active"
    },
    {
        "Root": "10013440",
        "LV": "1",
        "Item Description": "VEG TOMATO ROASTED YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013440001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30585",
        "Last Price Paid": "$8.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013440",
        "Product Status": "Active"
    },
    {
        "Root": "10018141",
        "LV": "1",
        "Item Description": "PINOT NOIR ELOUAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "579951",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018141",
        "Product Status": "Active"
    },
    {
        "Root": "10044395",
        "LV": "1",
        "Item Description": "PASTRY, EPCOT, KATAIFFI 60/4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7060963",
        "Last Price Paid": "$0.88",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044395",
        "Product Status": "Active"
    },
    {
        "Root": "10041599",
        "LV": "1",
        "Item Description": "SB LIDS STRAWLESS 16/26OZ 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041599",
        "Product Status": "Active"
    },
    {
        "Root": "10032444",
        "LV": "1",
        "Item Description": "COLOR CANDY LIQUID ROYAL BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "304017B",
        "Last Price Paid": "$21.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032444",
        "Product Status": "Active"
    },
    {
        "Root": "10031432",
        "LV": "1",
        "Item Description": "WHITE TRUFFLE FRESH MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSTRU008",
        "Last Price Paid": "$156.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031432",
        "Product Status": "Active"
    },
    {
        "Root": "10039776",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN CHOICE ANGUS STEAK READY 1\" BLOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039776001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195103",
        "Last Price Paid": "$29.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039776",
        "Product Status": "Active"
    },
    {
        "Root": "10000092",
        "LV": "1",
        "Item Description": "BEEF SHORT RIB BONELESS 24/8-10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000092001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190078",
        "Last Price Paid": "$10.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000092",
        "Product Status": "Active"
    },
    {
        "Root": "10030206",
        "LV": "1",
        "Item Description": "CUP CLEAR PLASTIC 6.7 OZ GC18137",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030206001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11097004",
        "Last Price Paid": "$0.30",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030206",
        "Product Status": "Active"
    },
    {
        "Root": "10018554",
        "LV": "1",
        "Item Description": "CAB FRANC JARVIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018554001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "977325",
        "Last Price Paid": "$110.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018554",
        "Product Status": "Active"
    },
    {
        "Root": "10038457",
        "LV": "1",
        "Item Description": "LINER WHITE LW BASKET RIVIERA 9X6 2C1S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038457001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51004887",
        "Last Price Paid": "$0.06",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038457",
        "Product Status": "Active"
    },
    {
        "Root": "10008102",
        "LV": "2",
        "Item Description": "CHARD PAUL HOBB RUS RVR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008102001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "835014",
        "Last Price Paid": "$55.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008102",
        "Product Status": "Active"
    },
    {
        "Root": "10016838",
        "LV": "1",
        "Item Description": "SPRINKLES ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9739",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016838",
        "Product Status": "Active"
    },
    {
        "Root": "10008167",
        "LV": "1",
        "Item Description": "INSIGNIA JOSEPH PHELPS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "590495",
        "Last Price Paid": "$224.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008167",
        "Product Status": "Active"
    },
    {
        "Root": "10001720",
        "LV": "1",
        "Item Description": "CELERY ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001720001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401099",
        "Last Price Paid": "$29.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001720",
        "Product Status": "Active"
    },
    {
        "Root": "10001720",
        "LV": "1",
        "Item Description": "CELERY ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001720001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001720",
        "Product Status": "Active"
    },
    {
        "Root": "10036001",
        "LV": "1",
        "Item Description": "CUP SIPPER NONALC PRIMETIME BEE POP 16 OZ 104/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.84",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036001",
        "Product Status": "Active"
    },
    {
        "Root": "10039806",
        "LV": "1",
        "Item Description": "CHOC HOLLY LEAVES 135/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1427",
        "Last Price Paid": "$0.26",
        "Case Pack": "135",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039806",
        "Product Status": "Active"
    },
    {
        "Root": "10010177",
        "LV": "1",
        "Item Description": "WATER DASANI 12Z PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010177",
        "Product Status": "Active"
    },
    {
        "Root": "10093939",
        "LV": "1",
        "Item Description": "GELATO GS FRENCH VANILLA SS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "GS22000",
        "Last Price Paid": "$15.38",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093939",
        "Product Status": "Active"
    },
    {
        "Root": "10033600",
        "LV": "2",
        "Item Description": "SUGAR CUBES MICKEY RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033600001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "49755",
        "Last Price Paid": "$0.06",
        "Case Pack": "2016",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10033600",
        "Product Status": "Active"
    },
    {
        "Root": "10092572",
        "LV": "1",
        "Item Description": "LAMB LOIN CHOP 1.25\" THICK /4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092572001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.45",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092572",
        "Product Status": "Active"
    },
    {
        "Root": "10103813",
        "LV": "1",
        "Item Description": "FONDANT RED VANILLA 2# PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "15400",
        "Last Price Paid": "$9.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103813",
        "Product Status": "Active"
    },
    {
        "Root": "10033204",
        "LV": "1",
        "Item Description": "SYRUP MONIN CINNAMON L 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5012",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033204",
        "Product Status": "Active"
    },
    {
        "Root": "10025450",
        "LV": "2",
        "Item Description": "PHOTOCAKE FROSTING SHEETS 8.5 X 11",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025450002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "842",
        "Last Price Paid": "$1.21",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025450",
        "Product Status": "Active"
    },
    {
        "Root": "10010548",
        "LV": "2",
        "Item Description": "SCHLOSS VOLLRADS RIESL QBA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010548001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "264865",
        "Last Price Paid": "$14.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010548",
        "Product Status": "Active"
    },
    {
        "Root": "10110369",
        "LV": "1",
        "Item Description": "CACTUS LEAVES L/S 20# CS 4V&A ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260400",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110369",
        "Product Status": "Active"
    },
    {
        "Root": "10038900",
        "LV": "1",
        "Item Description": "TUMBLER FROSTED 12 OUNCE EMI-MST12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11313022",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038900",
        "Product Status": "Active"
    },
    {
        "Root": "10040012",
        "LV": "1",
        "Item Description": "DRAFT REDSTONE BLACK RASPBERRY 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1454",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040012",
        "Product Status": "Active"
    },
    {
        "Root": "10102089",
        "LV": "1",
        "Item Description": "TINKERBELL WINGS GOLD & GREEN 5 X 1.3CM 3000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6235380000",
        "Last Price Paid": "",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102089",
        "Product Status": "Active"
    },
    {
        "Root": "10001818",
        "LV": "1",
        "Item Description": "PASTE ACHIOTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598285",
        "Last Price Paid": "$37.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001818",
        "Product Status": "Active"
    },
    {
        "Root": "10102136",
        "LV": "1",
        "Item Description": "COOKIE THANK YOU STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "TYS",
        "Last Price Paid": "$0.85",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102136",
        "Product Status": "Active"
    },
    {
        "Root": "10005462",
        "LV": "1",
        "Item Description": "SPRAY VELVET WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV00W",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005462",
        "Product Status": "Active"
    },
    {
        "Root": "10009543",
        "LV": "1",
        "Item Description": "VODKA SKYY CITRUS INFUSION 1LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009543",
        "Product Status": "Active"
    },
    {
        "Root": "10095414",
        "LV": "1",
        "Item Description": "BRANDY APPLE HARTLEY VSOP 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9251736",
        "Last Price Paid": "$8.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095414",
        "Product Status": "Active"
    },
    {
        "Root": "10019275",
        "LV": "1",
        "Item Description": "NUT PINE 5# CASES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "380705",
        "Last Price Paid": "$119.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019275",
        "Product Status": "Active"
    },
    {
        "Root": "10006049",
        "LV": "1",
        "Item Description": "STEIN 16Z MK GASTON S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.16",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006049",
        "Product Status": "Active"
    },
    {
        "Root": "10101170",
        "LV": "1",
        "Item Description": "CANDY MARSHMALLOW APPLE STRAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7580901",
        "Last Price Paid": "$1.74",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101170",
        "Product Status": "Active"
    },
    {
        "Root": "10018862",
        "LV": "1",
        "Item Description": "DÉCOR NON EDIBLE DISCO SPARKLES RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1006011",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018862",
        "Product Status": "Active"
    },
    {
        "Root": "10017352",
        "LV": "1",
        "Item Description": "GLOVE VINYL POW FREE LG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.11",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017352",
        "Product Status": "Active"
    },
    {
        "Root": "10037116",
        "LV": "1",
        "Item Description": "CHEESE ROMANO COW DOMESTIC 24 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037116001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3433232",
        "Last Price Paid": "$4.11",
        "Case Pack": "26",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037116",
        "Product Status": "Active"
    },
    {
        "Root": "10014223",
        "LV": "1",
        "Item Description": "CHOC DRK RECT HAPPY BIRTHDAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW240105R",
        "Last Price Paid": "$14.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014223",
        "Product Status": "Active"
    },
    {
        "Root": "10002920",
        "LV": "1",
        "Item Description": "SODA FANTA ORANGE 20Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002920001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002920",
        "Product Status": "Active"
    },
    {
        "Root": "10009557",
        "LV": "1",
        "Item Description": "VODKA TITOS HANDMADE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009557",
        "Product Status": "Active"
    },
    {
        "Root": "10038812",
        "LV": "1",
        "Item Description": "MUFFIN BLUEBERRY 5 OUNCE TULIP CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.94",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038812",
        "Product Status": "Active"
    },
    {
        "Root": "10103708",
        "LV": "1",
        "Item Description": "JUICE PET BODY ARMOR ORANGE MANGO 16OZ/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.59",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103708",
        "Product Status": "Active"
    },
    {
        "Root": "10104438",
        "LV": "1",
        "Item Description": "BEER DRAFT URBAN ARTIFACTS THE GADGET 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104438001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "12202",
        "Last Price Paid": "$259.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104438",
        "Product Status": "Active"
    },
    {
        "Root": "10003745",
        "LV": "1",
        "Item Description": "MIX BROWNIE GF BOBS 22Z/4EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101509",
        "Last Price Paid": "$4.60",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003745",
        "Product Status": "Active"
    },
    {
        "Root": "10032898",
        "LV": "1",
        "Item Description": "PERRETTE CLEAR BOTTLE  8.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51097006",
        "Last Price Paid": "$0.87",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032898",
        "Product Status": "Active"
    },
    {
        "Root": "10002045",
        "LV": "1",
        "Item Description": "SQUASH ACORN LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002045001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002045",
        "Product Status": "Active"
    },
    {
        "Root": "10007379",
        "LV": "1",
        "Item Description": "RIES HEINZ EIFEL AUS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007379001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "729979",
        "Last Price Paid": "$12.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007379",
        "Product Status": "Active"
    },
    {
        "Root": "10038898",
        "LV": "1",
        "Item Description": "LID BOWL 8-16 OUNCE FIBER BARREL BBL-SC-U12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005046",
        "Last Price Paid": "$0.11",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038898",
        "Product Status": "Active"
    },
    {
        "Root": "10002691",
        "LV": "1",
        "Item Description": "CEREAL FRUIT LOOP BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002691",
        "Product Status": "Active"
    },
    {
        "Root": "10005585",
        "LV": "1",
        "Item Description": "PASTRY DANISH PLAIN 3.5Z PRE-PROOFED 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.49",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005585",
        "Product Status": "Active"
    },
    {
        "Root": "10027291",
        "LV": "1",
        "Item Description": "COFFEE CAFE ESSENTIALS KONA MOCHA MIX 3.5 LB BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "S1700-200",
        "Last Price Paid": "$23.81",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027291",
        "Product Status": "Active"
    },
    {
        "Root": "10008099",
        "LV": "1",
        "Item Description": "PORT FONSECA 20YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008099001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273844",
        "Last Price Paid": "$40.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008099",
        "Product Status": "Active"
    },
    {
        "Root": "10004158",
        "LV": "3",
        "Item Description": "SB TOPPING CARAMEL BRULEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004158001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11053547",
        "Last Price Paid": "$6.53",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10004158",
        "Product Status": "Active"
    },
    {
        "Root": "10010402",
        "LV": "2",
        "Item Description": "BEER MOLSON CANADIAN 12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010402001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "57398",
        "Last Price Paid": "$1.28",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010402",
        "Product Status": "Active"
    },
    {
        "Root": "10086792",
        "LV": "1",
        "Item Description": "SB TOPPING SUGAR COOKIE RED GREEN SPRINKLES 71G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086792001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11120686",
        "Last Price Paid": "$4.82",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086792",
        "Product Status": "Active"
    },
    {
        "Root": "10088365",
        "LV": "2",
        "Item Description": "CONTAINER CUPCAKE SINGLE HINGED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088365002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510493",
        "Last Price Paid": "$0.35",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088365",
        "Product Status": "Active"
    },
    {
        "Root": "10032126",
        "LV": "1",
        "Item Description": "MIX FREESTYLE PART AA SPRITE SYRUP COMPONENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7036706",
        "Last Price Paid": "$10.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032126",
        "Product Status": "Active"
    },
    {
        "Root": "10033751",
        "LV": "1",
        "Item Description": "CHEESE MONTEREY JACK SLICED .75 OZ TWIN PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033751",
        "Product Status": "Active"
    },
    {
        "Root": "10033751",
        "LV": "1",
        "Item Description": "CHEESE MONTEREY JACK SLICED .75 OZ TWIN PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6419519",
        "Last Price Paid": "$4.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033751",
        "Product Status": "Active"
    },
    {
        "Root": "10033189",
        "LV": "1",
        "Item Description": "SMOOTHIE MIX PEACH 12/32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500008003",
        "Last Price Paid": "$4.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033189",
        "Product Status": "Active"
    },
    {
        "Root": "10035952",
        "LV": "1",
        "Item Description": "CORN DOG ALL MEAT FOOTLONG BATTERED COOKED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8057507",
        "Last Price Paid": "$1.22",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035952",
        "Product Status": "Active"
    },
    {
        "Root": "10000582",
        "LV": "1",
        "Item Description": "PORK SHOULDER CUSHION COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000582001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.60",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000582",
        "Product Status": "Active"
    },
    {
        "Root": "10002090",
        "LV": "1",
        "Item Description": "BEET GOLD LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002090001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BEETGDLB",
        "Last Price Paid": "$1.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002090",
        "Product Status": "Active"
    },
    {
        "Root": "10034322",
        "LV": "1",
        "Item Description": "TRANSFER SHEETS CONTEMPORARY REPETITIVE GOLD PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65663G",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034322",
        "Product Status": "Active"
    },
    {
        "Root": "10083064",
        "LV": "1",
        "Item Description": "DEC 10/6 RECT BB WHITE CHOC 4X2.9CM 330PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234470000",
        "Last Price Paid": "$0.33",
        "Case Pack": "330",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083064",
        "Product Status": "Active"
    },
    {
        "Root": "10002790",
        "LV": "1",
        "Item Description": "COFFEE INROOM REG 500CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.18",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002790",
        "Product Status": "Active"
    },
    {
        "Root": "10034359",
        "LV": "1",
        "Item Description": "BEER ALE GOLDEN KONA BIG WAVE 16Z CAN 24PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "24163",
        "Last Price Paid": "$33.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034359",
        "Product Status": "Active"
    },
    {
        "Root": "10088842",
        "LV": "1",
        "Item Description": "CHOCOLATE FEVES CARAMELIA MILK 36%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "70984",
        "Last Price Paid": "$75.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088842",
        "Product Status": "Active"
    },
    {
        "Root": "10002102",
        "LV": "1",
        "Item Description": "CABBAGE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002102001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002102",
        "Product Status": "Active"
    },
    {
        "Root": "10107589",
        "LV": "1",
        "Item Description": "COOKIES PARTAKE GINGER SNAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "00702-2",
        "Last Price Paid": "$3.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107589",
        "Product Status": "Active"
    },
    {
        "Root": "10018848",
        "LV": "1",
        "Item Description": "PEPPER SERRANO 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490125",
        "Last Price Paid": "$14.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018848",
        "Product Status": "Active"
    },
    {
        "Root": "10026056",
        "LV": "1",
        "Item Description": "CUTTING BOARD 11.8 X 7.1 X .2 FOTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11001506",
        "Last Price Paid": "$0.62",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026056",
        "Product Status": "Active"
    },
    {
        "Root": "10006410",
        "LV": "1",
        "Item Description": "LINER PAN 16X24 QUILON BR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006410",
        "Product Status": "Active"
    },
    {
        "Root": "10102697",
        "LV": "2",
        "Item Description": "SPICE SALT PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102697002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102697",
        "Product Status": "Active"
    },
    {
        "Root": "10102697",
        "LV": "2",
        "Item Description": "SPICE SALT PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102697002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "1329903",
        "Last Price Paid": "$6.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102697",
        "Product Status": "Active"
    },
    {
        "Root": "10088732",
        "LV": "1",
        "Item Description": "SYRUP MONIN BLUEBERRY CONCENTRATED FLAVOR 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C008FP",
        "Last Price Paid": "$8.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088732",
        "Product Status": "Active"
    },
    {
        "Root": "10034305",
        "LV": "1",
        "Item Description": "NUTMEG WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "161972",
        "Last Price Paid": "$11.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034305",
        "Product Status": "Active"
    },
    {
        "Root": "10003844",
        "LV": "1",
        "Item Description": "SNACK SURF SWTY FRTY BEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003844001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "T0004",
        "Last Price Paid": "$2.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003844",
        "Product Status": "Active"
    },
    {
        "Root": "10103348",
        "LV": "1",
        "Item Description": "SCALLOP 30/40 N. ATLANTIC IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103348001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6522702",
        "Last Price Paid": "$14.43",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103348",
        "Product Status": "Active"
    },
    {
        "Root": "10027478",
        "LV": "2",
        "Item Description": "JUICE BAR TRIO FLAVOR PANDORA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027478002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.46",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027478",
        "Product Status": "Active"
    },
    {
        "Root": "10084168",
        "LV": "2",
        "Item Description": "LINER BASKET 8.25X12 LIGHT WEIGHT 1 COLOR 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084168002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51015080",
        "Last Price Paid": "$0.03",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084168",
        "Product Status": "Active"
    },
    {
        "Root": "10016834",
        "LV": "1",
        "Item Description": "NETTLES STINGING LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016834001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FL015",
        "Last Price Paid": "$16.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016834",
        "Product Status": "Active"
    },
    {
        "Root": "10000774",
        "LV": "1",
        "Item Description": "BUFFALO STRIPLOIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000774001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4504100",
        "Last Price Paid": "$14.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000774",
        "Product Status": "Active"
    },
    {
        "Root": "10007939",
        "LV": "1",
        "Item Description": "CAB SAUV FLORA TRILOGY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "150245",
        "Last Price Paid": "$43.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007939",
        "Product Status": "Active"
    },
    {
        "Root": "10102917",
        "LV": "1",
        "Item Description": "KOSHER BSC HOT DOG POT/BUN 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102917",
        "Product Status": "Active"
    },
    {
        "Root": "10001858",
        "LV": "1",
        "Item Description": "HERB BASIL SWEET 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598014",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001858",
        "Product Status": "Active"
    },
    {
        "Root": "10001858",
        "LV": "1",
        "Item Description": "HERB BASIL SWEET 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001858",
        "Product Status": "Active"
    },
    {
        "Root": "10002757",
        "LV": "2",
        "Item Description": "JUICE BOX MM FRUIT PUNCH 40/6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002757002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002757",
        "Product Status": "Active"
    },
    {
        "Root": "10004444",
        "LV": "1",
        "Item Description": "KETCHUP HEINZ 2.25Z IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6002992",
        "Last Price Paid": "$0.56",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004444",
        "Product Status": "Active"
    },
    {
        "Root": "10009990",
        "LV": "1",
        "Item Description": "LIQ PERNOD ANISE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "23979",
        "Last Price Paid": "$33.56",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009990",
        "Product Status": "Active"
    },
    {
        "Root": "10011022",
        "LV": "1",
        "Item Description": "ESPRESSO ITALIAN DECAF GROUND 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.28",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011022",
        "Product Status": "Active"
    },
    {
        "Root": "10008269",
        "LV": "1",
        "Item Description": "PINOT GRIS TRIMBACH RS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "544237",
        "Last Price Paid": "$21.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008269",
        "Product Status": "Active"
    },
    {
        "Root": "10001772",
        "LV": "1",
        "Item Description": "TURNIP BABY WHITE W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480122",
        "Last Price Paid": "$36.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001772",
        "Product Status": "Active"
    },
    {
        "Root": "10001772",
        "LV": "1",
        "Item Description": "TURNIP BABY WHITE W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22321",
        "Last Price Paid": "$36.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001772",
        "Product Status": "Active"
    },
    {
        "Root": "10001772",
        "LV": "1",
        "Item Description": "TURNIP BABY WHITE W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001772",
        "Product Status": "Active"
    },
    {
        "Root": "10001772",
        "LV": "1",
        "Item Description": "TURNIP BABY WHITE W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001772",
        "Product Status": "Active"
    },
    {
        "Root": "10000197",
        "LV": "2",
        "Item Description": "POTATO YUKON GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000197002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000197",
        "Product Status": "Active"
    },
    {
        "Root": "10107290",
        "LV": "1",
        "Item Description": "VANILLA BEAN PASTE MADAGASCAR BOURBON QT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "10010",
        "Last Price Paid": "$94.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107290",
        "Product Status": "Active"
    },
    {
        "Root": "10002303",
        "LV": "1",
        "Item Description": "CHEESE GOUDA GOAT WIJNGAARD HOLLAND 22 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002303001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "4104",
        "Last Price Paid": "$14.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002303",
        "Product Status": "Active"
    },
    {
        "Root": "10013267",
        "LV": "1",
        "Item Description": "BEETS YOUNG MIXED 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01YMBT-26",
        "Last Price Paid": "$89.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013267",
        "Product Status": "Active"
    },
    {
        "Root": "10034364",
        "LV": "1",
        "Item Description": "WHITE CHOC DOPEYS PURPLE HAT 1 1/2 X 1 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW216005",
        "Last Price Paid": "$0.60",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034364",
        "Product Status": "Active"
    },
    {
        "Root": "10100310",
        "LV": "1",
        "Item Description": "KETCHUP BAG TOMATO FANCY SHELF STABLE 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6002364",
        "Last Price Paid": "$18.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100310",
        "Product Status": "Active"
    },
    {
        "Root": "10004686",
        "LV": "2",
        "Item Description": "MIX CALIFORNIA MIX 24CT 3.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004686002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203014",
        "Last Price Paid": "$1.23",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004686",
        "Product Status": "Active"
    },
    {
        "Root": "10019612",
        "LV": "1",
        "Item Description": "BROWNIE FULL SHEET CUT 40",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1666",
        "Last Price Paid": "$0.80",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019612",
        "Product Status": "Active"
    },
    {
        "Root": "10006126",
        "LV": "2",
        "Item Description": "TRAY FOOD #100 NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006126002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006126",
        "Product Status": "Active"
    },
    {
        "Root": "10004434",
        "LV": "1",
        "Item Description": "FLAVOR FIDELIO LIQ SOUR B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004434",
        "Product Status": "Active"
    },
    {
        "Root": "10007041",
        "LV": "1",
        "Item Description": "SAUV BLC M EDWARD 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "829865",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007041",
        "Product Status": "Active"
    },
    {
        "Root": "10027890",
        "LV": "1",
        "Item Description": "SPARKLING WATER ISKILDE 700ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "ISK11",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027890",
        "Product Status": "Active"
    },
    {
        "Root": "10110370",
        "LV": "1",
        "Item Description": "LIMES SUDACHI WHOLE 10#, 4V&A ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480552",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110370",
        "Product Status": "Active"
    },
    {
        "Root": "10104845",
        "LV": "1",
        "Item Description": "LAMB LOIN CHOP 1.25 IN THICK 4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104845001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104845",
        "Product Status": "Active"
    },
    {
        "Root": "10013943",
        "LV": "1",
        "Item Description": "WATERCRESS ULTRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013943",
        "Product Status": "Active"
    },
    {
        "Root": "10083346",
        "LV": "1",
        "Item Description": "BAGEL ASSORTED UNSLICED 72/4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3275599",
        "Last Price Paid": "$0.60",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083346",
        "Product Status": "Active"
    },
    {
        "Root": "10003597",
        "LV": "1",
        "Item Description": "CANDY SKITTLES ORIGINAL 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "58342",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003597",
        "Product Status": "Active"
    },
    {
        "Root": "10108225",
        "LV": "1",
        "Item Description": "MEZCAL LOS SIETE MISTERIOS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108225001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9225837",
        "Last Price Paid": "$29.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108225",
        "Product Status": "Active"
    },
    {
        "Root": "10029806",
        "LV": "4",
        "Item Description": "POPCORN POP SECRET MOVIE BUTTER 3.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029806003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "300358",
        "Last Price Paid": "$1.61",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10029806",
        "Product Status": "Active"
    },
    {
        "Root": "10005638",
        "LV": "1",
        "Item Description": "COOKIE CHOCOLATE CHUNK 3 OZ PLUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "85",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005638",
        "Product Status": "Active"
    },
    {
        "Root": "10004657",
        "LV": "2",
        "Item Description": "NUT PIGNOLIAS/PINENUTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004657002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "181501",
        "Last Price Paid": "$273.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004657",
        "Product Status": "Active"
    },
    {
        "Root": "10032406",
        "LV": "1",
        "Item Description": "SB DRAGONFRUIT INCLUSIONS 12EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032406001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11087540",
        "Last Price Paid": "$11.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032406",
        "Product Status": "Active"
    },
    {
        "Root": "10007673",
        "LV": "2",
        "Item Description": "BLND GAJA MARCND PROMIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007673001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9652367",
        "Last Price Paid": "$41.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007673",
        "Product Status": "Active"
    },
    {
        "Root": "10000368",
        "LV": "1",
        "Item Description": "PEPPER GREEN JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "07-005",
        "Last Price Paid": "$16.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000368",
        "Product Status": "Active"
    },
    {
        "Root": "10000368",
        "LV": "1",
        "Item Description": "PEPPER GREEN JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "690080",
        "Last Price Paid": "$16.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000368",
        "Product Status": "Active"
    },
    {
        "Root": "10021822",
        "LV": "1",
        "Item Description": "POLENTA SEMI FINE YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "POL000",
        "Last Price Paid": "$67.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021822",
        "Product Status": "Active"
    },
    {
        "Root": "10003515",
        "LV": "1",
        "Item Description": "SYRUP COFF BLKBRRY LTR 4E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003515001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5006",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003515",
        "Product Status": "Active"
    },
    {
        "Root": "10035214",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861320000",
        "Last Price Paid": "$14.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035214",
        "Product Status": "Active"
    },
    {
        "Root": "10108406",
        "LV": "1",
        "Item Description": "RICE, BEN'S ORGL BLEND-FESTIVALS ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108406001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4390398",
        "Last Price Paid": "$9.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108406",
        "Product Status": "Active"
    },
    {
        "Root": "10004603",
        "LV": "1",
        "Item Description": "PALM HEARTS WHOLE 28Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7641597",
        "Last Price Paid": "$5.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004603",
        "Product Status": "Active"
    },
    {
        "Root": "10010050",
        "LV": "1",
        "Item Description": "BEER SWEETWATR 420 16Z PALE ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010050",
        "Product Status": "Active"
    },
    {
        "Root": "10002766",
        "LV": "1",
        "Item Description": "COFFEE ESPRESSO DECAF BEAN 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.28",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002766",
        "Product Status": "Active"
    },
    {
        "Root": "10107528",
        "LV": "1",
        "Item Description": "BEEF BRISKET WHOLE CHOICE ANGUS 48#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107528001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "121",
        "Last Price Paid": "$3.48",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107528",
        "Product Status": "Active"
    },
    {
        "Root": "10037558",
        "LV": "1",
        "Item Description": "COCOA BUTTER JEWEL GREEN MERMAID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302244C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037558",
        "Product Status": "Active"
    },
    {
        "Root": "10001519",
        "LV": "1",
        "Item Description": "STARFLOWER EGYPTIAN 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001519001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05ESTF-24",
        "Last Price Paid": "$0.63",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001519",
        "Product Status": "Active"
    },
    {
        "Root": "10020190",
        "LV": "1",
        "Item Description": "HOLLY LEAF & BERRY NTR XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NTR",
        "Last Price Paid": "$4.90",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020190",
        "Product Status": "Active"
    },
    {
        "Root": "10005248",
        "LV": "1",
        "Item Description": "MIX FREESTYLE GRAPES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005248001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3964741",
        "Last Price Paid": "$16.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005248",
        "Product Status": "Active"
    },
    {
        "Root": "10003677",
        "LV": "1",
        "Item Description": "DECOR CHOC MICKEY PUZZLE 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.25",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003677",
        "Product Status": "Active"
    },
    {
        "Root": "10001615",
        "LV": "1",
        "Item Description": "ONION RED 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514047",
        "Last Price Paid": "$11.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001615",
        "Product Status": "Active"
    },
    {
        "Root": "10001615",
        "LV": "1",
        "Item Description": "ONION RED 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-002-12",
        "Last Price Paid": "$11.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001615",
        "Product Status": "Active"
    },
    {
        "Root": "10029462",
        "LV": "1",
        "Item Description": "FRUIT BUDDAH HAND S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390224",
        "Last Price Paid": "$54.00",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029462",
        "Product Status": "Active"
    },
    {
        "Root": "10091232",
        "LV": "1",
        "Item Description": "FLOUR, MASAREPA WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091232001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "098259-5",
        "Last Price Paid": "$4.16",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091232",
        "Product Status": "Active"
    },
    {
        "Root": "10037816",
        "LV": "1",
        "Item Description": "LADY BUG 1 1/2 X 1 RED/BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW26301",
        "Last Price Paid": "$0.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037816",
        "Product Status": "Active"
    },
    {
        "Root": "10009575",
        "LV": "2",
        "Item Description": "VODKA SMIRNOF 80 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009575002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "378937",
        "Last Price Paid": "$5.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009575",
        "Product Status": "Active"
    },
    {
        "Root": "10000614",
        "LV": "2",
        "Item Description": "BEEF POT ROAST COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000614002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.01",
        "Case Pack": "31",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000614",
        "Product Status": "Active"
    },
    {
        "Root": "10036049",
        "LV": "1",
        "Item Description": "GIN BLUECOAT AMERICAN DRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550032",
        "Last Price Paid": "$23.04",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036049",
        "Product Status": "Active"
    },
    {
        "Root": "10095588",
        "LV": "1",
        "Item Description": "EGG BAGELS 4OZ GUTTENPLAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7058050",
        "Last Price Paid": "$0.39",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095588",
        "Product Status": "Active"
    },
    {
        "Root": "10081624",
        "LV": "1",
        "Item Description": "BAG 5X1.5X4.5 20# KRAFT 6530-NAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081624001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51492039",
        "Last Price Paid": "$0.04",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081624",
        "Product Status": "Active"
    },
    {
        "Root": "10081624",
        "LV": "1",
        "Item Description": "BAG 5X1.5X4.5 20# KRAFT 6530-NAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081624001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081624",
        "Product Status": "Active"
    },
    {
        "Root": "10094968",
        "LV": "1",
        "Item Description": "PIZZA CRUST PARBAKED 7INCH 48/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094968",
        "Product Status": "Active"
    },
    {
        "Root": "10004457",
        "LV": "1",
        "Item Description": "MIX FREESTYLE LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004457001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3555844",
        "Last Price Paid": "$16.65",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004457",
        "Product Status": "Active"
    },
    {
        "Root": "10038814",
        "LV": "1",
        "Item Description": "MUFFIN BANANA LARGE 6.5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038814",
        "Product Status": "Active"
    },
    {
        "Root": "10001014",
        "LV": "1",
        "Item Description": "BOQUERONES (WHT ANCHOVIES) SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001014001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001014",
        "Product Status": "Active"
    },
    {
        "Root": "10001014",
        "LV": "1",
        "Item Description": "BOQUERONES (WHT ANCHOVIES) SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001014001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-81005",
        "Last Price Paid": "$19.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001014",
        "Product Status": "Active"
    },
    {
        "Root": "10019529",
        "LV": "1",
        "Item Description": "PASTA GEMELLI BARILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808039297",
        "Last Price Paid": "$9.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019529",
        "Product Status": "Active"
    },
    {
        "Root": "10001260",
        "LV": "1",
        "Item Description": "POTATO SHREDDED BLANCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.58",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001260",
        "Product Status": "Active"
    },
    {
        "Root": "10001733",
        "LV": "1",
        "Item Description": "POTATO PEANUT FINGERLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "POTFIN",
        "Last Price Paid": "$71.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001733",
        "Product Status": "Active"
    },
    {
        "Root": "10001733",
        "LV": "1",
        "Item Description": "POTATO PEANUT FINGERLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408445",
        "Last Price Paid": "$71.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001733",
        "Product Status": "Active"
    },
    {
        "Root": "10001733",
        "LV": "1",
        "Item Description": "POTATO PEANUT FINGERLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "29160",
        "Last Price Paid": "$71.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001733",
        "Product Status": "Active"
    },
    {
        "Root": "10007073",
        "LV": "1",
        "Item Description": "MALBEC COUTALE CLOS LA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "102593",
        "Last Price Paid": "$12.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007073",
        "Product Status": "Active"
    },
    {
        "Root": "10006224",
        "LV": "1",
        "Item Description": "BAG C-OUT 10X6.75X12 PAPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8658343",
        "Last Price Paid": "$0.20",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006224",
        "Product Status": "Active"
    },
    {
        "Root": "10000063",
        "LV": "1",
        "Item Description": "WEISSWURST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000063001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "190A",
        "Last Price Paid": "$4.18",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000063",
        "Product Status": "Active"
    },
    {
        "Root": "10000107",
        "LV": "1",
        "Item Description": "BRATWURST COARSE ROST 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000107001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "802",
        "Last Price Paid": "$5.17",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000107",
        "Product Status": "Active"
    },
    {
        "Root": "10004663",
        "LV": "1",
        "Item Description": "NUT PECAN HALV RST/NO SAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "140170",
        "Last Price Paid": "$231.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004663",
        "Product Status": "Active"
    },
    {
        "Root": "10020194",
        "LV": "1",
        "Item Description": "BOARD CAKE GOLD 10 IN X 3/16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.80",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020194",
        "Product Status": "Active"
    },
    {
        "Root": "10023962",
        "LV": "1",
        "Item Description": "DRAFT ELYSIAN SPACE DUST IPA 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "44306",
        "Last Price Paid": "$97.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023962",
        "Product Status": "Active"
    },
    {
        "Root": "10011008",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON RUST EN VREDE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "565055",
        "Last Price Paid": "$20.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011008",
        "Product Status": "Active"
    },
    {
        "Root": "10022719",
        "LV": "1",
        "Item Description": "WHSKY SMOKY PUMPKIN PIE MOONSHINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "396983",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022719",
        "Product Status": "Active"
    },
    {
        "Root": "10004324",
        "LV": "1",
        "Item Description": "HORS FRANKS IN BLANKET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300323",
        "Last Price Paid": "$0.54",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004324",
        "Product Status": "Active"
    },
    {
        "Root": "10005414",
        "LV": "1",
        "Item Description": "SUGAR SANDING ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8783",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005414",
        "Product Status": "Active"
    },
    {
        "Root": "10005571",
        "LV": "2",
        "Item Description": "PRETZEL MICKEY 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005571002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005571",
        "Product Status": "Active"
    },
    {
        "Root": "10107334",
        "LV": "1",
        "Item Description": "BEER SCHOFFERHOFER PRICKLY PEAR 50L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "61298",
        "Last Price Paid": "$174.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107334",
        "Product Status": "Active"
    },
    {
        "Root": "10018523",
        "LV": "1",
        "Item Description": "QUIN EASTER ASST. DELUXE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5809",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018523",
        "Product Status": "Active"
    },
    {
        "Root": "10100314",
        "LV": "1",
        "Item Description": "BEER PILSNER 81BAY CONGO 16OZ  24CT CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15358",
        "Last Price Paid": "$2.02",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100314",
        "Product Status": "Active"
    },
    {
        "Root": "10108431",
        "LV": "1",
        "Item Description": "BEER KEG TERRAPIN SURABAT 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$216.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108431",
        "Product Status": "Active"
    },
    {
        "Root": "10008109",
        "LV": "1",
        "Item Description": "SAUER PAUL KANOKOP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597826",
        "Last Price Paid": "$44.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008109",
        "Product Status": "Active"
    },
    {
        "Root": "10029417",
        "LV": "1",
        "Item Description": "SOUP CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7329199",
        "Last Price Paid": "$23.35",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029417",
        "Product Status": "Active"
    },
    {
        "Root": "10037111",
        "LV": "1",
        "Item Description": "FORGE CELLARS RIESLING 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "693543",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037111",
        "Product Status": "Active"
    },
    {
        "Root": "10037111",
        "LV": "1",
        "Item Description": "FORGE CELLARS RIESLING 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "693543",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037111",
        "Product Status": "Active"
    },
    {
        "Root": "10021517",
        "LV": "1",
        "Item Description": "SHELL EGG WHT STERILIZED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021517001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "588001",
        "Last Price Paid": "$1.33",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021517",
        "Product Status": "Active"
    },
    {
        "Root": "10083957",
        "LV": "1",
        "Item Description": "PULP AVOCADO 8/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450125",
        "Last Price Paid": "$22.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083957",
        "Product Status": "Active"
    },
    {
        "Root": "10002646",
        "LV": "2",
        "Item Description": "SPICE PEPPER WHITE GROUND 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002646002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002646",
        "Product Status": "Active"
    },
    {
        "Root": "10041316",
        "LV": "1",
        "Item Description": "SODA DIET MOUNTAIN DEW CANS 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9274218",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041316",
        "Product Status": "Active"
    },
    {
        "Root": "10006288",
        "LV": "1",
        "Item Description": "PICK BAMBOO W/KNOT 3.5 INCH 12/100EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006288",
        "Product Status": "Active"
    },
    {
        "Root": "10110635",
        "LV": "3",
        "Item Description": "CEREAL COCOA PUFFS BOWL 96/1.06OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110635003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10110635",
        "Product Status": "Active"
    },
    {
        "Root": "10100508",
        "LV": "1",
        "Item Description": "SAUCE CHS CHEDR RED SDM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3982782",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100508",
        "Product Status": "Active"
    },
    {
        "Root": "10038878",
        "LV": "1",
        "Item Description": "PUNNET WOOD SQ W/ BAKING PAPER 2.8X2.8X2.2 WA00013",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005115",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038878",
        "Product Status": "Active"
    },
    {
        "Root": "10041094",
        "LV": "1",
        "Item Description": "COOKIE EARTH DAY 36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041094001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041094",
        "Product Status": "Active"
    },
    {
        "Root": "10013343",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRUNET ITALY 6/8.8 OUNCE PIECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013343001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1735",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013343",
        "Product Status": "Active"
    },
    {
        "Root": "10010333",
        "LV": "1",
        "Item Description": "BEER CORSNDNK ABBY BRN ALE 11.2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "845316",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010333",
        "Product Status": "Active"
    },
    {
        "Root": "10044389",
        "LV": "1",
        "Item Description": "BAKLAVA SARAGLI 56/3.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5309779",
        "Last Price Paid": "$0.83",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044389",
        "Product Status": "Active"
    },
    {
        "Root": "10015045",
        "LV": "1",
        "Item Description": "SQUASH PATTY PAN LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "482237",
        "Last Price Paid": "$34.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015045",
        "Product Status": "Active"
    },
    {
        "Root": "10033723",
        "LV": "2",
        "Item Description": "NUT WALNUT HALVES & PIECES 4/2.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033723002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "183101",
        "Last Price Paid": "$26.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033723",
        "Product Status": "Active"
    },
    {
        "Root": "10015949",
        "LV": "1",
        "Item Description": "CHOC EGG HALF HOLLOW WHT 16 1/4\" X 11\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22233W",
        "Last Price Paid": "$20.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015949",
        "Product Status": "Active"
    },
    {
        "Root": "10004856",
        "LV": "1",
        "Item Description": "PASTA DRY ORZO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7319189",
        "Last Price Paid": "$1.67",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004856",
        "Product Status": "Active"
    },
    {
        "Root": "10000677",
        "LV": "1",
        "Item Description": "PORK CHEEKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000677001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000677",
        "Product Status": "Active"
    },
    {
        "Root": "10036384",
        "LV": "1",
        "Item Description": "GIN MARE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9668526",
        "Last Price Paid": "$31.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036384",
        "Product Status": "Active"
    },
    {
        "Root": "10001779",
        "LV": "1",
        "Item Description": "HERB PARSLEY CURLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450063",
        "Last Price Paid": "$0.50",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001779",
        "Product Status": "Active"
    },
    {
        "Root": "10001779",
        "LV": "1",
        "Item Description": "HERB PARSLEY CURLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001779",
        "Product Status": "Active"
    },
    {
        "Root": "10035393",
        "LV": "1",
        "Item Description": "MUSHROOM TRUFFLE ALBA WHITE FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "TRU001",
        "Last Price Paid": "$169.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035393",
        "Product Status": "Active"
    },
    {
        "Root": "10018688",
        "LV": "1",
        "Item Description": "CAVIAR PADDLEFISH 7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$146.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018688",
        "Product Status": "Active"
    },
    {
        "Root": "10005264",
        "LV": "1",
        "Item Description": "JAM INDV ASSORTED DIET 3/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9501354",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005264",
        "Product Status": "Active"
    },
    {
        "Root": "10001970",
        "LV": "1",
        "Item Description": "NASTURTIUM LEAVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001970001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MSLF-33-E",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001970",
        "Product Status": "Active"
    },
    {
        "Root": "10022042",
        "LV": "1",
        "Item Description": "SAUCE AIOLI SRIRACHA 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.13",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022042",
        "Product Status": "Active"
    },
    {
        "Root": "10002903",
        "LV": "1",
        "Item Description": "PICKLE RELISH SWEET 4/1GA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002903001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.43",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002903",
        "Product Status": "Active"
    },
    {
        "Root": "10103632",
        "LV": "1",
        "Item Description": "ALMOND EXTRACT BOYAJIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103632",
        "Product Status": "Active"
    },
    {
        "Root": "10003454",
        "LV": "1",
        "Item Description": "SPICE BBQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003454",
        "Product Status": "Active"
    },
    {
        "Root": "10025424",
        "LV": "1",
        "Item Description": "COLOR AIRBRUSH PREM. PEARL SHIMMER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12475",
        "Last Price Paid": "$9.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025424",
        "Product Status": "Active"
    },
    {
        "Root": "10009499",
        "LV": "2",
        "Item Description": "BOURBON TAYLOR SM BATCH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009499001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9184186",
        "Last Price Paid": "$33.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009499",
        "Product Status": "Active"
    },
    {
        "Root": "10004628",
        "LV": "1",
        "Item Description": "ONION PIECES FRENCH FRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2952778",
        "Last Price Paid": "$6.90",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004628",
        "Product Status": "Active"
    },
    {
        "Root": "10001452",
        "LV": "1",
        "Item Description": "ONIONS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406045",
        "Last Price Paid": "$34.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001452",
        "Product Status": "Active"
    },
    {
        "Root": "10001758",
        "LV": "1",
        "Item Description": "FIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001758",
        "Product Status": "Active"
    },
    {
        "Root": "10001758",
        "LV": "1",
        "Item Description": "FIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001758",
        "Product Status": "Active"
    },
    {
        "Root": "10001758",
        "LV": "1",
        "Item Description": "FIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390221",
        "Last Price Paid": "$51.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001758",
        "Product Status": "Active"
    },
    {
        "Root": "10005551",
        "LV": "1",
        "Item Description": "SB CROISSANT CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11083123",
        "Last Price Paid": "$1.05",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005551",
        "Product Status": "Active"
    },
    {
        "Root": "10107895",
        "LV": "1",
        "Item Description": "JUICE PINEAPPLE 14% PLASTIC BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107895001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1020845",
        "Last Price Paid": "$2.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107895",
        "Product Status": "Active"
    },
    {
        "Root": "10001560",
        "LV": "1",
        "Item Description": "PLANTAIN GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16051",
        "Last Price Paid": "$23.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001560",
        "Product Status": "Active"
    },
    {
        "Root": "10001560",
        "LV": "1",
        "Item Description": "PLANTAIN GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489952",
        "Last Price Paid": "$23.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001560",
        "Product Status": "Active"
    },
    {
        "Root": "10004672",
        "LV": "1",
        "Item Description": "CANDY TOPPING SPRINKLES RAINBOW 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582800",
        "Last Price Paid": "$20.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004672",
        "Product Status": "Active"
    },
    {
        "Root": "10009882",
        "LV": "1",
        "Item Description": "PORT DOW 30YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2313435",
        "Last Price Paid": "$108.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009882",
        "Product Status": "Active"
    },
    {
        "Root": "10000592",
        "LV": "1",
        "Item Description": "PORK BACON LAYOUT 18-22CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$46.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000592",
        "Product Status": "Active"
    },
    {
        "Root": "10108730",
        "LV": "2",
        "Item Description": "MIX THE BIG CHEESE SNACK BAG 24/2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108730002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203013",
        "Last Price Paid": "$0.89",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10108730",
        "Product Status": "Active"
    },
    {
        "Root": "10000106",
        "LV": "1",
        "Item Description": "BEEF SHORT RIB BONELESS CENTER CUT 10-12 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000106001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190058",
        "Last Price Paid": "$16.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000106",
        "Product Status": "Active"
    },
    {
        "Root": "10024637",
        "LV": "1",
        "Item Description": "CALIFORNIA PREMIUM MIXERS ISLAND PUNCH BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "448599",
        "Last Price Paid": "$51.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024637",
        "Product Status": "Active"
    },
    {
        "Root": "10024637",
        "LV": "1",
        "Item Description": "CALIFORNIA PREMIUM MIXERS ISLAND PUNCH BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "448599",
        "Last Price Paid": "$51.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024637",
        "Product Status": "Active"
    },
    {
        "Root": "10003689",
        "LV": "1",
        "Item Description": "JAM STRAWBERRY HERO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "H201..PASTRY 1",
        "Last Price Paid": "$150.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003689",
        "Product Status": "Active"
    },
    {
        "Root": "10000808",
        "LV": "4",
        "Item Description": "CHICKEN BREAST STRIP GRILLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000808004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.79",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10000808",
        "Product Status": "Active"
    },
    {
        "Root": "10010446",
        "LV": "1",
        "Item Description": "TOAD HOLLOW RISQUE BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "543014",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010446",
        "Product Status": "Active"
    },
    {
        "Root": "10005175",
        "LV": "1",
        "Item Description": "OIL SAFFLOWER 12/16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "925578",
        "Last Price Paid": "$10.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005175",
        "Product Status": "Active"
    },
    {
        "Root": "10010573",
        "LV": "2",
        "Item Description": "MOSCATO MOVENDO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010573002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "158314",
        "Last Price Paid": "$8.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10010573",
        "Product Status": "Active"
    },
    {
        "Root": "10008277",
        "LV": "1",
        "Item Description": "PINOT NOIR WILLIAMETT RES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9039771",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008277",
        "Product Status": "Active"
    },
    {
        "Root": "10014666",
        "LV": "1",
        "Item Description": "SHRIMP 21/25 PEELED AND DEVEINED TAIL OFF FRZ WHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014666001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125583",
        "Last Price Paid": "$5.58",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014666",
        "Product Status": "Active"
    },
    {
        "Root": "10029648",
        "LV": "1",
        "Item Description": "PINEAPPLE CUBES FROZEN CHEF READY 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "436675",
        "Last Price Paid": "$13.07",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029648",
        "Product Status": "Active"
    },
    {
        "Root": "10043187",
        "LV": "1",
        "Item Description": "BOX WITH WINDOW AND HANDLE 4X4X4 KRAFT PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51012997",
        "Last Price Paid": "$0.55",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043187",
        "Product Status": "Active"
    },
    {
        "Root": "10109608",
        "LV": "1",
        "Item Description": "WINE WHITE CHARD DUBOEUF OMBRE BETE MACON 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109608",
        "Product Status": "Active"
    },
    {
        "Root": "10005643",
        "LV": "1",
        "Item Description": "COOKIE CHOCOLATE CHIP 1 OZ PLUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005643001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "256",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005643",
        "Product Status": "Active"
    },
    {
        "Root": "10027703",
        "LV": "1",
        "Item Description": "POTATOES FINGERLING MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027703001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480099",
        "Last Price Paid": "$71.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027703",
        "Product Status": "Active"
    },
    {
        "Root": "10030074",
        "LV": "1",
        "Item Description": "APPLES ROCKIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030074001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480894",
        "Last Price Paid": "$2.95",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030074",
        "Product Status": "Active"
    },
    {
        "Root": "10003626",
        "LV": "1",
        "Item Description": "SB 5LB PIKE PLACE ROAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "190124",
        "Last Price Paid": "$45.55",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003626",
        "Product Status": "Active"
    },
    {
        "Root": "10000994",
        "LV": "2",
        "Item Description": "SALAD SEAWEED 4.4 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000994001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000994",
        "Product Status": "Active"
    },
    {
        "Root": "10000994",
        "LV": "2",
        "Item Description": "SALAD SEAWEED 4.4 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000994001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000445754",
        "Vendor": "OCEAN BEAUTY SEAFOODS LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000994",
        "Product Status": "Active"
    },
    {
        "Root": "10000994",
        "LV": "2",
        "Item Description": "SALAD SEAWEED 4.4 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000994001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000994",
        "Product Status": "Active"
    },
    {
        "Root": "10000994",
        "LV": "2",
        "Item Description": "SALAD SEAWEED 4.4 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000994001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6402825",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000994",
        "Product Status": "Active"
    },
    {
        "Root": "10000994",
        "LV": "2",
        "Item Description": "SALAD SEAWEED 4.4 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000994001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000994",
        "Product Status": "Active"
    },
    {
        "Root": "10000265",
        "LV": "1",
        "Item Description": "TOMATO GRAPE BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23038",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000265",
        "Product Status": "Active"
    },
    {
        "Root": "10000265",
        "LV": "1",
        "Item Description": "TOMATO GRAPE BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598019",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000265",
        "Product Status": "Active"
    },
    {
        "Root": "10008233",
        "LV": "2",
        "Item Description": "BLEND CARDINALE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008233001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "568518",
        "Last Price Paid": "$275.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008233",
        "Product Status": "Active"
    },
    {
        "Root": "10001326",
        "LV": "1",
        "Item Description": "POTATO TATER PUFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001326",
        "Product Status": "Active"
    },
    {
        "Root": "10004452",
        "LV": "1",
        "Item Description": "MIX FREESTYLE COKE ZERO 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4051597",
        "Last Price Paid": "$64.52",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004452",
        "Product Status": "Active"
    },
    {
        "Root": "10106575",
        "LV": "1",
        "Item Description": "COLOR GLITTER EDIBLE RAINBOW 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "403018B",
        "Last Price Paid": "$8.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106575",
        "Product Status": "Active"
    },
    {
        "Root": "10002839",
        "LV": "1",
        "Item Description": "SAUCE SOY PACKETS 6 ML 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002839001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002839",
        "Product Status": "Active"
    },
    {
        "Root": "10088424",
        "LV": "1",
        "Item Description": "COOKIE TRIPLE CHOCOLATE STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "TRICH",
        "Last Price Paid": "$0.95",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088424",
        "Product Status": "Active"
    },
    {
        "Root": "10001845",
        "LV": "1",
        "Item Description": "ONION VIDALIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001845001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001845",
        "Product Status": "Active"
    },
    {
        "Root": "10028311",
        "LV": "2",
        "Item Description": "MIX CAKE VANILLA GF 25 LB BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028311002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101513",
        "Last Price Paid": "$53.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028311",
        "Product Status": "Active"
    },
    {
        "Root": "10016033",
        "LV": "1",
        "Item Description": "BUTTER CLARIFIED UNSALTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5868435",
        "Last Price Paid": "$31.58",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016033",
        "Product Status": "Active"
    },
    {
        "Root": "10001822",
        "LV": "1",
        "Item Description": "MUSHROOM HEDGEHOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001822001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001822",
        "Product Status": "Active"
    },
    {
        "Root": "10001822",
        "LV": "1",
        "Item Description": "MUSHROOM HEDGEHOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001822001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405210",
        "Last Price Paid": "$21.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001822",
        "Product Status": "Active"
    },
    {
        "Root": "10001822",
        "LV": "1",
        "Item Description": "MUSHROOM HEDGEHOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001822001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50135",
        "Last Price Paid": "$21.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001822",
        "Product Status": "Active"
    },
    {
        "Root": "10081972",
        "LV": "1",
        "Item Description": "BOX BAKERY 9X9X4 KRAFT INSIDE WHITE OUTSIDE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51208012",
        "Last Price Paid": "$0.35",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081972",
        "Product Status": "Active"
    },
    {
        "Root": "10009210",
        "LV": "1",
        "Item Description": "CAB SAUV CAKEBREAD NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "246361",
        "Last Price Paid": "$60.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009210",
        "Product Status": "Active"
    },
    {
        "Root": "10006879",
        "LV": "1",
        "Item Description": "MOSCATO COPA CHALICE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006879",
        "Product Status": "Active"
    },
    {
        "Root": "10015357",
        "LV": "1",
        "Item Description": "DEC ROSE TEA MED RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKT RD",
        "Last Price Paid": "$1.80",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015357",
        "Product Status": "Active"
    },
    {
        "Root": "10008558",
        "LV": "1",
        "Item Description": "CAPSTONE REYNEKE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9237583",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008558",
        "Product Status": "Active"
    },
    {
        "Root": "10014404",
        "LV": "1",
        "Item Description": "VENISON SAUSAGE 4X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014404001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SAUS300",
        "Last Price Paid": "$10.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014404",
        "Product Status": "Active"
    },
    {
        "Root": "10017282",
        "LV": "1",
        "Item Description": "VODKA 44 NORTH HUCKLEBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "760041",
        "Last Price Paid": "$22.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017282",
        "Product Status": "Active"
    },
    {
        "Root": "10015340",
        "LV": "2",
        "Item Description": "FLOWER NASTURTIUM 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015340002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "005NSF-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015340",
        "Product Status": "Active"
    },
    {
        "Root": "10006834",
        "LV": "1",
        "Item Description": "CAKE CFF RASP BVRN 10 INCH SL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006834",
        "Product Status": "Active"
    },
    {
        "Root": "10002739",
        "LV": "1",
        "Item Description": "SYRUP CHERRY COKE 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$98.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002739",
        "Product Status": "Active"
    },
    {
        "Root": "10001263",
        "LV": "1",
        "Item Description": "POTATO REDSKIN DICED 5/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001263001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "303219",
        "Last Price Paid": "$15.18",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001263",
        "Product Status": "Active"
    },
    {
        "Root": "10003891",
        "LV": "1",
        "Item Description": "GARI NATURAL3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "16051",
        "Last Price Paid": "$49.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003891",
        "Product Status": "Active"
    },
    {
        "Root": "10034213",
        "LV": "1",
        "Item Description": "IMAGERY PINOT NOIR KEG 5.16 G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "902197",
        "Last Price Paid": "$250.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034213",
        "Product Status": "Active"
    },
    {
        "Root": "10089769",
        "LV": "1",
        "Item Description": "DEC MARCH HARE WHITE 1 1/2IN DISC EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089769001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW20463",
        "Last Price Paid": "$0.65",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089769",
        "Product Status": "Active"
    },
    {
        "Root": "10000323",
        "LV": "1",
        "Item Description": "POTATO YUKON GLD WHL PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480471",
        "Last Price Paid": "$40.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000323",
        "Product Status": "Active"
    },
    {
        "Root": "10000323",
        "LV": "1",
        "Item Description": "POTATO YUKON GLD WHL PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "POTYUKPL",
        "Last Price Paid": "$40.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000323",
        "Product Status": "Active"
    },
    {
        "Root": "10000323",
        "LV": "1",
        "Item Description": "POTATO YUKON GLD WHL PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "30-014",
        "Last Price Paid": "$40.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000323",
        "Product Status": "Active"
    },
    {
        "Root": "10004060",
        "LV": "1",
        "Item Description": "MASSA FONDANT TCNO TROPIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M6000",
        "Last Price Paid": "$84.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004060",
        "Product Status": "Active"
    },
    {
        "Root": "10000727",
        "LV": "1",
        "Item Description": "BEEFLESS TIPS GARDEIN 9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "084223400051",
        "Last Price Paid": "$3.95",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000727",
        "Product Status": "Active"
    },
    {
        "Root": "10004773",
        "LV": "1",
        "Item Description": "BARLEY PEARL 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3263142",
        "Last Price Paid": "$2.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004773",
        "Product Status": "Active"
    },
    {
        "Root": "10031068",
        "LV": "1",
        "Item Description": "SPARKLING COPPOLA SOFIA BRUT ROSE CAN 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "172630",
        "Last Price Paid": "$3.00",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031068",
        "Product Status": "Active"
    },
    {
        "Root": "10006298",
        "LV": "1",
        "Item Description": "LID FOR GELATO CONTAINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "LID475",
        "Last Price Paid": "$1.76",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006298",
        "Product Status": "Active"
    },
    {
        "Root": "10005562",
        "LV": "1",
        "Item Description": "COOKIE SUGAR PLUG 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "256",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005562",
        "Product Status": "Active"
    },
    {
        "Root": "10031226",
        "LV": "1",
        "Item Description": "HAM IBERICO BONELESS BELLOTA CINCO JOTAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031226001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "HAM050",
        "Last Price Paid": "$74.92",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031226",
        "Product Status": "Active"
    },
    {
        "Root": "10003779",
        "LV": "1",
        "Item Description": "BAR GRANOLA TRAIL MIX 7-G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4862272",
        "Last Price Paid": "$0.54",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003779",
        "Product Status": "Active"
    },
    {
        "Root": "10001545",
        "LV": "1",
        "Item Description": "FIG DRY BLACK MISSION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "303017",
        "Last Price Paid": "$46.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001545",
        "Product Status": "Active"
    },
    {
        "Root": "10005723",
        "LV": "1",
        "Item Description": "PASTRY DANISH GUAVA FAN 1.25Z RTB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005723001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061022",
        "Last Price Paid": "$0.56",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005723",
        "Product Status": "Active"
    },
    {
        "Root": "10008864",
        "LV": "2",
        "Item Description": "SYRAH CAB JUSTIN SAVANT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008864001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561465",
        "Last Price Paid": "$46.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008864",
        "Product Status": "Active"
    },
    {
        "Root": "10001313",
        "LV": "1",
        "Item Description": "CORN COB 3 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4328118",
        "Last Price Paid": "$0.21",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001313",
        "Product Status": "Active"
    },
    {
        "Root": "10001313",
        "LV": "1",
        "Item Description": "CORN COB 3 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001313",
        "Product Status": "Active"
    },
    {
        "Root": "10012352",
        "LV": "1",
        "Item Description": "COBIA FILLET SKIN OFF WILD SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012352001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3291640",
        "Last Price Paid": "$19.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012352",
        "Product Status": "Active"
    },
    {
        "Root": "10012352",
        "LV": "1",
        "Item Description": "COBIA FILLET SKIN OFF WILD SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012352001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00064",
        "Last Price Paid": "$19.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012352",
        "Product Status": "Active"
    },
    {
        "Root": "10108669",
        "LV": "2",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108669001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "9741-10",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10108669",
        "Product Status": "Active"
    },
    {
        "Root": "10109288",
        "LV": "1",
        "Item Description": "WHITE WINE CHENIN BLANC PROTEA 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "400232",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109288",
        "Product Status": "Active"
    },
    {
        "Root": "10041610",
        "LV": "1",
        "Item Description": "CREAMER HALF & HALF 10.5% BUTTERFAT DAIRY 12/1 QT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041610",
        "Product Status": "Active"
    },
    {
        "Root": "10006356",
        "LV": "1",
        "Item Description": "NAPKIN DINNER 15X17 2PLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8647091",
        "Last Price Paid": "",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006356",
        "Product Status": "Active"
    },
    {
        "Root": "10102344",
        "LV": "1",
        "Item Description": "WINE FATTORIA POGGIO ALLORO AMICO RISERVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "8033267180323",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102344",
        "Product Status": "Active"
    },
    {
        "Root": "10110368",
        "LV": "1",
        "Item Description": "CITRUS YUZU FRESH WHOLE S/O SSL 10# 4V&A ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410040",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110368",
        "Product Status": "Active"
    },
    {
        "Root": "10002370",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED GOAT HUMBOLDT FOG 4/16OZ WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002370001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002370",
        "Product Status": "Active"
    },
    {
        "Root": "10000827",
        "LV": "1",
        "Item Description": "CHICK CUT MARN 8 PC 2.25-2.5 #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000827001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000827",
        "Product Status": "Active"
    },
    {
        "Root": "10003437",
        "LV": "1",
        "Item Description": "SYRUP SHAKE CHOCOLATE SWIRL FB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003437",
        "Product Status": "Active"
    },
    {
        "Root": "10002899",
        "LV": "1",
        "Item Description": "TOMATO GROUND 7/11",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002899001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002899",
        "Product Status": "Active"
    },
    {
        "Root": "10032748",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON WATERMARK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9363382",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032748",
        "Product Status": "Active"
    },
    {
        "Root": "10036362",
        "LV": "1",
        "Item Description": "EGGS WHITE CHOC ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036362001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA836",
        "Last Price Paid": "$0.15",
        "Case Pack": "1220",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036362",
        "Product Status": "Active"
    },
    {
        "Root": "10004270",
        "LV": "1",
        "Item Description": "CEREAL RICE CHX GLTN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1666239",
        "Last Price Paid": "$1.04",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004270",
        "Product Status": "Active"
    },
    {
        "Root": "10004270",
        "LV": "1",
        "Item Description": "CEREAL RICE CHX GLTN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6960660",
        "Last Price Paid": "$1.04",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004270",
        "Product Status": "Active"
    },
    {
        "Root": "10007661",
        "LV": "1",
        "Item Description": "GRANACHA MONCAYO VERATON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007661001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "207936",
        "Last Price Paid": "$25.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007661",
        "Product Status": "Active"
    },
    {
        "Root": "10012463",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-LIGHT BLUE-2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22902",
        "Last Price Paid": "$0.68",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012463",
        "Product Status": "Active"
    },
    {
        "Root": "10004841",
        "LV": "1",
        "Item Description": "MUSTARD VIOLET 850 GR JAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "88-40080",
        "Last Price Paid": "$21.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004841",
        "Product Status": "Active"
    },
    {
        "Root": "10004841",
        "LV": "1",
        "Item Description": "MUSTARD VIOLET 850 GR JAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-40080",
        "Last Price Paid": "$21.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004841",
        "Product Status": "Active"
    },
    {
        "Root": "10001873",
        "LV": "1",
        "Item Description": "HERB MINT 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001873",
        "Product Status": "Active"
    },
    {
        "Root": "10001873",
        "LV": "1",
        "Item Description": "HERB MINT 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598235",
        "Last Price Paid": "$2.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001873",
        "Product Status": "Active"
    },
    {
        "Root": "10009460",
        "LV": "1",
        "Item Description": "SCOTCH J WALKER BLK 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.46",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009460",
        "Product Status": "Active"
    },
    {
        "Root": "10006449",
        "LV": "1",
        "Item Description": "BAG PASTRY DISPOSABLE 21I",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "KS152112",
        "Last Price Paid": "$0.19",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006449",
        "Product Status": "Active"
    },
    {
        "Root": "10075016",
        "LV": "1",
        "Item Description": "CHILI NO BEANS RTU 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10075016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "7655715",
        "Last Price Paid": "$14.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10075016",
        "Product Status": "Active"
    },
    {
        "Root": "10075016",
        "LV": "1",
        "Item Description": "CHILI NO BEANS RTU 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10075016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7655715",
        "Last Price Paid": "$14.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10075016",
        "Product Status": "Active"
    },
    {
        "Root": "10006291",
        "LV": "2",
        "Item Description": "MUG SOUV ROSE AND CROWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006291002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000003406",
        "Vendor": "EDWARD DON & CO",
        "Vendor Item Nb": "1219366",
        "Last Price Paid": "$67.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006291",
        "Product Status": "Active"
    },
    {
        "Root": "10027697",
        "LV": "1",
        "Item Description": "CHURRO SLEEVE STAR WARS 1X1X8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027697001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45855015",
        "Last Price Paid": "$0.60",
        "Case Pack": "2125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027697",
        "Product Status": "Active"
    },
    {
        "Root": "10000721",
        "LV": "1",
        "Item Description": "BEEF PATTY KID BURGR 2.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000721001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1072019",
        "Last Price Paid": "$3.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000721",
        "Product Status": "Active"
    },
    {
        "Root": "10001349",
        "LV": "1",
        "Item Description": "MARINADE SAG DAHL 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001349",
        "Product Status": "Active"
    },
    {
        "Root": "10001078",
        "LV": "1",
        "Item Description": "LOBSTER FRSH ME 1.5-1.75 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001078001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "2594877",
        "Last Price Paid": "$11.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001078",
        "Product Status": "Active"
    },
    {
        "Root": "10001078",
        "LV": "1",
        "Item Description": "LOBSTER FRSH ME 1.5-1.75 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001078001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "69-50150",
        "Last Price Paid": "$11.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001078",
        "Product Status": "Active"
    },
    {
        "Root": "10039961",
        "LV": "1",
        "Item Description": "GELATO CHERRY VANILLA SWIRL 5Z CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-AMAR-02-CS24",
        "Last Price Paid": "$1.38",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039961",
        "Product Status": "Active"
    },
    {
        "Root": "10106570",
        "LV": "1",
        "Item Description": "POPCORN OIL COCONUT & CANOLA BLEND 35LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106570",
        "Product Status": "Active"
    },
    {
        "Root": "10001564",
        "LV": "1",
        "Item Description": "POTATO SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001564001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489951",
        "Last Price Paid": "$6.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001564",
        "Product Status": "Active"
    },
    {
        "Root": "10025956",
        "LV": "1",
        "Item Description": "DUST SILVER NON EDIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ105050",
        "Last Price Paid": "$14.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025956",
        "Product Status": "Active"
    },
    {
        "Root": "10001446",
        "LV": "1",
        "Item Description": "ASPARAGUS LRGE GREEN 11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "431127",
        "Last Price Paid": "$31.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001446",
        "Product Status": "Active"
    },
    {
        "Root": "10001446",
        "LV": "1",
        "Item Description": "ASPARAGUS LRGE GREEN 11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ASP11L",
        "Last Price Paid": "$31.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001446",
        "Product Status": "Active"
    },
    {
        "Root": "10001446",
        "LV": "1",
        "Item Description": "ASPARAGUS LRGE GREEN 11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3305117",
        "Last Price Paid": "$31.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001446",
        "Product Status": "Active"
    },
    {
        "Root": "10001446",
        "LV": "1",
        "Item Description": "ASPARAGUS LRGE GREEN 11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20016",
        "Last Price Paid": "$31.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001446",
        "Product Status": "Active"
    },
    {
        "Root": "10095610",
        "LV": "1",
        "Item Description": "TEA CONCENTRATE REPUB OF TEA GREEN PASSIONPAPAYA 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095610",
        "Product Status": "Active"
    },
    {
        "Root": "10095610",
        "LV": "1",
        "Item Description": "TEA CONCENTRATE REPUB OF TEA GREEN PASSIONPAPAYA 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5290658",
        "Last Price Paid": "$27.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095610",
        "Product Status": "Active"
    },
    {
        "Root": "10020071",
        "LV": "2",
        "Item Description": "COOKIE XMAS CONTESSA MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020071002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BA002",
        "Last Price Paid": "$0.46",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10020071",
        "Product Status": "Active"
    },
    {
        "Root": "10009853",
        "LV": "2",
        "Item Description": "BRANDY GRAPPA BANFI CASTELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009853001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346301",
        "Last Price Paid": "$36.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009853",
        "Product Status": "Active"
    },
    {
        "Root": "10004431",
        "LV": "1",
        "Item Description": "SUGAR SUB ONE BY ONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "43066",
        "Last Price Paid": "$121.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004431",
        "Product Status": "Active"
    },
    {
        "Root": "10010391",
        "LV": "2",
        "Item Description": "BEER LF HND MILK STOUT NITRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010391001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2902",
        "Last Price Paid": "$1.80",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010391",
        "Product Status": "Active"
    },
    {
        "Root": "10083673",
        "LV": "1",
        "Item Description": "CHEESE BLUE DAIRY-FREE CRUMBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "39006",
        "Last Price Paid": "$3.67",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083673",
        "Product Status": "Active"
    },
    {
        "Root": "10006364",
        "LV": "1",
        "Item Description": "TOOTHPICK FRILLED 4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9855776",
        "Last Price Paid": "$0.00",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006364",
        "Product Status": "Active"
    },
    {
        "Root": "10012855",
        "LV": "1",
        "Item Description": "PINOT NOIR GAIERHOF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "595298",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012855",
        "Product Status": "Active"
    },
    {
        "Root": "10013210",
        "LV": "1",
        "Item Description": "NEBBIOLO CHIOSSO GATINARA PIEDMONTE 750 ML70352535",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558129",
        "Last Price Paid": "$26.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013210",
        "Product Status": "Active"
    },
    {
        "Root": "10094331",
        "LV": "1",
        "Item Description": "UMBRELLA HARD CANDY ON STRAW (BLUE WITH BLACK FROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4966649",
        "Last Price Paid": "$1.01",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094331",
        "Product Status": "Active"
    },
    {
        "Root": "10036365",
        "LV": "1",
        "Item Description": "CHEF MICKEY WHITE CHOC 1.2 RD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I23420",
        "Last Price Paid": "$0.10",
        "Case Pack": "1260",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036365",
        "Product Status": "Active"
    },
    {
        "Root": "10041422",
        "LV": "1",
        "Item Description": "TEQUILA CINCORO ANEJO 750ML 6PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041422001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9382940",
        "Last Price Paid": "$96.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041422",
        "Product Status": "Active"
    },
    {
        "Root": "10002632",
        "LV": "2",
        "Item Description": "SPICE SESAME SEED 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002632002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.31",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002632",
        "Product Status": "Active"
    },
    {
        "Root": "10039809",
        "LV": "1",
        "Item Description": "CHOC ROSE GOLD LUSTRE DUST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ROSEGOLD",
        "Last Price Paid": "$7.57",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039809",
        "Product Status": "Active"
    },
    {
        "Root": "10000394",
        "LV": "1",
        "Item Description": "MIX ROOT VEG 1/2 INCH DICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "511612-A",
        "Last Price Paid": "$12.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000394",
        "Product Status": "Active"
    },
    {
        "Root": "10000394",
        "LV": "1",
        "Item Description": "MIX ROOT VEG 1/2 INCH DICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511612",
        "Last Price Paid": "$12.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000394",
        "Product Status": "Active"
    },
    {
        "Root": "10011533",
        "LV": "1",
        "Item Description": "SB LIDS HOT 16-20 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011533",
        "Product Status": "Active"
    },
    {
        "Root": "10011533",
        "LV": "1",
        "Item Description": "SB LIDS HOT 16-20 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011533",
        "Product Status": "Active"
    },
    {
        "Root": "10018516",
        "LV": "1",
        "Item Description": "ALBARINO LA BURGANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "960647",
        "Last Price Paid": "$13.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018516",
        "Product Status": "Active"
    },
    {
        "Root": "10044411",
        "LV": "1",
        "Item Description": "BEER ESTRELLA 1906 RESERVA 11.2OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "267",
        "Last Price Paid": "$33.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044411",
        "Product Status": "Active"
    },
    {
        "Root": "10100833",
        "LV": "2",
        "Item Description": "CUP DISNEY ACR12 12OZ PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100833002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$78.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100833",
        "Product Status": "Active"
    },
    {
        "Root": "10037242",
        "LV": "1",
        "Item Description": "WATER DASANI DELOS 20 OZ STARWARS GALAXY EDGE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037242001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.66",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037242",
        "Product Status": "Active"
    },
    {
        "Root": "10036272",
        "LV": "1",
        "Item Description": "WINE WHITE MACABEO VINA BORGIA BAG-IN-BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "524088",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036272",
        "Product Status": "Active"
    },
    {
        "Root": "10036272",
        "LV": "1",
        "Item Description": "WINE WHITE MACABEO VINA BORGIA BAG-IN-BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "524088",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036272",
        "Product Status": "Active"
    },
    {
        "Root": "10000360",
        "LV": "1",
        "Item Description": "PEPPERS GREEN DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "07-001-12",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000360",
        "Product Status": "Active"
    },
    {
        "Root": "10000209",
        "LV": "1",
        "Item Description": "MUSHROOM BUTTON US#1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "26005",
        "Last Price Paid": "$26.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000209",
        "Product Status": "Active"
    },
    {
        "Root": "10000209",
        "LV": "1",
        "Item Description": "MUSHROOM BUTTON US#1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405040",
        "Last Price Paid": "$26.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000209",
        "Product Status": "Active"
    },
    {
        "Root": "10036205",
        "LV": "1",
        "Item Description": "COCOA BUTTER LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036205001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302099C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036205",
        "Product Status": "Active"
    },
    {
        "Root": "10002241",
        "LV": "1",
        "Item Description": "ICE CREAM VANILLA NSA 12 / 6 OZ CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002241001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "FC-016-016",
        "Last Price Paid": "$1.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002241",
        "Product Status": "Active"
    },
    {
        "Root": "10035018",
        "LV": "1",
        "Item Description": "LID TAKE AND GO ASD626-4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035018001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51262012",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035018",
        "Product Status": "Active"
    },
    {
        "Root": "10010180",
        "LV": "1",
        "Item Description": "WATER EVIAN 750ML GLS 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.87",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010180",
        "Product Status": "Active"
    },
    {
        "Root": "10001918",
        "LV": "1",
        "Item Description": "ONION RED PEARL PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "27030",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001918",
        "Product Status": "Active"
    },
    {
        "Root": "10001918",
        "LV": "1",
        "Item Description": "ONION RED PEARL PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482254",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001918",
        "Product Status": "Active"
    },
    {
        "Root": "10000279",
        "LV": "1",
        "Item Description": "AVOCADO 6 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490143",
        "Last Price Paid": "$3.46",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000279",
        "Product Status": "Active"
    },
    {
        "Root": "10007368",
        "LV": "1",
        "Item Description": "CANNONAU SANTADI NORAS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "549041",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007368",
        "Product Status": "Active"
    },
    {
        "Root": "10036057",
        "LV": "1",
        "Item Description": "CHATEAUNEUF DU PAPE DOM LUCIEN RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "970710",
        "Last Price Paid": "$26.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036057",
        "Product Status": "Active"
    },
    {
        "Root": "10093942",
        "LV": "1",
        "Item Description": "CHOC WHITE GRAPEFRUIT ROUND 7CM, 100PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6235050000",
        "Last Price Paid": "$1.55",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093942",
        "Product Status": "Active"
    },
    {
        "Root": "10011824",
        "LV": "2",
        "Item Description": "PORK TENDERLOIN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011824001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.70",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10011824",
        "Product Status": "Active"
    },
    {
        "Root": "10011824",
        "LV": "2",
        "Item Description": "PORK TENDERLOIN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011824001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.70",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10011824",
        "Product Status": "Active"
    },
    {
        "Root": "10009835",
        "LV": "1",
        "Item Description": "COGNAC HENNESSY VS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009835001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009835",
        "Product Status": "Active"
    },
    {
        "Root": "10021774",
        "LV": "1",
        "Item Description": "CUP PORTION 1 OZ WHT PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "867382",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021774",
        "Product Status": "Active"
    },
    {
        "Root": "10021774",
        "LV": "1",
        "Item Description": "CUP PORTION 1 OZ WHT PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11064204",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021774",
        "Product Status": "Active"
    },
    {
        "Root": "10000205",
        "LV": "1",
        "Item Description": "CARROTEENIES 1.6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000205001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482017",
        "Last Price Paid": "$0.15",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000205",
        "Product Status": "Active"
    },
    {
        "Root": "10012705",
        "LV": "1",
        "Item Description": "SORREL MIXED LUCKY 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012705001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MXLS-33",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012705",
        "Product Status": "Active"
    },
    {
        "Root": "10007835",
        "LV": "1",
        "Item Description": "PINOT NOIR MICHAEL POZZAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007835001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "609725",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007835",
        "Product Status": "Active"
    },
    {
        "Root": "10012040",
        "LV": "3",
        "Item Description": "SHRIMP 21/25 PEELED DEVEINED RAW TAIL ON IQF SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012040003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125601",
        "Last Price Paid": "$4.75",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10012040",
        "Product Status": "Active"
    },
    {
        "Root": "10006874",
        "LV": "1",
        "Item Description": "WHITE ZINFANDEL COPA CHALICE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006874",
        "Product Status": "Active"
    },
    {
        "Root": "10010422",
        "LV": "1",
        "Item Description": "BEER REDBRIDGE 12OZ LNNR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010422001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010422",
        "Product Status": "Active"
    },
    {
        "Root": "10001690",
        "LV": "1",
        "Item Description": "GRAPE RED SEEDLESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "14003",
        "Last Price Paid": "$50.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001690",
        "Product Status": "Active"
    },
    {
        "Root": "10001690",
        "LV": "1",
        "Item Description": "GRAPE RED SEEDLESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260305",
        "Last Price Paid": "$50.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001690",
        "Product Status": "Active"
    },
    {
        "Root": "10005569",
        "LV": "1",
        "Item Description": "CHURRO FRIED 16 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005569",
        "Product Status": "Active"
    },
    {
        "Root": "10103730",
        "LV": "1",
        "Item Description": "TRAY KRAFT 0598 LUNCH SUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11009081",
        "Last Price Paid": "$0.12",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103730",
        "Product Status": "Active"
    },
    {
        "Root": "10104309",
        "LV": "1",
        "Item Description": "BREAKFAST EMPANADA 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3007113",
        "Last Price Paid": "$2.12",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104309",
        "Product Status": "Active"
    },
    {
        "Root": "10017351",
        "LV": "1",
        "Item Description": "GLOVE VINYL POW FREE MED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.40",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017351",
        "Product Status": "Active"
    },
    {
        "Root": "10001008",
        "LV": "1",
        "Item Description": "SWORDFISH LOIN FRESH BNLS SKNLS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001008001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00292",
        "Last Price Paid": "$10.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001008",
        "Product Status": "Active"
    },
    {
        "Root": "10001008",
        "LV": "1",
        "Item Description": "SWORDFISH LOIN FRESH BNLS SKNLS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001008001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "5030850",
        "Last Price Paid": "$10.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001008",
        "Product Status": "Active"
    },
    {
        "Root": "10001008",
        "LV": "1",
        "Item Description": "SWORDFISH LOIN FRESH BNLS SKNLS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001008001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "1461",
        "Last Price Paid": "$10.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001008",
        "Product Status": "Active"
    },
    {
        "Root": "10001008",
        "LV": "1",
        "Item Description": "SWORDFISH LOIN FRESH BNLS SKNLS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001008001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000445754",
        "Vendor": "OCEAN BEAUTY SEAFOODS LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001008",
        "Product Status": "Active"
    },
    {
        "Root": "10001008",
        "LV": "1",
        "Item Description": "SWORDFISH LOIN FRESH BNLS SKNLS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001008001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001008",
        "Product Status": "Active"
    },
    {
        "Root": "10002663",
        "LV": "1",
        "Item Description": "BASE TURKEY NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002663",
        "Product Status": "Active"
    },
    {
        "Root": "10002663",
        "LV": "1",
        "Item Description": "BASE TURKEY NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9030917",
        "Last Price Paid": "$5.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002663",
        "Product Status": "Active"
    },
    {
        "Root": "10018937",
        "LV": "1",
        "Item Description": "MOSCATO COPPO MONCALVINO MOSCATO D'ASTI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "83311",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018937",
        "Product Status": "Active"
    },
    {
        "Root": "10027574",
        "LV": "1",
        "Item Description": "TEA BERRY FUSION 120 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13722",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027574",
        "Product Status": "Active"
    },
    {
        "Root": "10038456",
        "LV": "1",
        "Item Description": "WOODEN SPOON GANACHERY 5.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001525",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038456",
        "Product Status": "Active"
    },
    {
        "Root": "10008565",
        "LV": "2",
        "Item Description": "WINE RED ZINFANDEL HARTFORD COURT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008565001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "540259",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008565",
        "Product Status": "Active"
    },
    {
        "Root": "10102633",
        "LV": "1",
        "Item Description": "KOSHER BSC OMELET CHEESE 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102633",
        "Product Status": "Active"
    },
    {
        "Root": "10003864",
        "LV": "1",
        "Item Description": "VINEGAR WHITE WINE 1/LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VI075",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003864",
        "Product Status": "Active"
    },
    {
        "Root": "10108680",
        "LV": "2",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108680001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10108680",
        "Product Status": "Active"
    },
    {
        "Root": "10035996",
        "LV": "1",
        "Item Description": "CANDY TOPPING PEANUT BUTTER CUP REESES CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "525220",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035996",
        "Product Status": "Active"
    },
    {
        "Root": "10095636",
        "LV": "1",
        "Item Description": "WINE WHITE CHARDONNAY ROSEROCK 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "962973",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095636",
        "Product Status": "Active"
    },
    {
        "Root": "10019626",
        "LV": "1",
        "Item Description": "SPICE RUB ORANGE NTN BUSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7860349",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019626",
        "Product Status": "Active"
    },
    {
        "Root": "10001807",
        "LV": "1",
        "Item Description": "RAISINS ON THE VINE 10/4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001807",
        "Product Status": "Active"
    },
    {
        "Root": "10109332",
        "LV": "1",
        "Item Description": "TURKEY HOAGIE BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073720",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109332",
        "Product Status": "Active"
    },
    {
        "Root": "10024250",
        "LV": "1",
        "Item Description": "DRAFT KONA SEASONAL 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28306",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024250",
        "Product Status": "Active"
    },
    {
        "Root": "10034124",
        "LV": "1",
        "Item Description": "PIZZA BOX CLAM 0726 6.5X6.5X1.750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51009075",
        "Last Price Paid": "",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034124",
        "Product Status": "Active"
    },
    {
        "Root": "10109304",
        "LV": "1",
        "Item Description": "PIPETTE CLEAR HEART SHAPED 4ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11001904",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109304",
        "Product Status": "Active"
    },
    {
        "Root": "10086794",
        "LV": "1",
        "Item Description": "SHRIMP BREADED COCONUT RAW 16-20 COUNT 4/3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086794",
        "Product Status": "Active"
    },
    {
        "Root": "10005831",
        "LV": "1",
        "Item Description": "MUFFIN ZUCCHINI CARROT PETITE 1 OUNCE 12 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005831001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005831",
        "Product Status": "Active"
    },
    {
        "Root": "GENE002",
        "LV": "1",
        "Item Description": "GENERIC ARTICLE BEARING VARIANCES ON ARTICLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "GENE002001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.00",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "GENE002",
        "Product Status": "Active"
    },
    {
        "Root": "10028645",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT LARGE 3.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028645",
        "Product Status": "Active"
    },
    {
        "Root": "10110133",
        "LV": "1",
        "Item Description": "BAG CRYSTAL CLEAR NO FLAP 6-7/16\" X 8-1/4\" B86NF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51090086",
        "Last Price Paid": "$0.08",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110133",
        "Product Status": "Active"
    },
    {
        "Root": "10002056",
        "LV": "1",
        "Item Description": "APPLE RED DEL 88 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "APPRD88",
        "Last Price Paid": "$0.42",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002056",
        "Product Status": "Active"
    },
    {
        "Root": "10002056",
        "LV": "1",
        "Item Description": "APPLE RED DEL 88 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "111088",
        "Last Price Paid": "$0.42",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002056",
        "Product Status": "Active"
    },
    {
        "Root": "10109469",
        "LV": "1",
        "Item Description": "WINE FAIVELEY GEV CHAMB VV 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9680311",
        "Last Price Paid": "$63.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109469",
        "Product Status": "Active"
    },
    {
        "Root": "10004580",
        "LV": "2",
        "Item Description": "MIX MALTED MILK 2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004580002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2518983",
        "Last Price Paid": "$11.63",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004580",
        "Product Status": "Active"
    },
    {
        "Root": "10014286",
        "LV": "1",
        "Item Description": "WATER BTL SAN PELLEGRINO SPARKLING GLS 16.9OZ/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5280185",
        "Last Price Paid": "$1.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014286",
        "Product Status": "Active"
    },
    {
        "Root": "10002598",
        "LV": "1",
        "Item Description": "APPLE GRANNY SMITH 72CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "11010",
        "Last Price Paid": "$46.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002598",
        "Product Status": "Active"
    },
    {
        "Root": "10002598",
        "LV": "1",
        "Item Description": "APPLE GRANNY SMITH 72CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "122080",
        "Last Price Paid": "$46.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002598",
        "Product Status": "Active"
    },
    {
        "Root": "10039525",
        "LV": "1",
        "Item Description": "SYRUP FLAVOR RAGE VANILLA 2 1/2 GAL BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039525",
        "Product Status": "Active"
    },
    {
        "Root": "10008232",
        "LV": "1",
        "Item Description": "MERLOT PRIDE MOUNTAIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008232001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9681463",
        "Last Price Paid": "$28.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008232",
        "Product Status": "Active"
    },
    {
        "Root": "10102829",
        "LV": "1",
        "Item Description": "CHEESE TRUFFLE CORDERO SPANISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102829001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "338020",
        "Last Price Paid": "$13.99",
        "Case Pack": "6.6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102829",
        "Product Status": "Active"
    },
    {
        "Root": "10008940",
        "LV": "3",
        "Item Description": "WINE RED CAB SAUV ANAKOTA KNIGHTS VALLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008940002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "545319",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008940",
        "Product Status": "Active"
    },
    {
        "Root": "10032528",
        "LV": "2",
        "Item Description": "EXTRACT PURE ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032528002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "336870",
        "Last Price Paid": "$24.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032528",
        "Product Status": "Active"
    },
    {
        "Root": "10005312",
        "LV": "1",
        "Item Description": "PUREE KIWI 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "669...{BOIRON{",
        "Last Price Paid": "$11.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005312",
        "Product Status": "Active"
    },
    {
        "Root": "10087186",
        "LV": "1",
        "Item Description": "SALSA SS CUP CHUNKY MEDIUM SHELF STABLE 30/3.8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5317437",
        "Last Price Paid": "$0.67",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087186",
        "Product Status": "Active"
    },
    {
        "Root": "10002016",
        "LV": "1",
        "Item Description": "GRAPEFRUIT RED 32/36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$37.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002016",
        "Product Status": "Active"
    },
    {
        "Root": "10002016",
        "LV": "1",
        "Item Description": "GRAPEFRUIT RED 32/36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "200175",
        "Last Price Paid": "$37.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002016",
        "Product Status": "Active"
    },
    {
        "Root": "10084623",
        "LV": "1",
        "Item Description": "WINE WHITE JACQUES DUMONT SANCERRE 750ML/6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "317955",
        "Last Price Paid": "$23.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084623",
        "Product Status": "Active"
    },
    {
        "Root": "10075092",
        "LV": "1",
        "Item Description": "DECOR CHOC MILK 50TH ANNIV OVAL 3.3X4.5 CM 250PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10075092001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "624370000",
        "Last Price Paid": "$0.25",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10075092",
        "Product Status": "Active"
    },
    {
        "Root": "10001144",
        "LV": "1",
        "Item Description": "CAVIAR TOBIKO GOLD 1.1 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "17563",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001144",
        "Product Status": "Active"
    },
    {
        "Root": "10008811",
        "LV": "2",
        "Item Description": "SAUV BLANC BAYTEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008811001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "334965",
        "Last Price Paid": "$12.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008811",
        "Product Status": "Active"
    },
    {
        "Root": "10016160",
        "LV": "1",
        "Item Description": "SPICE SEASONING JERK MLD WALKERSWOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "934121",
        "Last Price Paid": "$38.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016160",
        "Product Status": "Active"
    },
    {
        "Root": "10001431",
        "LV": "1",
        "Item Description": "CARROT RAINBW OBLIQUE 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481862",
        "Last Price Paid": "$65.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001431",
        "Product Status": "Active"
    },
    {
        "Root": "10001431",
        "LV": "1",
        "Item Description": "CARROT RAINBW OBLIQUE 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30740",
        "Last Price Paid": "$65.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001431",
        "Product Status": "Active"
    },
    {
        "Root": "10016932",
        "LV": "1",
        "Item Description": "BOX BAKERY AUTO WHITE 8X8X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51377038",
        "Last Price Paid": "$0.47",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016932",
        "Product Status": "Active"
    },
    {
        "Root": "10007768",
        "LV": "2",
        "Item Description": "SAUV BLC SILVERADO 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007768001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "586915",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007768",
        "Product Status": "Active"
    },
    {
        "Root": "10007314",
        "LV": "1",
        "Item Description": "JH SELBACH DORNFELDER QBA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16172",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007314",
        "Product Status": "Active"
    },
    {
        "Root": "10016327",
        "LV": "1",
        "Item Description": "CUPCAKE LG RD VEL UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1637",
        "Last Price Paid": "$0.53",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016327",
        "Product Status": "Active"
    },
    {
        "Root": "10105089",
        "LV": "1",
        "Item Description": "CARTON FOOD #2 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105089",
        "Product Status": "Active"
    },
    {
        "Root": "10005444",
        "LV": "1",
        "Item Description": "SHEET TRSFR YACHT & BEACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.75",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005444",
        "Product Status": "Active"
    },
    {
        "Root": "10033434",
        "LV": "1",
        "Item Description": "GOLD SWIRL PLATE 235130 #12 4-3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11408050",
        "Last Price Paid": "$0.19",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033434",
        "Product Status": "Active"
    },
    {
        "Root": "10012982",
        "LV": "1",
        "Item Description": "LID DOME 80Z CLEAR APB80DM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11178123",
        "Last Price Paid": "$0.63",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012982",
        "Product Status": "Active"
    },
    {
        "Root": "10014283",
        "LV": "1",
        "Item Description": "WATER PANNA LTR GLS SPRING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014283001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3284189",
        "Last Price Paid": "$2.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014283",
        "Product Status": "Active"
    },
    {
        "Root": "10026978",
        "LV": "1",
        "Item Description": "COFFEE FRENCH ROAST REGULAR BEAN 2# FOIL BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "C130B-30B",
        "Last Price Paid": "$10.92",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026978",
        "Product Status": "Active"
    },
    {
        "Root": "10026978",
        "LV": "1",
        "Item Description": "COFFEE FRENCH ROAST REGULAR BEAN 2# FOIL BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.92",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026978",
        "Product Status": "Active"
    },
    {
        "Root": "10038361",
        "LV": "1",
        "Item Description": "SUGAR COCONUT 5LB BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY919",
        "Last Price Paid": "$60.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038361",
        "Product Status": "Active"
    },
    {
        "Root": "10040216",
        "LV": "1",
        "Item Description": "PORK PRIME RIB RACK 10BN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040216001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1455754",
        "Last Price Paid": "$7.33",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040216",
        "Product Status": "Active"
    },
    {
        "Root": "10028866",
        "LV": "1",
        "Item Description": "SYRUP MONIN ORANGE TANGERINE 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028866",
        "Product Status": "Active"
    },
    {
        "Root": "10003516",
        "LV": "1",
        "Item Description": "SYRUP MONIN BLOOD ORG LTR COFFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003516",
        "Product Status": "Active"
    },
    {
        "Root": "10025075",
        "LV": "1",
        "Item Description": "BITTER CARDAMOM FEE BROS 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025075001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "202106",
        "Last Price Paid": "$6.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025075",
        "Product Status": "Active"
    },
    {
        "Root": "10036747",
        "LV": "1",
        "Item Description": "TOLEDO OIL COCONUT ORGANIC 1/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "10230117",
        "Last Price Paid": "$58.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036747",
        "Product Status": "Active"
    },
    {
        "Root": "10003129",
        "LV": "1",
        "Item Description": "COFFEE JOFF HAW KON BLND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003129",
        "Product Status": "Active"
    },
    {
        "Root": "10007057",
        "LV": "1",
        "Item Description": "MERITAGE STERLING 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9081259",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007057",
        "Product Status": "Active"
    },
    {
        "Root": "10006379",
        "LV": "1",
        "Item Description": "BAG PAPER 10# WHITE 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006379001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "440082",
        "Last Price Paid": "$0.06",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006379",
        "Product Status": "Active"
    },
    {
        "Root": "10009170",
        "LV": "2",
        "Item Description": "CAB SAUV BOEKENHOUTSKLOOF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009170001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9406677",
        "Last Price Paid": "$28.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009170",
        "Product Status": "Active"
    },
    {
        "Root": "10004654",
        "LV": "1",
        "Item Description": "NUT MACADAMIA RAW WHOLE 25#/C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004654001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "181100",
        "Last Price Paid": "$356.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004654",
        "Product Status": "Active"
    },
    {
        "Root": "10002720",
        "LV": "1",
        "Item Description": "CEREAL CORN FLAKES BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002720001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002720",
        "Product Status": "Active"
    },
    {
        "Root": "10000325",
        "LV": "1",
        "Item Description": "TOMATO DICED FC WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481934",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000325",
        "Product Status": "Active"
    },
    {
        "Root": "10000325",
        "LV": "1",
        "Item Description": "TOMATO DICED FC WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "21-003-38-A",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000325",
        "Product Status": "Active"
    },
    {
        "Root": "10000325",
        "LV": "1",
        "Item Description": "TOMATO DICED FC WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23012",
        "Last Price Paid": "$10.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000325",
        "Product Status": "Active"
    },
    {
        "Root": "10034398",
        "LV": "1",
        "Item Description": "RUM REAL MCCOY 5YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "371857",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034398",
        "Product Status": "Active"
    },
    {
        "Root": "10005084",
        "LV": "1",
        "Item Description": "SYRUP SNO CONE STRAW 4/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1227H",
        "Last Price Paid": "$10.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005084",
        "Product Status": "Active"
    },
    {
        "Root": "10001292",
        "LV": "1",
        "Item Description": "MIX MM SMOOTHIE NONFAT YOGURT 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001292001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500008038",
        "Last Price Paid": "$6.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001292",
        "Product Status": "Active"
    },
    {
        "Root": "10104851",
        "LV": "2",
        "Item Description": "CUP COLD 12OZ SWGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104851002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$92.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104851",
        "Product Status": "Active"
    },
    {
        "Root": "10040620",
        "LV": "1",
        "Item Description": "BREAD BUN HOT DOG BLOOMFIELD ALLERGEN FREE 36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "14444",
        "Last Price Paid": "$0.83",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040620",
        "Product Status": "Active"
    },
    {
        "Root": "10036701",
        "LV": "1",
        "Item Description": "BUNNY IN TOP HAT & BOW LOLLIPOP 2 7/8\"X 4 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036701",
        "Product Status": "Active"
    },
    {
        "Root": "10000339",
        "LV": "1",
        "Item Description": "LETTUCE ROMAINE FILLETS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592028",
        "Last Price Paid": "$30.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000339",
        "Product Status": "Active"
    },
    {
        "Root": "10000339",
        "LV": "1",
        "Item Description": "LETTUCE ROMAINE FILLETS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETROMFL",
        "Last Price Paid": "$30.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000339",
        "Product Status": "Active"
    },
    {
        "Root": "10004243",
        "LV": "1",
        "Item Description": "QUINOA TRI COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004243001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "163441",
        "Last Price Paid": "$30.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004243",
        "Product Status": "Active"
    },
    {
        "Root": "10003758",
        "LV": "1",
        "Item Description": "DECOR CHOC MICKEY FACE SMALL 1008CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "1008 PER CASE",
        "Last Price Paid": "$0.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003758",
        "Product Status": "Active"
    },
    {
        "Root": "10000943",
        "LV": "1",
        "Item Description": "CAVIAR AMER STURG JAR 1 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6150000",
        "Last Price Paid": "$39.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000943",
        "Product Status": "Active"
    },
    {
        "Root": "10000943",
        "LV": "1",
        "Item Description": "CAVIAR AMER STURG JAR 1 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-90485",
        "Last Price Paid": "$39.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000943",
        "Product Status": "Active"
    },
    {
        "Root": "10000943",
        "LV": "1",
        "Item Description": "CAVIAR AMER STURG JAR 1 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000943",
        "Product Status": "Active"
    },
    {
        "Root": "10031730",
        "LV": "1",
        "Item Description": "PLAYALINDA SEASONAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "5766",
        "Last Price Paid": "$194.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031730",
        "Product Status": "Active"
    },
    {
        "Root": "10040142",
        "LV": "1",
        "Item Description": "COASTER RIVIERA MW OVAL 3.5X4.5IN 3500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006509",
        "Last Price Paid": "$0.09",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040142",
        "Product Status": "Active"
    },
    {
        "Root": "10030015",
        "LV": "2",
        "Item Description": "SPIRIT ROTHMAN VIOLET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030015002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677486",
        "Last Price Paid": "$19.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030015",
        "Product Status": "Active"
    },
    {
        "Root": "10107291",
        "LV": "1",
        "Item Description": "SEEDS SUNFLOWER ROASTED N/SALT 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107291001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "321320",
        "Last Price Paid": "$47.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107291",
        "Product Status": "Active"
    },
    {
        "Root": "10000049",
        "LV": "1",
        "Item Description": "SAUSAGE PORK BANGER 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000049001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "308",
        "Last Price Paid": "$3.62",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000049",
        "Product Status": "Active"
    },
    {
        "Root": "10031663",
        "LV": "1",
        "Item Description": "CEREAL OATMEAL ROLLED QUICK CANISTER SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1004662",
        "Last Price Paid": "$4.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031663",
        "Product Status": "Active"
    },
    {
        "Root": "10102650",
        "LV": "1",
        "Item Description": "POPCHIPS - BARBEQUE .8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "968011",
        "Last Price Paid": "$1.02",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102650",
        "Product Status": "Active"
    },
    {
        "Root": "10015882",
        "LV": "1",
        "Item Description": "HERB KOP UPLAND CRESS LIVING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480462",
        "Last Price Paid": "$21.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015882",
        "Product Status": "Active"
    },
    {
        "Root": "10015882",
        "LV": "1",
        "Item Description": "HERB KOP UPLAND CRESS LIVING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21688",
        "Last Price Paid": "$21.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015882",
        "Product Status": "Active"
    },
    {
        "Root": "10109510",
        "LV": "1",
        "Item Description": "BRATWURST LINK PORK RAW 4 OZ 5.5\" NATURAL CASING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8259699",
        "Last Price Paid": "$19.05",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109510",
        "Product Status": "Active"
    },
    {
        "Root": "10017415",
        "LV": "2",
        "Item Description": "TEA PASSNFRT VAN ICE 36 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017415001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "ITLL9901",
        "Last Price Paid": "$188.20",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10017415",
        "Product Status": "Active"
    },
    {
        "Root": "10041058",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED TALEGGIO WASHED RIND ITALY 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041058001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "76460",
        "Last Price Paid": "$8.97",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041058",
        "Product Status": "Active"
    },
    {
        "Root": "10042843",
        "LV": "1",
        "Item Description": "WINE WHITE CHARDONNAY MCBRINDE SISTERS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "522326",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042843",
        "Product Status": "Active"
    },
    {
        "Root": "10000642",
        "LV": "1",
        "Item Description": "HAM SMOKED BONE IN RTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000642001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3301031",
        "Last Price Paid": "$3.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000642",
        "Product Status": "Active"
    },
    {
        "Root": "10021450",
        "LV": "1",
        "Item Description": "SPICE PEPPER ALEPPO GROUND 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI005",
        "Last Price Paid": "$21.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021450",
        "Product Status": "Active"
    },
    {
        "Root": "10002503",
        "LV": "1",
        "Item Description": "CORN HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002503001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002503",
        "Product Status": "Active"
    },
    {
        "Root": "10016443",
        "LV": "4",
        "Item Description": "MUSTARD VOL PK 1/3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016443003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10016443",
        "Product Status": "Active"
    },
    {
        "Root": "10102570",
        "LV": "1",
        "Item Description": "SB CHOCOLATE PISTACHIO SWIRL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11138565",
        "Last Price Paid": "$1.56",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102570",
        "Product Status": "Active"
    },
    {
        "Root": "10105496",
        "LV": "1",
        "Item Description": "BLACK VINEGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35333",
        "Last Price Paid": "$6.95",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105496",
        "Product Status": "Active"
    },
    {
        "Root": "10109308",
        "LV": "1",
        "Item Description": "DRIED SEAWEED SALAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "65-16700",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109308",
        "Product Status": "Active"
    },
    {
        "Root": "10034871",
        "LV": "1",
        "Item Description": "STRAW PAPER GIANT AARDVARK 7.75 BLACK UNWRAPPED ST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034871",
        "Product Status": "Active"
    },
    {
        "Root": "10019917",
        "LV": "1",
        "Item Description": "REINEER BOOTS SANTS XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VO-1080",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019917",
        "Product Status": "Active"
    },
    {
        "Root": "10040536",
        "LV": "1",
        "Item Description": "CANDY MALBAN SWEET PISTACHIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "347310",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040536",
        "Product Status": "Active"
    },
    {
        "Root": "10003298",
        "LV": "1",
        "Item Description": "POPCORN PASTE CHEDDAR GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "94680",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003298",
        "Product Status": "Active"
    },
    {
        "Root": "10005668",
        "LV": "1",
        "Item Description": "BREAD WHEAT SANDWICH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005668",
        "Product Status": "Active"
    },
    {
        "Root": "10107957",
        "LV": "1",
        "Item Description": "CUBE GLOW ORB LED WHT VENT 200/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "DIS921UN",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107957",
        "Product Status": "Active"
    },
    {
        "Root": "10019269",
        "LV": "2",
        "Item Description": "GUMPASTE-BOWS WHITE-LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019269002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "14206",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019269",
        "Product Status": "Active"
    },
    {
        "Root": "10014444",
        "LV": "1",
        "Item Description": "CHOC FALL LEAVES ASST.2 TONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1230",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014444",
        "Product Status": "Active"
    },
    {
        "Root": "10022099",
        "LV": "1",
        "Item Description": "CAB KANANKOP 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022099001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "413289",
        "Last Price Paid": "$35.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022099",
        "Product Status": "Active"
    },
    {
        "Root": "10002125",
        "LV": "1",
        "Item Description": "CHEESE PARMESAN SHREDDED 6/3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3587490",
        "Last Price Paid": "$11.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002125",
        "Product Status": "Active"
    },
    {
        "Root": "10002125",
        "LV": "1",
        "Item Description": "CHEESE PARMESAN SHREDDED 6/3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002125",
        "Product Status": "Active"
    },
    {
        "Root": "10010132",
        "LV": "2",
        "Item Description": "WATER SAN BENEDETTO SPARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010132001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.86",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010132",
        "Product Status": "Active"
    },
    {
        "Root": "10039557",
        "LV": "1",
        "Item Description": "PIZZA CHEESE FROZEN BIG EDGE NY FAMILY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039557",
        "Product Status": "Active"
    },
    {
        "Root": "10024883",
        "LV": "1",
        "Item Description": "POTATO STIX 5.5 OZ BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY925",
        "Last Price Paid": "$5.38",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024883",
        "Product Status": "Active"
    },
    {
        "Root": "10107892",
        "LV": "1",
        "Item Description": "TURKEY HOAGIE HURRICANE BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107892001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073553",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107892",
        "Product Status": "Active"
    },
    {
        "Root": "10075090",
        "LV": "1",
        "Item Description": "PAPER BUTCHER 36 IN X 900 FT ROLL PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10075090001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51180014",
        "Last Price Paid": "$52.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10075090",
        "Product Status": "Active"
    },
    {
        "Root": "10104669",
        "LV": "1",
        "Item Description": "CANDY JUNIOR MINTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "586016",
        "Last Price Paid": "$1.51",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104669",
        "Product Status": "Active"
    },
    {
        "Root": "10004771",
        "LV": "1",
        "Item Description": "GRITS QUICK COOKING 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1142470",
        "Last Price Paid": "$4.21",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004771",
        "Product Status": "Active"
    },
    {
        "Root": "10004116",
        "LV": "1",
        "Item Description": "SB POWDER BAR MOCHA 62.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11071912",
        "Last Price Paid": "$18.97",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004116",
        "Product Status": "Active"
    },
    {
        "Root": "10002718",
        "LV": "1",
        "Item Description": "CEREAL RICE CRISPY BOWL 96CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002718",
        "Product Status": "Active"
    },
    {
        "Root": "10007778",
        "LV": "2",
        "Item Description": "CHENIN BLC FAIR VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007778002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10007778",
        "Product Status": "Active"
    },
    {
        "Root": "10086665",
        "LV": "1",
        "Item Description": "CUP DIXIE PERFECTOUCH DISNEY GALACTICA 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.10",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086665",
        "Product Status": "Active"
    },
    {
        "Root": "10004280",
        "LV": "1",
        "Item Description": "CANDY FRUIT FILLED ASST 8/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "583250",
        "Last Price Paid": "$14.91",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004280",
        "Product Status": "Active"
    },
    {
        "Root": "10040658",
        "LV": "1",
        "Item Description": "SAUCE BBQ SWEET BABY RAY PIT BOSS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.55",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040658",
        "Product Status": "Active"
    },
    {
        "Root": "10034163",
        "LV": "2",
        "Item Description": "JUICE COLD PRESSED SUJA MIGHTY GREENS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034163002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "159398",
        "Last Price Paid": "$3.45",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034163",
        "Product Status": "Active"
    },
    {
        "Root": "10004807",
        "LV": "1",
        "Item Description": "CONCENTRATE ORANGE BLOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B1167",
        "Last Price Paid": "$21.12",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004807",
        "Product Status": "Active"
    },
    {
        "Root": "10000546",
        "LV": "1",
        "Item Description": "BREAD ROLL BRIOCHE SLIDER MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000546001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "2:C",
        "Last Price Paid": "$0.25",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000546",
        "Product Status": "Active"
    },
    {
        "Root": "10088189",
        "LV": "1",
        "Item Description": "CIDER DRAFT POSSMAN'S PURE 30L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14226",
        "Last Price Paid": "$152.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088189",
        "Product Status": "Active"
    },
    {
        "Root": "10031821",
        "LV": "1",
        "Item Description": "ICING CREAM CHEESE BUTTERCREME 18#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "2437863",
        "Last Price Paid": "$45.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031821",
        "Product Status": "Active"
    },
    {
        "Root": "10001218",
        "LV": "1",
        "Item Description": "LAMB SHANK BRAISED BNWRKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001218001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4601566",
        "Last Price Paid": "$10.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001218",
        "Product Status": "Active"
    },
    {
        "Root": "10005621",
        "LV": "1",
        "Item Description": "BREAD ROLL DINNER ASSORTED EURO BAKED 160 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6702609",
        "Last Price Paid": "$0.14",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005621",
        "Product Status": "Active"
    },
    {
        "Root": "10005621",
        "LV": "1",
        "Item Description": "BREAD ROLL DINNER ASSORTED EURO BAKED 160 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005621",
        "Product Status": "Active"
    },
    {
        "Root": "10031828",
        "LV": "1",
        "Item Description": "WET PROOF CRISPY PASSION FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672096",
        "Last Price Paid": "$34.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031828",
        "Product Status": "Active"
    },
    {
        "Root": "10025063",
        "LV": "1",
        "Item Description": "DRAFT ELYSIAN DRAGONSTOOTH STOUT 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "43506",
        "Last Price Paid": "$90.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025063",
        "Product Status": "Active"
    },
    {
        "Root": "10007961",
        "LV": "1",
        "Item Description": "PINOT NOIR DUTTON GLDFLD RR 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9003592",
        "Last Price Paid": "$26.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007961",
        "Product Status": "Active"
    },
    {
        "Root": "10010279",
        "LV": "2",
        "Item Description": "BEER NEW BELGIUM VOODOO RANGER IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010279001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "54838",
        "Last Price Paid": "$1.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010279",
        "Product Status": "Active"
    },
    {
        "Root": "10010437",
        "LV": "2",
        "Item Description": "BEER VANILLA PORTER 12 OZ BOTTLE BRECKENRDGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010437001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "47618",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010437",
        "Product Status": "Active"
    },
    {
        "Root": "10014284",
        "LV": "1",
        "Item Description": "WATER PANNA 16.9 GLS SPRING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9255563",
        "Last Price Paid": "$1.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014284",
        "Product Status": "Active"
    },
    {
        "Root": "10004202",
        "LV": "1",
        "Item Description": "SAUCE TABASCO 2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3018538",
        "Last Price Paid": "$1.35",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004202",
        "Product Status": "Active"
    },
    {
        "Root": "10000206",
        "LV": "1",
        "Item Description": "APPLE SLICED RED 2.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000206001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "581208",
        "Last Price Paid": "$0.28",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000206",
        "Product Status": "Active"
    },
    {
        "Root": "10035828",
        "LV": "1",
        "Item Description": "BOWL WAFFLE CONE LARGE 60 COUNT 7.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5403233",
        "Last Price Paid": "$0.33",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035828",
        "Product Status": "Active"
    },
    {
        "Root": "10002325",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BONNE BOUCHE ASH GOAT VERMONT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "11827",
        "Last Price Paid": "$6.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002325",
        "Product Status": "Active"
    },
    {
        "Root": "10001588",
        "LV": "1",
        "Item Description": "PEACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270564",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001588",
        "Product Status": "Active"
    },
    {
        "Root": "10001588",
        "LV": "1",
        "Item Description": "PEACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001588",
        "Product Status": "Active"
    },
    {
        "Root": "10001588",
        "LV": "1",
        "Item Description": "PEACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "14120",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001588",
        "Product Status": "Active"
    },
    {
        "Root": "10002876",
        "LV": "1",
        "Item Description": "BAKING POWDER 10# BOX FLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002876",
        "Product Status": "Active"
    },
    {
        "Root": "10101428",
        "LV": "1",
        "Item Description": "SAUCE SRIRACHA TABASCO 6/20OZ SQUEEZE BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6140379",
        "Last Price Paid": "$3.54",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101428",
        "Product Status": "Active"
    },
    {
        "Root": "10009832",
        "LV": "1",
        "Item Description": "BRANDY E&J ORIGINAL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "220236",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009832",
        "Product Status": "Active"
    },
    {
        "Root": "10009832",
        "LV": "1",
        "Item Description": "BRANDY E&J ORIGINAL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "220236",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009832",
        "Product Status": "Active"
    },
    {
        "Root": "10082065",
        "LV": "1",
        "Item Description": "WINE RED KIR YANNI NAOUSSA XINOMAVRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9414880",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082065",
        "Product Status": "Active"
    },
    {
        "Root": "10027276",
        "LV": "1",
        "Item Description": "DRIED FRUIT PINEAPPLE CHUNKS 6/10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891447",
        "Last Price Paid": "$4.91",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027276",
        "Product Status": "Active"
    },
    {
        "Root": "10004652",
        "LV": "1",
        "Item Description": "DRIED FRUIT DATE PIECES 3/8 INCH CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "282110",
        "Last Price Paid": "$69.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004652",
        "Product Status": "Active"
    },
    {
        "Root": "10037114",
        "LV": "1",
        "Item Description": "JUICE GRAPE WHITE 100% SHELF STABLE PLASTIC BOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037114001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7203052",
        "Last Price Paid": "$5.01",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037114",
        "Product Status": "Active"
    },
    {
        "Root": "10000432",
        "LV": "1",
        "Item Description": "BREAD ROLL SOURDOUGH W SEED SQUARE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.25",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000432",
        "Product Status": "Active"
    },
    {
        "Root": "10001757",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BULBS W/TOPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450004",
        "Last Price Paid": "$2.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001757",
        "Product Status": "Active"
    },
    {
        "Root": "10001757",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BULBS W/TOPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20471",
        "Last Price Paid": "$2.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001757",
        "Product Status": "Active"
    },
    {
        "Root": "10001757",
        "LV": "1",
        "Item Description": "FENNEL/ANISE BULBS W/TOPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001757",
        "Product Status": "Active"
    },
    {
        "Root": "10106032",
        "LV": "1",
        "Item Description": "BRM VITAL WHEAT GLUTEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101153",
        "Last Price Paid": "$81.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106032",
        "Product Status": "Active"
    },
    {
        "Root": "10040129",
        "LV": "1",
        "Item Description": "BEEF PRIME RIB COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040129001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.34",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040129",
        "Product Status": "Active"
    },
    {
        "Root": "10000740",
        "LV": "1",
        "Item Description": "BOAR WILD SHOULDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000740001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4521402",
        "Last Price Paid": "$7.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000740",
        "Product Status": "Active"
    },
    {
        "Root": "10017246",
        "LV": "1",
        "Item Description": "LOBSTER 3 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017246001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "69-50350",
        "Last Price Paid": "$13.95",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017246",
        "Product Status": "Active"
    },
    {
        "Root": "10089244",
        "LV": "1",
        "Item Description": "PRETZEL JUMBO 10OZ FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089244",
        "Product Status": "Active"
    },
    {
        "Root": "10008206",
        "LV": "3",
        "Item Description": "ZINF EDMEADES MENDOC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008206002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "575041",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008206",
        "Product Status": "Active"
    },
    {
        "Root": "10008206",
        "LV": "3",
        "Item Description": "ZINF EDMEADES MENDOC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008206002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "575041",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008206",
        "Product Status": "Active"
    },
    {
        "Root": "10090587",
        "LV": "3",
        "Item Description": "SNACK DRIED GREEN BEANS 3LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090587003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "DGB-02",
        "Last Price Paid": "$25.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10090587",
        "Product Status": "Active"
    },
    {
        "Root": "10085513",
        "LV": "1",
        "Item Description": "GLASS WINE STAR WARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085513001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085513",
        "Product Status": "Active"
    },
    {
        "Root": "10001760",
        "LV": "1",
        "Item Description": "HERB LAVENDER DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001760001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001760",
        "Product Status": "Active"
    },
    {
        "Root": "10001760",
        "LV": "1",
        "Item Description": "HERB LAVENDER DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001760001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480091",
        "Last Price Paid": "$23.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001760",
        "Product Status": "Active"
    },
    {
        "Root": "10001537",
        "LV": "1",
        "Item Description": "BEAN LENTIL CRIMSON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482192",
        "Last Price Paid": "$34.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001537",
        "Product Status": "Active"
    },
    {
        "Root": "10030200",
        "LV": "1",
        "Item Description": "MIX CAKE VEGAN CHOCOLATE 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "A60154",
        "Last Price Paid": "$53.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030200",
        "Product Status": "Active"
    },
    {
        "Root": "10034188",
        "LV": "2",
        "Item Description": "BLUEBERRIES STANDARD 12/6OZ FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034188002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250015",
        "Last Price Paid": "$7.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034188",
        "Product Status": "Active"
    },
    {
        "Root": "10034188",
        "LV": "2",
        "Item Description": "BLUEBERRIES STANDARD 12/6OZ FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034188002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BERBB",
        "Last Price Paid": "$7.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034188",
        "Product Status": "Active"
    },
    {
        "Root": "10034188",
        "LV": "2",
        "Item Description": "BLUEBERRIES STANDARD 12/6OZ FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034188002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "12002",
        "Last Price Paid": "$7.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034188",
        "Product Status": "Active"
    },
    {
        "Root": "10018657",
        "LV": "1",
        "Item Description": "MOSCATO RODOLFO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018657001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "016292.19",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018657",
        "Product Status": "Active"
    },
    {
        "Root": "10004875",
        "LV": "1",
        "Item Description": "PASTA DRY RIGATONI #83 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3325610",
        "Last Price Paid": "$14.83",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004875",
        "Product Status": "Active"
    },
    {
        "Root": "10011860",
        "LV": "1",
        "Item Description": "SB WDW CUP HOT 20OZ PRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011860",
        "Product Status": "Active"
    },
    {
        "Root": "10003048",
        "LV": "1",
        "Item Description": "ICING WHITE DONUT AND ROLL 41LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$81.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003048",
        "Product Status": "Active"
    },
    {
        "Root": "10009680",
        "LV": "1",
        "Item Description": "TEQUILA CASA DRAGONES JOVEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009680001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294410",
        "Last Price Paid": "$242.84",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009680",
        "Product Status": "Active"
    },
    {
        "Root": "10007054",
        "LV": "1",
        "Item Description": "PINOT NOIR CARMEL RD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "599178",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007054",
        "Product Status": "Active"
    },
    {
        "Root": "10106228",
        "LV": "1",
        "Item Description": "FRESH CHOPPED CLAMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "CLA310170",
        "Last Price Paid": "$63.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106228",
        "Product Status": "Active"
    },
    {
        "Root": "10025319",
        "LV": "1",
        "Item Description": "BAG SNGL SERV WINDOW 6.5 X 2 X 8.5 GREASE RESIST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025319001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008174",
        "Last Price Paid": "$0.09",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025319",
        "Product Status": "Active"
    },
    {
        "Root": "10004563",
        "LV": "1",
        "Item Description": "PASTA DRY DITALINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004563001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1325711",
        "Last Price Paid": "$1.45",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004563",
        "Product Status": "Active"
    },
    {
        "Root": "10001369",
        "LV": "1",
        "Item Description": "SQUASH BLOSSOMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001369",
        "Product Status": "Active"
    },
    {
        "Root": "10004969",
        "LV": "1",
        "Item Description": "PUREE RASPBERRY 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AFR1C6UA0",
        "Last Price Paid": "$14.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004969",
        "Product Status": "Active"
    },
    {
        "Root": "10032804",
        "LV": "1",
        "Item Description": "FLOUR ALMOND TEMPORARY 5# BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Z85503",
        "Last Price Paid": "$28.66",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032804",
        "Product Status": "Active"
    },
    {
        "Root": "10003608",
        "LV": "1",
        "Item Description": "CANDY M&M PEANUT 1.74Z 48",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534201",
        "Last Price Paid": "$1.48",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003608",
        "Product Status": "Active"
    },
    {
        "Root": "10006937",
        "LV": "1",
        "Item Description": "CHARD CHAT STE MICHEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006937",
        "Product Status": "Active"
    },
    {
        "Root": "10037041",
        "LV": "1",
        "Item Description": "PARKER WEST CHARDONNAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9370857",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037041",
        "Product Status": "Active"
    },
    {
        "Root": "10005340",
        "LV": "1",
        "Item Description": "DEC ROSE LEAVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7079",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005340",
        "Product Status": "Active"
    },
    {
        "Root": "10023313",
        "LV": "1",
        "Item Description": "CHOC DRK FRAME MED 6 X 4 3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW50663D",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023313",
        "Product Status": "Active"
    },
    {
        "Root": "10110636",
        "LV": "1",
        "Item Description": "BEER 81BAY CINNAMON ORANGE CRAN ALE 1/2 BBL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16611",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110636",
        "Product Status": "Active"
    },
    {
        "Root": "10095637",
        "LV": "1",
        "Item Description": "WINE CHAMPAGNE BESSERAT BELLOFON BRUT 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "605604",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095637",
        "Product Status": "Active"
    },
    {
        "Root": "10036615",
        "LV": "1",
        "Item Description": "POWDER HIGH ACYL GELLAN GUM 400GR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY097",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036615",
        "Product Status": "Active"
    },
    {
        "Root": "10018906",
        "LV": "1",
        "Item Description": "PABST BLUE RIBBON 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14157",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018906",
        "Product Status": "Active"
    },
    {
        "Root": "10039689",
        "LV": "1",
        "Item Description": "VEGANAISE CHIPOTLE FYH 1 GALLON 4/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "72128",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039689",
        "Product Status": "Active"
    },
    {
        "Root": "10110208",
        "LV": "1",
        "Item Description": "BUCKET PREM POP SW HELMET STORM TRPR 64OZ 8/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110208",
        "Product Status": "Active"
    },
    {
        "Root": "10037524",
        "LV": "1",
        "Item Description": "GELATO CHOC VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "IGV-002",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037524",
        "Product Status": "Active"
    },
    {
        "Root": "10089504",
        "LV": "1",
        "Item Description": "TEA HOT HERBAL HIBISCUS CAFFEINE FREE ENVELOPE PAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089504001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5178710",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089504",
        "Product Status": "Active"
    },
    {
        "Root": "10041584",
        "LV": "1",
        "Item Description": "SHRIMP U-7 HEADLESS SHELL-ON RAW WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041584001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041584",
        "Product Status": "Active"
    },
    {
        "Root": "10003979",
        "LV": "1",
        "Item Description": "PACKAGING PAPER CONE PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003979",
        "Product Status": "Active"
    },
    {
        "Root": "10037406",
        "LV": "2",
        "Item Description": "CHEESE SLICES DAIRY FREE SMOKED GOUDA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037406002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "29027",
        "Last Price Paid": "$3.75",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037406",
        "Product Status": "Active"
    },
    {
        "Root": "10025144",
        "LV": "1",
        "Item Description": "BELL FLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SSWH",
        "Last Price Paid": "$0.64",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025144",
        "Product Status": "Active"
    },
    {
        "Root": "10014765",
        "LV": "1",
        "Item Description": "POWDER ESPRESSO 3OZ KING ARTHUR 4447",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD507",
        "Last Price Paid": "$21.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014765",
        "Product Status": "Active"
    },
    {
        "Root": "10031785",
        "LV": "1",
        "Item Description": "DIVINE DONUT REGULAR BEAN1 RETAIL JOFFREYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D380B-21J",
        "Last Price Paid": "$6.73",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031785",
        "Product Status": "Active"
    },
    {
        "Root": "10013349",
        "LV": "2",
        "Item Description": "MUSTARD WHOLE GRAIN INTL IMP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013349002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7330202",
        "Last Price Paid": "$18.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013349",
        "Product Status": "Active"
    },
    {
        "Root": "10038216",
        "LV": "1",
        "Item Description": "BUCKET POP LIONKING SIMBA 3D 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.96",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038216",
        "Product Status": "Active"
    },
    {
        "Root": "10089229",
        "LV": "1",
        "Item Description": "DONUT PLAIN CAKE HOLE UNGLAZED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS204",
        "Last Price Paid": "$0.07",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089229",
        "Product Status": "Active"
    },
    {
        "Root": "10032426",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC TOKARA RESERVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "667238",
        "Last Price Paid": "$14.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032426",
        "Product Status": "Active"
    },
    {
        "Root": "10037251",
        "LV": "1",
        "Item Description": "STRAW COCKTAIL PAPER BLACK 5.75 IN UNWRAPPED 8/875",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1171988",
        "Last Price Paid": "$0.02",
        "Case Pack": "7000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037251",
        "Product Status": "Active"
    },
    {
        "Root": "10009887",
        "LV": "1",
        "Item Description": "SHERRY HARVEY BRTL CRM 12/1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009887001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "936159",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009887",
        "Product Status": "Active"
    },
    {
        "Root": "10002632",
        "LV": "1",
        "Item Description": "SPICE SESAME SEED 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "761734",
        "Last Price Paid": "$2.31",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002632",
        "Product Status": "Active"
    },
    {
        "Root": "10094649",
        "LV": "1",
        "Item Description": "LIQUEUR DEKUYPER MELON SCHNAPPS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "990366",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094649",
        "Product Status": "Active"
    },
    {
        "Root": "10003765",
        "LV": "1",
        "Item Description": "SPICE PAPRIKA SMOKED 20Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "51660",
        "Last Price Paid": "$15.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003765",
        "Product Status": "Active"
    },
    {
        "Root": "10003765",
        "LV": "1",
        "Item Description": "SPICE PAPRIKA SMOKED 20Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593133",
        "Last Price Paid": "$15.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003765",
        "Product Status": "Active"
    },
    {
        "Root": "10006936",
        "LV": "1",
        "Item Description": "CHAMP VEUVE CLIQ YEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006936",
        "Product Status": "Active"
    },
    {
        "Root": "10003672",
        "LV": "1",
        "Item Description": "PEPPER PIQUILLO CANNED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593134",
        "Last Price Paid": "$11.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003672",
        "Product Status": "Active"
    },
    {
        "Root": "10003672",
        "LV": "1",
        "Item Description": "PEPPER PIQUILLO CANNED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PANISH GARDEN{",
        "Last Price Paid": "$11.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003672",
        "Product Status": "Active"
    },
    {
        "Root": "10017994",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON TERRAZAS ALTOS DEL PLATA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "573361",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017994",
        "Product Status": "Active"
    },
    {
        "Root": "10032540",
        "LV": "1",
        "Item Description": "CINNAMON GROUND SRI LANKAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "H144",
        "Last Price Paid": "$36.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032540",
        "Product Status": "Active"
    },
    {
        "Root": "10009851",
        "LV": "1",
        "Item Description": "COGNAC REMY MARTIN VSOP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "105219",
        "Last Price Paid": "$46.27",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009851",
        "Product Status": "Active"
    },
    {
        "Root": "10006881",
        "LV": "2",
        "Item Description": "PINOT GRIG SARTORI VE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006881001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "278592",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006881",
        "Product Status": "Active"
    },
    {
        "Root": "10002827",
        "LV": "2",
        "Item Description": "COKE CLASSIC CAN 12OZ/24E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002827002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "100278",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002827",
        "Product Status": "Active"
    },
    {
        "Root": "10022402",
        "LV": "1",
        "Item Description": "WINE PG SANTA JULIA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022402001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "357095",
        "Last Price Paid": "$8.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022402",
        "Product Status": "Active"
    },
    {
        "Root": "10001620",
        "LV": "1",
        "Item Description": "BEAN LIMA BABY FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1328251",
        "Last Price Paid": "$35.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001620",
        "Product Status": "Active"
    },
    {
        "Root": "10035171",
        "LV": "2",
        "Item Description": "CRAB JONAH WHL RAW IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035171002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50018",
        "Last Price Paid": "$6.28",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035171",
        "Product Status": "Active"
    },
    {
        "Root": "10035171",
        "LV": "2",
        "Item Description": "CRAB JONAH WHL RAW IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035171002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6339504",
        "Last Price Paid": "$6.28",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035171",
        "Product Status": "Active"
    },
    {
        "Root": "10106348",
        "LV": "1",
        "Item Description": "BEER KEG KONA LONGBOARD LAGER 5.16GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$64.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106348",
        "Product Status": "Active"
    },
    {
        "Root": "10043558",
        "LV": "1",
        "Item Description": "CHOCOLATE MILK MICKEY EAR BLANK SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330M",
        "Last Price Paid": "$2.52",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043558",
        "Product Status": "Active"
    },
    {
        "Root": "10009701",
        "LV": "1",
        "Item Description": "TEQ DON JULIO BLANCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "971835",
        "Last Price Paid": "$36.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009701",
        "Product Status": "Active"
    },
    {
        "Root": "10017347",
        "LV": "1",
        "Item Description": "VEUVE CLICQUOT DEMI SEC 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "41897",
        "Last Price Paid": "$53.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017347",
        "Product Status": "Active"
    },
    {
        "Root": "10003896",
        "LV": "1",
        "Item Description": "SYRUP FALERNUM 750ML BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.57",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003896",
        "Product Status": "Active"
    },
    {
        "Root": "10005819",
        "LV": "1",
        "Item Description": "BREAD FLAT 10 INCH X 4 INCH 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005819",
        "Product Status": "Active"
    },
    {
        "Root": "10107918",
        "LV": "1",
        "Item Description": "NEVAS 1.5L SPARKLING WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "NVS2",
        "Last Price Paid": "$64.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107918",
        "Product Status": "Active"
    },
    {
        "Root": "10038384",
        "LV": "1",
        "Item Description": "POPCORN CONFETTI RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "RAINBOW-PB16",
        "Last Price Paid": "$78.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038384",
        "Product Status": "Active"
    },
    {
        "Root": "10091583",
        "LV": "1",
        "Item Description": "MEAT BOAR NECK BNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091583001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4521403",
        "Last Price Paid": "$6.80",
        "Case Pack": "13.008",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091583",
        "Product Status": "Active"
    },
    {
        "Root": "10003980",
        "LV": "1",
        "Item Description": "PACKAGING PAPER CN PUR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003980001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003980",
        "Product Status": "Active"
    },
    {
        "Root": "10009552",
        "LV": "1",
        "Item Description": "GIN HENDRICKS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009552001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009552",
        "Product Status": "Active"
    },
    {
        "Root": "10005623",
        "LV": "1",
        "Item Description": "BREAD BAGUETTE FRENCH LONG EURO 25 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.71",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005623",
        "Product Status": "Active"
    },
    {
        "Root": "10009190",
        "LV": "1",
        "Item Description": "BLND RUST VREDE EST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "565875",
        "Last Price Paid": "$39.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009190",
        "Product Status": "Active"
    },
    {
        "Root": "10001580",
        "LV": "1",
        "Item Description": "PUMPKIN PLD & HLVD 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512003",
        "Last Price Paid": "$19.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001580",
        "Product Status": "Active"
    },
    {
        "Root": "10001580",
        "LV": "1",
        "Item Description": "PUMPKIN PLD & HLVD 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001580",
        "Product Status": "Active"
    },
    {
        "Root": "10001079",
        "LV": "2",
        "Item Description": "SEAWEED FRESH 25 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001079002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001079",
        "Product Status": "Active"
    },
    {
        "Root": "10001079",
        "LV": "2",
        "Item Description": "SEAWEED FRESH 25 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001079002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "9999999",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001079",
        "Product Status": "Active"
    },
    {
        "Root": "10006419",
        "LV": "1",
        "Item Description": "BAG CRYOVAC 18X24 500 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006419",
        "Product Status": "Active"
    },
    {
        "Root": "10040141",
        "LV": "1",
        "Item Description": "COASTER TOPOLINO MW SQUARE 3.5IN 3500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006402",
        "Last Price Paid": "$0.06",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040141",
        "Product Status": "Active"
    },
    {
        "Root": "10044436",
        "LV": "1",
        "Item Description": "SPICE BAY LEAF WHOLE DRIED 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760793",
        "Last Price Paid": "$21.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044436",
        "Product Status": "Active"
    },
    {
        "Root": "10028030",
        "LV": "1",
        "Item Description": "LIQUEUR BAYOU GATOR BITE RUM LIQUEUR 1L/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9356773",
        "Last Price Paid": "$14.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028030",
        "Product Status": "Active"
    },
    {
        "Root": "10013468",
        "LV": "1",
        "Item Description": "TORTA ORANGE OLIVE OIL FLATBREAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "4849",
        "Last Price Paid": "$4.39",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013468",
        "Product Status": "Active"
    },
    {
        "Root": "10107490",
        "LV": "1",
        "Item Description": "BEER ACF HOUBLON CHOUFFE 20 L KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16073",
        "Last Price Paid": "$117.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107490",
        "Product Status": "Active"
    },
    {
        "Root": "10019382",
        "LV": "1",
        "Item Description": "TEA ENCHANTING FOREST FRUITS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13700",
        "Last Price Paid": "$31.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019382",
        "Product Status": "Active"
    },
    {
        "Root": "10007826",
        "LV": "1",
        "Item Description": "MALBEC DON MIGUEL GASCON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9019651",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007826",
        "Product Status": "Active"
    },
    {
        "Root": "10029401",
        "LV": "1",
        "Item Description": "RED BLEND ARGYROS ATLANTIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029401001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "601170",
        "Last Price Paid": "$17.41",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029401",
        "Product Status": "Active"
    },
    {
        "Root": "10031908",
        "LV": "1",
        "Item Description": "FREEZE DRIED ROSE PETALS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672075",
        "Last Price Paid": "$28.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031908",
        "Product Status": "Active"
    },
    {
        "Root": "10036235",
        "LV": "1",
        "Item Description": "CUP HOT/COLD PAPER 8Z P TOUCH MEADOW PRINT 20/50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6790463",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036235",
        "Product Status": "Active"
    },
    {
        "Root": "10037279",
        "LV": "2",
        "Item Description": "POMODORACCIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037279002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "26501",
        "Last Price Paid": "$41.70",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037279",
        "Product Status": "Active"
    },
    {
        "Root": "10103968",
        "LV": "1",
        "Item Description": "SPIRIT VODKA WODKA LTR 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103968",
        "Product Status": "Active"
    },
    {
        "Root": "10045616",
        "LV": "2",
        "Item Description": "FORK MED WT POLYSTYRENE WRAPPED BULK 1000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045616002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10045616",
        "Product Status": "Active"
    },
    {
        "Root": "10040097",
        "LV": "1",
        "Item Description": "PIZZA PEPPERONI TOMBSTONE ORIGINAL 12IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040097001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12422035",
        "Last Price Paid": "$4.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040097",
        "Product Status": "Active"
    },
    {
        "Root": "10000645",
        "LV": "1",
        "Item Description": "BEEF BRISOLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000645001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000645",
        "Product Status": "Active"
    },
    {
        "Root": "10012441",
        "LV": "1",
        "Item Description": "GUMPASTE-DIAMANTE FLOWER-3/4\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "11176",
        "Last Price Paid": "$0.27",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012441",
        "Product Status": "Active"
    },
    {
        "Root": "10031875",
        "LV": "1",
        "Item Description": "PASTRY DOUGH SHELL EMPANADA 5 IN 10/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "495504",
        "Last Price Paid": "$1.79",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031875",
        "Product Status": "Active"
    },
    {
        "Root": "10000069",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN TEXAS TAIL 4Z-6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000069001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2130100",
        "Last Price Paid": "$9.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000069",
        "Product Status": "Active"
    },
    {
        "Root": "10035052",
        "LV": "1",
        "Item Description": "JUICE APPLE MM 12 OZ/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.92",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035052",
        "Product Status": "Active"
    },
    {
        "Root": "10012382",
        "LV": "1",
        "Item Description": "HORS SPANAKOPITA SPINACH & FETA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2136972",
        "Last Price Paid": "$0.42",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012382",
        "Product Status": "Active"
    },
    {
        "Root": "10100230",
        "LV": "1",
        "Item Description": "ITALIAN ICE, LEMON NSA 72/4OZ CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6325211",
        "Last Price Paid": "$0.58",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100230",
        "Product Status": "Active"
    },
    {
        "Root": "10004879",
        "LV": "1",
        "Item Description": "OLIVE KALAMATA SLICED 6/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090292",
        "Last Price Paid": "$15.07",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004879",
        "Product Status": "Active"
    },
    {
        "Root": "10017331",
        "LV": "4",
        "Item Description": "TRAY SQ WHITE 7 7/8\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017331003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091248",
        "Last Price Paid": "$0.33",
        "Case Pack": "660",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10017331",
        "Product Status": "Active"
    },
    {
        "Root": "10041050",
        "LV": "1",
        "Item Description": "CHEESE HARD MONTEGRAPPA ITALY 6.2 LB QUARTER WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041050001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "39522",
        "Last Price Paid": "$11.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041050",
        "Product Status": "Active"
    },
    {
        "Root": "10005770",
        "LV": "1",
        "Item Description": "BREAD ONION KULCHA 2.75 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043447",
        "Last Price Paid": "$0.71",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005770",
        "Product Status": "Active"
    },
    {
        "Root": "10005959",
        "LV": "1",
        "Item Description": "CAKE DECORATED FULL-SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$114.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005959",
        "Product Status": "Active"
    },
    {
        "Root": "10044122",
        "LV": "1",
        "Item Description": "BEER CIDERBOYS MAD BARK 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "1679",
        "Last Price Paid": "$201.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044122",
        "Product Status": "Active"
    },
    {
        "Root": "10005440",
        "LV": "1",
        "Item Description": "HORS EMPANADA BEEF 3Z BRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005440001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300625",
        "Last Price Paid": "$1.62",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005440",
        "Product Status": "Active"
    },
    {
        "Root": "10040086",
        "LV": "1",
        "Item Description": "ICE CREAM ROLO 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040086001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12318595",
        "Last Price Paid": "$1.59",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040086",
        "Product Status": "Active"
    },
    {
        "Root": "10003257",
        "LV": "1",
        "Item Description": "SYRUP ALMOND LTR PET MONI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003257001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003257",
        "Product Status": "Active"
    },
    {
        "Root": "10020837",
        "LV": "1",
        "Item Description": "COFFEE ORG DECAF COLUM FRENCH PRESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.63",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020837",
        "Product Status": "Active"
    },
    {
        "Root": "10001053",
        "LV": "1",
        "Item Description": "CRAYFISH WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001053001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001053",
        "Product Status": "Active"
    },
    {
        "Root": "10001053",
        "LV": "1",
        "Item Description": "CRAYFISH WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001053001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6437502",
        "Last Price Paid": "$3.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001053",
        "Product Status": "Active"
    },
    {
        "Root": "10000249",
        "LV": "1",
        "Item Description": "LEAVES HIBISCUS 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05HL-1L",
        "Last Price Paid": "$0.70",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000249",
        "Product Status": "Active"
    },
    {
        "Root": "10021815",
        "LV": "1",
        "Item Description": "CHOC WHT SQ BAY LAKE TOWER 2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW15042",
        "Last Price Paid": "$1.15",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021815",
        "Product Status": "Active"
    },
    {
        "Root": "10105034",
        "LV": "2",
        "Item Description": "BEEF BARBACOA FULLY COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105034002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.87",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10105034",
        "Product Status": "Active"
    },
    {
        "Root": "10044480",
        "LV": "1",
        "Item Description": "DONUT HOLE YEAST PLAIN .35OZ 384 CT READY TO DECOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4890588",
        "Last Price Paid": "$0.08",
        "Case Pack": "384",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044480",
        "Product Status": "Active"
    },
    {
        "Root": "10016979",
        "LV": "2",
        "Item Description": "TEA NUTCRACKER LOOSE TIN 2.82OZ 80G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016979002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "WMC9978R",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016979",
        "Product Status": "Active"
    },
    {
        "Root": "10039808",
        "LV": "1",
        "Item Description": "CHOC FRUIT BLOSSOMS MIX 40/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P-FRUBLMIX",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039808",
        "Product Status": "Active"
    },
    {
        "Root": "10100874",
        "LV": "1",
        "Item Description": "CROISSANT MINI BUTTER READY TO BAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "225",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100874",
        "Product Status": "Active"
    },
    {
        "Root": "10007869",
        "LV": "1",
        "Item Description": "MERLOT ERNIE ELS FL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "933718",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007869",
        "Product Status": "Active"
    },
    {
        "Root": "10027516",
        "LV": "1",
        "Item Description": "COASTER 4\" ROUND 40PT CM CALIFORNIA GRILL 44R4DB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731045",
        "Last Price Paid": "",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027516",
        "Product Status": "Active"
    },
    {
        "Root": "GENE001",
        "LV": "1",
        "Item Description": "GENERIC ARTICLE BEARING THE DISCOUNT VARIANCES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "GENE001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "GENE001",
        "Product Status": "Active"
    },
    {
        "Root": "10035747",
        "LV": "1",
        "Item Description": "GUMPASTE PETITE GARDEN ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035747",
        "Product Status": "Active"
    },
    {
        "Root": "10087655",
        "LV": "1",
        "Item Description": "CONTAINER PAPER HOT FIRE 16OZ 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510516",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087655",
        "Product Status": "Active"
    },
    {
        "Root": "10019915",
        "LV": "1",
        "Item Description": "SNOWMAN W/SQUIRREL XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "YO-2590",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019915",
        "Product Status": "Active"
    },
    {
        "Root": "10012964",
        "LV": "1",
        "Item Description": "RHUBARB MR FRYE'S 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MRF-2",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012964",
        "Product Status": "Active"
    },
    {
        "Root": "10090514",
        "LV": "1",
        "Item Description": "BEER BOOKLYN PULP ART HAZY IPA CAN 12OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14179",
        "Last Price Paid": "$1.41",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090514",
        "Product Status": "Active"
    },
    {
        "Root": "10001704",
        "LV": "1",
        "Item Description": "HERB TARRAGON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001704001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001704",
        "Product Status": "Active"
    },
    {
        "Root": "10001704",
        "LV": "1",
        "Item Description": "HERB TARRAGON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001704001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482259",
        "Last Price Paid": "$12.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001704",
        "Product Status": "Active"
    },
    {
        "Root": "10001704",
        "LV": "1",
        "Item Description": "HERB TARRAGON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001704001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24180",
        "Last Price Paid": "$12.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001704",
        "Product Status": "Active"
    },
    {
        "Root": "10021674",
        "LV": "1",
        "Item Description": "SEASONING SOY MAGGI F&W TEMP SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SCE900",
        "Last Price Paid": "$35.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021674",
        "Product Status": "Active"
    },
    {
        "Root": "10009864",
        "LV": "1",
        "Item Description": "PORT FONSECA BIN 27 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273784",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009864",
        "Product Status": "Active"
    },
    {
        "Root": "10001847",
        "LV": "1",
        "Item Description": "TANGERINE SUNBURST 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001847",
        "Product Status": "Active"
    },
    {
        "Root": "10001847",
        "LV": "1",
        "Item Description": "TANGERINE SUNBURST 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "212085",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001847",
        "Product Status": "Active"
    },
    {
        "Root": "10028997",
        "LV": "1",
        "Item Description": "WINE LAURENT PERRIER 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "911125",
        "Last Price Paid": "$13.52",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028997",
        "Product Status": "Active"
    },
    {
        "Root": "10041164",
        "LV": "2",
        "Item Description": "STRAW PAPER WHITE UNWRAPPED 8.5 IN 2000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041164001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10041164",
        "Product Status": "Active"
    },
    {
        "Root": "10031632",
        "LV": "2",
        "Item Description": "DUMPLING PORK & VEGETABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031632002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.15",
        "Case Pack": "382",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031632",
        "Product Status": "Active"
    },
    {
        "Root": "10027262",
        "LV": "1",
        "Item Description": "MIX HAPPY HOUR 6/8 0Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891056",
        "Last Price Paid": "$2.84",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027262",
        "Product Status": "Active"
    },
    {
        "Root": "10016945",
        "LV": "1",
        "Item Description": "SPICE CURRY POWDER PREMIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016945001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164000",
        "Last Price Paid": "$6.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016945",
        "Product Status": "Active"
    },
    {
        "Root": "10014063",
        "LV": "1",
        "Item Description": "COCOA BUTTER INDIGO BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5863080000",
        "Last Price Paid": "$19.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014063",
        "Product Status": "Active"
    },
    {
        "Root": "10017015",
        "LV": "1",
        "Item Description": "SHELL GRAHAM 2.2\" ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD302",
        "Last Price Paid": "$0.36",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017015",
        "Product Status": "Active"
    },
    {
        "Root": "10034429",
        "LV": "2",
        "Item Description": "PORT TAYLOR-FLADGATE FINE TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034429002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273564",
        "Last Price Paid": "$11.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034429",
        "Product Status": "Active"
    },
    {
        "Root": "10008156",
        "LV": "1",
        "Item Description": "MALBEC CANTENA MENDOZA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "335758",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008156",
        "Product Status": "Active"
    },
    {
        "Root": "10000792",
        "LV": "1",
        "Item Description": "PORK FAT BACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000792001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1430349",
        "Last Price Paid": "$2.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000792",
        "Product Status": "Active"
    },
    {
        "Root": "10027907",
        "LV": "1",
        "Item Description": "PROSECCO MASCHIO DEI CAVALIERI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "158304",
        "Last Price Paid": "$7.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027907",
        "Product Status": "Active"
    },
    {
        "Root": "10011761",
        "LV": "1",
        "Item Description": "VENISON SADDLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011761001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VN035",
        "Last Price Paid": "$15.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011761",
        "Product Status": "Active"
    },
    {
        "Root": "10036096",
        "LV": "1",
        "Item Description": "TWININGS SUPPORT WHITE HIBISCUS WITH LIME & GINGER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F15006",
        "Last Price Paid": "$2.54",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036096",
        "Product Status": "Active"
    },
    {
        "Root": "10018267",
        "LV": "1",
        "Item Description": "BREAD FRENCH STRIPS 3/4 X 3/4 X 3-1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "2412CU",
        "Last Price Paid": "$12.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018267",
        "Product Status": "Active"
    },
    {
        "Root": "10019977",
        "LV": "1",
        "Item Description": "TUNA PORTION 4OZ 10 LBS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019977001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "70-65047",
        "Last Price Paid": "$7.55",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019977",
        "Product Status": "Active"
    },
    {
        "Root": "10019977",
        "LV": "1",
        "Item Description": "TUNA PORTION 4OZ 10 LBS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019977001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6505258",
        "Last Price Paid": "$7.55",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019977",
        "Product Status": "Active"
    },
    {
        "Root": "10019977",
        "LV": "1",
        "Item Description": "TUNA PORTION 4OZ 10 LBS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019977001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.55",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019977",
        "Product Status": "Active"
    },
    {
        "Root": "10002919",
        "LV": "2",
        "Item Description": "WATER VITAMIN ZERO RISE 20Z 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002919002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002919",
        "Product Status": "Active"
    },
    {
        "Root": "10036534",
        "LV": "1",
        "Item Description": "SHOT GLASS P20 CLEAR 2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11178495",
        "Last Price Paid": "$0.09",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036534",
        "Product Status": "Active"
    },
    {
        "Root": "10004973",
        "LV": "1",
        "Item Description": "PUREE BLACK CURRANT 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "545...{BOIRON{",
        "Last Price Paid": "$15.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004973",
        "Product Status": "Active"
    },
    {
        "Root": "10006671",
        "LV": "1",
        "Item Description": "CUPCAKES, 12 EA, VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "4",
        "Last Price Paid": "$5.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006671",
        "Product Status": "Active"
    },
    {
        "Root": "10036310",
        "LV": "3",
        "Item Description": "FLAXSEEDS BOB'S RED MILL GOLDEN WHOLE 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036310002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101143",
        "Last Price Paid": "$2.32",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10036310",
        "Product Status": "Active"
    },
    {
        "Root": "10033898",
        "LV": "1",
        "Item Description": "DRAFT IPA DOGFISH HEAD 60 MINUTE 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12333",
        "Last Price Paid": "$216.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033898",
        "Product Status": "Active"
    },
    {
        "Root": "10003594",
        "LV": "1",
        "Item Description": "CANDY SKITTLES TROP 1.72Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534391",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003594",
        "Product Status": "Active"
    },
    {
        "Root": "10016953",
        "LV": "1",
        "Item Description": "SPICE CARAWAY SEED WHL 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760074",
        "Last Price Paid": "$12.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016953",
        "Product Status": "Active"
    },
    {
        "Root": "10010151",
        "LV": "2",
        "Item Description": "WATER VOSS STILL 800ML 12EA GLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010151001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9420316",
        "Last Price Paid": "$2.77",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010151",
        "Product Status": "Active"
    },
    {
        "Root": "10044392",
        "LV": "1",
        "Item Description": "PASTRY, EPCOT, TYROPITA MINT CHEESE 144/1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5906921",
        "Last Price Paid": "$0.42",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044392",
        "Product Status": "Active"
    },
    {
        "Root": "10009974",
        "LV": "1",
        "Item Description": "LIQ KWV VANDERHUM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009974",
        "Product Status": "Active"
    },
    {
        "Root": "10001282",
        "LV": "1",
        "Item Description": "MIX MM SMOOTHIE STRAWBERRY 12/32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500008212",
        "Last Price Paid": "$5.18",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001282",
        "Product Status": "Active"
    },
    {
        "Root": "10094637",
        "LV": "1",
        "Item Description": "SB COOKIES & CREAM CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11135355",
        "Last Price Paid": "$0.85",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094637",
        "Product Status": "Active"
    },
    {
        "Root": "10000260",
        "LV": "1",
        "Item Description": "CAULIFLOWER CARNIVAL TRIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "CAUTRI",
        "Last Price Paid": "$36.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000260",
        "Product Status": "Active"
    },
    {
        "Root": "10000260",
        "LV": "1",
        "Item Description": "CAULIFLOWER CARNIVAL TRIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "487799",
        "Last Price Paid": "$36.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000260",
        "Product Status": "Active"
    },
    {
        "Root": "10000260",
        "LV": "1",
        "Item Description": "CAULIFLOWER CARNIVAL TRIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20338",
        "Last Price Paid": "$36.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000260",
        "Product Status": "Active"
    },
    {
        "Root": "10040959",
        "LV": "2",
        "Item Description": "PUREE BLUEBERRY 6/1KG ANDROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040959002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "816966",
        "Last Price Paid": "$14.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040959",
        "Product Status": "Active"
    },
    {
        "Root": "10008090",
        "LV": "1",
        "Item Description": "CHARD HAMILTON RUSSEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008090001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2773240",
        "Last Price Paid": "$32.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008090",
        "Product Status": "Active"
    },
    {
        "Root": "10101131",
        "LV": "1",
        "Item Description": "ALE CIDER CAN KOPPARBERG STRWBRRY LIME 11.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "57048",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101131",
        "Product Status": "Active"
    },
    {
        "Root": "10013990",
        "LV": "1",
        "Item Description": "DEC CRISPEARL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C25602",
        "Last Price Paid": "$14.28",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013990",
        "Product Status": "Active"
    },
    {
        "Root": "10087141",
        "LV": "1",
        "Item Description": "DEC CHOC GF GINGERBREAD HOUSE LOGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW55748",
        "Last Price Paid": "$2.25",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087141",
        "Product Status": "Active"
    },
    {
        "Root": "10019217",
        "LV": "1",
        "Item Description": "MUSHROOM CHANTRELLE 3 LB EUROPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019217001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSWIL011",
        "Last Price Paid": "$26.00",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019217",
        "Product Status": "Active"
    },
    {
        "Root": "10004345",
        "LV": "1",
        "Item Description": "OATMEAL BROWN SUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7892037",
        "Last Price Paid": "$1.14",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004345",
        "Product Status": "Active"
    },
    {
        "Root": "10101990",
        "LV": "1",
        "Item Description": "CHIP BAKED CHEETOS FLAMIN HOT WHOLE GRAIN  104/.88",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2939505",
        "Last Price Paid": "$0.35",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101990",
        "Product Status": "Active"
    },
    {
        "Root": "10102154",
        "LV": "1",
        "Item Description": "COOKIE FR GINGER INDIV WRAP 1.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102154",
        "Product Status": "Active"
    },
    {
        "Root": "10004820",
        "LV": "3",
        "Item Description": "OIL GRAPESEED 5 LITERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004820003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA6001",
        "Last Price Paid": "$37.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10004820",
        "Product Status": "Active"
    },
    {
        "Root": "10031920",
        "LV": "2",
        "Item Description": "SB NITRO COLD BREW LID 16OZ 510-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031920002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11071344",
        "Last Price Paid": "$0.07",
        "Case Pack": "510",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031920",
        "Product Status": "Active"
    },
    {
        "Root": "10102150",
        "LV": "1",
        "Item Description": "COOKIE CHOC CHIP INDIV WRAP 1.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102150",
        "Product Status": "Active"
    },
    {
        "Root": "10010516",
        "LV": "1",
        "Item Description": "LEMBERGER TROCKEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388434",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010516",
        "Product Status": "Active"
    },
    {
        "Root": "10001426",
        "LV": "1",
        "Item Description": "CORN RSTED W/PEPPR&ONION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4237228",
        "Last Price Paid": "$5.68",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001426",
        "Product Status": "Active"
    },
    {
        "Root": "10001653",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE SLCD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001653001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405086",
        "Last Price Paid": "$27.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001653",
        "Product Status": "Active"
    },
    {
        "Root": "10023496",
        "LV": "1",
        "Item Description": "DRIED FRUIT COCONUT LARGE FLAKE 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "281200",
        "Last Price Paid": "$81.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023496",
        "Product Status": "Active"
    },
    {
        "Root": "10009244",
        "LV": "2",
        "Item Description": "CHARD SHAFER RED SHLDR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009244001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9654988",
        "Last Price Paid": "$46.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009244",
        "Product Status": "Active"
    },
    {
        "Root": "10002208",
        "LV": "1",
        "Item Description": "MILK HOMO 8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002208",
        "Product Status": "Active"
    },
    {
        "Root": "10000391",
        "LV": "1",
        "Item Description": "CHIVES MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MC-33-E",
        "Last Price Paid": "$13.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000391",
        "Product Status": "Active"
    },
    {
        "Root": "10000391",
        "LV": "1",
        "Item Description": "CHIVES MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401147",
        "Last Price Paid": "$13.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000391",
        "Product Status": "Active"
    },
    {
        "Root": "10019713",
        "LV": "1",
        "Item Description": "VINEGAR SHERRY POMMERY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019713001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN152",
        "Last Price Paid": "$13.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019713",
        "Product Status": "Active"
    },
    {
        "Root": "10008250",
        "LV": "1",
        "Item Description": "CABBERNET BLEND QUINTESSA NAPA 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "559459",
        "Last Price Paid": "$90.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008250",
        "Product Status": "Active"
    },
    {
        "Root": "10008820",
        "LV": "3",
        "Item Description": "BORDEAUX CH PONTENT CANET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008820002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300997",
        "Last Price Paid": "$115.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008820",
        "Product Status": "Active"
    },
    {
        "Root": "10001995",
        "LV": "1",
        "Item Description": "PEPPER ARBOL DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001995",
        "Product Status": "Active"
    },
    {
        "Root": "10001995",
        "LV": "1",
        "Item Description": "PEPPER ARBOL DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407937",
        "Last Price Paid": "$23.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001995",
        "Product Status": "Active"
    },
    {
        "Root": "10008180",
        "LV": "1",
        "Item Description": "MERLOT DUCKHORN NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "560891",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008180",
        "Product Status": "Active"
    },
    {
        "Root": "10002845",
        "LV": "1",
        "Item Description": "NUT WALNUT SYRUP PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002845001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "183203",
        "Last Price Paid": "$83.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002845",
        "Product Status": "Active"
    },
    {
        "Root": "10027520",
        "LV": "1",
        "Item Description": "DECOR PCB CREATION PETITE FLOWERS TRIO WHITE CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "616320",
        "Last Price Paid": "$0.38",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027520",
        "Product Status": "Active"
    },
    {
        "Root": "10005946",
        "LV": "1",
        "Item Description": "BREAD ROLL KAISER FLOUR DUSTED EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005946",
        "Product Status": "Active"
    },
    {
        "Root": "10102710",
        "LV": "2",
        "Item Description": "DONUT RED VELVET GLAZED CAKE DZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102710002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS205",
        "Last Price Paid": "$0.83",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102710",
        "Product Status": "Active"
    },
    {
        "Root": "10003997",
        "LV": "2",
        "Item Description": "CANDY SEASONAL CANDY CANE MINI 8/240 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003997002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "288",
        "Last Price Paid": "$0.10",
        "Case Pack": "1920",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003997",
        "Product Status": "Active"
    },
    {
        "Root": "10023531",
        "LV": "2",
        "Item Description": "BAR CRANBERRY ALMOND KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023531002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "303330",
        "Last Price Paid": "$2.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10023531",
        "Product Status": "Active"
    },
    {
        "Root": "10036320",
        "LV": "1",
        "Item Description": "PROESPUMA COLD 700G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6721280000",
        "Last Price Paid": "$17.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036320",
        "Product Status": "Active"
    },
    {
        "Root": "10003063",
        "LV": "3",
        "Item Description": "PASTA DRY PENNE WHL GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003063003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10003063",
        "Product Status": "Active"
    },
    {
        "Root": "10038105",
        "LV": "1",
        "Item Description": "LIQUEUR LA FAVORITE PETITE SHRUBB 1.0L 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038105001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "100039",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038105",
        "Product Status": "Active"
    },
    {
        "Root": "10012637",
        "LV": "1",
        "Item Description": "COASTER ROUND PYTHON HANGER BAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-019",
        "Last Price Paid": "",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012637",
        "Product Status": "Active"
    },
    {
        "Root": "10014541",
        "LV": "1",
        "Item Description": "TINY CUCUMBER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480399",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014541",
        "Product Status": "Active"
    },
    {
        "Root": "10030033",
        "LV": "1",
        "Item Description": "KRAFT SNACK SAK STAND UP A-4 GREASE RESISTANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51492023",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030033",
        "Product Status": "Active"
    },
    {
        "Root": "10037929",
        "LV": "1",
        "Item Description": "PEPPERS NEW MEXICO DRIED S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407126",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037929",
        "Product Status": "Active"
    },
    {
        "Root": "10033158",
        "LV": "1",
        "Item Description": "PEANUT BUTTER TRU NUT POWDERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "171894",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033158",
        "Product Status": "Active"
    },
    {
        "Root": "10015276",
        "LV": "1",
        "Item Description": "OLIVES TAGGIASCA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015276001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OV410",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015276",
        "Product Status": "Active"
    },
    {
        "Root": "10030351",
        "LV": "1",
        "Item Description": "SEASONING TAJIN 14 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030351",
        "Product Status": "Active"
    },
    {
        "Root": "10034764",
        "LV": "1",
        "Item Description": "GLAZE MIRROR WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "646005",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034764",
        "Product Status": "Active"
    },
    {
        "Root": "10008776",
        "LV": "1",
        "Item Description": "SAUV BLC REYNEKE WHT RSV 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008776001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9238235",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008776",
        "Product Status": "Active"
    },
    {
        "Root": "10107951",
        "LV": "1",
        "Item Description": "CUP SIPPER PREM WISH STAR 16OZ 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "CB-STAR316517",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107951",
        "Product Status": "Active"
    },
    {
        "Root": "10044729",
        "LV": "1",
        "Item Description": "SPICE CELERY SEED WHOLE 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760090",
        "Last Price Paid": "$10.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044729",
        "Product Status": "Active"
    },
    {
        "Root": "10031894",
        "LV": "1",
        "Item Description": "BEEF MARINATED SLICED BULK 2 / 5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031894001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190175",
        "Last Price Paid": "$6.32",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031894",
        "Product Status": "Active"
    },
    {
        "Root": "10004594",
        "LV": "1",
        "Item Description": "SUGAR CUBE BROWN 35Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6323364",
        "Last Price Paid": "$6.40",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004594",
        "Product Status": "Active"
    },
    {
        "Root": "10094668",
        "LV": "1",
        "Item Description": "BOWL PAPER CONTAINER  KRAFT SOUP 8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51054148",
        "Last Price Paid": "$0.15",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094668",
        "Product Status": "Active"
    },
    {
        "Root": "10030011",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE CANDY CANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "23111",
        "Last Price Paid": "$0.46",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030011",
        "Product Status": "Active"
    },
    {
        "Root": "10039734",
        "LV": "1",
        "Item Description": "FLOUR CHICK PEA 6.95#/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "290790",
        "Last Price Paid": "$61.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039734",
        "Product Status": "Active"
    },
    {
        "Root": "10012991",
        "LV": "1",
        "Item Description": "PASTA FROZEN RAVIOLI RICOTTA SPINACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070114",
        "Last Price Paid": "$16.40",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012991",
        "Product Status": "Active"
    },
    {
        "Root": "10102134",
        "LV": "1",
        "Item Description": "BEER KEG COLLECTIVE ARTS STRANGER MAPLE COFFEE POR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15559",
        "Last Price Paid": "$204.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102134",
        "Product Status": "Active"
    },
    {
        "Root": "10032899",
        "LV": "1",
        "Item Description": "CAPPER SILVER CAP FOR PERRETTE BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032899001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51097007",
        "Last Price Paid": "$0.49",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032899",
        "Product Status": "Active"
    },
    {
        "Root": "10003347",
        "LV": "1",
        "Item Description": "DRINK MONST JAVA M BEAN 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.16",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003347",
        "Product Status": "Active"
    },
    {
        "Root": "10009926",
        "LV": "2",
        "Item Description": "LIQ SOLERNO BLOOD ORNG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009926001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9079658",
        "Last Price Paid": "$28.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009926",
        "Product Status": "Active"
    },
    {
        "Root": "10012024",
        "LV": "1",
        "Item Description": "MUSTARD MIXED BLOOMS 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012024001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09MMMB-33",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012024",
        "Product Status": "Active"
    },
    {
        "Root": "10003586",
        "LV": "1",
        "Item Description": "QUIN STAR RED WHITE BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9496",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003586",
        "Product Status": "Active"
    },
    {
        "Root": "10037123",
        "LV": "2",
        "Item Description": "SPRITE DELOS 12/13.5 OZ STARWARS GALAXYS EDGE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037123002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037123",
        "Product Status": "Active"
    },
    {
        "Root": "10037032",
        "LV": "1",
        "Item Description": "OIL OLIVE EV CORATINA PUGLIA ITALY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-011078",
        "Last Price Paid": "$19.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037032",
        "Product Status": "Active"
    },
    {
        "Root": "10002272",
        "LV": "1",
        "Item Description": "CHEESE BLUE CAVEMAN ORGANIC ROGUE CREAMERY 5 LB WH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002272001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "118071",
        "Last Price Paid": "$21.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002272",
        "Product Status": "Active"
    },
    {
        "Root": "10105040",
        "LV": "1",
        "Item Description": "GYOZA SKINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35130",
        "Last Price Paid": "$3.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105040",
        "Product Status": "Active"
    },
    {
        "Root": "10001020",
        "LV": "1",
        "Item Description": "BASS STRIPED FILET SKN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001020001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001020",
        "Product Status": "Active"
    },
    {
        "Root": "10001020",
        "LV": "1",
        "Item Description": "BASS STRIPED FILET SKN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001020001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "7527732",
        "Last Price Paid": "$18.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001020",
        "Product Status": "Active"
    },
    {
        "Root": "10001020",
        "LV": "1",
        "Item Description": "BASS STRIPED FILET SKN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001020001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00040",
        "Last Price Paid": "$18.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001020",
        "Product Status": "Active"
    },
    {
        "Root": "10028957",
        "LV": "1",
        "Item Description": "CHARDONNAY MONTES ALPHA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "292141",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028957",
        "Product Status": "Active"
    },
    {
        "Root": "10084167",
        "LV": "1",
        "Item Description": "LINER BASKET 8.5X12 HEAVY WEIGHT 4 COLORS 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51015077",
        "Last Price Paid": "$0.05",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084167",
        "Product Status": "Active"
    },
    {
        "Root": "10004980",
        "LV": "1",
        "Item Description": "GLUCOSE POWERED DRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004980001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA 514",
        "Last Price Paid": "$38.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004980",
        "Product Status": "Active"
    },
    {
        "Root": "10100748",
        "LV": "1",
        "Item Description": "TEACAKES STRAWBERRY 108 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "7110137",
        "Last Price Paid": "$0.41",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100748",
        "Product Status": "Active"
    },
    {
        "Root": "10040136",
        "LV": "1",
        "Item Description": "SOUP TOMATO BASIL BISQUE 4/4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8831687",
        "Last Price Paid": "$11.96",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040136",
        "Product Status": "Active"
    },
    {
        "Root": "10030428",
        "LV": "1",
        "Item Description": "BRUNELLO CAMIGLIANO RIS GUALTO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "524797",
        "Last Price Paid": "$79.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030428",
        "Product Status": "Active"
    },
    {
        "Root": "10009852",
        "LV": "1",
        "Item Description": "COGNAC REMY MARTIN XO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294598",
        "Last Price Paid": "$123.30",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009852",
        "Product Status": "Active"
    },
    {
        "Root": "10007467",
        "LV": "2",
        "Item Description": "BLND CHAT CADRANS DE LASSEGUE GRCRU 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007467001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "586232",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007467",
        "Product Status": "Active"
    },
    {
        "Root": "10023355",
        "LV": "3",
        "Item Description": "POTATO FRYER 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023355002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10023355",
        "Product Status": "Active"
    },
    {
        "Root": "10104603",
        "LV": "1",
        "Item Description": "PASSOVER BSC ASSORTED COOKIES 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.34",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104603",
        "Product Status": "Active"
    },
    {
        "Root": "10040747",
        "LV": "1",
        "Item Description": "PINEAPPLE WEDGE LONG 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "800204",
        "Last Price Paid": "$15.83",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040747",
        "Product Status": "Active"
    },
    {
        "Root": "10002410",
        "LV": "1",
        "Item Description": "CHEESE MUENSTER 5.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002410001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5660",
        "Last Price Paid": "$3.92",
        "Case Pack": "5.5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002410",
        "Product Status": "Active"
    },
    {
        "Root": "10036035",
        "LV": "1",
        "Item Description": "BEEFEATER LONDON PINK GIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "918336",
        "Last Price Paid": "$21.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036035",
        "Product Status": "Active"
    },
    {
        "Root": "10105042",
        "LV": "1",
        "Item Description": "CURRY PASTE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-36065",
        "Last Price Paid": "$7.05",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105042",
        "Product Status": "Active"
    },
    {
        "Root": "10018809",
        "LV": "1",
        "Item Description": "POWDER DECOR SUPER PEARL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1001768",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018809",
        "Product Status": "Active"
    },
    {
        "Root": "10003404",
        "LV": "2",
        "Item Description": "SPICE HERB DE PROVINCE 6/0.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003404002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003404",
        "Product Status": "Active"
    },
    {
        "Root": "10033429",
        "LV": "1",
        "Item Description": "BELUGA VODKA ALLURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "592594",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033429",
        "Product Status": "Active"
    },
    {
        "Root": "10039826",
        "LV": "1",
        "Item Description": "ICE BALLS 2 INCH 320/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609904",
        "Vendor": "ICE PROFESSIONALS OF FLORIDA LLC",
        "Vendor Item Nb": "WBALL2-320",
        "Last Price Paid": "$0.96",
        "Case Pack": "320",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039826",
        "Product Status": "Active"
    },
    {
        "Root": "10014623",
        "LV": "1",
        "Item Description": "FLOWER PENNY JUMP UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450251",
        "Last Price Paid": "$0.38",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014623",
        "Product Status": "Active"
    },
    {
        "Root": "10012173",
        "LV": "1",
        "Item Description": "CHIVES MEMO GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012173001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MCI-33-E",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012173",
        "Product Status": "Active"
    },
    {
        "Root": "10001919",
        "LV": "1",
        "Item Description": "PEA TENDRILS YEL 1/2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001919001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001919",
        "Product Status": "Active"
    },
    {
        "Root": "10001919",
        "LV": "1",
        "Item Description": "PEA TENDRILS YEL 1/2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001919001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460201",
        "Last Price Paid": "$18.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001919",
        "Product Status": "Active"
    },
    {
        "Root": "10018609",
        "LV": "1",
        "Item Description": "TOMATO MIXED BABY SPEC 1/2 PT FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17MBS-11",
        "Last Price Paid": "$93.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018609",
        "Product Status": "Active"
    },
    {
        "Root": "10037879",
        "LV": "3",
        "Item Description": "RICE CAULIFLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037879003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407862",
        "Last Price Paid": "$6.73",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10037879",
        "Product Status": "Active"
    },
    {
        "Root": "10004975",
        "LV": "2",
        "Item Description": "CHOCOLATE CARAMEL 31.2% COCOA BARRY LACTEE 4/11 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004975002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C5871",
        "Last Price Paid": "$84.52",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004975",
        "Product Status": "Active"
    },
    {
        "Root": "10000654",
        "LV": "1",
        "Item Description": "BEEF DRIED SLICED BRESAOLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000654001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3200444",
        "Last Price Paid": "$21.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000654",
        "Product Status": "Active"
    },
    {
        "Root": "10103513",
        "LV": "1",
        "Item Description": "DONUT CROISSANT DOUGH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103513001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "6492",
        "Last Price Paid": "$0.53",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103513",
        "Product Status": "Active"
    },
    {
        "Root": "10092971",
        "LV": "1",
        "Item Description": "LIME-FROSTED COCONUT BAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11134136",
        "Last Price Paid": "$1.10",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092971",
        "Product Status": "Active"
    },
    {
        "Root": "10027329",
        "LV": "2",
        "Item Description": "MERLOT BOOMTOWN 2014 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027329001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "593140",
        "Last Price Paid": "$13.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10027329",
        "Product Status": "Active"
    },
    {
        "Root": "10026480",
        "LV": "1",
        "Item Description": "MERLOT COLUMBIA CREST H3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "529515",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026480",
        "Product Status": "Active"
    },
    {
        "Root": "10001901",
        "LV": "1",
        "Item Description": "SQUASH KABOCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001901",
        "Product Status": "Active"
    },
    {
        "Root": "10001901",
        "LV": "1",
        "Item Description": "SQUASH KABOCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "409082",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001901",
        "Product Status": "Active"
    },
    {
        "Root": "10039530",
        "LV": "1",
        "Item Description": "DRAFT GOOSE ISLAND IPA 1/6 BARREL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21406",
        "Last Price Paid": "$83.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039530",
        "Product Status": "Active"
    },
    {
        "Root": "10025960",
        "LV": "1",
        "Item Description": "PEPPERMINT BARK DRK WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW50345",
        "Last Price Paid": "$9.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025960",
        "Product Status": "Active"
    },
    {
        "Root": "10004605",
        "LV": "1",
        "Item Description": "BEAN REFRIED QUE BUENO #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8212643",
        "Last Price Paid": "$7.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004605",
        "Product Status": "Active"
    },
    {
        "Root": "10027923",
        "LV": "1",
        "Item Description": "WHISKEY KNOB CREEK RYE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "581362",
        "Last Price Paid": "$29.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027923",
        "Product Status": "Active"
    },
    {
        "Root": "10005736",
        "LV": "1",
        "Item Description": "BREAD PITA WHEAT 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "93080",
        "Last Price Paid": "$0.22",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005736",
        "Product Status": "Active"
    },
    {
        "Root": "10021595",
        "LV": "1",
        "Item Description": "RIESLING TRIMBACH 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "585284",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021595",
        "Product Status": "Active"
    },
    {
        "Root": "10036319",
        "LV": "1",
        "Item Description": "POWDER SOY LECITHIN 500G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036319001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6721170000",
        "Last Price Paid": "$19.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036319",
        "Product Status": "Active"
    },
    {
        "Root": "10005752",
        "LV": "1",
        "Item Description": "BAGEL PLAIN 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005752",
        "Product Status": "Active"
    },
    {
        "Root": "10004799",
        "LV": "1",
        "Item Description": "SAUCE WORCHESTERSHIRE 4/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4004446",
        "Last Price Paid": "$8.60",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004799",
        "Product Status": "Active"
    },
    {
        "Root": "10031417",
        "LV": "1",
        "Item Description": "JUICE BASE PINEAPPLE 100% FROZEN CONCENTRATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7392186",
        "Last Price Paid": "$102.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031417",
        "Product Status": "Active"
    },
    {
        "Root": "10005174",
        "LV": "1",
        "Item Description": "CHOC CARRIAGE W/2 HORSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "1000067785",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005174",
        "Product Status": "Active"
    },
    {
        "Root": "10004833",
        "LV": "1",
        "Item Description": "MARSHMALLOW LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "61168",
        "Last Price Paid": "$1.51",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004833",
        "Product Status": "Active"
    },
    {
        "Root": "10015404",
        "LV": "1",
        "Item Description": "DEC ROSE MED WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKT WH",
        "Last Price Paid": "$1.80",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015404",
        "Product Status": "Active"
    },
    {
        "Root": "10011744",
        "LV": "1",
        "Item Description": "TUNA SAKU BLOCK SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011744001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "70-65097",
        "Last Price Paid": "$15.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011744",
        "Product Status": "Active"
    },
    {
        "Root": "10011744",
        "LV": "1",
        "Item Description": "TUNA SAKU BLOCK SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011744001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "0244170",
        "Last Price Paid": "$15.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011744",
        "Product Status": "Active"
    },
    {
        "Root": "10011744",
        "LV": "1",
        "Item Description": "TUNA SAKU BLOCK SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011744001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "TUN410250",
        "Last Price Paid": "$15.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011744",
        "Product Status": "Active"
    },
    {
        "Root": "10037928",
        "LV": "1",
        "Item Description": "BULLSEYE WHITE COMPOUND ROUND 4CM 1440PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233300000",
        "Last Price Paid": "$0.31",
        "Case Pack": "1440",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037928",
        "Product Status": "Active"
    },
    {
        "Root": "10007072",
        "LV": "1",
        "Item Description": "VERMINTINO GUADO AL TASSO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "585193",
        "Last Price Paid": "$22.43",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007072",
        "Product Status": "Active"
    },
    {
        "Root": "10003653",
        "LV": "2",
        "Item Description": "SB FRPPCCN ROAST COFFEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003653001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011116348",
        "Last Price Paid": "$7.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003653",
        "Product Status": "Active"
    },
    {
        "Root": "10002863",
        "LV": "1",
        "Item Description": "KETCHUP DICKNSON 1.4Z 72C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002863001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002863",
        "Product Status": "Active"
    },
    {
        "Root": "10033010",
        "LV": "2",
        "Item Description": "SALT FINE KOSHER 4LB 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033010002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.72",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033010",
        "Product Status": "Active"
    },
    {
        "Root": "10003620",
        "LV": "1",
        "Item Description": "SB 5LB ESPRESSO ROAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7360",
        "Last Price Paid": "$49.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003620",
        "Product Status": "Active"
    },
    {
        "Root": "10001214",
        "LV": "1",
        "Item Description": "GROUPER BIAS CUT 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001214001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01224-D",
        "Last Price Paid": "$31.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001214",
        "Product Status": "Active"
    },
    {
        "Root": "10001214",
        "LV": "1",
        "Item Description": "GROUPER BIAS CUT 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001214001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001214",
        "Product Status": "Active"
    },
    {
        "Root": "10001214",
        "LV": "1",
        "Item Description": "GROUPER BIAS CUT 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001214001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6497540",
        "Last Price Paid": "$31.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001214",
        "Product Status": "Active"
    },
    {
        "Root": "10007873",
        "LV": "1",
        "Item Description": "PINOT NOIR EMERITUS 09 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "566323",
        "Last Price Paid": "$32.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007873",
        "Product Status": "Active"
    },
    {
        "Root": "10004108",
        "LV": "1",
        "Item Description": "CANDY 3 MUSKATEERS 36/1.9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534342",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004108",
        "Product Status": "Active"
    },
    {
        "Root": "10036891",
        "LV": "2",
        "Item Description": "BAKING CUP GREEN SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036891002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24736",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036891",
        "Product Status": "Active"
    },
    {
        "Root": "10001781",
        "LV": "1",
        "Item Description": "DAIKON ROOT - CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482050",
        "Last Price Paid": "$36.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001781",
        "Product Status": "Active"
    },
    {
        "Root": "10001781",
        "LV": "1",
        "Item Description": "DAIKON ROOT - CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001781",
        "Product Status": "Active"
    },
    {
        "Root": "10004025",
        "LV": "1",
        "Item Description": "BASE DSRT VANIL CRM BRULE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004025001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118808",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004025",
        "Product Status": "Active"
    },
    {
        "Root": "10030325",
        "LV": "1",
        "Item Description": "DISNEY COTTON CANDY BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3071",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030325",
        "Product Status": "Active"
    },
    {
        "Root": "10088795",
        "LV": "1",
        "Item Description": "CHAMP RARE BRUT MILLESIME 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9624284",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088795",
        "Product Status": "Active"
    },
    {
        "Root": "10004534",
        "LV": "1",
        "Item Description": "JUICE CLAMATO 12/1LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004534",
        "Product Status": "Active"
    },
    {
        "Root": "10013720",
        "LV": "1",
        "Item Description": "BEAN FRENCH HORTICULTURAL FRESH CRANBERRY #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013720001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "20FH-2",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013720",
        "Product Status": "Active"
    },
    {
        "Root": "10028852",
        "LV": "1",
        "Item Description": "CHEESE SPREAD BOURSIN PEPPER FRANCE 6/5.2 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "180",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028852",
        "Product Status": "Active"
    },
    {
        "Root": "10015418",
        "LV": "1",
        "Item Description": "DEC POINSETTIA RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8992",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015418",
        "Product Status": "Active"
    },
    {
        "Root": "10016260",
        "LV": "1",
        "Item Description": "PIE APPLE 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "834",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016260",
        "Product Status": "Active"
    },
    {
        "Root": "10104853",
        "LV": "2",
        "Item Description": "CUP COLD 21OZ SWGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104853002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104853",
        "Product Status": "Active"
    },
    {
        "Root": "10008265",
        "LV": "1",
        "Item Description": "RIES LATE HARVEST HOGUE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10253",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008265",
        "Product Status": "Active"
    },
    {
        "Root": "10040013",
        "LV": "1",
        "Item Description": "SPORK METAL SINGLES DB7 SWGE 240/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040013",
        "Product Status": "Active"
    },
    {
        "Root": "10000173",
        "LV": "1",
        "Item Description": "PINEAPPLE PREMIUM SELECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000173001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481589",
        "Last Price Paid": "$2.88",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000173",
        "Product Status": "Active"
    },
    {
        "Root": "10015495",
        "LV": "1",
        "Item Description": "CAB SAUV TORRES MAS PLANA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9094626",
        "Last Price Paid": "$81.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015495",
        "Product Status": "Active"
    },
    {
        "Root": "10002548",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 1/4INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "27-004-14",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002548",
        "Product Status": "Active"
    },
    {
        "Root": "10002548",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 1/4INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515031",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002548",
        "Product Status": "Active"
    },
    {
        "Root": "10002548",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 1/4INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30566",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002548",
        "Product Status": "Active"
    },
    {
        "Root": "10013273",
        "LV": "1",
        "Item Description": "BEET CHAMPAGNE ICE #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01CIT-2",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013273",
        "Product Status": "Active"
    },
    {
        "Root": "10102708",
        "LV": "3",
        "Item Description": "SPICE KOSHER SALT 4/9 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102708003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10102708",
        "Product Status": "Active"
    },
    {
        "Root": "10005789",
        "LV": "1",
        "Item Description": "MUFFIN CHOCOLATE DOUBLE GLUTEN FREE UDIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "781039",
        "Last Price Paid": "$4.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005789",
        "Product Status": "Active"
    },
    {
        "Root": "10027715",
        "LV": "1",
        "Item Description": "WHITE MONORAIL ONE CAR 3 1/8\" X 1\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3515",
        "Last Price Paid": "$0.85",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027715",
        "Product Status": "Active"
    },
    {
        "Root": "10012564",
        "LV": "1",
        "Item Description": "SPINACH ROOT 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012564001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04RISPI-10",
        "Last Price Paid": "$140.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012564",
        "Product Status": "Active"
    },
    {
        "Root": "10101151",
        "LV": "1",
        "Item Description": "CORNSTARCH 16OZ/ 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101151",
        "Product Status": "Active"
    },
    {
        "Root": "10036751",
        "LV": "1",
        "Item Description": "KIWI SLICED DRIED S/O 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390043",
        "Last Price Paid": "$5.03",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036751",
        "Product Status": "Active"
    },
    {
        "Root": "10003052",
        "LV": "2",
        "Item Description": "PEPPER RED ROASTED JULIEN 48OZ 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003052002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7523343",
        "Last Price Paid": "$11.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003052",
        "Product Status": "Active"
    },
    {
        "Root": "10037934",
        "LV": "1",
        "Item Description": "BAKING CHIP MEGA CHUNK SEMI SWT 5LB 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F00559W",
        "Last Price Paid": "$27.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037934",
        "Product Status": "Active"
    },
    {
        "Root": "10010221",
        "LV": "2",
        "Item Description": "BEER KEG LABATT BLUE LIGHT LAGER 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010221001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11325",
        "Last Price Paid": "$147.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010221",
        "Product Status": "Active"
    },
    {
        "Root": "10105138",
        "LV": "1",
        "Item Description": "ALMOND FLAVOR EMULSOIN 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "21109",
        "Last Price Paid": "$6.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105138",
        "Product Status": "Active"
    },
    {
        "Root": "10004538",
        "LV": "1",
        "Item Description": "JUICE PRUNE 5.5Z IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "948083",
        "Last Price Paid": "$0.89",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004538",
        "Product Status": "Active"
    },
    {
        "Root": "10008474",
        "LV": "1",
        "Item Description": "RIESLING C SMITH KUNG FU GIRL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008474001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550287",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008474",
        "Product Status": "Active"
    },
    {
        "Root": "10104435",
        "LV": "1",
        "Item Description": "BEER DRAFT BLAKES MULE DE POMME 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15622",
        "Last Price Paid": "$209.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104435",
        "Product Status": "Active"
    },
    {
        "Root": "10044479",
        "LV": "1",
        "Item Description": "PEPPER ROASTED RED 6.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "PEP001",
        "Last Price Paid": "$12.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044479",
        "Product Status": "Active"
    },
    {
        "Root": "10000770",
        "LV": "1",
        "Item Description": "LAMB HINDSHANK 24Z - 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000770001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240005",
        "Last Price Paid": "$10.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000770",
        "Product Status": "Active"
    },
    {
        "Root": "10106589",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER  STEEL GRAY 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313080E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106589",
        "Product Status": "Active"
    },
    {
        "Root": "10014413",
        "LV": "1",
        "Item Description": "SHRIMP 41/51 P&D T/OFF SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014413001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125581",
        "Last Price Paid": "$4.24",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014413",
        "Product Status": "Active"
    },
    {
        "Root": "10016534",
        "LV": "1",
        "Item Description": "HOMINY WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8015786",
        "Last Price Paid": "$4.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016534",
        "Product Status": "Active"
    },
    {
        "Root": "10005433",
        "LV": "1",
        "Item Description": "VEGANAISE ORIG DAIRY FREE 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "101016",
        "Last Price Paid": "$3.87",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005433",
        "Product Status": "Active"
    },
    {
        "Root": "10010240",
        "LV": "2",
        "Item Description": "DRFT BELGIUM FAT TIRE HBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010240001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10680",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010240",
        "Product Status": "Active"
    },
    {
        "Root": "10023186",
        "LV": "1",
        "Item Description": "POWDER COCOA BAKING UNSWEETENED MAJ DUTCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2051414",
        "Last Price Paid": "$16.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023186",
        "Product Status": "Active"
    },
    {
        "Root": "10007941",
        "LV": "1",
        "Item Description": "PINOTAGE FAIR VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007941",
        "Product Status": "Active"
    },
    {
        "Root": "10019394",
        "LV": "1",
        "Item Description": "MERLOT BLND CHATEAU LASSEGUE GRCRU 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "538534",
        "Last Price Paid": "$45.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019394",
        "Product Status": "Active"
    },
    {
        "Root": "10012853",
        "LV": "1",
        "Item Description": "AVIGNONESI ROSSO MONTEPULCIANO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "189309",
        "Last Price Paid": "$12.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012853",
        "Product Status": "Active"
    },
    {
        "Root": "10009682",
        "LV": "1",
        "Item Description": "TEQUILA LLEGALE MEZ JOV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "990142",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009682",
        "Product Status": "Active"
    },
    {
        "Root": "10003035",
        "LV": "1",
        "Item Description": "JUICE LEMON 8/48OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.91",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003035",
        "Product Status": "Active"
    },
    {
        "Root": "10104437",
        "LV": "1",
        "Item Description": "BEER DRAFT STEVENS POINT WHOLE HOG RASP SORBET 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13694",
        "Last Price Paid": "$204.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104437",
        "Product Status": "Active"
    },
    {
        "Root": "10036561",
        "LV": "1",
        "Item Description": "LID PLASTIC 24/32OZ FOR BAMBOO FIBER BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51070032",
        "Last Price Paid": "$0.19",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036561",
        "Product Status": "Active"
    },
    {
        "Root": "10002697",
        "LV": "1",
        "Item Description": "FLOUR BREAD 12% KING MIDAS SPECIAL 50# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002697001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002697",
        "Product Status": "Active"
    },
    {
        "Root": "10031615",
        "LV": "1",
        "Item Description": "CANDY SWEDISH FISH ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585950",
        "Last Price Paid": "$127.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031615",
        "Product Status": "Active"
    },
    {
        "Root": "10102888",
        "LV": "1",
        "Item Description": "ICE CREAM PEPPERMINT SWIRL 3 GAL TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "PP-125",
        "Last Price Paid": "$37.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102888",
        "Product Status": "Active"
    },
    {
        "Root": "10037373",
        "LV": "1",
        "Item Description": "SORBET BANANA 4/.5GAL/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-BANA-08-CS",
        "Last Price Paid": "$9.83",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037373",
        "Product Status": "Active"
    },
    {
        "Root": "10018499",
        "LV": "1",
        "Item Description": "ASPARAGUS PNK BLUSH PENCIL #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018499001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MPASP-2",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018499",
        "Product Status": "Active"
    },
    {
        "Root": "10006891",
        "LV": "1",
        "Item Description": "RIES JO FESS PARKER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9074616",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006891",
        "Product Status": "Active"
    },
    {
        "Root": "10009872",
        "LV": "1",
        "Item Description": "APERITIF APEROL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557366",
        "Last Price Paid": "$19.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009872",
        "Product Status": "Active"
    },
    {
        "Root": "10001450",
        "LV": "1",
        "Item Description": "SEEDS POMEGRANATE 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450201",
        "Last Price Paid": "$3.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001450",
        "Product Status": "Active"
    },
    {
        "Root": "10001450",
        "LV": "1",
        "Item Description": "SEEDS POMEGRANATE 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16193",
        "Last Price Paid": "$3.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001450",
        "Product Status": "Active"
    },
    {
        "Root": "10004989",
        "LV": "1",
        "Item Description": "MUFFIN CUP MED P60-40",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P6040",
        "Last Price Paid": "$0.09",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004989",
        "Product Status": "Active"
    },
    {
        "Root": "10088706",
        "LV": "1",
        "Item Description": "SYRUP MONIN PEAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1037",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088706",
        "Product Status": "Active"
    },
    {
        "Root": "10012568",
        "LV": "1",
        "Item Description": "FAVA BLOOMS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05FBE-24",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012568",
        "Product Status": "Active"
    },
    {
        "Root": "10018619",
        "LV": "1",
        "Item Description": "SHELL PIE 10X10 SHEET FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6017073",
        "Last Price Paid": "$1.14",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018619",
        "Product Status": "Active"
    },
    {
        "Root": "10034226",
        "LV": "1",
        "Item Description": "LID CLEAR FLAT NO HOLE A626PNS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034226001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51262146",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034226",
        "Product Status": "Active"
    },
    {
        "Root": "10107631",
        "LV": "1",
        "Item Description": "CRACKER, CHEDDAR GOLDFISH BAG 300/.75OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "2315760",
        "Last Price Paid": "$0.19",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107631",
        "Product Status": "Active"
    },
    {
        "Root": "10007746",
        "LV": "3",
        "Item Description": "SAKE SHO CHIKU REG 3LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007746002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "410",
        "Last Price Paid": "$16.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007746",
        "Product Status": "Active"
    },
    {
        "Root": "10034848",
        "LV": "1",
        "Item Description": "JUST EGG PATTY FROZEN 120 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "910110004100",
        "Last Price Paid": "$0.85",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034848",
        "Product Status": "Active"
    },
    {
        "Root": "10036968",
        "LV": "1",
        "Item Description": "BLEND RED MONTSANT CAN BLAU CELLARS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "161908",
        "Last Price Paid": "$28.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036968",
        "Product Status": "Active"
    },
    {
        "Root": "10033278",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC CONSTANTIA GLEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9230795",
        "Last Price Paid": "$17.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033278",
        "Product Status": "Active"
    },
    {
        "Root": "10007083",
        "LV": "1",
        "Item Description": "GEWUR ZIND HUMBRCH WINT ALSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007083",
        "Product Status": "Active"
    },
    {
        "Root": "10106749",
        "LV": "1",
        "Item Description": "CHEESE AMERICAN VELVEETA LOAF 6/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "33308",
        "Last Price Paid": "$15.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106749",
        "Product Status": "Active"
    },
    {
        "Root": "10002492",
        "LV": "1",
        "Item Description": "APPLE HEIRLOOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "195015",
        "Last Price Paid": "$1.62",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002492",
        "Product Status": "Active"
    },
    {
        "Root": "10014414",
        "LV": "1",
        "Item Description": "CABERNET SAUV STAGS LEAP NAPA VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9014254",
        "Last Price Paid": "$35.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014414",
        "Product Status": "Active"
    },
    {
        "Root": "10019942",
        "LV": "1",
        "Item Description": "BAG HANDLED DIE CUT 11X6X11",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091863",
        "Last Price Paid": "$0.22",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019942",
        "Product Status": "Active"
    },
    {
        "Root": "10001323",
        "LV": "1",
        "Item Description": "BEAN GREEN HARICOT VERT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.64",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001323",
        "Product Status": "Active"
    },
    {
        "Root": "10103850",
        "LV": "1",
        "Item Description": "CREAMER NON-DIARY ORIGINAL LIQUID IND 384CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "384",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103850",
        "Product Status": "Active"
    },
    {
        "Root": "10030321",
        "LV": "1",
        "Item Description": "DRIED FRUIT COCONUT SHRED SWEET FLAKE 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "281001",
        "Last Price Paid": "$2.74",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030321",
        "Product Status": "Active"
    },
    {
        "Root": "10085869",
        "LV": "1",
        "Item Description": "BREAD NATURES OWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000415720",
        "Vendor": "FLOWERS BAKING",
        "Vendor Item Nb": "10215260",
        "Last Price Paid": "$1.99",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085869",
        "Product Status": "Active"
    },
    {
        "Root": "10018850",
        "LV": "1",
        "Item Description": "POTATO SWEET 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490129",
        "Last Price Paid": "$14.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018850",
        "Product Status": "Active"
    },
    {
        "Root": "10022785",
        "LV": "1",
        "Item Description": "SYRUP BASIL MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C235FP",
        "Last Price Paid": "$6.57",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022785",
        "Product Status": "Active"
    },
    {
        "Root": "10002639",
        "LV": "1",
        "Item Description": "SPICE CELERY SALT 6/2 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002639",
        "Product Status": "Active"
    },
    {
        "Root": "10035818",
        "LV": "1",
        "Item Description": "CAPUTO FARINA SEMOLINA 1 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "RF45",
        "Last Price Paid": "$3.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035818",
        "Product Status": "Active"
    },
    {
        "Root": "10003535",
        "LV": "1",
        "Item Description": "COFFEE VIC&ALB REG 5@1LB/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.11",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003535",
        "Product Status": "Active"
    },
    {
        "Root": "10101449",
        "LV": "1",
        "Item Description": "CLEANING EGRO TABLETS ESPRESSO 100CT 12/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "RANC-69000427",
        "Last Price Paid": "$26.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101449",
        "Product Status": "Active"
    },
    {
        "Root": "10107338",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS RASPBERRY LEMONADE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76562",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107338",
        "Product Status": "Active"
    },
    {
        "Root": "10034219",
        "LV": "1",
        "Item Description": "CUP CLEAR SQUAT PET 9OZ ACR9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034219001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034219",
        "Product Status": "Active"
    },
    {
        "Root": "10081175",
        "LV": "1",
        "Item Description": "GRANOLA HONEY & OAT ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9422965",
        "Last Price Paid": "$16.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081175",
        "Product Status": "Active"
    },
    {
        "Root": "10106370",
        "LV": "1",
        "Item Description": "KOJI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87501",
        "Last Price Paid": "$75.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106370",
        "Product Status": "Active"
    },
    {
        "Root": "10001816",
        "LV": "1",
        "Item Description": "LETTUCE TREVISO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.82",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001816",
        "Product Status": "Active"
    },
    {
        "Root": "10001816",
        "LV": "1",
        "Item Description": "LETTUCE TREVISO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482146",
        "Last Price Paid": "$4.82",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001816",
        "Product Status": "Active"
    },
    {
        "Root": "10004666",
        "LV": "1",
        "Item Description": "NUT CASHEW WHOLE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "120310",
        "Last Price Paid": "$179.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004666",
        "Product Status": "Active"
    },
    {
        "Root": "10037413",
        "LV": "2",
        "Item Description": "CHEESE SLICES DAIRY FREE MED CHEDDAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037413002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "25027",
        "Last Price Paid": "$3.75",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037413",
        "Product Status": "Active"
    },
    {
        "Root": "10036266",
        "LV": "1",
        "Item Description": "SUGAR LARGE EYES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036266",
        "Product Status": "Active"
    },
    {
        "Root": "10109428",
        "LV": "1",
        "Item Description": "MUG TIKI PIRANHA ED1 CORAL BONE GG 16OZ 18/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109428",
        "Product Status": "Active"
    },
    {
        "Root": "10037012",
        "LV": "1",
        "Item Description": "VIURA CVNE CLASICO BLANCO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "267978",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037012",
        "Product Status": "Active"
    },
    {
        "Root": "10002237",
        "LV": "2",
        "Item Description": "ICE CREAM CUP VANILLA BEAN 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002237002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002237",
        "Product Status": "Active"
    },
    {
        "Root": "10093659",
        "LV": "1",
        "Item Description": "FISH POLLOCK BREADED STICKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093659",
        "Product Status": "Active"
    },
    {
        "Root": "10010541",
        "LV": "1",
        "Item Description": "TWO PRINCES RIESL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388879",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010541",
        "Product Status": "Active"
    },
    {
        "Root": "10007281",
        "LV": "1",
        "Item Description": "NEBB SCAVINO BAR DOCG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007281001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "104574",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007281",
        "Product Status": "Active"
    },
    {
        "Root": "10001579",
        "LV": "2",
        "Item Description": "EDAMAME SHELLED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001579002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001579",
        "Product Status": "Active"
    },
    {
        "Root": "10013262",
        "LV": "1",
        "Item Description": "TEA TWNG MEDLEY OF MINT 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013262",
        "Product Status": "Active"
    },
    {
        "Root": "10013629",
        "LV": "1",
        "Item Description": "CO2 TANK 10LBW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013629",
        "Product Status": "Active"
    },
    {
        "Root": "10013522",
        "LV": "1",
        "Item Description": "CABERNET EDGEBASTON GS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013522001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "EDGEGSCAB",
        "Last Price Paid": "$40.79",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013522",
        "Product Status": "Active"
    },
    {
        "Root": "10000218",
        "LV": "1",
        "Item Description": "LETTUCE ROMAINE CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514033",
        "Last Price Paid": "$4.31",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000218",
        "Product Status": "Active"
    },
    {
        "Root": "10014583",
        "LV": "1",
        "Item Description": "SHRIMP 21/25 FROZEN RAW EZ PEEL SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014583001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125585",
        "Last Price Paid": "$4.95",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014583",
        "Product Status": "Active"
    },
    {
        "Root": "10004401",
        "LV": "1",
        "Item Description": "CHIP PITA GARLIC PARM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004401001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6935043",
        "Last Price Paid": "$0.64",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004401",
        "Product Status": "Active"
    },
    {
        "Root": "10004195",
        "LV": "1",
        "Item Description": "CANDY CHOCOLATE ESPRESSO BEAN CHOC CVRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550660",
        "Last Price Paid": "$58.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004195",
        "Product Status": "Active"
    },
    {
        "Root": "10000171",
        "LV": "1",
        "Item Description": "ORANGES 113 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000171001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.40",
        "Case Pack": "113",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000171",
        "Product Status": "Active"
    },
    {
        "Root": "10037621",
        "LV": "1",
        "Item Description": "BLUE MOON BELGIAN WHITE 12OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14513",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037621",
        "Product Status": "Active"
    },
    {
        "Root": "10002773",
        "LV": "1",
        "Item Description": "SYRUP LEMONADE LT MM 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$99.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002773",
        "Product Status": "Active"
    },
    {
        "Root": "10006244",
        "LV": "1",
        "Item Description": "LID CUP 12.6 PLST CLR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5999768",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006244",
        "Product Status": "Active"
    },
    {
        "Root": "10006223",
        "LV": "1",
        "Item Description": "BAG COOKIE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.02",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006223",
        "Product Status": "Active"
    },
    {
        "Root": "10006223",
        "LV": "1",
        "Item Description": "BAG COOKIE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "778654",
        "Last Price Paid": "$0.02",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006223",
        "Product Status": "Active"
    },
    {
        "Root": "10003918",
        "LV": "1",
        "Item Description": "CO2 NITROGEN MIX 20LB TNK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "505",
        "Last Price Paid": "$10.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003918",
        "Product Status": "Active"
    },
    {
        "Root": "10095631",
        "LV": "1",
        "Item Description": "WINE RED CDP DOMAINE SOLITUDE 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "425540",
        "Last Price Paid": "$37.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095631",
        "Product Status": "Active"
    },
    {
        "Root": "10090952",
        "LV": "1",
        "Item Description": "DEC CHOCOLATE DARK CLUB 33 1.2IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234910000",
        "Last Price Paid": "$0.20",
        "Case Pack": "440",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090952",
        "Product Status": "Active"
    },
    {
        "Root": "10001518",
        "LV": "1",
        "Item Description": "CAULIFLOWER MIXED BABY 16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07MBC-26",
        "Last Price Paid": "$0.31",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001518",
        "Product Status": "Active"
    },
    {
        "Root": "10013316",
        "LV": "1",
        "Item Description": "PEPPER MIXED BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013316001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "10BMB-2",
        "Last Price Paid": "$14.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013316",
        "Product Status": "Active"
    },
    {
        "Root": "10103494",
        "LV": "1",
        "Item Description": "CHEESE BREAD FRZ GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "18219",
        "Last Price Paid": "$0.25",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103494",
        "Product Status": "Active"
    },
    {
        "Root": "10017606",
        "LV": "1",
        "Item Description": "GREEN PEPPER DICED 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514512",
        "Last Price Paid": "$29.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017606",
        "Product Status": "Active"
    },
    {
        "Root": "10001353",
        "LV": "1",
        "Item Description": "MARINADE TANDORI 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001353",
        "Product Status": "Active"
    },
    {
        "Root": "10081047",
        "LV": "1",
        "Item Description": "DEC QUINS DISNEY WORLD 50TH ANNIVERSARY 3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "27685",
        "Last Price Paid": "$12.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081047",
        "Product Status": "Active"
    },
    {
        "Root": "10003880",
        "LV": "1",
        "Item Description": "PEACH SLICED LIGHT SYRUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4333522",
        "Last Price Paid": "$10.56",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003880",
        "Product Status": "Active"
    },
    {
        "Root": "10041046",
        "LV": "1",
        "Item Description": "BOWL PAPER 12 OZ MARY BLAIR 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041046001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041046",
        "Product Status": "Active"
    },
    {
        "Root": "10038810",
        "LV": "1",
        "Item Description": "MUFFIN BLUEBERRY 5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038810",
        "Product Status": "Active"
    },
    {
        "Root": "10007974",
        "LV": "3",
        "Item Description": "CHARD 14 HANDS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007974002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "990879",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007974",
        "Product Status": "Active"
    },
    {
        "Root": "10076695",
        "LV": "1",
        "Item Description": "MIX SHAKE CHOCOLATE 5% 9HG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076695001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.97",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076695",
        "Product Status": "Active"
    },
    {
        "Root": "10037121",
        "LV": "2",
        "Item Description": "COKE DELOS 12/13.5 OZ STARWARS GALAXYS EDGE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037121002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037121",
        "Product Status": "Active"
    },
    {
        "Root": "10009573",
        "LV": "2",
        "Item Description": "VODKA ABSOLUT PEPPAR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009573001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "572835",
        "Last Price Paid": "$19.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009573",
        "Product Status": "Active"
    },
    {
        "Root": "10005404",
        "LV": "1",
        "Item Description": "OLIVES BLK OIL CURED N PITS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "3051",
        "Last Price Paid": "$48.17",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005404",
        "Product Status": "Active"
    },
    {
        "Root": "10006255",
        "LV": "1",
        "Item Description": "CAKE BAND PLASTIC 2.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1...{KOPYKAKE{",
        "Last Price Paid": "$25.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006255",
        "Product Status": "Active"
    },
    {
        "Root": "10105928",
        "LV": "2",
        "Item Description": "PEANUTBUTTER GELATO SOFT SERVE, 6 PACK, HALF GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105928002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-PBSS-11-CS6",
        "Last Price Paid": "$11.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10105928",
        "Product Status": "Active"
    },
    {
        "Root": "10002813",
        "LV": "1",
        "Item Description": "SUGAR GRANULATED 50LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002813",
        "Product Status": "Active"
    },
    {
        "Root": "10035766",
        "LV": "1",
        "Item Description": "SB STRAW PAPER GREEN COMPOSTABLE 7.75 IN CS-4100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11084254",
        "Last Price Paid": "$0.03",
        "Case Pack": "4100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035766",
        "Product Status": "Active"
    },
    {
        "Root": "10035629",
        "LV": "2",
        "Item Description": "ADRIATIC FIG TAPENADE 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035629001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10035629",
        "Product Status": "Active"
    },
    {
        "Root": "10002037",
        "LV": "1",
        "Item Description": "PALM HEARTS FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002037001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002037",
        "Product Status": "Active"
    },
    {
        "Root": "10002037",
        "LV": "1",
        "Item Description": "PALM HEARTS FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002037001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460502",
        "Last Price Paid": "$14.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002037",
        "Product Status": "Active"
    },
    {
        "Root": "10002037",
        "LV": "1",
        "Item Description": "PALM HEARTS FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002037001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-80800",
        "Last Price Paid": "$14.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002037",
        "Product Status": "Active"
    },
    {
        "Root": "10004389",
        "LV": "1",
        "Item Description": "SB TEAV MINT MAJESTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11046310",
        "Last Price Paid": "$0.20",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004389",
        "Product Status": "Active"
    },
    {
        "Root": "10007066",
        "LV": "1",
        "Item Description": "PINOTAGE KANONKOP KAD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "471035",
        "Last Price Paid": "$10.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007066",
        "Product Status": "Active"
    },
    {
        "Root": "10018851",
        "LV": "1",
        "Item Description": "RAMBUTAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482188",
        "Last Price Paid": "$14.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018851",
        "Product Status": "Active"
    },
    {
        "Root": "10019301",
        "LV": "2",
        "Item Description": "TEQUILA AVION EXTRA ANEJO RSV 44 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019301002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "516993",
        "Last Price Paid": "$121.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019301",
        "Product Status": "Active"
    },
    {
        "Root": "10104596",
        "LV": "1",
        "Item Description": "PASSOVER BSC MEATLOAF W/ MASHPOTATO AND VEG 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104596",
        "Product Status": "Active"
    },
    {
        "Root": "10031842",
        "LV": "1",
        "Item Description": "CEREAL FRUITY PEBBLES 12/15OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7628936",
        "Last Price Paid": "$6.29",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031842",
        "Product Status": "Active"
    },
    {
        "Root": "10018932",
        "LV": "1",
        "Item Description": "DUCK BREAST PROSCUITTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018932001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018932",
        "Product Status": "Active"
    },
    {
        "Root": "10031774",
        "LV": "1",
        "Item Description": "IVY LEAF FILLERS GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "IV7GR",
        "Last Price Paid": "$5.51",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031774",
        "Product Status": "Active"
    },
    {
        "Root": "10104869",
        "LV": "3",
        "Item Description": "CONE ICE CREAM TORCH 1120CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104869002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2331041",
        "Last Price Paid": "$0.07",
        "Case Pack": "1120",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10104869",
        "Product Status": "Active"
    },
    {
        "Root": "10000186",
        "LV": "1",
        "Item Description": "PEPPER GREEN CHOPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407030",
        "Last Price Paid": "$27.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000186",
        "Product Status": "Active"
    },
    {
        "Root": "10008138",
        "LV": "2",
        "Item Description": "ROSE GOATS DU ROAM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008138001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2163",
        "Last Price Paid": "$9.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008138",
        "Product Status": "Active"
    },
    {
        "Root": "10000190",
        "LV": "1",
        "Item Description": "LETTUCE LEAF GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510060",
        "Last Price Paid": "$2.32",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000190",
        "Product Status": "Active"
    },
    {
        "Root": "10000190",
        "LV": "1",
        "Item Description": "LETTUCE LEAF GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.32",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000190",
        "Product Status": "Active"
    },
    {
        "Root": "10023246",
        "LV": "1",
        "Item Description": "TORTILLA PREM 12 IN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "1559293",
        "Last Price Paid": "$120.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023246",
        "Product Status": "Active"
    },
    {
        "Root": "10006289",
        "LV": "1",
        "Item Description": "ACETATE ROLL 500 FT 2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "0...{KOPYKAKE{",
        "Last Price Paid": "$25.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006289",
        "Product Status": "Active"
    },
    {
        "Root": "10038208",
        "LV": "1",
        "Item Description": "SB UNFLAVORED ALMONDMILK 64OZ 8CS US ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11106361",
        "Last Price Paid": "$4.66",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038208",
        "Product Status": "Active"
    },
    {
        "Root": "10001997",
        "LV": "1",
        "Item Description": "NAPA/BOK CHOY SHRED 1/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511540",
        "Last Price Paid": "$12.68",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001997",
        "Product Status": "Active"
    },
    {
        "Root": "10108093",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED MONTEREY JACK 5LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108093001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "10806",
        "Last Price Paid": "$10.94",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108093",
        "Product Status": "Active"
    },
    {
        "Root": "10018120",
        "LV": "1",
        "Item Description": "CAB SAUV ENEMIGO GUATALLARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "799099",
        "Last Price Paid": "$83.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018120",
        "Product Status": "Active"
    },
    {
        "Root": "10039820",
        "LV": "1",
        "Item Description": "CHICKEN PLANT BASED STRIPS GARDEIN 10# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "7426700010",
        "Last Price Paid": "$43.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039820",
        "Product Status": "Active"
    },
    {
        "Root": "10027427",
        "LV": "1",
        "Item Description": "CUP ESPNWWS BEER MAGENTA 22 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G22-MAGENTA",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027427",
        "Product Status": "Active"
    },
    {
        "Root": "10009826",
        "LV": "2",
        "Item Description": "RUM CAPT MORG PRVT STK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009826001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009826",
        "Product Status": "Active"
    },
    {
        "Root": "10029264",
        "LV": "1",
        "Item Description": "SUSHI SASHIMI SEARED BEEF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029264",
        "Product Status": "Active"
    },
    {
        "Root": "10093648",
        "LV": "1",
        "Item Description": "SYRUP MONIN PINEAPPLE CONCENTRATE 375ML/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C038FP",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093648",
        "Product Status": "Active"
    },
    {
        "Root": "10014599",
        "LV": "1",
        "Item Description": "CHIPOTLE RANCH DRESSING SS 60/1.5OZ. SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8060834",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014599",
        "Product Status": "Active"
    },
    {
        "Root": "10012551",
        "LV": "1",
        "Item Description": "HYSSOP MICRO ANISE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MAH-33-S",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012551",
        "Product Status": "Active"
    },
    {
        "Root": "10037405",
        "LV": "1",
        "Item Description": "DRESSING VEGAN ORGANIC COLESLAW 6 X 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037405",
        "Product Status": "Active"
    },
    {
        "Root": "10032707",
        "LV": "1",
        "Item Description": "CUP BEER 20 OZ REFILLABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G-22",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032707",
        "Product Status": "Active"
    },
    {
        "Root": "10008485",
        "LV": "2",
        "Item Description": "CAB CH TANUNDA NBL BARON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008485001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "345900",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008485",
        "Product Status": "Active"
    },
    {
        "Root": "10108808",
        "LV": "1",
        "Item Description": "WINE CAB BLEND MATTHEWS BLACKBOARD  12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "566373",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108808",
        "Product Status": "Active"
    },
    {
        "Root": "10038282",
        "LV": "1",
        "Item Description": "RICE BASMATI INTERNATIONAL GRAINS 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2278104",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038282",
        "Product Status": "Active"
    },
    {
        "Root": "10025454",
        "LV": "1",
        "Item Description": "PREMIUM CANDY COLOR ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21312",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025454",
        "Product Status": "Active"
    },
    {
        "Root": "10012971",
        "LV": "1",
        "Item Description": "COASTER PYTHN HANGAR 4\" RND 64R4DB2S 60PT CMYK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-020",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012971",
        "Product Status": "Active"
    },
    {
        "Root": "10044405",
        "LV": "1",
        "Item Description": "SPICE SESAME SEED BLACK WHOLE SHAKER BOTTLE 19OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6501225",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044405",
        "Product Status": "Active"
    },
    {
        "Root": "10011660",
        "LV": "1",
        "Item Description": "RICE JADE BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011660001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5902531",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011660",
        "Product Status": "Active"
    },
    {
        "Root": "10095413",
        "LV": "1",
        "Item Description": "WINE SAVAGNIN ROUSSET-MARTIN SOUS VOILE 10 ANS SOU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "411118",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095413",
        "Product Status": "Active"
    },
    {
        "Root": "10000704",
        "LV": "1",
        "Item Description": "SALAME OLLI GUANCIALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000704001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "80801",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000704",
        "Product Status": "Active"
    },
    {
        "Root": "10038464",
        "LV": "1",
        "Item Description": "PREMIUM CARAMEL APPLE POPCORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038464001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038464",
        "Product Status": "Active"
    },
    {
        "Root": "10010281",
        "LV": "2",
        "Item Description": "BEER REKORDRNG STRW LM 11.2Z CN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010281001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010281",
        "Product Status": "Active"
    },
    {
        "Root": "10012555",
        "LV": "1",
        "Item Description": "DANDELION MICRO MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MDA-33-L",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012555",
        "Product Status": "Active"
    },
    {
        "Root": "10006805",
        "LV": "2",
        "Item Description": "BEER FAUCET LOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006805001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "8024",
        "Last Price Paid": "$58.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006805",
        "Product Status": "Active"
    },
    {
        "Root": "10005706",
        "LV": "1",
        "Item Description": "BISCUIT CHOCOLATE SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.09",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005706",
        "Product Status": "Active"
    },
    {
        "Root": "10004731",
        "LV": "1",
        "Item Description": "GLAZE NEUTRAL HARMONY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "21...{PURATOS{",
        "Last Price Paid": "$48.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004731",
        "Product Status": "Active"
    },
    {
        "Root": "10037834",
        "LV": "1",
        "Item Description": "MIX MANDARIN ORANGE BLOSSOM GREEN SWGE 6/4.27#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$108.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037834",
        "Product Status": "Active"
    },
    {
        "Root": "10032509",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "9770-35",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032509",
        "Product Status": "Active"
    },
    {
        "Root": "10032509",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032509",
        "Product Status": "Active"
    },
    {
        "Root": "10034314",
        "LV": "1",
        "Item Description": "DRAFT NCB RED SEAL 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "6666",
        "Last Price Paid": "$168.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034314",
        "Product Status": "Active"
    },
    {
        "Root": "10000954",
        "LV": "1",
        "Item Description": "SHRIMP ROCK FRZN 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000954001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "79-99098",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000954",
        "Product Status": "Active"
    },
    {
        "Root": "10000954",
        "LV": "1",
        "Item Description": "SHRIMP ROCK FRZN 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000954001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6898400",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000954",
        "Product Status": "Active"
    },
    {
        "Root": "10040746",
        "LV": "1",
        "Item Description": "CUP KIDS TSR TOYSTORY 16OZ 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040746",
        "Product Status": "Active"
    },
    {
        "Root": "10040237",
        "LV": "1",
        "Item Description": "RHONE RED JANASSE CDP CHAUPIN 2015 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040237001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "750637",
        "Last Price Paid": "$71.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040237",
        "Product Status": "Active"
    },
    {
        "Root": "10028012",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON CHATEAU SMITH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "513688",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028012",
        "Product Status": "Active"
    },
    {
        "Root": "10045619",
        "LV": "2",
        "Item Description": "SPOON MED WT POLYSTYRENE WRAPPED BULK 1000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045619002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10045619",
        "Product Status": "Active"
    },
    {
        "Root": "10006387",
        "LV": "1",
        "Item Description": "FILTER BUNN (20120) 1.5 GAL 2/252CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "504",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006387",
        "Product Status": "Active"
    },
    {
        "Root": "10001738",
        "LV": "1",
        "Item Description": "PEAR FORELLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "274116",
        "Last Price Paid": "$61.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001738",
        "Product Status": "Active"
    },
    {
        "Root": "10002015",
        "LV": "1",
        "Item Description": "PAPAYA GREEN ASIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "303021",
        "Last Price Paid": "$36.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002015",
        "Product Status": "Active"
    },
    {
        "Root": "10002015",
        "LV": "1",
        "Item Description": "PAPAYA GREEN ASIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002015",
        "Product Status": "Active"
    },
    {
        "Root": "10001345",
        "LV": "1",
        "Item Description": "HUMMUS CALAMATA OLIVE 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$172.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001345",
        "Product Status": "Active"
    },
    {
        "Root": "10031364",
        "LV": "1",
        "Item Description": "CIDERBOYS GRAND MIMOSA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2055",
        "Last Price Paid": "$1.41",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031364",
        "Product Status": "Active"
    },
    {
        "Root": "10032366",
        "LV": "1",
        "Item Description": "BAKEABLE SWEET CORN HUSHPUPPY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "00020",
        "Last Price Paid": "$9.35",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032366",
        "Product Status": "Active"
    },
    {
        "Root": "10091373",
        "LV": "1",
        "Item Description": "WINE RED PINOTAGE BRAAI SOUTH AFRICA 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "353157",
        "Last Price Paid": "$11.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091373",
        "Product Status": "Active"
    },
    {
        "Root": "10035884",
        "LV": "1",
        "Item Description": "NATIVE FOREST JACKFRUIT IN BRINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035884001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "V9869",
        "Last Price Paid": "$10.56",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035884",
        "Product Status": "Active"
    },
    {
        "Root": "10010036",
        "LV": "2",
        "Item Description": "BEER YUENGLING LGR CN 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010036001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11460",
        "Last Price Paid": "$1.03",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010036",
        "Product Status": "Active"
    },
    {
        "Root": "10024596",
        "LV": "1",
        "Item Description": "TEA GREEN K CUP 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024596",
        "Product Status": "Active"
    },
    {
        "Root": "10004278",
        "LV": "1",
        "Item Description": "SB LB BIRTHDAY CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11037822",
        "Last Price Paid": "$0.72",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004278",
        "Product Status": "Active"
    },
    {
        "Root": "10019391",
        "LV": "1",
        "Item Description": "RICE BOMBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-65030",
        "Last Price Paid": "$58.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019391",
        "Product Status": "Active"
    },
    {
        "Root": "10004287",
        "LV": "1",
        "Item Description": "GRAVY POUTINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6125824",
        "Last Price Paid": "$14.57",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004287",
        "Product Status": "Active"
    },
    {
        "Root": "10001096",
        "LV": "1",
        "Item Description": "SHRIMP P&D T/FF FLA 26/30",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001096001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6126002",
        "Last Price Paid": "$11.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001096",
        "Product Status": "Active"
    },
    {
        "Root": "10007859",
        "LV": "1",
        "Item Description": "PINOT NOIR ERATH WILLAM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007859001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "572050",
        "Last Price Paid": "$29.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007859",
        "Product Status": "Active"
    },
    {
        "Root": "10040745",
        "LV": "1",
        "Item Description": "CUP KIDS TSR FROZEN 16OZ 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040745",
        "Product Status": "Active"
    },
    {
        "Root": "10021555",
        "LV": "1",
        "Item Description": "MIX TRAIL SUNBURST MINI BAG 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "202560",
        "Last Price Paid": "$0.51",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021555",
        "Product Status": "Active"
    },
    {
        "Root": "10040595",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED PROVOLONE 4/5 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040595001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "102663",
        "Last Price Paid": "$3.70",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040595",
        "Product Status": "Active"
    },
    {
        "Root": "10016529",
        "LV": "1",
        "Item Description": "BREAD RYE W/CARROWAY PULLMAN LENGTHWISE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "0812SD",
        "Last Price Paid": "$3.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016529",
        "Product Status": "Active"
    },
    {
        "Root": "10009865",
        "LV": "2",
        "Item Description": "APERITIF RICARD ANISE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009865001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11414",
        "Last Price Paid": "$29.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009865",
        "Product Status": "Active"
    },
    {
        "Root": "10009865",
        "LV": "2",
        "Item Description": "APERITIF RICARD ANISE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009865001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "11414",
        "Last Price Paid": "$29.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009865",
        "Product Status": "Active"
    },
    {
        "Root": "10035932",
        "LV": "1",
        "Item Description": "OIL POPCORN 100% COCONUT BNB 35 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035932",
        "Product Status": "Active"
    },
    {
        "Root": "10002820",
        "LV": "1",
        "Item Description": "VINEGAR RED 5% 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002820",
        "Product Status": "Active"
    },
    {
        "Root": "10001798",
        "LV": "1",
        "Item Description": "PEPPER THAI CHILI5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407938",
        "Last Price Paid": "$17.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001798",
        "Product Status": "Active"
    },
    {
        "Root": "10000623",
        "LV": "1",
        "Item Description": "BEEF SIRLOIN TOP BUTT CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000623001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0000039",
        "Last Price Paid": "$7.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000623",
        "Product Status": "Active"
    },
    {
        "Root": "10015947",
        "LV": "1",
        "Item Description": "EGG HALF HOLLOW 11\" WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22230W",
        "Last Price Paid": "$9.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015947",
        "Product Status": "Active"
    },
    {
        "Root": "10006412",
        "LV": "1",
        "Item Description": "LEIS FLOWER SILK MULTICOLORED ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006412",
        "Product Status": "Active"
    },
    {
        "Root": "10007978",
        "LV": "1",
        "Item Description": "MERLOT ANNABELA 750ML 08",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "291692",
        "Last Price Paid": "$13.41",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007978",
        "Product Status": "Active"
    },
    {
        "Root": "10023305",
        "LV": "1",
        "Item Description": "CHOC KING CROWN SMALL YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW17691",
        "Last Price Paid": "$1.25",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023305",
        "Product Status": "Active"
    },
    {
        "Root": "10105114",
        "LV": "2",
        "Item Description": "MAYONNAISE BAG SHLF STABL KEWPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105114002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1682138",
        "Last Price Paid": "$12.14",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10105114",
        "Product Status": "Active"
    },
    {
        "Root": "10038174",
        "LV": "2",
        "Item Description": "6893 ANIMAL PRINT BAKE CUP SET 75 CUPS 12/75 PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038174001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51004480",
        "Last Price Paid": "$0.04",
        "Case Pack": "900",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10038174",
        "Product Status": "Active"
    },
    {
        "Root": "10037827",
        "LV": "1",
        "Item Description": "BOBS RED MILL SMALL PEARL TAPIOCA 4 X 24OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101379",
        "Last Price Paid": "$4.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037827",
        "Product Status": "Active"
    },
    {
        "Root": "10000211",
        "LV": "1",
        "Item Description": "MUSHROOM CRIMINI 5# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405090",
        "Last Price Paid": "$16.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000211",
        "Product Status": "Active"
    },
    {
        "Root": "10008139",
        "LV": "2",
        "Item Description": "CHARD FRANCISCAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008139001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9642354",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008139",
        "Product Status": "Active"
    },
    {
        "Root": "10001002",
        "LV": "1",
        "Item Description": "SNAPPER RED FLT FRESH SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001002001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6503101",
        "Last Price Paid": "$30.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001002",
        "Product Status": "Active"
    },
    {
        "Root": "10033239",
        "LV": "1",
        "Item Description": "BEEF BRISKET FLAT RAW SOL CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033239001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2981816",
        "Last Price Paid": "$9.26",
        "Case Pack": "34",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033239",
        "Product Status": "Active"
    },
    {
        "Root": "10088173",
        "LV": "1",
        "Item Description": "BEER DRAFT BRECKENRIDGE CHRISTMAS ALE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088173001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "47302",
        "Last Price Paid": "$183.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088173",
        "Product Status": "Active"
    },
    {
        "Root": "10001143",
        "LV": "1",
        "Item Description": "MAME NORI GOMA SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "10039",
        "Last Price Paid": "$1.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001143",
        "Product Status": "Active"
    },
    {
        "Root": "10006929",
        "LV": "1",
        "Item Description": "PINOT NOIR MIRASSOU 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1300441",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006929",
        "Product Status": "Active"
    },
    {
        "Root": "10035072",
        "LV": "2",
        "Item Description": "LID HOT CUP LARGE DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035072001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D9542",
        "Last Price Paid": "$34.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10035072",
        "Product Status": "Active"
    },
    {
        "Root": "10009495",
        "LV": "1",
        "Item Description": "BOURBON JACK DANL BLK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "22137",
        "Last Price Paid": "$28.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009495",
        "Product Status": "Active"
    },
    {
        "Root": "10091229",
        "LV": "1",
        "Item Description": "CHOCOLATE TABLET STARCRUISER 14.7X7X0.5CM, 200PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234950000",
        "Last Price Paid": "$1.91",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091229",
        "Product Status": "Active"
    },
    {
        "Root": "10007251",
        "LV": "2",
        "Item Description": "CAB SAUV MOUNT BRAVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007251001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "982913",
        "Last Price Paid": "$94.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007251",
        "Product Status": "Active"
    },
    {
        "Root": "10034199",
        "LV": "1",
        "Item Description": "STRAWBERRY FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BERSB",
        "Last Price Paid": "$3.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034199",
        "Product Status": "Active"
    },
    {
        "Root": "10034199",
        "LV": "1",
        "Item Description": "STRAWBERRY FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482194",
        "Last Price Paid": "$3.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034199",
        "Product Status": "Active"
    },
    {
        "Root": "10034199",
        "LV": "1",
        "Item Description": "STRAWBERRY FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "12004",
        "Last Price Paid": "$3.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034199",
        "Product Status": "Active"
    },
    {
        "Root": "10105050",
        "LV": "1",
        "Item Description": "SZECHUAN PEPPERCORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-46210",
        "Last Price Paid": "$22.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105050",
        "Product Status": "Active"
    },
    {
        "Root": "10010417",
        "LV": "2",
        "Item Description": "BEER HARP IRISH BT 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010417001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30811",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010417",
        "Product Status": "Active"
    },
    {
        "Root": "10010417",
        "LV": "2",
        "Item Description": "BEER HARP IRISH BT 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010417001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "30811",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010417",
        "Product Status": "Active"
    },
    {
        "Root": "10004728",
        "LV": "1",
        "Item Description": "CHIP SUNCHIP 1.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5605522",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004728",
        "Product Status": "Active"
    },
    {
        "Root": "10013926",
        "LV": "1",
        "Item Description": "DRAFT TAMPA BAY REEF DONKEY 1/6 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$85.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013926",
        "Product Status": "Active"
    },
    {
        "Root": "10002091",
        "LV": "1",
        "Item Description": "RADISH BY THE BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002091",
        "Product Status": "Active"
    },
    {
        "Root": "10002091",
        "LV": "1",
        "Item Description": "RADISH BY THE BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "801630",
        "Last Price Paid": "$1.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002091",
        "Product Status": "Active"
    },
    {
        "Root": "10000079",
        "LV": "1",
        "Item Description": "BEEF BUTCHER FILET TM 8-10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000079001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190013",
        "Last Price Paid": "$12.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000079",
        "Product Status": "Active"
    },
    {
        "Root": "10041419",
        "LV": "1",
        "Item Description": "EGGS HARD COOKED 2 PACK 48/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.74",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041419",
        "Product Status": "Active"
    },
    {
        "Root": "10011085",
        "LV": "2",
        "Item Description": "BEER KONA LONGBOARD LAGER HALF BARREL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011085001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10011085",
        "Product Status": "Active"
    },
    {
        "Root": "10104606",
        "LV": "1",
        "Item Description": "PASSOVER KIT - TSU WITH CHICKEN CASSEROLE 3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.18",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104606",
        "Product Status": "Active"
    },
    {
        "Root": "10003065",
        "LV": "2",
        "Item Description": "WATER VITAMIN POWER C 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003065002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003065",
        "Product Status": "Active"
    },
    {
        "Root": "10003961",
        "LV": "1",
        "Item Description": "VINEGAR RED MINUS 8 L8 HARVEST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD0355",
        "Last Price Paid": "$21.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003961",
        "Product Status": "Active"
    },
    {
        "Root": "10006872",
        "LV": "1",
        "Item Description": "CHARD FESS PARKER 100YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9074594",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006872",
        "Product Status": "Active"
    },
    {
        "Root": "10007833",
        "LV": "1",
        "Item Description": "SPARK GRAHAM BECK BRUT NV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "690322",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007833",
        "Product Status": "Active"
    },
    {
        "Root": "10000405",
        "LV": "1",
        "Item Description": "PICKLE BRANSTON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD0175",
        "Last Price Paid": "$2.90",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000405",
        "Product Status": "Active"
    },
    {
        "Root": "10080756",
        "LV": "1",
        "Item Description": "SHEET TRANS DISNEY CASTLE 50 CUT 4CM GOLD 3000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6172660000",
        "Last Price Paid": "$0.05",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10080756",
        "Product Status": "Active"
    },
    {
        "Root": "10103362",
        "LV": "1",
        "Item Description": "PASTA NOODLE SWEET POTATO DANGMYEON 15/1.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103362001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6043605",
        "Last Price Paid": "$3.70",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103362",
        "Product Status": "Active"
    },
    {
        "Root": "10003799",
        "LV": "2",
        "Item Description": "SEASONING OLD BAY 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003799002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6000301",
        "Last Price Paid": "$8.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003799",
        "Product Status": "Active"
    },
    {
        "Root": "10003965",
        "LV": "1",
        "Item Description": "NUT ALMOND MARCONA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-89005",
        "Last Price Paid": "$44.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003965",
        "Product Status": "Active"
    },
    {
        "Root": "10001071",
        "LV": "1",
        "Item Description": "MAHI PRTN FRZN SKIN OFF 4 OZ 1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001071001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "1219366",
        "Last Price Paid": "$5.99",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001071",
        "Product Status": "Active"
    },
    {
        "Root": "10045062",
        "LV": "1",
        "Item Description": "POTATOES FC WHITE 1/4IN FF S/ON DRYPACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481828",
        "Last Price Paid": "$15.08",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045062",
        "Product Status": "Active"
    },
    {
        "Root": "10001609",
        "LV": "1",
        "Item Description": "CANTALOUPE SML DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001609001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "812058",
        "Last Price Paid": "$3.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001609",
        "Product Status": "Active"
    },
    {
        "Root": "10001609",
        "LV": "1",
        "Item Description": "CANTALOUPE SML DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001609001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-001D-12",
        "Last Price Paid": "$3.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001609",
        "Product Status": "Active"
    },
    {
        "Root": "10013471",
        "LV": "1",
        "Item Description": "CUP BAKING BRN PLEATED BRN PLAID 6.8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391003",
        "Last Price Paid": "$0.20",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013471",
        "Product Status": "Active"
    },
    {
        "Root": "10030753",
        "LV": "2",
        "Item Description": "MIX CHOCOLATE SWEET TOOTH 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030753002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "102750",
        "Last Price Paid": "$111.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030753",
        "Product Status": "Active"
    },
    {
        "Root": "10109311",
        "LV": "1",
        "Item Description": "PORCELETE BELLY SKIN ON /LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109311001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109311",
        "Product Status": "Active"
    },
    {
        "Root": "10024359",
        "LV": "1",
        "Item Description": "SUGAR SANDING PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9457",
        "Last Price Paid": "$15.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024359",
        "Product Status": "Active"
    },
    {
        "Root": "10036792",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET BONE-IN 16Z CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036792001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190112",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036792",
        "Product Status": "Active"
    },
    {
        "Root": "10042412",
        "LV": "1",
        "Item Description": "CHARDONNAY INTERCEPT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042412",
        "Product Status": "Active"
    },
    {
        "Root": "10094790",
        "LV": "1",
        "Item Description": "BREAD ROLL DINNER GLUETEN FREE UDI FRNCH 8/7.4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "123881-5",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094790",
        "Product Status": "Active"
    },
    {
        "Root": "10076855",
        "LV": "1",
        "Item Description": "CHOC COATED CROQUANT SILVER 1.1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6168100000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076855",
        "Product Status": "Active"
    },
    {
        "Root": "10110211",
        "LV": "2",
        "Item Description": "PLATE PAPER OVAL 9X11\" WDW MARY BLAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110211001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10110211",
        "Product Status": "Active"
    },
    {
        "Root": "10025057",
        "LV": "1",
        "Item Description": "WHISKEY DILLIONS WHITE RYE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9198490",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025057",
        "Product Status": "Active"
    },
    {
        "Root": "10027896",
        "LV": "1",
        "Item Description": "MUG MAI TAI CERAMIC GROG GROTTO 24 CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "TRAMAI",
        "Last Price Paid": "$8.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027896",
        "Product Status": "Active"
    },
    {
        "Root": "10037434",
        "LV": "1",
        "Item Description": "DAWN CREAM CHEESE FILLING (IN PIPING TUBES) 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DF2405092",
        "Last Price Paid": "$5.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037434",
        "Product Status": "Active"
    },
    {
        "Root": "10000298",
        "LV": "1",
        "Item Description": "POTATO RED A 1/6 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515016",
        "Last Price Paid": "$38.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000298",
        "Product Status": "Active"
    },
    {
        "Root": "10000298",
        "LV": "1",
        "Item Description": "POTATO RED A 1/6 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "06-010-38",
        "Last Price Paid": "$38.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000298",
        "Product Status": "Active"
    },
    {
        "Root": "10109532",
        "LV": "1",
        "Item Description": "BEER KEG SAM ADAMS SEASONAL 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109532",
        "Product Status": "Active"
    },
    {
        "Root": "10039969",
        "LV": "1",
        "Item Description": "CHEESE VEGAN MIYOKOS MOZZ 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "181556-2",
        "Last Price Paid": "$6.81",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039969",
        "Product Status": "Active"
    },
    {
        "Root": "10003413",
        "LV": "1",
        "Item Description": "SYRUP GRAPE FANTA 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003413",
        "Product Status": "Active"
    },
    {
        "Root": "10014299",
        "LV": "1",
        "Item Description": "APPLE GOLDEN DELICIOUS 88CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014299001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "101088",
        "Last Price Paid": "$0.55",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014299",
        "Product Status": "Active"
    },
    {
        "Root": "10022955",
        "LV": "2",
        "Item Description": "PEPPER CHILI HATCH FRESH TEMP F&W SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022955002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VEG900",
        "Last Price Paid": "$46.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10022955",
        "Product Status": "Active"
    },
    {
        "Root": "10028131",
        "LV": "1",
        "Item Description": "WHITE SILHOUETTE MOANA 2\" WITH TAB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW10830",
        "Last Price Paid": "$0.85",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028131",
        "Product Status": "Active"
    },
    {
        "Root": "10014064",
        "LV": "2",
        "Item Description": "COCOA BUTTER JADE GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014064002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302060C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014064",
        "Product Status": "Active"
    },
    {
        "Root": "10032244",
        "LV": "1",
        "Item Description": "CAULIFLOWERETTS MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032244001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480783",
        "Last Price Paid": "$11.10",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032244",
        "Product Status": "Active"
    },
    {
        "Root": "10015407",
        "LV": "1",
        "Item Description": "DEC ROSE MED PEACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKT PE",
        "Last Price Paid": "$1.80",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015407",
        "Product Status": "Active"
    },
    {
        "Root": "10007932",
        "LV": "2",
        "Item Description": "CAB SAUV TURNBULL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007932001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "287761",
        "Last Price Paid": "$29.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007932",
        "Product Status": "Active"
    },
    {
        "Root": "10014768",
        "LV": "1",
        "Item Description": "AFRICAN ORCHID SMALL WHITE A3P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014768001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "A3P",
        "Last Price Paid": "$1.87",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014768",
        "Product Status": "Active"
    },
    {
        "Root": "10004337",
        "LV": "1",
        "Item Description": "MILK, COCONUT DAIRY FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090110",
        "Last Price Paid": "$1.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004337",
        "Product Status": "Active"
    },
    {
        "Root": "10017212",
        "LV": "1",
        "Item Description": "CUP HOT 4 OZ RIPPLE WRAP BLK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11013548",
        "Last Price Paid": "$0.14",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017212",
        "Product Status": "Active"
    },
    {
        "Root": "10009065",
        "LV": "1",
        "Item Description": "FIANO DI AVEL FEUDI GREGORIO 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557872",
        "Last Price Paid": "$18.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009065",
        "Product Status": "Active"
    },
    {
        "Root": "10106790",
        "LV": "1",
        "Item Description": "BUCKET POP PREM HOL23 ROTATING GOLD 100OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106790",
        "Product Status": "Active"
    },
    {
        "Root": "10022721",
        "LV": "1",
        "Item Description": "LIQ SOMRUS INDIAN CRM 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022721001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9188301",
        "Last Price Paid": "$19.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022721",
        "Product Status": "Active"
    },
    {
        "Root": "10022721",
        "LV": "1",
        "Item Description": "LIQ SOMRUS INDIAN CRM 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022721001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9188301",
        "Last Price Paid": "$19.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022721",
        "Product Status": "Active"
    },
    {
        "Root": "10015942",
        "LV": "1",
        "Item Description": "CHOC EGG HALF HOLLOW DRK 20\"X15 1/2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22232D",
        "Last Price Paid": "$35.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015942",
        "Product Status": "Active"
    },
    {
        "Root": "10002196",
        "LV": "1",
        "Item Description": "CHEESE COTTAGE 1%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1685882",
        "Last Price Paid": "$8.82",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002196",
        "Product Status": "Active"
    },
    {
        "Root": "10000225",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000225001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000225",
        "Product Status": "Active"
    },
    {
        "Root": "10104175",
        "LV": "1",
        "Item Description": "BEER DRAFT 81 BAY BLUE BUTTERFLY LAGER 1/2KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15827",
        "Last Price Paid": "$192.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104175",
        "Product Status": "Active"
    },
    {
        "Root": "10001715",
        "LV": "1",
        "Item Description": "KUMQUATS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$72.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001715",
        "Product Status": "Active"
    },
    {
        "Root": "10001715",
        "LV": "1",
        "Item Description": "KUMQUATS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "305101",
        "Last Price Paid": "$72.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001715",
        "Product Status": "Active"
    },
    {
        "Root": "10001715",
        "LV": "1",
        "Item Description": "KUMQUATS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "13370",
        "Last Price Paid": "$72.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001715",
        "Product Status": "Active"
    },
    {
        "Root": "10023895",
        "LV": "1",
        "Item Description": "PORK ST LOUIS STYLE SPARERIB SKIRT OFF 2.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023895001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.71",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023895",
        "Product Status": "Active"
    },
    {
        "Root": "10023895",
        "LV": "1",
        "Item Description": "PORK ST LOUIS STYLE SPARERIB SKIRT OFF 2.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023895001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "61715",
        "Last Price Paid": "$2.71",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023895",
        "Product Status": "Active"
    },
    {
        "Root": "10007596",
        "LV": "2",
        "Item Description": "MALBEC ERNESTO CATENA SIESTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007596001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9144957",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007596",
        "Product Status": "Active"
    },
    {
        "Root": "10016505",
        "LV": "1",
        "Item Description": "PIZZA CHEESE PERSONAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016505001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "10484",
        "Last Price Paid": "$1.37",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016505",
        "Product Status": "Active"
    },
    {
        "Root": "10009893",
        "LV": "1",
        "Item Description": "PORT TAYLOR 20YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273584",
        "Last Price Paid": "$37.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009893",
        "Product Status": "Active"
    },
    {
        "Root": "10000956",
        "LV": "1",
        "Item Description": "LOBSTER MEAT CLAW KNCKL BROKEN 12 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000956001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6475301",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000956",
        "Product Status": "Active"
    },
    {
        "Root": "10000956",
        "LV": "1",
        "Item Description": "LOBSTER MEAT CLAW KNCKL BROKEN 12 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000956001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "79-07015",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000956",
        "Product Status": "Active"
    },
    {
        "Root": "10000956",
        "LV": "1",
        "Item Description": "LOBSTER MEAT CLAW KNCKL BROKEN 12 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000956001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000956",
        "Product Status": "Active"
    },
    {
        "Root": "10005882",
        "LV": "1",
        "Item Description": "PARATHA PANEER FLATBREAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "70-43448",
        "Last Price Paid": "$0.76",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005882",
        "Product Status": "Active"
    },
    {
        "Root": "10015408",
        "LV": "1",
        "Item Description": "DEC ROSE MED PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKT PK",
        "Last Price Paid": "$1.80",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015408",
        "Product Status": "Active"
    },
    {
        "Root": "10005114",
        "LV": "1",
        "Item Description": "SYRUP COFF ESPRESSO LTR 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005114001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5014",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005114",
        "Product Status": "Active"
    },
    {
        "Root": "10014586",
        "LV": "1",
        "Item Description": "CHARD ALB BICHOT CHAB 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "44736",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014586",
        "Product Status": "Active"
    },
    {
        "Root": "10037036",
        "LV": "1",
        "Item Description": "OIL OLIVE EV ORGANIC GREEK KORONEIKI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01145",
        "Last Price Paid": "$13.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037036",
        "Product Status": "Active"
    },
    {
        "Root": "10088342",
        "LV": "1",
        "Item Description": "PASTRY STRAWBERRY MINI LATTICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "31105",
        "Last Price Paid": "$0.63",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088342",
        "Product Status": "Active"
    },
    {
        "Root": "10009591",
        "LV": "2",
        "Item Description": "RTD 1800 MARG 1.75 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009591001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12994",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009591",
        "Product Status": "Active"
    },
    {
        "Root": "10013600",
        "LV": "1",
        "Item Description": "SB CRANBERRY BLISS BAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11081558",
        "Last Price Paid": "$1.39",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013600",
        "Product Status": "Active"
    },
    {
        "Root": "10037360",
        "LV": "1",
        "Item Description": "RECTANGLE TRAY BLACK 20Z 300/CS 78120B300",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303041",
        "Last Price Paid": "$0.55",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037360",
        "Product Status": "Active"
    },
    {
        "Root": "10000376",
        "LV": "1",
        "Item Description": "SLAW CABBAGE W/ COLOR MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511525",
        "Last Price Paid": "$5.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000376",
        "Product Status": "Active"
    },
    {
        "Root": "10000376",
        "LV": "1",
        "Item Description": "SLAW CABBAGE W/ COLOR MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "55-019S-A",
        "Last Price Paid": "$5.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000376",
        "Product Status": "Active"
    },
    {
        "Root": "10006614",
        "LV": "1",
        "Item Description": "COVER TRAM 34X20X72",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5980735",
        "Last Price Paid": "$0.35",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006614",
        "Product Status": "Active"
    },
    {
        "Root": "10006972",
        "LV": "1",
        "Item Description": "RED FESS PARKER FRONTIER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9152270",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006972",
        "Product Status": "Active"
    },
    {
        "Root": "10000729",
        "LV": "1",
        "Item Description": "BEEF FEMUR BN SPLT 4 INCH FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000729001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1100033",
        "Last Price Paid": "$3.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000729",
        "Product Status": "Active"
    },
    {
        "Root": "10040109",
        "LV": "1",
        "Item Description": "NOODLES UDON ORGANIC 30/12.8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3467925",
        "Last Price Paid": "$2.48",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040109",
        "Product Status": "Active"
    },
    {
        "Root": "10038757",
        "LV": "1",
        "Item Description": "DRAGON FRUIT RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "121825",
        "Last Price Paid": "$2.49",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038757",
        "Product Status": "Active"
    },
    {
        "Root": "10002860",
        "LV": "1",
        "Item Description": "COKE CLASSIC 2 LTR BT 8/C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.22",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002860",
        "Product Status": "Active"
    },
    {
        "Root": "10002882",
        "LV": "1",
        "Item Description": "TEA IRISH BRKFST TWINING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002882",
        "Product Status": "Active"
    },
    {
        "Root": "10024861",
        "LV": "1",
        "Item Description": "NOODLE RAMEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "14221",
        "Last Price Paid": "$1.03",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024861",
        "Product Status": "Active"
    },
    {
        "Root": "10090646",
        "LV": "1",
        "Item Description": "PRETZEL BAG DOTS HOMESTYLE ORIGINAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302106",
        "Last Price Paid": "$1.37",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090646",
        "Product Status": "Active"
    },
    {
        "Root": "10014166",
        "LV": "1",
        "Item Description": "SOLE DOVER 16-18OZ WHOLE FRSH SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014166001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "61-00087",
        "Last Price Paid": "$19.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014166",
        "Product Status": "Active"
    },
    {
        "Root": "10014166",
        "LV": "1",
        "Item Description": "SOLE DOVER 16-18OZ WHOLE FRSH SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014166001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-00087",
        "Last Price Paid": "$19.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014166",
        "Product Status": "Active"
    },
    {
        "Root": "10014166",
        "LV": "1",
        "Item Description": "SOLE DOVER 16-18OZ WHOLE FRSH SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014166001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "61-00087",
        "Last Price Paid": "$19.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014166",
        "Product Status": "Active"
    },
    {
        "Root": "10014166",
        "LV": "1",
        "Item Description": "SOLE DOVER 16-18OZ WHOLE FRSH SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014166001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF201",
        "Last Price Paid": "$19.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014166",
        "Product Status": "Active"
    },
    {
        "Root": "10006304",
        "LV": "2",
        "Item Description": "WRAP SANDWICH FOIL BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006304002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9421371",
        "Last Price Paid": "$0.09",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006304",
        "Product Status": "Active"
    },
    {
        "Root": "10017451",
        "LV": "1",
        "Item Description": "POWDER SPORT PROTEIN CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "VEG00856",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017451",
        "Product Status": "Active"
    },
    {
        "Root": "10031617",
        "LV": "1",
        "Item Description": "HONEY ORANGE BLOSSOM SAV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "85845",
        "Last Price Paid": "$46.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031617",
        "Product Status": "Active"
    },
    {
        "Root": "10002304",
        "LV": "1",
        "Item Description": "CHEESE FETA CRUMBLE BULK 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "31166",
        "Last Price Paid": "$8.16",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002304",
        "Product Status": "Active"
    },
    {
        "Root": "10002304",
        "LV": "1",
        "Item Description": "CHEESE FETA CRUMBLE BULK 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6560577",
        "Last Price Paid": "$8.16",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002304",
        "Product Status": "Active"
    },
    {
        "Root": "10004631",
        "LV": "1",
        "Item Description": "NUTRI GRAIN BAR STRAW 1.3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3067154",
        "Last Price Paid": "$0.66",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004631",
        "Product Status": "Active"
    },
    {
        "Root": "10017491",
        "LV": "1",
        "Item Description": "DULSE DRY 1# SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "50060000",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017491",
        "Product Status": "Active"
    },
    {
        "Root": "10007058",
        "LV": "1",
        "Item Description": "NUMANTHIA TERMES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "570037",
        "Last Price Paid": "$13.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007058",
        "Product Status": "Active"
    },
    {
        "Root": "10007058",
        "LV": "1",
        "Item Description": "NUMANTHIA TERMES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570037",
        "Last Price Paid": "$13.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007058",
        "Product Status": "Active"
    },
    {
        "Root": "10002833",
        "LV": "1",
        "Item Description": "COKE ZERO 20OZ 24EA CONTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "143885",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002833",
        "Product Status": "Active"
    },
    {
        "Root": "10002833",
        "LV": "1",
        "Item Description": "COKE ZERO 20OZ 24EA CONTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002833",
        "Product Status": "Active"
    },
    {
        "Root": "10009599",
        "LV": "1",
        "Item Description": "GIN TANQUERAY NMBR 10 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "355661",
        "Last Price Paid": "$33.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009599",
        "Product Status": "Active"
    },
    {
        "Root": "10031363",
        "LV": "1",
        "Item Description": "BROCCOLI FLORET MINATURE 4/3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "275004",
        "Last Price Paid": "$7.53",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031363",
        "Product Status": "Active"
    },
    {
        "Root": "10033586",
        "LV": "1",
        "Item Description": "SHELL TART SAVARIN VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8095143",
        "Last Price Paid": "$1.14",
        "Case Pack": "27",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033586",
        "Product Status": "Active"
    },
    {
        "Root": "10033998",
        "LV": "1",
        "Item Description": "02788 KRAFT 4CUP DRINK CARRIER HANDLED UP TO 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11009024",
        "Last Price Paid": "$0.49",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033998",
        "Product Status": "Active"
    },
    {
        "Root": "10009997",
        "LV": "2",
        "Item Description": "LIQ BLUE CURACAO BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009997002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009997",
        "Product Status": "Active"
    },
    {
        "Root": "10108092",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED CHEDDAR WHITE 5LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108092001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.37",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108092",
        "Product Status": "Active"
    },
    {
        "Root": "10015520",
        "LV": "2",
        "Item Description": "POWDER GREEN TEA MATCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015520002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "GMT1",
        "Last Price Paid": "$125.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015520",
        "Product Status": "Active"
    },
    {
        "Root": "10090645",
        "LV": "1",
        "Item Description": "PRETZEL BAG DOTS HOMESTYLE HONEY MUSTARD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302108",
        "Last Price Paid": "$1.37",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090645",
        "Product Status": "Active"
    },
    {
        "Root": "10037051",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT MEDIUM ROUND 2.5Z BAKED 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037051",
        "Product Status": "Active"
    },
    {
        "Root": "10000416",
        "LV": "1",
        "Item Description": "CHIP TORTILLA YELLOW ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5911193",
        "Last Price Paid": "$2.51",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000416",
        "Product Status": "Active"
    },
    {
        "Root": "10004842",
        "LV": "1",
        "Item Description": "CANDY ALTOID PEPPERMINT 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "597451",
        "Last Price Paid": "$3.19",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004842",
        "Product Status": "Active"
    },
    {
        "Root": "10001271",
        "LV": "1",
        "Item Description": "JUICE MM LEMONADE CONCENTRATE 6/64OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "2500006434",
        "Last Price Paid": "$8.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001271",
        "Product Status": "Active"
    },
    {
        "Root": "10031264",
        "LV": "1",
        "Item Description": "SYRUP POWERADE LEMON LIME 2.5 GALLON BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031264",
        "Product Status": "Active"
    },
    {
        "Root": "10023620",
        "LV": "1",
        "Item Description": "COOKIE, SUGAR SHAPED GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10015602",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023620",
        "Product Status": "Active"
    },
    {
        "Root": "10081599",
        "LV": "1",
        "Item Description": "FLUTE JR 6OZ CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510180",
        "Last Price Paid": "",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081599",
        "Product Status": "Active"
    },
    {
        "Root": "10088027",
        "LV": "2",
        "Item Description": "CHICKEN WOG SPLIT 1.20# - 1.375#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088027002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088027",
        "Product Status": "Active"
    },
    {
        "Root": "10102695",
        "LV": "1",
        "Item Description": "JUICE BOLTHOUSE MANGO SMOOTHIE 6/15 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102695001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480504",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102695",
        "Product Status": "Active"
    },
    {
        "Root": "10007963",
        "LV": "2",
        "Item Description": "SAUV BLC BEYOND BUITEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007963001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007963",
        "Product Status": "Active"
    },
    {
        "Root": "10041559",
        "LV": "1",
        "Item Description": "GUACAMOLE BITES 30 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "301055",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041559",
        "Product Status": "Active"
    },
    {
        "Root": "10031943",
        "LV": "1",
        "Item Description": "SUGAR-BUNNY TAILS AND TOES ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031943",
        "Product Status": "Active"
    },
    {
        "Root": "10031845",
        "LV": "1",
        "Item Description": "CHARDONNAY GRAHAM BECK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031845001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "927474",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031845",
        "Product Status": "Active"
    },
    {
        "Root": "10002821",
        "LV": "2",
        "Item Description": "VINEGAR APPLE CIDER 4% 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002821002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002821",
        "Product Status": "Active"
    },
    {
        "Root": "10092934",
        "LV": "1",
        "Item Description": "JERKY MEATLESS 24/1 OZ STRIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6750079",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092934",
        "Product Status": "Active"
    },
    {
        "Root": "10031826",
        "LV": "1",
        "Item Description": "GELCREM COLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672136",
        "Last Price Paid": "$12.70",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031826",
        "Product Status": "Active"
    },
    {
        "Root": "10103936",
        "LV": "1",
        "Item Description": "IMPOSSIBLE SAUSAGE BRATWURST 4CT/8CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3000000012",
        "Last Price Paid": "$6.53",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103936",
        "Product Status": "Active"
    },
    {
        "Root": "10001339",
        "LV": "1",
        "Item Description": "SAUCE GARLIC CHIMICHURRI 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001339",
        "Product Status": "Active"
    },
    {
        "Root": "10023555",
        "LV": "1",
        "Item Description": "COCOA BUTTER PALE EGGPLANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "630177",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023555",
        "Product Status": "Active"
    },
    {
        "Root": "10005426",
        "LV": "2",
        "Item Description": "POWERADE POWDER LEMON LIM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005426002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005426",
        "Product Status": "Active"
    },
    {
        "Root": "10000886",
        "LV": "1",
        "Item Description": "DUCK LEG FRESH LI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000886001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000886",
        "Product Status": "Active"
    },
    {
        "Root": "10002444",
        "LV": "2",
        "Item Description": "ICE CREAM VANILLA NO SUGAR ADDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002444002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2228153",
        "Last Price Paid": "$35.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002444",
        "Product Status": "Active"
    },
    {
        "Root": "10012223",
        "LV": "1",
        "Item Description": "LIQ DOMAINE BENEDICTINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28789",
        "Last Price Paid": "$27.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012223",
        "Product Status": "Active"
    },
    {
        "Root": "10040822",
        "LV": "1",
        "Item Description": "COASTER SEBASTIAN/BANANA CABANA ROUND 4IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006588",
        "Last Price Paid": "$0.11",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040822",
        "Product Status": "Active"
    },
    {
        "Root": "10001396",
        "LV": "1",
        "Item Description": "PEACHES LOCAL FLORIDA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$60.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001396",
        "Product Status": "Active"
    },
    {
        "Root": "10004561",
        "LV": "1",
        "Item Description": "PEPPER RINGS MILD GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1807825",
        "Last Price Paid": "$10.08",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004561",
        "Product Status": "Active"
    },
    {
        "Root": "10008905",
        "LV": "1",
        "Item Description": "CAB SAUV HOBBS CRANE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555703",
        "Last Price Paid": "$230.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008905",
        "Product Status": "Active"
    },
    {
        "Root": "10015600",
        "LV": "1",
        "Item Description": "SPRITE ZERO 12OZ CAN FRIDGE PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "119791",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015600",
        "Product Status": "Active"
    },
    {
        "Root": "10028197",
        "LV": "1",
        "Item Description": "CANDY SNICKERS CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028197001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "525240",
        "Last Price Paid": "$95.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028197",
        "Product Status": "Active"
    },
    {
        "Root": "10002507",
        "LV": "1",
        "Item Description": "BERRY JUNIPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002507001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002507",
        "Product Status": "Active"
    },
    {
        "Root": "10007019",
        "LV": "1",
        "Item Description": "SPARK ENCHNTD WOODS OUAV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007019001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007019",
        "Product Status": "Active"
    },
    {
        "Root": "10034271",
        "LV": "1",
        "Item Description": "DRAFT PACIFIC RIM J RIELSING KEG 19.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346737",
        "Last Price Paid": "$194.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034271",
        "Product Status": "Active"
    },
    {
        "Root": "10000972",
        "LV": "1",
        "Item Description": "SMELT LAKE AK FORAGE 3-5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000972001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6850010",
        "Last Price Paid": "$2.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000972",
        "Product Status": "Active"
    },
    {
        "Root": "10000972",
        "LV": "1",
        "Item Description": "SMELT LAKE AK FORAGE 3-5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000972001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50032",
        "Last Price Paid": "$2.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000972",
        "Product Status": "Active"
    },
    {
        "Root": "10000296",
        "LV": "1",
        "Item Description": "SALSA PINEAPPLE WITHOUT JALAPENOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "815615",
        "Last Price Paid": "$33.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000296",
        "Product Status": "Active"
    },
    {
        "Root": "10014821",
        "LV": "1",
        "Item Description": "CIRCLE CAKE 8\" GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51616599",
        "Last Price Paid": "$0.27",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014821",
        "Product Status": "Active"
    },
    {
        "Root": "10106349",
        "LV": "1",
        "Item Description": "WATER AHA LIME WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106349",
        "Product Status": "Active"
    },
    {
        "Root": "10003659",
        "LV": "1",
        "Item Description": "HONEY TUPELO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "IO129",
        "Last Price Paid": "$30.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003659",
        "Product Status": "Active"
    },
    {
        "Root": "10034810",
        "LV": "1",
        "Item Description": "HERB ENGLISH LAVENDER BLOOM 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05ELB-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034810",
        "Product Status": "Active"
    },
    {
        "Root": "10034810",
        "LV": "1",
        "Item Description": "HERB ENGLISH LAVENDER BLOOM 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22013",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034810",
        "Product Status": "Active"
    },
    {
        "Root": "10030342",
        "LV": "1",
        "Item Description": "POWDER KATSUO DASHI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY948",
        "Last Price Paid": "$6.53",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030342",
        "Product Status": "Active"
    },
    {
        "Root": "10015065",
        "LV": "1",
        "Item Description": "RADISH BREAKFAST 24 BU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481517",
        "Last Price Paid": "$2.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015065",
        "Product Status": "Active"
    },
    {
        "Root": "10001414",
        "LV": "1",
        "Item Description": "FLOWER MARIGOLD MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MMA-24",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001414",
        "Product Status": "Active"
    },
    {
        "Root": "10000771",
        "LV": "1",
        "Item Description": "BEEF WAGYU TENDERLOIN AUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000771001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000771",
        "Product Status": "Active"
    },
    {
        "Root": "10009391",
        "LV": "2",
        "Item Description": "PINOT NOIR TALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009391001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9686586",
        "Last Price Paid": "$25.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009391",
        "Product Status": "Active"
    },
    {
        "Root": "10001262",
        "LV": "1",
        "Item Description": "POTATO REDSKIN WEDGE BLANCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001262",
        "Product Status": "Active"
    },
    {
        "Root": "10021891",
        "LV": "1",
        "Item Description": "SAUCE SOY TAMARI GF LOW SODIUM 6/.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2595348",
        "Last Price Paid": "$12.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021891",
        "Product Status": "Active"
    },
    {
        "Root": "10004595",
        "LV": "1",
        "Item Description": "SPICE SALT SEA COURSE 26Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7422207",
        "Last Price Paid": "$3.43",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004595",
        "Product Status": "Active"
    },
    {
        "Root": "10000370",
        "LV": "1",
        "Item Description": "PEPPER GRN DCD 1/4 INCH 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "112298",
        "Last Price Paid": "$13.90",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000370",
        "Product Status": "Active"
    },
    {
        "Root": "10000370",
        "LV": "1",
        "Item Description": "PEPPER GRN DCD 1/4 INCH 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "07-001-14",
        "Last Price Paid": "$13.90",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000370",
        "Product Status": "Active"
    },
    {
        "Root": "10035767",
        "LV": "1",
        "Item Description": "SB STRAW PAPER GREEN COMPOSTABLE 10.25 IN CS-2400",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11084255",
        "Last Price Paid": "$0.05",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035767",
        "Product Status": "Active"
    },
    {
        "Root": "10006200",
        "LV": "2",
        "Item Description": "BAG GUSSET CELLO 4 X 2.75 X 9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006200001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51066003",
        "Last Price Paid": "$0.05",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006200",
        "Product Status": "Active"
    },
    {
        "Root": "10038269",
        "LV": "1",
        "Item Description": "MUG PLASTIC COPPER MOSCOW MULE 16Z 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11004617",
        "Last Price Paid": "$2.29",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038269",
        "Product Status": "Active"
    },
    {
        "Root": "10088423",
        "LV": "1",
        "Item Description": "RICE KRISPY RASPBERRY STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "RRK",
        "Last Price Paid": "$0.84",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088423",
        "Product Status": "Active"
    },
    {
        "Root": "10031244",
        "LV": "1",
        "Item Description": "SWEET POTATO BABY 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031244001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MHBSP-2",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031244",
        "Product Status": "Active"
    },
    {
        "Root": "10025274",
        "LV": "1",
        "Item Description": "FLAVORING APPLE OIL SOLUBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "506102B",
        "Last Price Paid": "$25.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025274",
        "Product Status": "Active"
    },
    {
        "Root": "10030429",
        "LV": "2",
        "Item Description": "CHIANTI CASTELLO DEI RAMPOLA CLASSICO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030429002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "981066",
        "Last Price Paid": "$30.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030429",
        "Product Status": "Active"
    },
    {
        "Root": "10018532",
        "LV": "3",
        "Item Description": "GEL NAVY BLUE  COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018532003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22511",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10018532",
        "Product Status": "Active"
    },
    {
        "Root": "10044127",
        "LV": "1",
        "Item Description": "MILK CHOCOLATE 1% 48/8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044127001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8353906",
        "Last Price Paid": "$0.46",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044127",
        "Product Status": "Active"
    },
    {
        "Root": "10003595",
        "LV": "1",
        "Item Description": "CANDY TWIX CARMEL 2.1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "58345",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003595",
        "Product Status": "Active"
    },
    {
        "Root": "10004152",
        "LV": "1",
        "Item Description": "PASTA RAVIOLI PMPKN MSCRP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070302",
        "Last Price Paid": "$25.06",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004152",
        "Product Status": "Active"
    },
    {
        "Root": "10000533",
        "LV": "1",
        "Item Description": "BREAD RYE WHITE OVAL 2.25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000533",
        "Product Status": "Active"
    },
    {
        "Root": "10009820",
        "LV": "2",
        "Item Description": "RUM PYRAT XO RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009820001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "563203",
        "Last Price Paid": "$21.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009820",
        "Product Status": "Active"
    },
    {
        "Root": "10037294",
        "LV": "2",
        "Item Description": "MASON JAR DISNEY BEACH CLUB 24OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037294001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000071765",
        "Vendor": "PROGRESSIVE SPECIALTY GLASS COMPANY",
        "Vendor Item Nb": "35229-5330-3P",
        "Last Price Paid": "$1.38",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10037294",
        "Product Status": "Active"
    },
    {
        "Root": "10034813",
        "LV": "1",
        "Item Description": "SNOWMAN W/HAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y2386",
        "Last Price Paid": "$0.47",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034813",
        "Product Status": "Active"
    },
    {
        "Root": "10101928",
        "LV": "1",
        "Item Description": "COGNAC BRANSON PHANTOM VS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "941563",
        "Last Price Paid": "$36.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101928",
        "Product Status": "Active"
    },
    {
        "Root": "10000181",
        "LV": "1",
        "Item Description": "CABBAGE GREEN MEDIUM 50# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000181001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482075",
        "Last Price Paid": "$1.66",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000181",
        "Product Status": "Active"
    },
    {
        "Root": "10024571",
        "LV": "2",
        "Item Description": "CHARD LEFLAIVE PULIGNY CLAVOILLON 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024571002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9653947",
        "Last Price Paid": "$260.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10024571",
        "Product Status": "Active"
    },
    {
        "Root": "10000332",
        "LV": "1",
        "Item Description": "ONION SPNSH SLIVERED 3/16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514869",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000332",
        "Product Status": "Active"
    },
    {
        "Root": "10000332",
        "LV": "1",
        "Item Description": "ONION SPNSH SLIVERED 3/16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-007-16",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000332",
        "Product Status": "Active"
    },
    {
        "Root": "10021899",
        "LV": "1",
        "Item Description": "APRICOT DRIED 5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021899001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY450",
        "Last Price Paid": "$57.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021899",
        "Product Status": "Active"
    },
    {
        "Root": "10004797",
        "LV": "1",
        "Item Description": "SAUCE OYSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4348173",
        "Last Price Paid": "$7.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004797",
        "Product Status": "Active"
    },
    {
        "Root": "10035688",
        "LV": "2",
        "Item Description": "SAUCE GHIRARDELLI WHITE CHOCOLATE 6/87 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035688002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582781",
        "Last Price Paid": "$24.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035688",
        "Product Status": "Active"
    },
    {
        "Root": "10010130",
        "LV": "2",
        "Item Description": "WATER FIUGGI SPARKLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010130001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010130",
        "Product Status": "Active"
    },
    {
        "Root": "10036993",
        "LV": "1",
        "Item Description": "ZINFANDEL SALDO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036993001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570865",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036993",
        "Product Status": "Active"
    },
    {
        "Root": "10010617",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN 30YR FINE OAK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294235",
        "Last Price Paid": "$3,825.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010617",
        "Product Status": "Active"
    },
    {
        "Root": "10002687",
        "LV": "1",
        "Item Description": "CRACKER OYSTER 150PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002687001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7877093",
        "Last Price Paid": "$0.14",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002687",
        "Product Status": "Active"
    },
    {
        "Root": "10015909",
        "LV": "1",
        "Item Description": "BAR PEANUT BUTTER CHOC CHP VEGAN LARA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "409144",
        "Last Price Paid": "$1.58",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015909",
        "Product Status": "Active"
    },
    {
        "Root": "10008115",
        "LV": "2",
        "Item Description": "PINOT NOIR GOLDENEYE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008115001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "599141",
        "Last Price Paid": "$35.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008115",
        "Product Status": "Active"
    },
    {
        "Root": "10005768",
        "LV": "1",
        "Item Description": "MUFFIN ASSORTED MINI 2.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005768001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005768",
        "Product Status": "Active"
    },
    {
        "Root": "10017894",
        "LV": "1",
        "Item Description": "SYRUP BARQS OLD STYLE ROOT BEER 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "229187",
        "Last Price Paid": "$107.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017894",
        "Product Status": "Active"
    },
    {
        "Root": "10039012",
        "LV": "1",
        "Item Description": "COOKIES NUTTER BUTTER 1.9Z 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302440",
        "Last Price Paid": "$0.82",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039012",
        "Product Status": "Active"
    },
    {
        "Root": "10003637",
        "LV": "1",
        "Item Description": "MUSTARD YELLOW 12Z SQUEEZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1306653",
        "Last Price Paid": "$1.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003637",
        "Product Status": "Active"
    },
    {
        "Root": "10105628",
        "LV": "1",
        "Item Description": "ICING WHITE AND GLOSSY 43LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$96.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105628",
        "Product Status": "Active"
    },
    {
        "Root": "10004658",
        "LV": "1",
        "Item Description": "NUT CASHEW PIECES RSTD NO SALT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "120720",
        "Last Price Paid": "$106.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004658",
        "Product Status": "Active"
    },
    {
        "Root": "10005755",
        "LV": "1",
        "Item Description": "BAGEL EVERYTHING 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005755",
        "Product Status": "Active"
    },
    {
        "Root": "10006424",
        "LV": "1",
        "Item Description": "STAND SMART STOCK DISP BL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "SSBASE08",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006424",
        "Product Status": "Active"
    },
    {
        "Root": "10039964",
        "LV": "1",
        "Item Description": "CHEESE VEGAN MIYOKOS SMOKED MOZZ 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "209983",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039964",
        "Product Status": "Active"
    },
    {
        "Root": "10001658",
        "LV": "2",
        "Item Description": "APPLE CIDER PAST 4-1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001658002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001658",
        "Product Status": "Active"
    },
    {
        "Root": "10017806",
        "LV": "1",
        "Item Description": "PETITE SQUASH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "16PMS-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017806",
        "Product Status": "Active"
    },
    {
        "Root": "10096012",
        "LV": "1",
        "Item Description": "TEA TEALEAVES SENCHA FUKUJYU CHA GRN LOOSE LEAF 14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10096012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W5004",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10096012",
        "Product Status": "Active"
    },
    {
        "Root": "10091212",
        "LV": "2",
        "Item Description": "STRAW HM BOBA COLOSSAL STRIPE WRAPPED 8.5IN/960CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091212001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10091212",
        "Product Status": "Active"
    },
    {
        "Root": "10083470",
        "LV": "1",
        "Item Description": "MIX BAR STRAWBERRY 2 TO 1 CONCENTRATE FZN 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083470",
        "Product Status": "Active"
    },
    {
        "Root": "10030805",
        "LV": "1",
        "Item Description": "NITRILE GLOVE BLUE LARGE P/F 8099-3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "79198155",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030805",
        "Product Status": "Active"
    },
    {
        "Root": "10002664",
        "LV": "1",
        "Item Description": "SAUCE DEMI-GLACE 4/40.8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002664",
        "Product Status": "Active"
    },
    {
        "Root": "10025559",
        "LV": "1",
        "Item Description": "CONTAINER CLAMSHELL COMPOSTABLE 9X5X3 CLEAR HINGED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51400004",
        "Last Price Paid": "",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025559",
        "Product Status": "Active"
    },
    {
        "Root": "10035748",
        "LV": "1",
        "Item Description": "GUMPASTE SPRAY MULTI COLOR SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035748",
        "Product Status": "Active"
    },
    {
        "Root": "10041514",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT AOC BUTTER STRAIGHT 2.2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041514",
        "Product Status": "Active"
    },
    {
        "Root": "10007588",
        "LV": "2",
        "Item Description": "CHARD PAUL HOBBS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007588001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007588",
        "Product Status": "Active"
    },
    {
        "Root": "10005122",
        "LV": "1",
        "Item Description": "SEQUINS SUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9500",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005122",
        "Product Status": "Active"
    },
    {
        "Root": "10092214",
        "LV": "2",
        "Item Description": "TEA BAGS STARCRUISER ONLY REP BKFST BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092214002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8288297",
        "Last Price Paid": "",
        "Case Pack": "1920",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092214",
        "Product Status": "Active"
    },
    {
        "Root": "10028970",
        "LV": "2",
        "Item Description": "WINE SOKOL BLOSSER EVOLUTION PINOT NOIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028970001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "512236",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10028970",
        "Product Status": "Active"
    },
    {
        "Root": "10105111",
        "LV": "1",
        "Item Description": "CUP PINT JR 12OZ FOTA HOL REDEMPTION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11090125",
        "Last Price Paid": "",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105111",
        "Product Status": "Active"
    },
    {
        "Root": "10012713",
        "LV": "1",
        "Item Description": "MUSTARD CRESS BLOOMS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012713001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09NGMCB-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012713",
        "Product Status": "Active"
    },
    {
        "Root": "10032484",
        "LV": "1",
        "Item Description": "RICE BOMBA MATIZ 2.2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032484001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "RIC001",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032484",
        "Product Status": "Active"
    },
    {
        "Root": "10103942",
        "LV": "1",
        "Item Description": "BAR MIX COCO LOPEZ CCNUT CRM SHLF STABL 15OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6004030",
        "Last Price Paid": "$2.88",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103942",
        "Product Status": "Active"
    },
    {
        "Root": "10002309",
        "LV": "1",
        "Item Description": "MILK ALMOND PLN SILK 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "136462",
        "Last Price Paid": "$2.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002309",
        "Product Status": "Active"
    },
    {
        "Root": "10089230",
        "LV": "1",
        "Item Description": "DONUT YEAST RING UNGLAZED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS103",
        "Last Price Paid": "$0.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089230",
        "Product Status": "Active"
    },
    {
        "Root": "10001359",
        "LV": "1",
        "Item Description": "MASALA TIKKA 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001359",
        "Product Status": "Active"
    },
    {
        "Root": "10002870",
        "LV": "1",
        "Item Description": "SPRITE 20OZ BTL 24EA PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "143886",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002870",
        "Product Status": "Active"
    },
    {
        "Root": "10015788",
        "LV": "1",
        "Item Description": "FENNEL LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482355",
        "Last Price Paid": "$56.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015788",
        "Product Status": "Active"
    },
    {
        "Root": "10004130",
        "LV": "1",
        "Item Description": "SHELL CHOCOLATE 2.3IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MG66",
        "Last Price Paid": "$0.30",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004130",
        "Product Status": "Active"
    },
    {
        "Root": "10016339",
        "LV": "1",
        "Item Description": "CUPCAKE MED YELL BLUE ICING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1648",
        "Last Price Paid": "$0.71",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016339",
        "Product Status": "Active"
    },
    {
        "Root": "10005493",
        "LV": "1",
        "Item Description": "OIL EXTRA VIRGIN OLIVE PR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005493",
        "Product Status": "Active"
    },
    {
        "Root": "10003998",
        "LV": "1",
        "Item Description": "CHOCOLATE MILK 40% JIVARA VALRHONA LACTEE 6.6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.97",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003998",
        "Product Status": "Active"
    },
    {
        "Root": "10021766",
        "LV": "1",
        "Item Description": "PASTA SQUID INK FETTU NEST 6/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "70-70283",
        "Last Price Paid": "$12.71",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021766",
        "Product Status": "Active"
    },
    {
        "Root": "10001273",
        "LV": "1",
        "Item Description": "JUICE MM APPLE CONCENTRATE 6/64OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "2500006446",
        "Last Price Paid": "$14.85",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001273",
        "Product Status": "Active"
    },
    {
        "Root": "10033726",
        "LV": "1",
        "Item Description": "LAMB RACKS FRCH AUSSIE 28 UP FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033726001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1506314",
        "Last Price Paid": "$14.60",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033726",
        "Product Status": "Active"
    },
    {
        "Root": "10005656",
        "LV": "2",
        "Item Description": "MACAROONS ASSORTED LRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005656001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LRN8094056",
        "Last Price Paid": "$0.85",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005656",
        "Product Status": "Active"
    },
    {
        "Root": "10004850",
        "LV": "1",
        "Item Description": "ARTICHOKE HEART MARINATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5357546",
        "Last Price Paid": "$24.11",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004850",
        "Product Status": "Active"
    },
    {
        "Root": "10005620",
        "LV": "1",
        "Item Description": "PIZZA CHEESE KIDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "10594",
        "Last Price Paid": "$1.00",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005620",
        "Product Status": "Active"
    },
    {
        "Root": "10032590",
        "LV": "1",
        "Item Description": "CHEESE MOZZARELLA LOW MOISTURE WHOLE MILK 6 LB PIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032590001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5890",
        "Last Price Paid": "$3.32",
        "Case Pack": "6.13",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032590",
        "Product Status": "Active"
    },
    {
        "Root": "10090628",
        "LV": "1",
        "Item Description": "SNAPPER RED 6OZ PORTION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090628001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6503103",
        "Last Price Paid": "$29.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090628",
        "Product Status": "Active"
    },
    {
        "Root": "10107262",
        "LV": "1",
        "Item Description": "SAUCE, PIZZA TOMATO RTU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.69",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107262",
        "Product Status": "Active"
    },
    {
        "Root": "10106572",
        "LV": "1",
        "Item Description": "COLOR GLITTER EDIBLE BLUE 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "403003B",
        "Last Price Paid": "$8.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106572",
        "Product Status": "Active"
    },
    {
        "Root": "10003697",
        "LV": "1",
        "Item Description": "SB 9OZ SB ICD CFF BLND CL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003697001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "195704",
        "Last Price Paid": "$5.92",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003697",
        "Product Status": "Active"
    },
    {
        "Root": "10001575",
        "LV": "1",
        "Item Description": "PEPPER RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598831",
        "Last Price Paid": "$17.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001575",
        "Product Status": "Active"
    },
    {
        "Root": "10004939",
        "LV": "1",
        "Item Description": "SUGAR SWEET NEW SNOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BEL & SCHAF",
        "Last Price Paid": "$32.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004939",
        "Product Status": "Active"
    },
    {
        "Root": "10003565",
        "LV": "1",
        "Item Description": "SPICE CREAM OF TARTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003565001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003565",
        "Product Status": "Active"
    },
    {
        "Root": "10018625",
        "LV": "1",
        "Item Description": "SPARK,PASCUAL,TOSO,BRUT,750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "125271",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018625",
        "Product Status": "Active"
    },
    {
        "Root": "10018625",
        "LV": "1",
        "Item Description": "SPARK,PASCUAL,TOSO,BRUT,750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "125271",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018625",
        "Product Status": "Active"
    },
    {
        "Root": "10030684",
        "LV": "1",
        "Item Description": "RICE CRISPY TREAT MM EARS DIP ON STICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030684001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "2000",
        "Last Price Paid": "$1.55",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030684",
        "Product Status": "Active"
    },
    {
        "Root": "10107439",
        "LV": "1",
        "Item Description": "BEEF BUTCHER'S FILET TAIL 4Z-6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107439001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190115",
        "Last Price Paid": "$12.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107439",
        "Product Status": "Active"
    },
    {
        "Root": "10015386",
        "LV": "1",
        "Item Description": "DEC ROSE LG RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKB RD",
        "Last Price Paid": "$2.42",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015386",
        "Product Status": "Active"
    },
    {
        "Root": "10000052",
        "LV": "1",
        "Item Description": "LAMB SHOULDER BONELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000052001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240205",
        "Last Price Paid": "$9.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000052",
        "Product Status": "Active"
    },
    {
        "Root": "10016573",
        "LV": "1",
        "Item Description": "BOX LUNCH WF CLASS 1 - NO SANDWICH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1723090",
        "Last Price Paid": "$10.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016573",
        "Product Status": "Active"
    },
    {
        "Root": "10007430",
        "LV": "1",
        "Item Description": "CAB CAYMUS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "601088",
        "Last Price Paid": "$60.00",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007430",
        "Product Status": "Active"
    },
    {
        "Root": "10001388",
        "LV": "1",
        "Item Description": "PEPPER RED JUL 1/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510010",
        "Last Price Paid": "$17.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001388",
        "Product Status": "Active"
    },
    {
        "Root": "10001388",
        "LV": "1",
        "Item Description": "PEPPER RED JUL 1/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-005",
        "Last Price Paid": "$17.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001388",
        "Product Status": "Active"
    },
    {
        "Root": "10001920",
        "LV": "1",
        "Item Description": "ONION CIPPOLINI GRILLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001920001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593026",
        "Last Price Paid": "$32.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001920",
        "Product Status": "Active"
    },
    {
        "Root": "10108430",
        "LV": "1",
        "Item Description": "SUGAR-HOLIDAY GNOMES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28355",
        "Last Price Paid": "$0.31",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108430",
        "Product Status": "Active"
    },
    {
        "Root": "10001472",
        "LV": "1",
        "Item Description": "APPLE RED DELICIOUS 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001472001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480273",
        "Last Price Paid": "$0.42",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001472",
        "Product Status": "Active"
    },
    {
        "Root": "10034057",
        "LV": "1",
        "Item Description": "JUICE RASPBERRY LEMONADE SIMPLY 12/11.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8706673",
        "Last Price Paid": "$1.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034057",
        "Product Status": "Active"
    },
    {
        "Root": "10004592",
        "LV": "2",
        "Item Description": "VINEGAR RICE WINE SEASONED 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004592002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8036717",
        "Last Price Paid": "$13.85",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004592",
        "Product Status": "Active"
    },
    {
        "Root": "10108336",
        "LV": "1",
        "Item Description": "DEC CHOC OVAL 1.5X1 INCREDIBLE 576CS WHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "1119CP",
        "Last Price Paid": "$0.39",
        "Case Pack": "576",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108336",
        "Product Status": "Active"
    },
    {
        "Root": "10009876",
        "LV": "1",
        "Item Description": "PORT QNTA NOVAL BLK NV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9101283",
        "Last Price Paid": "$15.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009876",
        "Product Status": "Active"
    },
    {
        "Root": "10005308",
        "LV": "1",
        "Item Description": "PUREE GRAPEFRUIT 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B530",
        "Last Price Paid": "$14.62",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005308",
        "Product Status": "Active"
    },
    {
        "Root": "10023616",
        "LV": "1",
        "Item Description": "FLOUR CASSAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "MISC900",
        "Last Price Paid": "$8.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023616",
        "Product Status": "Active"
    },
    {
        "Root": "10039901",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET - 7Z AAA LAYER PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039901001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190166",
        "Last Price Paid": "$29.06",
        "Case Pack": "8.75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039901",
        "Product Status": "Active"
    },
    {
        "Root": "10036330",
        "LV": "1",
        "Item Description": "SEASONAL DL521028 2.5\" ROUND LABEL BLACK ON KRAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001088",
        "Last Price Paid": "$0.06",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036330",
        "Product Status": "Active"
    },
    {
        "Root": "10044437",
        "LV": "1",
        "Item Description": "SPICE CELERY SALT 35 OZ PLASTIC BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6353429",
        "Last Price Paid": "$9.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044437",
        "Product Status": "Active"
    },
    {
        "Root": "10010071",
        "LV": "1",
        "Item Description": "BEER MICH ULTRA ALUM 16OZ/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.09",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010071",
        "Product Status": "Active"
    },
    {
        "Root": "10039981",
        "LV": "1",
        "Item Description": "SELTZER HARD CAN WHITE CLAW BLACK CHERRY 16OZ 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039981",
        "Product Status": "Active"
    },
    {
        "Root": "10002740",
        "LV": "1",
        "Item Description": "SYRUP LEMONADE MM 2.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002740",
        "Product Status": "Active"
    },
    {
        "Root": "10002307",
        "LV": "2",
        "Item Description": "CHEESE, VEGAN MOZZ SHRD DAIYA 8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002307001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3DFU01-101021",
        "Last Price Paid": "$3.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002307",
        "Product Status": "Active"
    },
    {
        "Root": "10017096",
        "LV": "1",
        "Item Description": "APPLE GALA 88 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.34",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017096",
        "Product Status": "Active"
    },
    {
        "Root": "10004664",
        "LV": "1",
        "Item Description": "CANDY TOPPING MORSEL BUTTERSCOTCH MORSELS 4000C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582501",
        "Last Price Paid": "$55.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004664",
        "Product Status": "Active"
    },
    {
        "Root": "10004146",
        "LV": "1",
        "Item Description": "TRUFFLE PINEAPPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.08",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004146",
        "Product Status": "Active"
    },
    {
        "Root": "10002940",
        "LV": "1",
        "Item Description": "TEA ICE LADY GREY LS 100G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.56",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002940",
        "Product Status": "Active"
    },
    {
        "Root": "10101888",
        "LV": "1",
        "Item Description": "MEATBALL FONTANINI ALLNATURALGLUTENFREE 2OZ 5/3 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.47",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101888",
        "Product Status": "Active"
    },
    {
        "Root": "10002065",
        "LV": "1",
        "Item Description": "ONION WHITE LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002065001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002065",
        "Product Status": "Active"
    },
    {
        "Root": "10028673",
        "LV": "1",
        "Item Description": "BRANDY MASSENEZ KIRSCH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "107790",
        "Last Price Paid": "$41.97",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028673",
        "Product Status": "Active"
    },
    {
        "Root": "10012362",
        "LV": "1",
        "Item Description": "BLACK GROUPER FILLET SKIN OFF,WILD SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012362001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3314328",
        "Last Price Paid": "$23.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012362",
        "Product Status": "Active"
    },
    {
        "Root": "10012362",
        "LV": "1",
        "Item Description": "BLACK GROUPER FILLET SKIN OFF,WILD SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012362001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00340",
        "Last Price Paid": "$23.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012362",
        "Product Status": "Active"
    },
    {
        "Root": "10012919",
        "LV": "3",
        "Item Description": "MEAT LUNCH SPAM PORK COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012919002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4583241",
        "Last Price Paid": "$2.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10012919",
        "Product Status": "Active"
    },
    {
        "Root": "10001328",
        "LV": "1",
        "Item Description": "ONION RING BEER BATTERED 5/8 INCH 6/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.18",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001328",
        "Product Status": "Active"
    },
    {
        "Root": "10044620",
        "LV": "1",
        "Item Description": "BEER LOST COAST TANGERINE 15.5G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "7851",
        "Last Price Paid": "$159.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044620",
        "Product Status": "Active"
    },
    {
        "Root": "10044620",
        "LV": "1",
        "Item Description": "BEER LOST COAST TANGERINE 15.5G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "7851",
        "Last Price Paid": "$159.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044620",
        "Product Status": "Active"
    },
    {
        "Root": "10003963",
        "LV": "1",
        "Item Description": "OIL OLIVE SMOKED NAPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003963001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OL150",
        "Last Price Paid": "$19.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003963",
        "Product Status": "Active"
    },
    {
        "Root": "10002955",
        "LV": "1",
        "Item Description": "MUSTARD HONEY 1OZ SIMPLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002955",
        "Product Status": "Active"
    },
    {
        "Root": "10027345",
        "LV": "1",
        "Item Description": "EXTRACT POPCORN OIL SOLUBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1064",
        "Last Price Paid": "$196.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027345",
        "Product Status": "Active"
    },
    {
        "Root": "10019277",
        "LV": "1",
        "Item Description": "MIXED CURRANT TOMATOES 1/2 PT FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17MCURT-11",
        "Last Price Paid": "$132.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019277",
        "Product Status": "Active"
    },
    {
        "Root": "10110028",
        "LV": "1",
        "Item Description": "CHAMPAGNE POL ROGER 16 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "591902",
        "Last Price Paid": "$99.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110028",
        "Product Status": "Active"
    },
    {
        "Root": "10000844",
        "LV": "1",
        "Item Description": "CHICK DRUM LEG FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000844001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.54",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000844",
        "Product Status": "Active"
    },
    {
        "Root": "10102910",
        "LV": "1",
        "Item Description": "KOSHER BSC HOT DOG POT/VEG/BUN 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102910",
        "Product Status": "Active"
    },
    {
        "Root": "10005821",
        "LV": "1",
        "Item Description": "CAKE CHEESE RAINBOW INDIVIDUAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.71",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005821",
        "Product Status": "Active"
    },
    {
        "Root": "10032919",
        "LV": "1",
        "Item Description": "SYRUP LAVENDER MONIN LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5061",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032919",
        "Product Status": "Active"
    },
    {
        "Root": "10025495",
        "LV": "1",
        "Item Description": "CANDY RED HOT CINN IMPERIALS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "24345",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025495",
        "Product Status": "Active"
    },
    {
        "Root": "10018874",
        "LV": "1",
        "Item Description": "DECOR, FLASHY SPARKLES, DARK GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1006061",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018874",
        "Product Status": "Active"
    },
    {
        "Root": "10034412",
        "LV": "1",
        "Item Description": "MONASTRELL JUAN GIL SILVER LABEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034412",
        "Product Status": "Active"
    },
    {
        "Root": "10034319",
        "LV": "1",
        "Item Description": "TAWNY PORT RL BULLER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034319001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034319",
        "Product Status": "Active"
    },
    {
        "Root": "10012554",
        "LV": "1",
        "Item Description": "HYSSOP ORANGE BLOOMS 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012554001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05OHYB-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012554",
        "Product Status": "Active"
    },
    {
        "Root": "10014001",
        "LV": "1",
        "Item Description": "LINER BASKET YELLOW 12X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3128097",
        "Last Price Paid": "",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014001",
        "Product Status": "Active"
    },
    {
        "Root": "10032491",
        "LV": "1",
        "Item Description": "NAITVE BLUE HOMINY CORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032491001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "COR002",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032491",
        "Product Status": "Active"
    },
    {
        "Root": "10036735",
        "LV": "1",
        "Item Description": "COFFEENOSA FILLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500470000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036735",
        "Product Status": "Active"
    },
    {
        "Root": "10001410",
        "LV": "1",
        "Item Description": "ASIAN BLEND MICRO PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MAB-33-L",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001410",
        "Product Status": "Active"
    },
    {
        "Root": "10090522",
        "LV": "1",
        "Item Description": "DRAFT CENTRAL 28 WALL ART OATMEAL BROWN ALE 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090522001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99977",
        "Last Price Paid": "$185.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090522",
        "Product Status": "Active"
    },
    {
        "Root": "10000854",
        "LV": "1",
        "Item Description": "DUCK BRST SMOKED NEUS 13 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000854001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4311111",
        "Last Price Paid": "$20.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000854",
        "Product Status": "Active"
    },
    {
        "Root": "10003083",
        "LV": "2",
        "Item Description": "SAUCE MARINARA BELL ORTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003083002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.06",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003083",
        "Product Status": "Active"
    },
    {
        "Root": "10000760",
        "LV": "1",
        "Item Description": "LAMB GROUND FINE FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000760001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1500100",
        "Last Price Paid": "$7.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000760",
        "Product Status": "Active"
    },
    {
        "Root": "10005652",
        "LV": "2",
        "Item Description": "SHELL CONE VANILLA 83CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005652001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LRN8090456",
        "Last Price Paid": "$0.56",
        "Case Pack": "83",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005652",
        "Product Status": "Active"
    },
    {
        "Root": "10108731",
        "LV": "1",
        "Item Description": "CHICKEN BREAST FLATTENED 6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108731001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.17",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108731",
        "Product Status": "Active"
    },
    {
        "Root": "10010271",
        "LV": "2",
        "Item Description": "DRAFT SHOCKTOP 1/6BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010271001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "23006",
        "Last Price Paid": "$63.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010271",
        "Product Status": "Active"
    },
    {
        "Root": "10001771",
        "LV": "1",
        "Item Description": "TOMATO MIXED SMALL/TOYBOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23052",
        "Last Price Paid": "$25.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001771",
        "Product Status": "Active"
    },
    {
        "Root": "10001771",
        "LV": "1",
        "Item Description": "TOMATO MIXED SMALL/TOYBOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598920",
        "Last Price Paid": "$25.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001771",
        "Product Status": "Active"
    },
    {
        "Root": "10001771",
        "LV": "1",
        "Item Description": "TOMATO MIXED SMALL/TOYBOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001771",
        "Product Status": "Active"
    },
    {
        "Root": "10002081",
        "LV": "1",
        "Item Description": "SUGAR CANE SWIZZLE STICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.48",
        "Case Pack": "132",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002081",
        "Product Status": "Active"
    },
    {
        "Root": "10002081",
        "LV": "1",
        "Item Description": "SUGAR CANE SWIZZLE STICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482040",
        "Last Price Paid": "$0.48",
        "Case Pack": "132",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002081",
        "Product Status": "Active"
    },
    {
        "Root": "10030146",
        "LV": "1",
        "Item Description": "SHEET HALF SILVER 1/2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ES12-SILVER",
        "Last Price Paid": "$2.96",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030146",
        "Product Status": "Active"
    },
    {
        "Root": "10088235",
        "LV": "1",
        "Item Description": "STEAMED BUN FILLED W/BEEF TERIYAKI AND VEGETABLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.65",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088235",
        "Product Status": "Active"
    },
    {
        "Root": "10001246",
        "LV": "1",
        "Item Description": "CAVIAR RED SALMON 9 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001246",
        "Product Status": "Active"
    },
    {
        "Root": "10001246",
        "LV": "1",
        "Item Description": "CAVIAR RED SALMON 9 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6150007",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001246",
        "Product Status": "Active"
    },
    {
        "Root": "10001246",
        "LV": "1",
        "Item Description": "CAVIAR RED SALMON 9 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001246",
        "Product Status": "Active"
    },
    {
        "Root": "10001246",
        "LV": "1",
        "Item Description": "CAVIAR RED SALMON 9 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "61110060",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001246",
        "Product Status": "Active"
    },
    {
        "Root": "10001246",
        "LV": "1",
        "Item Description": "CAVIAR RED SALMON 9 OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-90440",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001246",
        "Product Status": "Active"
    },
    {
        "Root": "10001842",
        "LV": "1",
        "Item Description": "BEAN SEA 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001842001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20107",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001842",
        "Product Status": "Active"
    },
    {
        "Root": "10001842",
        "LV": "1",
        "Item Description": "BEAN SEA 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001842001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001842",
        "Product Status": "Active"
    },
    {
        "Root": "10001842",
        "LV": "1",
        "Item Description": "BEAN SEA 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001842001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30165",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001842",
        "Product Status": "Active"
    },
    {
        "Root": "10092251",
        "LV": "1",
        "Item Description": "CUP PLASTIC 8OZ TUMBLER CLEAR SMOOTH WALL 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "T8T",
        "Last Price Paid": "$0.09",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092251",
        "Product Status": "Active"
    },
    {
        "Root": "10018608",
        "LV": "1",
        "Item Description": "COMPOUND BANANA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "11107",
        "Last Price Paid": "$9.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018608",
        "Product Status": "Active"
    },
    {
        "Root": "10039941",
        "LV": "1",
        "Item Description": "PASTRY CINNAMON BUN 4.6 OZ BUTTER RTB 30 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.92",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039941",
        "Product Status": "Active"
    },
    {
        "Root": "10016588",
        "LV": "1",
        "Item Description": "COFFEE TIFFINS ETHOP REG 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.78",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016588",
        "Product Status": "Active"
    },
    {
        "Root": "10006833",
        "LV": "1",
        "Item Description": "CAKE COFFEE CINNMN/CRUMB 10 IN SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006833",
        "Product Status": "Active"
    },
    {
        "Root": "10038060",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE WORKHORSE 26% CALLEBAUT CALLET 22#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "G13387",
        "Last Price Paid": "$130.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038060",
        "Product Status": "Active"
    },
    {
        "Root": "10041317",
        "LV": "1",
        "Item Description": "SB STIRRER WOOD WRAPPED 7 INCH CS 10000 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11110028",
        "Last Price Paid": "$0.01",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041317",
        "Product Status": "Active"
    },
    {
        "Root": "10000076",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 4Z  GRAIN FED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000076001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195102",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000076",
        "Product Status": "Active"
    },
    {
        "Root": "10005642",
        "LV": "1",
        "Item Description": "COOKIE CHOCOLATE CHIP 3 OZ PLUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.38",
        "Case Pack": "85",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005642",
        "Product Status": "Active"
    },
    {
        "Root": "10101069",
        "LV": "2",
        "Item Description": "FOAM POWDER SWGE ONLY ELECTRICDUST BUZZ 320G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101069002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000605753",
        "Vendor": "WANDERLUSH LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$408.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10101069",
        "Product Status": "Active"
    },
    {
        "Root": "10005828",
        "LV": "1",
        "Item Description": "BAGEL MINI (2.5 OZ) 8-GRAIN 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005828",
        "Product Status": "Active"
    },
    {
        "Root": "10025335",
        "LV": "1",
        "Item Description": "BOURBON SONOMA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "846534",
        "Last Price Paid": "$32.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025335",
        "Product Status": "Active"
    },
    {
        "Root": "10000163",
        "LV": "1",
        "Item Description": "SALMON D CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000163001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6501497",
        "Last Price Paid": "$10.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000163",
        "Product Status": "Active"
    },
    {
        "Root": "10007027",
        "LV": "1",
        "Item Description": "CAB SAUV PRITCHRD HLL 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "CP016X2005",
        "Last Price Paid": "$225.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007027",
        "Product Status": "Active"
    },
    {
        "Root": "10104668",
        "LV": "1",
        "Item Description": "WRAPPER EGG ROLL 7X7 FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6822579",
        "Last Price Paid": "$6.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104668",
        "Product Status": "Active"
    },
    {
        "Root": "10006617",
        "LV": "1",
        "Item Description": "STICK WHT PAPR LOLLIPOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51888030",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006617",
        "Product Status": "Active"
    },
    {
        "Root": "10017889",
        "LV": "1",
        "Item Description": "CHIC TENDER JUMBO CVP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017889001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.51",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017889",
        "Product Status": "Active"
    },
    {
        "Root": "10004637",
        "LV": "1",
        "Item Description": "MUSTARD DIJON SQUEEZE BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9257320",
        "Last Price Paid": "$4.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004637",
        "Product Status": "Active"
    },
    {
        "Root": "10034169",
        "LV": "1",
        "Item Description": "BEER IPA CROOKED CAN HIGHSTEPPER 16 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "41537",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034169",
        "Product Status": "Active"
    },
    {
        "Root": "10011706",
        "LV": "1",
        "Item Description": "PINOT GRIGIO KRIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "252941",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011706",
        "Product Status": "Active"
    },
    {
        "Root": "10032525",
        "LV": "1",
        "Item Description": "CAB FRANC EDGEBASTON CAMINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "EDGECAMCABFRA",
        "Last Price Paid": "$38.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032525",
        "Product Status": "Active"
    },
    {
        "Root": "10002977",
        "LV": "1",
        "Item Description": "KETCHUP VOL PAK 1/3GL BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002977",
        "Product Status": "Active"
    },
    {
        "Root": "10094990",
        "LV": "1",
        "Item Description": "CHIPS VOODOO ZAPPS 60/1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "61541",
        "Last Price Paid": "$0.68",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094990",
        "Product Status": "Active"
    },
    {
        "Root": "10012040",
        "LV": "2",
        "Item Description": "SHRIMP 21/25 PEELED DEVEINED RAW TAIL ON IQF SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012040002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125602",
        "Last Price Paid": "$5.06",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012040",
        "Product Status": "Active"
    },
    {
        "Root": "10005753",
        "LV": "1",
        "Item Description": "BAGEL SESAME SEED 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005753",
        "Product Status": "Active"
    },
    {
        "Root": "10005669",
        "LV": "2",
        "Item Description": "CAKE FUNNEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005669002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8217301",
        "Last Price Paid": "$0.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005669",
        "Product Status": "Active"
    },
    {
        "Root": "10029204",
        "LV": "1",
        "Item Description": "SANDWICH ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1704178",
        "Last Price Paid": "$4.10",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029204",
        "Product Status": "Active"
    },
    {
        "Root": "10040417",
        "LV": "1",
        "Item Description": "RUM PLANTATION DARK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "467733",
        "Last Price Paid": "$13.90",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040417",
        "Product Status": "Active"
    },
    {
        "Root": "10040417",
        "LV": "1",
        "Item Description": "RUM PLANTATION DARK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.90",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040417",
        "Product Status": "Active"
    },
    {
        "Root": "10025122",
        "LV": "1",
        "Item Description": "VODKA HANGAR 1 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558344",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025122",
        "Product Status": "Active"
    },
    {
        "Root": "10000709",
        "LV": "1",
        "Item Description": "HAM HOCKS SMOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000709001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3301040",
        "Last Price Paid": "$2.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000709",
        "Product Status": "Active"
    },
    {
        "Root": "10025395",
        "LV": "1",
        "Item Description": "COFFEE PERU ALTO MAYO ORG FRENCH PRESS 1# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025395",
        "Product Status": "Active"
    },
    {
        "Root": "10027613",
        "LV": "1",
        "Item Description": "SAUCE TARTAR KENS NEW ENGLAND 4/1 GALLONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027613001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "KE0887",
        "Last Price Paid": "$9.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027613",
        "Product Status": "Active"
    },
    {
        "Root": "10102770",
        "LV": "1",
        "Item Description": "CHOCOLATE CHUNK DK SEMISWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NOE210",
        "Last Price Paid": "$108.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102770",
        "Product Status": "Active"
    },
    {
        "Root": "10006503",
        "LV": "1",
        "Item Description": "SB CUP COLD 3.5 SMPL SIZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11011167",
        "Last Price Paid": "$0.16",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006503",
        "Product Status": "Active"
    },
    {
        "Root": "10041040",
        "LV": "1",
        "Item Description": "CUCUMBER PICKLING SLICED 0.125 IN 5LB 2CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "517009",
        "Last Price Paid": "$20.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041040",
        "Product Status": "Active"
    },
    {
        "Root": "10000671",
        "LV": "1",
        "Item Description": "BISON STRIPLOIN WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000671001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "BU100",
        "Last Price Paid": "$15.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000671",
        "Product Status": "Active"
    },
    {
        "Root": "10032879",
        "LV": "1",
        "Item Description": "BORAGE BLOOMS BLUE 50CT PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05BBOF-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032879",
        "Product Status": "Active"
    },
    {
        "Root": "10009623",
        "LV": "2",
        "Item Description": "TEQ CUERVO TRAD SILVER LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009623001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12458",
        "Last Price Paid": "$19.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009623",
        "Product Status": "Active"
    },
    {
        "Root": "10009514",
        "LV": "1",
        "Item Description": "WHISKEY CANADIAN CLUB LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009514",
        "Product Status": "Active"
    },
    {
        "Root": "10103548",
        "LV": "1",
        "Item Description": "MIX BAR FRZ TROPICS CAPPUCCINO 64OZ/9CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.97",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103548",
        "Product Status": "Active"
    },
    {
        "Root": "10039611",
        "LV": "1",
        "Item Description": "CUP SIPPER TYPHOON TILLYSBOAT 20OZ 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.68",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039611",
        "Product Status": "Active"
    },
    {
        "Root": "10021132",
        "LV": "1",
        "Item Description": "BEER PURE CIDER 350ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14225",
        "Last Price Paid": "$1.92",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021132",
        "Product Status": "Active"
    },
    {
        "Root": "10015415",
        "LV": "2",
        "Item Description": "DEC GUMPASTE LEAF STEMS GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015415002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5284",
        "Last Price Paid": "$2.38",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015415",
        "Product Status": "Active"
    },
    {
        "Root": "10006221",
        "LV": "3",
        "Item Description": "CONTAINER 6X9\" BLACK HINGED CLEAR LID PP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006221003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9419090",
        "Last Price Paid": "$0.41",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10006221",
        "Product Status": "Active"
    },
    {
        "Root": "10029932",
        "LV": "1",
        "Item Description": "GEL PASTE LEAF GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC LEAF GREEN",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029932",
        "Product Status": "Active"
    },
    {
        "Root": "10038811",
        "LV": "1",
        "Item Description": "MUFFIN BLUEBERRY 5 OUNCE INDV WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038811",
        "Product Status": "Active"
    },
    {
        "Root": "10017969",
        "LV": "1",
        "Item Description": "RUM MALIBU PINEAPPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "68331",
        "Last Price Paid": "$17.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017969",
        "Product Status": "Active"
    },
    {
        "Root": "10002648",
        "LV": "2",
        "Item Description": "SPICE CINNAMON POWDER 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002648002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002648",
        "Product Status": "Active"
    },
    {
        "Root": "10002648",
        "LV": "2",
        "Item Description": "SPICE CINNAMON POWDER 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002648002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00301-2",
        "Last Price Paid": "$18.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002648",
        "Product Status": "Active"
    },
    {
        "Root": "10015589",
        "LV": "1",
        "Item Description": "RICE CAROLINA GOLD 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480475",
        "Last Price Paid": "$94.57",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015589",
        "Product Status": "Active"
    },
    {
        "Root": "10010106",
        "LV": "1",
        "Item Description": "BEER LAGUNITAS IPA 12Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.55",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010106",
        "Product Status": "Active"
    },
    {
        "Root": "10002033",
        "LV": "1",
        "Item Description": "MANGO RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002033",
        "Product Status": "Active"
    },
    {
        "Root": "10002033",
        "LV": "1",
        "Item Description": "MANGO RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "886010",
        "Last Price Paid": "$2.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002033",
        "Product Status": "Active"
    },
    {
        "Root": "10021893",
        "LV": "2",
        "Item Description": "DUCK RILLETTE FRZ 2LB TUBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021893002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZCHARIL101",
        "Last Price Paid": "$35.00",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021893",
        "Product Status": "Active"
    },
    {
        "Root": "10107346",
        "LV": "1",
        "Item Description": "DISC DK CHOC 5 CM NO PRINT 648 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "5083D",
        "Last Price Paid": "$0.31",
        "Case Pack": "648",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107346",
        "Product Status": "Active"
    },
    {
        "Root": "10028226",
        "LV": "1",
        "Item Description": "SPRAY VELVET MILK CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028226001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV005N",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028226",
        "Product Status": "Active"
    },
    {
        "Root": "10000787",
        "LV": "1",
        "Item Description": "VEAL GROUND FINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000787001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1396062",
        "Last Price Paid": "$4.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000787",
        "Product Status": "Active"
    },
    {
        "Root": "10035786",
        "LV": "1",
        "Item Description": "NUTELLA HAZELNUT BUTTER SPREAD INDIVIDUAL 120/.52Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7863376",
        "Last Price Paid": "$0.19",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035786",
        "Product Status": "Active"
    },
    {
        "Root": "10004463",
        "LV": "1",
        "Item Description": "MIX FREESTYLE POWERADE/ZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4245951",
        "Last Price Paid": "$57.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004463",
        "Product Status": "Active"
    },
    {
        "Root": "10004317",
        "LV": "1",
        "Item Description": "HORS SATAY BEEF 2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7301693",
        "Last Price Paid": "$1.25",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004317",
        "Product Status": "Active"
    },
    {
        "Root": "10003268",
        "LV": "1",
        "Item Description": "COFFEE HAW KONA JOFF 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$40.05",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003268",
        "Product Status": "Active"
    },
    {
        "Root": "10009778",
        "LV": "2",
        "Item Description": "TEQ CASAMIGOS RESPSDO LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009778001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "446127",
        "Last Price Paid": "$54.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009778",
        "Product Status": "Active"
    },
    {
        "Root": "10101949",
        "LV": "1",
        "Item Description": "WINE SPARKLING EMMOLO BRUT SPARKLING 750ML/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "544759",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101949",
        "Product Status": "Active"
    },
    {
        "Root": "10087733",
        "LV": "1",
        "Item Description": "MIX BAR RASPBERRY DAQUIRI CNCNT 2 TO 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9250777",
        "Last Price Paid": "$88.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087733",
        "Product Status": "Active"
    },
    {
        "Root": "10030082",
        "LV": "2",
        "Item Description": "BAR CHOCOLATE CHIP 12CT CLIF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030082002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302471",
        "Last Price Paid": "$1.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030082",
        "Product Status": "Active"
    },
    {
        "Root": "10091308",
        "LV": "1",
        "Item Description": "WINE RED MAN FAMILY MERLOT 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9250435",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091308",
        "Product Status": "Active"
    },
    {
        "Root": "10036707",
        "LV": "1",
        "Item Description": "PINOT NOIR CHAPPELLET GROWER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677472",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036707",
        "Product Status": "Active"
    },
    {
        "Root": "10034161",
        "LV": "1",
        "Item Description": "SLEIGH AMENITY MARBLE 12 FOIL BALLS IN CELLO 5X3X3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034161001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW62305",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034161",
        "Product Status": "Active"
    },
    {
        "Root": "10105130",
        "LV": "2",
        "Item Description": "LINER BASKET HVY WT 16X16 4 COLORS RODEO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105130002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "1503003537",
        "Last Price Paid": "",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10105130",
        "Product Status": "Active"
    },
    {
        "Root": "10018792",
        "LV": "2",
        "Item Description": "DECOR DISCO SPARKLES VIOLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018792002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11176",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018792",
        "Product Status": "Active"
    },
    {
        "Root": "10032227",
        "LV": "1",
        "Item Description": "BUFFALO SHORT RIBS B/I",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032227001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "BUF150",
        "Last Price Paid": "",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032227",
        "Product Status": "Active"
    },
    {
        "Root": "10040891",
        "LV": "1",
        "Item Description": "BEER BLONDE ALE VEZA MANGOLANDIA 16 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "48062",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040891",
        "Product Status": "Active"
    },
    {
        "Root": "10015401",
        "LV": "3",
        "Item Description": "CHEESE FRESH MOZZARELLA LOG 16 SLICES 8/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015401002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10015401",
        "Product Status": "Active"
    },
    {
        "Root": "10014267",
        "LV": "1",
        "Item Description": "COASTER HANGER CAFE NOIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-030",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014267",
        "Product Status": "Active"
    },
    {
        "Root": "10089366",
        "LV": "1",
        "Item Description": "RICE JASMINIE RAW 20LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089366",
        "Product Status": "Active"
    },
    {
        "Root": "10000389",
        "LV": "1",
        "Item Description": "AMARANTH BURGUNDY MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04BAM-33-S",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000389",
        "Product Status": "Active"
    },
    {
        "Root": "10101930",
        "LV": "1",
        "Item Description": "SUGAR-LARGE CRYSTALS-CLEAR-CON AA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8898",
        "Last Price Paid": "$21.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101930",
        "Product Status": "Active"
    },
    {
        "Root": "10001705",
        "LV": "1",
        "Item Description": "HERB BASIL SWEET ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001705001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482070",
        "Last Price Paid": "$10.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001705",
        "Product Status": "Active"
    },
    {
        "Root": "10001705",
        "LV": "1",
        "Item Description": "HERB BASIL SWEET ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001705001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24000",
        "Last Price Paid": "$10.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001705",
        "Product Status": "Active"
    },
    {
        "Root": "10001705",
        "LV": "1",
        "Item Description": "HERB BASIL SWEET ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001705001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001705",
        "Product Status": "Active"
    },
    {
        "Root": "10001705",
        "LV": "1",
        "Item Description": "HERB BASIL SWEET ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001705001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4326401",
        "Last Price Paid": "$10.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001705",
        "Product Status": "Active"
    },
    {
        "Root": "10106273",
        "LV": "2",
        "Item Description": "PAPER LINER 8X10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106273001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.01",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10106273",
        "Product Status": "Active"
    },
    {
        "Root": "10008370",
        "LV": "1",
        "Item Description": "RIES TRIMBACH FREDRICH EM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547631",
        "Last Price Paid": "$75.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008370",
        "Product Status": "Active"
    },
    {
        "Root": "10004997",
        "LV": "1",
        "Item Description": "FLAVORING LEMON 4/1 KG BT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "R103",
        "Last Price Paid": "$13.92",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004997",
        "Product Status": "Active"
    },
    {
        "Root": "10010187",
        "LV": "1",
        "Item Description": "GINGER ALE SEAGRAM 12Z/24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010187",
        "Product Status": "Active"
    },
    {
        "Root": "10015133",
        "LV": "1",
        "Item Description": "MIX CHEF MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480466",
        "Last Price Paid": "$24.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015133",
        "Product Status": "Active"
    },
    {
        "Root": "10022040",
        "LV": "1",
        "Item Description": "PEA FRESH SHELLED LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480602",
        "Last Price Paid": "$21.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022040",
        "Product Status": "Active"
    },
    {
        "Root": "10002717",
        "LV": "1",
        "Item Description": "CEREAL RAISIN BRAN BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002717",
        "Product Status": "Active"
    },
    {
        "Root": "10003690",
        "LV": "1",
        "Item Description": "SB WHT CHC MCH SAUCE 63OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11020180",
        "Last Price Paid": "$19.96",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003690",
        "Product Status": "Active"
    },
    {
        "Root": "10003242",
        "LV": "2",
        "Item Description": "TEA SHANGHAI ROSE LS 400G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003242001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3531",
        "Last Price Paid": "$35.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003242",
        "Product Status": "Active"
    },
    {
        "Root": "10035875",
        "LV": "1",
        "Item Description": "CHEESE HARD PLAIN NAKED GOAT 2/6 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035875001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19069",
        "Last Price Paid": "$15.35",
        "Case Pack": "22.2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035875",
        "Product Status": "Active"
    },
    {
        "Root": "10003905",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE GOOFY W/BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 2466",
        "Last Price Paid": "$8.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003905",
        "Product Status": "Active"
    },
    {
        "Root": "10001553",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "26110",
        "Last Price Paid": "$30.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001553",
        "Product Status": "Active"
    },
    {
        "Root": "10001553",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480973",
        "Last Price Paid": "$30.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001553",
        "Product Status": "Active"
    },
    {
        "Root": "10001553",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "MUSHSH",
        "Last Price Paid": "$30.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001553",
        "Product Status": "Active"
    },
    {
        "Root": "10091351",
        "LV": "2",
        "Item Description": "STRAW DRINKING WHITE UNWRAPPED 7.75IN/4800CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091351001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$122.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10091351",
        "Product Status": "Active"
    },
    {
        "Root": "10022679",
        "LV": "1",
        "Item Description": "COOKIE CHOC CHIP PLUG .54 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "355",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022679",
        "Product Status": "Active"
    },
    {
        "Root": "10095790",
        "LV": "1",
        "Item Description": "BEEF, BRISKET SLICED SMOKED COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095790001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6304885",
        "Last Price Paid": "$9.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095790",
        "Product Status": "Active"
    },
    {
        "Root": "10030364",
        "LV": "1",
        "Item Description": "CAKEPOP CHOCOLATE FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "700876",
        "Last Price Paid": "$1.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030364",
        "Product Status": "Active"
    },
    {
        "Root": "10034366",
        "LV": "1",
        "Item Description": "MICKEY HAT W/EARS 2 DOME X 1 3/4 H",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW31772",
        "Last Price Paid": "$0.85",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034366",
        "Product Status": "Active"
    },
    {
        "Root": "10009797",
        "LV": "1",
        "Item Description": "RUM MYERS ORIGINAL DK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9000293",
        "Last Price Paid": "$13.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009797",
        "Product Status": "Active"
    },
    {
        "Root": "10011503",
        "LV": "1",
        "Item Description": "BEER KONA LONGBOARD 16 OZ CAN 24 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011503",
        "Product Status": "Active"
    },
    {
        "Root": "10104929",
        "LV": "1",
        "Item Description": "OIL SESAME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01240",
        "Last Price Paid": "$24.65",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104929",
        "Product Status": "Active"
    },
    {
        "Root": "10018988",
        "LV": "1",
        "Item Description": "SHELL TART CHOC LG ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8090443",
        "Last Price Paid": "$1.07",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018988",
        "Product Status": "Active"
    },
    {
        "Root": "10004534",
        "LV": "2",
        "Item Description": "JUICE CLAMATO 12/1LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004534002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5308139",
        "Last Price Paid": "$3.74",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004534",
        "Product Status": "Active"
    },
    {
        "Root": "10002012",
        "LV": "1",
        "Item Description": "PEPPER CUBANELLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407910",
        "Last Price Paid": "$33.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002012",
        "Product Status": "Active"
    },
    {
        "Root": "10002012",
        "LV": "1",
        "Item Description": "PEPPER CUBANELLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002012",
        "Product Status": "Active"
    },
    {
        "Root": "10002680",
        "LV": "2",
        "Item Description": "TOMATO CRUSHED #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002680002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3023231",
        "Last Price Paid": "$6.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002680",
        "Product Status": "Active"
    },
    {
        "Root": "10002680",
        "LV": "2",
        "Item Description": "TOMATO CRUSHED #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002680002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002680",
        "Product Status": "Active"
    },
    {
        "Root": "10095639",
        "LV": "1",
        "Item Description": "WINE CHAMPAGNE J LASSALLE PREFERENCE BRUT 750ML/12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677690",
        "Last Price Paid": "$35.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095639",
        "Product Status": "Active"
    },
    {
        "Root": "10004321",
        "LV": "1",
        "Item Description": "HORS EMPANADA BEEF 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300321",
        "Last Price Paid": "$0.90",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004321",
        "Product Status": "Active"
    },
    {
        "Root": "10109632",
        "LV": "1",
        "Item Description": "LIQ SPIRIT TEMPUS CREME DE BANANA 750ML 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "144270",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109632",
        "Product Status": "Active"
    },
    {
        "Root": "10036952",
        "LV": "1",
        "Item Description": "SB CHOCOLATE COOKIE CRUMBLE TOPPING 5OZ SHAKER 4-E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11103265",
        "Last Price Paid": "$5.98",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036952",
        "Product Status": "Active"
    },
    {
        "Root": "10035021",
        "LV": "1",
        "Item Description": "SYRUP SPRITE ZERO 2.5 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$49.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035021",
        "Product Status": "Active"
    },
    {
        "Root": "10008235",
        "LV": "1",
        "Item Description": "SAUV BLC CLOUDY BAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "565533",
        "Last Price Paid": "$20.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008235",
        "Product Status": "Active"
    },
    {
        "Root": "10003235",
        "LV": "2",
        "Item Description": "TEA ENGLSH DCF BRKFST LS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003235001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3506",
        "Last Price Paid": "$36.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003235",
        "Product Status": "Active"
    },
    {
        "Root": "10082482",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL SPRITE CUCUMBER 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "55570010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082482",
        "Product Status": "Active"
    },
    {
        "Root": "10004968",
        "LV": "1",
        "Item Description": "PUREE STRAWBERRY 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AFA1C6UA0",
        "Last Price Paid": "$12.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004968",
        "Product Status": "Active"
    },
    {
        "Root": "10006865",
        "LV": "2",
        "Item Description": "CAB SAUV FRANZIA 3LTR BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006865002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006865",
        "Product Status": "Active"
    },
    {
        "Root": "10006610",
        "LV": "2",
        "Item Description": "HAT CHEF 7IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006610002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1294321",
        "Last Price Paid": "$1.49",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006610",
        "Product Status": "Active"
    },
    {
        "Root": "10105088",
        "LV": "1",
        "Item Description": "CARTON FOOD #1 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "450",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105088",
        "Product Status": "Active"
    },
    {
        "Root": "10001427",
        "LV": "1",
        "Item Description": "RADISH NG PET CHERRY BOMB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PCBR-33",
        "Last Price Paid": "$0.68",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001427",
        "Product Status": "Active"
    },
    {
        "Root": "10034790",
        "LV": "1",
        "Item Description": "SQUARES WHITE GOOFY SANTA HAT 1 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63214",
        "Last Price Paid": "$0.46",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034790",
        "Product Status": "Active"
    },
    {
        "Root": "10009946",
        "LV": "1",
        "Item Description": "LIQ AMARO AVERNA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557370",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009946",
        "Product Status": "Active"
    },
    {
        "Root": "10091418",
        "LV": "1",
        "Item Description": "CHICKEN PIECES CKD 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7005584",
        "Last Price Paid": "$1.73",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091418",
        "Product Status": "Active"
    },
    {
        "Root": "10038374",
        "LV": "1",
        "Item Description": "BAKING CUP SACHET PINK SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "25308",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038374",
        "Product Status": "Active"
    },
    {
        "Root": "10005518",
        "LV": "1",
        "Item Description": "SPICE 5 POWDR 6/16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005518",
        "Product Status": "Active"
    },
    {
        "Root": "10004312",
        "LV": "1",
        "Item Description": "HORS BEEF WELLINGTON MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300306",
        "Last Price Paid": "$1.94",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004312",
        "Product Status": "Active"
    },
    {
        "Root": "10107550",
        "LV": "1",
        "Item Description": "WINE SPARKLING WILDLY BRUT  6/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "728155",
        "Last Price Paid": "$13.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107550",
        "Product Status": "Active"
    },
    {
        "Root": "10005417",
        "LV": "1",
        "Item Description": "SYRUP FCB LEMONADE MM BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005417",
        "Product Status": "Active"
    },
    {
        "Root": "10006890",
        "LV": "1",
        "Item Description": "MERLOT COLUMBIA 2VINE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "988979",
        "Last Price Paid": "$5.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006890",
        "Product Status": "Active"
    },
    {
        "Root": "10008847",
        "LV": "3",
        "Item Description": "ORIN SWIFT ABSTRACT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008847002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9239383",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008847",
        "Product Status": "Active"
    },
    {
        "Root": "10037976",
        "LV": "1",
        "Item Description": "NAPKIN POCKET BLACK XL DUNILETTO 16X19 184/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "3825",
        "Last Price Paid": "",
        "Case Pack": "184",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037976",
        "Product Status": "Active"
    },
    {
        "Root": "10006335",
        "LV": "1",
        "Item Description": "DOILY 6 INCH LACE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7435415",
        "Last Price Paid": "",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006335",
        "Product Status": "Active"
    },
    {
        "Root": "10008965",
        "LV": "1",
        "Item Description": "PINOT NOIR HARTFD LND EDGE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "513519",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008965",
        "Product Status": "Active"
    },
    {
        "Root": "10032891",
        "LV": "2",
        "Item Description": "CUP LED RAINBOW 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032891002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "DIS513UN",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032891",
        "Product Status": "Active"
    },
    {
        "Root": "10104855",
        "LV": "2",
        "Item Description": "CUP COLD 32OZ SWGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104855002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104855",
        "Product Status": "Active"
    },
    {
        "Root": "10035891",
        "LV": "2",
        "Item Description": "DECOR CHOC FEATHER SYNERGY DEFINE COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035891001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW23106",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10035891",
        "Product Status": "Active"
    },
    {
        "Root": "10006729",
        "LV": "1",
        "Item Description": "CUP FLUTED BKNG WHT 4.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51139461",
        "Last Price Paid": "",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006729",
        "Product Status": "Active"
    },
    {
        "Root": "10022828",
        "LV": "1",
        "Item Description": "CHOC WHTE 2 X 1.25 RECT LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2163W",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022828",
        "Product Status": "Active"
    },
    {
        "Root": "10005007",
        "LV": "1",
        "Item Description": "FLAVORING ALMOND BARI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "102...{SIEBEN{",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005007",
        "Product Status": "Active"
    },
    {
        "Root": "10023838",
        "LV": "1",
        "Item Description": "PASTA MACARONI CHEESE TRAY FROZEN MOLLYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4326302",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023838",
        "Product Status": "Active"
    },
    {
        "Root": "10035020",
        "LV": "1",
        "Item Description": "HAM SWEDISH 6-9 LB PORTION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035020001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035020",
        "Product Status": "Active"
    },
    {
        "Root": "10092224",
        "LV": "1",
        "Item Description": "COCOA BUTTER 100% GRATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500210000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092224",
        "Product Status": "Active"
    },
    {
        "Root": "10030268",
        "LV": "1",
        "Item Description": "EGG WASH SUBSTITUTE SUNSET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P4005",
        "Last Price Paid": "$5.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030268",
        "Product Status": "Active"
    },
    {
        "Root": "10014423",
        "LV": "1",
        "Item Description": "RED PEPPERS (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407100",
        "Last Price Paid": "$34.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014423",
        "Product Status": "Active"
    },
    {
        "Root": "10006839",
        "LV": "1",
        "Item Description": "BOARD CAKE SILVER 20 INCH X1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006839001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006839",
        "Product Status": "Active"
    },
    {
        "Root": "10003922",
        "LV": "1",
        "Item Description": "GELATIN SHEET SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "L1021",
        "Last Price Paid": "$34.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003922",
        "Product Status": "Active"
    },
    {
        "Root": "10009529",
        "LV": "2",
        "Item Description": "WHSKY SMOKY WHT MOONSHINE 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009529001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "377782",
        "Last Price Paid": "$18.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009529",
        "Product Status": "Active"
    },
    {
        "Root": "10040133",
        "LV": "1",
        "Item Description": "DRAFT PLAYALINDA PEPPERMINT MILK STOUT 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11276",
        "Last Price Paid": "$199.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040133",
        "Product Status": "Active"
    },
    {
        "Root": "10040133",
        "LV": "1",
        "Item Description": "DRAFT PLAYALINDA PEPPERMINT MILK STOUT 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "11276",
        "Last Price Paid": "$199.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040133",
        "Product Status": "Active"
    },
    {
        "Root": "10034141",
        "LV": "1",
        "Item Description": "DOUGH PHYLLO KATAIFI 12/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6423004",
        "Last Price Paid": "$11.46",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034141",
        "Product Status": "Active"
    },
    {
        "Root": "10108231",
        "LV": "1",
        "Item Description": "TOMATO SUN DRIED IN OIL 8.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.41",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108231",
        "Product Status": "Active"
    },
    {
        "Root": "10104589",
        "LV": "1",
        "Item Description": "PASSOVER BSC MATZO BALL CHICKEN SOUP 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104589",
        "Product Status": "Active"
    },
    {
        "Root": "10082106",
        "LV": "2",
        "Item Description": "CRACKER PARMESAN PEPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082106002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "3702",
        "Last Price Paid": "$2.49",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10082106",
        "Product Status": "Active"
    },
    {
        "Root": "10002772",
        "LV": "1",
        "Item Description": "SYRUP SPRITE HIGH YIELD 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$112.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002772",
        "Product Status": "Active"
    },
    {
        "Root": "10002124",
        "LV": "1",
        "Item Description": "CHEESE PARMESAN GRATED 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3585031",
        "Last Price Paid": "$17.77",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002124",
        "Product Status": "Active"
    },
    {
        "Root": "10002124",
        "LV": "1",
        "Item Description": "CHEESE PARMESAN GRATED 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.77",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002124",
        "Product Status": "Active"
    },
    {
        "Root": "10002850",
        "LV": "1",
        "Item Description": "DRESSING BALSAMIC WITH OLIVE OIL LITESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002850",
        "Product Status": "Active"
    },
    {
        "Root": "10008881",
        "LV": "2",
        "Item Description": "PINOT NOIR DOM SERENE EVEN RSV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008881001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9648356",
        "Last Price Paid": "$67.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008881",
        "Product Status": "Active"
    },
    {
        "Root": "10004214",
        "LV": "1",
        "Item Description": "SB TEAV ICE PASS TANGO 24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11036932",
        "Last Price Paid": "$0.86",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004214",
        "Product Status": "Active"
    },
    {
        "Root": "10016468",
        "LV": "1",
        "Item Description": "SAUCE SOY TAMARI GF 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7074456",
        "Last Price Paid": "$83.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016468",
        "Product Status": "Active"
    },
    {
        "Root": "10027821",
        "LV": "1",
        "Item Description": "TEA GOLD PEAK UNSWEET 18.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.49",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027821",
        "Product Status": "Active"
    },
    {
        "Root": "10005107",
        "LV": "1",
        "Item Description": "SYRUP COFF CHAI 750ML 12E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005107001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005107",
        "Product Status": "Active"
    },
    {
        "Root": "10002817",
        "LV": "2",
        "Item Description": "SAUCE TARTAR IND 12GM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002817002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002817",
        "Product Status": "Active"
    },
    {
        "Root": "10034778",
        "LV": "1",
        "Item Description": "HONDURAN GLUTEN FREE BROWNIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034778001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6474239",
        "Last Price Paid": "$1.50",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034778",
        "Product Status": "Active"
    },
    {
        "Root": "10015930",
        "LV": "1",
        "Item Description": "EGG HALF HOLLOW 8\" WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22234W",
        "Last Price Paid": "$7.00",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015930",
        "Product Status": "Active"
    },
    {
        "Root": "10109274",
        "LV": "1",
        "Item Description": "DISNEY HOLIDAY ORNAMT GOOFY 3.5X3.5CM 3000 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175930000",
        "Last Price Paid": "$0.14",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109274",
        "Product Status": "Active"
    },
    {
        "Root": "10003010",
        "LV": "1",
        "Item Description": "SPICE SALT IND 6000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5358825",
        "Last Price Paid": "$0.00",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003010",
        "Product Status": "Active"
    },
    {
        "Root": "10034316",
        "LV": "1",
        "Item Description": "ALBARINO LAGAR DE BOUZA GALICIA SPAIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "7891013",
        "Last Price Paid": "$10.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034316",
        "Product Status": "Active"
    },
    {
        "Root": "10009826",
        "LV": "1",
        "Item Description": "RUM CAPT MORG PRVT STK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "357705",
        "Last Price Paid": "$21.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009826",
        "Product Status": "Active"
    },
    {
        "Root": "10004948",
        "LV": "1",
        "Item Description": "BLINTZ CHEESE 2.2Z 144/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7058081",
        "Last Price Paid": "$0.64",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004948",
        "Product Status": "Active"
    },
    {
        "Root": "10110134",
        "LV": "1",
        "Item Description": "BAGS CRYSTAL CLEAR GUSSET 4\" X 2.12 X 8 GB4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "5142488",
        "Last Price Paid": "$0.05",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110134",
        "Product Status": "Active"
    },
    {
        "Root": "10009810",
        "LV": "1",
        "Item Description": "RUM ZACAPA CENTENR XO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "406950",
        "Last Price Paid": "$95.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009810",
        "Product Status": "Active"
    },
    {
        "Root": "10012576",
        "LV": "1",
        "Item Description": "LEAVES PURPLE SWEET POTATO 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08PSPL-33",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012576",
        "Product Status": "Active"
    },
    {
        "Root": "10107350",
        "LV": "1",
        "Item Description": "BEEF SHORT RIBS SRD RD WN BEER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7028928",
        "Last Price Paid": "$8.53",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107350",
        "Product Status": "Active"
    },
    {
        "Root": "10025437",
        "LV": "1",
        "Item Description": "QUIN NEON BRIGHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "17909",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025437",
        "Product Status": "Active"
    },
    {
        "Root": "10023335",
        "LV": "1",
        "Item Description": "HOT DOG KIT BEEF SLIDER WITH BUN 48/ 1.25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8659351",
        "Last Price Paid": "$0.97",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023335",
        "Product Status": "Active"
    },
    {
        "Root": "10035666",
        "LV": "1",
        "Item Description": "PEANUT BUTTER CREAMY TUB SHELF STABLE 6/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4327581",
        "Last Price Paid": "$10.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035666",
        "Product Status": "Active"
    },
    {
        "Root": "10008221",
        "LV": "1",
        "Item Description": "ZINF GRGICH HILLS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2011443",
        "Last Price Paid": "$26.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008221",
        "Product Status": "Active"
    },
    {
        "Root": "10036740",
        "LV": "1",
        "Item Description": "CHOCOLATE VEGAN MILK CONFECTIONARY MASS BRUN 44%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7501400000",
        "Last Price Paid": "$159.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036740",
        "Product Status": "Active"
    },
    {
        "Root": "10024916",
        "LV": "1",
        "Item Description": "LA SPINETTA MOSCATO BIANCOSPINO 22 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9691403",
        "Last Price Paid": "$14.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024916",
        "Product Status": "Active"
    },
    {
        "Root": "10007062",
        "LV": "3",
        "Item Description": "SAUV BLC MONTES LMTD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007062002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "218190",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007062",
        "Product Status": "Active"
    },
    {
        "Root": "10039685",
        "LV": "1",
        "Item Description": "MILK OAT SHELF STABLE CARTON 1 QUART 12/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039685001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8023443",
        "Last Price Paid": "$3.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039685",
        "Product Status": "Active"
    },
    {
        "Root": "10007031",
        "LV": "1",
        "Item Description": "BLND TOKARA RED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "247116",
        "Last Price Paid": "$16.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007031",
        "Product Status": "Active"
    },
    {
        "Root": "10029398",
        "LV": "1",
        "Item Description": "CHENIN BLANC SULA CB 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "268335",
        "Last Price Paid": "$9.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029398",
        "Product Status": "Active"
    },
    {
        "Root": "10029926",
        "LV": "1",
        "Item Description": "GEL PASTE NAVY BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC NAVY BLUE",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029926",
        "Product Status": "Active"
    },
    {
        "Root": "10004420",
        "LV": "1",
        "Item Description": "SYRUP ORGEAT BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004420001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "803201",
        "Last Price Paid": "$16.57",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004420",
        "Product Status": "Active"
    },
    {
        "Root": "10023640",
        "LV": "1",
        "Item Description": "COMPOUND PUMPKIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023640001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6900060000",
        "Last Price Paid": "$38.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023640",
        "Product Status": "Active"
    },
    {
        "Root": "10006923",
        "LV": "1",
        "Item Description": "SPARK ASTI SPUM SWT M&R 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "243157",
        "Last Price Paid": "$10.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006923",
        "Product Status": "Active"
    },
    {
        "Root": "10019193",
        "LV": "2",
        "Item Description": "CASING LIVERWURST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019193002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "1000",
        "Last Price Paid": "$1.67",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019193",
        "Product Status": "Active"
    },
    {
        "Root": "10040264",
        "LV": "1",
        "Item Description": "CHICKEN BREAST FILLETS BREADED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.77",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040264",
        "Product Status": "Active"
    },
    {
        "Root": "10039866",
        "LV": "1",
        "Item Description": "SHEET TRANSFER CHRISTMAS FIREWORKS MVMCP 100/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW6572",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039866",
        "Product Status": "Active"
    },
    {
        "Root": "10002446",
        "LV": "2",
        "Item Description": "SHERBET RAINBOW 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002446002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002446",
        "Product Status": "Active"
    },
    {
        "Root": "10002446",
        "LV": "2",
        "Item Description": "SHERBET RAINBOW 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002446002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2740769",
        "Last Price Paid": "$22.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002446",
        "Product Status": "Active"
    },
    {
        "Root": "10108509",
        "LV": "1",
        "Item Description": "MICKEY GINGERBREAD 2IN BAKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "GBMK",
        "Last Price Paid": "$0.48",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108509",
        "Product Status": "Active"
    },
    {
        "Root": "10030416",
        "LV": "1",
        "Item Description": "FROSTING DAWN WHITE ZGTF 32#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030416",
        "Product Status": "Active"
    },
    {
        "Root": "10001101",
        "LV": "1",
        "Item Description": "SHRIMP PIECES MED SUSTAINABLE SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001101001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125604",
        "Last Price Paid": "$5.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001101",
        "Product Status": "Active"
    },
    {
        "Root": "10002445",
        "LV": "1",
        "Item Description": "SHERBET ORANGE 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "H-011.3",
        "Last Price Paid": "$21.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002445",
        "Product Status": "Active"
    },
    {
        "Root": "10001382",
        "LV": "1",
        "Item Description": "CAULIFLOWER ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001382",
        "Product Status": "Active"
    },
    {
        "Root": "10001382",
        "LV": "1",
        "Item Description": "CAULIFLOWER ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482250",
        "Last Price Paid": "$5.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001382",
        "Product Status": "Active"
    },
    {
        "Root": "10033668",
        "LV": "1",
        "Item Description": "EDIBLE GLITTER-AMETHYST 4 OZ EDIBLE GLITTER-AMETHY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "39203",
        "Last Price Paid": "$12.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033668",
        "Product Status": "Active"
    },
    {
        "Root": "10039763",
        "LV": "1",
        "Item Description": "BEEF BURGER ANGUS D-LUXE GRIND-BRISKET & CHUCK BLK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039763001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1095002",
        "Last Price Paid": "$4.48",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039763",
        "Product Status": "Active"
    },
    {
        "Root": "10001274",
        "LV": "2",
        "Item Description": "BLUEBERRY FROZEN WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001274002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4909362",
        "Last Price Paid": "$99.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001274",
        "Product Status": "Active"
    },
    {
        "Root": "10013731",
        "LV": "1",
        "Item Description": "COFFEE GOLDEN OAK BLEND REG BEAN 5# FOIL BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013731",
        "Product Status": "Active"
    },
    {
        "Root": "10013213",
        "LV": "1",
        "Item Description": "BAROLO DAMILANO CANNUBI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "795270",
        "Last Price Paid": "$62.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013213",
        "Product Status": "Active"
    },
    {
        "Root": "10000340",
        "LV": "1",
        "Item Description": "LETTUCE ICEBERG CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "487780",
        "Last Price Paid": "$6.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000340",
        "Product Status": "Active"
    },
    {
        "Root": "10000340",
        "LV": "1",
        "Item Description": "LETTUCE ICEBERG CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "24-002",
        "Last Price Paid": "$6.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000340",
        "Product Status": "Active"
    },
    {
        "Root": "10010603",
        "LV": "3",
        "Item Description": "FIREFLY MS BLACKBERRY 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010603002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9184187",
        "Last Price Paid": "$9.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10010603",
        "Product Status": "Active"
    },
    {
        "Root": "10006293",
        "LV": "2",
        "Item Description": "SKEWER BAMBOO 6 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006293001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9063462",
        "Last Price Paid": "$0.01",
        "Case Pack": "1600",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006293",
        "Product Status": "Active"
    },
    {
        "Root": "10035213",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861310000",
        "Last Price Paid": "$10.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035213",
        "Product Status": "Active"
    },
    {
        "Root": "10003747",
        "LV": "1",
        "Item Description": "FLOUR RICE WHITE BOBS 42Z/4EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101328",
        "Last Price Paid": "$3.43",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003747",
        "Product Status": "Active"
    },
    {
        "Root": "10104612",
        "LV": "3",
        "Item Description": "CRABMEAT SUPER LUMP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104612003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "9801014",
        "Last Price Paid": "$23.87",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10104612",
        "Product Status": "Active"
    },
    {
        "Root": "10030236",
        "LV": "1",
        "Item Description": "PORTUGUESE JOHN DORY WILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030236001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "18720010",
        "Last Price Paid": "$36.00",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030236",
        "Product Status": "Active"
    },
    {
        "Root": "10008300",
        "LV": "2",
        "Item Description": "SAUV BLC SILVERADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008300001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "604402",
        "Last Price Paid": "$12.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008300",
        "Product Status": "Active"
    },
    {
        "Root": "10003709",
        "LV": "1",
        "Item Description": "SB HONEY PACKETS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11079702",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003709",
        "Product Status": "Active"
    },
    {
        "Root": "10002707",
        "LV": "2",
        "Item Description": "FLOUR CAKE 8% WHEAT BLEACHED AMERICAN BEAUTY 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002707002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3008364",
        "Last Price Paid": "$22.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002707",
        "Product Status": "Active"
    },
    {
        "Root": "10014022",
        "LV": "1",
        "Item Description": "TUBE CLR WITH CORK STOPPER PLASTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11171001",
        "Last Price Paid": "$0.80",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014022",
        "Product Status": "Active"
    },
    {
        "Root": "10016583",
        "LV": "2",
        "Item Description": "SHEET GLASSINE CHOC 5.5X14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016583002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510379",
        "Last Price Paid": "$0.13",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016583",
        "Product Status": "Active"
    },
    {
        "Root": "10081294",
        "LV": "1",
        "Item Description": "COOKIE SUGAR CUSTOM PRINT 2.5\" ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "2.5SGCUS",
        "Last Price Paid": "$1.00",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081294",
        "Product Status": "Active"
    },
    {
        "Root": "10036087",
        "LV": "1",
        "Item Description": "GIN PUERTO DE INDIAS STRAWBERRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036087001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036087",
        "Product Status": "Active"
    },
    {
        "Root": "10022370",
        "LV": "1",
        "Item Description": "CHOC DRK DISK 1 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2006D",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022370",
        "Product Status": "Active"
    },
    {
        "Root": "10016261",
        "LV": "1",
        "Item Description": "PIE BLUEBERRY 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "837",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016261",
        "Product Status": "Active"
    },
    {
        "Root": "10038337",
        "LV": "2",
        "Item Description": "RED BLEND ANTINORI GUADO ALTASSO II BRUCIATO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038337002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "526130",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038337",
        "Product Status": "Active"
    },
    {
        "Root": "10037630",
        "LV": "1",
        "Item Description": "PRETZEL BAVARIAN SOFT 48Z 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037630",
        "Product Status": "Active"
    },
    {
        "Root": "10031223",
        "LV": "1",
        "Item Description": "SWEET POTATO CREME BRULEE 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01CB-26",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031223",
        "Product Status": "Active"
    },
    {
        "Root": "10002529",
        "LV": "1",
        "Item Description": "CHEESE BRIE GOAT WHEELS 9/6.5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "824",
        "Last Price Paid": "",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002529",
        "Product Status": "Active"
    },
    {
        "Root": "10002126",
        "LV": "2",
        "Item Description": "CHEESE CHEDDAR SHREDDED FINE/FANCY 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002126001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002126",
        "Product Status": "Active"
    },
    {
        "Root": "10036602",
        "LV": "1",
        "Item Description": "BEEF TOP RND PALOMILLA STEAK 20/8 OZ CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036602001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190048",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036602",
        "Product Status": "Active"
    },
    {
        "Root": "10012567",
        "LV": "1",
        "Item Description": "HERBS FLOWERING SAMPLER 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012567001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14FHS-33",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012567",
        "Product Status": "Active"
    },
    {
        "Root": "10004517",
        "LV": "2",
        "Item Description": "COMPOUND BANANA DOHLER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004517001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "R117",
        "Last Price Paid": "$35.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004517",
        "Product Status": "Active"
    },
    {
        "Root": "10001321",
        "LV": "1",
        "Item Description": "PEA GREEN IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8328247",
        "Last Price Paid": "$19.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001321",
        "Product Status": "Active"
    },
    {
        "Root": "10001321",
        "LV": "1",
        "Item Description": "PEA GREEN IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001321",
        "Product Status": "Active"
    },
    {
        "Root": "10001099",
        "LV": "1",
        "Item Description": "LANGOUSTINE WHOLE FROZEN U5/7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001099001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "33000280",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001099",
        "Product Status": "Active"
    },
    {
        "Root": "10001099",
        "LV": "1",
        "Item Description": "LANGOUSTINE WHOLE FROZEN U5/7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001099001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6124002",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001099",
        "Product Status": "Active"
    },
    {
        "Root": "10001099",
        "LV": "1",
        "Item Description": "LANGOUSTINE WHOLE FROZEN U5/7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001099001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZFISHSF007",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001099",
        "Product Status": "Active"
    },
    {
        "Root": "10019390",
        "LV": "1",
        "Item Description": "AO TOSAKA, GARNISH FOR SASHIMI (20/1.1LB) SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019390",
        "Product Status": "Active"
    },
    {
        "Root": "10017887",
        "LV": "2",
        "Item Description": "CHIC THIGH CUT BNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017887001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.06",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10017887",
        "Product Status": "Active"
    },
    {
        "Root": "10022385",
        "LV": "1",
        "Item Description": "CHOC WHT DISK 1 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2006W",
        "Last Price Paid": "$0.90",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022385",
        "Product Status": "Active"
    },
    {
        "Root": "10029488",
        "LV": "1",
        "Item Description": "BREAD PITA MINI COCKTAIL 2.5\" ROUND 648 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4921250",
        "Last Price Paid": "$0.08",
        "Case Pack": "648",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029488",
        "Product Status": "Active"
    },
    {
        "Root": "10035015",
        "LV": "1",
        "Item Description": "CHEESE BLEND SHAVED PARM ASIAGO ROMANO SALAD 20LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6797658",
        "Last Price Paid": "$93.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035015",
        "Product Status": "Active"
    },
    {
        "Root": "10027615",
        "LV": "1",
        "Item Description": "SAUCE KENS BBQ CANNONBALL 4/1 GALLONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.86",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027615",
        "Product Status": "Active"
    },
    {
        "Root": "10001922",
        "LV": "1",
        "Item Description": "ARTICHOKE 18 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598651",
        "Last Price Paid": "$3.49",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001922",
        "Product Status": "Active"
    },
    {
        "Root": "10008005",
        "LV": "1",
        "Item Description": "WINE PINOT NOIR DROUHIN BOURGOGNE ROUGE 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "298941",
        "Last Price Paid": "$19.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008005",
        "Product Status": "Active"
    },
    {
        "Root": "10103940",
        "LV": "1",
        "Item Description": "SCOOP POPCORN WDW D100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103940",
        "Product Status": "Active"
    },
    {
        "Root": "10003023",
        "LV": "1",
        "Item Description": "DRESSING RANCH CHIPOTLE 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003023001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3534153",
        "Last Price Paid": "$16.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003023",
        "Product Status": "Active"
    },
    {
        "Root": "10016439",
        "LV": "2",
        "Item Description": "SAUCE HNY MUSTARD PIKNIK 2/1.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016439001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.62",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016439",
        "Product Status": "Active"
    },
    {
        "Root": "10102158",
        "LV": "1",
        "Item Description": "DECOR 3IN X 1 1/2IN RECT NIGHTMARE BEFORE CHRISTM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2173W",
        "Last Price Paid": "$1.10",
        "Case Pack": "510",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102158",
        "Product Status": "Active"
    },
    {
        "Root": "10033156",
        "LV": "1",
        "Item Description": "POTATOES FC 3/8 FRENCH FRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "800084",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033156",
        "Product Status": "Active"
    },
    {
        "Root": "10033156",
        "LV": "1",
        "Item Description": "POTATOES FC 3/8 FRENCH FRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "06-006",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033156",
        "Product Status": "Active"
    },
    {
        "Root": "10000396",
        "LV": "2",
        "Item Description": "CORN SHUCKED COBBETTES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000396002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480082",
        "Last Price Paid": "$0.54",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000396",
        "Product Status": "Active"
    },
    {
        "Root": "10010175",
        "LV": "1",
        "Item Description": "WATER PET DISTILLED 1 GALLON/6 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4959990",
        "Last Price Paid": "$2.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010175",
        "Product Status": "Active"
    },
    {
        "Root": "10000308",
        "LV": "1",
        "Item Description": "SALAD SENSATION MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SAL-33",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000308",
        "Product Status": "Active"
    },
    {
        "Root": "10031836",
        "LV": "1",
        "Item Description": "PEPPERMINT EXTRACT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011011",
        "Last Price Paid": "$71.80",
        "Case Pack": "26",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031836",
        "Product Status": "Active"
    },
    {
        "Root": "10007807",
        "LV": "1",
        "Item Description": "CHARD RUSTENBERG STE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2179",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007807",
        "Product Status": "Active"
    },
    {
        "Root": "10037568",
        "LV": "1",
        "Item Description": "STELLA ARTOIS 11.2Z CANS 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99753",
        "Last Price Paid": "$1.36",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037568",
        "Product Status": "Active"
    },
    {
        "Root": "10025703",
        "LV": "1",
        "Item Description": "KALETTES PETITE 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025703001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07PFSPRT-33",
        "Last Price Paid": "$0.67",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025703",
        "Product Status": "Active"
    },
    {
        "Root": "10001506",
        "LV": "1",
        "Item Description": "HERB FINE MICRO 3.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MFH-33-S",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001506",
        "Product Status": "Active"
    },
    {
        "Root": "10011007",
        "LV": "1",
        "Item Description": "CHOC WHITE CHARACTER SQ 1.25 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW6321",
        "Last Price Paid": "$0.34",
        "Case Pack": "350",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011007",
        "Product Status": "Active"
    },
    {
        "Root": "10003369",
        "LV": "1",
        "Item Description": "COFFEE CALF GRL ESPRES 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.66",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003369",
        "Product Status": "Active"
    },
    {
        "Root": "10005031",
        "LV": "1",
        "Item Description": "SYRUP COFF HIBISCUS LTR 4EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5177",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005031",
        "Product Status": "Active"
    },
    {
        "Root": "10012629",
        "LV": "1",
        "Item Description": "SMOKED FISH DIP 2/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6327003",
        "Last Price Paid": "$31.01",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012629",
        "Product Status": "Active"
    },
    {
        "Root": "10019851",
        "LV": "1",
        "Item Description": "EGG HARD COOKED CF 12/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3657618",
        "Last Price Paid": "$54.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019851",
        "Product Status": "Active"
    },
    {
        "Root": "10019851",
        "LV": "1",
        "Item Description": "EGG HARD COOKED CF 12/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$54.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019851",
        "Product Status": "Active"
    },
    {
        "Root": "10000280",
        "LV": "1",
        "Item Description": "CRESS AFILLA KOPPERT MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482710",
        "Last Price Paid": "$1.39",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000280",
        "Product Status": "Active"
    },
    {
        "Root": "10030947",
        "LV": "1",
        "Item Description": "HARD SELTZER WHITE CLAW BLACK CHERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "31915",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030947",
        "Product Status": "Active"
    },
    {
        "Root": "10033285",
        "LV": "1",
        "Item Description": "TRAY ECOSMART UNBLEACHED 1/4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033285001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "ES25U",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033285",
        "Product Status": "Active"
    },
    {
        "Root": "10016635",
        "LV": "1",
        "Item Description": "OIL OLIVE EV J LEBLANC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OL085",
        "Last Price Paid": "$49.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016635",
        "Product Status": "Active"
    },
    {
        "Root": "10000521",
        "LV": "1",
        "Item Description": "MUFFIN PETITE CHOCOLATE CHIP (1 OZ) 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000521001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000521",
        "Product Status": "Active"
    },
    {
        "Root": "10038263",
        "LV": "1",
        "Item Description": "CHAMPAGNE FLUTE CLEAR 9Z 252/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038263001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11004637",
        "Last Price Paid": "$0.56",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038263",
        "Product Status": "Active"
    },
    {
        "Root": "10020943",
        "LV": "1",
        "Item Description": "CHATEAU HAUT-SURGET LALANDE DE POMEROL 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "252276",
        "Last Price Paid": "$19.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020943",
        "Product Status": "Active"
    },
    {
        "Root": "10007819",
        "LV": "2",
        "Item Description": "CHAMP TAITTINGER NOCTURNE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007819001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "18041",
        "Last Price Paid": "$55.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007819",
        "Product Status": "Active"
    },
    {
        "Root": "10030207",
        "LV": "1",
        "Item Description": "BOURBON OLD FORESTER 86 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030207",
        "Product Status": "Active"
    },
    {
        "Root": "10002396",
        "LV": "1",
        "Item Description": "CHEESE FRESH GOAT PURPLE HAZE CYPRESS GROVE 6/4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "39497",
        "Last Price Paid": "$4.37",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002396",
        "Product Status": "Active"
    },
    {
        "Root": "10044408",
        "LV": "1",
        "Item Description": "SB PISTACHIO SAUCE 63 FL OZ 4 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11121796",
        "Last Price Paid": "$33.64",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044408",
        "Product Status": "Active"
    },
    {
        "Root": "10076280",
        "LV": "1",
        "Item Description": "WRAPPER CUPCAKE 3.25INX1.9IN 50TH ANNIVERY 1500 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51014777",
        "Last Price Paid": "$0.09",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076280",
        "Product Status": "Active"
    },
    {
        "Root": "10025038",
        "LV": "1",
        "Item Description": "SUGAR SANDING BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025038001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9459",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025038",
        "Product Status": "Active"
    },
    {
        "Root": "10001003",
        "LV": "1",
        "Item Description": "SNAPPER WHOLE DISPLAY SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001003001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-02022",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001003",
        "Product Status": "Active"
    },
    {
        "Root": "10001003",
        "LV": "1",
        "Item Description": "SNAPPER WHOLE DISPLAY SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001003001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001003",
        "Product Status": "Active"
    },
    {
        "Root": "10001003",
        "LV": "1",
        "Item Description": "SNAPPER WHOLE DISPLAY SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001003001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6484750",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001003",
        "Product Status": "Active"
    },
    {
        "Root": "10005247",
        "LV": "1",
        "Item Description": "MIX FREESTYLE DASANI FLAV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005247001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3964760",
        "Last Price Paid": "$64.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005247",
        "Product Status": "Active"
    },
    {
        "Root": "10001723",
        "LV": "1",
        "Item Description": "PEPPER HOLLAND ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001723001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407933",
        "Last Price Paid": "$25.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001723",
        "Product Status": "Active"
    },
    {
        "Root": "10009815",
        "LV": "2",
        "Item Description": "RUM SAILOR JERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009815001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001739",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009815",
        "Product Status": "Active"
    },
    {
        "Root": "10009815",
        "LV": "2",
        "Item Description": "RUM SAILOR JERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009815001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9001739",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009815",
        "Product Status": "Active"
    },
    {
        "Root": "10004456",
        "LV": "1",
        "Item Description": "MIX FREESTYLE HI-C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4245989",
        "Last Price Paid": "$42.28",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004456",
        "Product Status": "Active"
    },
    {
        "Root": "10037132",
        "LV": "1",
        "Item Description": "CUP GLASS H20GLOW PRINTEDLED 16 OZ 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037132",
        "Product Status": "Active"
    },
    {
        "Root": "10082530",
        "LV": "1",
        "Item Description": "PORK EARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082530001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1480021",
        "Last Price Paid": "",
        "Case Pack": "0.25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082530",
        "Product Status": "Active"
    },
    {
        "Root": "10039907",
        "LV": "1",
        "Item Description": "BEER LEXINGTON COFFEE VANILLA 12OZ BTL 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039907",
        "Product Status": "Active"
    },
    {
        "Root": "10018098",
        "LV": "1",
        "Item Description": "ACHAVAL FERRER FINCA BELLA VISTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018098001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9006847",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018098",
        "Product Status": "Active"
    },
    {
        "Root": "10033008",
        "LV": "1",
        "Item Description": "SUGAR BEACHCOMBER ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033008",
        "Product Status": "Active"
    },
    {
        "Root": "10006977",
        "LV": "1",
        "Item Description": "CAB SAUV HOGUE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2170843",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006977",
        "Product Status": "Active"
    },
    {
        "Root": "10040077",
        "LV": "2",
        "Item Description": "CAMPFIRE TREAT KIT RECREATION DVC LOGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040077002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "CAMP",
        "Last Price Paid": "",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040077",
        "Product Status": "Active"
    },
    {
        "Root": "10037932",
        "LV": "1",
        "Item Description": "PROTEIN BITE LG DARK RASPBERRY 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F12101W",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037932",
        "Product Status": "Active"
    },
    {
        "Root": "10038559",
        "LV": "1",
        "Item Description": "TEQUILA PARTIDA ELEGANTE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038559",
        "Product Status": "Active"
    },
    {
        "Root": "10003379",
        "LV": "2",
        "Item Description": "SPICE MESQUITE 6/2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003379002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003379",
        "Product Status": "Active"
    },
    {
        "Root": "10023857",
        "LV": "1",
        "Item Description": "MICKEY HAT W EARS MINI 1 1/2 X 7/8 DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023857001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3185D",
        "Last Price Paid": "$0.80",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023857",
        "Product Status": "Active"
    },
    {
        "Root": "10032471",
        "LV": "1",
        "Item Description": "BEER IPA CIGAR CITY JAI ALAI 16OZ CAN 24 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38060",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032471",
        "Product Status": "Active"
    },
    {
        "Root": "10107332",
        "LV": "1",
        "Item Description": "CIDER RASPBERRY CITRUS 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "62160",
        "Last Price Paid": "$200.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107332",
        "Product Status": "Active"
    },
    {
        "Root": "10037361",
        "LV": "1",
        "Item Description": "RECTANGLE TRAY LIDS CLEAR DOME 20/30Z 300/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303120",
        "Last Price Paid": "$0.48",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037361",
        "Product Status": "Active"
    },
    {
        "Root": "10102232",
        "LV": "1",
        "Item Description": "CHICKEN VEGAN GARDEIN ULTIMATE BREAD FILETS 32/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102232001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "087426700012",
        "Last Price Paid": "$2.13",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102232",
        "Product Status": "Active"
    },
    {
        "Root": "10001328",
        "LV": "2",
        "Item Description": "ONION RING BEER BATTERED 5/8 INCH 6/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001328002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.79",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001328",
        "Product Status": "Active"
    },
    {
        "Root": "10002209",
        "LV": "1",
        "Item Description": "MILK MICKEY 1% HALF PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002209",
        "Product Status": "Active"
    },
    {
        "Root": "10002698",
        "LV": "1",
        "Item Description": "FLOUR CORN 5.6% 50# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002698",
        "Product Status": "Active"
    },
    {
        "Root": "10001505",
        "LV": "1",
        "Item Description": "POPCORN SHOOTS E PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001505001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001505",
        "Product Status": "Active"
    },
    {
        "Root": "10004692",
        "LV": "1",
        "Item Description": "SNACK PRETZEL YOGURT COVERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004692001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "503303",
        "Last Price Paid": "$52.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004692",
        "Product Status": "Active"
    },
    {
        "Root": "10009979",
        "LV": "1",
        "Item Description": "LIQ MELON MIDORI LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "284690",
        "Last Price Paid": "$21.18",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009979",
        "Product Status": "Active"
    },
    {
        "Root": "10040261",
        "LV": "1",
        "Item Description": "PEPPER PEPPERONCINI MILD 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9054032",
        "Last Price Paid": "$7.89",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040261",
        "Product Status": "Active"
    },
    {
        "Root": "10036267",
        "LV": "1",
        "Item Description": "VINA BORGIA GARNACHA RED WINE BAG-IN-BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "515980",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036267",
        "Product Status": "Active"
    },
    {
        "Root": "10036267",
        "LV": "1",
        "Item Description": "VINA BORGIA GARNACHA RED WINE BAG-IN-BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "515980",
        "Last Price Paid": "$17.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036267",
        "Product Status": "Active"
    },
    {
        "Root": "10001589",
        "LV": "1",
        "Item Description": "SHALLOT WHOLE UNPEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001589001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001589",
        "Product Status": "Active"
    },
    {
        "Root": "10002797",
        "LV": "1",
        "Item Description": "BUTTER MARGARINE EUROPEAN BLEND 36/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "974584",
        "Last Price Paid": "$1.70",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002797",
        "Product Status": "Active"
    },
    {
        "Root": "10013627",
        "LV": "1",
        "Item Description": "SALMON VERLLASO 3 OZ PORTION SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013627001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6501823",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013627",
        "Product Status": "Active"
    },
    {
        "Root": "10001340",
        "LV": "1",
        "Item Description": "HUMMUS PLAIN 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$139.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001340",
        "Product Status": "Active"
    },
    {
        "Root": "10001807",
        "LV": "2",
        "Item Description": "RAISINS ON THE VINE 10/4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001807002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598905",
        "Last Price Paid": "$5.23",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001807",
        "Product Status": "Active"
    },
    {
        "Root": "10002533",
        "LV": "1",
        "Item Description": "CHEESE GOUDA HOLLAND 9 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002533001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1670",
        "Last Price Paid": "$4.93",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002533",
        "Product Status": "Active"
    },
    {
        "Root": "10019380",
        "LV": "1",
        "Item Description": "TEA LOOSE LONDON STRAND EARL GREY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13698",
        "Last Price Paid": "$31.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019380",
        "Product Status": "Active"
    },
    {
        "Root": "10001460",
        "LV": "1",
        "Item Description": "KALE VEGETABLE BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450160",
        "Last Price Paid": "$6.37",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001460",
        "Product Status": "Active"
    },
    {
        "Root": "10009455",
        "LV": "1",
        "Item Description": "SCOTCH J WALKER BLACK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009455",
        "Product Status": "Active"
    },
    {
        "Root": "10001984",
        "LV": "1",
        "Item Description": "SHISO MIX MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001984001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MSM-33-L",
        "Last Price Paid": "$50.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001984",
        "Product Status": "Active"
    },
    {
        "Root": "10001687",
        "LV": "1",
        "Item Description": "FRUIT KABOB 3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001687001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-004",
        "Last Price Paid": "$1.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001687",
        "Product Status": "Active"
    },
    {
        "Root": "10001687",
        "LV": "1",
        "Item Description": "FRUIT KABOB 3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001687001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "840016",
        "Last Price Paid": "$1.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001687",
        "Product Status": "Active"
    },
    {
        "Root": "10006945",
        "LV": "1",
        "Item Description": "CHARD COPPERIDGE 1.5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006945001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1224427",
        "Last Price Paid": "$6.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006945",
        "Product Status": "Active"
    },
    {
        "Root": "10102689",
        "LV": "1",
        "Item Description": "GUACAMOLE WESTERN POUCH FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6602288",
        "Last Price Paid": "$14.74",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102689",
        "Product Status": "Active"
    },
    {
        "Root": "10022818",
        "LV": "1",
        "Item Description": "PERRIN SINARDS CDP ROUGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2270643",
        "Last Price Paid": "$36.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022818",
        "Product Status": "Active"
    },
    {
        "Root": "10001479",
        "LV": "1",
        "Item Description": "POTATO SWEET 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408440",
        "Last Price Paid": "$0.43",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001479",
        "Product Status": "Active"
    },
    {
        "Root": "10001479",
        "LV": "1",
        "Item Description": "POTATO SWEET 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.43",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001479",
        "Product Status": "Active"
    },
    {
        "Root": "10018311",
        "LV": "1",
        "Item Description": "TORTILLA 12\" TOMATO BASIL WRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "384800",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018311",
        "Product Status": "Active"
    },
    {
        "Root": "10025958",
        "LV": "1",
        "Item Description": "PLATE 10 IN FIBER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "51100800",
        "Last Price Paid": "$0.15",
        "Case Pack": "800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025958",
        "Product Status": "Active"
    },
    {
        "Root": "10003964",
        "LV": "1",
        "Item Description": "MOLASSES POMEGRANATE 17Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-10070",
        "Last Price Paid": "$4.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003964",
        "Product Status": "Active"
    },
    {
        "Root": "10018715",
        "LV": "1",
        "Item Description": "FLOUR BAKING 1 TO 1 GF BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101471",
        "Last Price Paid": "$14.24",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018715",
        "Product Status": "Active"
    },
    {
        "Root": "10000346",
        "LV": "1",
        "Item Description": "VEG MIX STIRFRY D",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "513450",
        "Last Price Paid": "$13.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000346",
        "Product Status": "Active"
    },
    {
        "Root": "10001543",
        "LV": "1",
        "Item Description": "HERB CULANTRO FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450010",
        "Last Price Paid": "$0.55",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001543",
        "Product Status": "Active"
    },
    {
        "Root": "10002421",
        "LV": "1",
        "Item Description": "SORBET LEMON 3 GAL TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002421001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002421",
        "Product Status": "Active"
    },
    {
        "Root": "10000411",
        "LV": "1",
        "Item Description": "BOX LNCH WF PREMIER (CHX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000411",
        "Product Status": "Active"
    },
    {
        "Root": "10044728",
        "LV": "1",
        "Item Description": "SPICE CINNAMON STICK 2.75IN 8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5032941",
        "Last Price Paid": "$18.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044728",
        "Product Status": "Active"
    },
    {
        "Root": "10100728",
        "LV": "1",
        "Item Description": "LIQUEUR GRANDE ABSENTE ANSINTHE 750ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "255349",
        "Last Price Paid": "$45.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100728",
        "Product Status": "Active"
    },
    {
        "Root": "10014154",
        "LV": "1",
        "Item Description": "LEEKS BABY 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$140.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014154",
        "Product Status": "Active"
    },
    {
        "Root": "10014840",
        "LV": "1",
        "Item Description": "NAPKIN BEV TREE FREE 100% DISNEY PARKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014840",
        "Product Status": "Active"
    },
    {
        "Root": "10042184",
        "LV": "2",
        "Item Description": "CUP COMPOSTABLE 16OZ CLEAR CP-CS-16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042184002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10042184",
        "Product Status": "Active"
    },
    {
        "Root": "10004454",
        "LV": "1",
        "Item Description": "MIX FREESTYLE FANTA/ZERO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3555816",
        "Last Price Paid": "$53.38",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004454",
        "Product Status": "Active"
    },
    {
        "Root": "10001361",
        "LV": "1",
        "Item Description": "SAUCE HIBISCUS 12/24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001361",
        "Product Status": "Active"
    },
    {
        "Root": "10005791",
        "LV": "1",
        "Item Description": "BAGEL PLAIN GLUTEN FREE UDIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "780954",
        "Last Price Paid": "$4.05",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005791",
        "Product Status": "Active"
    },
    {
        "Root": "10040679",
        "LV": "1",
        "Item Description": "HAM SERRANO BLOCK 12 MONTH 11LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040679001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "71051",
        "Last Price Paid": "$16.95",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040679",
        "Product Status": "Active"
    },
    {
        "Root": "10001969",
        "LV": "1",
        "Item Description": "MACHE MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MA-33-L",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001969",
        "Product Status": "Active"
    },
    {
        "Root": "10081381",
        "LV": "1",
        "Item Description": "POPCORN SUMMER FESTIVE MIX HSTUDIOS 50-4OZ BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "SUMMER FESTIVE",
        "Last Price Paid": "$3.71",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081381",
        "Product Status": "Active"
    },
    {
        "Root": "10010659",
        "LV": "2",
        "Item Description": "TEQ CASAMIGOS ANEJO LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010659001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "446125",
        "Last Price Paid": "$61.40",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010659",
        "Product Status": "Active"
    },
    {
        "Root": "10005935",
        "LV": "1",
        "Item Description": "BREAD, WHITE SLCD PKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BROOKE FOODS-C",
        "Last Price Paid": "$1.45",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005935",
        "Product Status": "Active"
    },
    {
        "Root": "10102030",
        "LV": "1",
        "Item Description": "CHEDDAR SHREDS FOLLOW YOUR HEART DAIRYFREE 6/2.2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "15022",
        "Last Price Paid": "$9.94",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102030",
        "Product Status": "Active"
    },
    {
        "Root": "10076722",
        "LV": "1",
        "Item Description": "SHERRY COOKING TAYLOR 1.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9004910",
        "Last Price Paid": "$1.63",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076722",
        "Product Status": "Active"
    },
    {
        "Root": "10015900",
        "LV": "1",
        "Item Description": "SMOOTHIE STRAWBERRY DANIMALS BTL 3.1OZ/48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.30",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015900",
        "Product Status": "Active"
    },
    {
        "Root": "10091690",
        "LV": "1",
        "Item Description": "DEMI GLACE DE VEAU CLASSIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4602536",
        "Last Price Paid": "$6.19",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091690",
        "Product Status": "Active"
    },
    {
        "Root": "10004791",
        "LV": "1",
        "Item Description": "COCONUT MILK UNSWEET 6/#1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4192969",
        "Last Price Paid": "$11.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004791",
        "Product Status": "Active"
    },
    {
        "Root": "10036772",
        "LV": "3",
        "Item Description": "SALAMI SANDWICH SLICED 8/2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036772002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.44",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10036772",
        "Product Status": "Active"
    },
    {
        "Root": "10003824",
        "LV": "1",
        "Item Description": "BAR TRAIL DRK CHOC FREE N",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "688309",
        "Last Price Paid": "$1.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003824",
        "Product Status": "Active"
    },
    {
        "Root": "10003322",
        "LV": "1",
        "Item Description": "SYRUP MONIN RASPBERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003322",
        "Product Status": "Active"
    },
    {
        "Root": "10001684",
        "LV": "1",
        "Item Description": "POTATO YUKON CHEF 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001684001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001684",
        "Product Status": "Active"
    },
    {
        "Root": "10001684",
        "LV": "1",
        "Item Description": "POTATO YUKON CHEF 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001684001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408447",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001684",
        "Product Status": "Active"
    },
    {
        "Root": "10001684",
        "LV": "1",
        "Item Description": "POTATO YUKON CHEF 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001684001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "29400",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001684",
        "Product Status": "Active"
    },
    {
        "Root": "10109653",
        "LV": "1",
        "Item Description": "PICKLE DILL BREADED SPEAR FROZEN APPETIZER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109653001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6375943",
        "Last Price Paid": "$12.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109653",
        "Product Status": "Active"
    },
    {
        "Root": "10103288",
        "LV": "1",
        "Item Description": "BEER 3 DAUGHTERS WINTER SPICE LAGER 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76307",
        "Last Price Paid": "$190.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103288",
        "Product Status": "Active"
    },
    {
        "Root": "10104651",
        "LV": "1",
        "Item Description": "MISO WHITE PASTE FLAVORING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7793847",
        "Last Price Paid": "$6.92",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104651",
        "Product Status": "Active"
    },
    {
        "Root": "10104509",
        "LV": "1",
        "Item Description": "MAHI MAHI 6 OZ PIECE FRZ SKIN OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104509001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.64",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104509",
        "Product Status": "Active"
    },
    {
        "Root": "10004983",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK 53.1% CALLEBAUT CALLET 2/22 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004983001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "G42809",
        "Last Price Paid": "$91.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004983",
        "Product Status": "Active"
    },
    {
        "Root": "10010661",
        "LV": "3",
        "Item Description": "VODKA SKYY INFUS PINE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010661002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "993071",
        "Last Price Paid": "$12.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10010661",
        "Product Status": "Active"
    },
    {
        "Root": "10002028",
        "LV": "1",
        "Item Description": "CARROT BABY MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MBCT-26",
        "Last Price Paid": "$115.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002028",
        "Product Status": "Active"
    },
    {
        "Root": "10012559",
        "LV": "1",
        "Item Description": "MINT BLACK 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08BLM-33",
        "Last Price Paid": "$27.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012559",
        "Product Status": "Active"
    },
    {
        "Root": "10032124",
        "LV": "1",
        "Item Description": "MIX FREESTYLE PT 2 PIBB 23OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "5131745",
        "Last Price Paid": "$10.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032124",
        "Product Status": "Active"
    },
    {
        "Root": "10002849",
        "LV": "1",
        "Item Description": "DRESSING BLUE CHEESE 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.25",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002849",
        "Product Status": "Active"
    },
    {
        "Root": "10018801",
        "LV": "1",
        "Item Description": "POWDER DECOR EGGPLANT LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "100279",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018801",
        "Product Status": "Active"
    },
    {
        "Root": "10086633",
        "LV": "1",
        "Item Description": "MIX BASIC POPCORN TREAT COATING 12/22OZ BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2096",
        "Last Price Paid": "$3.11",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086633",
        "Product Status": "Active"
    },
    {
        "Root": "10044189",
        "LV": "1",
        "Item Description": "BUN HAMBURGER GF FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1689397",
        "Last Price Paid": "$1.16",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044189",
        "Product Status": "Active"
    },
    {
        "Root": "10002640",
        "LV": "2",
        "Item Description": "SPICE TURMERIC GROUND 6/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002640002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.51",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002640",
        "Product Status": "Active"
    },
    {
        "Root": "10036355",
        "LV": "1",
        "Item Description": "DIANTHUS BUNCHES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DIAPK",
        "Last Price Paid": "$4.16",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036355",
        "Product Status": "Active"
    },
    {
        "Root": "10044333",
        "LV": "1",
        "Item Description": "CANDY M&M BLUE 2/5# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "10469",
        "Last Price Paid": "$42.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044333",
        "Product Status": "Active"
    },
    {
        "Root": "10006984",
        "LV": "3",
        "Item Description": "CAB SAUV SILVERADO VNYRD 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006984003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "603648",
        "Last Price Paid": "$34.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10006984",
        "Product Status": "Active"
    },
    {
        "Root": "10012569",
        "LV": "1",
        "Item Description": "CHOC MINNIE WITCH HAT CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW32294",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012569",
        "Product Status": "Active"
    },
    {
        "Root": "10042488",
        "LV": "1",
        "Item Description": "RED BLEND INTERCEPT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9391252",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042488",
        "Product Status": "Active"
    },
    {
        "Root": "10005272",
        "LV": "1",
        "Item Description": "COLORING FOOD GREEN 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5537741",
        "Last Price Paid": "$39.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005272",
        "Product Status": "Active"
    },
    {
        "Root": "10011824",
        "LV": "1",
        "Item Description": "PORK TENDERLOIN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011824001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.97",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011824",
        "Product Status": "Active"
    },
    {
        "Root": "10034135",
        "LV": "1",
        "Item Description": "BEER RED STRIPE 16 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13566",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034135",
        "Product Status": "Active"
    },
    {
        "Root": "10012349",
        "LV": "1",
        "Item Description": "VERSA WHIP 620K",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87396",
        "Last Price Paid": "$52.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012349",
        "Product Status": "Active"
    },
    {
        "Root": "10001827",
        "LV": "1",
        "Item Description": "LETTUCE BABY ASSORTD 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001827",
        "Product Status": "Active"
    },
    {
        "Root": "10001827",
        "LV": "1",
        "Item Description": "LETTUCE BABY ASSORTD 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481826",
        "Last Price Paid": "$26.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001827",
        "Product Status": "Active"
    },
    {
        "Root": "10034754",
        "LV": "1",
        "Item Description": "TRANSFER SHEET SNOWFLAKES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "902361C",
        "Last Price Paid": "$4.23",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034754",
        "Product Status": "Active"
    },
    {
        "Root": "10008019",
        "LV": "2",
        "Item Description": "WINE LAPOSTELLE CLOS APALTA RED BLEND 14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008019001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "410027",
        "Last Price Paid": "$109.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008019",
        "Product Status": "Active"
    },
    {
        "Root": "10010568",
        "LV": "1",
        "Item Description": "SELB/OSTER RIESL SPAT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010568",
        "Product Status": "Active"
    },
    {
        "Root": "10001590",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001590",
        "Product Status": "Active"
    },
    {
        "Root": "10001590",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "29440",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001590",
        "Product Status": "Active"
    },
    {
        "Root": "10001590",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408481",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001590",
        "Product Status": "Active"
    },
    {
        "Root": "10108728",
        "LV": "1",
        "Item Description": "SAUCE ENCHLDA RED MILD 6/#10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2015485",
        "Last Price Paid": "$7.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108728",
        "Product Status": "Active"
    },
    {
        "Root": "10033236",
        "LV": "1",
        "Item Description": "COLOR GEL PASTE CORAL RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC CORAL RED",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033236",
        "Product Status": "Active"
    },
    {
        "Root": "10008206",
        "LV": "2",
        "Item Description": "ZINF EDMEADES MENDOC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008206001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008206",
        "Product Status": "Active"
    },
    {
        "Root": "10083981",
        "LV": "1",
        "Item Description": "LID CLEAR HIGH DOME 8 IN X 11 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "15518",
        "Last Price Paid": "",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083981",
        "Product Status": "Active"
    },
    {
        "Root": "10005574",
        "LV": "1",
        "Item Description": "PIZZA CRUST PERSONAL 5.5Z/7INCH 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005574",
        "Product Status": "Active"
    },
    {
        "Root": "10009050",
        "LV": "2",
        "Item Description": "SYRAH TURLEY PETITE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009050001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9187917",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009050",
        "Product Status": "Active"
    },
    {
        "Root": "10036408",
        "LV": "2",
        "Item Description": "SPOON SHOVEL FLOWER POT PLASTIC BLK/WHT 5X1.25 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036408002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036408",
        "Product Status": "Active"
    },
    {
        "Root": "10005280",
        "LV": "1",
        "Item Description": "JELLY APPLE MINT 4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7420755",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005280",
        "Product Status": "Active"
    },
    {
        "Root": "10011949",
        "LV": "1",
        "Item Description": "TOMATO MIXED LARGE 8 POUNDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17MXHM-44",
        "Last Price Paid": "$86.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011949",
        "Product Status": "Active"
    },
    {
        "Root": "10021130",
        "LV": "1",
        "Item Description": "TUNA OCEAN NATURAL CHUNK SKIPJACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6228811",
        "Last Price Paid": "$10.94",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021130",
        "Product Status": "Active"
    },
    {
        "Root": "10041047",
        "LV": "1",
        "Item Description": "PLATE PAPER OVAL 7X9 IN MARY BLAIR 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041047",
        "Product Status": "Active"
    },
    {
        "Root": "10004126",
        "LV": "1",
        "Item Description": "CRACKER CHEESE PB 1.42OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6823872",
        "Last Price Paid": "$0.26",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004126",
        "Product Status": "Active"
    },
    {
        "Root": "10001567",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI BABY 5 POUNDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001567001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480100",
        "Last Price Paid": "$24.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001567",
        "Product Status": "Active"
    },
    {
        "Root": "10001567",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI BABY 5 POUNDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001567001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22322",
        "Last Price Paid": "$24.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001567",
        "Product Status": "Active"
    },
    {
        "Root": "10017438",
        "LV": "1",
        "Item Description": "HEINZ BA EIFEL 375 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017438001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017438",
        "Product Status": "Active"
    },
    {
        "Root": "10010967",
        "LV": "1",
        "Item Description": "CANDY CHOCOLATE ESPRESSO BEANS - MARICH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "593004",
        "Last Price Paid": "$2.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010967",
        "Product Status": "Active"
    },
    {
        "Root": "10102347",
        "LV": "1",
        "Item Description": "POULET ROUGE SEMI BNLS 2.25-2.75# WOG 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102347001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.54",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102347",
        "Product Status": "Active"
    },
    {
        "Root": "10088028",
        "LV": "2",
        "Item Description": "CANDY GUMMY WORMS 20# BULK CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088028002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "780",
        "Last Price Paid": "$43.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088028",
        "Product Status": "Active"
    },
    {
        "Root": "10004206",
        "LV": "1",
        "Item Description": "NOODLE FRZ YAKISOBA LO ME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004206001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7162175",
        "Last Price Paid": "$7.52",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004206",
        "Product Status": "Active"
    },
    {
        "Root": "10015124",
        "LV": "1",
        "Item Description": "BEET GOLD BABY PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490092",
        "Last Price Paid": "$1.07",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015124",
        "Product Status": "Active"
    },
    {
        "Root": "10107340",
        "LV": "1",
        "Item Description": "CIDER BOLD ROCK PEACH BERRY 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76546",
        "Last Price Paid": "$167.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107340",
        "Product Status": "Active"
    },
    {
        "Root": "10038382",
        "LV": "1",
        "Item Description": "SW CLUTCH SAUCE 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$142.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038382",
        "Product Status": "Active"
    },
    {
        "Root": "10105130",
        "LV": "1",
        "Item Description": "LINER BASKET HVY WT 16X16 4 COLORS RODEO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659035",
        "Last Price Paid": "$0.10",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105130",
        "Product Status": "Active"
    },
    {
        "Root": "10025699",
        "LV": "1",
        "Item Description": "DENATURED ALCOHOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025699001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "818019",
        "Last Price Paid": "$14.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025699",
        "Product Status": "Active"
    },
    {
        "Root": "10018390",
        "LV": "1",
        "Item Description": "SQUID WHL LOLIGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018390001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50065",
        "Last Price Paid": "$2.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018390",
        "Product Status": "Active"
    },
    {
        "Root": "10018390",
        "LV": "1",
        "Item Description": "SQUID WHL LOLIGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018390001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6410390",
        "Last Price Paid": "$2.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018390",
        "Product Status": "Active"
    },
    {
        "Root": "10040499",
        "LV": "1",
        "Item Description": "SAUCE ALFREDO FROZEN 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8690270",
        "Last Price Paid": "$13.04",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040499",
        "Product Status": "Active"
    },
    {
        "Root": "10094818",
        "LV": "1",
        "Item Description": "TURKEY WHOLE BIRD 20-24 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094818001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.62",
        "Case Pack": "44",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094818",
        "Product Status": "Active"
    },
    {
        "Root": "10091231",
        "LV": "1",
        "Item Description": "MOCHI MY 12 X 6 PK. STRAWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "9991",
        "Last Price Paid": "$4.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091231",
        "Product Status": "Active"
    },
    {
        "Root": "10013221",
        "LV": "1",
        "Item Description": "DELI CARAMEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "7158",
        "Last Price Paid": "$67.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013221",
        "Product Status": "Active"
    },
    {
        "Root": "10041321",
        "LV": "1",
        "Item Description": "CANDY TWIX CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "525280",
        "Last Price Paid": "$102.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041321",
        "Product Status": "Active"
    },
    {
        "Root": "10009533",
        "LV": "2",
        "Item Description": "WHISKY CRWN RYL REGAL APL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009533001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "411310",
        "Last Price Paid": "$33.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009533",
        "Product Status": "Active"
    },
    {
        "Root": "10036168",
        "LV": "1",
        "Item Description": "TRAY LOAF PAN RED W/ WHITE DOTS 15.5Z 4.5X2.5X2.3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001649",
        "Last Price Paid": "$0.47",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036168",
        "Product Status": "Active"
    },
    {
        "Root": "10012029",
        "LV": "1",
        "Item Description": "BASIL BLOOMS MIXED 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MBBL-24",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012029",
        "Product Status": "Active"
    },
    {
        "Root": "10001682",
        "LV": "1",
        "Item Description": "ONION VIDALIA 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "27150",
        "Last Price Paid": "$36.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001682",
        "Product Status": "Active"
    },
    {
        "Root": "10001682",
        "LV": "1",
        "Item Description": "ONION VIDALIA 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001682",
        "Product Status": "Active"
    },
    {
        "Root": "10001682",
        "LV": "1",
        "Item Description": "ONION VIDALIA 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406033",
        "Last Price Paid": "$36.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001682",
        "Product Status": "Active"
    },
    {
        "Root": "10010038",
        "LV": "1",
        "Item Description": "BEER CORONA EXTRA CAN 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010038001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "62428",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010038",
        "Product Status": "Active"
    },
    {
        "Root": "10006197",
        "LV": "2",
        "Item Description": "TIES GOLD 4 INCH METALLIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006197002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51082040",
        "Last Price Paid": "$0.01",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006197",
        "Product Status": "Active"
    },
    {
        "Root": "10002248",
        "LV": "1",
        "Item Description": "CHEESE PEPPER JACK 10 LB PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002248001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "858",
        "Last Price Paid": "$4.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002248",
        "Product Status": "Active"
    },
    {
        "Root": "10009991",
        "LV": "1",
        "Item Description": "LIQ SOUTHRN COMFT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "20339",
        "Last Price Paid": "$16.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009991",
        "Product Status": "Active"
    },
    {
        "Root": "10002431",
        "LV": "2",
        "Item Description": "ICE CREAM DULCE DE LECHE EDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002431002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002431",
        "Product Status": "Active"
    },
    {
        "Root": "10002431",
        "LV": "2",
        "Item Description": "ICE CREAM DULCE DE LECHE EDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002431002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9447715",
        "Last Price Paid": "$52.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002431",
        "Product Status": "Active"
    },
    {
        "Root": "10009487",
        "LV": "1",
        "Item Description": "SCOTCH J WALKER BLUE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009487001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "402745",
        "Last Price Paid": "$214.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009487",
        "Product Status": "Active"
    },
    {
        "Root": "10034756",
        "LV": "1",
        "Item Description": "TRANSFER SHEET PRESENTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "902359C",
        "Last Price Paid": "$4.59",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034756",
        "Product Status": "Active"
    },
    {
        "Root": "10036933",
        "LV": "2",
        "Item Description": "SUGAR DOUGH 144PCS 2.75 X 5.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036933002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.41",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036933",
        "Product Status": "Active"
    },
    {
        "Root": "10004099",
        "LV": "1",
        "Item Description": "CHOC DRK NSA SQR 1 INCH RD BO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004099001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW240456R",
        "Last Price Paid": "$0.12",
        "Case Pack": "1540",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004099",
        "Product Status": "Active"
    },
    {
        "Root": "10006737",
        "LV": "1",
        "Item Description": "BOARD CAKE 18 INCH X 1/2 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006737001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1812...{ENJAY{",
        "Last Price Paid": "$3.85",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006737",
        "Product Status": "Active"
    },
    {
        "Root": "10000169",
        "LV": "1",
        "Item Description": "GRAPEFRUIT PINK 27 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "200172",
        "Last Price Paid": "$1.51",
        "Case Pack": "27",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000169",
        "Product Status": "Active"
    },
    {
        "Root": "10004996",
        "LV": "1",
        "Item Description": "POWDER O TENTIC DURUM PUR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P96003",
        "Last Price Paid": "$18.28",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004996",
        "Product Status": "Active"
    },
    {
        "Root": "10012956",
        "LV": "1",
        "Item Description": "SQUASH MIXED BABY 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "16MBS-26A",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012956",
        "Product Status": "Active"
    },
    {
        "Root": "10013977",
        "LV": "1",
        "Item Description": "ENV GLASSINE G10 2.75 X 2.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51682002",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013977",
        "Product Status": "Active"
    },
    {
        "Root": "10023615",
        "LV": "1",
        "Item Description": "PUMPKIN FACE 15 - 20 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482114",
        "Last Price Paid": "$9.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023615",
        "Product Status": "Active"
    },
    {
        "Root": "10029193",
        "LV": "1",
        "Item Description": "CHEESE HARD TOMME SWEET GRASS 6.5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029193001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19003",
        "Last Price Paid": "$12.87",
        "Case Pack": "5.46",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029193",
        "Product Status": "Active"
    },
    {
        "Root": "10001940",
        "LV": "1",
        "Item Description": "ONION FC YELLOW JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514098",
        "Last Price Paid": "$11.48",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001940",
        "Product Status": "Active"
    },
    {
        "Root": "10001940",
        "LV": "1",
        "Item Description": "ONION FC YELLOW JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-008",
        "Last Price Paid": "$11.48",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001940",
        "Product Status": "Active"
    },
    {
        "Root": "10016092",
        "LV": "1",
        "Item Description": "PORK BUTT BONELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016092001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.76",
        "Case Pack": "37",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016092",
        "Product Status": "Active"
    },
    {
        "Root": "10039451",
        "LV": "1",
        "Item Description": "CEREAL RAISIN BRAN BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.35",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039451",
        "Product Status": "Active"
    },
    {
        "Root": "10086596",
        "LV": "1",
        "Item Description": "BAR MIX NATURAL BLONDE ORIGINAL BLOODY MARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "112243",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086596",
        "Product Status": "Active"
    },
    {
        "Root": "10107499",
        "LV": "1",
        "Item Description": "BEER NORTH COAST LAGUNA BAJA 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "4965",
        "Last Price Paid": "$219.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107499",
        "Product Status": "Active"
    },
    {
        "Root": "10009590",
        "LV": "1",
        "Item Description": "VODKA KETEL ONE 80 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009590",
        "Product Status": "Active"
    },
    {
        "Root": "10006175",
        "LV": "2",
        "Item Description": "GLASS STACKING HURRICANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006175001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.65",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006175",
        "Product Status": "Active"
    },
    {
        "Root": "10030266",
        "LV": "1",
        "Item Description": "OIL OLIVE EXTRA VIRGIN VILLA MANODORI EVOO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "OI58",
        "Last Price Paid": "$17.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030266",
        "Product Status": "Active"
    },
    {
        "Root": "10012364",
        "LV": "1",
        "Item Description": "RED GROUPER FILLET SKIN OFF, WILD SUSTAINABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012364001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00742",
        "Last Price Paid": "$20.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012364",
        "Product Status": "Active"
    },
    {
        "Root": "10012364",
        "LV": "1",
        "Item Description": "RED GROUPER FILLET SKIN OFF, WILD SUSTAINABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012364001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3275017",
        "Last Price Paid": "$20.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012364",
        "Product Status": "Active"
    },
    {
        "Root": "10001686",
        "LV": "1",
        "Item Description": "TURNIPS 25# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001686001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001686",
        "Product Status": "Active"
    },
    {
        "Root": "10001686",
        "LV": "1",
        "Item Description": "TURNIPS 25# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001686001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401350",
        "Last Price Paid": "$18.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001686",
        "Product Status": "Active"
    },
    {
        "Root": "10009308",
        "LV": "2",
        "Item Description": "CAB SAUV BROADSIDE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009308001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9188121",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009308",
        "Product Status": "Active"
    },
    {
        "Root": "10009308",
        "LV": "2",
        "Item Description": "CAB SAUV BROADSIDE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009308001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9188121",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009308",
        "Product Status": "Active"
    },
    {
        "Root": "10035777",
        "LV": "1",
        "Item Description": "PORT BLACK AND BLUE BERRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035777001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069784",
        "Vendor": "FLORIDA ORANGE GROVES INC",
        "Vendor Item Nb": "BBP",
        "Last Price Paid": "$20.84",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035777",
        "Product Status": "Active"
    },
    {
        "Root": "10003562",
        "LV": "1",
        "Item Description": "SPICE ANISE SEED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003562",
        "Product Status": "Active"
    },
    {
        "Root": "10003793",
        "LV": "1",
        "Item Description": "NUT MACADAMIAS RAW 4/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003793001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "18110-10",
        "Last Price Paid": "$120.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003793",
        "Product Status": "Active"
    },
    {
        "Root": "10023426",
        "LV": "1",
        "Item Description": "CANDY NERD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550572",
        "Last Price Paid": "$32.69",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023426",
        "Product Status": "Active"
    },
    {
        "Root": "10006922",
        "LV": "1",
        "Item Description": "CAB SAUV SIMI ALEX-SON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "581010",
        "Last Price Paid": "$14.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006922",
        "Product Status": "Active"
    },
    {
        "Root": "10006922",
        "LV": "1",
        "Item Description": "CAB SAUV SIMI ALEX-SON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006922",
        "Product Status": "Active"
    },
    {
        "Root": "10107548",
        "LV": "1",
        "Item Description": "SPIRIT LIQUEUR CHOC CARMEL 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "5900637",
        "Last Price Paid": "$15.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107548",
        "Product Status": "Active"
    },
    {
        "Root": "10042499",
        "LV": "1",
        "Item Description": "WINE MAISON NOIR HORSESHOES & HANDGRENADES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9222242",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042499",
        "Product Status": "Active"
    },
    {
        "Root": "10009519",
        "LV": "1",
        "Item Description": "WHISKEY SEAGRAM 7 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009519001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "928096",
        "Last Price Paid": "$6.05",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009519",
        "Product Status": "Active"
    },
    {
        "Root": "10001895",
        "LV": "1",
        "Item Description": "CARROT JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001895001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "28-001",
        "Last Price Paid": "$20.93",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001895",
        "Product Status": "Active"
    },
    {
        "Root": "10001895",
        "LV": "1",
        "Item Description": "CARROT JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001895001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512013",
        "Last Price Paid": "$20.93",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001895",
        "Product Status": "Active"
    },
    {
        "Root": "10030351",
        "LV": "2",
        "Item Description": "SEASONING TAJIN 14 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030351002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI991",
        "Last Price Paid": "$7.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030351",
        "Product Status": "Active"
    },
    {
        "Root": "10005634",
        "LV": "1",
        "Item Description": "PASTRY CINNAMON LOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4045878",
        "Last Price Paid": "$6.68",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005634",
        "Product Status": "Active"
    },
    {
        "Root": "10005593",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT PRE-PROOF WORKHORSE 2.73OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.53",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005593",
        "Product Status": "Active"
    },
    {
        "Root": "10090524",
        "LV": "1",
        "Item Description": "SORBET WILD BERRY SOFT SERVE MIX 6 HALF GAL JUGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-WILDB-11-M",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090524",
        "Product Status": "Active"
    },
    {
        "Root": "10016959",
        "LV": "1",
        "Item Description": "SPICE ROSEMARY GRND 6/9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3551165",
        "Last Price Paid": "$11.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016959",
        "Product Status": "Active"
    },
    {
        "Root": "10107228",
        "LV": "1",
        "Item Description": "POLENTA - YELLOW GRITS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7180351",
        "Last Price Paid": "$19.83",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107228",
        "Product Status": "Active"
    },
    {
        "Root": "10036071",
        "LV": "1",
        "Item Description": "CRACKER CHEEZ IT WHOLE GRAIN SS 60/1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4585563",
        "Last Price Paid": "$0.24",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036071",
        "Product Status": "Active"
    },
    {
        "Root": "10007881",
        "LV": "1",
        "Item Description": "CHAMP TAITTINGER FRANCAIS BLC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007881001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "277266",
        "Last Price Paid": "$31.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007881",
        "Product Status": "Active"
    },
    {
        "Root": "10108094",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED PEPPER JACK 5LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108094001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "10858",
        "Last Price Paid": "$10.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108094",
        "Product Status": "Active"
    },
    {
        "Root": "10108094",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED PEPPER JACK 5LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108094001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108094",
        "Product Status": "Active"
    },
    {
        "Root": "10009973",
        "LV": "1",
        "Item Description": "LIQ APPLE SOUR DEKYPR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14983",
        "Last Price Paid": "$9.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009973",
        "Product Status": "Active"
    },
    {
        "Root": "10007690",
        "LV": "2",
        "Item Description": "TEMPRNL TORREMORON TINTO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007690001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9174308",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007690",
        "Product Status": "Active"
    },
    {
        "Root": "10007068",
        "LV": "1",
        "Item Description": "BLND RED MR COMP STELLEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "842425",
        "Last Price Paid": "$63.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007068",
        "Product Status": "Active"
    },
    {
        "Root": "10003629",
        "LV": "1",
        "Item Description": "SOUP CHOWDER CLAM BOSTON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4633087",
        "Last Price Paid": "$14.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003629",
        "Product Status": "Active"
    },
    {
        "Root": "10083666",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE DONALD HAT 3.5X2.63CM 560PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233030000",
        "Last Price Paid": "$0.27",
        "Case Pack": "560",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083666",
        "Product Status": "Active"
    },
    {
        "Root": "10094034",
        "LV": "1",
        "Item Description": "SPRINKLES DELUXE FUSION MIX-RAINBOW PARTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094034001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094034",
        "Product Status": "Active"
    },
    {
        "Root": "10009110",
        "LV": "1",
        "Item Description": "RIES PAUL CULVER NLH 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009110",
        "Product Status": "Active"
    },
    {
        "Root": "10045540",
        "LV": "1",
        "Item Description": "HOT CUP 2 OZ WORLD ART COMPOSTABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51014247",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045540",
        "Product Status": "Active"
    },
    {
        "Root": "10010961",
        "LV": "1",
        "Item Description": "BUGLES ORIGINAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "553919",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010961",
        "Product Status": "Active"
    },
    {
        "Root": "10007981",
        "LV": "1",
        "Item Description": "CAB SAUV CEDERBERG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007981",
        "Product Status": "Active"
    },
    {
        "Root": "10101031",
        "LV": "1",
        "Item Description": "SAMOSA POTATO & PEA .95OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043446",
        "Last Price Paid": "",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101031",
        "Product Status": "Active"
    },
    {
        "Root": "10029253",
        "LV": "1",
        "Item Description": "SUSHI NIGIRI SEARED BEEF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029253001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029253",
        "Product Status": "Active"
    },
    {
        "Root": "10026700",
        "LV": "1",
        "Item Description": "CRACKER SALTINE SLEEVE ZESTA SODA 40 PACKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026700001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5017025",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026700",
        "Product Status": "Active"
    },
    {
        "Root": "10094131",
        "LV": "1",
        "Item Description": "CANDY JUJU COINS CHERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "748",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094131",
        "Product Status": "Active"
    },
    {
        "Root": "10017753",
        "LV": "1",
        "Item Description": "PEPPER CHIPOLTE DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407120",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017753",
        "Product Status": "Active"
    },
    {
        "Root": "10008234",
        "LV": "2",
        "Item Description": "CHARD HESS SELECT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008234001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008234",
        "Product Status": "Active"
    },
    {
        "Root": "10027320",
        "LV": "1",
        "Item Description": "WHITE BLEND FONTANA CANDIDA ROMA BIANCO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "8851",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027320",
        "Product Status": "Active"
    },
    {
        "Root": "10032827",
        "LV": "1",
        "Item Description": "SATIN ICE GUM PASTE 2 PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "11830",
        "Last Price Paid": "$9.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032827",
        "Product Status": "Active"
    },
    {
        "Root": "10014622",
        "LV": "1",
        "Item Description": "RADISH EASTER EGG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20511",
        "Last Price Paid": "$1.48",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014622",
        "Product Status": "Active"
    },
    {
        "Root": "10014622",
        "LV": "1",
        "Item Description": "RADISH EASTER EGG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515522",
        "Last Price Paid": "$1.48",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014622",
        "Product Status": "Active"
    },
    {
        "Root": "10010963",
        "LV": "1",
        "Item Description": "PASTA RAVIOLI BUTTERNUT SQUASH FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010963001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070112",
        "Last Price Paid": "$19.25",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010963",
        "Product Status": "Active"
    },
    {
        "Root": "10004471",
        "LV": "1",
        "Item Description": "CO2 TANK 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "38",
        "Last Price Paid": "$6.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004471",
        "Product Status": "Active"
    },
    {
        "Root": "10005745",
        "LV": "1",
        "Item Description": "COOKIE CHOCOLATE CHIP GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3366236",
        "Last Price Paid": "$1.22",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005745",
        "Product Status": "Active"
    },
    {
        "Root": "10002933",
        "LV": "1",
        "Item Description": "TEA CHAI SPICE 6/20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002933001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002933",
        "Product Status": "Active"
    },
    {
        "Root": "10017199",
        "LV": "1",
        "Item Description": "LETTUCE FRISSEE LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482365",
        "Last Price Paid": "$1.77",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017199",
        "Product Status": "Active"
    },
    {
        "Root": "10006130",
        "LV": "1",
        "Item Description": "CUP 8Z PLAS FROSTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006130",
        "Product Status": "Active"
    },
    {
        "Root": "10003444",
        "LV": "1",
        "Item Description": "COFFEE KENYA DECF 18/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.96",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003444",
        "Product Status": "Active"
    },
    {
        "Root": "10003331",
        "LV": "2",
        "Item Description": "SYRUP SNO WATERMELON 4/1G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003331001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1253H",
        "Last Price Paid": "$10.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003331",
        "Product Status": "Active"
    },
    {
        "Root": "10002816",
        "LV": "1",
        "Item Description": "SUGAR POWDERED 10X 24/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.09",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002816",
        "Product Status": "Active"
    },
    {
        "Root": "10005639",
        "LV": "1",
        "Item Description": "COOKIE SUGAR 3 OZ PLUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "85",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005639",
        "Product Status": "Active"
    },
    {
        "Root": "10035149",
        "LV": "1",
        "Item Description": "SAUSAGE PORK SMOKED BRAZILIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$65.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035149",
        "Product Status": "Active"
    },
    {
        "Root": "10002366",
        "LV": "1",
        "Item Description": "CHEESE SEMI HARD GJETOST COW AND GOAT NORWAY 12/8.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "2350",
        "Last Price Paid": "$5.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002366",
        "Product Status": "Active"
    },
    {
        "Root": "10002456",
        "LV": "1",
        "Item Description": "CHEESE FONTINA DANISH 15 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002456001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "730",
        "Last Price Paid": "$5.36",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002456",
        "Product Status": "Active"
    },
    {
        "Root": "10015273",
        "LV": "1",
        "Item Description": "VINEGAR BALS FIG EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN057",
        "Last Price Paid": "$20.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015273",
        "Product Status": "Active"
    },
    {
        "Root": "10023556",
        "LV": "1",
        "Item Description": "COCOA BUTTER MOULIN ROUGE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "620028",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023556",
        "Product Status": "Active"
    },
    {
        "Root": "10022603",
        "LV": "1",
        "Item Description": "PERSIMMONS1000069667",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "370600",
        "Last Price Paid": "$56.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022603",
        "Product Status": "Active"
    },
    {
        "Root": "10082075",
        "LV": "1",
        "Item Description": "WHISKEY RYE MANIFEST 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082075001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "16162",
        "Last Price Paid": "$33.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082075",
        "Product Status": "Active"
    },
    {
        "Root": "10021938",
        "LV": "1",
        "Item Description": "CHOC DRK GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CHD-X5226P-X71",
        "Last Price Paid": "$17.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021938",
        "Product Status": "Active"
    },
    {
        "Root": "10104171",
        "LV": "1",
        "Item Description": "PICK BAMBOO ALLERGY 4.75\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104171001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11091167",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104171",
        "Product Status": "Active"
    },
    {
        "Root": "10090607",
        "LV": "1",
        "Item Description": "LIQ DEKUYPER AMARETTO 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "229684",
        "Last Price Paid": "$9.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090607",
        "Product Status": "Active"
    },
    {
        "Root": "10040829",
        "LV": "1",
        "Item Description": "DECOR CHOC MINNIE HAT BOW 2IN DOME RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW32324",
        "Last Price Paid": "$1.25",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040829",
        "Product Status": "Active"
    },
    {
        "Root": "10000361",
        "LV": "1",
        "Item Description": "PEPPERS RED DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-001-12-A",
        "Last Price Paid": "$17.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000361",
        "Product Status": "Active"
    },
    {
        "Root": "10107498",
        "LV": "1",
        "Item Description": "BEER NORTH COAST PRANQSTER 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "3839",
        "Last Price Paid": "$249.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107498",
        "Product Status": "Active"
    },
    {
        "Root": "10086522",
        "LV": "1",
        "Item Description": "COFFEE JOFFREYS PERU ALTO INRM RG GD EA/150CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086522001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.54",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086522",
        "Product Status": "Active"
    },
    {
        "Root": "10003142",
        "LV": "1",
        "Item Description": "SPICE ANCHO SWT RUB MARN 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003142",
        "Product Status": "Active"
    },
    {
        "Root": "10010006",
        "LV": "2",
        "Item Description": "DRAFT HURRICANE 1/6BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010006001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30306",
        "Last Price Paid": "$81.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010006",
        "Product Status": "Active"
    },
    {
        "Root": "10037237",
        "LV": "1",
        "Item Description": "FRUIT PECTIN NH 500G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037237001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6721550000",
        "Last Price Paid": "$34.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037237",
        "Product Status": "Active"
    },
    {
        "Root": "10026258",
        "LV": "1",
        "Item Description": "PEPPERCORN SZECHUAN 8 OZ JANSAL VALLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "145870",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026258",
        "Product Status": "Active"
    },
    {
        "Root": "10004031",
        "LV": "1",
        "Item Description": "MARGARINE EARTH BALANCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "01174",
        "Last Price Paid": "$4.15",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004031",
        "Product Status": "Active"
    },
    {
        "Root": "10002088",
        "LV": "1",
        "Item Description": "TOFU EXTRA FIRM NASOYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "50065",
        "Last Price Paid": "$5.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002088",
        "Product Status": "Active"
    },
    {
        "Root": "10002088",
        "LV": "1",
        "Item Description": "TOFU EXTRA FIRM NASOYA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401011",
        "Last Price Paid": "$5.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002088",
        "Product Status": "Active"
    },
    {
        "Root": "10010685",
        "LV": "2",
        "Item Description": "BRANDY APPLE JK LAIRD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010685001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "112111",
        "Last Price Paid": "$15.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010685",
        "Product Status": "Active"
    },
    {
        "Root": "10010558",
        "LV": "2",
        "Item Description": "LA VIEILLE ROUGE FERME CDV 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010558001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2271542",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010558",
        "Product Status": "Active"
    },
    {
        "Root": "10100488",
        "LV": "1",
        "Item Description": "CANDY SD YUMEARTH GUMMY BEARS 2.5OZ BAG 6X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1552",
        "Last Price Paid": "$1.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100488",
        "Product Status": "Active"
    },
    {
        "Root": "10109628",
        "LV": "1",
        "Item Description": "JUICE PG APPLE 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "32187",
        "Last Price Paid": "$4.93",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109628",
        "Product Status": "Active"
    },
    {
        "Root": "10002721",
        "LV": "1",
        "Item Description": "CEREAL SPECIAL K BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002721001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002721",
        "Product Status": "Active"
    },
    {
        "Root": "10040096",
        "LV": "1",
        "Item Description": "PIZZA 5 CHEESE TOMBSTONE ORIGINAL 12IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040096",
        "Product Status": "Active"
    },
    {
        "Root": "10005632",
        "LV": "1",
        "Item Description": "BREAD ROLL CIABATTA SQUARE 4 IN X 4 IN 3.5 OZ 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005632",
        "Product Status": "Active"
    },
    {
        "Root": "10009825",
        "LV": "2",
        "Item Description": "RUM BACARDI GOLD RSV OCHO 8 YR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009825001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "893641",
        "Last Price Paid": "$27.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009825",
        "Product Status": "Active"
    },
    {
        "Root": "10012363",
        "LV": "1",
        "Item Description": "RED GROUPER FILLET SKIN ON, WILD SUSTAINABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012363001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6497490",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012363",
        "Product Status": "Active"
    },
    {
        "Root": "10012363",
        "LV": "1",
        "Item Description": "RED GROUPER FILLET SKIN ON, WILD SUSTAINABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012363001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00741",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012363",
        "Product Status": "Active"
    },
    {
        "Root": "10014827",
        "LV": "1",
        "Item Description": "ASIAN BLEND PETITE AKA CA GRILL ASIAN BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04PAB-33",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014827",
        "Product Status": "Active"
    },
    {
        "Root": "10005276",
        "LV": "1",
        "Item Description": "JELLO ASST CITRUS 24Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7370323",
        "Last Price Paid": "$3.82",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005276",
        "Product Status": "Active"
    },
    {
        "Root": "10010080",
        "LV": "1",
        "Item Description": "BEER MODELO ESP 12Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010080",
        "Product Status": "Active"
    },
    {
        "Root": "10101889",
        "LV": "1",
        "Item Description": "MEATBALL FONTANINI ALLNATURALGLUTENFREE 1OZ 5/3 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.47",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101889",
        "Product Status": "Active"
    },
    {
        "Root": "10000860",
        "LV": "1",
        "Item Description": "DUCK FAT RENDERED 8 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4303131",
        "Last Price Paid": "$49.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000860",
        "Product Status": "Active"
    },
    {
        "Root": "10039717",
        "LV": "1",
        "Item Description": "PISTACHIOS SICILIAN 5.3 OZ F&W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "NUT015",
        "Last Price Paid": "$32.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039717",
        "Product Status": "Active"
    },
    {
        "Root": "10003940",
        "LV": "1",
        "Item Description": "HORS POPPER CHEDDAR JALAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7042020",
        "Last Price Paid": "$13.82",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003940",
        "Product Status": "Active"
    },
    {
        "Root": "10033487",
        "LV": "1",
        "Item Description": "SPRAY ORANGE COCOA BUTTER VELVET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033487001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586004",
        "Last Price Paid": "$51.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033487",
        "Product Status": "Active"
    },
    {
        "Root": "10002901",
        "LV": "1",
        "Item Description": "TOMATO STRIPS PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2019594",
        "Last Price Paid": "$5.91",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002901",
        "Product Status": "Active"
    },
    {
        "Root": "10002901",
        "LV": "1",
        "Item Description": "TOMATO STRIPS PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.91",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002901",
        "Product Status": "Active"
    },
    {
        "Root": "10002990",
        "LV": "1",
        "Item Description": "DRESSING RANCH FF 60CT 1.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.18",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002990",
        "Product Status": "Active"
    },
    {
        "Root": "10035130",
        "LV": "1",
        "Item Description": "SYRUP MONIN MINT CONCENTRATE 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C188FP",
        "Last Price Paid": "$8.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035130",
        "Product Status": "Active"
    },
    {
        "Root": "10006290",
        "LV": "1",
        "Item Description": "DISH MINI GEOMETRIC 1.75Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000003406",
        "Vendor": "EDWARD DON & CO",
        "Vendor Item Nb": "2C2314",
        "Last Price Paid": "$0.15",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006290",
        "Product Status": "Active"
    },
    {
        "Root": "10110137",
        "LV": "1",
        "Item Description": "BLACK COCOA POWDER DUTCH PROCESS 2KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110137001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6790030000",
        "Last Price Paid": "$54.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110137",
        "Product Status": "Active"
    },
    {
        "Root": "10005260",
        "LV": "1",
        "Item Description": "SILKSCREEN CASTLE SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "208808 - KLM",
        "Last Price Paid": "$75.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005260",
        "Product Status": "Active"
    },
    {
        "Root": "10034537",
        "LV": "1",
        "Item Description": "SHORTENING CAKE & ICING BAKING SOYBEAN COTTONSEED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1250860",
        "Last Price Paid": "$89.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034537",
        "Product Status": "Active"
    },
    {
        "Root": "10032614",
        "LV": "1",
        "Item Description": "TEA MASALA CHAI BLACK 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "T7500-16T",
        "Last Price Paid": "$23.36",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032614",
        "Product Status": "Active"
    },
    {
        "Root": "10013469",
        "LV": "2",
        "Item Description": "TORTA OLIVE OIL FLATBREAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013469002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "4847",
        "Last Price Paid": "$0.73",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013469",
        "Product Status": "Active"
    },
    {
        "Root": "10018052",
        "LV": "1",
        "Item Description": "TEMPRANILLO ALTOCEDRO AC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "181860",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018052",
        "Product Status": "Active"
    },
    {
        "Root": "10032479",
        "LV": "1",
        "Item Description": "POWDER LUSTER GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860350000",
        "Last Price Paid": "$28.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032479",
        "Product Status": "Active"
    },
    {
        "Root": "10002417",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR MILD YELLOW 10 LB PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002417001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5210",
        "Last Price Paid": "$3.81",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002417",
        "Product Status": "Active"
    },
    {
        "Root": "10004480",
        "LV": "1",
        "Item Description": "SUSHI SASHIMI YELLOWTAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004480",
        "Product Status": "Active"
    },
    {
        "Root": "10010086",
        "LV": "1",
        "Item Description": "BEER BTL GLS SCHOFFERHOFFER HEFE 11.2OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010086001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.56",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010086",
        "Product Status": "Active"
    },
    {
        "Root": "10040009",
        "LV": "1",
        "Item Description": "SYRUP MONIN CUCUMBER CONCENTRATE 375ML 4/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C095FP",
        "Last Price Paid": "$8.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040009",
        "Product Status": "Active"
    },
    {
        "Root": "10030126",
        "LV": "1",
        "Item Description": "BAKING MIX ALL PURPOSE CAMBOOKE 2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10301",
        "Last Price Paid": "$31.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030126",
        "Product Status": "Active"
    },
    {
        "Root": "10002380",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR MEDIUM CUBE 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30736",
        "Last Price Paid": "$16.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002380",
        "Product Status": "Active"
    },
    {
        "Root": "10002380",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR MEDIUM CUBE 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "31195",
        "Last Price Paid": "$16.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002380",
        "Product Status": "Active"
    },
    {
        "Root": "10012949",
        "LV": "1",
        "Item Description": "LA CREMA ROSE MONTEREY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587166",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012949",
        "Product Status": "Active"
    },
    {
        "Root": "10034412",
        "LV": "2",
        "Item Description": "MONASTRELL JUAN GIL SILVER LABEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034412002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "503198",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034412",
        "Product Status": "Active"
    },
    {
        "Root": "10004518",
        "LV": "1",
        "Item Description": "GELATIN SHEET SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA0400",
        "Last Price Paid": "$0.09",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004518",
        "Product Status": "Active"
    },
    {
        "Root": "10038255",
        "LV": "1",
        "Item Description": "GUMPASTE BOWS PRINTED/SOLID BRIGHT PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "20938",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038255",
        "Product Status": "Active"
    },
    {
        "Root": "10110271",
        "LV": "1",
        "Item Description": ".",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110271001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110271",
        "Product Status": "Active"
    },
    {
        "Root": "10035890",
        "LV": "1",
        "Item Description": "BLEND EL NIDO NIDO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "133765",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035890",
        "Product Status": "Active"
    },
    {
        "Root": "10018738",
        "LV": "1",
        "Item Description": "HEADSTONE JACK SKELL 2D",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6230210000",
        "Last Price Paid": "",
        "Case Pack": "480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018738",
        "Product Status": "Active"
    },
    {
        "Root": "10000885",
        "LV": "1",
        "Item Description": "DUCK BREAST SMOKED MAGRET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000885001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000885",
        "Product Status": "Active"
    },
    {
        "Root": "10104030",
        "LV": "1",
        "Item Description": "SPIRIT RUM CRUZAN MANGO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104030",
        "Product Status": "Active"
    },
    {
        "Root": "10008028",
        "LV": "2",
        "Item Description": "DSRT VALCKEN DORNFLDR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008028001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008028",
        "Product Status": "Active"
    },
    {
        "Root": "10031960",
        "LV": "2",
        "Item Description": "SPECK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031960002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "10.6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10031960",
        "Product Status": "Active"
    },
    {
        "Root": "10107866",
        "LV": "1",
        "Item Description": "BOWL TIKI GROG GROTTO NAUTILUS ED4 GREEN 36OZ 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107866",
        "Product Status": "Active"
    },
    {
        "Root": "10037241",
        "LV": "1",
        "Item Description": "TEMPRANILLO VINA REAL ROSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037241001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "433507",
        "Last Price Paid": "$11.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037241",
        "Product Status": "Active"
    },
    {
        "Root": "10024655",
        "LV": "1",
        "Item Description": "SHRIMP 16/20 P&D CKD T/ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024655001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6302500",
        "Last Price Paid": "$8.85",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024655",
        "Product Status": "Active"
    },
    {
        "Root": "10033485",
        "LV": "1",
        "Item Description": "LAPOSTOLLE PETIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "849806",
        "Last Price Paid": "$19.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033485",
        "Product Status": "Active"
    },
    {
        "Root": "10040036",
        "LV": "1",
        "Item Description": "POPCORN CARAMEL HOLIDAY PACKAGED DHS ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "HOLIDAYFESTIVE",
        "Last Price Paid": "$3.30",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040036",
        "Product Status": "Active"
    },
    {
        "Root": "10038276",
        "LV": "1",
        "Item Description": "BUCKET POPCORN STARWARS BASE 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.05",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038276",
        "Product Status": "Active"
    },
    {
        "Root": "10093091",
        "LV": "1",
        "Item Description": "TRAY 12OZ BLACK CPET, OVENABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303826",
        "Last Price Paid": "$0.25",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093091",
        "Product Status": "Active"
    },
    {
        "Root": "10105134",
        "LV": "1",
        "Item Description": "ODYSSEY BLEND 5LB 1-EA - US ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011120892",
        "Last Price Paid": "$59.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105134",
        "Product Status": "Active"
    },
    {
        "Root": "10009363",
        "LV": "2",
        "Item Description": "CHARD DE WETSHOFF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009363001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "762638",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009363",
        "Product Status": "Active"
    },
    {
        "Root": "10108735",
        "LV": "1",
        "Item Description": "BEER CAN LABATT BLUE 12OZ 12PK 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11473",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108735",
        "Product Status": "Active"
    },
    {
        "Root": "10004889",
        "LV": "1",
        "Item Description": "FLOUR SEMOLINA 12.7% PASTA GOLD MEDAL 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3051265",
        "Last Price Paid": "$30.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004889",
        "Product Status": "Active"
    },
    {
        "Root": "10015908",
        "LV": "1",
        "Item Description": "CHIC BRST AIRLINE 8-10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015908001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CX012",
        "Last Price Paid": "$6.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015908",
        "Product Status": "Active"
    },
    {
        "Root": "10021672",
        "LV": "1",
        "Item Description": "MUSHROOM ASSORTED SLICED TEMP SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2447388",
        "Last Price Paid": "$7.00",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021672",
        "Product Status": "Active"
    },
    {
        "Root": "10005265",
        "LV": "1",
        "Item Description": "SAUCE HOT DATL DO IT 4/1G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "5905120",
        "Last Price Paid": "$32.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005265",
        "Product Status": "Active"
    },
    {
        "Root": "10030668",
        "LV": "1",
        "Item Description": "TEA  HONEY VANILLA 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "T9800-16T",
        "Last Price Paid": "$14.37",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030668",
        "Product Status": "Active"
    },
    {
        "Root": "10021382",
        "LV": "1",
        "Item Description": "SYRUP FANTA STRAWBERRY 5 GAL BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "51977",
        "Last Price Paid": "$91.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021382",
        "Product Status": "Active"
    },
    {
        "Root": "10001103",
        "LV": "1",
        "Item Description": "SHRIMP COLD WATER 150/250 SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001103001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001103",
        "Product Status": "Active"
    },
    {
        "Root": "10001103",
        "LV": "1",
        "Item Description": "SHRIMP COLD WATER 150/250 SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001103001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125150",
        "Last Price Paid": "$6.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001103",
        "Product Status": "Active"
    },
    {
        "Root": "10001103",
        "LV": "1",
        "Item Description": "SHRIMP COLD WATER 150/250 SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001103001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "73-15000",
        "Last Price Paid": "$6.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001103",
        "Product Status": "Active"
    },
    {
        "Root": "10010496",
        "LV": "1",
        "Item Description": "VLKNBRG MDNA AUSLESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388254",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010496",
        "Product Status": "Active"
    },
    {
        "Root": "10000751",
        "LV": "1",
        "Item Description": "LAMB LOIN BNLS SLIVERED SKN OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000751001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1506300",
        "Last Price Paid": "$13.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000751",
        "Product Status": "Active"
    },
    {
        "Root": "10104939",
        "LV": "1",
        "Item Description": "MUG TIKI KRAKEN WDW C33 16OZ 16/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.18",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104939",
        "Product Status": "Active"
    },
    {
        "Root": "10005005",
        "LV": "2",
        "Item Description": "CANDY RAISIN YOGURT 24/4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005005002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203026",
        "Last Price Paid": "$1.36",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005005",
        "Product Status": "Active"
    },
    {
        "Root": "10014030",
        "LV": "1",
        "Item Description": "PAD CHOC SOFT CELL 9CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51149002",
        "Last Price Paid": "$0.14",
        "Case Pack": "1250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014030",
        "Product Status": "Active"
    },
    {
        "Root": "10001945",
        "LV": "1",
        "Item Description": "MUSHROOM MIX FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001945001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50040-A",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001945",
        "Product Status": "Active"
    },
    {
        "Root": "10010197",
        "LV": "2",
        "Item Description": "DRAFT BLUE MOON 1/6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010197001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10194",
        "Last Price Paid": "$75.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010197",
        "Product Status": "Active"
    },
    {
        "Root": "10018032",
        "LV": "1",
        "Item Description": "ZOLO BLACK PETIT VERDOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16764",
        "Last Price Paid": "$26.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018032",
        "Product Status": "Active"
    },
    {
        "Root": "10007086",
        "LV": "2",
        "Item Description": "CAB SAUV FAUST NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007086001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "588995",
        "Last Price Paid": "$33.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007086",
        "Product Status": "Active"
    },
    {
        "Root": "10002662",
        "LV": "1",
        "Item Description": "BASE VEGETABLE MIREPOIX 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.40",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002662",
        "Product Status": "Active"
    },
    {
        "Root": "10001874",
        "LV": "2",
        "Item Description": "WATERMELON SEEDLESS 1 / 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001874002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "MELWMS",
        "Last Price Paid": "$9.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001874",
        "Product Status": "Active"
    },
    {
        "Root": "10001874",
        "LV": "2",
        "Item Description": "WATERMELON SEEDLESS 1 / 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001874002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "849091",
        "Last Price Paid": "$9.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001874",
        "Product Status": "Active"
    },
    {
        "Root": "10001874",
        "LV": "2",
        "Item Description": "WATERMELON SEEDLESS 1 / 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001874002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "15103",
        "Last Price Paid": "$9.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001874",
        "Product Status": "Active"
    },
    {
        "Root": "10002914",
        "LV": "1",
        "Item Description": "JUICE PINEAPPLE 46Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.59",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002914",
        "Product Status": "Active"
    },
    {
        "Root": "10001934",
        "LV": "3",
        "Item Description": "ONION CIPPOLINI PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001934001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10001934",
        "Product Status": "Active"
    },
    {
        "Root": "10009568",
        "LV": "1",
        "Item Description": "GIN BEEFEATERS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "962220",
        "Last Price Paid": "$20.53",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009568",
        "Product Status": "Active"
    },
    {
        "Root": "10009568",
        "LV": "1",
        "Item Description": "GIN BEEFEATERS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9781",
        "Last Price Paid": "$20.53",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009568",
        "Product Status": "Active"
    },
    {
        "Root": "10004328",
        "LV": "1",
        "Item Description": "HORS CHIC EMPANADA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300412",
        "Last Price Paid": "$0.88",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004328",
        "Product Status": "Active"
    },
    {
        "Root": "10000995",
        "LV": "1",
        "Item Description": "TILE GLDN FILLET SKIN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000995001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000995",
        "Product Status": "Active"
    },
    {
        "Root": "10000995",
        "LV": "1",
        "Item Description": "TILE GLDN FILLET SKIN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000995001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000995",
        "Product Status": "Active"
    },
    {
        "Root": "10000995",
        "LV": "1",
        "Item Description": "TILE GLDN FILLET SKIN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000995001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "8607129",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000995",
        "Product Status": "Active"
    },
    {
        "Root": "10000995",
        "LV": "1",
        "Item Description": "TILE GLDN FILLET SKIN ON SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000995001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-01038",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000995",
        "Product Status": "Active"
    },
    {
        "Root": "10011012",
        "LV": "1",
        "Item Description": "SB TODDY FILTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11048319",
        "Last Price Paid": "$51.92",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011012",
        "Product Status": "Active"
    },
    {
        "Root": "10002650",
        "LV": "2",
        "Item Description": "SPICE CUMIN SEED GROUND 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002650002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00304-3",
        "Last Price Paid": "$9.56",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002650",
        "Product Status": "Active"
    },
    {
        "Root": "10002650",
        "LV": "2",
        "Item Description": "SPICE CUMIN SEED GROUND 4/4 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002650002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.56",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002650",
        "Product Status": "Active"
    },
    {
        "Root": "10003921",
        "LV": "1",
        "Item Description": "RICE YLW PRBLD 4% BRKN 6/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4253142",
        "Last Price Paid": "$3.37",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003921",
        "Product Status": "Active"
    },
    {
        "Root": "10000266",
        "LV": "1",
        "Item Description": "ORANGE SEGMENTS 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "211113",
        "Last Price Paid": "$39.23",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000266",
        "Product Status": "Active"
    },
    {
        "Root": "10076231",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE SPRIAL DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I77051",
        "Last Price Paid": "$11.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076231",
        "Product Status": "Active"
    },
    {
        "Root": "10016462",
        "LV": "1",
        "Item Description": "BREAD ROLL PARKER HOUSE PLAIN 80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "13:PX",
        "Last Price Paid": "$0.12",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016462",
        "Product Status": "Active"
    },
    {
        "Root": "10002930",
        "LV": "1",
        "Item Description": "DRESSING RANCH 60CT 1.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.19",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002930",
        "Product Status": "Active"
    },
    {
        "Root": "10037336",
        "LV": "1",
        "Item Description": "GLOVES POWDER-FREE VINYL GENERAL PURPOSE  SIZE SM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.36",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037336",
        "Product Status": "Active"
    },
    {
        "Root": "10010838",
        "LV": "1",
        "Item Description": "BEER BUD LT ALUM 16OZ 24E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010838",
        "Product Status": "Active"
    },
    {
        "Root": "10016360",
        "LV": "1",
        "Item Description": "LINER FRY CUP GREASE REST. 8X8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659013",
        "Last Price Paid": "$0.02",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016360",
        "Product Status": "Active"
    },
    {
        "Root": "10002661",
        "LV": "2",
        "Item Description": "BASE CHICKEN NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002661002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5867676",
        "Last Price Paid": "$3.26",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002661",
        "Product Status": "Active"
    },
    {
        "Root": "10002661",
        "LV": "2",
        "Item Description": "BASE CHICKEN NO MSG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002661002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.26",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002661",
        "Product Status": "Active"
    },
    {
        "Root": "10004702",
        "LV": "1",
        "Item Description": "CANDY HARD ASST RODS 20# - 90CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004702001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "554365",
        "Last Price Paid": "$76.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004702",
        "Product Status": "Active"
    },
    {
        "Root": "10002885",
        "LV": "2",
        "Item Description": "SPRITE CAN 12OZ/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002885002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "100287",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002885",
        "Product Status": "Active"
    },
    {
        "Root": "10037278",
        "LV": "1",
        "Item Description": "BEEF RIBEYE SHAVED 4MM THICK FZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037278001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190176",
        "Last Price Paid": "$6.31",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037278",
        "Product Status": "Active"
    },
    {
        "Root": "10083345",
        "LV": "1",
        "Item Description": "CONE ICE CREAM WAFFLE SMALL #87 BULK 16/17EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6460753",
        "Last Price Paid": "$0.30",
        "Case Pack": "272",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083345",
        "Product Status": "Active"
    },
    {
        "Root": "10036676",
        "LV": "1",
        "Item Description": "COLOR SPRAY LUSTER GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860180000",
        "Last Price Paid": "$30.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036676",
        "Product Status": "Active"
    },
    {
        "Root": "10002106",
        "LV": "2",
        "Item Description": "HERB THYME FRZ CHPD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002106002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002106",
        "Product Status": "Active"
    },
    {
        "Root": "10034223",
        "LV": "1",
        "Item Description": "CUP CLEAR PET 22OZ ACR22",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034223",
        "Product Status": "Active"
    },
    {
        "Root": "10002250",
        "LV": "1",
        "Item Description": "GELATO CUP MANGO NSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "NSA-005C",
        "Last Price Paid": "$1.39",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002250",
        "Product Status": "Active"
    },
    {
        "Root": "10012003",
        "LV": "3",
        "Item Description": "BAR MIXER HIBISCUS FLOWERS IN SYRUP 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012003003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "800855",
        "Last Price Paid": "$8.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10012003",
        "Product Status": "Active"
    },
    {
        "Root": "10001487",
        "LV": "1",
        "Item Description": "CARROT STICK 2.5X3/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001487001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "912002",
        "Last Price Paid": "$20.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001487",
        "Product Status": "Active"
    },
    {
        "Root": "10004123",
        "LV": "1",
        "Item Description": "VINEGAR RED WINE AGED 5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004123001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7198021",
        "Last Price Paid": "$89.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004123",
        "Product Status": "Active"
    },
    {
        "Root": "10014833",
        "LV": "1",
        "Item Description": "SPINACH PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SPIP-33",
        "Last Price Paid": "$28.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014833",
        "Product Status": "Active"
    },
    {
        "Root": "10001163",
        "LV": "3",
        "Item Description": "SALMON PASTRAMI SLCD FSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001163003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.00",
        "Case Pack": "52",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10001163",
        "Product Status": "Active"
    },
    {
        "Root": "10006445",
        "LV": "1",
        "Item Description": "PAN HOTEL HALF 4 INCH DEEP AL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9984774",
        "Last Price Paid": "$5.04",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006445",
        "Product Status": "Active"
    },
    {
        "Root": "10006056",
        "LV": "1",
        "Item Description": "GOBLET LITE UP BOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.57",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006056",
        "Product Status": "Active"
    },
    {
        "Root": "10035871",
        "LV": "1",
        "Item Description": "CHERRIES CAN LUXARDO 3KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "286892",
        "Last Price Paid": "$62.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035871",
        "Product Status": "Active"
    },
    {
        "Root": "10040443",
        "LV": "1",
        "Item Description": "SB SYRUP HONEY BLEND 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11112443",
        "Last Price Paid": "$17.87",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040443",
        "Product Status": "Active"
    },
    {
        "Root": "10108671",
        "LV": "2",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108671001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10108671",
        "Product Status": "Active"
    },
    {
        "Root": "10004852",
        "LV": "2",
        "Item Description": "MIX, BATTER PKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004852002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004852",
        "Product Status": "Active"
    },
    {
        "Root": "10016524",
        "LV": "2",
        "Item Description": "BREAD TEXAS TOAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016524001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016524",
        "Product Status": "Active"
    },
    {
        "Root": "10015345",
        "LV": "1",
        "Item Description": "DEC ROSE BRIAR SMALL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BR13 WH",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015345",
        "Product Status": "Active"
    },
    {
        "Root": "10018755",
        "LV": "1",
        "Item Description": "SERVICE FEE SPLIT CASE CHARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018755",
        "Product Status": "Active"
    },
    {
        "Root": "10035339",
        "LV": "1",
        "Item Description": "MATZO BALL COOKED FROZEN 180 3.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3074307",
        "Last Price Paid": "",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035339",
        "Product Status": "Active"
    },
    {
        "Root": "10032148",
        "LV": "1",
        "Item Description": "QUINOA ANCIENT GRAIN MEDLEY 2-5 POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5990704",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032148",
        "Product Status": "Active"
    },
    {
        "Root": "10034449",
        "LV": "1",
        "Item Description": "GLERA AMOR DI AMANTI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034449",
        "Product Status": "Active"
    },
    {
        "Root": "10043305",
        "LV": "1",
        "Item Description": "CIDER ORIGINAL SIN BLACK WIDOW 12/24 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "4631",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043305",
        "Product Status": "Active"
    },
    {
        "Root": "10042383",
        "LV": "1",
        "Item Description": "WINE WHITE CHARD LONGEVITY CALIF/LIVERMORE 12/750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "102495",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042383",
        "Product Status": "Active"
    },
    {
        "Root": "10004506",
        "LV": "1",
        "Item Description": "TOPPING RASPBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C60662 CARMA",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004506",
        "Product Status": "Active"
    },
    {
        "Root": "10040279",
        "LV": "1",
        "Item Description": "PINOT GRIGIO ARANCIO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561495",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040279",
        "Product Status": "Active"
    },
    {
        "Root": "10084834",
        "LV": "1",
        "Item Description": "BEEF BRESAOLA PRINCIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084834001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "515058",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084834",
        "Product Status": "Active"
    },
    {
        "Root": "10091574",
        "LV": "1",
        "Item Description": "VODKA  PEACH SKYY INFUSED 1.0L/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "993067",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091574",
        "Product Status": "Active"
    },
    {
        "Root": "10017215",
        "LV": "1",
        "Item Description": "CAKE SPONGE10 INCH YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017215",
        "Product Status": "Active"
    },
    {
        "Root": "10018793",
        "LV": "2",
        "Item Description": "DECOR DISCO SPARKLES YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018793002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11127",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018793",
        "Product Status": "Active"
    },
    {
        "Root": "10109650",
        "LV": "1",
        "Item Description": "BAG PAPER KRAFT TIN TIE W/WINDOW 1/2LB 3.5X7.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "43312LBCBWKR",
        "Last Price Paid": "$0.11",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109650",
        "Product Status": "Active"
    },
    {
        "Root": "10001269",
        "LV": "1",
        "Item Description": "PICKLE SAND STRIP 3/16 INCH SM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001269",
        "Product Status": "Active"
    },
    {
        "Root": "10039996",
        "LV": "1",
        "Item Description": "TEMPRANILLO TRITON TINTO DE TORO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001125",
        "Last Price Paid": "$13.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039996",
        "Product Status": "Active"
    },
    {
        "Root": "10012940",
        "LV": "1",
        "Item Description": "LAMB RACK CHOP 24-26OZ AUS HALAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012940001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1590400",
        "Last Price Paid": "$10.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012940",
        "Product Status": "Active"
    },
    {
        "Root": "10015969",
        "LV": "1",
        "Item Description": "APPLESAUCE GOGO SQUEEZ 3.2OZ 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015969",
        "Product Status": "Active"
    },
    {
        "Root": "10001496",
        "LV": "1",
        "Item Description": "LEAVE SHISO 3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "790430",
        "Last Price Paid": "$18.75",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001496",
        "Product Status": "Active"
    },
    {
        "Root": "10039864",
        "LV": "2",
        "Item Description": "COOKIE GINGERBREAD MAN 72/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039864002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "40509051",
        "Last Price Paid": "$2.36",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039864",
        "Product Status": "Active"
    },
    {
        "Root": "10104602",
        "LV": "1",
        "Item Description": "PASSOVER BSC ASSORTED MACAROONS 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104602",
        "Product Status": "Active"
    },
    {
        "Root": "10019728",
        "LV": "1",
        "Item Description": "SALT INSTACURE #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SAL301",
        "Last Price Paid": "$20.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019728",
        "Product Status": "Active"
    },
    {
        "Root": "10018940",
        "LV": "1",
        "Item Description": "AIOLI ROASTED GARLIC 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$178.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018940",
        "Product Status": "Active"
    },
    {
        "Root": "10016526",
        "LV": "1",
        "Item Description": "BREAD FRENCH WHT PULLMAN LENGTHWISE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "2412D",
        "Last Price Paid": "$2.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016526",
        "Product Status": "Active"
    },
    {
        "Root": "10044460",
        "LV": "1",
        "Item Description": "BREADING JACKS 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$62.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044460",
        "Product Status": "Active"
    },
    {
        "Root": "10002620",
        "LV": "1",
        "Item Description": "CHEESE SEMI HARD PORT SALUTE ST PAULIN FRANCE 5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002620001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1390",
        "Last Price Paid": "$10.70",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002620",
        "Product Status": "Active"
    },
    {
        "Root": "10013725",
        "LV": "1",
        "Item Description": "SHRIMP 8/12 P&D T/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013725001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "74-08100",
        "Last Price Paid": "$8.65",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013725",
        "Product Status": "Active"
    },
    {
        "Root": "10013725",
        "LV": "1",
        "Item Description": "SHRIMP 8/12 P&D T/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013725001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6128499",
        "Last Price Paid": "$8.65",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013725",
        "Product Status": "Active"
    },
    {
        "Root": "10000242",
        "LV": "1",
        "Item Description": "PAPAYA DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000242001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-016-12",
        "Last Price Paid": "$19.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000242",
        "Product Status": "Active"
    },
    {
        "Root": "10000242",
        "LV": "1",
        "Item Description": "PAPAYA DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000242001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "599300",
        "Last Price Paid": "$19.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000242",
        "Product Status": "Active"
    },
    {
        "Root": "10012782",
        "LV": "1",
        "Item Description": "CHATEAU D'ESCLANS WHISPERING ANGEL ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "568603",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012782",
        "Product Status": "Active"
    },
    {
        "Root": "10105128",
        "LV": "1",
        "Item Description": "SHRIMP TEMPURA 21-25CT FZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3850142",
        "Last Price Paid": "$52.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105128",
        "Product Status": "Active"
    },
    {
        "Root": "10037143",
        "LV": "1",
        "Item Description": "SALSA PICO DE GALLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "55-010",
        "Last Price Paid": "$58.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037143",
        "Product Status": "Active"
    },
    {
        "Root": "10001934",
        "LV": "1",
        "Item Description": "ONION CIPPOLINI PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ONICHIPPLG",
        "Last Price Paid": "$84.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001934",
        "Product Status": "Active"
    },
    {
        "Root": "10001934",
        "LV": "1",
        "Item Description": "ONION CIPPOLINI PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "881181",
        "Last Price Paid": "$84.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001934",
        "Product Status": "Active"
    },
    {
        "Root": "10033725",
        "LV": "1",
        "Item Description": "NUT PECAN MEDIUM PIECES 30LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "140200",
        "Last Price Paid": "$250.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033725",
        "Product Status": "Active"
    },
    {
        "Root": "10005201",
        "LV": "1",
        "Item Description": "SHEET TRANSFER ZEBRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005201001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1007804",
        "Last Price Paid": "$2.14",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005201",
        "Product Status": "Active"
    },
    {
        "Root": "10091306",
        "LV": "1",
        "Item Description": "WINE RTD RAMONA SPRITZ DRY GRAPEFRUIT 250ML/48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9625537",
        "Last Price Paid": "$2.69",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091306",
        "Product Status": "Active"
    },
    {
        "Root": "10100496",
        "LV": "2",
        "Item Description": "CONTAINER MEDIUM CLAMSHELL UTILITY 1 CAVITY 8\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100496002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.27",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100496",
        "Product Status": "Active"
    },
    {
        "Root": "10023856",
        "LV": "1",
        "Item Description": "MICKEY HAT W EARS MINI 1 1/2 X 7/8 WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3185W",
        "Last Price Paid": "$0.80",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023856",
        "Product Status": "Active"
    },
    {
        "Root": "10035888",
        "LV": "1",
        "Item Description": "PUREE REAL BLACKBERRY16.9OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9291909",
        "Last Price Paid": "$4.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035888",
        "Product Status": "Active"
    },
    {
        "Root": "10089197",
        "LV": "2",
        "Item Description": "JUICE PIUROS WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089197002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450120",
        "Last Price Paid": "$8.11",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10089197",
        "Product Status": "Active"
    },
    {
        "Root": "10005261",
        "LV": "1",
        "Item Description": "MINNIE STANDING 6 INCH W/BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3228-A",
        "Last Price Paid": "$9.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005261",
        "Product Status": "Active"
    },
    {
        "Root": "10004872",
        "LV": "3",
        "Item Description": "NOODLE RICE THAI PAD 14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004872003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8059920",
        "Last Price Paid": "$2.41",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10004872",
        "Product Status": "Active"
    },
    {
        "Root": "10017968",
        "LV": "1",
        "Item Description": "RUM CRUZAN BANANA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "973274",
        "Last Price Paid": "$10.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017968",
        "Product Status": "Active"
    },
    {
        "Root": "10041043",
        "LV": "1",
        "Item Description": "CHEESE BLUE VALDEON COW GOAT SPAIN 2/6 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041043001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19168",
        "Last Price Paid": "$12.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041043",
        "Product Status": "Active"
    },
    {
        "Root": "10003040",
        "LV": "1",
        "Item Description": "SUGAR BROWN LIGHT 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003040",
        "Product Status": "Active"
    },
    {
        "Root": "10003812",
        "LV": "1",
        "Item Description": "OIL OLIVE EX VRGN LE BLNC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01000-A",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003812",
        "Product Status": "Active"
    },
    {
        "Root": "10104609",
        "LV": "1",
        "Item Description": "PASTA DRY FUSILLI 16OZ/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104609",
        "Product Status": "Active"
    },
    {
        "Root": "10029666",
        "LV": "1",
        "Item Description": "PINOT GRIS J VINEYARDS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9017182",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029666",
        "Product Status": "Active"
    },
    {
        "Root": "10004336",
        "LV": "1",
        "Item Description": "MUSTARD POMMERY GRAINY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "MT040",
        "Last Price Paid": "$13.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004336",
        "Product Status": "Active"
    },
    {
        "Root": "10004093",
        "LV": "2",
        "Item Description": "SHELL GRAHAM 1.7 INCH ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004093001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD202",
        "Last Price Paid": "$0.27",
        "Case Pack": "378",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004093",
        "Product Status": "Active"
    },
    {
        "Root": "10004712",
        "LV": "1",
        "Item Description": "CHIP POTATO BBQ 1.375Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5601943",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004712",
        "Product Status": "Active"
    },
    {
        "Root": "10078200",
        "LV": "1",
        "Item Description": "BAG W/HANDLE KRAFT 14X10X15.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10078200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091832",
        "Last Price Paid": "$0.35",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10078200",
        "Product Status": "Active"
    },
    {
        "Root": "10004887",
        "LV": "1",
        "Item Description": "CANDY TOPPING OREO LARGE CRUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004887001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302260",
        "Last Price Paid": "$142.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004887",
        "Product Status": "Active"
    },
    {
        "Root": "10042486",
        "LV": "1",
        "Item Description": "CABERNET INTERCEPT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042486001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9391169",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042486",
        "Product Status": "Active"
    },
    {
        "Root": "10006835",
        "LV": "2",
        "Item Description": "TEA DISPLAY BOX WOOD 12 U",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006835001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "R00025",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006835",
        "Product Status": "Active"
    },
    {
        "Root": "10003859",
        "LV": "1",
        "Item Description": "CEREAL COCA KRISPIE BWL 9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003859001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2006930",
        "Last Price Paid": "$0.31",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003859",
        "Product Status": "Active"
    },
    {
        "Root": "10107337",
        "LV": "1",
        "Item Description": "LA GIOIOSA SPARKLING BERRY FIZZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "981312",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107337",
        "Product Status": "Active"
    },
    {
        "Root": "10007033",
        "LV": "1",
        "Item Description": "PINOT NOIR P HOBB CRSSBRN 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "716633",
        "Last Price Paid": "$25.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007033",
        "Product Status": "Active"
    },
    {
        "Root": "10003483",
        "LV": "1",
        "Item Description": "SAUCE HOT CHILI SRIRACHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4544425",
        "Last Price Paid": "$3.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003483",
        "Product Status": "Active"
    },
    {
        "Root": "10001796",
        "LV": "1",
        "Item Description": "CAULIFLOWER PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001796001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401083",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001796",
        "Product Status": "Active"
    },
    {
        "Root": "10001796",
        "LV": "1",
        "Item Description": "CAULIFLOWER PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001796001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001796",
        "Product Status": "Active"
    },
    {
        "Root": "10002945",
        "LV": "1",
        "Item Description": "COFFEE INROOM REG 8-CUP 150CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002945001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.54",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002945",
        "Product Status": "Active"
    },
    {
        "Root": "10009977",
        "LV": "1",
        "Item Description": "LIQ CHAMBORD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009977",
        "Product Status": "Active"
    },
    {
        "Root": "10027497",
        "LV": "1",
        "Item Description": "FENNEL BLOOMS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027497001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05FENB-33",
        "Last Price Paid": "$0.10",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027497",
        "Product Status": "Active"
    },
    {
        "Root": "10002208",
        "LV": "2",
        "Item Description": "MILK HOMO 8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002208002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.25",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002208",
        "Product Status": "Active"
    },
    {
        "Root": "10003592",
        "LV": "2",
        "Item Description": "TORTILLA CORN WHITE 6 INCH 12/60 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003592002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5119913",
        "Last Price Paid": "$0.05",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003592",
        "Product Status": "Active"
    },
    {
        "Root": "10017155",
        "LV": "2",
        "Item Description": "RIESLING CLEAN SLATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017155002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "742151",
        "Last Price Paid": "$9.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10017155",
        "Product Status": "Active"
    },
    {
        "Root": "10006914",
        "LV": "2",
        "Item Description": "ICE WINE INNISKILLIN PEARL 6/375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006914002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006914",
        "Product Status": "Active"
    },
    {
        "Root": "10013140",
        "LV": "1",
        "Item Description": "TEA TWNG GOLDEN TIPPED ENGLISH BREAKFAST 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013140001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013140",
        "Product Status": "Active"
    },
    {
        "Root": "10003791",
        "LV": "1",
        "Item Description": "NUT PEANUT HONEY ROASTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "131820",
        "Last Price Paid": "$63.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003791",
        "Product Status": "Active"
    },
    {
        "Root": "10030070",
        "LV": "1",
        "Item Description": "TOMATO DICED WITH GREEN CHILIS ROTEL 12/28OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030070001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8268518",
        "Last Price Paid": "$2.43",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030070",
        "Product Status": "Active"
    },
    {
        "Root": "10003351",
        "LV": "1",
        "Item Description": "COFFEE ANCHOR REG BN 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.16",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003351",
        "Product Status": "Active"
    },
    {
        "Root": "10033464",
        "LV": "1",
        "Item Description": "STIRRER WOOD COFFEE UNWRAPPED 5.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033464001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3013877",
        "Last Price Paid": "$0.00",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033464",
        "Product Status": "Active"
    },
    {
        "Root": "10105650",
        "LV": "1",
        "Item Description": "FLAVORING, DOENJANG PASTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1692678",
        "Last Price Paid": "$4.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105650",
        "Product Status": "Active"
    },
    {
        "Root": "10027745",
        "LV": "1",
        "Item Description": "POWDER CRANBERRY FREEZE DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U30043",
        "Last Price Paid": "$16.56",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027745",
        "Product Status": "Active"
    },
    {
        "Root": "10032414",
        "LV": "1",
        "Item Description": "SB STRAWBERRY PUREE 1L 6EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11089606",
        "Last Price Paid": "$11.56",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032414",
        "Product Status": "Active"
    },
    {
        "Root": "10002371",
        "LV": "1",
        "Item Description": "CHEESE RICOTTA WHOLE MILK TUB 6/3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "316315",
        "Last Price Paid": "$7.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002371",
        "Product Status": "Active"
    },
    {
        "Root": "10018517",
        "LV": "2",
        "Item Description": "SPICE, ASIAN FIVE SPICE ALL PURPOSE BLEND 6/4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018517001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10018517",
        "Product Status": "Active"
    },
    {
        "Root": "10010883",
        "LV": "2",
        "Item Description": "TEQ JIMADOR BLANCO LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010883001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010883",
        "Product Status": "Active"
    },
    {
        "Root": "10012571",
        "LV": "1",
        "Item Description": "BEET BLUSH MIXED 20 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MXBB-33",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012571",
        "Product Status": "Active"
    },
    {
        "Root": "10005125",
        "LV": "2",
        "Item Description": "PUREE WATERMELON 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005125002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "APS0U3AA0",
        "Last Price Paid": "$15.69",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005125",
        "Product Status": "Active"
    },
    {
        "Root": "10017118",
        "LV": "1",
        "Item Description": "PEPPER JIMMY NARDELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017118001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490047",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017118",
        "Product Status": "Active"
    },
    {
        "Root": "10028246",
        "LV": "1",
        "Item Description": "WINE SIDURI PINOT NOIR WILLAMETTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "963179",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028246",
        "Product Status": "Active"
    },
    {
        "Root": "10104955",
        "LV": "1",
        "Item Description": "SHRIMP COCONUT BREADED 16/20",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5366414",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104955",
        "Product Status": "Active"
    },
    {
        "Root": "10002314",
        "LV": "1",
        "Item Description": "GELATO LEMON ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "GT-006",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002314",
        "Product Status": "Active"
    },
    {
        "Root": "10001810",
        "LV": "1",
        "Item Description": "MUSHROOM YELLOW FOOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001810001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50185",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001810",
        "Product Status": "Active"
    },
    {
        "Root": "10041597",
        "LV": "1",
        "Item Description": "COOKIE THE CHILD WHITE 2 IN SILHOUETTE GREEN/BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041597",
        "Product Status": "Active"
    },
    {
        "Root": "10019026",
        "LV": "1",
        "Item Description": "LAMB TOP ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019026001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240203",
        "Last Price Paid": "",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019026",
        "Product Status": "Active"
    },
    {
        "Root": "10034625",
        "LV": "1",
        "Item Description": "PINOT NOIR MUGNIER NSG MARECHALE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9631176",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034625",
        "Product Status": "Active"
    },
    {
        "Root": "10015302",
        "LV": "1",
        "Item Description": "HONEY TANGERINE JANS VALLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "115470",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015302",
        "Product Status": "Active"
    },
    {
        "Root": "10102548",
        "LV": "1",
        "Item Description": "CAVIAR FRENCH OSSETRA 30 GRAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "CAVOSS002",
        "Last Price Paid": "$59.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102548",
        "Product Status": "Active"
    },
    {
        "Root": "10033496",
        "LV": "1",
        "Item Description": "LUSTER SPRAY GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586027",
        "Last Price Paid": "$29.16",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033496",
        "Product Status": "Active"
    },
    {
        "Root": "10035223",
        "LV": "1",
        "Item Description": "FRUIT FC LEMON 8 WAY WEDGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "31-001-8",
        "Last Price Paid": "$16.33",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035223",
        "Product Status": "Active"
    },
    {
        "Root": "10035223",
        "LV": "1",
        "Item Description": "FRUIT FC LEMON 8 WAY WEDGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511008",
        "Last Price Paid": "$16.33",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035223",
        "Product Status": "Active"
    },
    {
        "Root": "10032806",
        "LV": "1",
        "Item Description": "BOARD CAKE 6 INCHX1/4INCH SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "E614-SILVER",
        "Last Price Paid": "$0.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032806",
        "Product Status": "Active"
    },
    {
        "Root": "10009255",
        "LV": "1",
        "Item Description": "CAB SAUV BARON HERZOG KOSHER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "71883",
        "Last Price Paid": "$8.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009255",
        "Product Status": "Active"
    },
    {
        "Root": "10014847",
        "LV": "1",
        "Item Description": "VINEGAR SHERRY 25 YEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VI038",
        "Last Price Paid": "$13.45",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014847",
        "Product Status": "Active"
    },
    {
        "Root": "10012823",
        "LV": "1",
        "Item Description": "SHELL TART GRAHAM CRACKER MINI ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8095066",
        "Last Price Paid": "$0.35",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012823",
        "Product Status": "Active"
    },
    {
        "Root": "10110228",
        "LV": "1",
        "Item Description": "LIGHTLIFE SMART BACON PLANT-BASED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "C10104",
        "Last Price Paid": "$3.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110228",
        "Product Status": "Active"
    },
    {
        "Root": "10004448",
        "LV": "1",
        "Item Description": "MIX FREESTYLE BARQ S/DIET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4051583",
        "Last Price Paid": "$64.52",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004448",
        "Product Status": "Active"
    },
    {
        "Root": "10095909",
        "LV": "1",
        "Item Description": "PUREE FROZEN, PASSION FRUIT, 5 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6555320000",
        "Last Price Paid": "$71.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095909",
        "Product Status": "Active"
    },
    {
        "Root": "10002023",
        "LV": "1",
        "Item Description": "ONION YELLOW DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002023001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-002-12",
        "Last Price Paid": "$10.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002023",
        "Product Status": "Active"
    },
    {
        "Root": "10002023",
        "LV": "1",
        "Item Description": "ONION YELLOW DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002023001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30722",
        "Last Price Paid": "$10.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002023",
        "Product Status": "Active"
    },
    {
        "Root": "10002023",
        "LV": "1",
        "Item Description": "ONION YELLOW DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002023001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "442023",
        "Last Price Paid": "$10.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002023",
        "Product Status": "Active"
    },
    {
        "Root": "10034072",
        "LV": "1",
        "Item Description": "BE-FC66EB 6X6 BAMBOO FIBER CONT HINGED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51851101",
        "Last Price Paid": "$0.15",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034072",
        "Product Status": "Active"
    },
    {
        "Root": "10014287",
        "LV": "1",
        "Item Description": "WATER PERRIER PET 16.9 SPARKLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2326510",
        "Last Price Paid": "$1.01",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014287",
        "Product Status": "Active"
    },
    {
        "Root": "10006140",
        "LV": "1",
        "Item Description": "SB WDW CUP 31Z PRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006140001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.17",
        "Case Pack": "270",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006140",
        "Product Status": "Active"
    },
    {
        "Root": "10018013",
        "LV": "1",
        "Item Description": "LIQUEUR TORRES ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27887",
        "Last Price Paid": "$21.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018013",
        "Product Status": "Active"
    },
    {
        "Root": "10086573",
        "LV": "1",
        "Item Description": "BAR MIX PASSION FRUIT 2 TO 1 CONC FZN 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3359349",
        "Last Price Paid": "$6.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086573",
        "Product Status": "Active"
    },
    {
        "Root": "10085877",
        "LV": "1",
        "Item Description": "POPCORN WEAVER KERNEL 4/12.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085877",
        "Product Status": "Active"
    },
    {
        "Root": "10084129",
        "LV": "1",
        "Item Description": "POPCORN FRUITY POP CANDY CORN 50/8 OZ BAGS 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "FRUITY POP",
        "Last Price Paid": "$3.74",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084129",
        "Product Status": "Active"
    },
    {
        "Root": "10000621",
        "LV": "1",
        "Item Description": "PORK COPPA DRY MILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000621001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3302037",
        "Last Price Paid": "$14.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000621",
        "Product Status": "Active"
    },
    {
        "Root": "10012902",
        "LV": "1",
        "Item Description": "FIG TIGER STRIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012902001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390222",
        "Last Price Paid": "$5.06",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012902",
        "Product Status": "Active"
    },
    {
        "Root": "10107292",
        "LV": "1",
        "Item Description": "CHIP TORTILLA STRP WHITE RAW 20 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107292001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2665433",
        "Last Price Paid": "$30.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107292",
        "Product Status": "Active"
    },
    {
        "Root": "10107212",
        "LV": "1",
        "Item Description": "CHOC DAIRY FREE 55.7% 1/22#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CNXT",
        "Last Price Paid": "$119.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107212",
        "Product Status": "Active"
    },
    {
        "Root": "10035658",
        "LV": "1",
        "Item Description": "MACADAMIA PIECES UNSALTED SHELL OFF DRY ROASTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7486376",
        "Last Price Paid": "$62.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035658",
        "Product Status": "Active"
    },
    {
        "Root": "10010683",
        "LV": "2",
        "Item Description": "ARMAGNAC LARRESS VSOP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010683001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "272305",
        "Last Price Paid": "$50.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010683",
        "Product Status": "Active"
    },
    {
        "Root": "10002689",
        "LV": "1",
        "Item Description": "CRACKER MEDLEY KEEBLER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2004000",
        "Last Price Paid": "$0.03",
        "Case Pack": "983",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002689",
        "Product Status": "Active"
    },
    {
        "Root": "10038021",
        "LV": "1",
        "Item Description": "COCOA BUTTER BUTTONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NOE200",
        "Last Price Paid": "$29.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038021",
        "Product Status": "Active"
    },
    {
        "Root": "10081236",
        "LV": "1",
        "Item Description": "SLIDER PRETZEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081236",
        "Product Status": "Active"
    },
    {
        "Root": "10004033",
        "LV": "1",
        "Item Description": "ICE DRY NUGGETS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004033001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004033",
        "Product Status": "Active"
    },
    {
        "Root": "10006108",
        "LV": "1",
        "Item Description": "CUP DRINK PEARL TRADER SA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.52",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006108",
        "Product Status": "Active"
    },
    {
        "Root": "10036833",
        "LV": "1",
        "Item Description": "BEEF PORTERHOUSE STEAK CC DRY AGED 28Z PRIME ANGU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036833001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2590001",
        "Last Price Paid": "$39.17",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036833",
        "Product Status": "Active"
    },
    {
        "Root": "10028766",
        "LV": "1",
        "Item Description": "WINE TENSHEN WHITE BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9189409",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028766",
        "Product Status": "Active"
    },
    {
        "Root": "10028766",
        "LV": "1",
        "Item Description": "WINE TENSHEN WHITE BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9189409",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028766",
        "Product Status": "Active"
    },
    {
        "Root": "10087508",
        "LV": "1",
        "Item Description": "BAG POPCORN DUMBO 5X3X12 4 COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51015226",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087508",
        "Product Status": "Active"
    },
    {
        "Root": "10002626",
        "LV": "1",
        "Item Description": "TURNIP WHITE PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PMT-33",
        "Last Price Paid": "$34.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002626",
        "Product Status": "Active"
    },
    {
        "Root": "10036892",
        "LV": "1",
        "Item Description": "CHEESE HARD RICOTTA SALATA ITALY 7 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036892001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19079",
        "Last Price Paid": "$4.77",
        "Case Pack": "7.08",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036892",
        "Product Status": "Active"
    },
    {
        "Root": "10009453",
        "LV": "1",
        "Item Description": "SCOTCH DEWARS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009453001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "19307",
        "Last Price Paid": "$26.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009453",
        "Product Status": "Active"
    },
    {
        "Root": "10037139",
        "LV": "1",
        "Item Description": "ROLL GF HOAGIE STANDARD BLOOMFIELD FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "14446",
        "Last Price Paid": "$1.65",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037139",
        "Product Status": "Active"
    },
    {
        "Root": "10001896",
        "LV": "1",
        "Item Description": "BEAN GREEN SNIPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510500",
        "Last Price Paid": "$10.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001896",
        "Product Status": "Active"
    },
    {
        "Root": "10001896",
        "LV": "1",
        "Item Description": "BEAN GREEN SNIPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BEANGRSN",
        "Last Price Paid": "$10.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001896",
        "Product Status": "Active"
    },
    {
        "Root": "10002787",
        "LV": "1",
        "Item Description": "JOFFREYS FRCH RST DCF 2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.76",
        "Case Pack": "128",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002787",
        "Product Status": "Active"
    },
    {
        "Root": "10007018",
        "LV": "1",
        "Item Description": "PINOT NOIR CHARMING OUAV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007018001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007018",
        "Product Status": "Active"
    },
    {
        "Root": "10029074",
        "LV": "1",
        "Item Description": "ARTICHOKE GRILLED OLIVE OIL AND HERB MARINADE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029074001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.04",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029074",
        "Product Status": "Active"
    },
    {
        "Root": "10022537",
        "LV": "1",
        "Item Description": "BITTERS RHUBARB FEE BROS 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "202197",
        "Last Price Paid": "$6.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022537",
        "Product Status": "Active"
    },
    {
        "Root": "10092230",
        "LV": "1",
        "Item Description": "COOKIES LEMON MINI MICKEY 240",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "MKLM",
        "Last Price Paid": "$0.25",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092230",
        "Product Status": "Active"
    },
    {
        "Root": "10001915",
        "LV": "1",
        "Item Description": "FLOWER VIOLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22009",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001915",
        "Product Status": "Active"
    },
    {
        "Root": "10001915",
        "LV": "1",
        "Item Description": "FLOWER VIOLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001915",
        "Product Status": "Active"
    },
    {
        "Root": "10001915",
        "LV": "1",
        "Item Description": "FLOWER VIOLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482560",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001915",
        "Product Status": "Active"
    },
    {
        "Root": "10001474",
        "LV": "2",
        "Item Description": "ONION RED JUMBO, #25 POUND BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001474001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10001474",
        "Product Status": "Active"
    },
    {
        "Root": "10102869",
        "LV": "1",
        "Item Description": "BUCKET SANDPAIL WATERPARK 32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.11",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102869",
        "Product Status": "Active"
    },
    {
        "Root": "10004988",
        "LV": "1",
        "Item Description": "CHOC WHITE CASTLE SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381425",
        "Vendor": "THEMES COME TRUE INC / IMPRESSIONS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$92.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004988",
        "Product Status": "Active"
    },
    {
        "Root": "10000374",
        "LV": "1",
        "Item Description": "LETTUCE PREMIX W/ROMAINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "24-002-2",
        "Last Price Paid": "$5.02",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000374",
        "Product Status": "Active"
    },
    {
        "Root": "10000374",
        "LV": "1",
        "Item Description": "LETTUCE PREMIX W/ROMAINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482574",
        "Last Price Paid": "$5.02",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000374",
        "Product Status": "Active"
    },
    {
        "Root": "10010668",
        "LV": "2",
        "Item Description": "RUM SIESTA KEY SPICED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010668001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9286228",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010668",
        "Product Status": "Active"
    },
    {
        "Root": "10107729",
        "LV": "1",
        "Item Description": "MIX TRAIL TREAT4U SUNSHINE BLEND 120/1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "212140",
        "Last Price Paid": "$0.47",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107729",
        "Product Status": "Active"
    },
    {
        "Root": "10031020",
        "LV": "1",
        "Item Description": "DUCK WHOLE 4.5-5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031020001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4301005",
        "Last Price Paid": "$3.58",
        "Case Pack": "27",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031020",
        "Product Status": "Active"
    },
    {
        "Root": "10036041",
        "LV": "1",
        "Item Description": "GIN MAGELLAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346706",
        "Last Price Paid": "$25.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036041",
        "Product Status": "Active"
    },
    {
        "Root": "10001907",
        "LV": "1",
        "Item Description": "FLOWER ROSE PETAL EDIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482063",
        "Last Price Paid": "$13.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001907",
        "Product Status": "Active"
    },
    {
        "Root": "10032926",
        "LV": "1",
        "Item Description": "BAT WINGS DARK W/WHITE IMAGE 2 1/2 X 1 1/4 2PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW15034",
        "Last Price Paid": "$1.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032926",
        "Product Status": "Active"
    },
    {
        "Root": "10001578",
        "LV": "1",
        "Item Description": "SPROUT BRUSSEL FANCY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001578",
        "Product Status": "Active"
    },
    {
        "Root": "10040275",
        "LV": "1",
        "Item Description": "CHIANTI CLASSICO AMA AMA 2015 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "586810",
        "Last Price Paid": "$23.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040275",
        "Product Status": "Active"
    },
    {
        "Root": "10094760",
        "LV": "1",
        "Item Description": "CAVIAR TOBIKO ORANGE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094760001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6150026",
        "Last Price Paid": "$43.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094760",
        "Product Status": "Active"
    },
    {
        "Root": "10005412",
        "LV": "1",
        "Item Description": "COFFEE YCHT STKHSE REG BLK BEAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.17",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005412",
        "Product Status": "Active"
    },
    {
        "Root": "10040414",
        "LV": "1",
        "Item Description": "CHEESE CREAM PHILY POUCH 100/1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.34",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040414",
        "Product Status": "Active"
    },
    {
        "Root": "10040414",
        "LV": "1",
        "Item Description": "CHEESE CREAM PHILY POUCH 100/1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "11205",
        "Last Price Paid": "$0.34",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040414",
        "Product Status": "Active"
    },
    {
        "Root": "10001903",
        "LV": "1",
        "Item Description": "PAPAYA MARADOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001903001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001903",
        "Product Status": "Active"
    },
    {
        "Root": "10001903",
        "LV": "1",
        "Item Description": "PAPAYA MARADOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001903001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "303016",
        "Last Price Paid": "$42.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001903",
        "Product Status": "Active"
    },
    {
        "Root": "10009725",
        "LV": "2",
        "Item Description": "TEQ LUNAZUL ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009725001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009725",
        "Product Status": "Active"
    },
    {
        "Root": "10002083",
        "LV": "1",
        "Item Description": "SQUASH CALABAZA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002083001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002083",
        "Product Status": "Active"
    },
    {
        "Root": "10000123",
        "LV": "1",
        "Item Description": "SAUSAGE SOUTH AFRICAN BOE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000123001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "259A-A",
        "Last Price Paid": "$4.36",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000123",
        "Product Status": "Active"
    },
    {
        "Root": "10001581",
        "LV": "1",
        "Item Description": "MUSHROOM CRIMINI SLCD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "26130",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001581",
        "Product Status": "Active"
    },
    {
        "Root": "10001581",
        "LV": "1",
        "Item Description": "MUSHROOM CRIMINI SLCD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001581",
        "Product Status": "Active"
    },
    {
        "Root": "10001581",
        "LV": "1",
        "Item Description": "MUSHROOM CRIMINI SLCD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405091",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001581",
        "Product Status": "Active"
    },
    {
        "Root": "10010386",
        "LV": "2",
        "Item Description": "BEER ABITA PURPLE HAZE 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010386001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14169",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010386",
        "Product Status": "Active"
    },
    {
        "Root": "10104909",
        "LV": "1",
        "Item Description": "JELLY GRAPE SQZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6404297",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104909",
        "Product Status": "Active"
    },
    {
        "Root": "10029260",
        "LV": "1",
        "Item Description": "SUSHI SASHIMI EEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029260",
        "Product Status": "Active"
    },
    {
        "Root": "10041080",
        "LV": "1",
        "Item Description": "STRAW CLIP MALEFICENT 80/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041080",
        "Product Status": "Active"
    },
    {
        "Root": "10009088",
        "LV": "2",
        "Item Description": "SYRAH AMAVI CELLARS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009088001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9031110",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009088",
        "Product Status": "Active"
    },
    {
        "Root": "10034890",
        "LV": "2",
        "Item Description": "GINGERDEER CHRISTMAS DECOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034890001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I21168",
        "Last Price Paid": "",
        "Case Pack": "126",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10034890",
        "Product Status": "Active"
    },
    {
        "Root": "10000587",
        "LV": "3",
        "Item Description": "BACON PCS CKD 3/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000587001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10000587",
        "Product Status": "Active"
    },
    {
        "Root": "10035224",
        "LV": "1",
        "Item Description": "M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035224",
        "Product Status": "Active"
    },
    {
        "Root": "10035428",
        "LV": "1",
        "Item Description": "CUP SOUVENIR VANELLOPE TROPHY FAMILY CHALLENGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035428",
        "Product Status": "Active"
    },
    {
        "Root": "10000344",
        "LV": "1",
        "Item Description": "LETTUCE BABY ICEBERG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETIB",
        "Last Price Paid": "$1.02",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000344",
        "Product Status": "Active"
    },
    {
        "Root": "10000344",
        "LV": "1",
        "Item Description": "LETTUCE BABY ICEBERG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403671",
        "Last Price Paid": "$1.02",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000344",
        "Product Status": "Active"
    },
    {
        "Root": "10084173",
        "LV": "1",
        "Item Description": "ICE CREAM BAR MICKEY BLUE RASP CONE DIP 2PK 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084173001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "K1313",
        "Last Price Paid": "$22.12",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084173",
        "Product Status": "Active"
    },
    {
        "Root": "10008234",
        "LV": "3",
        "Item Description": "CHARD HESS SELECT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008234002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1192442",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008234",
        "Product Status": "Active"
    },
    {
        "Root": "10095967",
        "LV": "1",
        "Item Description": "BAG CLEAR ZIP 4MIL LDPE  4PE48 4X8\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51014272",
        "Last Price Paid": "$0.05",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095967",
        "Product Status": "Active"
    },
    {
        "Root": "10085371",
        "LV": "1",
        "Item Description": "CUP COLD PAPER 12OZ RED/WHITE STRIPE 50TH ANIV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510268",
        "Last Price Paid": "$0.06",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085371",
        "Product Status": "Active"
    },
    {
        "Root": "10034867",
        "LV": "1",
        "Item Description": "TURKEY BURGR 30/5.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7919764",
        "Last Price Paid": "$1.25",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034867",
        "Product Status": "Active"
    },
    {
        "Root": "10007717",
        "LV": "2",
        "Item Description": "SPARK KEY LIME 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007717001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069784",
        "Vendor": "FLORIDA ORANGE GROVES INC",
        "Vendor Item Nb": "659663-67750",
        "Last Price Paid": "$14.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007717",
        "Product Status": "Active"
    },
    {
        "Root": "10007717",
        "LV": "2",
        "Item Description": "SPARK KEY LIME 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007717001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "659663-67750",
        "Last Price Paid": "$14.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007717",
        "Product Status": "Active"
    },
    {
        "Root": "10015258",
        "LV": "3",
        "Item Description": "SYRUP MAPLE BOURBON BLIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015258003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-10217",
        "Last Price Paid": "$25.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10015258",
        "Product Status": "Active"
    },
    {
        "Root": "10002027",
        "LV": "1",
        "Item Description": "LETTUCE MIXED BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09MX-9",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002027",
        "Product Status": "Active"
    },
    {
        "Root": "10000226",
        "LV": "1",
        "Item Description": "SQUASH KABOCHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000226001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000226",
        "Product Status": "Active"
    },
    {
        "Root": "10013086",
        "LV": "2",
        "Item Description": "SHELL TART SMALL RND VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013086002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LRN8090417X",
        "Last Price Paid": "$0.48",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013086",
        "Product Status": "Active"
    },
    {
        "Root": "10001638",
        "LV": "1",
        "Item Description": "MUSHROOM MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "26045",
        "Last Price Paid": "$60.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001638",
        "Product Status": "Active"
    },
    {
        "Root": "10001638",
        "LV": "1",
        "Item Description": "MUSHROOM MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405109",
        "Last Price Paid": "$60.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001638",
        "Product Status": "Active"
    },
    {
        "Root": "10001638",
        "LV": "1",
        "Item Description": "MUSHROOM MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$60.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001638",
        "Product Status": "Active"
    },
    {
        "Root": "10023797",
        "LV": "2",
        "Item Description": "COFFEE KCUP DECAF 120CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023797001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.46",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10023797",
        "Product Status": "Active"
    },
    {
        "Root": "10002639",
        "LV": "2",
        "Item Description": "SPICE CELERY SALT 6/2 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002639002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002639",
        "Product Status": "Active"
    },
    {
        "Root": "10002639",
        "LV": "2",
        "Item Description": "SPICE CELERY SALT 6/2 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002639002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00577-1",
        "Last Price Paid": "$2.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002639",
        "Product Status": "Active"
    },
    {
        "Root": "10031839",
        "LV": "1",
        "Item Description": "SB MATCHA GREEN TEA BLEND POWDER 500GRAM 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031839001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011090010",
        "Last Price Paid": "$15.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031839",
        "Product Status": "Active"
    },
    {
        "Root": "10100349",
        "LV": "1",
        "Item Description": "INULIN POWDER NOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100349001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "2951",
        "Last Price Paid": "$11.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100349",
        "Product Status": "Active"
    },
    {
        "Root": "10001812",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001812001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50072-A",
        "Last Price Paid": "$12.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001812",
        "Product Status": "Active"
    },
    {
        "Root": "10001812",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001812001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001812",
        "Product Status": "Active"
    },
    {
        "Root": "10039106",
        "LV": "1",
        "Item Description": "SAUCE TAJIN SNACK 15.38OZ 12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SCE008",
        "Last Price Paid": "$4.87",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039106",
        "Product Status": "Active"
    },
    {
        "Root": "10101628",
        "LV": "1",
        "Item Description": "PORK TENDERLOIN SOLOMILLO IBERICON15-18LB/AVG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101628001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "POR059",
        "Last Price Paid": "$13.50",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101628",
        "Product Status": "Active"
    },
    {
        "Root": "10033188",
        "LV": "1",
        "Item Description": "DILL BLOOM 50 CT PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05DILLB-33",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033188",
        "Product Status": "Active"
    },
    {
        "Root": "10037918",
        "LV": "1",
        "Item Description": "AMAFRUITS SWEETENED ACAI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "855738002010",
        "Last Price Paid": "$0.76",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037918",
        "Product Status": "Active"
    },
    {
        "Root": "10002224",
        "LV": "1",
        "Item Description": "TOFUTTI VAN CUTIE 12Z/8EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "452961",
        "Last Price Paid": "$8.39",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002224",
        "Product Status": "Active"
    },
    {
        "Root": "10040757",
        "LV": "1",
        "Item Description": "SOFT SERVE PURPLE CREAM CHEESE DVAH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040757",
        "Product Status": "Active"
    },
    {
        "Root": "10018802",
        "LV": "1",
        "Item Description": "POWDER DECOR LAVENDER LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018802001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11104",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018802",
        "Product Status": "Active"
    },
    {
        "Root": "10009492",
        "LV": "1",
        "Item Description": "BOURBON MAKERS MARK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009492",
        "Product Status": "Active"
    },
    {
        "Root": "10007004",
        "LV": "1",
        "Item Description": "MERLOT KEND JACK VT RES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "583855",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007004",
        "Product Status": "Active"
    },
    {
        "Root": "10005402",
        "LV": "1",
        "Item Description": "SB CHEESE DANISH SQUARE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005402001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11083579",
        "Last Price Paid": "$0.94",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005402",
        "Product Status": "Active"
    },
    {
        "Root": "10101088",
        "LV": "1",
        "Item Description": "POWDER HOT FOAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87362",
        "Last Price Paid": "$29.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101088",
        "Product Status": "Active"
    },
    {
        "Root": "10031988",
        "LV": "1",
        "Item Description": "SEEDS POMEGRANATE FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490147",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031988",
        "Product Status": "Active"
    },
    {
        "Root": "10018092",
        "LV": "1",
        "Item Description": "ROSE ALMA NEGRA BRUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018092001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9178122",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018092",
        "Product Status": "Active"
    },
    {
        "Root": "10019274",
        "LV": "1",
        "Item Description": "BERRIES, JUNIPER DRIED 11 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250016",
        "Last Price Paid": "$19.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019274",
        "Product Status": "Active"
    },
    {
        "Root": "10110229",
        "LV": "1",
        "Item Description": "WISH FRENCH MACARONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "WSMC",
        "Last Price Paid": "$1.09",
        "Case Pack": "105",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110229",
        "Product Status": "Active"
    },
    {
        "Root": "10002427",
        "LV": "2",
        "Item Description": "ICE CREAM BUTTER PECAN EDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002427002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002427",
        "Product Status": "Active"
    },
    {
        "Root": "10002427",
        "LV": "2",
        "Item Description": "ICE CREAM BUTTER PECAN EDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002427002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "904110",
        "Last Price Paid": "$32.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002427",
        "Product Status": "Active"
    },
    {
        "Root": "10038479",
        "LV": "1",
        "Item Description": "BEANS BAKED HEINZ 14.8Z CAN 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "BEA014",
        "Last Price Paid": "$3.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038479",
        "Product Status": "Active"
    },
    {
        "Root": "10042414",
        "LV": "1",
        "Item Description": "PINOT NOIR INTERCEPT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9391251",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042414",
        "Product Status": "Active"
    },
    {
        "Root": "10038915",
        "LV": "2",
        "Item Description": "GRAIN COUS COUS MOROCCAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038915002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "360610",
        "Last Price Paid": "$61.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038915",
        "Product Status": "Active"
    },
    {
        "Root": "10002043",
        "LV": "1",
        "Item Description": "LEEKS BY THE BUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002043",
        "Product Status": "Active"
    },
    {
        "Root": "10007861",
        "LV": "1",
        "Item Description": "CAB SAUV 14 HANDS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578950",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007861",
        "Product Status": "Active"
    },
    {
        "Root": "10002294",
        "LV": "1",
        "Item Description": "CHEESE COMTE 12 MONTH FRANCE 12 LB QUARTER WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002294001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "46509",
        "Last Price Paid": "$14.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002294",
        "Product Status": "Active"
    },
    {
        "Root": "10008498",
        "LV": "2",
        "Item Description": "MERLOT VERITE LA MUSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008498001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "571094",
        "Last Price Paid": "$285.00",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008498",
        "Product Status": "Active"
    },
    {
        "Root": "10001502",
        "LV": "1",
        "Item Description": "HERB OPAL BASIL PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001502",
        "Product Status": "Active"
    },
    {
        "Root": "10017370",
        "LV": "1",
        "Item Description": "ICE CREAM VANILLA DAIRY FREE SO DELICIOUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "994715",
        "Last Price Paid": "$5.38",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017370",
        "Product Status": "Active"
    },
    {
        "Root": "10033932",
        "LV": "1",
        "Item Description": "MASTER GRACEY HEADSTONE SILHOUETTE WHITE 2 X1 3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW29975",
        "Last Price Paid": "$0.60",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033932",
        "Product Status": "Active"
    },
    {
        "Root": "10008183",
        "LV": "1",
        "Item Description": "CHARD CH MONTELENA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9673720",
        "Last Price Paid": "$50.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008183",
        "Product Status": "Active"
    },
    {
        "Root": "10030343",
        "LV": "1",
        "Item Description": "EDEN ARAME SEAWEED 2.1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY972",
        "Last Price Paid": "$24.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030343",
        "Product Status": "Active"
    },
    {
        "Root": "10035050",
        "LV": "1",
        "Item Description": "CHEESE BLEND SHREDDED PARM ASIAGO FONTIN & PROVOLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5311295",
        "Last Price Paid": "$21.70",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035050",
        "Product Status": "Active"
    },
    {
        "Root": "10032425",
        "LV": "1",
        "Item Description": "CHARDONNAY TOKARA RESERVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "398183",
        "Last Price Paid": "$19.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032425",
        "Product Status": "Active"
    },
    {
        "Root": "10040655",
        "LV": "2",
        "Item Description": "COOKIE PALETTE INDV WRAPPED FOTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040655002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "CK-PAL",
        "Last Price Paid": "$2.53",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040655",
        "Product Status": "Active"
    },
    {
        "Root": "10094640",
        "LV": "2",
        "Item Description": "CANDY YUMEARTH ORG. POPS BULK CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094640002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1394",
        "Last Price Paid": "$0.08",
        "Case Pack": "1250",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094640",
        "Product Status": "Active"
    },
    {
        "Root": "10015144",
        "LV": "1",
        "Item Description": "JUICE ROSE HIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FRD038",
        "Last Price Paid": "$65.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015144",
        "Product Status": "Active"
    },
    {
        "Root": "10044398",
        "LV": "1",
        "Item Description": "DOUGH BEIGNET 180 / .9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9285077",
        "Last Price Paid": "$0.38",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044398",
        "Product Status": "Active"
    },
    {
        "Root": "10003002",
        "LV": "2",
        "Item Description": "CORNSTARCH 3.5LB 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003002002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.28",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003002",
        "Product Status": "Active"
    },
    {
        "Root": "10009586",
        "LV": "1",
        "Item Description": "VODKA STOLICHNAYA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "65136",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009586",
        "Product Status": "Active"
    },
    {
        "Root": "10035951",
        "LV": "1",
        "Item Description": "CHOC FEVES STRAWBERRY INSP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "15391",
        "Last Price Paid": "$115.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035951",
        "Product Status": "Active"
    },
    {
        "Root": "10010602",
        "LV": "2",
        "Item Description": "FIREFLY MS PEACH 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010602001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9678983",
        "Last Price Paid": "$9.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010602",
        "Product Status": "Active"
    },
    {
        "Root": "10010061",
        "LV": "1",
        "Item Description": "BEER HEINEKEN CAN 16OZ 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010061",
        "Product Status": "Active"
    },
    {
        "Root": "10100869",
        "LV": "1",
        "Item Description": "WINE CAB SAUV FORTUNATE SON THE WARRIOR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547327",
        "Last Price Paid": "$270.00",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100869",
        "Product Status": "Active"
    },
    {
        "Root": "10002957",
        "LV": "1",
        "Item Description": "MIX STUFFING WHITE BREAD 58OZ 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5610605",
        "Last Price Paid": "$10.89",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002957",
        "Product Status": "Active"
    },
    {
        "Root": "10004570",
        "LV": "1",
        "Item Description": "CORN CREAMED FANCY #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8330086",
        "Last Price Paid": "$8.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004570",
        "Product Status": "Active"
    },
    {
        "Root": "10037936",
        "LV": "1",
        "Item Description": "BAKING CHIP DRK CHOC 5LB 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F10220W",
        "Last Price Paid": "$27.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037936",
        "Product Status": "Active"
    },
    {
        "Root": "10081397",
        "LV": "1",
        "Item Description": "FILLING PINEAPPLE CRUSHED DAWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081397001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "2407585",
        "Last Price Paid": "$51.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081397",
        "Product Status": "Active"
    },
    {
        "Root": "10035310",
        "LV": "1",
        "Item Description": "BOX POPCORN 2X4.75X7.5 3EHE HOOK/EYE CLOSE AT TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51365001",
        "Last Price Paid": "$0.13",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035310",
        "Product Status": "Active"
    },
    {
        "Root": "10104843",
        "LV": "2",
        "Item Description": "CUP COLD 12OZ MARY BLAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104843002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$83.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104843",
        "Product Status": "Active"
    },
    {
        "Root": "10008108",
        "LV": "2",
        "Item Description": "SYRAH BOEKENHOUTSKLOOF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008108001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2305449",
        "Last Price Paid": "$45.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008108",
        "Product Status": "Active"
    },
    {
        "Root": "10012394",
        "LV": "2",
        "Item Description": "PEPPER SPORT WHOLE PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012394002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1445857",
        "Last Price Paid": "$28.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012394",
        "Product Status": "Active"
    },
    {
        "Root": "10000116",
        "LV": "1",
        "Item Description": "SAUSAGE BRATWURST CKD 4X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000116001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "120",
        "Last Price Paid": "$4.01",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000116",
        "Product Status": "Active"
    },
    {
        "Root": "10018568",
        "LV": "1",
        "Item Description": "GEL OLIVE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22507",
        "Last Price Paid": "$5.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018568",
        "Product Status": "Active"
    },
    {
        "Root": "10104177",
        "LV": "1",
        "Item Description": "BEEF BRISKET SMKD BURNT ENDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104177001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7003628",
        "Last Price Paid": "$8.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104177",
        "Product Status": "Active"
    },
    {
        "Root": "10014909",
        "LV": "1",
        "Item Description": "TEA SUPERFRUITY LOOSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014909",
        "Product Status": "Active"
    },
    {
        "Root": "10030737",
        "LV": "1",
        "Item Description": "GEL COLOR PASTE ELECTRIC YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030737001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC ELECTRIC YE",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030737",
        "Product Status": "Active"
    },
    {
        "Root": "10039847",
        "LV": "1",
        "Item Description": "CHOC JAQ AND GUS SQARE BB WH 2.5CM 3240/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233760000",
        "Last Price Paid": "",
        "Case Pack": "3240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039847",
        "Product Status": "Active"
    },
    {
        "Root": "10034154",
        "LV": "1",
        "Item Description": "SHELL TART MINI ROUND VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8090416",
        "Last Price Paid": "",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034154",
        "Product Status": "Active"
    },
    {
        "Root": "10014717",
        "LV": "1",
        "Item Description": "CHAMP TAITTINGR COMTE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "37097",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014717",
        "Product Status": "Active"
    },
    {
        "Root": "10106629",
        "LV": "1",
        "Item Description": "CELLULASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87504",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106629",
        "Product Status": "Active"
    },
    {
        "Root": "10030366",
        "LV": "1",
        "Item Description": "MINNIEMOUSE BOW PINK W/WHT  DOTS 2 1/8X1 1/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW322936",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030366",
        "Product Status": "Active"
    },
    {
        "Root": "10004319",
        "LV": "1",
        "Item Description": "HORS PIZZA DEEP DISH ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004319001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300475",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004319",
        "Product Status": "Active"
    },
    {
        "Root": "10034429",
        "LV": "1",
        "Item Description": "PORT TAYLOR-FLADGATE FINE TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034429",
        "Product Status": "Active"
    },
    {
        "Root": "10002246",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRIE RED HAWK ORG 6/10OZ WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002246001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "94331",
        "Last Price Paid": "$23.16",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002246",
        "Product Status": "Active"
    },
    {
        "Root": "10006452",
        "LV": "1",
        "Item Description": "BOARD SILVER 14 INCH X1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006452",
        "Product Status": "Active"
    },
    {
        "Root": "10015452",
        "LV": "1",
        "Item Description": "FLAVORING BACON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2377",
        "Last Price Paid": "$36.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015452",
        "Product Status": "Active"
    },
    {
        "Root": "10002638",
        "LV": "3",
        "Item Description": "SPICE ONION GRANULATED 6/1.25LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002638003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.32",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002638",
        "Product Status": "Active"
    },
    {
        "Root": "10002638",
        "LV": "3",
        "Item Description": "SPICE ONION GRANULATED 6/1.25LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002638003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00535-1",
        "Last Price Paid": "$4.32",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002638",
        "Product Status": "Active"
    },
    {
        "Root": "10004496",
        "LV": "1",
        "Item Description": "CHOC PARALLEL CONGRATULAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "CONGRATULATION",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004496",
        "Product Status": "Active"
    },
    {
        "Root": "10004567",
        "LV": "1",
        "Item Description": "SUGAR BRN CANE DRK 24/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004567001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3002276",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004567",
        "Product Status": "Active"
    },
    {
        "Root": "10082440",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL COUNTRY CLUB MERENGUE 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082440001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "36850010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082440",
        "Product Status": "Active"
    },
    {
        "Root": "10033394",
        "LV": "1",
        "Item Description": "HUMMUS ROASTED RED PEPPER TUB 2/4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7724990",
        "Last Price Paid": "$19.13",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033394",
        "Product Status": "Active"
    },
    {
        "Root": "10002771",
        "LV": "1",
        "Item Description": "SYRUP DIET COKE HIGH YIELD 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$140.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002771",
        "Product Status": "Active"
    },
    {
        "Root": "10106579",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER ORANGE 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106579001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313020E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106579",
        "Product Status": "Active"
    },
    {
        "Root": "10032882",
        "LV": "1",
        "Item Description": "FRESH HERB MICRO BLEND SHISO CRAZY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SCB-33-L",
        "Last Price Paid": "$48.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032882",
        "Product Status": "Active"
    },
    {
        "Root": "10029030",
        "LV": "1",
        "Item Description": "CHARDONNAY ST FRANCIS 19.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$223.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029030",
        "Product Status": "Active"
    },
    {
        "Root": "10100313",
        "LV": "2",
        "Item Description": "LID CUPS DESSERT DISPOSABLE FLAT CDS 1000",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100313002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$72.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100313",
        "Product Status": "Active"
    },
    {
        "Root": "10000976",
        "LV": "1",
        "Item Description": "SILVER SIDES FOR ANIMAL FORAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000976001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50057",
        "Last Price Paid": "$2.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000976",
        "Product Status": "Active"
    },
    {
        "Root": "10000976",
        "LV": "1",
        "Item Description": "SILVER SIDES FOR ANIMAL FORAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000976001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6384100",
        "Last Price Paid": "$2.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000976",
        "Product Status": "Active"
    },
    {
        "Root": "10002192",
        "LV": "1",
        "Item Description": "CREAM HALF & HALF 1/2 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002192001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.72",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002192",
        "Product Status": "Active"
    },
    {
        "Root": "10005958",
        "LV": "1",
        "Item Description": "CAKE DECORATED 1/4-SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$43.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005958",
        "Product Status": "Active"
    },
    {
        "Root": "10030642",
        "LV": "1",
        "Item Description": "PORK SKIN PELLETS RENDERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030642001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1399718",
        "Last Price Paid": "$4.65",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030642",
        "Product Status": "Active"
    },
    {
        "Root": "10030642",
        "LV": "1",
        "Item Description": "PORK SKIN PELLETS RENDERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030642001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1480025",
        "Last Price Paid": "$4.65",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030642",
        "Product Status": "Active"
    },
    {
        "Root": "10005630",
        "LV": "1",
        "Item Description": "BREAD FOCACCIA BASIL CHEESE 3 INCH X 7 INCH 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005630",
        "Product Status": "Active"
    },
    {
        "Root": "10028191",
        "LV": "1",
        "Item Description": "BEYOND MEAT FEISTY CRUMBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103315",
        "Last Price Paid": "$6.95",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028191",
        "Product Status": "Active"
    },
    {
        "Root": "10016892",
        "LV": "3",
        "Item Description": "LASAGNA SHEETS NO BOIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016892002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070115",
        "Last Price Paid": "$2.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10016892",
        "Product Status": "Active"
    },
    {
        "Root": "10001893",
        "LV": "1",
        "Item Description": "PEPPER GREEN DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "07-001-34",
        "Last Price Paid": "$8.43",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001893",
        "Product Status": "Active"
    },
    {
        "Root": "10001893",
        "LV": "1",
        "Item Description": "PEPPER GREEN DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514522",
        "Last Price Paid": "$8.43",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001893",
        "Product Status": "Active"
    },
    {
        "Root": "10013204",
        "LV": "1",
        "Item Description": "CHIANTI FATTORI COLLI SENESI ITALY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555042",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013204",
        "Product Status": "Active"
    },
    {
        "Root": "10002874",
        "LV": "1",
        "Item Description": "JUICE MM LEMONADE 20OZ PE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002874",
        "Product Status": "Active"
    },
    {
        "Root": "10028726",
        "LV": "1",
        "Item Description": "YAKI NORI BLUE HALF CUT SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "10048",
        "Last Price Paid": "$8.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028726",
        "Product Status": "Active"
    },
    {
        "Root": "10003261",
        "LV": "1",
        "Item Description": "SYRUP VANILLA LTR PET MON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003261",
        "Product Status": "Active"
    },
    {
        "Root": "10000342",
        "LV": "1",
        "Item Description": "CELERY 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30162",
        "Last Price Paid": "$8.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000342",
        "Product Status": "Active"
    },
    {
        "Root": "10000342",
        "LV": "1",
        "Item Description": "CELERY 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-003-12",
        "Last Price Paid": "$8.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000342",
        "Product Status": "Active"
    },
    {
        "Root": "10000342",
        "LV": "1",
        "Item Description": "CELERY 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512516",
        "Last Price Paid": "$8.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000342",
        "Product Status": "Active"
    },
    {
        "Root": "10006373",
        "LV": "1",
        "Item Description": "FILM PVC 12 INCH X 2000",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006373",
        "Product Status": "Active"
    },
    {
        "Root": "10009542",
        "LV": "2",
        "Item Description": "VODKA ABSOLUT MANDRIN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009542001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "47875",
        "Last Price Paid": "$19.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009542",
        "Product Status": "Active"
    },
    {
        "Root": "10009542",
        "LV": "2",
        "Item Description": "VODKA ABSOLUT MANDRIN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009542001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "47875",
        "Last Price Paid": "$19.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009542",
        "Product Status": "Active"
    },
    {
        "Root": "10001829",
        "LV": "1",
        "Item Description": "MUSHROOM WHITE CLAMSHELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001829001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001829",
        "Product Status": "Active"
    },
    {
        "Root": "10001829",
        "LV": "1",
        "Item Description": "MUSHROOM WHITE CLAMSHELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001829001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50095",
        "Last Price Paid": "$9.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001829",
        "Product Status": "Active"
    },
    {
        "Root": "10109534",
        "LV": "1",
        "Item Description": "CIDER KEG ANGRY ORCHARD CRISP APPLE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$183.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109534",
        "Product Status": "Active"
    },
    {
        "Root": "10090584",
        "LV": "1",
        "Item Description": "TEA JOFFREYS BUTTERFLY PEA FLOWER 1/2 LB BAG EA/2C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090584",
        "Product Status": "Active"
    },
    {
        "Root": "10102110",
        "LV": "1",
        "Item Description": "BABY LEEKS 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22327",
        "Last Price Paid": "$2.38",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102110",
        "Product Status": "Active"
    },
    {
        "Root": "10109553",
        "LV": "1",
        "Item Description": "BEER KEG WARSTEINER DUNKEL 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109553",
        "Product Status": "Active"
    },
    {
        "Root": "10003966",
        "LV": "1",
        "Item Description": "OIL HAZELNUT 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01055",
        "Last Price Paid": "$61.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003966",
        "Product Status": "Active"
    },
    {
        "Root": "10089246",
        "LV": "1",
        "Item Description": "CHICKEN WOG CITRUS MARINATED 2.25-2.50 LB10089246",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089246001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.09",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089246",
        "Product Status": "Active"
    },
    {
        "Root": "10027544",
        "LV": "1",
        "Item Description": "TEA GOLD PEAK SWEET 18.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027544001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "135333",
        "Last Price Paid": "$1.49",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027544",
        "Product Status": "Active"
    },
    {
        "Root": "10042850",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK MICKEY EAR BLANK SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330D",
        "Last Price Paid": "$2.52",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042850",
        "Product Status": "Active"
    },
    {
        "Root": "10025236",
        "LV": "1",
        "Item Description": "LID SQUARE DOME FOR 32 OZ 300/CASE 5DD200DL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025236",
        "Product Status": "Active"
    },
    {
        "Root": "10004489",
        "LV": "1",
        "Item Description": "MUSTARD GERMAN SWT BURKHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000316955",
        "Vendor": "GOURMET INTERNATIONAL INC",
        "Vendor Item Nb": "268221",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004489",
        "Product Status": "Active"
    },
    {
        "Root": "10004489",
        "LV": "1",
        "Item Description": "MUSTARD GERMAN SWT BURKHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "504",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004489",
        "Product Status": "Active"
    },
    {
        "Root": "10002044",
        "LV": "1",
        "Item Description": "LETTUCE BABY ROMAINE GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480066",
        "Last Price Paid": "$23.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002044",
        "Product Status": "Active"
    },
    {
        "Root": "10002044",
        "LV": "1",
        "Item Description": "LETTUCE BABY ROMAINE GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002044",
        "Product Status": "Active"
    },
    {
        "Root": "10017547",
        "LV": "1",
        "Item Description": "GUAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390018",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017547",
        "Product Status": "Active"
    },
    {
        "Root": "10010355",
        "LV": "2",
        "Item Description": "BEER VICTORY GLDN MONKEY 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010355001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "35718",
        "Last Price Paid": "$1.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010355",
        "Product Status": "Active"
    },
    {
        "Root": "10025681",
        "LV": "1",
        "Item Description": "CAB SAUV SIMI 375 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025681001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "549773",
        "Last Price Paid": "$7.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025681",
        "Product Status": "Active"
    },
    {
        "Root": "10105043",
        "LV": "2",
        "Item Description": "PICKLED DAIKON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105043002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35075",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10105043",
        "Product Status": "Active"
    },
    {
        "Root": "10003084",
        "LV": "2",
        "Item Description": "MIX DOLE WHIP ORANGE 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003084001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D531-A6120",
        "Last Price Paid": "$17.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003084",
        "Product Status": "Active"
    },
    {
        "Root": "10095569",
        "LV": "1",
        "Item Description": "BAR SYRUP DRESS THE DRINK ROSEMARY 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "RSS-750",
        "Last Price Paid": "$10.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095569",
        "Product Status": "Active"
    },
    {
        "Root": "10004589",
        "LV": "1",
        "Item Description": "BEAN BAKED VEGETARIAN #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8181919",
        "Last Price Paid": "$6.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004589",
        "Product Status": "Active"
    },
    {
        "Root": "10023764",
        "LV": "1",
        "Item Description": "BEET ULTRA MIXED TRIMMED CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01UMXBT-26",
        "Last Price Paid": "$125.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023764",
        "Product Status": "Active"
    },
    {
        "Root": "10006951",
        "LV": "1",
        "Item Description": "SANGRIA BESO DEL SOL RED 3.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "922573",
        "Last Price Paid": "$15.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006951",
        "Product Status": "Active"
    },
    {
        "Root": "10003314",
        "LV": "1",
        "Item Description": "WASABI DRY MUSTARD 2.2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2595403",
        "Last Price Paid": "$25.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003314",
        "Product Status": "Active"
    },
    {
        "Root": "10104210",
        "LV": "1",
        "Item Description": "TEA TEALEAVES ORG WILD HIMLYN GOJI LOOSE LEAF 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104210",
        "Product Status": "Active"
    },
    {
        "Root": "10013680",
        "LV": "1",
        "Item Description": "VEG TOMATO ROASTED RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013680001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30575",
        "Last Price Paid": "$6.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013680",
        "Product Status": "Active"
    },
    {
        "Root": "10001113",
        "LV": "1",
        "Item Description": "CLAM CHOPPED FROZEN SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001113001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6403000",
        "Last Price Paid": "$8.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001113",
        "Product Status": "Active"
    },
    {
        "Root": "10001113",
        "LV": "1",
        "Item Description": "CLAM CHOPPED FROZEN SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001113001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "76-00915-A",
        "Last Price Paid": "$8.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001113",
        "Product Status": "Active"
    },
    {
        "Root": "10001113",
        "LV": "1",
        "Item Description": "CLAM CHOPPED FROZEN SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001113001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001113",
        "Product Status": "Active"
    },
    {
        "Root": "10004091",
        "LV": "2",
        "Item Description": "SHELL SWEET 3.3 ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004091001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SDB250",
        "Last Price Paid": "$0.66",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004091",
        "Product Status": "Active"
    },
    {
        "Root": "10002134",
        "LV": "1",
        "Item Description": "CHEESE PROVOLONE NON SMOKED SLICED .5OZ 4/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4996898",
        "Last Price Paid": "$7.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002134",
        "Product Status": "Active"
    },
    {
        "Root": "10002134",
        "LV": "1",
        "Item Description": "CHEESE PROVOLONE NON SMOKED SLICED .5OZ 4/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002134",
        "Product Status": "Active"
    },
    {
        "Root": "10040997",
        "LV": "1",
        "Item Description": "CUP TUMBLER ACRYLIC DRAWNTOLIFE 16Z 72/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.73",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040997",
        "Product Status": "Active"
    },
    {
        "Root": "10038363",
        "LV": "1",
        "Item Description": "MALLET WOODEN GANACHERY 7IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11001511",
        "Last Price Paid": "$1.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038363",
        "Product Status": "Active"
    },
    {
        "Root": "10038818",
        "LV": "1",
        "Item Description": "MUFFIN DOUBLE CHOCOLATE LARGE 6.5 OZ INDV WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038818",
        "Product Status": "Active"
    },
    {
        "Root": "10038438",
        "LV": "1",
        "Item Description": "HUMMUS BLACK BEAN RED SOD 5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038438001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$159.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038438",
        "Product Status": "Active"
    },
    {
        "Root": "10107649",
        "LV": "2",
        "Item Description": "CRABMEAT CLAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107649002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "9801018",
        "Last Price Paid": "$11.90",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107649",
        "Product Status": "Active"
    },
    {
        "Root": "10017410",
        "LV": "1",
        "Item Description": "CHEESE, CREAM PLAIN VEGAN DAIYA, DAIRY FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3DFU21-102001",
        "Last Price Paid": "$3.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017410",
        "Product Status": "Active"
    },
    {
        "Root": "10004691",
        "LV": "1",
        "Item Description": "CHIPS BANANA 14# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "300100",
        "Last Price Paid": "$33.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004691",
        "Product Status": "Active"
    },
    {
        "Root": "10001934",
        "LV": "4",
        "Item Description": "ONION CIPPOLINI PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001934002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10001934",
        "Product Status": "Active"
    },
    {
        "Root": "10016023",
        "LV": "1",
        "Item Description": "FRILLS RED MUSTARD MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016023001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482071",
        "Last Price Paid": "$14.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016023",
        "Product Status": "Active"
    },
    {
        "Root": "10002871",
        "LV": "1",
        "Item Description": "POWERADE LEMON LIME 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002871",
        "Product Status": "Active"
    },
    {
        "Root": "10032752",
        "LV": "1",
        "Item Description": "CAVIAR BELUGA HYBRID MALOSSOL 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6150012",
        "Last Price Paid": "$37.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032752",
        "Product Status": "Active"
    },
    {
        "Root": "10032752",
        "LV": "1",
        "Item Description": "CAVIAR BELUGA HYBRID MALOSSOL 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-90490",
        "Last Price Paid": "$37.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032752",
        "Product Status": "Active"
    },
    {
        "Root": "10009747",
        "LV": "3",
        "Item Description": "TEQ CASA DRAGONE BLNC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009747001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "360265",
        "Last Price Paid": "$49.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10009747",
        "Product Status": "Active"
    },
    {
        "Root": "10036212",
        "LV": "1",
        "Item Description": "COCOA BUTTER POPSICLE ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302035C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036212",
        "Product Status": "Active"
    },
    {
        "Root": "10020147",
        "LV": "1",
        "Item Description": "ZIN FEDERALIST 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550654",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020147",
        "Product Status": "Active"
    },
    {
        "Root": "10084341",
        "LV": "1",
        "Item Description": "BUCKET POPCORN SAND CASTLE WATER PARK 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.23",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084341",
        "Product Status": "Active"
    },
    {
        "Root": "10015040",
        "LV": "1",
        "Item Description": "BROCCOLI FLOWERETTS 18#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "35009",
        "Last Price Paid": "$40.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015040",
        "Product Status": "Active"
    },
    {
        "Root": "10015040",
        "LV": "1",
        "Item Description": "BROCCOLI FLOWERETTS 18#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401056",
        "Last Price Paid": "$40.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015040",
        "Product Status": "Active"
    },
    {
        "Root": "10004066",
        "LV": "1",
        "Item Description": "APPETIZER PICKLE CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2985679",
        "Last Price Paid": "$8.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004066",
        "Product Status": "Active"
    },
    {
        "Root": "10005774",
        "LV": "1",
        "Item Description": "BREAD ROLL FRENCH 1.5 OZ EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005774",
        "Product Status": "Active"
    },
    {
        "Root": "10110031",
        "LV": "1",
        "Item Description": "CHAMPAGNE POL ROGER ROSE 6/750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "535336",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110031",
        "Product Status": "Active"
    },
    {
        "Root": "10110637",
        "LV": "1",
        "Item Description": "CIDER CIDERBOYS CARAMEL APPLE 1/2 BBL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16351",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110637",
        "Product Status": "Active"
    },
    {
        "Root": "10041039",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE H2O GLOW TYPHOON DISC 1.5IN 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041039001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2515",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041039",
        "Product Status": "Active"
    },
    {
        "Root": "10107809",
        "LV": "1",
        "Item Description": "BUCKET PREM EC FIGMENT 2.0 4OZ BAG 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107809",
        "Product Status": "Active"
    },
    {
        "Root": "10004292",
        "LV": "1",
        "Item Description": "SB MUFFIN PUMPKIN CREAM CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004292001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004292",
        "Product Status": "Active"
    },
    {
        "Root": "10106630",
        "LV": "1",
        "Item Description": "VEGETAGEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87378",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106630",
        "Product Status": "Active"
    },
    {
        "Root": "10000110",
        "LV": "1",
        "Item Description": "BEEF FILET CERTIFIED ANGUS CENTER CUT 6 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000110001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190101",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000110",
        "Product Status": "Active"
    },
    {
        "Root": "10014674",
        "LV": "1",
        "Item Description": "MIX STOLLEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "60159",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014674",
        "Product Status": "Active"
    },
    {
        "Root": "10013683",
        "LV": "1",
        "Item Description": "DEC SPRING LEAF GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SPRLGR",
        "Last Price Paid": "",
        "Case Pack": "112",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013683",
        "Product Status": "Active"
    },
    {
        "Root": "10007552",
        "LV": "2",
        "Item Description": "CH BLC BELLING CITRUS GROVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007552001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007552",
        "Product Status": "Active"
    },
    {
        "Root": "10030133",
        "LV": "1",
        "Item Description": "PEARS COMICE S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "275080",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030133",
        "Product Status": "Active"
    },
    {
        "Root": "10000380",
        "LV": "1",
        "Item Description": "BROCCOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20225",
        "Last Price Paid": "$41.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000380",
        "Product Status": "Active"
    },
    {
        "Root": "10000380",
        "LV": "1",
        "Item Description": "BROCCOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BROLINI",
        "Last Price Paid": "$41.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000380",
        "Product Status": "Active"
    },
    {
        "Root": "10000380",
        "LV": "1",
        "Item Description": "BROCCOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401055",
        "Last Price Paid": "$41.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000380",
        "Product Status": "Active"
    },
    {
        "Root": "10000380",
        "LV": "1",
        "Item Description": "BROCCOLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000380001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8532087",
        "Last Price Paid": "$41.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000380",
        "Product Status": "Active"
    },
    {
        "Root": "10039882",
        "LV": "2",
        "Item Description": "COLOR FONDANT SATIN VANILLA GOLD SHIMMER 10/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039882002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B0051",
        "Last Price Paid": "$15.39",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039882",
        "Product Status": "Active"
    },
    {
        "Root": "10036284",
        "LV": "1",
        "Item Description": "CHOC WHITE SQUARES 1\" INTERNATL WOMEN'S DAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63208",
        "Last Price Paid": "$0.25",
        "Case Pack": "315",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036284",
        "Product Status": "Active"
    },
    {
        "Root": "10012610",
        "LV": "1",
        "Item Description": "SPRAY VELVET ORANGE 6 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV001O",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012610",
        "Product Status": "Active"
    },
    {
        "Root": "10044289",
        "LV": "1",
        "Item Description": "PASTRY BOARD RECTANGLE U-SHAPED BRONZE, 6X1.75IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013388",
        "Last Price Paid": "$0.08",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044289",
        "Product Status": "Active"
    },
    {
        "Root": "10039774",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET  PRIME ANGUS 7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039774001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195000",
        "Last Price Paid": "$40.25",
        "Case Pack": "8.75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039774",
        "Product Status": "Active"
    },
    {
        "Root": "10040118",
        "LV": "1",
        "Item Description": "COOKIE GINGERSNAP MVMCP 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040118001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8596736",
        "Last Price Paid": "$6.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040118",
        "Product Status": "Active"
    },
    {
        "Root": "10001658",
        "LV": "1",
        "Item Description": "APPLE CIDER PAST 4-1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "32021",
        "Last Price Paid": "$7.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001658",
        "Product Status": "Active"
    },
    {
        "Root": "10001658",
        "LV": "1",
        "Item Description": "APPLE CIDER PAST 4-1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7915234",
        "Last Price Paid": "$7.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001658",
        "Product Status": "Active"
    },
    {
        "Root": "10001658",
        "LV": "1",
        "Item Description": "APPLE CIDER PAST 4-1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "602020",
        "Last Price Paid": "$7.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001658",
        "Product Status": "Active"
    },
    {
        "Root": "10013520",
        "LV": "1",
        "Item Description": "BANFI LA PETTEGOLA VERMENTINO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346695",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013520",
        "Product Status": "Active"
    },
    {
        "Root": "10030323",
        "LV": "1",
        "Item Description": "COOKIE GRAHAM CRACKER CRUMBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302330",
        "Last Price Paid": "$94.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030323",
        "Product Status": "Active"
    },
    {
        "Root": "10040316",
        "LV": "1",
        "Item Description": "STRAW PLASTIC GREEN ADA 10.25IN 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11113007",
        "Last Price Paid": "$1.88",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040316",
        "Product Status": "Active"
    },
    {
        "Root": "10087189",
        "LV": "2",
        "Item Description": "CUP COLD 50TH ANNIV WDW 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087189001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$110.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10087189",
        "Product Status": "Active"
    },
    {
        "Root": "10087734",
        "LV": "1",
        "Item Description": "PINEAPPLE RINGS 11# BAG 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087734",
        "Product Status": "Active"
    },
    {
        "Root": "10015030",
        "LV": "1",
        "Item Description": "HAY BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "02HAR-1",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015030",
        "Product Status": "Active"
    },
    {
        "Root": "10005895",
        "LV": "1",
        "Item Description": "BREAD ROLL KAISER ONION 4 OZ EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005895001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "17080",
        "Last Price Paid": "$0.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005895",
        "Product Status": "Active"
    },
    {
        "Root": "10013492",
        "LV": "1",
        "Item Description": "SUNFLOWER MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SS-33-L",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013492",
        "Product Status": "Active"
    },
    {
        "Root": "10014359",
        "LV": "2",
        "Item Description": "CAPE GOOSEBERRIES W/ HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014359002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FR061",
        "Last Price Paid": "$7.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014359",
        "Product Status": "Active"
    },
    {
        "Root": "10009993",
        "LV": "1",
        "Item Description": "LIQ BRANDY BLACKBERRY BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009993001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009993",
        "Product Status": "Active"
    },
    {
        "Root": "10102128",
        "LV": "1",
        "Item Description": "BEER KEG BROOKLYN BREW WINTER IPA 1/2 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14592",
        "Last Price Paid": "$189.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102128",
        "Product Status": "Active"
    },
    {
        "Root": "10033626",
        "LV": "1",
        "Item Description": "VODKA KETEL BOTANICAL GRAPEFRUIT ROSE 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033626",
        "Product Status": "Active"
    },
    {
        "Root": "10007012",
        "LV": "1",
        "Item Description": "SAUV BLC KIM CRAWFORD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007012",
        "Product Status": "Active"
    },
    {
        "Root": "10007012",
        "LV": "1",
        "Item Description": "SAUV BLC KIM CRAWFORD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "14580",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007012",
        "Product Status": "Active"
    },
    {
        "Root": "10005782",
        "LV": "1",
        "Item Description": "COOKIES, VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "6",
        "Last Price Paid": "$1.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005782",
        "Product Status": "Active"
    },
    {
        "Root": "10028906",
        "LV": "1",
        "Item Description": "MIX FC VEGGIE KABOB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598150",
        "Last Price Paid": "$2.39",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028906",
        "Product Status": "Active"
    },
    {
        "Root": "10036749",
        "LV": "1",
        "Item Description": "JUICE POM WONDERFUL S/O 60Z BOT 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404025",
        "Last Price Paid": "$10.08",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036749",
        "Product Status": "Active"
    },
    {
        "Root": "10004660",
        "LV": "2",
        "Item Description": "NUT PISTACHO R/NO SALT SHELLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004660002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "182571",
        "Last Price Paid": "$120.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004660",
        "Product Status": "Active"
    },
    {
        "Root": "10001725",
        "LV": "1",
        "Item Description": "PEPPER HOLLAND RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407105",
        "Last Price Paid": "$25.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001725",
        "Product Status": "Active"
    },
    {
        "Root": "10019089",
        "LV": "1",
        "Item Description": "SUGAR-SMALL EYES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "16652",
        "Last Price Paid": "$0.02",
        "Case Pack": "1016",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019089",
        "Product Status": "Active"
    },
    {
        "Root": "10002059",
        "LV": "1",
        "Item Description": "RUTABAGAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002059001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002059",
        "Product Status": "Active"
    },
    {
        "Root": "10004645",
        "LV": "1",
        "Item Description": "FLOUR CORN 3% TREATED WITH LIME QUAKER 8-4.4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6105902",
        "Last Price Paid": "$4.19",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004645",
        "Product Status": "Active"
    },
    {
        "Root": "10008048",
        "LV": "1",
        "Item Description": "CAB SAUV SEQUOIA GRVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "267929",
        "Last Price Paid": "$38.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008048",
        "Product Status": "Active"
    },
    {
        "Root": "10008048",
        "LV": "1",
        "Item Description": "CAB SAUV SEQUOIA GRVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "267929",
        "Last Price Paid": "$38.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008048",
        "Product Status": "Active"
    },
    {
        "Root": "10011141",
        "LV": "2",
        "Item Description": "VODKA FLA CANE ORANGE 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011141002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "112161",
        "Last Price Paid": "$21.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011141",
        "Product Status": "Active"
    },
    {
        "Root": "10002724",
        "LV": "1",
        "Item Description": "RICE ARBORIO SAN MARCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002724001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1134758",
        "Last Price Paid": "$26.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002724",
        "Product Status": "Active"
    },
    {
        "Root": "10026135",
        "LV": "1",
        "Item Description": "LID FOR COPPER BAKING CUP 10026120",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51491003",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026135",
        "Product Status": "Active"
    },
    {
        "Root": "10004827",
        "LV": "1",
        "Item Description": "SHELL TACO JUMBO 7 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2290633",
        "Last Price Paid": "$0.26",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004827",
        "Product Status": "Active"
    },
    {
        "Root": "10015906",
        "LV": "2",
        "Item Description": "BEEF TOP BLADE BRAISED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015906001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7028933",
        "Last Price Paid": "$10.35",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10015906",
        "Product Status": "Active"
    },
    {
        "Root": "10014421",
        "LV": "1",
        "Item Description": "EURO CUCUMBERS (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014421001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482045",
        "Last Price Paid": "$16.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014421",
        "Product Status": "Active"
    },
    {
        "Root": "10001307",
        "LV": "1",
        "Item Description": "FRY 1/2 INCH STRAIGHT CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001307",
        "Product Status": "Active"
    },
    {
        "Root": "10007792",
        "LV": "2",
        "Item Description": "CAB SAUV TREFE HALO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007792001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "560398",
        "Last Price Paid": "$178.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007792",
        "Product Status": "Active"
    },
    {
        "Root": "10036169",
        "LV": "1",
        "Item Description": "GINGER ALE 20Z 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036169",
        "Product Status": "Active"
    },
    {
        "Root": "10013604",
        "LV": "1",
        "Item Description": "CHERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013604001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013604",
        "Product Status": "Active"
    },
    {
        "Root": "10002413",
        "LV": "1",
        "Item Description": "CHEESE BABYBEL SNACK 12/6 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "391561",
        "Last Price Paid": "$0.57",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002413",
        "Product Status": "Active"
    },
    {
        "Root": "10009587",
        "LV": "2",
        "Item Description": "VODKA ABSOLUT 80 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009587001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009587",
        "Product Status": "Active"
    },
    {
        "Root": "10010495",
        "LV": "1",
        "Item Description": "MARSURET PROSECCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "971502",
        "Last Price Paid": "$13.41",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010495",
        "Product Status": "Active"
    },
    {
        "Root": "10020389",
        "LV": "1",
        "Item Description": "DUCK BREAST PETITE 7-8OZ 6-4 PKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020389001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "DUCROH104",
        "Last Price Paid": "$18.99",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020389",
        "Product Status": "Active"
    },
    {
        "Root": "10035886",
        "LV": "2",
        "Item Description": "CANDY STRAWBERRY SOUR BELTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035886002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585495",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035886",
        "Product Status": "Active"
    },
    {
        "Root": "10006060",
        "LV": "1",
        "Item Description": "CUBE LUMA/LITE RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.02",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006060",
        "Product Status": "Active"
    },
    {
        "Root": "10016584",
        "LV": "2",
        "Item Description": "PAD CHOC SOFT CELL 16CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016584002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51149007",
        "Last Price Paid": "$0.19",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016584",
        "Product Status": "Active"
    },
    {
        "Root": "10004087",
        "LV": "1",
        "Item Description": "CHIPS PEANUT BUTTER 4000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004087001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582720",
        "Last Price Paid": "$129.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004087",
        "Product Status": "Active"
    },
    {
        "Root": "10002038",
        "LV": "1",
        "Item Description": "JICAMA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002038001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002038",
        "Product Status": "Active"
    },
    {
        "Root": "10004579",
        "LV": "1",
        "Item Description": "HOT COCOA NO SUGAR ADD IND PACKETS 150CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004579001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9659319",
        "Last Price Paid": "$0.38",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004579",
        "Product Status": "Active"
    },
    {
        "Root": "10089502",
        "LV": "1",
        "Item Description": "POWDER MERINGUE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA5351",
        "Last Price Paid": "$108.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089502",
        "Product Status": "Active"
    },
    {
        "Root": "10034824",
        "LV": "1",
        "Item Description": "BUFFALO TENDERLOIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034824001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "BUFABF011",
        "Last Price Paid": "$28.99",
        "Case Pack": "5.75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034824",
        "Product Status": "Active"
    },
    {
        "Root": "10002644",
        "LV": "2",
        "Item Description": "SPICE GARLIC GRANULATED 4/5.5 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002644002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.84",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002644",
        "Product Status": "Active"
    },
    {
        "Root": "10001708",
        "LV": "1",
        "Item Description": "MUSHROOM PORTABELLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001708",
        "Product Status": "Active"
    },
    {
        "Root": "10001708",
        "LV": "1",
        "Item Description": "MUSHROOM PORTABELLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405108",
        "Last Price Paid": "$19.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001708",
        "Product Status": "Active"
    },
    {
        "Root": "10030911",
        "LV": "1",
        "Item Description": "SILHOUETTE WHITE MICKEY FACE HOLIDAY THEME 1 3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW31930",
        "Last Price Paid": "$0.65",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030911",
        "Product Status": "Active"
    },
    {
        "Root": "10004057",
        "LV": "1",
        "Item Description": "CHIP POTATO KETTLE ORIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5907258",
        "Last Price Paid": "$4.28",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004057",
        "Product Status": "Active"
    },
    {
        "Root": "10086779",
        "LV": "1",
        "Item Description": "SB SUGAR COOKIE SYRUP  1L 4-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011120679",
        "Last Price Paid": "$17.12",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086779",
        "Product Status": "Active"
    },
    {
        "Root": "10005035",
        "LV": "1",
        "Item Description": "SPRINKLES GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9472",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005035",
        "Product Status": "Active"
    },
    {
        "Root": "10035775",
        "LV": "1",
        "Item Description": "MINNIE 3 PC DECO CLOTHING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW30156",
        "Last Price Paid": "$0.50",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035775",
        "Product Status": "Active"
    },
    {
        "Root": "10021937",
        "LV": "1",
        "Item Description": "BEEF SKIRT OUTSIDE PEELED CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021937001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0122830",
        "Last Price Paid": "$13.03",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021937",
        "Product Status": "Active"
    },
    {
        "Root": "10034367",
        "LV": "1",
        "Item Description": "CLEAR CUP ROUND/SPHERICAL SF01000 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51097017",
        "Last Price Paid": "$0.73",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034367",
        "Product Status": "Active"
    },
    {
        "Root": "10036089",
        "LV": "1",
        "Item Description": "LIQUEUR CARDENAL MENDOZA ANGELUS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "398141",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036089",
        "Product Status": "Active"
    },
    {
        "Root": "10001868",
        "LV": "1",
        "Item Description": "POWDER MUSHROOM PORCINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001868001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401123",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001868",
        "Product Status": "Active"
    },
    {
        "Root": "10013092",
        "LV": "1",
        "Item Description": "BENNE SEEDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013092001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "RI047",
        "Last Price Paid": "$9.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013092",
        "Product Status": "Active"
    },
    {
        "Root": "10028587",
        "LV": "1",
        "Item Description": "CARROTS BABY RAINBOW UNPEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480033",
        "Last Price Paid": "$1.71",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028587",
        "Product Status": "Active"
    },
    {
        "Root": "10001429",
        "LV": "1",
        "Item Description": "GARLIC ROOTS PKG APPR 2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14GR-33",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001429",
        "Product Status": "Active"
    },
    {
        "Root": "10084932",
        "LV": "1",
        "Item Description": "STRAW JUMBO 7.75 IN WHITE WRAPPED PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7087840",
        "Last Price Paid": "$0.03",
        "Case Pack": "3200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084932",
        "Product Status": "Active"
    },
    {
        "Root": "10002669",
        "LV": "2",
        "Item Description": "CHERRY MARSCHINO W/ STEM 4-1G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002669002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002669",
        "Product Status": "Active"
    },
    {
        "Root": "10040378",
        "LV": "2",
        "Item Description": "SPICE MEMPHIS RUB 4/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040378002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040378",
        "Product Status": "Active"
    },
    {
        "Root": "10017488",
        "LV": "1",
        "Item Description": "BEAN LENTIL IVORY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480520",
        "Last Price Paid": "$53.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017488",
        "Product Status": "Active"
    },
    {
        "Root": "10003773",
        "LV": "1",
        "Item Description": "SAUCE TRUFFLE SELEZIONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "TR021",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003773",
        "Product Status": "Active"
    },
    {
        "Root": "10013743",
        "LV": "1",
        "Item Description": "VINEGAR BANYULS 6/26Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.45",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013743",
        "Product Status": "Active"
    },
    {
        "Root": "10035874",
        "LV": "1",
        "Item Description": "CHEESE SEMI HARD TRONCHON COW SHEEP GOAT  2/4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035874001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "23163",
        "Last Price Paid": "$8.98",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035874",
        "Product Status": "Active"
    },
    {
        "Root": "10035211",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861290000",
        "Last Price Paid": "$13.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035211",
        "Product Status": "Active"
    },
    {
        "Root": "10010104",
        "LV": "1",
        "Item Description": "BEER ANGRY ORCHRD CDR 12Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010104",
        "Product Status": "Active"
    },
    {
        "Root": "10108168",
        "LV": "1",
        "Item Description": "BOX CLEAR 2X2X6\" POP&LOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51137226",
        "Last Price Paid": "$0.39",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108168",
        "Product Status": "Active"
    },
    {
        "Root": "10033940",
        "LV": "1",
        "Item Description": "GC18300 10.5OZ TRANS BODEGA CUP PLASTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11097016",
        "Last Price Paid": "$0.38",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033940",
        "Product Status": "Active"
    },
    {
        "Root": "10007552",
        "LV": "3",
        "Item Description": "CH BLC BELLING CITRUS GROVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007552002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "642109",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007552",
        "Product Status": "Active"
    },
    {
        "Root": "10007933",
        "LV": "1",
        "Item Description": "BORD CH TOUR DE SEGUR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007933001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "911696",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007933",
        "Product Status": "Active"
    },
    {
        "Root": "10009309",
        "LV": "2",
        "Item Description": "BLND WHT STARK CONDE 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009309001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9182737",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009309",
        "Product Status": "Active"
    },
    {
        "Root": "10006905",
        "LV": "1",
        "Item Description": "ZINF ROSNBLM VNTR CV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "919106",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006905",
        "Product Status": "Active"
    },
    {
        "Root": "10001357",
        "LV": "1",
        "Item Description": "COULIS SUNDRIED TOMATO 34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001357",
        "Product Status": "Active"
    },
    {
        "Root": "10100454",
        "LV": "1",
        "Item Description": "KETCHUP LS RED GOLD 2/1.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100454",
        "Product Status": "Active"
    },
    {
        "Root": "10021802",
        "LV": "1",
        "Item Description": "CAKE SHEET SPONGE CHOC 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021802001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1663",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021802",
        "Product Status": "Active"
    },
    {
        "Root": "10014850",
        "LV": "2",
        "Item Description": "SB CROISSANT ALMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014850002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11112726",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014850",
        "Product Status": "Active"
    },
    {
        "Root": "10085553",
        "LV": "2",
        "Item Description": "DEC CHOC WHT DOME CASTLE DISNEY 4.4CM 1440PC 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085553002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "623645",
        "Last Price Paid": "",
        "Case Pack": "1440",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10085553",
        "Product Status": "Active"
    },
    {
        "Root": "10004073",
        "LV": "1",
        "Item Description": "CHOC CUP MINI DARK FLUTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I2002",
        "Last Price Paid": "",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004073",
        "Product Status": "Active"
    },
    {
        "Root": "10034631",
        "LV": "1",
        "Item Description": "22OZ GREEN WATER PARK GLASS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G-22/APPLE",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034631",
        "Product Status": "Active"
    },
    {
        "Root": "10034621",
        "LV": "1",
        "Item Description": "BLUE WATER PARK GLASS LT 22OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G-22/BLUE",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034621",
        "Product Status": "Active"
    },
    {
        "Root": "10027425",
        "LV": "1",
        "Item Description": "CUP ESPNWWS BEER GRAPE 22 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G22-GRAPE",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027425",
        "Product Status": "Active"
    },
    {
        "Root": "10036392",
        "LV": "2",
        "Item Description": "STRAW PAPER BRIGHT BLUE STRIPE 7.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036392002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "485UPJ7BLSTR",
        "Last Price Paid": "",
        "Case Pack": "4800",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036392",
        "Product Status": "Active"
    },
    {
        "Root": "10017754",
        "LV": "1",
        "Item Description": "PEPPER GUAJILLO DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407987",
        "Last Price Paid": "$45.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017754",
        "Product Status": "Active"
    },
    {
        "Root": "10010511",
        "LV": "1",
        "Item Description": "CASTLO BNFI BRNLO MNTLCNO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010511001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "225722",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010511",
        "Product Status": "Active"
    },
    {
        "Root": "10000200",
        "LV": "1",
        "Item Description": "CARROT BABY WITHOUT TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482016",
        "Last Price Paid": "$5.48",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000200",
        "Product Status": "Active"
    },
    {
        "Root": "10109557",
        "LV": "1",
        "Item Description": "BEEF SIRL KIDS STK 4Z-5Z FRZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109557001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190050",
        "Last Price Paid": "$10.36",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109557",
        "Product Status": "Active"
    },
    {
        "Root": "10015266",
        "LV": "2",
        "Item Description": "SPICE MUSTARD SEED WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015266002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1028794",
        "Last Price Paid": "$9.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015266",
        "Product Status": "Active"
    },
    {
        "Root": "10029895",
        "LV": "1",
        "Item Description": "KALE BABY BLEND UBU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029895001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480897",
        "Last Price Paid": "$16.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029895",
        "Product Status": "Active"
    },
    {
        "Root": "10004585",
        "LV": "1",
        "Item Description": "COLORING FOOD CARMEL 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "761320",
        "Last Price Paid": "$13.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004585",
        "Product Status": "Active"
    },
    {
        "Root": "10007113",
        "LV": "1",
        "Item Description": "ZINF EBERLE 07 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "411679",
        "Last Price Paid": "$16.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007113",
        "Product Status": "Active"
    },
    {
        "Root": "10002174",
        "LV": "2",
        "Item Description": "JUICE BAR STRAWBERRY NON COAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002174001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002174",
        "Product Status": "Active"
    },
    {
        "Root": "10001294",
        "LV": "1",
        "Item Description": "JUICE MM ORANGE MINUTE PAK 4/90 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006401",
        "Last Price Paid": "$32.19",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001294",
        "Product Status": "Active"
    },
    {
        "Root": "10035036",
        "LV": "2",
        "Item Description": "BOURBON WOODFORD RESERVE  Y&Y SELECT 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035036002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9421667",
        "Last Price Paid": "$40.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035036",
        "Product Status": "Active"
    },
    {
        "Root": "10001731",
        "LV": "1",
        "Item Description": "PEPPER HOLLAND YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407115",
        "Last Price Paid": "$15.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001731",
        "Product Status": "Active"
    },
    {
        "Root": "10001731",
        "LV": "1",
        "Item Description": "PEPPER HOLLAND YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001731",
        "Product Status": "Active"
    },
    {
        "Root": "10013905",
        "LV": "1",
        "Item Description": "PINOT NOIR STORM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "373714",
        "Last Price Paid": "$44.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013905",
        "Product Status": "Active"
    },
    {
        "Root": "10030076",
        "LV": "1",
        "Item Description": "JACK FRUIT S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030076001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "241009",
        "Last Price Paid": "$29.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030076",
        "Product Status": "Active"
    },
    {
        "Root": "10019646",
        "LV": "1",
        "Item Description": "BEEF CHEEK WAGYU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019646001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4501230",
        "Last Price Paid": "$9.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019646",
        "Product Status": "Active"
    },
    {
        "Root": "10002095",
        "LV": "1",
        "Item Description": "JUICE POM POMEGRANATE 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002095001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "602030",
        "Last Price Paid": "$4.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002095",
        "Product Status": "Active"
    },
    {
        "Root": "10002095",
        "LV": "1",
        "Item Description": "JUICE POM POMEGRANATE 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002095001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "32135",
        "Last Price Paid": "$4.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002095",
        "Product Status": "Active"
    },
    {
        "Root": "10005703",
        "LV": "1",
        "Item Description": "SHEET BISCUIT VANILLA THICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005703001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.27",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005703",
        "Product Status": "Active"
    },
    {
        "Root": "10080790",
        "LV": "2",
        "Item Description": "BEEF PATTY GRND 160/2Z ANGUS DISN FZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080790002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1090027",
        "Last Price Paid": "$4.10",
        "Case Pack": "0.125",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10080790",
        "Product Status": "Active"
    },
    {
        "Root": "10004147",
        "LV": "2",
        "Item Description": "SEA SALT SMOKED MALDON 1.1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004147002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SAL054",
        "Last Price Paid": "$20.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004147",
        "Product Status": "Active"
    },
    {
        "Root": "10100851",
        "LV": "1",
        "Item Description": "SAUCE PASTA MNARA TMTO CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7273566",
        "Last Price Paid": "$6.73",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100851",
        "Product Status": "Active"
    },
    {
        "Root": "10003691",
        "LV": "1",
        "Item Description": "MARMALADE APRICOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "H200...{ANNABE",
        "Last Price Paid": "$116.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003691",
        "Product Status": "Active"
    },
    {
        "Root": "10001211",
        "LV": "1",
        "Item Description": "OCTOPUS SPANISH FRZN SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001211001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZFISHSQ001",
        "Last Price Paid": "$7.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001211",
        "Product Status": "Active"
    },
    {
        "Root": "10001211",
        "LV": "1",
        "Item Description": "OCTOPUS SPANISH FRZN SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001211001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "18630060",
        "Last Price Paid": "$7.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001211",
        "Product Status": "Active"
    },
    {
        "Root": "10002326",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED CREMONT COW GOAT 6/5OZ WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "181016",
        "Last Price Paid": "$6.53",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002326",
        "Product Status": "Active"
    },
    {
        "Root": "10003345",
        "LV": "1",
        "Item Description": "SYRUP HFCS SWEETENER 5G F",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9679549",
        "Last Price Paid": "$22.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003345",
        "Product Status": "Active"
    },
    {
        "Root": "10102696",
        "LV": "1",
        "Item Description": "JUICE BOLTHOUSE STRAWBERRY BANANA SMOOTHIE 6/15 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102696001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480506",
        "Last Price Paid": "$3.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102696",
        "Product Status": "Active"
    },
    {
        "Root": "10016452",
        "LV": "1",
        "Item Description": "SYRUP CUCUMBER MONIN LTR PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016452",
        "Product Status": "Active"
    },
    {
        "Root": "10001586",
        "LV": "1",
        "Item Description": "ASPARAGUS JUMBO/XLARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ASP11J",
        "Last Price Paid": "$29.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001586",
        "Product Status": "Active"
    },
    {
        "Root": "10001586",
        "LV": "1",
        "Item Description": "ASPARAGUS JUMBO/XLARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20015",
        "Last Price Paid": "$29.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001586",
        "Product Status": "Active"
    },
    {
        "Root": "10001586",
        "LV": "1",
        "Item Description": "ASPARAGUS JUMBO/XLARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401016",
        "Last Price Paid": "$29.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001586",
        "Product Status": "Active"
    },
    {
        "Root": "10022041",
        "LV": "1",
        "Item Description": "VINEGAR BALSAMIN GIUSTI 3 MEDAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "VI66",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022041",
        "Product Status": "Active"
    },
    {
        "Root": "10015062",
        "LV": "1",
        "Item Description": "LEAVES NASTURTIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22060",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015062",
        "Product Status": "Active"
    },
    {
        "Root": "10015062",
        "LV": "1",
        "Item Description": "LEAVES NASTURTIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480392",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015062",
        "Product Status": "Active"
    },
    {
        "Root": "10005636",
        "LV": "1",
        "Item Description": "COOKIE CHOCOLATE CHIP WRAPPED SINGLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005636",
        "Product Status": "Active"
    },
    {
        "Root": "10002416",
        "LV": "1",
        "Item Description": "CHEESE AMERICAN WHITE SLICED .5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8340770",
        "Last Price Paid": "$5.56",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002416",
        "Product Status": "Active"
    },
    {
        "Root": "10100348",
        "LV": "1",
        "Item Description": "POPCORN BAGS SALTED INDIVIDUAL  50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "BBUTTER5O",
        "Last Price Paid": "$3.25",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100348",
        "Product Status": "Active"
    },
    {
        "Root": "10105115",
        "LV": "1",
        "Item Description": "SAUCE BBQ KOREAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105115001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1789742",
        "Last Price Paid": "$13.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105115",
        "Product Status": "Active"
    },
    {
        "Root": "10029966",
        "LV": "2",
        "Item Description": "LIQUEUR FERNET BRANCA MENTA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029966002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "797456",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029966",
        "Product Status": "Active"
    },
    {
        "Root": "10095628",
        "LV": "1",
        "Item Description": "WINE RED PINOT NOIR J CHRISTOPHER 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "315389",
        "Last Price Paid": "$43.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095628",
        "Product Status": "Active"
    },
    {
        "Root": "10012168",
        "LV": "1",
        "Item Description": "SALMON COPPER RIVER KING FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012168001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF208",
        "Last Price Paid": "$45.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012168",
        "Product Status": "Active"
    },
    {
        "Root": "10012168",
        "LV": "1",
        "Item Description": "SALMON COPPER RIVER KING FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012168001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-01433",
        "Last Price Paid": "$45.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012168",
        "Product Status": "Active"
    },
    {
        "Root": "10031952",
        "LV": "1",
        "Item Description": "DIVINE DONUT DECAF BEAN1RETAIL JOFFREYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D381B-21J",
        "Last Price Paid": "$7.14",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031952",
        "Product Status": "Active"
    },
    {
        "Root": "10013588",
        "LV": "1",
        "Item Description": "POINSETTA SPRAY RED/GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NTBL1",
        "Last Price Paid": "$28.13",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013588",
        "Product Status": "Active"
    },
    {
        "Root": "10002686",
        "LV": "1",
        "Item Description": "CRACKER CRUMBS GRAHAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002686001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002686",
        "Product Status": "Active"
    },
    {
        "Root": "10041512",
        "LV": "4",
        "Item Description": "STRAW PAPER BLACK WRAPPED 7.75 IN 3000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041512002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10041512",
        "Product Status": "Active"
    },
    {
        "Root": "10001698",
        "LV": "1",
        "Item Description": "HERB DILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001698001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001698",
        "Product Status": "Active"
    },
    {
        "Root": "10001698",
        "LV": "1",
        "Item Description": "HERB DILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001698001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24070",
        "Last Price Paid": "$9.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001698",
        "Product Status": "Active"
    },
    {
        "Root": "10001698",
        "LV": "1",
        "Item Description": "HERB DILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001698001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450098",
        "Last Price Paid": "$9.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001698",
        "Product Status": "Active"
    },
    {
        "Root": "10009066",
        "LV": "2",
        "Item Description": "SYRAH FESS PARKER RODNY VIN 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009066001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9155302",
        "Last Price Paid": "$31.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009066",
        "Product Status": "Active"
    },
    {
        "Root": "10028949",
        "LV": "1",
        "Item Description": "CAKE BASE FLAT CKBFLAT10 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51002008",
        "Last Price Paid": "$0.23",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028949",
        "Product Status": "Active"
    },
    {
        "Root": "10008644",
        "LV": "3",
        "Item Description": "CHAMP BILLECART BLANC DE BLANC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008644002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9135567",
        "Last Price Paid": "$66.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008644",
        "Product Status": "Active"
    },
    {
        "Root": "10094788",
        "LV": "1",
        "Item Description": "TRANSFER SHEETS CONGRATULATIONS GOLD 16\" X 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65662",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094788",
        "Product Status": "Active"
    },
    {
        "Root": "10100831",
        "LV": "1",
        "Item Description": "CHEESE ST ALBRAY WASH RIND SOFT RIPENED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100831001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "44679",
        "Last Price Paid": "$14.30",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100831",
        "Product Status": "Active"
    },
    {
        "Root": "10045063",
        "LV": "1",
        "Item Description": "MELON CANTALOUPE CUBED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "594064",
        "Last Price Paid": "$14.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045063",
        "Product Status": "Active"
    },
    {
        "Root": "10045063",
        "LV": "1",
        "Item Description": "MELON CANTALOUPE CUBED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-001D",
        "Last Price Paid": "$14.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045063",
        "Product Status": "Active"
    },
    {
        "Root": "10003982",
        "LV": "1",
        "Item Description": "SPICE RAS EL HANOUT 20Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "107910",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003982",
        "Product Status": "Active"
    },
    {
        "Root": "10038388",
        "LV": "1",
        "Item Description": "SWEET & SALTY KETTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "SSKETTLE-PB16",
        "Last Price Paid": "$34.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038388",
        "Product Status": "Active"
    },
    {
        "Root": "10108749",
        "LV": "1",
        "Item Description": "GANACHERY SKULLS DISNEY TR SHT 4X4CM 3000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175890000",
        "Last Price Paid": "$0.12",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108749",
        "Product Status": "Active"
    },
    {
        "Root": "10006415",
        "LV": "1",
        "Item Description": "TOOTHPICK WOOD CELLO WRPD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006415001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "407173",
        "Last Price Paid": "$0.00",
        "Case Pack": "12000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006415",
        "Product Status": "Active"
    },
    {
        "Root": "10000338",
        "LV": "1",
        "Item Description": "ONION SPANISH DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30717",
        "Last Price Paid": "$7.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000338",
        "Product Status": "Active"
    },
    {
        "Root": "10000338",
        "LV": "1",
        "Item Description": "ONION SPANISH DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-002-14",
        "Last Price Paid": "$7.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000338",
        "Product Status": "Active"
    },
    {
        "Root": "10000338",
        "LV": "1",
        "Item Description": "ONION SPANISH DICED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514585",
        "Last Price Paid": "$7.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000338",
        "Product Status": "Active"
    },
    {
        "Root": "10012625",
        "LV": "1",
        "Item Description": "TATSOI MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04TT-33-L",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012625",
        "Product Status": "Active"
    },
    {
        "Root": "10000119",
        "LV": "2",
        "Item Description": "FRANKFURTERS SMALL 7/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000119002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.99",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000119",
        "Product Status": "Active"
    },
    {
        "Root": "10011307",
        "LV": "1",
        "Item Description": "SB CUP HOT 16Z WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011307",
        "Product Status": "Active"
    },
    {
        "Root": "10013669",
        "LV": "1",
        "Item Description": "BEER FOUNDERS ALL DAY IPA 12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "19146",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013669",
        "Product Status": "Active"
    },
    {
        "Root": "10018556",
        "LV": "1",
        "Item Description": "MOSCATO VIETTI ASTI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "747047",
        "Last Price Paid": "$12.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018556",
        "Product Status": "Active"
    },
    {
        "Root": "10004741",
        "LV": "1",
        "Item Description": "BEAN NAVY DRIED BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004741001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5844535",
        "Last Price Paid": "$38.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004741",
        "Product Status": "Active"
    },
    {
        "Root": "10023311",
        "LV": "1",
        "Item Description": "CHOC WHT FRAME LG 6 1/2 X 8 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW50662W",
        "Last Price Paid": "$11.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023311",
        "Product Status": "Active"
    },
    {
        "Root": "10007677",
        "LV": "2",
        "Item Description": "MOSCATO NONINO GRAPPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007677001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99690",
        "Last Price Paid": "$49.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007677",
        "Product Status": "Active"
    },
    {
        "Root": "10028865",
        "LV": "1",
        "Item Description": "ALE KEG STONE DELICIOUS IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2664",
        "Last Price Paid": "$189.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028865",
        "Product Status": "Active"
    },
    {
        "Root": "10000679",
        "LV": "1",
        "Item Description": "SAUSAGE CHORIZO PALACIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3600017",
        "Last Price Paid": "$97.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000679",
        "Product Status": "Active"
    },
    {
        "Root": "10030475",
        "LV": "1",
        "Item Description": "POWDER KASHMIRI RED CHILI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI922",
        "Last Price Paid": "$21.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030475",
        "Product Status": "Active"
    },
    {
        "Root": "10002730",
        "LV": "3",
        "Item Description": "CEREAL RAISIN BRAN BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002730003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5001771",
        "Last Price Paid": "$10.44",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002730",
        "Product Status": "Active"
    },
    {
        "Root": "10027864",
        "LV": "1",
        "Item Description": "DRINK MIX FRUIT PUNCH 2 GALLON YILED CRYSTAL LI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2323937",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027864",
        "Product Status": "Active"
    },
    {
        "Root": "10027429",
        "LV": "1",
        "Item Description": "CUP ESPNWWS RUN DISNEY 22 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G22-CLEAR",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027429",
        "Product Status": "Active"
    },
    {
        "Root": "10005799",
        "LV": "1",
        "Item Description": "MIX PIZZA CRUST GF BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101725",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005799",
        "Product Status": "Active"
    },
    {
        "Root": "10014614",
        "LV": "1",
        "Item Description": "MUSTARD DRY SEEDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480411",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014614",
        "Product Status": "Active"
    },
    {
        "Root": "10030267",
        "LV": "1",
        "Item Description": "SUGAR CANDY CANE ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030267",
        "Product Status": "Active"
    },
    {
        "Root": "10028593",
        "LV": "1",
        "Item Description": "RUM MALIBU PINEAPPLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "68333",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028593",
        "Product Status": "Active"
    },
    {
        "Root": "10035811",
        "LV": "1",
        "Item Description": "CAVIAR OSSETRA MALOSSOL 100 GRAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "CAVOSS003",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035811",
        "Product Status": "Active"
    },
    {
        "Root": "10087593",
        "LV": "1",
        "Item Description": "GLAZE MIRROR GOLD 5KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6460060000",
        "Last Price Paid": "$62.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087593",
        "Product Status": "Active"
    },
    {
        "Root": "10031533",
        "LV": "1",
        "Item Description": "TOPPING RASPBERRY SAUCE PLATESCRAPERS SQUEEZE BOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4266805",
        "Last Price Paid": "$3.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031533",
        "Product Status": "Active"
    },
    {
        "Root": "10004030",
        "LV": "1",
        "Item Description": "GAS CO-2 BULK REFILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004030001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004030",
        "Product Status": "Active"
    },
    {
        "Root": "10004885",
        "LV": "1",
        "Item Description": "SALT SEA HAWAIIAN 40Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "624011",
        "Last Price Paid": "$39.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004885",
        "Product Status": "Active"
    },
    {
        "Root": "10004885",
        "LV": "1",
        "Item Description": "SALT SEA HAWAIIAN 40Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD146",
        "Last Price Paid": "$39.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004885",
        "Product Status": "Active"
    },
    {
        "Root": "10016063",
        "LV": "1",
        "Item Description": "SPICE BBQ SEASONING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3237856",
        "Last Price Paid": "$13.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016063",
        "Product Status": "Active"
    },
    {
        "Root": "10004527",
        "LV": "1",
        "Item Description": "CHOC MICKEY 3 INCH DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004527",
        "Product Status": "Active"
    },
    {
        "Root": "10088180",
        "LV": "1",
        "Item Description": "BEER DRAFT PLAYALINDA MAPLE COOKIE 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14434",
        "Last Price Paid": "$239.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088180",
        "Product Status": "Active"
    },
    {
        "Root": "10005016",
        "LV": "1",
        "Item Description": "SPRINKLES LAVENDAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "11004",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005016",
        "Product Status": "Active"
    },
    {
        "Root": "10000643",
        "LV": "1",
        "Item Description": "BEEF OXTAIL CUT 2X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000643001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1100518",
        "Last Price Paid": "$7.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000643",
        "Product Status": "Active"
    },
    {
        "Root": "10037133",
        "LV": "1",
        "Item Description": "BREAD GF SANDWICH 2-PACK STANDARD BLOOMFIELD FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "14443",
        "Last Price Paid": "$1.05",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037133",
        "Product Status": "Active"
    },
    {
        "Root": "10001849",
        "LV": "1",
        "Item Description": "POTATO SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001849001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001849",
        "Product Status": "Active"
    },
    {
        "Root": "10002480",
        "LV": "1",
        "Item Description": "PEPPERDEWS SWEET RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002480001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-30535",
        "Last Price Paid": "$5.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002480",
        "Product Status": "Active"
    },
    {
        "Root": "10005314",
        "LV": "1",
        "Item Description": "SPRAY FOOD SHELLCK CHEF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "RC661323",
        "Last Price Paid": "$46.43",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005314",
        "Product Status": "Active"
    },
    {
        "Root": "10041161",
        "LV": "2",
        "Item Description": "STRAW PAPER WHITE UNWRAPPED 7.75 IN 5000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041161001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10041161",
        "Product Status": "Active"
    },
    {
        "Root": "10095568",
        "LV": "1",
        "Item Description": "BITTERS HELLA MEXICAN CHOCOLATE 5OZ/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095568",
        "Product Status": "Active"
    },
    {
        "Root": "10022397",
        "LV": "1",
        "Item Description": "CHOC WHT SQ 1.5 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022397001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63204W",
        "Last Price Paid": "$1.00",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022397",
        "Product Status": "Active"
    },
    {
        "Root": "10014053",
        "LV": "2",
        "Item Description": "COCOA BUTTER CARDINAL RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014053002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302115C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014053",
        "Product Status": "Active"
    },
    {
        "Root": "10014053",
        "LV": "2",
        "Item Description": "COCOA BUTTER CARDINAL RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014053002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5863110000",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014053",
        "Product Status": "Active"
    },
    {
        "Root": "10015546",
        "LV": "2",
        "Item Description": "BAR GRANOLA HONEY N OAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015546002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302386",
        "Last Price Paid": "$0.53",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015546",
        "Product Status": "Active"
    },
    {
        "Root": "10007498",
        "LV": "1",
        "Item Description": "NEBIOLO VAJRA ALBE BAROLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "952804",
        "Last Price Paid": "$26.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007498",
        "Product Status": "Active"
    },
    {
        "Root": "10004504",
        "LV": "1",
        "Item Description": "CHOC BLOSSOM DRK CURL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004504001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2004...{DOBLA{",
        "Last Price Paid": "$75.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004504",
        "Product Status": "Active"
    },
    {
        "Root": "10014288",
        "LV": "1",
        "Item Description": "WATER BTL PERRIER SPARKLING GLASS 11OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3043353",
        "Last Price Paid": "$0.92",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014288",
        "Product Status": "Active"
    },
    {
        "Root": "10081600",
        "LV": "1",
        "Item Description": "RUM BACARDI RASPBERRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081600",
        "Product Status": "Active"
    },
    {
        "Root": "10005357",
        "LV": "1",
        "Item Description": "SUGAR SNOWFLAKE ASSORTMEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "43057",
        "Last Price Paid": "$0.10",
        "Case Pack": "138",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005357",
        "Product Status": "Active"
    },
    {
        "Root": "10002958",
        "LV": "1",
        "Item Description": "TEA NIGHTLY CALMING BG IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002958",
        "Product Status": "Active"
    },
    {
        "Root": "10100876",
        "LV": "2",
        "Item Description": "BEEF/LAMB  GYRO CONE LG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100876002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "128",
        "Last Price Paid": "$106.95",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100876",
        "Product Status": "Active"
    },
    {
        "Root": "10010090",
        "LV": "2",
        "Item Description": "BEER ERDINGER DUNKEL 16.9Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010090001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010090",
        "Product Status": "Active"
    },
    {
        "Root": "10030320",
        "LV": "1",
        "Item Description": "TWIST TIES RED AND WHITE STRIPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "4015",
        "Last Price Paid": "$0.31",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030320",
        "Product Status": "Active"
    },
    {
        "Root": "10005013",
        "LV": "1",
        "Item Description": "FONDANT ROLLED SATIN ICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C50311",
        "Last Price Paid": "$62.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005013",
        "Product Status": "Active"
    },
    {
        "Root": "10106373",
        "LV": "1",
        "Item Description": "RICE, NIIGATA KOSHIIBUKI 20 LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY084",
        "Last Price Paid": "$76.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106373",
        "Product Status": "Active"
    },
    {
        "Root": "10018488",
        "LV": "1",
        "Item Description": "ASPARAGUS MIXED 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MASP-26",
        "Last Price Paid": "$60.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018488",
        "Product Status": "Active"
    },
    {
        "Root": "10017973",
        "LV": "1",
        "Item Description": "LIQ BELLE DE BRILLE PEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "721085",
        "Last Price Paid": "$38.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017973",
        "Product Status": "Active"
    },
    {
        "Root": "10003271",
        "LV": "1",
        "Item Description": "SYRUP SWEET BLNED NON-NUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9692138",
        "Last Price Paid": "$101.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003271",
        "Product Status": "Active"
    },
    {
        "Root": "10005628",
        "LV": "1",
        "Item Description": "BREAD ROLL CIABATA SMALL 40 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.49",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005628",
        "Product Status": "Active"
    },
    {
        "Root": "10002989",
        "LV": "1",
        "Item Description": "DRESSING ITALIAN FF 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.17",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002989",
        "Product Status": "Active"
    },
    {
        "Root": "10013632",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK 65% VALRHONA DISNEY BLEND 1/26.4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "128890",
        "Last Price Paid": "$228.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013632",
        "Product Status": "Active"
    },
    {
        "Root": "10009501",
        "LV": "1",
        "Item Description": "BOURBON BOOKER NOE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597277",
        "Last Price Paid": "$82.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009501",
        "Product Status": "Active"
    },
    {
        "Root": "10001662",
        "LV": "1",
        "Item Description": "BOK CHOY 55# CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "402055",
        "Last Price Paid": "$30.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001662",
        "Product Status": "Active"
    },
    {
        "Root": "10001662",
        "LV": "1",
        "Item Description": "BOK CHOY 55# CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001662",
        "Product Status": "Active"
    },
    {
        "Root": "10034323",
        "LV": "1",
        "Item Description": "TRANSFER SHEETS CONTEMPORARY REPETITIVE SILVER PR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034323001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65663S",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034323",
        "Product Status": "Active"
    },
    {
        "Root": "10018967",
        "LV": "1",
        "Item Description": "TRAY TACO TRIAD 7X8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51296001",
        "Last Price Paid": "$0.26",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018967",
        "Product Status": "Active"
    },
    {
        "Root": "10033790",
        "LV": "1",
        "Item Description": "DIVING INTO HAMPTON WATER ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "571807",
        "Last Price Paid": "$13.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033790",
        "Product Status": "Active"
    },
    {
        "Root": "10001025",
        "LV": "1",
        "Item Description": "COD SALT 1 POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001025001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "65-00145",
        "Last Price Paid": "$16.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001025",
        "Product Status": "Active"
    },
    {
        "Root": "10001025",
        "LV": "1",
        "Item Description": "COD SALT 1 POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001025001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "17205010",
        "Last Price Paid": "$16.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001025",
        "Product Status": "Active"
    },
    {
        "Root": "10030008",
        "LV": "2",
        "Item Description": "CRISPEARLS CHOCOLATE MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030008002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C999",
        "Last Price Paid": "$11.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030008",
        "Product Status": "Active"
    },
    {
        "Root": "10001102",
        "LV": "1",
        "Item Description": "SHRIMP RAW P&D SKWR 26/30 SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001102001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2513281",
        "Last Price Paid": "$104.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001102",
        "Product Status": "Active"
    },
    {
        "Root": "10001635",
        "LV": "1",
        "Item Description": "TOMATO ROASTED RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598091",
        "Last Price Paid": "$23.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001635",
        "Product Status": "Active"
    },
    {
        "Root": "10001635",
        "LV": "1",
        "Item Description": "TOMATO ROASTED RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "598091",
        "Last Price Paid": "$23.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001635",
        "Product Status": "Active"
    },
    {
        "Root": "10016585",
        "LV": "1",
        "Item Description": "COASTER TIFFINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731040",
        "Last Price Paid": "$0.09",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016585",
        "Product Status": "Active"
    },
    {
        "Root": "10003821",
        "LV": "1",
        "Item Description": "FILLING CARAMEL CRAKINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CARA 656",
        "Last Price Paid": "$96.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003821",
        "Product Status": "Active"
    },
    {
        "Root": "10109536",
        "LV": "1",
        "Item Description": "BEER KEG SAM ADAMS BOSTON LAGER 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109536",
        "Product Status": "Active"
    },
    {
        "Root": "10004157",
        "LV": "2",
        "Item Description": "SB CHOCOLATE DARK CURLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004157001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11009033",
        "Last Price Paid": "$4.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004157",
        "Product Status": "Active"
    },
    {
        "Root": "10021814",
        "LV": "1",
        "Item Description": "OATS ROLLED GF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "101561",
        "Last Price Paid": "$43.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021814",
        "Product Status": "Active"
    },
    {
        "Root": "10002393",
        "LV": "1",
        "Item Description": "BUTTER CULTURED SALTED VERMONT CREAMERY 12/16 OUNC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "880",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002393",
        "Product Status": "Active"
    },
    {
        "Root": "10006798",
        "LV": "1",
        "Item Description": "CHARGE SET-UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006798",
        "Product Status": "Active"
    },
    {
        "Root": "10006798",
        "LV": "1",
        "Item Description": "CHARGE SET-UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000006360",
        "Vendor": "REDDY ICE CORPORATION",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006798",
        "Product Status": "Active"
    },
    {
        "Root": "10006798",
        "LV": "1",
        "Item Description": "CHARGE SET-UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006798",
        "Product Status": "Active"
    },
    {
        "Root": "10025317",
        "LV": "1",
        "Item Description": "BAG DELI DUBLVIEW 4.25 X 2.75 X 16.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008211",
        "Last Price Paid": "$0.30",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025317",
        "Product Status": "Active"
    },
    {
        "Root": "10102511",
        "LV": "1",
        "Item Description": "POWERADE FRUIT PUNCH 2.5GAL BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102511001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "2370298",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102511",
        "Product Status": "Active"
    },
    {
        "Root": "10029956",
        "LV": "1",
        "Item Description": "SUGAR ORNAMENT MINI ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "43673",
        "Last Price Paid": "$0.09",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029956",
        "Product Status": "Active"
    },
    {
        "Root": "10036794",
        "LV": "1",
        "Item Description": "DRIED RASPBERRIES NATIERRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "114965",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036794",
        "Product Status": "Active"
    },
    {
        "Root": "10015180",
        "LV": "2",
        "Item Description": "SPICE SALT SEA SMOKED FUMME DE SEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015180002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SHK-FUM",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015180",
        "Product Status": "Active"
    },
    {
        "Root": "10034725",
        "LV": "1",
        "Item Description": "PANETTONE FIASCONARO TRADIZONALE 2.2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY936",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034725",
        "Product Status": "Active"
    },
    {
        "Root": "10012694",
        "LV": "1",
        "Item Description": "HALIBUT WHOLE H&G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012694001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-00340",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012694",
        "Product Status": "Active"
    },
    {
        "Root": "10045637",
        "LV": "2",
        "Item Description": "LENTILS FRENCH GREEN 6/18 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045637002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B26",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10045637",
        "Product Status": "Active"
    },
    {
        "Root": "10000464",
        "LV": "1",
        "Item Description": "CAKE COFFEE APRICOT 10 INCH SLICED 12 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000464001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000464",
        "Product Status": "Active"
    },
    {
        "Root": "10092551",
        "LV": "1",
        "Item Description": "SODA PET COCA COLA CREATIONS LTO 20OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "411427",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092551",
        "Product Status": "Active"
    },
    {
        "Root": "10033914",
        "LV": "2",
        "Item Description": "SUGAR-SPOOKTACULAR ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033914002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "50724",
        "Last Price Paid": "",
        "Case Pack": "84",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033914",
        "Product Status": "Active"
    },
    {
        "Root": "10090675",
        "LV": "1",
        "Item Description": "DRESSING RANCH BUTTERMILK KENS 1.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090675",
        "Product Status": "Active"
    },
    {
        "Root": "10028950",
        "LV": "1",
        "Item Description": "WALL CAKE DOME SMOOTH CKDMSM105 10 X 5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51178166",
        "Last Price Paid": "$0.57",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028950",
        "Product Status": "Active"
    },
    {
        "Root": "10033296",
        "LV": "1",
        "Item Description": "RUM APPLETON 12 YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "964123",
        "Last Price Paid": "$36.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033296",
        "Product Status": "Active"
    },
    {
        "Root": "10006970",
        "LV": "1",
        "Item Description": "SPARK CHARLE FERE BRT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006970001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006970",
        "Product Status": "Active"
    },
    {
        "Root": "10004499",
        "LV": "1",
        "Item Description": "CANDIED ORANGE SLICE JELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA103",
        "Last Price Paid": "$26.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004499",
        "Product Status": "Active"
    },
    {
        "Root": "10004392",
        "LV": "1",
        "Item Description": "SB TEAV EARL GREY CS-100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11043721",
        "Last Price Paid": "$0.24",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004392",
        "Product Status": "Active"
    },
    {
        "Root": "10001644",
        "LV": "2",
        "Item Description": "SALAD TOSSED 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001644002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593131",
        "Last Price Paid": "$1.58",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001644",
        "Product Status": "Active"
    },
    {
        "Root": "10002879",
        "LV": "1",
        "Item Description": "TEA CAMOMILE TWINING IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002879",
        "Product Status": "Active"
    },
    {
        "Root": "10106448",
        "LV": "1",
        "Item Description": "LINER BASKET 12X12 LW 1COLOR GRAND FLORIDIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51361212",
        "Last Price Paid": "$0.05",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106448",
        "Product Status": "Active"
    },
    {
        "Root": "10010056",
        "LV": "1",
        "Item Description": "BEER CORONA CAN 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11319",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010056",
        "Product Status": "Active"
    },
    {
        "Root": "10107919",
        "LV": "1",
        "Item Description": "NEVAS 750ML SPARKLING WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "NVS1",
        "Last Price Paid": "$16.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107919",
        "Product Status": "Active"
    },
    {
        "Root": "10019176",
        "LV": "1",
        "Item Description": "WHISKEY HUDSON DO THE RYE THING 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9448423",
        "Last Price Paid": "$33.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019176",
        "Product Status": "Active"
    },
    {
        "Root": "10017014",
        "LV": "1",
        "Item Description": "SHELL SWT 2.2\" ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017014001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD300",
        "Last Price Paid": "$0.35",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017014",
        "Product Status": "Active"
    },
    {
        "Root": "10031907",
        "LV": "1",
        "Item Description": "FREEZE DRIED RASPBERRIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672082",
        "Last Price Paid": "$55.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031907",
        "Product Status": "Active"
    },
    {
        "Root": "10015548",
        "LV": "1",
        "Item Description": "STIRRER COFFEE WRPD 5.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "503526",
        "Last Price Paid": "$0.10",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015548",
        "Product Status": "Active"
    },
    {
        "Root": "10002052",
        "LV": "1",
        "Item Description": "COCONUT EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002052",
        "Product Status": "Active"
    },
    {
        "Root": "10038461",
        "LV": "1",
        "Item Description": "BEER KEG BELLS GAMORREAN ALE SWGE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038461001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "62987",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038461",
        "Product Status": "Active"
    },
    {
        "Root": "10004957",
        "LV": "2",
        "Item Description": "GLAZE NEUTRAL MIRROR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004957002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SK6002",
        "Last Price Paid": "$64.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004957",
        "Product Status": "Active"
    },
    {
        "Root": "10004957",
        "LV": "2",
        "Item Description": "GLAZE NEUTRAL MIRROR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004957002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ111734",
        "Last Price Paid": "$64.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004957",
        "Product Status": "Active"
    },
    {
        "Root": "10102812",
        "LV": "1",
        "Item Description": "BEER BREWDOG HOPPY CHRISTMAS 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15383",
        "Last Price Paid": "$204.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102812",
        "Product Status": "Active"
    },
    {
        "Root": "10040591",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED GRUYERE 4/5 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040591001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "2445",
        "Last Price Paid": "$9.89",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040591",
        "Product Status": "Active"
    },
    {
        "Root": "10005025",
        "LV": "2",
        "Item Description": "TEA ASSAM ORG 14.1Z LSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005025001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3324",
        "Last Price Paid": "$32.40",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005025",
        "Product Status": "Active"
    },
    {
        "Root": "10002978",
        "LV": "1",
        "Item Description": "KETCHUP POUCH 6/114Z RD G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.87",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002978",
        "Product Status": "Active"
    },
    {
        "Root": "10003820",
        "LV": "1",
        "Item Description": "POWDER COCONUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA4349",
        "Last Price Paid": "$14.99",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003820",
        "Product Status": "Active"
    },
    {
        "Root": "10001165",
        "LV": "1",
        "Item Description": "SALMON FLT STERLING SKLS BNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001165001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "ADIAN STERLING",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001165",
        "Product Status": "Active"
    },
    {
        "Root": "10001165",
        "LV": "1",
        "Item Description": "SALMON FLT STERLING SKLS BNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001165001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-01454",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001165",
        "Product Status": "Active"
    },
    {
        "Root": "10080957",
        "LV": "2",
        "Item Description": "SMOOTHIE MM PREMIUM MANGO 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080957001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10080957",
        "Product Status": "Active"
    },
    {
        "Root": "10010017",
        "LV": "1",
        "Item Description": "DRAFT ICHIBAN KIRIN LG HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010017001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40102",
        "Last Price Paid": "$142.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010017",
        "Product Status": "Active"
    },
    {
        "Root": "10009491",
        "LV": "1",
        "Item Description": "BOURBON JIM BEAM 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.43",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009491",
        "Product Status": "Active"
    },
    {
        "Root": "10038378",
        "LV": "1",
        "Item Description": "PHOTOCAKE PREMIUM EDIBLE 1/4 SHEET EXTD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "44626",
        "Last Price Paid": "$1.21",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038378",
        "Product Status": "Active"
    },
    {
        "Root": "10034716",
        "LV": "1",
        "Item Description": "PORK SHANK PIG WING 2OZ CKD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1420200",
        "Last Price Paid": "$0.85",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034716",
        "Product Status": "Active"
    },
    {
        "Root": "10018294",
        "LV": "1",
        "Item Description": "OIL COTTONSEED MASTERCHEF 35#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5540504",
        "Last Price Paid": "$47.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018294",
        "Product Status": "Active"
    },
    {
        "Root": "10008783",
        "LV": "1",
        "Item Description": "CAB SAUV MOUTON ROTHSCHILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008783001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "750744",
        "Last Price Paid": "$1,204.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008783",
        "Product Status": "Active"
    },
    {
        "Root": "10004606",
        "LV": "1",
        "Item Description": "NOODLE EGG WIDE BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6328678",
        "Last Price Paid": "$13.62",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004606",
        "Product Status": "Active"
    },
    {
        "Root": "10001477",
        "LV": "1",
        "Item Description": "APPLE GRANNY SMITH 88CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001477001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480272",
        "Last Price Paid": "$0.57",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001477",
        "Product Status": "Active"
    },
    {
        "Root": "10001470",
        "LV": "1",
        "Item Description": "PEPPER POBLANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490130",
        "Last Price Paid": "$14.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001470",
        "Product Status": "Active"
    },
    {
        "Root": "10001470",
        "LV": "1",
        "Item Description": "PEPPER POBLANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001470",
        "Product Status": "Active"
    },
    {
        "Root": "10002881",
        "LV": "1",
        "Item Description": "TEA BLACKCURRANT 120/6BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002881001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "07524",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002881",
        "Product Status": "Active"
    },
    {
        "Root": "10036354",
        "LV": "1",
        "Item Description": "CHEESE DL521028 2.5 ROUND LABEL BLACK ON KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001094",
        "Last Price Paid": "$0.06",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036354",
        "Product Status": "Active"
    },
    {
        "Root": "10004212",
        "LV": "1",
        "Item Description": "SB TEAVANA ICED BLK 20 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11034437",
        "Last Price Paid": "$0.59",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004212",
        "Product Status": "Active"
    },
    {
        "Root": "10002389",
        "LV": "1",
        "Item Description": "CHEESE PARMESAN REGGIANO 20 MON 1/4 WHEEL 18LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002389001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "25160",
        "Last Price Paid": "$9.96",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002389",
        "Product Status": "Active"
    },
    {
        "Root": "10007454",
        "LV": "1",
        "Item Description": "PINOT N BELLE GLOS DAIRYMAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "552537",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007454",
        "Product Status": "Active"
    },
    {
        "Root": "10004674",
        "LV": "1",
        "Item Description": "DRIED FRUIT DATE IMPORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "282260",
        "Last Price Paid": "$46.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004674",
        "Product Status": "Active"
    },
    {
        "Root": "10007832",
        "LV": "1",
        "Item Description": "SANGIO CAPARZO BRUNELLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9679539",
        "Last Price Paid": "$35.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007832",
        "Product Status": "Active"
    },
    {
        "Root": "10015278",
        "LV": "1",
        "Item Description": "AMCHOOR POWDER 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO654",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015278",
        "Product Status": "Active"
    },
    {
        "Root": "10088552",
        "LV": "1",
        "Item Description": "SPICE CINNAMON STICKS BAG  6/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088552001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "00664",
        "Last Price Paid": "$5.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088552",
        "Product Status": "Active"
    },
    {
        "Root": "10032287",
        "LV": "1",
        "Item Description": "CHAMPAGNE POL ROGER SIR WINSTON CHURCHILL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "927505",
        "Last Price Paid": "$207.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032287",
        "Product Status": "Active"
    },
    {
        "Root": "10007898",
        "LV": "2",
        "Item Description": "SAKE HANA FUJI APPLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007898001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "434",
        "Last Price Paid": "$10.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007898",
        "Product Status": "Active"
    },
    {
        "Root": "10109651",
        "LV": "1",
        "Item Description": "CUP 8OZ HOT DW WHITE PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.10",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109651",
        "Product Status": "Active"
    },
    {
        "Root": "10007186",
        "LV": "1",
        "Item Description": "BLND CONUNDRUM RED CALF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "599073",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007186",
        "Product Status": "Active"
    },
    {
        "Root": "10007186",
        "LV": "1",
        "Item Description": "BLND CONUNDRUM RED CALF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "599073",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007186",
        "Product Status": "Active"
    },
    {
        "Root": "10035025",
        "LV": "1",
        "Item Description": "SYRUP MONIN COOKIE BUTTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035025001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5247",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035025",
        "Product Status": "Active"
    },
    {
        "Root": "10002100",
        "LV": "1",
        "Item Description": "LETTUCE ICEBERG BY EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002100001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002100",
        "Product Status": "Active"
    },
    {
        "Root": "10044461",
        "LV": "1",
        "Item Description": "DECORATION QUINS FUSION MIX-COSMIC SKIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044461001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24519",
        "Last Price Paid": "$6.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044461",
        "Product Status": "Active"
    },
    {
        "Root": "10104936",
        "LV": "1",
        "Item Description": "BUCKET BASE 100TH ELITE8 TINK PLAT PURP 64OZ 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104936",
        "Product Status": "Active"
    },
    {
        "Root": "10001124",
        "LV": "1",
        "Item Description": "OCTOPUS FRZN WHOLE 2-4LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001124001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001124",
        "Product Status": "Active"
    },
    {
        "Root": "10001124",
        "LV": "1",
        "Item Description": "OCTOPUS FRZN WHOLE 2-4LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001124001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "71-00730",
        "Last Price Paid": "$5.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001124",
        "Product Status": "Active"
    },
    {
        "Root": "10001124",
        "LV": "1",
        "Item Description": "OCTOPUS FRZN WHOLE 2-4LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001124001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6270015",
        "Last Price Paid": "$5.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001124",
        "Product Status": "Active"
    },
    {
        "Root": "10006817",
        "LV": "1",
        "Item Description": "CHOC DARTH VADER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1885",
        "Last Price Paid": "$0.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006817",
        "Product Status": "Active"
    },
    {
        "Root": "10003702",
        "LV": "1",
        "Item Description": "SB SUGAR IN THE RAW-",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003702001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "186477",
        "Last Price Paid": "$0.01",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003702",
        "Product Status": "Active"
    },
    {
        "Root": "10006019",
        "LV": "1",
        "Item Description": "CUP 9Z PLAS WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006019001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006019",
        "Product Status": "Active"
    },
    {
        "Root": "10000470",
        "LV": "1",
        "Item Description": "BREAD BREAKFAST LEMON POPPY 8IN X 4IN SLICED 10 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000470",
        "Product Status": "Active"
    },
    {
        "Root": "10029202",
        "LV": "1",
        "Item Description": "SALAD STRAWBERRY SPRING MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1715202",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029202",
        "Product Status": "Active"
    },
    {
        "Root": "10025422",
        "LV": "2",
        "Item Description": "GEL PIPING VANILLA FLAVOR 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025422002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5305",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025422",
        "Product Status": "Active"
    },
    {
        "Root": "10027885",
        "LV": "1",
        "Item Description": "DEC NAME TAG W/ WEDGE 2 3/4\"X2\" LEGACY CSTM LOGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3595",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027885",
        "Product Status": "Active"
    },
    {
        "Root": "10039731",
        "LV": "1",
        "Item Description": "BEAN LENTIL CRIMSON 12/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "131370",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039731",
        "Product Status": "Active"
    },
    {
        "Root": "10034230",
        "LV": "1",
        "Item Description": "CUPCAKE MICKEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1815",
        "Last Price Paid": "$1.91",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034230",
        "Product Status": "Active"
    },
    {
        "Root": "10039338",
        "LV": "1",
        "Item Description": "TRANSFER SHEETS MNSSHP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65751",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039338",
        "Product Status": "Active"
    },
    {
        "Root": "10033489",
        "LV": "1",
        "Item Description": "SPRAY COCOA BUTTER DARK CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586008",
        "Last Price Paid": "$51.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033489",
        "Product Status": "Active"
    },
    {
        "Root": "10001726",
        "LV": "1",
        "Item Description": "SQUASH BABY PATTY PAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480110",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001726",
        "Product Status": "Active"
    },
    {
        "Root": "10001726",
        "LV": "1",
        "Item Description": "SQUASH BABY PATTY PAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001726",
        "Product Status": "Active"
    },
    {
        "Root": "10001726",
        "LV": "1",
        "Item Description": "SQUASH BABY PATTY PAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22318",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001726",
        "Product Status": "Active"
    },
    {
        "Root": "10041075",
        "LV": "1",
        "Item Description": "CHEESE BLUE OREGON ORG ROGUE CREAMERY 6LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041075001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "18083",
        "Last Price Paid": "$16.48",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041075",
        "Product Status": "Active"
    },
    {
        "Root": "10032684",
        "LV": "2",
        "Item Description": "BREAD ROLL BRIOCHE HOT DOG 6 TOPSLICE 32PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032684001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "3:LP",
        "Last Price Paid": "$0.40",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10032684",
        "Product Status": "Active"
    },
    {
        "Root": "10012616",
        "LV": "1",
        "Item Description": "BEAN CARMELLINI 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "20CA-2",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012616",
        "Product Status": "Active"
    },
    {
        "Root": "10000375",
        "LV": "1",
        "Item Description": "POTATO SWEET WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480470",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000375",
        "Product Status": "Active"
    },
    {
        "Root": "10000375",
        "LV": "1",
        "Item Description": "POTATO SWEET WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "27-001",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000375",
        "Product Status": "Active"
    },
    {
        "Root": "10002302",
        "LV": "1",
        "Item Description": "CREAM HVY V & A 40% UHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8340978",
        "Last Price Paid": "$5.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002302",
        "Product Status": "Active"
    },
    {
        "Root": "10033672",
        "LV": "1",
        "Item Description": "BULK JUMBO RED CUPCAKE LINER 9102",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003797",
        "Last Price Paid": "$0.08",
        "Case Pack": "660",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033672",
        "Product Status": "Active"
    },
    {
        "Root": "10037819",
        "LV": "1",
        "Item Description": "PEPPERS MIX W RED ONION DICED 1 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037819001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-017-1",
        "Last Price Paid": "$5.80",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037819",
        "Product Status": "Active"
    },
    {
        "Root": "10001512",
        "LV": "1",
        "Item Description": "TOMATO SUNDRIED JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001512",
        "Product Status": "Active"
    },
    {
        "Root": "10001512",
        "LV": "1",
        "Item Description": "TOMATO SUNDRIED JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410427",
        "Last Price Paid": "$16.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001512",
        "Product Status": "Active"
    },
    {
        "Root": "10001512",
        "LV": "1",
        "Item Description": "TOMATO SUNDRIED JULIENNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001512",
        "Product Status": "Active"
    },
    {
        "Root": "10006012",
        "LV": "1",
        "Item Description": "LID PLATE 7X9 GP PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006012",
        "Product Status": "Active"
    },
    {
        "Root": "10001645",
        "LV": "1",
        "Item Description": "CELERY STICK 2.5 X 3.8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-006-A",
        "Last Price Paid": "$14.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001645",
        "Product Status": "Active"
    },
    {
        "Root": "10001645",
        "LV": "1",
        "Item Description": "CELERY STICK 2.5 X 3.8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512525",
        "Last Price Paid": "$14.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001645",
        "Product Status": "Active"
    },
    {
        "Root": "10004481",
        "LV": "1",
        "Item Description": "ROLL SUSHI SPICY TUNA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004481",
        "Product Status": "Active"
    },
    {
        "Root": "10006382",
        "LV": "2",
        "Item Description": "NAPKIN DINNER 17X17 3PLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006382002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$64.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006382",
        "Product Status": "Active"
    },
    {
        "Root": "10002679",
        "LV": "1",
        "Item Description": "TOMATO DICED #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002679",
        "Product Status": "Active"
    },
    {
        "Root": "10003796",
        "LV": "1",
        "Item Description": "MIX, CAKE CHOC GF NAMASTE 16Z/6EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003796001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "839340",
        "Last Price Paid": "$7.09",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003796",
        "Product Status": "Active"
    },
    {
        "Root": "10041581",
        "LV": "1",
        "Item Description": "KIT CUTLERY CHROME HW K-F-S WHITE NAPKIN W/BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9664947",
        "Last Price Paid": "$0.79",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041581",
        "Product Status": "Active"
    },
    {
        "Root": "10102809",
        "LV": "1",
        "Item Description": "TEQUILA 818 BLANCO 750ML/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "511786",
        "Last Price Paid": "$28.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102809",
        "Product Status": "Active"
    },
    {
        "Root": "10028731",
        "LV": "1",
        "Item Description": "BEER KENTUCKY BURBON ALE VANILLA 1/6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "3877",
        "Last Price Paid": "$84.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028731",
        "Product Status": "Active"
    },
    {
        "Root": "10044782",
        "LV": "2",
        "Item Description": "CHEESE CHEDDAR SMOKED 2/2.5 LB PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044782001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5800",
        "Last Price Paid": "$4.61",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10044782",
        "Product Status": "Active"
    },
    {
        "Root": "10017976",
        "LV": "1",
        "Item Description": "GIN HAYMAN'S OLD TOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677480",
        "Last Price Paid": "$22.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017976",
        "Product Status": "Active"
    },
    {
        "Root": "10010288",
        "LV": "2",
        "Item Description": "LAGER FOSTER OIL 25Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010288001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10011",
        "Last Price Paid": "$2.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010288",
        "Product Status": "Active"
    },
    {
        "Root": "10094638",
        "LV": "1",
        "Item Description": "SIPPER PREM FG23 ORANGE BIRD 3.0 16OZ 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.16",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094638",
        "Product Status": "Active"
    },
    {
        "Root": "10009664",
        "LV": "1",
        "Item Description": "TEQ HERRADURA BLANCO LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9034223",
        "Last Price Paid": "$26.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009664",
        "Product Status": "Active"
    },
    {
        "Root": "10005901",
        "LV": "1",
        "Item Description": "BREAD CIABATTA 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "15023",
        "Last Price Paid": "$3.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005901",
        "Product Status": "Active"
    },
    {
        "Root": "10001400",
        "LV": "1",
        "Item Description": "MIX PETITE CITRUS MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481978",
        "Last Price Paid": "$14.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001400",
        "Product Status": "Active"
    },
    {
        "Root": "10095009",
        "LV": "1",
        "Item Description": "DECOR-COLOR SWEET POP BUTTERFLIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "23018",
        "Last Price Paid": "$0.11",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095009",
        "Product Status": "Active"
    },
    {
        "Root": "10000109",
        "LV": "1",
        "Item Description": "SAUSAGE CHORIZO LINKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000109001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "258C",
        "Last Price Paid": "$3.92",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000109",
        "Product Status": "Active"
    },
    {
        "Root": "10012976",
        "LV": "1",
        "Item Description": "BOWL 80Z BLCK CATERING APB80BL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11178108",
        "Last Price Paid": "$1.00",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012976",
        "Product Status": "Active"
    },
    {
        "Root": "10029728",
        "LV": "2",
        "Item Description": "BEEF CLOD HEART SKINNED CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029728002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2090101",
        "Last Price Paid": "$7.27",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029728",
        "Product Status": "Active"
    },
    {
        "Root": "10018055",
        "LV": "1",
        "Item Description": "CHARDONNAY VINA COBOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "372864",
        "Last Price Paid": "$34.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018055",
        "Product Status": "Active"
    },
    {
        "Root": "10010131",
        "LV": "2",
        "Item Description": "WATER FIUGGI STILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010131001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010131",
        "Product Status": "Active"
    },
    {
        "Root": "10010509",
        "LV": "1",
        "Item Description": "SONOMA CUTRER CHARDONNAY 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1033166",
        "Last Price Paid": "$8.96",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010509",
        "Product Status": "Active"
    },
    {
        "Root": "10103868",
        "LV": "1",
        "Item Description": "COLOR CB VLV SPRY GRN 13.5 FL OZ 400 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860020000",
        "Last Price Paid": "$53.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103868",
        "Product Status": "Active"
    },
    {
        "Root": "10004834",
        "LV": "1",
        "Item Description": "MAYONNAISE IND 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "204",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004834",
        "Product Status": "Active"
    },
    {
        "Root": "10108330",
        "LV": "1",
        "Item Description": "MINI BLINI PANCAKE 1.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-10000",
        "Last Price Paid": "$5.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108330",
        "Product Status": "Active"
    },
    {
        "Root": "10015634",
        "LV": "1",
        "Item Description": "CHARD DROUHIN MEURSAULT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "206225",
        "Last Price Paid": "$87.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015634",
        "Product Status": "Active"
    },
    {
        "Root": "10024021",
        "LV": "1",
        "Item Description": "SYRUP HI-C LIME FUB 2.5 GAL BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024021",
        "Product Status": "Active"
    },
    {
        "Root": "10016580",
        "LV": "2",
        "Item Description": "SMALL FRY BOX 4X4X2 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016580002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51301128",
        "Last Price Paid": "$0.24",
        "Case Pack": "700",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016580",
        "Product Status": "Active"
    },
    {
        "Root": "10035348",
        "LV": "1",
        "Item Description": "FLOUR CHESTNUT 5 LB BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FLO011",
        "Last Price Paid": "$99.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035348",
        "Product Status": "Active"
    },
    {
        "Root": "10019594",
        "LV": "1",
        "Item Description": "BOURBON STRAIGHT WIDOW JANE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550162",
        "Last Price Paid": "$56.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019594",
        "Product Status": "Active"
    },
    {
        "Root": "10006361",
        "LV": "1",
        "Item Description": "STIRRER COFFEE WOOD 7.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7356942",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006361",
        "Product Status": "Active"
    },
    {
        "Root": "10002168",
        "LV": "1",
        "Item Description": "LEMONADE FROZEN 12OZ SOFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002168",
        "Product Status": "Active"
    },
    {
        "Root": "10019891",
        "LV": "1",
        "Item Description": "PEPPERMINT PIECES CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2091",
        "Last Price Paid": "$62.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019891",
        "Product Status": "Active"
    },
    {
        "Root": "10000315",
        "LV": "1",
        "Item Description": "PEA TENDRILS GOLDEN FARMERS BOUNTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000315",
        "Product Status": "Active"
    },
    {
        "Root": "10005212",
        "LV": "1",
        "Item Description": "DRIED FRUIT APPLE SULFURED RINGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "280140",
        "Last Price Paid": "$107.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005212",
        "Product Status": "Active"
    },
    {
        "Root": "10094110",
        "LV": "1",
        "Item Description": "POCKY CHOCOLATE COVERED COOKIE STICKS 1.41OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "25369",
        "Last Price Paid": "$1.12",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094110",
        "Product Status": "Active"
    },
    {
        "Root": "10014103",
        "LV": "1",
        "Item Description": "DEC CRISPEARL MILK CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014103001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C25605",
        "Last Price Paid": "$14.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014103",
        "Product Status": "Active"
    },
    {
        "Root": "10001882",
        "LV": "1",
        "Item Description": "YUCCA PEELED 20# TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-005",
        "Last Price Paid": "$46.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001882",
        "Product Status": "Active"
    },
    {
        "Root": "10001882",
        "LV": "1",
        "Item Description": "YUCCA PEELED 20# TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "599992",
        "Last Price Paid": "$46.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001882",
        "Product Status": "Active"
    },
    {
        "Root": "10100858",
        "LV": "1",
        "Item Description": "TEQUILA HERRADURA REPOSADO 750ML/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9111613",
        "Last Price Paid": "$19.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100858",
        "Product Status": "Active"
    },
    {
        "Root": "10029309",
        "LV": "3",
        "Item Description": "BEEF CUBED STEAK 3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029309003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190140",
        "Last Price Paid": "$5.72",
        "Case Pack": "7.5",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10029309",
        "Product Status": "Active"
    },
    {
        "Root": "10001917",
        "LV": "1",
        "Item Description": "PLANTAIN RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001917001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001917",
        "Product Status": "Active"
    },
    {
        "Root": "10032900",
        "LV": "1",
        "Item Description": "SCALLOP DRY U-1O FRESH SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032900001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6392733",
        "Last Price Paid": "$23.29",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032900",
        "Product Status": "Active"
    },
    {
        "Root": "10036624",
        "LV": "1",
        "Item Description": "SUGAR CARROT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036624001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9731",
        "Last Price Paid": "$29.23",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036624",
        "Product Status": "Active"
    },
    {
        "Root": "10006326",
        "LV": "2",
        "Item Description": "PARASOL WOOD FRILL PICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006326002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1002685",
        "Last Price Paid": "$0.03",
        "Case Pack": "1440",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006326",
        "Product Status": "Active"
    },
    {
        "Root": "10006137",
        "LV": "1",
        "Item Description": "SB WDW CUP 12Z PRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006137001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006137",
        "Product Status": "Active"
    },
    {
        "Root": "10017866",
        "LV": "2",
        "Item Description": "TEQUILA MAESTRO DOBEL DIAMONTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017866002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "354481",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10017866",
        "Product Status": "Active"
    },
    {
        "Root": "10001866",
        "LV": "1",
        "Item Description": "MUSHROOM CEPES FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001866001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001866",
        "Product Status": "Active"
    },
    {
        "Root": "10006280",
        "LV": "1",
        "Item Description": "BOARD CAKE 10 INCH X 1/4 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006280001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1014...{ENJAY{",
        "Last Price Paid": "$0.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006280",
        "Product Status": "Active"
    },
    {
        "Root": "10012704",
        "LV": "1",
        "Item Description": "HERBS TARRAGON PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PTA-33-E",
        "Last Price Paid": "$36.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012704",
        "Product Status": "Active"
    },
    {
        "Root": "10010194",
        "LV": "1",
        "Item Description": "MIX BAR LIME SOUR GAL 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9658808",
        "Last Price Paid": "$15.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010194",
        "Product Status": "Active"
    },
    {
        "Root": "10034216",
        "LV": "1",
        "Item Description": "VERMOUTH DOLIN DRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677470",
        "Last Price Paid": "$12.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034216",
        "Product Status": "Active"
    },
    {
        "Root": "10001974",
        "LV": "1",
        "Item Description": "PEA TENDRILS YELLOW PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04GPT-33-L",
        "Last Price Paid": "$30.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001974",
        "Product Status": "Active"
    },
    {
        "Root": "10007937",
        "LV": "2",
        "Item Description": "SYRAH RELENTLESS SHAFER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007937001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9636814",
        "Last Price Paid": "$74.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007937",
        "Product Status": "Active"
    },
    {
        "Root": "10004384",
        "LV": "1",
        "Item Description": "WATER SAN PELG GRPFRT 200",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "BM61",
        "Last Price Paid": "$0.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004384",
        "Product Status": "Active"
    },
    {
        "Root": "10009959",
        "LV": "1",
        "Item Description": "LIQ SCHNAPPS PEPPERMINT BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001680",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009959",
        "Product Status": "Active"
    },
    {
        "Root": "10033864",
        "LV": "2",
        "Item Description": "SB SANDWICH BREAKFAST SAUSAGE EGG AND CHEDDAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033864001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11094364",
        "Last Price Paid": "$1.52",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10033864",
        "Product Status": "Active"
    },
    {
        "Root": "10006343",
        "LV": "1",
        "Item Description": "LID HOT CUP 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4949533",
        "Last Price Paid": "$0.15",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006343",
        "Product Status": "Active"
    },
    {
        "Root": "10109558",
        "LV": "1",
        "Item Description": "BEEF BUTCHERS FILET TAIIL 3Z - 5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109558001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190116",
        "Last Price Paid": "$11.94",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109558",
        "Product Status": "Active"
    },
    {
        "Root": "10110893",
        "LV": "1",
        "Item Description": "HOT CHOCOLATE GROUND SEMI SWEET 50 LB BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CHD-A99",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110893",
        "Product Status": "Active"
    },
    {
        "Root": "10034456",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON BORNE OF FIRE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034456",
        "Product Status": "Active"
    },
    {
        "Root": "10084126",
        "LV": "1",
        "Item Description": "DONUT HOLES CHOC GLAZED 35CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084126",
        "Product Status": "Active"
    },
    {
        "Root": "10004492",
        "LV": "1",
        "Item Description": "CHERRY BRANDIED GRIOTTINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": ".{WOLFSBERGER{",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004492",
        "Product Status": "Active"
    },
    {
        "Root": "10024339",
        "LV": "2",
        "Item Description": "JUICE WELCHS GRAPE BOTTLE 48OZ/8CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024339002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7242542",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10024339",
        "Product Status": "Active"
    },
    {
        "Root": "10008314",
        "LV": "2",
        "Item Description": "PINOT NOIR WIL SELYEM RR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008314001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "931511",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008314",
        "Product Status": "Active"
    },
    {
        "Root": "10012474",
        "LV": "1",
        "Item Description": "CUP HOT RIPPLE KRAFT 16OZ SWGE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012474001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11009956",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012474",
        "Product Status": "Active"
    },
    {
        "Root": "10001640",
        "LV": "1",
        "Item Description": "GREENS CAHABA BOUNTY BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001640001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481314",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001640",
        "Product Status": "Active"
    },
    {
        "Root": "10019923",
        "LV": "1",
        "Item Description": "SNOWMAN RED HAT XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VO-1470",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019923",
        "Product Status": "Active"
    },
    {
        "Root": "10025423",
        "LV": "2",
        "Item Description": "COLOR AIRBRUSH TRUE GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025423002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "10340",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025423",
        "Product Status": "Active"
    },
    {
        "Root": "10095615",
        "LV": "1",
        "Item Description": "SHERRY GONZALES BYASS NOE PX30YR 375ML/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "316487",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095615",
        "Product Status": "Active"
    },
    {
        "Root": "10009858",
        "LV": "1",
        "Item Description": "MADEIRA PAUL MASSON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009858",
        "Product Status": "Active"
    },
    {
        "Root": "10002973",
        "LV": "1",
        "Item Description": "TEA PEPPERMINT PURE 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002973",
        "Product Status": "Active"
    },
    {
        "Root": "10096005",
        "LV": "1",
        "Item Description": "GELATO SWEET CORN SOFT SERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10096005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "MK-CORN-11-CS6",
        "Last Price Paid": "$11.79",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10096005",
        "Product Status": "Active"
    },
    {
        "Root": "10035835",
        "LV": "1",
        "Item Description": "FUSION MIX UNICORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035835001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22706",
        "Last Price Paid": "$6.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035835",
        "Product Status": "Active"
    },
    {
        "Root": "10030742",
        "LV": "1",
        "Item Description": "GEL COLOR REGAL PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC REGAL PURPL",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030742",
        "Product Status": "Active"
    },
    {
        "Root": "10006455",
        "LV": "1",
        "Item Description": "LINER SMALL LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P100",
        "Last Price Paid": "$0.22",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006455",
        "Product Status": "Active"
    },
    {
        "Root": "10023596",
        "LV": "1",
        "Item Description": "MILK, ALMOND CHOC, DAIRY FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2632115",
        "Last Price Paid": "$1.15",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023596",
        "Product Status": "Active"
    },
    {
        "Root": "10038283",
        "LV": "2",
        "Item Description": "SANDWICH CUBAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038283002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1704095",
        "Last Price Paid": "$5.60",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038283",
        "Product Status": "Active"
    },
    {
        "Root": "10001904",
        "LV": "1",
        "Item Description": "APPLE GRANNY SMITH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "122088",
        "Last Price Paid": "$0.55",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001904",
        "Product Status": "Active"
    },
    {
        "Root": "10013498",
        "LV": "1",
        "Item Description": "BREAD CRANBERRY ARTISAN 2.5# LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.40",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013498",
        "Product Status": "Active"
    },
    {
        "Root": "10033344",
        "LV": "1",
        "Item Description": "CRACKER GOLDFISH COLORS WHOLE GRAIN 300-.75 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "652018",
        "Last Price Paid": "$0.23",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033344",
        "Product Status": "Active"
    },
    {
        "Root": "10032257",
        "LV": "1",
        "Item Description": "BARILLA PENNE RIGATE 72  2/10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032257001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "BR72B",
        "Last Price Paid": "$13.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032257",
        "Product Status": "Active"
    },
    {
        "Root": "10105049",
        "LV": "1",
        "Item Description": "SOBA BUCKWHEAT NOODLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35310",
        "Last Price Paid": "$31.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105049",
        "Product Status": "Active"
    },
    {
        "Root": "10030850",
        "LV": "1",
        "Item Description": "SALMON ORA KING FILLET SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030850001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-50061",
        "Last Price Paid": "$19.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030850",
        "Product Status": "Active"
    },
    {
        "Root": "10009922",
        "LV": "1",
        "Item Description": "LIQ LEMONCIELLO CARAVELLA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9115008",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009922",
        "Product Status": "Active"
    },
    {
        "Root": "10005691",
        "LV": "1",
        "Item Description": "BREAD LOAF FIG & ANISE 1.25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005691",
        "Product Status": "Active"
    },
    {
        "Root": "10004530",
        "LV": "1",
        "Item Description": "MICKEY 7 INCH W/BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "208808KLM",
        "Last Price Paid": "$9.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004530",
        "Product Status": "Active"
    },
    {
        "Root": "10012952",
        "LV": "2",
        "Item Description": "WATERCRESS BLOOMS 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012952002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05WB-24",
        "Last Price Paid": "$0.64",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012952",
        "Product Status": "Active"
    },
    {
        "Root": "10039452",
        "LV": "1",
        "Item Description": "CEREAL CORN POPS BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039452",
        "Product Status": "Active"
    },
    {
        "Root": "10003940",
        "LV": "4",
        "Item Description": "HORS POPPER CHEDDAR JALAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003940003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1375948",
        "Last Price Paid": "$8.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10003940",
        "Product Status": "Active"
    },
    {
        "Root": "10003892",
        "LV": "1",
        "Item Description": "MIX SNACK INDIAN BHEL 14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003892001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043500",
        "Last Price Paid": "$4.85",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003892",
        "Product Status": "Active"
    },
    {
        "Root": "10033660",
        "LV": "2",
        "Item Description": "SUGAR ITSY BITSY SPIDER ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033660002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "49240",
        "Last Price Paid": "$0.14",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033660",
        "Product Status": "Active"
    },
    {
        "Root": "10032504",
        "LV": "1",
        "Item Description": "CHEESE MASCARPONE BELEGILIOSO 5# POUCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032504001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "16",
        "Last Price Paid": "$17.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032504",
        "Product Status": "Active"
    },
    {
        "Root": "10032504",
        "LV": "1",
        "Item Description": "CHEESE MASCARPONE BELEGILIOSO 5# POUCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032504001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2479977",
        "Last Price Paid": "$17.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032504",
        "Product Status": "Active"
    },
    {
        "Root": "10008338",
        "LV": "1",
        "Item Description": "BLND TEN SEA SMOKE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008338001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "SM021X05",
        "Last Price Paid": "$81.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008338",
        "Product Status": "Active"
    },
    {
        "Root": "10110073",
        "LV": "2",
        "Item Description": "TORTILLA CORN WHITE 6\" DIE CUT 6/90 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110073002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1674860",
        "Last Price Paid": "$0.05",
        "Case Pack": "540",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10110073",
        "Product Status": "Active"
    },
    {
        "Root": "10091572",
        "LV": "1",
        "Item Description": "VODKA  RASPBERRY SKYY INFUSED 1.0L/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "993068",
        "Last Price Paid": "$12.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091572",
        "Product Status": "Active"
    },
    {
        "Root": "10041600",
        "LV": "1",
        "Item Description": "SB LIDS STRAWLESS 31OZ 540/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "540",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041600",
        "Product Status": "Active"
    },
    {
        "Root": "10000147",
        "LV": "1",
        "Item Description": "MAHI BONELESS SKINLESS FRESH 5-7 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000147001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00610-A",
        "Last Price Paid": "$8.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000147",
        "Product Status": "Active"
    },
    {
        "Root": "10000147",
        "LV": "1",
        "Item Description": "MAHI BONELESS SKINLESS FRESH 5-7 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000147001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "MAH130006",
        "Last Price Paid": "$8.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000147",
        "Product Status": "Active"
    },
    {
        "Root": "10000147",
        "LV": "1",
        "Item Description": "MAHI BONELESS SKINLESS FRESH 5-7 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000147001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000147",
        "Product Status": "Active"
    },
    {
        "Root": "10000147",
        "LV": "1",
        "Item Description": "MAHI BONELESS SKINLESS FRESH 5-7 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000147001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3381940",
        "Last Price Paid": "$8.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000147",
        "Product Status": "Active"
    },
    {
        "Root": "10034231",
        "LV": "1",
        "Item Description": "CUPCAKE MINNIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1816",
        "Last Price Paid": "$1.91",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034231",
        "Product Status": "Active"
    },
    {
        "Root": "10002511",
        "LV": "1",
        "Item Description": "PEPPER CHIPOLTE DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002511001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002511",
        "Product Status": "Active"
    },
    {
        "Root": "10008076",
        "LV": "1",
        "Item Description": "ZINF SEGHESIO SONOMA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008076001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "292280",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008076",
        "Product Status": "Active"
    },
    {
        "Root": "10014204",
        "LV": "1",
        "Item Description": "BREAD BREAKFAST BASKET LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014204",
        "Product Status": "Active"
    },
    {
        "Root": "10012021",
        "LV": "1",
        "Item Description": "BALM MICRO LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MLBA-33-S",
        "Last Price Paid": "$49.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012021",
        "Product Status": "Active"
    },
    {
        "Root": "10012021",
        "LV": "1",
        "Item Description": "BALM MICRO LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480501",
        "Last Price Paid": "$49.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012021",
        "Product Status": "Active"
    },
    {
        "Root": "10092572",
        "LV": "2",
        "Item Description": "LAMB LOIN CHOP 1.25\" THICK /4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092572002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1290101",
        "Last Price Paid": "$8.45",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092572",
        "Product Status": "Active"
    },
    {
        "Root": "10005965",
        "LV": "1",
        "Item Description": "TURKEY GROUND BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005965001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4125075",
        "Last Price Paid": "$1.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005965",
        "Product Status": "Active"
    },
    {
        "Root": "10027642",
        "LV": "1",
        "Item Description": "COASTER HANGAR BAR 60 PT MIXED CASE 4\" 64R4DB2S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731046",
        "Last Price Paid": "$0.08",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027642",
        "Product Status": "Active"
    },
    {
        "Root": "10027642",
        "LV": "1",
        "Item Description": "COASTER HANGAR BAR 60 PT MIXED CASE 4\" 64R4DB2S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731046",
        "Last Price Paid": "$0.08",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027642",
        "Product Status": "Active"
    },
    {
        "Root": "10028189",
        "LV": "2",
        "Item Description": "ROSA REGALE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028189002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "206394",
        "Last Price Paid": "$3.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028189",
        "Product Status": "Active"
    },
    {
        "Root": "10005765",
        "LV": "1",
        "Item Description": "BAGEL CINNAMON RAISIN MINI 2.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005765",
        "Product Status": "Active"
    },
    {
        "Root": "10005332",
        "LV": "1",
        "Item Description": "SYRUP AGAVE NECTAR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.20",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005332",
        "Product Status": "Active"
    },
    {
        "Root": "10015542",
        "LV": "2",
        "Item Description": "BROWNIE BITES GF VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015542002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "002",
        "Last Price Paid": "$0.71",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015542",
        "Product Status": "Active"
    },
    {
        "Root": "10030824",
        "LV": "1",
        "Item Description": "SUN BUTTER SUNFLOWER SEED CREAMY BUTTER PAIL SHEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3641784",
        "Last Price Paid": "$17.81",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030824",
        "Product Status": "Active"
    },
    {
        "Root": "10003493",
        "LV": "2",
        "Item Description": "TEA THNDRBLT DARG 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003493001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3380",
        "Last Price Paid": "$39.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003493",
        "Product Status": "Active"
    },
    {
        "Root": "10039578",
        "LV": "1",
        "Item Description": "SANGRIA WHITE OPICI FAMILY 3 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039578",
        "Product Status": "Active"
    },
    {
        "Root": "10040696",
        "LV": "1",
        "Item Description": "CHIPS TORTILLA BLUE RAW 4 CUT 20LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040696001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2975746",
        "Last Price Paid": "$38.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040696",
        "Product Status": "Active"
    },
    {
        "Root": "10034191",
        "LV": "1",
        "Item Description": "TRULY WILD BERRY SELTZER 16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40185",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034191",
        "Product Status": "Active"
    },
    {
        "Root": "10030326",
        "LV": "1",
        "Item Description": "APPLE WRAPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "4012",
        "Last Price Paid": "$0.05",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030326",
        "Product Status": "Active"
    },
    {
        "Root": "10102342",
        "LV": "1",
        "Item Description": "SUGAR-SUNFLOWER ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "48110",
        "Last Price Paid": "$0.19",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102342",
        "Product Status": "Active"
    },
    {
        "Root": "10104433",
        "LV": "1",
        "Item Description": "BEER DRAFT STEM FLOCKSTAR 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15821",
        "Last Price Paid": "$249.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104433",
        "Product Status": "Active"
    },
    {
        "Root": "10034404",
        "LV": "1",
        "Item Description": "OLLI PEPPERONI WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "90301",
        "Last Price Paid": "$6.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034404",
        "Product Status": "Active"
    },
    {
        "Root": "10027325",
        "LV": "1",
        "Item Description": "COMPOUND S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2325",
        "Last Price Paid": "$137.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027325",
        "Product Status": "Active"
    },
    {
        "Root": "10003089",
        "LV": "1",
        "Item Description": "RICE JASMINE 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3490434",
        "Last Price Paid": "$27.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003089",
        "Product Status": "Active"
    },
    {
        "Root": "10003089",
        "LV": "1",
        "Item Description": "RICE JASMINE 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3490434",
        "Last Price Paid": "$27.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003089",
        "Product Status": "Active"
    },
    {
        "Root": "10001461",
        "LV": "1",
        "Item Description": "HERB BASIL LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001461001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482233",
        "Last Price Paid": "$9.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001461",
        "Product Status": "Active"
    },
    {
        "Root": "10033848",
        "LV": "1",
        "Item Description": "PINOT NOIR GOGI BIRDIE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "901220",
        "Last Price Paid": "$55.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033848",
        "Product Status": "Active"
    },
    {
        "Root": "10033848",
        "LV": "1",
        "Item Description": "PINOT NOIR GOGI BIRDIE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "901220",
        "Last Price Paid": "$55.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033848",
        "Product Status": "Active"
    },
    {
        "Root": "10003343",
        "LV": "1",
        "Item Description": "SYRUP PIBB XTRA 2.5 GALLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "115468",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003343",
        "Product Status": "Active"
    },
    {
        "Root": "10002331",
        "LV": "1",
        "Item Description": "CHEESE GOUDA REYPENEAR 1 YEAR 6 LB QUARTER WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002331001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "39519",
        "Last Price Paid": "$15.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002331",
        "Product Status": "Active"
    },
    {
        "Root": "10108286",
        "LV": "1",
        "Item Description": "PASTA CAVATELLI 12/1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070904",
        "Last Price Paid": "$2.72",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108286",
        "Product Status": "Active"
    },
    {
        "Root": "10000492",
        "LV": "1",
        "Item Description": "BREAD BAGUETTE CUBAN 24 INCH 1.5# 2 PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.11",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000492",
        "Product Status": "Active"
    },
    {
        "Root": "10041480",
        "LV": "1",
        "Item Description": "SANDWICH PORTOBELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041480",
        "Product Status": "Active"
    },
    {
        "Root": "10036514",
        "LV": "2",
        "Item Description": "OLIVE OIL AFFIORATO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036514002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "174502",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036514",
        "Product Status": "Active"
    },
    {
        "Root": "10003168",
        "LV": "1",
        "Item Description": "PRINT HEAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "794",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003168",
        "Product Status": "Active"
    },
    {
        "Root": "10090553",
        "LV": "1",
        "Item Description": "LIQUEUR BYEJOE RED 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "342250",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090553",
        "Product Status": "Active"
    },
    {
        "Root": "10005716",
        "LV": "1",
        "Item Description": "PASTRY DANISH RAISIN CUSTARD ROLL RTB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061012",
        "Last Price Paid": "",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005716",
        "Product Status": "Active"
    },
    {
        "Root": "10108255",
        "LV": "1",
        "Item Description": "MONTS FOURNOIS COTE CRAMANT GC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108255",
        "Product Status": "Active"
    },
    {
        "Root": "10020188",
        "LV": "1",
        "Item Description": "SANTA & SNOWMAN XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "YO-2390",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020188",
        "Product Status": "Active"
    },
    {
        "Root": "10028586",
        "LV": "1",
        "Item Description": "PASTA PIPETTE BARILLA USA 16OZ 12 PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "583136",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028586",
        "Product Status": "Active"
    },
    {
        "Root": "10023743",
        "LV": "1",
        "Item Description": "DRAFT 3D 72 FLORIDA WINTERWEIZEN 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "84206",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023743",
        "Product Status": "Active"
    },
    {
        "Root": "10004946",
        "LV": "1",
        "Item Description": "SHELL CREAM HORN LRG 4.7 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I007...{PRUVE{",
        "Last Price Paid": "",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004946",
        "Product Status": "Active"
    },
    {
        "Root": "10102339",
        "LV": "1",
        "Item Description": "WINE FORTIFIED MADEIRA RWC SERCIAL 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102339",
        "Product Status": "Active"
    },
    {
        "Root": "10023967",
        "LV": "2",
        "Item Description": "BEER ROGUE DEAD GUY ALE 12 OZ CAN 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023967002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "35404",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10023967",
        "Product Status": "Active"
    },
    {
        "Root": "10023256",
        "LV": "1",
        "Item Description": "CHIP PRINGLE SOUR CRM ONION 3/12/1.3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6620223",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023256",
        "Product Status": "Active"
    },
    {
        "Root": "10038215",
        "LV": "1",
        "Item Description": "MUG FLIP LID OLAF 16Z 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038215",
        "Product Status": "Active"
    },
    {
        "Root": "10002491",
        "LV": "1",
        "Item Description": "MANGO CHUNKS IQF 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8382772",
        "Last Price Paid": "$35.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002491",
        "Product Status": "Active"
    },
    {
        "Root": "10007176",
        "LV": "1",
        "Item Description": "SAUV BLC CLDY TEKOKO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "572437",
        "Last Price Paid": "$43.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007176",
        "Product Status": "Active"
    },
    {
        "Root": "10002255",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR SHARP YELLOW TILLAMOOK 10 LB PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002255001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "27122",
        "Last Price Paid": "$5.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002255",
        "Product Status": "Active"
    },
    {
        "Root": "10033224",
        "LV": "1",
        "Item Description": "COLOR GEL PASTE ELECTRIC GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC ELECTRIC GR",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033224",
        "Product Status": "Active"
    },
    {
        "Root": "10004068",
        "LV": "2",
        "Item Description": "SHEET TRANSFER CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004068002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65635A",
        "Last Price Paid": "$1.00",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004068",
        "Product Status": "Active"
    },
    {
        "Root": "10102268",
        "LV": "1",
        "Item Description": "BAR NON ALC SPIRIT LYRES PINK LONDON 700ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9672291",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102268",
        "Product Status": "Active"
    },
    {
        "Root": "10101951",
        "LV": "1",
        "Item Description": "WINE RED CAYMUS WALKING FOOL RED BLEND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "556950",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101951",
        "Product Status": "Active"
    },
    {
        "Root": "10003069",
        "LV": "1",
        "Item Description": "CHEESE PARMESAN IND 3.5GR 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003069001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003069",
        "Product Status": "Active"
    },
    {
        "Root": "10027214",
        "LV": "1",
        "Item Description": "TOOTHFISH FILLET GLACIER 51 SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027214001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZFISHFF040",
        "Last Price Paid": "$36.00",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027214",
        "Product Status": "Active"
    },
    {
        "Root": "10027214",
        "LV": "1",
        "Item Description": "TOOTHFISH FILLET GLACIER 51 SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027214001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "15030050",
        "Last Price Paid": "$36.00",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027214",
        "Product Status": "Active"
    },
    {
        "Root": "10007786",
        "LV": "1",
        "Item Description": "BLEND RED DETOREN Z 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "421530",
        "Last Price Paid": "$32.92",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007786",
        "Product Status": "Active"
    },
    {
        "Root": "10007789",
        "LV": "1",
        "Item Description": "PINOT NOIR PENNER ASH WILLAMETTE VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "577466",
        "Last Price Paid": "$45.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007789",
        "Product Status": "Active"
    },
    {
        "Root": "10029738",
        "LV": "2",
        "Item Description": "BLEND TABLAS CREEK PATELIN DE TABLAS ROUGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029738002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9140856",
        "Last Price Paid": "$18.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029738",
        "Product Status": "Active"
    },
    {
        "Root": "10038209",
        "LV": "1",
        "Item Description": "SORBET SWEET TEA CASE 4 HALF GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-SWTE-08-CS",
        "Last Price Paid": "$9.83",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038209",
        "Product Status": "Active"
    },
    {
        "Root": "10002769",
        "LV": "1",
        "Item Description": "SYRUP TEA GOLD PEAK SWEET 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002769001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002769",
        "Product Status": "Active"
    },
    {
        "Root": "10004141",
        "LV": "1",
        "Item Description": "OIL WHITE TRUFFLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004141",
        "Product Status": "Active"
    },
    {
        "Root": "10000531",
        "LV": "1",
        "Item Description": "BREAD BUN BURGER KIDS 60 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000404845",
        "Vendor": "CAMPAGNA-TURANO BAKERY INC",
        "Vendor Item Nb": "35722",
        "Last Price Paid": "$0.15",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000531",
        "Product Status": "Active"
    },
    {
        "Root": "10022836",
        "LV": "1",
        "Item Description": "CHOC WHT 2.5 X 1.5 RECT LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2167W",
        "Last Price Paid": "$1.20",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022836",
        "Product Status": "Active"
    },
    {
        "Root": "10001806",
        "LV": "1",
        "Item Description": "HERB CILANTRO MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22110",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001806",
        "Product Status": "Active"
    },
    {
        "Root": "10001806",
        "LV": "1",
        "Item Description": "HERB CILANTRO MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001806",
        "Product Status": "Active"
    },
    {
        "Root": "10001806",
        "LV": "1",
        "Item Description": "HERB CILANTRO MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401075",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001806",
        "Product Status": "Active"
    },
    {
        "Root": "10109348",
        "LV": "1",
        "Item Description": "TAMALE CHICKEN COOKED RUIZ IQF 5OZ/60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.30",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109348",
        "Product Status": "Active"
    },
    {
        "Root": "10000263",
        "LV": "1",
        "Item Description": "MIX RAINBOW MICRO LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000263001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489798",
        "Last Price Paid": "$15.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000263",
        "Product Status": "Active"
    },
    {
        "Root": "10102631",
        "LV": "1",
        "Item Description": "SEEDS SUNFLOWER CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550564",
        "Last Price Paid": "$65.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102631",
        "Product Status": "Active"
    },
    {
        "Root": "10010261",
        "LV": "2",
        "Item Description": "DRAFT SIERRA NEV SEAS 5GL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010261001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13388",
        "Last Price Paid": "$85.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010261",
        "Product Status": "Active"
    },
    {
        "Root": "10035264",
        "LV": "1",
        "Item Description": "SB LEMONADE 2X CONCENTRATE 1.5L - 50.7 FL OZ 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011092210",
        "Last Price Paid": "$5.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035264",
        "Product Status": "Active"
    },
    {
        "Root": "10003783",
        "LV": "1",
        "Item Description": "CAMBURGER 4CT PKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003783001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003783",
        "Product Status": "Active"
    },
    {
        "Root": "10009215",
        "LV": "2",
        "Item Description": "CAB SAUV LONG MDW RCH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009215001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9092066",
        "Last Price Paid": "$39.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009215",
        "Product Status": "Active"
    },
    {
        "Root": "10000628",
        "LV": "2",
        "Item Description": "SALAME OLLI CHORIZO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000628002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "77777",
        "Last Price Paid": "$7.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000628",
        "Product Status": "Active"
    },
    {
        "Root": "10002463",
        "LV": "2",
        "Item Description": "CHEESE CHEDDAR COTSWOLD ONIONAND CHIVE 2/5 LB WHEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002463002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1060",
        "Last Price Paid": "$9.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002463",
        "Product Status": "Active"
    },
    {
        "Root": "10016956",
        "LV": "2",
        "Item Description": "SPICE FENNEL SEED GRND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016956001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6028757",
        "Last Price Paid": "$12.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016956",
        "Product Status": "Active"
    },
    {
        "Root": "10018314",
        "LV": "1",
        "Item Description": "TORTILLA SPINACH 12\" WRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018314",
        "Product Status": "Active"
    },
    {
        "Root": "10018314",
        "LV": "1",
        "Item Description": "TORTILLA SPINACH 12\" WRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "384735",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018314",
        "Product Status": "Active"
    },
    {
        "Root": "10042858",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK CONGRATS MICKEY EAR SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330D1",
        "Last Price Paid": "$2.79",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042858",
        "Product Status": "Active"
    },
    {
        "Root": "10107266",
        "LV": "2",
        "Item Description": "SALMON VERLASSO FILET SK/OFF 35 LB CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107266002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6404514",
        "Last Price Paid": "$8.20",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107266",
        "Product Status": "Active"
    },
    {
        "Root": "10031444",
        "LV": "1",
        "Item Description": "CINDERELLA TOWER CLOCK 6CM WH CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6231660000",
        "Last Price Paid": "$0.51",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031444",
        "Product Status": "Active"
    },
    {
        "Root": "10003602",
        "LV": "1",
        "Item Description": "COOKIE OREO 4/12 CT 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302252",
        "Last Price Paid": "$0.85",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003602",
        "Product Status": "Active"
    },
    {
        "Root": "10011881",
        "LV": "1",
        "Item Description": "SB PUMPKIN SCONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011881001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11106386",
        "Last Price Paid": "$1.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011881",
        "Product Status": "Active"
    },
    {
        "Root": "10032432",
        "LV": "1",
        "Item Description": "IMPOSSIBLE BURGER BULK 4 X 5LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "60-00010",
        "Last Price Paid": "$36.03",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032432",
        "Product Status": "Active"
    },
    {
        "Root": "10102512",
        "LV": "2",
        "Item Description": "PUREE FROZEN EXOTIC 12/1KG PONTHIER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102512002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102512",
        "Product Status": "Active"
    },
    {
        "Root": "10103854",
        "LV": "1",
        "Item Description": "HAM SPECK ALTO SMKD PROSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103854001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3313018",
        "Last Price Paid": "$12.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103854",
        "Product Status": "Active"
    },
    {
        "Root": "10001428",
        "LV": "1",
        "Item Description": "CARROTS NG PETITE MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PMCGH-33",
        "Last Price Paid": "$0.84",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001428",
        "Product Status": "Active"
    },
    {
        "Root": "10100868",
        "LV": "1",
        "Item Description": "WINE CAB SAUV FORTUNATE SON THE DREAMER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "552975",
        "Last Price Paid": "$86.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100868",
        "Product Status": "Active"
    },
    {
        "Root": "10029528",
        "LV": "2",
        "Item Description": "SPRINKLES HALLOWEEN MIX WITH CONTAINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029528002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21348",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029528",
        "Product Status": "Active"
    },
    {
        "Root": "10005614",
        "LV": "1",
        "Item Description": "FLATBREAD GYRO 6 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5278924",
        "Last Price Paid": "$0.24",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005614",
        "Product Status": "Active"
    },
    {
        "Root": "10005230",
        "LV": "1",
        "Item Description": "PEPPERCORNS CUBUBE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "HB115",
        "Last Price Paid": "$31.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005230",
        "Product Status": "Active"
    },
    {
        "Root": "10084843",
        "LV": "1",
        "Item Description": "CHEESE MOZZARELLA SHREDDED LMWM 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.94",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084843",
        "Product Status": "Active"
    },
    {
        "Root": "10001672",
        "LV": "2",
        "Item Description": "POTATO BAKER 80 CT/50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001672002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.41",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001672",
        "Product Status": "Active"
    },
    {
        "Root": "10000490",
        "LV": "1",
        "Item Description": "DOUGH SHEET SUGAR SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.55",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000490",
        "Product Status": "Active"
    },
    {
        "Root": "10003523",
        "LV": "1",
        "Item Description": "SYRUP COF DESERT PEAR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003523",
        "Product Status": "Active"
    },
    {
        "Root": "10039450",
        "LV": "1",
        "Item Description": "CEREAL RICE KRISPIES BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.34",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039450",
        "Product Status": "Active"
    },
    {
        "Root": "10025154",
        "LV": "1",
        "Item Description": "WAFFLE GRID CONDITIONER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449776",
        "Vendor": "NEW CARBON COMPANY LLC",
        "Vendor Item Nb": "132",
        "Last Price Paid": "$7.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025154",
        "Product Status": "Active"
    },
    {
        "Root": "10082486",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL MM JOY APPLE LYCHEE 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082486001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "28410010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082486",
        "Product Status": "Active"
    },
    {
        "Root": "10007128",
        "LV": "1",
        "Item Description": "SYRAH LECOLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "801257",
        "Last Price Paid": "$22.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007128",
        "Product Status": "Active"
    },
    {
        "Root": "10033256",
        "LV": "1",
        "Item Description": "CRACKER SEA SALT FIREHOOK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "550124",
        "Last Price Paid": "$3.85",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033256",
        "Product Status": "Active"
    },
    {
        "Root": "10020795",
        "LV": "1",
        "Item Description": "CAVIAR OSETRA 1 OZ EACH SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020795",
        "Product Status": "Active"
    },
    {
        "Root": "10020795",
        "LV": "1",
        "Item Description": "CAVIAR OSETRA 1 OZ EACH SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020795",
        "Product Status": "Active"
    },
    {
        "Root": "10020795",
        "LV": "1",
        "Item Description": "CAVIAR OSETRA 1 OZ EACH SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020795",
        "Product Status": "Active"
    },
    {
        "Root": "10020795",
        "LV": "1",
        "Item Description": "CAVIAR OSETRA 1 OZ EACH SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6150008",
        "Last Price Paid": "$48.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020795",
        "Product Status": "Active"
    },
    {
        "Root": "10095423",
        "LV": "1",
        "Item Description": "KEG XINGU DARK 15.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15106",
        "Last Price Paid": "$359.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095423",
        "Product Status": "Active"
    },
    {
        "Root": "10027951",
        "LV": "2",
        "Item Description": "CHENIN BLNC BAUMARD SAVENIERES CLOSDU PAPILLON 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027951002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9128949",
        "Last Price Paid": "$37.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027951",
        "Product Status": "Active"
    },
    {
        "Root": "10016334",
        "LV": "1",
        "Item Description": "CUPCAKE MED CHOC ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1644",
        "Last Price Paid": "$0.62",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016334",
        "Product Status": "Active"
    },
    {
        "Root": "10004433",
        "LV": "1",
        "Item Description": "MIX UNIVERSAL GRAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "31036",
        "Last Price Paid": "$117.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004433",
        "Product Status": "Active"
    },
    {
        "Root": "10003889",
        "LV": "1",
        "Item Description": "MIRIN (TAKARA MIRIN FU)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "6094",
        "Last Price Paid": "$3.42",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003889",
        "Product Status": "Active"
    },
    {
        "Root": "10002005",
        "LV": "1",
        "Item Description": "PINEAPPLE REG CUBED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-007D-A",
        "Last Price Paid": "$12.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002005",
        "Product Status": "Active"
    },
    {
        "Root": "10002005",
        "LV": "1",
        "Item Description": "PINEAPPLE REG CUBED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511017",
        "Last Price Paid": "$12.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002005",
        "Product Status": "Active"
    },
    {
        "Root": "10004803",
        "LV": "1",
        "Item Description": "PEANUT SALTED PLANTERS 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9986670",
        "Last Price Paid": "$0.36",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004803",
        "Product Status": "Active"
    },
    {
        "Root": "10025452",
        "LV": "1",
        "Item Description": "PREMIUM CANDY COLOR PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21311",
        "Last Price Paid": "$5.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025452",
        "Product Status": "Active"
    },
    {
        "Root": "10002935",
        "LV": "1",
        "Item Description": "ROOT BEER BARQS 20OZ BT 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002935",
        "Product Status": "Active"
    },
    {
        "Root": "10037119",
        "LV": "2",
        "Item Description": "LAMB DOMESTIC 8 RIB RACK CHINE OFF FRENCHED 28 30",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037119001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "LAMDOM002",
        "Last Price Paid": "$38.99",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10037119",
        "Product Status": "Active"
    },
    {
        "Root": "10095409",
        "LV": "1",
        "Item Description": "SALT SEA FINE GROUND 12/26.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7300262",
        "Last Price Paid": "$2.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095409",
        "Product Status": "Active"
    },
    {
        "Root": "10083668",
        "LV": "1",
        "Item Description": "DECOR CHOC WHT DONALD BOW TIE 2.5X2.2CM 600PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233040000",
        "Last Price Paid": "$0.26",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083668",
        "Product Status": "Active"
    },
    {
        "Root": "10029284",
        "LV": "1",
        "Item Description": "FLOWERS BUTTERFLY PEA S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480851",
        "Last Price Paid": "$9.31",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029284",
        "Product Status": "Active"
    },
    {
        "Root": "10008271",
        "LV": "1",
        "Item Description": "BRUNELLO MONTAL BANFI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "225722",
        "Last Price Paid": "$51.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008271",
        "Product Status": "Active"
    },
    {
        "Root": "10008271",
        "LV": "1",
        "Item Description": "BRUNELLO MONTAL BANFI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "225722",
        "Last Price Paid": "$51.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008271",
        "Product Status": "Active"
    },
    {
        "Root": "10003716",
        "LV": "1",
        "Item Description": "SB FRAPPUCCINO JAVA CHIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003716001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "504168",
        "Last Price Paid": "$8.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003716",
        "Product Status": "Active"
    },
    {
        "Root": "10030273",
        "LV": "1",
        "Item Description": "CRACKER JACKS CARAMEL POPCORN 28/3.125 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5892301",
        "Last Price Paid": "$0.99",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030273",
        "Product Status": "Active"
    },
    {
        "Root": "10004756",
        "LV": "1",
        "Item Description": "MUSTARD WHOLE GRAIN MAILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090199",
        "Last Price Paid": "$19.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004756",
        "Product Status": "Active"
    },
    {
        "Root": "10006013",
        "LV": "1",
        "Item Description": "LID PLATE 9X11 GP PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.28",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006013",
        "Product Status": "Active"
    },
    {
        "Root": "10040615",
        "LV": "1",
        "Item Description": "CAN TIN TRANSPARENT W/ LID 7.4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006778",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040615",
        "Product Status": "Active"
    },
    {
        "Root": "10110638",
        "LV": "1",
        "Item Description": "CANDY SKITTLES WB 12/3.5OZ THEATER BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "50186",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110638",
        "Product Status": "Active"
    },
    {
        "Root": "10033965",
        "LV": "1",
        "Item Description": "WAFER PAPER 290MMX410MM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "410001A",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033965",
        "Product Status": "Active"
    },
    {
        "Root": "10014965",
        "LV": "1",
        "Item Description": "PINOT N CHAMONIX FELDSPAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9192779",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014965",
        "Product Status": "Active"
    },
    {
        "Root": "10009117",
        "LV": "1",
        "Item Description": "WINE KLEIN CONSTANTIA VIN CONSTANCE 2014 500 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009117001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "537045",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009117",
        "Product Status": "Active"
    },
    {
        "Root": "10016372",
        "LV": "1",
        "Item Description": "CHENIN BLANC LECOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "465935",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016372",
        "Product Status": "Active"
    },
    {
        "Root": "10037077",
        "LV": "1",
        "Item Description": "HONEYCOMB WHOLE FRAME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "02HCFR-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037077",
        "Product Status": "Active"
    },
    {
        "Root": "10002057",
        "LV": "1",
        "Item Description": "LETTUCE ESCAROLE 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403202",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002057",
        "Product Status": "Active"
    },
    {
        "Root": "10007022",
        "LV": "1",
        "Item Description": "SAUV BLANC KENWOOD SON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007022",
        "Product Status": "Active"
    },
    {
        "Root": "10004555",
        "LV": "1",
        "Item Description": "CEREAL SHREDDED MINI WHEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7489412",
        "Last Price Paid": "",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004555",
        "Product Status": "Active"
    },
    {
        "Root": "10018870",
        "LV": "1",
        "Item Description": "DECOR FLASHY SPARKLES GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1006021",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018870",
        "Product Status": "Active"
    },
    {
        "Root": "10034889",
        "LV": "1",
        "Item Description": "GINGERDEER CHRISTMAS DECOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034889",
        "Product Status": "Active"
    },
    {
        "Root": "10094629",
        "LV": "1",
        "Item Description": "BAG CHILI HEAT SEALED CHILL MILL ANGLE 20 X 24 3.0",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094629",
        "Product Status": "Active"
    },
    {
        "Root": "10020938",
        "LV": "2",
        "Item Description": "OCTOPUS LEGS COOKED SPANISH SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020938002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6400014",
        "Last Price Paid": "$16.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10020938",
        "Product Status": "Active"
    },
    {
        "Root": "10103849",
        "LV": "1",
        "Item Description": "CREAMER FRENCH VANILLA LIQUID IND 192CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103849",
        "Product Status": "Active"
    },
    {
        "Root": "10004429",
        "LV": "1",
        "Item Description": "DEXTROSE 8#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004429",
        "Product Status": "Active"
    },
    {
        "Root": "10088773",
        "LV": "1",
        "Item Description": "WHISKEY OLD FORESTER RYE 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9325987",
        "Last Price Paid": "$17.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088773",
        "Product Status": "Active"
    },
    {
        "Root": "10044737",
        "LV": "1",
        "Item Description": "SPICE FENNEL SEED WHOLE 14.OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044737001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "761056",
        "Last Price Paid": "$12.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044737",
        "Product Status": "Active"
    },
    {
        "Root": "10107413",
        "LV": "1",
        "Item Description": "DIASTATIC MALT POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "14305",
        "Last Price Paid": "$118.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107413",
        "Product Status": "Active"
    },
    {
        "Root": "10002048",
        "LV": "1",
        "Item Description": "LETTUCE RADICCHIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482145",
        "Last Price Paid": "$1.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002048",
        "Product Status": "Active"
    },
    {
        "Root": "10002048",
        "LV": "1",
        "Item Description": "LETTUCE RADICCHIO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002048",
        "Product Status": "Active"
    },
    {
        "Root": "10016328",
        "LV": "1",
        "Item Description": "CUPCAKE LG CHOC UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016328001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1638",
        "Last Price Paid": "$0.53",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016328",
        "Product Status": "Active"
    },
    {
        "Root": "10038845",
        "LV": "1",
        "Item Description": "CHEESE CRUMBLE BLUE DOMESTIC 4/5 POUND BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038845001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "75045",
        "Last Price Paid": "$7.81",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038845",
        "Product Status": "Active"
    },
    {
        "Root": "10101168",
        "LV": "1",
        "Item Description": "LIQUEUR AVERNA AMARO 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557371",
        "Last Price Paid": "$38.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101168",
        "Product Status": "Active"
    },
    {
        "Root": "10010087",
        "LV": "1",
        "Item Description": "BEER BOULVRD TANK 7 12Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010087001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13535",
        "Last Price Paid": "$1.86",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010087",
        "Product Status": "Active"
    },
    {
        "Root": "10037275",
        "LV": "1",
        "Item Description": "JAM CONCORD GRAPE SS GLASS JAR4 SHELF STABLE 72/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3065471",
        "Last Price Paid": "$0.44",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037275",
        "Product Status": "Active"
    },
    {
        "Root": "10040540",
        "LV": "1",
        "Item Description": "TOOTHPICK AMERICAN FLAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11009680",
        "Last Price Paid": "$0.20",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040540",
        "Product Status": "Active"
    },
    {
        "Root": "10110270",
        "LV": "1",
        "Item Description": "BOX SLEEVE 16CT GANACHERY HOLIDAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51021016",
        "Last Price Paid": "$0.48",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110270",
        "Product Status": "Active"
    },
    {
        "Root": "10012061",
        "LV": "1",
        "Item Description": "SORREL PETITE RAINBOW LUCKY 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PRLSB-33",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012061",
        "Product Status": "Active"
    },
    {
        "Root": "10017589",
        "LV": "1",
        "Item Description": "PASTRY DANISH APPLE LARGE 6Z WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017589",
        "Product Status": "Active"
    },
    {
        "Root": "10040972",
        "LV": "2",
        "Item Description": "LABEL GANACHERY CLEAR COPPER LEAF 3X1 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040972002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006833",
        "Last Price Paid": "$69.63",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040972",
        "Product Status": "Active"
    },
    {
        "Root": "10002645",
        "LV": "2",
        "Item Description": "SPICE PAPRIKA SPANISH 4/4.5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002645002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002645",
        "Product Status": "Active"
    },
    {
        "Root": "10002645",
        "LV": "2",
        "Item Description": "SPICE PAPRIKA SPANISH 4/4.5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002645002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00311-1",
        "Last Price Paid": "$12.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002645",
        "Product Status": "Active"
    },
    {
        "Root": "10020622",
        "LV": "1",
        "Item Description": "COOKIE SHEET SALTED CARAMEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "158",
        "Last Price Paid": "$95.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020622",
        "Product Status": "Active"
    },
    {
        "Root": "10001077",
        "LV": "1",
        "Item Description": "LOBSTER FRSH ME 1.25-1.5 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001077001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "69-50125",
        "Last Price Paid": "$11.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001077",
        "Product Status": "Active"
    },
    {
        "Root": "10001077",
        "LV": "1",
        "Item Description": "LOBSTER FRSH ME 1.25-1.5 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001077001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "1636729",
        "Last Price Paid": "$11.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001077",
        "Product Status": "Active"
    },
    {
        "Root": "10001077",
        "LV": "1",
        "Item Description": "LOBSTER FRSH ME 1.25-1.5 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001077001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "LOB310250",
        "Last Price Paid": "$11.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001077",
        "Product Status": "Active"
    },
    {
        "Root": "10004843",
        "LV": "1",
        "Item Description": "OIL TRUFFLE WHT 6/8.4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "AG100",
        "Last Price Paid": "$26.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004843",
        "Product Status": "Active"
    },
    {
        "Root": "10015156",
        "LV": "1",
        "Item Description": "CRYSTAL CRANBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "165CRANBERRY",
        "Last Price Paid": "$7.44",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015156",
        "Product Status": "Active"
    },
    {
        "Root": "10037351",
        "LV": "1",
        "Item Description": "VEGANAISE SOY-FREE 1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "6128",
        "Last Price Paid": "$20.89",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037351",
        "Product Status": "Active"
    },
    {
        "Root": "10006142",
        "LV": "1",
        "Item Description": "SB WDW CUP CORE HOT 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006142",
        "Product Status": "Active"
    },
    {
        "Root": "10008695",
        "LV": "2",
        "Item Description": "CAB FRNC MICH DVD INKBLOT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008695001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "271027",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008695",
        "Product Status": "Active"
    },
    {
        "Root": "10009957",
        "LV": "1",
        "Item Description": "LIQ GRN CRM MENTHE LTR BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001667",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009957",
        "Product Status": "Active"
    },
    {
        "Root": "10090520",
        "LV": "1",
        "Item Description": "CIDER DRAFT 3 DAUGHTERS CHAI 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99975",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090520",
        "Product Status": "Active"
    },
    {
        "Root": "10010626",
        "LV": "2",
        "Item Description": "BOURBON EAGLE RARE 10YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010626001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9031427",
        "Last Price Paid": "$26.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010626",
        "Product Status": "Active"
    },
    {
        "Root": "10021839",
        "LV": "2",
        "Item Description": "CANDY CHOCOLATE SQ CHOC & SEA SALT GHIRARDELLI 55C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021839002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "597846",
        "Last Price Paid": "$0.52",
        "Case Pack": "55",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021839",
        "Product Status": "Active"
    },
    {
        "Root": "10004622",
        "LV": "1",
        "Item Description": "COOKIE CRUMBS OREO FIND GROUND 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1492148",
        "Last Price Paid": "$123.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004622",
        "Product Status": "Active"
    },
    {
        "Root": "10007930",
        "LV": "1",
        "Item Description": "ZINF FROGS LEAP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "172172",
        "Last Price Paid": "$18.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007930",
        "Product Status": "Active"
    },
    {
        "Root": "10007930",
        "LV": "1",
        "Item Description": "ZINF FROGS LEAP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "172172",
        "Last Price Paid": "$18.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007930",
        "Product Status": "Active"
    },
    {
        "Root": "10004176",
        "LV": "2",
        "Item Description": "FLOUR RICE GLUTEN FREE CUP4CUP 25# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004176001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2955276",
        "Last Price Paid": "$68.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004176",
        "Product Status": "Active"
    },
    {
        "Root": "10006329",
        "LV": "1",
        "Item Description": "PAPER DELI 6X10.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2139343",
        "Last Price Paid": "$0.01",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006329",
        "Product Status": "Active"
    },
    {
        "Root": "10004840",
        "LV": "1",
        "Item Description": "LENTIL FRENCH GREEN 6/2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "57245",
        "Last Price Paid": "$9.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004840",
        "Product Status": "Active"
    },
    {
        "Root": "10004840",
        "LV": "1",
        "Item Description": "LENTIL FRENCH GREEN 6/2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "26BJ",
        "Last Price Paid": "$9.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004840",
        "Product Status": "Active"
    },
    {
        "Root": "10084066",
        "LV": "1",
        "Item Description": "ICE CREAM BAR MICKEY PREMIUM 100TH ANNIV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.54",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084066",
        "Product Status": "Active"
    },
    {
        "Root": "10005040",
        "LV": "1",
        "Item Description": "TEA ENGLSH BRK DCF LS TIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005040",
        "Product Status": "Active"
    },
    {
        "Root": "10003731",
        "LV": "1",
        "Item Description": "LECITHIN SOY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003731",
        "Product Status": "Active"
    },
    {
        "Root": "10003731",
        "LV": "1",
        "Item Description": "LECITHIN SOY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "57619",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003731",
        "Product Status": "Active"
    },
    {
        "Root": "10008083",
        "LV": "2",
        "Item Description": "CAB CONCHA TORO MARQ 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008083002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "145468",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10008083",
        "Product Status": "Active"
    },
    {
        "Root": "10009874",
        "LV": "1",
        "Item Description": "APERITIF CYNAR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557424",
        "Last Price Paid": "$22.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009874",
        "Product Status": "Active"
    },
    {
        "Root": "10003621",
        "LV": "1",
        "Item Description": "SB 5LB CAFFE VERONA-",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7369",
        "Last Price Paid": "$49.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003621",
        "Product Status": "Active"
    },
    {
        "Root": "10006626",
        "LV": "1",
        "Item Description": "HAT SANIT PAPER OVERSEAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7013881",
        "Last Price Paid": "$0.14",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006626",
        "Product Status": "Active"
    },
    {
        "Root": "10107289",
        "LV": "1",
        "Item Description": "EXTRACT PURE ALMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "83016",
        "Last Price Paid": "$20.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107289",
        "Product Status": "Active"
    },
    {
        "Root": "10010822",
        "LV": "2",
        "Item Description": "BEER ORNGE BLOS PILS CAN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010822001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27323",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010822",
        "Product Status": "Active"
    },
    {
        "Root": "10016141",
        "LV": "1",
        "Item Description": "BREAD BUN BURGER PRETZEL SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "5380",
        "Last Price Paid": "$0.45",
        "Case Pack": "84",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016141",
        "Product Status": "Active"
    },
    {
        "Root": "10033413",
        "LV": "1",
        "Item Description": "BEER ALE CENTRAL 28 UNDER DUCK CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "64554",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033413",
        "Product Status": "Active"
    },
    {
        "Root": "10009796",
        "LV": "1",
        "Item Description": "RUM CAPT MORGAN SPEC 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009796001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.80",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009796",
        "Product Status": "Active"
    },
    {
        "Root": "10041336",
        "LV": "1",
        "Item Description": "ROLL, HAWAIIAN 240/1OZ UNSLICED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2483824",
        "Last Price Paid": "$0.22",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041336",
        "Product Status": "Active"
    },
    {
        "Root": "10001258",
        "LV": "2",
        "Item Description": "GARLIC PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001258001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "28010",
        "Last Price Paid": "$17.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10001258",
        "Product Status": "Active"
    },
    {
        "Root": "10000221",
        "LV": "1",
        "Item Description": "LETTUCE SPRING MIX 2 COUNT 4# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000221",
        "Product Status": "Active"
    },
    {
        "Root": "10106578",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER IVORY 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313022E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106578",
        "Product Status": "Active"
    },
    {
        "Root": "10009901",
        "LV": "1",
        "Item Description": "LIQ LICOR 43 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "341716",
        "Last Price Paid": "$20.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009901",
        "Product Status": "Active"
    },
    {
        "Root": "10001681",
        "LV": "1",
        "Item Description": "LETTUCE BOSTON 24 CT/20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001681001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001681",
        "Product Status": "Active"
    },
    {
        "Root": "10001681",
        "LV": "1",
        "Item Description": "LETTUCE BOSTON 24 CT/20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001681001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "601104",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001681",
        "Product Status": "Active"
    },
    {
        "Root": "10044384",
        "LV": "1",
        "Item Description": "COOKIE, EPCOT, KOURABIEDES CRESCENT SHAPED 75/1.7O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3307744",
        "Last Price Paid": "$0.58",
        "Case Pack": "75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044384",
        "Product Status": "Active"
    },
    {
        "Root": "10013089",
        "LV": "1",
        "Item Description": "HOR SPRING ROLL BUFFALO CHICKEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7302568",
        "Last Price Paid": "$1.74",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013089",
        "Product Status": "Active"
    },
    {
        "Root": "10007722",
        "LV": "2",
        "Item Description": "MALBEC BRAMARE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007722001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "427388",
        "Last Price Paid": "$35.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007722",
        "Product Status": "Active"
    },
    {
        "Root": "10000191",
        "LV": "1",
        "Item Description": "BROCCOLI 14 BUNCHES 28# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401050",
        "Last Price Paid": "$2.39",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000191",
        "Product Status": "Active"
    },
    {
        "Root": "10018547",
        "LV": "1",
        "Item Description": "CAVA MASFI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "627462",
        "Last Price Paid": "$8.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018547",
        "Product Status": "Active"
    },
    {
        "Root": "10018547",
        "LV": "1",
        "Item Description": "CAVA MASFI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "627462",
        "Last Price Paid": "$8.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018547",
        "Product Status": "Active"
    },
    {
        "Root": "10110789",
        "LV": "1",
        "Item Description": "CUP 12OZ JAR PINT \"COOKIE STROLL\" FOTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11121250",
        "Last Price Paid": "$0.92",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110789",
        "Product Status": "Active"
    },
    {
        "Root": "10038064",
        "LV": "1",
        "Item Description": "COMPOND WHITE STITCH SURFING 4X4.06CM 270/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233350000",
        "Last Price Paid": "$0.51",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038064",
        "Product Status": "Active"
    },
    {
        "Root": "10022789",
        "LV": "1",
        "Item Description": "PUMPKIN MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482115",
        "Last Price Paid": "$0.44",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022789",
        "Product Status": "Active"
    },
    {
        "Root": "10022789",
        "LV": "1",
        "Item Description": "PUMPKIN MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20556",
        "Last Price Paid": "$0.44",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022789",
        "Product Status": "Active"
    },
    {
        "Root": "10011959",
        "LV": "1",
        "Item Description": "KALE PESTO WITHOUT NUTS 6/30OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8203448",
        "Last Price Paid": "$9.87",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011959",
        "Product Status": "Active"
    },
    {
        "Root": "10001167",
        "LV": "1",
        "Item Description": "SALMON FLT SCOTTISH SKN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001167001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001167",
        "Product Status": "Active"
    },
    {
        "Root": "10001167",
        "LV": "1",
        "Item Description": "SALMON FLT SCOTTISH SKN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001167001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-50064",
        "Last Price Paid": "$10.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001167",
        "Product Status": "Active"
    },
    {
        "Root": "10001167",
        "LV": "1",
        "Item Description": "SALMON FLT SCOTTISH SKN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001167001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "8130658",
        "Last Price Paid": "$10.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001167",
        "Product Status": "Active"
    },
    {
        "Root": "10092571",
        "LV": "1",
        "Item Description": "SODA CAN COCA COLA CREATIONS LTO 8OZ/30CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "411426",
        "Last Price Paid": "$0.47",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092571",
        "Product Status": "Active"
    },
    {
        "Root": "10016242",
        "LV": "1",
        "Item Description": "CHIP CHEETO CRUNCHY 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016242001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9513417",
        "Last Price Paid": "$0.35",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016242",
        "Product Status": "Active"
    },
    {
        "Root": "10001475",
        "LV": "1",
        "Item Description": "POTATO BAKER 90CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408209",
        "Last Price Paid": "$0.48",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001475",
        "Product Status": "Active"
    },
    {
        "Root": "10033437",
        "LV": "1",
        "Item Description": "WINE VALL LLACH EMBRUIX 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9018028",
        "Last Price Paid": "$17.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033437",
        "Product Status": "Active"
    },
    {
        "Root": "10013319",
        "LV": "1",
        "Item Description": "CHEESE BLUE STATESBORO 5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013319001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "38019",
        "Last Price Paid": "$8.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013319",
        "Product Status": "Active"
    },
    {
        "Root": "10039966",
        "LV": "1",
        "Item Description": "CHEESE CREAM VEGAN MIYOKOS CHIVE 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "177415",
        "Last Price Paid": "$10.09",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039966",
        "Product Status": "Active"
    },
    {
        "Root": "10108251",
        "LV": "1",
        "Item Description": "MUG BASE NIGHT FIREWORKS GREEN LID 16OZ 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108251",
        "Product Status": "Active"
    },
    {
        "Root": "10009877",
        "LV": "2",
        "Item Description": "PORT GRAHAM 20 YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009877001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009877",
        "Product Status": "Active"
    },
    {
        "Root": "10108428",
        "LV": "1",
        "Item Description": "SUGAR-POINSETTIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28362",
        "Last Price Paid": "",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108428",
        "Product Status": "Active"
    },
    {
        "Root": "10036595",
        "LV": "1",
        "Item Description": "CHARD ORANGE DECOY BACKPACK 250ML 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "995504",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036595",
        "Product Status": "Active"
    },
    {
        "Root": "10033013",
        "LV": "1",
        "Item Description": "LID CLEAR DOME 5 INCH 52571B500",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303140",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033013",
        "Product Status": "Active"
    },
    {
        "Root": "10108229",
        "LV": "1",
        "Item Description": "MUG TIKI URSULA CAULDRON GG BLUE 15OZ 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108229",
        "Product Status": "Active"
    },
    {
        "Root": "10004532",
        "LV": "1",
        "Item Description": "MICKEY/MINNIE BRIDAL PAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW256",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004532",
        "Product Status": "Active"
    },
    {
        "Root": "10092928",
        "LV": "1",
        "Item Description": "CHOC GLAZE COINS HARD WHT, 13.2LB (6KG)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5006200000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092928",
        "Product Status": "Active"
    },
    {
        "Root": "10007112",
        "LV": "1",
        "Item Description": "RED BLEND PRISONER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007112",
        "Product Status": "Active"
    },
    {
        "Root": "10036435",
        "LV": "1",
        "Item Description": "18X13 1 18X13X2 WHITE/KRAFT PIZZA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51002246",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036435",
        "Product Status": "Active"
    },
    {
        "Root": "10104295",
        "LV": "1",
        "Item Description": "VEGETABLE BLEND, ROASTED CORN, ONION, & POBLANO PE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104295001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8211428",
        "Last Price Paid": "$6.53",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104295",
        "Product Status": "Active"
    },
    {
        "Root": "10003543",
        "LV": "1",
        "Item Description": "SYRUP COFF RED PASSION LT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5068",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003543",
        "Product Status": "Active"
    },
    {
        "Root": "10088662",
        "LV": "1",
        "Item Description": "CANDY DARK CHOCOLATE DOVE PROMISES 8/8.46OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7997799",
        "Last Price Paid": "$6.07",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088662",
        "Product Status": "Active"
    },
    {
        "Root": "10016524",
        "LV": "3",
        "Item Description": "BREAD TEXAS TOAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016524002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000415720",
        "Vendor": "FLOWERS BAKING",
        "Vendor Item Nb": "61140100",
        "Last Price Paid": "$1.99",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10016524",
        "Product Status": "Active"
    },
    {
        "Root": "10002714",
        "LV": "1",
        "Item Description": "CEREAL APPLE JACKS BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002714001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002714",
        "Product Status": "Active"
    },
    {
        "Root": "10038356",
        "LV": "1",
        "Item Description": "BOURBON MICHTERS SMALL BATCH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9013044",
        "Last Price Paid": "$30.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038356",
        "Product Status": "Active"
    },
    {
        "Root": "10003610",
        "LV": "1",
        "Item Description": "CHERRY MORELLO FRZN IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B214",
        "Last Price Paid": "$10.49",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003610",
        "Product Status": "Active"
    },
    {
        "Root": "10084120",
        "LV": "2",
        "Item Description": "DONUT GLAZED RASPBERRY JELLY DZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084120002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS102",
        "Last Price Paid": "$0.87",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084120",
        "Product Status": "Active"
    },
    {
        "Root": "10030317",
        "LV": "1",
        "Item Description": "NUTS ALMONDS NATURAL SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "110700",
        "Last Price Paid": "$116.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030317",
        "Product Status": "Active"
    },
    {
        "Root": "10000357",
        "LV": "1",
        "Item Description": "CARROTS DICED 1/4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "451254",
        "Last Price Paid": "$8.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000357",
        "Product Status": "Active"
    },
    {
        "Root": "10000357",
        "LV": "1",
        "Item Description": "CARROTS DICED 1/4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-008-14",
        "Last Price Paid": "$8.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000357",
        "Product Status": "Active"
    },
    {
        "Root": "10000357",
        "LV": "1",
        "Item Description": "CARROTS DICED 1/4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069667",
        "Vendor": "GARDEN GOURMET SPECIALTIES",
        "Vendor Item Nb": "512025",
        "Last Price Paid": "$8.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000357",
        "Product Status": "Active"
    },
    {
        "Root": "10000523",
        "LV": "2",
        "Item Description": "CUPCAKES, VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000523002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000523",
        "Product Status": "Active"
    },
    {
        "Root": "10042854",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE BDAY MICKEY EAR SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042854001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330CP2",
        "Last Price Paid": "$2.79",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042854",
        "Product Status": "Active"
    },
    {
        "Root": "10034793",
        "LV": "1",
        "Item Description": "DUCK MEAT DEBONED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034793001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZDUCABF030",
        "Last Price Paid": "$9.99",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034793",
        "Product Status": "Active"
    },
    {
        "Root": "10019966",
        "LV": "2",
        "Item Description": "BLND SKOURAS MOSHOFILERO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019966001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9128579",
        "Last Price Paid": "$11.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10019966",
        "Product Status": "Active"
    },
    {
        "Root": "10008859",
        "LV": "2",
        "Item Description": "BLND CAB JUSTIN ISOS RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008859001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "533629",
        "Last Price Paid": "$115.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008859",
        "Product Status": "Active"
    },
    {
        "Root": "10037882",
        "LV": "1",
        "Item Description": "COCOA NIBS IN DARK CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NOE991",
        "Last Price Paid": "$9.53",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037882",
        "Product Status": "Active"
    },
    {
        "Root": "10005884",
        "LV": "1",
        "Item Description": "BREAD BAGUETTE FRENCH 22 INCH 14 OZ EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005884001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005884",
        "Product Status": "Active"
    },
    {
        "Root": "10041603",
        "LV": "1",
        "Item Description": "SMOOTHIE STRAWBERRY BANANA NON DAIRY REFRI 12/11.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1140850",
        "Last Price Paid": "$2.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041603",
        "Product Status": "Active"
    },
    {
        "Root": "10004600",
        "LV": "1",
        "Item Description": "PINEAPPLE SLICED #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7333503",
        "Last Price Paid": "$8.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004600",
        "Product Status": "Active"
    },
    {
        "Root": "10041509",
        "LV": "4",
        "Item Description": "STRAW STIRRER PAPER BLACK WRAPPED 5.75 IN 4000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041509002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10041509",
        "Product Status": "Active"
    },
    {
        "Root": "10033957",
        "LV": "1",
        "Item Description": "NEBBIOLO GRESY MARTINENGA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "382066",
        "Last Price Paid": "$47.17",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033957",
        "Product Status": "Active"
    },
    {
        "Root": "10036951",
        "LV": "2",
        "Item Description": "SB TOPPING CARAMEL RIBBON CRUNCH 6OZ SHAKER 8 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036951002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.97",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036951",
        "Product Status": "Active"
    },
    {
        "Root": "10003894",
        "LV": "1",
        "Item Description": "DECOR CHOC SPEAR 8IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "91775",
        "Last Price Paid": "$0.08",
        "Case Pack": "2940",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003894",
        "Product Status": "Active"
    },
    {
        "Root": "10007077",
        "LV": "1",
        "Item Description": "BLANC BARSAC CHAT DUCASSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "968091",
        "Last Price Paid": "$14.82",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007077",
        "Product Status": "Active"
    },
    {
        "Root": "10016337",
        "LV": "1",
        "Item Description": "CUPCAKE LG CHOC ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1647",
        "Last Price Paid": "$1.08",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016337",
        "Product Status": "Active"
    },
    {
        "Root": "10035369",
        "LV": "1",
        "Item Description": "PASTE VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$43.91",
        "Case Pack": "26",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035369",
        "Product Status": "Active"
    },
    {
        "Root": "10008557",
        "LV": "1",
        "Item Description": "SAUV BLNC REYNEKE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9238479",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008557",
        "Product Status": "Active"
    },
    {
        "Root": "10013443",
        "LV": "1",
        "Item Description": "ONION RED LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013443001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ONRDLB",
        "Last Price Paid": "$0.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013443",
        "Product Status": "Active"
    },
    {
        "Root": "10013630",
        "LV": "1",
        "Item Description": "ROLL BUN DARK SIDE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "23076",
        "Last Price Paid": "$0.89",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013630",
        "Product Status": "Active"
    },
    {
        "Root": "10012444",
        "LV": "2",
        "Item Description": "GUMPASTE-MAGNOLIA ASSORTMENT-WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012444002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "18071",
        "Last Price Paid": "$2.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10012444",
        "Product Status": "Active"
    },
    {
        "Root": "10004529",
        "LV": "1",
        "Item Description": "SILKSCREEN CASTLE LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "208808-KLM-A",
        "Last Price Paid": "$75.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004529",
        "Product Status": "Active"
    },
    {
        "Root": "10004836",
        "LV": "1",
        "Item Description": "TRUFFLE OIL BLACK 8.4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004836",
        "Product Status": "Active"
    },
    {
        "Root": "10021833",
        "LV": "1",
        "Item Description": "SHAKER GOLD FLAKE 100 MG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CRGG/CUBE",
        "Last Price Paid": "$159.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021833",
        "Product Status": "Active"
    },
    {
        "Root": "10010183",
        "LV": "1",
        "Item Description": "SODA CLUB SEAGRAM CAN 12OZ/24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010183",
        "Product Status": "Active"
    },
    {
        "Root": "10036318",
        "LV": "1",
        "Item Description": "CAKE MIX VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "A60151",
        "Last Price Paid": "$46.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036318",
        "Product Status": "Active"
    },
    {
        "Root": "10091227",
        "LV": "1",
        "Item Description": "WINE CRIOS TORRONTES ARGENTINA 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091227001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9189091",
        "Last Price Paid": "$9.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091227",
        "Product Status": "Active"
    },
    {
        "Root": "10001712",
        "LV": "1",
        "Item Description": "BOK CHOY BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480043",
        "Last Price Paid": "$12.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001712",
        "Product Status": "Active"
    },
    {
        "Root": "10001712",
        "LV": "1",
        "Item Description": "BOK CHOY BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001712",
        "Product Status": "Active"
    },
    {
        "Root": "10000095",
        "LV": "1",
        "Item Description": "PORK T-BONE CHOP KUROBOTA 1.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000095001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2491360",
        "Last Price Paid": "$11.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000095",
        "Product Status": "Active"
    },
    {
        "Root": "10091073",
        "LV": "1",
        "Item Description": "POPCORN STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "STARCRUISER",
        "Last Price Paid": "$1.35",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091073",
        "Product Status": "Active"
    },
    {
        "Root": "10010119",
        "LV": "2",
        "Item Description": "WATER VOSS PET 500ML 24CT STILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010119001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1484516",
        "Last Price Paid": "$1.09",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010119",
        "Product Status": "Active"
    },
    {
        "Root": "10038820",
        "LV": "1",
        "Item Description": "MUFFIN BLUEBERRY LARGE 6.5 OUNCE INDV WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038820",
        "Product Status": "Active"
    },
    {
        "Root": "10014126",
        "LV": "1",
        "Item Description": "FRY YUCCA BATTERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "273458",
        "Last Price Paid": "$1.75",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014126",
        "Product Status": "Active"
    },
    {
        "Root": "10004593",
        "LV": "1",
        "Item Description": "CORN MEAL WHITE PLAIN 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2213999",
        "Last Price Paid": "$33.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004593",
        "Product Status": "Active"
    },
    {
        "Root": "10010537",
        "LV": "2",
        "Item Description": "WINE CHAMPAGNE ROSE POMMERY POP 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010537001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "95607",
        "Last Price Paid": "$14.00",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010537",
        "Product Status": "Active"
    },
    {
        "Root": "10000398",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN 9#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482310",
        "Last Price Paid": "$15.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000398",
        "Product Status": "Active"
    },
    {
        "Root": "10000398",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN 9#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000398",
        "Product Status": "Active"
    },
    {
        "Root": "10000398",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN 9#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21610",
        "Last Price Paid": "$15.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000398",
        "Product Status": "Active"
    },
    {
        "Root": "10000398",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN 9#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510526",
        "Last Price Paid": "$15.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000398",
        "Product Status": "Active"
    },
    {
        "Root": "10108688",
        "LV": "1",
        "Item Description": "PROFITEROLE/CREAM PUFF MINI ALL BTR 43MM 200PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5892060000",
        "Last Price Paid": "$0.24",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108688",
        "Product Status": "Active"
    },
    {
        "Root": "10000168",
        "LV": "1",
        "Item Description": "ORANGE 56 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000168",
        "Product Status": "Active"
    },
    {
        "Root": "10016523",
        "LV": "4",
        "Item Description": "BEER KEG SMITHWICKS IRISH RED 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016523003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10016523",
        "Product Status": "Active"
    },
    {
        "Root": "10014221",
        "LV": "1",
        "Item Description": "CHOC DRK RECT CONGRATULATIONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW240103R",
        "Last Price Paid": "$14.50",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014221",
        "Product Status": "Active"
    },
    {
        "Root": "10088247",
        "LV": "1",
        "Item Description": "GARNISH DEHYDRATED ORANGE WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088247001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "OC-1",
        "Last Price Paid": "$0.25",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088247",
        "Product Status": "Active"
    },
    {
        "Root": "10001914",
        "LV": "1",
        "Item Description": "SALSIFY OYSTER ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593096",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001914",
        "Product Status": "Active"
    },
    {
        "Root": "10001914",
        "LV": "1",
        "Item Description": "SALSIFY OYSTER ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001914",
        "Product Status": "Active"
    },
    {
        "Root": "10001914",
        "LV": "1",
        "Item Description": "SALSIFY OYSTER ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001914",
        "Product Status": "Active"
    },
    {
        "Root": "10086532",
        "LV": "1",
        "Item Description": "GELATO BLACKBERRY PLANT BASED ALLERGEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "P-BKBY-08-CS4",
        "Last Price Paid": "$19.38",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086532",
        "Product Status": "Active"
    },
    {
        "Root": "10003728",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE DOME 3IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "GE00744W",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003728",
        "Product Status": "Active"
    },
    {
        "Root": "10018080",
        "LV": "1",
        "Item Description": "LIQ COMBIER PAMPLEMOUSSE ROSE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9205963",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018080",
        "Product Status": "Active"
    },
    {
        "Root": "10040995",
        "LV": "1",
        "Item Description": "VINEGAR RICE WINE MARUKAN 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5445424",
        "Last Price Paid": "$7.52",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040995",
        "Product Status": "Active"
    },
    {
        "Root": "10039770",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 6Z - AUS STEER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039770001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195051",
        "Last Price Paid": "$19.72",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039770",
        "Product Status": "Active"
    },
    {
        "Root": "10027480",
        "LV": "1",
        "Item Description": "ONION PETITE COIN 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MPON-33",
        "Last Price Paid": "$0.68",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027480",
        "Product Status": "Active"
    },
    {
        "Root": "10042862",
        "LV": "1",
        "Item Description": "BAG BRONZE ZIPPER OVAL WINDOW 5.125X 3.125X 8.125",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51012654",
        "Last Price Paid": "$0.21",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042862",
        "Product Status": "Active"
    },
    {
        "Root": "10012876",
        "LV": "1",
        "Item Description": "COLOR RED GEL PASTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "RED RED",
        "Last Price Paid": "$30.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012876",
        "Product Status": "Active"
    },
    {
        "Root": "10076278",
        "LV": "1",
        "Item Description": "BOX CAKE AMORETTES 4X4X4 2 COLOR WITH FOILING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013662",
        "Last Price Paid": "$0.88",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076278",
        "Product Status": "Active"
    },
    {
        "Root": "10014368",
        "LV": "1",
        "Item Description": "SORBET RASPBERRY NSA ITALIAN 3 GALLON TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "NSASOR3-002",
        "Last Price Paid": "$63.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014368",
        "Product Status": "Active"
    },
    {
        "Root": "10003237",
        "LV": "2",
        "Item Description": "SPICE NUTMEG WHOLE 6/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003237002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003237",
        "Product Status": "Active"
    },
    {
        "Root": "10037075",
        "LV": "1",
        "Item Description": "HONEYCOMB WHOLE FRAME WITH STAND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037075001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "02HCF-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037075",
        "Product Status": "Active"
    },
    {
        "Root": "10037043",
        "LV": "1",
        "Item Description": "GARNACHA CLOS MOGADOR PRIORAT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "538511",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037043",
        "Product Status": "Active"
    },
    {
        "Root": "10023115",
        "LV": "1",
        "Item Description": "CORN STALK OUTDOOR USE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023115001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482024",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023115",
        "Product Status": "Active"
    },
    {
        "Root": "10005128",
        "LV": "1",
        "Item Description": "CANDY ORANGE PEEL DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8...{PARADISE{",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005128",
        "Product Status": "Active"
    },
    {
        "Root": "10039105",
        "LV": "1",
        "Item Description": "FILLING MINTOSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039105001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039105",
        "Product Status": "Active"
    },
    {
        "Root": "10025456",
        "LV": "1",
        "Item Description": "PREMIUM CANDY COLOR YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21313",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025456",
        "Product Status": "Active"
    },
    {
        "Root": "10038588",
        "LV": "1",
        "Item Description": "FLAT INSERT CLEAR CPL-CS-2P 2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11003230",
        "Last Price Paid": "",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038588",
        "Product Status": "Active"
    },
    {
        "Root": "10002716",
        "LV": "1",
        "Item Description": "CEREAL FROSTED FLAKES BOWL 96CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002716",
        "Product Status": "Active"
    },
    {
        "Root": "10036215",
        "LV": "1",
        "Item Description": "TULIP MEDIUM PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "STL PK",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036215",
        "Product Status": "Active"
    },
    {
        "Root": "10033663",
        "LV": "1",
        "Item Description": "SUGAR PALM TREE ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033663",
        "Product Status": "Active"
    },
    {
        "Root": "10008372",
        "LV": "3",
        "Item Description": "CHAMP SALON BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008372003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9690149",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10008372",
        "Product Status": "Active"
    },
    {
        "Root": "10107708",
        "LV": "1",
        "Item Description": "POPCORN GLAZE RED CINNAMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2526",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107708",
        "Product Status": "Active"
    },
    {
        "Root": "10100768",
        "LV": "1",
        "Item Description": "PEPPER MIXED HOT 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100768001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "10MXH-2",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100768",
        "Product Status": "Active"
    },
    {
        "Root": "10009439",
        "LV": "2",
        "Item Description": "RIOJA MUGA RESERVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009439001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001045.14",
        "Last Price Paid": "$23.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009439",
        "Product Status": "Active"
    },
    {
        "Root": "10003085",
        "LV": "1",
        "Item Description": "CHILI MEAT W/BEANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003085001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4608607",
        "Last Price Paid": "$20.82",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003085",
        "Product Status": "Active"
    },
    {
        "Root": "10107229",
        "LV": "1",
        "Item Description": "EGG PATTY FC RND  1.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7180351",
        "Last Price Paid": "$1.11",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107229",
        "Product Status": "Active"
    },
    {
        "Root": "10005738",
        "LV": "1",
        "Item Description": "BREAD PITA MINI 7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1256212",
        "Last Price Paid": "$0.11",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005738",
        "Product Status": "Active"
    },
    {
        "Root": "10010426",
        "LV": "2",
        "Item Description": "BEER LABATTS BLUE CANADA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010426001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10394",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010426",
        "Product Status": "Active"
    },
    {
        "Root": "10012022",
        "LV": "1",
        "Item Description": "ARUGASABI BLOOMS 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05ASB-E",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012022",
        "Product Status": "Active"
    },
    {
        "Root": "10108628",
        "LV": "1",
        "Item Description": "PEPPERMINT BARK DARK CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "9057DW11",
        "Last Price Paid": "$85.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108628",
        "Product Status": "Active"
    },
    {
        "Root": "10034274",
        "LV": "1",
        "Item Description": "JUICE BLOOD ORANGE 16 X 1 QT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "32033",
        "Last Price Paid": "$4.44",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034274",
        "Product Status": "Active"
    },
    {
        "Root": "10000174",
        "LV": "1",
        "Item Description": "ORANGE 88 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "13204",
        "Last Price Paid": "$0.54",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000174",
        "Product Status": "Active"
    },
    {
        "Root": "10000174",
        "LV": "1",
        "Item Description": "ORANGE 88 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "210088",
        "Last Price Paid": "$0.54",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000174",
        "Product Status": "Active"
    },
    {
        "Root": "10015761",
        "LV": "1",
        "Item Description": "CAKE SPONGE 8 INCH YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1606",
        "Last Price Paid": "$6.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015761",
        "Product Status": "Active"
    },
    {
        "Root": "10025435",
        "LV": "1",
        "Item Description": "DECOR FLOWER FALL 3.5 IN ASST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12592",
        "Last Price Paid": "$2.65",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025435",
        "Product Status": "Active"
    },
    {
        "Root": "10004395",
        "LV": "1",
        "Item Description": "SB TEAV ROY ENG BKFAST CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11046309",
        "Last Price Paid": "$0.23",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004395",
        "Product Status": "Active"
    },
    {
        "Root": "10100272",
        "LV": "1",
        "Item Description": "LIQUEUR MIDORI MELON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100272",
        "Product Status": "Active"
    },
    {
        "Root": "10082139",
        "LV": "1",
        "Item Description": "BEER CRT AUDIO VISUAL 15.5 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "11330",
        "Last Price Paid": "$204.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082139",
        "Product Status": "Active"
    },
    {
        "Root": "10016566",
        "LV": "1",
        "Item Description": "YOGURT PARFAIT FRUIT 9 OZ PREPARED FOODS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1707015",
        "Last Price Paid": "$3.90",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016566",
        "Product Status": "Active"
    },
    {
        "Root": "10022535",
        "LV": "1",
        "Item Description": "BITTERS CHOC FEE BROS 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "202194",
        "Last Price Paid": "$6.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022535",
        "Product Status": "Active"
    },
    {
        "Root": "10027344",
        "LV": "1",
        "Item Description": "PINOT NOIR BANSHEE KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "991632",
        "Last Price Paid": "$312.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027344",
        "Product Status": "Active"
    },
    {
        "Root": "10016791",
        "LV": "1",
        "Item Description": "CHARD HARTENBERG ELEANOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60047015",
        "Last Price Paid": "$33.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016791",
        "Product Status": "Active"
    },
    {
        "Root": "10009473",
        "LV": "1",
        "Item Description": "SCOTCH SNGL GLENMRNG SIGNET 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009473001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "224365",
        "Last Price Paid": "$229.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009473",
        "Product Status": "Active"
    },
    {
        "Root": "10004115",
        "LV": "1",
        "Item Description": "SB SYRUP HAZELNUT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004115001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108263",
        "Last Price Paid": "$6.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004115",
        "Product Status": "Active"
    },
    {
        "Root": "10005217",
        "LV": "1",
        "Item Description": "SB HOLIDAY BLND RST 5LB B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11023476",
        "Last Price Paid": "$57.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005217",
        "Product Status": "Active"
    },
    {
        "Root": "10000742",
        "LV": "1",
        "Item Description": "BEEF RIBEYE LIPON PRIME ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000742001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0112815",
        "Last Price Paid": "$15.21",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000742",
        "Product Status": "Active"
    },
    {
        "Root": "10001473",
        "LV": "2",
        "Item Description": "ONION SPANISH COLOSSAL 50# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001473002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001473",
        "Product Status": "Active"
    },
    {
        "Root": "10000868",
        "LV": "1",
        "Item Description": "MEATBALLS TURKEY 1-2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4100007",
        "Last Price Paid": "$0.11",
        "Case Pack": "320",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000868",
        "Product Status": "Active"
    },
    {
        "Root": "10103511",
        "LV": "1",
        "Item Description": "CHEESE MOZZARELLA PROVOLONE SHREDDED 50/50 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103511001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8410805",
        "Last Price Paid": "$10.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103511",
        "Product Status": "Active"
    },
    {
        "Root": "10001557",
        "LV": "1",
        "Item Description": "PARSNIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510043",
        "Last Price Paid": "$17.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001557",
        "Product Status": "Active"
    },
    {
        "Root": "10100311",
        "LV": "2",
        "Item Description": "CUP DESSERT DISPOSABLE PET CDS 5OZ CLASSIC 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100311002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$68.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100311",
        "Product Status": "Active"
    },
    {
        "Root": "10083149",
        "LV": "1",
        "Item Description": "BUCKET POPCORN DRAWN TO LIFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.17",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083149",
        "Product Status": "Active"
    },
    {
        "Root": "10033749",
        "LV": "1",
        "Item Description": "QUINS WILDFLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21336",
        "Last Price Paid": "$5.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033749",
        "Product Status": "Active"
    },
    {
        "Root": "10036679",
        "LV": "1",
        "Item Description": "CORVINA FILET SKINLESS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036679001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3785538",
        "Last Price Paid": "$13.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036679",
        "Product Status": "Active"
    },
    {
        "Root": "10088609",
        "LV": "1",
        "Item Description": "SUGAR ARCTIC WHITE SNOW 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "0860010000",
        "Last Price Paid": "$41.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088609",
        "Product Status": "Active"
    },
    {
        "Root": "10106149",
        "LV": "2",
        "Item Description": "CONTAINER COLD-HOT 4OZ WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106149002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106149",
        "Product Status": "Active"
    },
    {
        "Root": "10106149",
        "LV": "2",
        "Item Description": "CONTAINER COLD-HOT 4OZ WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106149002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "104387010",
        "Last Price Paid": "$47.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106149",
        "Product Status": "Active"
    },
    {
        "Root": "10107469",
        "LV": "1",
        "Item Description": "PORK CHOP KAN KAN 1.5\" DUROC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107469001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1450450",
        "Last Price Paid": "$6.38",
        "Case Pack": "57",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107469",
        "Product Status": "Active"
    },
    {
        "Root": "10106633",
        "LV": "1",
        "Item Description": "BAG COTTON CANDY 1000CT D100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3071D",
        "Last Price Paid": "$0.12",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106633",
        "Product Status": "Active"
    },
    {
        "Root": "10004868",
        "LV": "1",
        "Item Description": "VINEGAR RIESLING WINE 4/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CS200",
        "Last Price Paid": "$19.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004868",
        "Product Status": "Active"
    },
    {
        "Root": "10004311",
        "LV": "1",
        "Item Description": "HORS SCALLOP IN BACON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300342",
        "Last Price Paid": "$1.16",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004311",
        "Product Status": "Active"
    },
    {
        "Root": "10100909",
        "LV": "1",
        "Item Description": "PETITE SORREL GRN APPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22152",
        "Last Price Paid": "$0.15",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100909",
        "Product Status": "Active"
    },
    {
        "Root": "10008296",
        "LV": "1",
        "Item Description": "PINOT GRIG SANTA MARGARITA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "229689",
        "Last Price Paid": "$16.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008296",
        "Product Status": "Active"
    },
    {
        "Root": "10019388",
        "LV": "1",
        "Item Description": "CHOC 2\" DISC DARK NO PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2052",
        "Last Price Paid": "$0.35",
        "Case Pack": "506",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019388",
        "Product Status": "Active"
    },
    {
        "Root": "10000207",
        "LV": "1",
        "Item Description": "SPINACH BABY CELLO 2X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482065",
        "Last Price Paid": "$14.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000207",
        "Product Status": "Active"
    },
    {
        "Root": "10011952",
        "LV": "1",
        "Item Description": "OYSTER BLUEPOINT SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "2235570",
        "Last Price Paid": "$88.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011952",
        "Product Status": "Active"
    },
    {
        "Root": "10013881",
        "LV": "1",
        "Item Description": "RADISH BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013881001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480012",
        "Last Price Paid": "$41.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013881",
        "Product Status": "Active"
    },
    {
        "Root": "10040217",
        "LV": "1",
        "Item Description": "BACON END/PCS SMKD APPLWD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040217001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3433530",
        "Last Price Paid": "$3.47",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040217",
        "Product Status": "Active"
    },
    {
        "Root": "10028498",
        "LV": "1",
        "Item Description": "ZINFANDEL MAURITSON DRY CREEK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "MAUR-02-14",
        "Last Price Paid": "$25.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028498",
        "Product Status": "Active"
    },
    {
        "Root": "10007838",
        "LV": "1",
        "Item Description": "CHENIN BLC GAMESKPR RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "480858",
        "Last Price Paid": "$13.41",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007838",
        "Product Status": "Active"
    },
    {
        "Root": "10036623",
        "LV": "1",
        "Item Description": "NON PAREILS WHITE PEARL GRANDE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "18200",
        "Last Price Paid": "$30.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036623",
        "Product Status": "Active"
    },
    {
        "Root": "10002176",
        "LV": "1",
        "Item Description": "ICE CREAM BAR ORANGE CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002176",
        "Product Status": "Active"
    },
    {
        "Root": "10035647",
        "LV": "1",
        "Item Description": "PHOTOCAKE INK CARTRIDGE MAGENTA XP430 T288XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035647001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22672",
        "Last Price Paid": "$16.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035647",
        "Product Status": "Active"
    },
    {
        "Root": "10081328",
        "LV": "2",
        "Item Description": "FLAVORING BIRTHDAY CAKE ESSENCE 50TH ANNIVERSARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081328001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000534944",
        "Vendor": "SENSIENT TECHNOLOGIES CORP FLAVORS",
        "Vendor Item Nb": "SN3000000992",
        "Last Price Paid": "$327.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10081328",
        "Product Status": "Active"
    },
    {
        "Root": "10025396",
        "LV": "1",
        "Item Description": "COFFEE PERU MAYO ORG WHL BEAN 1# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025396",
        "Product Status": "Active"
    },
    {
        "Root": "10037999",
        "LV": "1",
        "Item Description": "TEMPRANILLO DOMINIO DE PINGUS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "000325",
        "Last Price Paid": "$935.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037999",
        "Product Status": "Active"
    },
    {
        "Root": "10004709",
        "LV": "2",
        "Item Description": "NUT CASHEW R/Z 24/2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004709002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203006",
        "Last Price Paid": "$0.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004709",
        "Product Status": "Active"
    },
    {
        "Root": "10010066",
        "LV": "1",
        "Item Description": "BEER CORONITA EXTRA 7Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.06",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010066",
        "Product Status": "Active"
    },
    {
        "Root": "10001562",
        "LV": "1",
        "Item Description": "POTATO FINGERLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489956",
        "Last Price Paid": "$27.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001562",
        "Product Status": "Active"
    },
    {
        "Root": "10005677",
        "LV": "1",
        "Item Description": "BREAD BLACK OLIVE LOAF 1.25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "1.25",
        "Last Price Paid": "$4.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005677",
        "Product Status": "Active"
    },
    {
        "Root": "10004363",
        "LV": "1",
        "Item Description": "SPRAY VELVET YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV004Y",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004363",
        "Product Status": "Active"
    },
    {
        "Root": "10082138",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS NONALCOHOLIC APPLE CINN 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99726",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082138",
        "Product Status": "Active"
    },
    {
        "Root": "10005781",
        "LV": "1",
        "Item Description": "CAKE ROUND 9IN, VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$87.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005781",
        "Product Status": "Active"
    },
    {
        "Root": "10104610",
        "LV": "3",
        "Item Description": "CRAB JONAH COCKTAIL CLAW 16/20",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104610003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6339506",
        "Last Price Paid": "$17.99",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10104610",
        "Product Status": "Active"
    },
    {
        "Root": "10012409",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN CHUNKS 1.25Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012409001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "8000043",
        "Last Price Paid": "$6.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012409",
        "Product Status": "Active"
    },
    {
        "Root": "10110991",
        "LV": "1",
        "Item Description": "CHOCOLATE GRAHAM CRACKERS FROZEN RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "CHGRA",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110991",
        "Product Status": "Active"
    },
    {
        "Root": "10023675",
        "LV": "1",
        "Item Description": "CHARD DEMORGENZON 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023675",
        "Product Status": "Active"
    },
    {
        "Root": "10084128",
        "LV": "1",
        "Item Description": "POPCORN CHILI LIME CARAMEL CORN 50/8 OZ BAGS 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "CHILI LIME",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084128",
        "Product Status": "Active"
    },
    {
        "Root": "10009530",
        "LV": "2",
        "Item Description": "WHSKY SMOKY BLKBRRY MOONSHIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009530001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009530",
        "Product Status": "Active"
    },
    {
        "Root": "10028970",
        "LV": "2",
        "Item Description": "WINE SOKOL BLOSSER EVOLUTION PINOT NOIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028970001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "512236",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10028970",
        "Product Status": "Active"
    },
    {
        "Root": "10007085",
        "LV": "2",
        "Item Description": "TENUTA SAN GUID TUSC SAS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007085001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "314762",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007085",
        "Product Status": "Active"
    },
    {
        "Root": "10028309",
        "LV": "2",
        "Item Description": "WINE BOVE VOUVRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028309001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "660497",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10028309",
        "Product Status": "Active"
    },
    {
        "Root": "10103446",
        "LV": "1",
        "Item Description": "CANDY M&M PEANUT 12/3.1OZ THEATER BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "20323",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103446",
        "Product Status": "Active"
    },
    {
        "Root": "10027874",
        "LV": "2",
        "Item Description": "CABERNET SAUVIGNON J LOHR SIGNATURE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027874002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "61708",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027874",
        "Product Status": "Active"
    },
    {
        "Root": "10041315",
        "LV": "1",
        "Item Description": "DRINK TROPICANA CRANBERRY 12/15.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8484719",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041315",
        "Product Status": "Active"
    },
    {
        "Root": "10034549",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC BOURGEOIS FUME 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034549",
        "Product Status": "Active"
    },
    {
        "Root": "10004699",
        "LV": "1",
        "Item Description": "DRIED FRUIT CHERRY 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004699001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "231050",
        "Last Price Paid": "$79.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004699",
        "Product Status": "Active"
    },
    {
        "Root": "10002819",
        "LV": "1",
        "Item Description": "TEA GREEN DECF INROOM 20C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002819",
        "Product Status": "Active"
    },
    {
        "Root": "10003177",
        "LV": "1",
        "Item Description": "SYRUP COFF GINGER LTR 4EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5018",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003177",
        "Product Status": "Active"
    },
    {
        "Root": "10009187",
        "LV": "2",
        "Item Description": "ZINF STORYBOOK MAYACAMAS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009187001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9190021",
        "Last Price Paid": "$29.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009187",
        "Product Status": "Active"
    },
    {
        "Root": "10011400",
        "LV": "2",
        "Item Description": "CROUTON SEASONED HOMESTYLE 4/2.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011400002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1805379",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011400",
        "Product Status": "Active"
    },
    {
        "Root": "10001928",
        "LV": "1",
        "Item Description": "MELON HONEYDEW PLD/HLVD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-002H-A",
        "Last Price Paid": "$58.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001928",
        "Product Status": "Active"
    },
    {
        "Root": "10001928",
        "LV": "1",
        "Item Description": "MELON HONEYDEW PLD/HLVD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598335",
        "Last Price Paid": "$58.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001928",
        "Product Status": "Active"
    },
    {
        "Root": "10001753",
        "LV": "1",
        "Item Description": "BROCCOLI RAPINI 18#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20215",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001753",
        "Product Status": "Active"
    },
    {
        "Root": "10001753",
        "LV": "1",
        "Item Description": "BROCCOLI RAPINI 18#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001753",
        "Product Status": "Active"
    },
    {
        "Root": "10001753",
        "LV": "1",
        "Item Description": "BROCCOLI RAPINI 18#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401058",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001753",
        "Product Status": "Active"
    },
    {
        "Root": "10014405",
        "LV": "1",
        "Item Description": "YUKON POTATO (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408480",
        "Last Price Paid": "$30.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014405",
        "Product Status": "Active"
    },
    {
        "Root": "10002400",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRIE FRANCE 6.6 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002400001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "90660",
        "Last Price Paid": "$8.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002400",
        "Product Status": "Active"
    },
    {
        "Root": "10026982",
        "LV": "1",
        "Item Description": "WHISKEY OLE SMOKEY STRAWBERRY MOONSHINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "586199",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026982",
        "Product Status": "Active"
    },
    {
        "Root": "10027539",
        "LV": "2",
        "Item Description": "PORK BELLY SEASONED CKD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027539001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7028939",
        "Last Price Paid": "$7.18",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10027539",
        "Product Status": "Active"
    },
    {
        "Root": "10026257",
        "LV": "1",
        "Item Description": "GRIT HOMINY WHITE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026257001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026257",
        "Product Status": "Active"
    },
    {
        "Root": "10011666",
        "LV": "1",
        "Item Description": "LIVERWURST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011666001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "158",
        "Last Price Paid": "$4.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011666",
        "Product Status": "Active"
    },
    {
        "Root": "10024676",
        "LV": "1",
        "Item Description": "BOAR WILD TENDERLOIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024676001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7040310",
        "Last Price Paid": "$14.52",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024676",
        "Product Status": "Active"
    },
    {
        "Root": "10036222",
        "LV": "1",
        "Item Description": "COCOA BUTTER VICTORIA PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302109C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036222",
        "Product Status": "Active"
    },
    {
        "Root": "10028528",
        "LV": "2",
        "Item Description": "MILK SOY VANILLA SILK 6/64OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028528002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.10",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028528",
        "Product Status": "Active"
    },
    {
        "Root": "10011863",
        "LV": "1",
        "Item Description": "DRAFT KEY WEST SUNSET ALE 1/6 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011863001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76680",
        "Last Price Paid": "$81.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011863",
        "Product Status": "Active"
    },
    {
        "Root": "10001701",
        "LV": "1",
        "Item Description": "HERB ROSEMARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001701001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482257",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001701",
        "Product Status": "Active"
    },
    {
        "Root": "10001701",
        "LV": "1",
        "Item Description": "HERB ROSEMARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001701001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24130",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001701",
        "Product Status": "Active"
    },
    {
        "Root": "10001701",
        "LV": "1",
        "Item Description": "HERB ROSEMARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001701001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001701",
        "Product Status": "Active"
    },
    {
        "Root": "10004730",
        "LV": "1",
        "Item Description": "PEPPER CHERRY SWEET GALLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1810225",
        "Last Price Paid": "$11.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004730",
        "Product Status": "Active"
    },
    {
        "Root": "10004330",
        "LV": "1",
        "Item Description": "SAMOSA TRAD JUMBO 2.75Z F",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043451",
        "Last Price Paid": "$0.79",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004330",
        "Product Status": "Active"
    },
    {
        "Root": "10035659",
        "LV": "2",
        "Item Description": "HOT SAUCE CHOLULA 12/5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035659002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1285548",
        "Last Price Paid": "$2.37",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035659",
        "Product Status": "Active"
    },
    {
        "Root": "10013917",
        "LV": "1",
        "Item Description": "FLOUR CUP4CUP WHOLESOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "111",
        "Last Price Paid": "$78.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013917",
        "Product Status": "Active"
    },
    {
        "Root": "10002782",
        "LV": "1",
        "Item Description": "SYRUP POWERADE MTN BLAST 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$95.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002782",
        "Product Status": "Active"
    },
    {
        "Root": "10006947",
        "LV": "2",
        "Item Description": "RIES JH SELBACH QBA RED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006947001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006947",
        "Product Status": "Active"
    },
    {
        "Root": "10034602",
        "LV": "1",
        "Item Description": "RASPBERRY GOURMET SORBET TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "GS-009-T",
        "Last Price Paid": "$9.00",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034602",
        "Product Status": "Active"
    },
    {
        "Root": "10012398",
        "LV": "1",
        "Item Description": "DEC DAISY PINK GERBER 3\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7094",
        "Last Price Paid": "$1.91",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012398",
        "Product Status": "Active"
    },
    {
        "Root": "10009371",
        "LV": "2",
        "Item Description": "CHAMPAGNE CLICQUOT PONSARDIN BRUT ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009371001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "403137",
        "Last Price Paid": "$42.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009371",
        "Product Status": "Active"
    },
    {
        "Root": "10036379",
        "LV": "2",
        "Item Description": "FIGENZA FIG VODKA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036379002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036379",
        "Product Status": "Active"
    },
    {
        "Root": "10019846",
        "LV": "1",
        "Item Description": "COASTER FLYING FISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731043",
        "Last Price Paid": "$0.11",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019846",
        "Product Status": "Active"
    },
    {
        "Root": "10109528",
        "LV": "1",
        "Item Description": "BEER KEG SAM ADAMS SEASONAL 5.17GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109528001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$75.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109528",
        "Product Status": "Active"
    },
    {
        "Root": "10019177",
        "LV": "2",
        "Item Description": "GLASS 45 OZ PLASTIC CLR MARGARITA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019177002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000071765",
        "Vendor": "PROGRESSIVE SPECIALTY GLASS COMPANY",
        "Vendor Item Nb": "350833-5330-1P",
        "Last Price Paid": "$3.89",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019177",
        "Product Status": "Active"
    },
    {
        "Root": "10013378",
        "LV": "1",
        "Item Description": "BEER ANGRY ORCHARD CRISP APPLE CAN 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21350",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013378",
        "Product Status": "Active"
    },
    {
        "Root": "10005392",
        "LV": "1",
        "Item Description": "NUT PISTACHIO RAW SHELLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "182550",
        "Last Price Paid": "$288.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005392",
        "Product Status": "Active"
    },
    {
        "Root": "10016961",
        "LV": "1",
        "Item Description": "SPICE THYME GRND 13 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760546",
        "Last Price Paid": "$15.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016961",
        "Product Status": "Active"
    },
    {
        "Root": "10101417",
        "LV": "1",
        "Item Description": "WAFFLE CONES LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "LV-M",
        "Last Price Paid": "$0.82",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101417",
        "Product Status": "Active"
    },
    {
        "Root": "10004428",
        "LV": "1",
        "Item Description": "CHOC WHITE CASTLE MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004428",
        "Product Status": "Active"
    },
    {
        "Root": "10103817",
        "LV": "1",
        "Item Description": "CREAMER HAZELNUT SINGLE SERVE 192 CT/CS DELIGHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103817",
        "Product Status": "Active"
    },
    {
        "Root": "10002951",
        "LV": "1",
        "Item Description": "TEA TWINNG LEMN GINGR 120",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002951",
        "Product Status": "Active"
    },
    {
        "Root": "10005203",
        "LV": "1",
        "Item Description": "STARCH POTATO BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101294",
        "Last Price Paid": "$3.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005203",
        "Product Status": "Active"
    },
    {
        "Root": "10002654",
        "LV": "2",
        "Item Description": "SPICE OREGANO WHOLE LEAF 4/1.35 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002654002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00312-8",
        "Last Price Paid": "$7.34",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002654",
        "Product Status": "Active"
    },
    {
        "Root": "10002654",
        "LV": "2",
        "Item Description": "SPICE OREGANO WHOLE LEAF 4/1.35 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002654002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.34",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002654",
        "Product Status": "Active"
    },
    {
        "Root": "10100759",
        "LV": "1",
        "Item Description": "CABBAGE RED NAPA PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100759001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07PNAPA-1",
        "Last Price Paid": "$6.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100759",
        "Product Status": "Active"
    },
    {
        "Root": "10006370",
        "LV": "1",
        "Item Description": "CONTAINER OCTAVIEW 9 INCH HNG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7113376",
        "Last Price Paid": "$0.73",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006370",
        "Product Status": "Active"
    },
    {
        "Root": "10094308",
        "LV": "1",
        "Item Description": "CHEESE QUESO FRESCO CRUMBLE 6/2# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4466749",
        "Last Price Paid": "$10.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094308",
        "Product Status": "Active"
    },
    {
        "Root": "10037534",
        "LV": "1",
        "Item Description": "FLOUR TAPIOCA BOBS RED MILL TAPIOCA 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037534001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1532B25",
        "Last Price Paid": "$1.99",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037534",
        "Product Status": "Active"
    },
    {
        "Root": "10004531",
        "LV": "1",
        "Item Description": "CHOC TEAR DROP LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004531",
        "Product Status": "Active"
    },
    {
        "Root": "10008304",
        "LV": "1",
        "Item Description": "COOLER SEAGRAM FUZ NAVEL 355ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.94",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008304",
        "Product Status": "Active"
    },
    {
        "Root": "10003862",
        "LV": "1",
        "Item Description": "VINEGAR RICE JUNMAI 20L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "4040",
        "Last Price Paid": "$85.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003862",
        "Product Status": "Active"
    },
    {
        "Root": "10001648",
        "LV": "1",
        "Item Description": "CABBAGE SHRED RED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "35002",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001648",
        "Product Status": "Active"
    },
    {
        "Root": "10001648",
        "LV": "1",
        "Item Description": "CABBAGE SHRED RED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480914",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001648",
        "Product Status": "Active"
    },
    {
        "Root": "10001648",
        "LV": "1",
        "Item Description": "CABBAGE SHRED RED 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "11-002",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001648",
        "Product Status": "Active"
    },
    {
        "Root": "10107169",
        "LV": "1",
        "Item Description": "PORCELET BONELESS LOIN 2.2# AVG SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107169001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "PORPOR016",
        "Last Price Paid": "$19.99",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107169",
        "Product Status": "Active"
    },
    {
        "Root": "10016739",
        "LV": "1",
        "Item Description": "GRUNER VELTLINER BRUNDLMAYER KAMP TERR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "552493",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016739",
        "Product Status": "Active"
    },
    {
        "Root": "10015301",
        "LV": "1",
        "Item Description": "CAKE CIRCLE WHITE 10\" CORRUGATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015301001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51616210",
        "Last Price Paid": "$0.20",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015301",
        "Product Status": "Active"
    },
    {
        "Root": "10006138",
        "LV": "1",
        "Item Description": "SB WDW CUP 16Z PRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "YPP160NSBDSN2",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006138",
        "Product Status": "Active"
    },
    {
        "Root": "10002101",
        "LV": "1",
        "Item Description": "STRAWBERRY DRISCOLL PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "850056",
        "Last Price Paid": "$4.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002101",
        "Product Status": "Active"
    },
    {
        "Root": "10002101",
        "LV": "1",
        "Item Description": "STRAWBERRY DRISCOLL PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "855056",
        "Last Price Paid": "$4.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002101",
        "Product Status": "Active"
    },
    {
        "Root": "10002360",
        "LV": "1",
        "Item Description": "CHEESE BLUE BUTTERMILK BLUE 2/3 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002360001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "33952",
        "Last Price Paid": "$9.11",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002360",
        "Product Status": "Active"
    },
    {
        "Root": "10010078",
        "LV": "1",
        "Item Description": "BEER YUENGLING LAGER BT 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010078001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.03",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010078",
        "Product Status": "Active"
    },
    {
        "Root": "10006655",
        "LV": "2",
        "Item Description": "BOX CAKE FULL SHEET TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006655002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3355583",
        "Last Price Paid": "$1.20",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006655",
        "Product Status": "Active"
    },
    {
        "Root": "10001156",
        "LV": "1",
        "Item Description": "SALMON SUSHI GRADE SKIN ON FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001156001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6380357",
        "Last Price Paid": "$8.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001156",
        "Product Status": "Active"
    },
    {
        "Root": "10001156",
        "LV": "1",
        "Item Description": "SALMON SUSHI GRADE SKIN ON FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001156001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-01453",
        "Last Price Paid": "$8.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001156",
        "Product Status": "Active"
    },
    {
        "Root": "10100487",
        "LV": "1",
        "Item Description": "DIP MILK CHOCOLATE FRUIT & SNACK 100/2OZ SHELF ST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100487001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "815066",
        "Last Price Paid": "$0.50",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100487",
        "Product Status": "Active"
    },
    {
        "Root": "10001217",
        "LV": "1",
        "Item Description": "LAMB STEW 1X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001217001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240505",
        "Last Price Paid": "$8.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001217",
        "Product Status": "Active"
    },
    {
        "Root": "10107496",
        "LV": "1",
        "Item Description": "BEER LHB SAWTOOTH 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "2922",
        "Last Price Paid": "$191.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107496",
        "Product Status": "Active"
    },
    {
        "Root": "10002676",
        "LV": "1",
        "Item Description": "BEET SLICED MED #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8330094",
        "Last Price Paid": "$6.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002676",
        "Product Status": "Active"
    },
    {
        "Root": "10039571",
        "LV": "1",
        "Item Description": "PINOT NOIR DELOACH HERITAGE RESERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9173014",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039571",
        "Product Status": "Active"
    },
    {
        "Root": "10031833",
        "LV": "1",
        "Item Description": "FRESH FRUIT BANANA 4-5 COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "302205",
        "Last Price Paid": "$21.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031833",
        "Product Status": "Active"
    },
    {
        "Root": "10000369",
        "LV": "1",
        "Item Description": "PEPPER RED DCD 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-001-14-A",
        "Last Price Paid": "$17.89",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000369",
        "Product Status": "Active"
    },
    {
        "Root": "10000369",
        "LV": "1",
        "Item Description": "PEPPER RED DCD 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481533",
        "Last Price Paid": "$17.89",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000369",
        "Product Status": "Active"
    },
    {
        "Root": "10035726",
        "LV": "1",
        "Item Description": "SCHNAPPS PEPPERMINT DEKUYPER 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "25222",
        "Last Price Paid": "$9.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035726",
        "Product Status": "Active"
    },
    {
        "Root": "10007803",
        "LV": "2",
        "Item Description": "PINOTAGE CAMBERLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007803001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60043416",
        "Last Price Paid": "$26.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007803",
        "Product Status": "Active"
    },
    {
        "Root": "10002774",
        "LV": "1",
        "Item Description": "SYRUP LEMONADE MM 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$99.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002774",
        "Product Status": "Active"
    },
    {
        "Root": "10006576",
        "LV": "1",
        "Item Description": "SB FLTRS ALTRA 14X6.4 DGT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "100369",
        "Last Price Paid": "$12.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006576",
        "Product Status": "Active"
    },
    {
        "Root": "10003495",
        "LV": "2",
        "Item Description": "TEA IMPRL ENG BRKFST 14.1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003495001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3518",
        "Last Price Paid": "$32.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003495",
        "Product Status": "Active"
    },
    {
        "Root": "10002006",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI BABY 5 POUNDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002006",
        "Product Status": "Active"
    },
    {
        "Root": "10105046",
        "LV": "1",
        "Item Description": "CELLOPHANE NOODLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105046001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35320",
        "Last Price Paid": "$4.35",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105046",
        "Product Status": "Active"
    },
    {
        "Root": "10017629",
        "LV": "2",
        "Item Description": "CARROT BABY ORANGE WITH TOPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017629002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22309",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10017629",
        "Product Status": "Active"
    },
    {
        "Root": "10017629",
        "LV": "2",
        "Item Description": "CARROT BABY ORANGE WITH TOPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017629002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480036",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10017629",
        "Product Status": "Active"
    },
    {
        "Root": "10010059",
        "LV": "1",
        "Item Description": "BEER CAN CIGAR CITY JAI ALAI IPA 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010059",
        "Product Status": "Active"
    },
    {
        "Root": "10010059",
        "LV": "1",
        "Item Description": "BEER CAN CIGAR CITY JAI ALAI IPA 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32533",
        "Last Price Paid": "$1.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010059",
        "Product Status": "Active"
    },
    {
        "Root": "10035188",
        "LV": "1",
        "Item Description": "LOAF MINI CRANBERRY APPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10005820",
        "Last Price Paid": "$5.78",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035188",
        "Product Status": "Active"
    },
    {
        "Root": "10037791",
        "LV": "1",
        "Item Description": "BAG MESH COOKING CLEAR PLASTIC 12X24 1000 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9765975",
        "Last Price Paid": "$0.17",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037791",
        "Product Status": "Active"
    },
    {
        "Root": "10034279",
        "LV": "2",
        "Item Description": "PROSECCO RUFFINO 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034279002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "905956",
        "Last Price Paid": "$2.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034279",
        "Product Status": "Active"
    },
    {
        "Root": "10028880",
        "LV": "1",
        "Item Description": "OLIVE OIL SKYWALKER 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "469371",
        "Last Price Paid": "$28.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028880",
        "Product Status": "Active"
    },
    {
        "Root": "10000365",
        "LV": "1",
        "Item Description": "CABBAGE GRN SHRD W/BAG CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511550",
        "Last Price Paid": "$6.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000365",
        "Product Status": "Active"
    },
    {
        "Root": "10000365",
        "LV": "1",
        "Item Description": "CABBAGE GRN SHRD W/BAG CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "55-019S",
        "Last Price Paid": "$6.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000365",
        "Product Status": "Active"
    },
    {
        "Root": "10101648",
        "LV": "1",
        "Item Description": "COGNAC HARDY NOCES DIAMANT 1/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "70221",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101648",
        "Product Status": "Active"
    },
    {
        "Root": "10004331",
        "LV": "1",
        "Item Description": "SORBET SLIPPER 36EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609904",
        "Vendor": "ICE PROFESSIONALS OF FLORIDA LLC",
        "Vendor Item Nb": "SSOR36",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004331",
        "Product Status": "Active"
    },
    {
        "Root": "10004174",
        "LV": "1",
        "Item Description": "TROPICAL KALLIPPO PUSH POP W/COVER BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004174",
        "Product Status": "Active"
    },
    {
        "Root": "10092211",
        "LV": "2",
        "Item Description": "TEA BAGS STARCRUISER ONLY REP GREEN TEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092211002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4964902",
        "Last Price Paid": "",
        "Case Pack": "1920",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092211",
        "Product Status": "Active"
    },
    {
        "Root": "10033778",
        "LV": "1",
        "Item Description": "BAYTEN MAXIMUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033778001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033778",
        "Product Status": "Active"
    },
    {
        "Root": "10025237",
        "LV": "1",
        "Item Description": "SHIRAZ CEDERBERG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025237001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025237",
        "Product Status": "Active"
    },
    {
        "Root": "10001986",
        "LV": "1",
        "Item Description": "TARRAGON MEXICAN MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MTA-33-E",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001986",
        "Product Status": "Active"
    },
    {
        "Root": "10004613",
        "LV": "2",
        "Item Description": "CHERRY MARASCHINO LARGE G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004613002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004613",
        "Product Status": "Active"
    },
    {
        "Root": "10010723",
        "LV": "1",
        "Item Description": "LIQ WATERMELON DEKYPER LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010723001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "49357",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010723",
        "Product Status": "Active"
    },
    {
        "Root": "10037512",
        "LV": "1",
        "Item Description": "HONDARRABI AMEZTOI STIMATUM TXAKOLINA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037512001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "528034",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037512",
        "Product Status": "Active"
    },
    {
        "Root": "10035572",
        "LV": "1",
        "Item Description": "CHAMPAGNE HENRIOT MILLESIME BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "916153",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035572",
        "Product Status": "Active"
    },
    {
        "Root": "10010187",
        "LV": "2",
        "Item Description": "GINGER ALE SEAGRAM 12Z/24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010187001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010187",
        "Product Status": "Active"
    },
    {
        "Root": "10000887",
        "LV": "1",
        "Item Description": "DUCK THIGH BONELESS SKINL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000887001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000887",
        "Product Status": "Active"
    },
    {
        "Root": "10086440",
        "LV": "1",
        "Item Description": "BREAD FLAT 6.25X11.25IN FZN 48/5.25OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086440001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5178874",
        "Last Price Paid": "$0.69",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086440",
        "Product Status": "Active"
    },
    {
        "Root": "10044748",
        "LV": "1",
        "Item Description": "MARKER WOOD STEAK WELL 3.5IN 5000 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "406560",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044748",
        "Product Status": "Active"
    },
    {
        "Root": "10000358",
        "LV": "1",
        "Item Description": "CARROTS DICED 3/4 INCH 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-008-34",
        "Last Price Paid": "$20.35",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000358",
        "Product Status": "Active"
    },
    {
        "Root": "10000358",
        "LV": "1",
        "Item Description": "CARROTS DICED 3/4 INCH 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512027",
        "Last Price Paid": "$20.35",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000358",
        "Product Status": "Active"
    },
    {
        "Root": "10000102",
        "LV": "1",
        "Item Description": "SAUSAGE FENNEL BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000102001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "171",
        "Last Price Paid": "$3.48",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000102",
        "Product Status": "Active"
    },
    {
        "Root": "10107288",
        "LV": "1",
        "Item Description": "EXTRACT PURE LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "85016",
        "Last Price Paid": "$18.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107288",
        "Product Status": "Active"
    },
    {
        "Root": "10003959",
        "LV": "1",
        "Item Description": "BACTOFERM F-RM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD188",
        "Last Price Paid": "$39.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003959",
        "Product Status": "Active"
    },
    {
        "Root": "10102028",
        "LV": "1",
        "Item Description": "FLOUR ORGANIC RYE WHITE MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "GF259",
        "Last Price Paid": "$58.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102028",
        "Product Status": "Active"
    },
    {
        "Root": "10002984",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002984001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8129468",
        "Last Price Paid": "$8.91",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002984",
        "Product Status": "Active"
    },
    {
        "Root": "10001996",
        "LV": "1",
        "Item Description": "CUCUMBER DICED 3/8 INCH S/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "13-010D-38",
        "Last Price Paid": "$9.25",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001996",
        "Product Status": "Active"
    },
    {
        "Root": "10001996",
        "LV": "1",
        "Item Description": "CUCUMBER DICED 3/8 INCH S/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "513510",
        "Last Price Paid": "$9.25",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001996",
        "Product Status": "Active"
    },
    {
        "Root": "10106088",
        "LV": "1",
        "Item Description": "WINE CHAMP PALMER BRUT RSV NV 6/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "016696",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106088",
        "Product Status": "Active"
    },
    {
        "Root": "10002907",
        "LV": "1",
        "Item Description": "BURGER, VEGETARIAN 3.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.39",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002907",
        "Product Status": "Active"
    },
    {
        "Root": "10001739",
        "LV": "1",
        "Item Description": "BEET CANDYCANE BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001739",
        "Product Status": "Active"
    },
    {
        "Root": "10001739",
        "LV": "1",
        "Item Description": "BEET CANDYCANE BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001739",
        "Product Status": "Active"
    },
    {
        "Root": "10001739",
        "LV": "1",
        "Item Description": "BEET CANDYCANE BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22300",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001739",
        "Product Status": "Active"
    },
    {
        "Root": "10001739",
        "LV": "1",
        "Item Description": "BEET CANDYCANE BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401037",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001739",
        "Product Status": "Active"
    },
    {
        "Root": "10009485",
        "LV": "1",
        "Item Description": "SCOTCH GLENKINCHIE 12YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "181083",
        "Last Price Paid": "$52.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009485",
        "Product Status": "Active"
    },
    {
        "Root": "10001381",
        "LV": "1",
        "Item Description": "MINT MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "513302",
        "Last Price Paid": "$19.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001381",
        "Product Status": "Active"
    },
    {
        "Root": "10001381",
        "LV": "1",
        "Item Description": "MINT MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001381",
        "Product Status": "Active"
    },
    {
        "Root": "10041420",
        "LV": "1",
        "Item Description": "TEQUILA CINCORO BLANCO 750ML 6PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041420001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9382935",
        "Last Price Paid": "$55.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041420",
        "Product Status": "Active"
    },
    {
        "Root": "10101398",
        "LV": "1",
        "Item Description": "CHOCO DARK MICKEY CONFECTIONARY 30 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101398001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C434",
        "Last Price Paid": "$122.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101398",
        "Product Status": "Active"
    },
    {
        "Root": "10010468",
        "LV": "2",
        "Item Description": "CHARDONNAY TOAD HOLLOW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010468001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "543024",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010468",
        "Product Status": "Active"
    },
    {
        "Root": "10016351",
        "LV": "2",
        "Item Description": "KETCHUP IND 9 GR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016351002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016351",
        "Product Status": "Active"
    },
    {
        "Root": "10000137",
        "LV": "1",
        "Item Description": "CORVINA PORTIONED 6 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000137001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01100",
        "Last Price Paid": "$18.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000137",
        "Product Status": "Active"
    },
    {
        "Root": "10000137",
        "LV": "1",
        "Item Description": "CORVINA PORTIONED 6 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000137001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6496508",
        "Last Price Paid": "$18.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000137",
        "Product Status": "Active"
    },
    {
        "Root": "10000137",
        "LV": "1",
        "Item Description": "CORVINA PORTIONED 6 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000137001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "1603",
        "Last Price Paid": "$18.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000137",
        "Product Status": "Active"
    },
    {
        "Root": "10006451",
        "LV": "1",
        "Item Description": "BOARD CAKE SILVER 12 INCH X1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006451",
        "Product Status": "Active"
    },
    {
        "Root": "10028482",
        "LV": "1",
        "Item Description": "BEER KEG KONIG LUDWIG WEISSBEIR 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028482",
        "Product Status": "Active"
    },
    {
        "Root": "10028482",
        "LV": "1",
        "Item Description": "BEER KEG KONIG LUDWIG WEISSBEIR 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14148",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028482",
        "Product Status": "Active"
    },
    {
        "Root": "10001594",
        "LV": "1",
        "Item Description": "POTATO RED C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7074453",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001594",
        "Product Status": "Active"
    },
    {
        "Root": "10001594",
        "LV": "1",
        "Item Description": "POTATO RED C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "29280",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001594",
        "Product Status": "Active"
    },
    {
        "Root": "10001594",
        "LV": "1",
        "Item Description": "POTATO RED C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408240",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001594",
        "Product Status": "Active"
    },
    {
        "Root": "10001594",
        "LV": "1",
        "Item Description": "POTATO RED C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001594",
        "Product Status": "Active"
    },
    {
        "Root": "10004519",
        "LV": "1",
        "Item Description": "PECTIN FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004519001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "0...{PASTRY 1{",
        "Last Price Paid": "$37.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004519",
        "Product Status": "Active"
    },
    {
        "Root": "10009467",
        "LV": "1",
        "Item Description": "SCOTCH GLENFIDDICH 12YR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9011439",
        "Last Price Paid": "$45.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009467",
        "Product Status": "Active"
    },
    {
        "Root": "10001743",
        "LV": "1",
        "Item Description": "PEPPER HABANERO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001743001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001743",
        "Product Status": "Active"
    },
    {
        "Root": "10019051",
        "LV": "1",
        "Item Description": "CHERRY FARMERS MARKET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019051002001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "670005",
        "Last Price Paid": "$64.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "002",
        "NALA": "10019051",
        "Product Status": "Active"
    },
    {
        "Root": "10019276",
        "LV": "1",
        "Item Description": "MIXED CURRANT TOMATOES 1/2 PT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17MCURT-12",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019276",
        "Product Status": "Active"
    },
    {
        "Root": "10004399",
        "LV": "1",
        "Item Description": "CHIP POTATO REG PLN SS BG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5601679",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004399",
        "Product Status": "Active"
    },
    {
        "Root": "10003111",
        "LV": "1",
        "Item Description": "HONEY BUN SMEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003111",
        "Product Status": "Active"
    },
    {
        "Root": "10001761",
        "LV": "1",
        "Item Description": "KOHLRABI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "482033",
        "Last Price Paid": "$3.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001761",
        "Product Status": "Active"
    },
    {
        "Root": "10001761",
        "LV": "1",
        "Item Description": "KOHLRABI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "482029",
        "Last Price Paid": "$3.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001761",
        "Product Status": "Active"
    },
    {
        "Root": "10001761",
        "LV": "1",
        "Item Description": "KOHLRABI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482033",
        "Last Price Paid": "$3.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001761",
        "Product Status": "Active"
    },
    {
        "Root": "10009790",
        "LV": "1",
        "Item Description": "RUM DON Q CRISTAL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "109109",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009790",
        "Product Status": "Active"
    },
    {
        "Root": "10037082",
        "LV": "1",
        "Item Description": "MARQUES DE MURRIETA CASTIL YGAY GRAN RES ESP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037082001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "544223",
        "Last Price Paid": "$164.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037082",
        "Product Status": "Active"
    },
    {
        "Root": "10030852",
        "LV": "1",
        "Item Description": "MUSTARD BABY RED RUFFLED 3# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09BRFM-309U-33",
        "Last Price Paid": "$45.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030852",
        "Product Status": "Active"
    },
    {
        "Root": "10033669",
        "LV": "1",
        "Item Description": "EDIBLE GLITTER-PINK 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "39209",
        "Last Price Paid": "$12.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033669",
        "Product Status": "Active"
    },
    {
        "Root": "10004819",
        "LV": "1",
        "Item Description": "SPICE ACHIOTE POWDER BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "00...{MERMAID{",
        "Last Price Paid": "$189.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004819",
        "Product Status": "Active"
    },
    {
        "Root": "10001476",
        "LV": "1",
        "Item Description": "POTATO BAKER 70CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001476001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480450",
        "Last Price Paid": "$0.44",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001476",
        "Product Status": "Active"
    },
    {
        "Root": "10034185",
        "LV": "2",
        "Item Description": "MORIS FARMS VERMENTINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034185002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9634931",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034185",
        "Product Status": "Active"
    },
    {
        "Root": "10013341",
        "LV": "1",
        "Item Description": "CHEESE SEMI HARD MAHON SPAIN 2/6.6 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013341001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1150",
        "Last Price Paid": "$13.83",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013341",
        "Product Status": "Active"
    },
    {
        "Root": "10015203",
        "LV": "1",
        "Item Description": "SHIRAZ DETORREN LION 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "798530",
        "Last Price Paid": "$252.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015203",
        "Product Status": "Active"
    },
    {
        "Root": "10009572",
        "LV": "2",
        "Item Description": "VODKA ABSOLUT CITRON LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009572001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10950",
        "Last Price Paid": "$19.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009572",
        "Product Status": "Active"
    },
    {
        "Root": "10109555",
        "LV": "1",
        "Item Description": "BEEF RIBEYE 20-24Z B/I E/E CAB PRIME DSY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109555001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190117",
        "Last Price Paid": "$18.46",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109555",
        "Product Status": "Active"
    },
    {
        "Root": "10038460",
        "LV": "1",
        "Item Description": "DRAFT BAD MOTIVATOR IPA 15.5 GAL KEG SWGE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "45225",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038460",
        "Product Status": "Active"
    },
    {
        "Root": "10015953",
        "LV": "1",
        "Item Description": "PIMIENTO RED DICED 12/28OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4333480",
        "Last Price Paid": "$3.27",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015953",
        "Product Status": "Active"
    },
    {
        "Root": "10016938",
        "LV": "1",
        "Item Description": "BOX GOLD FOIL W/TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1589",
        "Last Price Paid": "$1.00",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016938",
        "Product Status": "Active"
    },
    {
        "Root": "10028007",
        "LV": "1",
        "Item Description": "DRAFT FIRST MAGNITUDE WAKULLA WHEAT 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028007",
        "Product Status": "Active"
    },
    {
        "Root": "10039716",
        "LV": "1",
        "Item Description": "RICE WINE SHAOXING 21.7 OZ F&W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY204",
        "Last Price Paid": "$57.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039716",
        "Product Status": "Active"
    },
    {
        "Root": "10010198",
        "LV": "2",
        "Item Description": "DRAFT ORANGE BLOS PIL HBL 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010198001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "15903",
        "Last Price Paid": "$173.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010198",
        "Product Status": "Active"
    },
    {
        "Root": "10006915",
        "LV": "1",
        "Item Description": "MERLOT CAPE INDABA CST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "476008",
        "Last Price Paid": "$7.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006915",
        "Product Status": "Active"
    },
    {
        "Root": "10001607",
        "LV": "1",
        "Item Description": "PARSNIP WHLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510522",
        "Last Price Paid": "$57.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001607",
        "Product Status": "Active"
    },
    {
        "Root": "10001607",
        "LV": "1",
        "Item Description": "PARSNIP WHLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-003",
        "Last Price Paid": "$57.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001607",
        "Product Status": "Active"
    },
    {
        "Root": "10084124",
        "LV": "2",
        "Item Description": "DONUT HOLES GLAZED 35CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084124002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS201",
        "Last Price Paid": "$0.08",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084124",
        "Product Status": "Active"
    },
    {
        "Root": "10006991",
        "LV": "1",
        "Item Description": "CHARD BENZIGER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006991",
        "Product Status": "Active"
    },
    {
        "Root": "10093053",
        "LV": "1",
        "Item Description": "SYRUP MONIN PEACH CONCENTRATE 375ML 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093053001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C036FP",
        "Last Price Paid": "$6.57",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093053",
        "Product Status": "Active"
    },
    {
        "Root": "10008122",
        "LV": "2",
        "Item Description": "SPARK ROEDERER BRT EST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008122002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "483235",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10008122",
        "Product Status": "Active"
    },
    {
        "Root": "10000944",
        "LV": "1",
        "Item Description": "SALMON SMOKED HARBOR POINT SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000944001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6430512",
        "Last Price Paid": "$13.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000944",
        "Product Status": "Active"
    },
    {
        "Root": "10095108",
        "LV": "1",
        "Item Description": "TRAY PAPER FLATBREAD PIZZA 11.5X6.5X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2691845",
        "Last Price Paid": "$0.21",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095108",
        "Product Status": "Active"
    },
    {
        "Root": "10002716",
        "LV": "2",
        "Item Description": "CEREAL FROSTED FLAKES BOWL 96CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002716002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7003080",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002716",
        "Product Status": "Active"
    },
    {
        "Root": "10002716",
        "LV": "2",
        "Item Description": "CEREAL FROSTED FLAKES BOWL 96CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002716002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002716",
        "Product Status": "Active"
    },
    {
        "Root": "10016066",
        "LV": "1",
        "Item Description": "CAKE ANGEL FOOD INDIV BUNDT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "985",
        "Last Price Paid": "$1.30",
        "Case Pack": "42",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016066",
        "Product Status": "Active"
    },
    {
        "Root": "10009929",
        "LV": "1",
        "Item Description": "LIQ CARAVELLA ORNGCELLA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9115783",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009929",
        "Product Status": "Active"
    },
    {
        "Root": "10003960",
        "LV": "1",
        "Item Description": "SALT PINK CURING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD107",
        "Last Price Paid": "$25.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003960",
        "Product Status": "Active"
    },
    {
        "Root": "10037545",
        "LV": "1",
        "Item Description": "TEMPRANILLO LANZAGA TELMO LZ RIOJA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9699529",
        "Last Price Paid": "$17.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037545",
        "Product Status": "Active"
    },
    {
        "Root": "10001573",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "597022",
        "Last Price Paid": "$13.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001573",
        "Product Status": "Active"
    },
    {
        "Root": "10001573",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20230",
        "Last Price Paid": "$13.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001573",
        "Product Status": "Active"
    },
    {
        "Root": "10001344",
        "LV": "1",
        "Item Description": "COULIS RED PEPPER 24Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.38",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001344",
        "Product Status": "Active"
    },
    {
        "Root": "10106729",
        "LV": "1",
        "Item Description": "SIPPER PREM HOL23 XMAS TREE DONALD 16OZ 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.58",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106729",
        "Product Status": "Active"
    },
    {
        "Root": "10015927",
        "LV": "1",
        "Item Description": "EGG HALF HOLLOW 5.75\" DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22235D",
        "Last Price Paid": "$3.00",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015927",
        "Product Status": "Active"
    },
    {
        "Root": "10007413",
        "LV": "1",
        "Item Description": "BLND CAB FRC RAATS RED JASPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "254432",
        "Last Price Paid": "$13.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007413",
        "Product Status": "Active"
    },
    {
        "Root": "10031608",
        "LV": "1",
        "Item Description": "OLLI GENOA SALAMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "77722",
        "Last Price Paid": "$7.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031608",
        "Product Status": "Active"
    },
    {
        "Root": "10005650",
        "LV": "2",
        "Item Description": "SHELL CONE TOMATO 83CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005650001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LRN8090459",
        "Last Price Paid": "$0.52",
        "Case Pack": "83",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005650",
        "Product Status": "Active"
    },
    {
        "Root": "10102654",
        "LV": "1",
        "Item Description": "URCHIN UNI TRAYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102654001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6546001",
        "Last Price Paid": "$23.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102654",
        "Product Status": "Active"
    },
    {
        "Root": "10088343",
        "LV": "1",
        "Item Description": "BOX POPCORN CIRQUE 6.5X2.56X9.67",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510518",
        "Last Price Paid": "$0.41",
        "Case Pack": "275",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088343",
        "Product Status": "Active"
    },
    {
        "Root": "10014462",
        "LV": "1",
        "Item Description": "POWDER MATCHA TEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "83448",
        "Last Price Paid": "$43.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014462",
        "Product Status": "Active"
    },
    {
        "Root": "10003904",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE DONALD W/BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 2121",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003904",
        "Product Status": "Active"
    },
    {
        "Root": "10039663",
        "LV": "1",
        "Item Description": "EGGS DOZEN CARTON CAGE FREE 15/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407785",
        "Last Price Paid": "$3.04",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039663",
        "Product Status": "Active"
    },
    {
        "Root": "10001988",
        "LV": "1",
        "Item Description": "WATERCRESS PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480978",
        "Last Price Paid": "$12.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001988",
        "Product Status": "Active"
    },
    {
        "Root": "10001988",
        "LV": "1",
        "Item Description": "WATERCRESS PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PWA-33",
        "Last Price Paid": "$12.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001988",
        "Product Status": "Active"
    },
    {
        "Root": "10039762",
        "LV": "1",
        "Item Description": "BEEF BURGER ANGUS D-LUXE 7Z  -  BRISKET & CHUCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039762001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1095000",
        "Last Price Paid": "$4.48",
        "Case Pack": "10.5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039762",
        "Product Status": "Active"
    },
    {
        "Root": "10003681",
        "LV": "1",
        "Item Description": "CANDY M&M PEANUT KS 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003681001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534210",
        "Last Price Paid": "$2.59",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003681",
        "Product Status": "Active"
    },
    {
        "Root": "10100308",
        "LV": "2",
        "Item Description": "DISPOSABLE PET 7OZ CLARUS CLEAR CUP 2500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100308002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$125.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100308",
        "Product Status": "Active"
    },
    {
        "Root": "10088167",
        "LV": "1",
        "Item Description": "BEER DRAFT 3 DAUGHTERS EGG NOG 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99865",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088167",
        "Product Status": "Active"
    },
    {
        "Root": "10032478",
        "LV": "1",
        "Item Description": "MILK CHOCOLATE PETA CRISPY 900G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032478001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6720330000",
        "Last Price Paid": "$47.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032478",
        "Product Status": "Active"
    },
    {
        "Root": "10001880",
        "LV": "1",
        "Item Description": "CELERY DICED 3/4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "513999",
        "Last Price Paid": "$10.44",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001880",
        "Product Status": "Active"
    },
    {
        "Root": "10001880",
        "LV": "1",
        "Item Description": "CELERY DICED 3/4 INCH 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-003-34",
        "Last Price Paid": "$10.44",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001880",
        "Product Status": "Active"
    },
    {
        "Root": "10109488",
        "LV": "1",
        "Item Description": "WINE CHATEAU DES CHARMES CHARDONNAY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9699160",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109488",
        "Product Status": "Active"
    },
    {
        "Root": "10034819",
        "LV": "1",
        "Item Description": "SANTA FACE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1423",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034819",
        "Product Status": "Active"
    },
    {
        "Root": "10016524",
        "LV": "1",
        "Item Description": "BREAD TEXAS TOAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016524",
        "Product Status": "Active"
    },
    {
        "Root": "10044397",
        "LV": "1",
        "Item Description": "CIDER CAN 1911 HONEYCRISP 16OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044397001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99806",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044397",
        "Product Status": "Active"
    },
    {
        "Root": "10085633",
        "LV": "1",
        "Item Description": "LIQUID ROSE GOLD 50TH ANNIVERSARY CASTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "045481501",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085633",
        "Product Status": "Active"
    },
    {
        "Root": "10101188",
        "LV": "1",
        "Item Description": "CANDY PEANUT BUTTER M&M 25# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000044078",
        "Vendor": "SPECIALTY PRODUCTS UNLIMITED INC",
        "Vendor Item Nb": "274069",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101188",
        "Product Status": "Active"
    },
    {
        "Root": "10092991",
        "LV": "1",
        "Item Description": "SUGAR SUB SPLENDA SUCRALOSE 12/6.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6620918",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092991",
        "Product Status": "Active"
    },
    {
        "Root": "10086207",
        "LV": "1",
        "Item Description": "CANDY SWEDISH FISH RED LAY DOWN 12/14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3922321",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086207",
        "Product Status": "Active"
    },
    {
        "Root": "10016771",
        "LV": "1",
        "Item Description": "MERLOT POST HOUSE BLACK MAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60040416",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016771",
        "Product Status": "Active"
    },
    {
        "Root": "10010561",
        "LV": "1",
        "Item Description": "AFFENTALER PINOT ROSE MONKY 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069637",
        "Vendor": "IMPORT ANT WINES INCE",
        "Vendor Item Nb": "30592",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010561",
        "Product Status": "Active"
    },
    {
        "Root": "10010561",
        "LV": "1",
        "Item Description": "AFFENTALER PINOT ROSE MONKY 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010561",
        "Product Status": "Active"
    },
    {
        "Root": "10000955",
        "LV": "1",
        "Item Description": "SQUID RING & TENACLE FRSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000955001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "66-50201A",
        "Last Price Paid": "$8.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000955",
        "Product Status": "Active"
    },
    {
        "Root": "10000955",
        "LV": "1",
        "Item Description": "SQUID RING & TENACLE FRSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000955001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "0632822",
        "Last Price Paid": "$8.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000955",
        "Product Status": "Active"
    },
    {
        "Root": "10109546",
        "LV": "2",
        "Item Description": "GINGERBREAD HOUSE SHINGLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109546002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "GBGF",
        "Last Price Paid": "$3.00",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10109546",
        "Product Status": "Active"
    },
    {
        "Root": "10034823",
        "LV": "1",
        "Item Description": "HOLIDAY TREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1890",
        "Last Price Paid": "$0.47",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034823",
        "Product Status": "Active"
    },
    {
        "Root": "10036247",
        "LV": "2",
        "Item Description": "CUP W/RFID WDW PAPER 21 OUNCE/1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036247001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10036247",
        "Product Status": "Active"
    },
    {
        "Root": "10035490",
        "LV": "2",
        "Item Description": "CAVIAR STURGEON CLASSIC WHITE 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035490002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60712000",
        "Last Price Paid": "$25.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035490",
        "Product Status": "Active"
    },
    {
        "Root": "10022057",
        "LV": "1",
        "Item Description": "CUP BAKE IN 1.7 IN R2D2 OVEN SAFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391028",
        "Last Price Paid": "$0.20",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022057",
        "Product Status": "Active"
    },
    {
        "Root": "10106288",
        "LV": "1",
        "Item Description": "BEER KEG MICHELOB ULTRA 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "19202",
        "Last Price Paid": "$110.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106288",
        "Product Status": "Active"
    },
    {
        "Root": "10001978",
        "LV": "1",
        "Item Description": "ROMAINE RED BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09RRF-9",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001978",
        "Product Status": "Active"
    },
    {
        "Root": "10107492",
        "LV": "1",
        "Item Description": "BEER PAR STRAWBERRY MOCHI 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16128",
        "Last Price Paid": "$299.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107492",
        "Product Status": "Active"
    },
    {
        "Root": "10010977",
        "LV": "1",
        "Item Description": "GUACAMOLE WHOLLY 2OZ SS CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2879521",
        "Last Price Paid": "$0.54",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010977",
        "Product Status": "Active"
    },
    {
        "Root": "10041056",
        "LV": "2",
        "Item Description": "CHEESE SOFT RIPENED BRIE 6.6 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041056002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6656",
        "Last Price Paid": "$9.21",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10041056",
        "Product Status": "Active"
    },
    {
        "Root": "10037086",
        "LV": "1",
        "Item Description": "ROSE MARQUES DE CACERES EXCELLENS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037086001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9313374",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037086",
        "Product Status": "Active"
    },
    {
        "Root": "10018808",
        "LV": "1",
        "Item Description": "POWDER DECOR BLUE LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11148",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018808",
        "Product Status": "Active"
    },
    {
        "Root": "10088121",
        "LV": "2",
        "Item Description": "BASS STRIPPED HYBRID WHOLE SCALED FINS OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088121002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3774900",
        "Last Price Paid": "$8.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088121",
        "Product Status": "Active"
    },
    {
        "Root": "10016574",
        "LV": "1",
        "Item Description": "BOX BREAKFAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1750271",
        "Last Price Paid": "$3.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016574",
        "Product Status": "Active"
    },
    {
        "Root": "10076136",
        "LV": "1",
        "Item Description": "JUICE STRAWBERRY CRANBERRY WHITE DRNK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1954353",
        "Last Price Paid": "$3.69",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076136",
        "Product Status": "Active"
    },
    {
        "Root": "10001832",
        "LV": "1",
        "Item Description": "GARLIC JUMBO WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001832001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "GAR30LB",
        "Last Price Paid": "$2.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001832",
        "Product Status": "Active"
    },
    {
        "Root": "10083361",
        "LV": "1",
        "Item Description": "SIPPER WATER PARK MICKEY & MINNIE 24 OZ 30/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.18",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083361",
        "Product Status": "Active"
    },
    {
        "Root": "10000659",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN WAGYU PSMO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000659001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4501892",
        "Last Price Paid": "$44.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000659",
        "Product Status": "Active"
    },
    {
        "Root": "10017888",
        "LV": "2",
        "Item Description": "CHICKEN THIGH SKIN ON BONE IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017888001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.71",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10017888",
        "Product Status": "Active"
    },
    {
        "Root": "10084927",
        "LV": "1",
        "Item Description": "SEASONING ALL PURPOSE SALT FREE ORGANIC 3/19OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3668570",
        "Last Price Paid": "$14.87",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084927",
        "Product Status": "Active"
    },
    {
        "Root": "10110081",
        "LV": "1",
        "Item Description": "SCARPETTA CHARDONNAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9640337",
        "Last Price Paid": "$11.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110081",
        "Product Status": "Active"
    },
    {
        "Root": "10009914",
        "LV": "1",
        "Item Description": "LIQ LIMONCELLO PALLINI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9003692",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009914",
        "Product Status": "Active"
    },
    {
        "Root": "10017921",
        "LV": "1",
        "Item Description": "COCONUT FANCY SHRED 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017921",
        "Product Status": "Active"
    },
    {
        "Root": "10102911",
        "LV": "1",
        "Item Description": "KOSHER BSC HAMBURGER POT/BUN/VEG 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102911",
        "Product Status": "Active"
    },
    {
        "Root": "10003625",
        "LV": "2",
        "Item Description": "SB FRAPPUCCINO VANILLA BEAN POWDER 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003625001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11071916",
        "Last Price Paid": "$4.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003625",
        "Product Status": "Active"
    },
    {
        "Root": "10002293",
        "LV": "1",
        "Item Description": "CHEESE HARD FLAGSHIP RESERVE TRUCKLE BEECHERS 16LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002293001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "230060",
        "Last Price Paid": "$15.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002293",
        "Product Status": "Active"
    },
    {
        "Root": "10044823",
        "LV": "1",
        "Item Description": "CAN TIN WITH LID SILVER 7.4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013445",
        "Last Price Paid": "$0.89",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044823",
        "Product Status": "Active"
    },
    {
        "Root": "10024478",
        "LV": "1",
        "Item Description": "PASTE AJI AMARILLO 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024478001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "340260",
        "Last Price Paid": "$5.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024478",
        "Product Status": "Active"
    },
    {
        "Root": "10000876",
        "LV": "1",
        "Item Description": "DUCK BREAST FRESH LI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000876001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000876",
        "Product Status": "Active"
    },
    {
        "Root": "10087215",
        "LV": "3",
        "Item Description": "CUP WHITE PAPER UNPRINTED 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087215001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.00",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10087215",
        "Product Status": "Active"
    },
    {
        "Root": "10004596",
        "LV": "2",
        "Item Description": "WATERCHESTNUT SLICED #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004596002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5330196",
        "Last Price Paid": "$10.80",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004596",
        "Product Status": "Active"
    },
    {
        "Root": "10004423",
        "LV": "1",
        "Item Description": "CHERRY AMARENA 6/7.05LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "R104",
        "Last Price Paid": "$91.88",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004423",
        "Product Status": "Active"
    },
    {
        "Root": "10004493",
        "LV": "1",
        "Item Description": "PASTE ALMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "GB298",
        "Last Price Paid": "$39.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004493",
        "Product Status": "Active"
    },
    {
        "Root": "10004493",
        "LV": "1",
        "Item Description": "PASTE ALMOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004493",
        "Product Status": "Active"
    },
    {
        "Root": "10018727",
        "LV": "2",
        "Item Description": "CANDY STARBURST FRUIT ORIGINAL 6/50 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018727002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534673",
        "Last Price Paid": "$14.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018727",
        "Product Status": "Active"
    },
    {
        "Root": "10007484",
        "LV": "3",
        "Item Description": "CAB S MARTIN REY SONO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007484002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "270686",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007484",
        "Product Status": "Active"
    },
    {
        "Root": "10000187",
        "LV": "1",
        "Item Description": "LEEKS 12 COUNT 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20490",
        "Last Price Paid": "$2.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000187",
        "Product Status": "Active"
    },
    {
        "Root": "10000187",
        "LV": "1",
        "Item Description": "LEEKS 12 COUNT 20# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406085",
        "Last Price Paid": "$2.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000187",
        "Product Status": "Active"
    },
    {
        "Root": "10091869",
        "LV": "1",
        "Item Description": "RICE, BLEND WILD LONG GRAIN BEN'S ORIGINAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8027081",
        "Last Price Paid": "$9.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091869",
        "Product Status": "Active"
    },
    {
        "Root": "10103951",
        "LV": "1",
        "Item Description": "COCKTAIL RTD LIVEWIRE ROCKET QUEEN 355ML/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9647955",
        "Last Price Paid": "$3.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103951",
        "Product Status": "Active"
    },
    {
        "Root": "10034572",
        "LV": "1",
        "Item Description": "BLUE CORNMEAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "B5DISNEY",
        "Last Price Paid": "$34.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034572",
        "Product Status": "Active"
    },
    {
        "Root": "10040385",
        "LV": "1",
        "Item Description": "LINER TRAY MAP BBQ 8.25X5.25IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006597",
        "Last Price Paid": "$0.11",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040385",
        "Product Status": "Active"
    },
    {
        "Root": "10008079",
        "LV": "1",
        "Item Description": "CHIANTI LUCENTE LUCE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008079001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2248748",
        "Last Price Paid": "$20.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008079",
        "Product Status": "Active"
    },
    {
        "Root": "10001448",
        "LV": "1",
        "Item Description": "JUICE LIME PAST 16/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "652076",
        "Last Price Paid": "$3.26",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001448",
        "Product Status": "Active"
    },
    {
        "Root": "10107591",
        "LV": "2",
        "Item Description": "COOKIES PARTAKE SNICKERDOODLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107591002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "52859-4",
        "Last Price Paid": "$3.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107591",
        "Product Status": "Active"
    },
    {
        "Root": "10096008",
        "LV": "1",
        "Item Description": "WINE NEBBIOLO ALESSANDRIA BAROLO CHINATO 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10096008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "982091",
        "Last Price Paid": "$34.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10096008",
        "Product Status": "Active"
    },
    {
        "Root": "10035509",
        "LV": "1",
        "Item Description": "SB RED VELVET LOAF CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11096882",
        "Last Price Paid": "$1.61",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035509",
        "Product Status": "Active"
    },
    {
        "Root": "10105408",
        "LV": "1",
        "Item Description": "TEMPURA FLOUR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "TFRF23",
        "Last Price Paid": "$65.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105408",
        "Product Status": "Active"
    },
    {
        "Root": "10028467",
        "LV": "1",
        "Item Description": "CAVIAR BELGIAN PLATINUM SQS 30G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "63520010",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028467",
        "Product Status": "Active"
    },
    {
        "Root": "10007029",
        "LV": "1",
        "Item Description": "TINTA TORO NUMANTHIA NUMANTIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "577400",
        "Last Price Paid": "$25.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007029",
        "Product Status": "Active"
    },
    {
        "Root": "10003968",
        "LV": "1",
        "Item Description": "OLIVE STUFFED BLUE CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003968001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-15010",
        "Last Price Paid": "$9.89",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003968",
        "Product Status": "Active"
    },
    {
        "Root": "10108489",
        "LV": "1",
        "Item Description": "FANTA BIB BLACK WHAT THE 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "411987",
        "Last Price Paid": "$57.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108489",
        "Product Status": "Active"
    },
    {
        "Root": "10002818",
        "LV": "1",
        "Item Description": "TEA DARJEELING 300CT IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002818",
        "Product Status": "Active"
    },
    {
        "Root": "10006409",
        "LV": "2",
        "Item Description": "ALUMINUM LID FULL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006409002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7603141",
        "Last Price Paid": "$0.54",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006409",
        "Product Status": "Active"
    },
    {
        "Root": "10025419",
        "LV": "1",
        "Item Description": "ECLAIR STENCIL 6 PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6110200000",
        "Last Price Paid": "$41.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025419",
        "Product Status": "Active"
    },
    {
        "Root": "10004205",
        "LV": "1",
        "Item Description": "MUSTARD SPICY GUILDENS PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004205001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9000076",
        "Last Price Paid": "$0.05",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004205",
        "Product Status": "Active"
    },
    {
        "Root": "10018803",
        "LV": "1",
        "Item Description": "POWDER DECOR GREEN LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11046",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018803",
        "Product Status": "Active"
    },
    {
        "Root": "10005166",
        "LV": "1",
        "Item Description": "CHOC WHT PLUTO/BASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005166001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 5090",
        "Last Price Paid": "$7.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005166",
        "Product Status": "Active"
    },
    {
        "Root": "10014855",
        "LV": "1",
        "Item Description": "MICKEY 3 PC CLOTHING SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW30155",
        "Last Price Paid": "$0.50",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014855",
        "Product Status": "Active"
    },
    {
        "Root": "10014855",
        "LV": "1",
        "Item Description": "MICKEY 3 PC CLOTHING SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014855",
        "Product Status": "Active"
    },
    {
        "Root": "10006381",
        "LV": "1",
        "Item Description": "TRAY CARRY W/4 HOLE 2096",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8920571",
        "Last Price Paid": "$0.34",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006381",
        "Product Status": "Active"
    },
    {
        "Root": "10039801",
        "LV": "1",
        "Item Description": "CHOC CHRISTMAS GIFTS LARGE 144/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1411",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039801",
        "Product Status": "Active"
    },
    {
        "Root": "10101649",
        "LV": "1",
        "Item Description": "COGNAC HARDY NOCES D'OR 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "70165",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101649",
        "Product Status": "Active"
    },
    {
        "Root": "10000051",
        "LV": "1",
        "Item Description": "LAMB LEG BONE IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000051001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1530050",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000051",
        "Product Status": "Active"
    },
    {
        "Root": "10003372",
        "LV": "1",
        "Item Description": "MARINADE MOROCCAN CHERMOU 5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003372",
        "Product Status": "Active"
    },
    {
        "Root": "10016148",
        "LV": "1",
        "Item Description": "APRICOT FRESH 2 LAYER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "487774",
        "Last Price Paid": "$0.67",
        "Case Pack": "84",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016148",
        "Product Status": "Active"
    },
    {
        "Root": "10000657",
        "LV": "1",
        "Item Description": "BEEF STROGANAFF STRIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000657001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "8000019",
        "Last Price Paid": "$6.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000657",
        "Product Status": "Active"
    },
    {
        "Root": "10015371",
        "LV": "1",
        "Item Description": "WASABI MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "MICWAS",
        "Last Price Paid": "$13.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015371",
        "Product Status": "Active"
    },
    {
        "Root": "10107230",
        "LV": "1",
        "Item Description": "CHOCOLATE CHIFFON CAKE (48 OZ SHEETS)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "CHF48",
        "Last Price Paid": "$21.20",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107230",
        "Product Status": "Active"
    },
    {
        "Root": "10014479",
        "LV": "2",
        "Item Description": "POWDER XANTHAM GUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014479002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87429",
        "Last Price Paid": "$51.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014479",
        "Product Status": "Active"
    },
    {
        "Root": "10001795",
        "LV": "1",
        "Item Description": "HERB DILL 1/4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598313",
        "Last Price Paid": "$2.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001795",
        "Product Status": "Active"
    },
    {
        "Root": "10001795",
        "LV": "1",
        "Item Description": "HERB DILL 1/4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001795",
        "Product Status": "Active"
    },
    {
        "Root": "10034463",
        "LV": "2",
        "Item Description": "CABERNET SAUVIGNON CHATEAU SOUVERAIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034463002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9244381",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034463",
        "Product Status": "Active"
    },
    {
        "Root": "10001209",
        "LV": "1",
        "Item Description": "LEAVES KAFFIR LIME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001209001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LEAKLL",
        "Last Price Paid": "$42.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001209",
        "Product Status": "Active"
    },
    {
        "Root": "10001500",
        "LV": "1",
        "Item Description": "TURNIP BABY WHT TRIMMED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$125.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001500",
        "Product Status": "Active"
    },
    {
        "Root": "10104148",
        "LV": "1",
        "Item Description": "CHICKEN BREAST BONELESS SKINLESS 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104148001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.97",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104148",
        "Product Status": "Active"
    },
    {
        "Root": "10011121",
        "LV": "1",
        "Item Description": "DRAFT BLD CTY DUKE 1/6 BROWN ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011121001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "81306",
        "Last Price Paid": "$74.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011121",
        "Product Status": "Active"
    },
    {
        "Root": "10004026",
        "LV": "1",
        "Item Description": "CO2 TNK 50# HIGH PRSSURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004026",
        "Product Status": "Active"
    },
    {
        "Root": "10004207",
        "LV": "1",
        "Item Description": "CHEESE STICK MOZZ BATTERE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4332680",
        "Last Price Paid": "$21.89",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004207",
        "Product Status": "Active"
    },
    {
        "Root": "10040268",
        "LV": "1",
        "Item Description": "DESSERT DONNAFUGATA PASSITO 2016 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9005953",
        "Last Price Paid": "$32.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040268",
        "Product Status": "Active"
    },
    {
        "Root": "10004549",
        "LV": "1",
        "Item Description": "MUSTARD DRY ENGLISH 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1544220",
        "Last Price Paid": "$8.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004549",
        "Product Status": "Active"
    },
    {
        "Root": "10044742",
        "LV": "1",
        "Item Description": "MARKER WOOD STEAK RARE 3.5IN 5000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "406556",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044742",
        "Product Status": "Active"
    },
    {
        "Root": "10003046",
        "LV": "1",
        "Item Description": "FILLING APPLE PIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003046001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6331003",
        "Last Price Paid": "$54.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003046",
        "Product Status": "Active"
    },
    {
        "Root": "10034220",
        "LV": "1",
        "Item Description": "CUP CLEAR PET 12OZ ACR12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034220001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034220",
        "Product Status": "Active"
    },
    {
        "Root": "10004919",
        "LV": "1",
        "Item Description": "SPICE PAPRIKA SMOKED 35OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164076",
        "Last Price Paid": "$22.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004919",
        "Product Status": "Active"
    },
    {
        "Root": "10000329",
        "LV": "1",
        "Item Description": "FRUIT APPLE W/CARAMEL 4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "811063",
        "Last Price Paid": "$1.43",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000329",
        "Product Status": "Active"
    },
    {
        "Root": "10015541",
        "LV": "2",
        "Item Description": "BLONDIE BITES GF VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015541002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "003",
        "Last Price Paid": "$1.43",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015541",
        "Product Status": "Active"
    },
    {
        "Root": "10009751",
        "LV": "1",
        "Item Description": "TEQUILA DON JULIO ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "973347",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009751",
        "Product Status": "Active"
    },
    {
        "Root": "10025657",
        "LV": "1",
        "Item Description": "BOURBON WATHENS SINGLE BARREL 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025657001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "638709",
        "Last Price Paid": "$46.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025657",
        "Product Status": "Active"
    },
    {
        "Root": "10085279",
        "LV": "2",
        "Item Description": "KNIFE CPLA HW 24/40 SMARTSTOCK TT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085279001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$63.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10085279",
        "Product Status": "Active"
    },
    {
        "Root": "10002855",
        "LV": "1",
        "Item Description": "SALSA THICK & CHUNKY MILD ALL NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1104389",
        "Last Price Paid": "$14.74",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002855",
        "Product Status": "Active"
    },
    {
        "Root": "10002712",
        "LV": "1",
        "Item Description": "MIX CAKE CARROT BAKERS 50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$83.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002712",
        "Product Status": "Active"
    },
    {
        "Root": "10004258",
        "LV": "1",
        "Item Description": "SB LB BANANA PCN WLNT LF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11039076",
        "Last Price Paid": "$0.96",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004258",
        "Product Status": "Active"
    },
    {
        "Root": "10006439",
        "LV": "1",
        "Item Description": "FILTER BUNN (20115) REG 12 CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006439001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.01",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006439",
        "Product Status": "Active"
    },
    {
        "Root": "10013682",
        "LV": "1",
        "Item Description": "DEC ROSE LEAF GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.85",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013682",
        "Product Status": "Active"
    },
    {
        "Root": "10017912",
        "LV": "1",
        "Item Description": "PETITE BASIL SAMPLER PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PBS-33",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017912",
        "Product Status": "Active"
    },
    {
        "Root": "10030272",
        "LV": "1",
        "Item Description": "CANDY HEATH BAR GROUND 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "53060-10E",
        "Last Price Paid": "$69.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030272",
        "Product Status": "Active"
    },
    {
        "Root": "10108405",
        "LV": "2",
        "Item Description": "BLACK SUB ROLL 6\" 3.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108405002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "2308",
        "Last Price Paid": "$0.91",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10108405",
        "Product Status": "Active"
    },
    {
        "Root": "10036070",
        "LV": "1",
        "Item Description": "FRITTATA PEPPADEW ASPARAGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036070001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "2417",
        "Last Price Paid": "$2.10",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036070",
        "Product Status": "Active"
    },
    {
        "Root": "10030623",
        "LV": "1",
        "Item Description": "GELATIN MIX BLUE BERRY 12/24 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2227734",
        "Last Price Paid": "$3.55",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030623",
        "Product Status": "Active"
    },
    {
        "Root": "10002210",
        "LV": "1",
        "Item Description": "MILK FAT FREE HALF PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002210",
        "Product Status": "Active"
    },
    {
        "Root": "10004269",
        "LV": "1",
        "Item Description": "STRAWB FREEZE DRIED DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "812907-011078",
        "Last Price Paid": "$47.28",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004269",
        "Product Status": "Active"
    },
    {
        "Root": "10001744",
        "LV": "1",
        "Item Description": "BEET GOLD BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001744001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22302",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001744",
        "Product Status": "Active"
    },
    {
        "Root": "10001744",
        "LV": "1",
        "Item Description": "BEET GOLD BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001744001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480022",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001744",
        "Product Status": "Active"
    },
    {
        "Root": "10001744",
        "LV": "1",
        "Item Description": "BEET GOLD BABY W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001744001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001744",
        "Product Status": "Active"
    },
    {
        "Root": "10001933",
        "LV": "1",
        "Item Description": "BEET GOLDEN PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001933001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512047",
        "Last Price Paid": "$58.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001933",
        "Product Status": "Active"
    },
    {
        "Root": "10001933",
        "LV": "1",
        "Item Description": "BEET GOLDEN PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001933001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "36-004G",
        "Last Price Paid": "$58.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001933",
        "Product Status": "Active"
    },
    {
        "Root": "10012692",
        "LV": "1",
        "Item Description": "SORBET MANGO LIME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012692001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-MGLM-08-CS",
        "Last Price Paid": "$9.48",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012692",
        "Product Status": "Active"
    },
    {
        "Root": "10038083",
        "LV": "3",
        "Item Description": "BEEF HOT DOG 6-1 2/5LB 6INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038083002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.51",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10038083",
        "Product Status": "Active"
    },
    {
        "Root": "10040129",
        "LV": "2",
        "Item Description": "BEEF PRIME RIB COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040129002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3100038",
        "Last Price Paid": "$11.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040129",
        "Product Status": "Active"
    },
    {
        "Root": "10036048",
        "LV": "1",
        "Item Description": "GIN GINRAW GASTRONOMIC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9329376",
        "Last Price Paid": "$25.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036048",
        "Product Status": "Active"
    },
    {
        "Root": "10006122",
        "LV": "4",
        "Item Description": "CUP 5Z PLAS CHAMP FLUTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006122004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10006122",
        "Product Status": "Active"
    },
    {
        "Root": "10017313",
        "LV": "1",
        "Item Description": "CHEESE CURD WHITE IQF 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$75.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017313",
        "Product Status": "Active"
    },
    {
        "Root": "10005608",
        "LV": "1",
        "Item Description": "PRETZEL CREAM CHEESE STUFFED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.99",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005608",
        "Product Status": "Active"
    },
    {
        "Root": "10019691",
        "LV": "1",
        "Item Description": "SAUCE PERI PERI 12/34 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019691",
        "Product Status": "Active"
    },
    {
        "Root": "10100371",
        "LV": "1",
        "Item Description": "CONTAINER SQUARED TAMPER EVIDENT HINGED  8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091157",
        "Last Price Paid": "$0.34",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100371",
        "Product Status": "Active"
    },
    {
        "Root": "10016357",
        "LV": "1",
        "Item Description": "POWDER HIBISCUS SOSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672077",
        "Last Price Paid": "$45.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016357",
        "Product Status": "Active"
    },
    {
        "Root": "10041067",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR 2 YEAR AGED WHITE BLACK DIAMOND CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041067001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "7001",
        "Last Price Paid": "$9.94",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041067",
        "Product Status": "Active"
    },
    {
        "Root": "10004813",
        "LV": "1",
        "Item Description": "CHIP TORTILLA ROUND 3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1280494",
        "Last Price Paid": "$0.61",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004813",
        "Product Status": "Active"
    },
    {
        "Root": "10003655",
        "LV": "1",
        "Item Description": "GAS NITRGEN 25 CO-2 GUINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "14934",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003655",
        "Product Status": "Active"
    },
    {
        "Root": "10104604",
        "LV": "1",
        "Item Description": "PASSOVER BSC MATZO CRACKERS 45CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.02",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104604",
        "Product Status": "Active"
    },
    {
        "Root": "10031832",
        "LV": "1",
        "Item Description": "FRESH FRUIT BANANA 2-4 COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "302203",
        "Last Price Paid": "$21.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031832",
        "Product Status": "Active"
    },
    {
        "Root": "10010517",
        "LV": "1",
        "Item Description": "CASTLO BNFI ANGLO PIN GRGIO 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010517001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "124018",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010517",
        "Product Status": "Active"
    },
    {
        "Root": "10004888",
        "LV": "1",
        "Item Description": "PEPPADEW CANNED 2/105",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3698214",
        "Last Price Paid": "$22.28",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004888",
        "Product Status": "Active"
    },
    {
        "Root": "10004888",
        "LV": "1",
        "Item Description": "PEPPADEW CANNED 2/105",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "65517",
        "Last Price Paid": "$22.28",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004888",
        "Product Status": "Active"
    },
    {
        "Root": "10002007",
        "LV": "2",
        "Item Description": "POTATO MARBLE TRI-COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002007002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "29190",
        "Last Price Paid": "$94.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002007",
        "Product Status": "Active"
    },
    {
        "Root": "10002007",
        "LV": "2",
        "Item Description": "POTATO MARBLE TRI-COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002007002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408225",
        "Last Price Paid": "$94.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002007",
        "Product Status": "Active"
    },
    {
        "Root": "10105633",
        "LV": "1",
        "Item Description": "BF ALLERGEN FREE BREAD CRUMBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "RM2060",
        "Last Price Paid": "$78.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105633",
        "Product Status": "Active"
    },
    {
        "Root": "10024515",
        "LV": "1",
        "Item Description": "PREMIUM CANDY COLOR RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024515001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21310",
        "Last Price Paid": "$5.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024515",
        "Product Status": "Active"
    },
    {
        "Root": "10010441",
        "LV": "2",
        "Item Description": "SCHONAUER APPLE SCHNAPPS 1LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010441001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "10873",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010441",
        "Product Status": "Active"
    },
    {
        "Root": "10010441",
        "LV": "2",
        "Item Description": "SCHONAUER APPLE SCHNAPPS 1LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010441001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10873",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010441",
        "Product Status": "Active"
    },
    {
        "Root": "10010057",
        "LV": "1",
        "Item Description": "BEER CORONA LIGHT CAN 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13241",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010057",
        "Product Status": "Active"
    },
    {
        "Root": "10004209",
        "LV": "1",
        "Item Description": "SHELL CANNOLI MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "G1015",
        "Last Price Paid": "$0.17",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004209",
        "Product Status": "Active"
    },
    {
        "Root": "10008211",
        "LV": "1",
        "Item Description": "CAB SAUV SLVR OAK NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "223755",
        "Last Price Paid": "$102.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008211",
        "Product Status": "Active"
    },
    {
        "Root": "10094809",
        "LV": "1",
        "Item Description": "TEA TWININGS COLD INFUSE STRAW LMN 22CT/4CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F16318",
        "Last Price Paid": "$5.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094809",
        "Product Status": "Active"
    },
    {
        "Root": "10010915",
        "LV": "2",
        "Item Description": "DRAFT FLORIDA LAGER 1/6BL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010915001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "31706",
        "Last Price Paid": "$81.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010915",
        "Product Status": "Active"
    },
    {
        "Root": "10110794",
        "LV": "1",
        "Item Description": "BOX 1 PIECE \"AMORETTE'S\" MACARON 2.125X2.125X6.375",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51000018",
        "Last Price Paid": "$0.49",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110794",
        "Product Status": "Active"
    },
    {
        "Root": "10032603",
        "LV": "1",
        "Item Description": "MEATLESS BEYOND SAUSAGE SWEET ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103317",
        "Last Price Paid": "$1.81",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032603",
        "Product Status": "Active"
    },
    {
        "Root": "10042190",
        "LV": "1",
        "Item Description": "CONTAINER PAPER FOOD 8OZ COMPOSTABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042190",
        "Product Status": "Active"
    },
    {
        "Root": "10042190",
        "LV": "1",
        "Item Description": "CONTAINER PAPER FOOD 8OZ COMPOSTABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51657017",
        "Last Price Paid": "$0.16",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042190",
        "Product Status": "Active"
    },
    {
        "Root": "10034857",
        "LV": "2",
        "Item Description": "SANGIOVESE RUFFINO CHIANTI 1.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034857002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "90336",
        "Last Price Paid": "$11.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034857",
        "Product Status": "Active"
    },
    {
        "Root": "10025175",
        "LV": "1",
        "Item Description": "MIX DRESSING RANCH GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7812993",
        "Last Price Paid": "$2.47",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025175",
        "Product Status": "Active"
    },
    {
        "Root": "10029797",
        "LV": "1",
        "Item Description": "PALM SUGAR S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480284",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029797",
        "Product Status": "Active"
    },
    {
        "Root": "10007801",
        "LV": "1",
        "Item Description": "SHIRAZ STARK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2769446",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007801",
        "Product Status": "Active"
    },
    {
        "Root": "10013662",
        "LV": "1",
        "Item Description": "LEMONADE MM 2 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013662",
        "Product Status": "Active"
    },
    {
        "Root": "10015210",
        "LV": "1",
        "Item Description": "SKEWER ANTIPASTO W/MEAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015210",
        "Product Status": "Active"
    },
    {
        "Root": "10015541",
        "LV": "1",
        "Item Description": "BLONDIE BITES GF VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015541",
        "Product Status": "Active"
    },
    {
        "Root": "10022597",
        "LV": "2",
        "Item Description": "PEPSI SIERRA MIST TWIST 24/12 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022597002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8540956",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10022597",
        "Product Status": "Active"
    },
    {
        "Root": "10006273",
        "LV": "1",
        "Item Description": "CUP 16Z PLAS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2639200",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006273",
        "Product Status": "Active"
    },
    {
        "Root": "10037977",
        "LV": "1",
        "Item Description": "FORK COMPOSTABLE BLACK 6.5 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "3833",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037977",
        "Product Status": "Active"
    },
    {
        "Root": "10035747",
        "LV": "2",
        "Item Description": "GUMPASTE PETITE GARDEN ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035747001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "7689",
        "Last Price Paid": "$1.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10035747",
        "Product Status": "Active"
    },
    {
        "Root": "10090549",
        "LV": "1",
        "Item Description": "VODKA SKYY INFUSED CHERRY 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992502",
        "Last Price Paid": "$12.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090549",
        "Product Status": "Active"
    },
    {
        "Root": "10033286",
        "LV": "3",
        "Item Description": "PUREE REAL PINEAPPLE 16.9 OZ/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033286003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9247655",
        "Last Price Paid": "$4.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10033286",
        "Product Status": "Active"
    },
    {
        "Root": "10106750",
        "LV": "1",
        "Item Description": "CANDIED ORANGE SLICES 20/1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106750001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10726",
        "Last Price Paid": "$2.97",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106750",
        "Product Status": "Active"
    },
    {
        "Root": "10003198",
        "LV": "2",
        "Item Description": "SPICE CLOVE GROUND 6/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003198002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003198",
        "Product Status": "Active"
    },
    {
        "Root": "10009463",
        "LV": "1",
        "Item Description": "SCOTCH J WALKER GOLD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "362713",
        "Last Price Paid": "$75.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009463",
        "Product Status": "Active"
    },
    {
        "Root": "10095999",
        "LV": "1",
        "Item Description": "PASTRY APPLE TURNOVER MINI 1.4OZ RTB 255CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "37656",
        "Last Price Paid": "$0.28",
        "Case Pack": "255",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095999",
        "Product Status": "Active"
    },
    {
        "Root": "10014381",
        "LV": "1",
        "Item Description": "SHRIMP 16/20 P&D T/ON SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014381001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125505",
        "Last Price Paid": "$5.49",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014381",
        "Product Status": "Active"
    },
    {
        "Root": "10088615",
        "LV": "1",
        "Item Description": "MIX NEAPOLITAN 25/1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "102050",
        "Last Price Paid": "$7.25",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088615",
        "Product Status": "Active"
    },
    {
        "Root": "10014557",
        "LV": "1",
        "Item Description": "COFFEE LIQUID DECF 2CT HGAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D031L-84J",
        "Last Price Paid": "$61.23",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014557",
        "Product Status": "Active"
    },
    {
        "Root": "10014748",
        "LV": "1",
        "Item Description": "BEANS SEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401653",
        "Last Price Paid": "$49.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014748",
        "Product Status": "Active"
    },
    {
        "Root": "10038156",
        "LV": "1",
        "Item Description": "SAUCE PERI PERI 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038156",
        "Product Status": "Active"
    },
    {
        "Root": "10010645",
        "LV": "1",
        "Item Description": "GRAIN ALCOHOL EVERCLEAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "840068",
        "Last Price Paid": "$15.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010645",
        "Product Status": "Active"
    },
    {
        "Root": "10009581",
        "LV": "2",
        "Item Description": "TEQ CUERVO SLVR 80 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009581002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "18161",
        "Last Price Paid": "$10.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009581",
        "Product Status": "Active"
    },
    {
        "Root": "10001467",
        "LV": "1",
        "Item Description": "EGGPLANT CHINESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20451",
        "Last Price Paid": "$57.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001467",
        "Product Status": "Active"
    },
    {
        "Root": "10001467",
        "LV": "1",
        "Item Description": "EGGPLANT CHINESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490141",
        "Last Price Paid": "$57.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001467",
        "Product Status": "Active"
    },
    {
        "Root": "10000431",
        "LV": "1",
        "Item Description": "BREAD ROLL LEVAIN SOURDOUGH SQUARE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.22",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000431",
        "Product Status": "Active"
    },
    {
        "Root": "10000160",
        "LV": "1",
        "Item Description": "TUNA AHI SPECIAL TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000160001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6505265",
        "Last Price Paid": "$19.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000160",
        "Product Status": "Active"
    },
    {
        "Root": "10000160",
        "LV": "1",
        "Item Description": "TUNA AHI SPECIAL TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000160001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000445754",
        "Vendor": "OCEAN BEAUTY SEAFOODS LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000160",
        "Product Status": "Active"
    },
    {
        "Root": "10000160",
        "LV": "1",
        "Item Description": "TUNA AHI SPECIAL TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000160001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-01210",
        "Last Price Paid": "$19.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000160",
        "Product Status": "Active"
    },
    {
        "Root": "10000160",
        "LV": "1",
        "Item Description": "TUNA AHI SPECIAL TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000160001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000160",
        "Product Status": "Active"
    },
    {
        "Root": "10012100",
        "LV": "1",
        "Item Description": "CARROT BABY YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012100001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01BYCT-2",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012100",
        "Product Status": "Active"
    },
    {
        "Root": "10004926",
        "LV": "1",
        "Item Description": "SAUCE PESTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070952",
        "Last Price Paid": "$30.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004926",
        "Product Status": "Active"
    },
    {
        "Root": "10095949",
        "LV": "1",
        "Item Description": "EXTRACT, SWEET CORN WS 2 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "577",
        "Last Price Paid": "$226.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095949",
        "Product Status": "Active"
    },
    {
        "Root": "10003986",
        "LV": "1",
        "Item Description": "VINEGAR BALSAMIC 8YR FOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VI036",
        "Last Price Paid": "$24.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003986",
        "Product Status": "Active"
    },
    {
        "Root": "10031495",
        "LV": "1",
        "Item Description": "BEER KEG GOLDEN ROAD MANGO CART 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$192.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031495",
        "Product Status": "Active"
    },
    {
        "Root": "10004734",
        "LV": "2",
        "Item Description": "CANDY GUMMY BEARS 12/3.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004734002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585434",
        "Last Price Paid": "$1.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004734",
        "Product Status": "Active"
    },
    {
        "Root": "10031970",
        "LV": "1",
        "Item Description": "JUICE LEMONADE SIMPLY 6/52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031970001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9714432",
        "Last Price Paid": "$2.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031970",
        "Product Status": "Active"
    },
    {
        "Root": "10031824",
        "LV": "1",
        "Item Description": "WET PROOF CRISPY RASPBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672099",
        "Last Price Paid": "$43.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031824",
        "Product Status": "Active"
    },
    {
        "Root": "10022731",
        "LV": "1",
        "Item Description": "DECOR SUGAR BONES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "43342",
        "Last Price Paid": "$0.16",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022731",
        "Product Status": "Active"
    },
    {
        "Root": "10024457",
        "LV": "1",
        "Item Description": "SALMON WHOLE ORA KING FR SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024457001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024457",
        "Product Status": "Active"
    },
    {
        "Root": "10024457",
        "LV": "1",
        "Item Description": "SALMON WHOLE ORA KING FR SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024457001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "2119604",
        "Last Price Paid": "$13.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024457",
        "Product Status": "Active"
    },
    {
        "Root": "10005816",
        "LV": "1",
        "Item Description": "PASTRY DANISH CHEESE MINI SWIRL 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "208528-A",
        "Last Price Paid": "$0.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005816",
        "Product Status": "Active"
    },
    {
        "Root": "10040955",
        "LV": "2",
        "Item Description": "PUREE MANGO 6/1KG ANDROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040955002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "708147",
        "Last Price Paid": "$10.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040955",
        "Product Status": "Active"
    },
    {
        "Root": "10008124",
        "LV": "1",
        "Item Description": "CAB SAUV DE TOREN FUSION 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "933233",
        "Last Price Paid": "$41.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008124",
        "Product Status": "Active"
    },
    {
        "Root": "10083721",
        "LV": "1",
        "Item Description": "PINEAPPLE RINGS 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083721001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "919",
        "Last Price Paid": "$49.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083721",
        "Product Status": "Active"
    },
    {
        "Root": "10009947",
        "LV": "1",
        "Item Description": "LIQ CREME DE CASSIS BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9114142",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009947",
        "Product Status": "Active"
    },
    {
        "Root": "10092936",
        "LV": "1",
        "Item Description": "POPCHIPS .8OZ BBQ CHIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "400599",
        "Last Price Paid": "$0.77",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092936",
        "Product Status": "Active"
    },
    {
        "Root": "10002353",
        "LV": "1",
        "Item Description": "TOFUTTI CHOC PINTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1207",
        "Last Price Paid": "$3.16",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002353",
        "Product Status": "Active"
    },
    {
        "Root": "10002353",
        "LV": "1",
        "Item Description": "TOFUTTI CHOC PINTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.16",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002353",
        "Product Status": "Active"
    },
    {
        "Root": "10004612",
        "LV": "1",
        "Item Description": "VINEGAR CHAMPAGNE 16.7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5348362",
        "Last Price Paid": "$3.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004612",
        "Product Status": "Active"
    },
    {
        "Root": "10103932",
        "LV": "1",
        "Item Description": "IMPOSSIBLE MEATBALLS 2 X 80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3000000006",
        "Last Price Paid": "$1.13",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103932",
        "Product Status": "Active"
    },
    {
        "Root": "10006916",
        "LV": "1",
        "Item Description": "CHARD CAPE INDABA CST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006916",
        "Product Status": "Active"
    },
    {
        "Root": "10039887",
        "LV": "1",
        "Item Description": "SB CHEESE DANISH SUGAR PLUM 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039887001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11091676",
        "Last Price Paid": "$1.56",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039887",
        "Product Status": "Active"
    },
    {
        "Root": "10001341",
        "LV": "1",
        "Item Description": "HUMMUS ROASTED RED PEPPER 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$172.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001341",
        "Product Status": "Active"
    },
    {
        "Root": "10022081",
        "LV": "1",
        "Item Description": "LIQ RUDY TONIC 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "437247",
        "Last Price Paid": "$11.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022081",
        "Product Status": "Active"
    },
    {
        "Root": "10040683",
        "LV": "1",
        "Item Description": "CANDY POP ROCKS STRAWBERRY 20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000546229",
        "Vendor": "G&J HOLDINGS LLC DBA CANDY.COM",
        "Vendor Item Nb": "209-D0231",
        "Last Price Paid": "$14.80",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040683",
        "Product Status": "Active"
    },
    {
        "Root": "10004729",
        "LV": "2",
        "Item Description": "SAUCE CHILI GARLIC GOCHUJANG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004729002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7824511",
        "Last Price Paid": "$18.44",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004729",
        "Product Status": "Active"
    },
    {
        "Root": "10008855",
        "LV": "2",
        "Item Description": "BLND RED BELLING SMV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008855001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "238982",
        "Last Price Paid": "$22.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008855",
        "Product Status": "Active"
    },
    {
        "Root": "10001909",
        "LV": "1",
        "Item Description": "BERRY GOOSE CAPE W/HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.29",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001909",
        "Product Status": "Active"
    },
    {
        "Root": "10001909",
        "LV": "1",
        "Item Description": "BERRY GOOSE CAPE W/HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "USE 04990171**",
        "Last Price Paid": "$2.29",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001909",
        "Product Status": "Active"
    },
    {
        "Root": "10001909",
        "LV": "1",
        "Item Description": "BERRY GOOSE CAPE W/HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250025",
        "Last Price Paid": "$2.29",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001909",
        "Product Status": "Active"
    },
    {
        "Root": "10103950",
        "LV": "1",
        "Item Description": "COCKTAIL RTD SIPONEY ROYAL 355ML/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9630214",
        "Last Price Paid": "$3.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103950",
        "Product Status": "Active"
    },
    {
        "Root": "10003405",
        "LV": "2",
        "Item Description": "SPICE ARROWROOT 4/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003405002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.91",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003405",
        "Product Status": "Active"
    },
    {
        "Root": "10041159",
        "LV": "1",
        "Item Description": "DRAFT SIERRA NEVADA HAZY LITTLE 15.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38546",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041159",
        "Product Status": "Active"
    },
    {
        "Root": "10036402",
        "LV": "1",
        "Item Description": "WHOLE BEAN MEXICO CAFE FEMENINO ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036402001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "C234B-30B",
        "Last Price Paid": "$18.94",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036402",
        "Product Status": "Active"
    },
    {
        "Root": "10026815",
        "LV": "1",
        "Item Description": "MEAT GYRO AEGEAN THICK SLICED 4/5 LB PRIDE GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2795714",
        "Last Price Paid": "$19.90",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026815",
        "Product Status": "Active"
    },
    {
        "Root": "10010029",
        "LV": "1",
        "Item Description": "DRAFT BUD LIGHT 1/2BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$91.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010029",
        "Product Status": "Active"
    },
    {
        "Root": "10011165",
        "LV": "1",
        "Item Description": "JUICE BEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011165001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480372",
        "Last Price Paid": "$33.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011165",
        "Product Status": "Active"
    },
    {
        "Root": "10025975",
        "LV": "1",
        "Item Description": "STRAWBERRY GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482223",
        "Last Price Paid": "$3.59",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025975",
        "Product Status": "Active"
    },
    {
        "Root": "10108214",
        "LV": "1",
        "Item Description": "SODA BTL FEVER TREE TONIC SPARKLING CUCUMBER 200ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "597784",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108214",
        "Product Status": "Active"
    },
    {
        "Root": "10017628",
        "LV": "1",
        "Item Description": "BOARD SILVER FULL SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ESFS",
        "Last Price Paid": "$4.92",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017628",
        "Product Status": "Active"
    },
    {
        "Root": "10110080",
        "LV": "1",
        "Item Description": "SCARPETTA CAB FRANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9642026",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110080",
        "Product Status": "Active"
    },
    {
        "Root": "10002404",
        "LV": "1",
        "Item Description": "CHEESE BLUE ORIGINAL POINT REYES 6.5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002404001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "16048",
        "Last Price Paid": "$12.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002404",
        "Product Status": "Active"
    },
    {
        "Root": "10013967",
        "LV": "1",
        "Item Description": "GRUNER HUBER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "350013",
        "Last Price Paid": "$10.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013967",
        "Product Status": "Active"
    },
    {
        "Root": "10013967",
        "LV": "1",
        "Item Description": "GRUNER HUBER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "350013",
        "Last Price Paid": "$10.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013967",
        "Product Status": "Active"
    },
    {
        "Root": "10003989",
        "LV": "1",
        "Item Description": "HOMINY GRITS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482541",
        "Last Price Paid": "$77.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003989",
        "Product Status": "Active"
    },
    {
        "Root": "10010160",
        "LV": "1",
        "Item Description": "BEER GINGER FEVER TREE 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.34",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010160",
        "Product Status": "Active"
    },
    {
        "Root": "10009669",
        "LV": "2",
        "Item Description": "VODKA SVEDKA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009669001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "526261",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009669",
        "Product Status": "Active"
    },
    {
        "Root": "10030023",
        "LV": "1",
        "Item Description": "SNACK TRAY BLACK 7.8X5.7X.78 900608D300",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030023001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "303-122",
        "Last Price Paid": "$0.31",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030023",
        "Product Status": "Active"
    },
    {
        "Root": "10003470",
        "LV": "1",
        "Item Description": "OIL WHITE COCONUT 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003470001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2350",
        "Last Price Paid": "$79.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003470",
        "Product Status": "Active"
    },
    {
        "Root": "10029413",
        "LV": "1",
        "Item Description": "WIDE WICK CHAFING FUEL SCREW CAP DH600 6HOUR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51839204",
        "Last Price Paid": "$2.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029413",
        "Product Status": "Active"
    },
    {
        "Root": "10001142",
        "LV": "1",
        "Item Description": "HIYASHI WAKAME 4.4 LB 4 TO CA SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "18110",
        "Last Price Paid": "$27.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001142",
        "Product Status": "Active"
    },
    {
        "Root": "10010052",
        "LV": "2",
        "Item Description": "BEER CAN SAM ADAMS SEASONAL 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010052001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21470",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010052",
        "Product Status": "Active"
    },
    {
        "Root": "10032889",
        "LV": "1",
        "Item Description": "NOVELTY ICE CUBES NEON LED ASSORTED 4 PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "DIS735UN",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032889",
        "Product Status": "Active"
    },
    {
        "Root": "10033888",
        "LV": "1",
        "Item Description": "CINDERELLAS COACH 16 17161E  BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51700071",
        "Last Price Paid": "",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033888",
        "Product Status": "Active"
    },
    {
        "Root": "10110611",
        "LV": "1",
        "Item Description": "BEER IVANHOE PARK FROSTED GINGERBREAD STOUT 15.5GA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76745",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110611",
        "Product Status": "Active"
    },
    {
        "Root": "10033486",
        "LV": "1",
        "Item Description": "PORK RACK 10RB FRCH LNGBN FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033486001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1440025",
        "Last Price Paid": "",
        "Case Pack": "100.5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033486",
        "Product Status": "Active"
    },
    {
        "Root": "10013467",
        "LV": "1",
        "Item Description": "TORTA CINNAMON LEMON FLATBREAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "4848",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013467",
        "Product Status": "Active"
    },
    {
        "Root": "10031871",
        "LV": "1",
        "Item Description": "GELATO SWEET CORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-CORN-18",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031871",
        "Product Status": "Active"
    },
    {
        "Root": "10108664",
        "LV": "1",
        "Item Description": "PARTAKE CHOCOLATE CHIP MINIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "28795",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108664",
        "Product Status": "Active"
    },
    {
        "Root": "10039633",
        "LV": "1",
        "Item Description": "SHEET TRANSFER WHIMSICAL TREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "901020C",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039633",
        "Product Status": "Active"
    },
    {
        "Root": "10041274",
        "LV": "1",
        "Item Description": "SB KIWI STARFRUIT INCLUSION 75G 12 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011113153",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041274",
        "Product Status": "Active"
    },
    {
        "Root": "10040314",
        "LV": "1",
        "Item Description": "ROSE BODVAR PROVENCE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040314",
        "Product Status": "Active"
    },
    {
        "Root": "10004661",
        "LV": "2",
        "Item Description": "HAZELNUTS BLANCHED 55 LB CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004661002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "180555",
        "Last Price Paid": "$392.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004661",
        "Product Status": "Active"
    },
    {
        "Root": "10028874",
        "LV": "1",
        "Item Description": "PEA ENGLISH SHUCKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "PEAENGSH",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028874",
        "Product Status": "Active"
    },
    {
        "Root": "10024347",
        "LV": "1",
        "Item Description": "QUIN HARVEST THANKSGIVING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5811",
        "Last Price Paid": "$3.97",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024347",
        "Product Status": "Active"
    },
    {
        "Root": "10004364",
        "LV": "1",
        "Item Description": "SPRAY VELVET GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV009G",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004364",
        "Product Status": "Active"
    },
    {
        "Root": "10002948",
        "LV": "1",
        "Item Description": "COFFEE FRCH RST DCF 7OZ G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002948",
        "Product Status": "Active"
    },
    {
        "Root": "10039885",
        "LV": "1",
        "Item Description": "SB SYRUP IRISH CREAM 1L 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11109592",
        "Last Price Paid": "$19.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039885",
        "Product Status": "Active"
    },
    {
        "Root": "10034401",
        "LV": "1",
        "Item Description": "CHIP BAGEL EVERYTHING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034401001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "92357",
        "Last Price Paid": "$2.94",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034401",
        "Product Status": "Active"
    },
    {
        "Root": "10006967",
        "LV": "1",
        "Item Description": "CHARD SONO CUTRER RR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1033141",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006967",
        "Product Status": "Active"
    },
    {
        "Root": "10000340",
        "LV": "2",
        "Item Description": "LETTUCE ICEBERG CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000340002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000340",
        "Product Status": "Active"
    },
    {
        "Root": "10020069",
        "LV": "1",
        "Item Description": "COOKIE XMAS NUSS PRINTIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020069001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LB205",
        "Last Price Paid": "$99.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020069",
        "Product Status": "Active"
    },
    {
        "Root": "10004576",
        "LV": "1",
        "Item Description": "HONEY 12Z BEAR SQUEEZE BT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3737699",
        "Last Price Paid": "$4.64",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004576",
        "Product Status": "Active"
    },
    {
        "Root": "10038218",
        "LV": "1",
        "Item Description": "DECOR CHOC STITCH EARS W/TAB WHITE E A SET 100/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63628",
        "Last Price Paid": "$0.90",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038218",
        "Product Status": "Active"
    },
    {
        "Root": "10003122",
        "LV": "1",
        "Item Description": "QUIN SHAMROCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9393",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003122",
        "Product Status": "Active"
    },
    {
        "Root": "10031547",
        "LV": "1",
        "Item Description": "SYRUP LEMONADE FLAVOR BURST 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "61-45524",
        "Last Price Paid": "$17.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031547",
        "Product Status": "Active"
    },
    {
        "Root": "10005881",
        "LV": "1",
        "Item Description": "DONUTS VANILLA GLAZED GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005881001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "2209",
        "Last Price Paid": "$4.15",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005881",
        "Product Status": "Active"
    },
    {
        "Root": "10102588",
        "LV": "2",
        "Item Description": "GELATO SUGAR PLUM SOFT SERVE 6 PACK HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102588002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "P-SPLM-11-CS6",
        "Last Price Paid": "$11.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102588",
        "Product Status": "Active"
    },
    {
        "Root": "10010145",
        "LV": "2",
        "Item Description": "WATER STILL TAU 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010145001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.78",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010145",
        "Product Status": "Active"
    },
    {
        "Root": "10005047",
        "LV": "1",
        "Item Description": "SYRUP ELDERFLOWER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005047",
        "Product Status": "Active"
    },
    {
        "Root": "10027972",
        "LV": "1",
        "Item Description": "COCOA BUTTER COLOR AQUA BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302085C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027972",
        "Product Status": "Active"
    },
    {
        "Root": "10005251",
        "LV": "1",
        "Item Description": "TRUFFLE PEELED 7Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090520",
        "Last Price Paid": "$24.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005251",
        "Product Status": "Active"
    },
    {
        "Root": "10104847",
        "LV": "2",
        "Item Description": "CUP COLD 22OZ MARY BLAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104847002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104847",
        "Product Status": "Active"
    },
    {
        "Root": "10004698",
        "LV": "1",
        "Item Description": "QUINOA 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "548404",
        "Last Price Paid": "$88.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004698",
        "Product Status": "Active"
    },
    {
        "Root": "10004698",
        "LV": "1",
        "Item Description": "QUINOA 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "401126",
        "Last Price Paid": "$88.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004698",
        "Product Status": "Active"
    },
    {
        "Root": "10004698",
        "LV": "1",
        "Item Description": "QUINOA 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401126",
        "Last Price Paid": "$88.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004698",
        "Product Status": "Active"
    },
    {
        "Root": "10035842",
        "LV": "1",
        "Item Description": "SYRUP RICH DEMERARA BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BG106",
        "Last Price Paid": "$16.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035842",
        "Product Status": "Active"
    },
    {
        "Root": "10032045",
        "LV": "1",
        "Item Description": "61710036 GREEN STRIPED 8.5 PAPER STRAW COLOSSAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11835013",
        "Last Price Paid": "$0.11",
        "Case Pack": "1480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032045",
        "Product Status": "Active"
    },
    {
        "Root": "10004494",
        "LV": "1",
        "Item Description": "CHOC PARALLEL BIRTHDAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "DW402 BIRTHDAY",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004494",
        "Product Status": "Active"
    },
    {
        "Root": "10037236",
        "LV": "1",
        "Item Description": "MILLES FEUILLES EXTRA LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8067053",
        "Last Price Paid": "$1.27",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037236",
        "Product Status": "Active"
    },
    {
        "Root": "10038210",
        "LV": "1",
        "Item Description": "SORBET BLACKBERRY CASE 4 HALF GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-BLAC-08-CS",
        "Last Price Paid": "$9.83",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038210",
        "Product Status": "Active"
    },
    {
        "Root": "10044478",
        "LV": "1",
        "Item Description": "PALM HEARTS CAN 28OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044478001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "PAL000",
        "Last Price Paid": "$4.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044478",
        "Product Status": "Active"
    },
    {
        "Root": "10045396",
        "LV": "1",
        "Item Description": "STRAWBERRY SLICED TOPPING 6/6.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1419365",
        "Last Price Paid": "$10.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045396",
        "Product Status": "Active"
    },
    {
        "Root": "10001566",
        "LV": "1",
        "Item Description": "SQUASH ACORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "409000",
        "Last Price Paid": "$30.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001566",
        "Product Status": "Active"
    },
    {
        "Root": "10091953",
        "LV": "2",
        "Item Description": "SAUCE, BLACK BEAN LEE KUM KEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091953002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7920168",
        "Last Price Paid": "$3.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10091953",
        "Product Status": "Active"
    },
    {
        "Root": "10034449",
        "LV": "2",
        "Item Description": "GLERA AMOR DI AMANTI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034449002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "413601",
        "Last Price Paid": "$1.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034449",
        "Product Status": "Active"
    },
    {
        "Root": "10021773",
        "LV": "1",
        "Item Description": "CUP PORTION 2 OZ BLK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11600308",
        "Last Price Paid": "$0.01",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021773",
        "Product Status": "Active"
    },
    {
        "Root": "10045066",
        "LV": "1",
        "Item Description": "MUSSEL LIVE PEI CANADIAN COVE 1/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045066001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3379976",
        "Last Price Paid": "$2.95",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045066",
        "Product Status": "Active"
    },
    {
        "Root": "10003007",
        "LV": "1",
        "Item Description": "DRESSING CEASAR WDW 4/1GA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.03",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003007",
        "Product Status": "Active"
    },
    {
        "Root": "10008229",
        "LV": "2",
        "Item Description": "CAB SAUV CAYMUS SPC SEL 1.5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008229001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "928021",
        "Last Price Paid": "$330.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008229",
        "Product Status": "Active"
    },
    {
        "Root": "10000337",
        "LV": "1",
        "Item Description": "ONION RED WHOLE/PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-001-20",
        "Last Price Paid": "$16.20",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000337",
        "Product Status": "Active"
    },
    {
        "Root": "10000337",
        "LV": "1",
        "Item Description": "ONION RED WHOLE/PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514050",
        "Last Price Paid": "$16.20",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000337",
        "Product Status": "Active"
    },
    {
        "Root": "10037419",
        "LV": "1",
        "Item Description": "CHEESE SHREDS DAIRY FREE PARMESAN 8 OZ BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "6004",
        "Last Price Paid": "$3.20",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037419",
        "Product Status": "Active"
    },
    {
        "Root": "10006383",
        "LV": "2",
        "Item Description": "WYPALL* L30 WIPERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006383002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006383",
        "Product Status": "Active"
    },
    {
        "Root": "10000318",
        "LV": "1",
        "Item Description": "CARROT STICK 3/8X4 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-007-14",
        "Last Price Paid": "$9.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000318",
        "Product Status": "Active"
    },
    {
        "Root": "10000318",
        "LV": "1",
        "Item Description": "CARROT STICK 3/8X4 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512048",
        "Last Price Paid": "$9.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000318",
        "Product Status": "Active"
    },
    {
        "Root": "10001337",
        "LV": "1",
        "Item Description": "CHUTNEY MANGO 12/34 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069745",
        "Vendor": "GREEK ISLAND SPICE INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001337",
        "Product Status": "Active"
    },
    {
        "Root": "10001337",
        "LV": "1",
        "Item Description": "CHUTNEY MANGO 12/34 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001337",
        "Product Status": "Active"
    },
    {
        "Root": "10017690",
        "LV": "1",
        "Item Description": "DRAFT LAGUNITAS IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13085",
        "Last Price Paid": "$171.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017690",
        "Product Status": "Active"
    },
    {
        "Root": "10040843",
        "LV": "1",
        "Item Description": "CUP 12Z PLASTIC ROCKS GLASS F&W LOGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11012257",
        "Last Price Paid": "$0.76",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040843",
        "Product Status": "Active"
    },
    {
        "Root": "10003492",
        "LV": "2",
        "Item Description": "TEA OSMNTHS OOLONG 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003492001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3411",
        "Last Price Paid": "$58.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003492",
        "Product Status": "Active"
    },
    {
        "Root": "10036773",
        "LV": "4",
        "Item Description": "HAM SMOKED SLICED 6/2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036773003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2971551",
        "Last Price Paid": "$7.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10036773",
        "Product Status": "Active"
    },
    {
        "Root": "10006151",
        "LV": "2",
        "Item Description": "FORK MED WT SMARTSTOCK BL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006151002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006151",
        "Product Status": "Active"
    },
    {
        "Root": "10044824",
        "LV": "2",
        "Item Description": "SPICE CAYENNE PEPPER GROUND 16OZ PLAST SHAKER BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044824002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760611",
        "Last Price Paid": "$11.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10044824",
        "Product Status": "Active"
    },
    {
        "Root": "10014685",
        "LV": "1",
        "Item Description": "HORS SAMOSA POTATO & PEA 160 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014685001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043443",
        "Last Price Paid": "$0.32",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014685",
        "Product Status": "Active"
    },
    {
        "Root": "10022788",
        "LV": "1",
        "Item Description": "GOURD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482062",
        "Last Price Paid": "$0.75",
        "Case Pack": "42",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022788",
        "Product Status": "Active"
    },
    {
        "Root": "10000673",
        "LV": "1",
        "Item Description": "SAUSAGE ANDOUILLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000673001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "202B",
        "Last Price Paid": "$4.58",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000673",
        "Product Status": "Active"
    },
    {
        "Root": "10025159",
        "LV": "1",
        "Item Description": "GREENS AFILLA LIVING LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480689",
        "Last Price Paid": "$18.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025159",
        "Product Status": "Active"
    },
    {
        "Root": "10105137",
        "LV": "1",
        "Item Description": "POPCORN MINT GREEN MDSE BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105137001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "MGREEN PB16",
        "Last Price Paid": "$82.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105137",
        "Product Status": "Active"
    },
    {
        "Root": "10040594",
        "LV": "2",
        "Item Description": "CHEESE CRUMBLE BLUE GORGONZOLA 2/2.5 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040594002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "32443",
        "Last Price Paid": "$4.60",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040594",
        "Product Status": "Active"
    },
    {
        "Root": "10025149",
        "LV": "1",
        "Item Description": "MICRO CITRUS MIX CAHABA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22161",
        "Last Price Paid": "$18.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025149",
        "Product Status": "Active"
    },
    {
        "Root": "10025149",
        "LV": "1",
        "Item Description": "MICRO CITRUS MIX CAHABA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480687",
        "Last Price Paid": "$18.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025149",
        "Product Status": "Active"
    },
    {
        "Root": "10101208",
        "LV": "1",
        "Item Description": "DOUGH COOKIE CHOCOLATE BROWNIE FUDGE 3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3240160",
        "Last Price Paid": "$0.55",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101208",
        "Product Status": "Active"
    },
    {
        "Root": "10036027",
        "LV": "1",
        "Item Description": "GIN THE BOTANIST ISLAY DRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294816",
        "Last Price Paid": "$33.08",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036027",
        "Product Status": "Active"
    },
    {
        "Root": "10006360",
        "LV": "1",
        "Item Description": "BAG PLASTIC WHITE W/HANDL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2245298",
        "Last Price Paid": "$0.13",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006360",
        "Product Status": "Active"
    },
    {
        "Root": "10003840",
        "LV": "1",
        "Item Description": "BAR LUNA NUTZ OVER CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "210002",
        "Last Price Paid": "$1.20",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003840",
        "Product Status": "Active"
    },
    {
        "Root": "10003720",
        "LV": "1",
        "Item Description": "COMPOUND LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003720001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DREIDOPPEL",
        "Last Price Paid": "$32.80",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003720",
        "Product Status": "Active"
    },
    {
        "Root": "10039741",
        "LV": "1",
        "Item Description": "LID CUP HOT PLASTIC SMALL WHITE SLEEV 10/100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039741001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039741",
        "Product Status": "Active"
    },
    {
        "Root": "10030249",
        "LV": "1",
        "Item Description": "GRAIN FARRO SEMI PEARL 12/17.6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4302568",
        "Last Price Paid": "$3.38",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030249",
        "Product Status": "Active"
    },
    {
        "Root": "10029756",
        "LV": "2",
        "Item Description": "LIQUEUR MOZART CHOCOLATE PUMPKIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029756002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "17710",
        "Last Price Paid": "$21.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029756",
        "Product Status": "Active"
    },
    {
        "Root": "10008178",
        "LV": "1",
        "Item Description": "MERLOT STGS LEAP WINER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008178001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9003836",
        "Last Price Paid": "$24.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008178",
        "Product Status": "Active"
    },
    {
        "Root": "10002398",
        "LV": "2",
        "Item Description": "CHEESE BLUE STILTON ENGLAND 5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002398002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1110",
        "Last Price Paid": "$13.36",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002398",
        "Product Status": "Active"
    },
    {
        "Root": "10032047",
        "LV": "1",
        "Item Description": "CLUB 33 WDW REGULAR BEAN 1 RETAIL JOFFREY'S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "P942B-21J",
        "Last Price Paid": "$15.03",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032047",
        "Product Status": "Active"
    },
    {
        "Root": "10003770",
        "LV": "2",
        "Item Description": "PAILLETE FEUILLETINE 4/6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003770002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ118724",
        "Last Price Paid": "$45.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003770",
        "Product Status": "Active"
    },
    {
        "Root": "10086534",
        "LV": "1",
        "Item Description": "DEC MILK CHOC CHEWBACCA BELT 4IN X 3/4IN W/WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW17328",
        "Last Price Paid": "$0.90",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086534",
        "Product Status": "Active"
    },
    {
        "Root": "10003648",
        "LV": "1",
        "Item Description": "COOKIE LADY FINGERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "012...{BONOMI{",
        "Last Price Paid": "$0.08",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003648",
        "Product Status": "Active"
    },
    {
        "Root": "10007762",
        "LV": "1",
        "Item Description": "ZIN FRO MEEKER DESERT 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "290318",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007762",
        "Product Status": "Active"
    },
    {
        "Root": "10006861",
        "LV": "2",
        "Item Description": "CHAMP NIC FEUILLATTE BRT 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006861002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006861",
        "Product Status": "Active"
    },
    {
        "Root": "10012344",
        "LV": "1",
        "Item Description": "ARCTIC CHAR FILLET SKIN OFF, FARMED SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012344001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7209987",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012344",
        "Product Status": "Active"
    },
    {
        "Root": "10012344",
        "LV": "1",
        "Item Description": "ARCTIC CHAR FILLET SKIN OFF, FARMED SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012344001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00006",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012344",
        "Product Status": "Active"
    },
    {
        "Root": "10012344",
        "LV": "1",
        "Item Description": "ARCTIC CHAR FILLET SKIN OFF, FARMED SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012344001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "ART30100",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012344",
        "Product Status": "Active"
    },
    {
        "Root": "10022266",
        "LV": "1",
        "Item Description": "POWDER KOREAN CHILI F&W TEMP SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY905",
        "Last Price Paid": "$19.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022266",
        "Product Status": "Active"
    },
    {
        "Root": "10107412",
        "LV": "1",
        "Item Description": "LITTLE MERMAID SILHOUETTE WHT CHOC 2 1/8\"X1 1/2\" A",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW12061",
        "Last Price Paid": "$1.30",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107412",
        "Product Status": "Active"
    },
    {
        "Root": "10001592",
        "LV": "1",
        "Item Description": "ARTICHOKE LONG STEM MARINATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "100030",
        "Last Price Paid": "$24.98",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001592",
        "Product Status": "Active"
    },
    {
        "Root": "10008824",
        "LV": "3",
        "Item Description": "BORDEAUX CH GISCOURS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008824002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300985",
        "Last Price Paid": "$79.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008824",
        "Product Status": "Active"
    },
    {
        "Root": "10000184",
        "LV": "1",
        "Item Description": "CUCUMBER EURO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000184001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "195031",
        "Last Price Paid": "$1.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000184",
        "Product Status": "Active"
    },
    {
        "Root": "10107632",
        "LV": "1",
        "Item Description": "CRACKER, GRAHAM CINNAMON GOLDFISH WGRN 300/.9OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4692964",
        "Last Price Paid": "$0.18",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107632",
        "Product Status": "Active"
    },
    {
        "Root": "10004753",
        "LV": "1",
        "Item Description": "OLIVE MARTINI SANTA BARBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004753",
        "Product Status": "Active"
    },
    {
        "Root": "10002489",
        "LV": "1",
        "Item Description": "POTATO SWEET CENTER CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8113088",
        "Last Price Paid": "$9.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002489",
        "Product Status": "Active"
    },
    {
        "Root": "10018569",
        "LV": "1",
        "Item Description": "GEL PURPLE NEON BRIGHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22513",
        "Last Price Paid": "$6.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018569",
        "Product Status": "Active"
    },
    {
        "Root": "10001775",
        "LV": "1",
        "Item Description": "RUTABAGAS 50#CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401338",
        "Last Price Paid": "$0.90",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001775",
        "Product Status": "Active"
    },
    {
        "Root": "10001775",
        "LV": "1",
        "Item Description": "RUTABAGAS 50#CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001775",
        "Product Status": "Active"
    },
    {
        "Root": "10037091",
        "LV": "1",
        "Item Description": "GARBANZO BEANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037091001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BEAGALB",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037091",
        "Product Status": "Active"
    },
    {
        "Root": "10070908",
        "LV": "1",
        "Item Description": "EGG JUST EGG SCRAMBLED 6/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10070908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "229120",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10070908",
        "Product Status": "Active"
    },
    {
        "Root": "10006905",
        "LV": "1",
        "Item Description": "ZINF ROSNBLM VNTR CV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "919106",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006905",
        "Product Status": "Active"
    },
    {
        "Root": "10091111",
        "LV": "1",
        "Item Description": "WINE DESSERT CHENIN BLANC MULLINEUX STRAW WINE 375",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091111001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "906246",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091111",
        "Product Status": "Active"
    },
    {
        "Root": "10087818",
        "LV": "1",
        "Item Description": "CUP PORTION 4OZ SUGARCANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11657065",
        "Last Price Paid": "",
        "Case Pack": "1800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087818",
        "Product Status": "Active"
    },
    {
        "Root": "10004866",
        "LV": "1",
        "Item Description": "SALT SEA COARSE 55# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "258889",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004866",
        "Product Status": "Active"
    },
    {
        "Root": "10003438",
        "LV": "1",
        "Item Description": "SYRUP SHAKE STRAWBERRY FB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003438001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003438",
        "Product Status": "Active"
    },
    {
        "Root": "10100636",
        "LV": "1",
        "Item Description": "WINE RIESLING SPATLESE MERKELBACH URZIGER WURZGART",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "951343",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100636",
        "Product Status": "Active"
    },
    {
        "Root": "10009082",
        "LV": "3",
        "Item Description": "SAUV BLC FAIR VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009082002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009082",
        "Product Status": "Active"
    },
    {
        "Root": "10080755",
        "LV": "1",
        "Item Description": "CUBE LIGHT SILVER MM JINGLE LED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10080755",
        "Product Status": "Active"
    },
    {
        "Root": "10008484",
        "LV": "2",
        "Item Description": "RED BLND DOM SERENE ROSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008484001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9127889",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008484",
        "Product Status": "Active"
    },
    {
        "Root": "10039893",
        "LV": "2",
        "Item Description": "COD BLACK SKINLESS PORTION 7OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039893002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6361106",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039893",
        "Product Status": "Active"
    },
    {
        "Root": "10036268",
        "LV": "1",
        "Item Description": "QUINS CONFETTI SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24047",
        "Last Price Paid": "$6.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036268",
        "Product Status": "Active"
    },
    {
        "Root": "10009585",
        "LV": "1",
        "Item Description": "TEQUILA PATRON SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009585",
        "Product Status": "Active"
    },
    {
        "Root": "10006374",
        "LV": "1",
        "Item Description": "FILM PVC 18 INCH X 3000",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006374",
        "Product Status": "Active"
    },
    {
        "Root": "10108333",
        "LV": "1",
        "Item Description": "CHEESE SWISS SHREDED 4/5#CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "30916",
        "Last Price Paid": "$50.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108333",
        "Product Status": "Active"
    },
    {
        "Root": "10027077",
        "LV": "1",
        "Item Description": "CANDY GUMMI BEARS 12 FLAVOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027077001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000439038",
        "Vendor": "HAYDEN VALLEY FOODS INC",
        "Vendor Item Nb": "58565-20",
        "Last Price Paid": "$38.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027077",
        "Product Status": "Active"
    },
    {
        "Root": "10010553",
        "LV": "1",
        "Item Description": "BOUCHARD PINOT NOIR VDP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2271542",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010553",
        "Product Status": "Active"
    },
    {
        "Root": "10003962",
        "LV": "1",
        "Item Description": "VINEGAR MINUS 8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-00190",
        "Last Price Paid": "$41.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003962",
        "Product Status": "Active"
    },
    {
        "Root": "10003962",
        "LV": "1",
        "Item Description": "VINEGAR MINUS 8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD0291",
        "Last Price Paid": "$41.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003962",
        "Product Status": "Active"
    },
    {
        "Root": "10024534",
        "LV": "3",
        "Item Description": "ESCARGOT XL FRENCH 72 CT PER TIN SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024534002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-86190",
        "Last Price Paid": "$23.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10024534",
        "Product Status": "Active"
    },
    {
        "Root": "10024534",
        "LV": "3",
        "Item Description": "ESCARGOT XL FRENCH 72 CT PER TIN SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024534002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "ESC000",
        "Last Price Paid": "$23.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10024534",
        "Product Status": "Active"
    },
    {
        "Root": "10008347",
        "LV": "1",
        "Item Description": "MUSCAT BOTANI OLD VINE MOSCATEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1131.17",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008347",
        "Product Status": "Active"
    },
    {
        "Root": "10033226",
        "LV": "1",
        "Item Description": "COLOR GEL PASTE MINT GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033226001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC MINT GREEN",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033226",
        "Product Status": "Active"
    },
    {
        "Root": "10002786",
        "LV": "1",
        "Item Description": "JOFFREYS FRCH RST GRND 2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "128",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002786",
        "Product Status": "Active"
    },
    {
        "Root": "10019067",
        "LV": "1",
        "Item Description": "CHIP POTATO KETTLE SEA SALT MISS VICKI 64/1.375OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5601968",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019067",
        "Product Status": "Active"
    },
    {
        "Root": "10002742",
        "LV": "1",
        "Item Description": "SYRUP MAPLE FLVRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.91",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002742",
        "Product Status": "Active"
    },
    {
        "Root": "10003288",
        "LV": "1",
        "Item Description": "COFFEE LE CELLIER BLND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "P840B-48L",
        "Last Price Paid": "$39.16",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003288",
        "Product Status": "Active"
    },
    {
        "Root": "10034108",
        "LV": "1",
        "Item Description": "MANGO COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011114",
        "Last Price Paid": "$85.07",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034108",
        "Product Status": "Active"
    },
    {
        "Root": "10034765",
        "LV": "1",
        "Item Description": "GLAZE MIRROR GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "646006",
        "Last Price Paid": "$53.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034765",
        "Product Status": "Active"
    },
    {
        "Root": "10005233",
        "LV": "2",
        "Item Description": "SB TOPPING CHSTNUT PRALIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005233001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11053545",
        "Last Price Paid": "$6.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005233",
        "Product Status": "Active"
    },
    {
        "Root": "10010328",
        "LV": "2",
        "Item Description": "CIDER HARD BTL MAGNERS PEAR 11.2OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010328001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.56",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010328",
        "Product Status": "Active"
    },
    {
        "Root": "10030670",
        "LV": "3",
        "Item Description": "TEA JOFFREYS EARL GREY LOOSE LEAF 1LB/2CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030670001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "T5200-16T",
        "Last Price Paid": "$20.54",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10030670",
        "Product Status": "Active"
    },
    {
        "Root": "10037001",
        "LV": "1",
        "Item Description": "BUN HAMBURGER GF BLOOMFIELD FARMS STANDARD 3.5 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "14442",
        "Last Price Paid": "$1.00",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037001",
        "Product Status": "Active"
    },
    {
        "Root": "10001409",
        "LV": "1",
        "Item Description": "HERB SENSATION PETITE PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PHS-33",
        "Last Price Paid": "$40.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001409",
        "Product Status": "Active"
    },
    {
        "Root": "10004461",
        "LV": "1",
        "Item Description": "MIX FREESTYLE ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004461001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3555855",
        "Last Price Paid": "$16.65",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004461",
        "Product Status": "Active"
    },
    {
        "Root": "10001529",
        "LV": "1",
        "Item Description": "LEAF OYSTER 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "080YL-33",
        "Last Price Paid": "$0.79",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001529",
        "Product Status": "Active"
    },
    {
        "Root": "10004937",
        "LV": "1",
        "Item Description": "MIX 6 GRAIN CHOICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BEL \\& SCHAFER",
        "Last Price Paid": "$69.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004937",
        "Product Status": "Active"
    },
    {
        "Root": "10001908",
        "LV": "1",
        "Item Description": "ORANGE SEVILLE SOUR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "302051",
        "Last Price Paid": "$23.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001908",
        "Product Status": "Active"
    },
    {
        "Root": "10034850",
        "LV": "2",
        "Item Description": "SPRINKLES PINK 26OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034850002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "20701",
        "Last Price Paid": "$4.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034850",
        "Product Status": "Active"
    },
    {
        "Root": "10088251",
        "LV": "2",
        "Item Description": "GARNISH DEHYDRATED PINEAPPLE WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088251002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "PC-1",
        "Last Price Paid": "$0.25",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088251",
        "Product Status": "Active"
    },
    {
        "Root": "10095890",
        "LV": "1",
        "Item Description": "BUTTER FRENCH UNSALTED ECHIRE 20/8.8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "250031",
        "Last Price Paid": "$7.35",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095890",
        "Product Status": "Active"
    },
    {
        "Root": "10105090",
        "LV": "1",
        "Item Description": "CARTON FOOD #3 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105090001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105090",
        "Product Status": "Active"
    },
    {
        "Root": "10036226",
        "LV": "1",
        "Item Description": "COCOA BUTTER MEDITERRANEAN BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036226001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302090C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036226",
        "Product Status": "Active"
    },
    {
        "Root": "10022555",
        "LV": "1",
        "Item Description": "SWISS CHARD RED 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482186",
        "Last Price Paid": "$0.89",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022555",
        "Product Status": "Active"
    },
    {
        "Root": "10000929",
        "LV": "1",
        "Item Description": "SALAD SEAWEED HIJIKI SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "77-70204",
        "Last Price Paid": "$45.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000929",
        "Product Status": "Active"
    },
    {
        "Root": "10009833",
        "LV": "1",
        "Item Description": "COGNAC HENNESSY VS 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "363420",
        "Last Price Paid": "$18.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009833",
        "Product Status": "Active"
    },
    {
        "Root": "10007222",
        "LV": "1",
        "Item Description": "CHIANTI QUERCETO CLS 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "548215",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007222",
        "Product Status": "Active"
    },
    {
        "Root": "10036311",
        "LV": "1",
        "Item Description": "SAUERKRAUT SHREDDED 2 GALLON PAIL REFRIGERATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2698801",
        "Last Price Paid": "$22.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036311",
        "Product Status": "Active"
    },
    {
        "Root": "10004787",
        "LV": "1",
        "Item Description": "BEAN BLACK TURTLE 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4332326",
        "Last Price Paid": "$26.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004787",
        "Product Status": "Active"
    },
    {
        "Root": "10002129",
        "LV": "1",
        "Item Description": "CHEESE AMERICAN YELLOW SLICED .5OZ 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.07",
        "Case Pack": "640",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002129",
        "Product Status": "Active"
    },
    {
        "Root": "10104949",
        "LV": "1",
        "Item Description": "WINE COOKING AJI MIRIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35225",
        "Last Price Paid": "$15.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104949",
        "Product Status": "Active"
    },
    {
        "Root": "10029921",
        "LV": "2",
        "Item Description": "CHAMPAGNE LOUIS ROEDERER ROSE BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029921002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "940540",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029921",
        "Product Status": "Active"
    },
    {
        "Root": "10003155",
        "LV": "1",
        "Item Description": "PRINTABLE FROSTING SHEET 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8835",
        "Last Price Paid": "$3.06",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003155",
        "Product Status": "Active"
    },
    {
        "Root": "10102388",
        "LV": "1",
        "Item Description": "MEZCAL DOS HOMBRES TOBALA JOVEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "986248",
        "Last Price Paid": "$245.00",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102388",
        "Product Status": "Active"
    },
    {
        "Root": "10007160",
        "LV": "1",
        "Item Description": "PROSECCO ANNIV TOAST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "62421",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007160",
        "Product Status": "Active"
    },
    {
        "Root": "10000794",
        "LV": "1",
        "Item Description": "MORTADELLA WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000794001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3613016",
        "Last Price Paid": "$3.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000794",
        "Product Status": "Active"
    },
    {
        "Root": "10009870",
        "LV": "1",
        "Item Description": "PORT DOW LBV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2312643",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009870",
        "Product Status": "Active"
    },
    {
        "Root": "10010521",
        "LV": "1",
        "Item Description": "AMARONE SARTORI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010521001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "989932",
        "Last Price Paid": "$35.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010521",
        "Product Status": "Active"
    },
    {
        "Root": "10081123",
        "LV": "1",
        "Item Description": "VODKA CIROC PEACH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081123001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "314826",
        "Last Price Paid": "$23.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081123",
        "Product Status": "Active"
    },
    {
        "Root": "10002913",
        "LV": "1",
        "Item Description": "JUICE MM ORANGE PET 10OZ/24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002913",
        "Product Status": "Active"
    },
    {
        "Root": "10006354",
        "LV": "1",
        "Item Description": "PAPER DELI RITE WRAP 12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "778662",
        "Last Price Paid": "$0.01",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006354",
        "Product Status": "Active"
    },
    {
        "Root": "10000353",
        "LV": "1",
        "Item Description": "PINEAPPLE CORED CUP 6 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-018",
        "Last Price Paid": "$7.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000353",
        "Product Status": "Active"
    },
    {
        "Root": "10000353",
        "LV": "1",
        "Item Description": "PINEAPPLE CORED CUP 6 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482201",
        "Last Price Paid": "$7.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000353",
        "Product Status": "Active"
    },
    {
        "Root": "10007693",
        "LV": "3",
        "Item Description": "PINOT NOIR ROCO GRAVEL ROAD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007693002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "166121",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007693",
        "Product Status": "Active"
    },
    {
        "Root": "10045438",
        "LV": "1",
        "Item Description": "CHILI VEGETARIAN RTU FZN PLST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045438001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1150440",
        "Last Price Paid": "$10.69",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045438",
        "Product Status": "Active"
    },
    {
        "Root": "10102552",
        "LV": "1",
        "Item Description": "CAVIAR PURE BELUGA 30G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102552001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60080020",
        "Last Price Paid": "$600.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102552",
        "Product Status": "Active"
    },
    {
        "Root": "10040028",
        "LV": "1",
        "Item Description": "BEEF PORTERHOUSE STEAK DRY AGE CHOICE ANGUS CC 28Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040028001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2690003",
        "Last Price Paid": "$22.13",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040028",
        "Product Status": "Active"
    },
    {
        "Root": "10003886",
        "LV": "1",
        "Item Description": "CRANBERRY DRY SWT 03479#1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003886001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7184807",
        "Last Price Paid": "$25.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003886",
        "Product Status": "Active"
    },
    {
        "Root": "10028024",
        "LV": "1",
        "Item Description": "OLIVE GREEN QUEEN PITTED GAL GLASS 130-140CT 4/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028024001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6333702",
        "Last Price Paid": "$23.19",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028024",
        "Product Status": "Active"
    },
    {
        "Root": "10004450",
        "LV": "1",
        "Item Description": "MIX FREESTYLE CHERRY 21Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3555800",
        "Last Price Paid": "$16.65",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004450",
        "Product Status": "Active"
    },
    {
        "Root": "10014205",
        "LV": "1",
        "Item Description": "CASING LAMB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014205001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7015335",
        "Last Price Paid": "$32.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014205",
        "Product Status": "Active"
    },
    {
        "Root": "10036246",
        "LV": "2",
        "Item Description": "CUP W/RFID WDW PAPER 12 OUNCE/2000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036246001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$65.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10036246",
        "Product Status": "Active"
    },
    {
        "Root": "10018667",
        "LV": "2",
        "Item Description": "CHEESE FRESH MOZZARELLA PEARLS 2.5 GRAM CRYOVAC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018667002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8935249",
        "Last Price Paid": "$2.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018667",
        "Product Status": "Active"
    },
    {
        "Root": "10007483",
        "LV": "1",
        "Item Description": "CHARD GLENELLY ESTATE GRAND VIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "560967",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007483",
        "Product Status": "Active"
    },
    {
        "Root": "10006900",
        "LV": "1",
        "Item Description": "ROSE CUVEE ENCHANTEE AOP LANGUEDOC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006900",
        "Product Status": "Active"
    },
    {
        "Root": "10031430",
        "LV": "1",
        "Item Description": "CUP COFFEE 3OZ MILANO W/ HANDLE FOTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11911015",
        "Last Price Paid": "$0.23",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031430",
        "Product Status": "Active"
    },
    {
        "Root": "10002998",
        "LV": "1",
        "Item Description": "CAPER NONPAREIL 6 32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2333599",
        "Last Price Paid": "$6.94",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002998",
        "Product Status": "Active"
    },
    {
        "Root": "10010549",
        "LV": "1",
        "Item Description": "HEX VOM DASENSTEIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010549",
        "Product Status": "Active"
    },
    {
        "Root": "10005050",
        "LV": "1",
        "Item Description": "SYRUP MARSHMLLW TSTD LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5145",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005050",
        "Product Status": "Active"
    },
    {
        "Root": "10009603",
        "LV": "1",
        "Item Description": "VODKA CHOPIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "899830",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009603",
        "Product Status": "Active"
    },
    {
        "Root": "10000731",
        "LV": "1",
        "Item Description": "BEEF TIPS SUBSTITUTE VEGETARIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1611334",
        "Last Price Paid": "$51.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000731",
        "Product Status": "Active"
    },
    {
        "Root": "10003140",
        "LV": "2",
        "Item Description": "QUINS SPOOKY HALLOWEEN 3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003140002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5813",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003140",
        "Product Status": "Active"
    },
    {
        "Root": "10001312",
        "LV": "1",
        "Item Description": "VEGETABLE MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001312",
        "Product Status": "Active"
    },
    {
        "Root": "10001312",
        "LV": "1",
        "Item Description": "VEGETABLE MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8328080",
        "Last Price Paid": "$28.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001312",
        "Product Status": "Active"
    },
    {
        "Root": "10102346",
        "LV": "1",
        "Item Description": "SIPPER PREM DAK 25TH TIMON 20OZ 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102346",
        "Product Status": "Active"
    },
    {
        "Root": "10035955",
        "LV": "1",
        "Item Description": "DRAFT SAUGATUCK BLUEBERRY LEMONADE 1/2 1984 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "8668",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035955",
        "Product Status": "Active"
    },
    {
        "Root": "10036358",
        "LV": "1",
        "Item Description": "DARK CHOCOLATE RASPBERRY TWIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5180040000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036358",
        "Product Status": "Active"
    },
    {
        "Root": "10039129",
        "LV": "1",
        "Item Description": "NOODLE FROZEN SWEET POTATO 16 INCH COOKED  4/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5352546",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039129",
        "Product Status": "Active"
    },
    {
        "Root": "10026957",
        "LV": "1",
        "Item Description": "CHOC WHITE MINNIE AND MICKEY HEART WITH PICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6230900000",
        "Last Price Paid": "",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026957",
        "Product Status": "Active"
    },
    {
        "Root": "10037083",
        "LV": "1",
        "Item Description": "AMONTILLADO OSBORNE 500 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9202215",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037083",
        "Product Status": "Active"
    },
    {
        "Root": "10035571",
        "LV": "1",
        "Item Description": "CHAMPAGNE HENRIOT CUVEE DES ENCHANTELEURS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "398064",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035571",
        "Product Status": "Active"
    },
    {
        "Root": "10009581",
        "LV": "1",
        "Item Description": "TEQ CUERVO SLVR 80 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009581",
        "Product Status": "Active"
    },
    {
        "Root": "10033660",
        "LV": "1",
        "Item Description": "SUGAR ITSY BITSY SPIDER ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033660001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033660",
        "Product Status": "Active"
    },
    {
        "Root": "10040999",
        "LV": "1",
        "Item Description": "SHELL, MINI CREAM PUFF 1.75 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA1018",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040999",
        "Product Status": "Active"
    },
    {
        "Root": "10002691",
        "LV": "3",
        "Item Description": "CEREAL FRUIT LOOP BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002691002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10002691",
        "Product Status": "Active"
    },
    {
        "Root": "10007745",
        "LV": "2",
        "Item Description": "CAB SAUV SCARECROW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007745001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9190408",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007745",
        "Product Status": "Active"
    },
    {
        "Root": "10010435",
        "LV": "2",
        "Item Description": "BEER OMMEGANG THREE PHILOSOPHER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010435001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12179",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010435",
        "Product Status": "Active"
    },
    {
        "Root": "10003657",
        "LV": "1",
        "Item Description": "ARGON TANK 125CF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003657001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003657",
        "Product Status": "Active"
    },
    {
        "Root": "10091631",
        "LV": "1",
        "Item Description": "TEQUILA TERAMANA BLANCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "965145",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091631",
        "Product Status": "Active"
    },
    {
        "Root": "10007992",
        "LV": "2",
        "Item Description": "ICE WINE CAB FRANC IINNISKILLIN 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007992001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "525412",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007992",
        "Product Status": "Active"
    },
    {
        "Root": "10090953",
        "LV": "1",
        "Item Description": "FILLING APPLE DICED 42#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090953",
        "Product Status": "Active"
    },
    {
        "Root": "10037365",
        "LV": "1",
        "Item Description": "MINNIE E+T CONTAINER BAND SMALL 16.81X2.38 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45003625",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037365",
        "Product Status": "Active"
    },
    {
        "Root": "10001465",
        "LV": "1",
        "Item Description": "HERB ANISE FENNEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001465001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490140",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001465",
        "Product Status": "Active"
    },
    {
        "Root": "10004344",
        "LV": "1",
        "Item Description": "JELLIED LIME SLICES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA102",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004344",
        "Product Status": "Active"
    },
    {
        "Root": "10003004",
        "LV": "1",
        "Item Description": "PASTA DRY SPAGHETTI RIGAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808521808",
        "Last Price Paid": "$1.25",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003004",
        "Product Status": "Active"
    },
    {
        "Root": "10020955",
        "LV": "1",
        "Item Description": "SFOGLIATELLE MINI FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ118656",
        "Last Price Paid": "$0.86",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020955",
        "Product Status": "Active"
    },
    {
        "Root": "10100279",
        "LV": "1",
        "Item Description": "KOSHER BSC DANISH CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.64",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100279",
        "Product Status": "Active"
    },
    {
        "Root": "10016455",
        "LV": "3",
        "Item Description": "MAYO 2/1.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016455002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.79",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10016455",
        "Product Status": "Active"
    },
    {
        "Root": "10029752",
        "LV": "1",
        "Item Description": "COOKIE OREO CHOCOLATE SANDWICH 24/5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2007557",
        "Last Price Paid": "$1.60",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029752",
        "Product Status": "Active"
    },
    {
        "Root": "10027754",
        "LV": "2",
        "Item Description": "TRAY FOOD #200 NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027754002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027754",
        "Product Status": "Active"
    },
    {
        "Root": "10018290",
        "LV": "1",
        "Item Description": "SPICE PEPPERCORN PINK WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018290",
        "Product Status": "Active"
    },
    {
        "Root": "10003260",
        "LV": "1",
        "Item Description": "SYRUP STRAWBERRY LTR PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003260001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003260",
        "Product Status": "Active"
    },
    {
        "Root": "10040110",
        "LV": "1",
        "Item Description": "MIX TEA KELVIN SLUSH 4/64OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8796070",
        "Last Price Paid": "$30.85",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040110",
        "Product Status": "Active"
    },
    {
        "Root": "10001894",
        "LV": "1",
        "Item Description": "PEPPER RED DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-001-34-A",
        "Last Price Paid": "$16.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001894",
        "Product Status": "Active"
    },
    {
        "Root": "10106829",
        "LV": "1",
        "Item Description": "LABEL DISNEY HARVEST 250CT.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1032649",
        "Last Price Paid": "$1.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106829",
        "Product Status": "Active"
    },
    {
        "Root": "10023306",
        "LV": "1",
        "Item Description": "CHOC DRK FRAME 12 X 8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW50661D",
        "Last Price Paid": "$25.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023306",
        "Product Status": "Active"
    },
    {
        "Root": "10085558",
        "LV": "1",
        "Item Description": "WINE DESSERT EMOTIONS SAUTERNES 24/375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "385620",
        "Last Price Paid": "$16.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085558",
        "Product Status": "Active"
    },
    {
        "Root": "10030587",
        "LV": "1",
        "Item Description": "SYRUP MONIN PEPPERMINT 1L PLASTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5050",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030587",
        "Product Status": "Active"
    },
    {
        "Root": "10001593",
        "LV": "1",
        "Item Description": "SWISS CHARD RAINBOW 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21670",
        "Last Price Paid": "$38.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001593",
        "Product Status": "Active"
    },
    {
        "Root": "10001593",
        "LV": "1",
        "Item Description": "SWISS CHARD RAINBOW 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482180",
        "Last Price Paid": "$38.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001593",
        "Product Status": "Active"
    },
    {
        "Root": "10001593",
        "LV": "1",
        "Item Description": "SWISS CHARD RAINBOW 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001593",
        "Product Status": "Active"
    },
    {
        "Root": "10001593",
        "LV": "1",
        "Item Description": "SWISS CHARD RAINBOW 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069667",
        "Vendor": "GARDEN GOURMET SPECIALTIES",
        "Vendor Item Nb": "482180",
        "Last Price Paid": "$38.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001593",
        "Product Status": "Active"
    },
    {
        "Root": "10001826",
        "LV": "1",
        "Item Description": "LETTUCE BABY ROMAINE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480062",
        "Last Price Paid": "$16.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001826",
        "Product Status": "Active"
    },
    {
        "Root": "10001826",
        "LV": "1",
        "Item Description": "LETTUCE BABY ROMAINE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001826",
        "Product Status": "Active"
    },
    {
        "Root": "10001558",
        "LV": "1",
        "Item Description": "PEPPER HABANERO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407101",
        "Last Price Paid": "$36.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001558",
        "Product Status": "Active"
    },
    {
        "Root": "10001857",
        "LV": "1",
        "Item Description": "LETTUCE BIBB HYDROPONIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001857001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403792",
        "Last Price Paid": "$24.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001857",
        "Product Status": "Active"
    },
    {
        "Root": "10001857",
        "LV": "1",
        "Item Description": "LETTUCE BIBB HYDROPONIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001857001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001857",
        "Product Status": "Active"
    },
    {
        "Root": "10106653",
        "LV": "1",
        "Item Description": "DAKEL PINOT NOIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106653001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "563445",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106653",
        "Product Status": "Active"
    },
    {
        "Root": "10036228",
        "LV": "1",
        "Item Description": "10G-042008 4X2X8 POLY BAG (1.0)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51805244",
        "Last Price Paid": "$0.01",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036228",
        "Product Status": "Active"
    },
    {
        "Root": "10002300",
        "LV": "1",
        "Item Description": "GELATO RASPBERRY SCOOP 48 / 1OZ  CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "9992",
        "Last Price Paid": "$0.49",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002300",
        "Product Status": "Active"
    },
    {
        "Root": "10039587",
        "LV": "1",
        "Item Description": "SPIRIT SEEDLIP GARDEN 108 NON-ALCOHOLIC 6/700ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550291",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039587",
        "Product Status": "Active"
    },
    {
        "Root": "10035610",
        "LV": "1",
        "Item Description": "BOX CLEAR 32OZ MICROLITE MW DELI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51026068",
        "Last Price Paid": "$0.19",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035610",
        "Product Status": "Active"
    },
    {
        "Root": "10034099",
        "LV": "2",
        "Item Description": "RASPBERRY COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034099002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "0111060000",
        "Last Price Paid": "$93.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034099",
        "Product Status": "Active"
    },
    {
        "Root": "10105036",
        "LV": "1",
        "Item Description": "OLD SPECKLED HEN NITRO 50 L KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$147.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105036",
        "Product Status": "Active"
    },
    {
        "Root": "10001786",
        "LV": "1",
        "Item Description": "PEA SUGAR SNAP STRINGLESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401268",
        "Last Price Paid": "$47.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001786",
        "Product Status": "Active"
    },
    {
        "Root": "10085967",
        "LV": "1",
        "Item Description": "PORK BRATWURST PATTY 36/4OZ 5OTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8670358",
        "Last Price Paid": "$0.80",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085967",
        "Product Status": "Active"
    },
    {
        "Root": "10001817",
        "LV": "1",
        "Item Description": "PEAR BOSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.50",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001817",
        "Product Status": "Active"
    },
    {
        "Root": "10001817",
        "LV": "1",
        "Item Description": "PEAR BOSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "272000",
        "Last Price Paid": "$0.50",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001817",
        "Product Status": "Active"
    },
    {
        "Root": "10001817",
        "LV": "1",
        "Item Description": "PEAR BOSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "11101",
        "Last Price Paid": "$0.50",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001817",
        "Product Status": "Active"
    },
    {
        "Root": "10022830",
        "LV": "1",
        "Item Description": "CHOC WHT 2 X 1.5 RECT LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2164W",
        "Last Price Paid": "$1.15",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022830",
        "Product Status": "Active"
    },
    {
        "Root": "10037425",
        "LV": "1",
        "Item Description": "CHEESE SHREDS DAIRY FREE PARMESAN 5 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "60080",
        "Last Price Paid": "$34.06",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037425",
        "Product Status": "Active"
    },
    {
        "Root": "10001961",
        "LV": "1",
        "Item Description": "POTATO SWEET CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515078",
        "Last Price Paid": "$35.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001961",
        "Product Status": "Active"
    },
    {
        "Root": "10001961",
        "LV": "1",
        "Item Description": "POTATO SWEET CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "27-005-14",
        "Last Price Paid": "$35.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001961",
        "Product Status": "Active"
    },
    {
        "Root": "10021939",
        "LV": "1",
        "Item Description": "CAVIAR BELGIAN SIBERIAN 125 GR SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021939001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60135000",
        "Last Price Paid": "$160.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021939",
        "Product Status": "Active"
    },
    {
        "Root": "10003766",
        "LV": "1",
        "Item Description": "FLOUR CHICK PEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481493",
        "Last Price Paid": "$23.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003766",
        "Product Status": "Active"
    },
    {
        "Root": "10083545",
        "LV": "1",
        "Item Description": "BEER COLLECTIVE ARTS GOOD MONSTER 16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13390",
        "Last Price Paid": "$3.08",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083545",
        "Product Status": "Active"
    },
    {
        "Root": "10083545",
        "LV": "1",
        "Item Description": "BEER COLLECTIVE ARTS GOOD MONSTER 16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13390",
        "Last Price Paid": "$3.08",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083545",
        "Product Status": "Active"
    },
    {
        "Root": "10002195",
        "LV": "1",
        "Item Description": "CREAM WHIPPED 24%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002195",
        "Product Status": "Active"
    },
    {
        "Root": "10006825",
        "LV": "1",
        "Item Description": "NAPKIN BEV PLAIN BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2653012",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006825",
        "Product Status": "Active"
    },
    {
        "Root": "10084256",
        "LV": "1",
        "Item Description": "PORK CHOP B/I RIBEYE DRY AGED FROZEN 12/14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084256001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1463767",
        "Last Price Paid": "$10.47",
        "Case Pack": "0.875",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084256",
        "Product Status": "Active"
    },
    {
        "Root": "10090648",
        "LV": "1",
        "Item Description": "PRETZEL BAG DOTS HOMESTYLE SOUTHWEST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302107",
        "Last Price Paid": "$1.37",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090648",
        "Product Status": "Active"
    },
    {
        "Root": "10004806",
        "LV": "1",
        "Item Description": "BEAN BLACK CANNED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004806",
        "Product Status": "Active"
    },
    {
        "Root": "10107921",
        "LV": "1",
        "Item Description": "EIRA 700ML SPARKLING WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "EIR02",
        "Last Price Paid": "$4.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107921",
        "Product Status": "Active"
    },
    {
        "Root": "10043196",
        "LV": "1",
        "Item Description": "CIDER HARD CAN ACE PINEAPPLE 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "50311",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043196",
        "Product Status": "Active"
    },
    {
        "Root": "10010767",
        "LV": "1",
        "Item Description": "BITTERS ANGOSTURA 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "38000-A",
        "Last Price Paid": "$22.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010767",
        "Product Status": "Active"
    },
    {
        "Root": "10034221",
        "LV": "1",
        "Item Description": "CUP CLEAR PET 16OZ APC16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034221",
        "Product Status": "Active"
    },
    {
        "Root": "10018805",
        "LV": "1",
        "Item Description": "POWDER DECOR GARNET SATIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11183",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018805",
        "Product Status": "Active"
    },
    {
        "Root": "10100353",
        "LV": "1",
        "Item Description": "SB SANDWICH TURKEY BACON REDUCED FAT 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11041854",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100353",
        "Product Status": "Active"
    },
    {
        "Root": "10109334",
        "LV": "1",
        "Item Description": "COOKIE RED VELVET CHERYLS 100CT/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.58",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109334",
        "Product Status": "Active"
    },
    {
        "Root": "10006941",
        "LV": "1",
        "Item Description": "BLEND WHITE CONUNDRUM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600588",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006941",
        "Product Status": "Active"
    },
    {
        "Root": "10028730",
        "LV": "1",
        "Item Description": "WINE MONGEARD MUGNERET VOSNE ROMANEE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9619682",
        "Last Price Paid": "$77.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028730",
        "Product Status": "Active"
    },
    {
        "Root": "10004634",
        "LV": "1",
        "Item Description": "LIQUID SMOKE GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5003975",
        "Last Price Paid": "$11.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004634",
        "Product Status": "Active"
    },
    {
        "Root": "10034360",
        "LV": "1",
        "Item Description": "CHORIZO SPANISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1376",
        "Last Price Paid": "$91.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034360",
        "Product Status": "Active"
    },
    {
        "Root": "10040436",
        "LV": "1",
        "Item Description": "WHITE HOT CUP PAPER 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040436",
        "Product Status": "Active"
    },
    {
        "Root": "10009607",
        "LV": "1",
        "Item Description": "TEQ DON JULIO 1942 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "411403",
        "Last Price Paid": "$152.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009607",
        "Product Status": "Active"
    },
    {
        "Root": "10009607",
        "LV": "1",
        "Item Description": "TEQ DON JULIO 1942 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "411403",
        "Last Price Paid": "$152.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009607",
        "Product Status": "Active"
    },
    {
        "Root": "10004620",
        "LV": "1",
        "Item Description": "MUSTARD GUILDEN GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8014623",
        "Last Price Paid": "$9.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004620",
        "Product Status": "Active"
    },
    {
        "Root": "10033727",
        "LV": "1",
        "Item Description": "BEEF RIBEYE STEAK BONEIN E-2-E 20-22Z CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033727001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2090048",
        "Last Price Paid": "$14.99",
        "Case Pack": "12.5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033727",
        "Product Status": "Active"
    },
    {
        "Root": "10001293",
        "LV": "1",
        "Item Description": "JUICE MM ORANGE GUAVA PASSION 4/90OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001293001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006539",
        "Last Price Paid": "$22.07",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001293",
        "Product Status": "Active"
    },
    {
        "Root": "10002634",
        "LV": "1",
        "Item Description": "SPICE TARRAGON LEAF 6/3.5 OZ.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002634",
        "Product Status": "Active"
    },
    {
        "Root": "10002643",
        "LV": "2",
        "Item Description": "SPICE PEPPER BLACK GROUND 4/4 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002643002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00306-7",
        "Last Price Paid": "$17.97",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002643",
        "Product Status": "Active"
    },
    {
        "Root": "10002643",
        "LV": "2",
        "Item Description": "SPICE PEPPER BLACK GROUND 4/4 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002643002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.97",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002643",
        "Product Status": "Active"
    },
    {
        "Root": "10004682",
        "LV": "1",
        "Item Description": "CANDY TOPPING HEATH BAR GROUND 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "53060",
        "Last Price Paid": "$188.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004682",
        "Product Status": "Active"
    },
    {
        "Root": "10004682",
        "LV": "1",
        "Item Description": "CANDY TOPPING HEATH BAR GROUND 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "HC6057",
        "Last Price Paid": "$188.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004682",
        "Product Status": "Active"
    },
    {
        "Root": "10006159",
        "LV": "3",
        "Item Description": "DRIZZLER DECO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006159002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9746",
        "Last Price Paid": "$9.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10006159",
        "Product Status": "Active"
    },
    {
        "Root": "10029757",
        "LV": "2",
        "Item Description": "LIQUEUR MOZART CHOCOLATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029757002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029757",
        "Product Status": "Active"
    },
    {
        "Root": "10029757",
        "LV": "2",
        "Item Description": "LIQUEUR MOZART CHOCOLATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029757002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "155196",
        "Last Price Paid": "$20.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029757",
        "Product Status": "Active"
    },
    {
        "Root": "10014540",
        "LV": "1",
        "Item Description": "TINY CRUDITE MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450171",
        "Last Price Paid": "$0.34",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014540",
        "Product Status": "Active"
    },
    {
        "Root": "10032208",
        "LV": "1",
        "Item Description": "CANDY TOPPING CARAMEL BITS 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550290",
        "Last Price Paid": "$3.71",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032208",
        "Product Status": "Active"
    },
    {
        "Root": "10001335",
        "LV": "1",
        "Item Description": "GARLIC CHIMICHURRI 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$161.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001335",
        "Product Status": "Active"
    },
    {
        "Root": "10002550",
        "LV": "1",
        "Item Description": "ASPARAGUS CALIF JUMB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401337",
        "Last Price Paid": "$72.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002550",
        "Product Status": "Active"
    },
    {
        "Root": "10004987",
        "LV": "1",
        "Item Description": "FILLING GUAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004987001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "06...{PURATOS{",
        "Last Price Paid": "$47.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004987",
        "Product Status": "Active"
    },
    {
        "Root": "10034238",
        "LV": "2",
        "Item Description": "DECOR CHOC WHITE TREE OF LIFE IMAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034238002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234410000",
        "Last Price Paid": "$0.82",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034238",
        "Product Status": "Active"
    },
    {
        "Root": "10027164",
        "LV": "1",
        "Item Description": "TINY TUMBLER 6403 5.4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027164001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11417060",
        "Last Price Paid": "$0.38",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027164",
        "Product Status": "Active"
    },
    {
        "Root": "10010316",
        "LV": "2",
        "Item Description": "BEER PERONI NASTRO 12OZ LNNR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010316001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "22540",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010316",
        "Product Status": "Active"
    },
    {
        "Root": "10003953",
        "LV": "1",
        "Item Description": "MARINARA TURKEY 4-8LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2908957",
        "Last Price Paid": "$18.82",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003953",
        "Product Status": "Active"
    },
    {
        "Root": "10035870",
        "LV": "1",
        "Item Description": "BITTERS FEE BRO BLACK WALNUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "202221",
        "Last Price Paid": "$7.11",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035870",
        "Product Status": "Active"
    },
    {
        "Root": "10039825",
        "LV": "1",
        "Item Description": "ICE CUBES 3 INCH 72/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609904",
        "Vendor": "ICE PROFESSIONALS OF FLORIDA LLC",
        "Vendor Item Nb": "WCBPR3-72",
        "Last Price Paid": "",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039825",
        "Product Status": "Active"
    },
    {
        "Root": "10036934",
        "LV": "1",
        "Item Description": "CHOC WHITE PUMBA SQUARE EXTRA 4CM 240 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6232930000",
        "Last Price Paid": "",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036934",
        "Product Status": "Active"
    },
    {
        "Root": "10000254",
        "LV": "1",
        "Item Description": "BEETS SPIRAL CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "112209",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000254",
        "Product Status": "Active"
    },
    {
        "Root": "10019352",
        "LV": "1",
        "Item Description": "GLERA PROSECCO MARSURET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019352",
        "Product Status": "Active"
    },
    {
        "Root": "10006758",
        "LV": "1",
        "Item Description": "BOARD CAKE 20 INCH X 1/2 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2010...{ENJAY{",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006758",
        "Product Status": "Active"
    },
    {
        "Root": "10009789",
        "LV": "1",
        "Item Description": "RUM STARR AFRICAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009789",
        "Product Status": "Active"
    },
    {
        "Root": "10035510",
        "LV": "1",
        "Item Description": "SB CAKE POP VALENTINES DAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11096875",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035510",
        "Product Status": "Active"
    },
    {
        "Root": "10038806",
        "LV": "1",
        "Item Description": "BEEF HANGER STEAKS 28/6OZ CAB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038806001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2011084",
        "Last Price Paid": "",
        "Case Pack": "10.5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038806",
        "Product Status": "Active"
    },
    {
        "Root": "10012747",
        "LV": "1",
        "Item Description": "LEEK PETITE 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PLK-33",
        "Last Price Paid": "$34.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012747",
        "Product Status": "Active"
    },
    {
        "Root": "10004573",
        "LV": "1",
        "Item Description": "NUT MIXED 40Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004573001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3605086",
        "Last Price Paid": "$21.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004573",
        "Product Status": "Active"
    },
    {
        "Root": "10001956",
        "LV": "1",
        "Item Description": "CHERVIL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04CHE-33-L",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001956",
        "Product Status": "Active"
    },
    {
        "Root": "10001956",
        "LV": "1",
        "Item Description": "CHERVIL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482304",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001956",
        "Product Status": "Active"
    },
    {
        "Root": "10001956",
        "LV": "1",
        "Item Description": "CHERVIL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22108",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001956",
        "Product Status": "Active"
    },
    {
        "Root": "10034159",
        "LV": "1",
        "Item Description": "TOY STORY 11.5 X 5 YEL CHECKERBOARD LINER LIGHTWEI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659032",
        "Last Price Paid": "$0.02",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034159",
        "Product Status": "Active"
    },
    {
        "Root": "10019592",
        "LV": "1",
        "Item Description": "WHISKEY RYE PIG WHISTLE 10 YR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9183531",
        "Last Price Paid": "$66.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019592",
        "Product Status": "Active"
    },
    {
        "Root": "10001975",
        "LV": "1",
        "Item Description": "POPCORN SHOOTS YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MP-33-L",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001975",
        "Product Status": "Active"
    },
    {
        "Root": "10001727",
        "LV": "1",
        "Item Description": "PEAR SECKEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$110.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001727",
        "Product Status": "Active"
    },
    {
        "Root": "10001727",
        "LV": "1",
        "Item Description": "PEAR SECKEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "279010",
        "Last Price Paid": "$110.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001727",
        "Product Status": "Active"
    },
    {
        "Root": "10007986",
        "LV": "2",
        "Item Description": "PINOT NOIR M EDWARD MEREDTH 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007986001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$69.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007986",
        "Product Status": "Active"
    },
    {
        "Root": "10034158",
        "LV": "1",
        "Item Description": "TOY STORY 11.5 X 5 RED CHECKERBOARD LINER LIGHTWEI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659031",
        "Last Price Paid": "$0.02",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034158",
        "Product Status": "Active"
    },
    {
        "Root": "10092082",
        "LV": "1",
        "Item Description": "COOKIE CRUMB OREO MEDIUM PIECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092082001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3274537",
        "Last Price Paid": "$3.92",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092082",
        "Product Status": "Active"
    },
    {
        "Root": "10005560",
        "LV": "1",
        "Item Description": "BISCUIT TRANS SENSE 2.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005560001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005560",
        "Product Status": "Active"
    },
    {
        "Root": "10003399",
        "LV": "1",
        "Item Description": "PASTE GRN THAI CURRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4304283",
        "Last Price Paid": "$69.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003399",
        "Product Status": "Active"
    },
    {
        "Root": "10021454",
        "LV": "1",
        "Item Description": "CHEESE HARD COTIJA 12/10 OUNCE WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "74562",
        "Last Price Paid": "$4.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021454",
        "Product Status": "Active"
    },
    {
        "Root": "10000469",
        "LV": "1",
        "Item Description": "BREAD BREAKFAST BANANA 8 IN X 4 IN UNSLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000469",
        "Product Status": "Active"
    },
    {
        "Root": "10005877",
        "LV": "1",
        "Item Description": "CAKE SPONGE 8 INCH CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005877",
        "Product Status": "Active"
    },
    {
        "Root": "10094988",
        "LV": "1",
        "Item Description": "HIBISCUS POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "257625",
        "Last Price Paid": "$13.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094988",
        "Product Status": "Active"
    },
    {
        "Root": "10010000",
        "LV": "1",
        "Item Description": "LIQ MELON LTR BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010000001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010000",
        "Product Status": "Active"
    },
    {
        "Root": "10040098",
        "LV": "1",
        "Item Description": "PIZZA SUPREME TOMBSTONE ORIGINAL 12IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040098001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040098",
        "Product Status": "Active"
    },
    {
        "Root": "10040098",
        "LV": "1",
        "Item Description": "PIZZA SUPREME TOMBSTONE ORIGINAL 12IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040098001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12406067",
        "Last Price Paid": "$4.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040098",
        "Product Status": "Active"
    },
    {
        "Root": "10007810",
        "LV": "1",
        "Item Description": "VIOGNIER DARENBRG HERMIT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "DAR-05-16",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007810",
        "Product Status": "Active"
    },
    {
        "Root": "10000766",
        "LV": "1",
        "Item Description": "TURKEY SAUSAGE LINK MAPLE 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000766001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3600025",
        "Last Price Paid": "$9.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000766",
        "Product Status": "Active"
    },
    {
        "Root": "10084117",
        "LV": "2",
        "Item Description": "FORK CPLA HW 24/40 SMARTSTOCK TT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084117001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$63.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10084117",
        "Product Status": "Active"
    },
    {
        "Root": "10011164",
        "LV": "1",
        "Item Description": "BEANS FAVA FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011164001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480370",
        "Last Price Paid": "$7.85",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011164",
        "Product Status": "Active"
    },
    {
        "Root": "10001366",
        "LV": "1",
        "Item Description": "SAUCE TZDAZIKI YOG CUC 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001366",
        "Product Status": "Active"
    },
    {
        "Root": "10019952",
        "LV": "1",
        "Item Description": "MERLOT LAPLAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "286958",
        "Last Price Paid": "$27.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019952",
        "Product Status": "Active"
    },
    {
        "Root": "10085971",
        "LV": "1",
        "Item Description": "BAG POPCORN MSC 5X3X12, 25# BLEACHED GLASSINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510186",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085971",
        "Product Status": "Active"
    },
    {
        "Root": "10007728",
        "LV": "2",
        "Item Description": "MALBEC SALENTEIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007728001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "807973",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007728",
        "Product Status": "Active"
    },
    {
        "Root": "10106135",
        "LV": "1",
        "Item Description": "DTD PINK GLITTER SUGAR/SALT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106135001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "PGSPS-01",
        "Last Price Paid": "$22.00",
        "Case Pack": "1.002",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106135",
        "Product Status": "Active"
    },
    {
        "Root": "10032864",
        "LV": "1",
        "Item Description": "CARBON DIOXIDE WITH SIPHON TUBE 20 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "CD FG20S",
        "Last Price Paid": "$5.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032864",
        "Product Status": "Active"
    },
    {
        "Root": "10039761",
        "LV": "1",
        "Item Description": "BEEF BURGER ANGUS D-LUXE  5.33Z  - BRISKET & CHUCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039761001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1095001",
        "Last Price Paid": "$4.48",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039761",
        "Product Status": "Active"
    },
    {
        "Root": "10003341",
        "LV": "1",
        "Item Description": "SYRUP DIET COKE CF 2.5G B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "03470298",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003341",
        "Product Status": "Active"
    },
    {
        "Root": "10044251",
        "LV": "2",
        "Item Description": "BAG GIFT AMORETTES HANDLES SMALL 8.5X7.5X5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044251002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013423",
        "Last Price Paid": "$1.02",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10044251",
        "Product Status": "Active"
    },
    {
        "Root": "10002429",
        "LV": "2",
        "Item Description": "ICE CREAM MINT CHIP EDY 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002429002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002429",
        "Product Status": "Active"
    },
    {
        "Root": "10088447",
        "LV": "1",
        "Item Description": "MIX GELATO APPLE PIE SOFT SERVE 1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088447001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "GS1003",
        "Last Price Paid": "$15.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088447",
        "Product Status": "Active"
    },
    {
        "Root": "10107865",
        "LV": "1",
        "Item Description": "CUP BASE KIDS WISH VAL STAR PURP 16OZ 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107865",
        "Product Status": "Active"
    },
    {
        "Root": "10041155",
        "LV": "1",
        "Item Description": "BEER HEINEKEN NON ALC 11.2 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "42856",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041155",
        "Product Status": "Active"
    },
    {
        "Root": "10110088",
        "LV": "1",
        "Item Description": "CUP BEER CLEAR PLASTIC 10OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "347SLBG10",
        "Last Price Paid": "$0.65",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110088",
        "Product Status": "Active"
    },
    {
        "Root": "10003937",
        "LV": "1",
        "Item Description": "WATER CORTAS ROSE WATER 10OZ/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "149000",
        "Last Price Paid": "$4.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003937",
        "Product Status": "Active"
    },
    {
        "Root": "10007032",
        "LV": "1",
        "Item Description": "BLND QUINTESSA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "941810",
        "Last Price Paid": "$191.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007032",
        "Product Status": "Active"
    },
    {
        "Root": "10001523",
        "LV": "1",
        "Item Description": "FLOWER DIANTHUS 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05DIA-24",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001523",
        "Product Status": "Active"
    },
    {
        "Root": "10001523",
        "LV": "1",
        "Item Description": "FLOWER DIANTHUS 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480376",
        "Last Price Paid": "$0.59",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001523",
        "Product Status": "Active"
    },
    {
        "Root": "10081424",
        "LV": "1",
        "Item Description": "JUICE GRAPEFRUIT RUBY RED 100% NOT FROM CONCENTRAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8620197",
        "Last Price Paid": "$1.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081424",
        "Product Status": "Active"
    },
    {
        "Root": "10005435",
        "LV": "1",
        "Item Description": "MIX FLORENTINE FLORENTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "107615",
        "Last Price Paid": "$4.96",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005435",
        "Product Status": "Active"
    },
    {
        "Root": "10038234",
        "LV": "1",
        "Item Description": "LETTUCE ARCADIAN BLEND SPRING MIX 4,3#CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21200",
        "Last Price Paid": "$9.36",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038234",
        "Product Status": "Active"
    },
    {
        "Root": "10038234",
        "LV": "1",
        "Item Description": "LETTUCE ARCADIAN BLEND SPRING MIX 4,3#CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480794",
        "Last Price Paid": "$9.36",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038234",
        "Product Status": "Active"
    },
    {
        "Root": "10110128",
        "LV": "1",
        "Item Description": "BAG O' TREATS CHOCOLATE SUNBUTTER CUP 240 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "FF01RT12",
        "Last Price Paid": "$0.84",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110128",
        "Product Status": "Active"
    },
    {
        "Root": "10009464",
        "LV": "1",
        "Item Description": "WHISKEY IRSH BUSHMIL 16YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009464001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "6375",
        "Last Price Paid": "$113.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009464",
        "Product Status": "Active"
    },
    {
        "Root": "10036210",
        "LV": "1",
        "Item Description": "COCOA BUTTER GILDED YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302010C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036210",
        "Product Status": "Active"
    },
    {
        "Root": "10000363",
        "LV": "1",
        "Item Description": "SQUASH YELLOW 1/2 MOON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "16-003",
        "Last Price Paid": "$9.61",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000363",
        "Product Status": "Active"
    },
    {
        "Root": "10000363",
        "LV": "1",
        "Item Description": "SQUASH YELLOW 1/2 MOON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490074",
        "Last Price Paid": "$9.61",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000363",
        "Product Status": "Active"
    },
    {
        "Root": "10009828",
        "LV": "1",
        "Item Description": "RUM MOUNT GAY ECLIPSE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "102356",
        "Last Price Paid": "$24.61",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009828",
        "Product Status": "Active"
    },
    {
        "Root": "10104441",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS BLACK CHERRY 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104441",
        "Product Status": "Active"
    },
    {
        "Root": "10104441",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS BLACK CHERRY 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76398",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104441",
        "Product Status": "Active"
    },
    {
        "Root": "10034098",
        "LV": "1",
        "Item Description": "SYRUP 100% BOURBON MAPLE BLIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034098001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SYR000",
        "Last Price Paid": "$17.80",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034098",
        "Product Status": "Active"
    },
    {
        "Root": "10001289",
        "LV": "1",
        "Item Description": "JUICE MM ORANGE GUAVA PASSION FRUIT 6/64OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006468",
        "Last Price Paid": "$14.38",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001289",
        "Product Status": "Active"
    },
    {
        "Root": "10005064",
        "LV": "1",
        "Item Description": "COLORING LIQUA GEL GRN LE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22506",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005064",
        "Product Status": "Active"
    },
    {
        "Root": "10008141",
        "LV": "1",
        "Item Description": "PINOT NOIR ARGYLE OREGON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "963932",
        "Last Price Paid": "$18.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008141",
        "Product Status": "Active"
    },
    {
        "Root": "10030851",
        "LV": "1",
        "Item Description": "PORK SHORTENING LARD SOLID CUBE 50LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5888599",
        "Last Price Paid": "$73.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030851",
        "Product Status": "Active"
    },
    {
        "Root": "10031840",
        "LV": "1",
        "Item Description": "SB COCONUTMILK 64OZ 8-CS US ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011086983",
        "Last Price Paid": "$5.12",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031840",
        "Product Status": "Active"
    },
    {
        "Root": "10007842",
        "LV": "3",
        "Item Description": "PINOT NOIR EDNA VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007842002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9041672",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007842",
        "Product Status": "Active"
    },
    {
        "Root": "10034147",
        "LV": "1",
        "Item Description": "CHIP CHEE-TOS FLAMING HOT 64/2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5597489",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034147",
        "Product Status": "Active"
    },
    {
        "Root": "10025305",
        "LV": "1",
        "Item Description": "SHEET TRAN 36X13 AMORETTES FONT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6170450000",
        "Last Price Paid": "$0.79",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025305",
        "Product Status": "Active"
    },
    {
        "Root": "10013694",
        "LV": "1",
        "Item Description": "TURKEY BRST RSTD SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013694001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7032402",
        "Last Price Paid": "$8.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013694",
        "Product Status": "Active"
    },
    {
        "Root": "10007777",
        "LV": "2",
        "Item Description": "CHAMP DOM PERIGN 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007777001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "580836",
        "Last Price Paid": "$201.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007777",
        "Product Status": "Active"
    },
    {
        "Root": "10036361",
        "LV": "1",
        "Item Description": "EGG WRAPPED DARK CHOC PRALINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA12608",
        "Last Price Paid": "$0.30",
        "Case Pack": "267",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036361",
        "Product Status": "Active"
    },
    {
        "Root": "10092820",
        "LV": "2",
        "Item Description": "LIQUEUR CHARTREUSE YELLOW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092820002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "932469",
        "Last Price Paid": "$49.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092820",
        "Product Status": "Active"
    },
    {
        "Root": "10018617",
        "LV": "1",
        "Item Description": "STICK PAPER LOLLIPOP 5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51888013",
        "Last Price Paid": "$0.01",
        "Case Pack": "11000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018617",
        "Product Status": "Active"
    },
    {
        "Root": "10026678",
        "LV": "1",
        "Item Description": "SB SYRUP TURBINADO 1L 6 EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026678001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11111668",
        "Last Price Paid": "$6.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026678",
        "Product Status": "Active"
    },
    {
        "Root": "10105439",
        "LV": "1",
        "Item Description": "GIN LANE GIN CUC WTRMLN MINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105439001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9623424",
        "Last Price Paid": "$24.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105439",
        "Product Status": "Active"
    },
    {
        "Root": "10004557",
        "LV": "1",
        "Item Description": "PEA BLACK EYED #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3330073",
        "Last Price Paid": "$7.56",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004557",
        "Product Status": "Active"
    },
    {
        "Root": "10007963",
        "LV": "3",
        "Item Description": "SAUV BLC BEYOND BUITEN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007963002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "262178",
        "Last Price Paid": "$7.52",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007963",
        "Product Status": "Active"
    },
    {
        "Root": "10010301",
        "LV": "2",
        "Item Description": "BEER NEGRA MODELO BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010301001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10172",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010301",
        "Product Status": "Active"
    },
    {
        "Root": "10032918",
        "LV": "1",
        "Item Description": "SYRUP BLACKBERRY CONCENTRATE MONIN 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-C006FP",
        "Last Price Paid": "$8.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032918",
        "Product Status": "Active"
    },
    {
        "Root": "10095889",
        "LV": "1",
        "Item Description": "SODA GRPFRT Q MIXER CAN 7.5OZ/24CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9252264",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095889",
        "Product Status": "Active"
    },
    {
        "Root": "10030853",
        "LV": "1",
        "Item Description": "MESCLUN ULTRA PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030853",
        "Product Status": "Active"
    },
    {
        "Root": "10008242",
        "LV": "1",
        "Item Description": "PETITE SIRAH STAGS LEAP WINERY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008242001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9014255",
        "Last Price Paid": "$27.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008242",
        "Product Status": "Active"
    },
    {
        "Root": "10040538",
        "LV": "1",
        "Item Description": "CANDY MALBAN SWEET KASHTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "347330",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040538",
        "Product Status": "Active"
    },
    {
        "Root": "10011536",
        "LV": "1",
        "Item Description": "WASABI MICRO MUSTARD 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MWM-33-E",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011536",
        "Product Status": "Active"
    },
    {
        "Root": "10006320",
        "LV": "1",
        "Item Description": "WRAP LONDON NEWS 12 X 16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7524929",
        "Last Price Paid": "",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006320",
        "Product Status": "Active"
    },
    {
        "Root": "10025595",
        "LV": "1",
        "Item Description": "CHARD DROUHIN MACON 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "669662",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025595",
        "Product Status": "Active"
    },
    {
        "Root": "10002333",
        "LV": "4",
        "Item Description": "CHEESE FRESH BURRATA 4 OZ PORTION 6/2/4 OZ TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002333004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10002333",
        "Product Status": "Active"
    },
    {
        "Root": "10092938",
        "LV": "1",
        "Item Description": "PEA PROTEIN ANTHONY'S POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3N-Y71B-0563",
        "Last Price Paid": "",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092938",
        "Product Status": "Active"
    },
    {
        "Root": "10101405",
        "LV": "1",
        "Item Description": "WAFFLE CONES TOASTED COCONUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "TC-M",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101405",
        "Product Status": "Active"
    },
    {
        "Root": "10085549",
        "LV": "1",
        "Item Description": "BAG PEANUT DUMBO 3.5X2X8 BLEACHED KRAFT 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510257",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085549",
        "Product Status": "Active"
    },
    {
        "Root": "10003189",
        "LV": "1",
        "Item Description": "COCOA POWDER OCG 10-12% 5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003189",
        "Product Status": "Active"
    },
    {
        "Root": "10020059",
        "LV": "1",
        "Item Description": "COOKIE XMAS BASLER BRUNES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "901400",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020059",
        "Product Status": "Active"
    },
    {
        "Root": "10031343",
        "LV": "1",
        "Item Description": "CUP LED LITTLE GREEN MEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "DIS008UN",
        "Last Price Paid": "$4.01",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031343",
        "Product Status": "Active"
    },
    {
        "Root": "10002792",
        "LV": "1",
        "Item Description": "OIL CANOLA FRY N/TRNS CLR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002792001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002792",
        "Product Status": "Active"
    },
    {
        "Root": "10106730",
        "LV": "1",
        "Item Description": "CHOPSTICKS R809 9\" BAMBOO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11054000",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106730",
        "Product Status": "Active"
    },
    {
        "Root": "10027277",
        "LV": "1",
        "Item Description": "CANDY GUMMY BEARS 6/12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891716",
        "Last Price Paid": "$2.84",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027277",
        "Product Status": "Active"
    },
    {
        "Root": "10004908",
        "LV": "1",
        "Item Description": "CRACKER GRAHAM HONEY 4.8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5416755",
        "Last Price Paid": "$0.97",
        "Case Pack": "27",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004908",
        "Product Status": "Active"
    },
    {
        "Root": "10023085",
        "LV": "1",
        "Item Description": "RED BLND J LUC COLOMBO CHAT DU PAPE BARTAVELLE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023085001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "512443",
        "Last Price Paid": "$50.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023085",
        "Product Status": "Active"
    },
    {
        "Root": "10001902",
        "LV": "1",
        "Item Description": "HERB BASIL THAI 1/4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001902001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24020",
        "Last Price Paid": "$7.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001902",
        "Product Status": "Active"
    },
    {
        "Root": "10001902",
        "LV": "1",
        "Item Description": "HERB BASIL THAI 1/4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001902001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450015",
        "Last Price Paid": "$7.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001902",
        "Product Status": "Active"
    },
    {
        "Root": "10002974",
        "LV": "1",
        "Item Description": "TEA GREEN JASMINE 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002974",
        "Product Status": "Active"
    },
    {
        "Root": "10004501",
        "LV": "1",
        "Item Description": "MIX POTATO DUMPLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "505",
        "Last Price Paid": "$52.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004501",
        "Product Status": "Active"
    },
    {
        "Root": "10001317",
        "LV": "1",
        "Item Description": "FRY STEALTH 5/16 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4198990",
        "Last Price Paid": "$6.38",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001317",
        "Product Status": "Active"
    },
    {
        "Root": "10034849",
        "LV": "2",
        "Item Description": "JUST EGGS LIQ FROZEN 12 2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034849002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "91011-00047",
        "Last Price Paid": "$9.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034849",
        "Product Status": "Active"
    },
    {
        "Root": "10003919",
        "LV": "1",
        "Item Description": "SB MILK SOY HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11120112",
        "Last Price Paid": "$4.72",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003919",
        "Product Status": "Active"
    },
    {
        "Root": "10025272",
        "LV": "1",
        "Item Description": "MICRO LATIN LATTITUDES CAHABA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480677",
        "Last Price Paid": "$18.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025272",
        "Product Status": "Active"
    },
    {
        "Root": "10094995",
        "LV": "1",
        "Item Description": "OIL COCONUT TROPICAL GREEN ORG.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "4666",
        "Last Price Paid": "$5.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094995",
        "Product Status": "Active"
    },
    {
        "Root": "10001679",
        "LV": "1",
        "Item Description": "GARLIC JUMBO WHOLE - 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460035",
        "Last Price Paid": "$66.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001679",
        "Product Status": "Active"
    },
    {
        "Root": "10001679",
        "LV": "1",
        "Item Description": "GARLIC JUMBO WHOLE - 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$66.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001679",
        "Product Status": "Active"
    },
    {
        "Root": "10002156",
        "LV": "1",
        "Item Description": "BUTTER PRINT SALTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "877506",
        "Last Price Paid": "$4.23",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002156",
        "Product Status": "Active"
    },
    {
        "Root": "10021673",
        "LV": "2",
        "Item Description": "SAUCE FISH RED BOAT FIRST PRESS 40N 17 OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021673002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SCE000",
        "Last Price Paid": "$16.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021673",
        "Product Status": "Active"
    },
    {
        "Root": "10002693",
        "LV": "1",
        "Item Description": "CEREAL RICE CRISPY BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002693001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.21",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002693",
        "Product Status": "Active"
    },
    {
        "Root": "10044746",
        "LV": "1",
        "Item Description": "MARKER WOOD STEAK MED WELL 3.5IN 5000 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "406559",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044746",
        "Product Status": "Active"
    },
    {
        "Root": "10004265",
        "LV": "1",
        "Item Description": "SPICE MALT VINEGAR POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490118",
        "Last Price Paid": "$16.72",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004265",
        "Product Status": "Active"
    },
    {
        "Root": "10001535",
        "LV": "1",
        "Item Description": "BEAN CANNELLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "57095",
        "Last Price Paid": "$35.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001535",
        "Product Status": "Active"
    },
    {
        "Root": "10001535",
        "LV": "1",
        "Item Description": "BEAN CANNELLINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401040",
        "Last Price Paid": "$35.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001535",
        "Product Status": "Active"
    },
    {
        "Root": "10004286",
        "LV": "1",
        "Item Description": "OLIVES PICHOLINE PITTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-15117-A",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004286",
        "Product Status": "Active"
    },
    {
        "Root": "10006258",
        "LV": "1",
        "Item Description": "BAG FOIL HOT DOG 12 INCH P&R",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3651536",
        "Last Price Paid": "$0.11",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006258",
        "Product Status": "Active"
    },
    {
        "Root": "10040961",
        "LV": "1",
        "Item Description": "SELTZER HARD WHITE CLAW MANGO 16 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "46094",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040961",
        "Product Status": "Active"
    },
    {
        "Root": "10107551",
        "LV": "1",
        "Item Description": "FISH SABLEFISH FRESH WHOLE H/ON 6-8 LBS KYUQOUT SO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107551001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF206",
        "Last Price Paid": "$14.35",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107551",
        "Product Status": "Active"
    },
    {
        "Root": "10020061",
        "LV": "1",
        "Item Description": "COOKIE XMAS BASLER LECKERLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MR212",
        "Last Price Paid": "$99.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020061",
        "Product Status": "Active"
    },
    {
        "Root": "10009998",
        "LV": "1",
        "Item Description": "LIQ CREME DE BANANA LTR BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009998",
        "Product Status": "Active"
    },
    {
        "Root": "10004825",
        "LV": "1",
        "Item Description": "EXTRACT VANILLA MADAGASCAR MADAGASCAR PURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "T902",
        "Last Price Paid": "$80.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004825",
        "Product Status": "Active"
    },
    {
        "Root": "10040906",
        "LV": "1",
        "Item Description": "OATS ROLLED 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040906",
        "Product Status": "Active"
    },
    {
        "Root": "10034698",
        "LV": "1",
        "Item Description": "MICKEY VAMPIRE 2 SQUARE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63215",
        "Last Price Paid": "$0.75",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034698",
        "Product Status": "Active"
    },
    {
        "Root": "10009767",
        "LV": "1",
        "Item Description": "GIN NOLETS SLVR DRY 95.2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300490",
        "Last Price Paid": "$32.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009767",
        "Product Status": "Active"
    },
    {
        "Root": "10088422",
        "LV": "1",
        "Item Description": "RICE CRISPY ASTROID BALLS STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088422001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "ABALL",
        "Last Price Paid": "$0.78",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088422",
        "Product Status": "Active"
    },
    {
        "Root": "10003499",
        "LV": "2",
        "Item Description": "TEA EARL GRY RUSSN 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003499001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3501",
        "Last Price Paid": "$34.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003499",
        "Product Status": "Active"
    },
    {
        "Root": "10000745",
        "LV": "1",
        "Item Description": "CHARGE ER/LATE ORDERS NONEXPENSED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "9700028",
        "Last Price Paid": "$350.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000745",
        "Product Status": "Active"
    },
    {
        "Root": "10012220",
        "LV": "2",
        "Item Description": "PROSECCO NINO FRANCO RUSTICO DI VALDOBBIADENE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012220001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.90",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10012220",
        "Product Status": "Active"
    },
    {
        "Root": "10017010",
        "LV": "1",
        "Item Description": "SALAME NAPOLI SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6049",
        "Last Price Paid": "$80.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017010",
        "Product Status": "Active"
    },
    {
        "Root": "10014386",
        "LV": "1",
        "Item Description": "HERB THAI LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014386001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482249",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014386",
        "Product Status": "Active"
    },
    {
        "Root": "10034579",
        "LV": "1",
        "Item Description": "ROCK SALT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034579001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SAL925",
        "Last Price Paid": "$6.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034579",
        "Product Status": "Active"
    },
    {
        "Root": "10007123",
        "LV": "2",
        "Item Description": "PINOTAGE PAINTED WOLF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007123001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "PAIWOLPTAGE",
        "Last Price Paid": "$8.77",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007123",
        "Product Status": "Active"
    },
    {
        "Root": "10034639",
        "LV": "1",
        "Item Description": "TUMBLER FROST FLEX PF12 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.17",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034639",
        "Product Status": "Active"
    },
    {
        "Root": "10017198",
        "LV": "1",
        "Item Description": "LETTUCE MESCLUN MIX LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017198001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "597012",
        "Last Price Paid": "$12.73",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017198",
        "Product Status": "Active"
    },
    {
        "Root": "10010150",
        "LV": "2",
        "Item Description": "WATER VOSS SPRKLNG 800ML 12EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010150001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2420321",
        "Last Price Paid": "$2.59",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010150",
        "Product Status": "Active"
    },
    {
        "Root": "10001958",
        "LV": "1",
        "Item Description": "CITRUS MARIGOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05CM-1",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001958",
        "Product Status": "Active"
    },
    {
        "Root": "10017012",
        "LV": "1",
        "Item Description": "BAG SANDWICH DRY WAX 6 X.75X6.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008113",
        "Last Price Paid": "$0.02",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017012",
        "Product Status": "Active"
    },
    {
        "Root": "10033537",
        "LV": "1",
        "Item Description": "SAUCE BERBERE MARINADE 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "EBM5G",
        "Last Price Paid": "$222.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033537",
        "Product Status": "Active"
    },
    {
        "Root": "10004930",
        "LV": "1",
        "Item Description": "PUREE MANDARIN 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "550...{BOIRON{",
        "Last Price Paid": "$11.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004930",
        "Product Status": "Active"
    },
    {
        "Root": "10104432",
        "LV": "1",
        "Item Description": "BEER DRAFT PARISH SOUTH COAST AMBER 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15822",
        "Last Price Paid": "$204.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104432",
        "Product Status": "Active"
    },
    {
        "Root": "10002722",
        "LV": "1",
        "Item Description": "PASTA DRY ELBOW MACARONI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002722",
        "Product Status": "Active"
    },
    {
        "Root": "10027740",
        "LV": "1",
        "Item Description": "POWDER STRAWBERRY FINE FREEZE DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U30038",
        "Last Price Paid": "$32.13",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027740",
        "Product Status": "Active"
    },
    {
        "Root": "10009812",
        "LV": "1",
        "Item Description": "RUM DON Q COCONUT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "107302",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009812",
        "Product Status": "Active"
    },
    {
        "Root": "10004572",
        "LV": "1",
        "Item Description": "GRAPEFRUIT SECTIONS 46Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3333689",
        "Last Price Paid": "$4.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004572",
        "Product Status": "Active"
    },
    {
        "Root": "10004306",
        "LV": "1",
        "Item Description": "BAR PEANUT BTR DK CHOCOLATE KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "303560",
        "Last Price Paid": "$2.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004306",
        "Product Status": "Active"
    },
    {
        "Root": "10091621",
        "LV": "1",
        "Item Description": "FIGS DRY UNIT 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091621",
        "Product Status": "Active"
    },
    {
        "Root": "10016144",
        "LV": "1",
        "Item Description": "FLOUR ALMOND BLANCHE FINE 25# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ1195809",
        "Last Price Paid": "$110.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016144",
        "Product Status": "Active"
    },
    {
        "Root": "10035268",
        "LV": "1",
        "Item Description": "CHIPOTLE MORITA DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035268",
        "Product Status": "Active"
    },
    {
        "Root": "10036204",
        "LV": "1",
        "Item Description": "COCOA BUTTER ATLANTIC BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036204001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302080C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036204",
        "Product Status": "Active"
    },
    {
        "Root": "10016458",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10016",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016458",
        "Product Status": "Active"
    },
    {
        "Root": "10016562",
        "LV": "2",
        "Item Description": "JUICE YUZU 3.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016562002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO376",
        "Last Price Paid": "$21.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016562",
        "Product Status": "Active"
    },
    {
        "Root": "10000310",
        "LV": "1",
        "Item Description": "SORREL LUCKY PETITE PLUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PPLS-33",
        "Last Price Paid": "$0.65",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000310",
        "Product Status": "Active"
    },
    {
        "Root": "10044300",
        "LV": "1",
        "Item Description": "PECAN PIECES MEDIUM FANCY RAW SHELL OFF 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8349474",
        "Last Price Paid": "$48.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044300",
        "Product Status": "Active"
    },
    {
        "Root": "10001555",
        "LV": "1",
        "Item Description": "HERB PARSLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598085",
        "Last Price Paid": "$1.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001555",
        "Product Status": "Active"
    },
    {
        "Root": "10014746",
        "LV": "2",
        "Item Description": "PEPPER RED DICED 1\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014746002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514519",
        "Last Price Paid": "$18.98",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014746",
        "Product Status": "Active"
    },
    {
        "Root": "10004671",
        "LV": "1",
        "Item Description": "DRIED FRUIT PRUNE PITTED/DRY 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "283950",
        "Last Price Paid": "$101.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004671",
        "Product Status": "Active"
    },
    {
        "Root": "10009907",
        "LV": "1",
        "Item Description": "LIQ GRAND MARNIER 100 YR CENTENAIRE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557430",
        "Last Price Paid": "$156.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009907",
        "Product Status": "Active"
    },
    {
        "Root": "10041071",
        "LV": "1",
        "Item Description": "CHEESE HARD PECORINO TOSCANO DOP SHEEP ITALY 2/5 L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041071001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "12504",
        "Last Price Paid": "$14.83",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041071",
        "Product Status": "Active"
    },
    {
        "Root": "10000393",
        "LV": "1",
        "Item Description": "LETTUCE 50/50 SHRED 3/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515565",
        "Last Price Paid": "$5.03",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000393",
        "Product Status": "Active"
    },
    {
        "Root": "10000393",
        "LV": "1",
        "Item Description": "LETTUCE 50/50 SHRED 3/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-004-4",
        "Last Price Paid": "$5.03",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000393",
        "Product Status": "Active"
    },
    {
        "Root": "10000118",
        "LV": "1",
        "Item Description": "SAUSAGE DEBRIZINGER 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000118001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "124A",
        "Last Price Paid": "$4.88",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000118",
        "Product Status": "Active"
    },
    {
        "Root": "10001459",
        "LV": "1",
        "Item Description": "LIME FINGER .4OZ EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001459001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408411",
        "Last Price Paid": "$30.92",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001459",
        "Product Status": "Active"
    },
    {
        "Root": "10001630",
        "LV": "1",
        "Item Description": "TOMATO SUNDRIED STRIPS 2/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7355092",
        "Last Price Paid": "$11.04",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001630",
        "Product Status": "Active"
    },
    {
        "Root": "10038921",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE 1 INCH DISC ART REQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "1423CP",
        "Last Price Paid": "$0.22",
        "Case Pack": "684",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038921",
        "Product Status": "Active"
    },
    {
        "Root": "10033948",
        "LV": "1",
        "Item Description": "SB TEAVANA PEACH TRANQUIL FL FB CS 100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11087956",
        "Last Price Paid": "$0.26",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033948",
        "Product Status": "Active"
    },
    {
        "Root": "10009484",
        "LV": "2",
        "Item Description": "WHISKY TULLAMORE DEW LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009484001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9088152",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009484",
        "Product Status": "Active"
    },
    {
        "Root": "10035504",
        "LV": "1",
        "Item Description": "LOBSTER TAIL CW CAND/MA 4 OZ 1/10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035504001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6474399",
        "Last Price Paid": "$25.38",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035504",
        "Product Status": "Active"
    },
    {
        "Root": "10088165",
        "LV": "1",
        "Item Description": "BEER DRAFT IVANHOE  HAZEL NUTTY-CRACKER IMPERIAL S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088165001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99928",
        "Last Price Paid": "$202.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088165",
        "Product Status": "Active"
    },
    {
        "Root": "10001977",
        "LV": "1",
        "Item Description": "RADISH FRENCH BRKFST PETI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01FR-33",
        "Last Price Paid": "$34.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001977",
        "Product Status": "Active"
    },
    {
        "Root": "10037813",
        "LV": "2",
        "Item Description": "CUP COLD 21OZ GALAXYS EDGE RNP21 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037813001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$69.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10037813",
        "Product Status": "Active"
    },
    {
        "Root": "10091307",
        "LV": "1",
        "Item Description": "WINE WHITE MAN FAMILY SAUVIGNON BLANC 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2775245",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091307",
        "Product Status": "Active"
    },
    {
        "Root": "10009500",
        "LV": "5",
        "Item Description": "BOURBON ANGELS ENVY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009500001005",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "005",
        "Long SV": "001",
        "NALA": "10009500",
        "Product Status": "Active"
    },
    {
        "Root": "10036992",
        "LV": "1",
        "Item Description": "CHERRIES COCKTAIL TRAVERSE CITY 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "903814",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036992",
        "Product Status": "Active"
    },
    {
        "Root": "10088121",
        "LV": "1",
        "Item Description": "BASS STRIPPED HYBRID WHOLE SCALED FINS OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088121001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088121",
        "Product Status": "Active"
    },
    {
        "Root": "10001149",
        "LV": "1",
        "Item Description": "TROUT TREATS IQF 20 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6431450",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001149",
        "Product Status": "Active"
    },
    {
        "Root": "10029547",
        "LV": "1",
        "Item Description": "LENOTTI AMARONE DELLA VALPOLICELLA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029547",
        "Product Status": "Active"
    },
    {
        "Root": "10085818",
        "LV": "1",
        "Item Description": "BAG POPCORN ECO-CRAFT 46 OZ RED STRIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "8132",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085818",
        "Product Status": "Active"
    },
    {
        "Root": "10030345",
        "LV": "1",
        "Item Description": "SB TOASTED WHITE MOCHA SAUCE 64OZ 4-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11081715",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030345",
        "Product Status": "Active"
    },
    {
        "Root": "10033031",
        "LV": "1",
        "Item Description": "TALE AS OLD AS TIME 1 WHT SQUARES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW24042",
        "Last Price Paid": "",
        "Case Pack": "1120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033031",
        "Product Status": "Active"
    },
    {
        "Root": "10003064",
        "LV": "1",
        "Item Description": "WATER VITAMIN ENERGY 20Z 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003064",
        "Product Status": "Active"
    },
    {
        "Root": "10037014",
        "LV": "1",
        "Item Description": "TEMPRANILLO CVNE REAL GRAN RESERVA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037014001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9219225",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037014",
        "Product Status": "Active"
    },
    {
        "Root": "10004811",
        "LV": "1",
        "Item Description": "SPICE SALT SHAKER DISPOSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5492673",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004811",
        "Product Status": "Active"
    },
    {
        "Root": "10000253",
        "LV": "1",
        "Item Description": "CARROT SPIRAL CUT 2/3 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000253001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512060",
        "Last Price Paid": "$11.40",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000253",
        "Product Status": "Active"
    },
    {
        "Root": "10001921",
        "LV": "1",
        "Item Description": "CRANBERRIES 12OZ 24EA/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250020",
        "Last Price Paid": "$1.93",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001921",
        "Product Status": "Active"
    },
    {
        "Root": "10001921",
        "LV": "1",
        "Item Description": "CRANBERRIES 12OZ 24EA/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.93",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001921",
        "Product Status": "Active"
    },
    {
        "Root": "10009982",
        "LV": "1",
        "Item Description": "LIQ SCHNAPPS PEACH DEKUYPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "25213",
        "Last Price Paid": "$9.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009982",
        "Product Status": "Active"
    },
    {
        "Root": "10086211",
        "LV": "2",
        "Item Description": "POPCORN FRUITY KETTLE CORN 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086211002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "FRUITYKETTLE",
        "Last Price Paid": "$3.32",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10086211",
        "Product Status": "Active"
    },
    {
        "Root": "10031049",
        "LV": "3",
        "Item Description": "GARLIC FRIED' P.PRATTE'",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031049002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35255",
        "Last Price Paid": "$23.49",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10031049",
        "Product Status": "Active"
    },
    {
        "Root": "10002681",
        "LV": "1",
        "Item Description": "TOMATO PASTE 6/111 POUCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002681001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002681",
        "Product Status": "Active"
    },
    {
        "Root": "10015130",
        "LV": "2",
        "Item Description": "TOMATO POMA ROSA W/BASIL #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015130002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "PT12",
        "Last Price Paid": "$6.18",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015130",
        "Product Status": "Active"
    },
    {
        "Root": "10103493",
        "LV": "1",
        "Item Description": "BEER KEG COPPERTAIL GINGERBREAD STOUT 1/2BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "58912",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103493",
        "Product Status": "Active"
    },
    {
        "Root": "10082068",
        "LV": "1",
        "Item Description": "RICE DREAMS 64OZ MILK ALTERNATIVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "M92230",
        "Last Price Paid": "$6.71",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082068",
        "Product Status": "Active"
    },
    {
        "Root": "10004910",
        "LV": "1",
        "Item Description": "SAUCE SOY WF TAMARI 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004910001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "525568",
        "Last Price Paid": "$7.68",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004910",
        "Product Status": "Active"
    },
    {
        "Root": "10000197",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000197001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000197",
        "Product Status": "Active"
    },
    {
        "Root": "10005646",
        "LV": "2",
        "Item Description": "SHELL TART CHOCOLATE RECTANGLE LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005646001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8090399",
        "Last Price Paid": "$0.93",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005646",
        "Product Status": "Active"
    },
    {
        "Root": "10082072",
        "LV": "1",
        "Item Description": "WINE SPARKLING DOMAINE CARNEROS ROSE 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273095",
        "Last Price Paid": "$24.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082072",
        "Product Status": "Active"
    },
    {
        "Root": "10082072",
        "LV": "1",
        "Item Description": "WINE SPARKLING DOMAINE CARNEROS ROSE 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "273095",
        "Last Price Paid": "$24.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082072",
        "Product Status": "Active"
    },
    {
        "Root": "10035408",
        "LV": "1",
        "Item Description": "POTSTICKER CHICKEN WYLIE FOODS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.53",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035408",
        "Product Status": "Active"
    },
    {
        "Root": "10007442",
        "LV": "1",
        "Item Description": "SAUV BLC WATERBROOK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9030995",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007442",
        "Product Status": "Active"
    },
    {
        "Root": "10032480",
        "LV": "1",
        "Item Description": "POWDER LUSTER SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860360000",
        "Last Price Paid": "$28.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032480",
        "Product Status": "Active"
    },
    {
        "Root": "10018737",
        "LV": "1",
        "Item Description": "CHOC WHT MINNIE BOW 2D RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018737001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6230220000",
        "Last Price Paid": "$0.38",
        "Case Pack": "480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018737",
        "Product Status": "Active"
    },
    {
        "Root": "10031612",
        "LV": "1",
        "Item Description": "OLLI CALABRESE SALAMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "77733",
        "Last Price Paid": "$7.16",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031612",
        "Product Status": "Active"
    },
    {
        "Root": "10000600",
        "LV": "2",
        "Item Description": "VEAL OSSO BUCCO 2 INCH CENTERCUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000600002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "01146025",
        "Last Price Paid": "$15.20",
        "Case Pack": "14",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000600",
        "Product Status": "Active"
    },
    {
        "Root": "10002042",
        "LV": "1",
        "Item Description": "LETTUCE BABY OAK RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.74",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002042",
        "Product Status": "Active"
    },
    {
        "Root": "10002042",
        "LV": "1",
        "Item Description": "LETTUCE BABY OAK RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480070",
        "Last Price Paid": "$0.74",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002042",
        "Product Status": "Active"
    },
    {
        "Root": "10004316",
        "LV": "1",
        "Item Description": "HORS BEEF W/BLU CHS & BAC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300304",
        "Last Price Paid": "$1.07",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004316",
        "Product Status": "Active"
    },
    {
        "Root": "10109531",
        "LV": "1",
        "Item Description": "CHICKEN NECKS BELL AND EVANS 40 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109531001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CHI501",
        "Last Price Paid": "$1.15",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109531",
        "Product Status": "Active"
    },
    {
        "Root": "10016520",
        "LV": "1",
        "Item Description": "CHOC DARK DOME SPRHE 2.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2112",
        "Last Price Paid": "$1.15",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016520",
        "Product Status": "Active"
    },
    {
        "Root": "10003926",
        "LV": "1",
        "Item Description": "SB SYRUP PEPPERMINT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108262",
        "Last Price Paid": "$5.46",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003926",
        "Product Status": "Active"
    },
    {
        "Root": "10044731",
        "LV": "1",
        "Item Description": "SPICE CORIANDER SEED WHOLE 10OZ PLASTIC BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2469864",
        "Last Price Paid": "$20.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044731",
        "Product Status": "Active"
    },
    {
        "Root": "10002488",
        "LV": "1",
        "Item Description": "HORSERADISH PREPARED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4081980",
        "Last Price Paid": "$11.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002488",
        "Product Status": "Active"
    },
    {
        "Root": "10004143",
        "LV": "1",
        "Item Description": "TRUFFLE COOKIES CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.08",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004143",
        "Product Status": "Active"
    },
    {
        "Root": "10108290",
        "LV": "1",
        "Item Description": "CLUB 33 CURATED WINE BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "WB3-PLYWOOD-33",
        "Last Price Paid": "$450.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108290",
        "Product Status": "Active"
    },
    {
        "Root": "10005880",
        "LV": "1",
        "Item Description": "DONUTS CINNAMON SUGAR GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "2230",
        "Last Price Paid": "$4.15",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005880",
        "Product Status": "Active"
    },
    {
        "Root": "10035513",
        "LV": "1",
        "Item Description": "CHOCOLATE CARAMEL 30.4%CALLEBAUT 4/5.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035513001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C30GOLD",
        "Last Price Paid": "$40.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035513",
        "Product Status": "Active"
    },
    {
        "Root": "10017922",
        "LV": "1",
        "Item Description": "ROE SEA URCHIN UNI WEST COAST 300G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "59-04010",
        "Last Price Paid": "$25.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017922",
        "Product Status": "Active"
    },
    {
        "Root": "10088660",
        "LV": "1",
        "Item Description": "BEER KEG TANK PROST 15.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088660001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "54484",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088660",
        "Product Status": "Active"
    },
    {
        "Root": "10010460",
        "LV": "1",
        "Item Description": "AVIVE GRAPEFRUIT NV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "228864",
        "Last Price Paid": "$7.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010460",
        "Product Status": "Active"
    },
    {
        "Root": "10006372",
        "LV": "1",
        "Item Description": "BUTCHER TWINE 2.5 LB/CONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5971247",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006372",
        "Product Status": "Active"
    },
    {
        "Root": "10040500",
        "LV": "1",
        "Item Description": "SAUCE BBQ CAROLINA VINEGAR 1 GAL 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1345643",
        "Last Price Paid": "$20.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040500",
        "Product Status": "Active"
    },
    {
        "Root": "10002320",
        "LV": "1",
        "Item Description": "SPREAD SOY FREE EARTH BAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "544163",
        "Last Price Paid": "$4.91",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002320",
        "Product Status": "Active"
    },
    {
        "Root": "10007395",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC BENZIGER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "468626",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007395",
        "Product Status": "Active"
    },
    {
        "Root": "10036198",
        "LV": "3",
        "Item Description": "COOKIES BANTHA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036198003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "BANTHA",
        "Last Price Paid": "$1.92",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10036198",
        "Product Status": "Active"
    },
    {
        "Root": "10009860",
        "LV": "1",
        "Item Description": "PORT SANDEMAN FOUNDERS RES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009860",
        "Product Status": "Active"
    },
    {
        "Root": "10044401",
        "LV": "1",
        "Item Description": "SPICE TURMERIC GROUND SKAER BOTTLE 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044401001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760769",
        "Last Price Paid": "$12.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044401",
        "Product Status": "Active"
    },
    {
        "Root": "10008834",
        "LV": "2",
        "Item Description": "MERLOT LA JOTA VNYD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008834001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "531783",
        "Last Price Paid": "$75.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008834",
        "Product Status": "Active"
    },
    {
        "Root": "10044739",
        "LV": "1",
        "Item Description": "SPICE GINGER GROUND 15OZ PLASTIC SHAKER BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760306",
        "Last Price Paid": "$16.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044739",
        "Product Status": "Active"
    },
    {
        "Root": "10018525",
        "LV": "1",
        "Item Description": "GEL COLOR ROSE PINK PREM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22495",
        "Last Price Paid": "$5.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018525",
        "Product Status": "Active"
    },
    {
        "Root": "10018570",
        "LV": "1",
        "Item Description": "GEL PINK NEON BRIGHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22496",
        "Last Price Paid": "$6.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018570",
        "Product Status": "Active"
    },
    {
        "Root": "10034872",
        "LV": "1",
        "Item Description": "LEIS FLOWER SILK SOLID COLOR ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.24",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034872",
        "Product Status": "Active"
    },
    {
        "Root": "10100269",
        "LV": "1",
        "Item Description": "KOSHER BSC APPLE PIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.26",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100269",
        "Product Status": "Active"
    },
    {
        "Root": "10018289",
        "LV": "1",
        "Item Description": "SPICE PEPPERCORN MELANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018289001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018289",
        "Product Status": "Active"
    },
    {
        "Root": "10031825",
        "LV": "1",
        "Item Description": "AGAR AGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672146",
        "Last Price Paid": "$41.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031825",
        "Product Status": "Active"
    },
    {
        "Root": "10031301",
        "LV": "1",
        "Item Description": "PEARLS MANGO BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031301001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MGPOPP",
        "Last Price Paid": "$34.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031301",
        "Product Status": "Active"
    },
    {
        "Root": "10003318",
        "LV": "1",
        "Item Description": "SYRUP 2.5 CHERRY COKE BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "103888",
        "Last Price Paid": "$51.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003318",
        "Product Status": "Active"
    },
    {
        "Root": "10107342",
        "LV": "1",
        "Item Description": "BEER MOTORWORKS MIDNIGHT ESPRESSO COFFEE PORTER 15",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "41102",
        "Last Price Paid": "$200.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107342",
        "Product Status": "Active"
    },
    {
        "Root": "10013740",
        "LV": "1",
        "Item Description": "CHUTNEY APPLE 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$218.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013740",
        "Product Status": "Active"
    },
    {
        "Root": "10017858",
        "LV": "1",
        "Item Description": "CAB SAUV OPUS ONE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "510176",
        "Last Price Paid": "$323.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017858",
        "Product Status": "Active"
    },
    {
        "Root": "10001139",
        "LV": "2",
        "Item Description": "NORI GOLD SHEETS 10/50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001139002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6480008",
        "Last Price Paid": "$7.07",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001139",
        "Product Status": "Active"
    },
    {
        "Root": "10014400",
        "LV": "1",
        "Item Description": "SALMON VERLASSO WHOLE SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014400001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6485305",
        "Last Price Paid": "$5.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014400",
        "Product Status": "Active"
    },
    {
        "Root": "10007761",
        "LV": "1",
        "Item Description": "CHAMP VEUVE CLIQ ROSE 750ML NV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "130560",
        "Last Price Paid": "$57.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007761",
        "Product Status": "Active"
    },
    {
        "Root": "10007761",
        "LV": "1",
        "Item Description": "CHAMP VEUVE CLIQ ROSE 750ML NV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "130560",
        "Last Price Paid": "$57.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007761",
        "Product Status": "Active"
    },
    {
        "Root": "10006018",
        "LV": "1",
        "Item Description": "LID PLATE 9 INCH GP PAPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006018001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.19",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006018",
        "Product Status": "Active"
    },
    {
        "Root": "10095635",
        "LV": "1",
        "Item Description": "WINE CHARD WINE CHARD CHANZY BLANC MERCUREY 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "577746",
        "Last Price Paid": "$39.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095635",
        "Product Status": "Active"
    },
    {
        "Root": "10037627",
        "LV": "1",
        "Item Description": "MOBILE ORDER 2.25\" ROUND SEMI-GLOSS 1C LABEL DL0",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45003876",
        "Last Price Paid": "$0.04",
        "Case Pack": "8000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037627",
        "Product Status": "Active"
    },
    {
        "Root": "10016252",
        "LV": "1",
        "Item Description": "PIE PUMPKIN 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "832",
        "Last Price Paid": "$15.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016252",
        "Product Status": "Active"
    },
    {
        "Root": "10100428",
        "LV": "1",
        "Item Description": "PUREE GUAVA FROZEN 1 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6550260000",
        "Last Price Paid": "$14.19",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100428",
        "Product Status": "Active"
    },
    {
        "Root": "10027603",
        "LV": "1",
        "Item Description": "CANDY CARAMEL EASTER EGG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "3151",
        "Last Price Paid": "$0.30",
        "Case Pack": "267",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027603",
        "Product Status": "Active"
    },
    {
        "Root": "10038883",
        "LV": "1",
        "Item Description": "PLATE BAGASSE SQUARE SUGARCANE 6X6 BE-FSP6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11004875",
        "Last Price Paid": "$0.11",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038883",
        "Product Status": "Active"
    },
    {
        "Root": "10107893",
        "LV": "1",
        "Item Description": "YOGURT PARFAIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "852872006419",
        "Last Price Paid": "$5.50",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107893",
        "Product Status": "Active"
    },
    {
        "Root": "10084284",
        "LV": "1",
        "Item Description": "SAUSAGE VEGAN BREAKFAST IMPOSSIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "6000022",
        "Last Price Paid": "$0.72",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084284",
        "Product Status": "Active"
    },
    {
        "Root": "10006122",
        "LV": "1",
        "Item Description": "CUP 5Z PLAS CHAMP FLUTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.49",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006122",
        "Product Status": "Active"
    },
    {
        "Root": "10011330",
        "LV": "1",
        "Item Description": "BASIL SAMPLER SMALL BITES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14SBBS-33-R",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011330",
        "Product Status": "Active"
    },
    {
        "Root": "10040084",
        "LV": "1",
        "Item Description": "ICE CREAM DULCE DE LECHE HGDZ 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040084",
        "Product Status": "Active"
    },
    {
        "Root": "10040084",
        "LV": "1",
        "Item Description": "ICE CREAM DULCE DE LECHE HGDZ 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12197087",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040084",
        "Product Status": "Active"
    },
    {
        "Root": "10007372",
        "LV": "1",
        "Item Description": "CAB SAUV DECOY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597600",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007372",
        "Product Status": "Active"
    },
    {
        "Root": "10106791",
        "LV": "3",
        "Item Description": "BREADSTICK PLAIN 8 INCH 150CT/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106791002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "02-276",
        "Last Price Paid": "$0.17",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10106791",
        "Product Status": "Active"
    },
    {
        "Root": "10007118",
        "LV": "1",
        "Item Description": "CAB SAUV SILVERADO SOLO RSV 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007118001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "581244",
        "Last Price Paid": "$85.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007118",
        "Product Status": "Active"
    },
    {
        "Root": "10003008",
        "LV": "1",
        "Item Description": "DRESSING ITALIAN LOW CAL 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003008",
        "Product Status": "Active"
    },
    {
        "Root": "10002986",
        "LV": "2",
        "Item Description": "PESTO BASIL W/O NUT 2/30Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002986002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3599404",
        "Last Price Paid": "$13.61",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002986",
        "Product Status": "Active"
    },
    {
        "Root": "10019772",
        "LV": "1",
        "Item Description": "BLND RED FESS PARKER BIG EASY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9155523",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019772",
        "Product Status": "Active"
    },
    {
        "Root": "10004649",
        "LV": "1",
        "Item Description": "PASTA DRY SHELLS MED KOSH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6327662",
        "Last Price Paid": "$13.90",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004649",
        "Product Status": "Active"
    },
    {
        "Root": "10009578",
        "LV": "1",
        "Item Description": "VODKA KETEL ONE 80 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "77629",
        "Last Price Paid": "$12.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009578",
        "Product Status": "Active"
    },
    {
        "Root": "10001663",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA 40#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001663",
        "Product Status": "Active"
    },
    {
        "Root": "10001663",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA 40#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "402035",
        "Last Price Paid": "$33.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001663",
        "Product Status": "Active"
    },
    {
        "Root": "10033230",
        "LV": "1",
        "Item Description": "COLOR GEL PASTE ELECTRIC BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC ELECTRIC BL",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033230",
        "Product Status": "Active"
    },
    {
        "Root": "10001821",
        "LV": "1",
        "Item Description": "MUSHROOM CHANTERELLE YELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001821001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001821",
        "Product Status": "Active"
    },
    {
        "Root": "10001821",
        "LV": "1",
        "Item Description": "MUSHROOM CHANTERELLE YELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001821001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50111",
        "Last Price Paid": "$22.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001821",
        "Product Status": "Active"
    },
    {
        "Root": "10033536",
        "LV": "1",
        "Item Description": "CORIANDER CHUTNEY DHANIA 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "DCC5G",
        "Last Price Paid": "$217.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033536",
        "Product Status": "Active"
    },
    {
        "Root": "10042860",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE CONGRATS MICKEY EAR SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330CP1",
        "Last Price Paid": "$2.79",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042860",
        "Product Status": "Active"
    },
    {
        "Root": "10000278",
        "LV": "1",
        "Item Description": "BEETS RED 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490144",
        "Last Price Paid": "$14.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000278",
        "Product Status": "Active"
    },
    {
        "Root": "10004266",
        "LV": "1",
        "Item Description": "SPICE SORGHUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490117",
        "Last Price Paid": "$6.66",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004266",
        "Product Status": "Active"
    },
    {
        "Root": "10004761",
        "LV": "2",
        "Item Description": "YEAST DRY INSTANT 20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004761001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8009656",
        "Last Price Paid": "$4.37",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004761",
        "Product Status": "Active"
    },
    {
        "Root": "10017346",
        "LV": "1",
        "Item Description": "WILLHELM KABINETT 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "264499",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017346",
        "Product Status": "Active"
    },
    {
        "Root": "10004465",
        "LV": "1",
        "Item Description": "MIX FREESTYLE SEAGRAM/DIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004465001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7025797",
        "Last Price Paid": "$64.52",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004465",
        "Product Status": "Active"
    },
    {
        "Root": "10107913",
        "LV": "1",
        "Item Description": "JAURE 700ML STILL WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "JAU1",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107913",
        "Product Status": "Active"
    },
    {
        "Root": "10040697",
        "LV": "1",
        "Item Description": "CHEESE NUT SMOKED FARMHOUSE MIYOKOS VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040697001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "177404",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040697",
        "Product Status": "Active"
    },
    {
        "Root": "10017306",
        "LV": "1",
        "Item Description": "PEPPER BANANA RING BREADED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6976344",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017306",
        "Product Status": "Active"
    },
    {
        "Root": "10010376",
        "LV": "2",
        "Item Description": "SPATEN OPTIMATOR 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010376001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "895026",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010376",
        "Product Status": "Active"
    },
    {
        "Root": "10110630",
        "LV": "1",
        "Item Description": "CIDER KEG KEEL FARMS PUMPKIN 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "56507",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110630",
        "Product Status": "Active"
    },
    {
        "Root": "10008888",
        "LV": "1",
        "Item Description": "CHARD BOSCHEN ELGIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "64611",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008888",
        "Product Status": "Active"
    },
    {
        "Root": "10001935",
        "LV": "1",
        "Item Description": "PEAR HALVES PEELED CORED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-033C",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001935",
        "Product Status": "Active"
    },
    {
        "Root": "10028203",
        "LV": "1",
        "Item Description": "COTTAGE CHEESE BLUEBRY ACAI GOOD CULTURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "173820",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028203",
        "Product Status": "Active"
    },
    {
        "Root": "10088347",
        "LV": "1",
        "Item Description": "LID CONTAINER TRANSLUCENT VENTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51095316",
        "Last Price Paid": "",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088347",
        "Product Status": "Active"
    },
    {
        "Root": "10036596",
        "LV": "1",
        "Item Description": "ROSE SELTZ DECOY BPACK 250ML 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "990912",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036596",
        "Product Status": "Active"
    },
    {
        "Root": "10004107",
        "LV": "1",
        "Item Description": "CANDY MILKY WAY 36/18.4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004107001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534322",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004107",
        "Product Status": "Active"
    },
    {
        "Root": "10038971",
        "LV": "1",
        "Item Description": "SYRUP BREAKFAST SUGAR FREE 14.5OZ 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038971",
        "Product Status": "Active"
    },
    {
        "Root": "10009900",
        "LV": "1",
        "Item Description": "LIQ GINGER CANTON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "374480",
        "Last Price Paid": "$29.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009900",
        "Product Status": "Active"
    },
    {
        "Root": "10004715",
        "LV": "1",
        "Item Description": "CHIP POTATO RUFFLES REG B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5907282",
        "Last Price Paid": "$3.34",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004715",
        "Product Status": "Active"
    },
    {
        "Root": "10000680",
        "LV": "1",
        "Item Description": "LAMB LOIN TNDRS AUS 2-3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000680001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240404",
        "Last Price Paid": "$9.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000680",
        "Product Status": "Active"
    },
    {
        "Root": "10107336",
        "LV": "1",
        "Item Description": "WHISKY KURVBALL BBQ 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "561176",
        "Last Price Paid": "$18.74",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107336",
        "Product Status": "Active"
    },
    {
        "Root": "10009541",
        "LV": "1",
        "Item Description": "VODKA GREY GOOSE 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009541",
        "Product Status": "Active"
    },
    {
        "Root": "10014732",
        "LV": "1",
        "Item Description": "PINOT NOIR GUADLP KEN WRGHT GUADALUPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9637276",
        "Last Price Paid": "$52.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014732",
        "Product Status": "Active"
    },
    {
        "Root": "10012456",
        "LV": "1",
        "Item Description": "DEC HYDRANGEA SINGLES 1\" -1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8985A",
        "Last Price Paid": "$0.51",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012456",
        "Product Status": "Active"
    },
    {
        "Root": "10004643",
        "LV": "1",
        "Item Description": "FRUIT ROLL UP CRAZY COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004643001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3526159",
        "Last Price Paid": "$0.31",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004643",
        "Product Status": "Active"
    },
    {
        "Root": "10001288",
        "LV": "1",
        "Item Description": "JUICE MM LEMONADE RASPBERRY 4/90OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "2500006454",
        "Last Price Paid": "$15.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001288",
        "Product Status": "Active"
    },
    {
        "Root": "10001288",
        "LV": "1",
        "Item Description": "JUICE MM LEMONADE RASPBERRY 4/90OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001288",
        "Product Status": "Active"
    },
    {
        "Root": "10001288",
        "LV": "1",
        "Item Description": "JUICE MM LEMONADE RASPBERRY 4/90OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006454",
        "Last Price Paid": "$15.46",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001288",
        "Product Status": "Active"
    },
    {
        "Root": "10014048",
        "LV": "2",
        "Item Description": "CHOC CHIP SEMI SWT 4000 CT GHIRADELLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014048002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9680015",
        "Last Price Paid": "$138.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014048",
        "Product Status": "Active"
    },
    {
        "Root": "10029824",
        "LV": "1",
        "Item Description": "PEPPERS FRESNO 3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490123",
        "Last Price Paid": "$8.85",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029824",
        "Product Status": "Active"
    },
    {
        "Root": "10025432",
        "LV": "2",
        "Item Description": "COLOR AIRBRUSH CLEANAIRE CLEANER 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025432002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9623",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025432",
        "Product Status": "Active"
    },
    {
        "Root": "10002549",
        "LV": "1",
        "Item Description": "PEA ENGLISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002549",
        "Product Status": "Active"
    },
    {
        "Root": "10002549",
        "LV": "1",
        "Item Description": "PEA ENGLISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401270",
        "Last Price Paid": "$44.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002549",
        "Product Status": "Active"
    },
    {
        "Root": "10002015",
        "LV": "2",
        "Item Description": "PAPAYA GREEN ASIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002015001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16152",
        "Last Price Paid": "$0.74",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002015",
        "Product Status": "Active"
    },
    {
        "Root": "10038455",
        "LV": "2",
        "Item Description": "GIFT BOX W/BAND COMBO GANACHERY 15.2CMX14.6 16CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038455002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091144",
        "Last Price Paid": "$2.94",
        "Case Pack": "56",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038455",
        "Product Status": "Active"
    },
    {
        "Root": "10030843",
        "LV": "1",
        "Item Description": "POTATO FRENCH FRY WAFFLE CUT COATED 6 4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7979677",
        "Last Price Paid": "$9.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030843",
        "Product Status": "Active"
    },
    {
        "Root": "10033235",
        "LV": "1",
        "Item Description": "COLOR SUPER RED GEL PASTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC SUPER RED",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033235",
        "Product Status": "Active"
    },
    {
        "Root": "10103853",
        "LV": "1",
        "Item Description": "FONDANT WHITE TROPICAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "50548",
        "Last Price Paid": "$34.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103853",
        "Product Status": "Active"
    },
    {
        "Root": "10022825",
        "LV": "1",
        "Item Description": "CHOC WHT RECT 1.5 X 1 LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW296",
        "Last Price Paid": "$0.90",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022825",
        "Product Status": "Active"
    },
    {
        "Root": "10006875",
        "LV": "1",
        "Item Description": "PINOT GRIG COPA CHALC 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006875001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006875",
        "Product Status": "Active"
    },
    {
        "Root": "10001432",
        "LV": "1",
        "Item Description": "ONION SWEET WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001432",
        "Product Status": "Active"
    },
    {
        "Root": "10001432",
        "LV": "1",
        "Item Description": "ONION SWEET WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514024",
        "Last Price Paid": "$12.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001432",
        "Product Status": "Active"
    },
    {
        "Root": "10003579",
        "LV": "2",
        "Item Description": "TEA MOUNTAIN BERRY 14.1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003579001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W2130",
        "Last Price Paid": "$35.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003579",
        "Product Status": "Active"
    },
    {
        "Root": "10004916",
        "LV": "1",
        "Item Description": "OIL OLIVE ARBENQUINA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OL114",
        "Last Price Paid": "$18.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004916",
        "Product Status": "Active"
    },
    {
        "Root": "10005085",
        "LV": "1",
        "Item Description": "SYRUP SNO CONE PINA COLAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005085001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1264H",
        "Last Price Paid": "$10.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005085",
        "Product Status": "Active"
    },
    {
        "Root": "10003520",
        "LV": "1",
        "Item Description": "SYRUP WATERMELON LTR COFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003520001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003520",
        "Product Status": "Active"
    },
    {
        "Root": "10024736",
        "LV": "1",
        "Item Description": "COTTON CANDY MAPLE FLOSSUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3228",
        "Last Price Paid": "$5.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024736",
        "Product Status": "Active"
    },
    {
        "Root": "10094109",
        "LV": "1",
        "Item Description": "POCKY STRAWBERRY COOKIE STICK 1.41OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "925369",
        "Last Price Paid": "$1.12",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094109",
        "Product Status": "Active"
    },
    {
        "Root": "10015043",
        "LV": "1",
        "Item Description": "PEARL BALSAMIC 1.75OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "330780",
        "Last Price Paid": "$16.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015043",
        "Product Status": "Active"
    },
    {
        "Root": "10009786",
        "LV": "1",
        "Item Description": "RUM CAPT MORG LNG ISL 750ML RTD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "373686",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009786",
        "Product Status": "Active"
    },
    {
        "Root": "10006656",
        "LV": "1",
        "Item Description": "BOX CAKE FULL SHEET BOTTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006656001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7411242",
        "Last Price Paid": "$2.99",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006656",
        "Product Status": "Active"
    },
    {
        "Root": "10038595",
        "LV": "1",
        "Item Description": "QUINOA COOKED RED WHITE NATES IQF FROZEN 2/15LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3130665",
        "Last Price Paid": "$42.06",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038595",
        "Product Status": "Active"
    },
    {
        "Root": "10032893",
        "LV": "1",
        "Item Description": "CARAMELIZED COCOA NIBS CROQUANT 2-3MM GHANA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500020000",
        "Last Price Paid": "$18.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032893",
        "Product Status": "Active"
    },
    {
        "Root": "10010912",
        "LV": "1",
        "Item Description": "MEZCAL ZIGNUM RESPSDO 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "971036",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010912",
        "Product Status": "Active"
    },
    {
        "Root": "10031880",
        "LV": "1",
        "Item Description": "ALUMINUM 15430 6X3.75X2 1LB LOAF PAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51072274",
        "Last Price Paid": "$0.18",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031880",
        "Product Status": "Active"
    },
    {
        "Root": "10006222",
        "LV": "1",
        "Item Description": "CONTAINER HINGED 6 INCH CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5865282",
        "Last Price Paid": "$0.18",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006222",
        "Product Status": "Active"
    },
    {
        "Root": "10092953",
        "LV": "1",
        "Item Description": "LIQUEUR SOREL 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "239721",
        "Last Price Paid": "$31.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092953",
        "Product Status": "Active"
    },
    {
        "Root": "10000320",
        "LV": "1",
        "Item Description": "ONION RED RING 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-002C",
        "Last Price Paid": "$3.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000320",
        "Product Status": "Active"
    },
    {
        "Root": "10000320",
        "LV": "1",
        "Item Description": "ONION RED RING 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "441122",
        "Last Price Paid": "$3.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000320",
        "Product Status": "Active"
    },
    {
        "Root": "10105129",
        "LV": "1",
        "Item Description": "LINER  BASKET  HVY WT 11.5X8.5 2 COLORS  RODEO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659034",
        "Last Price Paid": "$0.06",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105129",
        "Product Status": "Active"
    },
    {
        "Root": "10011945",
        "LV": "2",
        "Item Description": "MIX BAR COCO PEACH PUREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011945002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9247654",
        "Last Price Paid": "$4.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011945",
        "Product Status": "Active"
    },
    {
        "Root": "10028867",
        "LV": "1",
        "Item Description": "KEG NCB SCRIMSHAW PILS 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "3704",
        "Last Price Paid": "$189.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028867",
        "Product Status": "Active"
    },
    {
        "Root": "10028867",
        "LV": "1",
        "Item Description": "KEG NCB SCRIMSHAW PILS 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "3704",
        "Last Price Paid": "$189.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028867",
        "Product Status": "Active"
    },
    {
        "Root": "10018566",
        "LV": "1",
        "Item Description": "GEL IVORY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22493",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018566",
        "Product Status": "Active"
    },
    {
        "Root": "10008046",
        "LV": "2",
        "Item Description": "PINOT NOIR ARGYLE RES 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008046001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "826663",
        "Last Price Paid": "$14.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008046",
        "Product Status": "Active"
    },
    {
        "Root": "10012715",
        "LV": "1",
        "Item Description": "APPLES RED SKIN ON SLICED 1/4\" DRY PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012715001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-024-DRY",
        "Last Price Paid": "$4.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012715",
        "Product Status": "Active"
    },
    {
        "Root": "10009582",
        "LV": "1",
        "Item Description": "TEQUILA PATRON SLVR 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "71616",
        "Last Price Paid": "$20.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009582",
        "Product Status": "Active"
    },
    {
        "Root": "10101402",
        "LV": "1",
        "Item Description": "WAFFLE CONES BIRTHDAY CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101402001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "BC-M",
        "Last Price Paid": "$0.82",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101402",
        "Product Status": "Active"
    },
    {
        "Root": "10017307",
        "LV": "1",
        "Item Description": "TOMATO GRN SLICED BRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6996185",
        "Last Price Paid": "$10.44",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017307",
        "Product Status": "Active"
    },
    {
        "Root": "10003368",
        "LV": "1",
        "Item Description": "COFFEE CALF GRL BLND 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.16",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003368",
        "Product Status": "Active"
    },
    {
        "Root": "10004106",
        "LV": "1",
        "Item Description": "HORS EGG ROLL PORK 3OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4958252",
        "Last Price Paid": "$0.92",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004106",
        "Product Status": "Active"
    },
    {
        "Root": "10007981",
        "LV": "2",
        "Item Description": "CAB SAUV CEDERBERG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007981002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "SA003F2006",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10007981",
        "Product Status": "Active"
    },
    {
        "Root": "10036227",
        "LV": "1",
        "Item Description": "COCOA BUTTER PASSION PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036227001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302130C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036227",
        "Product Status": "Active"
    },
    {
        "Root": "10003109",
        "LV": "1",
        "Item Description": "SAUCE CHEESE NACHO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5635008",
        "Last Price Paid": "$13.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003109",
        "Product Status": "Active"
    },
    {
        "Root": "10032959",
        "LV": "1",
        "Item Description": "BAG PLAIN 208 3-1/2X2-1/4X7-3/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51168007",
        "Last Price Paid": "$0.03",
        "Case Pack": "8000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032959",
        "Product Status": "Active"
    },
    {
        "Root": "10009116",
        "LV": "1",
        "Item Description": "CAB FRANC RAATS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "523679",
        "Last Price Paid": "$34.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009116",
        "Product Status": "Active"
    },
    {
        "Root": "10016043",
        "LV": "1",
        "Item Description": "YUCCA CASSAVA DICED 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4674628",
        "Last Price Paid": "$0.91",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016043",
        "Product Status": "Active"
    },
    {
        "Root": "10009503",
        "LV": "1",
        "Item Description": "BOURBON GENTLEMAN JACK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9087288",
        "Last Price Paid": "$36.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009503",
        "Product Status": "Active"
    },
    {
        "Root": "10013126",
        "LV": "2",
        "Item Description": "NAPKIN ULTRA INTERFOLD BROWN 2 PLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013126002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013126",
        "Product Status": "Active"
    },
    {
        "Root": "10001625",
        "LV": "1",
        "Item Description": "BLACKBERRY MARION FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7327653",
        "Last Price Paid": "$23.67",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001625",
        "Product Status": "Active"
    },
    {
        "Root": "10011882",
        "LV": "1",
        "Item Description": "SB PUMPKIN SPICE SAUCE 63OZ 4CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11053771",
        "Last Price Paid": "$21.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011882",
        "Product Status": "Active"
    },
    {
        "Root": "10036674",
        "LV": "1",
        "Item Description": "GOLD FOIL INSULATED WRAP 300816 10.5X14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51002765",
        "Last Price Paid": "$0.09",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036674",
        "Product Status": "Active"
    },
    {
        "Root": "10033708",
        "LV": "1",
        "Item Description": "HUMMUS PLAIN TUB CHEFS LINE 2/4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2554244",
        "Last Price Paid": "$16.01",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033708",
        "Product Status": "Active"
    },
    {
        "Root": "10015989",
        "LV": "1",
        "Item Description": "SAUCE DEMI GLACE GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7094194",
        "Last Price Paid": "$20.35",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015989",
        "Product Status": "Active"
    },
    {
        "Root": "10003502",
        "LV": "2",
        "Item Description": "TEA MAD HATTR PRTY 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003502001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W5509",
        "Last Price Paid": "$35.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003502",
        "Product Status": "Active"
    },
    {
        "Root": "10038566",
        "LV": "1",
        "Item Description": "GOLDSCHMIDT IMPERIAL GUARD CABERNET SAUVIGNON 19.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "563934",
        "Last Price Paid": "$355.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038566",
        "Product Status": "Active"
    },
    {
        "Root": "10101491",
        "LV": "1",
        "Item Description": "SYRUP ICE CREAM FLAVOR BURST CARAMEL 1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "044",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101491",
        "Product Status": "Active"
    },
    {
        "Root": "10004870",
        "LV": "1",
        "Item Description": "PESTO CILANTRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1449719",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004870",
        "Product Status": "Active"
    },
    {
        "Root": "10006334",
        "LV": "2",
        "Item Description": "WRAP CHEESEBURGER FOIL 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006334002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1358423",
        "Last Price Paid": "",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006334",
        "Product Status": "Active"
    },
    {
        "Root": "10006950",
        "LV": "2",
        "Item Description": "SANGRIA WHITE BESO SOL 3 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006950001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30199",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006950",
        "Product Status": "Active"
    },
    {
        "Root": "10032385",
        "LV": "1",
        "Item Description": "LW KRAFT DELI PAPER ROARING FORK 1S1C RF 12X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659028",
        "Last Price Paid": "",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032385",
        "Product Status": "Active"
    },
    {
        "Root": "10033234",
        "LV": "1",
        "Item Description": "COLOR GEL PASTE FUCHSIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC FUCHSIA",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033234",
        "Product Status": "Active"
    },
    {
        "Root": "10018718",
        "LV": "1",
        "Item Description": "POTATO MIXED G ENVELOPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MPG-33-E",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018718",
        "Product Status": "Active"
    },
    {
        "Root": "10005793",
        "LV": "3",
        "Item Description": "PASTRY DANISH CINNAMON SWIRL 180CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005793002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061016",
        "Last Price Paid": "",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10005793",
        "Product Status": "Active"
    },
    {
        "Root": "10035935",
        "LV": "1",
        "Item Description": "DRAFT LEMON GINGER RADLER KARBACH 15.5 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "84702",
        "Last Price Paid": "",
        "Case Pack": "1984",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035935",
        "Product Status": "Active"
    },
    {
        "Root": "10100754",
        "LV": "1",
        "Item Description": "SPOTTSWOODE LYNDENHURST CAB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9647968",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100754",
        "Product Status": "Active"
    },
    {
        "Root": "10002276",
        "LV": "1",
        "Item Description": "CHEESE HARD PETIT AGOUR SHEEP FRANCE 1.5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002276001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6421",
        "Last Price Paid": "$15.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002276",
        "Product Status": "Active"
    },
    {
        "Root": "10012495",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-PINK-2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "23215",
        "Last Price Paid": "$0.33",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012495",
        "Product Status": "Active"
    },
    {
        "Root": "10002501",
        "LV": "1",
        "Item Description": "TOMATO MIXED BABY HEIRLOO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17HMBS-11",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002501",
        "Product Status": "Active"
    },
    {
        "Root": "10017222",
        "LV": "2",
        "Item Description": "SCHLMBRGR BRUT CLASSIC TUBE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017222001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16159",
        "Last Price Paid": "$16.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10017222",
        "Product Status": "Active"
    },
    {
        "Root": "10100495",
        "LV": "1",
        "Item Description": "CHEESE BRIE AFFINOIS V&A ONLY 2 RNDS PER CS CATCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100495001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "67940",
        "Last Price Paid": "$16.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100495",
        "Product Status": "Active"
    },
    {
        "Root": "10041052",
        "LV": "1",
        "Item Description": "CHEESE BLUE GORGONZOLA DOLCE ITALY 2/3.5 LB PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041052001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1740",
        "Last Price Paid": "$9.07",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041052",
        "Product Status": "Active"
    },
    {
        "Root": "10004844",
        "LV": "1",
        "Item Description": "RICE KRISPY TREAT SHEET 32OZ 5CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004844001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7202385",
        "Last Price Paid": "$11.48",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004844",
        "Product Status": "Active"
    },
    {
        "Root": "10025301",
        "LV": "2",
        "Item Description": "SPARK CANELLA MIMOSA BLOOD ORANGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025301002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "223560",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025301",
        "Product Status": "Active"
    },
    {
        "Root": "10010785",
        "LV": "2",
        "Item Description": "BEER 3 DAUGHTER ST PETE BEACH BLONDE ALE 12Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010785001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "80954",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010785",
        "Product Status": "Active"
    },
    {
        "Root": "10006037",
        "LV": "1",
        "Item Description": "CONTAINER HNGD CLR 9X4.58",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006037001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5840202",
        "Last Price Paid": "$0.50",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006037",
        "Product Status": "Active"
    },
    {
        "Root": "10021764",
        "LV": "1",
        "Item Description": "CHOC WHT SLIPPER SMOOTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW24112",
        "Last Price Paid": "$1.00",
        "Case Pack": "75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021764",
        "Product Status": "Active"
    },
    {
        "Root": "10026657",
        "LV": "1",
        "Item Description": "JUICE COCKTAIL CRANBERRY SHELF STABLE 8/60 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026657001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3050689",
        "Last Price Paid": "$3.17",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026657",
        "Product Status": "Active"
    },
    {
        "Root": "10085393",
        "LV": "1",
        "Item Description": "BEEF D-LUXE BURGER 108CT / 3.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085393001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1095003",
        "Last Price Paid": "$4.48",
        "Case Pack": "21.6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085393",
        "Product Status": "Active"
    },
    {
        "Root": "10024589",
        "LV": "1",
        "Item Description": "ROLL OLIVE OIL CIABATTA 60 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024589001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "9908",
        "Last Price Paid": "$0.90",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024589",
        "Product Status": "Active"
    },
    {
        "Root": "10043916",
        "LV": "1",
        "Item Description": "TOPPING HEATH CHUNK ENGLISH TOFFEE 4/3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8056947",
        "Last Price Paid": "$14.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043916",
        "Product Status": "Active"
    },
    {
        "Root": "10107948",
        "LV": "1",
        "Item Description": "BLISTER STITCH EAR 2.3X6CM, 5600PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175680000",
        "Last Price Paid": "$0.15",
        "Case Pack": "5600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107948",
        "Product Status": "Active"
    },
    {
        "Root": "10012365",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN ON 8OZ, WILD SUSTAINABLE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012365001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01310",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012365",
        "Product Status": "Active"
    },
    {
        "Root": "10012365",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN ON 8OZ, WILD SUSTAINABLE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012365001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF205",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012365",
        "Product Status": "Active"
    },
    {
        "Root": "10012365",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN ON 8OZ, WILD SUSTAINABLE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012365001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "63-01310",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012365",
        "Product Status": "Active"
    },
    {
        "Root": "10039655",
        "LV": "1",
        "Item Description": "MIX SOFT SERVE COTTON CANDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039655",
        "Product Status": "Active"
    },
    {
        "Root": "10031804",
        "LV": "1",
        "Item Description": "GARDEN MINT AROMA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672649",
        "Last Price Paid": "$25.81",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031804",
        "Product Status": "Active"
    },
    {
        "Root": "10001443",
        "LV": "1",
        "Item Description": "TANGERINE UM LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "211040",
        "Last Price Paid": "$46.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001443",
        "Product Status": "Active"
    },
    {
        "Root": "10076694",
        "LV": "1",
        "Item Description": "MIX SHAKE VANILLA 5% MIX 9HG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076694001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.69",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076694",
        "Product Status": "Active"
    },
    {
        "Root": "10076694",
        "LV": "1",
        "Item Description": "MIX SHAKE VANILLA 5% MIX 9HG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076694001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.69",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076694",
        "Product Status": "Active"
    },
    {
        "Root": "10002084",
        "LV": "1",
        "Item Description": "MUSHROOM TRUMPET ROYALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002084001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50180",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002084",
        "Product Status": "Active"
    },
    {
        "Root": "10002084",
        "LV": "1",
        "Item Description": "MUSHROOM TRUMPET ROYALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002084001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002084",
        "Product Status": "Active"
    },
    {
        "Root": "10022389",
        "LV": "1",
        "Item Description": "CHOC WHT DISK 1.5 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2048W",
        "Last Price Paid": "$1.15",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022389",
        "Product Status": "Active"
    },
    {
        "Root": "10003971",
        "LV": "1",
        "Item Description": "RICE BAMBOO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003971001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-65010",
        "Last Price Paid": "$10.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003971",
        "Product Status": "Active"
    },
    {
        "Root": "10013480",
        "LV": "1",
        "Item Description": "DRAFT EINSTOK TOAST PORTER KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14929",
        "Last Price Paid": "$98.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013480",
        "Product Status": "Active"
    },
    {
        "Root": "10002932",
        "LV": "1",
        "Item Description": "DRESSING CAESAR 60CT 1.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002932",
        "Product Status": "Active"
    },
    {
        "Root": "10005470",
        "LV": "2",
        "Item Description": "HORS SPRING ROLL VEG .75Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005470002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7042140",
        "Last Price Paid": "$0.75",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005470",
        "Product Status": "Active"
    },
    {
        "Root": "10007137",
        "LV": "2",
        "Item Description": "CHARD MICHEL CHABLIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007137001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9672800",
        "Last Price Paid": "$24.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007137",
        "Product Status": "Active"
    },
    {
        "Root": "10005060",
        "LV": "1",
        "Item Description": "SYRUP MONIN PMPKN SPIC LT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005060",
        "Product Status": "Active"
    },
    {
        "Root": "10008154",
        "LV": "1",
        "Item Description": "PINOTAGE KANOKOP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "965463",
        "Last Price Paid": "$33.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008154",
        "Product Status": "Active"
    },
    {
        "Root": "10093057",
        "LV": "1",
        "Item Description": "RAW DUCK WING DRUMMETTES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093057001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4232011",
        "Last Price Paid": "$6.84",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093057",
        "Product Status": "Active"
    },
    {
        "Root": "10015820",
        "LV": "1",
        "Item Description": "AVIVE PEACH NV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "127245",
        "Last Price Paid": "$7.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015820",
        "Product Status": "Active"
    },
    {
        "Root": "10009462",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN 12YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$75.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009462",
        "Product Status": "Active"
    },
    {
        "Root": "10017890",
        "LV": "1",
        "Item Description": "CHIC 6 OZ BTRFLY HALAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017890001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "000513",
        "Last Price Paid": "$3.22",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017890",
        "Product Status": "Active"
    },
    {
        "Root": "10003494",
        "LV": "2",
        "Item Description": "TEA ORG BRKFST 14.1OZ LSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003494001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3329",
        "Last Price Paid": "$35.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003494",
        "Product Status": "Active"
    },
    {
        "Root": "10102409",
        "LV": "2",
        "Item Description": "CRAB SNOW CLUSTER SUSTAINABLE 30LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102409002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6830053",
        "Last Price Paid": "$6.56",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102409",
        "Product Status": "Active"
    },
    {
        "Root": "10016813",
        "LV": "1",
        "Item Description": "SAUERKRAUT BAGGED 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "658859",
        "Last Price Paid": "$2.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016813",
        "Product Status": "Active"
    },
    {
        "Root": "10110894",
        "LV": "1",
        "Item Description": "ORA KING SALMON SK/OFF PBO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110894001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.95",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110894",
        "Product Status": "Active"
    },
    {
        "Root": "10110132",
        "LV": "1",
        "Item Description": "BAG CRYSTAL CLEAR NO FLAP 5-11/16\" X 8-5/8\" B59SNF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51596788",
        "Last Price Paid": "$0.06",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110132",
        "Product Status": "Active"
    },
    {
        "Root": "10004525",
        "LV": "1",
        "Item Description": "CHOC GLASS SLIPPER DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004525",
        "Product Status": "Active"
    },
    {
        "Root": "10001013",
        "LV": "1",
        "Item Description": "FISH TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001013001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6528802",
        "Last Price Paid": "$3.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001013",
        "Product Status": "Active"
    },
    {
        "Root": "10001013",
        "LV": "1",
        "Item Description": "FISH TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001013001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001013",
        "Product Status": "Active"
    },
    {
        "Root": "10001013",
        "LV": "1",
        "Item Description": "FISH TRIM SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001013001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00001",
        "Last Price Paid": "$3.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001013",
        "Product Status": "Active"
    },
    {
        "Root": "10001713",
        "LV": "1",
        "Item Description": "ASPARAGUS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001713001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FRENCH",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001713",
        "Product Status": "Active"
    },
    {
        "Root": "10001713",
        "LV": "1",
        "Item Description": "ASPARAGUS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001713001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ASPW",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001713",
        "Product Status": "Active"
    },
    {
        "Root": "10001713",
        "LV": "1",
        "Item Description": "ASPARAGUS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001713001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401014",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001713",
        "Product Status": "Active"
    },
    {
        "Root": "10001541",
        "LV": "1",
        "Item Description": "BERRY CURRANT BLCK DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "62560",
        "Last Price Paid": "$33.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001541",
        "Product Status": "Active"
    },
    {
        "Root": "10001541",
        "LV": "1",
        "Item Description": "BERRY CURRANT BLCK DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390031",
        "Last Price Paid": "$33.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001541",
        "Product Status": "Active"
    },
    {
        "Root": "10038808",
        "LV": "1",
        "Item Description": "DANISH CHEESE 4 INCH INDV WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.94",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038808",
        "Product Status": "Active"
    },
    {
        "Root": "10014220",
        "LV": "1",
        "Item Description": "COCOA BUTTER MYCRYO 8/1.1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014220001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C706",
        "Last Price Paid": "$15.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014220",
        "Product Status": "Active"
    },
    {
        "Root": "10037372",
        "LV": "1",
        "Item Description": "COASTER 4\" ROUND CORONADO 35PT LW CMYK 5000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003205",
        "Last Price Paid": "$0.05",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037372",
        "Product Status": "Active"
    },
    {
        "Root": "10022391",
        "LV": "1",
        "Item Description": "CHOC WHT DISK 2 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2062W",
        "Last Price Paid": "$1.25",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022391",
        "Product Status": "Active"
    },
    {
        "Root": "10041042",
        "LV": "1",
        "Item Description": "LID BOWL 24 OZ CLEAR OPS 400CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51010950",
        "Last Price Paid": "$0.11",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041042",
        "Product Status": "Active"
    },
    {
        "Root": "10101413",
        "LV": "1",
        "Item Description": "WAFFLE CONES FRENCH VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "FV-M",
        "Last Price Paid": "$0.82",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101413",
        "Product Status": "Active"
    },
    {
        "Root": "10016340",
        "LV": "1",
        "Item Description": "CUPCAKE LG YELL BLUE ICING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1649",
        "Last Price Paid": "$1.16",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016340",
        "Product Status": "Active"
    },
    {
        "Root": "10012230",
        "LV": "1",
        "Item Description": "LIQ HEERING CHERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "951189",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012230",
        "Product Status": "Active"
    },
    {
        "Root": "10001563",
        "LV": "1",
        "Item Description": "POTATO PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001563001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489962",
        "Last Price Paid": "$31.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001563",
        "Product Status": "Active"
    },
    {
        "Root": "10002481",
        "LV": "1",
        "Item Description": "BEAN FAVA FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401626",
        "Last Price Paid": "$40.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002481",
        "Product Status": "Active"
    },
    {
        "Root": "10002481",
        "LV": "1",
        "Item Description": "BEAN FAVA FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20460",
        "Last Price Paid": "$40.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002481",
        "Product Status": "Active"
    },
    {
        "Root": "10000468",
        "LV": "1",
        "Item Description": "BREAKFAST BREAD CHOC CHIP (8 IN X4 IN) 10 SLICE EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000468",
        "Product Status": "Active"
    },
    {
        "Root": "10085548",
        "LV": "1",
        "Item Description": "STRAW PAPER HAUNT MANS 50TH 8.5IN COLOSSAL UNWRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510258",
        "Last Price Paid": "$0.10",
        "Case Pack": "1480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085548",
        "Product Status": "Active"
    },
    {
        "Root": "10013884",
        "LV": "1",
        "Item Description": "PASTRY CREAM ELSAY HOT PROCESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013884001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SK2625",
        "Last Price Paid": "$87.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013884",
        "Product Status": "Active"
    },
    {
        "Root": "10027421",
        "LV": "1",
        "Item Description": "CALVIN PEA TENDRILS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027421001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04CPT-33",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027421",
        "Product Status": "Active"
    },
    {
        "Root": "10004653",
        "LV": "1",
        "Item Description": "NUT PEANUT GRANULATED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004653001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "130600",
        "Last Price Paid": "$69.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004653",
        "Product Status": "Active"
    },
    {
        "Root": "10012761",
        "LV": "1",
        "Item Description": "ALLIUM PETITE MIXED 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PMA-33",
        "Last Price Paid": "$34.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012761",
        "Product Status": "Active"
    },
    {
        "Root": "10002032",
        "LV": "1",
        "Item Description": "FLOWER CHIVE BLOSSOMS 150",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482055",
        "Last Price Paid": "$0.09",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002032",
        "Product Status": "Active"
    },
    {
        "Root": "10109649",
        "LV": "1",
        "Item Description": "BOX PAPER WHITE 16OZ ASIAN TAKE-OUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "79516MWWHITE",
        "Last Price Paid": "$0.12",
        "Case Pack": "450",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109649",
        "Product Status": "Active"
    },
    {
        "Root": "10008059",
        "LV": "1",
        "Item Description": "MERLOT PORCUPINE RIDGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2302149",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008059",
        "Product Status": "Active"
    },
    {
        "Root": "10003931",
        "LV": "1",
        "Item Description": "FLOUR POTATO BOBS 4/2.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101299",
        "Last Price Paid": "$5.84",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003931",
        "Product Status": "Active"
    },
    {
        "Root": "10102927",
        "LV": "1",
        "Item Description": "KOSHER BSC SOUP SPOON SILVER 30CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.12",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102927",
        "Product Status": "Active"
    },
    {
        "Root": "10022375",
        "LV": "1",
        "Item Description": "CHOC DRK DISK 2 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2062D",
        "Last Price Paid": "$1.25",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022375",
        "Product Status": "Active"
    },
    {
        "Root": "10001735",
        "LV": "1",
        "Item Description": "PEPPER ANCHO DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407954",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001735",
        "Product Status": "Active"
    },
    {
        "Root": "10001735",
        "LV": "1",
        "Item Description": "PEPPER ANCHO DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001735",
        "Product Status": "Active"
    },
    {
        "Root": "10005960",
        "LV": "1",
        "Item Description": "BEEF STEW 1X1 CHOICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005960001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190094",
        "Last Price Paid": "$8.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005960",
        "Product Status": "Active"
    },
    {
        "Root": "10012968",
        "LV": "1",
        "Item Description": "MUSTARD BABY MIXED 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09MBM-26",
        "Last Price Paid": "$45.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012968",
        "Product Status": "Active"
    },
    {
        "Root": "10107168",
        "LV": "1",
        "Item Description": "PORCELET RACK 4-6# AVG SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107168001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "PORPOR009",
        "Last Price Paid": "$25.99",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107168",
        "Product Status": "Active"
    },
    {
        "Root": "10106586",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER  BLUE 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313060E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106586",
        "Product Status": "Active"
    },
    {
        "Root": "10008209",
        "LV": "2",
        "Item Description": "RIES SELBACH BERN SPT BAD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008209001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008209",
        "Product Status": "Active"
    },
    {
        "Root": "10018721",
        "LV": "1",
        "Item Description": "HOR CHICKEN SATAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018721001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6952",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018721",
        "Product Status": "Active"
    },
    {
        "Root": "10009256",
        "LV": "1",
        "Item Description": "CHARD BARON HERZOG KOSHER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "71882",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009256",
        "Product Status": "Active"
    },
    {
        "Root": "10094669",
        "LV": "1",
        "Item Description": "BOWL PAPER WHITE FOOD 8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51054149",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094669",
        "Product Status": "Active"
    },
    {
        "Root": "10004958",
        "LV": "1",
        "Item Description": "BASE NEUTRAL FOND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "..{DREIDOPPEL{",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004958",
        "Product Status": "Active"
    },
    {
        "Root": "10031026",
        "LV": "1",
        "Item Description": "MASSEL FLAVOR CENTER BEEF FLAVOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "00143",
        "Last Price Paid": "$22.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031026",
        "Product Status": "Active"
    },
    {
        "Root": "10106808",
        "LV": "1",
        "Item Description": "SAUCE COCONUT AMINOS ORGANIC 16.9OZ/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "718998",
        "Last Price Paid": "$8.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106808",
        "Product Status": "Active"
    },
    {
        "Root": "10022278",
        "LV": "1",
        "Item Description": "PESTO MINT 5 GAL F&W TEMP SKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "EMPF/5G",
        "Last Price Paid": "$209.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022278",
        "Product Status": "Active"
    },
    {
        "Root": "10015269",
        "LV": "1",
        "Item Description": "TRAY BLACK HINGED 4 SEC DEEP DINNER TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11874014",
        "Last Price Paid": "$0.53",
        "Case Pack": "170",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015269",
        "Product Status": "Active"
    },
    {
        "Root": "10019466",
        "LV": "1",
        "Item Description": "SAFFRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019466001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482212",
        "Last Price Paid": "$45.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019466",
        "Product Status": "Active"
    },
    {
        "Root": "10102152",
        "LV": "1",
        "Item Description": "COOKIE RED RND INDIV WRAP 1.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102152",
        "Product Status": "Active"
    },
    {
        "Root": "10019647",
        "LV": "1",
        "Item Description": "MILK LACTAID 100% FF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019647001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "149518",
        "Last Price Paid": "$4.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019647",
        "Product Status": "Active"
    },
    {
        "Root": "10038127",
        "LV": "1",
        "Item Description": "DRAFT WEINHENSTEPHANER FESTBIER 50L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038127001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "8585",
        "Last Price Paid": "$209.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038127",
        "Product Status": "Active"
    },
    {
        "Root": "10001054",
        "LV": "1",
        "Item Description": "CRABMEAT LUMP FRZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001054001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6339547",
        "Last Price Paid": "$13.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001054",
        "Product Status": "Active"
    },
    {
        "Root": "10018518",
        "LV": "1",
        "Item Description": "BLND,MACABEO,KILA,CAVA,750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001152",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018518",
        "Product Status": "Active"
    },
    {
        "Root": "10002215",
        "LV": "1",
        "Item Description": "MILK HOMO PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.49",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002215",
        "Product Status": "Active"
    },
    {
        "Root": "10003546",
        "LV": "1",
        "Item Description": "SPICE CURRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003546001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.17",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003546",
        "Product Status": "Active"
    },
    {
        "Root": "10010848",
        "LV": "1",
        "Item Description": "CHARD MEIOMI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "523076",
        "Last Price Paid": "$12.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010848",
        "Product Status": "Active"
    },
    {
        "Root": "10036335",
        "LV": "2",
        "Item Description": "MUG CERAMIC ENDOR SWGE 18Z 18/C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036335002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.85",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036335",
        "Product Status": "Active"
    },
    {
        "Root": "10091433",
        "LV": "1",
        "Item Description": "MICKEY HAT W/EARS 2\" DOME WHT W/COLORBRUSH GOLD/SP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW31773",
        "Last Price Paid": "$1.50",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091433",
        "Product Status": "Active"
    },
    {
        "Root": "10106968",
        "LV": "2",
        "Item Description": "SPICE VINEGAR POWDER WHITE 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106968002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3535062",
        "Last Price Paid": "$25.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106968",
        "Product Status": "Active"
    },
    {
        "Root": "10000251",
        "LV": "1",
        "Item Description": "PEPPER DRIED BIRD S EYE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489937",
        "Last Price Paid": "$49.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000251",
        "Product Status": "Active"
    },
    {
        "Root": "10006949",
        "LV": "1",
        "Item Description": "CAB SAUV CH STE MICHELLE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "593773",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006949",
        "Product Status": "Active"
    },
    {
        "Root": "10040474",
        "LV": "1",
        "Item Description": "FOOD TRAY KRAFT #300 3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040474001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040474",
        "Product Status": "Active"
    },
    {
        "Root": "10002098",
        "LV": "1",
        "Item Description": "PEA ENGLISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002098001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002098",
        "Product Status": "Active"
    },
    {
        "Root": "10001206",
        "LV": "1",
        "Item Description": "HALIBUT PORTION 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001206001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF204",
        "Last Price Paid": "$25.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001206",
        "Product Status": "Active"
    },
    {
        "Root": "10001206",
        "LV": "1",
        "Item Description": "HALIBUT PORTION 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001206001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01305-A",
        "Last Price Paid": "$25.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001206",
        "Product Status": "Active"
    },
    {
        "Root": "10001206",
        "LV": "1",
        "Item Description": "HALIBUT PORTION 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001206001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "4814218",
        "Last Price Paid": "$25.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001206",
        "Product Status": "Active"
    },
    {
        "Root": "10001206",
        "LV": "1",
        "Item Description": "HALIBUT PORTION 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001206001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "4814218",
        "Last Price Paid": "$25.33",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001206",
        "Product Status": "Active"
    },
    {
        "Root": "10004882",
        "LV": "1",
        "Item Description": "MARSHMALLOW MINI 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "60764",
        "Last Price Paid": "$1.51",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004882",
        "Product Status": "Active"
    },
    {
        "Root": "10022959",
        "LV": "1",
        "Item Description": "LEAVES TI GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450109",
        "Last Price Paid": "$5.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022959",
        "Product Status": "Active"
    },
    {
        "Root": "10108218",
        "LV": "1",
        "Item Description": "DÉCOR WHITE CRUNCHY PEARL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA10843",
        "Last Price Paid": "$76.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108218",
        "Product Status": "Active"
    },
    {
        "Root": "10012504",
        "LV": "1",
        "Item Description": "BEET BULLS BLOOD FARMERS BOUNTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012504001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04BBFB-2",
        "Last Price Paid": "$41.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012504",
        "Product Status": "Active"
    },
    {
        "Root": "10038565",
        "LV": "1",
        "Item Description": "CHARDONNAY GOLDSCHMIDT TONIRAY RRV 19.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038565001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "556997",
        "Last Price Paid": "$330.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038565",
        "Product Status": "Active"
    },
    {
        "Root": "10009558",
        "LV": "1",
        "Item Description": "VODKA STANDARD RUSSIAN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009558",
        "Product Status": "Active"
    },
    {
        "Root": "10002597",
        "LV": "1",
        "Item Description": "MUSHROOM MOREL BLK FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002597001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50145",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002597",
        "Product Status": "Active"
    },
    {
        "Root": "10002597",
        "LV": "1",
        "Item Description": "MUSHROOM MOREL BLK FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002597001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002597",
        "Product Status": "Active"
    },
    {
        "Root": "10010135",
        "LV": "2",
        "Item Description": "WATER SAINT GERON SPARKLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010135001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.51",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010135",
        "Product Status": "Active"
    },
    {
        "Root": "10007912",
        "LV": "1",
        "Item Description": "WINE RED AMARONE SARTORI CORTE BRA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "579967",
        "Last Price Paid": "$44.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007912",
        "Product Status": "Active"
    },
    {
        "Root": "10000216",
        "LV": "1",
        "Item Description": "LETTUCE ROMAINE HEARTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482059",
        "Last Price Paid": "$0.82",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000216",
        "Product Status": "Active"
    },
    {
        "Root": "10000836",
        "LV": "2",
        "Item Description": "TURKEY BRST OIL BROWNED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000836001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.51",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000836",
        "Product Status": "Active"
    },
    {
        "Root": "10032527",
        "LV": "1",
        "Item Description": "DECOR PASTRY SPRAY IDEALE PINK LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860240000",
        "Last Price Paid": "$28.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032527",
        "Product Status": "Active"
    },
    {
        "Root": "10018128",
        "LV": "1",
        "Item Description": "BLEND NEYEN ESPITU APALTA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "526667",
        "Last Price Paid": "$61.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018128",
        "Product Status": "Active"
    },
    {
        "Root": "10106374",
        "LV": "1",
        "Item Description": "CHOCOLATE COOKIE MINT SPRINKLE 142 G 4-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11145223",
        "Last Price Paid": "$6.07",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106374",
        "Product Status": "Active"
    },
    {
        "Root": "10041070",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR MEDIUM YELLOW TILLAMOOK 10 LB PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041070001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "11111",
        "Last Price Paid": "$5.48",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041070",
        "Product Status": "Active"
    },
    {
        "Root": "10004153",
        "LV": "1",
        "Item Description": "CROQUANT HAZELNUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C903",
        "Last Price Paid": "$24.64",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004153",
        "Product Status": "Active"
    },
    {
        "Root": "10018709",
        "LV": "1",
        "Item Description": "OIL ORANGE PURE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ORANGE6",
        "Last Price Paid": "$17.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018709",
        "Product Status": "Active"
    },
    {
        "Root": "10000201",
        "LV": "1",
        "Item Description": "CARROT WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000201001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482021",
        "Last Price Paid": "$3.64",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000201",
        "Product Status": "Active"
    },
    {
        "Root": "10017350",
        "LV": "1",
        "Item Description": "NUERNBERGER GLUHWEIN RED 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27937",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017350",
        "Product Status": "Active"
    },
    {
        "Root": "10107335",
        "LV": "1",
        "Item Description": "BEER SUN KING CAIPIRINHA 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "62161",
        "Last Price Paid": "$200.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107335",
        "Product Status": "Active"
    },
    {
        "Root": "10009930",
        "LV": "1",
        "Item Description": "LIQ BARENJGR HONEY BOURBON 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "340251",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009930",
        "Product Status": "Active"
    },
    {
        "Root": "10004697",
        "LV": "1",
        "Item Description": "SEEDS SUNFLOWER UNSLTD 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004697001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "321310",
        "Last Price Paid": "$51.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004697",
        "Product Status": "Active"
    },
    {
        "Root": "10002036",
        "LV": "1",
        "Item Description": "AVOCADO HAUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002036",
        "Product Status": "Active"
    },
    {
        "Root": "10083784",
        "LV": "2",
        "Item Description": "PEANUT BUTTER POWDER 6/6/5 OZ CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083784002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "944700",
        "Last Price Paid": "$5.13",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083784",
        "Product Status": "Active"
    },
    {
        "Root": "10008112",
        "LV": "1",
        "Item Description": "ICE WINE VIDAL CH CHRM 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2531564",
        "Last Price Paid": "$26.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008112",
        "Product Status": "Active"
    },
    {
        "Root": "10013240",
        "LV": "1",
        "Item Description": "MUSHROOM OYSTER WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013240001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50050",
        "Last Price Paid": "$10.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013240",
        "Product Status": "Active"
    },
    {
        "Root": "10036748",
        "LV": "1",
        "Item Description": "TOLEDO COCONUT FLAKES UNSWEETENED 1/EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "HC911",
        "Last Price Paid": "$31.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036748",
        "Product Status": "Active"
    },
    {
        "Root": "10015457",
        "LV": "1",
        "Item Description": "FLAVORING BUFFALO BREATH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015457001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2414",
        "Last Price Paid": "$50.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015457",
        "Product Status": "Active"
    },
    {
        "Root": "10032305",
        "LV": "2",
        "Item Description": "PUSH-DECOPOP CLEAR 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032305002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "23230",
        "Last Price Paid": "$0.25",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032305",
        "Product Status": "Active"
    },
    {
        "Root": "10005740",
        "LV": "1",
        "Item Description": "DOUGH PIZZA PRESHEETED 7 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6364590",
        "Last Price Paid": "$0.56",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005740",
        "Product Status": "Active"
    },
    {
        "Root": "10002683",
        "LV": "2",
        "Item Description": "TOMATO PLUM WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002683002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.45",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002683",
        "Product Status": "Active"
    },
    {
        "Root": "10003743",
        "LV": "1",
        "Item Description": "OIL SAFFLOWER 32Z/12EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "925560",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003743",
        "Product Status": "Active"
    },
    {
        "Root": "10003767",
        "LV": "1",
        "Item Description": "SPICE FENNEL POLLEN 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405115",
        "Last Price Paid": "$23.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003767",
        "Product Status": "Active"
    },
    {
        "Root": "10003767",
        "LV": "1",
        "Item Description": "SPICE FENNEL POLLEN 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003767",
        "Product Status": "Active"
    },
    {
        "Root": "10009458",
        "LV": "1",
        "Item Description": "SCOTCH OBAN 14YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "20965",
        "Last Price Paid": "$78.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009458",
        "Product Status": "Active"
    },
    {
        "Root": "10036034",
        "LV": "1",
        "Item Description": "DRAFT LAGER ESTRELLA DAMM 30L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036034001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32440",
        "Last Price Paid": "$89.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036034",
        "Product Status": "Active"
    },
    {
        "Root": "10021817",
        "LV": "2",
        "Item Description": "OIL COCONUT OG2 EXPL PRSD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021817002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "181672",
        "Last Price Paid": "$5.48",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021817",
        "Product Status": "Active"
    },
    {
        "Root": "10027326",
        "LV": "1",
        "Item Description": "BREAD BUN POTATO 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027326001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "46083D",
        "Last Price Paid": "$0.45",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027326",
        "Product Status": "Active"
    },
    {
        "Root": "10040951",
        "LV": "1",
        "Item Description": "CHOC MILK SHELL TRUFFLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I96821",
        "Last Price Paid": "$0.12",
        "Case Pack": "504",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040951",
        "Product Status": "Active"
    },
    {
        "Root": "10088243",
        "LV": "1",
        "Item Description": "JUICE YUZU STARCRUISER 100%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088243001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "3500",
        "Last Price Paid": "$27.90",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088243",
        "Product Status": "Active"
    },
    {
        "Root": "10016027",
        "LV": "1",
        "Item Description": "HERB BASIL MICRO OPAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401143",
        "Last Price Paid": "$13.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016027",
        "Product Status": "Active"
    },
    {
        "Root": "10108809",
        "LV": "1",
        "Item Description": "SCARPETTA FRICO ROSATO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9341895",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108809",
        "Product Status": "Active"
    },
    {
        "Root": "10006971",
        "LV": "1",
        "Item Description": "PINOT NOIR KING EST OR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "249163",
        "Last Price Paid": "$19.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006971",
        "Product Status": "Active"
    },
    {
        "Root": "10002050",
        "LV": "1",
        "Item Description": "EGGPLANT LARGE LB=LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002050001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002050",
        "Product Status": "Active"
    },
    {
        "Root": "10002050",
        "LV": "1",
        "Item Description": "EGGPLANT LARGE LB=LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002050001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002050",
        "Product Status": "Active"
    },
    {
        "Root": "10040143",
        "LV": "1",
        "Item Description": "MUSHROOM MATSUTAKE 4# CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSWIL017",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040143",
        "Product Status": "Active"
    },
    {
        "Root": "10005393",
        "LV": "1",
        "Item Description": "CHOC TOPPER OLAF 2.37",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW4453",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005393",
        "Product Status": "Active"
    },
    {
        "Root": "10030336",
        "LV": "1",
        "Item Description": "BEER ELYSIAN DAYGLOW IPA 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "64306",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030336",
        "Product Status": "Active"
    },
    {
        "Root": "10007612",
        "LV": "2",
        "Item Description": "SAUV BLC FOURNIER SANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007612001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "519454",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007612",
        "Product Status": "Active"
    },
    {
        "Root": "10038342",
        "LV": "1",
        "Item Description": "WHISKEY LOCH LOMOND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038342001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038342",
        "Product Status": "Active"
    },
    {
        "Root": "10092929",
        "LV": "1",
        "Item Description": "STRAW BOBA PAPER DISP 8.5IN WHITE UNWRAPPED 1080CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1080",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092929",
        "Product Status": "Active"
    },
    {
        "Root": "10089066",
        "LV": "1",
        "Item Description": "DEC OVAL CHIP CUP WHITE 1 1/2IN X 1 1/8IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW4546",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089066",
        "Product Status": "Active"
    },
    {
        "Root": "10110610",
        "LV": "1",
        "Item Description": "CIDER BOLD ROCK ORCHARD FROST 15.5GAL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76746",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110610",
        "Product Status": "Active"
    },
    {
        "Root": "10019850",
        "LV": "1",
        "Item Description": "EGG WHITE CF 15/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019850001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019850",
        "Product Status": "Active"
    },
    {
        "Root": "10022816",
        "LV": "1",
        "Item Description": "CANDY GLITTERATI FRUIT BERRY 4800CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "505602",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022816",
        "Product Status": "Active"
    },
    {
        "Root": "10034752",
        "LV": "1",
        "Item Description": "PORCINI CORK CREPES FROZEN 11 LB CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034752001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZMUSFRO006",
        "Last Price Paid": "",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034752",
        "Product Status": "Active"
    },
    {
        "Root": "10003617",
        "LV": "1",
        "Item Description": "SB 5LB KOMODO DRGN BLND-",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7355",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003617",
        "Product Status": "Active"
    },
    {
        "Root": "10027167",
        "LV": "1",
        "Item Description": "GELATO TRAY COOKIES AND CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "IG004-T",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027167",
        "Product Status": "Active"
    },
    {
        "Root": "10033791",
        "LV": "1",
        "Item Description": "WINE WHITE SPARKLING FREIXENET BLANC DE BLANC 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033791",
        "Product Status": "Active"
    },
    {
        "Root": "10086456",
        "LV": "1",
        "Item Description": "COFFEE JOFFREYS PERU ALTO INRM REG EA/500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086456",
        "Product Status": "Active"
    },
    {
        "Root": "10001371",
        "LV": "1",
        "Item Description": "MICRO THANKSGIVING BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MTHB-33-S",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001371",
        "Product Status": "Active"
    },
    {
        "Root": "10035827",
        "LV": "1",
        "Item Description": "SUGAR PRIMARY RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "147",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035827",
        "Product Status": "Active"
    },
    {
        "Root": "10036314",
        "LV": "1",
        "Item Description": "TWININGS ENG BREAK HONEY VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036314",
        "Product Status": "Active"
    },
    {
        "Root": "10044407",
        "LV": "1",
        "Item Description": "SB EARTH CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11122648",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044407",
        "Product Status": "Active"
    },
    {
        "Root": "10010356",
        "LV": "2",
        "Item Description": "BEER SWEET WATER IPA BT 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010356001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "67918",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010356",
        "Product Status": "Active"
    },
    {
        "Root": "10040834",
        "LV": "1",
        "Item Description": "CHARD SEA TURTLE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040834",
        "Product Status": "Active"
    },
    {
        "Root": "10036043",
        "LV": "1",
        "Item Description": "FUNDADOR BRANDY SOLERA RSV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "542890",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036043",
        "Product Status": "Active"
    },
    {
        "Root": "10002096",
        "LV": "1",
        "Item Description": "FLOWER ORCHID 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.82",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002096",
        "Product Status": "Active"
    },
    {
        "Root": "10012586",
        "LV": "1",
        "Item Description": "TWIN 24CT GREEN K-CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012586",
        "Product Status": "Active"
    },
    {
        "Root": "10010047",
        "LV": "1",
        "Item Description": "BEER GOOSE ISLAND 312 16Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21563",
        "Last Price Paid": "$1.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010047",
        "Product Status": "Active"
    },
    {
        "Root": "10004837",
        "LV": "1",
        "Item Description": "TRUFFLE PEELINGS BLACK 7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-55070",
        "Last Price Paid": "$34.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004837",
        "Product Status": "Active"
    },
    {
        "Root": "10004837",
        "LV": "1",
        "Item Description": "TRUFFLE PEELINGS BLACK 7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004837",
        "Product Status": "Active"
    },
    {
        "Root": "10004837",
        "LV": "1",
        "Item Description": "TRUFFLE PEELINGS BLACK 7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004837",
        "Product Status": "Active"
    },
    {
        "Root": "10036225",
        "LV": "1",
        "Item Description": "COCOA BUTTER BATTLESHIP GRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036225001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302160C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036225",
        "Product Status": "Active"
    },
    {
        "Root": "10002177",
        "LV": "2",
        "Item Description": "JUICE BAR LEMONADE STRAWBERRY OLAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002177001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.51",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002177",
        "Product Status": "Active"
    },
    {
        "Root": "10094068",
        "LV": "1",
        "Item Description": "WATER COCONUT VITA COCO NATURAL 16.9OZ/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6924690",
        "Last Price Paid": "$2.22",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094068",
        "Product Status": "Active"
    },
    {
        "Root": "10012617",
        "LV": "1",
        "Item Description": "BARQ'S CREME SODA 2.5 GALLON BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "004900098664",
        "Last Price Paid": "$51.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012617",
        "Product Status": "Active"
    },
    {
        "Root": "10009958",
        "LV": "1",
        "Item Description": "LIQ ORANGE CURACAO BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001670",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009958",
        "Product Status": "Active"
    },
    {
        "Root": "10001296",
        "LV": "1",
        "Item Description": "JUICE MM CRANBERRY MINUTE PAK 4/90OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006330",
        "Last Price Paid": "$33.55",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001296",
        "Product Status": "Active"
    },
    {
        "Root": "10014440",
        "LV": "1",
        "Item Description": "KALE BABY MIX 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014440001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07BKM-9A",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014440",
        "Product Status": "Active"
    },
    {
        "Root": "10038347",
        "LV": "1",
        "Item Description": "PEPPERS MIXED RED AND YELLOW DICED 1/2'",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038347001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-017-4",
        "Last Price Paid": "$3.10",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038347",
        "Product Status": "Active"
    },
    {
        "Root": "10027734",
        "LV": "1",
        "Item Description": "BAG DISNEY KID'S MEAL PAPER 6X3.63X12.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "738872",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027734",
        "Product Status": "Active"
    },
    {
        "Root": "10102915",
        "LV": "1",
        "Item Description": "KOSHER BSC MACARONI AND CHEESE 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102915",
        "Product Status": "Active"
    },
    {
        "Root": "10004451",
        "LV": "1",
        "Item Description": "MIX FREESTYLE COCA COLA 4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4051706",
        "Last Price Paid": "$64.52",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004451",
        "Product Status": "Active"
    },
    {
        "Root": "10009083",
        "LV": "1",
        "Item Description": "CHAMP KRUG GRAND CUV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597485",
        "Last Price Paid": "$169.08",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009083",
        "Product Status": "Active"
    },
    {
        "Root": "10001604",
        "LV": "1",
        "Item Description": "CARROT MATCHSTICK 2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511999",
        "Last Price Paid": "$28.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001604",
        "Product Status": "Active"
    },
    {
        "Root": "10022776",
        "LV": "1",
        "Item Description": "YEAST NUTRITIONAL 8 OZ BOBS RED MILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022776001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY912",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022776",
        "Product Status": "Active"
    },
    {
        "Root": "10003599",
        "LV": "1",
        "Item Description": "CANDY STARBUST ORIG 2.07Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "58343",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003599",
        "Product Status": "Active"
    },
    {
        "Root": "10002131",
        "LV": "1",
        "Item Description": "CHEESE CREAM PC CUP .75Z/100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.31",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002131",
        "Product Status": "Active"
    },
    {
        "Root": "10009848",
        "LV": "2",
        "Item Description": "BRANDY POIRE MASSENEZ WILL 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009848001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "373235",
        "Last Price Paid": "$50.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009848",
        "Product Status": "Active"
    },
    {
        "Root": "10001473",
        "LV": "3",
        "Item Description": "ONION SPANISH COLOSSAL 50# BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001473003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10001473",
        "Product Status": "Active"
    },
    {
        "Root": "10107293",
        "LV": "1",
        "Item Description": "SPROUTS BRUSSEL SHREDDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107293001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593132",
        "Last Price Paid": "$19.60",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107293",
        "Product Status": "Active"
    },
    {
        "Root": "10000644",
        "LV": "1",
        "Item Description": "BEEF STRIPLOIN 0X1 PRIME ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000644001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0181815",
        "Last Price Paid": "$12.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000644",
        "Product Status": "Active"
    },
    {
        "Root": "10038901",
        "LV": "1",
        "Item Description": "COASTER STAR WARS MW 4 IN ROUND 5 PRINT COMBO PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005095",
        "Last Price Paid": "$0.06",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038901",
        "Product Status": "Active"
    },
    {
        "Root": "10018429",
        "LV": "2",
        "Item Description": "CONTAINER 4CT MUFFIN W/ SHALLOW LID  SLP44SA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018429002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51357103",
        "Last Price Paid": "$0.33",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018429",
        "Product Status": "Active"
    },
    {
        "Root": "10107799",
        "LV": "1",
        "Item Description": "GELATO SOFT SERVE VANILLA 6/0.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-VANB-11-CS6",
        "Last Price Paid": "$11.58",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107799",
        "Product Status": "Active"
    },
    {
        "Root": "10002151",
        "LV": "2",
        "Item Description": "BUTTER BALL UNSALTED .25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002151001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9045473",
        "Last Price Paid": "$0.10",
        "Case Pack": "768",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002151",
        "Product Status": "Active"
    },
    {
        "Root": "10091444",
        "LV": "2",
        "Item Description": "GELATO BLUEBERRY SOFT SERVE MIX (6 HALF GAL JUGS)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091444002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-BLBR-11-CS6",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10091444",
        "Product Status": "Active"
    },
    {
        "Root": "10001356",
        "LV": "1",
        "Item Description": "GINGER PUREE FRESH 6/34OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001356001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001356",
        "Product Status": "Active"
    },
    {
        "Root": "10000382",
        "LV": "1",
        "Item Description": "PEPPER RED SLIVERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-024",
        "Last Price Paid": "$17.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000382",
        "Product Status": "Active"
    },
    {
        "Root": "10002142",
        "LV": "1",
        "Item Description": "BUTTER CHIPS UNSALTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "540",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002142",
        "Product Status": "Active"
    },
    {
        "Root": "10005844",
        "LV": "1",
        "Item Description": "CAKE BAR CHOCOLATE FUDGE NEW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005844001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.45",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005844",
        "Product Status": "Active"
    },
    {
        "Root": "10083956",
        "LV": "1",
        "Item Description": "SB SAUCE SPICED APPLE DRIZZLE 36OZ 6-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11128584",
        "Last Price Paid": "$7.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083956",
        "Product Status": "Active"
    },
    {
        "Root": "10000496",
        "LV": "1",
        "Item Description": "ROLL SWEET RAW FROZEN 2.2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.41",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000496",
        "Product Status": "Active"
    },
    {
        "Root": "10025217",
        "LV": "1",
        "Item Description": "SAUCE CHEESE QUESO BLANCO POUCH 60OZ 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.38",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025217",
        "Product Status": "Active"
    },
    {
        "Root": "10002064",
        "LV": "1",
        "Item Description": "BEAN LENTIL GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002064001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002064",
        "Product Status": "Active"
    },
    {
        "Root": "10029148",
        "LV": "1",
        "Item Description": "VEGETABLE SHREDDED RAINBOW CARROT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480864",
        "Last Price Paid": "$20.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029148",
        "Product Status": "Active"
    },
    {
        "Root": "10085835",
        "LV": "2",
        "Item Description": "KIT CAMPFIRE RECREATION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085835001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "50CAMP",
        "Last Price Paid": "$4.95",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10085835",
        "Product Status": "Active"
    },
    {
        "Root": "10041045",
        "LV": "1",
        "Item Description": "PLATE PAPER 6.875 IN MARY BLAIR 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041045",
        "Product Status": "Active"
    },
    {
        "Root": "10033996",
        "LV": "1",
        "Item Description": "TRANSFER SHEET CHEETAH GOLD BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1004229",
        "Last Price Paid": "$5.26",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033996",
        "Product Status": "Active"
    },
    {
        "Root": "10005609",
        "LV": "1",
        "Item Description": "APPLE BLOSSOM 5.6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.61",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005609",
        "Product Status": "Active"
    },
    {
        "Root": "10002454",
        "LV": "1",
        "Item Description": "TOFUTTI VAN DAIRY FREE, PINT8,EA,7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.16",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002454",
        "Product Status": "Active"
    },
    {
        "Root": "10002454",
        "LV": "1",
        "Item Description": "TOFUTTI VAN DAIRY FREE, PINT8,EA,7",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002454001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1201",
        "Last Price Paid": "$3.16",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002454",
        "Product Status": "Active"
    },
    {
        "Root": "10083497",
        "LV": "2",
        "Item Description": "MIX SOFT SERVE MANGO LASSI GELATO 6 JUG 1/2 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083497002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-MLAS-11-MX-C",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083497",
        "Product Status": "Active"
    },
    {
        "Root": "10012036",
        "LV": "1",
        "Item Description": "TOPPER ELSA WITH TAB 3 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22448",
        "Last Price Paid": "$1.00",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012036",
        "Product Status": "Active"
    },
    {
        "Root": "10007371",
        "LV": "1",
        "Item Description": "CHARD DECOY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "559506",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007371",
        "Product Status": "Active"
    },
    {
        "Root": "10002166",
        "LV": "1",
        "Item Description": "ICE CREAM BAR MICKEY PREMIUM 20CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002166001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.54",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002166",
        "Product Status": "Active"
    },
    {
        "Root": "10000395",
        "LV": "1",
        "Item Description": "APPLE GRANNY WEDGE SKN/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-025",
        "Last Price Paid": "$20.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000395",
        "Product Status": "Active"
    },
    {
        "Root": "10000395",
        "LV": "1",
        "Item Description": "APPLE GRANNY WEDGE SKN/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000395001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "562789",
        "Last Price Paid": "$20.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000395",
        "Product Status": "Active"
    },
    {
        "Root": "10012570",
        "LV": "1",
        "Item Description": "PEA BLOOMS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MPBS-33",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012570",
        "Product Status": "Active"
    },
    {
        "Root": "10100911",
        "LV": "1",
        "Item Description": "ALYSSUM FLOWER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22029",
        "Last Price Paid": "$0.29",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100911",
        "Product Status": "Active"
    },
    {
        "Root": "10009813",
        "LV": "3",
        "Item Description": "RUM SIESTA KEY WHITE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009813002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9286226",
        "Last Price Paid": "$15.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009813",
        "Product Status": "Active"
    },
    {
        "Root": "10003533",
        "LV": "1",
        "Item Description": "COFFEE WHL BEAN DECF FLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003533",
        "Product Status": "Active"
    },
    {
        "Root": "10007588",
        "LV": "3",
        "Item Description": "CHARD PAUL HOBBS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007588002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007588",
        "Product Status": "Active"
    },
    {
        "Root": "10007588",
        "LV": "3",
        "Item Description": "CHARD PAUL HOBBS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007588002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "549117",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007588",
        "Product Status": "Active"
    },
    {
        "Root": "10093531",
        "LV": "1",
        "Item Description": "WINE WHITE RIESLING JH SELBACH QBA RED LABEL 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093531",
        "Product Status": "Active"
    },
    {
        "Root": "10000327",
        "LV": "1",
        "Item Description": "TOMATO CHERRY RED 200 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410400",
        "Last Price Paid": "$0.14",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000327",
        "Product Status": "Active"
    },
    {
        "Root": "10010101",
        "LV": "1",
        "Item Description": "BEER TSINGTAO CHINA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10185",
        "Last Price Paid": "$1.64",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010101",
        "Product Status": "Active"
    },
    {
        "Root": "10003246",
        "LV": "1",
        "Item Description": "SYRUP SF FRNCH VANILA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003246",
        "Product Status": "Active"
    },
    {
        "Root": "10095992",
        "LV": "1",
        "Item Description": "SYRUP MIX BAR AMARENA CHERRY 34OZ/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "9157061",
        "Last Price Paid": "$11.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095992",
        "Product Status": "Active"
    },
    {
        "Root": "10000534",
        "LV": "1",
        "Item Description": "BREAD ROLL CIABATTA SQUARE FLOUR DUSTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000534",
        "Product Status": "Active"
    },
    {
        "Root": "10040030",
        "LV": "1",
        "Item Description": "COFFEE RIVIERA RESORT BLEND REG WHOLE BEAN 5/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.11",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040030",
        "Product Status": "Active"
    },
    {
        "Root": "10039672",
        "LV": "1",
        "Item Description": "BEER CEDERBERG IPA 340 ML BOTTLE 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "899327000059",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039672",
        "Product Status": "Active"
    },
    {
        "Root": "10024476",
        "LV": "1",
        "Item Description": "COMPOUND CRANBERRY 1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024476001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024476",
        "Product Status": "Active"
    },
    {
        "Root": "10023805",
        "LV": "1",
        "Item Description": "CAULIFLOWER ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "704960",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023805",
        "Product Status": "Active"
    },
    {
        "Root": "10007505",
        "LV": "1",
        "Item Description": "CHARD GRAMONA CUV CAVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007505001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "390865",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007505",
        "Product Status": "Active"
    },
    {
        "Root": "10037628",
        "LV": "1",
        "Item Description": "YUCCA CHIPS ROOT VEGETABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037628001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "35-007",
        "Last Price Paid": "",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037628",
        "Product Status": "Active"
    },
    {
        "Root": "10032917",
        "LV": "1",
        "Item Description": "SYRUP HAWAIIAN ISLAND MONIN LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5246",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032917",
        "Product Status": "Active"
    },
    {
        "Root": "10033435",
        "LV": "1",
        "Item Description": "MIX CAKE CHOC GF BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101520",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033435",
        "Product Status": "Active"
    },
    {
        "Root": "10083471",
        "LV": "1",
        "Item Description": "BOWL 16OZ REVERSIBLE COMBO CLEAR/CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083471",
        "Product Status": "Active"
    },
    {
        "Root": "10003529",
        "LV": "1",
        "Item Description": "COFFEE JIKO WHL BN DCF 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003529",
        "Product Status": "Active"
    },
    {
        "Root": "10103490",
        "LV": "1",
        "Item Description": "SAUERKRAUT SHREDDED 6/#10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9330077",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103490",
        "Product Status": "Active"
    },
    {
        "Root": "10036009",
        "LV": "1",
        "Item Description": "GIN RANSOM OLD TOM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "185106",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036009",
        "Product Status": "Active"
    },
    {
        "Root": "10033750",
        "LV": "1",
        "Item Description": "SUGAR DELUXE HOLLY JOLLY ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033750001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033750",
        "Product Status": "Active"
    },
    {
        "Root": "10039919",
        "LV": "1",
        "Item Description": "BEER BUDWIEISER DISCOVERY 12Z BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039919",
        "Product Status": "Active"
    },
    {
        "Root": "10037234",
        "LV": "1",
        "Item Description": "WEDDING CAKE 9IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10005788",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037234",
        "Product Status": "Active"
    },
    {
        "Root": "10004081",
        "LV": "1",
        "Item Description": "SHELL GRAHAM 3.3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004081",
        "Product Status": "Active"
    },
    {
        "Root": "10034934",
        "LV": "1",
        "Item Description": "SWEDISH STYLE HAM 6 9LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034934001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034934",
        "Product Status": "Active"
    },
    {
        "Root": "10015120",
        "LV": "1",
        "Item Description": "FRUIT KABOB 2OZ STR/CAN/PINE/HD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592015",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015120",
        "Product Status": "Active"
    },
    {
        "Root": "10037523",
        "LV": "1",
        "Item Description": "BILLY TOOLED WHITE COMPOUND 5X3.7CM 840PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233190000",
        "Last Price Paid": "",
        "Case Pack": "840",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037523",
        "Product Status": "Active"
    },
    {
        "Root": "10040898",
        "LV": "1",
        "Item Description": "POWERADE MELON 28 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.49",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040898",
        "Product Status": "Active"
    },
    {
        "Root": "10034308",
        "LV": "1",
        "Item Description": "MACE GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164039",
        "Last Price Paid": "$27.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034308",
        "Product Status": "Active"
    },
    {
        "Root": "10035190",
        "LV": "1",
        "Item Description": "DONUT POWDERED BLUEBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10005793",
        "Last Price Paid": "$3.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035190",
        "Product Status": "Active"
    },
    {
        "Root": "10106652",
        "LV": "1",
        "Item Description": "COLLINS STICKS CASE OF 200 (5 BAGS) - DIMENSIONS 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$320.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106652",
        "Product Status": "Active"
    },
    {
        "Root": "10029347",
        "LV": "1",
        "Item Description": "MAYONNAISE REAL HELLMANS SQUEEZE BOTTLE 12/20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9836222",
        "Last Price Paid": "$6.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029347",
        "Product Status": "Active"
    },
    {
        "Root": "10002856",
        "LV": "2",
        "Item Description": "MAYONNAISE IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002856002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002856",
        "Product Status": "Active"
    },
    {
        "Root": "10036288",
        "LV": "2",
        "Item Description": "SB CROISSANT HAM & CHEESE (WDW) 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036288002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11130008",
        "Last Price Paid": "$1.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036288",
        "Product Status": "Active"
    },
    {
        "Root": "10001976",
        "LV": "1",
        "Item Description": "FLOWER SORBET BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05MSP024",
        "Last Price Paid": "$31.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001976",
        "Product Status": "Active"
    },
    {
        "Root": "10032895",
        "LV": "1",
        "Item Description": "WHITE CHO HALF SPHERE PIXAR LOGO 2.6 CM 63O PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032895001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "623218",
        "Last Price Paid": "$0.29",
        "Case Pack": "630",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032895",
        "Product Status": "Active"
    },
    {
        "Root": "10013595",
        "LV": "1",
        "Item Description": "SB 5LB THANKSGIVING BLEND 1-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "191311",
        "Last Price Paid": "$53.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013595",
        "Product Status": "Active"
    },
    {
        "Root": "10003639",
        "LV": "1",
        "Item Description": "SB TPPNG CNNMN DLC SPRNKL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "193890",
        "Last Price Paid": "$12.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003639",
        "Product Status": "Active"
    },
    {
        "Root": "10082488",
        "LV": "1",
        "Item Description": "TRANSFER SHEETS GIRAFFE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW65666",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082488",
        "Product Status": "Active"
    },
    {
        "Root": "10001065",
        "LV": "1",
        "Item Description": "GROUPER FILET FRZN FIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001065001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "70-33100",
        "Last Price Paid": "$15.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001065",
        "Product Status": "Active"
    },
    {
        "Root": "10001065",
        "LV": "1",
        "Item Description": "GROUPER FILET FRZN FIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001065001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001065",
        "Product Status": "Active"
    },
    {
        "Root": "10001065",
        "LV": "1",
        "Item Description": "GROUPER FILET FRZN FIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001065001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6487805",
        "Last Price Paid": "$15.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001065",
        "Product Status": "Active"
    },
    {
        "Root": "10003660",
        "LV": "1",
        "Item Description": "OIL TRUFFLE WHITE 8.45Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003660001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01270",
        "Last Price Paid": "$22.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003660",
        "Product Status": "Active"
    },
    {
        "Root": "10017126",
        "LV": "1",
        "Item Description": "SYRUP MONIN CUPCAKE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017126",
        "Product Status": "Active"
    },
    {
        "Root": "10016433",
        "LV": "2",
        "Item Description": "SAUCE BBQ PIKNIK 2/1.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016433001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.34",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016433",
        "Product Status": "Active"
    },
    {
        "Root": "10001989",
        "LV": "1",
        "Item Description": "LETTUCE MIXED PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09MXP-33",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001989",
        "Product Status": "Active"
    },
    {
        "Root": "10000269",
        "LV": "1",
        "Item Description": "HERB CILANTRO MICRO LOCAL 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490069",
        "Last Price Paid": "$14.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000269",
        "Product Status": "Active"
    },
    {
        "Root": "10000372",
        "LV": "3",
        "Item Description": "SQUASH BUTTERNUT DICED 3/4 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000372002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481848",
        "Last Price Paid": "$16.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10000372",
        "Product Status": "Active"
    },
    {
        "Root": "10000372",
        "LV": "3",
        "Item Description": "SQUASH BUTTERNUT DICED 3/4 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000372002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "40-005-34",
        "Last Price Paid": "$16.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10000372",
        "Product Status": "Active"
    },
    {
        "Root": "10001295",
        "LV": "1",
        "Item Description": "JUICE MM APPLE MINUTE PAK 4/90OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001295001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006501",
        "Last Price Paid": "$31.17",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001295",
        "Product Status": "Active"
    },
    {
        "Root": "10012488",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-WHITE-2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "23217",
        "Last Price Paid": "$0.35",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012488",
        "Product Status": "Active"
    },
    {
        "Root": "10031545",
        "LV": "1",
        "Item Description": "SYRUP BLUE RASBERRY FLAVOR BURST 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "61-45154",
        "Last Price Paid": "$17.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031545",
        "Product Status": "Active"
    },
    {
        "Root": "10002068",
        "LV": "1",
        "Item Description": "BEET RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002068001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002068",
        "Product Status": "Active"
    },
    {
        "Root": "10001831",
        "LV": "1",
        "Item Description": "LETTUCE BULLS BEET BLOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001831001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450240",
        "Last Price Paid": "$21.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001831",
        "Product Status": "Active"
    },
    {
        "Root": "10001831",
        "LV": "1",
        "Item Description": "LETTUCE BULLS BEET BLOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001831001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001831",
        "Product Status": "Active"
    },
    {
        "Root": "10002055",
        "LV": "1",
        "Item Description": "CABBAGE NAPPA-POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002055001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002055",
        "Product Status": "Active"
    },
    {
        "Root": "10010154",
        "LV": "2",
        "Item Description": "MIX BLOODY MARY MRS T CAN 5.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010154001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "973839",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010154",
        "Product Status": "Active"
    },
    {
        "Root": "10092931",
        "LV": "1",
        "Item Description": "JERKY BEEF, 12 / 2.5 OZ BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1358765",
        "Last Price Paid": "$6.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092931",
        "Product Status": "Active"
    },
    {
        "Root": "10009838",
        "LV": "2",
        "Item Description": "COGNAC HENNESSY PARADIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009838001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "363492",
        "Last Price Paid": "$912.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009838",
        "Product Status": "Active"
    },
    {
        "Root": "10040587",
        "LV": "1",
        "Item Description": "CHEESE SHREDDED SWISS 4/5 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040587001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.48",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040587",
        "Product Status": "Active"
    },
    {
        "Root": "10092791",
        "LV": "2",
        "Item Description": "BOX CHANDRILLA WHITE 6CT GIFT W RIBBON PULL 97CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092791002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510617",
        "Last Price Paid": "$2.10",
        "Case Pack": "97",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092791",
        "Product Status": "Active"
    },
    {
        "Root": "10013169",
        "LV": "1",
        "Item Description": "SAUCE CHOC GHIRARDELLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013169",
        "Product Status": "Active"
    },
    {
        "Root": "10004950",
        "LV": "1",
        "Item Description": "SPRAY CHOCO FREEZE 4/15Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "{CHEF RUBBER{",
        "Last Price Paid": "$21.02",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004950",
        "Product Status": "Active"
    },
    {
        "Root": "10007953",
        "LV": "1",
        "Item Description": "MERLOT WATERBROOK RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9134500",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007953",
        "Product Status": "Active"
    },
    {
        "Root": "10104634",
        "LV": "3",
        "Item Description": "CRABMEAT COLLASAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104634003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "9801012",
        "Last Price Paid": "$32.46",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10104634",
        "Product Status": "Active"
    },
    {
        "Root": "10003976",
        "LV": "1",
        "Item Description": "SALT SEA MALDON SMKD 4.4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-45190",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003976",
        "Product Status": "Active"
    },
    {
        "Root": "10078393",
        "LV": "1",
        "Item Description": "TEQUILA CASA DRAGONES ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10078393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "965804",
        "Last Price Paid": "$119.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10078393",
        "Product Status": "Active"
    },
    {
        "Root": "10100370",
        "LV": "1",
        "Item Description": "CONTAINER SQUARED TAMPER EVIDENT HINGED  32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091158",
        "Last Price Paid": "$0.63",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100370",
        "Product Status": "Active"
    },
    {
        "Root": "10091578",
        "LV": "1",
        "Item Description": "WINE WHITE TESSELAARSDAL CHARDONNAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9615267",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091578",
        "Product Status": "Active"
    },
    {
        "Root": "10004695",
        "LV": "1",
        "Item Description": "SEEDS PUMPKIN SEEDS SHELLED R/S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004695001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "320810",
        "Last Price Paid": "$125.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004695",
        "Product Status": "Active"
    },
    {
        "Root": "10009530",
        "LV": "4",
        "Item Description": "WHSKY SMOKY BLKBRRY MOONSHIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009530003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10009530",
        "Product Status": "Active"
    },
    {
        "Root": "10005467",
        "LV": "1",
        "Item Description": "PEA SPLIT DRIED GREEN 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5841390",
        "Last Price Paid": "$26.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005467",
        "Product Status": "Active"
    },
    {
        "Root": "10016042",
        "LV": "1",
        "Item Description": "BREAD BUN KAISER MINI BRIOCHE 3\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016042",
        "Product Status": "Active"
    },
    {
        "Root": "10107429",
        "LV": "1",
        "Item Description": "COCKTAIL RTD HORNITOS MARGARITA 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "918485",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107429",
        "Product Status": "Active"
    },
    {
        "Root": "10004365",
        "LV": "1",
        "Item Description": "SPRAY VELVET RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV008R",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004365",
        "Product Status": "Active"
    },
    {
        "Root": "10000351",
        "LV": "1",
        "Item Description": "RASPBERRY RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BERRB",
        "Last Price Paid": "$2.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000351",
        "Product Status": "Active"
    },
    {
        "Root": "10000351",
        "LV": "1",
        "Item Description": "RASPBERRY RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "12003",
        "Last Price Paid": "$2.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000351",
        "Product Status": "Active"
    },
    {
        "Root": "10000351",
        "LV": "1",
        "Item Description": "RASPBERRY RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481536",
        "Last Price Paid": "$2.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000351",
        "Product Status": "Active"
    },
    {
        "Root": "10108508",
        "LV": "1",
        "Item Description": "SAUCE CHEESE CHEDDAR WHITE 4/140 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4971024",
        "Last Price Paid": "$21.01",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108508",
        "Product Status": "Active"
    },
    {
        "Root": "10102157",
        "LV": "1",
        "Item Description": "PIZZA PEPPERONI FROZEN BIG EDGE NY FAMILY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102157001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.14",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102157",
        "Product Status": "Active"
    },
    {
        "Root": "10002628",
        "LV": "1",
        "Item Description": "SPICE SALT SEASONED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002628",
        "Product Status": "Active"
    },
    {
        "Root": "10014281",
        "LV": "1",
        "Item Description": "WATER SAN PELLGN PET 16.9 SPARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014281001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6519441",
        "Last Price Paid": "$1.01",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014281",
        "Product Status": "Active"
    },
    {
        "Root": "10015367",
        "LV": "1",
        "Item Description": "DEC ROSE TEA SMALL PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015367001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKK PK",
        "Last Price Paid": "$1.12",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015367",
        "Product Status": "Active"
    },
    {
        "Root": "10033035",
        "LV": "1",
        "Item Description": "FEATHER RED 2 1/2 X 7/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW23105",
        "Last Price Paid": "$0.65",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033035",
        "Product Status": "Active"
    },
    {
        "Root": "10041499",
        "LV": "1",
        "Item Description": "SNACK GUACAMOLE W/TORTILLA CHIP 12/2.8OZ CUP SABRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5343456",
        "Last Price Paid": "$2.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041499",
        "Product Status": "Active"
    },
    {
        "Root": "10031468",
        "LV": "1",
        "Item Description": "PLATE 7X7 BIO'N'CHIC SUGARCANE FOTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11210048",
        "Last Price Paid": "$0.31",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031468",
        "Product Status": "Active"
    },
    {
        "Root": "10006285",
        "LV": "1",
        "Item Description": "BOARD CAKE FULL X 1/2 INCH GO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006285001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ESF1...{ENJAY{",
        "Last Price Paid": "$6.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006285",
        "Product Status": "Active"
    },
    {
        "Root": "10088727",
        "LV": "1",
        "Item Description": "SYRUP MONIN BLACK CURRANT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1005",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088727",
        "Product Status": "Active"
    },
    {
        "Root": "10031994",
        "LV": "1",
        "Item Description": "MONIN SPICE BROWN SUGAR L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031994",
        "Product Status": "Active"
    },
    {
        "Root": "10004140",
        "LV": "1",
        "Item Description": "CANDY TOPPING BUTTERFINGR CHOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004140001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "525230",
        "Last Price Paid": "$67.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004140",
        "Product Status": "Active"
    },
    {
        "Root": "10005938",
        "LV": "1",
        "Item Description": "BAGEL BLUEBERRY LARGE 4 OUNCE 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005938",
        "Product Status": "Active"
    },
    {
        "Root": "10028031",
        "LV": "2",
        "Item Description": "RUM BAYOU SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028031002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9690103",
        "Last Price Paid": "$12.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028031",
        "Product Status": "Active"
    },
    {
        "Root": "10028031",
        "LV": "2",
        "Item Description": "RUM BAYOU SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028031002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9690103",
        "Last Price Paid": "$12.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028031",
        "Product Status": "Active"
    },
    {
        "Root": "10003420",
        "LV": "1",
        "Item Description": "SYRUP PEACH MONIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003420001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1036",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003420",
        "Product Status": "Active"
    },
    {
        "Root": "10015588",
        "LV": "1",
        "Item Description": "PISTACHIO PEELED SICILY BRONTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015588",
        "Product Status": "Active"
    },
    {
        "Root": "10002318",
        "LV": "1",
        "Item Description": "SORBET RASPBERRY NSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "NSATR-002",
        "Last Price Paid": "$21.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002318",
        "Product Status": "Active"
    },
    {
        "Root": "10010142",
        "LV": "1",
        "Item Description": "BEER ODOULS NON ALC CAN 12PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010142",
        "Product Status": "Active"
    },
    {
        "Root": "10093310",
        "LV": "1",
        "Item Description": "COCKTAIL RTD AUSTIN RBY RED GRPFRUIT 24/250ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555790",
        "Last Price Paid": "$2.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093310",
        "Product Status": "Active"
    },
    {
        "Root": "10018530",
        "LV": "1",
        "Item Description": "GEL COLOR TEAL GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22508",
        "Last Price Paid": "$4.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018530",
        "Product Status": "Active"
    },
    {
        "Root": "10008293",
        "LV": "1",
        "Item Description": "CAB SAUV JORDAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008293001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547503",
        "Last Price Paid": "$41.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008293",
        "Product Status": "Active"
    },
    {
        "Root": "10036404",
        "LV": "1",
        "Item Description": "MEXICO CAFE FEMENINO ORGANIC GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "C234G-54J",
        "Last Price Paid": "$4.14",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036404",
        "Product Status": "Active"
    },
    {
        "Root": "10087594",
        "LV": "1",
        "Item Description": "FILLING PIE PEACH RTU 6/#10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1016856",
        "Last Price Paid": "$18.58",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087594",
        "Product Status": "Active"
    },
    {
        "Root": "10087594",
        "LV": "1",
        "Item Description": "FILLING PIE PEACH RTU 6/#10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "411478406",
        "Last Price Paid": "$18.58",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087594",
        "Product Status": "Active"
    },
    {
        "Root": "10087594",
        "LV": "1",
        "Item Description": "FILLING PIE PEACH RTU 6/#10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8330995",
        "Last Price Paid": "$18.58",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087594",
        "Product Status": "Active"
    },
    {
        "Root": "10037131",
        "LV": "1",
        "Item Description": "BOWL BATHTUB ACRYLIC WHITE TRANSLUCENT 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.79",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037131",
        "Product Status": "Active"
    },
    {
        "Root": "10018091",
        "LV": "1",
        "Item Description": "CAB SAUV ODJFELL ARMADOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "243280",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018091",
        "Product Status": "Active"
    },
    {
        "Root": "10110109",
        "LV": "1",
        "Item Description": "POPCORN ROSEMARY PEPPERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110109",
        "Product Status": "Active"
    },
    {
        "Root": "10083980",
        "LV": "1",
        "Item Description": "TRAY MODULAR BLACK 8 IN X 11 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083980001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "15517",
        "Last Price Paid": "",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083980",
        "Product Status": "Active"
    },
    {
        "Root": "10006033",
        "LV": "1",
        "Item Description": "CLIP LIGHT-UP ARIEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006033001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006033",
        "Product Status": "Active"
    },
    {
        "Root": "10008200",
        "LV": "2",
        "Item Description": "SHERRY COLOMBO DRY MARSALA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008200001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001479",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008200",
        "Product Status": "Active"
    },
    {
        "Root": "10109309",
        "LV": "1",
        "Item Description": "PETITE CRUDITE SAMPLER 50 CT PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PCS-33-L",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109309",
        "Product Status": "Active"
    },
    {
        "Root": "10001935",
        "LV": "1",
        "Item Description": "PEAR HALVES PEELED CORED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510535",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001935",
        "Product Status": "Active"
    },
    {
        "Root": "10109430",
        "LV": "1",
        "Item Description": "BUCKET POP PREM DONALD MUNCHLING 64OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109430",
        "Product Status": "Active"
    },
    {
        "Root": "10009206",
        "LV": "2",
        "Item Description": "CHARD ROMBAUER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009206001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1431840",
        "Last Price Paid": "$29.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009206",
        "Product Status": "Active"
    },
    {
        "Root": "10107876",
        "LV": "1",
        "Item Description": "POPCORN USA MDSE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "POPCORNUSA",
        "Last Price Paid": "$78.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107876",
        "Product Status": "Active"
    },
    {
        "Root": "10009538",
        "LV": "1",
        "Item Description": "VODKA PAU MAUI HAWAII 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009538",
        "Product Status": "Active"
    },
    {
        "Root": "10009538",
        "LV": "1",
        "Item Description": "VODKA PAU MAUI HAWAII 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "58169",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009538",
        "Product Status": "Active"
    },
    {
        "Root": "10011081",
        "LV": "1",
        "Item Description": "CHEESECAKE MINI PLAIN 1.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1510",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011081",
        "Product Status": "Active"
    },
    {
        "Root": "10033877",
        "LV": "1",
        "Item Description": "AC5173 ASST COLORS PAINT BRUSHES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11825001",
        "Last Price Paid": "$0.05",
        "Case Pack": "3456",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033877",
        "Product Status": "Active"
    },
    {
        "Root": "10001538",
        "LV": "1",
        "Item Description": "BEAN LENTIL GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401041",
        "Last Price Paid": "$43.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001538",
        "Product Status": "Active"
    },
    {
        "Root": "10086673",
        "LV": "1",
        "Item Description": "SB REINDEER CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11128442",
        "Last Price Paid": "$1.08",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086673",
        "Product Status": "Active"
    },
    {
        "Root": "10017124",
        "LV": "1",
        "Item Description": "GREENS MUSTARD LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482380",
        "Last Price Paid": "$5.08",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017124",
        "Product Status": "Active"
    },
    {
        "Root": "10003983",
        "LV": "1",
        "Item Description": "PEPPERCORN PINK 10OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003983001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164102",
        "Last Price Paid": "$8.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003983",
        "Product Status": "Active"
    },
    {
        "Root": "10017977",
        "LV": "1",
        "Item Description": "DOLIN BLANC CHAMBLY VERMOUTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017977",
        "Product Status": "Active"
    },
    {
        "Root": "10017016",
        "LV": "1",
        "Item Description": "SHELL VERY CHOC 2.2\" ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD305",
        "Last Price Paid": "$0.36",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017016",
        "Product Status": "Active"
    },
    {
        "Root": "10034090",
        "LV": "1",
        "Item Description": "GOOSEBERRIES WHL LT SYRUP 15OZ 8CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034090001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "665358",
        "Last Price Paid": "$3.60",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034090",
        "Product Status": "Active"
    },
    {
        "Root": "10013583",
        "LV": "1",
        "Item Description": "POINSETTA SM WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NTKWH",
        "Last Price Paid": "$7.09",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013583",
        "Product Status": "Active"
    },
    {
        "Root": "10013402",
        "LV": "1",
        "Item Description": "NECTAR GOYA PEAR 12-42 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013402001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013402",
        "Product Status": "Active"
    },
    {
        "Root": "10004586",
        "LV": "1",
        "Item Description": "POTATO BOATS 1.25Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "987149",
        "Last Price Paid": "$0.22",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004586",
        "Product Status": "Active"
    },
    {
        "Root": "10002821",
        "LV": "1",
        "Item Description": "VINEGAR APPLE CIDER 4% 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002821",
        "Product Status": "Active"
    },
    {
        "Root": "10022523",
        "LV": "1",
        "Item Description": "DUMPLING EDAMEME VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6869961",
        "Last Price Paid": "$0.27",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022523",
        "Product Status": "Active"
    },
    {
        "Root": "10025282",
        "LV": "1",
        "Item Description": "ONION YELLOW MED UBU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406034",
        "Last Price Paid": "$9.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025282",
        "Product Status": "Active"
    },
    {
        "Root": "10003723",
        "LV": "2",
        "Item Description": "SAUCE SWT & SOUR GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003723002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1017284",
        "Last Price Paid": "$20.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003723",
        "Product Status": "Active"
    },
    {
        "Root": "10080768",
        "LV": "1",
        "Item Description": "CIDER FLORIDA BEER CARIBE PINEAPPLE 5.16GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080768001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60306",
        "Last Price Paid": "$83.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10080768",
        "Product Status": "Active"
    },
    {
        "Root": "10003193",
        "LV": "1",
        "Item Description": "COKE CLASSIC 8Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "049000418347",
        "Last Price Paid": "$0.92",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003193",
        "Product Status": "Active"
    },
    {
        "Root": "10039869",
        "LV": "1",
        "Item Description": "BEER IPA GOOSE ISLAND CAN 12OZ 6 PACK 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039869",
        "Product Status": "Active"
    },
    {
        "Root": "10003898",
        "LV": "1",
        "Item Description": "YUZU KOSHU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003898",
        "Product Status": "Active"
    },
    {
        "Root": "10025160",
        "LV": "1",
        "Item Description": "FLOWER HIBISCUS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480691",
        "Last Price Paid": "$0.27",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025160",
        "Product Status": "Active"
    },
    {
        "Root": "10011806",
        "LV": "1",
        "Item Description": "CHICKEN BONES BREAST FRAME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011806001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.66",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011806",
        "Product Status": "Active"
    },
    {
        "Root": "10036382",
        "LV": "1",
        "Item Description": "GIN XORIGURER MAHON 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "100205-LTR",
        "Last Price Paid": "$35.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036382",
        "Product Status": "Active"
    },
    {
        "Root": "10033627",
        "LV": "1",
        "Item Description": "VODKA KETEL BOTANICAL PEACH ORANGE 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "904689",
        "Last Price Paid": "$24.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033627",
        "Product Status": "Active"
    },
    {
        "Root": "10088345",
        "LV": "1",
        "Item Description": "CONTAINER PAPER TO GO W/ WINDOW BLACK 42Z 8X5.5X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "111531",
        "Last Price Paid": "$0.87",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088345",
        "Product Status": "Active"
    },
    {
        "Root": "10010083",
        "LV": "1",
        "Item Description": "BEER MILLER LT ALUM 16Z 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.05",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010083",
        "Product Status": "Active"
    },
    {
        "Root": "10001525",
        "LV": "1",
        "Item Description": "HERB THYME MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22164",
        "Last Price Paid": "$22.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001525",
        "Product Status": "Active"
    },
    {
        "Root": "10001525",
        "LV": "1",
        "Item Description": "HERB THYME MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MTH-33-S",
        "Last Price Paid": "$22.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001525",
        "Product Status": "Active"
    },
    {
        "Root": "10000312",
        "LV": "1",
        "Item Description": "HERBAL SENSATION FARMERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$75.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000312",
        "Product Status": "Active"
    },
    {
        "Root": "10025143",
        "LV": "1",
        "Item Description": "SPOON WHITE MED PP TASTING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11600160",
        "Last Price Paid": "$0.01",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025143",
        "Product Status": "Active"
    },
    {
        "Root": "10018134",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON HARAS DE PIRQUE HUSSONET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "412282",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018134",
        "Product Status": "Active"
    },
    {
        "Root": "10000700",
        "LV": "1",
        "Item Description": "BEEF MANHATTAN STRIP 6Z CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000700001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190151",
        "Last Price Paid": "$23.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000700",
        "Product Status": "Active"
    },
    {
        "Root": "10010303",
        "LV": "2",
        "Item Description": "BEER SINGHA 4/6PK 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010303001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "8530",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010303",
        "Product Status": "Active"
    },
    {
        "Root": "10000624",
        "LV": "1",
        "Item Description": "BEEF FLANK STEAK CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000624001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0000051",
        "Last Price Paid": "$6.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000624",
        "Product Status": "Active"
    },
    {
        "Root": "10003179",
        "LV": "1",
        "Item Description": "SYRUP COFF WILDBERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003179001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5114",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003179",
        "Product Status": "Active"
    },
    {
        "Root": "10036329",
        "LV": "1",
        "Item Description": "BBQ DL521028 2.5 BBQ ROUND LABEL BLACK ON KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001087",
        "Last Price Paid": "$0.06",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036329",
        "Product Status": "Active"
    },
    {
        "Root": "10005916",
        "LV": "2",
        "Item Description": "BREAD CHALLAH NO BRAID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005916002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "0102D",
        "Last Price Paid": "$7.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005916",
        "Product Status": "Active"
    },
    {
        "Root": "10041598",
        "LV": "1",
        "Item Description": "SB LIDS STRAWLESS 12OZ 1000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041598",
        "Product Status": "Active"
    },
    {
        "Root": "10002747",
        "LV": "1",
        "Item Description": "SYRUP MM JUICE APPLE ORCHARD 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$97.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002747",
        "Product Status": "Active"
    },
    {
        "Root": "10032833",
        "LV": "2",
        "Item Description": "SUGAR-SMALL DAISIES ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032833002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "16664",
        "Last Price Paid": "$0.05",
        "Case Pack": "675",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032833",
        "Product Status": "Active"
    },
    {
        "Root": "10032123",
        "LV": "1",
        "Item Description": "MIX FREESTYLE PT 1 PIBB 23OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032123001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "5131735",
        "Last Price Paid": "$53.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032123",
        "Product Status": "Active"
    },
    {
        "Root": "10004127",
        "LV": "1",
        "Item Description": "CHOCOLATE CARAMELIZED 32% VALRHONA DULCEY 6.6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004127001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "9458",
        "Last Price Paid": "$83.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004127",
        "Product Status": "Active"
    },
    {
        "Root": "10094645",
        "LV": "1",
        "Item Description": "CANDY SMARTIES CANDY ROLLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094645",
        "Product Status": "Active"
    },
    {
        "Root": "10033849",
        "LV": "2",
        "Item Description": "CABERNET SAUVIGNON DAOU PASO ROBLES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033849002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "218398",
        "Last Price Paid": "$14.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033849",
        "Product Status": "Active"
    },
    {
        "Root": "10083056",
        "LV": "1",
        "Item Description": "BACON APPLEWOOD 1/2IN THICK SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083056001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3432120",
        "Last Price Paid": "$6.12",
        "Case Pack": "0.2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083056",
        "Product Status": "Active"
    },
    {
        "Root": "10107491",
        "LV": "1",
        "Item Description": "BEER GSB CLOUD 9 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15543",
        "Last Price Paid": "$209.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107491",
        "Product Status": "Active"
    },
    {
        "Root": "10076275",
        "LV": "1",
        "Item Description": "SB STRAWBERRY ACAI 4X REFRESHER BASE 1 LITER 6-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011125331",
        "Last Price Paid": "$10.31",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076275",
        "Product Status": "Active"
    },
    {
        "Root": "10027973",
        "LV": "1",
        "Item Description": "COCOA BUTTER COLOR SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302530C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027973",
        "Product Status": "Active"
    },
    {
        "Root": "10029409",
        "LV": "1",
        "Item Description": "WINE TOKARA SHIRAZ 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "834353",
        "Last Price Paid": "$16.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029409",
        "Product Status": "Active"
    },
    {
        "Root": "10040175",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK BROWN MODELING SATIN ICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "11016",
        "Last Price Paid": "$34.12",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040175",
        "Product Status": "Active"
    },
    {
        "Root": "10002775",
        "LV": "1",
        "Item Description": "SYRUP FCB COKE 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$107.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002775",
        "Product Status": "Active"
    },
    {
        "Root": "10028596",
        "LV": "1",
        "Item Description": "CHEESE SAUCE BEER CHEDDAR ARTISAN STYLE BOIL IN BA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4503294",
        "Last Price Paid": "$14.53",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028596",
        "Product Status": "Active"
    },
    {
        "Root": "10004925",
        "LV": "1",
        "Item Description": "PUREE PEAR 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004925001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "735...{BOIRON{",
        "Last Price Paid": "$9.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004925",
        "Product Status": "Active"
    },
    {
        "Root": "10008259",
        "LV": "1",
        "Item Description": "CHAMP LAURNT ROSE BRT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008259001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "448383",
        "Last Price Paid": "$55.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008259",
        "Product Status": "Active"
    },
    {
        "Root": "10018065",
        "LV": "1",
        "Item Description": "RIESLING CHATEAU MONTELENA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9652244",
        "Last Price Paid": "$26.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018065",
        "Product Status": "Active"
    },
    {
        "Root": "10002970",
        "LV": "1",
        "Item Description": "TEA ENGLISH BRKFST 25CT B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002970001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002970",
        "Product Status": "Active"
    },
    {
        "Root": "10040094",
        "LV": "3",
        "Item Description": "PIZZA PEPPERONI DIGIORNO TDCR 6.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040094002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.16",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10040094",
        "Product Status": "Active"
    },
    {
        "Root": "10040121",
        "LV": "1",
        "Item Description": "CHICK LIVERS FRESH 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040121001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.88",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040121",
        "Product Status": "Active"
    },
    {
        "Root": "10109548",
        "LV": "1",
        "Item Description": "BEER KEG SCHOFFERHOFFER GRAPEFRUIT HEFE 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$174.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109548",
        "Product Status": "Active"
    },
    {
        "Root": "10036958",
        "LV": "1",
        "Item Description": "SB SPINACH & FETA WRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11104507",
        "Last Price Paid": "$2.00",
        "Case Pack": "21",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036958",
        "Product Status": "Active"
    },
    {
        "Root": "10044781",
        "LV": "1",
        "Item Description": "SPICE ITALIAN SEASONING BULK 28OZ SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760991",
        "Last Price Paid": "$29.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044781",
        "Product Status": "Active"
    },
    {
        "Root": "10032412",
        "LV": "1",
        "Item Description": "SB DARK CARAMEL 63OZ JUG 4EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11089603",
        "Last Price Paid": "$24.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032412",
        "Product Status": "Active"
    },
    {
        "Root": "10002729",
        "LV": "1",
        "Item Description": "CEREAL FRUIT LOOP RDCD SUGAR BOWL 96CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.63",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002729",
        "Product Status": "Active"
    },
    {
        "Root": "10094764",
        "LV": "1",
        "Item Description": "CLAMSHELL PAPER KRAFT PIZZA 55722  8.18X8.28 2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51301112",
        "Last Price Paid": "$0.33",
        "Case Pack": "184",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094764",
        "Product Status": "Active"
    },
    {
        "Root": "10100312",
        "LV": "2",
        "Item Description": "CUP DESSERT DISPOSABLE PET CDS 8OZ CLASSIC 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100312002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$93.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10100312",
        "Product Status": "Active"
    },
    {
        "Root": "10002866",
        "LV": "1",
        "Item Description": "COCA COLA CLASSIC 20OZ BOTTLE 24 PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "143883",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002866",
        "Product Status": "Active"
    },
    {
        "Root": "10009969",
        "LV": "1",
        "Item Description": "LIQ X FUSION 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009969",
        "Product Status": "Active"
    },
    {
        "Root": "10040867",
        "LV": "1",
        "Item Description": "BEAN BLACK DRIED 1/50LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2618767",
        "Last Price Paid": "$50.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040867",
        "Product Status": "Active"
    },
    {
        "Root": "10002814",
        "LV": "2",
        "Item Description": "SUGAR PACKET 2M/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002814002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002814",
        "Product Status": "Active"
    },
    {
        "Root": "10036316",
        "LV": "1",
        "Item Description": "ROSE CHATEAU MARGUI SKYWALKER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "909213",
        "Last Price Paid": "$28.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036316",
        "Product Status": "Active"
    },
    {
        "Root": "10102890",
        "LV": "1",
        "Item Description": "PORK SHLDR SECRETO IBERICO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102890001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7026430",
        "Last Price Paid": "$24.07",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102890",
        "Product Status": "Active"
    },
    {
        "Root": "10039883",
        "LV": "2",
        "Item Description": "COLOR FONDANT SATIN VANILLA SILVER SHIMMER 10-2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039883002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B0050",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039883",
        "Product Status": "Active"
    },
    {
        "Root": "10044825",
        "LV": "1",
        "Item Description": "COOKIE VANILLA WAFER 12/11OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1594357",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044825",
        "Product Status": "Active"
    },
    {
        "Root": "10034351",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC BABICH BLACK LABEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034351",
        "Product Status": "Active"
    },
    {
        "Root": "10002177",
        "LV": "1",
        "Item Description": "JUICE BAR LEMONADE STRAWBERRY OLAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002177",
        "Product Status": "Active"
    },
    {
        "Root": "10039744",
        "LV": "1",
        "Item Description": "COOKIE SUGAR 3IN WH FONDANT MICKEY CUST PRNT 25/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039744001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "3\"CUSTMCK",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039744",
        "Product Status": "Active"
    },
    {
        "Root": "10081361",
        "LV": "2",
        "Item Description": "CHICKEN THIGHS MARINATED APPLE CIDER VINEGAR 40LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081361001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "43",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10081361",
        "Product Status": "Active"
    },
    {
        "Root": "10008116",
        "LV": "2",
        "Item Description": "SANGIOVESE SILVERADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008116001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "52823",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008116",
        "Product Status": "Active"
    },
    {
        "Root": "10002633",
        "LV": "1",
        "Item Description": "SPICE SAFFRON 1/1 OZ.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5642632",
        "Last Price Paid": "$51.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002633",
        "Product Status": "Active"
    },
    {
        "Root": "10005108",
        "LV": "1",
        "Item Description": "ESPRESSO DCF BLND 1/5LB B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005108",
        "Product Status": "Active"
    },
    {
        "Root": "10013044",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON PEPPERBRIDGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9086272",
        "Last Price Paid": "$46.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013044",
        "Product Status": "Active"
    },
    {
        "Root": "10001304",
        "LV": "1",
        "Item Description": "JUICE SIMPLY ORANGE CHILLED 24/11.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001304001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "002500008091",
        "Last Price Paid": "$1.07",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001304",
        "Product Status": "Active"
    },
    {
        "Root": "10001736",
        "LV": "1",
        "Item Description": "ASPARAGUS PENCIL THIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ASP11",
        "Last Price Paid": "$29.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001736",
        "Product Status": "Active"
    },
    {
        "Root": "10001736",
        "LV": "1",
        "Item Description": "ASPARAGUS PENCIL THIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401015",
        "Last Price Paid": "$29.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001736",
        "Product Status": "Active"
    },
    {
        "Root": "10009223",
        "LV": "1",
        "Item Description": "DOLCE FARNIENTE 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587544",
        "Last Price Paid": "$42.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009223",
        "Product Status": "Active"
    },
    {
        "Root": "10006925",
        "LV": "3",
        "Item Description": "POUILLY FUISSE G DUBOEUF FLOWER LABEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006925002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16637",
        "Last Price Paid": "$26.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10006925",
        "Product Status": "Active"
    },
    {
        "Root": "10007067",
        "LV": "1",
        "Item Description": "MOSCATO PAOLO SARACCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "571074",
        "Last Price Paid": "$11.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007067",
        "Product Status": "Active"
    },
    {
        "Root": "10029567",
        "LV": "1",
        "Item Description": "OAT BRAN GF BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029567001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101697",
        "Last Price Paid": "$3.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029567",
        "Product Status": "Active"
    },
    {
        "Root": "10008272",
        "LV": "1",
        "Item Description": "RIES CH MICH EROICA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "598195",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008272",
        "Product Status": "Active"
    },
    {
        "Root": "10002041",
        "LV": "1",
        "Item Description": "LETTUCE BABY LOLA ROSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480079",
        "Last Price Paid": "$0.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002041",
        "Product Status": "Active"
    },
    {
        "Root": "10002041",
        "LV": "1",
        "Item Description": "LETTUCE BABY LOLA ROSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002041",
        "Product Status": "Active"
    },
    {
        "Root": "10004954",
        "LV": "1",
        "Item Description": "CRACKER BREADSTICK GARLIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004954001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BS102...{VIGO{",
        "Last Price Paid": "$1.14",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004954",
        "Product Status": "Active"
    },
    {
        "Root": "10006153",
        "LV": "2",
        "Item Description": "SPOON MED WT SMARTSTOCK B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006153002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006153",
        "Product Status": "Active"
    },
    {
        "Root": "10008209",
        "LV": "3",
        "Item Description": "RIES SELBACH BERN SPT BAD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008209002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16542",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008209",
        "Product Status": "Active"
    },
    {
        "Root": "10010192",
        "LV": "1",
        "Item Description": "MIX BAR GRENADINE LTR 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010192001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2312957",
        "Last Price Paid": "$2.64",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010192",
        "Product Status": "Active"
    },
    {
        "Root": "10103941",
        "LV": "1",
        "Item Description": "PICKLE DILL BREADED SPEAR FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068816",
        "Vendor": "SYSCO",
        "Vendor Item Nb": "9455999",
        "Last Price Paid": "$12.59",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103941",
        "Product Status": "Active"
    },
    {
        "Root": "10103941",
        "LV": "1",
        "Item Description": "PICKLE DILL BREADED SPEAR FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9686059",
        "Last Price Paid": "$12.59",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103941",
        "Product Status": "Active"
    },
    {
        "Root": "10038580",
        "LV": "1",
        "Item Description": "CHICKEN CORDON BLEU APPETIZER 160/1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7943132",
        "Last Price Paid": "$0.26",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038580",
        "Product Status": "Active"
    },
    {
        "Root": "10040297",
        "LV": "1",
        "Item Description": "RED BLEND TRIENNES AUGUSTE PROVENCE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040297001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "548266",
        "Last Price Paid": "$14.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040297",
        "Product Status": "Active"
    },
    {
        "Root": "10002768",
        "LV": "1",
        "Item Description": "SYRUP TEA GOLD PEAK UNSWEET 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002768001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002768",
        "Product Status": "Active"
    },
    {
        "Root": "10034830",
        "LV": "2",
        "Item Description": "MSK ULTRATEX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034830002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87482",
        "Last Price Paid": "$30.73",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034830",
        "Product Status": "Active"
    },
    {
        "Root": "10102629",
        "LV": "1",
        "Item Description": "CHEESE BRIE SPREAD TUB 2/3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9404374",
        "Last Price Paid": "$20.07",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102629",
        "Product Status": "Active"
    },
    {
        "Root": "10003566",
        "LV": "1",
        "Item Description": "SPICE CORIANDER GRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003566",
        "Product Status": "Active"
    },
    {
        "Root": "10001302",
        "LV": "1",
        "Item Description": "JUICE MM ORANGE CHILLED 8/59OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "002500004784",
        "Last Price Paid": "$3.59",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001302",
        "Product Status": "Active"
    },
    {
        "Root": "10031610",
        "LV": "1",
        "Item Description": "OLLI TOSCANO SALAMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "21673",
        "Last Price Paid": "$7.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031610",
        "Product Status": "Active"
    },
    {
        "Root": "10004832",
        "LV": "1",
        "Item Description": "CRUMBS BREAD PLAIN 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6052245",
        "Last Price Paid": "$45.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004832",
        "Product Status": "Active"
    },
    {
        "Root": "10010965",
        "LV": "1",
        "Item Description": "WATER VITAMIN ACAI BLUEBERRY BAG IN BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$51.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010965",
        "Product Status": "Active"
    },
    {
        "Root": "10108091",
        "LV": "1",
        "Item Description": "CONTAINER LARGE SAUCE PAN COPPER-BLACK PLAST F&W E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000529576",
        "Vendor": "CLARK ASSOCIATES",
        "Vendor Item Nb": "708PS34400C",
        "Last Price Paid": "$0.79",
        "Case Pack": "84",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108091",
        "Product Status": "Active"
    },
    {
        "Root": "10091225",
        "LV": "1",
        "Item Description": "WINE SUSANA BALBO CAB SAUV ARGENTINA 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091225001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9189094",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091225",
        "Product Status": "Active"
    },
    {
        "Root": "10004861",
        "LV": "1",
        "Item Description": "ALMOND MARCONA RSTD/SLTD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598900",
        "Last Price Paid": "$147.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004861",
        "Product Status": "Active"
    },
    {
        "Root": "10016302",
        "LV": "1",
        "Item Description": "MANGO DICED 3/4\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481358",
        "Last Price Paid": "$39.23",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016302",
        "Product Status": "Active"
    },
    {
        "Root": "10031816",
        "LV": "1",
        "Item Description": "RUM CRUZAN SINGLE BARREL 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "981393",
        "Last Price Paid": "$32.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031816",
        "Product Status": "Active"
    },
    {
        "Root": "10018114",
        "LV": "1",
        "Item Description": "MALBEC TIKAL NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018114001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9266732",
        "Last Price Paid": "$15.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018114",
        "Product Status": "Active"
    },
    {
        "Root": "10040294",
        "LV": "1",
        "Item Description": "BRUNELLO IL POGGIONE 2013 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "583040",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040294",
        "Product Status": "Active"
    },
    {
        "Root": "10000261",
        "LV": "1",
        "Item Description": "LETTUCE ROMAINE BABY ARTIS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482209",
        "Last Price Paid": "$23.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000261",
        "Product Status": "Active"
    },
    {
        "Root": "10101529",
        "LV": "1",
        "Item Description": "SAKE FUKUCHO MOON ON WATER JDG 720ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9223120",
        "Last Price Paid": "$26.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101529",
        "Product Status": "Active"
    },
    {
        "Root": "10044403",
        "LV": "1",
        "Item Description": "SPICE ONION GRANULATED PLASTIC SHAKER BOTTLE 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760371",
        "Last Price Paid": "$10.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044403",
        "Product Status": "Active"
    },
    {
        "Root": "10028729",
        "LV": "1",
        "Item Description": "SUSHI EBI SHRIMP 5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "17548",
        "Last Price Paid": "$12.95",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028729",
        "Product Status": "Active"
    },
    {
        "Root": "10106371",
        "LV": "1",
        "Item Description": "THICKENER, LOCUST BEAN GUM SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106371001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2433242",
        "Last Price Paid": "$122.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106371",
        "Product Status": "Active"
    },
    {
        "Root": "10002126",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR SHREDDED FINE/FANCY 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002126001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.86",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002126",
        "Product Status": "Active"
    },
    {
        "Root": "10001135",
        "LV": "1",
        "Item Description": "TUNA SUSHI FROZEN SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001135001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "70-67011",
        "Last Price Paid": "$6.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001135",
        "Product Status": "Active"
    },
    {
        "Root": "10001135",
        "LV": "1",
        "Item Description": "TUNA SUSHI FROZEN SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001135001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001135",
        "Product Status": "Active"
    },
    {
        "Root": "10001135",
        "LV": "1",
        "Item Description": "TUNA SUSHI FROZEN SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001135001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6205081",
        "Last Price Paid": "$6.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001135",
        "Product Status": "Active"
    },
    {
        "Root": "10004860",
        "LV": "2",
        "Item Description": "CHIPS BANANA 12/5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004860002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "208136",
        "Last Price Paid": "$1.37",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004860",
        "Product Status": "Active"
    },
    {
        "Root": "10001671",
        "LV": "1",
        "Item Description": "POTATO BAKER 40 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001671",
        "Product Status": "Active"
    },
    {
        "Root": "10001671",
        "LV": "1",
        "Item Description": "POTATO BAKER 40 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408214",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001671",
        "Product Status": "Active"
    },
    {
        "Root": "10033748",
        "LV": "1",
        "Item Description": "SPRINKLES SPRING PASTEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21053",
        "Last Price Paid": "$4.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033748",
        "Product Status": "Active"
    },
    {
        "Root": "10000449",
        "LV": "1",
        "Item Description": "ROLL, DINNER GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000449",
        "Product Status": "Active"
    },
    {
        "Root": "10037893",
        "LV": "2",
        "Item Description": "CHIPS LENTIL SEA SALT 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037893002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F14167W",
        "Last Price Paid": "$1.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037893",
        "Product Status": "Active"
    },
    {
        "Root": "10002018",
        "LV": "1",
        "Item Description": "ONION RED DICED 3/4IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002018001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514041",
        "Last Price Paid": "$13.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002018",
        "Product Status": "Active"
    },
    {
        "Root": "10002018",
        "LV": "1",
        "Item Description": "ONION RED DICED 3/4IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002018001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "03-002-34",
        "Last Price Paid": "$13.39",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002018",
        "Product Status": "Active"
    },
    {
        "Root": "10002959",
        "LV": "1",
        "Item Description": "TEA MIXED BERRY BLK 120CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002959",
        "Product Status": "Active"
    },
    {
        "Root": "10019273",
        "LV": "2",
        "Item Description": "6'' CLEAR PET CLAMSHELL FOOD CONTAINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019273002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51357821",
        "Last Price Paid": "$0.19",
        "Case Pack": "330",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019273",
        "Product Status": "Active"
    },
    {
        "Root": "10002432",
        "LV": "1",
        "Item Description": "GELATO CHOCOLATE 3 GAL TU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "IG-039.3",
        "Last Price Paid": "$25.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002432",
        "Product Status": "Active"
    },
    {
        "Root": "10023547",
        "LV": "1",
        "Item Description": "TOMATILLO PINEAPPLE 12/ 1/2 PINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17PT-11",
        "Last Price Paid": "$1.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023547",
        "Product Status": "Active"
    },
    {
        "Root": "10011565",
        "LV": "2",
        "Item Description": "CROUTON SEASONED CLASSIC 4/2.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011565002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6237160",
        "Last Price Paid": "$6.11",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011565",
        "Product Status": "Active"
    },
    {
        "Root": "10005919",
        "LV": "1",
        "Item Description": "BAGEL PLAIN LARGE 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "558937",
        "Last Price Paid": "$0.32",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005919",
        "Product Status": "Active"
    },
    {
        "Root": "10012485",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-WHITE-1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22905",
        "Last Price Paid": "$0.23",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012485",
        "Product Status": "Active"
    },
    {
        "Root": "10090462",
        "LV": "1",
        "Item Description": "DUCK LEG CONFIT 8-10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090462001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4301322",
        "Last Price Paid": "$11.38",
        "Case Pack": "0.542",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090462",
        "Product Status": "Active"
    },
    {
        "Root": "10018786",
        "LV": "1",
        "Item Description": "PLUOTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "295093",
        "Last Price Paid": "$65.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018786",
        "Product Status": "Active"
    },
    {
        "Root": "10000126",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN CERTIFIED ANGUS STEAK READY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000126001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190105",
        "Last Price Paid": "$29.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000126",
        "Product Status": "Active"
    },
    {
        "Root": "10003527",
        "LV": "1",
        "Item Description": "SYRUP COFF PUMPKIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003527",
        "Product Status": "Active"
    },
    {
        "Root": "10020216",
        "LV": "1",
        "Item Description": "PINOT NOIR SKYWALKER SOMMITA VINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "573497",
        "Last Price Paid": "$50.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020216",
        "Product Status": "Active"
    },
    {
        "Root": "10003582",
        "LV": "1",
        "Item Description": "COFFEE ROMA NESPRESSO POD 200 CT - ORIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.66",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003582",
        "Product Status": "Active"
    },
    {
        "Root": "10041041",
        "LV": "1",
        "Item Description": "BOWL PAPER 24 OZ WHITE 400CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51187021",
        "Last Price Paid": "$0.21",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041041",
        "Product Status": "Active"
    },
    {
        "Root": "10011306",
        "LV": "1",
        "Item Description": "RICE 5 GRAIN BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8609539",
        "Last Price Paid": "$7.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011306",
        "Product Status": "Active"
    },
    {
        "Root": "10037876",
        "LV": "1",
        "Item Description": "TG0080 RED WHITE CHECK LOAF LINER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003735",
        "Last Price Paid": "$0.08",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037876",
        "Product Status": "Active"
    },
    {
        "Root": "10027751",
        "LV": "1",
        "Item Description": "BEANS GARBANZO DRIED 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480785",
        "Last Price Paid": "$23.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027751",
        "Product Status": "Active"
    },
    {
        "Root": "10095416",
        "LV": "1",
        "Item Description": "BEER KEG COOPERS PACIFIC PALE 50LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "270844",
        "Last Price Paid": "$243.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095416",
        "Product Status": "Active"
    },
    {
        "Root": "10036754",
        "LV": "2",
        "Item Description": "AQUAFABA POWDER VOR 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036754002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "21797",
        "Last Price Paid": "$310.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036754",
        "Product Status": "Active"
    },
    {
        "Root": "10001095",
        "LV": "1",
        "Item Description": "SHRIMP COCONUT BRD 16/20 SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001095001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125515",
        "Last Price Paid": "$5.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001095",
        "Product Status": "Active"
    },
    {
        "Root": "10002865",
        "LV": "1",
        "Item Description": "MUSTARD DICKENSON 1.4Z 72",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.39",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002865",
        "Product Status": "Active"
    },
    {
        "Root": "10001083",
        "LV": "1",
        "Item Description": "LOBSTER SHELL SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001083001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "LWW100005",
        "Last Price Paid": "$1.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001083",
        "Product Status": "Active"
    },
    {
        "Root": "10001083",
        "LV": "1",
        "Item Description": "LOBSTER SHELL SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001083001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "69-01602",
        "Last Price Paid": "$1.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001083",
        "Product Status": "Active"
    },
    {
        "Root": "10001083",
        "LV": "1",
        "Item Description": "LOBSTER SHELL SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001083001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6470501",
        "Last Price Paid": "$1.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001083",
        "Product Status": "Active"
    },
    {
        "Root": "10015961",
        "LV": "1",
        "Item Description": "CANDY M&M PLAIN 36/1.69OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534052",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015961",
        "Product Status": "Active"
    },
    {
        "Root": "10004548",
        "LV": "1",
        "Item Description": "TOPPING BUTTERSCOTCH 6/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4002283",
        "Last Price Paid": "$12.07",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004548",
        "Product Status": "Active"
    },
    {
        "Root": "10000762",
        "LV": "1",
        "Item Description": "SAUSAGE MERGUEZ ROPE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000762001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000762",
        "Product Status": "Active"
    },
    {
        "Root": "10010506",
        "LV": "1",
        "Item Description": "CHTEAU TOUR DE SEGUR 2011 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "563599",
        "Last Price Paid": "$24.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010506",
        "Product Status": "Active"
    },
    {
        "Root": "10027152",
        "LV": "1",
        "Item Description": "HERBAL SENSATION MICRO V&A PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14VMHS-33",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027152",
        "Product Status": "Active"
    },
    {
        "Root": "10094834",
        "LV": "1",
        "Item Description": "PASTE CHILI 136 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY192",
        "Last Price Paid": "$21.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094834",
        "Product Status": "Active"
    },
    {
        "Root": "10001191",
        "LV": "1",
        "Item Description": "BASS STRIPED FILET SKN OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001191001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "4910642",
        "Last Price Paid": "$26.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001191",
        "Product Status": "Active"
    },
    {
        "Root": "10001191",
        "LV": "1",
        "Item Description": "BASS STRIPED FILET SKN OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001191001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001191",
        "Product Status": "Active"
    },
    {
        "Root": "10001191",
        "LV": "1",
        "Item Description": "BASS STRIPED FILET SKN OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001191001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00045",
        "Last Price Paid": "$26.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001191",
        "Product Status": "Active"
    },
    {
        "Root": "10003774",
        "LV": "1",
        "Item Description": "VINEGAR TARRAGON 17OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-00320",
        "Last Price Paid": "$16.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003774",
        "Product Status": "Active"
    },
    {
        "Root": "10038816",
        "LV": "1",
        "Item Description": "MUFFIN CHOCOLATE CHIP LARGE 6.5 OUNCE INDV WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038816",
        "Product Status": "Active"
    },
    {
        "Root": "10090519",
        "LV": "1",
        "Item Description": "BEER DRAFT 3 DAUGHTERS STRAWBERRY BLONDE NITRO ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090519001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76400",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090519",
        "Product Status": "Active"
    },
    {
        "Root": "10024496",
        "LV": "1",
        "Item Description": "CHOC MILK SNOWGLOBE W/CHARACTERS IN CELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW624750",
        "Last Price Paid": "",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024496",
        "Product Status": "Active"
    },
    {
        "Root": "10033811",
        "LV": "2",
        "Item Description": "CHEESE AMERICAN GRANA PARMESAN 18MO BELGIOIOSO 16#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033811001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10033811",
        "Product Status": "Active"
    },
    {
        "Root": "10030517",
        "LV": "1",
        "Item Description": "FLOUR SORGHUM G/F",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030517001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "2597334",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030517",
        "Product Status": "Active"
    },
    {
        "Root": "10041214",
        "LV": "1",
        "Item Description": "BUCKET BASE MANDALORIAN 64 OZ 60/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041214",
        "Product Status": "Active"
    },
    {
        "Root": "10012062",
        "LV": "1",
        "Item Description": "PINOT NOIR BODEGA CHACRA BARDA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "EC907140",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012062",
        "Product Status": "Active"
    },
    {
        "Root": "10106654",
        "LV": "1",
        "Item Description": "BEEF CORNED ROUND SLICED 20 CT / 1.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106654001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "3190006",
        "Last Price Paid": "",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106654",
        "Product Status": "Active"
    },
    {
        "Root": "10027294",
        "LV": "1",
        "Item Description": "POWDER BLACK FAT DISPERSIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "306037E",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027294",
        "Product Status": "Active"
    },
    {
        "Root": "10009583",
        "LV": "1",
        "Item Description": "TEQUILA J CUERVO GOLD LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12453",
        "Last Price Paid": "$16.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009583",
        "Product Status": "Active"
    },
    {
        "Root": "10016964",
        "LV": "1",
        "Item Description": "CHIP POTATO BBQ INDIV 54 /1.5 OZ BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5601653",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016964",
        "Product Status": "Active"
    },
    {
        "Root": "10002745",
        "LV": "1",
        "Item Description": "SYRUP GINGER ALE SEAGRAMS 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002745",
        "Product Status": "Active"
    },
    {
        "Root": "10088242",
        "LV": "2",
        "Item Description": "SPICE SEA SALT EGYPTIAN GANACHERY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088242002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$94.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088242",
        "Product Status": "Active"
    },
    {
        "Root": "10041255",
        "LV": "1",
        "Item Description": "LETTUCE SHREDDED ICEBERG 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "801790",
        "Last Price Paid": "$6.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041255",
        "Product Status": "Active"
    },
    {
        "Root": "10006913",
        "LV": "1",
        "Item Description": "CHIANTI SOGNO DITALIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006913",
        "Product Status": "Active"
    },
    {
        "Root": "10029031",
        "LV": "1",
        "Item Description": "CAULIFLOWER FLORET MULTI COLOR GREEN PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5090992",
        "Last Price Paid": "$4.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029031",
        "Product Status": "Active"
    },
    {
        "Root": "10018432",
        "LV": "1",
        "Item Description": "INSERT CUP CLR 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7762628",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018432",
        "Product Status": "Active"
    },
    {
        "Root": "10002551",
        "LV": "1",
        "Item Description": "WASABI ROOT FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002551001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405066",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002551",
        "Product Status": "Active"
    },
    {
        "Root": "10002551",
        "LV": "1",
        "Item Description": "WASABI ROOT FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002551001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ROOTWASLB",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002551",
        "Product Status": "Active"
    },
    {
        "Root": "10016151",
        "LV": "1",
        "Item Description": "GRAPE CHAMPAGNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260408",
        "Last Price Paid": "$47.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016151",
        "Product Status": "Active"
    },
    {
        "Root": "10000763",
        "LV": "1",
        "Item Description": "PANCETTA BACON ROLLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000763001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3400515",
        "Last Price Paid": "$6.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000763",
        "Product Status": "Active"
    },
    {
        "Root": "10015883",
        "LV": "1",
        "Item Description": "LEAVES SHISO RED MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401141",
        "Last Price Paid": "$15.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015883",
        "Product Status": "Active"
    },
    {
        "Root": "10022505",
        "LV": "1",
        "Item Description": "MALBEC RED SCHOONER VOYAGE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022505001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578600",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022505",
        "Product Status": "Active"
    },
    {
        "Root": "10002206",
        "LV": "2",
        "Item Description": "YOGURT GREEK BLBRRY LITE AND FIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002206001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002206",
        "Product Status": "Active"
    },
    {
        "Root": "10011865",
        "LV": "1",
        "Item Description": "SYRUP BANANA MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011865",
        "Product Status": "Active"
    },
    {
        "Root": "10034446",
        "LV": "1",
        "Item Description": "NERELLO MASCALESE PIETRADOLCE ETNA ROSSO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578310",
        "Last Price Paid": "$17.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034446",
        "Product Status": "Active"
    },
    {
        "Root": "10038964",
        "LV": "1",
        "Item Description": "CAKE SPONGE FULL SHEET LAYER  CELEBRATION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "072319",
        "Last Price Paid": "$14.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038964",
        "Product Status": "Active"
    },
    {
        "Root": "10023583",
        "LV": "1",
        "Item Description": "GELATO CARAMEL SEA SALT 4/HALF GALLON TUBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-CASS-08-CS4",
        "Last Price Paid": "$10.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023583",
        "Product Status": "Active"
    },
    {
        "Root": "10034351",
        "LV": "2",
        "Item Description": "SAUVIGNON BLANC BABICH BLACK LABEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034351002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "116122",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034351",
        "Product Status": "Active"
    },
    {
        "Root": "10033574",
        "LV": "1",
        "Item Description": "ROSE SPARKLING SANTA MARGHERTIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "17584",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033574",
        "Product Status": "Active"
    },
    {
        "Root": "10023623",
        "LV": "1",
        "Item Description": "LOAF, MINI CHOC RASBERRY GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10015604",
        "Last Price Paid": "$6.06",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023623",
        "Product Status": "Active"
    },
    {
        "Root": "10001979",
        "LV": "1",
        "Item Description": "ROMAINE GREEN BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09GRO-9",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001979",
        "Product Status": "Active"
    },
    {
        "Root": "10007384",
        "LV": "2",
        "Item Description": "BLND RED AMUSE BOUCHE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007384001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9625249",
        "Last Price Paid": "$200.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007384",
        "Product Status": "Active"
    },
    {
        "Root": "10001695",
        "LV": "1",
        "Item Description": "BEAN CRANBERRY DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001695001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001695",
        "Product Status": "Active"
    },
    {
        "Root": "10107877",
        "LV": "1",
        "Item Description": "MARMALADE ORANGE BULK 8.25LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107877",
        "Product Status": "Active"
    },
    {
        "Root": "10012947",
        "LV": "1",
        "Item Description": "SEA SPEAR MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14SSP-33",
        "Last Price Paid": "$36.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012947",
        "Product Status": "Active"
    },
    {
        "Root": "10009997",
        "LV": "1",
        "Item Description": "LIQ BLUE CURACAO BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009997",
        "Product Status": "Active"
    },
    {
        "Root": "10005788",
        "LV": "1",
        "Item Description": "MUFFIN, BLUEBERRY GLUTEN FREE UDI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "781038",
        "Last Price Paid": "$4.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005788",
        "Product Status": "Active"
    },
    {
        "Root": "10038001",
        "LV": "1",
        "Item Description": "TEMPRANILLO MUGA RSV SELECCION ESPECIAL RIOJA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001047",
        "Last Price Paid": "$34.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038001",
        "Product Status": "Active"
    },
    {
        "Root": "10009880",
        "LV": "3",
        "Item Description": "PORT DOW 10YR TAWNY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009880002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9360343",
        "Last Price Paid": "$28.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009880",
        "Product Status": "Active"
    },
    {
        "Root": "10088169",
        "LV": "1",
        "Item Description": "CIDER DRAFT 3 DAUGHTERS CRANBERRY 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99926",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088169",
        "Product Status": "Active"
    },
    {
        "Root": "10091415",
        "LV": "1",
        "Item Description": "WINE WHITE BLEND SAVAGE SOUTH AFRICA 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091415001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "349856",
        "Last Price Paid": "$34.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091415",
        "Product Status": "Active"
    },
    {
        "Root": "10004821",
        "LV": "1",
        "Item Description": "POPCORN BUTTER 12CT 4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9317353",
        "Last Price Paid": "$2.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004821",
        "Product Status": "Active"
    },
    {
        "Root": "10035841",
        "LV": "1",
        "Item Description": "CORN CHULPE S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "461352",
        "Last Price Paid": "$101.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035841",
        "Product Status": "Active"
    },
    {
        "Root": "10002363",
        "LV": "1",
        "Item Description": "CHEESE FRESH GOAT LOG LAURA CHENEL 3/2 LB LOGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "30521",
        "Last Price Paid": "$23.15",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002363",
        "Product Status": "Active"
    },
    {
        "Root": "10000861",
        "LV": "1",
        "Item Description": "CHICKEN SKINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000861001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4200017",
        "Last Price Paid": "$1.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000861",
        "Product Status": "Active"
    },
    {
        "Root": "10008073",
        "LV": "2",
        "Item Description": "BLND ELLIS LEFT BANK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008073001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "976534",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008073",
        "Product Status": "Active"
    },
    {
        "Root": "10000180",
        "LV": "2",
        "Item Description": "LIME MEXI 175 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000180002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.34",
        "Case Pack": "175",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000180",
        "Product Status": "Active"
    },
    {
        "Root": "10009129",
        "LV": "1",
        "Item Description": "PINOT NOIR KING EST DOM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "161617",
        "Last Price Paid": "$47.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009129",
        "Product Status": "Active"
    },
    {
        "Root": "10044385",
        "LV": "1",
        "Item Description": "PASTRY, EPCOT, BAKLAVA, PISTACHIO 60/1.7OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3455878",
        "Last Price Paid": "$0.73",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044385",
        "Product Status": "Active"
    },
    {
        "Root": "10037596",
        "LV": "1",
        "Item Description": "MINNIE ET CONTAINER 5X5 SANDWICH BLK RND EDGE 500/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001915",
        "Last Price Paid": "$0.33",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037596",
        "Product Status": "Active"
    },
    {
        "Root": "10107493",
        "LV": "1",
        "Item Description": "HARD SELTZER QUIRK ORANGE PINEAPPLE WHIP 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15914",
        "Last Price Paid": "$194.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107493",
        "Product Status": "Active"
    },
    {
        "Root": "10110668",
        "LV": "1",
        "Item Description": "WINE RED SANGIOVESE RUFFINO 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "417220",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110668",
        "Product Status": "Active"
    },
    {
        "Root": "10030147",
        "LV": "1",
        "Item Description": "ASIAN STEPHANOTIS FILLERS WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AGP101 WH",
        "Last Price Paid": "$2.71",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030147",
        "Product Status": "Active"
    },
    {
        "Root": "10107731",
        "LV": "1",
        "Item Description": "CANDY TWIX MINI 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530150",
        "Last Price Paid": "$0.14",
        "Case Pack": "880",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107731",
        "Product Status": "Active"
    },
    {
        "Root": "10107590",
        "LV": "1",
        "Item Description": "COOKIES PARTAKE LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "00796-8",
        "Last Price Paid": "$3.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107590",
        "Product Status": "Active"
    },
    {
        "Root": "10090633",
        "LV": "1",
        "Item Description": "OLIVE CASTEVETRANO PITTED ITALIAN 2 LB BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-15013",
        "Last Price Paid": "$12.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090633",
        "Product Status": "Active"
    },
    {
        "Root": "10003012",
        "LV": "1",
        "Item Description": "DRESSING RANCH CUP 100/1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "KE0789A1",
        "Last Price Paid": "$0.16",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003012",
        "Product Status": "Active"
    },
    {
        "Root": "10014132",
        "LV": "1",
        "Item Description": "BAG BOPP CLR 3X5 FLAT 1.2ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51131001",
        "Last Price Paid": "$0.04",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014132",
        "Product Status": "Active"
    },
    {
        "Root": "10104599",
        "LV": "1",
        "Item Description": "PASSOVER BSC BRISKET POTATO KUGEL AND VEG 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104599",
        "Product Status": "Active"
    },
    {
        "Root": "10000322",
        "LV": "1",
        "Item Description": "STRAWBERRY CLND & QRTRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480123",
        "Last Price Paid": "$68.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000322",
        "Product Status": "Active"
    },
    {
        "Root": "10000322",
        "LV": "1",
        "Item Description": "STRAWBERRY CLND & QRTRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-031Q",
        "Last Price Paid": "$68.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000322",
        "Product Status": "Active"
    },
    {
        "Root": "10011123",
        "LV": "1",
        "Item Description": "TOMATO PLUM WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011123001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5434519",
        "Last Price Paid": "$6.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011123",
        "Product Status": "Active"
    },
    {
        "Root": "10087656",
        "LV": "1",
        "Item Description": "PICKLE DILL CHIP CRINKLE CUT 1/8IN 2730-3050 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087656001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3839756",
        "Last Price Paid": "$37.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087656",
        "Product Status": "Active"
    },
    {
        "Root": "10001133",
        "LV": "1",
        "Item Description": "TUNA LOIN SASHIMI SK/OFF NO BLOOD LINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001133001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "1919273",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001133",
        "Product Status": "Active"
    },
    {
        "Root": "10001133",
        "LV": "1",
        "Item Description": "TUNA LOIN SASHIMI SK/OFF NO BLOOD LINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001133001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001133",
        "Product Status": "Active"
    },
    {
        "Root": "10001133",
        "LV": "1",
        "Item Description": "TUNA LOIN SASHIMI SK/OFF NO BLOOD LINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001133001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-01225",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001133",
        "Product Status": "Active"
    },
    {
        "Root": "10009849",
        "LV": "1",
        "Item Description": "COGNAC REMY LOUIS XIII 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294094",
        "Last Price Paid": "$2,550.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009849",
        "Product Status": "Active"
    },
    {
        "Root": "10025195",
        "LV": "1",
        "Item Description": "POTATO SWEET CUT CANNED COOKED YAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2950979",
        "Last Price Paid": "$8.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025195",
        "Product Status": "Active"
    },
    {
        "Root": "10009402",
        "LV": "2",
        "Item Description": "REYNEKE WHITE BLEND VINEHUGGER ORGANIC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009402001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9237584",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009402",
        "Product Status": "Active"
    },
    {
        "Root": "10010168",
        "LV": "1",
        "Item Description": "WATER EVIAN SPORT 16.9OZ PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010168",
        "Product Status": "Active"
    },
    {
        "Root": "10110628",
        "LV": "1",
        "Item Description": "BEER KEG BREW HUB SUGAR PLUM 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110628001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "63351",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110628",
        "Product Status": "Active"
    },
    {
        "Root": "10003670",
        "LV": "1",
        "Item Description": "SB 5LB WB FAIR TRD ITLN 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7366",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003670",
        "Product Status": "Active"
    },
    {
        "Root": "10005150",
        "LV": "1",
        "Item Description": "SPRAY SHELLAC CONFECTNRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "21403",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005150",
        "Product Status": "Active"
    },
    {
        "Root": "10006183",
        "LV": "1",
        "Item Description": "BAG COTTON CANDY PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006183",
        "Product Status": "Active"
    },
    {
        "Root": "10037232",
        "LV": "1",
        "Item Description": "WEDDING CAKE TIERED  6 PLUS 9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037232001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10005786",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037232",
        "Product Status": "Active"
    },
    {
        "Root": "10037865",
        "LV": "1",
        "Item Description": "SYRUP TIGER BLOOD RED 4 1GAL 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1283H",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037865",
        "Product Status": "Active"
    },
    {
        "Root": "10036997",
        "LV": "2",
        "Item Description": "BRINE FILTHY OLIVE 12/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036997002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "595375",
        "Last Price Paid": "$2.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036997",
        "Product Status": "Active"
    },
    {
        "Root": "10016556",
        "LV": "1",
        "Item Description": "PEAR DICED 1/4\" SKIN ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481811",
        "Last Price Paid": "$16.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016556",
        "Product Status": "Active"
    },
    {
        "Root": "10035651",
        "LV": "1",
        "Item Description": "PHOTOCAKE INK CARTRIDGE YELLOW XP430 T288XL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22673",
        "Last Price Paid": "$16.91",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035651",
        "Product Status": "Active"
    },
    {
        "Root": "10019056",
        "LV": "1",
        "Item Description": "LUCID ABSINTHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "185353",
        "Last Price Paid": "$44.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019056",
        "Product Status": "Active"
    },
    {
        "Root": "10104130",
        "LV": "1",
        "Item Description": "WINE WHITE RIESLING J LOHR BAY MIST 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "253397",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104130",
        "Product Status": "Active"
    },
    {
        "Root": "10028417",
        "LV": "1",
        "Item Description": "CHEESE HALLOUMI SUBSTITUTE FOR PANEER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "14086",
        "Last Price Paid": "$4.85",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028417",
        "Product Status": "Active"
    },
    {
        "Root": "10028869",
        "LV": "1",
        "Item Description": "DRINK MIX LEMONADE COUNTRY TIME 2 GAL YIELD 15/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "174359",
        "Last Price Paid": "$4.02",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028869",
        "Product Status": "Active"
    },
    {
        "Root": "10008258",
        "LV": "1",
        "Item Description": "CAB SAUV CHIMNEY ROCK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "561055",
        "Last Price Paid": "$65.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008258",
        "Product Status": "Active"
    },
    {
        "Root": "10004476",
        "LV": "1",
        "Item Description": "CANDY M&M PEANUT FUN SIZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004476001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530080",
        "Last Price Paid": "$0.16",
        "Case Pack": "823",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004476",
        "Product Status": "Active"
    },
    {
        "Root": "10002183",
        "LV": "1",
        "Item Description": "CREAM BAKERS SWEET HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.70",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002183",
        "Product Status": "Active"
    },
    {
        "Root": "10103953",
        "LV": "1",
        "Item Description": "GLAZE MIRROR NEUTRAL 8.8LB. PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103953001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA5328",
        "Last Price Paid": "$24.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103953",
        "Product Status": "Active"
    },
    {
        "Root": "10014268",
        "LV": "1",
        "Item Description": "COASTER SKIPPER JUNGLE CRUISE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51731033",
        "Last Price Paid": "$0.08",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014268",
        "Product Status": "Active"
    },
    {
        "Root": "10006416",
        "LV": "1",
        "Item Description": "BOX DISPLAY 6 COMP TEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006416",
        "Product Status": "Active"
    },
    {
        "Root": "10102549",
        "LV": "1",
        "Item Description": "CAVIAR ROYAL BELGIAN  WHITE PEARL 30G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60412500",
        "Last Price Paid": "$110.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102549",
        "Product Status": "Active"
    },
    {
        "Root": "10003469",
        "LV": "1",
        "Item Description": "WRAPPER SPRING ROLL 40X25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2048171",
        "Last Price Paid": "$1.44",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003469",
        "Product Status": "Active"
    },
    {
        "Root": "10028287",
        "LV": "1",
        "Item Description": "BREAD ROLL POTATO PARKERHOUSE 80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "13:PP",
        "Last Price Paid": "$12.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028287",
        "Product Status": "Active"
    },
    {
        "Root": "10106609",
        "LV": "1",
        "Item Description": "SHRIMP KEY WEST PINK 16/20 P&D T/ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106609001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "72-15323",
        "Last Price Paid": "$17.85",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106609",
        "Product Status": "Active"
    },
    {
        "Root": "10028733",
        "LV": "1",
        "Item Description": "CEREAL CHEERIOS WHOLE GRAIN SS BOWL SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2013514",
        "Last Price Paid": "$48.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028733",
        "Product Status": "Active"
    },
    {
        "Root": "10108287",
        "LV": "1",
        "Item Description": "DTD YELLOW SHIMMER SIMPLE SYRUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "YSS-750",
        "Last Price Paid": "$11.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108287",
        "Product Status": "Active"
    },
    {
        "Root": "10001322",
        "LV": "1",
        "Item Description": "CORN CUT IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4328233",
        "Last Price Paid": "$27.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001322",
        "Product Status": "Active"
    },
    {
        "Root": "10001322",
        "LV": "1",
        "Item Description": "CORN CUT IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001322",
        "Product Status": "Active"
    },
    {
        "Root": "10036897",
        "LV": "2",
        "Item Description": "BAKING CUP TURQUOISE SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036897002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24735",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036897",
        "Product Status": "Active"
    },
    {
        "Root": "10001603",
        "LV": "1",
        "Item Description": "TOMATO HEIRLOOM MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "USE SKU",
        "Last Price Paid": "$49.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001603",
        "Product Status": "Active"
    },
    {
        "Root": "10001603",
        "LV": "1",
        "Item Description": "TOMATO HEIRLOOM MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410413",
        "Last Price Paid": "$49.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001603",
        "Product Status": "Active"
    },
    {
        "Root": "10001603",
        "LV": "1",
        "Item Description": "TOMATO HEIRLOOM MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23048",
        "Last Price Paid": "$49.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001603",
        "Product Status": "Active"
    },
    {
        "Root": "10105047",
        "LV": "1",
        "Item Description": "RICE PAPER ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35091",
        "Last Price Paid": "$2.73",
        "Case Pack": "44",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105047",
        "Product Status": "Active"
    },
    {
        "Root": "10032469",
        "LV": "1",
        "Item Description": "BEER KEG SAM ADAMS WICKED HAZY IPA 15.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "51374",
        "Last Price Paid": "$168.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032469",
        "Product Status": "Active"
    },
    {
        "Root": "10021772",
        "LV": "1",
        "Item Description": "CUP PORTION 5.5 OZ BLK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11600326",
        "Last Price Paid": "$0.02",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021772",
        "Product Status": "Active"
    },
    {
        "Root": "10002352",
        "LV": "2",
        "Item Description": "CHEESE HARD QUESDO FRESCO 12/10 OUNCE WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002352002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "13775",
        "Last Price Paid": "$4.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002352",
        "Product Status": "Active"
    },
    {
        "Root": "10018527",
        "LV": "1",
        "Item Description": "LIQ,LUXARADO,CHERRY,MARA, 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "17257",
        "Last Price Paid": "$27.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018527",
        "Product Status": "Active"
    },
    {
        "Root": "10009985",
        "LV": "1",
        "Item Description": "LIQ COINTREAU LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$37.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009985",
        "Product Status": "Active"
    },
    {
        "Root": "10001641",
        "LV": "1",
        "Item Description": "CARROT RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401076",
        "Last Price Paid": "$41.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001641",
        "Product Status": "Active"
    },
    {
        "Root": "10001641",
        "LV": "1",
        "Item Description": "CARROT RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20306",
        "Last Price Paid": "$41.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001641",
        "Product Status": "Active"
    },
    {
        "Root": "10001641",
        "LV": "1",
        "Item Description": "CARROT RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$41.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001641",
        "Product Status": "Active"
    },
    {
        "Root": "10001641",
        "LV": "1",
        "Item Description": "CARROT RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$41.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001641",
        "Product Status": "Active"
    },
    {
        "Root": "10002376",
        "LV": "1",
        "Item Description": "GELATO MANGO SORBET 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "200584",
        "Last Price Paid": "$37.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002376",
        "Product Status": "Active"
    },
    {
        "Root": "10004656",
        "LV": "1",
        "Item Description": "NUT ALMOND WHOLE/BLANCHD 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004656001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "110900",
        "Last Price Paid": "$113.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004656",
        "Product Status": "Active"
    },
    {
        "Root": "10004513",
        "LV": "1",
        "Item Description": "CORNICHONS PICKLED 9#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004513001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7045641",
        "Last Price Paid": "$19.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004513",
        "Product Status": "Active"
    },
    {
        "Root": "10004817",
        "LV": "1",
        "Item Description": "BEAN CANNELLINI 6/#10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004817",
        "Product Status": "Active"
    },
    {
        "Root": "10010469",
        "LV": "2",
        "Item Description": "MERLOT TOAD HOLLOW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010469001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "543064",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010469",
        "Product Status": "Active"
    },
    {
        "Root": "10004737",
        "LV": "1",
        "Item Description": "DRIED FRUIT DICED FRUIT MIX 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004737001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "10114",
        "Last Price Paid": "$59.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004737",
        "Product Status": "Active"
    },
    {
        "Root": "10104633",
        "LV": "2",
        "Item Description": "CRABMEAT SPECIAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104633002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6513934",
        "Last Price Paid": "$11.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104633",
        "Product Status": "Active"
    },
    {
        "Root": "10004667",
        "LV": "1",
        "Item Description": "DRIED FRUIT APRICOT DRIED LRG 28LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "280650",
        "Last Price Paid": "$154.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004667",
        "Product Status": "Active"
    },
    {
        "Root": "10012710",
        "LV": "1",
        "Item Description": "MUSTARD ULTRA RED RUFFLED 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09URRM-33",
        "Last Price Paid": "$0.61",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012710",
        "Product Status": "Active"
    },
    {
        "Root": "10004042",
        "LV": "1",
        "Item Description": "DEC PEARLS CRSPY WHT CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118587",
        "Last Price Paid": "$7.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004042",
        "Product Status": "Active"
    },
    {
        "Root": "10004042",
        "LV": "1",
        "Item Description": "DEC PEARLS CRSPY WHT CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87352",
        "Last Price Paid": "$7.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004042",
        "Product Status": "Active"
    },
    {
        "Root": "10014751",
        "LV": "1",
        "Item Description": "POTATO SWEET JAPANESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408823",
        "Last Price Paid": "$73.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014751",
        "Product Status": "Active"
    },
    {
        "Root": "10014751",
        "LV": "1",
        "Item Description": "POTATO SWEET JAPANESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$73.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014751",
        "Product Status": "Active"
    },
    {
        "Root": "10001137",
        "LV": "1",
        "Item Description": "HAMACHI LOIN 11 POUNDS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001137001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "68-51014",
        "Last Price Paid": "$18.44",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001137",
        "Product Status": "Active"
    },
    {
        "Root": "10014735",
        "LV": "2",
        "Item Description": "BLEND RED SARTORI REGOLO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014735001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "562342",
        "Last Price Paid": "$17.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10014735",
        "Product Status": "Active"
    },
    {
        "Root": "10031546",
        "LV": "1",
        "Item Description": "SYRUP STRAWBERRY FLAVOR BURST 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031546001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "61-45884",
        "Last Price Paid": "$17.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031546",
        "Product Status": "Active"
    },
    {
        "Root": "10034635",
        "LV": "1",
        "Item Description": "SB TOASTED WHITE CHOCOLATE MOCHA SUGAR PEARLS 7OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11096028",
        "Last Price Paid": "$6.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034635",
        "Product Status": "Active"
    },
    {
        "Root": "10012500",
        "LV": "1",
        "Item Description": "BEEF HANGER STEAK CAB 8 OZ PORTIONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012500001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2010908",
        "Last Price Paid": "$18.93",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012500",
        "Product Status": "Active"
    },
    {
        "Root": "10034767",
        "LV": "1",
        "Item Description": "GLAZE MIRROR SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "646001",
        "Last Price Paid": "$50.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034767",
        "Product Status": "Active"
    },
    {
        "Root": "10017152",
        "LV": "1",
        "Item Description": "FERN FIDDLEHEAD 5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480001",
        "Last Price Paid": "$121.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017152",
        "Product Status": "Active"
    },
    {
        "Root": "10021089",
        "LV": "1",
        "Item Description": "SAUV BLC HONIG RUTHERFORD 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9700508",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021089",
        "Product Status": "Active"
    },
    {
        "Root": "10009954",
        "LV": "1",
        "Item Description": "LIQ CREME DE NOYAUX BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009954001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001668",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009954",
        "Product Status": "Active"
    },
    {
        "Root": "10001314",
        "LV": "1",
        "Item Description": "POTATO SWEET FRY 1/4 INCH X 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.17",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001314",
        "Product Status": "Active"
    },
    {
        "Root": "10040197",
        "LV": "1",
        "Item Description": "DRAFT KRONENBOURG 1664 LAGER 50LTR KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040197001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "910457",
        "Last Price Paid": "$134.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040197",
        "Product Status": "Active"
    },
    {
        "Root": "10087742",
        "LV": "1",
        "Item Description": "BAG COTTON CANDY 1000CT 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3071-2",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087742",
        "Product Status": "Active"
    },
    {
        "Root": "10002149",
        "LV": "1",
        "Item Description": "BUTTER MARGARINE CUP 5GRA 720 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "974733",
        "Last Price Paid": "$0.04",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002149",
        "Product Status": "Active"
    },
    {
        "Root": "10110131",
        "LV": "1",
        "Item Description": "BAG CRYSTAL CLEAR WITH NO FLAP 6\"X10\" B610NF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51610610",
        "Last Price Paid": "$0.08",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110131",
        "Product Status": "Active"
    },
    {
        "Root": "10002811",
        "LV": "1",
        "Item Description": "TEA PRINCE OF WALES 120CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002811",
        "Product Status": "Active"
    },
    {
        "Root": "10002504",
        "LV": "1",
        "Item Description": "RAMPS - 5#/CA INCREMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002504001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002504",
        "Product Status": "Active"
    },
    {
        "Root": "10002504",
        "LV": "1",
        "Item Description": "RAMPS - 5#/CA INCREMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002504001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002504",
        "Product Status": "Active"
    },
    {
        "Root": "10100969",
        "LV": "1",
        "Item Description": "BOSSSTRAW PAPER WHITE WRAPPED 8MM 4200CT 8.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11091170",
        "Last Price Paid": "$0.03",
        "Case Pack": "4200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100969",
        "Product Status": "Active"
    },
    {
        "Root": "10004681",
        "LV": "1",
        "Item Description": "CANDY REECES PIECES BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004681001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530500",
        "Last Price Paid": "$128.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004681",
        "Product Status": "Active"
    },
    {
        "Root": "10018292",
        "LV": "1",
        "Item Description": "DRAFT BUD LIGHT 1/6 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018292001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018292",
        "Product Status": "Active"
    },
    {
        "Root": "10000331",
        "LV": "1",
        "Item Description": "POTATO RUSSET WHOLE PEELED 1/20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480472",
        "Last Price Paid": "$38.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000331",
        "Product Status": "Active"
    },
    {
        "Root": "10041073",
        "LV": "1",
        "Item Description": "CHEESE HARD TEAHIVE BEEHIVE CHEESE UTAH 2/5 LB WHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041073001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "12594",
        "Last Price Paid": "$15.35",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041073",
        "Product Status": "Active"
    },
    {
        "Root": "10040824",
        "LV": "2",
        "Item Description": "COOKIES OREO 62.7OZ/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040824002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040824",
        "Product Status": "Active"
    },
    {
        "Root": "10013607",
        "LV": "1",
        "Item Description": "PORK RACK 10 BONE 2CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013607001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1412454",
        "Last Price Paid": "$3.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013607",
        "Product Status": "Active"
    },
    {
        "Root": "10001155",
        "LV": "1",
        "Item Description": "CRAYFISH MEAT FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001155001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "79-08015",
        "Last Price Paid": "$15.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001155",
        "Product Status": "Active"
    },
    {
        "Root": "10001155",
        "LV": "1",
        "Item Description": "CRAYFISH MEAT FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001155001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6437401",
        "Last Price Paid": "$15.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001155",
        "Product Status": "Active"
    },
    {
        "Root": "10001155",
        "LV": "1",
        "Item Description": "CRAYFISH MEAT FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001155001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001155",
        "Product Status": "Active"
    },
    {
        "Root": "10001540",
        "LV": "1",
        "Item Description": "BERRY CRAN DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410422",
        "Last Price Paid": "$25.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001540",
        "Product Status": "Active"
    },
    {
        "Root": "10032673",
        "LV": "1",
        "Item Description": "RASPBERRY ITALIAN SORBET NSA 1.5 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "NSASOR1.5-002",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032673",
        "Product Status": "Active"
    },
    {
        "Root": "10089288",
        "LV": "1",
        "Item Description": "PORK FRITTER HOMESTYLE RAW 40/4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089288001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1089655",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089288",
        "Product Status": "Active"
    },
    {
        "Root": "10036332",
        "LV": "1",
        "Item Description": "SOUTHERN DL521028 2.5 ROUND LABEL BLACK ON KRAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001092",
        "Last Price Paid": "",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036332",
        "Product Status": "Active"
    },
    {
        "Root": "10091220",
        "LV": "1",
        "Item Description": "RUM SELVAREY WHITE 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091220001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "131867",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091220",
        "Product Status": "Active"
    },
    {
        "Root": "10010394",
        "LV": "1",
        "Item Description": "BEER 2 HENRY 7 MILE IPA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "26010",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010394",
        "Product Status": "Active"
    },
    {
        "Root": "10044230",
        "LV": "1",
        "Item Description": "WINE RED CAB SAUV BONASSIA 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "47668",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044230",
        "Product Status": "Active"
    },
    {
        "Root": "10107950",
        "LV": "1",
        "Item Description": "BUCKET PREM POP WISH VALENTINO 54OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107950",
        "Product Status": "Active"
    },
    {
        "Root": "10036031",
        "LV": "1",
        "Item Description": "BRANDY GRAN DUQUE D ALBA BLUE XO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "45044",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036031",
        "Product Status": "Active"
    },
    {
        "Root": "10033159",
        "LV": "1",
        "Item Description": "ENGLISH MUFFIN GLUTEN FREE OG3 BR RC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "30279",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033159",
        "Product Status": "Active"
    },
    {
        "Root": "10005667",
        "LV": "1",
        "Item Description": "BREAD WHITE SANDWICH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005667001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "26",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005667",
        "Product Status": "Active"
    },
    {
        "Root": "10032838",
        "LV": "1",
        "Item Description": "SUGAR TIE ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032838",
        "Product Status": "Active"
    },
    {
        "Root": "10015130",
        "LV": "1",
        "Item Description": "TOMATO POMA ROSA W/BASIL #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015130",
        "Product Status": "Active"
    },
    {
        "Root": "10088125",
        "LV": "1",
        "Item Description": "CHAMP DOM PERIGNON 2010 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088125001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088125",
        "Product Status": "Active"
    },
    {
        "Root": "10021337",
        "LV": "2",
        "Item Description": "CHIANTI FRATTORIA POGGIO ALLORO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021337002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "803326718008",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021337",
        "Product Status": "Active"
    },
    {
        "Root": "10000060",
        "LV": "1",
        "Item Description": "PORK CHOP RIB FRENCHED 10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000060001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2491343",
        "Last Price Paid": "$6.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000060",
        "Product Status": "Active"
    },
    {
        "Root": "10032423",
        "LV": "1",
        "Item Description": "NAPKIN LINEN LIKE CLUB 1/8 FOLD 33 WHITE 17X17",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51139387",
        "Last Price Paid": "$0.21",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032423",
        "Product Status": "Active"
    },
    {
        "Root": "10038352",
        "LV": "1",
        "Item Description": "PINOT NOIR DROUHIN GEVRY CHAM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "456564",
        "Last Price Paid": "$81.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038352",
        "Product Status": "Active"
    },
    {
        "Root": "10008152",
        "LV": "1",
        "Item Description": "CHARD FLOWERS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "600201",
        "Last Price Paid": "$23.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008152",
        "Product Status": "Active"
    },
    {
        "Root": "10006895",
        "LV": "2",
        "Item Description": "CHAMP FEUILLTT ENCHNTEE BRT 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006895001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "363797",
        "Last Price Paid": "$24.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006895",
        "Product Status": "Active"
    },
    {
        "Root": "10013880",
        "LV": "1",
        "Item Description": "CORN BABY IN HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "CORNB",
        "Last Price Paid": "$0.19",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013880",
        "Product Status": "Active"
    },
    {
        "Root": "10013880",
        "LV": "1",
        "Item Description": "CORN BABY IN HUSK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480052",
        "Last Price Paid": "$0.19",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013880",
        "Product Status": "Active"
    },
    {
        "Root": "10009949",
        "LV": "1",
        "Item Description": "LIQ B & B LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28783",
        "Last Price Paid": "$39.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009949",
        "Product Status": "Active"
    },
    {
        "Root": "10018295",
        "LV": "2",
        "Item Description": "STARCH TAPIOCA ULTRA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018295002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "501809D",
        "Last Price Paid": "$16.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018295",
        "Product Status": "Active"
    },
    {
        "Root": "10031811",
        "LV": "1",
        "Item Description": "CHOC BOW PINK EEYORE ROUND EDGED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6231670000",
        "Last Price Paid": "$0.06",
        "Case Pack": "5130",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031811",
        "Product Status": "Active"
    },
    {
        "Root": "10021515",
        "LV": "1",
        "Item Description": "NUT CASHEW WHL RAW 320 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021515001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "120300",
        "Last Price Paid": "$173.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021515",
        "Product Status": "Active"
    },
    {
        "Root": "10002946",
        "LV": "1",
        "Item Description": "COFFEE INROOM DECAF 8-CUP 150CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.58",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002946",
        "Product Status": "Active"
    },
    {
        "Root": "10003912",
        "LV": "1",
        "Item Description": "DECOR CHOC CAR&MONORAIL SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003912001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 3520",
        "Last Price Paid": "$4.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003912",
        "Product Status": "Active"
    },
    {
        "Root": "10041344",
        "LV": "1",
        "Item Description": "LEMON ORGANIC 75/95CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "712030",
        "Last Price Paid": "$55.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041344",
        "Product Status": "Active"
    },
    {
        "Root": "10014543",
        "LV": "1",
        "Item Description": "TINY CARROT MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482562",
        "Last Price Paid": "$0.32",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014543",
        "Product Status": "Active"
    },
    {
        "Root": "10004738",
        "LV": "1",
        "Item Description": "GELATIN PLAIN KNOX 12/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5472247",
        "Last Price Paid": "$13.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004738",
        "Product Status": "Active"
    },
    {
        "Root": "10001932",
        "LV": "1",
        "Item Description": "SPINACH CURLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404031",
        "Last Price Paid": "$19.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001932",
        "Product Status": "Active"
    },
    {
        "Root": "10001932",
        "LV": "1",
        "Item Description": "SPINACH CURLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001932",
        "Product Status": "Active"
    },
    {
        "Root": "10093092",
        "LV": "1",
        "Item Description": "LID CLEAR 5X6.625X.62",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093092001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51303085",
        "Last Price Paid": "$0.34",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093092",
        "Product Status": "Active"
    },
    {
        "Root": "10007268",
        "LV": "1",
        "Item Description": "PINOT GRIS MASO POLI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "551183",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007268",
        "Product Status": "Active"
    },
    {
        "Root": "10004920",
        "LV": "2",
        "Item Description": "SNACK PRETZEL YOGURT MINI 24/2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004920002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203012",
        "Last Price Paid": "$0.79",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004920",
        "Product Status": "Active"
    },
    {
        "Root": "10039732",
        "LV": "1",
        "Item Description": "BEAN LENTIL GOLDEN 12/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "131400",
        "Last Price Paid": "$5.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039732",
        "Product Status": "Active"
    },
    {
        "Root": "10001773",
        "LV": "1",
        "Item Description": "HERB LEMON GRASS 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001773001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450045",
        "Last Price Paid": "$7.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001773",
        "Product Status": "Active"
    },
    {
        "Root": "10001773",
        "LV": "1",
        "Item Description": "HERB LEMON GRASS 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001773001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001773",
        "Product Status": "Active"
    },
    {
        "Root": "10000061",
        "LV": "1",
        "Item Description": "BEEF STRIP STEAK CENTER CUT CHOICE ANGUS 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000061001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2990081",
        "Last Price Paid": "$18.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000061",
        "Product Status": "Active"
    },
    {
        "Root": "10009789",
        "LV": "2",
        "Item Description": "RUM STARR AFRICAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009789001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009789",
        "Product Status": "Active"
    },
    {
        "Root": "10083664",
        "LV": "1",
        "Item Description": "CHOC DARK SPHERE LARGE HALF 118MM 25PCS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "4611180000",
        "Last Price Paid": "$3.18",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083664",
        "Product Status": "Active"
    },
    {
        "Root": "10007694",
        "LV": "2",
        "Item Description": "CHARD HESS SHRTAIL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007694001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9167159",
        "Last Price Paid": "$192.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007694",
        "Product Status": "Active"
    },
    {
        "Root": "10023540",
        "LV": "1",
        "Item Description": "COCOA BUTTER PEARL PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "630149",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023540",
        "Product Status": "Active"
    },
    {
        "Root": "10010990",
        "LV": "1",
        "Item Description": "SHELL TART ROUND LARGE VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8090444",
        "Last Price Paid": "$1.08",
        "Case Pack": "44",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010990",
        "Product Status": "Active"
    },
    {
        "Root": "10004981",
        "LV": "1",
        "Item Description": "PUREE CHERRY MORELLO 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "642...{BOIRON{",
        "Last Price Paid": "$12.02",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004981",
        "Product Status": "Active"
    },
    {
        "Root": "10007017",
        "LV": "1",
        "Item Description": "CHARD THE FAIREST OUAV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007017001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007017",
        "Product Status": "Active"
    },
    {
        "Root": "10091444",
        "LV": "3",
        "Item Description": "GELATO BLUEBERRY SOFT SERVE MIX (6 HALF GAL JUGS)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091444003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "MK-BLBR-11-CS6",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10091444",
        "Product Status": "Active"
    },
    {
        "Root": "10001348",
        "LV": "1",
        "Item Description": "MARINADE ZHAUGH 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001348",
        "Product Status": "Active"
    },
    {
        "Root": "10003923",
        "LV": "1",
        "Item Description": "SB SYRUP RASPBERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108264",
        "Last Price Paid": "$7.44",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003923",
        "Product Status": "Active"
    },
    {
        "Root": "10004139",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK COATING NON-HYDRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "117892",
        "Last Price Paid": "$63.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004139",
        "Product Status": "Active"
    },
    {
        "Root": "10094641",
        "LV": "2",
        "Item Description": "CANDY YUMEARTH FRUIT SNACKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094641002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1391",
        "Last Price Paid": "$0.52",
        "Case Pack": "315",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094641",
        "Product Status": "Active"
    },
    {
        "Root": "10014755",
        "LV": "1",
        "Item Description": "CHIC BRST GRILLED SEARED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7028547",
        "Last Price Paid": "$2.83",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014755",
        "Product Status": "Active"
    },
    {
        "Root": "10007646",
        "LV": "1",
        "Item Description": "CORVINA ZENATO VALPOL SUPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "659826",
        "Last Price Paid": "$13.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007646",
        "Product Status": "Active"
    },
    {
        "Root": "10032559",
        "LV": "1",
        "Item Description": "MINNIE HAT W/BOW 2IN DOME WHITE 2.75IN X 1.375IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW32321W",
        "Last Price Paid": "$0.90",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032559",
        "Product Status": "Active"
    },
    {
        "Root": "10040354",
        "LV": "1",
        "Item Description": "TEA BAGS ICED TEA JOFFREYS 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040354",
        "Product Status": "Active"
    },
    {
        "Root": "10034755",
        "LV": "1",
        "Item Description": "TRANSFER SHEET STOCKINGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "902364C",
        "Last Price Paid": "$5.42",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034755",
        "Product Status": "Active"
    },
    {
        "Root": "10088796",
        "LV": "1",
        "Item Description": "CHAMP CHARLES HEIDSIECK BLANC DE BLANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088796001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9315503",
        "Last Price Paid": "$67.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088796",
        "Product Status": "Active"
    },
    {
        "Root": "10102830",
        "LV": "1",
        "Item Description": "NOODLE COOKED UDON 5MM (F)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "49309",
        "Last Price Paid": "$1.08",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102830",
        "Product Status": "Active"
    },
    {
        "Root": "10106587",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER  ICY BLUE 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313061E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106587",
        "Product Status": "Active"
    },
    {
        "Root": "10004990",
        "LV": "1",
        "Item Description": "CRACKER BREADSTICK PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BS100...{VIGO{",
        "Last Price Paid": "$1.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004990",
        "Product Status": "Active"
    },
    {
        "Root": "10039645",
        "LV": "1",
        "Item Description": "BEER SCHOFFERHOFER HEFEWEIZEN PMGRNTE 12OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "41104",
        "Last Price Paid": "$1.56",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039645",
        "Product Status": "Active"
    },
    {
        "Root": "10032955",
        "LV": "1",
        "Item Description": "ROSES TINEY UNWIRED WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "TR WH",
        "Last Price Paid": "$0.46",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032955",
        "Product Status": "Active"
    },
    {
        "Root": "10104928",
        "LV": "1",
        "Item Description": "PASTE WHITE MISO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35241",
        "Last Price Paid": "$7.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104928",
        "Product Status": "Active"
    },
    {
        "Root": "10011900",
        "LV": "2",
        "Item Description": "SB WDW 12OZ HOT CUP PRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011900002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$67.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011900",
        "Product Status": "Active"
    },
    {
        "Root": "10004170",
        "LV": "1",
        "Item Description": "ALGINATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87402",
        "Last Price Paid": "$68.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004170",
        "Product Status": "Active"
    },
    {
        "Root": "10002752",
        "LV": "1",
        "Item Description": "TOPPING CHOCOLATE 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002752",
        "Product Status": "Active"
    },
    {
        "Root": "10108732",
        "LV": "1",
        "Item Description": "SPRING ROLL PIZZA PEPPERONI & CHEESE 2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108732",
        "Product Status": "Active"
    },
    {
        "Root": "10002596",
        "LV": "1",
        "Item Description": "HERB BASIL MICRO LEMON 1/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482072",
        "Last Price Paid": "$14.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002596",
        "Product Status": "Active"
    },
    {
        "Root": "10034313",
        "LV": "2",
        "Item Description": "COCKTAIL CUTWATER VODKA MULE 12 OZ CAN/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034313001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99420",
        "Last Price Paid": "$1.95",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10034313",
        "Product Status": "Active"
    },
    {
        "Root": "10027970",
        "LV": "1",
        "Item Description": "SAUSAGE REGENSBURGER 4-1 NT CASING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027970001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "208",
        "Last Price Paid": "$4.51",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027970",
        "Product Status": "Active"
    },
    {
        "Root": "10010526",
        "LV": "1",
        "Item Description": "ALSACE SPARR PINOT GRIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9155664",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010526",
        "Product Status": "Active"
    },
    {
        "Root": "10009480",
        "LV": "1",
        "Item Description": "SCOTCH LAPHROAIG 10YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009480001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9994",
        "Last Price Paid": "$51.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009480",
        "Product Status": "Active"
    },
    {
        "Root": "10006254",
        "LV": "1",
        "Item Description": "CUP STARWARS BAKEIN 1.7 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391012",
        "Last Price Paid": "$0.19",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006254",
        "Product Status": "Active"
    },
    {
        "Root": "10007878",
        "LV": "2",
        "Item Description": "PINOT NOIR K WRIGHT CARTER 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007878001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9637274",
        "Last Price Paid": "$52.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007878",
        "Product Status": "Active"
    },
    {
        "Root": "10094772",
        "LV": "1",
        "Item Description": "MILK CHOCOLATE 8OZ/50CS FAT FREETRUMOO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094772",
        "Product Status": "Active"
    },
    {
        "Root": "10010235",
        "LV": "2",
        "Item Description": "DRAFT VICTORY GLDN MONKEY HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010235001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "35702",
        "Last Price Paid": "$207.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010235",
        "Product Status": "Active"
    },
    {
        "Root": "10003757",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE FLOWER POT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "FL00090D",
        "Last Price Paid": "$1.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003757",
        "Product Status": "Active"
    },
    {
        "Root": "10004279",
        "LV": "1",
        "Item Description": "CHOC GARNISH GREEN TWIST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DOBLA 97135",
        "Last Price Paid": "$0.28",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004279",
        "Product Status": "Active"
    },
    {
        "Root": "10104453",
        "LV": "1",
        "Item Description": "TEA ICED BLACK RITUALS 1GAL YIELD 96/1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104453001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.16",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104453",
        "Product Status": "Active"
    },
    {
        "Root": "10001415",
        "LV": "1",
        "Item Description": "CUKE BLOOM W/BLSOM ATCHD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001415001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05CU-33",
        "Last Price Paid": "$1.35",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001415",
        "Product Status": "Active"
    },
    {
        "Root": "10001463",
        "LV": "1",
        "Item Description": "RAMPS 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460503",
        "Last Price Paid": "$134.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001463",
        "Product Status": "Active"
    },
    {
        "Root": "10028657",
        "LV": "1",
        "Item Description": "CONE COTTON CANDY LIGHT UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028657001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000286345",
        "Vendor": "GLO CONE INTERNATIONAL, LLC",
        "Vendor Item Nb": "00494",
        "Last Price Paid": "$1.31",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028657",
        "Product Status": "Active"
    },
    {
        "Root": "10006568",
        "LV": "1",
        "Item Description": "LINER PANETONE BAKING 240",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P90-40",
        "Last Price Paid": "$0.15",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006568",
        "Product Status": "Active"
    },
    {
        "Root": "10001876",
        "LV": "1",
        "Item Description": "TOMATO SLICED ROMA 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511527",
        "Last Price Paid": "$15.75",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001876",
        "Product Status": "Active"
    },
    {
        "Root": "10001876",
        "LV": "1",
        "Item Description": "TOMATO SLICED ROMA 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "21-020-14",
        "Last Price Paid": "$15.75",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001876",
        "Product Status": "Active"
    },
    {
        "Root": "10001233",
        "LV": "1",
        "Item Description": "HALIBUT PACIFIC SKNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001233001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00425B",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001233",
        "Product Status": "Active"
    },
    {
        "Root": "10001233",
        "LV": "1",
        "Item Description": "HALIBUT PACIFIC SKNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001233001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001233",
        "Product Status": "Active"
    },
    {
        "Root": "10076230",
        "LV": "1",
        "Item Description": "DECOR CHOC DISC 2.25IN GENERIC ART",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW20892",
        "Last Price Paid": "$0.79",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076230",
        "Product Status": "Active"
    },
    {
        "Root": "10001934",
        "LV": "2",
        "Item Description": "ONION CIPPOLINI PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001934001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10001934",
        "Product Status": "Active"
    },
    {
        "Root": "10002847",
        "LV": "2",
        "Item Description": "SAUCE STEAK A1 5 OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002847001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9002080",
        "Last Price Paid": "$2.70",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002847",
        "Product Status": "Active"
    },
    {
        "Root": "10109630",
        "LV": "1",
        "Item Description": "JUICE PG COCONUT HYDRATE 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.43",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109630",
        "Product Status": "Active"
    },
    {
        "Root": "10031823",
        "LV": "1",
        "Item Description": "FREEZE DRIED STRAWBERRY SLICES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672086",
        "Last Price Paid": "$52.87",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031823",
        "Product Status": "Active"
    },
    {
        "Root": "10038474",
        "LV": "1",
        "Item Description": "ALPHONSE MELLOT LA MOUSSIERE SANCERRE ROUGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038474001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001095",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038474",
        "Product Status": "Active"
    },
    {
        "Root": "10008966",
        "LV": "1",
        "Item Description": "CAB ABACUS ZD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "23265",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008966",
        "Product Status": "Active"
    },
    {
        "Root": "10028471",
        "LV": "1",
        "Item Description": "WNE BANFI ROSSO DI MONTALCINO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "94578",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028471",
        "Product Status": "Active"
    },
    {
        "Root": "10091311",
        "LV": "1",
        "Item Description": "WINE RED MAN FAMILY CABERNET SAUVIGNON 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2774040",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091311",
        "Product Status": "Active"
    },
    {
        "Root": "10002451",
        "LV": "1",
        "Item Description": "SORBET STRWBRY 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002451",
        "Product Status": "Active"
    },
    {
        "Root": "10000627",
        "LV": "1",
        "Item Description": "BEEF ROAST TP RND SLCD COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000627001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3101040",
        "Last Price Paid": "$1.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000627",
        "Product Status": "Active"
    },
    {
        "Root": "10002595",
        "LV": "1",
        "Item Description": "CARROT BABY PLANET W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512026",
        "Last Price Paid": "$55.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002595",
        "Product Status": "Active"
    },
    {
        "Root": "10002595",
        "LV": "1",
        "Item Description": "CARROT BABY PLANET W/TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002595",
        "Product Status": "Active"
    },
    {
        "Root": "10003195",
        "LV": "1",
        "Item Description": "MIX CORNBREAD STUFFING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5610860",
        "Last Price Paid": "$10.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003195",
        "Product Status": "Active"
    },
    {
        "Root": "10001098",
        "LV": "1",
        "Item Description": "SHRIMP WHIT BAIT LV SEA 51/60",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001098001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6126060",
        "Last Price Paid": "$5.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001098",
        "Product Status": "Active"
    },
    {
        "Root": "10000235",
        "LV": "1",
        "Item Description": "HUMMUS CUP FELAFEL CHIP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "599011",
        "Last Price Paid": "$1.96",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000235",
        "Product Status": "Active"
    },
    {
        "Root": "10000660",
        "LV": "1",
        "Item Description": "PROSCIUTTO DI PARMA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000660001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3313015",
        "Last Price Paid": "$10.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000660",
        "Product Status": "Active"
    },
    {
        "Root": "10033390",
        "LV": "2",
        "Item Description": "TEQUILA VOLCAN BLANCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033390002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "576550",
        "Last Price Paid": "$35.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033390",
        "Product Status": "Active"
    },
    {
        "Root": "10101528",
        "LV": "1",
        "Item Description": "SAKE TAKATENJIN SOUL OF SENSEI JDG 720ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101528001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9223210",
        "Last Price Paid": "$34.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101528",
        "Product Status": "Active"
    },
    {
        "Root": "10040006",
        "LV": "1",
        "Item Description": "BEER ALE BIRRIFICIO LE BALADIN NAZIONALE BTL 11.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10865",
        "Last Price Paid": "$3.62",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040006",
        "Product Status": "Active"
    },
    {
        "Root": "10102921",
        "LV": "1",
        "Item Description": "KOSHER BSC ROAST CHICKEN BREAST 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102921",
        "Product Status": "Active"
    },
    {
        "Root": "10002781",
        "LV": "1",
        "Item Description": "SYRUP TEA GOLD PEAK GREEN SWEET 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002781",
        "Product Status": "Active"
    },
    {
        "Root": "10106397",
        "LV": "1",
        "Item Description": "MILK CHOCOLATE WHOLE GALLON 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106397001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.58",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106397",
        "Product Status": "Active"
    },
    {
        "Root": "10015201",
        "LV": "2",
        "Item Description": "BEER TERRAPIN HOPSCTNR 12OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015201002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27093",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015201",
        "Product Status": "Active"
    },
    {
        "Root": "10010434",
        "LV": "2",
        "Item Description": "BEER MOOSEHEAD 12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010434001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10764",
        "Last Price Paid": "$30.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010434",
        "Product Status": "Active"
    },
    {
        "Root": "10018810",
        "LV": "1",
        "Item Description": "TOMATO MIX TRUE REBEL VF 12/10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450213",
        "Last Price Paid": "$2.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018810",
        "Product Status": "Active"
    },
    {
        "Root": "10009967",
        "LV": "1",
        "Item Description": "LIQ BAILEYS IRSH CRM 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "333018",
        "Last Price Paid": "$12.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009967",
        "Product Status": "Active"
    },
    {
        "Root": "10017315",
        "LV": "1",
        "Item Description": "LID 8 OZ BLK PS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017315",
        "Product Status": "Active"
    },
    {
        "Root": "10106508",
        "LV": "1",
        "Item Description": "CHICKEN CORDON BLEU 7.5LBS/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.69",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106508",
        "Product Status": "Active"
    },
    {
        "Root": "10090630",
        "LV": "1",
        "Item Description": "SHELL CHOCOLATE 2.2IN ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD310C",
        "Last Price Paid": "$0.36",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090630",
        "Product Status": "Active"
    },
    {
        "Root": "10001276",
        "LV": "1",
        "Item Description": "STRAWBERRY WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1419308",
        "Last Price Paid": "$48.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001276",
        "Product Status": "Active"
    },
    {
        "Root": "10001268",
        "LV": "1",
        "Item Description": "PICKLE -N- POUCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.45",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001268",
        "Product Status": "Active"
    },
    {
        "Root": "10010623",
        "LV": "2",
        "Item Description": "SCOTCH CHIVAS 18YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010623001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "134491",
        "Last Price Paid": "$78.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010623",
        "Product Status": "Active"
    },
    {
        "Root": "10037597",
        "LV": "1",
        "Item Description": "MINNIE ET LID SQUARE CLEAR 5.15 INCH 300/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303079",
        "Last Price Paid": "$0.28",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037597",
        "Product Status": "Active"
    },
    {
        "Root": "10023116",
        "LV": "1",
        "Item Description": "HAY BALE OUTDOOR USE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "950100",
        "Last Price Paid": "$19.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023116",
        "Product Status": "Active"
    },
    {
        "Root": "10083978",
        "LV": "2",
        "Item Description": "PLATE 10.25 1 SEC PULP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083978002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303156",
        "Last Price Paid": "$0.35",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083978",
        "Product Status": "Active"
    },
    {
        "Root": "10010148",
        "LV": "2",
        "Item Description": "SODA GINGER BEER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010148002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "986564",
        "Last Price Paid": "$0.65",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10010148",
        "Product Status": "Active"
    },
    {
        "Root": "10003526",
        "LV": "1",
        "Item Description": "SYRUP MONIN PURE CANE 4/L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.58",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003526",
        "Product Status": "Active"
    },
    {
        "Root": "10007524",
        "LV": "2",
        "Item Description": "CAB SAU STAG LP CLR ARTEMIS 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007524001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "577376",
        "Last Price Paid": "$52.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007524",
        "Product Status": "Active"
    },
    {
        "Root": "10009928",
        "LV": "1",
        "Item Description": "LIQ COMBIER PECHE DE VIGNE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9320904",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009928",
        "Product Status": "Active"
    },
    {
        "Root": "10002219",
        "LV": "2",
        "Item Description": "MILK SOY CHOCOLATE SILK 8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002219001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002219",
        "Product Status": "Active"
    },
    {
        "Root": "10000233",
        "LV": "1",
        "Item Description": "WATERMELON DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000233001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-030D-12",
        "Last Price Paid": "$14.38",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000233",
        "Product Status": "Active"
    },
    {
        "Root": "10000233",
        "LV": "1",
        "Item Description": "WATERMELON DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000233001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481148",
        "Last Price Paid": "$14.38",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000233",
        "Product Status": "Active"
    },
    {
        "Root": "10005213",
        "LV": "1",
        "Item Description": "CHOC WHT ROSE DISH RD BOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "FL00222C",
        "Last Price Paid": "$1.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005213",
        "Product Status": "Active"
    },
    {
        "Root": "10003556",
        "LV": "1",
        "Item Description": "SPICE CUMIN WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003556001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.90",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003556",
        "Product Status": "Active"
    },
    {
        "Root": "10013988",
        "LV": "1",
        "Item Description": "DEC CRISPEARL CARAMEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C25604",
        "Last Price Paid": "$17.76",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013988",
        "Product Status": "Active"
    },
    {
        "Root": "10034648",
        "LV": "1",
        "Item Description": "FLOURLESS CHOCOLATE FULL SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1821",
        "Last Price Paid": "$13.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034648",
        "Product Status": "Active"
    },
    {
        "Root": "10045536",
        "LV": "1",
        "Item Description": "SYRUP FOUNTAIN CHOC RTU JUG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8065567",
        "Last Price Paid": "$10.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045536",
        "Product Status": "Active"
    },
    {
        "Root": "10081267",
        "LV": "1",
        "Item Description": "CHICKEN WINGS RAW IQF 4/10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.03",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081267",
        "Product Status": "Active"
    },
    {
        "Root": "10008146",
        "LV": "1",
        "Item Description": "CAB S MARTIN RAY NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "270686",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008146",
        "Product Status": "Active"
    },
    {
        "Root": "10003157",
        "LV": "2",
        "Item Description": "TEA SPICE CHAI HERB LS 14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003157001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W1002",
        "Last Price Paid": "$35.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003157",
        "Product Status": "Active"
    },
    {
        "Root": "10034309",
        "LV": "1",
        "Item Description": "FENUGREEK WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164014",
        "Last Price Paid": "$3.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034309",
        "Product Status": "Active"
    },
    {
        "Root": "10106651",
        "LV": "1",
        "Item Description": "JUICE, GRAPEFRUIT 100% 7.2OZ CAN SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2814800",
        "Last Price Paid": "$0.99",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106651",
        "Product Status": "Active"
    },
    {
        "Root": "10036321",
        "LV": "1",
        "Item Description": "NEUTRAL PETA CRISPY 750G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6720300000",
        "Last Price Paid": "$38.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036321",
        "Product Status": "Active"
    },
    {
        "Root": "10003003",
        "LV": "1",
        "Item Description": "MILK EVAPORATED #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003003001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7328206",
        "Last Price Paid": "$9.34",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003003",
        "Product Status": "Active"
    },
    {
        "Root": "10009995",
        "LV": "1",
        "Item Description": "LIQ DRK CRM CACAO BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001664",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009995",
        "Product Status": "Active"
    },
    {
        "Root": "10014832",
        "LV": "1",
        "Item Description": "MUSTARD RED MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14RM-33-L",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014832",
        "Product Status": "Active"
    },
    {
        "Root": "10039842",
        "LV": "1",
        "Item Description": "SOUR CREAM VEGAN FYH 32OZ 4/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "63032",
        "Last Price Paid": "$8.81",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039842",
        "Product Status": "Active"
    },
    {
        "Root": "10041056",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED BRIE 6.6 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041056001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "67869",
        "Last Price Paid": "$9.21",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041056",
        "Product Status": "Active"
    },
    {
        "Root": "10016495",
        "LV": "1",
        "Item Description": "DOUGH BISCUIT JUMBO 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016495001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1184274",
        "Last Price Paid": "$0.40",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016495",
        "Product Status": "Active"
    },
    {
        "Root": "10107497",
        "LV": "1",
        "Item Description": "BEER KROMBACHER PILS 50 L KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107497001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "3201",
        "Last Price Paid": "$155.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107497",
        "Product Status": "Active"
    },
    {
        "Root": "10005052",
        "LV": "1",
        "Item Description": "SYRUP HONEY SWEETNR MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5084",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005052",
        "Product Status": "Active"
    },
    {
        "Root": "10037864",
        "LV": "1",
        "Item Description": "CARROTS RIBBON TRICOLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037864001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-003",
        "Last Price Paid": "$7.60",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037864",
        "Product Status": "Active"
    },
    {
        "Root": "10086525",
        "LV": "1",
        "Item Description": "COOKIE SNICKERDOODLE 1.6 OZ. INDIVIDUALLY WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086525001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.30",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086525",
        "Product Status": "Active"
    },
    {
        "Root": "10002169",
        "LV": "1",
        "Item Description": "ICE CREAM SANDWICH MICKEY COOKIE/CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002169",
        "Product Status": "Active"
    },
    {
        "Root": "10033891",
        "LV": "1",
        "Item Description": "PINOT NOIR RAINSTORM ROSE 19.5 LITER KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "287916",
        "Last Price Paid": "$248.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033891",
        "Product Status": "Active"
    },
    {
        "Root": "10033891",
        "LV": "1",
        "Item Description": "PINOT NOIR RAINSTORM ROSE 19.5 LITER KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "287916",
        "Last Price Paid": "$248.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033891",
        "Product Status": "Active"
    },
    {
        "Root": "10034319",
        "LV": "2",
        "Item Description": "TAWNY PORT RL BULLER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034319001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "BULRLTWA",
        "Last Price Paid": "$12.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10034319",
        "Product Status": "Active"
    },
    {
        "Root": "10037117",
        "LV": "1",
        "Item Description": "CHEESE CURD MOZARELLA BELGIOIOSO 20 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037117001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1604958",
        "Last Price Paid": "$2.98",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037117",
        "Product Status": "Active"
    },
    {
        "Root": "10014898",
        "LV": "1",
        "Item Description": "TEA FIG ROSE WHITE LOOSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.98",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014898",
        "Product Status": "Active"
    },
    {
        "Root": "10004951",
        "LV": "1",
        "Item Description": "MIX MUFFIN OAT BRAN 50LB/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BEL & SCHA",
        "Last Price Paid": "$85.01",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004951",
        "Product Status": "Active"
    },
    {
        "Root": "10034051",
        "LV": "1",
        "Item Description": "MERLOT SHIRAZ CEDERBERG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "872887000233",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034051",
        "Product Status": "Active"
    },
    {
        "Root": "10002189",
        "LV": "1",
        "Item Description": "MILK SKIM GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002189",
        "Product Status": "Active"
    },
    {
        "Root": "10102820",
        "LV": "1",
        "Item Description": "CUP PLASTIC WATER PARKS BLUE 22OZ 96/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.62",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102820",
        "Product Status": "Active"
    },
    {
        "Root": "10021629",
        "LV": "2",
        "Item Description": "VERMENTINO SELLA MOSCA LA CALA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021629002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555852",
        "Last Price Paid": "$9.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021629",
        "Product Status": "Active"
    },
    {
        "Root": "10041257",
        "LV": "1",
        "Item Description": "HAKE FILLET PREV FROZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041257001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6499017",
        "Last Price Paid": "$4.87",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041257",
        "Product Status": "Active"
    },
    {
        "Root": "10009924",
        "LV": "1",
        "Item Description": "LIQ POMEGRANATE STIRRING 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9105095",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009924",
        "Product Status": "Active"
    },
    {
        "Root": "10095629",
        "LV": "1",
        "Item Description": "WINE WHITE RIESLING LOOSEN GRAACHER GG TROKEN 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "549095",
        "Last Price Paid": "$38.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095629",
        "Product Status": "Active"
    },
    {
        "Root": "10001602",
        "LV": "1",
        "Item Description": "CELERY ROOT DICED 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512021",
        "Last Price Paid": "$20.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001602",
        "Product Status": "Active"
    },
    {
        "Root": "10001602",
        "LV": "1",
        "Item Description": "CELERY ROOT DICED 1/4#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-007D-14",
        "Last Price Paid": "$20.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001602",
        "Product Status": "Active"
    },
    {
        "Root": "10010193",
        "LV": "1",
        "Item Description": "MIX LIME JUICE LTR 12CT RCNSTTD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1312958",
        "Last Price Paid": "$2.85",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010193",
        "Product Status": "Active"
    },
    {
        "Root": "10002633",
        "LV": "2",
        "Item Description": "SPICE SAFFRON 1/1 OZ.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002633002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$49.92",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002633",
        "Product Status": "Active"
    },
    {
        "Root": "10086571",
        "LV": "1",
        "Item Description": "APPLE CLAM SHELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "4149",
        "Last Price Paid": "$0.11",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086571",
        "Product Status": "Active"
    },
    {
        "Root": "10014977",
        "LV": "1",
        "Item Description": "CAB SAUV HOBBS CROSS BARN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "230976",
        "Last Price Paid": "$31.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014977",
        "Product Status": "Active"
    },
    {
        "Root": "10014977",
        "LV": "1",
        "Item Description": "CAB SAUV HOBBS CROSS BARN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "230976",
        "Last Price Paid": "$31.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014977",
        "Product Status": "Active"
    },
    {
        "Root": "10025284",
        "LV": "3",
        "Item Description": "GUMPASTE SEASHELL ASST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025284003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "37967",
        "Last Price Paid": "$2.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10025284",
        "Product Status": "Active"
    },
    {
        "Root": "10106531",
        "LV": "1",
        "Item Description": "DOUGH, CINNAMON POPPERS, 1OZ, 200/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DUO001",
        "Last Price Paid": "$0.40",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106531",
        "Product Status": "Active"
    },
    {
        "Root": "10036209",
        "LV": "1",
        "Item Description": "DRAFT CIGAR CITY JAI ALAI 5.17 GAL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32532",
        "Last Price Paid": "$93.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036209",
        "Product Status": "Active"
    },
    {
        "Root": "10021820",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "96395",
        "Last Price Paid": "$87.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021820",
        "Product Status": "Active"
    },
    {
        "Root": "10023549",
        "LV": "1",
        "Item Description": "TYLOSE POWDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA1236",
        "Last Price Paid": "$32.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023549",
        "Product Status": "Active"
    },
    {
        "Root": "10041308",
        "LV": "1",
        "Item Description": "JUICE NAKED GREEN MACHINE 8/15.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5788013",
        "Last Price Paid": "$2.81",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041308",
        "Product Status": "Active"
    },
    {
        "Root": "10106396",
        "LV": "1",
        "Item Description": "MILK CHOCOLATE 1/2 GALLON 9/64 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.05",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106396",
        "Product Status": "Active"
    },
    {
        "Root": "10034167",
        "LV": "1",
        "Item Description": "BEER IPA TERRAPIN LUAU KRUNKLES 12 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "37741",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034167",
        "Product Status": "Active"
    },
    {
        "Root": "10017974",
        "LV": "1",
        "Item Description": "BOURBON RUSSELLS RSV 10YR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "396669",
        "Last Price Paid": "$32.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017974",
        "Product Status": "Active"
    },
    {
        "Root": "10017980",
        "LV": "1",
        "Item Description": "ST ELIZABETH ALLSPICE DRAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017980001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677564",
        "Last Price Paid": "$23.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017980",
        "Product Status": "Active"
    },
    {
        "Root": "10029617",
        "LV": "1",
        "Item Description": "CHOC HAUNTED HOUSE DYNAMITE 4\"X1 1/4\" RECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW25631",
        "Last Price Paid": "$0.50",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029617",
        "Product Status": "Active"
    },
    {
        "Root": "10004063",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004063",
        "Product Status": "Active"
    },
    {
        "Root": "10008255",
        "LV": "3",
        "Item Description": "SAUV BLC HONIG NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008255002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9664326",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008255",
        "Product Status": "Active"
    },
    {
        "Root": "10044335",
        "LV": "1",
        "Item Description": "SAUCE TZITZIKI TUB REFRIGERATED 4/64 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6009476",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044335",
        "Product Status": "Active"
    },
    {
        "Root": "10102648",
        "LV": "1",
        "Item Description": "BUTTERMILK QUART 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102648",
        "Product Status": "Active"
    },
    {
        "Root": "10033227",
        "LV": "1",
        "Item Description": "COLOR GEL PASTE MOSS GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033227001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC MOSS GREEN",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033227",
        "Product Status": "Active"
    },
    {
        "Root": "10006431",
        "LV": "2",
        "Item Description": "RACK SYRUP MONIN 4-TIER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006431001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-9573",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006431",
        "Product Status": "Active"
    },
    {
        "Root": "10004834",
        "LV": "2",
        "Item Description": "MAYONNAISE IND 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004834002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004834",
        "Product Status": "Active"
    },
    {
        "Root": "10031863",
        "LV": "1",
        "Item Description": "SAKE HIDEYOSHI JUNMAI SPARKLING 280ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031863001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "598573",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031863",
        "Product Status": "Active"
    },
    {
        "Root": "10110632",
        "LV": "1",
        "Item Description": "BEER BREW DOG IT'S A BEAUT 1/2 BBL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16612",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110632",
        "Product Status": "Active"
    },
    {
        "Root": "10005177",
        "LV": "1",
        "Item Description": "OIL AVACADO ORG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01015",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005177",
        "Product Status": "Active"
    },
    {
        "Root": "10032523",
        "LV": "1",
        "Item Description": "PAINTED WOLF SYRAH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "PAIWOLSYR",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032523",
        "Product Status": "Active"
    },
    {
        "Root": "10010441",
        "LV": "1",
        "Item Description": "SCHONAUER APPLE SCHNAPPS 1LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010441",
        "Product Status": "Active"
    },
    {
        "Root": "10037355",
        "LV": "1",
        "Item Description": "VEGANAISE SOY-FREE 32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "118032",
        "Last Price Paid": "$7.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037355",
        "Product Status": "Active"
    },
    {
        "Root": "10015121",
        "LV": "1",
        "Item Description": "HIBISCUS DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015121001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490043",
        "Last Price Paid": "$9.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015121",
        "Product Status": "Active"
    },
    {
        "Root": "10012806",
        "LV": "1",
        "Item Description": "BLANC DE BLEU SPARKLING WINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "35733",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012806",
        "Product Status": "Active"
    },
    {
        "Root": "10012806",
        "LV": "1",
        "Item Description": "BLANC DE BLEU SPARKLING WINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "35733",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012806",
        "Product Status": "Active"
    },
    {
        "Root": "10004784",
        "LV": "1",
        "Item Description": "OIL SUNFLOWER/TRUFFLE 8.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004784001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "301614",
        "Last Price Paid": "$10.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004784",
        "Product Status": "Active"
    },
    {
        "Root": "10004890",
        "LV": "1",
        "Item Description": "HONEY COMB 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-11042",
        "Last Price Paid": "$18.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004890",
        "Product Status": "Active"
    },
    {
        "Root": "10004890",
        "LV": "1",
        "Item Description": "HONEY COMB 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "H0140",
        "Last Price Paid": "$18.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004890",
        "Product Status": "Active"
    },
    {
        "Root": "10004941",
        "LV": "1",
        "Item Description": "CHOC SPRINKLES DOBLA 4/1.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C25601",
        "Last Price Paid": "$14.05",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004941",
        "Product Status": "Active"
    },
    {
        "Root": "10000334",
        "LV": "1",
        "Item Description": "GREEN COLLARD CLND/CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "24-013-1",
        "Last Price Paid": "$13.85",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000334",
        "Product Status": "Active"
    },
    {
        "Root": "10000334",
        "LV": "1",
        "Item Description": "GREEN COLLARD CLND/CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511541",
        "Last Price Paid": "$13.85",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000334",
        "Product Status": "Active"
    },
    {
        "Root": "10018676",
        "LV": "1",
        "Item Description": "SALT FLEUR DE SEL 2.2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD182",
        "Last Price Paid": "$34.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018676",
        "Product Status": "Active"
    },
    {
        "Root": "10100749",
        "LV": "1",
        "Item Description": "TEACAKES BIRTHDAY  108 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "7110135",
        "Last Price Paid": "$0.41",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100749",
        "Product Status": "Active"
    },
    {
        "Root": "10021557",
        "LV": "1",
        "Item Description": "SPICE POWDER MALT VINEGAR 14 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY001",
        "Last Price Paid": "$39.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021557",
        "Product Status": "Active"
    },
    {
        "Root": "10000293",
        "LV": "1",
        "Item Description": "CARROT SHREDDED RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000293001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481924",
        "Last Price Paid": "$13.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000293",
        "Product Status": "Active"
    },
    {
        "Root": "10008245",
        "LV": "2",
        "Item Description": "MERLOT COPPERIDGE 1.5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008245001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1224328",
        "Last Price Paid": "$7.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008245",
        "Product Status": "Active"
    },
    {
        "Root": "10103814",
        "LV": "1",
        "Item Description": "PEPPERMINT BARK 12\" X 14 3/4\" SHEET DK & WHT CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW50346",
        "Last Price Paid": "$9.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103814",
        "Product Status": "Active"
    },
    {
        "Root": "10032507",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032507",
        "Product Status": "Active"
    },
    {
        "Root": "10032507",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "9770-20",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032507",
        "Product Status": "Active"
    },
    {
        "Root": "10027508",
        "LV": "1",
        "Item Description": "BAG KRFT 87490 BSTRO W/PAPER TWST HNDLE 10X6.75X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091045",
        "Last Price Paid": "$0.21",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027508",
        "Product Status": "Active"
    },
    {
        "Root": "10008439",
        "LV": "1",
        "Item Description": "CAB SAUV LEONETTI RESV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008439001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "WA026X2007",
        "Last Price Paid": "$97.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008439",
        "Product Status": "Active"
    },
    {
        "Root": "10085122",
        "LV": "2",
        "Item Description": "OMELETTE CHEDDAR 5.5X2.5IN FOLDED FRZN 54/3.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085122001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1003366",
        "Last Price Paid": "$0.80",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10085122",
        "Product Status": "Active"
    },
    {
        "Root": "10025040",
        "LV": "1",
        "Item Description": "SPRINKLE BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025040001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9876",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025040",
        "Product Status": "Active"
    },
    {
        "Root": "10015951",
        "LV": "1",
        "Item Description": "CHOC EGG HALF HALLOW 16 1/4\" X 11\" DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW22233D",
        "Last Price Paid": "$20.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015951",
        "Product Status": "Active"
    },
    {
        "Root": "10024375",
        "LV": "1",
        "Item Description": "LIME FINGER 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024375",
        "Product Status": "Active"
    },
    {
        "Root": "10004814",
        "LV": "1",
        "Item Description": "SAUCE TERIYAKI 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1000256",
        "Last Price Paid": "$13.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004814",
        "Product Status": "Active"
    },
    {
        "Root": "10030140",
        "LV": "1",
        "Item Description": "CHAMPAGNE LOUIS ROEDERER BRUT PREMIER 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030140001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40632",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030140",
        "Product Status": "Active"
    },
    {
        "Root": "10010675",
        "LV": "1",
        "Item Description": "RUM RHUM CLEMENT VSOP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9037792",
        "Last Price Paid": "$30.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010675",
        "Product Status": "Active"
    },
    {
        "Root": "10004668",
        "LV": "1",
        "Item Description": "NUT PEANUT ROASTD/UNSLTD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "130220",
        "Last Price Paid": "$73.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004668",
        "Product Status": "Active"
    },
    {
        "Root": "10034394",
        "LV": "1",
        "Item Description": "BOURBON LARCENY VS SB 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "320822",
        "Last Price Paid": "$26.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034394",
        "Product Status": "Active"
    },
    {
        "Root": "10003353",
        "LV": "1",
        "Item Description": "COFFEE JA MARAGYP BN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.81",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003353",
        "Product Status": "Active"
    },
    {
        "Root": "10041072",
        "LV": "1",
        "Item Description": "CHEESE HARD SEAHIVE BEEHIVE CHEESE UTAH 2/5 LB WHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041072001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "12590",
        "Last Price Paid": "$13.13",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041072",
        "Product Status": "Active"
    },
    {
        "Root": "10001469",
        "LV": "1",
        "Item Description": "LEEKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490138",
        "Last Price Paid": "$17.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001469",
        "Product Status": "Active"
    },
    {
        "Root": "10001469",
        "LV": "1",
        "Item Description": "LEEKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001469001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20490",
        "Last Price Paid": "$17.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001469",
        "Product Status": "Active"
    },
    {
        "Root": "10005461",
        "LV": "1",
        "Item Description": "SPRAY VELVET BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005461001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LCV003B",
        "Last Price Paid": "$44.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005461",
        "Product Status": "Active"
    },
    {
        "Root": "10004016",
        "LV": "1",
        "Item Description": "CRM MATSS PSTRY CLD CSTRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004016001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "117724",
        "Last Price Paid": "$87.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004016",
        "Product Status": "Active"
    },
    {
        "Root": "10000377",
        "LV": "1",
        "Item Description": "CARROT DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-008-12",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000377",
        "Product Status": "Active"
    },
    {
        "Root": "10000377",
        "LV": "1",
        "Item Description": "CARROT DICED 1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512028",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000377",
        "Product Status": "Active"
    },
    {
        "Root": "10016305",
        "LV": "1",
        "Item Description": "FLOWER CALENDULA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480499",
        "Last Price Paid": "$0.27",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016305",
        "Product Status": "Active"
    },
    {
        "Root": "10005668",
        "LV": "3",
        "Item Description": "BREAD WHEAT SANDWICH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005668002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000415720",
        "Vendor": "FLOWERS BAKING",
        "Vendor Item Nb": "10215080",
        "Last Price Paid": "$1.99",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10005668",
        "Product Status": "Active"
    },
    {
        "Root": "10107502",
        "LV": "1",
        "Item Description": "HARD CIDER CIDERBOYS MANGO TANGO 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "12538",
        "Last Price Paid": "$201.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107502",
        "Product Status": "Active"
    },
    {
        "Root": "10001820",
        "LV": "1",
        "Item Description": "MUSHROOM SHITAKE DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001820001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480467",
        "Last Price Paid": "$23.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001820",
        "Product Status": "Active"
    },
    {
        "Root": "10089803",
        "LV": "1",
        "Item Description": "MIX BAR TROPICS WATERMELON 12/32 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6341658",
        "Last Price Paid": "$6.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089803",
        "Product Status": "Active"
    },
    {
        "Root": "10031325",
        "LV": "1",
        "Item Description": "RUM BARBANCOURT 15YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11284",
        "Last Price Paid": "$42.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031325",
        "Product Status": "Active"
    },
    {
        "Root": "10106532",
        "LV": "1",
        "Item Description": "CRACKER WATER CARR 24/4.25OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9113853",
        "Last Price Paid": "$1.41",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106532",
        "Product Status": "Active"
    },
    {
        "Root": "10001879",
        "LV": "1",
        "Item Description": "CABBAGE WHT CHOP 3/4 INCH 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "11-002-12",
        "Last Price Paid": "$12.36",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001879",
        "Product Status": "Active"
    },
    {
        "Root": "10001879",
        "LV": "1",
        "Item Description": "CABBAGE WHT CHOP 3/4 INCH 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511558",
        "Last Price Paid": "$12.36",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001879",
        "Product Status": "Active"
    },
    {
        "Root": "10018852",
        "LV": "1",
        "Item Description": "SQUASH RED KURI 38#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410056",
        "Last Price Paid": "$47.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018852",
        "Product Status": "Active"
    },
    {
        "Root": "10030666",
        "LV": "1",
        "Item Description": "TEA MOROCCAN MINT 1LB TEA MOROCCAN MINT 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030666001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.60",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030666",
        "Product Status": "Active"
    },
    {
        "Root": "10006924",
        "LV": "1",
        "Item Description": "CHARD CAKEBREAD NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "292121",
        "Last Price Paid": "$33.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006924",
        "Product Status": "Active"
    },
    {
        "Root": "10004619",
        "LV": "1",
        "Item Description": "CAPER CAPOTE LARGE 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9333584",
        "Last Price Paid": "$6.62",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004619",
        "Product Status": "Active"
    },
    {
        "Root": "10082063",
        "LV": "1",
        "Item Description": "WINE ROSE ZOE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9158036",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082063",
        "Product Status": "Active"
    },
    {
        "Root": "10014904",
        "LV": "1",
        "Item Description": "TEA THOROUGHLY MINTED LOOSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.83",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014904",
        "Product Status": "Active"
    },
    {
        "Root": "10001659",
        "LV": "1",
        "Item Description": "APPLE FUJI 80 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "APPFUJI",
        "Last Price Paid": "$0.51",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001659",
        "Product Status": "Active"
    },
    {
        "Root": "10001659",
        "LV": "1",
        "Item Description": "APPLE FUJI 80 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "141100",
        "Last Price Paid": "$0.51",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001659",
        "Product Status": "Active"
    },
    {
        "Root": "10030139",
        "LV": "1",
        "Item Description": "CABERNET INGLENOOK CASK 12 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "828962",
        "Last Price Paid": "$27.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030139",
        "Product Status": "Active"
    },
    {
        "Root": "10030139",
        "LV": "1",
        "Item Description": "CABERNET INGLENOOK CASK 12 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "514164",
        "Last Price Paid": "$27.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030139",
        "Product Status": "Active"
    },
    {
        "Root": "10013585",
        "LV": "1",
        "Item Description": "POINSETTA LG WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NTBWH",
        "Last Price Paid": "$16.06",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013585",
        "Product Status": "Active"
    },
    {
        "Root": "10105113",
        "LV": "1",
        "Item Description": "OIL SESME CHILI HOT IMP TIWAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1912588",
        "Last Price Paid": "$5.22",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105113",
        "Product Status": "Active"
    },
    {
        "Root": "10006119",
        "LV": "1",
        "Item Description": "CUP 2Z PLAS WINE TASTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006119001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006119",
        "Product Status": "Active"
    },
    {
        "Root": "10008060",
        "LV": "1",
        "Item Description": "CAB SAUV STONE CELLER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "449490",
        "Last Price Paid": "$4.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008060",
        "Product Status": "Active"
    },
    {
        "Root": "10105649",
        "LV": "1",
        "Item Description": "LID DOME PP ANTI-FOG VNTD FOR 54OZ BUCKET MDSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "515102",
        "Last Price Paid": "$0.30",
        "Case Pack": "360",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105649",
        "Product Status": "Active"
    },
    {
        "Root": "10004809",
        "LV": "1",
        "Item Description": "MOLASSES POMEGRANATE 10Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "319410",
        "Last Price Paid": "$5.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004809",
        "Product Status": "Active"
    },
    {
        "Root": "10002069",
        "LV": "1",
        "Item Description": "HERB BAY LEAVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002069001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002069",
        "Product Status": "Active"
    },
    {
        "Root": "10002069",
        "LV": "1",
        "Item Description": "HERB BAY LEAVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002069001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450028",
        "Last Price Paid": "$17.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002069",
        "Product Status": "Active"
    },
    {
        "Root": "10000185",
        "LV": "1",
        "Item Description": "PEPPER RED BELL CHOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403702",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000185",
        "Product Status": "Active"
    },
    {
        "Root": "10000400",
        "LV": "1",
        "Item Description": "LEMON WEDGE 6 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "202096",
        "Last Price Paid": "$18.90",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000400",
        "Product Status": "Active"
    },
    {
        "Root": "10000400",
        "LV": "1",
        "Item Description": "LEMON WEDGE 6 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "31-001W",
        "Last Price Paid": "$18.90",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000400",
        "Product Status": "Active"
    },
    {
        "Root": "10009200",
        "LV": "1",
        "Item Description": "CHAMPAGNE ROSE CRISTAL LOUIS ROEDERER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "513732",
        "Last Price Paid": "$525.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009200",
        "Product Status": "Active"
    },
    {
        "Root": "10006277",
        "LV": "1",
        "Item Description": "BOARD CAKE 6 INCH X 1/4 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "E614...{ENJAY{",
        "Last Price Paid": "$0.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006277",
        "Product Status": "Active"
    },
    {
        "Root": "10100453",
        "LV": "1",
        "Item Description": "BOURSIN FROZEN CUBES 4-26.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100453001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$62.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100453",
        "Product Status": "Active"
    },
    {
        "Root": "10044245",
        "LV": "1",
        "Item Description": "WINE SHERRY FAIRBANKS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044245001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044245",
        "Product Status": "Active"
    },
    {
        "Root": "10102251",
        "LV": "1",
        "Item Description": "POPCORN TRICOLOR FRUITY 3OZ/100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "TRI FRUITYPOP",
        "Last Price Paid": "$2.85",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102251",
        "Product Status": "Active"
    },
    {
        "Root": "10000156",
        "LV": "1",
        "Item Description": "SHRIMP ROCK MEAT FRZN 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000156001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6298405",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000156",
        "Product Status": "Active"
    },
    {
        "Root": "10000156",
        "LV": "1",
        "Item Description": "SHRIMP ROCK MEAT FRZN 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000156001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000156",
        "Product Status": "Active"
    },
    {
        "Root": "10000156",
        "LV": "1",
        "Item Description": "SHRIMP ROCK MEAT FRZN 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000156001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "79-99099",
        "Last Price Paid": "$9.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000156",
        "Product Status": "Active"
    },
    {
        "Root": "10004386",
        "LV": "1",
        "Item Description": "WATER SAN PELG ORANGE 200",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "BM27",
        "Last Price Paid": "$1.12",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004386",
        "Product Status": "Active"
    },
    {
        "Root": "10005100",
        "LV": "1",
        "Item Description": "COTTON CANDY MIX GRAPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005100001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3203",
        "Last Price Paid": "$5.63",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005100",
        "Product Status": "Active"
    },
    {
        "Root": "10019389",
        "LV": "1",
        "Item Description": "AKA TOSAKA, GARNISH FOR SASHIMI (20/1.1LB) SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "18063",
        "Last Price Paid": "$18.00",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019389",
        "Product Status": "Active"
    },
    {
        "Root": "10036972",
        "LV": "1",
        "Item Description": "MENCIA RAUL PEREZ ULTREIA ST JACQUES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "537392",
        "Last Price Paid": "$15.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036972",
        "Product Status": "Active"
    },
    {
        "Root": "10010278",
        "LV": "2",
        "Item Description": "BEER ULTRA MICH 12OZ CAN 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010278001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "19257",
        "Last Price Paid": "$1.18",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010278",
        "Product Status": "Active"
    },
    {
        "Root": "10022901",
        "LV": "1",
        "Item Description": "DRAFT GOOSE ISLAND 312 1/6 KEG URBAN WHEAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21506",
        "Last Price Paid": "$83.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022901",
        "Product Status": "Active"
    },
    {
        "Root": "10035391",
        "LV": "1",
        "Item Description": "CREME BRULEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "633400",
        "Last Price Paid": "$8.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035391",
        "Product Status": "Active"
    },
    {
        "Root": "10004162",
        "LV": "1",
        "Item Description": "CALCIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004162001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87407",
        "Last Price Paid": "$27.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004162",
        "Product Status": "Active"
    },
    {
        "Root": "10082061",
        "LV": "1",
        "Item Description": "WINE WHITE MYLONAS ASSYRTIKO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9441377",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082061",
        "Product Status": "Active"
    },
    {
        "Root": "10041158",
        "LV": "1",
        "Item Description": "BEER SIERRA NEVADA HAZY LITTLE 12 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38538",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041158",
        "Product Status": "Active"
    },
    {
        "Root": "10018717",
        "LV": "1",
        "Item Description": "CAKE TORTUGA RUM 2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "6532",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018717",
        "Product Status": "Active"
    },
    {
        "Root": "10019381",
        "LV": "1",
        "Item Description": "TEA LOOSE SUMMER BERRY GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13699",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019381",
        "Product Status": "Active"
    },
    {
        "Root": "10030269",
        "LV": "1",
        "Item Description": "GEL PASTE GOLD COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC GOLD",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030269",
        "Product Status": "Active"
    },
    {
        "Root": "10010681",
        "LV": "3",
        "Item Description": "COGNAC REMY 1738 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010681002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294125",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10010681",
        "Product Status": "Active"
    },
    {
        "Root": "10034582",
        "LV": "1",
        "Item Description": "PAPRIKA PIMENTON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI102",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034582",
        "Product Status": "Active"
    },
    {
        "Root": "10109279",
        "LV": "1",
        "Item Description": "SCOOP POPCORN WDW MARY BLAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "56056A1",
        "Last Price Paid": "",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109279",
        "Product Status": "Active"
    },
    {
        "Root": "10083722",
        "LV": "1",
        "Item Description": "CANDY PINEAPPLE GUMMY RING 2.2# BAG 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083722",
        "Product Status": "Active"
    },
    {
        "Root": "10040271",
        "LV": "3",
        "Item Description": "ORIN SWIFT PAPILLION 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040271003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9239388",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10040271",
        "Product Status": "Active"
    },
    {
        "Root": "10007434",
        "LV": "1",
        "Item Description": "MOSCATO J ORD VICT II 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001132.16-375M",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007434",
        "Product Status": "Active"
    },
    {
        "Root": "10089231",
        "LV": "1",
        "Item Description": "DONUT YEAST SHELL UNGLAZED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS104",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089231",
        "Product Status": "Active"
    },
    {
        "Root": "10040149",
        "LV": "1",
        "Item Description": "BEEF SHORT RIB PIECES RANDOM BNLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040149001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2194997",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040149",
        "Product Status": "Active"
    },
    {
        "Root": "10013208",
        "LV": "1",
        "Item Description": "BLEND FRASS SUGHERE SUPER TUSCANY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "556299",
        "Last Price Paid": "$21.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013208",
        "Product Status": "Active"
    },
    {
        "Root": "10024518",
        "LV": "1",
        "Item Description": "PREMIUM CANDY COLOR GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21309",
        "Last Price Paid": "$5.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024518",
        "Product Status": "Active"
    },
    {
        "Root": "10086544",
        "LV": "1",
        "Item Description": "JUICE CHERRY CHERIBUNDI 6/32OZ 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086544001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9255912",
        "Last Price Paid": "$9.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086544",
        "Product Status": "Active"
    },
    {
        "Root": "10001424",
        "LV": "1",
        "Item Description": "ARTICHOKE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "401028",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001424",
        "Product Status": "Active"
    },
    {
        "Root": "10001424",
        "LV": "1",
        "Item Description": "ARTICHOKE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401028",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001424",
        "Product Status": "Active"
    },
    {
        "Root": "10001424",
        "LV": "1",
        "Item Description": "ARTICHOKE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001424",
        "Product Status": "Active"
    },
    {
        "Root": "10003146",
        "LV": "1",
        "Item Description": "SYRUP COTTON CNDY LTR MON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.71",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003146",
        "Product Status": "Active"
    },
    {
        "Root": "10110083",
        "LV": "1",
        "Item Description": "MIX FREESTYLE PINEAPPLE 21OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7258728",
        "Last Price Paid": "$24.87",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110083",
        "Product Status": "Active"
    },
    {
        "Root": "10008670",
        "LV": "1",
        "Item Description": "CHARD MONTILLE CRTN CHARL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9677819",
        "Last Price Paid": "$150.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008670",
        "Product Status": "Active"
    },
    {
        "Root": "10036149",
        "LV": "1",
        "Item Description": "FRUIT CUP GRAPES RED DESTEMMED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480102",
        "Last Price Paid": "$1.92",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036149",
        "Product Status": "Active"
    },
    {
        "Root": "10009167",
        "LV": "2",
        "Item Description": "CAB SAUV P MICH PAVOT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009167001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9638151",
        "Last Price Paid": "$175.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009167",
        "Product Status": "Active"
    },
    {
        "Root": "10008687",
        "LV": "2",
        "Item Description": "CAB SAUV JAX 08 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008687001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "186309",
        "Last Price Paid": "$35.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008687",
        "Product Status": "Active"
    },
    {
        "Root": "10000797",
        "LV": "1",
        "Item Description": "SAUSAGE CHORIZO 4 INCH PC 20 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3600016",
        "Last Price Paid": "$36.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000797",
        "Product Status": "Active"
    },
    {
        "Root": "10037543",
        "LV": "1",
        "Item Description": "CHARDONNAY VEGA SINDOA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037543001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "001197",
        "Last Price Paid": "$7.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037543",
        "Product Status": "Active"
    },
    {
        "Root": "10110185",
        "LV": "1",
        "Item Description": "OLAF CHOCOLATE PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "OLAF",
        "Last Price Paid": "$4.00",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110185",
        "Product Status": "Active"
    },
    {
        "Root": "10040439",
        "LV": "1",
        "Item Description": "WHITE DW HOT CUP PAPER 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040439001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.26",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040439",
        "Product Status": "Active"
    },
    {
        "Root": "10004776",
        "LV": "1",
        "Item Description": "CORN ON COB MINI #10ROLAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004776001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8332827",
        "Last Price Paid": "$10.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004776",
        "Product Status": "Active"
    },
    {
        "Root": "10027845",
        "LV": "1",
        "Item Description": "RUM STARR AFRICAN AGED 7 YR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027845001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "450709",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027845",
        "Product Status": "Active"
    },
    {
        "Root": "10006314",
        "LV": "1",
        "Item Description": "CONTAINER HINGED 5 INCH PLAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006314001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5886775",
        "Last Price Paid": "$0.17",
        "Case Pack": "375",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006314",
        "Product Status": "Active"
    },
    {
        "Root": "10043052",
        "LV": "1",
        "Item Description": "LABEL 3.125X2.125 ALLERGY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52014624",
        "Last Price Paid": "$0.05",
        "Case Pack": "8000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043052",
        "Product Status": "Active"
    },
    {
        "Root": "10000072",
        "LV": "1",
        "Item Description": "BEEF RIBEYE TOMAHAWK CHOP 35 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000072001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2191207",
        "Last Price Paid": "$36.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000072",
        "Product Status": "Active"
    },
    {
        "Root": "10004061",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE BLACK 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "15401",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004061",
        "Product Status": "Active"
    },
    {
        "Root": "10004061",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE BLACK 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004061",
        "Product Status": "Active"
    },
    {
        "Root": "10001231",
        "LV": "2",
        "Item Description": "CRAB SOFT SHELL JUMBO SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001231002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6547873",
        "Last Price Paid": "$5.52",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001231",
        "Product Status": "Active"
    },
    {
        "Root": "10001231",
        "LV": "2",
        "Item Description": "CRAB SOFT SHELL JUMBO SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001231002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "5{ - 5.5{",
        "Last Price Paid": "$5.52",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001231",
        "Product Status": "Active"
    },
    {
        "Root": "10001231",
        "LV": "2",
        "Item Description": "CRAB SOFT SHELL JUMBO SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001231002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "69-02505",
        "Last Price Paid": "$5.52",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001231",
        "Product Status": "Active"
    },
    {
        "Root": "10031750",
        "LV": "1",
        "Item Description": "VEAL RIB CHOP FRENCH 14 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031750001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "VEAFRA006",
        "Last Price Paid": "$25.99",
        "Case Pack": "8.75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031750",
        "Product Status": "Active"
    },
    {
        "Root": "10005742",
        "LV": "1",
        "Item Description": "PIE SHELL DEEP DISH RAW 9 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1258581",
        "Last Price Paid": "$1.60",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005742",
        "Product Status": "Active"
    },
    {
        "Root": "10002067",
        "LV": "1",
        "Item Description": "TURNIP LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002067001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002067",
        "Product Status": "Active"
    },
    {
        "Root": "10007312",
        "LV": "1",
        "Item Description": "MOSCATO WHITE JAM JAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "445447",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007312",
        "Product Status": "Active"
    },
    {
        "Root": "10030310",
        "LV": "1",
        "Item Description": "DRIED BONITO FLAKES 2.8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY917",
        "Last Price Paid": "$13.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030310",
        "Product Status": "Active"
    },
    {
        "Root": "10003991",
        "LV": "1",
        "Item Description": "TOPPING PINEAPPLE 6/56Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6327654",
        "Last Price Paid": "$11.71",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003991",
        "Product Status": "Active"
    },
    {
        "Root": "10001878",
        "LV": "1",
        "Item Description": "FRUIT MIX CANT HONEY GRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-223",
        "Last Price Paid": "$20.68",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001878",
        "Product Status": "Active"
    },
    {
        "Root": "10001878",
        "LV": "1",
        "Item Description": "FRUIT MIX CANT HONEY GRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069667",
        "Vendor": "GARDEN GOURMET SPECIALTIES",
        "Vendor Item Nb": "519805",
        "Last Price Paid": "$20.68",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001878",
        "Product Status": "Active"
    },
    {
        "Root": "10001878",
        "LV": "1",
        "Item Description": "FRUIT MIX CANT HONEY GRAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "519805",
        "Last Price Paid": "$20.68",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001878",
        "Product Status": "Active"
    },
    {
        "Root": "10004623",
        "LV": "1",
        "Item Description": "CEREAL SPECIAL K BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7075534",
        "Last Price Paid": "$11.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004623",
        "Product Status": "Active"
    },
    {
        "Root": "10002688",
        "LV": "1",
        "Item Description": "FLOUR ALL PURPOSE 12% WHITE BLEACHED MN GIRL 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002688",
        "Product Status": "Active"
    },
    {
        "Root": "10102148",
        "LV": "1",
        "Item Description": "WINE ROSE JL COLOMBO ENCHANTEE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102148",
        "Product Status": "Active"
    },
    {
        "Root": "10083782",
        "LV": "2",
        "Item Description": "CANDY LICORICE BROADWAY WHEELS 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083782002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "35714",
        "Last Price Paid": "$14.38",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083782",
        "Product Status": "Active"
    },
    {
        "Root": "10000407",
        "LV": "1",
        "Item Description": "BOX LNCH WF CLASSIC (FRT&",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000407",
        "Product Status": "Active"
    },
    {
        "Root": "10007392",
        "LV": "1",
        "Item Description": "ZINF M&D EARTHQUAKE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "237600",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007392",
        "Product Status": "Active"
    },
    {
        "Root": "10019228",
        "LV": "1",
        "Item Description": "BROWNIE 72 CT WRP INDIV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "BRPKG",
        "Last Price Paid": "$2.65",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019228",
        "Product Status": "Active"
    },
    {
        "Root": "10008028",
        "LV": "3",
        "Item Description": "DSRT VALCKEN DORNFLDR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008028002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388265",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10008028",
        "Product Status": "Active"
    },
    {
        "Root": "10105429",
        "LV": "1",
        "Item Description": "MUSHROOM TRUMPET ROYALE 3LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "26065",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105429",
        "Product Status": "Active"
    },
    {
        "Root": "10001326",
        "LV": "2",
        "Item Description": "POTATO TATER PUFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001326002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6083968",
        "Last Price Paid": "$6.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001326",
        "Product Status": "Active"
    },
    {
        "Root": "10009996",
        "LV": "2",
        "Item Description": "LIQ WHT CREME DE CACAO BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009996001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009996",
        "Product Status": "Active"
    },
    {
        "Root": "10006256",
        "LV": "1",
        "Item Description": "BOWL PLASTIC BLACK 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6812440",
        "Last Price Paid": "$0.27",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006256",
        "Product Status": "Active"
    },
    {
        "Root": "10103852",
        "LV": "1",
        "Item Description": "CHIP TERRA VEGETABLE POTATO ORGINAL 1OZ/24CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6391767",
        "Last Price Paid": "$0.90",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103852",
        "Product Status": "Active"
    },
    {
        "Root": "10004483",
        "LV": "1",
        "Item Description": "SUSHI SASHIMI TUNA .6OZ/E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004483",
        "Product Status": "Active"
    },
    {
        "Root": "10001177",
        "LV": "1",
        "Item Description": "REDFISH FILET SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001177001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00028",
        "Last Price Paid": "$19.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001177",
        "Product Status": "Active"
    },
    {
        "Root": "10001177",
        "LV": "1",
        "Item Description": "REDFISH FILET SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001177001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3138835",
        "Last Price Paid": "$19.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001177",
        "Product Status": "Active"
    },
    {
        "Root": "10010358",
        "LV": "2",
        "Item Description": "BEER KONA BIG WAVE BTL 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010358001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "24118",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010358",
        "Product Status": "Active"
    },
    {
        "Root": "10000306",
        "LV": "1",
        "Item Description": "RADISH DAIKON PETITE 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PDR-33",
        "Last Price Paid": "$0.68",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000306",
        "Product Status": "Active"
    },
    {
        "Root": "10006952",
        "LV": "1",
        "Item Description": "CAB SAUV ESTANCIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9103031",
        "Last Price Paid": "$9.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006952",
        "Product Status": "Active"
    },
    {
        "Root": "10007055",
        "LV": "1",
        "Item Description": "CHARD ANTINORI BRAM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "585194",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007055",
        "Product Status": "Active"
    },
    {
        "Root": "10006315",
        "LV": "1",
        "Item Description": "BOX BAKERY 12X12X6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "171405",
        "Last Price Paid": "$0.84",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006315",
        "Product Status": "Active"
    },
    {
        "Root": "10014237",
        "LV": "1",
        "Item Description": "PARSLEY PINK TIPPED 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014237001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14PTP-33",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014237",
        "Product Status": "Active"
    },
    {
        "Root": "10016306",
        "LV": "1",
        "Item Description": "PINEAPPLE DICED 1/4\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481312",
        "Last Price Paid": "$35.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016306",
        "Product Status": "Active"
    },
    {
        "Root": "10030057",
        "LV": "1",
        "Item Description": "SYRUP REAL PASSION FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030057001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9247652",
        "Last Price Paid": "$5.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030057",
        "Product Status": "Active"
    },
    {
        "Root": "10007995",
        "LV": "1",
        "Item Description": "MERLOT SANTA EMMA RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2582849",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007995",
        "Product Status": "Active"
    },
    {
        "Root": "10001392",
        "LV": "1",
        "Item Description": "GARLIC BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001392001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450044",
        "Last Price Paid": "$39.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001392",
        "Product Status": "Active"
    },
    {
        "Root": "10001392",
        "LV": "1",
        "Item Description": "GARLIC BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001392001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "450044",
        "Last Price Paid": "$39.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001392",
        "Product Status": "Active"
    },
    {
        "Root": "10001392",
        "LV": "1",
        "Item Description": "GARLIC BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001392001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001392",
        "Product Status": "Active"
    },
    {
        "Root": "10104648",
        "LV": "1",
        "Item Description": "BEEF KABOB W/VEGT 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.94",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104648",
        "Product Status": "Active"
    },
    {
        "Root": "10010682",
        "LV": "1",
        "Item Description": "COGNAC HENNESSY VSOP 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "158146",
        "Last Price Paid": "$65.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010682",
        "Product Status": "Active"
    },
    {
        "Root": "10004361",
        "LV": "1",
        "Item Description": "CHOC ROCK MULTI COLORED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550569",
        "Last Price Paid": "$69.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004361",
        "Product Status": "Active"
    },
    {
        "Root": "10004361",
        "LV": "1",
        "Item Description": "CHOC ROCK MULTI COLORED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$69.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004361",
        "Product Status": "Active"
    },
    {
        "Root": "10033969",
        "LV": "1",
        "Item Description": "SELB-OST RIESL QBA FEINHERB 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069637",
        "Vendor": "IMPORT ANT WINES INCE",
        "Vendor Item Nb": "16122",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033969",
        "Product Status": "Active"
    },
    {
        "Root": "10004472",
        "LV": "1",
        "Item Description": "CANDY SKITTLES FUN SIZE W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004472001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "584274",
        "Last Price Paid": "$0.12",
        "Case Pack": "893",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004472",
        "Product Status": "Active"
    },
    {
        "Root": "10004472",
        "LV": "1",
        "Item Description": "CANDY SKITTLES FUN SIZE W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004472001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.12",
        "Case Pack": "893",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004472",
        "Product Status": "Active"
    },
    {
        "Root": "10005008",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK 64% COCOA BARRY X-BITTER GUAYAQUIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005008001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C105",
        "Last Price Paid": "$66.56",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005008",
        "Product Status": "Active"
    },
    {
        "Root": "10081358",
        "LV": "1",
        "Item Description": "CUP CLEAR GLASS ROCKS VINO EPCOT F&W 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510206",
        "Last Price Paid": "$1.01",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081358",
        "Product Status": "Active"
    },
    {
        "Root": "10030608",
        "LV": "1",
        "Item Description": "CHARDONNAY DOM SERENE EVENSTAD RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9681758",
        "Last Price Paid": "$50.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030608",
        "Product Status": "Active"
    },
    {
        "Root": "10025035",
        "LV": "2",
        "Item Description": "CUP BAKING SILVER FOIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025035002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8033",
        "Last Price Paid": "$0.04",
        "Case Pack": "390",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025035",
        "Product Status": "Active"
    },
    {
        "Root": "10038389",
        "LV": "1",
        "Item Description": "CRISPY CARAMEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "CCARAMEL-PB16",
        "Last Price Paid": "$78.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038389",
        "Product Status": "Active"
    },
    {
        "Root": "10006648",
        "LV": "1",
        "Item Description": "GLOW LIGHT WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000384112",
        "Vendor": "QUEST EVENTS LLC DBA TOTALLY MOD",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006648",
        "Product Status": "Active"
    },
    {
        "Root": "10107489",
        "LV": "1",
        "Item Description": "BOX PLAIN WHITE UNCOATED 5X5X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "5100019294",
        "Last Price Paid": "$0.42",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107489",
        "Product Status": "Active"
    },
    {
        "Root": "10076694",
        "LV": "2",
        "Item Description": "MIX SHAKE VANILLA 5% MIX 9HG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076694002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.69",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10076694",
        "Product Status": "Active"
    },
    {
        "Root": "10032506",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "9770-10",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032506",
        "Product Status": "Active"
    },
    {
        "Root": "10032506",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032506",
        "Product Status": "Active"
    },
    {
        "Root": "10010619",
        "LV": "2",
        "Item Description": "SCOTCH GLENFIDDICH 18YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010619001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9186065",
        "Last Price Paid": "$101.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010619",
        "Product Status": "Active"
    },
    {
        "Root": "10015346",
        "LV": "2",
        "Item Description": "CANDY CORN BULK 30LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015346002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "1999",
        "Last Price Paid": "$92.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015346",
        "Product Status": "Active"
    },
    {
        "Root": "10007396",
        "LV": "2",
        "Item Description": "SAUV BLANC ROUND POND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007396001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "258610",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007396",
        "Product Status": "Active"
    },
    {
        "Root": "10003634",
        "LV": "1",
        "Item Description": "CANDY SWEDISH FISH ASST 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585990",
        "Last Price Paid": "$28.43",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003634",
        "Product Status": "Active"
    },
    {
        "Root": "10040622",
        "LV": "1",
        "Item Description": "BEEF NY STRIP STEAK 10Z AUS GRAIN HALAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040622001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190401",
        "Last Price Paid": "$16.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040622",
        "Product Status": "Active"
    },
    {
        "Root": "10034215",
        "LV": "2",
        "Item Description": "MALBEC CATENA FLORES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034215002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "692381",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034215",
        "Product Status": "Active"
    },
    {
        "Root": "10006611",
        "LV": "1",
        "Item Description": "COVER BUN PAN 20 X 11 X 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5908363",
        "Last Price Paid": "$0.15",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006611",
        "Product Status": "Active"
    },
    {
        "Root": "10087933",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET STEER 3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087933001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190032",
        "Last Price Paid": "$22.33",
        "Case Pack": "11.25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087933",
        "Product Status": "Active"
    },
    {
        "Root": "10014609",
        "LV": "1",
        "Item Description": "GEECHIE BOY HEIRLOOM GRITS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598016",
        "Last Price Paid": "$78.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014609",
        "Product Status": "Active"
    },
    {
        "Root": "10001579",
        "LV": "1",
        "Item Description": "EDAMAME SHELLED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001579001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "510498",
        "Last Price Paid": "$57.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001579",
        "Product Status": "Active"
    },
    {
        "Root": "10013973",
        "LV": "1",
        "Item Description": "MERLOT BLEND MEERLUST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "526038",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013973",
        "Product Status": "Active"
    },
    {
        "Root": "10036045",
        "LV": "1",
        "Item Description": "SUGAR FOOTBALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "264",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036045",
        "Product Status": "Active"
    },
    {
        "Root": "10029092",
        "LV": "1",
        "Item Description": "CHAMPAGNE CHARTOGNE TAILLET BRUT SAINTE ANNE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029092001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "214936",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029092",
        "Product Status": "Active"
    },
    {
        "Root": "10007828",
        "LV": "1",
        "Item Description": "CAB SAUV NATURA ORG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "345684",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007828",
        "Product Status": "Active"
    },
    {
        "Root": "10094052",
        "LV": "3",
        "Item Description": "DISPOSABLE WHITE HOT FOOD CUP 12OZ/500CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094052002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "111860",
        "Last Price Paid": "",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10094052",
        "Product Status": "Active"
    },
    {
        "Root": "10003701",
        "LV": "1",
        "Item Description": "SB SPLENDA.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "186418",
        "Last Price Paid": "",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003701",
        "Product Status": "Active"
    },
    {
        "Root": "10035575",
        "LV": "1",
        "Item Description": "SUNFLOWER LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SFLYL",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035575",
        "Product Status": "Active"
    },
    {
        "Root": "10015542",
        "LV": "1",
        "Item Description": "BROWNIE BITES GF VEGAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015542",
        "Product Status": "Active"
    },
    {
        "Root": "10004291",
        "LV": "2",
        "Item Description": "SB SANDWICH BREAKFAST ROASTED HAM SWISS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004291002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11096090",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004291",
        "Product Status": "Active"
    },
    {
        "Root": "10023275",
        "LV": "2",
        "Item Description": "OSTRICH FAN FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023275002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4534006",
        "Last Price Paid": "",
        "Case Pack": "13",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10023275",
        "Product Status": "Active"
    },
    {
        "Root": "10004509",
        "LV": "1",
        "Item Description": "YEAST FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004509",
        "Product Status": "Active"
    },
    {
        "Root": "10036540",
        "LV": "1",
        "Item Description": "GRENACHE BLANC EPIPHANY  750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036540001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9155306",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036540",
        "Product Status": "Active"
    },
    {
        "Root": "10035962",
        "LV": "1",
        "Item Description": "CARAMEL CORN MIX SWEET SHOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2689",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035962",
        "Product Status": "Active"
    },
    {
        "Root": "10109330",
        "LV": "1",
        "Item Description": "BREAKFAST BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073744",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109330",
        "Product Status": "Active"
    },
    {
        "Root": "10003176",
        "LV": "1",
        "Item Description": "SAUCE PLUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6030639",
        "Last Price Paid": "$21.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003176",
        "Product Status": "Active"
    },
    {
        "Root": "10003662",
        "LV": "1",
        "Item Description": "LEAVES GOLD 23K",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003662001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "W100",
        "Last Price Paid": "$71.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003662",
        "Product Status": "Active"
    },
    {
        "Root": "10027735",
        "LV": "1",
        "Item Description": "CHIPS TORTILLA, CORN BLUE 6\" DIE CUT SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2975050",
        "Last Price Paid": "$0.06",
        "Case Pack": "720",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027735",
        "Product Status": "Active"
    },
    {
        "Root": "10003442",
        "LV": "1",
        "Item Description": "COFFEE JIKO BLND 1/5LB BA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$80.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003442",
        "Product Status": "Active"
    },
    {
        "Root": "10016581",
        "LV": "2",
        "Item Description": "LG FRY BOX 4.5X4.5X3 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016581002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51301127",
        "Last Price Paid": "$0.28",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016581",
        "Product Status": "Active"
    },
    {
        "Root": "10025153",
        "LV": "1",
        "Item Description": "MIX WAFFLE PANCAKE COMPLETE 6/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025153",
        "Product Status": "Active"
    },
    {
        "Root": "10100829",
        "LV": "1",
        "Item Description": "SB OWL CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11137207",
        "Last Price Paid": "$1.23",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100829",
        "Product Status": "Active"
    },
    {
        "Root": "10017979",
        "LV": "1",
        "Item Description": "LIQ BATAVIA ARRACK VAN OOSTEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677478",
        "Last Price Paid": "$25.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017979",
        "Product Status": "Active"
    },
    {
        "Root": "10036051",
        "LV": "1",
        "Item Description": "JUICE SIMPLY PINEAPPLE ORANGE 6/52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2655109",
        "Last Price Paid": "$4.89",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036051",
        "Product Status": "Active"
    },
    {
        "Root": "10027433",
        "LV": "1",
        "Item Description": "EXTRACT VIOLET LAVENDER WATER SOLUBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "83",
        "Last Price Paid": "$306.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027433",
        "Product Status": "Active"
    },
    {
        "Root": "10010438",
        "LV": "2",
        "Item Description": "BEER DOGFISH HEAD 90 IPA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010438001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "37256",
        "Last Price Paid": "$2.09",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010438",
        "Product Status": "Active"
    },
    {
        "Root": "10000284",
        "LV": "1",
        "Item Description": "SALTY FINGER MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490146",
        "Last Price Paid": "$0.28",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000284",
        "Product Status": "Active"
    },
    {
        "Root": "10002011",
        "LV": "1",
        "Item Description": "CILANTRO 10LB/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450082",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002011",
        "Product Status": "Active"
    },
    {
        "Root": "10002011",
        "LV": "1",
        "Item Description": "CILANTRO 10LB/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21010",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002011",
        "Product Status": "Active"
    },
    {
        "Root": "10002011",
        "LV": "1",
        "Item Description": "CILANTRO 10LB/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002011",
        "Product Status": "Active"
    },
    {
        "Root": "10038919",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE 2.5X1.25 INCH TRIANGLE ART REQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "1426CP",
        "Last Price Paid": "$0.39",
        "Case Pack": "612",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038919",
        "Product Status": "Active"
    },
    {
        "Root": "10005737",
        "LV": "3",
        "Item Description": "BREAD PITA FLATBREAD 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005737003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "2030714",
        "Last Price Paid": "$0.40",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10005737",
        "Product Status": "Active"
    },
    {
        "Root": "10010108",
        "LV": "1",
        "Item Description": "BEER CAN BUDWEISER LAGER 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010108001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10154",
        "Last Price Paid": "$1.06",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010108",
        "Product Status": "Active"
    },
    {
        "Root": "10027733",
        "LV": "1",
        "Item Description": "UTENSILS GELATO SPOONS ASSORTED COLORS NEON PP232",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11816002",
        "Last Price Paid": "$0.02",
        "Case Pack": "6800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027733",
        "Product Status": "Active"
    },
    {
        "Root": "10040395",
        "LV": "1",
        "Item Description": "SAUSAGE ANDOUILLE 9IN 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040395001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3602345",
        "Last Price Paid": "$3.27",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040395",
        "Product Status": "Active"
    },
    {
        "Root": "10103709",
        "LV": "1",
        "Item Description": "JUICE PET BODY ARMOR STRAW BANANA 16OZ/12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.59",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103709",
        "Product Status": "Active"
    },
    {
        "Root": "10014960",
        "LV": "2",
        "Item Description": "SCOTCH MACALLAN M 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014960002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294865",
        "Last Price Paid": "$5,062.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014960",
        "Product Status": "Active"
    },
    {
        "Root": "10033406",
        "LV": "1",
        "Item Description": "CHOC FLAKES PAILLETE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033406001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C901-BULK",
        "Last Price Paid": "$72.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033406",
        "Product Status": "Active"
    },
    {
        "Root": "10040905",
        "LV": "1",
        "Item Description": "OATS STEEL CUT 54OZ 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040905",
        "Product Status": "Active"
    },
    {
        "Root": "10040379",
        "LV": "2",
        "Item Description": "SPICE KANSAS CITY RUB 4-5LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040379002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040379",
        "Product Status": "Active"
    },
    {
        "Root": "10035746",
        "LV": "1",
        "Item Description": "SPRINKLES WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9467",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035746",
        "Product Status": "Active"
    },
    {
        "Root": "10006983",
        "LV": "1",
        "Item Description": "CHARD SILVERADO BLOCK BLEND 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006983001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "590299",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006983",
        "Product Status": "Active"
    },
    {
        "Root": "10003418",
        "LV": "1",
        "Item Description": "SYRUP SWISS CHOC MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003418001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1043",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003418",
        "Product Status": "Active"
    },
    {
        "Root": "10010109",
        "LV": "1",
        "Item Description": "BEER BUD LIGHT 12OZ CAN 6PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.06",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010109",
        "Product Status": "Active"
    },
    {
        "Root": "10010156",
        "LV": "1",
        "Item Description": "BITTERS ANGOSTURA 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "38000",
        "Last Price Paid": "$25.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010156",
        "Product Status": "Active"
    },
    {
        "Root": "10008197",
        "LV": "3",
        "Item Description": "SPARK ASTI SPUM M&R 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008197001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558262",
        "Last Price Paid": "$2.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10008197",
        "Product Status": "Active"
    },
    {
        "Root": "10005176",
        "LV": "1",
        "Item Description": "OIL PUMPKIN SEED 17Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01250",
        "Last Price Paid": "$23.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005176",
        "Product Status": "Active"
    },
    {
        "Root": "10006877",
        "LV": "1",
        "Item Description": "CAB SAUV COPA CHALICE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006877",
        "Product Status": "Active"
    },
    {
        "Root": "10038063",
        "LV": "1",
        "Item Description": "FLOUR BLUE CORN MASA S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480330",
        "Last Price Paid": "$9.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038063",
        "Product Status": "Active"
    },
    {
        "Root": "10014728",
        "LV": "1",
        "Item Description": "PINOT GRIGIO JERMANN 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9138808",
        "Last Price Paid": "$16.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014728",
        "Product Status": "Active"
    },
    {
        "Root": "10043054",
        "LV": "1",
        "Item Description": "INSERT IBOX 3 COMPARTMENT PP 7.75X5.5X1.81",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51377045",
        "Last Price Paid": "$0.19",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043054",
        "Product Status": "Active"
    },
    {
        "Root": "10007363",
        "LV": "1",
        "Item Description": "VALPOLICELLA ALLEGRINI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9101816",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007363",
        "Product Status": "Active"
    },
    {
        "Root": "10033245",
        "LV": "1",
        "Item Description": "CHOCOLATE MILK COATING COINS 1/11#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033245001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "540092",
        "Last Price Paid": "$40.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033245",
        "Product Status": "Active"
    },
    {
        "Root": "10009522",
        "LV": "2",
        "Item Description": "WHISKEY FORTY CRK PREMIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009522001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "557427",
        "Last Price Paid": "$17.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009522",
        "Product Status": "Active"
    },
    {
        "Root": "10104937",
        "LV": "1",
        "Item Description": "MUG BASE 100TH ELITE8 TINK PLAT 16OZ 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104937",
        "Product Status": "Active"
    },
    {
        "Root": "10000183",
        "LV": "1",
        "Item Description": "CUCUMBER SUPERSELECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "141085",
        "Last Price Paid": "$0.25",
        "Case Pack": "75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000183",
        "Product Status": "Active"
    },
    {
        "Root": "10002457",
        "LV": "2",
        "Item Description": "CHEESE MANCHEGO 6 MONTH SHEEP SPAIN 7 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002457002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "45011",
        "Last Price Paid": "$13.49",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002457",
        "Product Status": "Active"
    },
    {
        "Root": "10031024",
        "LV": "1",
        "Item Description": "COMPOUND MAPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031024001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "329",
        "Last Price Paid": "$120.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031024",
        "Product Status": "Active"
    },
    {
        "Root": "10017158",
        "LV": "2",
        "Item Description": "SHINE DORNFELDER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017158001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "274128",
        "Last Price Paid": "$8.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10017158",
        "Product Status": "Active"
    },
    {
        "Root": "10104590",
        "LV": "1",
        "Item Description": "PASSOVER BSC CHEESE OMELET WITH POTATO 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104590",
        "Product Status": "Active"
    },
    {
        "Root": "10001116",
        "LV": "1",
        "Item Description": "CLAM MIDDLENECK 100 CT 10 LB SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.38",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001116",
        "Product Status": "Active"
    },
    {
        "Root": "10001116",
        "LV": "1",
        "Item Description": "CLAM MIDDLENECK 100 CT 10 LB SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.38",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001116",
        "Product Status": "Active"
    },
    {
        "Root": "10001116",
        "LV": "1",
        "Item Description": "CLAM MIDDLENECK 100 CT 10 LB SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6912011",
        "Last Price Paid": "$0.38",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001116",
        "Product Status": "Active"
    },
    {
        "Root": "10092929",
        "LV": "2",
        "Item Description": "STRAW BOBA PAPER DISP 8.5IN WHITE UNWRAPPED 1080CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092929001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$78.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10092929",
        "Product Status": "Active"
    },
    {
        "Root": "10002013",
        "LV": "1",
        "Item Description": "HERB LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002013001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002013",
        "Product Status": "Active"
    },
    {
        "Root": "10002013",
        "LV": "1",
        "Item Description": "HERB LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002013001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450021",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002013",
        "Product Status": "Active"
    },
    {
        "Root": "10030028",
        "LV": "1",
        "Item Description": "PUMP MONIN SYRUP PLASTIC 1 LITER BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-9240B",
        "Last Price Paid": "$2.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030028",
        "Product Status": "Active"
    },
    {
        "Root": "10095489",
        "LV": "1",
        "Item Description": "VEAL KNUCKLE BONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095489001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1380001",
        "Last Price Paid": "$3.75",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095489",
        "Product Status": "Active"
    },
    {
        "Root": "10001492",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI BY THE PO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001492001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "802490",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001492",
        "Product Status": "Active"
    },
    {
        "Root": "10001492",
        "LV": "1",
        "Item Description": "SQUASH ZUCCHINI BY THE PO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001492001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001492",
        "Product Status": "Active"
    },
    {
        "Root": "10107630",
        "LV": "1",
        "Item Description": "SPICE DILL WEED 6/7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107630",
        "Product Status": "Active"
    },
    {
        "Root": "10031965",
        "LV": "1",
        "Item Description": "DRIED PLANTAIN CHIP 36-2.25OZ/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.89",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031965",
        "Product Status": "Active"
    },
    {
        "Root": "10006253",
        "LV": "1",
        "Item Description": "BAG DUBLOPEN SINGLE SERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006253001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008500",
        "Last Price Paid": "$0.02",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006253",
        "Product Status": "Active"
    },
    {
        "Root": "10009523",
        "LV": "1",
        "Item Description": "WHISKEY TAP 357 MAPLE RYE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "113229",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009523",
        "Product Status": "Active"
    },
    {
        "Root": "10009523",
        "LV": "1",
        "Item Description": "WHISKEY TAP 357 MAPLE RYE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009523001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "113229",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009523",
        "Product Status": "Active"
    },
    {
        "Root": "10101129",
        "LV": "1",
        "Item Description": "WINE STARK KARA TARA CHARDONNAY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9635520",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101129",
        "Product Status": "Active"
    },
    {
        "Root": "10093668",
        "LV": "1",
        "Item Description": "BEER CAN FIRST MAGNUTUDE TIGER EYE GOLD 16OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.27",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093668",
        "Product Status": "Active"
    },
    {
        "Root": "10009614",
        "LV": "1",
        "Item Description": "VODKA HANGAR MANDARIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558324",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009614",
        "Product Status": "Active"
    },
    {
        "Root": "10002449",
        "LV": "2",
        "Item Description": "ICE CREAM CHOCOLATE HDAZ 2.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002449002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002449",
        "Product Status": "Active"
    },
    {
        "Root": "10031555",
        "LV": "1",
        "Item Description": "SYRUP MONIN IRISH CREAM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1028",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031555",
        "Product Status": "Active"
    },
    {
        "Root": "10011981",
        "LV": "1",
        "Item Description": "COFFEE ESPRESSO CAPPUCCINO DECAF 300 CT - PRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.57",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011981",
        "Product Status": "Active"
    },
    {
        "Root": "10012357",
        "LV": "1",
        "Item Description": "COD ATLANTIC FILLET SKIN WILD MSC CERTIFIED SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012357001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "65-00141",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012357",
        "Product Status": "Active"
    },
    {
        "Root": "10005661",
        "LV": "1",
        "Item Description": "MIX CAKE RED VELVET 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005661001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$122.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005661",
        "Product Status": "Active"
    },
    {
        "Root": "10002113",
        "LV": "1",
        "Item Description": "HERB OREGANO 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482256",
        "Last Price Paid": "$11.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002113",
        "Product Status": "Active"
    },
    {
        "Root": "10002113",
        "LV": "1",
        "Item Description": "HERB OREGANO 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24120",
        "Last Price Paid": "$11.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002113",
        "Product Status": "Active"
    },
    {
        "Root": "10102913",
        "LV": "1",
        "Item Description": "KOSHER BSC PIZZA 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102913",
        "Product Status": "Active"
    },
    {
        "Root": "10004641",
        "LV": "1",
        "Item Description": "NUTRI GRAIN BAR BLUEBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9067141",
        "Last Price Paid": "$0.66",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004641",
        "Product Status": "Active"
    },
    {
        "Root": "10032805",
        "LV": "1",
        "Item Description": "BOARD CAKE 8 INCHX1/4INCH SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "E814-SILVER",
        "Last Price Paid": "$0.82",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032805",
        "Product Status": "Active"
    },
    {
        "Root": "10035868",
        "LV": "1",
        "Item Description": "BITTERS FEE BRO GRAPEFRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035868001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "202208",
        "Last Price Paid": "$7.11",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035868",
        "Product Status": "Active"
    },
    {
        "Root": "10091571",
        "LV": "1",
        "Item Description": "VODKA BLOOD ORANGE SKYY INFUSED 1.0L/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992493",
        "Last Price Paid": "$12.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091571",
        "Product Status": "Active"
    },
    {
        "Root": "10015481",
        "LV": "1",
        "Item Description": "VODKA BELVEDERE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "584233",
        "Last Price Paid": "$33.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015481",
        "Product Status": "Active"
    },
    {
        "Root": "10004203",
        "LV": "1",
        "Item Description": "VINEGAR WHITE 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1328335",
        "Last Price Paid": "$5.07",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004203",
        "Product Status": "Active"
    },
    {
        "Root": "10035390",
        "LV": "1",
        "Item Description": "FLORENTINE MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "633404",
        "Last Price Paid": "$3.60",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035390",
        "Product Status": "Active"
    },
    {
        "Root": "10000383",
        "LV": "1",
        "Item Description": "ONION RED SLIVERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514023",
        "Last Price Paid": "$11.83",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000383",
        "Product Status": "Active"
    },
    {
        "Root": "10033846",
        "LV": "1",
        "Item Description": "GRENACHE ESPELT GARNACHA OLD VINES CATALONIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "301309",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033846",
        "Product Status": "Active"
    },
    {
        "Root": "10091221",
        "LV": "1",
        "Item Description": "RUM SELVAREY COCONUT 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091221",
        "Product Status": "Active"
    },
    {
        "Root": "10008237",
        "LV": "2",
        "Item Description": "CAB SAUV BLND DOMINUS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008237001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550791",
        "Last Price Paid": "$269.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008237",
        "Product Status": "Active"
    },
    {
        "Root": "10002971",
        "LV": "1",
        "Item Description": "TEA ENGLSH BRKFST DECF 25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002971",
        "Product Status": "Active"
    },
    {
        "Root": "10007946",
        "LV": "1",
        "Item Description": "CHAMP KRUG GRAND CUV 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "359522",
        "Last Price Paid": "$77.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007946",
        "Product Status": "Active"
    },
    {
        "Root": "10038807",
        "LV": "1",
        "Item Description": "CHOCOLATE MODELING BRIGHT WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "11011",
        "Last Price Paid": "$46.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038807",
        "Product Status": "Active"
    },
    {
        "Root": "10018986",
        "LV": "1",
        "Item Description": "MOLD BAKING BRN TORTINA 4X1.25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51408018",
        "Last Price Paid": "$0.20",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018986",
        "Product Status": "Active"
    },
    {
        "Root": "10020297",
        "LV": "2",
        "Item Description": "LOUIS ROED BRUT PREMIER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020297002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$45.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10020297",
        "Product Status": "Active"
    },
    {
        "Root": "10013500",
        "LV": "1",
        "Item Description": "STEIN DROID BB-8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "CB-BB8",
        "Last Price Paid": "$3.56",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013500",
        "Product Status": "Active"
    },
    {
        "Root": "10013500",
        "LV": "1",
        "Item Description": "STEIN DROID BB-8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013500001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.56",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013500",
        "Product Status": "Active"
    },
    {
        "Root": "10018795",
        "LV": "2",
        "Item Description": "DÉCOR NON EDIBLE DISCO SPARKLES SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018795002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11142",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018795",
        "Product Status": "Active"
    },
    {
        "Root": "10002991",
        "LV": "1",
        "Item Description": "DRESSING VNGRTT DISNEY 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.98",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002991",
        "Product Status": "Active"
    },
    {
        "Root": "10019216",
        "LV": "2",
        "Item Description": "MUSHROOM MOREL FRSH DOM. 2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019216002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "MUSWIL019",
        "Last Price Paid": "$68.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019216",
        "Product Status": "Active"
    },
    {
        "Root": "10000057",
        "LV": "1",
        "Item Description": "BEEF SHORT RIB 123B HALF CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000057001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190077",
        "Last Price Paid": "$8.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000057",
        "Product Status": "Active"
    },
    {
        "Root": "10001680",
        "LV": "1",
        "Item Description": "GINGER ROOT 30# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001680001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001680",
        "Product Status": "Active"
    },
    {
        "Root": "10001680",
        "LV": "1",
        "Item Description": "GINGER ROOT 30# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001680001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "28025",
        "Last Price Paid": "$48.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001680",
        "Product Status": "Active"
    },
    {
        "Root": "10001680",
        "LV": "1",
        "Item Description": "GINGER ROOT 30# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001680001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450040",
        "Last Price Paid": "$48.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001680",
        "Product Status": "Active"
    },
    {
        "Root": "10102922",
        "LV": "1",
        "Item Description": "KOSHER BSC BEEF BRISKET KUGEL CO 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102922",
        "Product Status": "Active"
    },
    {
        "Root": "10028112",
        "LV": "1",
        "Item Description": "BEER KONA WAILUA WHEAT 12 OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "27418",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028112",
        "Product Status": "Active"
    },
    {
        "Root": "10040904",
        "LV": "2",
        "Item Description": "CHOC THE CHILD WHITE 2 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040904001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW6156",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10040904",
        "Product Status": "Active"
    },
    {
        "Root": "10007140",
        "LV": "2",
        "Item Description": "GRUNER VELTLINER LAUR SING 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007140001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9006364",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007140",
        "Product Status": "Active"
    },
    {
        "Root": "10109310",
        "LV": "1",
        "Item Description": "PRODUCE LICORICE LACE 25 CT PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05LILA-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109310",
        "Product Status": "Active"
    },
    {
        "Root": "10010330",
        "LV": "2",
        "Item Description": "BEER MAGIC HAT NUMBER 9 BT 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010330001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "37018",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010330",
        "Product Status": "Active"
    },
    {
        "Root": "10039921",
        "LV": "1",
        "Item Description": "BEER BLUE POINT IPA 12Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039921",
        "Product Status": "Active"
    },
    {
        "Root": "10009894",
        "LV": "2",
        "Item Description": "PORT TAYLOR 1ST EST FLADGATE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009894001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009894",
        "Product Status": "Active"
    },
    {
        "Root": "10100233",
        "LV": "1",
        "Item Description": "CHOCOLATE OVAL BINX CAT TAIL EA WHITE 2\" X 1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100233001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW45496",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100233",
        "Product Status": "Active"
    },
    {
        "Root": "10045197",
        "LV": "1",
        "Item Description": "SPOON PLAST COMPOSTABLE GP DIXIE ULTRA SMARTSTOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045197001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "960",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045197",
        "Product Status": "Active"
    },
    {
        "Root": "10036736",
        "LV": "1",
        "Item Description": "PRALINE CROQUANTINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500490000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036736",
        "Product Status": "Active"
    },
    {
        "Root": "10036093",
        "LV": "1",
        "Item Description": "BRANDY CARDENAL MENDOZA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036093001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "327419",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036093",
        "Product Status": "Active"
    },
    {
        "Root": "10012652",
        "LV": "1",
        "Item Description": "PASTE GEL SKY BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SKY BLUE",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012652",
        "Product Status": "Active"
    },
    {
        "Root": "10036037",
        "LV": "1",
        "Item Description": "LAGER DUARA DAMM 11.2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036037001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "706129",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036037",
        "Product Status": "Active"
    },
    {
        "Root": "10002693",
        "LV": "2",
        "Item Description": "CEREAL RICE CRISPY BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002693002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002693",
        "Product Status": "Active"
    },
    {
        "Root": "10001373",
        "LV": "1",
        "Item Description": "POWDER TOMATO 1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481494",
        "Last Price Paid": "$17.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001373",
        "Product Status": "Active"
    },
    {
        "Root": "10005214",
        "LV": "2",
        "Item Description": "SB SYRUP GINGERBREAD LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005214001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11065078",
        "Last Price Paid": "$9.81",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005214",
        "Product Status": "Active"
    },
    {
        "Root": "10000923",
        "LV": "1",
        "Item Description": "MUSSEL MEAT CKD IQF FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000923001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6408000",
        "Last Price Paid": "$3.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000923",
        "Product Status": "Active"
    },
    {
        "Root": "10033625",
        "LV": "1",
        "Item Description": "VODKA KETEL BOTANICAL CUCMBER MINT 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033625",
        "Product Status": "Active"
    },
    {
        "Root": "10007829",
        "LV": "1",
        "Item Description": "BLEND EDGEBASTON PEPPER POT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "EDGEPEPPOT",
        "Last Price Paid": "$9.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007829",
        "Product Status": "Active"
    },
    {
        "Root": "10006442",
        "LV": "1",
        "Item Description": "BAG ICE 50 LB WITH WICKET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6731368",
        "Last Price Paid": "$0.27",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006442",
        "Product Status": "Active"
    },
    {
        "Root": "10044246",
        "LV": "1",
        "Item Description": "MARKER WOOD STEAK MED RARE 5000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "406557",
        "Last Price Paid": "$0.01",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044246",
        "Product Status": "Active"
    },
    {
        "Root": "10024787",
        "LV": "1",
        "Item Description": "BEEF STRIP 10 OZ EE 2VE GRAIN FED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024787001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190235",
        "Last Price Paid": "$13.75",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024787",
        "Product Status": "Active"
    },
    {
        "Root": "10038926",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE 1.5 INCH DISC ART REQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "698CP",
        "Last Price Paid": "$0.42",
        "Case Pack": "486",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038926",
        "Product Status": "Active"
    },
    {
        "Root": "10002053",
        "LV": "1",
        "Item Description": "BEAN FAVA FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002053001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002053",
        "Product Status": "Active"
    },
    {
        "Root": "10002053",
        "LV": "1",
        "Item Description": "BEAN FAVA FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002053001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002053",
        "Product Status": "Active"
    },
    {
        "Root": "10013400",
        "LV": "1",
        "Item Description": "CIDER ANGRY ORCHRD APL CAN 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21349",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013400",
        "Product Status": "Active"
    },
    {
        "Root": "10031974",
        "LV": "1",
        "Item Description": "JUICE ORANGE SIMPLY 6/52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2977741",
        "Last Price Paid": "$4.89",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031974",
        "Product Status": "Active"
    },
    {
        "Root": "10095570",
        "LV": "1",
        "Item Description": "PEA, DRIED WASABI 1/5 LB.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6530046",
        "Last Price Paid": "$68.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095570",
        "Product Status": "Active"
    },
    {
        "Root": "10001714",
        "LV": "1",
        "Item Description": "SQUASH CHAYOTE 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001714001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482025",
        "Last Price Paid": "$18.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001714",
        "Product Status": "Active"
    },
    {
        "Root": "10001714",
        "LV": "1",
        "Item Description": "SQUASH CHAYOTE 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001714001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001714",
        "Product Status": "Active"
    },
    {
        "Root": "10040014",
        "LV": "1",
        "Item Description": "CHICKEN THIGH BONE WHOLE FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040014001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.31",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040014",
        "Product Status": "Active"
    },
    {
        "Root": "10044390",
        "LV": "1",
        "Item Description": "COOKIE, EPCOT, FLOGERES WALNUT CINNAMON HONEY 60/3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5351103",
        "Last Price Paid": "$0.76",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044390",
        "Product Status": "Active"
    },
    {
        "Root": "10041156",
        "LV": "1",
        "Item Description": "BEER PILSNER ORANGE BLOSSOM 16 OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "39505",
        "Last Price Paid": "$1.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041156",
        "Product Status": "Active"
    },
    {
        "Root": "10103492",
        "LV": "1",
        "Item Description": "CRANBERRY WHOLE IQF 2/5# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1327709",
        "Last Price Paid": "$12.37",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103492",
        "Product Status": "Active"
    },
    {
        "Root": "10040501",
        "LV": "1",
        "Item Description": "SAUCE BBQ GOLD MUSTARD 1 GAL 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4939644",
        "Last Price Paid": "$17.97",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040501",
        "Product Status": "Active"
    },
    {
        "Root": "10001709",
        "LV": "1",
        "Item Description": "SQUASH BABY SUNBURST/STAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22320",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001709",
        "Product Status": "Active"
    },
    {
        "Root": "10001709",
        "LV": "1",
        "Item Description": "SQUASH BABY SUNBURST/STAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001709",
        "Product Status": "Active"
    },
    {
        "Root": "10001709",
        "LV": "1",
        "Item Description": "SQUASH BABY SUNBURST/STAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001709",
        "Product Status": "Active"
    },
    {
        "Root": "10001709",
        "LV": "1",
        "Item Description": "SQUASH BABY SUNBURST/STAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480085",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001709",
        "Product Status": "Active"
    },
    {
        "Root": "10007935",
        "LV": "1",
        "Item Description": "PINOT NOIR M EDWARD RR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$56.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007935",
        "Product Status": "Active"
    },
    {
        "Root": "10008058",
        "LV": "1",
        "Item Description": "CHARD J LOHR RIVERSTN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "142767",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008058",
        "Product Status": "Active"
    },
    {
        "Root": "10002220",
        "LV": "2",
        "Item Description": "MILK NONDAIRY SILK SOY VANILLA 8OZ/18CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002220001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "534618",
        "Last Price Paid": "$1.06",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002220",
        "Product Status": "Active"
    },
    {
        "Root": "10040897",
        "LV": "1",
        "Item Description": "PLATE PAPER 9 IN MARY BLAIR 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040897",
        "Product Status": "Active"
    },
    {
        "Root": "10008031",
        "LV": "2",
        "Item Description": "SAUV BLC EMMOLO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008031001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "591183",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008031",
        "Product Status": "Active"
    },
    {
        "Root": "10083158",
        "LV": "1",
        "Item Description": "BOX GABLE 3.5X3.5X8.5 KRAFT FS321",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51013746",
        "Last Price Paid": "$0.59",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083158",
        "Product Status": "Active"
    },
    {
        "Root": "10014906",
        "LV": "1",
        "Item Description": "TEA GOLDEN CARAMEL ROOIBOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.49",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014906",
        "Product Status": "Active"
    },
    {
        "Root": "10031607",
        "LV": "1",
        "Item Description": "OLLI NAPOLI SALAMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "77711",
        "Last Price Paid": "$6.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031607",
        "Product Status": "Active"
    },
    {
        "Root": "10004947",
        "LV": "1",
        "Item Description": "PUREE COCONUT 6/1PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B571",
        "Last Price Paid": "$14.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004947",
        "Product Status": "Active"
    },
    {
        "Root": "10025701",
        "LV": "1",
        "Item Description": "CUCUMBER EURO UBU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025701001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20410",
        "Last Price Paid": "$1.56",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025701",
        "Product Status": "Active"
    },
    {
        "Root": "10003159",
        "LV": "1",
        "Item Description": "SYRUP LYCHEE MONIN 4/LITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5030",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003159",
        "Product Status": "Active"
    },
    {
        "Root": "10000350",
        "LV": "1",
        "Item Description": "ARUGULA B&W ICELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21000",
        "Last Price Paid": "$11.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000350",
        "Product Status": "Active"
    },
    {
        "Root": "10000350",
        "LV": "1",
        "Item Description": "ARUGULA B&W ICELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000350",
        "Product Status": "Active"
    },
    {
        "Root": "10000350",
        "LV": "1",
        "Item Description": "ARUGULA B&W ICELESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000350001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "624005",
        "Last Price Paid": "$11.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000350",
        "Product Status": "Active"
    },
    {
        "Root": "10032508",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032508",
        "Product Status": "Active"
    },
    {
        "Root": "10032508",
        "LV": "1",
        "Item Description": "GLOVES NITRILE POWDER FREE FOOD SAFE VIOLET LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032508001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "9770-30",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032508",
        "Product Status": "Active"
    },
    {
        "Root": "10007007",
        "LV": "1",
        "Item Description": "CAB SHIRAZ BLK PEARL ORO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "BLAPERORO",
        "Last Price Paid": "$11.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007007",
        "Product Status": "Active"
    },
    {
        "Root": "10003005",
        "LV": "2",
        "Item Description": "PASTA DRY PENNE MINI 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003005002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003005",
        "Product Status": "Active"
    },
    {
        "Root": "10102129",
        "LV": "1",
        "Item Description": "BEER KEG HARPOON MASS BAY UFO TRIPLE BERRY  1/2 BB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15476",
        "Last Price Paid": "$174.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102129",
        "Product Status": "Active"
    },
    {
        "Root": "10038563",
        "LV": "1",
        "Item Description": "POPCORN CARMEL APPLE 20# FW ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038563001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000369805",
        "Vendor": "DANDY COTTON CANDY COMPANY INC",
        "Vendor Item Nb": "CARAPP45",
        "Last Price Paid": "$3.87",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038563",
        "Product Status": "Active"
    },
    {
        "Root": "10008160",
        "LV": "1",
        "Item Description": "SPARK DOMAINE CARNEROS BRUT 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "249603",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008160",
        "Product Status": "Active"
    },
    {
        "Root": "10001900",
        "LV": "1",
        "Item Description": "APPLE SLCD GRANNY SMITH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511575",
        "Last Price Paid": "$26.13",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001900",
        "Product Status": "Active"
    },
    {
        "Root": "10095630",
        "LV": "1",
        "Item Description": "WINE ROSE CHATEAU SAINTE MARGUERITE 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "828416",
        "Last Price Paid": "$23.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095630",
        "Product Status": "Active"
    },
    {
        "Root": "10037883",
        "LV": "1",
        "Item Description": "BUTTER PLUGRA UNSALTED 1LB 36CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "44277",
        "Last Price Paid": "$4.26",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037883",
        "Product Status": "Active"
    },
    {
        "Root": "10001516",
        "LV": "1",
        "Item Description": "RADISH FALL MIX BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01MFR-26",
        "Last Price Paid": "$88.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001516",
        "Product Status": "Active"
    },
    {
        "Root": "10039449",
        "LV": "1",
        "Item Description": "CEREAL CORN FLAKES BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.34",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039449",
        "Product Status": "Active"
    },
    {
        "Root": "10104968",
        "LV": "1",
        "Item Description": "CABBAGE KIMCHI FERMT REF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1664625",
        "Last Price Paid": "$29.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104968",
        "Product Status": "Active"
    },
    {
        "Root": "10004459",
        "LV": "1",
        "Item Description": "MIX FREESTYLE MEL YELLOW/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004459001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3832738",
        "Last Price Paid": "$64.52",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004459",
        "Product Status": "Active"
    },
    {
        "Root": "10002351",
        "LV": "1",
        "Item Description": "GELATO VANILLA BEAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-VANB-08-HP",
        "Last Price Paid": "$9.34",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002351",
        "Product Status": "Active"
    },
    {
        "Root": "10007747",
        "LV": "2",
        "Item Description": "MADEIRA BLANDY 10YR MALMSEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007747001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "275895",
        "Last Price Paid": "$22.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007747",
        "Product Status": "Active"
    },
    {
        "Root": "10011584",
        "LV": "1",
        "Item Description": "SB LID HOT 8 OZ PACTIV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "DDL8SBUXPP",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011584",
        "Product Status": "Active"
    },
    {
        "Root": "10028013",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC KIM CRAWFORD 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "252147",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028013",
        "Product Status": "Active"
    },
    {
        "Root": "10107507",
        "LV": "1",
        "Item Description": "WINE CHAT DES CHARMES CABERNETI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9690760",
        "Last Price Paid": "$14.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107507",
        "Product Status": "Active"
    },
    {
        "Root": "10015026",
        "LV": "2",
        "Item Description": "BEER BELL 2 HEART IPA 16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015026002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "57293",
        "Last Price Paid": "$1.88",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015026",
        "Product Status": "Active"
    },
    {
        "Root": "10016933",
        "LV": "1",
        "Item Description": "BEEF KIDNEY FAT SUET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016933001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1100005",
        "Last Price Paid": "$1.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016933",
        "Product Status": "Active"
    },
    {
        "Root": "10001131",
        "LV": "1",
        "Item Description": "TUNA AHI SASHIMI LOIN 1 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001131001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000445754",
        "Vendor": "OCEAN BEAUTY SEAFOODS LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001131",
        "Product Status": "Active"
    },
    {
        "Root": "10001131",
        "LV": "1",
        "Item Description": "TUNA AHI SASHIMI LOIN 1 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001131001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-01242",
        "Last Price Paid": "$21.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001131",
        "Product Status": "Active"
    },
    {
        "Root": "10001131",
        "LV": "1",
        "Item Description": "TUNA AHI SASHIMI LOIN 1 LB SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001131001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6505264",
        "Last Price Paid": "$21.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001131",
        "Product Status": "Active"
    },
    {
        "Root": "10001793",
        "LV": "1",
        "Item Description": "SQUASH DELICATA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001793001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480075",
        "Last Price Paid": "$54.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001793",
        "Product Status": "Active"
    },
    {
        "Root": "10016443",
        "LV": "3",
        "Item Description": "MUSTARD VOL PK 1/3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016443002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10016443",
        "Product Status": "Active"
    },
    {
        "Root": "10001106",
        "LV": "1",
        "Item Description": "MUSSEL GRN LIPFRZN SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001106001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6407450",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001106",
        "Product Status": "Active"
    },
    {
        "Root": "10040437",
        "LV": "1",
        "Item Description": "CUP COLD BAMBOO NO TREE 22OZ CU-SU-22C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.15",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040437",
        "Product Status": "Active"
    },
    {
        "Root": "10005761",
        "LV": "1",
        "Item Description": "BAGEL PLAIN MINI 2.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005761",
        "Product Status": "Active"
    },
    {
        "Root": "10003988",
        "LV": "1",
        "Item Description": "CRACKER TOWNHSE BULK 7.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1003995",
        "Last Price Paid": "$42.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003988",
        "Product Status": "Active"
    },
    {
        "Root": "10030845",
        "LV": "2",
        "Item Description": "PASTE FLAVORING GOCHUJANG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030845002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4016699",
        "Last Price Paid": "$4.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030845",
        "Product Status": "Active"
    },
    {
        "Root": "10044399",
        "LV": "1",
        "Item Description": "SPICE NUTMEG GROUND PLASTIC SHAKER BOTTLE 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760355",
        "Last Price Paid": "$17.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044399",
        "Product Status": "Active"
    },
    {
        "Root": "10014524",
        "LV": "1",
        "Item Description": "LINER TRAY WHT NON SKID 15X23",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11139023",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014524",
        "Product Status": "Active"
    },
    {
        "Root": "10019966",
        "LV": "1",
        "Item Description": "BLND SKOURAS MOSHOFILERO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019966",
        "Product Status": "Active"
    },
    {
        "Root": "10001637",
        "LV": "1",
        "Item Description": "MUSHROOM YUNAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001637",
        "Product Status": "Active"
    },
    {
        "Root": "10081675",
        "LV": "1",
        "Item Description": "CHAMP PAUL CHENEAU BLANC D BLANC CAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081675001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081675",
        "Product Status": "Active"
    },
    {
        "Root": "10013526",
        "LV": "1",
        "Item Description": "ROSE PAINTED WOLF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "PAIWOLROSE",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013526",
        "Product Status": "Active"
    },
    {
        "Root": "10092252",
        "LV": "1",
        "Item Description": "BOURBON WOODFORD RESERVE 1L/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21733",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092252",
        "Product Status": "Active"
    },
    {
        "Root": "10034968",
        "LV": "1",
        "Item Description": "MONIN COOKIE BUTTER L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034968",
        "Product Status": "Active"
    },
    {
        "Root": "10029303",
        "LV": "1",
        "Item Description": "BREAD CONEWICH 4\" WHITE BAKED 80 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9877910",
        "Last Price Paid": "",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029303",
        "Product Status": "Active"
    },
    {
        "Root": "10028196",
        "LV": "1",
        "Item Description": "PORK STEAMSHIP ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028196001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1423202",
        "Last Price Paid": "",
        "Case Pack": "22",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028196",
        "Product Status": "Active"
    },
    {
        "Root": "10029231",
        "LV": "2",
        "Item Description": "BORDEAUX CH PICHON BARON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029231002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300999",
        "Last Price Paid": "$195.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029231",
        "Product Status": "Active"
    },
    {
        "Root": "10006270",
        "LV": "2",
        "Item Description": "PLATE/TRAY MINI 3 COMPT P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006270002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7848799",
        "Last Price Paid": "$0.72",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006270",
        "Product Status": "Active"
    },
    {
        "Root": "10039579",
        "LV": "1",
        "Item Description": "SANGRIA RED OPICI FAMILY 3 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039579001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039579",
        "Product Status": "Active"
    },
    {
        "Root": "10020235",
        "LV": "1",
        "Item Description": "GLASS WINE FESTIVAL10OZ GOVINO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11502001",
        "Last Price Paid": "$0.84",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020235",
        "Product Status": "Active"
    },
    {
        "Root": "10007273",
        "LV": "2",
        "Item Description": "SAUV BLC SOUTHERN RIGHT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007273001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2773331",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007273",
        "Product Status": "Active"
    },
    {
        "Root": "10006958",
        "LV": "1",
        "Item Description": "RIES DR LOOSEN QBA SCRW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006958",
        "Product Status": "Active"
    },
    {
        "Root": "10088730",
        "LV": "1",
        "Item Description": "SYRUP MONIN FROSTED MINT 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5016",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088730",
        "Product Status": "Active"
    },
    {
        "Root": "10034109",
        "LV": "1",
        "Item Description": "ORANGE COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011104",
        "Last Price Paid": "$75.59",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034109",
        "Product Status": "Active"
    },
    {
        "Root": "10001494",
        "LV": "1",
        "Item Description": "PEA ENGLISH SHELLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489785",
        "Last Price Paid": "$34.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001494",
        "Product Status": "Active"
    },
    {
        "Root": "10001494",
        "LV": "1",
        "Item Description": "PEA ENGLISH SHELLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001494",
        "Product Status": "Active"
    },
    {
        "Root": "10004629",
        "LV": "1",
        "Item Description": "TOPPING MALLO CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4024691",
        "Last Price Paid": "$8.51",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004629",
        "Product Status": "Active"
    },
    {
        "Root": "10005918",
        "LV": "1",
        "Item Description": "BREAD BAGUETTE PUMPERNICKEL 14 OZ EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "LONG 2.5 WIDTH",
        "Last Price Paid": "$1.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005918",
        "Product Status": "Active"
    },
    {
        "Root": "10029198",
        "LV": "1",
        "Item Description": "SALAD KALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029198001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1712021",
        "Last Price Paid": "$5.00",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029198",
        "Product Status": "Active"
    },
    {
        "Root": "10009622",
        "LV": "3",
        "Item Description": "TEQ CASA NOBLE BLANCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009622002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992948",
        "Last Price Paid": "$31.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009622",
        "Product Status": "Active"
    },
    {
        "Root": "10016336",
        "LV": "1",
        "Item Description": "CUPCAKE LG RD VEL ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1646",
        "Last Price Paid": "$1.08",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016336",
        "Product Status": "Active"
    },
    {
        "Root": "10034187",
        "LV": "2",
        "Item Description": "BLACKBERRIES STANDARD 12/6OZ FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034187002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "250012",
        "Last Price Paid": "$2.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034187",
        "Product Status": "Active"
    },
    {
        "Root": "10002702",
        "LV": "1",
        "Item Description": "PASTA DRY ROTINI TRI COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002702001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "428139",
        "Last Price Paid": "$1.61",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002702",
        "Product Status": "Active"
    },
    {
        "Root": "10003130",
        "LV": "1",
        "Item Description": "COFFEE JOFF HAW KON DCF 5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$43.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003130",
        "Product Status": "Active"
    },
    {
        "Root": "10106031",
        "LV": "3",
        "Item Description": "BEEF SIRLOIN TRI TIP SEASONED COOKED 7/2.5LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106031002003",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "5651",
        "Last Price Paid": "$9.90",
        "Case Pack": "2.5",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10106031",
        "Product Status": "Active"
    },
    {
        "Root": "10009502",
        "LV": "1",
        "Item Description": "BOURBON KNOB CREEK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "986040",
        "Last Price Paid": "$30.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009502",
        "Product Status": "Active"
    },
    {
        "Root": "10000865",
        "LV": "1",
        "Item Description": "TURKEY SAUSAGE PATTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5898451",
        "Last Price Paid": "$0.33",
        "Case Pack": "115",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000865",
        "Product Status": "Active"
    },
    {
        "Root": "10094994",
        "LV": "1",
        "Item Description": "SHELL CANNOLI LG 48 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "G80297",
        "Last Price Paid": "$0.48",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094994",
        "Product Status": "Active"
    },
    {
        "Root": "10028473",
        "LV": "2",
        "Item Description": "BEER CARIB LAGER 12 OUNCE BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028473002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11492",
        "Last Price Paid": "$1.61",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028473",
        "Product Status": "Active"
    },
    {
        "Root": "10043717",
        "LV": "1",
        "Item Description": "MARINADE MOJO LIQUID GALLON JUG 4/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "668335",
        "Last Price Paid": "$9.05",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043717",
        "Product Status": "Active"
    },
    {
        "Root": "10024595",
        "LV": "1",
        "Item Description": "TEA ENGLISH BREAKFAST K CUP 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024595",
        "Product Status": "Active"
    },
    {
        "Root": "10028643",
        "LV": "1",
        "Item Description": "PASTRY CINNAMON ROLLS LARGE 4IN ICED BAKED 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028643001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028643",
        "Product Status": "Active"
    },
    {
        "Root": "10001611",
        "LV": "1",
        "Item Description": "SALAD POTATO RED SKIN O.K",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2982079",
        "Last Price Paid": "$18.71",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001611",
        "Product Status": "Active"
    },
    {
        "Root": "10029789",
        "LV": "1",
        "Item Description": "CANDY TOPPING MINI RAINBOW NON-PARIELS 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "525330",
        "Last Price Paid": "$64.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029789",
        "Product Status": "Active"
    },
    {
        "Root": "10000041",
        "LV": "1",
        "Item Description": "SAUSAGE RIVERSIDE 5X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000041001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000041",
        "Product Status": "Active"
    },
    {
        "Root": "10091434",
        "LV": "1",
        "Item Description": "BOX GANACHERY 9CT 2PC RIBBON PULL PMS 232OU 83/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "990065",
        "Last Price Paid": "$3.43",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091434",
        "Product Status": "Active"
    },
    {
        "Root": "10012122",
        "LV": "1",
        "Item Description": "CHICKEN FEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012122001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4200003",
        "Last Price Paid": "$2.64",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012122",
        "Product Status": "Active"
    },
    {
        "Root": "10001870",
        "LV": "1",
        "Item Description": "PEPPER GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001870001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001870",
        "Product Status": "Active"
    },
    {
        "Root": "10009994",
        "LV": "1",
        "Item Description": "LIQ TRIPLE SEC 30 PRF LTR BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009994",
        "Product Status": "Active"
    },
    {
        "Root": "10003170",
        "LV": "1",
        "Item Description": "SYRUP COFF COCONUT LTR 4E",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5013",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003170",
        "Product Status": "Active"
    },
    {
        "Root": "10042851",
        "LV": "1",
        "Item Description": "YOGURT ASSORTED GREEK LIGHT SS CUP STRAW/BLU 12/5.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6902639",
        "Last Price Paid": "$1.21",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042851",
        "Product Status": "Active"
    },
    {
        "Root": "10003555",
        "LV": "1",
        "Item Description": "SPICE GINGER GRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003555",
        "Product Status": "Active"
    },
    {
        "Root": "10104431",
        "LV": "1",
        "Item Description": "BEER DRAFT BOULEVARD TANK 7 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "13552",
        "Last Price Paid": "$223.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104431",
        "Product Status": "Active"
    },
    {
        "Root": "10016957",
        "LV": "1",
        "Item Description": "SPICE MARJORAM LEAF 6/3.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4538088",
        "Last Price Paid": "$7.30",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016957",
        "Product Status": "Active"
    },
    {
        "Root": "10009498",
        "LV": "2",
        "Item Description": "BOURBON BUFFALO TRACE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009498001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9031302",
        "Last Price Paid": "$21.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009498",
        "Product Status": "Active"
    },
    {
        "Root": "10003990",
        "LV": "1",
        "Item Description": "RICE BASMATI WHITE 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003990001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "380880",
        "Last Price Paid": "$17.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003990",
        "Product Status": "Active"
    },
    {
        "Root": "10006384",
        "LV": "2",
        "Item Description": "FILTER BUNN (20100) TEA BREWERS 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006384001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006384",
        "Product Status": "Active"
    },
    {
        "Root": "10003348",
        "LV": "1",
        "Item Description": "COFFEE AL FORNO REG BN 5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003348",
        "Product Status": "Active"
    },
    {
        "Root": "10080789",
        "LV": "1",
        "Item Description": "CHAMP LA MARCA PROSECCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10080789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9064483",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10080789",
        "Product Status": "Active"
    },
    {
        "Root": "10094930",
        "LV": "1",
        "Item Description": "POTSTICKER EDAMAME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "362451",
        "Last Price Paid": "$119.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094930",
        "Product Status": "Active"
    },
    {
        "Root": "10106050",
        "LV": "1",
        "Item Description": "HGDZS CARAMEL CONE CUP 12X3.6FLOZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.07",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106050",
        "Product Status": "Active"
    },
    {
        "Root": "10009916",
        "LV": "2",
        "Item Description": "LIQ LIMONCELLO VILLA MARZIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009916001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "297174",
        "Last Price Paid": "$17.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009916",
        "Product Status": "Active"
    },
    {
        "Root": "10002873",
        "LV": "1",
        "Item Description": "POWERADE MTN BLST 20OZ PE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002873",
        "Product Status": "Active"
    },
    {
        "Root": "10009972",
        "LV": "1",
        "Item Description": "LIQ AMARULA CREAM LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009972",
        "Product Status": "Active"
    },
    {
        "Root": "10001963",
        "LV": "1",
        "Item Description": "HERB SENSATION MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001963001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MHS-33-L",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001963",
        "Product Status": "Active"
    },
    {
        "Root": "10000574",
        "LV": "2",
        "Item Description": "PORK LOIN BONELESS CC STRP/OFF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000574001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "26815",
        "Last Price Paid": "$1.51",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000574",
        "Product Status": "Active"
    },
    {
        "Root": "10001964",
        "LV": "1",
        "Item Description": "FLOWER JOHNNY JUMP UP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05JJ-24",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001964",
        "Product Status": "Active"
    },
    {
        "Root": "10003481",
        "LV": "1",
        "Item Description": "SYRUP VANILLA SF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003481001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003481",
        "Product Status": "Active"
    },
    {
        "Root": "10036055",
        "LV": "1",
        "Item Description": "FELIPE II BRANDY DE JEREZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "978837",
        "Last Price Paid": "$17.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036055",
        "Product Status": "Active"
    },
    {
        "Root": "10017217",
        "LV": "1",
        "Item Description": "LIQUEUR KLEINER FEIGLNG FIG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017217",
        "Product Status": "Active"
    },
    {
        "Root": "10017217",
        "LV": "1",
        "Item Description": "LIQUEUR KLEINER FEIGLNG FIG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069637",
        "Vendor": "IMPORT ANT WINES INCE",
        "Vendor Item Nb": "40112",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017217",
        "Product Status": "Active"
    },
    {
        "Root": "10009203",
        "LV": "3",
        "Item Description": "MERLOT SILVERADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009203002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "568132",
        "Last Price Paid": "$27.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009203",
        "Product Status": "Active"
    },
    {
        "Root": "10001408",
        "LV": "1",
        "Item Description": "SPROUT BRUSSEL RED BABY F",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07BRBS-11",
        "Last Price Paid": "$145.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001408",
        "Product Status": "Active"
    },
    {
        "Root": "10026098",
        "LV": "1",
        "Item Description": "MUG TINY TONIC CLR 2.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026098001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11417152",
        "Last Price Paid": "$0.52",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026098",
        "Product Status": "Active"
    },
    {
        "Root": "10026101",
        "LV": "1",
        "Item Description": "PLATE RECT 10 X 5 SUGARCANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11043105",
        "Last Price Paid": "$0.12",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026101",
        "Product Status": "Active"
    },
    {
        "Root": "10000302",
        "LV": "1",
        "Item Description": "HEARTS OF FIRE MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401142",
        "Last Price Paid": "$13.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000302",
        "Product Status": "Active"
    },
    {
        "Root": "10000302",
        "LV": "1",
        "Item Description": "HEARTS OF FIRE MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000302",
        "Product Status": "Active"
    },
    {
        "Root": "10014767",
        "LV": "2",
        "Item Description": "SYRUP AGAVE ORG 11.6OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014767002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY351",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014767",
        "Product Status": "Active"
    },
    {
        "Root": "10015561",
        "LV": "1",
        "Item Description": "KALE LACINATO BABY 2LB CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07BLA-P",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015561",
        "Product Status": "Active"
    },
    {
        "Root": "10000857",
        "LV": "1",
        "Item Description": "CHICK GROUND 50/50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000857001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "42-28000",
        "Last Price Paid": "$3.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000857",
        "Product Status": "Active"
    },
    {
        "Root": "10102231",
        "LV": "2",
        "Item Description": "CREAM VEGAN VIOLIFE JUST LIKE HEAVY CREAM 1L/8CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102231001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "91304075",
        "Last Price Paid": "$4.97",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10102231",
        "Product Status": "Active"
    },
    {
        "Root": "10004693",
        "LV": "2",
        "Item Description": "CANDY GUMMY WORMS 4/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004693002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "589150",
        "Last Price Paid": "$12.86",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004693",
        "Product Status": "Active"
    },
    {
        "Root": "10004113",
        "LV": "1",
        "Item Description": "SB SYRUP SGR FREE VAN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11084108",
        "Last Price Paid": "$6.58",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004113",
        "Product Status": "Active"
    },
    {
        "Root": "10036401",
        "LV": "1",
        "Item Description": "MINNIE BOW SM RED W/ WHT DOTS 1 1/2X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036401001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW322926",
        "Last Price Paid": "$0.45",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036401",
        "Product Status": "Active"
    },
    {
        "Root": "10001702",
        "LV": "1",
        "Item Description": "HERB SAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001702001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24140",
        "Last Price Paid": "$13.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001702",
        "Product Status": "Active"
    },
    {
        "Root": "10001702",
        "LV": "1",
        "Item Description": "HERB SAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001702001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001702",
        "Product Status": "Active"
    },
    {
        "Root": "10001702",
        "LV": "1",
        "Item Description": "HERB SAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001702001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482258",
        "Last Price Paid": "$13.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001702",
        "Product Status": "Active"
    },
    {
        "Root": "10035192",
        "LV": "1",
        "Item Description": "CHARDONNAY NEIL ELLIS ELGIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035192001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "485755",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035192",
        "Product Status": "Active"
    },
    {
        "Root": "10091224",
        "LV": "1",
        "Item Description": "WINE SUSANA BALBO MALBEC ARGENTINA 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9189096",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091224",
        "Product Status": "Active"
    },
    {
        "Root": "10110139",
        "LV": "1",
        "Item Description": "PEANUT 1/50# SACK JUMBO RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270657",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110139",
        "Product Status": "Active"
    },
    {
        "Root": "10027555",
        "LV": "1",
        "Item Description": "LAMB DENVER RIB BONE IN FRZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027555001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240206",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027555",
        "Product Status": "Active"
    },
    {
        "Root": "10035333",
        "LV": "1",
        "Item Description": "CALLA LILY SMALL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CLK9 WH",
        "Last Price Paid": "",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035333",
        "Product Status": "Active"
    },
    {
        "Root": "10006031",
        "LV": "1",
        "Item Description": "CLIP LIGHT-UP BUZZ LIGHTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006031",
        "Product Status": "Active"
    },
    {
        "Root": "10035929",
        "LV": "1",
        "Item Description": "CHAMPAGNE MOET BRUT GRAND VINTAGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "929676",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035929",
        "Product Status": "Active"
    },
    {
        "Root": "10074776",
        "LV": "1",
        "Item Description": "BAG MERCHANDISE KRAFT 10X2X15 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10074776001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51014590",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10074776",
        "Product Status": "Active"
    },
    {
        "Root": "10022377",
        "LV": "1",
        "Item Description": "CHOC DRK SQ 1 IN LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW63202D",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022377",
        "Product Status": "Active"
    },
    {
        "Root": "10001791",
        "LV": "1",
        "Item Description": "MUSHROOM JUMBO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001791",
        "Product Status": "Active"
    },
    {
        "Root": "10001791",
        "LV": "1",
        "Item Description": "MUSHROOM JUMBO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405020",
        "Last Price Paid": "$22.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001791",
        "Product Status": "Active"
    },
    {
        "Root": "10040234",
        "LV": "1",
        "Item Description": "WHITE BLEND LITTLE JAMES 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040234001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "929477",
        "Last Price Paid": "$10.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040234",
        "Product Status": "Active"
    },
    {
        "Root": "10008158",
        "LV": "1",
        "Item Description": "CAB SAUV BERING KNGHT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9015682",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008158",
        "Product Status": "Active"
    },
    {
        "Root": "10001889",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "800054",
        "Last Price Paid": "$14.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001889",
        "Product Status": "Active"
    },
    {
        "Root": "10001889",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "27-004-34",
        "Last Price Paid": "$14.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001889",
        "Product Status": "Active"
    },
    {
        "Root": "10007999",
        "LV": "1",
        "Item Description": "CAB SAUV L MARTINI NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9616247",
        "Last Price Paid": "$30.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007999",
        "Product Status": "Active"
    },
    {
        "Root": "10016842",
        "LV": "2",
        "Item Description": "DRAFT TAMPA REEF DONKEY HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016842001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "83502",
        "Last Price Paid": "$191.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016842",
        "Product Status": "Active"
    },
    {
        "Root": "10014640",
        "LV": "1",
        "Item Description": "GAS CO2 MICRO BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014640001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "CD FGBLKMB",
        "Last Price Paid": "$0.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014640",
        "Product Status": "Active"
    },
    {
        "Root": "10082832",
        "LV": "1",
        "Item Description": "MIX BAR ICE CREAM CONCENTRATE 2TO1 12/32OZ FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2073997",
        "Last Price Paid": "$7.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082832",
        "Product Status": "Active"
    },
    {
        "Root": "10003782",
        "LV": "1",
        "Item Description": "FILLING STRAWBERRY 6/10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4331005",
        "Last Price Paid": "$13.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003782",
        "Product Status": "Active"
    },
    {
        "Root": "10036405",
        "LV": "1",
        "Item Description": "CAKE PINEAPPLE UPSIDE DOWN 4 MK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1901",
        "Last Price Paid": "$2.01",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036405",
        "Product Status": "Active"
    },
    {
        "Root": "10000656",
        "LV": "1",
        "Item Description": "PORK SCHNITZEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000656001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1426510",
        "Last Price Paid": "$4.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000656",
        "Product Status": "Active"
    },
    {
        "Root": "10007657",
        "LV": "2",
        "Item Description": "WINE RED BLEND RUFFINO MODUS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007657001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "530661",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007657",
        "Product Status": "Active"
    },
    {
        "Root": "10002193",
        "LV": "1",
        "Item Description": "SOUR CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.04",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002193",
        "Product Status": "Active"
    },
    {
        "Root": "10001972",
        "LV": "1",
        "Item Description": "PARSLEY MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MPA-33-L",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001972",
        "Product Status": "Active"
    },
    {
        "Root": "10088797",
        "LV": "1",
        "Item Description": "CHAMP CHARLES HEIDSIECK BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9045609",
        "Last Price Paid": "$42.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088797",
        "Product Status": "Active"
    },
    {
        "Root": "10107915",
        "LV": "1",
        "Item Description": "JAURE 700ML SPARKLING WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "JAU2",
        "Last Price Paid": "$3.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107915",
        "Product Status": "Active"
    },
    {
        "Root": "10003550",
        "LV": "1",
        "Item Description": "SPICE JAM JERK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003550",
        "Product Status": "Active"
    },
    {
        "Root": "10004998",
        "LV": "1",
        "Item Description": "DECOR CHOC TWISTER DARK & WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I96270",
        "Last Price Paid": "$52.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004998",
        "Product Status": "Active"
    },
    {
        "Root": "10013164",
        "LV": "3",
        "Item Description": "LIQ ST ELDER ELDERFLOWER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013164003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "851356",
        "Last Price Paid": "$15.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10013164",
        "Product Status": "Active"
    },
    {
        "Root": "10039988",
        "LV": "1",
        "Item Description": "BEEF NY STRIP STEAK 12Z CC PRIME ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039988001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2194999",
        "Last Price Paid": "$24.47",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039988",
        "Product Status": "Active"
    },
    {
        "Root": "10000203",
        "LV": "1",
        "Item Description": "CARROT CHIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000203001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482018",
        "Last Price Paid": "$5.84",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000203",
        "Product Status": "Active"
    },
    {
        "Root": "10036148",
        "LV": "1",
        "Item Description": "VEGGIE CUP CARROT AND CELERY STICKS W/RANCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515634",
        "Last Price Paid": "$1.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036148",
        "Product Status": "Active"
    },
    {
        "Root": "10015460",
        "LV": "1",
        "Item Description": "PEPPADEW CREAM CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7302830",
        "Last Price Paid": "$1.65",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015460",
        "Product Status": "Active"
    },
    {
        "Root": "10031973",
        "LV": "1",
        "Item Description": "JUICE GRAPEFRUIT SIMPLY 6/52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031973001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5150265",
        "Last Price Paid": "$4.89",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031973",
        "Product Status": "Active"
    },
    {
        "Root": "10013272",
        "LV": "1",
        "Item Description": "BEET BLUSH SUNRISE 25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01SBB-33",
        "Last Price Paid": "$0.45",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013272",
        "Product Status": "Active"
    },
    {
        "Root": "10007926",
        "LV": "1",
        "Item Description": "CAB SAUV GAMESKEEPER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "846038",
        "Last Price Paid": "$11.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007926",
        "Product Status": "Active"
    },
    {
        "Root": "10021831",
        "LV": "1",
        "Item Description": "CHOC DRK GOOFY EARS 480 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021831001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021831",
        "Product Status": "Active"
    },
    {
        "Root": "10004368",
        "LV": "1",
        "Item Description": "CONE SUGAR GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "108378",
        "Last Price Paid": "$3.81",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004368",
        "Product Status": "Active"
    },
    {
        "Root": "10110269",
        "LV": "1",
        "Item Description": "BOX SLEEVE 6CT GANACHERY HOLIDAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51020006",
        "Last Price Paid": "$0.39",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110269",
        "Product Status": "Active"
    },
    {
        "Root": "10003696",
        "LV": "1",
        "Item Description": "SB BAR CINNAMON 1LB CNTNR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003696001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "166051",
        "Last Price Paid": "$6.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003696",
        "Product Status": "Active"
    },
    {
        "Root": "10016952",
        "LV": "1",
        "Item Description": "SPICE POWDER ANCHO CHILI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7359177",
        "Last Price Paid": "$10.53",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016952",
        "Product Status": "Active"
    },
    {
        "Root": "10007671",
        "LV": "2",
        "Item Description": "CHARD ANTINORI CERVARO SALA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007671001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "556470",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007671",
        "Product Status": "Active"
    },
    {
        "Root": "10009500",
        "LV": "2",
        "Item Description": "BOURBON ANGELS ENVY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009500001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "404725",
        "Last Price Paid": "$36.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009500",
        "Product Status": "Active"
    },
    {
        "Root": "10008151",
        "LV": "1",
        "Item Description": "CAB SAUV STARK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2769149",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008151",
        "Product Status": "Active"
    },
    {
        "Root": "10002725",
        "LV": "1",
        "Item Description": "RICE PARBOILED WHITE 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002725",
        "Product Status": "Active"
    },
    {
        "Root": "10005411",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP LEMON 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D585-A6448",
        "Last Price Paid": "$17.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005411",
        "Product Status": "Active"
    },
    {
        "Root": "10008746",
        "LV": "1",
        "Item Description": "CORVINA GIARETTA RECIOTO 500ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "386763",
        "Last Price Paid": "$24.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008746",
        "Product Status": "Active"
    },
    {
        "Root": "10001649",
        "LV": "1",
        "Item Description": "CANTALOUPE PLD/HLVD 8CT T",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511610",
        "Last Price Paid": "$3.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001649",
        "Product Status": "Active"
    },
    {
        "Root": "10001649",
        "LV": "1",
        "Item Description": "CANTALOUPE PLD/HLVD 8CT T",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001649001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-001H",
        "Last Price Paid": "$3.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001649",
        "Product Status": "Active"
    },
    {
        "Root": "10040957",
        "LV": "2",
        "Item Description": "PUREE STRAWBERRY 6/1KG ANDROS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040957002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "708017",
        "Last Price Paid": "$10.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040957",
        "Product Status": "Active"
    },
    {
        "Root": "10106731",
        "LV": "1",
        "Item Description": "LAMB HINDSHANK 18-22OZ AUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106731001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1506311",
        "Last Price Paid": "$4.67",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106731",
        "Product Status": "Active"
    },
    {
        "Root": "10014408",
        "LV": "1",
        "Item Description": "BABY SPINACH (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014408",
        "Product Status": "Active"
    },
    {
        "Root": "10009461",
        "LV": "1",
        "Item Description": "SCOTCH GLENLIVET LTR 12 YR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009461001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "15501",
        "Last Price Paid": "$55.32",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009461",
        "Product Status": "Active"
    },
    {
        "Root": "10003178",
        "LV": "1",
        "Item Description": "SYRUP COFF LIME HABANERO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003178001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5146",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003178",
        "Product Status": "Active"
    },
    {
        "Root": "10016330",
        "LV": "2",
        "Item Description": "CUPCAKE MINI RD VEL ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016330002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1640",
        "Last Price Paid": "$0.49",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016330",
        "Product Status": "Active"
    },
    {
        "Root": "10038207",
        "LV": "1",
        "Item Description": "SB FRAPPUCCINO CREME SYRUP BASE 63OZ 4CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11071915",
        "Last Price Paid": "$8.87",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038207",
        "Product Status": "Active"
    },
    {
        "Root": "10001716",
        "LV": "1",
        "Item Description": "TOMATILLOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001716",
        "Product Status": "Active"
    },
    {
        "Root": "10001716",
        "LV": "1",
        "Item Description": "TOMATILLOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482190",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001716",
        "Product Status": "Active"
    },
    {
        "Root": "10001716",
        "LV": "1",
        "Item Description": "TOMATILLOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001716001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23102",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001716",
        "Product Status": "Active"
    },
    {
        "Root": "10004876",
        "LV": "1",
        "Item Description": "JUICE LEMON IND 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4329249",
        "Last Price Paid": "$0.07",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004876",
        "Product Status": "Active"
    },
    {
        "Root": "10004453",
        "LV": "1",
        "Item Description": "MIX FREESTYLE DIET COKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004453001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4051571",
        "Last Price Paid": "$64.52",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004453",
        "Product Status": "Active"
    },
    {
        "Root": "10013985",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "96398",
        "Last Price Paid": "$114.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013985",
        "Product Status": "Active"
    },
    {
        "Root": "10002187",
        "LV": "1",
        "Item Description": "MILK HOMO 1 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002187",
        "Product Status": "Active"
    },
    {
        "Root": "10005647",
        "LV": "2",
        "Item Description": "SHELL TART MICKEY VANILLA SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005647001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8092096",
        "Last Price Paid": "$0.71",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005647",
        "Product Status": "Active"
    },
    {
        "Root": "10009483",
        "LV": "1",
        "Item Description": "SCOTCH GLENMORNG 10YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009483001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "549083",
        "Last Price Paid": "$36.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009483",
        "Product Status": "Active"
    },
    {
        "Root": "10040592",
        "LV": "1",
        "Item Description": "CHEESE MASCARPONE VERMONT CREAMERY 5 LB TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040592001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "17022",
        "Last Price Paid": "$7.82",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040592",
        "Product Status": "Active"
    },
    {
        "Root": "10034225",
        "LV": "1",
        "Item Description": "LID CLEAR DOME NO HOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034225001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11262017",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034225",
        "Product Status": "Active"
    },
    {
        "Root": "10004327",
        "LV": "1",
        "Item Description": "HORS LOTUS WONTON CUPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7300510",
        "Last Price Paid": "$0.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004327",
        "Product Status": "Active"
    },
    {
        "Root": "10104428",
        "LV": "1",
        "Item Description": "SAUCE BBQ CANNONBALL CUP 1.25OZ/100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104428001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.14",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104428",
        "Product Status": "Active"
    },
    {
        "Root": "10015360",
        "LV": "1",
        "Item Description": "DEC ROSE TEA SMALL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKK WH",
        "Last Price Paid": "$1.12",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015360",
        "Product Status": "Active"
    },
    {
        "Root": "10001527",
        "LV": "1",
        "Item Description": "ONION PURPLETTE PETITE 50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PO-33",
        "Last Price Paid": "$0.68",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001527",
        "Product Status": "Active"
    },
    {
        "Root": "10003166",
        "LV": "2",
        "Item Description": "QUINS SNOWFLAKE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003166002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5830",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003166",
        "Product Status": "Active"
    },
    {
        "Root": "10003866",
        "LV": "1",
        "Item Description": "DEC BALLOON MICKEY WWYC 3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003866001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.25",
        "Case Pack": "312",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003866",
        "Product Status": "Active"
    },
    {
        "Root": "10100751",
        "LV": "1",
        "Item Description": "FILLO SHELLS MINI TRADITIONAL (PHYLLO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "7244",
        "Last Price Paid": "$0.21",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100751",
        "Product Status": "Active"
    },
    {
        "Root": "10006876",
        "LV": "1",
        "Item Description": "CHARD COPA CHALICE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006876",
        "Product Status": "Active"
    },
    {
        "Root": "10103333",
        "LV": "1",
        "Item Description": "INK PC-CTRG-CYAN-EPSON U SERIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28009",
        "Last Price Paid": "$17.82",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103333",
        "Product Status": "Active"
    },
    {
        "Root": "10010039",
        "LV": "2",
        "Item Description": "BEER SIERRA NEVD TORP 16Z CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010039001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "35603",
        "Last Price Paid": "$1.61",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010039",
        "Product Status": "Active"
    },
    {
        "Root": "10089043",
        "LV": "1",
        "Item Description": "MIX PUREE REAL LYCHEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9429081",
        "Last Price Paid": "$4.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089043",
        "Product Status": "Active"
    },
    {
        "Root": "10002653",
        "LV": "2",
        "Item Description": "SPICE PEPPER RED CRUSHED 4/3 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002653002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00317-3",
        "Last Price Paid": "$9.96",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002653",
        "Product Status": "Active"
    },
    {
        "Root": "10002653",
        "LV": "2",
        "Item Description": "SPICE PEPPER RED CRUSHED 4/3 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002653002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.96",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002653",
        "Product Status": "Active"
    },
    {
        "Root": "10015202",
        "LV": "1",
        "Item Description": "CAB SAUV DETORREN BOOK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "801629",
        "Last Price Paid": "$252.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015202",
        "Product Status": "Active"
    },
    {
        "Root": "10009859",
        "LV": "1",
        "Item Description": "PORT FAIRBANKS 1.5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009859001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009859",
        "Product Status": "Active"
    },
    {
        "Root": "10001308",
        "LV": "1",
        "Item Description": "CORN COB 5-1/2 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001308",
        "Product Status": "Active"
    },
    {
        "Root": "10014373",
        "LV": "1",
        "Item Description": "SYRUP GUAVA MONIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014373001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5066",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014373",
        "Product Status": "Active"
    },
    {
        "Root": "10001745",
        "LV": "1",
        "Item Description": "POMEGRANATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001745",
        "Product Status": "Active"
    },
    {
        "Root": "10001745",
        "LV": "1",
        "Item Description": "POMEGRANATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390550",
        "Last Price Paid": "$49.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001745",
        "Product Status": "Active"
    },
    {
        "Root": "10009814",
        "LV": "2",
        "Item Description": "RUM THOMAS TEW 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009814001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "980270",
        "Last Price Paid": "$28.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009814",
        "Product Status": "Active"
    },
    {
        "Root": "10023809",
        "LV": "1",
        "Item Description": "TOMATO CHERRY ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "723520",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023809",
        "Product Status": "Active"
    },
    {
        "Root": "10040315",
        "LV": "1",
        "Item Description": "STRAW PLASTIC GREEN ADA 7.75IN 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11113010",
        "Last Price Paid": "",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040315",
        "Product Status": "Active"
    },
    {
        "Root": "10035089",
        "LV": "1",
        "Item Description": "TRANSFER SHEET MUSIC NOTE GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035089001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "TS112G",
        "Last Price Paid": "",
        "Case Pack": "17",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035089",
        "Product Status": "Active"
    },
    {
        "Root": "10003514",
        "LV": "2",
        "Item Description": "TEA ICE PEAR GRN 24CT 3.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003514001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "ITLL9907",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003514",
        "Product Status": "Active"
    },
    {
        "Root": "10109433",
        "LV": "1",
        "Item Description": "WHITE CHOC OVAL 1 1/2 X 1 FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "1119W",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109433",
        "Product Status": "Active"
    },
    {
        "Root": "10035251",
        "LV": "1",
        "Item Description": "SPIDER LG WHITE 2 X 2 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035251",
        "Product Status": "Active"
    },
    {
        "Root": "10030072",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC MATUA MARLBOROUGH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030072",
        "Product Status": "Active"
    },
    {
        "Root": "10023582",
        "LV": "1",
        "Item Description": "SILVER FLAKE SHAKER EDIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CRS/CUBE",
        "Last Price Paid": "",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023582",
        "Product Status": "Active"
    },
    {
        "Root": "10001437",
        "LV": "1",
        "Item Description": "KALE LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482219",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001437",
        "Product Status": "Active"
    },
    {
        "Root": "10010062",
        "LV": "1",
        "Item Description": "BEER HEINEKEN CAN 12OZ 24PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010062",
        "Product Status": "Active"
    },
    {
        "Root": "10003358",
        "LV": "2",
        "Item Description": "CORE PWR LIGHT STRWBRRY B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003358002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "156188",
        "Last Price Paid": "$2.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003358",
        "Product Status": "Active"
    },
    {
        "Root": "10002592",
        "LV": "1",
        "Item Description": "PLUM 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512004",
        "Last Price Paid": "$38.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002592",
        "Product Status": "Active"
    },
    {
        "Root": "10002592",
        "LV": "1",
        "Item Description": "PLUM 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "512004",
        "Last Price Paid": "$38.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002592",
        "Product Status": "Active"
    },
    {
        "Root": "10006810",
        "LV": "1",
        "Item Description": "CHOC MINNIE BUNNY EARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW32291",
        "Last Price Paid": "$1.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006810",
        "Product Status": "Active"
    },
    {
        "Root": "10002835",
        "LV": "1",
        "Item Description": "SAUCE SOY 5Z BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002835001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002835",
        "Product Status": "Active"
    },
    {
        "Root": "10018061",
        "LV": "1",
        "Item Description": "GRENACHE BEAUCASTEL CHATEAUNEUF DU PAPE BLAN BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9631545",
        "Last Price Paid": "$76.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018061",
        "Product Status": "Active"
    },
    {
        "Root": "10004382",
        "LV": "1",
        "Item Description": "TOMATO DICED BELLA ROSA 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004382001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5329230",
        "Last Price Paid": "$5.35",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004382",
        "Product Status": "Active"
    },
    {
        "Root": "10001393",
        "LV": "1",
        "Item Description": "SPROUT BRUSSELS BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001393",
        "Product Status": "Active"
    },
    {
        "Root": "10001393",
        "LV": "1",
        "Item Description": "SPROUT BRUSSELS BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406087",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001393",
        "Product Status": "Active"
    },
    {
        "Root": "10010593",
        "LV": "1",
        "Item Description": "KAMMER WILLIAMS PEAR IN BTL 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30132",
        "Last Price Paid": "$45.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010593",
        "Product Status": "Active"
    },
    {
        "Root": "10031109",
        "LV": "1",
        "Item Description": "LAVOSH ROSEMARY 3.15\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "L612",
        "Last Price Paid": "$0.13",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031109",
        "Product Status": "Active"
    },
    {
        "Root": "10015564",
        "LV": "1",
        "Item Description": "SQUASH ACORN DICED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015564001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481917",
        "Last Price Paid": "$10.08",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015564",
        "Product Status": "Active"
    },
    {
        "Root": "10004509",
        "LV": "2",
        "Item Description": "YEAST FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004509001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4612164",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004509",
        "Product Status": "Active"
    },
    {
        "Root": "10029131",
        "LV": "1",
        "Item Description": "HERBS PETITE MEADOW SORREL MIX S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480611",
        "Last Price Paid": "$14.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029131",
        "Product Status": "Active"
    },
    {
        "Root": "10043306",
        "LV": "1",
        "Item Description": "BATTER CORN DOG MIX 6/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2271062",
        "Last Price Paid": "$5.82",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043306",
        "Product Status": "Active"
    },
    {
        "Root": "10001171",
        "LV": "1",
        "Item Description": "SALMON VERLASSO PTN 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001171001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6485310",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001171",
        "Product Status": "Active"
    },
    {
        "Root": "10004029",
        "LV": "1",
        "Item Description": "GAS MIX 75%CO2 25%NIT TNK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "4173",
        "Last Price Paid": "$26.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004029",
        "Product Status": "Active"
    },
    {
        "Root": "10001985",
        "LV": "1",
        "Item Description": "SORREL RED RIBBON PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22116",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001985",
        "Product Status": "Active"
    },
    {
        "Root": "10001985",
        "LV": "1",
        "Item Description": "SORREL RED RIBBON PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480695",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001985",
        "Product Status": "Active"
    },
    {
        "Root": "10001985",
        "LV": "1",
        "Item Description": "SORREL RED RIBBON PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08PRR-33",
        "Last Price Paid": "$32.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001985",
        "Product Status": "Active"
    },
    {
        "Root": "10009488",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN 18YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40791",
        "Last Price Paid": "$312.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009488",
        "Product Status": "Active"
    },
    {
        "Root": "10001484",
        "LV": "1",
        "Item Description": "MUSHROOM FOREST MIX DRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001484001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPMU225",
        "Last Price Paid": "$25.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001484",
        "Product Status": "Active"
    },
    {
        "Root": "10001484",
        "LV": "1",
        "Item Description": "MUSHROOM FOREST MIX DRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001484001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50120",
        "Last Price Paid": "$25.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001484",
        "Product Status": "Active"
    },
    {
        "Root": "10001484",
        "LV": "1",
        "Item Description": "MUSHROOM FOREST MIX DRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001484001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001484",
        "Product Status": "Active"
    },
    {
        "Root": "10106376",
        "LV": "1",
        "Item Description": "TOASTED COOKIE TOPPING (DRY ROASTED MACADAMIA SUGA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11145222",
        "Last Price Paid": "$5.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106376",
        "Product Status": "Active"
    },
    {
        "Root": "10030432",
        "LV": "1",
        "Item Description": "MOSCATO MARENCO MOSCATO D ASTI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547541",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030432",
        "Product Status": "Active"
    },
    {
        "Root": "10104950",
        "LV": "1",
        "Item Description": "SPICE ANISE GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-46045",
        "Last Price Paid": "$13.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104950",
        "Product Status": "Active"
    },
    {
        "Root": "10010055",
        "LV": "1",
        "Item Description": "BEER YUENGLING CAN 16Z 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "337788",
        "Last Price Paid": "$1.07",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010055",
        "Product Status": "Active"
    },
    {
        "Root": "10008103",
        "LV": "1",
        "Item Description": "CAB JUSTIN ISOSCELES 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008103001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570446",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008103",
        "Product Status": "Active"
    },
    {
        "Root": "10040236",
        "LV": "1",
        "Item Description": "TUSCAN ROSSO MOTTA PIEVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040236001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "212293",
        "Last Price Paid": "$17.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040236",
        "Product Status": "Active"
    },
    {
        "Root": "10000757",
        "LV": "1",
        "Item Description": "BUFFALO SAUSAGE LINK 4X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000757001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SAUS060",
        "Last Price Paid": "$10.13",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000757",
        "Product Status": "Active"
    },
    {
        "Root": "10001729",
        "LV": "1",
        "Item Description": "BEAN HERICOT VERT SNPD 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480054",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001729",
        "Product Status": "Active"
    },
    {
        "Root": "10001729",
        "LV": "1",
        "Item Description": "BEAN HERICOT VERT SNPD 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20092",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001729",
        "Product Status": "Active"
    },
    {
        "Root": "10001729",
        "LV": "1",
        "Item Description": "BEAN HERICOT VERT SNPD 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001729",
        "Product Status": "Active"
    },
    {
        "Root": "10011670",
        "LV": "1",
        "Item Description": "FLOUR TAPIOCA GF BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011670001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101384",
        "Last Price Paid": "$3.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011670",
        "Product Status": "Active"
    },
    {
        "Root": "10030725",
        "LV": "1",
        "Item Description": "PINOT NOIR LOUIS LATOUR CORTON CLOS DU ROI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "512306",
        "Last Price Paid": "$141.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030725",
        "Product Status": "Active"
    },
    {
        "Root": "10030081",
        "LV": "1",
        "Item Description": "ROOT VEG MIX DICED 1/2\" FC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030081001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481626",
        "Last Price Paid": "$11.23",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030081",
        "Product Status": "Active"
    },
    {
        "Root": "10037592",
        "LV": "1",
        "Item Description": "HAM DICED 1/4 IN FROZEN 4/2.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037592001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "02312",
        "Last Price Paid": "$3.26",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037592",
        "Product Status": "Active"
    },
    {
        "Root": "10003730",
        "LV": "2",
        "Item Description": "POWDER RASPBERRY FINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003730002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U30037",
        "Last Price Paid": "$85.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003730",
        "Product Status": "Active"
    },
    {
        "Root": "10010138",
        "LV": "3",
        "Item Description": "MIX BAR COCO CREAM 12/16.9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010138002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "9006278",
        "Last Price Paid": "$3.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10010138",
        "Product Status": "Active"
    },
    {
        "Root": "10032228",
        "LV": "1",
        "Item Description": "HONEY GRANULES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032228",
        "Product Status": "Active"
    },
    {
        "Root": "10100908",
        "LV": "1",
        "Item Description": "PINK PINEAPPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16108",
        "Last Price Paid": "$8.87",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100908",
        "Product Status": "Active"
    },
    {
        "Root": "10109547",
        "LV": "1",
        "Item Description": "BEER KEG YUENGLING LAGER 7.75GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$82.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109547",
        "Product Status": "Active"
    },
    {
        "Root": "10006068",
        "LV": "1",
        "Item Description": "BOX POPCORN PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.18",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006068",
        "Product Status": "Active"
    },
    {
        "Root": "10029166",
        "LV": "1",
        "Item Description": "TEA CHAI 6/25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029166001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F09185",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029166",
        "Product Status": "Active"
    },
    {
        "Root": "10037915",
        "LV": "1",
        "Item Description": "REDDI WHIP NON DAIRY COCONUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "7027249139",
        "Last Price Paid": "$5.10",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037915",
        "Product Status": "Active"
    },
    {
        "Root": "10000743",
        "LV": "1",
        "Item Description": "BISON GROUND BULK 6/2.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000743001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4506001",
        "Last Price Paid": "$7.57",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000743",
        "Product Status": "Active"
    },
    {
        "Root": "10005957",
        "LV": "3",
        "Item Description": "APPLE LATTICE 20 INCH LARGE UNCUT 3 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005957003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "900:AL",
        "Last Price Paid": "$5.27",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10005957",
        "Product Status": "Active"
    },
    {
        "Root": "10004039",
        "LV": "1",
        "Item Description": "CHOC SHAVING DARK CLASSIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004039001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "103961",
        "Last Price Paid": "$23.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004039",
        "Product Status": "Active"
    },
    {
        "Root": "10004762",
        "LV": "1",
        "Item Description": "CANDY MILK CHOC BAR 1.55Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "533161",
        "Last Price Paid": "$1.48",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004762",
        "Product Status": "Active"
    },
    {
        "Root": "10025436",
        "LV": "1",
        "Item Description": "NON-PAREIL MULTI COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9465",
        "Last Price Paid": "$24.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025436",
        "Product Status": "Active"
    },
    {
        "Root": "10016355",
        "LV": "1",
        "Item Description": "LAVASH STRIP SESAME 2\" X 4\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "3703D",
        "Last Price Paid": "$2.31",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016355",
        "Product Status": "Active"
    },
    {
        "Root": "10089285",
        "LV": "1",
        "Item Description": "POTATO TATER KEG W/ BACON & CHEDDAR CHIVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089285001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9760813",
        "Last Price Paid": "$35.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089285",
        "Product Status": "Active"
    },
    {
        "Root": "10005976",
        "LV": "2",
        "Item Description": "CUP COFFE MUG CLEAR 8OZ 24/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005976002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.27",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005976",
        "Product Status": "Active"
    },
    {
        "Root": "10095609",
        "LV": "1",
        "Item Description": "TEA CONCENTRATE REPUB OF TEA GREEN POM 64OZ/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095609",
        "Product Status": "Active"
    },
    {
        "Root": "10107874",
        "LV": "1",
        "Item Description": "OIL, PISTACHIO FRANCE 6/250 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107874001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7872534",
        "Last Price Paid": "$18.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107874",
        "Product Status": "Active"
    },
    {
        "Root": "10030271",
        "LV": "1",
        "Item Description": "GEL PASTE BRIGHT WHITE COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC BRIGHT WHIT",
        "Last Price Paid": "$33.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030271",
        "Product Status": "Active"
    },
    {
        "Root": "10004044",
        "LV": "1",
        "Item Description": "DEC PEARLS CRSPY STRW CHO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118620",
        "Last Price Paid": "$7.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004044",
        "Product Status": "Active"
    },
    {
        "Root": "10029191",
        "LV": "1",
        "Item Description": "HUMMUS WITH PITA CHIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "1707017",
        "Last Price Paid": "$5.50",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029191",
        "Product Status": "Active"
    },
    {
        "Root": "10012392",
        "LV": "1",
        "Item Description": "RELISH PICKLE CHICAGO GREEN PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3304805",
        "Last Price Paid": "$26.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012392",
        "Product Status": "Active"
    },
    {
        "Root": "10012858",
        "LV": "1",
        "Item Description": "GRAPPA SIBONA CHAMOMILE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012858001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "366782",
        "Last Price Paid": "$30.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012858",
        "Product Status": "Active"
    },
    {
        "Root": "10033298",
        "LV": "1",
        "Item Description": "HARD CIDER ANGRY ORCHARD ROSE 12 OZ BOTTLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "38879",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033298",
        "Product Status": "Active"
    },
    {
        "Root": "10038104",
        "LV": "1",
        "Item Description": "CIGAR 5 3/4 IN MARBLED DARK/MILK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW17621",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038104",
        "Product Status": "Active"
    },
    {
        "Root": "10108351",
        "LV": "1",
        "Item Description": "POPCORN SICLEPOP MDSE ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "SICLEPOP OR/GP",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108351",
        "Product Status": "Active"
    },
    {
        "Root": "10017239",
        "LV": "1",
        "Item Description": "CANDIED LEMON SLICE JELLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017239001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I182",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017239",
        "Product Status": "Active"
    },
    {
        "Root": "10037393",
        "LV": "1",
        "Item Description": "DRESSING VEGAN THOUSAND ISLAND 6 X 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "17012",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037393",
        "Product Status": "Active"
    },
    {
        "Root": "10084334",
        "LV": "1",
        "Item Description": "CANDY MY M&MS PURPLE 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "10476",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084334",
        "Product Status": "Active"
    },
    {
        "Root": "10040537",
        "LV": "1",
        "Item Description": "CANDY MALBAN COCONUT APRICOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "347320",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040537",
        "Product Status": "Active"
    },
    {
        "Root": "10037364",
        "LV": "1",
        "Item Description": "CONTAINER ROUND BLACK 12Z 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51001912",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037364",
        "Product Status": "Active"
    },
    {
        "Root": "10011702",
        "LV": "1",
        "Item Description": "SPRINKLES RED AND GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011702001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9474",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011702",
        "Product Status": "Active"
    },
    {
        "Root": "10000884",
        "LV": "1",
        "Item Description": "DUCK FAT RENDERED 7.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000884001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000884",
        "Product Status": "Active"
    },
    {
        "Root": "10102088",
        "LV": "1",
        "Item Description": "BEER KEG WELLS BOMBARDIER DRAFT 50L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10147",
        "Last Price Paid": "$143.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102088",
        "Product Status": "Active"
    },
    {
        "Root": "10004255",
        "LV": "1",
        "Item Description": "SB LB PETIT VAN BEAN SCON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11037568",
        "Last Price Paid": "$0.29",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004255",
        "Product Status": "Active"
    },
    {
        "Root": "10011524",
        "LV": "1",
        "Item Description": "HERB MINT PENNY ROYAL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011524001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MPR-33-E",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011524",
        "Product Status": "Active"
    },
    {
        "Root": "10009999",
        "LV": "1",
        "Item Description": "LIQ TRPL SEC 30* LTR ARROW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009999001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009999",
        "Product Status": "Active"
    },
    {
        "Root": "10004979",
        "LV": "2",
        "Item Description": "CANDY MINTS STARLIGHT RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004979002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "583255",
        "Last Price Paid": "$15.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004979",
        "Product Status": "Active"
    },
    {
        "Root": "10004979",
        "LV": "2",
        "Item Description": "CANDY MINTS STARLIGHT RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004979002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "44600",
        "Last Price Paid": "$15.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004979",
        "Product Status": "Active"
    },
    {
        "Root": "10088149",
        "LV": "1",
        "Item Description": "SAUCE WORCESTERSHIRE MONARCH NO ANCHOVY 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3636982",
        "Last Price Paid": "$13.28",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088149",
        "Product Status": "Active"
    },
    {
        "Root": "10021157",
        "LV": "1",
        "Item Description": "CHEESE PROVOLONE AGED ITALY 15 LB LOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021157001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1770",
        "Last Price Paid": "$6.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021157",
        "Product Status": "Active"
    },
    {
        "Root": "10032424",
        "LV": "1",
        "Item Description": "SHIRAZ TOKARA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "511205",
        "Last Price Paid": "$16.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032424",
        "Product Status": "Active"
    },
    {
        "Root": "10006641",
        "LV": "1",
        "Item Description": "TRAILER RENTAL 4X8 REFRIG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006641001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000006360",
        "Vendor": "REDDY ICE CORPORATION",
        "Vendor Item Nb": "100066",
        "Last Price Paid": "$85.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006641",
        "Product Status": "Active"
    },
    {
        "Root": "10005719",
        "LV": "1",
        "Item Description": "MUFFIN ENGLISH ORIGINAL 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8775629",
        "Last Price Paid": "$0.38",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005719",
        "Product Status": "Active"
    },
    {
        "Root": "10010960",
        "LV": "1",
        "Item Description": "COFFEE DECAF YACHTSMAN STEAKHOUSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.54",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010960",
        "Product Status": "Active"
    },
    {
        "Root": "10016358",
        "LV": "1",
        "Item Description": "LINER TRAY SAUSAGE GREASE REST. 12X5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659011",
        "Last Price Paid": "$0.02",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016358",
        "Product Status": "Active"
    },
    {
        "Root": "10002709",
        "LV": "1",
        "Item Description": "MIX CAKE DEVIL FOOD 50LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$93.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002709",
        "Product Status": "Active"
    },
    {
        "Root": "10001488",
        "LV": "1",
        "Item Description": "POTATO CHEF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408830",
        "Last Price Paid": "$32.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001488",
        "Product Status": "Active"
    },
    {
        "Root": "10004704",
        "LV": "1",
        "Item Description": "NUT ALMOND WHOLE NATURAL RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "110000",
        "Last Price Paid": "$102.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004704",
        "Product Status": "Active"
    },
    {
        "Root": "10009856",
        "LV": "1",
        "Item Description": "SHERRY DRY SACK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009856",
        "Product Status": "Active"
    },
    {
        "Root": "10003267",
        "LV": "1",
        "Item Description": "TEA PUMPKN SPICE CHAI 120 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003267",
        "Product Status": "Active"
    },
    {
        "Root": "10004976",
        "LV": "1",
        "Item Description": "GLUCOSE LARGE PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "71404",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004976",
        "Product Status": "Active"
    },
    {
        "Root": "10004976",
        "LV": "1",
        "Item Description": "GLUCOSE LARGE PAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004976001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PF6092",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004976",
        "Product Status": "Active"
    },
    {
        "Root": "10008652",
        "LV": "1",
        "Item Description": "MERLOT DECOY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "592689",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008652",
        "Product Status": "Active"
    },
    {
        "Root": "10031847",
        "LV": "1",
        "Item Description": "JUICE MM CRAN APPLE RASPBERRY 12 OZ/ 24 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031847001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "373777",
        "Last Price Paid": "$0.92",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031847",
        "Product Status": "Active"
    },
    {
        "Root": "10002433",
        "LV": "1",
        "Item Description": "GELATO VANILLA 3 GAL TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002433001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "IG-001.3",
        "Last Price Paid": "$31.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002433",
        "Product Status": "Active"
    },
    {
        "Root": "10004616",
        "LV": "1",
        "Item Description": "GRANOLA BAR HONEY/OATS .8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9017880",
        "Last Price Paid": "$0.27",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004616",
        "Product Status": "Active"
    },
    {
        "Root": "10036186",
        "LV": "1",
        "Item Description": "CONE ICE CREAM BLUE CAKE 6/112 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6817564",
        "Last Price Paid": "$0.15",
        "Case Pack": "672",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036186",
        "Product Status": "Active"
    },
    {
        "Root": "10002671",
        "LV": "1",
        "Item Description": "PINEAPPLE CRUSH/JUICE #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002671",
        "Product Status": "Active"
    },
    {
        "Root": "10016324",
        "LV": "1",
        "Item Description": "CUPCAKE MED RD VEL UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1634",
        "Last Price Paid": "$0.36",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016324",
        "Product Status": "Active"
    },
    {
        "Root": "10011983",
        "LV": "1",
        "Item Description": "MIX FALAFEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011983001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7579659",
        "Last Price Paid": "$18.46",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011983",
        "Product Status": "Active"
    },
    {
        "Root": "10025120",
        "LV": "1",
        "Item Description": "VODKA ABSOLUT ELYX LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "332433",
        "Last Price Paid": "$28.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025120",
        "Product Status": "Active"
    },
    {
        "Root": "10005940",
        "LV": "1",
        "Item Description": "RICE CRISPY TREAT PLAIN MICKEY WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.55",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005940",
        "Product Status": "Active"
    },
    {
        "Root": "10004805",
        "LV": "1",
        "Item Description": "SPICE CHAR CRUST ORIG HIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$178.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004805",
        "Product Status": "Active"
    },
    {
        "Root": "10017411",
        "LV": "1",
        "Item Description": "LEMON PRESERVED JANSAL VALLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "119930",
        "Last Price Paid": "$48.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017411",
        "Product Status": "Active"
    },
    {
        "Root": "10104601",
        "LV": "1",
        "Item Description": "PASSOVER BSC HONEY CAKE 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.34",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104601",
        "Product Status": "Active"
    },
    {
        "Root": "10037013",
        "LV": "2",
        "Item Description": "BLEND RED CVNE IMPERIAL 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037013002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9696657",
        "Last Price Paid": "$33.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037013",
        "Product Status": "Active"
    },
    {
        "Root": "10003017",
        "LV": "1",
        "Item Description": "PEANUT BUTTER CREAMY 40OZ 8CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003017001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.08",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003017",
        "Product Status": "Active"
    },
    {
        "Root": "10007997",
        "LV": "2",
        "Item Description": "SYRAH MONTES ALPHA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007997001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "206386",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007997",
        "Product Status": "Active"
    },
    {
        "Root": "10007766",
        "LV": "1",
        "Item Description": "CHARD NATURA 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "345692",
        "Last Price Paid": "$3.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007766",
        "Product Status": "Active"
    },
    {
        "Root": "10002842",
        "LV": "1",
        "Item Description": "MUSTARD GREY POUPON 6/48Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002842",
        "Product Status": "Active"
    },
    {
        "Root": "10002672",
        "LV": "1",
        "Item Description": "ORANGE MANDARIN WHLE SEGMT LGT SYRP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2910131",
        "Last Price Paid": "$9.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002672",
        "Product Status": "Active"
    },
    {
        "Root": "10009386",
        "LV": "2",
        "Item Description": "SAUV BLC OYSTER BAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009386001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "127630",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009386",
        "Product Status": "Active"
    },
    {
        "Root": "10006353",
        "LV": "1",
        "Item Description": "SPOON HVY WT SILVER REFLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7653447",
        "Last Price Paid": "$0.08",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006353",
        "Product Status": "Active"
    },
    {
        "Root": "10002838",
        "LV": "2",
        "Item Description": "VINEGAR MALT IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002838002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.02",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002838",
        "Product Status": "Active"
    },
    {
        "Root": "10002666",
        "LV": "2",
        "Item Description": "SPICE SESAME SEED BLACK 6/16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002666002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00787-4",
        "Last Price Paid": "$2.63",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002666",
        "Product Status": "Active"
    },
    {
        "Root": "10002666",
        "LV": "2",
        "Item Description": "SPICE SESAME SEED BLACK 6/16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002666002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.63",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002666",
        "Product Status": "Active"
    },
    {
        "Root": "10004719",
        "LV": "1",
        "Item Description": "PRETZEL TINY TWISTS BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7247216",
        "Last Price Paid": "$8.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004719",
        "Product Status": "Active"
    },
    {
        "Root": "10041508",
        "LV": "4",
        "Item Description": "STRAW PAPER WHITE WRAPPED 7.75 IN 4000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041508002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10041508",
        "Product Status": "Active"
    },
    {
        "Root": "10004055",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE TORNADO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "93208",
        "Last Price Paid": "$0.20",
        "Case Pack": "864",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004055",
        "Product Status": "Active"
    },
    {
        "Root": "10007005",
        "LV": "1",
        "Item Description": "CAB SAUV J LOHR 7 OAKS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007005",
        "Product Status": "Active"
    },
    {
        "Root": "10044387",
        "LV": "1",
        "Item Description": "COOKIE, EPCOT, ALMOND CRESCENT 90/1.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4975886",
        "Last Price Paid": "$0.87",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044387",
        "Product Status": "Active"
    },
    {
        "Root": "10001766",
        "LV": "1",
        "Item Description": "TOMATO GREEN 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23021",
        "Last Price Paid": "$18.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001766",
        "Product Status": "Active"
    },
    {
        "Root": "10001766",
        "LV": "1",
        "Item Description": "TOMATO GREEN 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001766001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489775",
        "Last Price Paid": "$18.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001766",
        "Product Status": "Active"
    },
    {
        "Root": "10006902",
        "LV": "2",
        "Item Description": "BLND JLC LOMBRE BETE RGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006902001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "590989",
        "Last Price Paid": "$37.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006902",
        "Product Status": "Active"
    },
    {
        "Root": "10034157",
        "LV": "1",
        "Item Description": "TOY STORY 11.5 X 5 BLUE CHECKERED LINER LIGHTWEIGT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034157001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51659030",
        "Last Price Paid": "$0.02",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034157",
        "Product Status": "Active"
    },
    {
        "Root": "10002180",
        "LV": "1",
        "Item Description": "MILK FAT FREE HALF GALLLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002180001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.21",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002180",
        "Product Status": "Active"
    },
    {
        "Root": "10002651",
        "LV": "2",
        "Item Description": "SPICE PARSLEY FLAKES  4/12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002651002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.68",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002651",
        "Product Status": "Active"
    },
    {
        "Root": "10002651",
        "LV": "2",
        "Item Description": "SPICE PARSLEY FLAKES  4/12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002651002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00603-7",
        "Last Price Paid": "$5.68",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002651",
        "Product Status": "Active"
    },
    {
        "Root": "10006904",
        "LV": "1",
        "Item Description": "CHIANTI BANFI CLA RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006904",
        "Product Status": "Active"
    },
    {
        "Root": "10001606",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT PLD 2X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515536",
        "Last Price Paid": "$8.69",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001606",
        "Product Status": "Active"
    },
    {
        "Root": "10001606",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT PLD 2X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "30420",
        "Last Price Paid": "$8.69",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001606",
        "Product Status": "Active"
    },
    {
        "Root": "10001606",
        "LV": "1",
        "Item Description": "SQUASH BUTTERNUT PLD 2X2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "40-005-2",
        "Last Price Paid": "$8.69",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001606",
        "Product Status": "Active"
    },
    {
        "Root": "10006908",
        "LV": "1",
        "Item Description": "CAB SAUV L MARTINI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1286640",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006908",
        "Product Status": "Active"
    },
    {
        "Root": "10090629",
        "LV": "1",
        "Item Description": "FLUTE CHAMPAGNE 5OZ 1PC CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090629001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11001767",
        "Last Price Paid": "$0.86",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090629",
        "Product Status": "Active"
    },
    {
        "Root": "10010501",
        "LV": "1",
        "Item Description": "VLKNBRG MDNA SPATLESE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "288256",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010501",
        "Product Status": "Active"
    },
    {
        "Root": "10015450",
        "LV": "1",
        "Item Description": "FLAVORING HOT JALAPENO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2374",
        "Last Price Paid": "$34.69",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015450",
        "Product Status": "Active"
    },
    {
        "Root": "10040380",
        "LV": "2",
        "Item Description": "SPICE TEXAS RUB 4-5LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040380002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040380",
        "Product Status": "Active"
    },
    {
        "Root": "10003066",
        "LV": "1",
        "Item Description": "DRINK MONSTER ENERGY 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.04",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003066",
        "Product Status": "Active"
    },
    {
        "Root": "10105431",
        "LV": "1",
        "Item Description": "BUCKET PREM BALLOON 100TH MM PURPLE 71OZ 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105431",
        "Product Status": "Active"
    },
    {
        "Root": "10010498",
        "LV": "1",
        "Item Description": "VLKNBRG MDNA LEBRFMLCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "388304",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010498",
        "Product Status": "Active"
    },
    {
        "Root": "10001286",
        "LV": "1",
        "Item Description": "PEACHES SLICED IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$79.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001286",
        "Product Status": "Active"
    },
    {
        "Root": "10037471",
        "LV": "2",
        "Item Description": "PUREE PAPAYA 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037471002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "APP0C3AA0",
        "Last Price Paid": "$14.51",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037471",
        "Product Status": "Active"
    },
    {
        "Root": "10004854",
        "LV": "1",
        "Item Description": "NUT ALMONDS SMOKED BULK 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004854001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "113100",
        "Last Price Paid": "$116.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004854",
        "Product Status": "Active"
    },
    {
        "Root": "10002683",
        "LV": "3",
        "Item Description": "TOMATO PLUM WHOLE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002683003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9329269",
        "Last Price Paid": "$4.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002683",
        "Product Status": "Active"
    },
    {
        "Root": "10008017",
        "LV": "1",
        "Item Description": "PINOT NOIR BELLE GLOS CLARK TEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008017001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555719",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008017",
        "Product Status": "Active"
    },
    {
        "Root": "10029266",
        "LV": "1",
        "Item Description": "VEGETABLES CARROTS PEELED BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029266001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PTMBC-26",
        "Last Price Paid": "$110.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029266",
        "Product Status": "Active"
    },
    {
        "Root": "10101510",
        "LV": "1",
        "Item Description": "DUCK TERRINE MOUSQUETAIRE 3 LB AVG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101510001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "PATTER012",
        "Last Price Paid": "$10.99",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101510",
        "Product Status": "Active"
    },
    {
        "Root": "10004049",
        "LV": "1",
        "Item Description": "SPRAY CLRNG VLVT YELLW CO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118759",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004049",
        "Product Status": "Active"
    },
    {
        "Root": "10001774",
        "LV": "1",
        "Item Description": "LETTUCE MICROGREENS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001774",
        "Product Status": "Active"
    },
    {
        "Root": "10001774",
        "LV": "1",
        "Item Description": "LETTUCE MICROGREENS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404069",
        "Last Price Paid": "$16.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001774",
        "Product Status": "Active"
    },
    {
        "Root": "10001774",
        "LV": "1",
        "Item Description": "LETTUCE MICROGREENS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001774001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22114",
        "Last Price Paid": "$16.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001774",
        "Product Status": "Active"
    },
    {
        "Root": "10022365",
        "LV": "1",
        "Item Description": "SYRAH PORSELEINBERG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9197159",
        "Last Price Paid": "$66.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022365",
        "Product Status": "Active"
    },
    {
        "Root": "10009736",
        "LV": "2",
        "Item Description": "TEQ AMBHAR ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009736001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "TA-TEQANE-750",
        "Last Price Paid": "$30.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009736",
        "Product Status": "Active"
    },
    {
        "Root": "10107810",
        "LV": "1",
        "Item Description": "BEER 3 DAUGHTERS TOASTED COCONUT PORTER 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76617",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107810",
        "Product Status": "Active"
    },
    {
        "Root": "10003531",
        "LV": "1",
        "Item Description": "COFFEE ANNIVERSARY REG 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.77",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003531",
        "Product Status": "Active"
    },
    {
        "Root": "10008787",
        "LV": "2",
        "Item Description": "CHAMP ROSE KRUG MV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008787002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "591109",
        "Last Price Paid": "$410.70",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10008787",
        "Product Status": "Active"
    },
    {
        "Root": "10033346",
        "LV": "1",
        "Item Description": "FUSION MIX-CHRISTMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22946",
        "Last Price Paid": "$6.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033346",
        "Product Status": "Active"
    },
    {
        "Root": "10026679",
        "LV": "1",
        "Item Description": "SB COFFEE WB ESPRESSO BLONDE 1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11072544",
        "Last Price Paid": "$9.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026679",
        "Product Status": "Active"
    },
    {
        "Root": "10006861",
        "LV": "1",
        "Item Description": "CHAMP NIC FEUILLATTE BRT 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006861",
        "Product Status": "Active"
    },
    {
        "Root": "10012892",
        "LV": "1",
        "Item Description": "MUFFIN CHOCOLATE CHIP MINI 2.5 OUNCE 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012892001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012892",
        "Product Status": "Active"
    },
    {
        "Root": "10040830",
        "LV": "1",
        "Item Description": "PICKS FOOD TURQUOISE 6IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040830001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510550",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040830",
        "Product Status": "Active"
    },
    {
        "Root": "10006132",
        "LV": "1",
        "Item Description": "TAG RFID 12Z WDW-P092",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006132001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006132",
        "Product Status": "Active"
    },
    {
        "Root": "10001801",
        "LV": "1",
        "Item Description": "BEAN LIMA DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401655",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001801",
        "Product Status": "Active"
    },
    {
        "Root": "10031353",
        "LV": "1",
        "Item Description": "COD LOIN ATL 5OZ MSC FZ 1/10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6360513",
        "Last Price Paid": "",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031353",
        "Product Status": "Active"
    },
    {
        "Root": "10033785",
        "LV": "1",
        "Item Description": "SAKE TAKATENJIN SWORD OF SUN 720ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033785",
        "Product Status": "Active"
    },
    {
        "Root": "10032258",
        "LV": "1",
        "Item Description": "CILANTRO CHOPPED FROZEN 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032258001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "522458",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032258",
        "Product Status": "Active"
    },
    {
        "Root": "10019383",
        "LV": "1",
        "Item Description": "TEA LOOSE ROSE GARDEN BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13701",
        "Last Price Paid": "$31.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019383",
        "Product Status": "Active"
    },
    {
        "Root": "10027269",
        "LV": "2",
        "Item Description": "CHIPS PLANTAIN SALTED 6/3.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027269002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891313",
        "Last Price Paid": "$2.52",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027269",
        "Product Status": "Active"
    },
    {
        "Root": "10093539",
        "LV": "1",
        "Item Description": "CHIPS TROPICON VEGGIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "CHVG-02-18",
        "Last Price Paid": "$22.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093539",
        "Product Status": "Active"
    },
    {
        "Root": "10041013",
        "LV": "1",
        "Item Description": "CUP FOOD 8OZ PAPER MARY BLAIR DFR8 WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041013001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "108387036",
        "Last Price Paid": "$0.06",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041013",
        "Product Status": "Active"
    },
    {
        "Root": "10001819",
        "LV": "1",
        "Item Description": "PARSNIPS W/O TOPS 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001819",
        "Product Status": "Active"
    },
    {
        "Root": "10001819",
        "LV": "1",
        "Item Description": "PARSNIPS W/O TOPS 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401250",
        "Last Price Paid": "$23.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001819",
        "Product Status": "Active"
    },
    {
        "Root": "10008029",
        "LV": "2",
        "Item Description": "BUKKATRAUBE CEDERBERG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008029001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "SA021X10",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008029",
        "Product Status": "Active"
    },
    {
        "Root": "10008029",
        "LV": "2",
        "Item Description": "BUKKATRAUBE CEDERBERG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008029001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425988",
        "Vendor": "HARVEST MOON DISTRIBUTORS LLC",
        "Vendor Item Nb": "SA021X10",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008029",
        "Product Status": "Active"
    },
    {
        "Root": "10034780",
        "LV": "1",
        "Item Description": "KING CRAB WHOLE ALASKA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034780001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "78-47931",
        "Last Price Paid": "$30.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034780",
        "Product Status": "Active"
    },
    {
        "Root": "10089503",
        "LV": "1",
        "Item Description": "CHEESE CREAM CHEESE 100/1 OZ PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "11197",
        "Last Price Paid": "$0.35",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089503",
        "Product Status": "Active"
    },
    {
        "Root": "10019430",
        "LV": "1",
        "Item Description": "TIO PEPE SHERRY FINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "74001",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019430",
        "Product Status": "Active"
    },
    {
        "Root": "10008011",
        "LV": "1",
        "Item Description": "CAB BLND COPPOLA CLARET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "491708",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008011",
        "Product Status": "Active"
    },
    {
        "Root": "10023324",
        "LV": "3",
        "Item Description": "NUT ALMOND RAW 12/3.25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023324002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "200290",
        "Last Price Paid": "$1.27",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10023324",
        "Product Status": "Active"
    },
    {
        "Root": "10010413",
        "LV": "1",
        "Item Description": "BEER UNIBREW MAUDITE 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010413001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "866167",
        "Last Price Paid": "$2.04",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010413",
        "Product Status": "Active"
    },
    {
        "Root": "10003091",
        "LV": "1",
        "Item Description": "TEA GREEN W MINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003091",
        "Product Status": "Active"
    },
    {
        "Root": "10016572",
        "LV": "1",
        "Item Description": "BOX LUNCH WF CLASS 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016572001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "123",
        "Last Price Paid": "$16.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016572",
        "Product Status": "Active"
    },
    {
        "Root": "10088806",
        "LV": "1",
        "Item Description": "BOX CAKE FULL SHEET TOP WITH WINDOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088806001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51009071",
        "Last Price Paid": "$1.46",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088806",
        "Product Status": "Active"
    },
    {
        "Root": "10008268",
        "LV": "1",
        "Item Description": "CHATEAU GREYSAC RED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558298",
        "Last Price Paid": "$16.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008268",
        "Product Status": "Active"
    },
    {
        "Root": "10003553",
        "LV": "1",
        "Item Description": "SPICE MUSTARD GRD 6/1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003553",
        "Product Status": "Active"
    },
    {
        "Root": "10095514",
        "LV": "1",
        "Item Description": "KEG BEER 3 DAUGHTERS WATERMELON WHEAT ALE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76197",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095514",
        "Product Status": "Active"
    },
    {
        "Root": "10010079",
        "LV": "1",
        "Item Description": "BEER KONA LNGBRD LAG BTL 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010079001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010079",
        "Product Status": "Active"
    },
    {
        "Root": "10033967",
        "LV": "1",
        "Item Description": "COCOA BUTTER CHOCOLATE BROWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "610116",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033967",
        "Product Status": "Active"
    },
    {
        "Root": "10033967",
        "LV": "1",
        "Item Description": "COCOA BUTTER CHOCOLATE BROWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302140C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033967",
        "Product Status": "Active"
    },
    {
        "Root": "10001153",
        "LV": "1",
        "Item Description": "CAVIAR AMERICAN STUR 7 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001153",
        "Product Status": "Active"
    },
    {
        "Root": "10001153",
        "LV": "1",
        "Item Description": "CAVIAR AMERICAN STUR 7 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-90495",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001153",
        "Product Status": "Active"
    },
    {
        "Root": "10001153",
        "LV": "1",
        "Item Description": "CAVIAR AMERICAN STUR 7 OZ SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001153001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60715000",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001153",
        "Product Status": "Active"
    },
    {
        "Root": "10028952",
        "LV": "1",
        "Item Description": "ALE BARREL LEXINGTON BOURBON 1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "53",
        "Last Price Paid": "$203.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028952",
        "Product Status": "Active"
    },
    {
        "Root": "10012035",
        "LV": "1",
        "Item Description": "SPRUCE TIPS 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08STIP-33-S",
        "Last Price Paid": "$24.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012035",
        "Product Status": "Active"
    },
    {
        "Root": "10006998",
        "LV": "1",
        "Item Description": "PINOT NOIR CASTLE ROCK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "6812",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006998",
        "Product Status": "Active"
    },
    {
        "Root": "10001354",
        "LV": "1",
        "Item Description": "MARINADE BERBERE 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001354",
        "Product Status": "Active"
    },
    {
        "Root": "10007405",
        "LV": "1",
        "Item Description": "AMARONE GIA DELLA VALPO 09",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007405001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "540620",
        "Last Price Paid": "$28.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007405",
        "Product Status": "Active"
    },
    {
        "Root": "10004339",
        "LV": "1",
        "Item Description": "SAUCE BALSAMIC GLAZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2427698",
        "Last Price Paid": "$7.90",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004339",
        "Product Status": "Active"
    },
    {
        "Root": "10107506",
        "LV": "1",
        "Item Description": "SPARKLING PEACH WINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107506001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069784",
        "Vendor": "FLORIDA ORANGE GROVES INC",
        "Vendor Item Nb": "SPARKLINGPEACH",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107506",
        "Product Status": "Active"
    },
    {
        "Root": "10010258",
        "LV": "2",
        "Item Description": "DRAFT 3D BLND 1/6BL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010258001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "80906",
        "Last Price Paid": "$87.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010258",
        "Product Status": "Active"
    },
    {
        "Root": "10007000",
        "LV": "1",
        "Item Description": "BLND BV TAPESTRY RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007000001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9660601",
        "Last Price Paid": "$37.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007000",
        "Product Status": "Active"
    },
    {
        "Root": "10006897",
        "LV": "1",
        "Item Description": "ZINF WHT BERINGER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006897",
        "Product Status": "Active"
    },
    {
        "Root": "10038813",
        "LV": "1",
        "Item Description": "MUFFIN CHOCOLATE CHIP 5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038813",
        "Product Status": "Active"
    },
    {
        "Root": "10008190",
        "LV": "1",
        "Item Description": "CHARD POUILLY FUIS JADOT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "129775",
        "Last Price Paid": "$22.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008190",
        "Product Status": "Active"
    },
    {
        "Root": "10101400",
        "LV": "1",
        "Item Description": "KALE BLACK 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21100",
        "Last Price Paid": "$2.93",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101400",
        "Product Status": "Active"
    },
    {
        "Root": "10003970",
        "LV": "1",
        "Item Description": "OLIVE PICHOLINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003970001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-15115",
        "Last Price Paid": "$7.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003970",
        "Product Status": "Active"
    },
    {
        "Root": "10010137",
        "LV": "2",
        "Item Description": "WATER TAU SPARKLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010137001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "TAU4",
        "Last Price Paid": "$5.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010137",
        "Product Status": "Active"
    },
    {
        "Root": "10003087",
        "LV": "2",
        "Item Description": "TEA ICE BLK 4.95Z 36CT BA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003087001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "ITLL9900-2",
        "Last Price Paid": "$142.30",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003087",
        "Product Status": "Active"
    },
    {
        "Root": "10004485",
        "LV": "1",
        "Item Description": "SUSHI SASHIMI SALMON .5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004485",
        "Product Status": "Active"
    },
    {
        "Root": "10006946",
        "LV": "1",
        "Item Description": "CAB SAUV COPPERIDGE 1.5LTR 6EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006946001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1224229",
        "Last Price Paid": "$6.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006946",
        "Product Status": "Active"
    },
    {
        "Root": "10021449",
        "LV": "1",
        "Item Description": "POWDER BUTTERMILK 4/24 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY005",
        "Last Price Paid": "$27.55",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021449",
        "Product Status": "Active"
    },
    {
        "Root": "10007951",
        "LV": "1",
        "Item Description": "CHARD KISTLER NOISETIER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "988091",
        "Last Price Paid": "$42.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007951",
        "Product Status": "Active"
    },
    {
        "Root": "10102750",
        "LV": "1",
        "Item Description": "SELTZER HARD BOULEVARD QUIRK CRANBERRY APPLE CINN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102750001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15334",
        "Last Price Paid": "$194.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102750",
        "Product Status": "Active"
    },
    {
        "Root": "10005256",
        "LV": "1",
        "Item Description": "SPICE GRAINS OF PARADISE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "108360",
        "Last Price Paid": "$95.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005256",
        "Product Status": "Active"
    },
    {
        "Root": "10004665",
        "LV": "1",
        "Item Description": "NUT WALNUT HALVES/PIECES 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "183100",
        "Last Price Paid": "$115.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004665",
        "Product Status": "Active"
    },
    {
        "Root": "10004665",
        "LV": "1",
        "Item Description": "NUT WALNUT HALVES/PIECES 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "55384",
        "Last Price Paid": "$115.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004665",
        "Product Status": "Active"
    },
    {
        "Root": "10021940",
        "LV": "1",
        "Item Description": "VINEGAR BALSAMIC CONDIMENTO MODENA TRENTASETTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN054",
        "Last Price Paid": "$24.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021940",
        "Product Status": "Active"
    },
    {
        "Root": "10002728",
        "LV": "1",
        "Item Description": "MIX MUFFIN CREME CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9463399",
        "Last Price Paid": "$58.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002728",
        "Product Status": "Active"
    },
    {
        "Root": "10002728",
        "LV": "1",
        "Item Description": "MIX MUFFIN CREME CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$58.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002728",
        "Product Status": "Active"
    },
    {
        "Root": "10012722",
        "LV": "1",
        "Item Description": "HYSSOP ANISE BLOOM 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05AHB-33",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012722",
        "Product Status": "Active"
    },
    {
        "Root": "10002695",
        "LV": "1",
        "Item Description": "PASTA DRY LINGUINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002695001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808039150",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002695",
        "Product Status": "Active"
    },
    {
        "Root": "10021768",
        "LV": "1",
        "Item Description": "CONT CLAMSHELL 4 5/8X4 5/8X2 9/16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021768001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51357820",
        "Last Price Paid": "$0.16",
        "Case Pack": "330",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021768",
        "Product Status": "Active"
    },
    {
        "Root": "10041465",
        "LV": "1",
        "Item Description": "SUGAR BROWN LIGHT 12/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041465001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041465",
        "Product Status": "Active"
    },
    {
        "Root": "10009795",
        "LV": "1",
        "Item Description": "RUM BACARDI SILVER 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.94",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009795",
        "Product Status": "Active"
    },
    {
        "Root": "10009795",
        "LV": "1",
        "Item Description": "RUM BACARDI SILVER 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28730",
        "Last Price Paid": "$6.94",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009795",
        "Product Status": "Active"
    },
    {
        "Root": "10037079",
        "LV": "1",
        "Item Description": "ALBARINO LEIRANA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037079001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9389734",
        "Last Price Paid": "$18.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037079",
        "Product Status": "Active"
    },
    {
        "Root": "10004591",
        "LV": "1",
        "Item Description": "CEREAL GRANOLA BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004591001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4162475",
        "Last Price Paid": "$15.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004591",
        "Product Status": "Active"
    },
    {
        "Root": "10002109",
        "LV": "1",
        "Item Description": "BANANA WITH COLOR #3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002109001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002109",
        "Product Status": "Active"
    },
    {
        "Root": "10036052",
        "LV": "1",
        "Item Description": "JUICE SIMPLY FRUIT PUNCH 6/52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4645551",
        "Last Price Paid": "$2.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036052",
        "Product Status": "Active"
    },
    {
        "Root": "10003679",
        "LV": "1",
        "Item Description": "CANDY SNICKER BAR KS 3.7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534551",
        "Last Price Paid": "$2.59",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003679",
        "Product Status": "Active"
    },
    {
        "Root": "10006028",
        "LV": "1",
        "Item Description": "STRAW PLAS NATURAL 7.5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006028",
        "Product Status": "Active"
    },
    {
        "Root": "10102916",
        "LV": "1",
        "Item Description": "KOSHER BSC CHICKEN  TENDER R.POT 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102916",
        "Product Status": "Active"
    },
    {
        "Root": "10002568",
        "LV": "1",
        "Item Description": "FRUIT CUP SEASONAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489757",
        "Last Price Paid": "$1.86",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002568",
        "Product Status": "Active"
    },
    {
        "Root": "10016084",
        "LV": "1",
        "Item Description": "VEAL BONES ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016084001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.91",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016084",
        "Product Status": "Active"
    },
    {
        "Root": "10028484",
        "LV": "1",
        "Item Description": "DRAFT GOLDEN ROAD HEFEWEIZEN 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028484001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76302",
        "Last Price Paid": "$224.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028484",
        "Product Status": "Active"
    },
    {
        "Root": "10004482",
        "LV": "1",
        "Item Description": "ROLL SUSHI VEGETABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004482",
        "Product Status": "Active"
    },
    {
        "Root": "10027879",
        "LV": "1",
        "Item Description": "BAKE IN CUP BB8 CKBB8 1.7\" 1.4\" WALL OVEN SAFE MIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027879001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391051",
        "Last Price Paid": "$0.21",
        "Case Pack": "1500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027879",
        "Product Status": "Active"
    },
    {
        "Root": "10000277",
        "LV": "1",
        "Item Description": "POTATO RED 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490122",
        "Last Price Paid": "$16.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000277",
        "Product Status": "Active"
    },
    {
        "Root": "10106574",
        "LV": "1",
        "Item Description": "COLOR GLITTER EDIBLE SILVER METALIC 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "403013B",
        "Last Price Paid": "$8.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106574",
        "Product Status": "Active"
    },
    {
        "Root": "10005141",
        "LV": "1",
        "Item Description": "SALT SMKD ALDERWOOD 16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005141001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-45210",
        "Last Price Paid": "$9.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005141",
        "Product Status": "Active"
    },
    {
        "Root": "10016460",
        "LV": "1",
        "Item Description": "TAT SOI PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09TTP-33",
        "Last Price Paid": "$24.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016460",
        "Product Status": "Active"
    },
    {
        "Root": "10109329",
        "LV": "1",
        "Item Description": "VEGAN BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073751",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109329",
        "Product Status": "Active"
    },
    {
        "Root": "10024768",
        "LV": "1",
        "Item Description": "BEEF STRIP STEAK 8Z 1-VEIN CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024768001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190207",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024768",
        "Product Status": "Active"
    },
    {
        "Root": "10034889",
        "LV": "2",
        "Item Description": "GINGERDEER CHRISTMAS DECOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034889002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034889",
        "Product Status": "Active"
    },
    {
        "Root": "10087554",
        "LV": "1",
        "Item Description": "DRAFT ESTRELLA RESERVA 50L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087554001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13914",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087554",
        "Product Status": "Active"
    },
    {
        "Root": "10024962",
        "LV": "1",
        "Item Description": "BRUSSEL SPROUT BABY RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480667",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024962",
        "Product Status": "Active"
    },
    {
        "Root": "10009195",
        "LV": "1",
        "Item Description": "SPARK DOM CARNERO REVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009195001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "78619",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009195",
        "Product Status": "Active"
    },
    {
        "Root": "10092047",
        "LV": "1",
        "Item Description": "BEER FIRST MAGNITUDE TIGER TAIL PALE ALE 16OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76063",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092047",
        "Product Status": "Active"
    },
    {
        "Root": "10095092",
        "LV": "1",
        "Item Description": "YELLOWTAIL LOIN&BELLY SKNLS/BNLS 12-24OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095092001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "64-86710",
        "Last Price Paid": "",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095092",
        "Product Status": "Active"
    },
    {
        "Root": "10040835",
        "LV": "1",
        "Item Description": "CAB SAUV AFRICAN LION 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040835001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040835",
        "Product Status": "Active"
    },
    {
        "Root": "10035961",
        "LV": "1",
        "Item Description": "SPRAY FREE-N-EASY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2250",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035961",
        "Product Status": "Active"
    },
    {
        "Root": "10006007",
        "LV": "1",
        "Item Description": "LID 32Z PLAS CLR ROSEWARE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1886084",
        "Last Price Paid": "",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006007",
        "Product Status": "Active"
    },
    {
        "Root": "10089065",
        "LV": "1",
        "Item Description": "LIQ RAMAZZOTTI ROSATO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "948671",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089065",
        "Product Status": "Active"
    },
    {
        "Root": "10029094",
        "LV": "1",
        "Item Description": "EDAMAME PKG ESPN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029094001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029094",
        "Product Status": "Active"
    },
    {
        "Root": "10000827",
        "LV": "4",
        "Item Description": "CHICK CUT MARN 8 PC 2.25-2.5 #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000827002004",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10000827",
        "Product Status": "Active"
    },
    {
        "Root": "10008295",
        "LV": "1",
        "Item Description": "SANCERRE REDDE MICHEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008295001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "78764",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008295",
        "Product Status": "Active"
    },
    {
        "Root": "10008177",
        "LV": "1",
        "Item Description": "SAUV BLANC SPOTTSWOODE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9647970",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008177",
        "Product Status": "Active"
    },
    {
        "Root": "10005162",
        "LV": "1",
        "Item Description": "IRIGOMA (BLACK)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005162001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "11021",
        "Last Price Paid": "$12.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005162",
        "Product Status": "Active"
    },
    {
        "Root": "10086598",
        "LV": "2",
        "Item Description": "PAPER PACKAGING CONE BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086598002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "PCHBLU",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10086598",
        "Product Status": "Active"
    },
    {
        "Root": "10005434",
        "LV": "2",
        "Item Description": "CANDY LIFESAVERS 5 FLAVOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005434002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530789",
        "Last Price Paid": "$15.65",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005434",
        "Product Status": "Active"
    },
    {
        "Root": "10014052",
        "LV": "2",
        "Item Description": "COCOA BUTTER CARNIVAL GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014052002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302045C",
        "Last Price Paid": "$19.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014052",
        "Product Status": "Active"
    },
    {
        "Root": "10014052",
        "LV": "2",
        "Item Description": "COCOA BUTTER CARNIVAL GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014052002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5863120000",
        "Last Price Paid": "$19.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014052",
        "Product Status": "Active"
    },
    {
        "Root": "10001310",
        "LV": "1",
        "Item Description": "SPINACH CHOP GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8630287",
        "Last Price Paid": "$4.78",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001310",
        "Product Status": "Active"
    },
    {
        "Root": "10002726",
        "LV": "1",
        "Item Description": "RICE LONG GRAIN WHITE 4% 50LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002726",
        "Product Status": "Active"
    },
    {
        "Root": "10109539",
        "LV": "1",
        "Item Description": "COFFEE JOFFREYS CONVENTION BLD REG GRD 7OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.16",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109539",
        "Product Status": "Active"
    },
    {
        "Root": "10016841",
        "LV": "1",
        "Item Description": "GEL PK NO SWEAT 8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45927001",
        "Last Price Paid": "$0.42",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016841",
        "Product Status": "Active"
    },
    {
        "Root": "10031303",
        "LV": "1",
        "Item Description": "PEARLS PINEAPPLE BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PIPOPP",
        "Last Price Paid": "$34.29",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031303",
        "Product Status": "Active"
    },
    {
        "Root": "10015240",
        "LV": "1",
        "Item Description": "MIX BLONDIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "A62036",
        "Last Price Paid": "$70.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015240",
        "Product Status": "Active"
    },
    {
        "Root": "10032417",
        "LV": "1",
        "Item Description": "SEASONING MONTREAL STEAK BULK 7LB TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032417001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4146478",
        "Last Price Paid": "$44.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032417",
        "Product Status": "Active"
    },
    {
        "Root": "10000077",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 6Z GRAIN FED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000077001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195101",
        "Last Price Paid": "$34.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000077",
        "Product Status": "Active"
    },
    {
        "Root": "10004669",
        "LV": "1",
        "Item Description": "RAISIN GOLDEN 30#/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1054949",
        "Last Price Paid": "$70.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004669",
        "Product Status": "Active"
    },
    {
        "Root": "10009084",
        "LV": "2",
        "Item Description": "INGLENOOK CABERNET SAUVIGNON 18",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009084001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "222326",
        "Last Price Paid": "$54.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009084",
        "Product Status": "Active"
    },
    {
        "Root": "10027611",
        "LV": "1",
        "Item Description": "DRESSING KENS RANCH 4/1 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "KE0677",
        "Last Price Paid": "$9.63",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027611",
        "Product Status": "Active"
    },
    {
        "Root": "10002715",
        "LV": "1",
        "Item Description": "CEREAL FROSTED FLAKES BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.59",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002715",
        "Product Status": "Active"
    },
    {
        "Root": "10005004",
        "LV": "1",
        "Item Description": "NUT PEANUT XL BLCND R/S 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005004001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "130210",
        "Last Price Paid": "$73.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005004",
        "Product Status": "Active"
    },
    {
        "Root": "10006994",
        "LV": "1",
        "Item Description": "VOUVRAY J SAUVION 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006994001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "559324",
        "Last Price Paid": "$11.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006994",
        "Product Status": "Active"
    },
    {
        "Root": "10005146",
        "LV": "2",
        "Item Description": "PUREE CRANBERRY MORELLO 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005146002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ACR1C3AA0",
        "Last Price Paid": "$15.57",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005146",
        "Product Status": "Active"
    },
    {
        "Root": "10027496",
        "LV": "1",
        "Item Description": "ARUGULA BLOOMS 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05AB-33",
        "Last Price Paid": "$0.64",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027496",
        "Product Status": "Active"
    },
    {
        "Root": "10002024",
        "LV": "1",
        "Item Description": "CARROT DICED 3/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002024001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481340",
        "Last Price Paid": "$15.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002024",
        "Product Status": "Active"
    },
    {
        "Root": "10007458",
        "LV": "1",
        "Item Description": "ROSE CH MIRAVAL ROSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9163018",
        "Last Price Paid": "$17.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007458",
        "Product Status": "Active"
    },
    {
        "Root": "10107872",
        "LV": "1",
        "Item Description": "SUN BUTTER DIPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073454",
        "Last Price Paid": "$4.89",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107872",
        "Product Status": "Active"
    },
    {
        "Root": "10005764",
        "LV": "1",
        "Item Description": "BAGEL GARLIC MINI 2.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005764",
        "Product Status": "Active"
    },
    {
        "Root": "10094691",
        "LV": "2",
        "Item Description": "SB_FREEZE DRIED PINEAPPLE INCLUSIONS 75G 12-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094691002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11133688",
        "Last Price Paid": "$13.86",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094691",
        "Product Status": "Active"
    },
    {
        "Root": "10018712",
        "LV": "1",
        "Item Description": "SHEET TRANSFER AMORETTES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6170460000",
        "Last Price Paid": "$1.16",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018712",
        "Product Status": "Active"
    },
    {
        "Root": "10000973",
        "LV": "1",
        "Item Description": "CAPELIN LIVING SEAS FORAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000973001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6850001",
        "Last Price Paid": "$1.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000973",
        "Product Status": "Active"
    },
    {
        "Root": "10000973",
        "LV": "1",
        "Item Description": "CAPELIN LIVING SEAS FORAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000973001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50008",
        "Last Price Paid": "$1.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000973",
        "Product Status": "Active"
    },
    {
        "Root": "10091473",
        "LV": "1",
        "Item Description": "CUP CAN \"PAND-ADE\" CN0103000",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091473001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "111625",
        "Last Price Paid": "$0.96",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091473",
        "Product Status": "Active"
    },
    {
        "Root": "10001016",
        "LV": "1",
        "Item Description": "SNAPPER YLLWTAIL WHOLE 1-2 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001016001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "949",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001016",
        "Product Status": "Active"
    },
    {
        "Root": "10001016",
        "LV": "1",
        "Item Description": "SNAPPER YLLWTAIL WHOLE 1-2 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001016001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-02051",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001016",
        "Product Status": "Active"
    },
    {
        "Root": "10001016",
        "LV": "1",
        "Item Description": "SNAPPER YLLWTAIL WHOLE 1-2 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001016001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6926588",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001016",
        "Product Status": "Active"
    },
    {
        "Root": "10001016",
        "LV": "1",
        "Item Description": "SNAPPER YLLWTAIL WHOLE 1-2 LB SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001016001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "SNAP91",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001016",
        "Product Status": "Active"
    },
    {
        "Root": "10014620",
        "LV": "1",
        "Item Description": "GREENS DANDILION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21020",
        "Last Price Paid": "$35.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014620",
        "Product Status": "Active"
    },
    {
        "Root": "10014620",
        "LV": "1",
        "Item Description": "GREENS DANDILION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "487802",
        "Last Price Paid": "$35.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014620",
        "Product Status": "Active"
    },
    {
        "Root": "10084931",
        "LV": "1",
        "Item Description": "STRAW JUMBO 5.75I IN BLACK WRAPPED STD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2650155",
        "Last Price Paid": "$0.04",
        "Case Pack": "3200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084931",
        "Product Status": "Active"
    },
    {
        "Root": "10004443",
        "LV": "1",
        "Item Description": "MUSTARD YELLOW 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4364063",
        "Last Price Paid": "$6.66",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004443",
        "Product Status": "Active"
    },
    {
        "Root": "10033791",
        "LV": "2",
        "Item Description": "WINE WHITE SPARKLING FREIXENET BLANC DE BLANC 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033791002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "142134",
        "Last Price Paid": "$5.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033791",
        "Product Status": "Active"
    },
    {
        "Root": "10033791",
        "LV": "2",
        "Item Description": "WINE WHITE SPARKLING FREIXENET BLANC DE BLANC 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033791002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033791",
        "Product Status": "Active"
    },
    {
        "Root": "10045317",
        "LV": "1",
        "Item Description": "GLOVE NITRILE 2XL CHEMO-RATED POWDER-FREE FINGERTI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045317",
        "Product Status": "Active"
    },
    {
        "Root": "10015940",
        "LV": "3",
        "Item Description": "SHRIMP BRD 26/30 BF T/OFF SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015940003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125584",
        "Last Price Paid": "$4.04",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10015940",
        "Product Status": "Active"
    },
    {
        "Root": "10001298",
        "LV": "1",
        "Item Description": "JUICE MM LEMONADE C/S MINUTE PAK 4/90 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006532",
        "Last Price Paid": "$15.28",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001298",
        "Product Status": "Active"
    },
    {
        "Root": "10003387",
        "LV": "1",
        "Item Description": "SAUCE SOY SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003387001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7563059",
        "Last Price Paid": "$4.46",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003387",
        "Product Status": "Active"
    },
    {
        "Root": "10023801",
        "LV": "1",
        "Item Description": "STRAWBERRY ORGANIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "703380",
        "Last Price Paid": "$4.19",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023801",
        "Product Status": "Active"
    },
    {
        "Root": "10000144",
        "LV": "1",
        "Item Description": "SALMON CENTER CUT PORTIONED 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000144001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6501978",
        "Last Price Paid": "$12.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000144",
        "Product Status": "Active"
    },
    {
        "Root": "10087207",
        "LV": "1",
        "Item Description": "BEAN GREEN WHOLE 2-4 SIEV IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5327556",
        "Last Price Paid": "$2.57",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087207",
        "Product Status": "Active"
    },
    {
        "Root": "10003006",
        "LV": "1",
        "Item Description": "DRESSING VINAIGRETTE RASPBERRY WALNUT 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1285287",
        "Last Price Paid": "$17.17",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003006",
        "Product Status": "Active"
    },
    {
        "Root": "10000636",
        "LV": "1",
        "Item Description": "BEEF TOP ROUND COOKED CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000636001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7003910",
        "Last Price Paid": "$7.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000636",
        "Product Status": "Active"
    },
    {
        "Root": "10007894",
        "LV": "1",
        "Item Description": "SHIRAZ EXCELSIOR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "475933",
        "Last Price Paid": "$6.27",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007894",
        "Product Status": "Active"
    },
    {
        "Root": "10040623",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 6Z AUS STEER HALAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040623001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.65",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040623",
        "Product Status": "Active"
    },
    {
        "Root": "10011538",
        "LV": "1",
        "Item Description": "TURNIP MIX PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PMT-1",
        "Last Price Paid": "$34.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011538",
        "Product Status": "Active"
    },
    {
        "Root": "10000889",
        "LV": "1",
        "Item Description": "CHICK BREAST AIRLINE DOUBLE LOBE FRZN 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4228308",
        "Last Price Paid": "$2.56",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000889",
        "Product Status": "Active"
    },
    {
        "Root": "10030027",
        "LV": "1",
        "Item Description": "SYRUP MONIN SOUTH SEAS 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5219",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030027",
        "Product Status": "Active"
    },
    {
        "Root": "10003656",
        "LV": "1",
        "Item Description": "NITROGEN 100 % 230CU TANK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003656001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.29",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003656",
        "Product Status": "Active"
    },
    {
        "Root": "10012224",
        "LV": "1",
        "Item Description": "VODKA ST. AUGUSTINE CANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012224",
        "Product Status": "Active"
    },
    {
        "Root": "10088208",
        "LV": "1",
        "Item Description": "SHRIMP KEY WEST PINK U/12 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088208001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "72-15290",
        "Last Price Paid": "$15.95",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088208",
        "Product Status": "Active"
    },
    {
        "Root": "10001012",
        "LV": "1",
        "Item Description": "SNAPPER PTN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001012001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001012",
        "Product Status": "Active"
    },
    {
        "Root": "10001012",
        "LV": "1",
        "Item Description": "SNAPPER PTN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001012001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "8874927",
        "Last Price Paid": "$26.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001012",
        "Product Status": "Active"
    },
    {
        "Root": "10001012",
        "LV": "1",
        "Item Description": "SNAPPER PTN FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001012001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "63-01230",
        "Last Price Paid": "$26.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001012",
        "Product Status": "Active"
    },
    {
        "Root": "10086210",
        "LV": "1",
        "Item Description": "POPCORN CHEESY CHEDDAR 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "CCHEDDAR PB-16",
        "Last Price Paid": "$78.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086210",
        "Product Status": "Active"
    },
    {
        "Root": "10040998",
        "LV": "1",
        "Item Description": "CUP WINE ACRYLIC DRAWNTOLIFE 10Z 72/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040998001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.67",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040998",
        "Product Status": "Active"
    },
    {
        "Root": "10010343",
        "LV": "2",
        "Item Description": "BEER SAISON DUPONT 11.2Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010343001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9597",
        "Last Price Paid": "$3.16",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010343",
        "Product Status": "Active"
    },
    {
        "Root": "10035210",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861280000",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035210",
        "Product Status": "Active"
    },
    {
        "Root": "10004717",
        "LV": "1",
        "Item Description": "CHIP DORITOS NACHO 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2315067",
        "Last Price Paid": "$0.35",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004717",
        "Product Status": "Active"
    },
    {
        "Root": "10037857",
        "LV": "1",
        "Item Description": "SB PEACH FLAVORED/FLAVOURED JUICE BLEND TETRA 1L 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037857001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11104438",
        "Last Price Paid": "$6.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037857",
        "Product Status": "Active"
    },
    {
        "Root": "10002420",
        "LV": "1",
        "Item Description": "CHEESE GOUDA MIDNIGHT MOON GOAT CYPRESS GROVE 9 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002420001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "39498",
        "Last Price Paid": "$19.01",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002420",
        "Product Status": "Active"
    },
    {
        "Root": "10107494",
        "LV": "1",
        "Item Description": "BEER STEIGL KEY LIME 50 L KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16115",
        "Last Price Paid": "$199.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107494",
        "Product Status": "Active"
    },
    {
        "Root": "10039871",
        "LV": "2",
        "Item Description": "TEQUILA MONTE ALBAN SILVER 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039871002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9679108",
        "Last Price Paid": "$13.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039871",
        "Product Status": "Active"
    },
    {
        "Root": "10029682",
        "LV": "1",
        "Item Description": "CABERNET SAVIGNON EMBLEM OSSO VYD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9162404",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029682",
        "Product Status": "Active"
    },
    {
        "Root": "10026463",
        "LV": "1",
        "Item Description": "SUGAR SANDING LAVENDER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9462",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026463",
        "Product Status": "Active"
    },
    {
        "Root": "10001614",
        "LV": "1",
        "Item Description": "PEPPER GREEN 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514516",
        "Last Price Paid": "$14.38",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001614",
        "Product Status": "Active"
    },
    {
        "Root": "10003485",
        "LV": "1",
        "Item Description": "PASTE SAMBAL OLECKI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7849086",
        "Last Price Paid": "$3.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003485",
        "Product Status": "Active"
    },
    {
        "Root": "10005587",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT MULTIGRAIN MINI 1.5Z PRE-PROOFED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "52405",
        "Last Price Paid": "$0.42",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005587",
        "Product Status": "Active"
    },
    {
        "Root": "10005587",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT MULTIGRAIN MINI 1.5Z PRE-PROOFED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005587",
        "Product Status": "Active"
    },
    {
        "Root": "10000273",
        "LV": "1",
        "Item Description": "FLOWER APPLE BLSSMS 50/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000273001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489978",
        "Last Price Paid": "$0.27",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000273",
        "Product Status": "Active"
    },
    {
        "Root": "10031298",
        "LV": "1",
        "Item Description": "DECOR PEARLS BLUEBERRY BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031298001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BLPOPP",
        "Last Price Paid": "$32.67",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031298",
        "Product Status": "Active"
    },
    {
        "Root": "10003872",
        "LV": "1",
        "Item Description": "YUZU JUICE 1.8 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003872001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "13200",
        "Last Price Paid": "$90.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003872",
        "Product Status": "Active"
    },
    {
        "Root": "10001208",
        "LV": "1",
        "Item Description": "CHICKEN BREAST SUB 3.8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9675760",
        "Last Price Paid": "$1.07",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001208",
        "Product Status": "Active"
    },
    {
        "Root": "10001208",
        "LV": "1",
        "Item Description": "CHICKEN BREAST SUB 3.8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.07",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001208",
        "Product Status": "Active"
    },
    {
        "Root": "10037561",
        "LV": "1",
        "Item Description": "EGG BITE 3 CHEESE 140/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.70",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037561",
        "Product Status": "Active"
    },
    {
        "Root": "10042496",
        "LV": "2",
        "Item Description": "WINE LA FETE ROSE DE PROVENCE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042496002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9635911",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10042496",
        "Product Status": "Active"
    },
    {
        "Root": "10008808",
        "LV": "1",
        "Item Description": "CAB SAUV CAYMUS SP SELECT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "576037",
        "Last Price Paid": "$155.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008808",
        "Product Status": "Active"
    },
    {
        "Root": "10104938",
        "LV": "1",
        "Item Description": "MUG BASE 100TH ELITE8 TINK PURP 16OZ 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104938",
        "Product Status": "Active"
    },
    {
        "Root": "10001790",
        "LV": "1",
        "Item Description": "BROCCOLI CROWNS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401054",
        "Last Price Paid": "$29.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001790",
        "Product Status": "Active"
    },
    {
        "Root": "10001790",
        "LV": "1",
        "Item Description": "BROCCOLI CROWNS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001790",
        "Product Status": "Active"
    },
    {
        "Root": "10000050",
        "LV": "1",
        "Item Description": "LAMB LEG B.R.T.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000050001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1240200",
        "Last Price Paid": "$9.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000050",
        "Product Status": "Active"
    },
    {
        "Root": "10004588",
        "LV": "1",
        "Item Description": "VINEGAR MALT 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7003742",
        "Last Price Paid": "$2.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004588",
        "Product Status": "Active"
    },
    {
        "Root": "10041856",
        "LV": "1",
        "Item Description": "COFFEE ICED BRAZILIAN COLD BRU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041856001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "660626",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041856",
        "Product Status": "Active"
    },
    {
        "Root": "10042531",
        "LV": "1",
        "Item Description": "WINE CABERNET SAUVIGNON WADE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042531001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042531",
        "Product Status": "Active"
    },
    {
        "Root": "10035334",
        "LV": "1",
        "Item Description": "CALLA LILY MEDIUM WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CLT5 WH",
        "Last Price Paid": "",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035334",
        "Product Status": "Active"
    },
    {
        "Root": "10023436",
        "LV": "1",
        "Item Description": "RED BLND TIKAL PATRIOTA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9266733",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023436",
        "Product Status": "Active"
    },
    {
        "Root": "10044171",
        "LV": "1",
        "Item Description": "POPCORN HOLIDAY PACKAGED DHS ONLY W/NUTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044171001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "HOLIDAY FESTIV",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044171",
        "Product Status": "Active"
    },
    {
        "Root": "10095612",
        "LV": "1",
        "Item Description": "TEA CONCENTRATE REPUB OF TEA GREEN MATCHA 64OZ/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7804284",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095612",
        "Product Status": "Active"
    },
    {
        "Root": "10101409",
        "LV": "1",
        "Item Description": "WAFFLE CONES ORANGE CREAMSICLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101409001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "ORC-M",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101409",
        "Product Status": "Active"
    },
    {
        "Root": "10037555",
        "LV": "1",
        "Item Description": "SUGAR SHARK ATTACK ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "49273",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037555",
        "Product Status": "Active"
    },
    {
        "Root": "10004760",
        "LV": "1",
        "Item Description": "BEAN RED DRY 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004760001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5844550",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004760",
        "Product Status": "Active"
    },
    {
        "Root": "10100712",
        "LV": "1",
        "Item Description": "LIQUEUR CHARTEUSE YELLOW VEP 1L/6CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "339318",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100712",
        "Product Status": "Active"
    },
    {
        "Root": "10005105",
        "LV": "1",
        "Item Description": "STICK APPLE SUPER SETTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005105001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "4004",
        "Last Price Paid": "$0.04",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005105",
        "Product Status": "Active"
    },
    {
        "Root": "10031354",
        "LV": "1",
        "Item Description": "BANANAS 22CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "302031",
        "Last Price Paid": "$0.54",
        "Case Pack": "22",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031354",
        "Product Status": "Active"
    },
    {
        "Root": "10108252",
        "LV": "1",
        "Item Description": "SLEEVE BOX \"HAUNTED MANSION\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "5100017",
        "Last Price Paid": "$0.38",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108252",
        "Product Status": "Active"
    },
    {
        "Root": "10004566",
        "LV": "2",
        "Item Description": "WAFER MILK CHOC 455EA/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004566002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000199290",
        "Vendor": "DAVID DELIGHTS LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.10",
        "Case Pack": "455",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004566",
        "Product Status": "Active"
    },
    {
        "Root": "10000612",
        "LV": "1",
        "Item Description": "MEATBALL ITALIAN CKD 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "106",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000612",
        "Product Status": "Active"
    },
    {
        "Root": "10004770",
        "LV": "1",
        "Item Description": "MUSTARD DIJON MAILLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090202",
        "Last Price Paid": "$18.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004770",
        "Product Status": "Active"
    },
    {
        "Root": "10007950",
        "LV": "2",
        "Item Description": "GIGONDAS E GUIGAL RED 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007950001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9128385",
        "Last Price Paid": "$26.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007950",
        "Product Status": "Active"
    },
    {
        "Root": "10000170",
        "LV": "2",
        "Item Description": "LEMON 200 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000170002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.28",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000170",
        "Product Status": "Active"
    },
    {
        "Root": "10005359",
        "LV": "1",
        "Item Description": "SPREAD HAZELNUT CHOCLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7863400",
        "Last Price Paid": "$22.05",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005359",
        "Product Status": "Active"
    },
    {
        "Root": "10041114",
        "LV": "1",
        "Item Description": "CHEESE RICOTTA IMPASTATA 2/10 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041114001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "61010",
        "Last Price Paid": "$3.60",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041114",
        "Product Status": "Active"
    },
    {
        "Root": "10000831",
        "LV": "2",
        "Item Description": "CHICKEN THIGH BONELESS SKINLESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000831001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000831",
        "Product Status": "Active"
    },
    {
        "Root": "10106268",
        "LV": "1",
        "Item Description": "GUACAMOLE WESTERN STYLE 12/1LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7174717",
        "Last Price Paid": "$3.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106268",
        "Product Status": "Active"
    },
    {
        "Root": "10002467",
        "LV": "1",
        "Item Description": "CHEESE GOUDA BABY 12/7 OZ WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002467001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "57579",
        "Last Price Paid": "$3.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002467",
        "Product Status": "Active"
    },
    {
        "Root": "10007928",
        "LV": "1",
        "Item Description": "PINOT GRIG LAGEDER RIFF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "972452",
        "Last Price Paid": "$8.02",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007928",
        "Product Status": "Active"
    },
    {
        "Root": "10027801",
        "LV": "1",
        "Item Description": "BLEND AALTO RED 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "119893",
        "Last Price Paid": "$45.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027801",
        "Product Status": "Active"
    },
    {
        "Root": "10035569",
        "LV": "1",
        "Item Description": "BREAD FICELLE UNSLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "2:FF",
        "Last Price Paid": "$0.87",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035569",
        "Product Status": "Active"
    },
    {
        "Root": "10041048",
        "LV": "1",
        "Item Description": "PLATE PAPER OVAL 9X11 IN MARY BLAIR 500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041048",
        "Product Status": "Active"
    },
    {
        "Root": "10108169",
        "LV": "1",
        "Item Description": "BACKER 2X6\" BLACK 4PLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "5100026",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108169",
        "Product Status": "Active"
    },
    {
        "Root": "10002466",
        "LV": "1",
        "Item Description": "CHEESE BLUE FOURME D AMBERT AOC FRANCE 5.5 LB WHEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002466001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "9042",
        "Last Price Paid": "$10.58",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002466",
        "Product Status": "Active"
    },
    {
        "Root": "10001619",
        "LV": "1",
        "Item Description": "ONION YELLOW DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "599997",
        "Last Price Paid": "$10.28",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001619",
        "Product Status": "Active"
    },
    {
        "Root": "10001619",
        "LV": "1",
        "Item Description": "ONION YELLOW DICED 3/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-002-34",
        "Last Price Paid": "$10.28",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001619",
        "Product Status": "Active"
    },
    {
        "Root": "10017916",
        "LV": "1",
        "Item Description": "NG PETITE LONG CARROT 50 CT PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PLC-33",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017916",
        "Product Status": "Active"
    },
    {
        "Root": "10023588",
        "LV": "1",
        "Item Description": "SPRAY COLOR SILVER PEARL SHEEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023588001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ118754",
        "Last Price Paid": "$7.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023588",
        "Product Status": "Active"
    },
    {
        "Root": "10086113",
        "LV": "1",
        "Item Description": "CHOCO WHITE RECT. (2-1/4\" X 1-1/2\") W/EDIBLE ART",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW17646",
        "Last Price Paid": "$0.69",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086113",
        "Product Status": "Active"
    },
    {
        "Root": "10034779",
        "LV": "1",
        "Item Description": "TAMALE PORK COOKED RUIZ 60/5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3135092",
        "Last Price Paid": "$1.30",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034779",
        "Product Status": "Active"
    },
    {
        "Root": "10002637",
        "LV": "2",
        "Item Description": "SPICE THYME LEAF 6/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002637002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.78",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002637",
        "Product Status": "Active"
    },
    {
        "Root": "10002637",
        "LV": "2",
        "Item Description": "SPICE THYME LEAF 6/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002637002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00562-7",
        "Last Price Paid": "$2.78",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002637",
        "Product Status": "Active"
    },
    {
        "Root": "10004775",
        "LV": "1",
        "Item Description": "ARTICHOKE HEART QUARTERED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2333508",
        "Last Price Paid": "$15.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004775",
        "Product Status": "Active"
    },
    {
        "Root": "10015423",
        "LV": "1",
        "Item Description": "FLAVORING SOUR CRM CHIVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2351",
        "Last Price Paid": "$33.68",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015423",
        "Product Status": "Active"
    },
    {
        "Root": "10034064",
        "LV": "1",
        "Item Description": "ALBARINO PACO & LOLA ALBARINO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "799732",
        "Last Price Paid": "$16.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034064",
        "Product Status": "Active"
    },
    {
        "Root": "10010110",
        "LV": "1",
        "Item Description": "BEER MILLER LITE 6PK 12OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.17",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010110",
        "Product Status": "Active"
    },
    {
        "Root": "10015660",
        "LV": "1",
        "Item Description": "CHICKEN TIKKI MASALA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015660001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043456",
        "Last Price Paid": "$25.47",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015660",
        "Product Status": "Active"
    },
    {
        "Root": "10032677",
        "LV": "1",
        "Item Description": "BOWL MINNIE SINK 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "B-936",
        "Last Price Paid": "$2.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032677",
        "Product Status": "Active"
    },
    {
        "Root": "10001358",
        "LV": "1",
        "Item Description": "TAPENADE CALAMATA OLIVE 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001358001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001358",
        "Product Status": "Active"
    },
    {
        "Root": "10036366",
        "LV": "1",
        "Item Description": "DOUGH PIE CRUST 6.75 SHEET 48/3.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036366001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6206221",
        "Last Price Paid": "$0.68",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036366",
        "Product Status": "Active"
    },
    {
        "Root": "10014032",
        "LV": "2",
        "Item Description": "SHEET GLASSINE CHOC UNSCRD SNGL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014032002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51149004",
        "Last Price Paid": "$0.11",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014032",
        "Product Status": "Active"
    },
    {
        "Root": "10000589",
        "LV": "2",
        "Item Description": "SAUSAGE PATTY CKD 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000589002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.20",
        "Case Pack": "106",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000589",
        "Product Status": "Active"
    },
    {
        "Root": "10033281",
        "LV": "3",
        "Item Description": "MINNIE BOW RED 2 1/8 X 1 1/8 MED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033281003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 322901",
        "Last Price Paid": "$0.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10033281",
        "Product Status": "Active"
    },
    {
        "Root": "10031553",
        "LV": "1",
        "Item Description": "SYRUP MONIN GINGERBREAD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-1060",
        "Last Price Paid": "$5.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031553",
        "Product Status": "Active"
    },
    {
        "Root": "10040682",
        "LV": "1",
        "Item Description": "BREAD ROLL PRETZEL UNSLICED 1.4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "240234",
        "Last Price Paid": "$0.38",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040682",
        "Product Status": "Active"
    },
    {
        "Root": "10011029",
        "LV": "1",
        "Item Description": "PASTA FROZEN RAVIOLI 4 CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070113",
        "Last Price Paid": "$10.68",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011029",
        "Product Status": "Active"
    },
    {
        "Root": "10030346",
        "LV": "1",
        "Item Description": "SPICE RAS EL HANOUT 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI943",
        "Last Price Paid": "$31.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030346",
        "Product Status": "Active"
    },
    {
        "Root": "10090551",
        "LV": "1",
        "Item Description": "MAHI MAHI 2-4OZ PORTION IQF BONELESS SKINLESS CHEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6528504",
        "Last Price Paid": "$48.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090551",
        "Product Status": "Active"
    },
    {
        "Root": "10028447",
        "LV": "1",
        "Item Description": "SHELL TART CHOCOLATE MINI ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028447001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "8090421",
        "Last Price Paid": "$0.34",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028447",
        "Product Status": "Active"
    },
    {
        "Root": "10095640",
        "LV": "1",
        "Item Description": "WINE RED BLEND MASSAYA 750ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095640001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "846300",
        "Last Price Paid": "$55.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095640",
        "Product Status": "Active"
    },
    {
        "Root": "10013982",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "96391",
        "Last Price Paid": "$87.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013982",
        "Product Status": "Active"
    },
    {
        "Root": "10092548",
        "LV": "1",
        "Item Description": "BOX DVC MEMBER EXPERIENCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "DVCX",
        "Last Price Paid": "$300.00",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092548",
        "Product Status": "Active"
    },
    {
        "Root": "10004507",
        "LV": "1",
        "Item Description": "CRACKER DISTINCTIVE HERIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004507001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3018439",
        "Last Price Paid": "$0.90",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004507",
        "Product Status": "Active"
    },
    {
        "Root": "10004213",
        "LV": "1",
        "Item Description": "SB TEAVANA ICED GRN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11034441",
        "Last Price Paid": "$0.75",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004213",
        "Product Status": "Active"
    },
    {
        "Root": "10001764",
        "LV": "1",
        "Item Description": "ONION PEARL RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406040",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001764",
        "Product Status": "Active"
    },
    {
        "Root": "10013545",
        "LV": "2",
        "Item Description": "CHURRO BITE CALIFORNIA 2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013545001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "4185",
        "Last Price Paid": "$0.05",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10013545",
        "Product Status": "Active"
    },
    {
        "Root": "10030072",
        "LV": "2",
        "Item Description": "SAUVIGNON BLANC MATUA MARLBOROUGH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030072002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9004108",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030072",
        "Product Status": "Active"
    },
    {
        "Root": "10037945",
        "LV": "1",
        "Item Description": "TRANSFER SQUARE MINNIE FOR CHOC 1.5X1.5 2400/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037945001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SS-MINNIE",
        "Last Price Paid": "$0.21",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037945",
        "Product Status": "Active"
    },
    {
        "Root": "10014336",
        "LV": "1",
        "Item Description": "BIALE CHICKEN ZINFADEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "264975",
        "Last Price Paid": "$25.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014336",
        "Product Status": "Active"
    },
    {
        "Root": "10004845",
        "LV": "1",
        "Item Description": "CRACKER GRAHAM 2/5# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004845001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6190797",
        "Last Price Paid": "$17.86",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004845",
        "Product Status": "Active"
    },
    {
        "Root": "10009452",
        "LV": "1",
        "Item Description": "SCOTCH CUTTY SARK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "96075",
        "Last Price Paid": "$17.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009452",
        "Product Status": "Active"
    },
    {
        "Root": "10009452",
        "LV": "1",
        "Item Description": "SCOTCH CUTTY SARK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "96075",
        "Last Price Paid": "$17.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009452",
        "Product Status": "Active"
    },
    {
        "Root": "10013845",
        "LV": "1",
        "Item Description": "MUSTARD RED RUFFLED PETITE 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013845001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.58",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013845",
        "Product Status": "Active"
    },
    {
        "Root": "10006658",
        "LV": "1",
        "Item Description": "APRON PLASTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9110032",
        "Last Price Paid": "$0.29",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006658",
        "Product Status": "Active"
    },
    {
        "Root": "10027431",
        "LV": "2",
        "Item Description": "CAKE DARK CHOCOLATE MICKEY DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027431002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027431",
        "Product Status": "Active"
    },
    {
        "Root": "10027431",
        "LV": "2",
        "Item Description": "CAKE DARK CHOCOLATE MICKEY DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027431002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "DOME-DK",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027431",
        "Product Status": "Active"
    },
    {
        "Root": "10004696",
        "LV": "1",
        "Item Description": "DRIED FRUIT CRANBERRY 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004696001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "231000",
        "Last Price Paid": "$88.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004696",
        "Product Status": "Active"
    },
    {
        "Root": "10002104",
        "LV": "1",
        "Item Description": "PASTE TAMARIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450017",
        "Last Price Paid": "$5.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002104",
        "Product Status": "Active"
    },
    {
        "Root": "10001942",
        "LV": "1",
        "Item Description": "LETTUCE/TOMATO SETUP 48/C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "411061",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001942",
        "Product Status": "Active"
    },
    {
        "Root": "10001942",
        "LV": "1",
        "Item Description": "LETTUCE/TOMATO SETUP 48/C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "FH011",
        "Last Price Paid": "$1.04",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001942",
        "Product Status": "Active"
    },
    {
        "Root": "10004614",
        "LV": "1",
        "Item Description": "GRANOLA BAR VARIETY PK 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5745260",
        "Last Price Paid": "$0.71",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004614",
        "Product Status": "Active"
    },
    {
        "Root": "10000348",
        "LV": "1",
        "Item Description": "ONION SPANISH 3/8 INCH JULIEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-008-38",
        "Last Price Paid": "$9.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000348",
        "Product Status": "Active"
    },
    {
        "Root": "10038775",
        "LV": "1",
        "Item Description": "SPICY MANGO MONIN 1 LITER BOTTLES 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038775",
        "Product Status": "Active"
    },
    {
        "Root": "10010734",
        "LV": "1",
        "Item Description": "LIQ WHT CRM DE MENTH BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9001666",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010734",
        "Product Status": "Active"
    },
    {
        "Root": "10016645",
        "LV": "1",
        "Item Description": "CHILI, VEGETARIAN, LENTIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7077015",
        "Last Price Paid": "$11.07",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016645",
        "Product Status": "Active"
    },
    {
        "Root": "10002834",
        "LV": "1",
        "Item Description": "SPRITE ZERO 20OZ 24BT CON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002834",
        "Product Status": "Active"
    },
    {
        "Root": "10005718",
        "LV": "1",
        "Item Description": "BREAD NAAN TANDOORI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005718001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7043455",
        "Last Price Paid": "$0.64",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005718",
        "Product Status": "Active"
    },
    {
        "Root": "10082054",
        "LV": "1",
        "Item Description": "WINE SPARK STEENBERG BRUT 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "132646",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082054",
        "Product Status": "Active"
    },
    {
        "Root": "10000466",
        "LV": "1",
        "Item Description": "CAKE COFFEE RASPBERRY/BAVARIAN 10 IN ROUND UNSLICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000466001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000466",
        "Product Status": "Active"
    },
    {
        "Root": "10009871",
        "LV": "1",
        "Item Description": "PORT FERREIRA 10YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "941262",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009871",
        "Product Status": "Active"
    },
    {
        "Root": "10001340",
        "LV": "2",
        "Item Description": "HUMMUS PLAIN 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001340001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10001340",
        "Product Status": "Active"
    },
    {
        "Root": "10082043",
        "LV": "1",
        "Item Description": "DRAFT IVANHOE PARK APPLE EVER AFTER SOUR ALE 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082043001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99693",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082043",
        "Product Status": "Active"
    },
    {
        "Root": "10025284",
        "LV": "2",
        "Item Description": "GUMPASTE SEASHELL ASST.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025284002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10025284",
        "Product Status": "Active"
    },
    {
        "Root": "10038254",
        "LV": "1",
        "Item Description": "GUMPASTE BOWS PRIMARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "14311",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038254",
        "Product Status": "Active"
    },
    {
        "Root": "10027724",
        "LV": "3",
        "Item Description": "BAG JUTE GANACHERY W/ HANDLE PRINTED 1C1S 06AB 5X6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027724003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51700066",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10027724",
        "Product Status": "Active"
    },
    {
        "Root": "10092935",
        "LV": "1",
        "Item Description": "YOGURT VANILLA POUCH 4/6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8992034",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092935",
        "Product Status": "Active"
    },
    {
        "Root": "10034913",
        "LV": "2",
        "Item Description": "PRO PANNACOTTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034913002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672150",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034913",
        "Product Status": "Active"
    },
    {
        "Root": "10110896",
        "LV": "1",
        "Item Description": "BOURBON OLD FORESTER BIRTHDAY 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9013356",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110896",
        "Product Status": "Active"
    },
    {
        "Root": "10007824",
        "LV": "1",
        "Item Description": "MERLOT PROVENANCE NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007824001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9014371",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007824",
        "Product Status": "Active"
    },
    {
        "Root": "10018853",
        "LV": "1",
        "Item Description": "POWDER DECOR PEARL SILVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M10993",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018853",
        "Product Status": "Active"
    },
    {
        "Root": "10090605",
        "LV": "1",
        "Item Description": "BRANDY DEKUYPER BLACKBERRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "233578",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090605",
        "Product Status": "Active"
    },
    {
        "Root": "10003788",
        "LV": "1",
        "Item Description": "PIZZA CHEESE PKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BROOKE FOODS-B",
        "Last Price Paid": "$9.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003788",
        "Product Status": "Active"
    },
    {
        "Root": "10001355",
        "LV": "1",
        "Item Description": "MARINADE TAMARIND 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001355",
        "Product Status": "Active"
    },
    {
        "Root": "10002399",
        "LV": "2",
        "Item Description": "CHEESE GOUDA SMOKED REAL CHEESE 2/6 LB LOAF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002399002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "30851",
        "Last Price Paid": "$6.56",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002399",
        "Product Status": "Active"
    },
    {
        "Root": "10003816",
        "LV": "1",
        "Item Description": "PIEROGI POT/CHS LARGE 1.4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6042584",
        "Last Price Paid": "$0.20",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003816",
        "Product Status": "Active"
    },
    {
        "Root": "10029615",
        "LV": "1",
        "Item Description": "CHOC MICKEY HEAD ORNGE SILH/BLK PUMPKIN HEAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW31927",
        "Last Price Paid": "$0.65",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029615",
        "Product Status": "Active"
    },
    {
        "Root": "10105460",
        "LV": "1",
        "Item Description": "MANGO & PINEAPPLE 100CT LARGE LEAF DISCOVERY TEA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105460",
        "Product Status": "Active"
    },
    {
        "Root": "10000965",
        "LV": "1",
        "Item Description": "FISHLESS FILET GARDEIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000965001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "84223400166",
        "Last Price Paid": "$3.95",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000965",
        "Product Status": "Active"
    },
    {
        "Root": "10090498",
        "LV": "1",
        "Item Description": "BEER PLAYALINDA HERD MENTALITY NITRO 15.5G KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "11428",
        "Last Price Paid": "$234.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090498",
        "Product Status": "Active"
    },
    {
        "Root": "10004359",
        "LV": "1",
        "Item Description": "CROUTON SEASONED SINGLE S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5610571",
        "Last Price Paid": "$0.08",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004359",
        "Product Status": "Active"
    },
    {
        "Root": "10033930",
        "LV": "1",
        "Item Description": "CHOCOLATE 1-1/2\" WHITE W/EDIBLE ART (200/CS)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW29872",
        "Last Price Paid": "$0.55",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033930",
        "Product Status": "Active"
    },
    {
        "Root": "10039782",
        "LV": "1",
        "Item Description": "OIL OLIVE EXTRA VIRGIN FILIPPO BERIO 1 GAL 3/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039782001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.57",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039782",
        "Product Status": "Active"
    },
    {
        "Root": "10104442",
        "LV": "1",
        "Item Description": "CIDER 3 DAUGHTERS BLOOD ORANGE 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76397",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104442",
        "Product Status": "Active"
    },
    {
        "Root": "10001982",
        "LV": "1",
        "Item Description": "SAGE SAMPLER DEMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08SAB-33",
        "Last Price Paid": "$41.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001982",
        "Product Status": "Active"
    },
    {
        "Root": "10034575",
        "LV": "1",
        "Item Description": "PASTA PENNE RIGATE SHELF STABLE 4/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034575001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1368679",
        "Last Price Paid": "$7.79",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034575",
        "Product Status": "Active"
    },
    {
        "Root": "10107172",
        "LV": "1",
        "Item Description": "FROZEN DART EUROPEAN QUAIL 2-PACK 14.10 - 15.52 -O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107172001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZGAMQUA005",
        "Last Price Paid": "$14.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107172",
        "Product Status": "Active"
    },
    {
        "Root": "10040136",
        "LV": "2",
        "Item Description": "SOUP TOMATO BASIL BISQUE 4/4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040136002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068816",
        "Vendor": "SYSCO",
        "Vendor Item Nb": "2737043",
        "Last Price Paid": "$14.81",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040136",
        "Product Status": "Active"
    },
    {
        "Root": "10005767",
        "LV": "1",
        "Item Description": "CREME CANNOLI 1.5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "60749",
        "Last Price Paid": "$6.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005767",
        "Product Status": "Active"
    },
    {
        "Root": "10016891",
        "LV": "1",
        "Item Description": "BACON SMKD APPLEWOOD SLCD 13/15",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "420",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016891",
        "Product Status": "Active"
    },
    {
        "Root": "10009562",
        "LV": "1",
        "Item Description": "TEQUILA SAUZA 3 GEN 750ML PLATA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "245842",
        "Last Price Paid": "$27.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009562",
        "Product Status": "Active"
    },
    {
        "Root": "10006184",
        "LV": "1",
        "Item Description": "COASTER WOVEN LINEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006184001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "40678300",
        "Last Price Paid": "$0.05",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006184",
        "Product Status": "Active"
    },
    {
        "Root": "10100888",
        "LV": "1",
        "Item Description": "ECLAIR MINI 2.25 IN 25P PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA7306",
        "Last Price Paid": "$0.15",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100888",
        "Product Status": "Active"
    },
    {
        "Root": "10007463",
        "LV": "1",
        "Item Description": "BLND INDABA MOSAIC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "841640",
        "Last Price Paid": "$7.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007463",
        "Product Status": "Active"
    },
    {
        "Root": "10026936",
        "LV": "1",
        "Item Description": "CHIPS TORTILLA DORITOS NACHO CHEESE SS 64/1.75 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5597851",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026936",
        "Product Status": "Active"
    },
    {
        "Root": "10004886",
        "LV": "1",
        "Item Description": "PASTA DRY FREGOLA 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004886001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$73.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004886",
        "Product Status": "Active"
    },
    {
        "Root": "10003050",
        "LV": "1",
        "Item Description": "PASTA DRY PENNONI RIGATI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808034087",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003050",
        "Product Status": "Active"
    },
    {
        "Root": "10034222",
        "LV": "1",
        "Item Description": "CUP CLEAR PET 20OZ ACR20",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034222",
        "Product Status": "Active"
    },
    {
        "Root": "10004541",
        "LV": "1",
        "Item Description": "OATMEAL INSTANT IND 1Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3168424",
        "Last Price Paid": "$0.32",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004541",
        "Product Status": "Active"
    },
    {
        "Root": "10001319",
        "LV": "1",
        "Item Description": "FRY STEALTH 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001319001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.73",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001319",
        "Product Status": "Active"
    },
    {
        "Root": "10031544",
        "LV": "1",
        "Item Description": "EGGS QUAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031544001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "EGG011",
        "Last Price Paid": "$0.65",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031544",
        "Product Status": "Active"
    },
    {
        "Root": "10082151",
        "LV": "1",
        "Item Description": "BEER TAJ MAHAL 650 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "8067",
        "Last Price Paid": "$2.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082151",
        "Product Status": "Active"
    },
    {
        "Root": "10013581",
        "LV": "1",
        "Item Description": "POINSETTA SM RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NTKRD `",
        "Last Price Paid": "$7.09",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013581",
        "Product Status": "Active"
    },
    {
        "Root": "10082026",
        "LV": "1",
        "Item Description": "DRAFT CIDER 3 DAUGHTERS FIG 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99695",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082026",
        "Product Status": "Active"
    },
    {
        "Root": "10100928",
        "LV": "1",
        "Item Description": "LIQUEUR DEKUYPER AMARETTO PET 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "537529",
        "Last Price Paid": "$9.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100928",
        "Product Status": "Active"
    },
    {
        "Root": "10093072",
        "LV": "1",
        "Item Description": "YOGURT PLAIN GREEK ALL NATURAL FAT-FREE TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093072001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1980077",
        "Last Price Paid": "$5.03",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093072",
        "Product Status": "Active"
    },
    {
        "Root": "10010257",
        "LV": "2",
        "Item Description": "DRAFT 3 DAUGHTR BLND HBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010257001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "80902",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010257",
        "Product Status": "Active"
    },
    {
        "Root": "10011811",
        "LV": "1",
        "Item Description": "SAUSAGE CHORIZO GROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011811001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "258A",
        "Last Price Paid": "$3.70",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011811",
        "Product Status": "Active"
    },
    {
        "Root": "10108629",
        "LV": "2",
        "Item Description": "TOPPER WH CHOC JACK 300 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108629002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2313CP1",
        "Last Price Paid": "$0.72",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10108629",
        "Product Status": "Active"
    },
    {
        "Root": "10009942",
        "LV": "1",
        "Item Description": "LIQ OUZO METAXA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "270674",
        "Last Price Paid": "$16.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009942",
        "Product Status": "Active"
    },
    {
        "Root": "10004145",
        "LV": "1",
        "Item Description": "TRUFFLE PBJ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004145001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.08",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004145",
        "Product Status": "Active"
    },
    {
        "Root": "10030010",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE GINGERBREAD BOY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "22006",
        "Last Price Paid": "$0.45",
        "Case Pack": "126",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030010",
        "Product Status": "Active"
    },
    {
        "Root": "10109652",
        "LV": "1",
        "Item Description": "LID WHITE DOME SIP FITS 8OZ HOT DW CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109652",
        "Product Status": "Active"
    },
    {
        "Root": "10025941",
        "LV": "1",
        "Item Description": "OIL PEPPERMINT NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "70",
        "Last Price Paid": "$72.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025941",
        "Product Status": "Active"
    },
    {
        "Root": "10040827",
        "LV": "2",
        "Item Description": "GIN FORDS 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040827002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040827",
        "Product Status": "Active"
    },
    {
        "Root": "10038206",
        "LV": "1",
        "Item Description": "SB FRAPPUCCINO COFFEE SYRUP BASE 63OZ 4CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038206001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11071914",
        "Last Price Paid": "$10.92",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038206",
        "Product Status": "Active"
    },
    {
        "Root": "10003327",
        "LV": "1",
        "Item Description": "GEL LIQUID SUNSET ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22501",
        "Last Price Paid": "$4.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003327",
        "Product Status": "Active"
    },
    {
        "Root": "10003047",
        "LV": "1",
        "Item Description": "MILK SWEETENED CONDENSED EAGLE 140OZ 3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.88",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003047",
        "Product Status": "Active"
    },
    {
        "Root": "10104650",
        "LV": "1",
        "Item Description": "BASE CURRY RED THAI PASTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5397762",
        "Last Price Paid": "$9.26",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104650",
        "Product Status": "Active"
    },
    {
        "Root": "10001599",
        "LV": "1",
        "Item Description": "APPLE PINK LADY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.35",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001599",
        "Product Status": "Active"
    },
    {
        "Root": "10001599",
        "LV": "1",
        "Item Description": "APPLE PINK LADY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001599001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "195012",
        "Last Price Paid": "$0.35",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001599",
        "Product Status": "Active"
    },
    {
        "Root": "10001384",
        "LV": "1",
        "Item Description": "CELERY MICRO CUTTING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "670002",
        "Last Price Paid": "$12.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001384",
        "Product Status": "Active"
    },
    {
        "Root": "10001384",
        "LV": "1",
        "Item Description": "CELERY MICRO CUTTING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001384001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MCE-33-L",
        "Last Price Paid": "$12.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001384",
        "Product Status": "Active"
    },
    {
        "Root": "10037537",
        "LV": "1",
        "Item Description": "BARBERA SCARPETTA DEL MONFERRATO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9341881",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037537",
        "Product Status": "Active"
    },
    {
        "Root": "10002753",
        "LV": "1",
        "Item Description": "TOPPING HOT FUDGE 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002753",
        "Product Status": "Active"
    },
    {
        "Root": "10001570",
        "LV": "1",
        "Item Description": "FRUIT STAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "390010",
        "Last Price Paid": "$28.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001570",
        "Product Status": "Active"
    },
    {
        "Root": "10039130",
        "LV": "1",
        "Item Description": "CONFETTI QUINS GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24048",
        "Last Price Paid": "$6.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039130",
        "Product Status": "Active"
    },
    {
        "Root": "10023091",
        "LV": "1",
        "Item Description": "PROSECCO ACINUM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023091001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "986720",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023091",
        "Product Status": "Active"
    },
    {
        "Root": "10002367",
        "LV": "2",
        "Item Description": "CHEESE RICOTTA LM PART SKIM 4/5LB TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002367002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1975408",
        "Last Price Paid": "$12.15",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002367",
        "Product Status": "Active"
    },
    {
        "Root": "10036150",
        "LV": "1",
        "Item Description": "FRUIT CUP MIXED FRUIT CANTALOUPE, HONEYDEW, GRAPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481855",
        "Last Price Paid": "$1.82",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036150",
        "Product Status": "Active"
    },
    {
        "Root": "10000347",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 1/2 INCH S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "27-004-12",
        "Last Price Paid": "$7.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000347",
        "Product Status": "Active"
    },
    {
        "Root": "10000347",
        "LV": "1",
        "Item Description": "POTATO SWEET DICED 1/2 INCH S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408835",
        "Last Price Paid": "$7.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000347",
        "Product Status": "Active"
    },
    {
        "Root": "10015590",
        "LV": "1",
        "Item Description": "HERB FINE MICRO.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450242",
        "Last Price Paid": "$12.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015590",
        "Product Status": "Active"
    },
    {
        "Root": "10037332",
        "LV": "1",
        "Item Description": "YELLOW CURLED BAKE CUP 3.5X1.1 CR53 6.8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003626",
        "Last Price Paid": "$0.26",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037332",
        "Product Status": "Active"
    },
    {
        "Root": "10018386",
        "LV": "1",
        "Item Description": "GIN AMSTERDAM 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018386",
        "Product Status": "Active"
    },
    {
        "Root": "10039740",
        "LV": "1",
        "Item Description": "CUP HOT 8Z PAPER INSULATED COFFEE HAZE SLEEV 20/50",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039740001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039740",
        "Product Status": "Active"
    },
    {
        "Root": "10088430",
        "LV": "1",
        "Item Description": "BREAD FOCACCIA SHEETPAN 6#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "03161D",
        "Last Price Paid": "$11.95",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088430",
        "Product Status": "Active"
    },
    {
        "Root": "10000833",
        "LV": "1",
        "Item Description": "TURKEY BACON SLICED COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000833001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$27.44",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000833",
        "Product Status": "Active"
    },
    {
        "Root": "10036746",
        "LV": "1",
        "Item Description": "TOLEDO SUGAR COCONUT PALM ORG NON GMO 1/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036746001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "10445388",
        "Last Price Paid": "$108.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036746",
        "Product Status": "Active"
    },
    {
        "Root": "10007318",
        "LV": "1",
        "Item Description": "BLND EVOLUTION SOKOL WHT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007318001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "512230",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007318",
        "Product Status": "Active"
    },
    {
        "Root": "10013104",
        "LV": "1",
        "Item Description": "MOLD MICKEY EARS BROWN PAPER 4.48X3.85X.59 BAKE IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391040",
        "Last Price Paid": "$0.46",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013104",
        "Product Status": "Active"
    },
    {
        "Root": "10027316",
        "LV": "1",
        "Item Description": "DRAFT HAWKES GROG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "37005",
        "Last Price Paid": "$198.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027316",
        "Product Status": "Active"
    },
    {
        "Root": "10102609",
        "LV": "1",
        "Item Description": "SHORTENING PALM FRYING AND BAKING SOLID CUBE 50 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2435011",
        "Last Price Paid": "$100.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102609",
        "Product Status": "Active"
    },
    {
        "Root": "10035827",
        "LV": "2",
        "Item Description": "SUGAR PRIMARY RAINBOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035827002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "41206",
        "Last Price Paid": "",
        "Case Pack": "147",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035827",
        "Product Status": "Active"
    },
    {
        "Root": "10020186",
        "LV": "1",
        "Item Description": "SANTA HOLDING PRESENT XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "YO-2380",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020186",
        "Product Status": "Active"
    },
    {
        "Root": "10101403",
        "LV": "1",
        "Item Description": "WAFFLE CONES PINK VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "C/BS-M",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101403",
        "Product Status": "Active"
    },
    {
        "Root": "10014811",
        "LV": "1",
        "Item Description": "FORGET ME NOT FILLERS (WHITE) FMN1WH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014811001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "FMN1WH",
        "Last Price Paid": "",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014811",
        "Product Status": "Active"
    },
    {
        "Root": "10013915",
        "LV": "1",
        "Item Description": "DEC ROSE ASST MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013915001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "66",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013915",
        "Product Status": "Active"
    },
    {
        "Root": "10110184",
        "LV": "1",
        "Item Description": "WINE CHATEAU D'ARMAILHAC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110184001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300996",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110184",
        "Product Status": "Active"
    },
    {
        "Root": "10110887",
        "LV": "2",
        "Item Description": "TRAY FOOD #100 KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110887001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10110887",
        "Product Status": "Active"
    },
    {
        "Root": "10004110",
        "LV": "1",
        "Item Description": "SHELL PHYLLO MINI SPINACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004110001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "7261",
        "Last Price Paid": "",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004110",
        "Product Status": "Active"
    },
    {
        "Root": "10001623",
        "LV": "1",
        "Item Description": "OKRA CUT FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001623",
        "Product Status": "Active"
    },
    {
        "Root": "10007192",
        "LV": "2",
        "Item Description": "CAB SAUV MURPHY GOOD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007192001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "534121",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007192",
        "Product Status": "Active"
    },
    {
        "Root": "10036216",
        "LV": "1",
        "Item Description": "TULIP MEDIUM WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "STL WH",
        "Last Price Paid": "",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036216",
        "Product Status": "Active"
    },
    {
        "Root": "10101569",
        "LV": "1",
        "Item Description": "SAUSAGE COTECHINO 8/17.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101569",
        "Product Status": "Active"
    },
    {
        "Root": "10007974",
        "LV": "2",
        "Item Description": "CHARD 14 HANDS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007974001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007974",
        "Product Status": "Active"
    },
    {
        "Root": "10039772",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET PRIME ANGUS 4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039772001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195002",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039772",
        "Product Status": "Active"
    },
    {
        "Root": "10105410",
        "LV": "1",
        "Item Description": "POPCORN CINNAMON CRUCNH KETTLE OSCAR DHS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "OKETTLECINN",
        "Last Price Paid": "$3.84",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105410",
        "Product Status": "Active"
    },
    {
        "Root": "10032146",
        "LV": "1",
        "Item Description": "SAUCE HOT SS PACKETS MONARCH 200/7GR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032146001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9877573",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032146",
        "Product Status": "Active"
    },
    {
        "Root": "10009800",
        "LV": "1",
        "Item Description": "RUM CRUZAN MANGO PET LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009800001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "537528",
        "Last Price Paid": "$10.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009800",
        "Product Status": "Active"
    },
    {
        "Root": "10005625",
        "LV": "1",
        "Item Description": "BREAD ROLL SANDWICH TUSCAN 6 INCH  60 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.27",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005625",
        "Product Status": "Active"
    },
    {
        "Root": "10031631",
        "LV": "1",
        "Item Description": "CHARD BESSERAT ROSE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "433751",
        "Last Price Paid": "$65.17",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031631",
        "Product Status": "Active"
    },
    {
        "Root": "10078303",
        "LV": "1",
        "Item Description": "BREAD TEXAS TOAST 1IN SLICED 6/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10078303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7536931",
        "Last Price Paid": "$4.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10078303",
        "Product Status": "Active"
    },
    {
        "Root": "10004772",
        "LV": "2",
        "Item Description": "SPICE JAMAICAN JERK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004772002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.46",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004772",
        "Product Status": "Active"
    },
    {
        "Root": "10036369",
        "LV": "1",
        "Item Description": "OMISSION ULTIMATE LIGHT GOLDEN ALE 12OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28756",
        "Last Price Paid": "$1.30",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036369",
        "Product Status": "Active"
    },
    {
        "Root": "10001420",
        "LV": "1",
        "Item Description": "LEMON MYERS 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001420001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FR096",
        "Last Price Paid": "$91.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001420",
        "Product Status": "Active"
    },
    {
        "Root": "10000073",
        "LV": "1",
        "Item Description": "BEEF SHORT RIB 3 BONE CENTER CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000073001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190076",
        "Last Price Paid": "$9.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000073",
        "Product Status": "Active"
    },
    {
        "Root": "10000939",
        "LV": "1",
        "Item Description": "CONCH FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000939001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000939",
        "Product Status": "Active"
    },
    {
        "Root": "10000939",
        "LV": "1",
        "Item Description": "CONCH FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000939001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "77-00405",
        "Last Price Paid": "$11.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000939",
        "Product Status": "Active"
    },
    {
        "Root": "10000939",
        "LV": "1",
        "Item Description": "CONCH FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000939001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6802410",
        "Last Price Paid": "$11.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000939",
        "Product Status": "Active"
    },
    {
        "Root": "10026538",
        "LV": "1",
        "Item Description": "VODKA 44 NORTH POTATO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9175300",
        "Last Price Paid": "$22.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026538",
        "Product Status": "Active"
    },
    {
        "Root": "10029807",
        "LV": "1",
        "Item Description": "PRETZEL BITE NUGGETS SALT 350/.4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029807001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6487464",
        "Last Price Paid": "$0.08",
        "Case Pack": "350",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029807",
        "Product Status": "Active"
    },
    {
        "Root": "10004635",
        "LV": "1",
        "Item Description": "PEPPER GREEK SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004635001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5252548",
        "Last Price Paid": "$10.05",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004635",
        "Product Status": "Active"
    },
    {
        "Root": "10008136",
        "LV": "1",
        "Item Description": "BLND CHAT PAPE GUIGAL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9128384",
        "Last Price Paid": "$42.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008136",
        "Product Status": "Active"
    },
    {
        "Root": "10012042",
        "LV": "4",
        "Item Description": "SHRIMP BREADED 21/25DC PEELED AND DEVEINED SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012042004004",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "7081090",
        "Last Price Paid": "$4.25",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10012042",
        "Product Status": "Active"
    },
    {
        "Root": "10045215",
        "LV": "1",
        "Item Description": "HERB ROSEMARY MINCED IQF 5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4249355",
        "Last Price Paid": "$37.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045215",
        "Product Status": "Active"
    },
    {
        "Root": "10000663",
        "LV": "1",
        "Item Description": "BEEF WAGYU JAPANEESE 100%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000663001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "HOLE STRIPLOIN",
        "Last Price Paid": "$74.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000663",
        "Product Status": "Active"
    },
    {
        "Root": "10001639",
        "LV": "1",
        "Item Description": "LETTUCE GREEN LEAF FLT 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001639",
        "Product Status": "Active"
    },
    {
        "Root": "10001639",
        "LV": "1",
        "Item Description": "LETTUCE GREEN LEAF FLT 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001639001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "CC24001LF",
        "Last Price Paid": "$99.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001639",
        "Product Status": "Active"
    },
    {
        "Root": "10002430",
        "LV": "2",
        "Item Description": "ICE CREAM CHOCOLATE CHIP EDY 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002430002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002430",
        "Product Status": "Active"
    },
    {
        "Root": "10036969",
        "LV": "1",
        "Item Description": "BLEND RED LA ATALAYA DEL CAMINO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10912",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036969",
        "Product Status": "Active"
    },
    {
        "Root": "10082877",
        "LV": "1",
        "Item Description": "RUM CRUZAN BANANA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "973275",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082877",
        "Product Status": "Active"
    },
    {
        "Root": "10031865",
        "LV": "1",
        "Item Description": "SAKE YUKIKAGE TOKUBETSU JUNMAI 300ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031865001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "679414",
        "Last Price Paid": "$12.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031865",
        "Product Status": "Active"
    },
    {
        "Root": "10027129",
        "LV": "1",
        "Item Description": "DECOR PEARLS POMEGRANATE BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "POMPOPP",
        "Last Price Paid": "$30.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027129",
        "Product Status": "Active"
    },
    {
        "Root": "10012246",
        "LV": "1",
        "Item Description": "ROOT CROP SMALL BITES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SBRC-33-C",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012246",
        "Product Status": "Active"
    },
    {
        "Root": "10086545",
        "LV": "1",
        "Item Description": "BOURBON BUFFALO TRACE CREAM 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086545001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9202450",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086545",
        "Product Status": "Active"
    },
    {
        "Root": "10091112",
        "LV": "1",
        "Item Description": "RICE BLACK NERONE ITALIAN RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6923569",
        "Last Price Paid": "$8.77",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091112",
        "Product Status": "Active"
    },
    {
        "Root": "10015368",
        "LV": "1",
        "Item Description": "DEC ROSE LG WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015368001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MKB WH",
        "Last Price Paid": "$2.42",
        "Case Pack": "28",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015368",
        "Product Status": "Active"
    },
    {
        "Root": "10107733",
        "LV": "1",
        "Item Description": "IMPOSSIBLE SPICE SAUSAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107733001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3000000023",
        "Last Price Paid": "$8.49",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107733",
        "Product Status": "Active"
    },
    {
        "Root": "10001549",
        "LV": "1",
        "Item Description": "GREEN MUSTARD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404065",
        "Last Price Paid": "$23.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001549",
        "Product Status": "Active"
    },
    {
        "Root": "10000345",
        "LV": "1",
        "Item Description": "POTATO RED B 6 WEDGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "06-008",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000345",
        "Product Status": "Active"
    },
    {
        "Root": "10004264",
        "LV": "1",
        "Item Description": "SPICE CHILI THREADS MILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004264001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490081",
        "Last Price Paid": "$7.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004264",
        "Product Status": "Active"
    },
    {
        "Root": "10094689",
        "LV": "1",
        "Item Description": "RANCH DRESSING KEN'S JALAPENO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.95",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094689",
        "Product Status": "Active"
    },
    {
        "Root": "10036248",
        "LV": "1",
        "Item Description": "SENSIPEARL SPARKLE SILVER 500 1KG BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036248001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000534944",
        "Vendor": "SENSIENT TECHNOLOGIES CORP FLAVORS",
        "Vendor Item Nb": "582341001",
        "Last Price Paid": "$35.79",
        "Case Pack": "2.204",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036248",
        "Product Status": "Active"
    },
    {
        "Root": "10041817",
        "LV": "1",
        "Item Description": "CHEESE SPREAD BOURSIN BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "25937",
        "Last Price Paid": "$27.90",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041817",
        "Product Status": "Active"
    },
    {
        "Root": "10000733",
        "LV": "1",
        "Item Description": "BEEF TENDER MEDALLION 3Z FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000733001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2719501",
        "Last Price Paid": "$15.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000733",
        "Product Status": "Active"
    },
    {
        "Root": "10036741",
        "LV": "1",
        "Item Description": "CHOCOLATE VEGAN WHITE CONFECTIONARY MASS BLANC 38%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036741001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7501410000",
        "Last Price Paid": "$163.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036741",
        "Product Status": "Active"
    },
    {
        "Root": "10002499",
        "LV": "1",
        "Item Description": "CORN BRENTWOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002499001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "523789",
        "Last Price Paid": "$65.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002499",
        "Product Status": "Active"
    },
    {
        "Root": "10034818",
        "LV": "1",
        "Item Description": "CHOC CHRISTMAS ORNAMENT 144/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034818001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1434",
        "Last Price Paid": "$0.42",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034818",
        "Product Status": "Active"
    },
    {
        "Root": "10039725",
        "LV": "1",
        "Item Description": "COASTER MIZNERS 4IN SQUARE PRINTED 2 SIDE 2500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51005742",
        "Last Price Paid": "$0.11",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039725",
        "Product Status": "Active"
    },
    {
        "Root": "10004739",
        "LV": "1",
        "Item Description": "MAYONNAISE HELLMAN 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004739001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6014278",
        "Last Price Paid": "$22.41",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004739",
        "Product Status": "Active"
    },
    {
        "Root": "10004539",
        "LV": "1",
        "Item Description": "JUICE PINEAPPLE 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004539001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9021866",
        "Last Price Paid": "$0.55",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004539",
        "Product Status": "Active"
    },
    {
        "Root": "10036536",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON RUTHERFORD FRANK FAMILY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9670351",
        "Last Price Paid": "$71.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036536",
        "Product Status": "Active"
    },
    {
        "Root": "10004038",
        "LV": "1",
        "Item Description": "CHOCOLATE WHITE COATING TRANS FAT FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004038001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "117894",
        "Last Price Paid": "$65.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004038",
        "Product Status": "Active"
    },
    {
        "Root": "10034414",
        "LV": "1",
        "Item Description": "GRAIN BULGUR WHEAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034414001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "163336",
        "Last Price Paid": "$1.31",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034414",
        "Product Status": "Active"
    },
    {
        "Root": "10038889",
        "LV": "1",
        "Item Description": "BOARD PASTRY CINDERELLA 4IN PO16303 .02 SBS C1S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45004940",
        "Last Price Paid": "$0.06",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038889",
        "Product Status": "Active"
    },
    {
        "Root": "10034761",
        "LV": "1",
        "Item Description": "TORTILLA CORN FLOUR GLUTEN FREE 6/12EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034761001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2716298",
        "Last Price Paid": "$0.61",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034761",
        "Product Status": "Active"
    },
    {
        "Root": "10026955",
        "LV": "1",
        "Item Description": "PLATE PAPER ROUND BROWN COMPOSTABLE 7 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.10",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026955",
        "Product Status": "Active"
    },
    {
        "Root": "10030066",
        "LV": "1",
        "Item Description": "CHEESE SHARP CHEDDAR SPREADABLE 4/5LB PLASTIC TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030066001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7672190",
        "Last Price Paid": "$24.37",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030066",
        "Product Status": "Active"
    },
    {
        "Root": "10014173",
        "LV": "2",
        "Item Description": "BOX GIFT 6 CT 2PC GIFT BOX WITH RIBBON PULL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014173002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510594",
        "Last Price Paid": "$1.86",
        "Case Pack": "97",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014173",
        "Product Status": "Active"
    },
    {
        "Root": "10037071",
        "LV": "1",
        "Item Description": "DRESSING VINAIGRETTE WHITE BALSAMIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6689262",
        "Last Price Paid": "$22.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037071",
        "Product Status": "Active"
    },
    {
        "Root": "10023575",
        "LV": "2",
        "Item Description": "GERARD BERTRAND COTES DE ROSES ROSE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023575001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570643",
        "Last Price Paid": "$11.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10023575",
        "Product Status": "Active"
    },
    {
        "Root": "10010179",
        "LV": "1",
        "Item Description": "WATER DASANI 20OZ 24EA PET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010179001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010179",
        "Product Status": "Active"
    },
    {
        "Root": "10002448",
        "LV": "2",
        "Item Description": "ICE CREAM VANILLA HDAZ 2.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002448002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002448",
        "Product Status": "Active"
    },
    {
        "Root": "10100270",
        "LV": "1",
        "Item Description": "KOSHER BSC CHEESE BLINTZES APPLESAUCE AND FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.40",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100270",
        "Product Status": "Active"
    },
    {
        "Root": "10094793",
        "LV": "1",
        "Item Description": "SALSA PICANTE VERDE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094793001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094793",
        "Product Status": "Active"
    },
    {
        "Root": "10003632",
        "LV": "1",
        "Item Description": "SOUP CHIC BROTH & VEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1327911",
        "Last Price Paid": "$16.32",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003632",
        "Product Status": "Active"
    },
    {
        "Root": "10039324",
        "LV": "1",
        "Item Description": "BEER GOOSE ISLAND IPA 12OZ BOTTLE 24 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21418",
        "Last Price Paid": "$1.54",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039324",
        "Product Status": "Active"
    },
    {
        "Root": "10004133",
        "LV": "1",
        "Item Description": "PASTA FRZ SHEET THIN 43CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070392",
        "Last Price Paid": "$0.85",
        "Case Pack": "43",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004133",
        "Product Status": "Active"
    },
    {
        "Root": "10001184",
        "LV": "1",
        "Item Description": "VEAL SWEETBREAD FROZEN SPECIAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001184001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1380068",
        "Last Price Paid": "$7.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001184",
        "Product Status": "Active"
    },
    {
        "Root": "10002460",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR IRISH 10 LB PRINT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002460001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "21",
        "Last Price Paid": "$8.30",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002460",
        "Product Status": "Active"
    },
    {
        "Root": "10003176",
        "LV": "2",
        "Item Description": "SAUCE PLUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003176002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6030639",
        "Last Price Paid": "$21.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003176",
        "Product Status": "Active"
    },
    {
        "Root": "10028129",
        "LV": "1",
        "Item Description": "TUNA YELLOWFIN SLICED SEARED SEASONED BONELSS SKIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9192185",
        "Last Price Paid": "$3.30",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028129",
        "Product Status": "Active"
    },
    {
        "Root": "10027522",
        "LV": "1",
        "Item Description": "AREPA STUFFED WITH CHEESE 1/2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027522001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069706",
        "Vendor": "C&B INTERNATIONAL TRADING",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027522",
        "Product Status": "Active"
    },
    {
        "Root": "10039827",
        "LV": "1",
        "Item Description": "ICE PRESSING CUBES 2.3 INCH 150/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609904",
        "Vendor": "ICE PROFESSIONALS OF FLORIDA LLC",
        "Vendor Item Nb": "WCBPR2-150",
        "Last Price Paid": "",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039827",
        "Product Status": "Active"
    },
    {
        "Root": "10007535",
        "LV": "2",
        "Item Description": "MONESTRELL TRIGA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007535001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "585308",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007535",
        "Product Status": "Active"
    },
    {
        "Root": "10036310",
        "LV": "2",
        "Item Description": "FLAXSEEDS BOB'S RED MILL GOLDEN WHOLE 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036310001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10036310",
        "Product Status": "Active"
    },
    {
        "Root": "10031357",
        "LV": "1",
        "Item Description": "TEA BAGS TWININGS LADY GREY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F05319",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031357",
        "Product Status": "Active"
    },
    {
        "Root": "10006663",
        "LV": "1",
        "Item Description": "CHOC WHITE CROWN LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006663001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1768",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006663",
        "Product Status": "Active"
    },
    {
        "Root": "10004810",
        "LV": "1",
        "Item Description": "SPICE PEPPER SHAKER DISP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004810001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6492672",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004810",
        "Product Status": "Active"
    },
    {
        "Root": "10018934",
        "LV": "1",
        "Item Description": "SHORTNING CAKE & ICING 50# CUBE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "110032832",
        "Last Price Paid": "$57.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018934",
        "Product Status": "Active"
    },
    {
        "Root": "10001406",
        "LV": "1",
        "Item Description": "SPINACH ROOT ICE 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001406001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04RISPI-26",
        "Last Price Paid": "$45.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001406",
        "Product Status": "Active"
    },
    {
        "Root": "10001799",
        "LV": "1",
        "Item Description": "ARUGULA MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450058",
        "Last Price Paid": "$19.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001799",
        "Product Status": "Active"
    },
    {
        "Root": "10001799",
        "LV": "1",
        "Item Description": "ARUGULA MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001799",
        "Product Status": "Active"
    },
    {
        "Root": "10040833",
        "LV": "1",
        "Item Description": "SHRIMP TIGER 9-11 CT HEAD ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040833001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZFISHSF009",
        "Last Price Paid": "$14.95",
        "Case Pack": "26.45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040833",
        "Product Status": "Active"
    },
    {
        "Root": "10040833",
        "LV": "1",
        "Item Description": "SHRIMP TIGER 9-11 CT HEAD ON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040833001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125411",
        "Last Price Paid": "$14.95",
        "Case Pack": "26.45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040833",
        "Product Status": "Active"
    },
    {
        "Root": "10037311",
        "LV": "1",
        "Item Description": "VEGANAISE ORIGINAL 1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037311001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "11128",
        "Last Price Paid": "$19.93",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037311",
        "Product Status": "Active"
    },
    {
        "Root": "10019272",
        "LV": "2",
        "Item Description": "SUGAR-STARS ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019272002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9713",
        "Last Price Paid": "$0.08",
        "Case Pack": "216",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10019272",
        "Product Status": "Active"
    },
    {
        "Root": "10003497",
        "LV": "2",
        "Item Description": "TEA EARL GRY FLWRY 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003497001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3502",
        "Last Price Paid": "$28.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003497",
        "Product Status": "Active"
    },
    {
        "Root": "10035217",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE AMETHYST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861690000",
        "Last Price Paid": "$13.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035217",
        "Product Status": "Active"
    },
    {
        "Root": "10110268",
        "LV": "1",
        "Item Description": "FLOUR HAZELNUT 25LB BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Z85551-BULK",
        "Last Price Paid": "$214.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110268",
        "Product Status": "Active"
    },
    {
        "Root": "10086299",
        "LV": "1",
        "Item Description": "DEC DISC 2IN WHITE AEP PRINT POLY 50 CELEBRATION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086299001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW20554",
        "Last Price Paid": "$0.60",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086299",
        "Product Status": "Active"
    },
    {
        "Root": "10001668",
        "LV": "1",
        "Item Description": "MANGO SLICES 4/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001668001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592035",
        "Last Price Paid": "$26.94",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001668",
        "Product Status": "Active"
    },
    {
        "Root": "10037905",
        "LV": "1",
        "Item Description": "BAR CHOC RICEMILK ENJOY 1.12Z 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037905001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F00702W",
        "Last Price Paid": "$1.49",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037905",
        "Product Status": "Active"
    },
    {
        "Root": "10004071",
        "LV": "1",
        "Item Description": "HUMMUS NONDAIRY W/PRZL CR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "654293",
        "Last Price Paid": "$2.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004071",
        "Product Status": "Active"
    },
    {
        "Root": "10037113",
        "LV": "1",
        "Item Description": "DRAGON FRUIT CUBED 2/10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037113001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2044052",
        "Last Price Paid": "$23.88",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037113",
        "Product Status": "Active"
    },
    {
        "Root": "10089683",
        "LV": "1",
        "Item Description": "ONION RING BEER BATTERED 5/8 INCH STK 4/2.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089683001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2029018",
        "Last Price Paid": "$10.22",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089683",
        "Product Status": "Active"
    },
    {
        "Root": "10003407",
        "LV": "1",
        "Item Description": "COFFEE NESPRESSO FINEZZO 300 CT PRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.57",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003407",
        "Product Status": "Active"
    },
    {
        "Root": "10010485",
        "LV": "2",
        "Item Description": "ASKA BOLGHERI ROSSO 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010485001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "216767",
        "Last Price Paid": "$22.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010485",
        "Product Status": "Active"
    },
    {
        "Root": "10010153",
        "LV": "2",
        "Item Description": "WATER VOSS STILL 375ML 24EA BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010153001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1484516",
        "Last Price Paid": "$1.05",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010153",
        "Product Status": "Active"
    },
    {
        "Root": "10027287",
        "LV": "2",
        "Item Description": "CHEESE SEMI HARD OAXACA 8/1 POUND PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027287002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1341",
        "Last Price Paid": "$6.78",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027287",
        "Product Status": "Active"
    },
    {
        "Root": "10000586",
        "LV": "1",
        "Item Description": "PEPPERONI SLICED LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000586001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "10043200150806",
        "Last Price Paid": "$41.16",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000586",
        "Product Status": "Active"
    },
    {
        "Root": "10006443",
        "LV": "1",
        "Item Description": "BAG ICE 20 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5908371",
        "Last Price Paid": "$0.15",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006443",
        "Product Status": "Active"
    },
    {
        "Root": "10104444",
        "LV": "1",
        "Item Description": "BAR MIXER FOAM FEE BROTHERS 5OZ/12PK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104444001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "202311",
        "Last Price Paid": "$3.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104444",
        "Product Status": "Active"
    },
    {
        "Root": "10005568",
        "LV": "1",
        "Item Description": "PANCAKE POTATO LEFSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.58",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005568",
        "Product Status": "Active"
    },
    {
        "Root": "10012728",
        "LV": "1",
        "Item Description": "LEAF KINOME 25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08KIN-24",
        "Last Price Paid": "$28.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012728",
        "Product Status": "Active"
    },
    {
        "Root": "10002711",
        "LV": "1",
        "Item Description": "MIX BROWNIE ENHANCED FUDGE 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002711001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$80.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002711",
        "Product Status": "Active"
    },
    {
        "Root": "10037904",
        "LV": "1",
        "Item Description": "BAR DARK CHOCOLATE ENJOY 1.12Z 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037904001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.68",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037904",
        "Product Status": "Active"
    },
    {
        "Root": "10031814",
        "LV": "1",
        "Item Description": "STOCK ROASTED CHICKEN REDUCTION 4X3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2528584",
        "Last Price Paid": "$33.17",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031814",
        "Product Status": "Active"
    },
    {
        "Root": "10023120",
        "LV": "1",
        "Item Description": "PUMPKIN 10-15 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482113",
        "Last Price Paid": "$8.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023120",
        "Product Status": "Active"
    },
    {
        "Root": "10031963",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP LIME 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031963001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D586-A6120",
        "Last Price Paid": "$17.74",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031963",
        "Product Status": "Active"
    },
    {
        "Root": "10000691",
        "LV": "1",
        "Item Description": "BEEF & PORK HOT DOG KOREAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000691001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "500",
        "Last Price Paid": "$4.41",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000691",
        "Product Status": "Active"
    },
    {
        "Root": "10008181",
        "LV": "2",
        "Item Description": "GAVI PRINCIPESSA BANFI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008181001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346734",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008181",
        "Product Status": "Active"
    },
    {
        "Root": "10094035",
        "LV": "1",
        "Item Description": "BOX POPCORN 3CUP  BETTER BUTTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094035001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "BBUTTERBX-3",
        "Last Price Paid": "$1.40",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094035",
        "Product Status": "Active"
    },
    {
        "Root": "10017408",
        "LV": "1",
        "Item Description": "CHIP PITA STACY'S NAKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "51113",
        "Last Price Paid": "$2.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017408",
        "Product Status": "Active"
    },
    {
        "Root": "10009393",
        "LV": "2",
        "Item Description": "ZINF TURLEY OLD VINE 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009393001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9171381",
        "Last Price Paid": "$22.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009393",
        "Product Status": "Active"
    },
    {
        "Root": "10002073",
        "LV": "1",
        "Item Description": "LETTUCE ENDIVE CURLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002073",
        "Product Status": "Active"
    },
    {
        "Root": "10002073",
        "LV": "1",
        "Item Description": "LETTUCE ENDIVE CURLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403002",
        "Last Price Paid": "$29.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002073",
        "Product Status": "Active"
    },
    {
        "Root": "10018511",
        "LV": "1",
        "Item Description": "JAR DISNEY SPRINGS LIGHT-UP MASON SIPPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018511001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "LIT845EA",
        "Last Price Paid": "$3.28",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018511",
        "Product Status": "Active"
    },
    {
        "Root": "10018009",
        "LV": "1",
        "Item Description": "PINOT NOIR FESS PARKER ASHLEYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9155308",
        "Last Price Paid": "$35.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018009",
        "Product Status": "Active"
    },
    {
        "Root": "10040821",
        "LV": "1",
        "Item Description": "COOKIE SUGAR MICKEY SHAPE 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "MM-CUS",
        "Last Price Paid": "$1.00",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040821",
        "Product Status": "Active"
    },
    {
        "Root": "10003532",
        "LV": "1",
        "Item Description": "COFFEE WHL BEAN FLY FSH 2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.35",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003532",
        "Product Status": "Active"
    },
    {
        "Root": "10102130",
        "LV": "1",
        "Item Description": "BEER HARD CIDER KEG STEM COCOA CARAMEL 1/2 BBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15477",
        "Last Price Paid": "$229.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102130",
        "Product Status": "Active"
    },
    {
        "Root": "10034458",
        "LV": "1",
        "Item Description": "CREAM VEGAN COOKING BASE LQ CTN GABLE TOP 4/8LB FZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7241932",
        "Last Price Paid": "$21.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034458",
        "Product Status": "Active"
    },
    {
        "Root": "10004952",
        "LV": "1",
        "Item Description": "PUREE GUAVA PINK 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "631...{BOIRON{",
        "Last Price Paid": "$11.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004952",
        "Product Status": "Active"
    },
    {
        "Root": "10036940",
        "LV": "2",
        "Item Description": "TILAPIA REGAL SPRINGS 5-7 SUSTAINABLE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036940002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6200300",
        "Last Price Paid": "$3.25",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036940",
        "Product Status": "Active"
    },
    {
        "Root": "10044730",
        "LV": "1",
        "Item Description": "SPICE CORIANDER GROUND 14OZ SHAKER BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760181",
        "Last Price Paid": "$10.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044730",
        "Product Status": "Active"
    },
    {
        "Root": "10028190",
        "LV": "1",
        "Item Description": "BEYOND MEAT CRUMBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103314",
        "Last Price Paid": "$6.92",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028190",
        "Product Status": "Active"
    },
    {
        "Root": "10000286",
        "LV": "1",
        "Item Description": "JICAMA 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489958",
        "Last Price Paid": "$12.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000286",
        "Product Status": "Active"
    },
    {
        "Root": "10007533",
        "LV": "2",
        "Item Description": "BLND RIDGE THREE VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007533001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547092",
        "Last Price Paid": "$19.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007533",
        "Product Status": "Active"
    },
    {
        "Root": "10034106",
        "LV": "1",
        "Item Description": "KEY LIME COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011122",
        "Last Price Paid": "$5.90",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034106",
        "Product Status": "Active"
    },
    {
        "Root": "10005860",
        "LV": "1",
        "Item Description": "PASTE PISTACHIO 13.2 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "D104 FABBRI",
        "Last Price Paid": "$158.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005860",
        "Product Status": "Active"
    },
    {
        "Root": "10003924",
        "LV": "1",
        "Item Description": "SB SYRUP CARAMEL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11108261",
        "Last Price Paid": "$10.66",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003924",
        "Product Status": "Active"
    },
    {
        "Root": "10035836",
        "LV": "1",
        "Item Description": "FUSION MIX VALENTINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22940",
        "Last Price Paid": "$6.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035836",
        "Product Status": "Active"
    },
    {
        "Root": "10007689",
        "LV": "2",
        "Item Description": "CAB SAUV LATOUR PAUILLAC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007689001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "683892",
        "Last Price Paid": "$115.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007689",
        "Product Status": "Active"
    },
    {
        "Root": "10005110",
        "LV": "2",
        "Item Description": "TEA TIN ENGLSH BRKFST 12/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005110002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3505M",
        "Last Price Paid": "$10.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005110",
        "Product Status": "Active"
    },
    {
        "Root": "10027270",
        "LV": "1",
        "Item Description": "SNACK HONEY ROASTED SESAME STICKS 6/7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891516",
        "Last Price Paid": "$2.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027270",
        "Product Status": "Active"
    },
    {
        "Root": "10020160",
        "LV": "1",
        "Item Description": "PASTE ALMOND RAW SPECIALITY XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020160001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PU001",
        "Last Price Paid": "$22.26",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020160",
        "Product Status": "Active"
    },
    {
        "Root": "10002852",
        "LV": "1",
        "Item Description": "MUSTARD IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.02",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002852",
        "Product Status": "Active"
    },
    {
        "Root": "10004397",
        "LV": "1",
        "Item Description": "SB TEAV EMPEROR CLOUD CS-",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004397001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11046312",
        "Last Price Paid": "$0.32",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004397",
        "Product Status": "Active"
    },
    {
        "Root": "10005006",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK NSA CALLEBAUT 5/10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "MALCHOC-D-101",
        "Last Price Paid": "$66.86",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005006",
        "Product Status": "Active"
    },
    {
        "Root": "10037938",
        "LV": "1",
        "Item Description": "CHIPS LENTIL SEA SALT 4Z 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037938001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F00801W",
        "Last Price Paid": "$2.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037938",
        "Product Status": "Active"
    },
    {
        "Root": "10007993",
        "LV": "1",
        "Item Description": "AMARONE CESARI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007993001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "212399",
        "Last Price Paid": "$32.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007993",
        "Product Status": "Active"
    },
    {
        "Root": "10088542",
        "LV": "2",
        "Item Description": "CHEESE IMPASTATA RICOTTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088542002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "44048",
        "Last Price Paid": "$3.88",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088542",
        "Product Status": "Active"
    },
    {
        "Root": "10008283",
        "LV": "1",
        "Item Description": "CAB SAUV JARVIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008283001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "977327",
        "Last Price Paid": "$157.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008283",
        "Product Status": "Active"
    },
    {
        "Root": "10001093",
        "LV": "1",
        "Item Description": "CLAM STRIPS BREADED SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001093001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "cla430201",
        "Last Price Paid": "$8.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001093",
        "Product Status": "Active"
    },
    {
        "Root": "10002732",
        "LV": "1",
        "Item Description": "PASTA DRY SPAGHETTI BARIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808039129",
        "Last Price Paid": "$9.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002732",
        "Product Status": "Active"
    },
    {
        "Root": "10044400",
        "LV": "1",
        "Item Description": "SPICE CINNAMON GROUND PLASTIC SHAKER 18OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5353115",
        "Last Price Paid": "$11.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044400",
        "Product Status": "Active"
    },
    {
        "Root": "10001561",
        "LV": "1",
        "Item Description": "PLANTAIN RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410503",
        "Last Price Paid": "$38.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001561",
        "Product Status": "Active"
    },
    {
        "Root": "10001561",
        "LV": "1",
        "Item Description": "PLANTAIN RIPE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16052",
        "Last Price Paid": "$38.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001561",
        "Product Status": "Active"
    },
    {
        "Root": "10012804",
        "LV": "1",
        "Item Description": "CHOC COMB CURL DARK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DR007D",
        "Last Price Paid": "$8.01",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012804",
        "Product Status": "Active"
    },
    {
        "Root": "10088222",
        "LV": "1",
        "Item Description": "COOKIE MACAROON ASSORTED 200/.39OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7867307",
        "Last Price Paid": "$0.48",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088222",
        "Product Status": "Active"
    },
    {
        "Root": "10018647",
        "LV": "1",
        "Item Description": "CAB,SAUV,KAIKEN,CORTE,750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018647001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2586543",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018647",
        "Product Status": "Active"
    },
    {
        "Root": "10102728",
        "LV": "1",
        "Item Description": "CHOCOLATE SANTA HAT SILHOUETTE 2-1/2 X 1-1/2\" WHT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW319705",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102728",
        "Product Status": "Active"
    },
    {
        "Root": "10093534",
        "LV": "1",
        "Item Description": "SPICE CINNAMON GROUND SRI LANKA 8OZ SUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093534001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CINN8-SL",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093534",
        "Product Status": "Active"
    },
    {
        "Root": "10000928",
        "LV": "1",
        "Item Description": "EGGS DUCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-80035",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000928",
        "Product Status": "Active"
    },
    {
        "Root": "10094948",
        "LV": "1",
        "Item Description": "CHOC WHITE BUZZ LIGHTYEAR WINGS 2 PC SET EA 3X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094948001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1635",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094948",
        "Product Status": "Active"
    },
    {
        "Root": "10035956",
        "LV": "1",
        "Item Description": "DRAFT SIXPOINT BENGALI 50 L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "101",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035956",
        "Product Status": "Active"
    },
    {
        "Root": "10039805",
        "LV": "1",
        "Item Description": "CHOC CHRISTMAS MINI  SET 2 140/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1405",
        "Last Price Paid": "",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039805",
        "Product Status": "Active"
    },
    {
        "Root": "10027424",
        "LV": "1",
        "Item Description": "CUP ESPNWWS BEER TANGERINE 22 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G22-TANGERINE",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027424",
        "Product Status": "Active"
    },
    {
        "Root": "10039802",
        "LV": "1",
        "Item Description": "CHOC CHRISMAS GIFTS SMALL 144/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039802001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1412",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039802",
        "Product Status": "Active"
    },
    {
        "Root": "10014265",
        "LV": "1",
        "Item Description": "COASTER HANGER RAVEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-028",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014265",
        "Product Status": "Active"
    },
    {
        "Root": "10088027",
        "LV": "1",
        "Item Description": "CHICKEN WOG SPLIT 1.20# - 1.375#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088027001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.65",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088027",
        "Product Status": "Active"
    },
    {
        "Root": "10001734",
        "LV": "1",
        "Item Description": "ONION PEARL WHITE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406090",
        "Last Price Paid": "$21.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001734",
        "Product Status": "Active"
    },
    {
        "Root": "10001734",
        "LV": "1",
        "Item Description": "ONION PEARL WHITE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001734",
        "Product Status": "Active"
    },
    {
        "Root": "10001734",
        "LV": "1",
        "Item Description": "ONION PEARL WHITE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001734001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "27040",
        "Last Price Paid": "$21.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001734",
        "Product Status": "Active"
    },
    {
        "Root": "10084172",
        "LV": "1",
        "Item Description": "LINER BASKET 12X12 LIGHT WEIGHT 1 COLOR 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084172001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51015081",
        "Last Price Paid": "$0.05",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084172",
        "Product Status": "Active"
    },
    {
        "Root": "10017526",
        "LV": "1",
        "Item Description": "BEET BABY MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450128",
        "Last Price Paid": "$1.96",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017526",
        "Product Status": "Active"
    },
    {
        "Root": "10004562",
        "LV": "1",
        "Item Description": "OLIVE RING RIPE SLCD #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004562001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7333479",
        "Last Price Paid": "$9.56",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004562",
        "Product Status": "Active"
    },
    {
        "Root": "10012492",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-PINK-1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22903",
        "Last Price Paid": "$0.23",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012492",
        "Product Status": "Active"
    },
    {
        "Root": "10010191",
        "LV": "1",
        "Item Description": "MIX BAR BLOODY MARY LTR 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5313036",
        "Last Price Paid": "$2.43",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010191",
        "Product Status": "Active"
    },
    {
        "Root": "10004558",
        "LV": "1",
        "Item Description": "PRESERVE STRAWBERRY #10 C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9327578",
        "Last Price Paid": "$22.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004558",
        "Product Status": "Active"
    },
    {
        "Root": "10040010",
        "LV": "1",
        "Item Description": "DRAFT GRAPEFRUIT LAGER SHINER RUBY REDBIRD 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040010001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "17246",
        "Last Price Paid": "$158.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040010",
        "Product Status": "Active"
    },
    {
        "Root": "10009630",
        "LV": "1",
        "Item Description": "VODKA ICEBERG LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009630001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "137673",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009630",
        "Product Status": "Active"
    },
    {
        "Root": "10039021",
        "LV": "1",
        "Item Description": "VEGETABLES STIRFRY 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039021001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "21-002",
        "Last Price Paid": "$2.40",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039021",
        "Product Status": "Active"
    },
    {
        "Root": "10000850",
        "LV": "1",
        "Item Description": "TURKEY NECKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000850001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.86",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000850",
        "Product Status": "Active"
    },
    {
        "Root": "10091302",
        "LV": "1",
        "Item Description": "WINE RTD RAMONA SPRITZ BLOOD ORANGE 250ML/48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9625538",
        "Last Price Paid": "$2.69",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091302",
        "Product Status": "Active"
    },
    {
        "Root": "10035388",
        "LV": "1",
        "Item Description": "CEREAL CINNAMON TOAST CRUNCH BULK PACK 4 45OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6013429",
        "Last Price Paid": "$15.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035388",
        "Product Status": "Active"
    },
    {
        "Root": "10010092",
        "LV": "2",
        "Item Description": "BEER HEINEKEN 12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010092001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010092",
        "Product Status": "Active"
    },
    {
        "Root": "10001542",
        "LV": "1",
        "Item Description": "BONIATO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482005",
        "Last Price Paid": "$39.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001542",
        "Product Status": "Active"
    },
    {
        "Root": "10021776",
        "LV": "1",
        "Item Description": "LID CUP PORTION 2 OZ CLR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021776001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11600310",
        "Last Price Paid": "$0.01",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021776",
        "Product Status": "Active"
    },
    {
        "Root": "10014138",
        "LV": "1",
        "Item Description": "PAPER TISSUE TURQ NOT FOR FOOD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014138001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52042079",
        "Last Price Paid": "$0.09",
        "Case Pack": "480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014138",
        "Product Status": "Active"
    },
    {
        "Root": "10028581",
        "LV": "1",
        "Item Description": "TEQUILA CUERVO REPO TRAD 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12589",
        "Last Price Paid": "$20.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028581",
        "Product Status": "Active"
    },
    {
        "Root": "10036073",
        "LV": "1",
        "Item Description": "PUREE FROZEN RASPBERRY 100% FRUIT 1 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036073001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6551080000",
        "Last Price Paid": "$1.04",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036073",
        "Product Status": "Active"
    },
    {
        "Root": "10031864",
        "LV": "1",
        "Item Description": "SAKE YUKI OTOKO HONJOZO 300ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "733982",
        "Last Price Paid": "$12.03",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031864",
        "Product Status": "Active"
    },
    {
        "Root": "10006644",
        "LV": "1",
        "Item Description": "GAS TANK RENTAL ANNUAL CH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006644001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$389.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006644",
        "Product Status": "Active"
    },
    {
        "Root": "10014660",
        "LV": "1",
        "Item Description": "GRITS WHITE STONE GROUND 8/3 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014660001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7958994",
        "Last Price Paid": "$6.42",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014660",
        "Product Status": "Active"
    },
    {
        "Root": "10040381",
        "LV": "1",
        "Item Description": "CHOCOLATE MILK NSA SF FELCHLIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "7500040000",
        "Last Price Paid": "$15.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040381",
        "Product Status": "Active"
    },
    {
        "Root": "10002150",
        "LV": "1",
        "Item Description": "BUTTER 83% UNSALTED 36/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.15",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002150",
        "Product Status": "Active"
    },
    {
        "Root": "10107488",
        "LV": "1",
        "Item Description": "BREAD PULLMAN WHITE SLCD LOAF IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.09",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107488",
        "Product Status": "Active"
    },
    {
        "Root": "10003305",
        "LV": "1",
        "Item Description": "COLOR BLACK LIQUID GEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22492",
        "Last Price Paid": "$5.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003305",
        "Product Status": "Active"
    },
    {
        "Root": "10003054",
        "LV": "1",
        "Item Description": "PASTA DRY FETTUCCINE BARI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808039136",
        "Last Price Paid": "$9.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003054",
        "Product Status": "Active"
    },
    {
        "Root": "10014981",
        "LV": "1",
        "Item Description": "CABERNET TEXT BOOK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "186169",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014981",
        "Product Status": "Active"
    },
    {
        "Root": "10014981",
        "LV": "1",
        "Item Description": "CABERNET TEXT BOOK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "186169",
        "Last Price Paid": "$25.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014981",
        "Product Status": "Active"
    },
    {
        "Root": "10014558",
        "LV": "1",
        "Item Description": "CANDY SEASONAL PEPPERMINT CRUSHED TWIST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014558001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "2054",
        "Last Price Paid": "$60.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014558",
        "Product Status": "Active"
    },
    {
        "Root": "10001559",
        "LV": "1",
        "Item Description": "PEPPER SCOTCH BONNET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407945",
        "Last Price Paid": "$30.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001559",
        "Product Status": "Active"
    },
    {
        "Root": "10040027",
        "LV": "1",
        "Item Description": "DRESSING RANCH VEGAN FYH 1 GALLON 4/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "40128",
        "Last Price Paid": "$26.81",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040027",
        "Product Status": "Active"
    },
    {
        "Root": "10094812",
        "LV": "1",
        "Item Description": "TEA TWININGS COLD INFUSE MANGO PASSION 22CT/4CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094812001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F16320",
        "Last Price Paid": "$5.72",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094812",
        "Product Status": "Active"
    },
    {
        "Root": "10106581",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER LAVENDER 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106581001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "314041E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106581",
        "Product Status": "Active"
    },
    {
        "Root": "10016587",
        "LV": "1",
        "Item Description": "COFFEE TIFFINS INDONESIA REG 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.78",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016587",
        "Product Status": "Active"
    },
    {
        "Root": "10037571",
        "LV": "2",
        "Item Description": "TRADITIONAL POTATO PANCAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037571002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5194907",
        "Last Price Paid": "$9.73",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037571",
        "Product Status": "Active"
    },
    {
        "Root": "10004871",
        "LV": "1",
        "Item Description": "SAUCE STIR FRY 4/.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8280182",
        "Last Price Paid": "$11.20",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004871",
        "Product Status": "Active"
    },
    {
        "Root": "10006453",
        "LV": "1",
        "Item Description": "BOARD CAKE SILVER 16 INCH X1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006453001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006453",
        "Product Status": "Active"
    },
    {
        "Root": "10016320",
        "LV": "1",
        "Item Description": "CUPCAKE MINI YELL UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016320001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1630",
        "Last Price Paid": "$0.28",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016320",
        "Product Status": "Active"
    },
    {
        "Root": "10001967",
        "LV": "1",
        "Item Description": "LEMON GRASS MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MLG-33-E",
        "Last Price Paid": "$48.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001967",
        "Product Status": "Active"
    },
    {
        "Root": "10030378",
        "LV": "1",
        "Item Description": "CANDY REESES PIECES MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "530660",
        "Last Price Paid": "$133.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030378",
        "Product Status": "Active"
    },
    {
        "Root": "10019688",
        "LV": "1",
        "Item Description": "FRENCH TST STICK MAPLE WHL GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8862096",
        "Last Price Paid": "$0.56",
        "Case Pack": "85",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019688",
        "Product Status": "Active"
    },
    {
        "Root": "10027853",
        "LV": "2",
        "Item Description": "TEQUILA OLMECA ALTOS REPOSADO 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027853002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "343830",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027853",
        "Product Status": "Active"
    },
    {
        "Root": "10010892",
        "LV": "2",
        "Item Description": "DRAFT KONA BIG WAVE GLD ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010892001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "24102",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010892",
        "Product Status": "Active"
    },
    {
        "Root": "10010892",
        "LV": "2",
        "Item Description": "DRAFT KONA BIG WAVE GLD ALE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010892001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "24102",
        "Last Price Paid": "$157.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010892",
        "Product Status": "Active"
    },
    {
        "Root": "10004978",
        "LV": "1",
        "Item Description": "PUREE PINEAPPLE 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "523...{BOIRON{",
        "Last Price Paid": "$13.06",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004978",
        "Product Status": "Active"
    },
    {
        "Root": "10010676",
        "LV": "1",
        "Item Description": "RUM LEBLON CACHACA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "483946",
        "Last Price Paid": "$20.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010676",
        "Product Status": "Active"
    },
    {
        "Root": "10010676",
        "LV": "1",
        "Item Description": "RUM LEBLON CACHACA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "483946",
        "Last Price Paid": "$20.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010676",
        "Product Status": "Active"
    },
    {
        "Root": "10007674",
        "LV": "2",
        "Item Description": "BAROLO GAJA DAGROMIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007674001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9684257",
        "Last Price Paid": "$87.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007674",
        "Product Status": "Active"
    },
    {
        "Root": "10102704",
        "LV": "1",
        "Item Description": "BAR DK CHOCOLATE PEANUT BTR BANANA CLIF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302453",
        "Last Price Paid": "$1.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102704",
        "Product Status": "Active"
    },
    {
        "Root": "10001677",
        "LV": "1",
        "Item Description": "EGGPLANT LARGE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001677",
        "Product Status": "Active"
    },
    {
        "Root": "10001677",
        "LV": "1",
        "Item Description": "EGGPLANT LARGE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20440",
        "Last Price Paid": "$13.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001677",
        "Product Status": "Active"
    },
    {
        "Root": "10001677",
        "LV": "1",
        "Item Description": "EGGPLANT LARGE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480058",
        "Last Price Paid": "$13.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001677",
        "Product Status": "Active"
    },
    {
        "Root": "10040081",
        "LV": "2",
        "Item Description": "ICE CREAM COOKIE DOUGH THSE 8/14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040081002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12331014",
        "Last Price Paid": "$1.58",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040081",
        "Product Status": "Active"
    },
    {
        "Root": "10040081",
        "LV": "2",
        "Item Description": "ICE CREAM COOKIE DOUGH THSE 8/14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040081002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.58",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040081",
        "Product Status": "Active"
    },
    {
        "Root": "10107448",
        "LV": "1",
        "Item Description": "CHEESE STRACCIATELLA 6/5.29 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "35805",
        "Last Price Paid": "$4.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107448",
        "Product Status": "Active"
    },
    {
        "Root": "10028416",
        "LV": "1",
        "Item Description": "BISCUIT HAM EGG CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "79280",
        "Last Price Paid": "$2.74",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028416",
        "Product Status": "Active"
    },
    {
        "Root": "10000335",
        "LV": "1",
        "Item Description": "CELERY DICED1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "05-003-14",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000335",
        "Product Status": "Active"
    },
    {
        "Root": "10000335",
        "LV": "1",
        "Item Description": "CELERY DICED1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "443422",
        "Last Price Paid": "$7.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000335",
        "Product Status": "Active"
    },
    {
        "Root": "10107930",
        "LV": "1",
        "Item Description": "SAUCE EEL 6/60 OZ.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35105",
        "Last Price Paid": "$20.85",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107930",
        "Product Status": "Active"
    },
    {
        "Root": "10009451",
        "LV": "1",
        "Item Description": "SCOTCH CHIVAS 12YR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "19075",
        "Last Price Paid": "$44.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009451",
        "Product Status": "Active"
    },
    {
        "Root": "10001240",
        "LV": "1",
        "Item Description": "VEAL STRIP LN W/TEND S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001240001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1346211",
        "Last Price Paid": "$17.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001240",
        "Product Status": "Active"
    },
    {
        "Root": "10082053",
        "LV": "1",
        "Item Description": "WINE SPARK SULA TROPICALE BRUT 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082053001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "221804",
        "Last Price Paid": "$13.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082053",
        "Product Status": "Active"
    },
    {
        "Root": "10040083",
        "LV": "1",
        "Item Description": "ICE CREAM KIT KAT BAR 14OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "12358215",
        "Last Price Paid": "$1.86",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040083",
        "Product Status": "Active"
    },
    {
        "Root": "10041014",
        "LV": "1",
        "Item Description": "BUTTER SPREAD NON DAIRY SOY FREE EARTH BALANCE 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041014001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "003377604115",
        "Last Price Paid": "$91.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041014",
        "Product Status": "Active"
    },
    {
        "Root": "10088607",
        "LV": "1",
        "Item Description": "SYRUP PETRICHOR TYPE NAT W/ COLOR 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000534944",
        "Vendor": "SENSIENT TECHNOLOGIES CORP FLAVORS",
        "Vendor Item Nb": "SN3000004423",
        "Last Price Paid": "$279.99",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088607",
        "Product Status": "Active"
    },
    {
        "Root": "10104174",
        "LV": "1",
        "Item Description": "BEER DRAFT 81 BAY GREEN W/ENVY 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15805",
        "Last Price Paid": "$192.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104174",
        "Product Status": "Active"
    },
    {
        "Root": "10001754",
        "LV": "1",
        "Item Description": "CUCUMBER PICKLING SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480398",
        "Last Price Paid": "$34.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001754",
        "Product Status": "Active"
    },
    {
        "Root": "10001754",
        "LV": "1",
        "Item Description": "CUCUMBER PICKLING SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001754",
        "Product Status": "Active"
    },
    {
        "Root": "10016032",
        "LV": "1",
        "Item Description": "KALE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489769",
        "Last Price Paid": "$8.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016032",
        "Product Status": "Active"
    },
    {
        "Root": "10016032",
        "LV": "1",
        "Item Description": "KALE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21105",
        "Last Price Paid": "$8.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016032",
        "Product Status": "Active"
    },
    {
        "Root": "10107128",
        "LV": "1",
        "Item Description": "PORT TAYLOR FLADGATE VVOP 750ML/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "309773",
        "Last Price Paid": "$590.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107128",
        "Product Status": "Active"
    },
    {
        "Root": "10035335",
        "LV": "1",
        "Item Description": "CALLA LILY LARGE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035335001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CLB6 WH",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035335",
        "Product Status": "Active"
    },
    {
        "Root": "10006920",
        "LV": "1",
        "Item Description": "GEWURTZ TRIMBACH ALSACE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006920001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "912365",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006920",
        "Product Status": "Active"
    },
    {
        "Root": "10027566",
        "LV": "1",
        "Item Description": "TEA TWININGS GREEN POM STRAW RASP REG BAG 20EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F11354",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027566",
        "Product Status": "Active"
    },
    {
        "Root": "10035410",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON BOND MELBURY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "208915",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035410",
        "Product Status": "Active"
    },
    {
        "Root": "10016262",
        "LV": "1",
        "Item Description": "PIE CHERRY 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016262001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "838",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016262",
        "Product Status": "Active"
    },
    {
        "Root": "10040935",
        "LV": "1",
        "Item Description": "SELTZER HARD BUD LIGHT STRAWBERRY 16OZ CAN 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10436",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040935",
        "Product Status": "Active"
    },
    {
        "Root": "10028309",
        "LV": "1",
        "Item Description": "WINE BOVE VOUVRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028309",
        "Product Status": "Active"
    },
    {
        "Root": "10101650",
        "LV": "1",
        "Item Description": "COGNAC HARDY LEGEND 1863 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "28262",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101650",
        "Product Status": "Active"
    },
    {
        "Root": "10039742",
        "LV": "1",
        "Item Description": "COOKIE SUGAR 3IN RND WH FONDANT CUSTOM PRINT 25/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039742001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "3\"CUST",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039742",
        "Product Status": "Active"
    },
    {
        "Root": "10031786",
        "LV": "1",
        "Item Description": "CHARTREUSE GREEN 110",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "31660",
        "Last Price Paid": "$49.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031786",
        "Product Status": "Active"
    },
    {
        "Root": "10088032",
        "LV": "1",
        "Item Description": "FLOUR TAPIOCA 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6373407",
        "Last Price Paid": "$61.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088032",
        "Product Status": "Active"
    },
    {
        "Root": "10001180",
        "LV": "1",
        "Item Description": "BEEF FLATIRON STEAK 8Z CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001180001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190068",
        "Last Price Paid": "$21.14",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001180",
        "Product Status": "Active"
    },
    {
        "Root": "10011502",
        "LV": "1",
        "Item Description": "MIX CHAI LATTE 6/8 COUNT 1.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.62",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011502",
        "Product Status": "Active"
    },
    {
        "Root": "10095435",
        "LV": "1",
        "Item Description": "CAVA LOS DOS 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "169483",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095435",
        "Product Status": "Active"
    },
    {
        "Root": "10095435",
        "LV": "1",
        "Item Description": "CAVA LOS DOS 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "169483",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095435",
        "Product Status": "Active"
    },
    {
        "Root": "10034127",
        "LV": "2",
        "Item Description": "POWERADE ZERO MOUNTAIN BLAST 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034127002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "130471",
        "Last Price Paid": "$1.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034127",
        "Product Status": "Active"
    },
    {
        "Root": "10010216",
        "LV": "1",
        "Item Description": "DRAFT EINSTOK WHT ALE 30L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "924282",
        "Last Price Paid": "$125.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010216",
        "Product Status": "Active"
    },
    {
        "Root": "10016743",
        "LV": "1",
        "Item Description": "PORT RIEBEEK RUBY CELLAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60067550",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016743",
        "Product Status": "Active"
    },
    {
        "Root": "10014354",
        "LV": "1",
        "Item Description": "TURNOVER GUAVA MINI 2.5 INCH 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.68",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014354",
        "Product Status": "Active"
    },
    {
        "Root": "10036970",
        "LV": "1",
        "Item Description": "BLEND RED SCALA DEI PRIORAT CRIANZA PRIOR 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036970001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "265906",
        "Last Price Paid": "$18.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036970",
        "Product Status": "Active"
    },
    {
        "Root": "10026615",
        "LV": "1",
        "Item Description": "BOX PIZZA FLATBREAD FIBER PB SC U13",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51400021",
        "Last Price Paid": "$0.44",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026615",
        "Product Status": "Active"
    },
    {
        "Root": "10003482",
        "LV": "1",
        "Item Description": "SAUCE CHAR SIU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9529447",
        "Last Price Paid": "$4.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003482",
        "Product Status": "Active"
    },
    {
        "Root": "10003482",
        "LV": "1",
        "Item Description": "SAUCE CHAR SIU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "9529447",
        "Last Price Paid": "$4.15",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003482",
        "Product Status": "Active"
    },
    {
        "Root": "10006432",
        "LV": "2",
        "Item Description": "PUMP MONIN GLASS BTL 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006432001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-9230",
        "Last Price Paid": "$3.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006432",
        "Product Status": "Active"
    },
    {
        "Root": "10021957",
        "LV": "1",
        "Item Description": "CHENIN BLNC RAATS OLD VINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "966658",
        "Last Price Paid": "$18.83",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021957",
        "Product Status": "Active"
    },
    {
        "Root": "10016705",
        "LV": "1",
        "Item Description": "SEAL PAPER CLR 1\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016705001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45864141",
        "Last Price Paid": "$0.00",
        "Case Pack": "50000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016705",
        "Product Status": "Active"
    },
    {
        "Root": "10013088",
        "LV": "2",
        "Item Description": "SHELL TART SMALL RND CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013088002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LRN8090422X",
        "Last Price Paid": "$0.48",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013088",
        "Product Status": "Active"
    },
    {
        "Root": "10091441",
        "LV": "1",
        "Item Description": "CUP MILK SWGE 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091441001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "G-16 311206",
        "Last Price Paid": "$0.51",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091441",
        "Product Status": "Active"
    },
    {
        "Root": "10002087",
        "LV": "1",
        "Item Description": "PEPPER THAI CHILI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002087001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002087",
        "Product Status": "Active"
    },
    {
        "Root": "10000159",
        "LV": "1",
        "Item Description": "SCALLOP SEA WORKHORSE N. ATLANTIC 10/20 IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000159001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6456700",
        "Last Price Paid": "$16.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000159",
        "Product Status": "Active"
    },
    {
        "Root": "10034651",
        "LV": "1",
        "Item Description": "GREEN FINGER GRAPES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034651001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260105",
        "Last Price Paid": "$6.29",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034651",
        "Product Status": "Active"
    },
    {
        "Root": "10003063",
        "LV": "4",
        "Item Description": "PASTA DRY PENNE WHL GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003063004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "76808011722",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10003063",
        "Product Status": "Active"
    },
    {
        "Root": "10003063",
        "LV": "4",
        "Item Description": "PASTA DRY PENNE WHL GRN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003063004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10003063",
        "Product Status": "Active"
    },
    {
        "Root": "10105430",
        "LV": "1",
        "Item Description": "TRANSFER SHEET TANO 3.5X9.2CM GOLD,2000PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175330000",
        "Last Price Paid": "$0.13",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105430",
        "Product Status": "Active"
    },
    {
        "Root": "10044826",
        "LV": "1",
        "Item Description": "BUN HOT DOG 10IN SLICED FOOTLONG 64/2.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9145202",
        "Last Price Paid": "$0.60",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044826",
        "Product Status": "Active"
    },
    {
        "Root": "10008216",
        "LV": "2",
        "Item Description": "CAB SAUV GROTH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008216001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9670503",
        "Last Price Paid": "$38.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008216",
        "Product Status": "Active"
    },
    {
        "Root": "10041060",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED GOAT TRUFFLE TREMOR 3LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041060001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "90039",
        "Last Price Paid": "$21.11",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041060",
        "Product Status": "Active"
    },
    {
        "Root": "10036231",
        "LV": "1",
        "Item Description": "PRESERVES STRAWBERRY DICKONSON'S GLASS JAR ROOM S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1060078",
        "Last Price Paid": "$0.48",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036231",
        "Product Status": "Active"
    },
    {
        "Root": "10001846",
        "LV": "1",
        "Item Description": "ENDIVE BELGIAN RED 8# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403020",
        "Last Price Paid": "$56.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001846",
        "Product Status": "Active"
    },
    {
        "Root": "10001846",
        "LV": "1",
        "Item Description": "ENDIVE BELGIAN RED 8# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETENDBELRD",
        "Last Price Paid": "$56.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001846",
        "Product Status": "Active"
    },
    {
        "Root": "10085510",
        "LV": "1",
        "Item Description": "BREAD FRENCH BOWL BLACK 10Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "2304",
        "Last Price Paid": "$1.89",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085510",
        "Product Status": "Active"
    },
    {
        "Root": "10009956",
        "LV": "1",
        "Item Description": "LIQ BRANDY CHERRY BOLS LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9001683",
        "Last Price Paid": "$8.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009956",
        "Product Status": "Active"
    },
    {
        "Root": "10031065",
        "LV": "2",
        "Item Description": "COCKTAIL CUTWATER SPICY BLOODY MARY 12 OZ CAN/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031065001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99421",
        "Last Price Paid": "$1.95",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10031065",
        "Product Status": "Active"
    },
    {
        "Root": "10038214",
        "LV": "1",
        "Item Description": "CHARDONNAY CHAPPELET CALESA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038214001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "220074",
        "Last Price Paid": "$38.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038214",
        "Product Status": "Active"
    },
    {
        "Root": "10010230",
        "LV": "2",
        "Item Description": "DRAFT ABITA TURBO DOG HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010230001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14162",
        "Last Price Paid": "$170.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010230",
        "Product Status": "Active"
    },
    {
        "Root": "10026979",
        "LV": "1",
        "Item Description": "COFFEE FRENCH ROAST DECAF BEAN 2# FOIL BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026979001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "C131B-30B",
        "Last Price Paid": "$11.24",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026979",
        "Product Status": "Active"
    },
    {
        "Root": "10005756",
        "LV": "1",
        "Item Description": "BAGEL CINNAMON RAISIN 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005756",
        "Product Status": "Active"
    },
    {
        "Root": "10030331",
        "LV": "1",
        "Item Description": "CANDY TOPPING YOGURT CHIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "525271",
        "Last Price Paid": "$59.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030331",
        "Product Status": "Active"
    },
    {
        "Root": "10015147",
        "LV": "1",
        "Item Description": "BERRY JUNIPER DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FD040-1",
        "Last Price Paid": "$19.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015147",
        "Product Status": "Active"
    },
    {
        "Root": "10031466",
        "LV": "1",
        "Item Description": "COBIA FILLET SKON FARMED OPEN BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031466001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6481702",
        "Last Price Paid": "$14.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031466",
        "Product Status": "Active"
    },
    {
        "Root": "10031466",
        "LV": "1",
        "Item Description": "COBIA FILLET SKON FARMED OPEN BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031466001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00061",
        "Last Price Paid": "$14.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031466",
        "Product Status": "Active"
    },
    {
        "Root": "10003878",
        "LV": "1",
        "Item Description": "HONEY ORNGE BLOS PURE 6LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9342080",
        "Last Price Paid": "$13.57",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003878",
        "Product Status": "Active"
    },
    {
        "Root": "10019246",
        "LV": "1",
        "Item Description": "SAUVIGNON BLANC MOHUA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019246001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9098039",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019246",
        "Product Status": "Active"
    },
    {
        "Root": "10002830",
        "LV": "2",
        "Item Description": "MIX DOLE WHIP PINEAPPLE 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002830002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002830",
        "Product Status": "Active"
    },
    {
        "Root": "10009950",
        "LV": "1",
        "Item Description": "LIQ DRAMBUIE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "92007",
        "Last Price Paid": "$42.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009950",
        "Product Status": "Active"
    },
    {
        "Root": "10009950",
        "LV": "1",
        "Item Description": "LIQ DRAMBUIE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "92007",
        "Last Price Paid": "$42.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009950",
        "Product Status": "Active"
    },
    {
        "Root": "10108789",
        "LV": "1",
        "Item Description": "CANDY MINI M&M RED & GREEN 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "52154",
        "Last Price Paid": "$158.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108789",
        "Product Status": "Active"
    },
    {
        "Root": "10010772",
        "LV": "1",
        "Item Description": "LIQ PAVAN DE FRANCE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "328874",
        "Last Price Paid": "$21.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010772",
        "Product Status": "Active"
    },
    {
        "Root": "10033347",
        "LV": "1",
        "Item Description": "FUSION MIX-ST PATRICKS DAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033347001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22941",
        "Last Price Paid": "$6.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033347",
        "Product Status": "Active"
    },
    {
        "Root": "10011364",
        "LV": "1",
        "Item Description": "EGG SUBSTITUTE LIQUID FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "718429",
        "Last Price Paid": "$5.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011364",
        "Product Status": "Active"
    },
    {
        "Root": "10026715",
        "LV": "1",
        "Item Description": "BAG PAPER KRAFT WITH HANDLES 12.8X9X15.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091114",
        "Last Price Paid": "$0.28",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026715",
        "Product Status": "Active"
    },
    {
        "Root": "10036223",
        "LV": "1",
        "Item Description": "COCOA BUTTER CRIMSON RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302110C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036223",
        "Product Status": "Active"
    },
    {
        "Root": "10101133",
        "LV": "1",
        "Item Description": "ALE CIDER CAN KOPPARBERG TROPICAL FRUIT 11.2OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76086",
        "Last Price Paid": "$1.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101133",
        "Product Status": "Active"
    },
    {
        "Root": "10006606",
        "LV": "1",
        "Item Description": "DOILY PAPER 5 INCH LACE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006606001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4435392",
        "Last Price Paid": "$0.01",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006606",
        "Product Status": "Active"
    },
    {
        "Root": "10034455",
        "LV": "2",
        "Item Description": "MERLOT TRIG POINT DIAMOND DUST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034455002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "588868",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034455",
        "Product Status": "Active"
    },
    {
        "Root": "10001636",
        "LV": "1",
        "Item Description": "TOMATO ROASTED YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598796",
        "Last Price Paid": "$19.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001636",
        "Product Status": "Active"
    },
    {
        "Root": "10025567",
        "LV": "2",
        "Item Description": "SYRUP SCRAPPY LAVENDER 148 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025567001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "718516",
        "Last Price Paid": "$14.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10025567",
        "Product Status": "Active"
    },
    {
        "Root": "10002001",
        "LV": "1",
        "Item Description": "RADISH PURPLE MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04MPRA-33",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002001",
        "Product Status": "Active"
    },
    {
        "Root": "10103331",
        "LV": "1",
        "Item Description": "INK PC-CTRG-MAGENTA-EPSON U SERIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28007",
        "Last Price Paid": "$17.82",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103331",
        "Product Status": "Active"
    },
    {
        "Root": "10000775",
        "LV": "1",
        "Item Description": "SAUSAGE ITALIAN BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3601322",
        "Last Price Paid": "$32.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000775",
        "Product Status": "Active"
    },
    {
        "Root": "10001182",
        "LV": "1",
        "Item Description": "VENISON STRP LN FULL TTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001182001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4502104",
        "Last Price Paid": "$17.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001182",
        "Product Status": "Active"
    },
    {
        "Root": "10017443",
        "LV": "1",
        "Item Description": "RED BLEND ERNIE ELS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "966656",
        "Last Price Paid": "$12.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017443",
        "Product Status": "Active"
    },
    {
        "Root": "10005159",
        "LV": "1",
        "Item Description": "CHOC DOMINO TRIANGLE 576P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005159001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.13",
        "Case Pack": "576",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005159",
        "Product Status": "Active"
    },
    {
        "Root": "10022329",
        "LV": "1",
        "Item Description": "HERB OXALIS BLOOM 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14OXB-33",
        "Last Price Paid": "$28.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022329",
        "Product Status": "Active"
    },
    {
        "Root": "10040374",
        "LV": "1",
        "Item Description": "COOKIE GINGERBREAD LARGE TOTEM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040374001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "GB-LT",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040374",
        "Product Status": "Active"
    },
    {
        "Root": "10006327",
        "LV": "1",
        "Item Description": "LINER BAKERY 4.5 INCH X4.25 INCH W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2416717",
        "Last Price Paid": "",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006327",
        "Product Status": "Active"
    },
    {
        "Root": "10041049",
        "LV": "1",
        "Item Description": "SALAMI SALAMETTO PICCANTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041049001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5220",
        "Last Price Paid": "",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041049",
        "Product Status": "Active"
    },
    {
        "Root": "10004625",
        "LV": "1",
        "Item Description": "PIEROGI POT/CHS MINI .45O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004625001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1199231",
        "Last Price Paid": "",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004625",
        "Product Status": "Active"
    },
    {
        "Root": "10038473",
        "LV": "1",
        "Item Description": "THE WITHERS MR BURGESS SYRAH 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038473001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "000435",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038473",
        "Product Status": "Active"
    },
    {
        "Root": "10000866",
        "LV": "1",
        "Item Description": "HEN CORNISH 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000866001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4228618",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000866",
        "Product Status": "Active"
    },
    {
        "Root": "10020115",
        "LV": "1",
        "Item Description": "DRAFT ALE KILKENNY IRISH CRM 13.2 GAL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020115001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "14109",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020115",
        "Product Status": "Active"
    },
    {
        "Root": "10092217",
        "LV": "1",
        "Item Description": "TUNA VEGAN ROLL WORTHINGTON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "WF3323",
        "Last Price Paid": "$4.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092217",
        "Product Status": "Active"
    },
    {
        "Root": "10107688",
        "LV": "1",
        "Item Description": "FROZEN ORANGE DIPPIN DOTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068041",
        "Vendor": "J&J SNACK FOODS CORP",
        "Vendor Item Nb": "R ORANGE",
        "Last Price Paid": "$14.00",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107688",
        "Product Status": "Active"
    },
    {
        "Root": "10014468",
        "LV": "1",
        "Item Description": "POWDER CURRY MADRAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "83572",
        "Last Price Paid": "$15.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014468",
        "Product Status": "Active"
    },
    {
        "Root": "10000224",
        "LV": "1",
        "Item Description": "HUMMUS CUP GT CUC CAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000224001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481768",
        "Last Price Paid": "$1.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000224",
        "Product Status": "Active"
    },
    {
        "Root": "10107790",
        "LV": "1",
        "Item Description": "CUP CAN 12OZ \"BREW-WING\" F&W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "1100103000",
        "Last Price Paid": "$0.96",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107790",
        "Product Status": "Active"
    },
    {
        "Root": "10014049",
        "LV": "1",
        "Item Description": "CHOC CHIP SEMI SWT 1000 CT HERSHEYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014049001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5004940",
        "Last Price Paid": "$66.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014049",
        "Product Status": "Active"
    },
    {
        "Root": "10006196",
        "LV": "1",
        "Item Description": "PLATE 4.7X1.6  CP133 GOLD RECTANGULAR SLICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391066",
        "Last Price Paid": "$0.17",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006196",
        "Product Status": "Active"
    },
    {
        "Root": "10040890",
        "LV": "1",
        "Item Description": "BOX CHARCUTERIE CIRQUE COMBO PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51010276",
        "Last Price Paid": "$2.95",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040890",
        "Product Status": "Active"
    },
    {
        "Root": "10105135",
        "LV": "1",
        "Item Description": "CINNAMON CARAMEL SYRUP 1 LITER 6-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011143570",
        "Last Price Paid": "$17.78",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105135",
        "Product Status": "Active"
    },
    {
        "Root": "10002014",
        "LV": "1",
        "Item Description": "MUSHROOM CLAMSHELL BROWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002014001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50105",
        "Last Price Paid": "$9.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002014",
        "Product Status": "Active"
    },
    {
        "Root": "10002014",
        "LV": "1",
        "Item Description": "MUSHROOM CLAMSHELL BROWN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002014001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002014",
        "Product Status": "Active"
    },
    {
        "Root": "10000059",
        "LV": "1",
        "Item Description": "BEEF TOP SIRLOIN FILET 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000059001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190135",
        "Last Price Paid": "$13.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000059",
        "Product Status": "Active"
    },
    {
        "Root": "10014922",
        "LV": "1",
        "Item Description": "BRONZINI 800-1000GR WHOLE (LOUP DE MER) SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014922001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "64-95708",
        "Last Price Paid": "$10.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014922",
        "Product Status": "Active"
    },
    {
        "Root": "10014922",
        "LV": "1",
        "Item Description": "BRONZINI 800-1000GR WHOLE (LOUP DE MER) SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014922001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00537",
        "Last Price Paid": "$10.43",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014922",
        "Product Status": "Active"
    },
    {
        "Root": "10004211",
        "LV": "1",
        "Item Description": "DECOR PEARLS STRAWBERRY BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004211001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "STR",
        "Last Price Paid": "$24.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004211",
        "Product Status": "Active"
    },
    {
        "Root": "10005823",
        "LV": "1",
        "Item Description": "SHELL PIE 5 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005823001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5525241",
        "Last Price Paid": "$0.66",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005823",
        "Product Status": "Active"
    },
    {
        "Root": "10102697",
        "LV": "1",
        "Item Description": "SPICE SALT PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102697001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.39",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102697",
        "Product Status": "Active"
    },
    {
        "Root": "10013268",
        "LV": "1",
        "Item Description": "CARROTS YOUNG MIXED 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013268001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01YMCT-26",
        "Last Price Paid": "$88.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013268",
        "Product Status": "Active"
    },
    {
        "Root": "10029602",
        "LV": "1",
        "Item Description": "WINE RED GAMAY CH AVIRON MOULIN A VENT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "547397",
        "Last Price Paid": "$21.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029602",
        "Product Status": "Active"
    },
    {
        "Root": "10004161",
        "LV": "2",
        "Item Description": "SB CHRISTMAS BLND 5LB BUL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004161002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7387",
        "Last Price Paid": "$57.80",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004161",
        "Product Status": "Active"
    },
    {
        "Root": "10001141",
        "LV": "3",
        "Item Description": "NOBASHI EBI MANEKI 16/20 SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001141003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "61-25399",
        "Last Price Paid": "$35.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10001141",
        "Product Status": "Active"
    },
    {
        "Root": "10001871",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001871001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001871",
        "Product Status": "Active"
    },
    {
        "Root": "10001871",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001871001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20638",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001871",
        "Product Status": "Active"
    },
    {
        "Root": "10005570",
        "LV": "1",
        "Item Description": "PRETZEL SOFT FROZEN 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2011278",
        "Last Price Paid": "$0.52",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005570",
        "Product Status": "Active"
    },
    {
        "Root": "10014419",
        "LV": "1",
        "Item Description": "CELERY (VERO)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401080",
        "Last Price Paid": "$40.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014419",
        "Product Status": "Active"
    },
    {
        "Root": "10002078",
        "LV": "1",
        "Item Description": "GINGER PICKLED 5# CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002078001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450041",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002078",
        "Product Status": "Active"
    },
    {
        "Root": "10002078",
        "LV": "1",
        "Item Description": "GINGER PICKLED 5# CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002078001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002078",
        "Product Status": "Active"
    },
    {
        "Root": "10014634",
        "LV": "1",
        "Item Description": "CAB SAUV GRGICH HILLS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9615922",
        "Last Price Paid": "$52.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014634",
        "Product Status": "Active"
    },
    {
        "Root": "10016329",
        "LV": "2",
        "Item Description": "CUPCAKE MINI YELL ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016329002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1639",
        "Last Price Paid": "$0.49",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10016329",
        "Product Status": "Active"
    },
    {
        "Root": "10033491",
        "LV": "1",
        "Item Description": "SPRAY COCOA BUTTER WHITE CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586009",
        "Last Price Paid": "$51.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033491",
        "Product Status": "Active"
    },
    {
        "Root": "10010203",
        "LV": "2",
        "Item Description": "DRAFT SWEET WATER 420 1/6BL IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010203001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "67706",
        "Last Price Paid": "$78.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010203",
        "Product Status": "Active"
    },
    {
        "Root": "10001794",
        "LV": "1",
        "Item Description": "CABBAGE TUSCAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482087",
        "Last Price Paid": "$40.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001794",
        "Product Status": "Active"
    },
    {
        "Root": "10012455",
        "LV": "1",
        "Item Description": "SUGARSOFT-ROSES-RED-MEDIUM 2.0\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "23216",
        "Last Price Paid": "$0.35",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012455",
        "Product Status": "Active"
    },
    {
        "Root": "10100452",
        "LV": "1",
        "Item Description": "ROLL, BAO BUN 2.25\" X 3.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100452001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2891619",
        "Last Price Paid": "$0.34",
        "Case Pack": "264",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100452",
        "Product Status": "Active"
    },
    {
        "Root": "10001598",
        "LV": "1",
        "Item Description": "GARLIC IN OIL 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460350",
        "Last Price Paid": "$2.90",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001598",
        "Product Status": "Active"
    },
    {
        "Root": "10017389",
        "LV": "1",
        "Item Description": "PARSLEY ROOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "399012",
        "Last Price Paid": "$43.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017389",
        "Product Status": "Active"
    },
    {
        "Root": "10003838",
        "LV": "1",
        "Item Description": "BAR LUNA CHOC DIP COCONT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "716423",
        "Last Price Paid": "$1.20",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003838",
        "Product Status": "Active"
    },
    {
        "Root": "10003838",
        "LV": "1",
        "Item Description": "BAR LUNA CHOC DIP COCONT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003838001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "210069",
        "Last Price Paid": "$1.20",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003838",
        "Product Status": "Active"
    },
    {
        "Root": "10015163",
        "LV": "1",
        "Item Description": "PASTA DRY ROTINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015163001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9325614",
        "Last Price Paid": "$9.81",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015163",
        "Product Status": "Active"
    },
    {
        "Root": "10084340",
        "LV": "1",
        "Item Description": "CANDY PUSH POP HAUNTED MANSION NO LID 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "27795",
        "Last Price Paid": "$0.81",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084340",
        "Product Status": "Active"
    },
    {
        "Root": "10020391",
        "LV": "1",
        "Item Description": "DUCK LEGS ROHAN 8-CS 10 LB AVG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020391001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "DUCROH003",
        "Last Price Paid": "$10.99",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020391",
        "Product Status": "Active"
    },
    {
        "Root": "10036278",
        "LV": "1",
        "Item Description": "VODKA BELVEDERE SMOGORY FOREST 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036278001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "485025",
        "Last Price Paid": "$35.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036278",
        "Product Status": "Active"
    },
    {
        "Root": "10082104",
        "LV": "1",
        "Item Description": "SAUCE TARTAR 200 COUNT MONARCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "580533",
        "Last Price Paid": "$0.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082104",
        "Product Status": "Active"
    },
    {
        "Root": "10005891",
        "LV": "1",
        "Item Description": "BREAD PUMPERNCKEL PULLMAN 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "3 LB-A",
        "Last Price Paid": "$5.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005891",
        "Product Status": "Active"
    },
    {
        "Root": "10007744",
        "LV": "2",
        "Item Description": "PINOT N ACROBAT 19.5 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007744001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "964056",
        "Last Price Paid": "$312.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007744",
        "Product Status": "Active"
    },
    {
        "Root": "10000647",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 7Z GRAIN FED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000647001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195100",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000647",
        "Product Status": "Active"
    },
    {
        "Root": "10002007",
        "LV": "1",
        "Item Description": "POTATO MARBLE TRI-COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VP020",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002007",
        "Product Status": "Active"
    },
    {
        "Root": "10002007",
        "LV": "1",
        "Item Description": "POTATO MARBLE TRI-COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002007001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002007",
        "Product Status": "Active"
    },
    {
        "Root": "10004859",
        "LV": "2",
        "Item Description": "BAR GRANOLA CHEWY ASSORTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004859002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6151161",
        "Last Price Paid": "$0.44",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004859",
        "Product Status": "Active"
    },
    {
        "Root": "10007011",
        "LV": "1",
        "Item Description": "SPARK BELLINI CANELLA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007011001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007011",
        "Product Status": "Active"
    },
    {
        "Root": "10003002",
        "LV": "3",
        "Item Description": "CORNSTARCH 3.5LB 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003002003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10003002",
        "Product Status": "Active"
    },
    {
        "Root": "10022343",
        "LV": "1",
        "Item Description": "TURKEY WHL NATURAL 16-20 LB JOYCE FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022343001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4129010",
        "Last Price Paid": "$3.48",
        "Case Pack": "34",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022343",
        "Product Status": "Active"
    },
    {
        "Root": "10010107",
        "LV": "1",
        "Item Description": "BEER CAN COORS LIGHT LAGER 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010107001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10231",
        "Last Price Paid": "$1.17",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010107",
        "Product Status": "Active"
    },
    {
        "Root": "10010107",
        "LV": "1",
        "Item Description": "BEER CAN COORS LIGHT LAGER 12OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010107001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.17",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010107",
        "Product Status": "Active"
    },
    {
        "Root": "10109550",
        "LV": "1",
        "Item Description": "BEER KEG HARP LAGER 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$169.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109550",
        "Product Status": "Active"
    },
    {
        "Root": "10002694",
        "LV": "1",
        "Item Description": "SHELL PIE GRAHAM 9 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002694001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.69",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002694",
        "Product Status": "Active"
    },
    {
        "Root": "10006771",
        "LV": "1",
        "Item Description": "CAKE, TEACAKE LEMON, GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006771",
        "Product Status": "Active"
    },
    {
        "Root": "10038514",
        "LV": "1",
        "Item Description": "DRAFT BLUE PT GOLD SQUADRON LAGER 1/2 KEG SWGE ONL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038514001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "39302",
        "Last Price Paid": "$217.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038514",
        "Product Status": "Active"
    },
    {
        "Root": "10105131",
        "LV": "1",
        "Item Description": "TEQ CLASE AZUL PLATA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578234",
        "Last Price Paid": "$117.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105131",
        "Product Status": "Active"
    },
    {
        "Root": "10017853",
        "LV": "1",
        "Item Description": "CHEESE RACLETTE SWITZERLAND 12 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017853001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "3930",
        "Last Price Paid": "$12.05",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017853",
        "Product Status": "Active"
    },
    {
        "Root": "10094056",
        "LV": "1",
        "Item Description": "DANISH PLAIN RAW MINI RF BRIOCHE W/SUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094056",
        "Product Status": "Active"
    },
    {
        "Root": "10024311",
        "LV": "2",
        "Item Description": "SPICE PUMPKIN 8/12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024311001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "80204",
        "Last Price Paid": "$0.75",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10024311",
        "Product Status": "Active"
    },
    {
        "Root": "10009626",
        "LV": "1",
        "Item Description": "VODKA KETEL 1 CITROEN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "97617",
        "Last Price Paid": "$24.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009626",
        "Product Status": "Active"
    },
    {
        "Root": "10009518",
        "LV": "1",
        "Item Description": "WHISKEY SEAGRAMS 7 CROWN LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "3001",
        "Last Price Paid": "$16.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009518",
        "Product Status": "Active"
    },
    {
        "Root": "10094647",
        "LV": "1",
        "Item Description": "CANDY CHEWY COCOA LOCO BAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094647001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F00382",
        "Last Price Paid": "$0.65",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094647",
        "Product Status": "Active"
    },
    {
        "Root": "10107871",
        "LV": "1",
        "Item Description": "TURKEY & CHEDDAR SANDWICH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107871001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073447",
        "Last Price Paid": "$4.24",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107871",
        "Product Status": "Active"
    },
    {
        "Root": "10001551",
        "LV": "1",
        "Item Description": "LEMON MEYER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410435",
        "Last Price Paid": "$40.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001551",
        "Product Status": "Active"
    },
    {
        "Root": "10003055",
        "LV": "1",
        "Item Description": "PASTA DRY FARFALLE BOWTIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003055001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "10076808039211",
        "Last Price Paid": "$20.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003055",
        "Product Status": "Active"
    },
    {
        "Root": "10006001",
        "LV": "1",
        "Item Description": "BOX PIZZA 16 INCH WDW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8432835",
        "Last Price Paid": "$0.56",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006001",
        "Product Status": "Active"
    },
    {
        "Root": "10003019",
        "LV": "1",
        "Item Description": "DRESSING 1000 ISLAND FF 60CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003019001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.21",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003019",
        "Product Status": "Active"
    },
    {
        "Root": "10001259",
        "LV": "3",
        "Item Description": "SHALLOTS PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001259002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "211036",
        "Last Price Paid": "$16.86",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10001259",
        "Product Status": "Active"
    },
    {
        "Root": "10029071",
        "LV": "1",
        "Item Description": "MIX SOFT SERVE VANILLA LACTOSE FREE 6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "100461",
        "Last Price Paid": "$10.73",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029071",
        "Product Status": "Active"
    },
    {
        "Root": "10037626",
        "LV": "1",
        "Item Description": "MOBILE ORDER 1.125X3.125 RECT SEMI-GLOSS 1C LABE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45012753",
        "Last Price Paid": "$0.04",
        "Case Pack": "8000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037626",
        "Product Status": "Active"
    },
    {
        "Root": "10086403",
        "LV": "1",
        "Item Description": "CHICKEN TENDERS BREADED GLUTEN FREE B&E 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CHI250",
        "Last Price Paid": "$59.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086403",
        "Product Status": "Active"
    },
    {
        "Root": "10091223",
        "LV": "1",
        "Item Description": "RUM SELVAREY OWNER'S RSV 4/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "200456",
        "Last Price Paid": "$129.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091223",
        "Product Status": "Active"
    },
    {
        "Root": "10023265",
        "LV": "1",
        "Item Description": "YOGURT PLAIN LOW FAT RBST FREE SINGLE SERVE CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023265001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8395154",
        "Last Price Paid": "$0.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023265",
        "Product Status": "Active"
    },
    {
        "Root": "10010020",
        "LV": "1",
        "Item Description": "DRAFT STELLA ARTOIS HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010020001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$172.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010020",
        "Product Status": "Active"
    },
    {
        "Root": "10103332",
        "LV": "1",
        "Item Description": "INK PC-CTRG-BLACK-EPSON U SERIES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28008",
        "Last Price Paid": "$17.82",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103332",
        "Product Status": "Active"
    },
    {
        "Root": "10004936",
        "LV": "1",
        "Item Description": "GLAZE APRICOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004936001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BEL & SCHAFER{",
        "Last Price Paid": "$75.59",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004936",
        "Product Status": "Active"
    },
    {
        "Root": "10016840",
        "LV": "1",
        "Item Description": "SUGAR SANDING GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5548",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016840",
        "Product Status": "Active"
    },
    {
        "Root": "10014731",
        "LV": "1",
        "Item Description": "CAB SAUV HONIG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014731001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9619533",
        "Last Price Paid": "$35.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014731",
        "Product Status": "Active"
    },
    {
        "Root": "10000682",
        "LV": "1",
        "Item Description": "BEEF CORNED ROUNDS COOKED NDELI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000682001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3101038",
        "Last Price Paid": "$9.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000682",
        "Product Status": "Active"
    },
    {
        "Root": "10006064",
        "LV": "1",
        "Item Description": "BOWL DESSERT KITCHEN SINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.35",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006064",
        "Product Status": "Active"
    },
    {
        "Root": "10001719",
        "LV": "1",
        "Item Description": "PASTE QUINCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "115760",
        "Last Price Paid": "$91.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001719",
        "Product Status": "Active"
    },
    {
        "Root": "10030322",
        "LV": "1",
        "Item Description": "FLOSSUGAR SOUR GREEN APPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030322001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3220",
        "Last Price Paid": "$5.26",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030322",
        "Product Status": "Active"
    },
    {
        "Root": "10107129",
        "LV": "1",
        "Item Description": "DISNEY HAUNTED MANT. TRANSF CUT OUT 3.6X8CM, 1500P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6175540000",
        "Last Price Paid": "$125.32",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107129",
        "Product Status": "Active"
    },
    {
        "Root": "10011668",
        "LV": "1",
        "Item Description": "HAM ALPINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011668001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "133A",
        "Last Price Paid": "$9.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011668",
        "Product Status": "Active"
    },
    {
        "Root": "10002844",
        "LV": "2",
        "Item Description": "JUICE V-8 46Z TOMATO 46OZ/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002844001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.66",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002844",
        "Product Status": "Active"
    },
    {
        "Root": "10031460",
        "LV": "1",
        "Item Description": "LIQ BITTERCUBE BLACKSTRAP BITTER 150ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "659433",
        "Last Price Paid": "$16.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031460",
        "Product Status": "Active"
    },
    {
        "Root": "10007175",
        "LV": "1",
        "Item Description": "CHARD SEBELLA HANZEL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007175",
        "Product Status": "Active"
    },
    {
        "Root": "10009700",
        "LV": "1",
        "Item Description": "TEQ SAUZA TRES GEN ANEJO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009700001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "950022",
        "Last Price Paid": "$34.15",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009700",
        "Product Status": "Active"
    },
    {
        "Root": "10005170",
        "LV": "1",
        "Item Description": "CHOC WHT 3 INCH MINNIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3210",
        "Last Price Paid": "$0.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005170",
        "Product Status": "Active"
    },
    {
        "Root": "10039985",
        "LV": "1",
        "Item Description": "BOX SET CAKE POP PAPER 8.5X6X8 50/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51121011",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039985",
        "Product Status": "Active"
    },
    {
        "Root": "10025107",
        "LV": "1",
        "Item Description": "PINOT NOIR BECKON 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025107001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346364",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025107",
        "Product Status": "Active"
    },
    {
        "Root": "10027139",
        "LV": "3",
        "Item Description": "RED BLEND FLORIOGRAPY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027139002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10027139",
        "Product Status": "Active"
    },
    {
        "Root": "10031029",
        "LV": "1",
        "Item Description": "BIG TOP HAWAIIAN LOIN COCONUT COFFEE PORTER 1/6 KE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "55706",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031029",
        "Product Status": "Active"
    },
    {
        "Root": "10088177",
        "LV": "1",
        "Item Description": "BEER DRAFT BIG STORM DUNDER & BLIXEM 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088177001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088177",
        "Product Status": "Active"
    },
    {
        "Root": "10029889",
        "LV": "1",
        "Item Description": "LID BUCKET VENTED PAPER 83 OZ 100 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6000210",
        "Last Price Paid": "",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029889",
        "Product Status": "Active"
    },
    {
        "Root": "10092051",
        "LV": "1",
        "Item Description": "CRAWFISH, WHOLE 10-15 COUNT COOKED FROZEN 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2353365",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092051",
        "Product Status": "Active"
    },
    {
        "Root": "10013918",
        "LV": "1",
        "Item Description": "ROSE PETITE SERIES RED DEC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "66",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013918",
        "Product Status": "Active"
    },
    {
        "Root": "10016249",
        "LV": "1",
        "Item Description": "RIESLING GROOTE POST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "60042117",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016249",
        "Product Status": "Active"
    },
    {
        "Root": "10036030",
        "LV": "1",
        "Item Description": "BRANDY GRAN DUQUE D ALBA SOLERA GRAN RESERVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036030001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "45036",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036030",
        "Product Status": "Active"
    },
    {
        "Root": "10088924",
        "LV": "1",
        "Item Description": "SAUCE CHEESE CHEDDAR POUCH W/DISP 80 OZ / 4 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7260949",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088924",
        "Product Status": "Active"
    },
    {
        "Root": "10033650",
        "LV": "1",
        "Item Description": "SUGAR TURKEY AND CORNUCOPIA ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033650001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033650",
        "Product Status": "Active"
    },
    {
        "Root": "10028006",
        "LV": "1",
        "Item Description": "BEER FIRST MAGNITUDE 72 PALE ALE 12 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.52",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028006",
        "Product Status": "Active"
    },
    {
        "Root": "10005238",
        "LV": "1",
        "Item Description": "DÉCOR NON EDIBLE DISCO SPARKLES RAINBO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005238001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1005669",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005238",
        "Product Status": "Active"
    },
    {
        "Root": "10005067",
        "LV": "1",
        "Item Description": "GEL LIQUID VIOLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005067001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22512",
        "Last Price Paid": "$5.39",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005067",
        "Product Status": "Active"
    },
    {
        "Root": "10012691",
        "LV": "1",
        "Item Description": "GELATO CARAMEL CASHEW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012691001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-CACH-08-CS",
        "Last Price Paid": "$10.87",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012691",
        "Product Status": "Active"
    },
    {
        "Root": "10028041",
        "LV": "2",
        "Item Description": "BAYOU SPICED RUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028041002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9204906",
        "Last Price Paid": "$15.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028041",
        "Product Status": "Active"
    },
    {
        "Root": "10028041",
        "LV": "2",
        "Item Description": "BAYOU SPICED RUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028041002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067871",
        "Vendor": "PREMIER BEVERAGE COMPANY",
        "Vendor Item Nb": "9204906",
        "Last Price Paid": "$15.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028041",
        "Product Status": "Active"
    },
    {
        "Root": "10000167",
        "LV": "1",
        "Item Description": "FISH TURBOT WHOLE WILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000167001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6489800",
        "Last Price Paid": "$46.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000167",
        "Product Status": "Active"
    },
    {
        "Root": "10000167",
        "LV": "1",
        "Item Description": "FISH TURBOT WHOLE WILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000167001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "19820010",
        "Last Price Paid": "$46.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000167",
        "Product Status": "Active"
    },
    {
        "Root": "10000167",
        "LV": "1",
        "Item Description": "FISH TURBOT WHOLE WILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000167001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-10030",
        "Last Price Paid": "$46.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000167",
        "Product Status": "Active"
    },
    {
        "Root": "10002883",
        "LV": "1",
        "Item Description": "TEA CEYLON ORANGE PEKOE 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002883001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002883",
        "Product Status": "Active"
    },
    {
        "Root": "10009728",
        "LV": "2",
        "Item Description": "TEQ GR PTRN PIEDRA EX ANEJO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009728001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "360260",
        "Last Price Paid": "$290.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009728",
        "Product Status": "Active"
    },
    {
        "Root": "10041513",
        "LV": "4",
        "Item Description": "STRAW PAPER WHITE WRAPPED 8.5 IN 1500CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041513002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10041513",
        "Product Status": "Active"
    },
    {
        "Root": "10016060",
        "LV": "1",
        "Item Description": "BAGEL PLAIN MINI (2.5 OZ) INDIV WRAPPED 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016060",
        "Product Status": "Active"
    },
    {
        "Root": "10002387",
        "LV": "2",
        "Item Description": "CHEESE FRESH MOZZARELLA OVOLINE 4 OUNCE BALLS 2/3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002387002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8172546",
        "Last Price Paid": "$11.79",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002387",
        "Product Status": "Active"
    },
    {
        "Root": "10105477",
        "LV": "1",
        "Item Description": "GLUTEN FREE/ALLERGEN FREE WAFFLE & PANCAKE MIX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105477001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.60",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105477",
        "Product Status": "Active"
    },
    {
        "Root": "10008797",
        "LV": "1",
        "Item Description": "CHAMP BILLECART BRUT RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008797001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9135569",
        "Last Price Paid": "$40.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008797",
        "Product Status": "Active"
    },
    {
        "Root": "10036202",
        "LV": "1",
        "Item Description": "COCOA BUTTER TEAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302070C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036202",
        "Product Status": "Active"
    },
    {
        "Root": "10009981",
        "LV": "1",
        "Item Description": "LIQ SAMBUCA ROMANO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009981001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9000484",
        "Last Price Paid": "$25.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009981",
        "Product Status": "Active"
    },
    {
        "Root": "10002039",
        "LV": "1",
        "Item Description": "POTATO PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002039001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002039",
        "Product Status": "Active"
    },
    {
        "Root": "10002039",
        "LV": "1",
        "Item Description": "POTATO PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002039001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002039",
        "Product Status": "Active"
    },
    {
        "Root": "10082213",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL BON BON ANGLAIS 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082213001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "52500010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082213",
        "Product Status": "Active"
    },
    {
        "Root": "10012347",
        "LV": "1",
        "Item Description": "CATFISH FILLET 7/9OZ, FARMED SUSTAINABLE SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012347001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "65-00040",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012347",
        "Product Status": "Active"
    },
    {
        "Root": "10012347",
        "LV": "1",
        "Item Description": "CATFISH FILLET 7/9OZ, FARMED SUSTAINABLE SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012347001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "CAT150100",
        "Last Price Paid": "$6.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012347",
        "Product Status": "Active"
    },
    {
        "Root": "10021707",
        "LV": "1",
        "Item Description": "DECOR FLASHY SPARKLE BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021707001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1006638",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021707",
        "Product Status": "Active"
    },
    {
        "Root": "10107504",
        "LV": "1",
        "Item Description": "HARD CIDER BLAKES TROPICOLADA 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107504001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "14963",
        "Last Price Paid": "$179.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107504",
        "Product Status": "Active"
    },
    {
        "Root": "10036230",
        "LV": "1",
        "Item Description": "MARMALADE ORANGE DICKINSON'S GLASS JAR ROOM SERVI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2060077",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036230",
        "Product Status": "Active"
    },
    {
        "Root": "10002034",
        "LV": "1",
        "Item Description": "PINEAPPL GLDN RIPE PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002034001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-007H-A",
        "Last Price Paid": "$1.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002034",
        "Product Status": "Active"
    },
    {
        "Root": "10035129",
        "LV": "1",
        "Item Description": "SYRUP MONIN ROCK MELON CANTALOUPE L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5132",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035129",
        "Product Status": "Active"
    },
    {
        "Root": "10105494",
        "LV": "1",
        "Item Description": "PASTE CURRY RED NO SHELLFISH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1003048",
        "Last Price Paid": "$57.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105494",
        "Product Status": "Active"
    },
    {
        "Root": "10013723",
        "LV": "1",
        "Item Description": "SHRIMP 31/40 P&D T/OFF SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013723001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125582",
        "Last Price Paid": "$4.88",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013723",
        "Product Status": "Active"
    },
    {
        "Root": "10016451",
        "LV": "1",
        "Item Description": "BEER MENABREA AMBER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016451001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "762551",
        "Last Price Paid": "$2.08",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016451",
        "Product Status": "Active"
    },
    {
        "Root": "10032485",
        "LV": "1",
        "Item Description": "CALIF PREM MIXERS COSMOPOLITAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "363866",
        "Last Price Paid": "$63.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032485",
        "Product Status": "Active"
    },
    {
        "Root": "10014632",
        "LV": "1",
        "Item Description": "PEPPER JALAPENO RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014632001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480461",
        "Last Price Paid": "$56.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014632",
        "Product Status": "Active"
    },
    {
        "Root": "10044129",
        "LV": "2",
        "Item Description": "CANDY M&M RED AND GREEN CHRISTMAS 25# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044129002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "52153",
        "Last Price Paid": "$141.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10044129",
        "Product Status": "Active"
    },
    {
        "Root": "10009570",
        "LV": "1",
        "Item Description": "VODKA STOLI VANIL LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009570001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009570",
        "Product Status": "Active"
    },
    {
        "Root": "10009803",
        "LV": "2",
        "Item Description": "RUM DON Q LIMON LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009803001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "107299",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009803",
        "Product Status": "Active"
    },
    {
        "Root": "10009803",
        "LV": "2",
        "Item Description": "RUM DON Q LIMON LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009803001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009803",
        "Product Status": "Active"
    },
    {
        "Root": "10003795",
        "LV": "1",
        "Item Description": "CARAMEL LATTE DULCE LECHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003795001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "27406",
        "Last Price Paid": "$27.08",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003795",
        "Product Status": "Active"
    },
    {
        "Root": "10002636",
        "LV": "3",
        "Item Description": "SPICE GARLIC POWDER 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002636003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.41",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002636",
        "Product Status": "Active"
    },
    {
        "Root": "10002636",
        "LV": "3",
        "Item Description": "SPICE GARLIC POWDER 6/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002636003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00680-2",
        "Last Price Paid": "$3.41",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002636",
        "Product Status": "Active"
    },
    {
        "Root": "10008254",
        "LV": "1",
        "Item Description": "MADEIRA BROADBENT 10 YR MALMSEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "454089",
        "Last Price Paid": "$35.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008254",
        "Product Status": "Active"
    },
    {
        "Root": "10022735",
        "LV": "1",
        "Item Description": "WINE RED SYRAH FAURY ST. JOSEPH 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "581820",
        "Last Price Paid": "$27.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022735",
        "Product Status": "Active"
    },
    {
        "Root": "10105133",
        "LV": "1",
        "Item Description": "ANNIVERSARY BLEND 5LB 1-EA - US ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "000007356",
        "Last Price Paid": "$63.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105133",
        "Product Status": "Active"
    },
    {
        "Root": "10006324",
        "LV": "1",
        "Item Description": "GLOVE ELBOW LENGTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6420525",
        "Last Price Paid": "$59.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006324",
        "Product Status": "Active"
    },
    {
        "Root": "10030645",
        "LV": "1",
        "Item Description": "BOX PIZZA PLAIN WHITE 10\" B FLUTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030645001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51208070",
        "Last Price Paid": "$0.48",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030645",
        "Product Status": "Active"
    },
    {
        "Root": "10017330",
        "LV": "1",
        "Item Description": "BOW RIBBON BURGUNDY 5/8 X 10.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017330001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52700039",
        "Last Price Paid": "$0.07",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017330",
        "Product Status": "Active"
    },
    {
        "Root": "10094822",
        "LV": "1",
        "Item Description": "ROLL, BAO WHT 2.5X3.5\" FLDED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7837552",
        "Last Price Paid": "$8.55",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094822",
        "Product Status": "Active"
    },
    {
        "Root": "10104308",
        "LV": "1",
        "Item Description": "CHORIZO EMPANADA 4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3022180",
        "Last Price Paid": "$1.58",
        "Case Pack": "54",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104308",
        "Product Status": "Active"
    },
    {
        "Root": "10002609",
        "LV": "1",
        "Item Description": "TARO ROOT/EDDO LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002609001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "882001",
        "Last Price Paid": "$1.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002609",
        "Product Status": "Active"
    },
    {
        "Root": "10002609",
        "LV": "1",
        "Item Description": "TARO ROOT/EDDO LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002609001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002609",
        "Product Status": "Active"
    },
    {
        "Root": "10001364",
        "LV": "1",
        "Item Description": "CHUTNEY DHANIA CORIANDER 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001364001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$44.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001364",
        "Product Status": "Active"
    },
    {
        "Root": "10004426",
        "LV": "1",
        "Item Description": "SUGAR INVERT NEVULINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA689",
        "Last Price Paid": "$37.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004426",
        "Product Status": "Active"
    },
    {
        "Root": "10092952",
        "LV": "1",
        "Item Description": "PECTIN YELLOW SLOW SET 500G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6721520000",
        "Last Price Paid": "$30.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092952",
        "Product Status": "Active"
    },
    {
        "Root": "10004357",
        "LV": "1",
        "Item Description": "CRACKER WHL GRAIN CHEEZIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7612294",
        "Last Price Paid": "$0.20",
        "Case Pack": "175",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004357",
        "Product Status": "Active"
    },
    {
        "Root": "10039102",
        "LV": "1",
        "Item Description": "SUGAR FALL LEAVES ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039102001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "38575",
        "Last Price Paid": "$0.13",
        "Case Pack": "188",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039102",
        "Product Status": "Active"
    },
    {
        "Root": "10007489",
        "LV": "1",
        "Item Description": "CAB S TOKARA DIR CUT RSRV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "381836",
        "Last Price Paid": "$29.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007489",
        "Product Status": "Active"
    },
    {
        "Root": "10010222",
        "LV": "2",
        "Item Description": "DRAFT KEY WEST ALE SUNSET HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010222001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "76681",
        "Last Price Paid": "$164.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010222",
        "Product Status": "Active"
    },
    {
        "Root": "10002867",
        "LV": "1",
        "Item Description": "COKE DIET 20OZ BTL 24EA P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "119827",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002867",
        "Product Status": "Active"
    },
    {
        "Root": "10002867",
        "LV": "1",
        "Item Description": "COKE DIET 20OZ BTL 24EA P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002867",
        "Product Status": "Active"
    },
    {
        "Root": "10090585",
        "LV": "1",
        "Item Description": "SHELL TART SWEET SQ 7CM 120PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "0090900000",
        "Last Price Paid": "$0.97",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090585",
        "Product Status": "Active"
    },
    {
        "Root": "10084122",
        "LV": "2",
        "Item Description": "DONUT DZ MK ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084122002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS203",
        "Last Price Paid": "$0.98",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084122",
        "Product Status": "Active"
    },
    {
        "Root": "10037403",
        "LV": "1",
        "Item Description": "DRESSING VEGAN ORGANIC CAESAR 6 X 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037403001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "68012",
        "Last Price Paid": "$4.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037403",
        "Product Status": "Active"
    },
    {
        "Root": "10036370",
        "LV": "1",
        "Item Description": "SAUV BLANC HENRI BOURGEOIS SANCERRE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036370001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "849379",
        "Last Price Paid": "$21.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036370",
        "Product Status": "Active"
    },
    {
        "Root": "10013820",
        "LV": "1",
        "Item Description": "POLLEN HONEY BEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013820",
        "Product Status": "Active"
    },
    {
        "Root": "10003039",
        "LV": "2",
        "Item Description": "OLIVE GREEN STUFFED GREEN 1GAL/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003039002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5333711",
        "Last Price Paid": "$17.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003039",
        "Product Status": "Active"
    },
    {
        "Root": "10001634",
        "LV": "1",
        "Item Description": "PEPPER JUL/SL FC MXD GR R",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514514",
        "Last Price Paid": "$16.73",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001634",
        "Product Status": "Active"
    },
    {
        "Root": "10039063",
        "LV": "2",
        "Item Description": "CRAB CLAW JONAH LARGE SCORED 20 LB CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039063002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6339501",
        "Last Price Paid": "$9.32",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039063",
        "Product Status": "Active"
    },
    {
        "Root": "10000255",
        "LV": "1",
        "Item Description": "TOMATO CHERRY ON THE VINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23036",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000255",
        "Product Status": "Active"
    },
    {
        "Root": "10000255",
        "LV": "1",
        "Item Description": "TOMATO CHERRY ON THE VINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410452",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000255",
        "Product Status": "Active"
    },
    {
        "Root": "10000255",
        "LV": "1",
        "Item Description": "TOMATO CHERRY ON THE VINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "410452",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000255",
        "Product Status": "Active"
    },
    {
        "Root": "10003848",
        "LV": "1",
        "Item Description": "CHIP RICE FIESTA LIME LUNDBERG 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "35348",
        "Last Price Paid": "$3.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003848",
        "Product Status": "Active"
    },
    {
        "Root": "10019103",
        "LV": "1",
        "Item Description": "COCONUT MILK,OG2,PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019103001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "136628",
        "Last Price Paid": "$4.87",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019103",
        "Product Status": "Active"
    },
    {
        "Root": "10015200",
        "LV": "1",
        "Item Description": "PINOT NOIR SKYWALKER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578149",
        "Last Price Paid": "$75.45",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015200",
        "Product Status": "Active"
    },
    {
        "Root": "10006499",
        "LV": "2",
        "Item Description": "SB FILTER #4 POUR OVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006499001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11074055",
        "Last Price Paid": "$5.58",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006499",
        "Product Status": "Active"
    },
    {
        "Root": "10003750",
        "LV": "1",
        "Item Description": "WAFFLES GLUTEN FREE 12/9Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003750001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "194688",
        "Last Price Paid": "$4.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003750",
        "Product Status": "Active"
    },
    {
        "Root": "10002967",
        "LV": "1",
        "Item Description": "TEA EARL GREY BLK IND 25C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002967",
        "Product Status": "Active"
    },
    {
        "Root": "10032840",
        "LV": "2",
        "Item Description": "FOLLOW YOUR HEART AMERICAN SLICES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032840002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "22027",
        "Last Price Paid": "$3.75",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032840",
        "Product Status": "Active"
    },
    {
        "Root": "10109551",
        "LV": "1",
        "Item Description": "BEER KEG GUINNESS STOUT 13.2GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$180.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109551",
        "Product Status": "Active"
    },
    {
        "Root": "10003710",
        "LV": "1",
        "Item Description": "PASTA FRZ GNOCCHI POTATO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070371",
        "Last Price Paid": "$44.66",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003710",
        "Product Status": "Active"
    },
    {
        "Root": "10006917",
        "LV": "1",
        "Item Description": "SAUV BLC INDABA CST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "967324",
        "Last Price Paid": "$7.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006917",
        "Product Status": "Active"
    },
    {
        "Root": "10095493",
        "LV": "1",
        "Item Description": "CHEESE BURRATA ITALIAN 6/4OZ PREORDER 21 DAY LEAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "35779",
        "Last Price Paid": "$6.57",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095493",
        "Product Status": "Active"
    },
    {
        "Root": "10106153",
        "LV": "2",
        "Item Description": "CUP COLD 16OZ DISNEY WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106153002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "116387089",
        "Last Price Paid": "$48.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106153",
        "Product Status": "Active"
    },
    {
        "Root": "10004982",
        "LV": "1",
        "Item Description": "PUREE LYCHEE 6/1KG BOIRON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "791...{BOIRON{",
        "Last Price Paid": "$15.18",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004982",
        "Product Status": "Active"
    },
    {
        "Root": "10105409",
        "LV": "1",
        "Item Description": "VEAL LOIN RIB CHOP 14OZ DOMESTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105409001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1304062",
        "Last Price Paid": "",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105409",
        "Product Status": "Active"
    },
    {
        "Root": "10088611",
        "LV": "1",
        "Item Description": "DEC MILK CHOCOLATE COVERED CROQUANT 1.8 KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "2042620000",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088611",
        "Product Status": "Active"
    },
    {
        "Root": "10088346",
        "LV": "1",
        "Item Description": "SPORK BLACK PS WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11004177",
        "Last Price Paid": "",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088346",
        "Product Status": "Active"
    },
    {
        "Root": "10017634",
        "LV": "1",
        "Item Description": "CHOC DARK TRUFFLE 70%",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA8877",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017634",
        "Product Status": "Active"
    },
    {
        "Root": "10027274",
        "LV": "2",
        "Item Description": "CHIPS BANANA 6/5 OZ RESEALABLE CUBE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027274002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891312",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027274",
        "Product Status": "Active"
    },
    {
        "Root": "10036056",
        "LV": "1",
        "Item Description": "BRANDY ST GEORGES PEAR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558530",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036056",
        "Product Status": "Active"
    },
    {
        "Root": "10094767",
        "LV": "1",
        "Item Description": "TOMATOES PEELED W/BASIL #10 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094767",
        "Product Status": "Active"
    },
    {
        "Root": "10103449",
        "LV": "1",
        "Item Description": "CANDY M&M PLAIN 12/3.1OZ THEATER BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103449001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "20324",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103449",
        "Product Status": "Active"
    },
    {
        "Root": "10022340",
        "LV": "1",
        "Item Description": "TURKEY WHL NATURAL 10-14 LBS JOYCE FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022340001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4128999",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022340",
        "Product Status": "Active"
    },
    {
        "Root": "10012738",
        "LV": "1",
        "Item Description": "COASTER HANGAR SOUTHSEASD 4\" 64R4DB2S CMYK 2S RND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000403992",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "731-022",
        "Last Price Paid": "",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012738",
        "Product Status": "Active"
    },
    {
        "Root": "10040396",
        "LV": "1",
        "Item Description": "CHOC SPACESHIP EARTH ON STAND WHITE 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW62701",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040396",
        "Product Status": "Active"
    },
    {
        "Root": "10023443",
        "LV": "1",
        "Item Description": "WATERCRESS HYDRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489797",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023443",
        "Product Status": "Active"
    },
    {
        "Root": "10095634",
        "LV": "1",
        "Item Description": "WINE WHITE BROCHARD SANCERRE 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "661304",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095634",
        "Product Status": "Active"
    },
    {
        "Root": "10016250",
        "LV": "1",
        "Item Description": "PIE PECAN 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "825",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016250",
        "Product Status": "Active"
    },
    {
        "Root": "10006893",
        "LV": "1",
        "Item Description": "CAB SAUV EXCELSIOR RBTSN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006893001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.77",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006893",
        "Product Status": "Active"
    },
    {
        "Root": "10039886",
        "LV": "1",
        "Item Description": "SB COCOA POWDER TOPPING 75 GRAM 2/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039886001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11111169",
        "Last Price Paid": "$3.85",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039886",
        "Product Status": "Active"
    },
    {
        "Root": "10020063",
        "LV": "1",
        "Item Description": "WHITE DOMINO XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LB203",
        "Last Price Paid": "$83.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020063",
        "Product Status": "Active"
    },
    {
        "Root": "10009799",
        "LV": "1",
        "Item Description": "RUM PARROT BAY COCONUT LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9000363",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009799",
        "Product Status": "Active"
    },
    {
        "Root": "10002880",
        "LV": "1",
        "Item Description": "TEA EARL GRY DCF TWINING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002880",
        "Product Status": "Active"
    },
    {
        "Root": "10016937",
        "LV": "1",
        "Item Description": "BEER BUD LIGHT 16 OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10263",
        "Last Price Paid": "$1.27",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016937",
        "Product Status": "Active"
    },
    {
        "Root": "10017434",
        "LV": "1",
        "Item Description": "PASTA FROZEN SHELLS WITH CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017434001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4982056",
        "Last Price Paid": "$0.67",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017434",
        "Product Status": "Active"
    },
    {
        "Root": "10003001",
        "LV": "2",
        "Item Description": "CORNSTARCH 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003001002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003001",
        "Product Status": "Active"
    },
    {
        "Root": "10100228",
        "LV": "1",
        "Item Description": "LAMB NECK DOMESTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100228001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "LAMDOM016",
        "Last Price Paid": "$16.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100228",
        "Product Status": "Active"
    },
    {
        "Root": "10035169",
        "LV": "1",
        "Item Description": "SYRUP PASSION FRUIT BG REYNOLDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BG105",
        "Last Price Paid": "$16.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035169",
        "Product Status": "Active"
    },
    {
        "Root": "10002323",
        "LV": "1",
        "Item Description": "CHEESE HARD BARELY BUZZED BEEHIVE CHEESE UTAH 2/5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002323001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "12585",
        "Last Price Paid": "$14.49",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002323",
        "Product Status": "Active"
    },
    {
        "Root": "10034107",
        "LV": "1",
        "Item Description": "LEMON COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034107001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "011113",
        "Last Price Paid": "$94.01",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034107",
        "Product Status": "Active"
    },
    {
        "Root": "10012751",
        "LV": "1",
        "Item Description": "SQUASH BABY MIXED 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "16MBS-26",
        "Last Price Paid": "$89.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012751",
        "Product Status": "Active"
    },
    {
        "Root": "10006152",
        "LV": "2",
        "Item Description": "KNIFE MED WT SMARTSTOCK B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006152002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "SSK51",
        "Last Price Paid": "$18.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006152",
        "Product Status": "Active"
    },
    {
        "Root": "10024348",
        "LV": "1",
        "Item Description": "EZ CUT COLUMNS 12 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024348001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "37911",
        "Last Price Paid": "$0.70",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024348",
        "Product Status": "Active"
    },
    {
        "Root": "10034911",
        "LV": "2",
        "Item Description": "ECLAIR LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034911001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "589201",
        "Last Price Paid": "$0.34",
        "Case Pack": "140",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10034911",
        "Product Status": "Active"
    },
    {
        "Root": "10026959",
        "LV": "1",
        "Item Description": "CHIP CHEETOS CRUNCHY CHEESE SS 64/2 0Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5597604",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026959",
        "Product Status": "Active"
    },
    {
        "Root": "10001569",
        "LV": "1",
        "Item Description": "SUNCHOKE JERSULEM ARTICHO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001569001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401029",
        "Last Price Paid": "$47.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001569",
        "Product Status": "Active"
    },
    {
        "Root": "10035687",
        "LV": "2",
        "Item Description": "SAUCE GHIRARDELLI CARAMEL  6/87 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035687002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "582731",
        "Last Price Paid": "$24.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035687",
        "Product Status": "Active"
    },
    {
        "Root": "10004610",
        "LV": "1",
        "Item Description": "MOLASSES GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3004371",
        "Last Price Paid": "$14.86",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004610",
        "Product Status": "Active"
    },
    {
        "Root": "10004094",
        "LV": "2",
        "Item Description": "SHELL SWEET 1.7 INCH ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004094001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD200",
        "Last Price Paid": "$0.26",
        "Case Pack": "378",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004094",
        "Product Status": "Active"
    },
    {
        "Root": "10017889",
        "LV": "2",
        "Item Description": "CHIC TENDER JUMBO CVP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017889001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.19",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10017889",
        "Product Status": "Active"
    },
    {
        "Root": "10029794",
        "LV": "1",
        "Item Description": "JUICE CARROT S/O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029794001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404070",
        "Last Price Paid": "$4.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029794",
        "Product Status": "Active"
    },
    {
        "Root": "10003303",
        "LV": "2",
        "Item Description": "COLORING GEL WHITE LIQUA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003303002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9511",
        "Last Price Paid": "$8.09",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003303",
        "Product Status": "Active"
    },
    {
        "Root": "10001300",
        "LV": "1",
        "Item Description": "MIX MM ICE CREAM SMOOTHIE 12/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500008005",
        "Last Price Paid": "$5.96",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001300",
        "Product Status": "Active"
    },
    {
        "Root": "10011163",
        "LV": "1",
        "Item Description": "PEPPER RED SWEET DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011163001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8326498",
        "Last Price Paid": "$13.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011163",
        "Product Status": "Active"
    },
    {
        "Root": "10004602",
        "LV": "1",
        "Item Description": "ONION COCKTAIL 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004602001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4690772",
        "Last Price Paid": "$8.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004602",
        "Product Status": "Active"
    },
    {
        "Root": "10036218",
        "LV": "1",
        "Item Description": "ROSES CAMELLIA WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CAM WH",
        "Last Price Paid": "$13.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036218",
        "Product Status": "Active"
    },
    {
        "Root": "10001916",
        "LV": "1",
        "Item Description": "FLOWER NASTURTIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482049",
        "Last Price Paid": "$0.28",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001916",
        "Product Status": "Active"
    },
    {
        "Root": "10009818",
        "LV": "2",
        "Item Description": "RUM GOSLING BLACK LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009818001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "20013",
        "Last Price Paid": "$22.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009818",
        "Product Status": "Active"
    },
    {
        "Root": "10001782",
        "LV": "2",
        "Item Description": "SHOOTS POPCORN GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001782002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22148",
        "Last Price Paid": "$11.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001782",
        "Product Status": "Active"
    },
    {
        "Root": "10001782",
        "LV": "2",
        "Item Description": "SHOOTS POPCORN GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001782002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401031",
        "Last Price Paid": "$11.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001782",
        "Product Status": "Active"
    },
    {
        "Root": "10001782",
        "LV": "2",
        "Item Description": "SHOOTS POPCORN GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001782002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "POPCORNSH",
        "Last Price Paid": "$11.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001782",
        "Product Status": "Active"
    },
    {
        "Root": "10024573",
        "LV": "2",
        "Item Description": "CHARD LEFLAIVE PULIGNY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024573002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9653942",
        "Last Price Paid": "$178.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10024573",
        "Product Status": "Active"
    },
    {
        "Root": "10003770",
        "LV": "1",
        "Item Description": "PAILLETE FEUILLETINE 4/6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C904",
        "Last Price Paid": "$39.91",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003770",
        "Product Status": "Active"
    },
    {
        "Root": "10003770",
        "LV": "1",
        "Item Description": "PAILLETE FEUILLETINE 4/6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ118724",
        "Last Price Paid": "$39.91",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003770",
        "Product Status": "Active"
    },
    {
        "Root": "10004491",
        "LV": "1",
        "Item Description": "COCOA BUTTER CHIPS 4/6.6 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SK7095",
        "Last Price Paid": "$58.45",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004491",
        "Product Status": "Active"
    },
    {
        "Root": "10002411",
        "LV": "2",
        "Item Description": "CHEESE FRESH MOZZARELLA LOG UNSLICED 8/1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002411002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5425301",
        "Last Price Paid": "$4.01",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002411",
        "Product Status": "Active"
    },
    {
        "Root": "10021698",
        "LV": "1",
        "Item Description": "COMPOUND PINEAPPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "D103",
        "Last Price Paid": "$38.36",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021698",
        "Product Status": "Active"
    },
    {
        "Root": "10002205",
        "LV": "2",
        "Item Description": "YOGURT VANILLA LITE & FIT 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002205001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1980085",
        "Last Price Paid": "$1.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002205",
        "Product Status": "Active"
    },
    {
        "Root": "10002205",
        "LV": "2",
        "Item Description": "YOGURT VANILLA LITE & FIT 32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002205001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002205",
        "Product Status": "Active"
    },
    {
        "Root": "10091620",
        "LV": "1",
        "Item Description": "PEPPER GUAJILLO DRY UNIT 5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091620001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091620",
        "Product Status": "Active"
    },
    {
        "Root": "10001788",
        "LV": "1",
        "Item Description": "TOMATO SLICE 6X6 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "21-004-14",
        "Last Price Paid": "$15.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001788",
        "Product Status": "Active"
    },
    {
        "Root": "10001788",
        "LV": "1",
        "Item Description": "TOMATO SLICE 6X6 1/4 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "517011",
        "Last Price Paid": "$15.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001788",
        "Product Status": "Active"
    },
    {
        "Root": "10036317",
        "LV": "1",
        "Item Description": "CANDY POP ROCKS TROPICAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585234",
        "Last Price Paid": "$0.55",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036317",
        "Product Status": "Active"
    },
    {
        "Root": "10003646",
        "LV": "1",
        "Item Description": "SEEDS PUMPKIN ROAST NO SALT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "320820",
        "Last Price Paid": "$127.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003646",
        "Product Status": "Active"
    },
    {
        "Root": "10009364",
        "LV": "2",
        "Item Description": "PINOT NOIR KARA TARA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009364001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9367164",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009364",
        "Product Status": "Active"
    },
    {
        "Root": "10036895",
        "LV": "2",
        "Item Description": "BAKING CUP BLACK W YLW STARS SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036895002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24734",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036895",
        "Product Status": "Active"
    },
    {
        "Root": "10100870",
        "LV": "1",
        "Item Description": "WINE RED BLEND FORTUNATE SON THE DIPLOMAT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100870001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "554077",
        "Last Price Paid": "$120.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100870",
        "Product Status": "Active"
    },
    {
        "Root": "10037890",
        "LV": "1",
        "Item Description": "CAVA GRAMONA III LUSTROS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "921816",
        "Last Price Paid": "$45.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037890",
        "Product Status": "Active"
    },
    {
        "Root": "10005000",
        "LV": "2",
        "Item Description": "PECTIN YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005000002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "U400",
        "Last Price Paid": "$25.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005000",
        "Product Status": "Active"
    },
    {
        "Root": "10017312",
        "LV": "1",
        "Item Description": "CHERRY BING GOLDBLUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017312001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270227",
        "Last Price Paid": "$37.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017312",
        "Product Status": "Active"
    },
    {
        "Root": "10006183",
        "LV": "2",
        "Item Description": "BAG COTTON CANDY PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006183001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3064",
        "Last Price Paid": "$0.05",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10006183",
        "Product Status": "Active"
    },
    {
        "Root": "10003984",
        "LV": "1",
        "Item Description": "PEPPERCORN GREEN 9 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003984001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "107950",
        "Last Price Paid": "$14.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003984",
        "Product Status": "Active"
    },
    {
        "Root": "10002690",
        "LV": "1",
        "Item Description": "CRACKER SALTINE 2CT IND P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7003999",
        "Last Price Paid": "$0.04",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002690",
        "Product Status": "Active"
    },
    {
        "Root": "10002690",
        "LV": "1",
        "Item Description": "CRACKER SALTINE 2CT IND P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002690001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002690",
        "Product Status": "Active"
    },
    {
        "Root": "10024579",
        "LV": "1",
        "Item Description": "SYRUP MONIN PEACH LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024579001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5036",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024579",
        "Product Status": "Active"
    },
    {
        "Root": "10006218",
        "LV": "1",
        "Item Description": "PLATE PLASTIC BLACK 6 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7148257",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006218",
        "Product Status": "Active"
    },
    {
        "Root": "10009247",
        "LV": "2",
        "Item Description": "CAB SAUV SHAFER HILL SEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009247001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9654995",
        "Last Price Paid": "$275.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009247",
        "Product Status": "Active"
    },
    {
        "Root": "10102389",
        "LV": "1",
        "Item Description": "MEZCAL CLASE AZUL JOVEN DURANGO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102389001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "390132",
        "Last Price Paid": "$287.25",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102389",
        "Product Status": "Active"
    },
    {
        "Root": "10029188",
        "LV": "2",
        "Item Description": "MIX OLD FASHION FUNNEL CAKE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029188002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7723638",
        "Last Price Paid": "$5.89",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10029188",
        "Product Status": "Active"
    },
    {
        "Root": "10000196",
        "LV": "1",
        "Item Description": "POTATO SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "408426",
        "Last Price Paid": "$18.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000196",
        "Product Status": "Active"
    },
    {
        "Root": "10000196",
        "LV": "1",
        "Item Description": "POTATO SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "29300",
        "Last Price Paid": "$18.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000196",
        "Product Status": "Active"
    },
    {
        "Root": "10033104",
        "LV": "1",
        "Item Description": "CORN FREEZE DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "130870",
        "Last Price Paid": "$15.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033104",
        "Product Status": "Active"
    },
    {
        "Root": "10023836",
        "LV": "1",
        "Item Description": "PASTA MACARONI CHEESE TRAY FROZEN STOUFFERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023836001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9002239",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023836",
        "Product Status": "Active"
    },
    {
        "Root": "10036598",
        "LV": "1",
        "Item Description": "CABERNET BACKPACK 250ML 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "483270",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036598",
        "Product Status": "Active"
    },
    {
        "Root": "10027896",
        "LV": "2",
        "Item Description": "MUG MAI TAI CERAMIC GROG GROTTO 24 CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027896002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027896",
        "Product Status": "Active"
    },
    {
        "Root": "10003501",
        "LV": "2",
        "Item Description": "TEA EROS 14.1OZ LSE BLK A",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003501001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W2013",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003501",
        "Product Status": "Active"
    },
    {
        "Root": "10009885",
        "LV": "2",
        "Item Description": "APERITIF CAMPARI BITR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009885001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009885",
        "Product Status": "Active"
    },
    {
        "Root": "10029963",
        "LV": "1",
        "Item Description": "SUGAR PLAYFUL PENGUIN ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029963001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "47215",
        "Last Price Paid": "",
        "Case Pack": "135",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029963",
        "Product Status": "Active"
    },
    {
        "Root": "10037607",
        "LV": "1",
        "Item Description": "DOUCH COOKIE SUGAR-FREE CHOCOLATE CHIP .75OZ 200",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7315385",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037607",
        "Product Status": "Active"
    },
    {
        "Root": "10009404",
        "LV": "2",
        "Item Description": "SPRK MOSCT TWO OCEAN 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009404001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "958723",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009404",
        "Product Status": "Active"
    },
    {
        "Root": "10018716",
        "LV": "1",
        "Item Description": "SHRIMP CARABINEROS SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018716001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "75-50160",
        "Last Price Paid": "$47.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018716",
        "Product Status": "Active"
    },
    {
        "Root": "10006500",
        "LV": "3",
        "Item Description": "SB CHARGERS WHIP CREAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006500002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11067232",
        "Last Price Paid": "$2.94",
        "Case Pack": "360",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10006500",
        "Product Status": "Active"
    },
    {
        "Root": "10025961",
        "LV": "1",
        "Item Description": "CRUCIFEROUR MICRO BLEND LIVING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "467530",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025961",
        "Product Status": "Active"
    },
    {
        "Root": "10020155",
        "LV": "1",
        "Item Description": "SPICE GINGERBREAD XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CU9985",
        "Last Price Paid": "$422.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020155",
        "Product Status": "Active"
    },
    {
        "Root": "10106585",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER GREEN 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106585001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313050E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106585",
        "Product Status": "Active"
    },
    {
        "Root": "10105112",
        "LV": "1",
        "Item Description": "CUP VINO 14OZ CLEAR RPET F&G GARDEN GRAZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11012259",
        "Last Price Paid": "$0.96",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105112",
        "Product Status": "Active"
    },
    {
        "Root": "10032433",
        "LV": "3",
        "Item Description": "IMPOSSIBLE BURGER 4OZ  PATTIES 40 X .25LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032433002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "60-00011",
        "Last Price Paid": "$1.81",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10032433",
        "Product Status": "Active"
    },
    {
        "Root": "10024735",
        "LV": "1",
        "Item Description": "COMPOUND S POPCORN SUPPLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024735001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2320MC",
        "Last Price Paid": "$7.42",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024735",
        "Product Status": "Active"
    },
    {
        "Root": "10034688",
        "LV": "1",
        "Item Description": "VEAL OSSO BUCCO FRENCH D'ARTAGNAN 13Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034688001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZVEAFRA025",
        "Last Price Paid": "$15.99",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034688",
        "Product Status": "Active"
    },
    {
        "Root": "10003574",
        "LV": "1",
        "Item Description": "MIX CAKE WHITE 50 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$109.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003574",
        "Product Status": "Active"
    },
    {
        "Root": "10034574",
        "LV": "1",
        "Item Description": "CAKE MILK CHOCOLATE MICKEY DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034574001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "DOME-MLK",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034574",
        "Product Status": "Active"
    },
    {
        "Root": "10009988",
        "LV": "1",
        "Item Description": "LIQ KAHLUA LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009988",
        "Product Status": "Active"
    },
    {
        "Root": "10012665",
        "LV": "1",
        "Item Description": "DECOR PEARLS GREEN APPLE BOBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012665001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "POPPSNKC",
        "Last Price Paid": "$30.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012665",
        "Product Status": "Active"
    },
    {
        "Root": "10094989",
        "LV": "1",
        "Item Description": "CUP, MARTINI PLASTIC 7.5OZ CLEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094989001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8867442",
        "Last Price Paid": "$0.80",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094989",
        "Product Status": "Active"
    },
    {
        "Root": "10108488",
        "LV": "1",
        "Item Description": "FLOUR DARK RYE BOBS 4X20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101342",
        "Last Price Paid": "$3.08",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108488",
        "Product Status": "Active"
    },
    {
        "Root": "10010375",
        "LV": "1",
        "Item Description": "ERDINGER DUNKEL HEFEWEIZEN 16.9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "925765",
        "Last Price Paid": "$2.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010375",
        "Product Status": "Active"
    },
    {
        "Root": "10010375",
        "LV": "1",
        "Item Description": "ERDINGER DUNKEL HEFEWEIZEN 16.9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "925765",
        "Last Price Paid": "$2.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010375",
        "Product Status": "Active"
    },
    {
        "Root": "10010865",
        "LV": "2",
        "Item Description": "BEER CAN OLD ELEPHANTS FOOT 16OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010865001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "83663",
        "Last Price Paid": "$2.04",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010865",
        "Product Status": "Active"
    },
    {
        "Root": "10008959",
        "LV": "1",
        "Item Description": "PINOT NOIR M EDWARD OLIVT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008959001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$72.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008959",
        "Product Status": "Active"
    },
    {
        "Root": "10104930",
        "LV": "1",
        "Item Description": "SHOI-MI W/BEEF SHITAKI MUSHROOM & ONION 1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.68",
        "Case Pack": "126",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104930",
        "Product Status": "Active"
    },
    {
        "Root": "10019046",
        "LV": "1",
        "Item Description": "COMPOUND PEACH NATURAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019046001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6900230000",
        "Last Price Paid": "$4.02",
        "Case Pack": "19",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019046",
        "Product Status": "Active"
    },
    {
        "Root": "10081361",
        "LV": "1",
        "Item Description": "CHICKEN THIGHS MARINATED APPLE CIDER VINEGAR 40LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081361001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.96",
        "Case Pack": "43",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081361",
        "Product Status": "Active"
    },
    {
        "Root": "10016303",
        "LV": "1",
        "Item Description": "SEASONING PICKLING BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760645",
        "Last Price Paid": "$11.47",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016303",
        "Product Status": "Active"
    },
    {
        "Root": "10015628",
        "LV": "2",
        "Item Description": "TEQUILA CASA NOBLE EX ANEJO LA CAVA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015628002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "992947",
        "Last Price Paid": "$39.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015628",
        "Product Status": "Active"
    },
    {
        "Root": "10011125",
        "LV": "1",
        "Item Description": "CHEESE NYD BLUE COLSTON BASSETT STILTON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011125001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "14022",
        "Last Price Paid": "$14.15",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011125",
        "Product Status": "Active"
    },
    {
        "Root": "10016029",
        "LV": "2",
        "Item Description": "CUP PORTION 3.25 OZ PLASTIC BLK USE LID 10016030",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016029001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11600323",
        "Last Price Paid": "$0.02",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016029",
        "Product Status": "Active"
    },
    {
        "Root": "10034142",
        "LV": "1",
        "Item Description": "RED BLEND PRISONER 1.5 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "529967",
        "Last Price Paid": "$72.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034142",
        "Product Status": "Active"
    },
    {
        "Root": "10017084",
        "LV": "1",
        "Item Description": "CUPCAKE XTRA LG CHOC UNICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1654",
        "Last Price Paid": "$0.72",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017084",
        "Product Status": "Active"
    },
    {
        "Root": "10092151",
        "LV": "1",
        "Item Description": "COASTER OGAS QR 4 MW 3C/2S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510990",
        "Last Price Paid": "$0.19",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092151",
        "Product Status": "Active"
    },
    {
        "Root": "10023997",
        "LV": "1",
        "Item Description": "CAB SAUV RAYMOND R COLLECTION 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023997001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9257212",
        "Last Price Paid": "$7.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023997",
        "Product Status": "Active"
    },
    {
        "Root": "10002356",
        "LV": "2",
        "Item Description": "ICE CREAM COFFEE EDY 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002356002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$52.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002356",
        "Product Status": "Active"
    },
    {
        "Root": "10002356",
        "LV": "2",
        "Item Description": "ICE CREAM COFFEE EDY 3 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002356002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2262723",
        "Last Price Paid": "$52.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002356",
        "Product Status": "Active"
    },
    {
        "Root": "10040724",
        "LV": "1",
        "Item Description": "LABEL OVAL TRUE GOLD FOIL BLIND EMBOSSED 2.5X1.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040724001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51010277",
        "Last Price Paid": "$0.12",
        "Case Pack": "5000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040724",
        "Product Status": "Active"
    },
    {
        "Root": "10019757",
        "LV": "1",
        "Item Description": "CHARD SOLEIL SANTA LUCIA HIGHL RESERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "575723",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019757",
        "Product Status": "Active"
    },
    {
        "Root": "10019757",
        "LV": "1",
        "Item Description": "CHARD SOLEIL SANTA LUCIA HIGHL RESERVE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019757001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "575723",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019757",
        "Product Status": "Active"
    },
    {
        "Root": "10021770",
        "LV": "1",
        "Item Description": "CUP ALUMINUM 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021770001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51801197",
        "Last Price Paid": "$0.12",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021770",
        "Product Status": "Active"
    },
    {
        "Root": "10022509",
        "LV": "1",
        "Item Description": "WINE CAB SAUV NICKEL NICKEL BEAR TRACK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "530048",
        "Last Price Paid": "$75.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022509",
        "Product Status": "Active"
    },
    {
        "Root": "10035873",
        "LV": "1",
        "Item Description": "CHEESE MANCHEGO 9 MONTH SHEEP SPAIN 6.6 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035873001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "19070",
        "Last Price Paid": "$9.23",
        "Case Pack": "5.9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035873",
        "Product Status": "Active"
    },
    {
        "Root": "10005250",
        "LV": "1",
        "Item Description": "SHELL ECLAIR MINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I011...{PRUVE{",
        "Last Price Paid": "$0.22",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005250",
        "Product Status": "Active"
    },
    {
        "Root": "10006961",
        "LV": "1",
        "Item Description": "PINOT GRIGIO PLACIDO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006961001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346174",
        "Last Price Paid": "$6.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006961",
        "Product Status": "Active"
    },
    {
        "Root": "10004193",
        "LV": "1",
        "Item Description": "DRIED FRUIT GINGER CRYSTALLIZED 11#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585400",
        "Last Price Paid": "$43.64",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004193",
        "Product Status": "Active"
    },
    {
        "Root": "10089200",
        "LV": "1",
        "Item Description": "SIPPER ORB ACID SPITTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.51",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089200",
        "Product Status": "Active"
    },
    {
        "Root": "10004907",
        "LV": "1",
        "Item Description": "COOKIE FIG NEWTON 48/2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004907001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302411",
        "Last Price Paid": "$0.72",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004907",
        "Product Status": "Active"
    },
    {
        "Root": "10004755",
        "LV": "1",
        "Item Description": "SPICE GARLIC SALT MCCORMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "760298",
        "Last Price Paid": "$10.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004755",
        "Product Status": "Active"
    },
    {
        "Root": "10087696",
        "LV": "1",
        "Item Description": "BREAD ROLL SPITZWECK PAR BAKED 240CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087696001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087696",
        "Product Status": "Active"
    },
    {
        "Root": "10002407",
        "LV": "2",
        "Item Description": "CHEESE PROVOLONE 6 LB LOG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002407002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "5750",
        "Last Price Paid": "$3.42",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002407",
        "Product Status": "Active"
    },
    {
        "Root": "10108028",
        "LV": "1",
        "Item Description": "MOCHI PASSION FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "9920FS",
        "Last Price Paid": "$5.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108028",
        "Product Status": "Active"
    },
    {
        "Root": "10005343",
        "LV": "1",
        "Item Description": "DEC GUMPASTE STAR SUGAR SPRAYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8967",
        "Last Price Paid": "$0.39",
        "Case Pack": "45",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005343",
        "Product Status": "Active"
    },
    {
        "Root": "10005934",
        "LV": "1",
        "Item Description": "MIX COOKIE FLORENTINE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "00...{KOMPLET{",
        "Last Price Paid": "$4.98",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005934",
        "Product Status": "Active"
    },
    {
        "Root": "10006286",
        "LV": "1",
        "Item Description": "BOARD CAKE 12 INCH X 1/2 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006286001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1212...{ENJAY{",
        "Last Price Paid": "$2.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006286",
        "Product Status": "Active"
    },
    {
        "Root": "10003789",
        "LV": "1",
        "Item Description": "TOPPING RICH S 12/16OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4000840",
        "Last Price Paid": "$3.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003789",
        "Product Status": "Active"
    },
    {
        "Root": "10012631",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR SLICED WHITE .75OZ CABOT 12/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2432912",
        "Last Price Paid": "$3.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012631",
        "Product Status": "Active"
    },
    {
        "Root": "10012631",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR SLICED WHITE .75OZ CABOT 12/8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "21494",
        "Last Price Paid": "$3.48",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012631",
        "Product Status": "Active"
    },
    {
        "Root": "10002808",
        "LV": "1",
        "Item Description": "RAISIN MIDGET 30LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002808001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002808",
        "Product Status": "Active"
    },
    {
        "Root": "10007863",
        "LV": "2",
        "Item Description": "PINOT NOIR BELLE GLOS LAS ALTURAS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007863002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10007863",
        "Product Status": "Active"
    },
    {
        "Root": "10002135",
        "LV": "1",
        "Item Description": "CHEESE SWISS SLICED .5OZ 4/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002135",
        "Product Status": "Active"
    },
    {
        "Root": "10002135",
        "LV": "1",
        "Item Description": "CHEESE SWISS SLICED .5OZ 4/2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4432878",
        "Last Price Paid": "$7.14",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002135",
        "Product Status": "Active"
    },
    {
        "Root": "10023250",
        "LV": "2",
        "Item Description": "CEREAL ASST WELLNESS CUP 60 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023250002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9422122",
        "Last Price Paid": "$0.96",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10023250",
        "Product Status": "Active"
    },
    {
        "Root": "10106582",
        "LV": "1",
        "Item Description": "COLOR POWDER GLIMMER  PEACH 1.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "313021E",
        "Last Price Paid": "$31.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106582",
        "Product Status": "Active"
    },
    {
        "Root": "10001089",
        "LV": "1",
        "Item Description": "LOBSTER MAINE LIVE CHIXS SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001089001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001089",
        "Product Status": "Active"
    },
    {
        "Root": "10001089",
        "LV": "1",
        "Item Description": "LOBSTER MAINE LIVE CHIXS SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001089001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "69-50100-A",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001089",
        "Product Status": "Active"
    },
    {
        "Root": "10001089",
        "LV": "1",
        "Item Description": "LOBSTER MAINE LIVE CHIXS SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001089001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6545601",
        "Last Price Paid": "$10.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001089",
        "Product Status": "Active"
    },
    {
        "Root": "10038916",
        "LV": "1",
        "Item Description": "DECOR CHOCOLATE 1.25X1.25 INCH SQUARE ART REQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038916001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000425243",
        "Vendor": "CHOCOLATES A LA CARTE",
        "Vendor Item Nb": "5069CP",
        "Last Price Paid": "$0.28",
        "Case Pack": "576",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038916",
        "Product Status": "Active"
    },
    {
        "Root": "10007050",
        "LV": "1",
        "Item Description": "ZINF CLINE ANCNT VINE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "271251",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007050",
        "Product Status": "Active"
    },
    {
        "Root": "10005560",
        "LV": "2",
        "Item Description": "BISCUIT TRANS SENSE 2.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005560002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1317973",
        "Last Price Paid": "$0.25",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005560",
        "Product Status": "Active"
    },
    {
        "Root": "10004700",
        "LV": "1",
        "Item Description": "DRIED FRUIT FIG BLACK MISSION 3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004700001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "283000",
        "Last Price Paid": "$211.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004700",
        "Product Status": "Active"
    },
    {
        "Root": "10002864",
        "LV": "1",
        "Item Description": "HONEY DICKINSON 1.1 OZ JAR 72 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002864001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.63",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002864",
        "Product Status": "Active"
    },
    {
        "Root": "10000795",
        "LV": "1",
        "Item Description": "BEEF STEAMSHIP ROUND CHOICE 80# DN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000795001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1160210",
        "Last Price Paid": "$4.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000795",
        "Product Status": "Active"
    },
    {
        "Root": "10037511",
        "LV": "1",
        "Item Description": "VERDEJO MARQUES DE RISCAL 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037511001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "589477",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037511",
        "Product Status": "Active"
    },
    {
        "Root": "10091355",
        "LV": "1",
        "Item Description": "CHICKEN AIRLINE BREAST 9-10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091355001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "000449",
        "Last Price Paid": "$4.59",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091355",
        "Product Status": "Active"
    },
    {
        "Root": "10033207",
        "LV": "1",
        "Item Description": "YOGURT VANILLA GREEK POUCH 2/6LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9566324",
        "Last Price Paid": "$15.48",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033207",
        "Product Status": "Active"
    },
    {
        "Root": "10002942",
        "LV": "2",
        "Item Description": "SPRAY CANOLA FD RELSE 17Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002942001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9856154",
        "Last Price Paid": "$5.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002942",
        "Product Status": "Active"
    },
    {
        "Root": "10039773",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET PRIME ANGUS 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039773001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195001",
        "Last Price Paid": "$39.93",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039773",
        "Product Status": "Active"
    },
    {
        "Root": "10037042",
        "LV": "1",
        "Item Description": "TEMPRANILLO LUBERRI BIGA RIOJA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "544038",
        "Last Price Paid": "$14.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037042",
        "Product Status": "Active"
    },
    {
        "Root": "10039803",
        "LV": "1",
        "Item Description": "CHOC GINGERBREAD W/HAT 144/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039803001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1889",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039803",
        "Product Status": "Active"
    },
    {
        "Root": "10034445",
        "LV": "1",
        "Item Description": "NEBBIOLO RIZZI LANGHE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034445",
        "Product Status": "Active"
    },
    {
        "Root": "10083950",
        "LV": "1",
        "Item Description": "SB BASE KIWI STARFRUIT 4X REFRESHER 1 LITER 6 CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011125330",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083950",
        "Product Status": "Active"
    },
    {
        "Root": "10002591",
        "LV": "1",
        "Item Description": "PEPPER GREEN DICED 1 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002591001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002591",
        "Product Status": "Active"
    },
    {
        "Root": "10084120",
        "LV": "1",
        "Item Description": "DONUT GLAZED RASPBERRY JELLY DZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084120001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084120",
        "Product Status": "Active"
    },
    {
        "Root": "10040222",
        "LV": "1",
        "Item Description": "MOSCATO FIERA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040222",
        "Product Status": "Active"
    },
    {
        "Root": "10015047",
        "LV": "1",
        "Item Description": "PINEAPPLE BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511033",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015047",
        "Product Status": "Active"
    },
    {
        "Root": "10106378",
        "LV": "1",
        "Item Description": "CUP STEIN VINTAGE PREM EPCOT GERMANY 24OZ 24/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106378",
        "Product Status": "Active"
    },
    {
        "Root": "10032148",
        "LV": "1",
        "Item Description": "QUINOA ANCIENT GRAIN MEDLEY 2-5 POUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "647576",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032148",
        "Product Status": "Active"
    },
    {
        "Root": "10001412",
        "LV": "1",
        "Item Description": "BLOOMS BY GEORGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "05BBG-33",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001412",
        "Product Status": "Active"
    },
    {
        "Root": "10007332",
        "LV": "3",
        "Item Description": "BORDEAUX CH GRUAUD LAROSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007332002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300992",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10007332",
        "Product Status": "Active"
    },
    {
        "Root": "10017267",
        "LV": "1",
        "Item Description": "BEER PURE CIDER ROSE 350 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14227",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017267",
        "Product Status": "Active"
    },
    {
        "Root": "10010867",
        "LV": "2",
        "Item Description": "BEER CIGAR CTY INVSIN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010867001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32530",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010867",
        "Product Status": "Active"
    },
    {
        "Root": "10008485",
        "LV": "2",
        "Item Description": "CAB CH TANUNDA NBL BARON 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008485001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "345900",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008485",
        "Product Status": "Active"
    },
    {
        "Root": "10009971",
        "LV": "1",
        "Item Description": "LIQ RUMCHATA CRM LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009971",
        "Product Status": "Active"
    },
    {
        "Root": "10013984",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013984001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "96394",
        "Last Price Paid": "$87.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013984",
        "Product Status": "Active"
    },
    {
        "Root": "10102925",
        "LV": "1",
        "Item Description": "KOSHER BSC CHEESECAKE 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102925001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102925",
        "Product Status": "Active"
    },
    {
        "Root": "10010259",
        "LV": "2",
        "Item Description": "DRAFT 3 DAUGHTR BIMN 1/6BL IPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010259001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "81006",
        "Last Price Paid": "$87.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010259",
        "Product Status": "Active"
    },
    {
        "Root": "10019212",
        "LV": "1",
        "Item Description": "DUCK WHL 5.25-5.75 LB ROHAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019212001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "DUCROH006",
        "Last Price Paid": "$5.75",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019212",
        "Product Status": "Active"
    },
    {
        "Root": "10004272",
        "LV": "1",
        "Item Description": "OLIVE OIL TUNISIAN 17 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004272001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01155",
        "Last Price Paid": "$195.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004272",
        "Product Status": "Active"
    },
    {
        "Root": "10004199",
        "LV": "1",
        "Item Description": "OIL OLIVE BLOOD ORNG 8.8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01040",
        "Last Price Paid": "$15.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004199",
        "Product Status": "Active"
    },
    {
        "Root": "10004199",
        "LV": "1",
        "Item Description": "OIL OLIVE BLOOD ORNG 8.8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OL125",
        "Last Price Paid": "$15.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004199",
        "Product Status": "Active"
    },
    {
        "Root": "10014145",
        "LV": "1",
        "Item Description": "SPROUTS BRUSSELS MIXED BABY FLAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014145001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07MBS-13",
        "Last Price Paid": "$145.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014145",
        "Product Status": "Active"
    },
    {
        "Root": "10033772",
        "LV": "1",
        "Item Description": "KANONKOP ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "762386",
        "Last Price Paid": "$11.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033772",
        "Product Status": "Active"
    },
    {
        "Root": "10035202",
        "LV": "1",
        "Item Description": "BOEKENHOUTSKLOOF CABERNET STELLENBOSCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035202001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9264088",
        "Last Price Paid": "$47.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035202",
        "Product Status": "Active"
    },
    {
        "Root": "10100648",
        "LV": "1",
        "Item Description": "WINE DOM R BOUTIRES POUILLY FUISSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100648001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9338652",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100648",
        "Product Status": "Active"
    },
    {
        "Root": "10030357",
        "LV": "1",
        "Item Description": "CABERNET HEITZ MARTHAS 2010 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030357001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "585739",
        "Last Price Paid": "$224.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030357",
        "Product Status": "Active"
    },
    {
        "Root": "10021485",
        "LV": "1",
        "Item Description": "RIES SELBACH OSTER SONNENUHR SPATLESE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021485001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069637",
        "Vendor": "IMPORT ANT WINES INCE",
        "Vendor Item Nb": "16692",
        "Last Price Paid": "$19.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021485",
        "Product Status": "Active"
    },
    {
        "Root": "10004535",
        "LV": "1",
        "Item Description": "ARTICHOKE SALAD PIECES #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2333581",
        "Last Price Paid": "$13.22",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004535",
        "Product Status": "Active"
    },
    {
        "Root": "10025218",
        "LV": "1",
        "Item Description": "SAUCE CHEESE CHEDDAR CUP 72 / 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.40",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025218",
        "Product Status": "Active"
    },
    {
        "Root": "10003037",
        "LV": "1",
        "Item Description": "BEEF CORNED HASH (6/CS)",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003037001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.36",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003037",
        "Product Status": "Active"
    },
    {
        "Root": "10005841",
        "LV": "1",
        "Item Description": "CAKE DECORATED HALF SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$69.97",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005841",
        "Product Status": "Active"
    },
    {
        "Root": "10034792",
        "LV": "1",
        "Item Description": "PORK COLLAR SLICED EDDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034792001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.02",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034792",
        "Product Status": "Active"
    },
    {
        "Root": "10004083",
        "LV": "1",
        "Item Description": "FLOUR CAPUTO 00 MILLED 50LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004083001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.44",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004083",
        "Product Status": "Active"
    },
    {
        "Root": "10006336",
        "LV": "1",
        "Item Description": "BOX BAKERY WINDOW 8X8X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5643341",
        "Last Price Paid": "$1.58",
        "Case Pack": "75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006336",
        "Product Status": "Active"
    },
    {
        "Root": "10000324",
        "LV": "1",
        "Item Description": "PEPPER RED 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "514500",
        "Last Price Paid": "$16.63",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000324",
        "Product Status": "Active"
    },
    {
        "Root": "10000324",
        "LV": "1",
        "Item Description": "PEPPER RED 1/2 INCH DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "08-001-12",
        "Last Price Paid": "$16.63",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000324",
        "Product Status": "Active"
    },
    {
        "Root": "10003183",
        "LV": "1",
        "Item Description": "SUGAR SANDING YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8785",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003183",
        "Product Status": "Active"
    },
    {
        "Root": "10018309",
        "LV": "2",
        "Item Description": "TORTILLA FLOUR 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018309001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2484573",
        "Last Price Paid": "$0.17",
        "Case Pack": "192",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10018309",
        "Product Status": "Active"
    },
    {
        "Root": "10031978",
        "LV": "1",
        "Item Description": "SB BLUEBERRY SCONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11085884",
        "Last Price Paid": "$1.28",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031978",
        "Product Status": "Active"
    },
    {
        "Root": "10004256",
        "LV": "2",
        "Item Description": "SB LOAF PUMPKIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004256001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11039079",
        "Last Price Paid": "$1.11",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004256",
        "Product Status": "Active"
    },
    {
        "Root": "10032127",
        "LV": "1",
        "Item Description": "MIX FREESTYLE CAFFEINE FREE COKE 36OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032127001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7036702",
        "Last Price Paid": "$50.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032127",
        "Product Status": "Active"
    },
    {
        "Root": "10093610",
        "LV": "1",
        "Item Description": "BAR NON ALC SPIRIT LYRES AGAVE RESERVA 700ML/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9677681",
        "Last Price Paid": "$20.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093610",
        "Product Status": "Active"
    },
    {
        "Root": "10009253",
        "LV": "2",
        "Item Description": "CAB SAUV DOM MELCHOR C Y TORO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009253001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "130890",
        "Last Price Paid": "$90.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009253",
        "Product Status": "Active"
    },
    {
        "Root": "10007837",
        "LV": "1",
        "Item Description": "CAB SAUV JARVIS LK WMS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "975113",
        "Last Price Paid": "$175.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007837",
        "Product Status": "Active"
    },
    {
        "Root": "10007837",
        "LV": "1",
        "Item Description": "CAB SAUV JARVIS LK WMS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007837001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "975113",
        "Last Price Paid": "$175.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007837",
        "Product Status": "Active"
    },
    {
        "Root": "10044124",
        "LV": "1",
        "Item Description": "BEER AYINGER CELEBRATOR 30L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2839",
        "Last Price Paid": "$164.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044124",
        "Product Status": "Active"
    },
    {
        "Root": "10044124",
        "LV": "1",
        "Item Description": "BEER AYINGER CELEBRATOR 30L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044124001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "2839",
        "Last Price Paid": "$164.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044124",
        "Product Status": "Active"
    },
    {
        "Root": "10002736",
        "LV": "1",
        "Item Description": "POP TART STRAWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1206424",
        "Last Price Paid": "$0.50",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002736",
        "Product Status": "Active"
    },
    {
        "Root": "10003551",
        "LV": "1",
        "Item Description": "SPICE ALLSPICE WHL 6/.75LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.44",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003551",
        "Product Status": "Active"
    },
    {
        "Root": "10095088",
        "LV": "1",
        "Item Description": "MUSTARD, DIJON FRENCH'S 6/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095088001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7162068",
        "Last Price Paid": "$7.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095088",
        "Product Status": "Active"
    },
    {
        "Root": "10010136",
        "LV": "2",
        "Item Description": "WATER VICHY CATALAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010136001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.78",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010136",
        "Product Status": "Active"
    },
    {
        "Root": "10016332",
        "LV": "1",
        "Item Description": "CUPCAKE MED YELL ICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016332001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1642",
        "Last Price Paid": "$0.62",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016332",
        "Product Status": "Active"
    },
    {
        "Root": "10107330",
        "LV": "2",
        "Item Description": "RIESLING PACIFIC RIM SOLSTICE 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107330002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "14587",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107330",
        "Product Status": "Active"
    },
    {
        "Root": "10003842",
        "LV": "1",
        "Item Description": "BAR PEANUT BTTR COOK LARA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003842001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "825984",
        "Last Price Paid": "$1.58",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003842",
        "Product Status": "Active"
    },
    {
        "Root": "10104548",
        "LV": "1",
        "Item Description": "NOODLES CHOW MEIN BULK SALAD TOPPING 15LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104548001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104548",
        "Product Status": "Active"
    },
    {
        "Root": "10036896",
        "LV": "2",
        "Item Description": "BAKING CUP LILAC SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036896002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "45906",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036896",
        "Product Status": "Active"
    },
    {
        "Root": "10031975",
        "LV": "1",
        "Item Description": "SCOTCH JOHNNIE WALKER 18YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "893301",
        "Last Price Paid": "$87.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031975",
        "Product Status": "Active"
    },
    {
        "Root": "10008279",
        "LV": "1",
        "Item Description": "SPARK ARGYLE BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "786047",
        "Last Price Paid": "$16.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008279",
        "Product Status": "Active"
    },
    {
        "Root": "10004062",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE PINK 2#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004062001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004062",
        "Product Status": "Active"
    },
    {
        "Root": "10038175",
        "LV": "2",
        "Item Description": "CHOCOLATE RUBY CLUB 33",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038175002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "CR36RB",
        "Last Price Paid": "$40.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038175",
        "Product Status": "Active"
    },
    {
        "Root": "10012063",
        "LV": "1",
        "Item Description": "BEER XINGU BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012063001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9845",
        "Last Price Paid": "$1.80",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012063",
        "Product Status": "Active"
    },
    {
        "Root": "10036712",
        "LV": "1",
        "Item Description": "PASTRY CINNAMON ROLL MINI 1.8Z BUTTER RTB 80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.41",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036712",
        "Product Status": "Active"
    },
    {
        "Root": "10009975",
        "LV": "1",
        "Item Description": "LIQ COINTREAU NOIR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$30.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009975",
        "Product Status": "Active"
    },
    {
        "Root": "10093212",
        "LV": "1",
        "Item Description": "BONBON CHOCOLATE DARK SALTED  CARAMEL 100 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA8882",
        "Last Price Paid": "$0.67",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093212",
        "Product Status": "Active"
    },
    {
        "Root": "10003708",
        "LV": "3",
        "Item Description": "VINEGAR SHERRY MAILLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003708002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7090580",
        "Last Price Paid": "$26.47",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10003708",
        "Product Status": "Active"
    },
    {
        "Root": "10010246",
        "LV": "2",
        "Item Description": "DRAFT GOOSE ISL IPA HBBL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010246001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21402",
        "Last Price Paid": "$157.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010246",
        "Product Status": "Active"
    },
    {
        "Root": "10105891",
        "LV": "1",
        "Item Description": "JUICE PG WATERMELON 6/16",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105891001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "32170",
        "Last Price Paid": "$4.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105891",
        "Product Status": "Active"
    },
    {
        "Root": "10029278",
        "LV": "1",
        "Item Description": "PORK BANGERS 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029278001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "308A",
        "Last Price Paid": "$4.30",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029278",
        "Product Status": "Active"
    },
    {
        "Root": "10041057",
        "LV": "1",
        "Item Description": "CHEESE DRUNKEN GOAT SPAIN 2/5 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041057001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "69692",
        "Last Price Paid": "$14.43",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041057",
        "Product Status": "Active"
    },
    {
        "Root": "10036375",
        "LV": "1",
        "Item Description": "COOKIE MERINGUE DELOS BONE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "BONES",
        "Last Price Paid": "$0.50",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036375",
        "Product Status": "Active"
    },
    {
        "Root": "10004609",
        "LV": "1",
        "Item Description": "PASTE TAHINI 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7340787",
        "Last Price Paid": "$4.55",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004609",
        "Product Status": "Active"
    },
    {
        "Root": "10013064",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED COUPOLE GOAT VERMONT CREAMERY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "11828",
        "Last Price Paid": "$57.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013064",
        "Product Status": "Active"
    },
    {
        "Root": "10017888",
        "LV": "1",
        "Item Description": "CHICKEN THIGH SKIN ON BONE IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017888001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.80",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017888",
        "Product Status": "Active"
    },
    {
        "Root": "10034100",
        "LV": "2",
        "Item Description": "STRAWBERRY COMPOUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034100002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "0111080000",
        "Last Price Paid": "$77.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034100",
        "Product Status": "Active"
    },
    {
        "Root": "10090951",
        "LV": "1",
        "Item Description": "SB TOASTED VANILLA SYRUP 1L 6-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11133529",
        "Last Price Paid": "$11.23",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090951",
        "Product Status": "Active"
    },
    {
        "Root": "10014532",
        "LV": "1",
        "Item Description": "FRUIT MIX CANTALOUPE HONEYDEW PINEAPPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014532001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592025",
        "Last Price Paid": "$40.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014532",
        "Product Status": "Active"
    },
    {
        "Root": "10040386",
        "LV": "1",
        "Item Description": "LINER TRAY MAP BBQ LG 11.75X8.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51006599",
        "Last Price Paid": "$0.13",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040386",
        "Product Status": "Active"
    },
    {
        "Root": "10006375",
        "LV": "1",
        "Item Description": "FILM PVC 24 INCH X 2000",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006375",
        "Product Status": "Active"
    },
    {
        "Root": "10042185",
        "LV": "1",
        "Item Description": "CUP PORTION 20Z CLEAR PLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042185001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.05",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042185",
        "Product Status": "Active"
    },
    {
        "Root": "10001104",
        "LV": "1",
        "Item Description": "OYSTER SHUCKED SELECT 120 CT 8 LB SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "67-60017",
        "Last Price Paid": "$0.81",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001104",
        "Product Status": "Active"
    },
    {
        "Root": "10001104",
        "LV": "1",
        "Item Description": "OYSTER SHUCKED SELECT 120 CT 8 LB SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.81",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001104",
        "Product Status": "Active"
    },
    {
        "Root": "10001104",
        "LV": "1",
        "Item Description": "OYSTER SHUCKED SELECT 120 CT 8 LB SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001104001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "1283183",
        "Last Price Paid": "$0.81",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001104",
        "Product Status": "Active"
    },
    {
        "Root": "10019755",
        "LV": "1",
        "Item Description": "CHARD MER SOLEIL SEA-SUN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019755001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "572566",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019755",
        "Product Status": "Active"
    },
    {
        "Root": "10017551",
        "LV": "1",
        "Item Description": "SHISO RED & GRN KOP LIVING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LEASHISO",
        "Last Price Paid": "$13.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017551",
        "Product Status": "Active"
    },
    {
        "Root": "10017551",
        "LV": "1",
        "Item Description": "SHISO RED & GRN KOP LIVING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482740",
        "Last Price Paid": "$13.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017551",
        "Product Status": "Active"
    },
    {
        "Root": "10025577",
        "LV": "1",
        "Item Description": "TRAY COMPOSTABLE PAPER 10X7.5X1.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025577001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11400016",
        "Last Price Paid": "$0.23",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025577",
        "Product Status": "Active"
    },
    {
        "Root": "10091228",
        "LV": "1",
        "Item Description": "GELATO PLANT BASED BLACKBERRY CUPS STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091228001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "P-BKBY-02-CS24",
        "Last Price Paid": "$1.65",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091228",
        "Product Status": "Active"
    },
    {
        "Root": "10034194",
        "LV": "1",
        "Item Description": "PUREE KALAMANSI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "B664",
        "Last Price Paid": "$16.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034194",
        "Product Status": "Active"
    },
    {
        "Root": "10016432",
        "LV": "1",
        "Item Description": "CUP BAKE BRN 2.25X1.9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51408038",
        "Last Price Paid": "$0.02",
        "Case Pack": "11400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016432",
        "Product Status": "Active"
    },
    {
        "Root": "10035706",
        "LV": "1",
        "Item Description": "CAB SAUV TOKARA TELOS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "702200",
        "Last Price Paid": "$415.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035706",
        "Product Status": "Active"
    },
    {
        "Root": "10003506",
        "LV": "2",
        "Item Description": "TEA HARMONY HRB 7.05OZ LS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003506001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W1116",
        "Last Price Paid": "$20.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003506",
        "Product Status": "Active"
    },
    {
        "Root": "10000108",
        "LV": "1",
        "Item Description": "BEEF ANGUS CHUCK BURGER 5.33 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000108001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1090015",
        "Last Price Paid": "$4.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000108",
        "Product Status": "Active"
    },
    {
        "Root": "10009494",
        "LV": "1",
        "Item Description": "BOURBON RED STAG BLK CHRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$20.38",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009494",
        "Product Status": "Active"
    },
    {
        "Root": "10089198",
        "LV": "1",
        "Item Description": "BAG GROCERY KRAFT 2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089198001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "630550",
        "Last Price Paid": "$0.02",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089198",
        "Product Status": "Active"
    },
    {
        "Root": "10002076",
        "LV": "1",
        "Item Description": "RICE WILD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002076001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002076",
        "Product Status": "Active"
    },
    {
        "Root": "10031605",
        "LV": "1",
        "Item Description": "OLLI SOPRESSATA SALAMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "77744",
        "Last Price Paid": "$7.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031605",
        "Product Status": "Active"
    },
    {
        "Root": "10028466",
        "LV": "1",
        "Item Description": "CAVIAR ROYAL GALILEE OSETRA SQS 30G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028466001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "61720040",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028466",
        "Product Status": "Active"
    },
    {
        "Root": "10000199",
        "LV": "1",
        "Item Description": "ASPARAGUS MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481800",
        "Last Price Paid": "$36.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000199",
        "Product Status": "Active"
    },
    {
        "Root": "10000199",
        "LV": "1",
        "Item Description": "ASPARAGUS MEDIUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000199001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20017",
        "Last Price Paid": "$36.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000199",
        "Product Status": "Active"
    },
    {
        "Root": "10005897",
        "LV": "1",
        "Item Description": "BREAD SOURDOUGH 3#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005897",
        "Product Status": "Active"
    },
    {
        "Root": "10000247",
        "LV": "1",
        "Item Description": "PEPPER SHISITO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000247001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000247",
        "Product Status": "Active"
    },
    {
        "Root": "10091868",
        "LV": "1",
        "Item Description": "SHRIMP 6-8CT GH SHELL ON 6X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091868001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6400000",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091868",
        "Product Status": "Active"
    },
    {
        "Root": "10019218",
        "LV": "1",
        "Item Description": "WHITE BLEND SARTORI MARANI VERONESE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "993568",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019218",
        "Product Status": "Active"
    },
    {
        "Root": "10038211",
        "LV": "2",
        "Item Description": "SORBET STRAWBERRY CASE 4 HALF GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038211002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "SOR-STWB-08-CS",
        "Last Price Paid": "",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038211",
        "Product Status": "Active"
    },
    {
        "Root": "10017017",
        "LV": "1",
        "Item Description": "CUPCAKE JUMBO SMORES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017017001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069834",
        "Vendor": "CALIGIURI CORP / EMPRESS SISSI",
        "Vendor Item Nb": "947",
        "Last Price Paid": "",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017017",
        "Product Status": "Active"
    },
    {
        "Root": "10001637",
        "LV": "1",
        "Item Description": "MUSHROOM YUNAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001637001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481159",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001637",
        "Product Status": "Active"
    },
    {
        "Root": "10110032",
        "LV": "1",
        "Item Description": "RUGELACH 70 COUNT BOX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS601",
        "Last Price Paid": "",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110032",
        "Product Status": "Active"
    },
    {
        "Root": "10092932",
        "LV": "1",
        "Item Description": "JERKY TURKEY 12/2.5 OZ BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092932001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1950305",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092932",
        "Product Status": "Active"
    },
    {
        "Root": "10024117",
        "LV": "1",
        "Item Description": "OIL OLIVE FRANTONIA 3 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024117001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OIL905",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024117",
        "Product Status": "Active"
    },
    {
        "Root": "10018791",
        "LV": "2",
        "Item Description": "DECOR DISCO SPARKLES PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018791002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "315105C",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018791",
        "Product Status": "Active"
    },
    {
        "Root": "10083784",
        "LV": "1",
        "Item Description": "PEANUT BUTTER POWDER 6/6/5 OZ CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083784001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083784",
        "Product Status": "Active"
    },
    {
        "Root": "10009634",
        "LV": "1",
        "Item Description": "VODKA CIROC LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "314833",
        "Last Price Paid": "$31.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009634",
        "Product Status": "Active"
    },
    {
        "Root": "10009634",
        "LV": "1",
        "Item Description": "VODKA CIROC LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "314833",
        "Last Price Paid": "$31.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009634",
        "Product Status": "Active"
    },
    {
        "Root": "10010054",
        "LV": "1",
        "Item Description": "BEER COORS LT ALUM CAN 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.05",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010054",
        "Product Status": "Active"
    },
    {
        "Root": "10037826",
        "LV": "1",
        "Item Description": "TOPPING CHOCOLATE MINT CREME PIECES ANDES 20LB BU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7145980",
        "Last Price Paid": "$79.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037826",
        "Product Status": "Active"
    },
    {
        "Root": "10002947",
        "LV": "1",
        "Item Description": "COFFEE GROUND REG 7OZ/24CT JOFFREYS WDW BLEND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002947001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.71",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002947",
        "Product Status": "Active"
    },
    {
        "Root": "10013006",
        "LV": "1",
        "Item Description": "TOMATO, HUCKLEBERRY 1/2PT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "17HB-12",
        "Last Price Paid": "$28.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013006",
        "Product Status": "Active"
    },
    {
        "Root": "10030626",
        "LV": "1",
        "Item Description": "PUMPKIN MUSQUEE DE PROVENCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482700",
        "Last Price Paid": "$52.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030626",
        "Product Status": "Active"
    },
    {
        "Root": "10002861",
        "LV": "1",
        "Item Description": "COKE DIET 2 LTR 8/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002861001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.22",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002861",
        "Product Status": "Active"
    },
    {
        "Root": "10012988",
        "LV": "1",
        "Item Description": "DASANI BOTTLED WATER 1 LITER /12 PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012988001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012988",
        "Product Status": "Active"
    },
    {
        "Root": "10107791",
        "LV": "1",
        "Item Description": "JAR MASON 16OZ \"BREW-WING\" F&W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107791001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "1100160119",
        "Last Price Paid": "$1.10",
        "Case Pack": "196",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107791",
        "Product Status": "Active"
    },
    {
        "Root": "10036127",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP MANGO 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036127001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.78",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036127",
        "Product Status": "Active"
    },
    {
        "Root": "10035133",
        "LV": "1",
        "Item Description": "SYRUP MONIN BLUEBERRY L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035133001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5008",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035133",
        "Product Status": "Active"
    },
    {
        "Root": "10010547",
        "LV": "1",
        "Item Description": "RAYMOND R CHARDONNAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9257213",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010547",
        "Product Status": "Active"
    },
    {
        "Root": "10002815",
        "LV": "1",
        "Item Description": "OIL CANOLA SALAD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002815001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$34.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002815",
        "Product Status": "Active"
    },
    {
        "Root": "10002405",
        "LV": "3",
        "Item Description": "CHEESE BLUE SAINT PETES SELECT 2/5 LB WHEELS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002405003003",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "93026",
        "Last Price Paid": "$9.62",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002405",
        "Product Status": "Active"
    },
    {
        "Root": "10088621",
        "LV": "1",
        "Item Description": "MIX SOFT SERVE GINGER BREAD GELATO 6 HALF GAL JUGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088621001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-AGRB-11-MX-C",
        "Last Price Paid": "$11.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088621",
        "Product Status": "Active"
    },
    {
        "Root": "10031964",
        "LV": "1",
        "Item Description": "CANTEEN ANIMAL KINGDOM COVER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "CB-CANT",
        "Last Price Paid": "$3.91",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031964",
        "Product Status": "Active"
    },
    {
        "Root": "10007283",
        "LV": "1",
        "Item Description": "MERLOT FREEMRK ABBEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007283001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "566051",
        "Last Price Paid": "$30.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007283",
        "Product Status": "Active"
    },
    {
        "Root": "10018087",
        "LV": "1",
        "Item Description": "SAUV BLANC MATETIC EQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018087001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "786666",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018087",
        "Product Status": "Active"
    },
    {
        "Root": "10000349",
        "LV": "1",
        "Item Description": "HERB CHIVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000349001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "24050",
        "Last Price Paid": "$12.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000349",
        "Product Status": "Active"
    },
    {
        "Root": "10000349",
        "LV": "1",
        "Item Description": "HERB CHIVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000349001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000349",
        "Product Status": "Active"
    },
    {
        "Root": "10000349",
        "LV": "1",
        "Item Description": "HERB CHIVES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000349001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482206",
        "Last Price Paid": "$12.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000349",
        "Product Status": "Active"
    },
    {
        "Root": "10076867",
        "LV": "1",
        "Item Description": "MILK COCONUT ASEPTIC SO DELICIOUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "136629",
        "Last Price Paid": "$2.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10076867",
        "Product Status": "Active"
    },
    {
        "Root": "10000228",
        "LV": "3",
        "Item Description": "FRUIT CUP PINEAPPLE 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000228003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489759",
        "Last Price Paid": "$1.61",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10000228",
        "Product Status": "Active"
    },
    {
        "Root": "10039678",
        "LV": "1",
        "Item Description": "KETCHUP HEINZ SMP ALL NATURAL 20 OZ BOTTLE 12/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039678001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039678",
        "Product Status": "Active"
    },
    {
        "Root": "10023355",
        "LV": "2",
        "Item Description": "POTATO FRYER 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023355001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10023355",
        "Product Status": "Active"
    },
    {
        "Root": "10000333",
        "LV": "1",
        "Item Description": "CABBAGE SHRED WHT 1/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "11-002S",
        "Last Price Paid": "$4.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000333",
        "Product Status": "Active"
    },
    {
        "Root": "10000333",
        "LV": "1",
        "Item Description": "CABBAGE SHRED WHT 1/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511515",
        "Last Price Paid": "$4.40",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000333",
        "Product Status": "Active"
    },
    {
        "Root": "10028579",
        "LV": "2",
        "Item Description": "LIQUEUR BAILEYS ALMANDE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028579001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "412715",
        "Last Price Paid": "$21.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10028579",
        "Product Status": "Active"
    },
    {
        "Root": "10033450",
        "LV": "2",
        "Item Description": "BLEND TENUTA LE VOLTE DELL ORNELLAIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033450002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9014247",
        "Last Price Paid": "$19.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033450",
        "Product Status": "Active"
    },
    {
        "Root": "10011901",
        "LV": "1",
        "Item Description": "SB WDW 12OZ LID HOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011901001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "DDL12SBUXPP",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011901",
        "Product Status": "Active"
    },
    {
        "Root": "10012126",
        "LV": "1",
        "Item Description": "CLAM RAZOR SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012126001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "325",
        "Last Price Paid": "$21.95",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012126",
        "Product Status": "Active"
    },
    {
        "Root": "10000646",
        "LV": "1",
        "Item Description": "BOLOGNA RING STYLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000646001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "313",
        "Last Price Paid": "$2.91",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000646",
        "Product Status": "Active"
    },
    {
        "Root": "10007346",
        "LV": "1",
        "Item Description": "MOSCATO LA PERLINA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007346001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9150613",
        "Last Price Paid": "$7.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007346",
        "Product Status": "Active"
    },
    {
        "Root": "10002692",
        "LV": "1",
        "Item Description": "CEREAL GRANOLA LOWFAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002692001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.44",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002692",
        "Product Status": "Active"
    },
    {
        "Root": "10001924",
        "LV": "1",
        "Item Description": "DATE MEDJOOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "593099",
        "Last Price Paid": "$42.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001924",
        "Product Status": "Active"
    },
    {
        "Root": "10001924",
        "LV": "1",
        "Item Description": "DATE MEDJOOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001924",
        "Product Status": "Active"
    },
    {
        "Root": "10001924",
        "LV": "1",
        "Item Description": "DATE MEDJOOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001924001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "14301",
        "Last Price Paid": "$42.78",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001924",
        "Product Status": "Active"
    },
    {
        "Root": "10088608",
        "LV": "1",
        "Item Description": "SYRUP JOGAN FRUIT 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000534944",
        "Vendor": "SENSIENT TECHNOLOGIES CORP FLAVORS",
        "Vendor Item Nb": "SN3000004287",
        "Last Price Paid": "$138.07",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088608",
        "Product Status": "Active"
    },
    {
        "Root": "10088605",
        "LV": "1",
        "Item Description": "SYRUP MATCHA FLAVORED 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000534944",
        "Vendor": "SENSIENT TECHNOLOGIES CORP FLAVORS",
        "Vendor Item Nb": "SN3000004416",
        "Last Price Paid": "$26.43",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088605",
        "Product Status": "Active"
    },
    {
        "Root": "10041034",
        "LV": "4",
        "Item Description": "BREAD BUN HOT DOG POTATO 5.5IN 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041034002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000404845",
        "Vendor": "CAMPAGNA-TURANO BAKERY INC",
        "Vendor Item Nb": "35753",
        "Last Price Paid": "$0.11",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10041034",
        "Product Status": "Active"
    },
    {
        "Root": "10086669",
        "LV": "1",
        "Item Description": "FLUTE JR 6OZ CLEAR 252CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086669001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510270",
        "Last Price Paid": "$0.41",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086669",
        "Product Status": "Active"
    },
    {
        "Root": "10037933",
        "LV": "1",
        "Item Description": "BAKING CHIP MINI SEMI SWT 5LB 4/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037933001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "F00542W",
        "Last Price Paid": "$27.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037933",
        "Product Status": "Active"
    },
    {
        "Root": "10003772",
        "LV": "1",
        "Item Description": "JUICE TRUFFLE 14.OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003772001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "TF062",
        "Last Price Paid": "$60.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003772",
        "Product Status": "Active"
    },
    {
        "Root": "10014057",
        "LV": "2",
        "Item Description": "COCOA BUTTER ECLIPSE BLK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014057002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302155C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014057",
        "Product Status": "Active"
    },
    {
        "Root": "10008135",
        "LV": "1",
        "Item Description": "SAUV BLC SPY VALLEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "677543",
        "Last Price Paid": "$12.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008135",
        "Product Status": "Active"
    },
    {
        "Root": "10001747",
        "LV": "1",
        "Item Description": "BEET GOLD LARGE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "402008",
        "Last Price Paid": "$24.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001747",
        "Product Status": "Active"
    },
    {
        "Root": "10001747",
        "LV": "1",
        "Item Description": "BEET GOLD LARGE 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001747001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "BEETGD",
        "Last Price Paid": "$24.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001747",
        "Product Status": "Active"
    },
    {
        "Root": "10102908",
        "LV": "1",
        "Item Description": "KOSHER BSC OMELET CHEESE 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.72",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102908",
        "Product Status": "Active"
    },
    {
        "Root": "10035611",
        "LV": "1",
        "Item Description": "LID CLEAR MICROLITE DELI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51026300",
        "Last Price Paid": "$0.05",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035611",
        "Product Status": "Active"
    },
    {
        "Root": "10004150",
        "LV": "1",
        "Item Description": "SALT SEA MALDON 1.5KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-45185",
        "Last Price Paid": "$28.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004150",
        "Product Status": "Active"
    },
    {
        "Root": "10004638",
        "LV": "1",
        "Item Description": "CHIP TORTILLA TRI COLOR T",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004638001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3144769",
        "Last Price Paid": "$5.94",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004638",
        "Product Status": "Active"
    },
    {
        "Root": "10006377",
        "LV": "1",
        "Item Description": "FOIL 18 INCH X1000 STD WT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4012845",
        "Last Price Paid": "$107.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006377",
        "Product Status": "Active"
    },
    {
        "Root": "10043186",
        "LV": "1",
        "Item Description": "CHEESE MT TAM SOFT RIPENED COWS MILK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "94637",
        "Last Price Paid": "$9.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10043186",
        "Product Status": "Active"
    },
    {
        "Root": "10091305",
        "LV": "1",
        "Item Description": "WINE RTD RAMONA SPRITZ MEYER LEMON 250ML/48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9625540",
        "Last Price Paid": "$2.69",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091305",
        "Product Status": "Active"
    },
    {
        "Root": "10007847",
        "LV": "2",
        "Item Description": "RED SPICE ROUTE CHAKALAKA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007847001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2165",
        "Last Price Paid": "$15.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007847",
        "Product Status": "Active"
    },
    {
        "Root": "10101448",
        "LV": "1",
        "Item Description": "CLEANING MILK TABLETS EGRO ESPRESSO 50CT 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101448001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "RANC-69000426",
        "Last Price Paid": "$44.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101448",
        "Product Status": "Active"
    },
    {
        "Root": "10005144",
        "LV": "1",
        "Item Description": "NUT MIXED DELUXE ROASTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005144001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "100720",
        "Last Price Paid": "$162.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005144",
        "Product Status": "Active"
    },
    {
        "Root": "10004713",
        "LV": "1",
        "Item Description": "POPCORN WHITE CHEDDAR .62",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004713001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2315307",
        "Last Price Paid": "$0.35",
        "Case Pack": "104",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004713",
        "Product Status": "Active"
    },
    {
        "Root": "10000827",
        "LV": "3",
        "Item Description": "CHICK CUT MARN 8 PC 2.25-2.5 #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000827002003",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.63",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10000827",
        "Product Status": "Active"
    },
    {
        "Root": "10024636",
        "LV": "1",
        "Item Description": "CALIFORNIA PREMIUM MIXERS LEMONADE BNB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "168867",
        "Last Price Paid": "$51.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024636",
        "Product Status": "Active"
    },
    {
        "Root": "10006321",
        "LV": "1",
        "Item Description": "PAPER DELI 15 INCH X10.75 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006321001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2402188",
        "Last Price Paid": "$0.02",
        "Case Pack": "6000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006321",
        "Product Status": "Active"
    },
    {
        "Root": "10102703",
        "LV": "1",
        "Item Description": "COGNAC COURVOISIER VS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102703001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$23.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102703",
        "Product Status": "Active"
    },
    {
        "Root": "10027432",
        "LV": "2",
        "Item Description": "CAKE WHITE CHOCOLATE MICKEY DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027432002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "DOME-WH",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027432",
        "Product Status": "Active"
    },
    {
        "Root": "10027432",
        "LV": "2",
        "Item Description": "CAKE WHITE CHOCOLATE MICKEY DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027432002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027432",
        "Product Status": "Active"
    },
    {
        "Root": "10004694",
        "LV": "1",
        "Item Description": "CANDY CHOCOLATE COVERED RAISINS 30# BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004694001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "503370",
        "Last Price Paid": "$122.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004694",
        "Product Status": "Active"
    },
    {
        "Root": "10025116",
        "LV": "1",
        "Item Description": "PINOTAGE PAINTED WOLF GUILLERMO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "PAIWOLGUI",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025116",
        "Product Status": "Active"
    },
    {
        "Root": "10032929",
        "LV": "1",
        "Item Description": "BOX GABLE KRAFT S-9615 9X6X6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "426-057",
        "Last Price Paid": "$0.81",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032929",
        "Product Status": "Active"
    },
    {
        "Root": "10004688",
        "LV": "1",
        "Item Description": "CANDY CHOCOLATE COVERED ALMONDS 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "550520",
        "Last Price Paid": "$135.71",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004688",
        "Product Status": "Active"
    },
    {
        "Root": "10009597",
        "LV": "1",
        "Item Description": "TEQ DON JULIO REPOSADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009597001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "971837",
        "Last Price Paid": "$45.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009597",
        "Product Status": "Active"
    },
    {
        "Root": "10013216",
        "LV": "1",
        "Item Description": "CIDER REKORDERLIG PEAR CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "14577",
        "Last Price Paid": "$2.31",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013216",
        "Product Status": "Active"
    },
    {
        "Root": "10005566",
        "LV": "1",
        "Item Description": "PUFF PASTRY SHEET DOUGH 16X12 IN 36 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005566001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.97",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005566",
        "Product Status": "Active"
    },
    {
        "Root": "10037240",
        "LV": "1",
        "Item Description": "CANDY STRAW MARSHMALLOW MICKEY MOUSE EARS WHT&BLK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7218213",
        "Last Price Paid": "$1.06",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037240",
        "Product Status": "Active"
    },
    {
        "Root": "10005303",
        "LV": "1",
        "Item Description": "POWDER THICKENER INSTANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8199564",
        "Last Price Paid": "$93.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005303",
        "Product Status": "Active"
    },
    {
        "Root": "10003352",
        "LV": "1",
        "Item Description": "COFFEE KONA BL REG BN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.63",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003352",
        "Product Status": "Active"
    },
    {
        "Root": "10004208",
        "LV": "1",
        "Item Description": "CIDER APPLE SPK MARTINELL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7019904",
        "Last Price Paid": "$3.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004208",
        "Product Status": "Active"
    },
    {
        "Root": "10004642",
        "LV": "1",
        "Item Description": "MILK POWDERED NONFAT 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1382076",
        "Last Price Paid": "$28.47",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004642",
        "Product Status": "Active"
    },
    {
        "Root": "10003340",
        "LV": "1",
        "Item Description": "SYRUP CARMEL SLTD LTR MON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003340001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5210",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003340",
        "Product Status": "Active"
    },
    {
        "Root": "10016372",
        "LV": "1",
        "Item Description": "CHENIN BLANC LECOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016372001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "465935",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016372",
        "Product Status": "Active"
    },
    {
        "Root": "10037296",
        "LV": "1",
        "Item Description": "CUP SAPPHIRE PEDESTAL 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000003406",
        "Vendor": "EDWARD DON & CO",
        "Vendor Item Nb": "435/SA",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037296",
        "Product Status": "Active"
    },
    {
        "Root": "10109333",
        "LV": "1",
        "Item Description": "YOGURT PARFAIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109333001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "851218008650",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109333",
        "Product Status": "Active"
    },
    {
        "Root": "10035061",
        "LV": "1",
        "Item Description": "CLEAR TUBE W/LID T4545 4-1/2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035061001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51425032",
        "Last Price Paid": "",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035061",
        "Product Status": "Active"
    },
    {
        "Root": "10106121",
        "LV": "1",
        "Item Description": "PROSCIUTTO DI PARMA 400 DAYS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106121001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "36639",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106121",
        "Product Status": "Active"
    },
    {
        "Root": "10034930",
        "LV": "1",
        "Item Description": "JOFFREY'S JAMAICAN BLUE MOUNTAIN REGULAR BEAN 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "C190B-21J",
        "Last Price Paid": "",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034930",
        "Product Status": "Active"
    },
    {
        "Root": "10007548",
        "LV": "2",
        "Item Description": "BLND PALACIOS MONTESA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007548001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007548",
        "Product Status": "Active"
    },
    {
        "Root": "10039554",
        "LV": "1",
        "Item Description": "PIZZA FLATBREAD FROZEN OVAL CRUST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039554001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039554",
        "Product Status": "Active"
    },
    {
        "Root": "10033709",
        "LV": "1",
        "Item Description": "FRUIT PUREE CQ BLACK RASPBERRY ACAI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033709001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "BRA32",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033709",
        "Product Status": "Active"
    },
    {
        "Root": "10104934",
        "LV": "1",
        "Item Description": "MUG BASE NIGHT FIREWORKS  BLUE LID 16OZ 48/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104934",
        "Product Status": "Active"
    },
    {
        "Root": "10003557",
        "LV": "1",
        "Item Description": "SPICE CELERY WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.16",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003557",
        "Product Status": "Active"
    },
    {
        "Root": "10004967",
        "LV": "1",
        "Item Description": "CITROPERL LEMON ZEST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": ".{DREIDOPPEL",
        "Last Price Paid": "$21.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004967",
        "Product Status": "Active"
    },
    {
        "Root": "10000388",
        "LV": "1",
        "Item Description": "CARROT 1 INCH COIN CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512010",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000388",
        "Product Status": "Active"
    },
    {
        "Root": "10000388",
        "LV": "1",
        "Item Description": "CARROT 1 INCH COIN CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000388001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-003-14",
        "Last Price Paid": "$10.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000388",
        "Product Status": "Active"
    },
    {
        "Root": "10107789",
        "LV": "2",
        "Item Description": "RICE BAMBOO 10LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107789002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5486097",
        "Last Price Paid": "$93.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107789",
        "Product Status": "Active"
    },
    {
        "Root": "10004977",
        "LV": "1",
        "Item Description": "FONDANT CREAM 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "P702",
        "Last Price Paid": "$60.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004977",
        "Product Status": "Active"
    },
    {
        "Root": "10109537",
        "LV": "1",
        "Item Description": "BEER KEG CIGAR CITY JAI ALAI IPA 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109537001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$218.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109537",
        "Product Status": "Active"
    },
    {
        "Root": "10013904",
        "LV": "4",
        "Item Description": "LIQUOR GRAND MARNIER LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013904004004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "004",
        "NALA": "10013904",
        "Product Status": "Active"
    },
    {
        "Root": "10001497",
        "LV": "1",
        "Item Description": "CITRUS CORIANDER BLOOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001497001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.90",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001497",
        "Product Status": "Active"
    },
    {
        "Root": "10002058",
        "LV": "1",
        "Item Description": "OKRA GREEN LB=LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002058001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002058",
        "Product Status": "Active"
    },
    {
        "Root": "10092152",
        "LV": "1",
        "Item Description": "CANDY M&M PEANUT 12/5.3OZ PEG BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092152001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "6923",
        "Last Price Paid": "$36.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092152",
        "Product Status": "Active"
    },
    {
        "Root": "10009511",
        "LV": "1",
        "Item Description": "BOURBON BLANTONS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009511001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9031293",
        "Last Price Paid": "$48.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009511",
        "Product Status": "Active"
    },
    {
        "Root": "10100277",
        "LV": "1",
        "Item Description": "WINE RED VIGNETI SALENTO PRIMITIVO MANDURIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "593655",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100277",
        "Product Status": "Active"
    },
    {
        "Root": "10034914",
        "LV": "1",
        "Item Description": "FEUILLETINE CRUNCH FLECKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034914001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "515001",
        "Last Price Paid": "$45.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034914",
        "Product Status": "Active"
    },
    {
        "Root": "10027941",
        "LV": "1",
        "Item Description": "TEQUILA GAD ESPANDIN JOVEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027941001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "478256",
        "Last Price Paid": "$23.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027941",
        "Product Status": "Active"
    },
    {
        "Root": "10016441",
        "LV": "3",
        "Item Description": "MUSTARD 2/1.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016441002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.83",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10016441",
        "Product Status": "Active"
    },
    {
        "Root": "10107208",
        "LV": "1",
        "Item Description": "DTD 2X BLUE SHIMMER SYRUP 750ML 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "2BSS-750",
        "Last Price Paid": "$11.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107208",
        "Product Status": "Active"
    },
    {
        "Root": "10109533",
        "LV": "1",
        "Item Description": "BEER KEG YUENGLING LAGER 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$98.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109533",
        "Product Status": "Active"
    },
    {
        "Root": "10094129",
        "LV": "1",
        "Item Description": "CANDY BROADWAY WHEELS - STRAWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094129001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "35715",
        "Last Price Paid": "$57.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094129",
        "Product Status": "Active"
    },
    {
        "Root": "10001633",
        "LV": "1",
        "Item Description": "SQUASH BUTERNT PLD&HALVED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "515523",
        "Last Price Paid": "$21.53",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001633",
        "Product Status": "Active"
    },
    {
        "Root": "10001633",
        "LV": "1",
        "Item Description": "SQUASH BUTERNT PLD&HALVED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "40-004",
        "Last Price Paid": "$21.53",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001633",
        "Product Status": "Active"
    },
    {
        "Root": "10006974",
        "LV": "1",
        "Item Description": "PINOT GRIG ECCO DOMANI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006974001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1259746",
        "Last Price Paid": "$7.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006974",
        "Product Status": "Active"
    },
    {
        "Root": "10002450",
        "LV": "2",
        "Item Description": "ICE CREAM STRAWBERRY HDAZ 2.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002450002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002450",
        "Product Status": "Active"
    },
    {
        "Root": "10084126",
        "LV": "2",
        "Item Description": "DONUT HOLES CHOC GLAZED 35CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084126002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000588369",
        "Vendor": "BAKERY EXPRESS OF CENTRAL FL IN1741",
        "Vendor Item Nb": "DIS202",
        "Last Price Paid": "$0.08",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084126",
        "Product Status": "Active"
    },
    {
        "Root": "10001926",
        "LV": "1",
        "Item Description": "MUSHROOM WOODEAR DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001926001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405056",
        "Last Price Paid": "$22.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001926",
        "Product Status": "Active"
    },
    {
        "Root": "10031424",
        "LV": "1",
        "Item Description": "BEEF BRISKET WAGYU MS9+",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031424001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "BEF452",
        "Last Price Paid": "$14.75",
        "Case Pack": "39.999",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031424",
        "Product Status": "Active"
    },
    {
        "Root": "10036359",
        "LV": "1",
        "Item Description": "DEC CURL BLOSSOM CURL BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "I96383",
        "Last Price Paid": "$128.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036359",
        "Product Status": "Active"
    },
    {
        "Root": "10031971",
        "LV": "1",
        "Item Description": "JUICE LIMEADE SIMPLY 6/52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031971001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6072530",
        "Last Price Paid": "$2.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031971",
        "Product Status": "Active"
    },
    {
        "Root": "10037015",
        "LV": "1",
        "Item Description": "MENCIA A PORTELA 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9176321",
        "Last Price Paid": "$17.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037015",
        "Product Status": "Active"
    },
    {
        "Root": "10002950",
        "LV": "1",
        "Item Description": "TEA TWINING POMEGRANATE RASPBERRY 120",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002950",
        "Product Status": "Active"
    },
    {
        "Root": "10103732",
        "LV": "1",
        "Item Description": "REDFISH FILET SKIN ON BONE IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103732001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "RED120105",
        "Last Price Paid": "$15.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103732",
        "Product Status": "Active"
    },
    {
        "Root": "10004727",
        "LV": "1",
        "Item Description": "PRETZEL ROLD GOLD TINY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004727001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5603857",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004727",
        "Product Status": "Active"
    },
    {
        "Root": "10014046",
        "LV": "2",
        "Item Description": "COCOA BUTTER ALABASTER WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014046002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302001C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014046",
        "Product Status": "Active"
    },
    {
        "Root": "10017436",
        "LV": "1",
        "Item Description": "POWDER SPORT PROTEIN VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017436001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "VEG00855",
        "Last Price Paid": "$33.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017436",
        "Product Status": "Active"
    },
    {
        "Root": "10107928",
        "LV": "2",
        "Item Description": "PICK BAMBOO W/KNOT 4.5\" 12/100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107928002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6202201",
        "Last Price Paid": "$0.03",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10107928",
        "Product Status": "Active"
    },
    {
        "Root": "10001600",
        "LV": "1",
        "Item Description": "SQUASH YELLOW PLANKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "16-007",
        "Last Price Paid": "$19.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001600",
        "Product Status": "Active"
    },
    {
        "Root": "10001600",
        "LV": "1",
        "Item Description": "SQUASH YELLOW PLANKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490071",
        "Last Price Paid": "$19.10",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001600",
        "Product Status": "Active"
    },
    {
        "Root": "10001802",
        "LV": "2",
        "Item Description": "MIX PICO DE GALLO DRY 2/5# TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001802002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "55-010 DRY",
        "Last Price Paid": "$19.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001802",
        "Product Status": "Active"
    },
    {
        "Root": "10107889",
        "LV": "1",
        "Item Description": "VEGAN HURRICANE BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107889001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073584",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107889",
        "Product Status": "Active"
    },
    {
        "Root": "10006299",
        "LV": "1",
        "Item Description": "SPOON GELATO ASST COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006299001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000434973",
        "Vendor": "MOLTO GOURMET",
        "Vendor Item Nb": "SPO100",
        "Last Price Paid": "$0.01",
        "Case Pack": "8350",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006299",
        "Product Status": "Active"
    },
    {
        "Root": "10094643",
        "LV": "2",
        "Item Description": "CANDY YUMEARTH GUMMY BEARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094643002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1390",
        "Last Price Paid": "$0.52",
        "Case Pack": "315",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10094643",
        "Product Status": "Active"
    },
    {
        "Root": "10031759",
        "LV": "1",
        "Item Description": "MERLOT EOS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031759001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "571923",
        "Last Price Paid": "$6.95",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031759",
        "Product Status": "Active"
    },
    {
        "Root": "10006116",
        "LV": "1",
        "Item Description": "MUG SHRUNKEN HEAD ZOMBIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.15",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006116",
        "Product Status": "Active"
    },
    {
        "Root": "10040032",
        "LV": "1",
        "Item Description": "RUM PLANTATION PINEAPPLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "366684",
        "Last Price Paid": "$23.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040032",
        "Product Status": "Active"
    },
    {
        "Root": "10090931",
        "LV": "1",
        "Item Description": "TEQUILA TEREMANA BLANCO 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "965143",
        "Last Price Paid": "$27.10",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090931",
        "Product Status": "Active"
    },
    {
        "Root": "10001778",
        "LV": "1",
        "Item Description": "CABBAGE RED 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001778001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.71",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001778",
        "Product Status": "Active"
    },
    {
        "Root": "10001778",
        "LV": "1",
        "Item Description": "CABBAGE RED 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001778001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "402005",
        "Last Price Paid": "$1.71",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001778",
        "Product Status": "Active"
    },
    {
        "Root": "10094643",
        "LV": "1",
        "Item Description": "CANDY YUMEARTH GUMMY BEARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094643001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1501A",
        "Last Price Paid": "$0.52",
        "Case Pack": "387",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094643",
        "Product Status": "Active"
    },
    {
        "Root": "10033785",
        "LV": "2",
        "Item Description": "SAKE TAKATENJIN SWORD OF SUN 720ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033785002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9223222",
        "Last Price Paid": "$17.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033785",
        "Product Status": "Active"
    },
    {
        "Root": "10007790",
        "LV": "1",
        "Item Description": "PINOT NOIR SOLENA GRD CUVEE 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007790001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "878513",
        "Last Price Paid": "$17.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007790",
        "Product Status": "Active"
    },
    {
        "Root": "10014844",
        "LV": "1",
        "Item Description": "ACTIVA RM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014844001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD177",
        "Last Price Paid": "$179.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014844",
        "Product Status": "Active"
    },
    {
        "Root": "10010113",
        "LV": "2",
        "Item Description": "WATER SARATOGA GLS 12OZ STILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010113001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010113",
        "Product Status": "Active"
    },
    {
        "Root": "10107190",
        "LV": "1",
        "Item Description": "RUM CRUZAN GUAVA PET 1.0L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550585",
        "Last Price Paid": "$10.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107190",
        "Product Status": "Active"
    },
    {
        "Root": "10006935",
        "LV": "1",
        "Item Description": "CAB SAUV BV COASTAL CAB 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006935001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "22344",
        "Last Price Paid": "$6.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006935",
        "Product Status": "Active"
    },
    {
        "Root": "10007739",
        "LV": "2",
        "Item Description": "WINE RED CAB SAUV FREEMARK ABBEY NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007739001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "584206",
        "Last Price Paid": "$50.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007739",
        "Product Status": "Active"
    },
    {
        "Root": "10027148",
        "LV": "1",
        "Item Description": "DRAFT KONA BIG WAVE 1/6 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "24106",
        "Last Price Paid": "$64.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027148",
        "Product Status": "Active"
    },
    {
        "Root": "10034443",
        "LV": "1",
        "Item Description": "WINE RED BLEND DOM GROS NORE BANDOL ROUGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "563184",
        "Last Price Paid": "$40.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034443",
        "Product Status": "Active"
    },
    {
        "Root": "10032601",
        "LV": "1",
        "Item Description": "MEATLESS BEYOND SAUSAGE BRATWURST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103319",
        "Last Price Paid": "$1.81",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032601",
        "Product Status": "Active"
    },
    {
        "Root": "10033293",
        "LV": "1",
        "Item Description": "DECOR SQUARE MICKEY TRANSFER 1.5 X 1.5 IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033293001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SS-MICKEY",
        "Last Price Paid": "$0.21",
        "Case Pack": "2400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033293",
        "Product Status": "Active"
    },
    {
        "Root": "10108404",
        "LV": "1",
        "Item Description": "BAKED APPLE CROISSANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108404001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11136403",
        "Last Price Paid": "$1.61",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108404",
        "Product Status": "Active"
    },
    {
        "Root": "10029952",
        "LV": "1",
        "Item Description": "SUGAR CHRISTMAS LIGHTS ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029952001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "41198",
        "Last Price Paid": "$0.10",
        "Case Pack": "240",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029952",
        "Product Status": "Active"
    },
    {
        "Root": "10005269",
        "LV": "1",
        "Item Description": "BAGELETTE LENDERS 144CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005269001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8033060",
        "Last Price Paid": "$0.21",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005269",
        "Product Status": "Active"
    },
    {
        "Root": "10009895",
        "LV": "2",
        "Item Description": "APERITIF FERNET BRANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009895001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "145004",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009895",
        "Product Status": "Active"
    },
    {
        "Root": "10009895",
        "LV": "2",
        "Item Description": "APERITIF FERNET BRANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009895001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "145004",
        "Last Price Paid": "$24.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009895",
        "Product Status": "Active"
    },
    {
        "Root": "10028876",
        "LV": "1",
        "Item Description": "ALE KEG ACE SPACE BLOODY ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "36918",
        "Last Price Paid": "$216.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028876",
        "Product Status": "Active"
    },
    {
        "Root": "10018154",
        "LV": "1",
        "Item Description": "POWDER DECOR PEACH LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018154001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11136",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018154",
        "Product Status": "Active"
    },
    {
        "Root": "10018095",
        "LV": "1",
        "Item Description": "MALBEC CRIOS ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018095001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9189090",
        "Last Price Paid": "$9.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018095",
        "Product Status": "Active"
    },
    {
        "Root": "10107308",
        "LV": "1",
        "Item Description": "CHEESE BOURSIN BLACK TRUFFLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107308001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "997291",
        "Last Price Paid": "$4.20",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107308",
        "Product Status": "Active"
    },
    {
        "Root": "10002848",
        "LV": "1",
        "Item Description": "PICKLE CHIP 6/5.75# BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002848001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.66",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002848",
        "Product Status": "Active"
    },
    {
        "Root": "10002198",
        "LV": "2",
        "Item Description": "YOGURT STRAWBERRY LITE & FIT 6/32OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002198001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002198",
        "Product Status": "Active"
    },
    {
        "Root": "10001877",
        "LV": "1",
        "Item Description": "GRAPE RED STEMMED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-011",
        "Last Price Paid": "$24.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001877",
        "Product Status": "Active"
    },
    {
        "Root": "10001877",
        "LV": "1",
        "Item Description": "GRAPE RED STEMMED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001877001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260101",
        "Last Price Paid": "$24.50",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001877",
        "Product Status": "Active"
    },
    {
        "Root": "10000786",
        "LV": "1",
        "Item Description": "BEEF BRISKET SMOKED SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000786001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7003629",
        "Last Price Paid": "$14.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000786",
        "Product Status": "Active"
    },
    {
        "Root": "10104169",
        "LV": "1",
        "Item Description": "JOFFREYS HOT COCOA PACKETS 1OZ/100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.49",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104169",
        "Product Status": "Active"
    },
    {
        "Root": "10000791",
        "LV": "1",
        "Item Description": "SALAMI DILSSO GENOA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000791001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3613005",
        "Last Price Paid": "$3.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000791",
        "Product Status": "Active"
    },
    {
        "Root": "10014412",
        "LV": "1",
        "Item Description": "ORANGES CARA CARA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "211037",
        "Last Price Paid": "$0.14",
        "Case Pack": "88",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014412",
        "Product Status": "Active"
    },
    {
        "Root": "10025315",
        "LV": "1",
        "Item Description": "BAG SAND LG DUBLVIEW 4.25X2.75X11.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008066",
        "Last Price Paid": "$0.28",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025315",
        "Product Status": "Active"
    },
    {
        "Root": "10014616",
        "LV": "1",
        "Item Description": "GRANNY 1/2 IN DICED APPLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481972",
        "Last Price Paid": "$23.20",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014616",
        "Product Status": "Active"
    },
    {
        "Root": "10001175",
        "LV": "1",
        "Item Description": "SALMON SCOTTISH WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001175001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001175",
        "Product Status": "Active"
    },
    {
        "Root": "10001175",
        "LV": "1",
        "Item Description": "SALMON SCOTTISH WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001175001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-50066",
        "Last Price Paid": "$8.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001175",
        "Product Status": "Active"
    },
    {
        "Root": "10001175",
        "LV": "1",
        "Item Description": "SALMON SCOTTISH WHL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001175001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "0435552",
        "Last Price Paid": "$8.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001175",
        "Product Status": "Active"
    },
    {
        "Root": "10022644",
        "LV": "1",
        "Item Description": "BUTTER CHIP INDIV WRP SALTED 5/59 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022644001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "568683",
        "Last Price Paid": "$18.05",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022644",
        "Product Status": "Active"
    },
    {
        "Root": "10005744",
        "LV": "2",
        "Item Description": "ROLL PRETZEL SANDWICH 3.5OZ 80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005744002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.57",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005744",
        "Product Status": "Active"
    },
    {
        "Root": "10045257",
        "LV": "1",
        "Item Description": "SB OATMILK 32OZ 12EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045257001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "011122802",
        "Last Price Paid": "$3.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045257",
        "Product Status": "Active"
    },
    {
        "Root": "10109593",
        "LV": "1",
        "Item Description": "KANPACHI WHOLE FRESH FISH FOREVER OCEANS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109593001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "61-00260",
        "Last Price Paid": "$8.95",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109593",
        "Product Status": "Active"
    },
    {
        "Root": "10003455",
        "LV": "1",
        "Item Description": "CARAMEL LIQUID PETER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003455",
        "Product Status": "Active"
    },
    {
        "Root": "10000942",
        "LV": "1",
        "Item Description": "FISH FOOD GLASS MINNOWS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000942001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6374010",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000942",
        "Product Status": "Active"
    },
    {
        "Root": "10042343",
        "LV": "1",
        "Item Description": "COOKIE HOMEFREE GINGER SNAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "GFMGC",
        "Last Price Paid": "",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042343",
        "Product Status": "Active"
    },
    {
        "Root": "10103729",
        "LV": "1",
        "Item Description": "TRAY KRAFT RED/WHITE PLAID 0590 SUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103729001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11009088",
        "Last Price Paid": "",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103729",
        "Product Status": "Active"
    },
    {
        "Root": "10034302",
        "LV": "1",
        "Item Description": "ANNATO SEED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034302001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "163941",
        "Last Price Paid": "$4.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034302",
        "Product Status": "Active"
    },
    {
        "Root": "10006654",
        "LV": "1",
        "Item Description": "LID HALF SIZE ALUMINUM 10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006654001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7737109",
        "Last Price Paid": "$1.20",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006654",
        "Product Status": "Active"
    },
    {
        "Root": "10017038",
        "LV": "1",
        "Item Description": "TORTILLA CHIP RAW TRI COLOR 4 CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017038001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5670765",
        "Last Price Paid": "$10.63",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017038",
        "Product Status": "Active"
    },
    {
        "Root": "10039456",
        "LV": "1",
        "Item Description": "CEREAL FROOT LOOPS BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039456001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039456",
        "Product Status": "Active"
    },
    {
        "Root": "10030619",
        "LV": "1",
        "Item Description": "PROSECCO BRANCHER VALDO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "579897",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030619",
        "Product Status": "Active"
    },
    {
        "Root": "10035208",
        "LV": "1",
        "Item Description": "LIQUEUR MOZART STRAWBERRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "29097",
        "Last Price Paid": "$21.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035208",
        "Product Status": "Active"
    },
    {
        "Root": "10035208",
        "LV": "1",
        "Item Description": "LIQUEUR MOZART STRAWBERRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "29097",
        "Last Price Paid": "$21.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035208",
        "Product Status": "Active"
    },
    {
        "Root": "10007409",
        "LV": "2",
        "Item Description": "SPARK IRON HORSE OCEAN 750 CUV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007409001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9119729",
        "Last Price Paid": "$36.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007409",
        "Product Status": "Active"
    },
    {
        "Root": "10024505",
        "LV": "1",
        "Item Description": "CHOC SAND COOKIE CHARACTER LOLLIPOP 2 IN IN CELLO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024505001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW17366",
        "Last Price Paid": "$3.52",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024505",
        "Product Status": "Active"
    },
    {
        "Root": "10004921",
        "LV": "3",
        "Item Description": "NUT MIXED W/PEANUT 24/3.25 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004921003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203015",
        "Last Price Paid": "$1.32",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10004921",
        "Product Status": "Active"
    },
    {
        "Root": "10001279",
        "LV": "1",
        "Item Description": "APPLE SLICED IQF GRANNY SMITH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001279",
        "Product Status": "Active"
    },
    {
        "Root": "10016839",
        "LV": "1",
        "Item Description": "DECOR CHOC WHT HAT DERBY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016839001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6167490000",
        "Last Price Paid": "$0.19",
        "Case Pack": "1650",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016839",
        "Product Status": "Active"
    },
    {
        "Root": "10030860",
        "LV": "1",
        "Item Description": "CABBAGE SAVOY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "402030",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030860",
        "Product Status": "Active"
    },
    {
        "Root": "10107345",
        "LV": "1",
        "Item Description": "DISC DK CHOC 4 CM PLAIN 864 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "5082D",
        "Last Price Paid": "$0.23",
        "Case Pack": "864",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107345",
        "Product Status": "Active"
    },
    {
        "Root": "10035026",
        "LV": "1",
        "Item Description": "GODELLO AVANCIA OLD VINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300053",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035026",
        "Product Status": "Active"
    },
    {
        "Root": "10005592",
        "LV": "2",
        "Item Description": "PASTRY CROISSANT MINI BUTTER PRE-PROOFED 165CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005592002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.33",
        "Case Pack": "165",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005592",
        "Product Status": "Active"
    },
    {
        "Root": "10005592",
        "LV": "2",
        "Item Description": "PASTRY CROISSANT MINI BUTTER PRE-PROOFED 165CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005592002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "39055",
        "Last Price Paid": "$0.33",
        "Case Pack": "165",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005592",
        "Product Status": "Active"
    },
    {
        "Root": "10001503",
        "LV": "1",
        "Item Description": "HERB LEMON BASIL PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001503001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001503",
        "Product Status": "Active"
    },
    {
        "Root": "10007773",
        "LV": "1",
        "Item Description": "RIES TREFETHEN EST DRY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "580970",
        "Last Price Paid": "$15.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007773",
        "Product Status": "Active"
    },
    {
        "Root": "10002996",
        "LV": "1",
        "Item Description": "CRUMBS BREAD PANKO 25#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002996001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$25.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002996",
        "Product Status": "Active"
    },
    {
        "Root": "10009516",
        "LV": "1",
        "Item Description": "WHISKEY CROWN ROYAL 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.67",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009516",
        "Product Status": "Active"
    },
    {
        "Root": "10090728",
        "LV": "2",
        "Item Description": "BASE COOKIE CRUSHED CHOCOLATE HYDROX 30 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090728002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "302312",
        "Last Price Paid": "$136.69",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10090728",
        "Product Status": "Active"
    },
    {
        "Root": "10001913",
        "LV": "1",
        "Item Description": "MANGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "306010",
        "Last Price Paid": "$16.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001913",
        "Product Status": "Active"
    },
    {
        "Root": "10001913",
        "LV": "1",
        "Item Description": "MANGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001913001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$16.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001913",
        "Product Status": "Active"
    },
    {
        "Root": "10034634",
        "LV": "1",
        "Item Description": "SB CARAMEL BRULEE SAUCE 63OZ 4 CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11119951",
        "Last Price Paid": "$27.53",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034634",
        "Product Status": "Active"
    },
    {
        "Root": "10003391",
        "LV": "1",
        "Item Description": "PASTA DRY BUCATINI 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003391001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.08",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003391",
        "Product Status": "Active"
    },
    {
        "Root": "10035191",
        "LV": "1",
        "Item Description": "DONUT DIPPED VANILLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10005773",
        "Last Price Paid": "$3.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035191",
        "Product Status": "Active"
    },
    {
        "Root": "10037956",
        "LV": "1",
        "Item Description": "WHITE BLEND SEGURA VIUDAS HEREDAD BRUT RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "446860",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037956",
        "Product Status": "Active"
    },
    {
        "Root": "10000966",
        "LV": "1",
        "Item Description": "TROUT SMOKED SQS MBA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000966001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "59-50040",
        "Last Price Paid": "$15.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000966",
        "Product Status": "Active"
    },
    {
        "Root": "10005216",
        "LV": "1",
        "Item Description": "YUZU JUICE 720ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "13203",
        "Last Price Paid": "$40.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005216",
        "Product Status": "Active"
    },
    {
        "Root": "10003450",
        "LV": "1",
        "Item Description": "SYRUP FCB WATERMELON 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003450001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$57.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003450",
        "Product Status": "Active"
    },
    {
        "Root": "10002370",
        "LV": "2",
        "Item Description": "CHEESE SOFT RIPENED GOAT HUMBOLDT FOG 4/16OZ WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002370002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "41400",
        "Last Price Paid": "$19.70",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002370",
        "Product Status": "Active"
    },
    {
        "Root": "10011162",
        "LV": "2",
        "Item Description": "CANDY SOUR BELTS QUATTRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011162002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585490",
        "Last Price Paid": "$17.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10011162",
        "Product Status": "Active"
    },
    {
        "Root": "10003045",
        "LV": "1",
        "Item Description": "FILLING BLUEBERRY PIE40LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9330994",
        "Last Price Paid": "$84.63",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003045",
        "Product Status": "Active"
    },
    {
        "Root": "10003585",
        "LV": "2",
        "Item Description": "QUINS MICKEY MOUSE PRIMARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003585002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5820",
        "Last Price Paid": "$14.02",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003585",
        "Product Status": "Active"
    },
    {
        "Root": "10033719",
        "LV": "1",
        "Item Description": "NUT ALMONDS BLANCHED SLICED 4/2.5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "11100-10",
        "Last Price Paid": "$15.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033719",
        "Product Status": "Active"
    },
    {
        "Root": "10106375",
        "LV": "1",
        "Item Description": "MACADAMIA SYRUP 1L 6-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106375001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11145221",
        "Last Price Paid": "$12.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106375",
        "Product Status": "Active"
    },
    {
        "Root": "10001221",
        "LV": "1",
        "Item Description": "HAM IBERICO WHL BNLS 4-8 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001221001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7026429",
        "Last Price Paid": "$98.40",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001221",
        "Product Status": "Active"
    },
    {
        "Root": "10002354",
        "LV": "2",
        "Item Description": "ICE CREAM STRAWBERRY HD 14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002354002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.74",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002354",
        "Product Status": "Active"
    },
    {
        "Root": "10003561",
        "LV": "1",
        "Item Description": "SPICE FENNEL SEED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003561001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003561",
        "Product Status": "Active"
    },
    {
        "Root": "10018789",
        "LV": "2",
        "Item Description": "DECOR DISCO SPARKLES GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018789002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "M11177",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10018789",
        "Product Status": "Active"
    },
    {
        "Root": "10010103",
        "LV": "1",
        "Item Description": "BEER CORONA EXTRA BT 12OZ/24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010103001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.42",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010103",
        "Product Status": "Active"
    },
    {
        "Root": "10001706",
        "LV": "1",
        "Item Description": "MUSHROOM OYSTER WHITE 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405050",
        "Last Price Paid": "$33.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001706",
        "Product Status": "Active"
    },
    {
        "Root": "10001706",
        "LV": "1",
        "Item Description": "MUSHROOM OYSTER WHITE 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001706001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.54",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001706",
        "Product Status": "Active"
    },
    {
        "Root": "10040494",
        "LV": "1",
        "Item Description": "LABEL VEGGIE ROUND 2.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040494001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45001090",
        "Last Price Paid": "$0.06",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040494",
        "Product Status": "Active"
    },
    {
        "Root": "10040296",
        "LV": "1",
        "Item Description": "ETNA ROSSO PIETRADOLCE BARBAGALLI 2014 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "543099",
        "Last Price Paid": "$84.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040296",
        "Product Status": "Active"
    },
    {
        "Root": "10021155",
        "LV": "1",
        "Item Description": "HOR QUICHE CRUSTLESS BACON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021155001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "84172",
        "Last Price Paid": "$2.46",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021155",
        "Product Status": "Active"
    },
    {
        "Root": "10006607",
        "LV": "1",
        "Item Description": "HAT CHEF 10 INCH WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2294320",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006607",
        "Product Status": "Active"
    },
    {
        "Root": "10028642",
        "LV": "1",
        "Item Description": "PASTRY CINNAMON ROLL MINI ICED 2.5IN 12CT BAKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "68471",
        "Last Price Paid": "$0.58",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028642",
        "Product Status": "Active"
    },
    {
        "Root": "10009459",
        "LV": "1",
        "Item Description": "SCOTCH J&B RARE FLSK 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009459001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "26127",
        "Last Price Paid": "$11.01",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009459",
        "Product Status": "Active"
    },
    {
        "Root": "10006565",
        "LV": "1",
        "Item Description": "CHEESECLOTH 36 INCH X60YD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006565001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1051978",
        "Last Price Paid": "$50.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006565",
        "Product Status": "Active"
    },
    {
        "Root": "10001411",
        "LV": "1",
        "Item Description": "PEA TENDRILS GREEN #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450042",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001411",
        "Product Status": "Active"
    },
    {
        "Root": "10001411",
        "LV": "1",
        "Item Description": "PEA TENDRILS GREEN #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04PT-2",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001411",
        "Product Status": "Active"
    },
    {
        "Root": "10037623",
        "LV": "1",
        "Item Description": "STONE IPA 12OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "13139",
        "Last Price Paid": "$1.41",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037623",
        "Product Status": "Active"
    },
    {
        "Root": "10037623",
        "LV": "1",
        "Item Description": "STONE IPA 12OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2671",
        "Last Price Paid": "$1.41",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037623",
        "Product Status": "Active"
    },
    {
        "Root": "10007015",
        "LV": "1",
        "Item Description": "BLND BIG BAD RED OUAV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007015",
        "Product Status": "Active"
    },
    {
        "Root": "10003899",
        "LV": "1",
        "Item Description": "CHOC GARNISH SHEET BRTHDY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003899001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 240105",
        "Last Price Paid": "$14.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003899",
        "Product Status": "Active"
    },
    {
        "Root": "10000336",
        "LV": "1",
        "Item Description": "ONION SPANISH WHL/PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "02-001-20",
        "Last Price Paid": "$13.93",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000336",
        "Product Status": "Active"
    },
    {
        "Root": "10000336",
        "LV": "1",
        "Item Description": "ONION SPANISH WHL/PLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000336001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480319",
        "Last Price Paid": "$13.93",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000336",
        "Product Status": "Active"
    },
    {
        "Root": "10095518",
        "LV": "1",
        "Item Description": "WINE VIOGNIER YALUMBA 12/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095518001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "532565",
        "Last Price Paid": "$8.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095518",
        "Product Status": "Active"
    },
    {
        "Root": "10006351",
        "LV": "1",
        "Item Description": "FORK HVY WT SILVER REFLEC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7653215",
        "Last Price Paid": "$0.08",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006351",
        "Product Status": "Active"
    },
    {
        "Root": "10103809",
        "LV": "1",
        "Item Description": "HOT CHOCOLATE POWDER MIX JOFFREYS 2LB/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.30",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103809",
        "Product Status": "Active"
    },
    {
        "Root": "10036097",
        "LV": "1",
        "Item Description": "TWININGS ENCHANTING FOREST FRUITS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036097001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F13109",
        "Last Price Paid": "$0.29",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036097",
        "Product Status": "Active"
    },
    {
        "Root": "10039325",
        "LV": "1",
        "Item Description": "PAPER BUTCHER ROLL WHITE 36 IN X 1300 FT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039325001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5036231",
        "Last Price Paid": "$71.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039325",
        "Product Status": "Active"
    },
    {
        "Root": "10001501",
        "LV": "1",
        "Item Description": "SNOW PEAS MIXED PETITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001501001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.65",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001501",
        "Product Status": "Active"
    },
    {
        "Root": "10004777",
        "LV": "1",
        "Item Description": "PEPPER CHIPOLTE 7Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004777001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9081829",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004777",
        "Product Status": "Active"
    },
    {
        "Root": "10004303",
        "LV": "2",
        "Item Description": "CHIP FRITOS TOP N GO 3.5OZ / 18 PK CASE 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004303002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6460805",
        "Last Price Paid": "$2.17",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004303",
        "Product Status": "Active"
    },
    {
        "Root": "10015060",
        "LV": "1",
        "Item Description": "DRAFT FUNKY BUDDAH SEASON 1/6 SEASONAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "32753",
        "Last Price Paid": "$95.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015060",
        "Product Status": "Active"
    },
    {
        "Root": "10002188",
        "LV": "1",
        "Item Description": "MILK LOW FAT 2% GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.89",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002188",
        "Product Status": "Active"
    },
    {
        "Root": "10030360",
        "LV": "1",
        "Item Description": "CABERNET HEITZ MARTHAS 2007 1.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "969116",
        "Last Price Paid": "$406.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030360",
        "Product Status": "Active"
    },
    {
        "Root": "10009151",
        "LV": "1",
        "Item Description": "CHARD RUSTBRG 5 SOLDIER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009151001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2182",
        "Last Price Paid": "$35.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009151",
        "Product Status": "Active"
    },
    {
        "Root": "10004112",
        "LV": "1",
        "Item Description": "SB SYRUP CLASSIC 1 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11111670",
        "Last Price Paid": "$5.01",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004112",
        "Product Status": "Active"
    },
    {
        "Root": "10040781",
        "LV": "1",
        "Item Description": "CANDY ROCK SWIZZLE STICK 6.5IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "586860",
        "Last Price Paid": "$0.99",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040781",
        "Product Status": "Active"
    },
    {
        "Root": "10014347",
        "LV": "1",
        "Item Description": "SHRIMP 51/60 RSHO IQF SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014347001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6125580",
        "Last Price Paid": "",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014347",
        "Product Status": "Active"
    },
    {
        "Root": "10101488",
        "LV": "1",
        "Item Description": "SYRUP ICE CREAM FLAVOR BURST CHOC SWIRL 1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "001-SEA",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101488",
        "Product Status": "Active"
    },
    {
        "Root": "10015425",
        "LV": "1",
        "Item Description": "FLAVORING BBQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2352",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015425",
        "Product Status": "Active"
    },
    {
        "Root": "10039131",
        "LV": "1",
        "Item Description": "SUGAR GINGERBREAD MAN ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039131001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "50807",
        "Last Price Paid": "",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039131",
        "Product Status": "Active"
    },
    {
        "Root": "10100229",
        "LV": "1",
        "Item Description": "LAMB SADDLE DOMESTIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100229001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "LAMDOM011",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100229",
        "Product Status": "Active"
    },
    {
        "Root": "10004094",
        "LV": "1",
        "Item Description": "SHELL SWEET 1.7 INCH ROUND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004094001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "360",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004094",
        "Product Status": "Active"
    },
    {
        "Root": "10110634",
        "LV": "1",
        "Item Description": "BEER KOSTRITZER SCHWARZBIER 50 L KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "10151",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110634",
        "Product Status": "Active"
    },
    {
        "Root": "10038463",
        "LV": "1",
        "Item Description": "GUINNESS ORIGINAL CHIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038463001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038463",
        "Product Status": "Active"
    },
    {
        "Root": "10000933",
        "LV": "1",
        "Item Description": "HIJIKE PACKAGE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000933001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "10080",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000933",
        "Product Status": "Active"
    },
    {
        "Root": "10010493",
        "LV": "2",
        "Item Description": "TASCA LAMURI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010493001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "540390",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010493",
        "Product Status": "Active"
    },
    {
        "Root": "10004445",
        "LV": "1",
        "Item Description": "CHOC FACE MICKEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004445",
        "Product Status": "Active"
    },
    {
        "Root": "10105136",
        "LV": "1",
        "Item Description": "LIQ ROCKEYS BOTANICAL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "995627",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105136",
        "Product Status": "Active"
    },
    {
        "Root": "10105136",
        "LV": "1",
        "Item Description": "LIQ ROCKEYS BOTANICAL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "995627",
        "Last Price Paid": "$16.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105136",
        "Product Status": "Active"
    },
    {
        "Root": "10035209",
        "LV": "1",
        "Item Description": "LIQUEUR MOZART WHITE CHOCOLATE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "29296",
        "Last Price Paid": "$21.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035209",
        "Product Status": "Active"
    },
    {
        "Root": "10035209",
        "LV": "1",
        "Item Description": "LIQUEUR MOZART WHITE CHOCOLATE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035209001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "29296",
        "Last Price Paid": "$21.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035209",
        "Product Status": "Active"
    },
    {
        "Root": "10020065",
        "LV": "1",
        "Item Description": "DARK DOMINO XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020065001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LB008",
        "Last Price Paid": "$75.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020065",
        "Product Status": "Active"
    },
    {
        "Root": "10035814",
        "LV": "1",
        "Item Description": "CUP BAKE GOLD LARGE CLG-111C 5OZ.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035814001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51017334",
        "Last Price Paid": "$0.11",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035814",
        "Product Status": "Active"
    },
    {
        "Root": "10012240",
        "LV": "1",
        "Item Description": "ASIAN BLEND SMALL BITES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012240001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04SBAB-33-R",
        "Last Price Paid": "$38.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012240",
        "Product Status": "Active"
    },
    {
        "Root": "10036894",
        "LV": "2",
        "Item Description": "BAKING CUP RED W WHITE DOTS SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036894002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "24680",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036894",
        "Product Status": "Active"
    },
    {
        "Root": "10009584",
        "LV": "1",
        "Item Description": "TEQUILA 1800 RESPOSAD LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12382",
        "Last Price Paid": "$26.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009584",
        "Product Status": "Active"
    },
    {
        "Root": "10038867",
        "LV": "1",
        "Item Description": "BAG KRAFT COOKIE/PASTRY 6.5X1X8 ECOCRAFT 300100",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008063",
        "Last Price Paid": "$0.03",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038867",
        "Product Status": "Active"
    },
    {
        "Root": "10001150",
        "LV": "1",
        "Item Description": "CRAB KING SPLIT 20 LB 16/20 SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001150001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$42.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001150",
        "Product Status": "Active"
    },
    {
        "Root": "10001150",
        "LV": "1",
        "Item Description": "CRAB KING SPLIT 20 LB 16/20 SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001150001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6328006",
        "Last Price Paid": "$42.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001150",
        "Product Status": "Active"
    },
    {
        "Root": "10001150",
        "LV": "1",
        "Item Description": "CRAB KING SPLIT 20 LB 16/20 SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001150001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "78-41605",
        "Last Price Paid": "$42.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001150",
        "Product Status": "Active"
    },
    {
        "Root": "10006502",
        "LV": "1",
        "Item Description": "SB CUP HOT 4OZ SAMPLE SIZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11035636",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006502",
        "Product Status": "Active"
    },
    {
        "Root": "10006934",
        "LV": "1",
        "Item Description": "CHARD CAMBRIA KATHERINE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006934001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "556644",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006934",
        "Product Status": "Active"
    },
    {
        "Root": "10005116",
        "LV": "1",
        "Item Description": "SPICE OREGANO GRD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005116001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005116",
        "Product Status": "Active"
    },
    {
        "Root": "10004292",
        "LV": "2",
        "Item Description": "SB MUFFIN PUMPKIN CREAM CHEESE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004292001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11051858",
        "Last Price Paid": "$1.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004292",
        "Product Status": "Active"
    },
    {
        "Root": "10027943",
        "LV": "1",
        "Item Description": "BOUCHARD POUILLY FUISSE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027943001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9175385",
        "Last Price Paid": "$25.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027943",
        "Product Status": "Active"
    },
    {
        "Root": "10032564",
        "LV": "1",
        "Item Description": "LINER TOY STORY BAKE WRAP 10001 PLAIN 6X10.75NK6T",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032564001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008090",
        "Last Price Paid": "$0.01",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032564",
        "Product Status": "Active"
    },
    {
        "Root": "10027878",
        "LV": "1",
        "Item Description": "COCOA POWDER 22-24% NOEL 11 LB TEMPORARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA970",
        "Last Price Paid": "$51.81",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027878",
        "Product Status": "Active"
    },
    {
        "Root": "10004058",
        "LV": "1",
        "Item Description": "CHOC MLK ELGNCE DECO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004058001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "97140",
        "Last Price Paid": "$0.21",
        "Case Pack": "960",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004058",
        "Product Status": "Active"
    },
    {
        "Root": "10002601",
        "LV": "1",
        "Item Description": "CARROT ROUND BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01BRCT-26",
        "Last Price Paid": "$125.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002601",
        "Product Status": "Active"
    },
    {
        "Root": "10002601",
        "LV": "1",
        "Item Description": "CARROT ROUND BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22307",
        "Last Price Paid": "$125.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002601",
        "Product Status": "Active"
    },
    {
        "Root": "10000938",
        "LV": "1",
        "Item Description": "GROUPER BLK SKIN ON FLT SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000938001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "4672747",
        "Last Price Paid": "$19.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000938",
        "Product Status": "Active"
    },
    {
        "Root": "10000938",
        "LV": "1",
        "Item Description": "GROUPER BLK SKIN ON FLT SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000938001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000938",
        "Product Status": "Active"
    },
    {
        "Root": "10000938",
        "LV": "1",
        "Item Description": "GROUPER BLK SKIN ON FLT SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000938001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00339",
        "Last Price Paid": "$19.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000938",
        "Product Status": "Active"
    },
    {
        "Root": "10005001",
        "LV": "1",
        "Item Description": "ACID TARTARIC 1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "661076",
        "Last Price Paid": "$17.98",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005001",
        "Product Status": "Active"
    },
    {
        "Root": "10032764",
        "LV": "1",
        "Item Description": "AMORETTE'S DIS CORNER TRAY120 8X8X4 BOX 2PMS 6",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.16",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032764",
        "Product Status": "Active"
    },
    {
        "Root": "10038377",
        "LV": "1",
        "Item Description": "BAKING CUP YELLOW SCALLOPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "25311",
        "Last Price Paid": "$0.08",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038377",
        "Product Status": "Active"
    },
    {
        "Root": "10004601",
        "LV": "1",
        "Item Description": "SAUCE CRANBERRY JELLD #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2328490",
        "Last Price Paid": "$9.29",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004601",
        "Product Status": "Active"
    },
    {
        "Root": "10005779",
        "LV": "1",
        "Item Description": "DOUGH SUGAR CHOCOLATE LARGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.58",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005779",
        "Product Status": "Active"
    },
    {
        "Root": "10088541",
        "LV": "1",
        "Item Description": "BEER CAN STELLA ARTOIS 16OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.65",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088541",
        "Product Status": "Active"
    },
    {
        "Root": "10108630",
        "LV": "2",
        "Item Description": "TOPPER WH CHOC SALLY 300 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108630002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2313CP2",
        "Last Price Paid": "$0.72",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10108630",
        "Product Status": "Active"
    },
    {
        "Root": "10002365",
        "LV": "1",
        "Item Description": "CHEESE CHEDDAR SMOKED YELLOW TILLAMOOK 6/2 LB PIEC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002365001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "6206",
        "Last Price Paid": "$12.54",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002365",
        "Product Status": "Active"
    },
    {
        "Root": "10004839",
        "LV": "1",
        "Item Description": "PEAR MINI 12/30Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004839001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004839",
        "Product Status": "Active"
    },
    {
        "Root": "10003977",
        "LV": "1",
        "Item Description": "HONEY WILD OAK 17.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-11135",
        "Last Price Paid": "$11.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003977",
        "Product Status": "Active"
    },
    {
        "Root": "10021335",
        "LV": "2",
        "Item Description": "FATTORIA POGGIO ALLORO VERNACCIA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021335002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "803326718003",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021335",
        "Product Status": "Active"
    },
    {
        "Root": "10010060",
        "LV": "1",
        "Item Description": "BEER NEW BLGM FT TIRE AMBR 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.27",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010060",
        "Product Status": "Active"
    },
    {
        "Root": "10104849",
        "LV": "2",
        "Item Description": "CUP COLD 32OZ MARY BLAIR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104849002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$60.49",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104849",
        "Product Status": "Active"
    },
    {
        "Root": "10000516",
        "LV": "1",
        "Item Description": "CAKE CHOCOLATE SHEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000516001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$12.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000516",
        "Product Status": "Active"
    },
    {
        "Root": "10034054",
        "LV": "1",
        "Item Description": "MILK SUB ALMOND UNSWEETENED ALMOND BREEZE 12/1QT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1669720",
        "Last Price Paid": "$3.18",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034054",
        "Product Status": "Active"
    },
    {
        "Root": "10004528",
        "LV": "1",
        "Item Description": "CHOC CUP POUCHETTE MINI M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004528001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "TB00425Z",
        "Last Price Paid": "$0.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004528",
        "Product Status": "Active"
    },
    {
        "Root": "10023101",
        "LV": "1",
        "Item Description": "CHOC DRK EASEL 6.5 IN HIGH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023101001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1209D",
        "Last Price Paid": "$4.00",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023101",
        "Product Status": "Active"
    },
    {
        "Root": "10106809",
        "LV": "1",
        "Item Description": "PASTRY CINN ROLL MICKEY SMALL RTB 1.6OZ/80CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106809001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.41",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106809",
        "Product Status": "Active"
    },
    {
        "Root": "10083710",
        "LV": "1",
        "Item Description": "MIX BAR BANANA CONCENTRATE 3 TO 1 FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083710001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3704524",
        "Last Price Paid": "$6.22",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083710",
        "Product Status": "Active"
    },
    {
        "Root": "10006386",
        "LV": "1",
        "Item Description": "FILTER BUNN (20111) COFFEE 6 GAL 252CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.08",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006386",
        "Product Status": "Active"
    },
    {
        "Root": "10104652",
        "LV": "1",
        "Item Description": "WINE RICE MIRIN COOKING SWEET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2314730",
        "Last Price Paid": "$14.65",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104652",
        "Product Status": "Active"
    },
    {
        "Root": "10104440",
        "LV": "1",
        "Item Description": "BEER IVANHOE BERRIES AND CREAM BERLINER WEISS 15.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104440001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "76401",
        "Last Price Paid": "$206.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104440",
        "Product Status": "Active"
    },
    {
        "Root": "10002112",
        "LV": "1",
        "Item Description": "CHARGE ER/LATE ORDERS EXPENSED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002112001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "888888",
        "Last Price Paid": "$1.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002112",
        "Product Status": "Active"
    },
    {
        "Root": "10006769",
        "LV": "1",
        "Item Description": "BREAD PULLMAN BRIOCHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006769001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "3:B",
        "Last Price Paid": "$4.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006769",
        "Product Status": "Active"
    },
    {
        "Root": "10029748",
        "LV": "1",
        "Item Description": "BRUSSEL SPROUTS HALVED FC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480657",
        "Last Price Paid": "$14.05",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029748",
        "Product Status": "Active"
    },
    {
        "Root": "10001455",
        "LV": "1",
        "Item Description": "TOMATOES 5X5 VR DISNEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410066",
        "Last Price Paid": "$0.91",
        "Case Pack": "38",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001455",
        "Product Status": "Active"
    },
    {
        "Root": "10001455",
        "LV": "1",
        "Item Description": "TOMATOES 5X5 VR DISNEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23014",
        "Last Price Paid": "$0.91",
        "Case Pack": "38",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001455",
        "Product Status": "Active"
    },
    {
        "Root": "10010321",
        "LV": "2",
        "Item Description": "BEER CHIMAY BLUE CAP BTL 11.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010321001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "15023",
        "Last Price Paid": "$4.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010321",
        "Product Status": "Active"
    },
    {
        "Root": "10004922",
        "LV": "1",
        "Item Description": "MIX PARTY KICKIN CAJUN 25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004922001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "10315",
        "Last Price Paid": "$84.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004922",
        "Product Status": "Active"
    },
    {
        "Root": "10014169",
        "LV": "1",
        "Item Description": "KALE EXOTIC PETITE 50CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07PKM-33",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014169",
        "Product Status": "Active"
    },
    {
        "Root": "10019446",
        "LV": "1",
        "Item Description": "SPICE PEPPERCORN TASMANIAN 8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019446001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SPI010",
        "Last Price Paid": "$87.10",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019446",
        "Product Status": "Active"
    },
    {
        "Root": "10000752",
        "LV": "1",
        "Item Description": "VENISON TENDERLOIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000752001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4530012",
        "Last Price Paid": "$21.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000752",
        "Product Status": "Active"
    },
    {
        "Root": "10000752",
        "LV": "1",
        "Item Description": "VENISON TENDERLOIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000752001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000752",
        "Product Status": "Active"
    },
    {
        "Root": "10031045",
        "LV": "1",
        "Item Description": "CAB SAV ZUCARDI 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "311970",
        "Last Price Paid": "$14.07",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031045",
        "Product Status": "Active"
    },
    {
        "Root": "10001591",
        "LV": "1",
        "Item Description": "HERB PARSLEY ITALIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001591001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001591",
        "Product Status": "Active"
    },
    {
        "Root": "10016530",
        "LV": "1",
        "Item Description": "BREAD SOURDOUGH PULLMAN UNSLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "02123D",
        "Last Price Paid": "$2.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016530",
        "Product Status": "Active"
    },
    {
        "Root": "10087730",
        "LV": "1",
        "Item Description": "MIXER DTD YUZU SOUR MIX NA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "YSM-750",
        "Last Price Paid": "$8.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087730",
        "Product Status": "Active"
    },
    {
        "Root": "10014215",
        "LV": "1",
        "Item Description": "HONEYCOMB 6EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014215001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "30044",
        "Last Price Paid": "$12.13",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014215",
        "Product Status": "Active"
    },
    {
        "Root": "10008162",
        "LV": "1",
        "Item Description": "PINOT GRIS WILLIAMETTE VALLEY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008162001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "1594449",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008162",
        "Product Status": "Active"
    },
    {
        "Root": "10038819",
        "LV": "1",
        "Item Description": "MUFFIN BANANA 5 OUNCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038819001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.88",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038819",
        "Product Status": "Active"
    },
    {
        "Root": "10004615",
        "LV": "1",
        "Item Description": "ONION CHOPPED PC 9GRM 200",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5343249",
        "Last Price Paid": "$0.08",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004615",
        "Product Status": "Active"
    },
    {
        "Root": "10034821",
        "LV": "1",
        "Item Description": "WREATH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034821001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "Y1430",
        "Last Price Paid": "$0.37",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034821",
        "Product Status": "Active"
    },
    {
        "Root": "10007962",
        "LV": "1",
        "Item Description": "RED CAMPO RIPASSO MASI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "205742",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007962",
        "Product Status": "Active"
    },
    {
        "Root": "10007962",
        "LV": "1",
        "Item Description": "RED CAMPO RIPASSO MASI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "205742",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007962",
        "Product Status": "Active"
    },
    {
        "Root": "10016030",
        "LV": "2",
        "Item Description": "LID PORTION CLR USE WITH 10016029 / 10021772",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016030001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11600318",
        "Last Price Paid": "$0.01",
        "Case Pack": "2500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016030",
        "Product Status": "Active"
    },
    {
        "Root": "10093940",
        "LV": "1",
        "Item Description": "GELATO GS BELGIAN CHOCOLATE SS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093940001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "GS3005",
        "Last Price Paid": "$15.38",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093940",
        "Product Status": "Active"
    },
    {
        "Root": "10016145",
        "LV": "1",
        "Item Description": "LYCHEE LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016145001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481897",
        "Last Price Paid": "$38.62",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016145",
        "Product Status": "Active"
    },
    {
        "Root": "10014822",
        "LV": "1",
        "Item Description": "CIRCLE CAKE 12\" GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51616602",
        "Last Price Paid": "$0.51",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014822",
        "Product Status": "Active"
    },
    {
        "Root": "10106128",
        "LV": "1",
        "Item Description": "PAVELOT SAVIGNY PEUILLETS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106128001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9615636",
        "Last Price Paid": "$36.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106128",
        "Product Status": "Active"
    },
    {
        "Root": "10005345",
        "LV": "1",
        "Item Description": "DEC QUINN STARS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005345001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "11144",
        "Last Price Paid": "$11.91",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005345",
        "Product Status": "Active"
    },
    {
        "Root": "10106191",
        "LV": "1",
        "Item Description": "FRENCH TOAST WHOLE GR STICK 1.11 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "37598",
        "Last Price Paid": "$11.31",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106191",
        "Product Status": "Active"
    },
    {
        "Root": "10045622",
        "LV": "1",
        "Item Description": "CHEESE FRESH BURRATA BALL 4 OZ PORTION 6/8 OZ TUBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045622001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6633291",
        "Last Price Paid": "$1.64",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045622",
        "Product Status": "Active"
    },
    {
        "Root": "10018167",
        "LV": "1",
        "Item Description": "LID DOME NO HOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018167001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8627796",
        "Last Price Paid": "$0.08",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018167",
        "Product Status": "Active"
    },
    {
        "Root": "10002603",
        "LV": "1",
        "Item Description": "POPCORN TRUMPET SHOOTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04TP-33-E",
        "Last Price Paid": "$30.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002603",
        "Product Status": "Active"
    },
    {
        "Root": "10017752",
        "LV": "1",
        "Item Description": "MUSHROOM PORCINI CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017752001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "598001",
        "Last Price Paid": "$165.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017752",
        "Product Status": "Active"
    },
    {
        "Root": "10015403",
        "LV": "2",
        "Item Description": "CHEESE FRESH MOZZARELLA CILIEGINE CRYO-DRY 6/1.75#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015403002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8350544",
        "Last Price Paid": "$6.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10015403",
        "Product Status": "Active"
    },
    {
        "Root": "10023176",
        "LV": "1",
        "Item Description": "TOPPING SAUSAGE ITALIAN HAND PULLED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "82317",
        "Last Price Paid": "$16.41",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023176",
        "Product Status": "Active"
    },
    {
        "Root": "10037124",
        "LV": "1",
        "Item Description": "PINEAPPLE GOLDEN RIPE PEELED DICED 3/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037124001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-007D-38",
        "Last Price Paid": "$2.55",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037124",
        "Product Status": "Active"
    },
    {
        "Root": "10010305",
        "LV": "2",
        "Item Description": "CIDER STRONGBOW 24/12OZ BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010305001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "23791",
        "Last Price Paid": "$1.53",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010305",
        "Product Status": "Active"
    },
    {
        "Root": "10088216",
        "LV": "1",
        "Item Description": "ICE CREAM DIBS VANILLA CRUNCH 16CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088216001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.76",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088216",
        "Product Status": "Active"
    },
    {
        "Root": "10041096",
        "LV": "1",
        "Item Description": "MIX CAKE CHOC DIETARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "A62205",
        "Last Price Paid": "$17.69",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041096",
        "Product Status": "Active"
    },
    {
        "Root": "10002051",
        "LV": "1",
        "Item Description": "ENDIVE BELGIAN WHITE-10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002051",
        "Product Status": "Active"
    },
    {
        "Root": "10002051",
        "LV": "1",
        "Item Description": "ENDIVE BELGIAN WHITE-10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002051001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403010",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002051",
        "Product Status": "Active"
    },
    {
        "Root": "10082054",
        "LV": "1",
        "Item Description": "WINE SPARK STEENBERG BRUT 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082054001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "132646",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082054",
        "Product Status": "Active"
    },
    {
        "Root": "10010439",
        "LV": "2",
        "Item Description": "BEER ROGUE MOCHA PORTER 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010439001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10743",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010439",
        "Product Status": "Active"
    },
    {
        "Root": "10110886",
        "LV": "2",
        "Item Description": "TRAY FOOD 1/4 LB KRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110886001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10110886",
        "Product Status": "Active"
    },
    {
        "Root": "10010353",
        "LV": "1",
        "Item Description": "FRANZISKANER WEISS BIER 12 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "844206",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010353",
        "Product Status": "Active"
    },
    {
        "Root": "10002540",
        "LV": "1",
        "Item Description": "MUSHROOM CHANTERELLE DRIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002540001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481732",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002540",
        "Product Status": "Active"
    },
    {
        "Root": "10085655",
        "LV": "1",
        "Item Description": "BEEF SUB PATTY BURGER MEATLESS VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10085655001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9803961",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10085655",
        "Product Status": "Active"
    },
    {
        "Root": "10034392",
        "LV": "1",
        "Item Description": "BAR MAPLE PECAN SEA SALT KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034392001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034392",
        "Product Status": "Active"
    },
    {
        "Root": "10003461",
        "LV": "1",
        "Item Description": "ANCHOVIES IN SALT SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003461001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003461",
        "Product Status": "Active"
    },
    {
        "Root": "10041036",
        "LV": "1",
        "Item Description": "CRIOLLO CHOCOLATE EASTER EGG ASSORTMENT 120/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041036",
        "Product Status": "Active"
    },
    {
        "Root": "10014583",
        "LV": "2",
        "Item Description": "SHRIMP 21/25 FROZEN RAW EZ PEEL SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014583002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10014583",
        "Product Status": "Active"
    },
    {
        "Root": "10034455",
        "LV": "1",
        "Item Description": "MERLOT TRIG POINT DIAMOND DUST 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034455",
        "Product Status": "Active"
    },
    {
        "Root": "10020855",
        "LV": "1",
        "Item Description": "LIQ OM DRK CHOC SEAL SALT 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020855001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020855",
        "Product Status": "Active"
    },
    {
        "Root": "10031264",
        "LV": "2",
        "Item Description": "SYRUP POWERADE LEMON LIME 2.5 GALLON BIB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031264001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10031264",
        "Product Status": "Active"
    },
    {
        "Root": "10110631",
        "LV": "1",
        "Item Description": "BEER LEFT HAND CHAI MILK STOUT 1/2 BBL KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "16607",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110631",
        "Product Status": "Active"
    },
    {
        "Root": "10000078",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN FILET 8Z GRAIN FED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000078001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2195104",
        "Last Price Paid": "$34.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000078",
        "Product Status": "Active"
    },
    {
        "Root": "10004985",
        "LV": "1",
        "Item Description": "DESICCANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "05...{SCHULER{",
        "Last Price Paid": "$0.67",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004985",
        "Product Status": "Active"
    },
    {
        "Root": "10039455",
        "LV": "1",
        "Item Description": "CEREAL SPECIAL K BOX 70 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.37",
        "Case Pack": "70",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039455",
        "Product Status": "Active"
    },
    {
        "Root": "10001092",
        "LV": "1",
        "Item Description": "LOBSTER TAIL W/W 8 OZ SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001092001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6475123",
        "Last Price Paid": "$24.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001092",
        "Product Status": "Active"
    },
    {
        "Root": "10018654",
        "LV": "1",
        "Item Description": "CUP BAKING 1-7/8\" RED W/WHT DOTS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018654001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51408012",
        "Last Price Paid": "$0.07",
        "Case Pack": "3000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018654",
        "Product Status": "Active"
    },
    {
        "Root": "10017193",
        "LV": "1",
        "Item Description": "TEA CHRISTMAS BLEND TWINNINGS 120 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017193001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017193",
        "Product Status": "Active"
    },
    {
        "Root": "10023619",
        "LV": "1",
        "Item Description": "COOKIE, SNICKERDOODLE GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023619001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10015601",
        "Last Price Paid": "$1.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023619",
        "Product Status": "Active"
    },
    {
        "Root": "10013091",
        "LV": "1",
        "Item Description": "RICE CAROLINA GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013091001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "RI065",
        "Last Price Paid": "$9.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013091",
        "Product Status": "Active"
    },
    {
        "Root": "10102249",
        "LV": "1",
        "Item Description": "BREAD SOURDOUGH SLICED 4X4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "527210",
        "Last Price Paid": "$0.77",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102249",
        "Product Status": "Active"
    },
    {
        "Root": "10033431",
        "LV": "1",
        "Item Description": "YAMAZAKI WHISKY 12 YEAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033431001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10761",
        "Last Price Paid": "$150.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033431",
        "Product Status": "Active"
    },
    {
        "Root": "10040376",
        "LV": "1",
        "Item Description": "COOKIE GINGERBREAD STITCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040376001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "GB-STITCH",
        "Last Price Paid": "$2.40",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040376",
        "Product Status": "Active"
    },
    {
        "Root": "10034392",
        "LV": "2",
        "Item Description": "BAR MAPLE PECAN SEA SALT KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034392002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "303551",
        "Last Price Paid": "$2.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034392",
        "Product Status": "Active"
    },
    {
        "Root": "10044232",
        "LV": "2",
        "Item Description": "COOKIE GINGERBREAD MAN UNDECORATED FOTH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044232001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "GB",
        "Last Price Paid": "$0.96",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10044232",
        "Product Status": "Active"
    },
    {
        "Root": "10107920",
        "LV": "1",
        "Item Description": "EIRA 700ML STILL WATER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107920001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "EIR01",
        "Last Price Paid": "$4.49",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107920",
        "Product Status": "Active"
    },
    {
        "Root": "10037353",
        "LV": "1",
        "Item Description": "VEGANAISE SOY-FREE 16 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037353001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "106016",
        "Last Price Paid": "$4.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037353",
        "Product Status": "Active"
    },
    {
        "Root": "10025316",
        "LV": "1",
        "Item Description": "BAG DELI LG DUBLVIEW 4.25 X 2.75 X 9.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025316001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008030",
        "Last Price Paid": "$0.22",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025316",
        "Product Status": "Active"
    },
    {
        "Root": "10022978",
        "LV": "1",
        "Item Description": "RUM BACARDI RASPBERRY LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "408512",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022978",
        "Product Status": "Active"
    },
    {
        "Root": "10003714",
        "LV": "2",
        "Item Description": "SB CARAMEL SAUCE 36OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003714001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11061421",
        "Last Price Paid": "$11.03",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003714",
        "Product Status": "Active"
    },
    {
        "Root": "10006352",
        "LV": "1",
        "Item Description": "KNIFE HVY WT SILVER REFLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7653421",
        "Last Price Paid": "$0.08",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006352",
        "Product Status": "Active"
    },
    {
        "Root": "10001724",
        "LV": "1",
        "Item Description": "PEPPER POBLANO 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001724001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001724",
        "Product Status": "Active"
    },
    {
        "Root": "10036090",
        "LV": "1",
        "Item Description": "VERMOUTH RED GONZALES BYASS LA COPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036090001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "938848",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036090",
        "Product Status": "Active"
    },
    {
        "Root": "10005015",
        "LV": "1",
        "Item Description": "SPRINKLES RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005015001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "9738",
        "Last Price Paid": "$13.76",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005015",
        "Product Status": "Active"
    },
    {
        "Root": "10020143",
        "LV": "1",
        "Item Description": "CAB SAUV FEDERALIST 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020143001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "550653",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020143",
        "Product Status": "Active"
    },
    {
        "Root": "10101407",
        "LV": "1",
        "Item Description": "WAFFLE CONES SALTED BLUE CORN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101407001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "SBC-M",
        "Last Price Paid": "$0.82",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101407",
        "Product Status": "Active"
    },
    {
        "Root": "10105609",
        "LV": "1",
        "Item Description": "BAG FOOD STRG 10X8X24 PLYLN DISNEY HARVEST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1032653",
        "Last Price Paid": "$0.01",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105609",
        "Product Status": "Active"
    },
    {
        "Root": "10106377",
        "LV": "1",
        "Item Description": "WHITE CHOCOLATE MINT SAUCE 63 FL OZ 4-EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106377001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11145224",
        "Last Price Paid": "$21.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106377",
        "Product Status": "Active"
    },
    {
        "Root": "10007096",
        "LV": "1",
        "Item Description": "RIES KAB SELB OSTER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069637",
        "Vendor": "IMPORT ANT WINES INCE",
        "Vendor Item Nb": "16602",
        "Last Price Paid": "$16.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007096",
        "Product Status": "Active"
    },
    {
        "Root": "10007096",
        "LV": "1",
        "Item Description": "RIES KAB SELB OSTER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16602",
        "Last Price Paid": "$16.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007096",
        "Product Status": "Active"
    },
    {
        "Root": "10009823",
        "LV": "2",
        "Item Description": "RUM APPLETON ESTATE RESERVE 8 YEAR LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009823001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "964129",
        "Last Price Paid": "$31.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009823",
        "Product Status": "Active"
    },
    {
        "Root": "10007553",
        "LV": "2",
        "Item Description": "BLEND MISCHIEF MAKER BLACK PEARL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007553001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "BLAPERMISMAK",
        "Last Price Paid": "$14.61",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007553",
        "Product Status": "Active"
    },
    {
        "Root": "10042385",
        "LV": "1",
        "Item Description": "WHISKEY UNCLE NEAREST 1856 PREMIUM 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042385001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042385",
        "Product Status": "Active"
    },
    {
        "Root": "10015122",
        "LV": "1",
        "Item Description": "BERRY GOGI DRIED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015122001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480468",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015122",
        "Product Status": "Active"
    },
    {
        "Root": "10001526",
        "LV": "1",
        "Item Description": "HERB SAGE MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14MS-33-S",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001526",
        "Product Status": "Active"
    },
    {
        "Root": "10002610",
        "LV": "1",
        "Item Description": "CORN YELLOW - EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002610001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002610",
        "Product Status": "Active"
    },
    {
        "Root": "10038471",
        "LV": "1",
        "Item Description": "SUGAR TROPICAL SUMMER ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "50767",
        "Last Price Paid": "$0.22",
        "Case Pack": "98",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038471",
        "Product Status": "Active"
    },
    {
        "Root": "10004191",
        "LV": "1",
        "Item Description": "TAPIOCA MALTODEXTRIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004191001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "MD026",
        "Last Price Paid": "$39.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004191",
        "Product Status": "Active"
    },
    {
        "Root": "10006647",
        "LV": "1",
        "Item Description": "GLOW LIGHT BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006647001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000384112",
        "Vendor": "QUEST EVENTS LLC DBA TOTALLY MOD",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006647",
        "Product Status": "Active"
    },
    {
        "Root": "10003522",
        "LV": "1",
        "Item Description": "SYRUP COF S/F RASPBERRY L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003522001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-6040",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003522",
        "Product Status": "Active"
    },
    {
        "Root": "10102909",
        "LV": "1",
        "Item Description": "KOSHER BSC CHICKEN STRIP 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102909001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102909",
        "Product Status": "Active"
    },
    {
        "Root": "10019426",
        "LV": "1",
        "Item Description": "CONT CLMSHELL CLR TEC 8X3.25X2.75",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11357083",
        "Last Price Paid": "$0.54",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019426",
        "Product Status": "Active"
    },
    {
        "Root": "10044317",
        "LV": "1",
        "Item Description": "GRAPE LEAVES DOLMAS STUFFED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044317001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "37423",
        "Last Price Paid": "$15.19",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044317",
        "Product Status": "Active"
    },
    {
        "Root": "10032829",
        "LV": "1",
        "Item Description": "QUINS CONFETTI WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032829001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "21417",
        "Last Price Paid": "$5.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032829",
        "Product Status": "Active"
    },
    {
        "Root": "10016150",
        "LV": "1",
        "Item Description": "GARLIC GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460382",
        "Last Price Paid": "$49.56",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016150",
        "Product Status": "Active"
    },
    {
        "Root": "10004873",
        "LV": "1",
        "Item Description": "SALT SEA MALDON 8.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-45180",
        "Last Price Paid": "$9.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004873",
        "Product Status": "Active"
    },
    {
        "Root": "10004873",
        "LV": "1",
        "Item Description": "SALT SEA MALDON 8.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004873",
        "Product Status": "Active"
    },
    {
        "Root": "10000080",
        "LV": "1",
        "Item Description": "BEEF FILET 4 OUNCE STEER AUSTRALIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000080001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2190011",
        "Last Price Paid": "$20.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000080",
        "Product Status": "Active"
    },
    {
        "Root": "10004679",
        "LV": "1",
        "Item Description": "DRIED FRUIT CURRANTS ZANTE 30#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "230403",
        "Last Price Paid": "$101.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004679",
        "Product Status": "Active"
    },
    {
        "Root": "10003508",
        "LV": "2",
        "Item Description": "TEA LEMON VERBENA 3.52OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003508001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W2003",
        "Last Price Paid": "$17.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003508",
        "Product Status": "Active"
    },
    {
        "Root": "10037921",
        "LV": "1",
        "Item Description": "GEL COLOR RED 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037921001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860390000",
        "Last Price Paid": "$4.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037921",
        "Product Status": "Active"
    },
    {
        "Root": "10008542",
        "LV": "1",
        "Item Description": "TEMP NUMANTHIA TERMANTHIA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "532765",
        "Last Price Paid": "$197.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008542",
        "Product Status": "Active"
    },
    {
        "Root": "10010481",
        "LV": "2",
        "Item Description": "FRANZ KELLER PINOT GRIS 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010481001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "264342",
        "Last Price Paid": "$17.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010481",
        "Product Status": "Active"
    },
    {
        "Root": "10003410",
        "LV": "1",
        "Item Description": "SYRUP SNO CONE BLUE RASPBERRY 4/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "1225H",
        "Last Price Paid": "$9.81",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003410",
        "Product Status": "Active"
    },
    {
        "Root": "10036291",
        "LV": "3",
        "Item Description": "HEINEKEN 0.0 NON-ALCOHOLIC 11.2Z BTLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036291002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "42855",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10036291",
        "Product Status": "Active"
    },
    {
        "Root": "10027261",
        "LV": "1",
        "Item Description": "TRAIL MIX DARK CHOCOLATE ENERGY BOOST 6/10 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "880031",
        "Last Price Paid": "$5.21",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027261",
        "Product Status": "Active"
    },
    {
        "Root": "10006219",
        "LV": "1",
        "Item Description": "PLATE PLASTIC BLACK 9 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006219001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6858559",
        "Last Price Paid": "$0.42",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006219",
        "Product Status": "Active"
    },
    {
        "Root": "10036753",
        "LV": "1",
        "Item Description": "RUM APPLETON ESTATE RSV BLEND 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036753001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "964124",
        "Last Price Paid": "$18.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036753",
        "Product Status": "Active"
    },
    {
        "Root": "10012175",
        "LV": "1",
        "Item Description": "BOURBON TAYLOR SINGLE BARREL EH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012175001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9186984",
        "Last Price Paid": "$46.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012175",
        "Product Status": "Active"
    },
    {
        "Root": "10000182",
        "LV": "1",
        "Item Description": "CELERY 24 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000182001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482031",
        "Last Price Paid": "$1.48",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000182",
        "Product Status": "Active"
    },
    {
        "Root": "10104208",
        "LV": "1",
        "Item Description": "JUICE FRZ MINUTE MAID LIMEADE CONCENTRATE 32OZ/12C",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104208001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "2500008222",
        "Last Price Paid": "$5.39",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104208",
        "Product Status": "Active"
    },
    {
        "Root": "10006642",
        "LV": "1",
        "Item Description": "BOX ICE TEMPORARY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000006360",
        "Vendor": "REDDY ICE CORPORATION",
        "Vendor Item Nb": "100048",
        "Last Price Paid": "$55.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006642",
        "Product Status": "Active"
    },
    {
        "Root": "10040435",
        "LV": "1",
        "Item Description": "CLEAR COLD CUP PLA 20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040435001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.24",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040435",
        "Product Status": "Active"
    },
    {
        "Root": "10092822",
        "LV": "1",
        "Item Description": "LIQUEUR DEKUYPER CREME DE CASSIS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092822001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "251968",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092822",
        "Product Status": "Active"
    },
    {
        "Root": "10003519",
        "LV": "1",
        "Item Description": "SYRUP MINT MOJITO LTR COF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003519001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5053",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003519",
        "Product Status": "Active"
    },
    {
        "Root": "10032923",
        "LV": "1",
        "Item Description": "BOW MINNIE LIGHT PINK 2 1/8 X 1 1/81",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032923001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW32289",
        "Last Price Paid": "$0.60",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032923",
        "Product Status": "Active"
    },
    {
        "Root": "10108140",
        "LV": "1",
        "Item Description": "TRUFFLE TROPICAL ASST 690 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108140001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "NLC-TROP",
        "Last Price Paid": "$1.96",
        "Case Pack": "690",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108140",
        "Product Status": "Active"
    },
    {
        "Root": "10002440",
        "LV": "2",
        "Item Description": "ICE CREAM VANILLA HDAZ 14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002440002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.53",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002440",
        "Product Status": "Active"
    },
    {
        "Root": "10010604",
        "LV": "2",
        "Item Description": "FIREFLY MS STRAWBERRY 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010604001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9184256",
        "Last Price Paid": "$9.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010604",
        "Product Status": "Active"
    },
    {
        "Root": "10108190",
        "LV": "1",
        "Item Description": "CEREAL CHEX CINNAMON SS BOWL 60/2OZ CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5478619",
        "Last Price Paid": "$1.31",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108190",
        "Product Status": "Active"
    },
    {
        "Root": "10001732",
        "LV": "1",
        "Item Description": "GRAIN QUINOA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001732001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001732",
        "Product Status": "Active"
    },
    {
        "Root": "10001777",
        "LV": "1",
        "Item Description": "GRAPEFRUIT SECTIONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001777001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "592020",
        "Last Price Paid": "$26.88",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001777",
        "Product Status": "Active"
    },
    {
        "Root": "10001777",
        "LV": "1",
        "Item Description": "GRAPEFRUIT SECTIONS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001777001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.88",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001777",
        "Product Status": "Active"
    },
    {
        "Root": "10018708",
        "LV": "1",
        "Item Description": "SUGAR SUB STEVIA RAW 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018708001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018708",
        "Product Status": "Active"
    },
    {
        "Root": "10001785",
        "LV": "1",
        "Item Description": "CARROT MIXED BABY PLD Y/W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$37.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001785",
        "Product Status": "Active"
    },
    {
        "Root": "10001785",
        "LV": "1",
        "Item Description": "CARROT MIXED BABY PLD Y/W",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "460400",
        "Last Price Paid": "$37.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001785",
        "Product Status": "Active"
    },
    {
        "Root": "10004064",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004064",
        "Product Status": "Active"
    },
    {
        "Root": "10006276",
        "LV": "1",
        "Item Description": "BAG 4.5X8.5 CIABATTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51492012",
        "Last Price Paid": "$0.03",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006276",
        "Product Status": "Active"
    },
    {
        "Root": "10037276",
        "LV": "1",
        "Item Description": "BASKET LINER ECO KRAFT 2C/1S YB 12X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037276001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51003095",
        "Last Price Paid": "$0.10",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037276",
        "Product Status": "Active"
    },
    {
        "Root": "10006896",
        "LV": "1",
        "Item Description": "CAB SAUV KEND JACK RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006896001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "592040",
        "Last Price Paid": "$12.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006896",
        "Product Status": "Active"
    },
    {
        "Root": "10089785",
        "LV": "1",
        "Item Description": "COASTER 4IN ROUND HUBBLE MW 1C/1S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510526",
        "Last Price Paid": "$0.06",
        "Case Pack": "3500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089785",
        "Product Status": "Active"
    },
    {
        "Root": "10035787",
        "LV": "1",
        "Item Description": "TORTILLA CHIP RAW 4 CUT WHITE CORN SHELF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035787001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5686096",
        "Last Price Paid": "$26.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035787",
        "Product Status": "Active"
    },
    {
        "Root": "10082148",
        "LV": "1",
        "Item Description": "BEER GAF KOLSCH 50 L KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "8250",
        "Last Price Paid": "$215.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082148",
        "Product Status": "Active"
    },
    {
        "Root": "10015123",
        "LV": "1",
        "Item Description": "BEET RED BABY PEELED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015123001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482043",
        "Last Price Paid": "$1.07",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015123",
        "Product Status": "Active"
    },
    {
        "Root": "10011080",
        "LV": "1",
        "Item Description": "CHEESECAKE MINI CHOCOLATE 1.3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1502-A",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011080",
        "Product Status": "Active"
    },
    {
        "Root": "10017086",
        "LV": "1",
        "Item Description": "OIL LEMON PURE BOYJIAN 12.7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017086001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "OL035-1",
        "Last Price Paid": "$50.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017086",
        "Product Status": "Active"
    },
    {
        "Root": "10007426",
        "LV": "1",
        "Item Description": "ALBARINO DON OLEGARIO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007426001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "275614",
        "Last Price Paid": "$12.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007426",
        "Product Status": "Active"
    },
    {
        "Root": "10101048",
        "LV": "1",
        "Item Description": "PAPER BAKE CUP LG VERTICAL RED STRIPE 2.375 X 2.25",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101048001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51091215",
        "Last Price Paid": "$0.17",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101048",
        "Product Status": "Active"
    },
    {
        "Root": "10010098",
        "LV": "1",
        "Item Description": "BEER BUD LIGHT 12OZ LNNR BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010098001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.98",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010098",
        "Product Status": "Active"
    },
    {
        "Root": "10014781",
        "LV": "1",
        "Item Description": "BOARD CAKE QRT 1/2\" GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014781001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ES14G",
        "Last Price Paid": "$1.86",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014781",
        "Product Status": "Active"
    },
    {
        "Root": "10010396",
        "LV": "2",
        "Item Description": "BEER BOTTLE SAPPORO LAGER 20.3OZ 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010396001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10718",
        "Last Price Paid": "$3.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010396",
        "Product Status": "Active"
    },
    {
        "Root": "10001763",
        "LV": "1",
        "Item Description": "ONION PEARL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001763001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "404043",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001763",
        "Product Status": "Active"
    },
    {
        "Root": "10001763",
        "LV": "1",
        "Item Description": "ONION PEARL WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001763001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001763",
        "Product Status": "Active"
    },
    {
        "Root": "10007851",
        "LV": "1",
        "Item Description": "BLEND CLINE CASHMERE GSM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "772524",
        "Last Price Paid": "$9.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007851",
        "Product Status": "Active"
    },
    {
        "Root": "10040005",
        "LV": "1",
        "Item Description": "CHOC WHITE RIVIERA ROUND DECOR 2200/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040005001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233960000",
        "Last Price Paid": "$0.16",
        "Case Pack": "2200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040005",
        "Product Status": "Active"
    },
    {
        "Root": "10002204",
        "LV": "2",
        "Item Description": "YOGURT GREEK VANILLA OIKOS 12/5.3Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002204001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002204",
        "Product Status": "Active"
    },
    {
        "Root": "10003554",
        "LV": "1",
        "Item Description": "PEPPERCORN WHITE WHOLE 6/1.37LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003554001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003554",
        "Product Status": "Active"
    },
    {
        "Root": "10008174",
        "LV": "1",
        "Item Description": "CAB SAUV MONDAVI NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "598919",
        "Last Price Paid": "$23.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008174",
        "Product Status": "Active"
    },
    {
        "Root": "10003583",
        "LV": "1",
        "Item Description": "ESPRESSO RISTRETTO 300 CT CAPS - PRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.57",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003583",
        "Product Status": "Active"
    },
    {
        "Root": "10107344",
        "LV": "1",
        "Item Description": "DISC DARK CHOC 3 CM 1584 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107344001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "5081D",
        "Last Price Paid": "$0.18",
        "Case Pack": "1584",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107344",
        "Product Status": "Active"
    },
    {
        "Root": "10027275",
        "LV": "1",
        "Item Description": "DRIED FRUIT MANGO SLICES 6/8 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "891421",
        "Last Price Paid": "$4.18",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027275",
        "Product Status": "Active"
    },
    {
        "Root": "10033951",
        "LV": "1",
        "Item Description": "GLO LIGHT GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033951001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000384112",
        "Vendor": "QUEST EVENTS LLC DBA TOTALLY MOD",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033951",
        "Product Status": "Active"
    },
    {
        "Root": "10008592",
        "LV": "1",
        "Item Description": "PINOT GRIS PULENTA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008592001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "532695",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008592",
        "Product Status": "Active"
    },
    {
        "Root": "10007828",
        "LV": "1",
        "Item Description": "CAB SAUV NATURA ORG 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007828001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "345684",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007828",
        "Product Status": "Active"
    },
    {
        "Root": "10035267",
        "LV": "1",
        "Item Description": "WINE DRC ROMANEE CONTI 2020",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9686246",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035267",
        "Product Status": "Active"
    },
    {
        "Root": "10093608",
        "LV": "1",
        "Item Description": "SWIZZLE STICK LED HOL22 MM JINGLE RED 200/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093608001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093608",
        "Product Status": "Active"
    },
    {
        "Root": "10075093",
        "LV": "1",
        "Item Description": "DECOR ANCHOR MICKEY BLISTER TO FILL 4CM SQUARE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10075093001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233860000",
        "Last Price Paid": "",
        "Case Pack": "14400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10075093",
        "Product Status": "Active"
    },
    {
        "Root": "10088301",
        "LV": "1",
        "Item Description": "LID PAPER WHITE 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088301001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1350",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088301",
        "Product Status": "Active"
    },
    {
        "Root": "10104671",
        "LV": "1",
        "Item Description": "POPCORN STRAWBERRY CRUNCH MDSE BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104671001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "STRAWBERRY PB1",
        "Last Price Paid": "$82.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104671",
        "Product Status": "Active"
    },
    {
        "Root": "10000213",
        "LV": "2",
        "Item Description": "MUSHROOM SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000213002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405010",
        "Last Price Paid": "$20.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000213",
        "Product Status": "Active"
    },
    {
        "Root": "10000213",
        "LV": "2",
        "Item Description": "MUSHROOM SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000213002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "480633",
        "Last Price Paid": "$20.85",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000213",
        "Product Status": "Active"
    },
    {
        "Root": "10107189",
        "LV": "1",
        "Item Description": "HAT, FEDORA DARK 3 1/4\" X 2 5/8\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW1910D",
        "Last Price Paid": "$1.60",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107189",
        "Product Status": "Active"
    },
    {
        "Root": "10045075",
        "LV": "1",
        "Item Description": "OLIVES PICHOLINE PITTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045075001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-15117",
        "Last Price Paid": "$27.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045075",
        "Product Status": "Active"
    },
    {
        "Root": "10040826",
        "LV": "1",
        "Item Description": "TRAY FOOD PAPER ECOSMART 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000499320",
        "Vendor": "GEORGIA PACIFIC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040826",
        "Product Status": "Active"
    },
    {
        "Root": "10040826",
        "LV": "1",
        "Item Description": "TRAY FOOD PAPER ECOSMART 1000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1655448",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040826",
        "Product Status": "Active"
    },
    {
        "Root": "10100760",
        "LV": "1",
        "Item Description": "CABBAGE, ARROWHEAD PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100760001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "07AHC-1",
        "Last Price Paid": "$5.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100760",
        "Product Status": "Active"
    },
    {
        "Root": "10044383",
        "LV": "1",
        "Item Description": "PASTRY, EPCOT, PHYLLO ALMOND ROLL NO MASTIHI 144/1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2579304",
        "Last Price Paid": "$0.54",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044383",
        "Product Status": "Active"
    },
    {
        "Root": "10030631",
        "LV": "1",
        "Item Description": "VODKA BELUGA NOBLE RUSSIAN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "592591",
        "Last Price Paid": "$26.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030631",
        "Product Status": "Active"
    },
    {
        "Root": "10035748",
        "LV": "2",
        "Item Description": "GUMPASTE SPRAY MULTI COLOR SMALL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035748002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "39261",
        "Last Price Paid": "$3.26",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10035748",
        "Product Status": "Active"
    },
    {
        "Root": "10002003",
        "LV": "1",
        "Item Description": "MIX VEG - CAR/CEL/ON 1 INCH ROUGH CUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002003001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511314",
        "Last Price Paid": "$14.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002003",
        "Product Status": "Active"
    },
    {
        "Root": "10107265",
        "LV": "1",
        "Item Description": "SALMON VERLASSO FILET SK/ON 35 LB CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107265001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6485306",
        "Last Price Paid": "$7.20",
        "Case Pack": "35",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107265",
        "Product Status": "Active"
    },
    {
        "Root": "10000170",
        "LV": "1",
        "Item Description": "LEMON 200 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000170001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.27",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000170",
        "Product Status": "Active"
    },
    {
        "Root": "10001445",
        "LV": "1",
        "Item Description": "ONION GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001445",
        "Product Status": "Active"
    },
    {
        "Root": "10001445",
        "LV": "1",
        "Item Description": "ONION GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406080",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001445",
        "Product Status": "Active"
    },
    {
        "Root": "10001445",
        "LV": "1",
        "Item Description": "ONION GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001445001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "27025",
        "Last Price Paid": "$6.31",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001445",
        "Product Status": "Active"
    },
    {
        "Root": "10000725",
        "LV": "1",
        "Item Description": "SALAMI SAPRESATTA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000725001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3613027",
        "Last Price Paid": "$6.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000725",
        "Product Status": "Active"
    },
    {
        "Root": "10006252",
        "LV": "1",
        "Item Description": "BAG DOUBLE OPEN SING ECOKRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008080",
        "Last Price Paid": "$0.03",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006252",
        "Product Status": "Active"
    },
    {
        "Root": "10000652",
        "LV": "1",
        "Item Description": "VEAL CHEEKS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000652001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1380078",
        "Last Price Paid": "$14.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000652",
        "Product Status": "Active"
    },
    {
        "Root": "10009798",
        "LV": "1",
        "Item Description": "RUM CPTN MORGAN SPICE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009798001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597191",
        "Last Price Paid": "$13.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009798",
        "Product Status": "Active"
    },
    {
        "Root": "10004183",
        "LV": "1",
        "Item Description": "BONBON MILK CHOC SALTED CARAMEL 100CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004183001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA8883",
        "Last Price Paid": "$0.67",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004183",
        "Product Status": "Active"
    },
    {
        "Root": "10003744",
        "LV": "1",
        "Item Description": "FLOUR BAKE GF BOBS 22Z/4EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003744001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101466",
        "Last Price Paid": "$4.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003744",
        "Product Status": "Active"
    },
    {
        "Root": "10009496",
        "LV": "1",
        "Item Description": "WHISKEY JK DANIEL BLK 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009496001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9180600",
        "Last Price Paid": "$9.70",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009496",
        "Product Status": "Active"
    },
    {
        "Root": "10006982",
        "LV": "1",
        "Item Description": "CHIANTI PLACIDO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006982001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006982",
        "Product Status": "Active"
    },
    {
        "Root": "10037831",
        "LV": "2",
        "Item Description": "CAKE CHOCOLATE FONDANT VALRHONA 2.8Z 20/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037831002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7067418",
        "Last Price Paid": "$1.40",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037831",
        "Product Status": "Active"
    },
    {
        "Root": "10006281",
        "LV": "1",
        "Item Description": "BOARD CAKE 12 INCH X 1/4 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006281001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1214...{ENJAY{",
        "Last Price Paid": "$1.08",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006281",
        "Product Status": "Active"
    },
    {
        "Root": "10104429",
        "LV": "1",
        "Item Description": "BEER DRAFT BREWDOG NIT JET BLACK HEART 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15793",
        "Last Price Paid": "$209.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104429",
        "Product Status": "Active"
    },
    {
        "Root": "10011762",
        "LV": "2",
        "Item Description": "PEAS MUSHY BATCHELORS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011762001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.65",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10011762",
        "Product Status": "Active"
    },
    {
        "Root": "10003756",
        "LV": "1",
        "Item Description": "MIX CAKE GOLDEN SPONGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003756001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "BEL & SCH",
        "Last Price Paid": "$79.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003756",
        "Product Status": "Active"
    },
    {
        "Root": "10000424",
        "LV": "1",
        "Item Description": "BREAD BOULE SOURDOUGH 10Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000424001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000424",
        "Product Status": "Active"
    },
    {
        "Root": "10002218",
        "LV": "1",
        "Item Description": "MILK NONDAIRY SOY PLAIN 1QT/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002218",
        "Product Status": "Active"
    },
    {
        "Root": "10038087",
        "LV": "2",
        "Item Description": "MEAT HOT DOG 6-1 2/5LB 6INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038087002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.35",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10038087",
        "Product Status": "Active"
    },
    {
        "Root": "10004282",
        "LV": "1",
        "Item Description": "SB LB BAR MARSHMALLOW DRE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004282001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11074671",
        "Last Price Paid": "$0.97",
        "Case Pack": "27",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004282",
        "Product Status": "Active"
    },
    {
        "Root": "10001823",
        "LV": "1",
        "Item Description": "MUSHROOM TRUMPET BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001823001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-50005",
        "Last Price Paid": "$85.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001823",
        "Product Status": "Active"
    },
    {
        "Root": "10001823",
        "LV": "1",
        "Item Description": "MUSHROOM TRUMPET BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001823001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "405201",
        "Last Price Paid": "$85.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001823",
        "Product Status": "Active"
    },
    {
        "Root": "10001823",
        "LV": "1",
        "Item Description": "MUSHROOM TRUMPET BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001823001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$85.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001823",
        "Product Status": "Active"
    },
    {
        "Root": "10026096",
        "LV": "1",
        "Item Description": "GLASS MARTINI 8 OZ 1PC 36CT/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026096001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11313376",
        "Last Price Paid": "$1.51",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026096",
        "Product Status": "Active"
    },
    {
        "Root": "10002804",
        "LV": "1",
        "Item Description": "NUT ALMOND SLICED BLANCHED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "111000",
        "Last Price Paid": "$113.36",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002804",
        "Product Status": "Active"
    },
    {
        "Root": "10006942",
        "LV": "1",
        "Item Description": "MERLOT MARKHAM VINEYDS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006942001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "130063",
        "Last Price Paid": "$16.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006942",
        "Product Status": "Active"
    },
    {
        "Root": "10033684",
        "LV": "1",
        "Item Description": "COLOR LUSTER SPRAY AMETHYST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033684001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5860700000",
        "Last Price Paid": "$29.68",
        "Case Pack": "224",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033684",
        "Product Status": "Active"
    },
    {
        "Root": "10006927",
        "LV": "1",
        "Item Description": "WINE PLUM TAKARA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "47656",
        "Last Price Paid": "$6.63",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006927",
        "Product Status": "Active"
    },
    {
        "Root": "10033490",
        "LV": "1",
        "Item Description": "KAPPA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "672149",
        "Last Price Paid": "$31.96",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033490",
        "Product Status": "Active"
    },
    {
        "Root": "10106369",
        "LV": "1",
        "Item Description": "BUMBLEBEE CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106369001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11143513",
        "Last Price Paid": "$1.18",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106369",
        "Product Status": "Active"
    },
    {
        "Root": "10002972",
        "LV": "1",
        "Item Description": "TEA GREEN INROOM 25CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002972001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002972",
        "Product Status": "Active"
    },
    {
        "Root": "10014158",
        "LV": "1",
        "Item Description": "PARSNIPS BABY 10# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014158001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01PARBT-26",
        "Last Price Paid": "$180.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014158",
        "Product Status": "Active"
    },
    {
        "Root": "10090603",
        "LV": "1",
        "Item Description": "CUP 12OZ CLEAR ROCKS GLASS RC0130 RPET FOTA PIE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090603001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "111587",
        "Last Price Paid": "$0.89",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090603",
        "Product Status": "Active"
    },
    {
        "Root": "10010559",
        "LV": "1",
        "Item Description": "AFFENTALER RIESLING MONKEY 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "30612",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010559",
        "Product Status": "Active"
    },
    {
        "Root": "10033006",
        "LV": "1",
        "Item Description": "CHICKEN CHUNK ALLERGY FREE FULLY COOKED 2/5LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033006001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6459750",
        "Last Price Paid": "$0.30",
        "Case Pack": "160",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033006",
        "Product Status": "Active"
    },
    {
        "Root": "10004510",
        "LV": "1",
        "Item Description": "POLENTA 20# ROLAND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004510001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9220906",
        "Last Price Paid": "$9.53",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004510",
        "Product Status": "Active"
    },
    {
        "Root": "10107888",
        "LV": "1",
        "Item Description": "KIDS HURRICANE BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073591",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107888",
        "Product Status": "Active"
    },
    {
        "Root": "10030427",
        "LV": "1",
        "Item Description": "AMARONE LA SALETTE MAREGA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030427001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "995182",
        "Last Price Paid": "$65.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030427",
        "Product Status": "Active"
    },
    {
        "Root": "10002704",
        "LV": "1",
        "Item Description": "FLOUR WHOLE WHEAT STONE GROUND ARDENT MILLS 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002704001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4023487",
        "Last Price Paid": "$20.22",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002704",
        "Product Status": "Active"
    },
    {
        "Root": "10001220",
        "LV": "1",
        "Item Description": "LAMB SHOULDER BONE IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001220001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1207211",
        "Last Price Paid": "$4.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001220",
        "Product Status": "Active"
    },
    {
        "Root": "10009850",
        "LV": "2",
        "Item Description": "COGNAC COURVOISIER VSOP LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009850001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "406997",
        "Last Price Paid": "$46.20",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009850",
        "Product Status": "Active"
    },
    {
        "Root": "10004132",
        "LV": "3",
        "Item Description": "POWDER EGG REPLACEMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004132002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "85050",
        "Last Price Paid": "$7.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10004132",
        "Product Status": "Active"
    },
    {
        "Root": "10081359",
        "LV": "2",
        "Item Description": "JAR MASON EPCOT F&W 16OZ RPET TWMA0130CCA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081359002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510203",
        "Last Price Paid": "$0.95",
        "Case Pack": "196",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10081359",
        "Product Status": "Active"
    },
    {
        "Root": "10100766",
        "LV": "1",
        "Item Description": "CARROT ULTRA MIXED 1LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100766001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01UMCT-2",
        "Last Price Paid": "$42.00",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100766",
        "Product Status": "Active"
    },
    {
        "Root": "10018682",
        "LV": "1",
        "Item Description": "COMPOUND CHAMPAGNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018682001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "314",
        "Last Price Paid": "$96.17",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018682",
        "Product Status": "Active"
    },
    {
        "Root": "10031834",
        "LV": "1",
        "Item Description": "FRESH FRUIT BANANA 5-6 COLOR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031834001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480010",
        "Last Price Paid": "$21.19",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031834",
        "Product Status": "Active"
    },
    {
        "Root": "10004578",
        "LV": "1",
        "Item Description": "APPLESAUCE UNSWEETENED #1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004578001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5328117",
        "Last Price Paid": "$8.51",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004578",
        "Product Status": "Active"
    },
    {
        "Root": "10004849",
        "LV": "1",
        "Item Description": "SAUCE WORCESTERSHIRE L&P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004849001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7143795",
        "Last Price Paid": "$16.04",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004849",
        "Product Status": "Active"
    },
    {
        "Root": "10017892",
        "LV": "1",
        "Item Description": "TURKEY THIGH BNLS CVP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017892001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.36",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017892",
        "Product Status": "Active"
    },
    {
        "Root": "10000301",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000301001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401320",
        "Last Price Paid": "$40.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000301",
        "Product Status": "Active"
    },
    {
        "Root": "10000301",
        "LV": "1",
        "Item Description": "RADISH WATERMELON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000301001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$40.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000301",
        "Product Status": "Active"
    },
    {
        "Root": "10039009",
        "LV": "1",
        "Item Description": "PATE A GLACER BRUNE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C0001",
        "Last Price Paid": "$60.04",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039009",
        "Product Status": "Active"
    },
    {
        "Root": "10106136",
        "LV": "1",
        "Item Description": "RIBOFLAVIN GLOW 3.5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "501601F",
        "Last Price Paid": "$26.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106136",
        "Product Status": "Active"
    },
    {
        "Root": "10002719",
        "LV": "1",
        "Item Description": "CEREAL CORN POPS BOWL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.29",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002719",
        "Product Status": "Active"
    },
    {
        "Root": "10034307",
        "LV": "1",
        "Item Description": "STAR ANISE WHOLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034307001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "164130",
        "Last Price Paid": "$8.79",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034307",
        "Product Status": "Active"
    },
    {
        "Root": "10003975",
        "LV": "1",
        "Item Description": "RICE CARNAROLLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003975001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-65005",
        "Last Price Paid": "$3.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003975",
        "Product Status": "Active"
    },
    {
        "Root": "10008236",
        "LV": "2",
        "Item Description": "NEBB GAJA BARBARESCO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008236001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9685957",
        "Last Price Paid": "$246.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008236",
        "Product Status": "Active"
    },
    {
        "Root": "10005315",
        "LV": "1",
        "Item Description": "OIL LEMON 6/12.7OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2...{BOYAJIAN{",
        "Last Price Paid": "$45.41",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005315",
        "Product Status": "Active"
    },
    {
        "Root": "10039684",
        "LV": "2",
        "Item Description": "CHUTNEY GINGER PEAR AND MINCEMEAT 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039684002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10039684",
        "Product Status": "Active"
    },
    {
        "Root": "10086529",
        "LV": "1",
        "Item Description": "CRACKER GRAHAM CINNAMON BUG SHAPED SS BAG 210/1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086529001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3885621",
        "Last Price Paid": "$0.20",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086529",
        "Product Status": "Active"
    },
    {
        "Root": "10041235",
        "LV": "1",
        "Item Description": "KNIFE POLYSTYRENE PLASTIC BLACK ULTRA SMART STOCK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041235001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "960",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041235",
        "Product Status": "Active"
    },
    {
        "Root": "10030864",
        "LV": "1",
        "Item Description": "D ARTAGNAN FROZEN VENISON OSSO BUCCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030864001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "ZVENNZE032",
        "Last Price Paid": "$9.99",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030864",
        "Product Status": "Active"
    },
    {
        "Root": "10042852",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK BDAY MICKEY EAR SET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042852001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1330D2",
        "Last Price Paid": "$2.79",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042852",
        "Product Status": "Active"
    },
    {
        "Root": "10003354",
        "LV": "1",
        "Item Description": "COFFEE BELLSSMO BN 5LB BL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$39.16",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003354",
        "Product Status": "Active"
    },
    {
        "Root": "10030229",
        "LV": "1",
        "Item Description": "BREAD FRENCH TOAST STICK WHOLE WHEAT COOKED 12/2LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.19",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030229",
        "Product Status": "Active"
    },
    {
        "Root": "10003771",
        "LV": "1",
        "Item Description": "SPICE SALT TRUFFLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CD151",
        "Last Price Paid": "$9.82",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003771",
        "Product Status": "Active"
    },
    {
        "Root": "10041034",
        "LV": "3",
        "Item Description": "BREAD BUN HOT DOG POTATO 5.5IN 6/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041034001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10041034",
        "Product Status": "Active"
    },
    {
        "Root": "10029616",
        "LV": "1",
        "Item Description": "CHOC HAUNTED HOUSE QUICKSAND 4\"X1 1/4\" RECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029616001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW25633",
        "Last Price Paid": "",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029616",
        "Product Status": "Active"
    },
    {
        "Root": "10109331",
        "LV": "1",
        "Item Description": "ROAST BEEF HOAGIE BOX LUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609850",
        "Vendor": "NATIONSMARKET LLC",
        "Vendor Item Nb": "850040073737",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109331",
        "Product Status": "Active"
    },
    {
        "Root": "10101415",
        "LV": "1",
        "Item Description": "WAFFLE CONES DARK CHOCOLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10101415001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000606557",
        "Vendor": "THE KONERY",
        "Vendor Item Nb": "C/H-M",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10101415",
        "Product Status": "Active"
    },
    {
        "Root": "10006738",
        "LV": "1",
        "Item Description": "BOARD CAKE 22 INCH X 1/2 INCH GOL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "2212...{ENJAY{",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006738",
        "Product Status": "Active"
    },
    {
        "Root": "10083672",
        "LV": "1",
        "Item Description": "BEER CAN CIGAR CITY CITRUS SIESTA 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083672001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083672",
        "Product Status": "Active"
    },
    {
        "Root": "10018863",
        "LV": "1",
        "Item Description": "DECOR FLASHY SPARKLES PURPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018863001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1006047",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018863",
        "Product Status": "Active"
    },
    {
        "Root": "10000062",
        "LV": "1",
        "Item Description": "VEAL RIB CHOP 11 OZ LOLLYPOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000062001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2330635",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000062",
        "Product Status": "Active"
    },
    {
        "Root": "10001582",
        "LV": "1",
        "Item Description": "FIG DRIED BLACK MISSION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001582001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001582",
        "Product Status": "Active"
    },
    {
        "Root": "10021699",
        "LV": "2",
        "Item Description": "DECOR FLASHY SPARKLE DEEP ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021699002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "1006013",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10021699",
        "Product Status": "Active"
    },
    {
        "Root": "10037926",
        "LV": "1",
        "Item Description": "SIMBA SKETCH WHITE CHOCOLATE ROUND 5CM 1050PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037926001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6233270000",
        "Last Price Paid": "",
        "Case Pack": "1050",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037926",
        "Product Status": "Active"
    },
    {
        "Root": "10001813",
        "LV": "1",
        "Item Description": "CAULIFLOWER 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401070",
        "Last Price Paid": "$2.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001813",
        "Product Status": "Active"
    },
    {
        "Root": "10001813",
        "LV": "1",
        "Item Description": "CAULIFLOWER 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.60",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001813",
        "Product Status": "Active"
    },
    {
        "Root": "10015547",
        "LV": "1",
        "Item Description": "CAVIAR OSETRA SUPREME POLISH 125 GRAMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015547001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60821400",
        "Last Price Paid": "$115.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015547",
        "Product Status": "Active"
    },
    {
        "Root": "10100492",
        "LV": "1",
        "Item Description": "LIQUOR RUM CACHACA NOVO FOGO SILVER 6/1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "185235",
        "Last Price Paid": "$21.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100492",
        "Product Status": "Active"
    },
    {
        "Root": "10100492",
        "LV": "1",
        "Item Description": "LIQUOR RUM CACHACA NOVO FOGO SILVER 6/1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "185235",
        "Last Price Paid": "$21.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100492",
        "Product Status": "Active"
    },
    {
        "Root": "10030437",
        "LV": "1",
        "Item Description": "DECORATION PEARLS SHIMMER WHITE 7 MM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030437001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "402016B",
        "Last Price Paid": "$15.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030437",
        "Product Status": "Active"
    },
    {
        "Root": "10004383",
        "LV": "1",
        "Item Description": "WATER SAN PELGN LIMONATA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.12",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004383",
        "Product Status": "Active"
    },
    {
        "Root": "10007736",
        "LV": "2",
        "Item Description": "SAUV BLC LOVEBLOCK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007736001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "568927",
        "Last Price Paid": "$15.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007736",
        "Product Status": "Active"
    },
    {
        "Root": "10000300",
        "LV": "1",
        "Item Description": "FLOWER MICRO ORCHID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401146",
        "Last Price Paid": "$15.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000300",
        "Product Status": "Active"
    },
    {
        "Root": "10000300",
        "LV": "1",
        "Item Description": "FLOWER MICRO ORCHID",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ORCEDMIC",
        "Last Price Paid": "$15.18",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000300",
        "Product Status": "Active"
    },
    {
        "Root": "10091313",
        "LV": "1",
        "Item Description": "WINE RED LIEVLAND BUSHVINE PINOTAGE 750ML/12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091313001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9353778",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091313",
        "Product Status": "Active"
    },
    {
        "Root": "10018567",
        "LV": "1",
        "Item Description": "GEL PRINCESS PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018567001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22494",
        "Last Price Paid": "$5.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018567",
        "Product Status": "Active"
    },
    {
        "Root": "10000256",
        "LV": "1",
        "Item Description": "SORREL RED RIBBON LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480393",
        "Last Price Paid": "$21.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000256",
        "Product Status": "Active"
    },
    {
        "Root": "10103349",
        "LV": "2",
        "Item Description": "DRESSING KENS ESSENTIALS GREEN GODDESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103349001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.55",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10103349",
        "Product Status": "Active"
    },
    {
        "Root": "10092221",
        "LV": "1",
        "Item Description": "CHOCOLATE BON CARAMEL APRICOT 100 CT STARCRUISER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VA8886",
        "Last Price Paid": "$0.67",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092221",
        "Product Status": "Active"
    },
    {
        "Root": "10000838",
        "LV": "2",
        "Item Description": "TURKEY BRST AIRLINE FRNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000838001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.26",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000838",
        "Product Status": "Active"
    },
    {
        "Root": "10009486",
        "LV": "2",
        "Item Description": "SCOTCH GLENMORNG 18YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009486001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "323282",
        "Last Price Paid": "$112.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009486",
        "Product Status": "Active"
    },
    {
        "Root": "10088425",
        "LV": "1",
        "Item Description": "BOX CAKE TIERED WINDOWED WHITE 12X12X14",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088425001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "450686",
        "Last Price Paid": "$7.08",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088425",
        "Product Status": "Active"
    },
    {
        "Root": "10003503",
        "LV": "2",
        "Item Description": "TEA CHAML FLWRS HRBL 5.6O",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003503001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W1234",
        "Last Price Paid": "$17.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003503",
        "Product Status": "Active"
    },
    {
        "Root": "10003969",
        "LV": "1",
        "Item Description": "OIL MEYER LEMON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-01080",
        "Last Price Paid": "$16.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003969",
        "Product Status": "Active"
    },
    {
        "Root": "10000178",
        "LV": "1",
        "Item Description": "GRAPE LUNCH BUNCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000178001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "260322",
        "Last Price Paid": "$0.32",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000178",
        "Product Status": "Active"
    },
    {
        "Root": "10037880",
        "LV": "1",
        "Item Description": "MIX DOLE WHIP STRAWBERRY 4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037880001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D542-A6120",
        "Last Price Paid": "$17.64",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037880",
        "Product Status": "Active"
    },
    {
        "Root": "10102817",
        "LV": "1",
        "Item Description": "CUP PLASTIC WATER PARKS GRAPE 22OZ 96/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102817001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.62",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102817",
        "Product Status": "Active"
    },
    {
        "Root": "10038383",
        "LV": "1",
        "Item Description": "SW DELOS BBQ 5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038383001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$146.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038383",
        "Product Status": "Active"
    },
    {
        "Root": "10006233",
        "LV": "1",
        "Item Description": "SB LID 12-22 OZ COLD DOME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006233001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11092051",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006233",
        "Product Status": "Active"
    },
    {
        "Root": "10014838",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD 3/4\" DICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014838001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "813940",
        "Last Price Paid": "$2.48",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014838",
        "Product Status": "Active"
    },
    {
        "Root": "10001352",
        "LV": "1",
        "Item Description": "SAUCE RED CHILI SAMBAL 4/1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$38.25",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001352",
        "Product Status": "Active"
    },
    {
        "Root": "10006339",
        "LV": "1",
        "Item Description": "PAPER BUTCHER 18 INCH ROLL WH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006339001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4374377",
        "Last Price Paid": "$25.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006339",
        "Product Status": "Active"
    },
    {
        "Root": "10031968",
        "LV": "1",
        "Item Description": "CHEESE MINI BABYBEL RED WAX ORIGINAL 20/3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031968001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6690791",
        "Last Price Paid": "$0.51",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031968",
        "Product Status": "Active"
    },
    {
        "Root": "10001544",
        "LV": "1",
        "Item Description": "EGGPLANT FANCY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001544001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "489954",
        "Last Price Paid": "$3.73",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001544",
        "Product Status": "Active"
    },
    {
        "Root": "10031820",
        "LV": "2",
        "Item Description": "TURKEY DRUMSTICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031820001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.19",
        "Case Pack": "38",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10031820",
        "Product Status": "Active"
    },
    {
        "Root": "10006609",
        "LV": "1",
        "Item Description": "TOWELETTE MOIST LEMON SCE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1404334",
        "Last Price Paid": "$0.02",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006609",
        "Product Status": "Active"
    },
    {
        "Root": "10028986",
        "LV": "1",
        "Item Description": "DOUGH BALL PIZZA 24 COUNT 19OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028986001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6364608",
        "Last Price Paid": "$1.72",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028986",
        "Product Status": "Active"
    },
    {
        "Root": "10003676",
        "LV": "1",
        "Item Description": "BASE GLACE DE VEAU VEAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003676001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "4600539",
        "Last Price Paid": "$13.92",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003676",
        "Product Status": "Active"
    },
    {
        "Root": "10009576",
        "LV": "1",
        "Item Description": "TEQUILA 1800 SILVR RSV LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009576001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12313",
        "Last Price Paid": "$25.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009576",
        "Product Status": "Active"
    },
    {
        "Root": "10024462",
        "LV": "1",
        "Item Description": "BEEF CHUCK SHORT RIB B/I",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024462001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1123340",
        "Last Price Paid": "$5.87",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024462",
        "Product Status": "Active"
    },
    {
        "Root": "10003717",
        "LV": "1",
        "Item Description": "SB SPLSH STCKS SUBX LOGO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003717001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "1123193",
        "Last Price Paid": "$0.01",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003717",
        "Product Status": "Active"
    },
    {
        "Root": "10016031",
        "LV": "1",
        "Item Description": "BOX FLATBREAD KRAFT BROWN 14\" X 7\" X 1.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51020001",
        "Last Price Paid": "$0.61",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016031",
        "Product Status": "Active"
    },
    {
        "Root": "10003631",
        "LV": "1",
        "Item Description": "SOUP VEGETABLE VEGETARIAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7861321",
        "Last Price Paid": "$9.89",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003631",
        "Product Status": "Active"
    },
    {
        "Root": "10029251",
        "LV": "1",
        "Item Description": "SUSHI NIGIRI EEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029251001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068834",
        "Vendor": "MITSUKOSHI USA INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029251",
        "Product Status": "Active"
    },
    {
        "Root": "10015254",
        "LV": "1",
        "Item Description": "BOW STRETCH LOOP SILVER 10\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015254001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "52425001",
        "Last Price Paid": "$0.32",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015254",
        "Product Status": "Active"
    },
    {
        "Root": "10010677",
        "LV": "2",
        "Item Description": "RUM DON Q GRAN ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010677001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "116278",
        "Last Price Paid": "$39.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010677",
        "Product Status": "Active"
    },
    {
        "Root": "10010677",
        "LV": "2",
        "Item Description": "RUM DON Q GRAN ANEJO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010677001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "116278",
        "Last Price Paid": "$39.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010677",
        "Product Status": "Active"
    },
    {
        "Root": "10001664",
        "LV": "1",
        "Item Description": "COCONUT 40 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001664",
        "Product Status": "Active"
    },
    {
        "Root": "10001664",
        "LV": "1",
        "Item Description": "COCONUT 40 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001664001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "303009",
        "Last Price Paid": "$0.79",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001664",
        "Product Status": "Active"
    },
    {
        "Root": "10030359",
        "LV": "1",
        "Item Description": "CHAMPAGNE LOUIS ROEDERER BLANC DE BLANC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "542821",
        "Last Price Paid": "$72.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030359",
        "Product Status": "Active"
    },
    {
        "Root": "10008535",
        "LV": "1",
        "Item Description": "BLND CAB NWTN PUZZLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008535001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "555357",
        "Last Price Paid": "$97.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008535",
        "Product Status": "Active"
    },
    {
        "Root": "10002148",
        "LV": "1",
        "Item Description": "CREAMER HALF AND HALF INDIVIDUAL SHLF STABLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.04",
        "Case Pack": "360",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002148",
        "Product Status": "Active"
    },
    {
        "Root": "10009556",
        "LV": "2",
        "Item Description": "TEQ CUERVO GLD 80 SPC 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009556002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12456",
        "Last Price Paid": "$9.43",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009556",
        "Product Status": "Active"
    },
    {
        "Root": "10029730",
        "LV": "1",
        "Item Description": "YEAST SEASONING BRAGG NUTRITIONAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "06605",
        "Last Price Paid": "$4.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029730",
        "Product Status": "Active"
    },
    {
        "Root": "10004713",
        "LV": "2",
        "Item Description": "POPCORN WHITE CHEDDAR .62",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004713002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5605332",
        "Last Price Paid": "$1.52",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004713",
        "Product Status": "Active"
    },
    {
        "Root": "10008161",
        "LV": "1",
        "Item Description": "CHARD GRGICH HILLS NAPA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008161001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9681133",
        "Last Price Paid": "$31.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008161",
        "Product Status": "Active"
    },
    {
        "Root": "10034950",
        "LV": "1",
        "Item Description": "BOURBON IRON SMOKE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034950001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "915413",
        "Last Price Paid": "$37.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034950",
        "Product Status": "Active"
    },
    {
        "Root": "10019764",
        "LV": "1",
        "Item Description": "MIX FREESTYLE CC SOLUTION INGREDIENT 23 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "4262129",
        "Last Price Paid": "$10.86",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019764",
        "Product Status": "Active"
    },
    {
        "Root": "10001119",
        "LV": "2",
        "Item Description": "CLAM CAPE MAY 400 CT 24 LB SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001119002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6912113",
        "Last Price Paid": "$0.24",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001119",
        "Product Status": "Active"
    },
    {
        "Root": "10001119",
        "LV": "2",
        "Item Description": "CLAM CAPE MAY 400 CT 24 LB SQS BAP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001119002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "CLA310010",
        "Last Price Paid": "$0.24",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001119",
        "Product Status": "Active"
    },
    {
        "Root": "10028457",
        "LV": "2",
        "Item Description": "CHARDONNAY CHATEAU CREE TOURELLE CREE 1ER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028457002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9696546",
        "Last Price Paid": "$21.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10028457",
        "Product Status": "Active"
    },
    {
        "Root": "10002987",
        "LV": "2",
        "Item Description": "PESTO TOMATO SUNDRIED GARLIC 3/30Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002987002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5445655",
        "Last Price Paid": "$12.56",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002987",
        "Product Status": "Active"
    },
    {
        "Root": "10086168",
        "LV": "1",
        "Item Description": "RED BLEND M BY MONDAVI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9658227",
        "Last Price Paid": "$144.00",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086168",
        "Product Status": "Active"
    },
    {
        "Root": "10007779",
        "LV": "1",
        "Item Description": "CHARD SANTA BARB 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007779001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "694496",
        "Last Price Paid": "$20.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007779",
        "Product Status": "Active"
    },
    {
        "Root": "10026659",
        "LV": "1",
        "Item Description": "CHIPS ASSORTED SS 1.75 OZ 2/30 EA LSS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2833239",
        "Last Price Paid": "$0.63",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026659",
        "Product Status": "Active"
    },
    {
        "Root": "10030381",
        "LV": "1",
        "Item Description": "BAG KRAFT NATURAL 4.75X2.5X9.5 TN-4PLA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030381001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008799",
        "Last Price Paid": "$0.24",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030381",
        "Product Status": "Active"
    },
    {
        "Root": "10001836",
        "LV": "1",
        "Item Description": "BEAN YELLOW WAX 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001836001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001836",
        "Product Status": "Active"
    },
    {
        "Root": "10001836",
        "LV": "1",
        "Item Description": "BEAN YELLOW WAX 1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001836001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001836",
        "Product Status": "Active"
    },
    {
        "Root": "10083958",
        "LV": "1",
        "Item Description": "DEC CHOC DARK ROUNDS EXTRA FIN 3.5 CM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083958001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6096010000",
        "Last Price Paid": "$0.33",
        "Case Pack": "325",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083958",
        "Product Status": "Active"
    },
    {
        "Root": "10035212",
        "LV": "1",
        "Item Description": "POWDER FAT SOLUBLE BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035212001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5861300000",
        "Last Price Paid": "$13.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035212",
        "Product Status": "Active"
    },
    {
        "Root": "10001872",
        "LV": "1",
        "Item Description": "PEPPER PABLANO LB=LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001872001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001872",
        "Product Status": "Active"
    },
    {
        "Root": "10001200",
        "LV": "1",
        "Item Description": "BEEF HOT DOG SKNLS 6 INCH 4-1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001200001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "182303",
        "Last Price Paid": "$1.31",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001200",
        "Product Status": "Active"
    },
    {
        "Root": "10001375",
        "LV": "2",
        "Item Description": "ROMANESCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001375002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "407885",
        "Last Price Paid": "$2.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001375",
        "Product Status": "Active"
    },
    {
        "Root": "10001375",
        "LV": "2",
        "Item Description": "ROMANESCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001375002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001375",
        "Product Status": "Active"
    },
    {
        "Root": "10001375",
        "LV": "2",
        "Item Description": "ROMANESCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001375002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20340",
        "Last Price Paid": "$2.79",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001375",
        "Product Status": "Active"
    },
    {
        "Root": "10002754",
        "LV": "1",
        "Item Description": "TOPPING CARAMEL 32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002754001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.12",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002754",
        "Product Status": "Active"
    },
    {
        "Root": "10029853",
        "LV": "1",
        "Item Description": "SANGIOVESE TENUTA ACRCENO CHIANTI CLASSICO RSV 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029853001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "527578",
        "Last Price Paid": "$25.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029853",
        "Product Status": "Active"
    },
    {
        "Root": "10009468",
        "LV": "1",
        "Item Description": "SCOTCH HIGHLAND PARK 18YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "21118",
        "Last Price Paid": "$120.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009468",
        "Product Status": "Active"
    },
    {
        "Root": "10009613",
        "LV": "3",
        "Item Description": "VODKA HANGAR ONE LIME, 1LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009613002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "558436",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009613",
        "Product Status": "Active"
    },
    {
        "Root": "10009613",
        "LV": "3",
        "Item Description": "VODKA HANGAR ONE LIME, 1LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009613002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "558436",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10009613",
        "Product Status": "Active"
    },
    {
        "Root": "10039134",
        "LV": "1",
        "Item Description": "SUGAR REINDEER ANTLERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039134001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "49309",
        "Last Price Paid": "$0.22",
        "Case Pack": "98",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039134",
        "Product Status": "Active"
    },
    {
        "Root": "10005394",
        "LV": "3",
        "Item Description": "BAR CHOC DARK SEA SALT KIND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005394002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "303680",
        "Last Price Paid": "$2.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10005394",
        "Product Status": "Active"
    },
    {
        "Root": "10045379",
        "LV": "1",
        "Item Description": "BOWL BARREL FIBER 12OZ BB-SC-U12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045379001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.00",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045379",
        "Product Status": "Active"
    },
    {
        "Root": "10015491",
        "LV": "1",
        "Item Description": "CHOC NEMO 3D ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015491001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW3600",
        "Last Price Paid": "$0.85",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015491",
        "Product Status": "Active"
    },
    {
        "Root": "10003032",
        "LV": "1",
        "Item Description": "JELLY GRAPE .5OZ 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003032001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.06",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003032",
        "Product Status": "Active"
    },
    {
        "Root": "10109530",
        "LV": "1",
        "Item Description": "CHICKEN BACKS BELL AND EVANS 40 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109530001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CHI500",
        "Last Price Paid": "$1.10",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109530",
        "Product Status": "Active"
    },
    {
        "Root": "10009539",
        "LV": "2",
        "Item Description": "TEQ LUNAZUL RESPOSADO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009539002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009539",
        "Product Status": "Active"
    },
    {
        "Root": "10008689",
        "LV": "1",
        "Item Description": "CHARD COPAIN LES VOISINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008689001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "953886",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008689",
        "Product Status": "Active"
    },
    {
        "Root": "10007905",
        "LV": "2",
        "Item Description": "CAB METTLER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007905001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "804224",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007905",
        "Product Status": "Active"
    },
    {
        "Root": "10000844",
        "LV": "2",
        "Item Description": "CHICK DRUM LEG FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000844001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000844",
        "Product Status": "Active"
    },
    {
        "Root": "10024598",
        "LV": "1",
        "Item Description": "TEA PEPPERMINT K CUP 24 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F08760",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024598",
        "Product Status": "Active"
    },
    {
        "Root": "10003860",
        "LV": "1",
        "Item Description": "PICKLES GHERKINS SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003860001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "501",
        "Last Price Paid": "",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003860",
        "Product Status": "Active"
    },
    {
        "Root": "10094789",
        "LV": "1",
        "Item Description": "SHEET GARNISH, CHOC DK GOLD SAVOR THE MOMENT 4X1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094789001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2401072",
        "Last Price Paid": "",
        "Case Pack": "280",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094789",
        "Product Status": "Active"
    },
    {
        "Root": "10026462",
        "LV": "1",
        "Item Description": "SAUCE STEAK A1 2 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5938107",
        "Last Price Paid": "",
        "Case Pack": "60",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026462",
        "Product Status": "Active"
    },
    {
        "Root": "10110612",
        "LV": "1",
        "Item Description": "WIPER WYPALL L30 POP-UP BOX 1200/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000493883",
        "Vendor": "VERITIV / UNISOURCE",
        "Vendor Item Nb": "10326941",
        "Last Price Paid": "",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110612",
        "Product Status": "Active"
    },
    {
        "Root": "10108429",
        "LV": "1",
        "Item Description": "SUGAR-HOLIDAY CANDY ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108429001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "28356",
        "Last Price Paid": "",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108429",
        "Product Status": "Active"
    },
    {
        "Root": "10012390",
        "LV": "1",
        "Item Description": "DEC ORCHID TROPICAL 3.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012390",
        "Product Status": "Active"
    },
    {
        "Root": "10036086",
        "LV": "1",
        "Item Description": "PALOMINO LUSTAU DRY OLOROSO DON NUNO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036086001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9404",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036086",
        "Product Status": "Active"
    },
    {
        "Root": "10000472",
        "LV": "1",
        "Item Description": "BREAKFAST BREAD BANANA (8 IN X4 IN) UNSLICED EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000472001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000472",
        "Product Status": "Active"
    },
    {
        "Root": "10037563",
        "LV": "1",
        "Item Description": "ORANGE DIAMONDS 1.5'' X 1.5''",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037563001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037563",
        "Product Status": "Active"
    },
    {
        "Root": "10002582",
        "LV": "1",
        "Item Description": "BLUEBERRY LOCAL BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002582001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002582",
        "Product Status": "Active"
    },
    {
        "Root": "10000594",
        "LV": "2",
        "Item Description": "PORK BELLY BONELESS SKIN OFF 11 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000594001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.49",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000594",
        "Product Status": "Active"
    },
    {
        "Root": "10000594",
        "LV": "2",
        "Item Description": "PORK BELLY BONELESS SKIN OFF 11 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000594001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.49",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000594",
        "Product Status": "Active"
    },
    {
        "Root": "10002892",
        "LV": "1",
        "Item Description": "DRESSING 1000 ISLAND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002892001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9213398",
        "Last Price Paid": "$15.09",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002892",
        "Product Status": "Active"
    },
    {
        "Root": "10003471",
        "LV": "1",
        "Item Description": "COTTON CANDY MIX RASPBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003471001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "3201",
        "Last Price Paid": "$4.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003471",
        "Product Status": "Active"
    },
    {
        "Root": "10034719",
        "LV": "3",
        "Item Description": "NUT PISTACHIO NATURAL 24/2 OZ BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034719003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "203007",
        "Last Price Paid": "$1.32",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10034719",
        "Product Status": "Active"
    },
    {
        "Root": "10107788",
        "LV": "1",
        "Item Description": "COFFEE JOFFREYS COLD BREW COFFEE REG 0.5GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$50.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107788",
        "Product Status": "Active"
    },
    {
        "Root": "10107788",
        "LV": "1",
        "Item Description": "COFFEE JOFFREYS COLD BREW COFFEE REG 0.5GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "D030L-84J",
        "Last Price Paid": "$50.43",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107788",
        "Product Status": "Active"
    },
    {
        "Root": "10029169",
        "LV": "1",
        "Item Description": "TEA LEMON AND GINGER 6/25 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029169001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "F09180",
        "Last Price Paid": "$0.11",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029169",
        "Product Status": "Active"
    },
    {
        "Root": "10008210",
        "LV": "1",
        "Item Description": "CABERNET SAUVIGNON SILVER OAK AV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "272821",
        "Last Price Paid": "$61.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008210",
        "Product Status": "Active"
    },
    {
        "Root": "10001117",
        "LV": "2",
        "Item Description": "CLAM LITTLENECK 100 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001117002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "32510110 A",
        "Last Price Paid": "$0.33",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001117",
        "Product Status": "Active"
    },
    {
        "Root": "10001117",
        "LV": "2",
        "Item Description": "CLAM LITTLENECK 100 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001117002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "32510110",
        "Last Price Paid": "$0.33",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001117",
        "Product Status": "Active"
    },
    {
        "Root": "10001117",
        "LV": "2",
        "Item Description": "CLAM LITTLENECK 100 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001117002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6912000",
        "Last Price Paid": "$0.33",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001117",
        "Product Status": "Active"
    },
    {
        "Root": "10006279",
        "LV": "1",
        "Item Description": "CHOP STICKS HELPER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006279001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "19054",
        "Last Price Paid": "$0.12",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006279",
        "Product Status": "Active"
    },
    {
        "Root": "10018549",
        "LV": "2",
        "Item Description": "CHAMP MOET IMPERIAL BRUT 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018549001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "411396",
        "Last Price Paid": "$9.63",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10018549",
        "Product Status": "Active"
    },
    {
        "Root": "10002852",
        "LV": "2",
        "Item Description": "MUSTARD IND",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002852002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3032182",
        "Last Price Paid": "$0.04",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002852",
        "Product Status": "Active"
    },
    {
        "Root": "10005442",
        "LV": "1",
        "Item Description": "CANDY JELLY BELLIE 10 LB/CS BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "574000",
        "Last Price Paid": "$73.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005442",
        "Product Status": "Active"
    },
    {
        "Root": "10033295",
        "LV": "1",
        "Item Description": "RUM ANGOSTURA 1919 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033295001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "417385",
        "Last Price Paid": "$31.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033295",
        "Product Status": "Active"
    },
    {
        "Root": "10005232",
        "LV": "1",
        "Item Description": "JUICE WHT CRANB COCKTAIL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005232001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5366000",
        "Last Price Paid": "$3.32",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005232",
        "Product Status": "Active"
    },
    {
        "Root": "10005205",
        "LV": "1",
        "Item Description": "SALT FLEUR DE SEL 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005205001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$94.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005205",
        "Product Status": "Active"
    },
    {
        "Root": "10002121",
        "LV": "1",
        "Item Description": "CHEESE CREAM PHILY BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002121001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "12997",
        "Last Price Paid": "$91.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002121",
        "Product Status": "Active"
    },
    {
        "Root": "10002121",
        "LV": "1",
        "Item Description": "CHEESE CREAM PHILY BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002121001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$91.73",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002121",
        "Product Status": "Active"
    },
    {
        "Root": "10000172",
        "LV": "1",
        "Item Description": "KIWI FRUIT 36-39 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000172001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "304000",
        "Last Price Paid": "$0.69",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000172",
        "Product Status": "Active"
    },
    {
        "Root": "10000172",
        "LV": "1",
        "Item Description": "KIWI FRUIT 36-39 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000172001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16215",
        "Last Price Paid": "$0.69",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000172",
        "Product Status": "Active"
    },
    {
        "Root": "10003867",
        "LV": "1",
        "Item Description": "LEAVES FEUILLE DE BRICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "BT110",
        "Last Price Paid": "$0.41",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003867",
        "Product Status": "Active"
    },
    {
        "Root": "10004617",
        "LV": "1",
        "Item Description": "SAUCE STEAK A-1 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004617001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4007621",
        "Last Price Paid": "$56.94",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004617",
        "Product Status": "Active"
    },
    {
        "Root": "10002846",
        "LV": "1",
        "Item Description": "BASE WHIP TOPPING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002846001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2002145",
        "Last Price Paid": "$67.16",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002846",
        "Product Status": "Active"
    },
    {
        "Root": "10008168",
        "LV": "1",
        "Item Description": "SAUV BLC MATANZAS CRK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "584216",
        "Last Price Paid": "$14.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008168",
        "Product Status": "Active"
    },
    {
        "Root": "10000354",
        "LV": "1",
        "Item Description": "CARROT BABY PEELED 1 INCH TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "401026",
        "Last Price Paid": "$0.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000354",
        "Product Status": "Active"
    },
    {
        "Root": "10000354",
        "LV": "1",
        "Item Description": "CARROT BABY PEELED 1 INCH TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "22310",
        "Last Price Paid": "$0.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000354",
        "Product Status": "Active"
    },
    {
        "Root": "10000354",
        "LV": "1",
        "Item Description": "CARROT BABY PEELED 1 INCH TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "01-005",
        "Last Price Paid": "$0.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000354",
        "Product Status": "Active"
    },
    {
        "Root": "10000354",
        "LV": "1",
        "Item Description": "CARROT BABY PEELED 1 INCH TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000354",
        "Product Status": "Active"
    },
    {
        "Root": "10000354",
        "LV": "1",
        "Item Description": "CARROT BABY PEELED 1 INCH TOP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000354001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.09",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000354",
        "Product Status": "Active"
    },
    {
        "Root": "10008016",
        "LV": "2",
        "Item Description": "BORDEAUX CH LAGRANGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008016002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "300991",
        "Last Price Paid": "$65.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10008016",
        "Product Status": "Active"
    },
    {
        "Root": "10102488",
        "LV": "1",
        "Item Description": "MIX SOFT SERVE SALTED CARAMEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102488001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$11.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102488",
        "Product Status": "Active"
    },
    {
        "Root": "10010414",
        "LV": "2",
        "Item Description": "BEER LABATTS LIGHT CANADIAN 12Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010414001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12610",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010414",
        "Product Status": "Active"
    },
    {
        "Root": "10012502",
        "LV": "1",
        "Item Description": "LEMON BALM FARMERS BOUNTY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "14FBLBA-2",
        "Last Price Paid": "$35.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012502",
        "Product Status": "Active"
    },
    {
        "Root": "10006645",
        "LV": "2",
        "Item Description": "ICE CRUSHED 16LB BG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006645002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000006360",
        "Vendor": "REDDY ICE CORPORATION",
        "Vendor Item Nb": "860006114961",
        "Last Price Paid": "$1.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006645",
        "Product Status": "Active"
    },
    {
        "Root": "10004781",
        "LV": "2",
        "Item Description": "VINEGAR CABERNET LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004781001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$21.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10004781",
        "Product Status": "Active"
    },
    {
        "Root": "10002049",
        "LV": "1",
        "Item Description": "DAIKON ROOT LB=LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002049001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.70",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002049",
        "Product Status": "Active"
    },
    {
        "Root": "10107929",
        "LV": "1",
        "Item Description": "SEASONING FURIKAKE 1.1#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107929001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "88-35042",
        "Last Price Paid": "$25.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107929",
        "Product Status": "Active"
    },
    {
        "Root": "10014271",
        "LV": "1",
        "Item Description": "VODKA NEW AMSTERDAM 1 LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014271001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9137928",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014271",
        "Product Status": "Active"
    },
    {
        "Root": "10016459",
        "LV": "1",
        "Item Description": "FONDANT SATIN ICE RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016459001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "10018",
        "Last Price Paid": "$9.73",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016459",
        "Product Status": "Active"
    },
    {
        "Root": "10001898",
        "LV": "1",
        "Item Description": "APPLE GRN SMITH 1/4 INCH D&P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511031",
        "Last Price Paid": "$26.13",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001898",
        "Product Status": "Active"
    },
    {
        "Root": "10001898",
        "LV": "1",
        "Item Description": "APPLE GRN SMITH 1/4 INCH D&P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001898001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "22-048",
        "Last Price Paid": "$26.13",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001898",
        "Product Status": "Active"
    },
    {
        "Root": "10001474",
        "LV": "3",
        "Item Description": "ONION RED JUMBO, #25 POUND BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001474002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$28.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10001474",
        "Product Status": "Active"
    },
    {
        "Root": "10074605",
        "LV": "1",
        "Item Description": "GRAHAM CRACKERS FROZEN RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10074605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "GCRACK",
        "Last Price Paid": "$0.28",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10074605",
        "Product Status": "Active"
    },
    {
        "Root": "10028464",
        "LV": "1",
        "Item Description": "CAVIAR SNAKE RIVER 1OZ SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028464001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "60632000",
        "Last Price Paid": "$50.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028464",
        "Product Status": "Active"
    },
    {
        "Root": "10035780",
        "LV": "1",
        "Item Description": "FLOUR BOB'S RED MILL 1 TO 1 BAKING 4 X 22OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035780001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101478",
        "Last Price Paid": "$4.34",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035780",
        "Product Status": "Active"
    },
    {
        "Root": "10010344",
        "LV": "2",
        "Item Description": "BEER HOEGAARDEN BEER 11.2Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010344001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "43929",
        "Last Price Paid": "$1.34",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010344",
        "Product Status": "Active"
    },
    {
        "Root": "10010070",
        "LV": "1",
        "Item Description": "BEER BUD ALUM 16OZ 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010070001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010070",
        "Product Status": "Active"
    },
    {
        "Root": "10003329",
        "LV": "1",
        "Item Description": "GEL LIQUID ROYAL BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "22509",
        "Last Price Paid": "$4.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003329",
        "Product Status": "Active"
    },
    {
        "Root": "10000631",
        "LV": "1",
        "Item Description": "BACON SLICED CANADIAN 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000631001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "3419972",
        "Last Price Paid": "$7.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000631",
        "Product Status": "Active"
    },
    {
        "Root": "10024535",
        "LV": "2",
        "Item Description": "OIL CANOLA MASTERCHEF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024535001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "110026477",
        "Last Price Paid": "$7.59",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10024535",
        "Product Status": "Active"
    },
    {
        "Root": "10108788",
        "LV": "1",
        "Item Description": "WINE FAIVELEY MAZIS CHAMB GC 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108788001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9681162",
        "Last Price Paid": "$426.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108788",
        "Product Status": "Active"
    },
    {
        "Root": "10021769",
        "LV": "1",
        "Item Description": "CONT HINGED LG CUPCAKE 1 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021769001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51123012",
        "Last Price Paid": "$0.31",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021769",
        "Product Status": "Active"
    },
    {
        "Root": "10002604",
        "LV": "1",
        "Item Description": "SWISS CHARD RAINBOW PETIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04RCP-33",
        "Last Price Paid": "$24.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002604",
        "Product Status": "Active"
    },
    {
        "Root": "10003751",
        "LV": "1",
        "Item Description": "MAC & CHEESE GF, ORGANIC, RICE 9Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "000045",
        "Last Price Paid": "$3.71",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003751",
        "Product Status": "Active"
    },
    {
        "Root": "10088286",
        "LV": "1",
        "Item Description": "PORK RIND PELLET MEDIUMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088286001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "11004",
        "Last Price Paid": "$5.24",
        "Case Pack": "30",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088286",
        "Product Status": "Active"
    },
    {
        "Root": "10007315",
        "LV": "1",
        "Item Description": "RIES JH SELBACH QBA BLUE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16792",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007315",
        "Product Status": "Active"
    },
    {
        "Root": "10007075",
        "LV": "2",
        "Item Description": "CAB SAUV PRIDE MOUNTAIN 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007075001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9631746",
        "Last Price Paid": "$65.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007075",
        "Product Status": "Active"
    },
    {
        "Root": "10017492",
        "LV": "1",
        "Item Description": "CHOC COIN PIRATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017492001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6542904",
        "Last Price Paid": "$0.14",
        "Case Pack": "1200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017492",
        "Product Status": "Active"
    },
    {
        "Root": "10031985",
        "LV": "1",
        "Item Description": "BOX COOKIE AMORETTES DIS119",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031985001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51855019",
        "Last Price Paid": "$0.50",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031985",
        "Product Status": "Active"
    },
    {
        "Root": "10006873",
        "LV": "1",
        "Item Description": "MERLOT COPA CHALICE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006873",
        "Product Status": "Active"
    },
    {
        "Root": "10006873",
        "LV": "1",
        "Item Description": "MERLOT COPA CHALICE 187ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "93299",
        "Last Price Paid": "$1.47",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006873",
        "Product Status": "Active"
    },
    {
        "Root": "10034949",
        "LV": "1",
        "Item Description": "CAMPOS DE ESTRELLAS CAVA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034949001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "376516",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034949",
        "Product Status": "Active"
    },
    {
        "Root": "10012557",
        "LV": "1",
        "Item Description": "DANDELION PETITE MIXED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012557001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09PMDA-33",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012557",
        "Product Status": "Active"
    },
    {
        "Root": "10034674",
        "LV": "1",
        "Item Description": "VINCOTTO LEMON 8.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034674001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "VIN105",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034674",
        "Product Status": "Active"
    },
    {
        "Root": "10041458",
        "LV": "1",
        "Item Description": "FRUIT FC HONEYDEW CUP 12/5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "15",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041458",
        "Product Status": "Active"
    },
    {
        "Root": "10100773",
        "LV": "1",
        "Item Description": "BEAN PETITE ROMANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100773001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "20CMAR-33",
        "Last Price Paid": "",
        "Case Pack": "",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100773",
        "Product Status": "Active"
    },
    {
        "Root": "10088363",
        "LV": "1",
        "Item Description": "BOX POPCORN RECLOSABLE 5-5/8X2-1/8X8.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51365002",
        "Last Price Paid": "",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088363",
        "Product Status": "Active"
    },
    {
        "Root": "10021837",
        "LV": "1",
        "Item Description": "VEAL RCK LONGBN CHP RDY 6 RB TOMAHAWK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021837001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1316215",
        "Last Price Paid": "",
        "Case Pack": "13",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021837",
        "Product Status": "Active"
    },
    {
        "Root": "10000618",
        "LV": "1",
        "Item Description": "GLACE DE VEAU PROVIMI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000618001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000086662",
        "Vendor": "MARCHO FARMS INC",
        "Vendor Item Nb": "991",
        "Last Price Paid": "",
        "Case Pack": "20",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000618",
        "Product Status": "Active"
    },
    {
        "Root": "10024527",
        "LV": "1",
        "Item Description": "DEC CAKE LACE-WHITE-500 GM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "18280.",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024527",
        "Product Status": "Active"
    },
    {
        "Root": "10000655",
        "LV": "1",
        "Item Description": "BEEF BRISKET WHOLE #120 CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000655001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0120806",
        "Last Price Paid": "$3.84",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000655",
        "Product Status": "Active"
    },
    {
        "Root": "10005612",
        "LV": "1",
        "Item Description": "PRETZEL PAR BAKED 7 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.68",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005612",
        "Product Status": "Active"
    },
    {
        "Root": "10002743",
        "LV": "1",
        "Item Description": "SYRUP FRUIT PUNCH HI-C 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002743001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$99.12",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002743",
        "Product Status": "Active"
    },
    {
        "Root": "10089286",
        "LV": "2",
        "Item Description": "CHICKEN SPLIT WOG CITRUS MARINATED 1.20-1.375 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089286001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.98",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10089286",
        "Product Status": "Active"
    },
    {
        "Root": "10002642",
        "LV": "1",
        "Item Description": "SPICE SALT PRETZEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002642001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$19.51",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002642",
        "Product Status": "Active"
    },
    {
        "Root": "10005306",
        "LV": "1",
        "Item Description": "PUREE GREEN APPLE 6/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005306001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "746...{BOIRON{",
        "Last Price Paid": "$8.97",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005306",
        "Product Status": "Active"
    },
    {
        "Root": "10092711",
        "LV": "1",
        "Item Description": "CANDY, DOVE DARK CHOCOLATE SEA SALT CARAMEL 8/7.61",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092711001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6182305",
        "Last Price Paid": "$4.70",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092711",
        "Product Status": "Active"
    },
    {
        "Root": "10001767",
        "LV": "1",
        "Item Description": "TOMATO LARGE YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001767",
        "Product Status": "Active"
    },
    {
        "Root": "10001767",
        "LV": "1",
        "Item Description": "TOMATO LARGE YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "410414",
        "Last Price Paid": "$26.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001767",
        "Product Status": "Active"
    },
    {
        "Root": "10001767",
        "LV": "1",
        "Item Description": "TOMATO LARGE YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001767001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "23098",
        "Last Price Paid": "$26.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001767",
        "Product Status": "Active"
    },
    {
        "Root": "10026835",
        "LV": "1",
        "Item Description": "COTTON CANDY PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10026835001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "E311",
        "Last Price Paid": "$2.02",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10026835",
        "Product Status": "Active"
    },
    {
        "Root": "10030919",
        "LV": "1",
        "Item Description": "SYRUP SORGHUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030919001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "SYR001",
        "Last Price Paid": "$18.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030919",
        "Product Status": "Active"
    },
    {
        "Root": "10013423",
        "LV": "1",
        "Item Description": "CANTALOUPE ATHENA LOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013423001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "240017",
        "Last Price Paid": "$24.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013423",
        "Product Status": "Active"
    },
    {
        "Root": "10045917",
        "LV": "1",
        "Item Description": "POPCORN SPRING FESTIVE DHS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10045917001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000553436",
        "Vendor": "POPCORN JUNKIE LLC",
        "Vendor Item Nb": "SPRING FESTIVE",
        "Last Price Paid": "$3.75",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10045917",
        "Product Status": "Active"
    },
    {
        "Root": "10004081",
        "LV": "2",
        "Item Description": "SHELL GRAHAM 3.3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004081002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SBD260",
        "Last Price Paid": "$0.70",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004081",
        "Product Status": "Active"
    },
    {
        "Root": "10018059",
        "LV": "1",
        "Item Description": "SYRAH BIG BASIN SANTA CRUZ MOUNTAINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018059001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9208257",
        "Last Price Paid": "$28.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018059",
        "Product Status": "Active"
    },
    {
        "Root": "10001498",
        "LV": "1",
        "Item Description": "BEET BABY MIXED TRIMMED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001498001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$119.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001498",
        "Product Status": "Active"
    },
    {
        "Root": "10004190",
        "LV": "1",
        "Item Description": "VANILLA PASTE MADAGASCAR PURE WITH SEEDS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "T901",
        "Last Price Paid": "$91.05",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004190",
        "Product Status": "Active"
    },
    {
        "Root": "10036003",
        "LV": "1",
        "Item Description": "GLUCOCAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036003001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000381203",
        "Vendor": "JOHN E KOERNER & CO INC",
        "Vendor Item Nb": "87406",
        "Last Price Paid": "$43.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036003",
        "Product Status": "Active"
    },
    {
        "Root": "10040377",
        "LV": "2",
        "Item Description": "SPICE CAROLINA RUB 4-5LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040377002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-00843-7",
        "Last Price Paid": "$13.24",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040377",
        "Product Status": "Active"
    },
    {
        "Root": "10040377",
        "LV": "2",
        "Item Description": "SPICE CAROLINA RUB 4-5LB/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040377002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$13.24",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10040377",
        "Product Status": "Active"
    },
    {
        "Root": "10003888",
        "LV": "1",
        "Item Description": "HYAKUMANGOKU",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003888001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "1013",
        "Last Price Paid": "$68.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003888",
        "Product Status": "Active"
    },
    {
        "Root": "10006643",
        "LV": "1",
        "Item Description": "CHARGE TANK RENTAL CO2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006643001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000524829",
        "Vendor": "AIRGAS USA",
        "Vendor Item Nb": "",
        "Last Price Paid": "$32.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006643",
        "Product Status": "Active"
    },
    {
        "Root": "10000780",
        "LV": "1",
        "Item Description": "BEEF INSIDE ROUND CAP-OFF CHOICE ANGUS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000780001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "0000070",
        "Last Price Paid": "$5.53",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000780",
        "Product Status": "Active"
    },
    {
        "Root": "10013168",
        "LV": "2",
        "Item Description": "SAUCE CARAMEL GHIRARDELLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013168002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$14.86",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013168",
        "Product Status": "Active"
    },
    {
        "Root": "10018310",
        "LV": "1",
        "Item Description": "TORTILLA FLOUR 6\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018310001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6056492",
        "Last Price Paid": "$0.08",
        "Case Pack": "288",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018310",
        "Product Status": "Active"
    },
    {
        "Root": "10088362",
        "LV": "1",
        "Item Description": "BACON SLICED FULLY COOKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088362001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$232.21",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088362",
        "Product Status": "Active"
    },
    {
        "Root": "10003598",
        "LV": "1",
        "Item Description": "CANDY MARS ALMOND 1.7Z 24",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003598001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "534331",
        "Last Price Paid": "$1.48",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003598",
        "Product Status": "Active"
    },
    {
        "Root": "10004816",
        "LV": "1",
        "Item Description": "NOODLE CHOW MEIN 6/#10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004816001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3509601",
        "Last Price Paid": "$6.02",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004816",
        "Product Status": "Active"
    },
    {
        "Root": "10002664",
        "LV": "2",
        "Item Description": "SAUCE DEMI-GLACE 4/40.8Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002664002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.98",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002664",
        "Product Status": "Active"
    },
    {
        "Root": "10024324",
        "LV": "1",
        "Item Description": "MERLOT J LOHR 375 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "641026",
        "Last Price Paid": "$6.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024324",
        "Product Status": "Active"
    },
    {
        "Root": "10013024",
        "LV": "1",
        "Item Description": "SPROUTS BRUSSEL CLEANED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013024001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480109",
        "Last Price Paid": "$18.49",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013024",
        "Product Status": "Active"
    },
    {
        "Root": "10090530",
        "LV": "1",
        "Item Description": "SPARKLING SCHLUMBERGER CUVEE KLIMT BRUT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090530001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069637",
        "Vendor": "IMPORT ANT WINES INCE",
        "Vendor Item Nb": "30812",
        "Last Price Paid": "$20.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090530",
        "Product Status": "Active"
    },
    {
        "Root": "10032612",
        "LV": "1",
        "Item Description": "BEER BUDWEISER 6/4/16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.30",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032612",
        "Product Status": "Active"
    },
    {
        "Root": "10018391",
        "LV": "1",
        "Item Description": "BONITA WHOLE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018391001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6356170",
        "Last Price Paid": "$2.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018391",
        "Product Status": "Active"
    },
    {
        "Root": "10018391",
        "LV": "1",
        "Item Description": "BONITA WHOLE SQS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018391001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50005",
        "Last Price Paid": "$2.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018391",
        "Product Status": "Active"
    },
    {
        "Root": "10023719",
        "LV": "1",
        "Item Description": "SAUV BLANC BRENDEL EVERBLOOM 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023719001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "510240",
        "Last Price Paid": "$18.10",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023719",
        "Product Status": "Active"
    },
    {
        "Root": "10000352",
        "LV": "1",
        "Item Description": "BERRY BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "12001",
        "Last Price Paid": "$4.18",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000352",
        "Product Status": "Active"
    },
    {
        "Root": "10000352",
        "LV": "1",
        "Item Description": "BERRY BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.18",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000352",
        "Product Status": "Active"
    },
    {
        "Root": "10000352",
        "LV": "1",
        "Item Description": "BERRY BLACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000352001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481535",
        "Last Price Paid": "$4.18",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000352",
        "Product Status": "Active"
    },
    {
        "Root": "10034196",
        "LV": "1",
        "Item Description": "PUREE YUZU 6/32Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034196001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "WA3503",
        "Last Price Paid": "$33.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034196",
        "Product Status": "Active"
    },
    {
        "Root": "10013084",
        "LV": "1",
        "Item Description": "GLASS 9 OZ CLR SQ TUMBLER EMI-ST9",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013084001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11313350",
        "Last Price Paid": "$0.22",
        "Case Pack": "168",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013084",
        "Product Status": "Active"
    },
    {
        "Root": "10005667",
        "LV": "4",
        "Item Description": "BREAD WHITE SANDWICH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005667002004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000415720",
        "Vendor": "FLOWERS BAKING",
        "Vendor Item Nb": "61110380",
        "Last Price Paid": "$1.99",
        "Case Pack": "7",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "002",
        "NALA": "10005667",
        "Product Status": "Active"
    },
    {
        "Root": "10011019",
        "LV": "1",
        "Item Description": "COMPOUND STRAWBERRY DREIDOPPEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011019001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "207",
        "Last Price Paid": "$43.13",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011019",
        "Product Status": "Active"
    },
    {
        "Root": "10037334",
        "LV": "1",
        "Item Description": "CUP SIPPER HAKUNAMATATA LIONKING 19Z 78/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037334001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.93",
        "Case Pack": "78",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037334",
        "Product Status": "Active"
    },
    {
        "Root": "10005270",
        "LV": "1",
        "Item Description": "SAUCE HEINZ 57 5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005270001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9003732",
        "Last Price Paid": "$2.38",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005270",
        "Product Status": "Active"
    },
    {
        "Root": "10007468",
        "LV": "1",
        "Item Description": "SPARK CHANDON BRUT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "994141",
        "Last Price Paid": "$15.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007468",
        "Product Status": "Active"
    },
    {
        "Root": "10000669",
        "LV": "1",
        "Item Description": "BEEF RIBEYE LIP ON YC 150 DAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000669001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "000000041",
        "Last Price Paid": "$10.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000669",
        "Product Status": "Active"
    },
    {
        "Root": "10039679",
        "LV": "1",
        "Item Description": "SODA FANTA GRAPE 20 OZ BOTTLE 24/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039679001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "115313",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039679",
        "Product Status": "Active"
    },
    {
        "Root": "10003748",
        "LV": "1",
        "Item Description": "PASTA SPAG OG BRN RICE 12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7633365",
        "Last Price Paid": "$2.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003748",
        "Product Status": "Active"
    },
    {
        "Root": "10018690",
        "LV": "1",
        "Item Description": "RAMP #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018690001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01RA-2",
        "Last Price Paid": "$19.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018690",
        "Product Status": "Active"
    },
    {
        "Root": "10033186",
        "LV": "1",
        "Item Description": "RUM KRAKEN BLACK SPICED 94 PROOF 1L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033186001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "12336",
        "Last Price Paid": "$19.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033186",
        "Product Status": "Active"
    },
    {
        "Root": "10012366",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN OFF, WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012366001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "7075414",
        "Last Price Paid": "$18.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012366",
        "Product Status": "Active"
    },
    {
        "Root": "10012366",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN OFF, WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012366001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000063514",
        "Vendor": "COD & CAPERS",
        "Vendor Item Nb": "1140",
        "Last Price Paid": "$18.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012366",
        "Product Status": "Active"
    },
    {
        "Root": "10012366",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN OFF, WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012366001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "HAL130100",
        "Last Price Paid": "$18.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012366",
        "Product Status": "Active"
    },
    {
        "Root": "10012366",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN OFF, WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012366001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00425A",
        "Last Price Paid": "$18.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012366",
        "Product Status": "Active"
    },
    {
        "Root": "10012366",
        "LV": "1",
        "Item Description": "HALIBUT FILLET SKIN OFF, WILD SUSTAINABLE SQS MSC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012366001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "FISHFF203",
        "Last Price Paid": "$18.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012366",
        "Product Status": "Active"
    },
    {
        "Root": "10037420",
        "LV": "2",
        "Item Description": "CHEESE SHREDS DAIRY FREE MOZZARELLA 6/2.2 LB BAGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037420002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "16022",
        "Last Price Paid": "$10.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10037420",
        "Product Status": "Active"
    },
    {
        "Root": "10022826",
        "LV": "1",
        "Item Description": "CHOC WHT RECT 2 X 1 LOGO REQUIRED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022826001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2162W",
        "Last Price Paid": "$1.00",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022826",
        "Product Status": "Active"
    },
    {
        "Root": "10009387",
        "LV": "2",
        "Item Description": "CHABLIS DOM SAVARY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009387001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "566457",
        "Last Price Paid": "$19.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009387",
        "Product Status": "Active"
    },
    {
        "Root": "10009387",
        "LV": "2",
        "Item Description": "CHABLIS DOM SAVARY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009387001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "566457",
        "Last Price Paid": "$19.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009387",
        "Product Status": "Active"
    },
    {
        "Root": "10040239",
        "LV": "1",
        "Item Description": "WHITE BLEND LAFAGE CENTENAIRE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040239001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "587729",
        "Last Price Paid": "$14.07",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040239",
        "Product Status": "Active"
    },
    {
        "Root": "10014673",
        "LV": "1",
        "Item Description": "COMPOUND COCONUT TOASTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014673001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "3021",
        "Last Price Paid": "$110.07",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014673",
        "Product Status": "Active"
    },
    {
        "Root": "10002232",
        "LV": "1",
        "Item Description": "CHEESE BLUE BAYLEY HAZEN JASPER HILL 8 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002232001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "10016",
        "Last Price Paid": "$17.08",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002232",
        "Product Status": "Active"
    },
    {
        "Root": "10005036",
        "LV": "1",
        "Item Description": "SUGAR SANDING RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005036001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8784",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005036",
        "Product Status": "Active"
    },
    {
        "Root": "10083659",
        "LV": "1",
        "Item Description": "CHOC WHT BUTTERFLIES ASST 3X2.2 CM BB 3120PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083659001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6234670000",
        "Last Price Paid": "$0.16",
        "Case Pack": "3120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083659",
        "Product Status": "Active"
    },
    {
        "Root": "10034460",
        "LV": "1",
        "Item Description": "TORTILLA CORN WHITE MINI TACO DIE CUT 4.5\" 6/80EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034460001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2434363",
        "Last Price Paid": "$0.05",
        "Case Pack": "480",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034460",
        "Product Status": "Active"
    },
    {
        "Root": "10022607",
        "LV": "1",
        "Item Description": "BITTERS ORANGE BITTERMANS 5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "895843",
        "Last Price Paid": "$13.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022607",
        "Product Status": "Active"
    },
    {
        "Root": "10028031",
        "LV": "1",
        "Item Description": "RUM BAYOU SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028031001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028031",
        "Product Status": "Active"
    },
    {
        "Root": "10106631",
        "LV": "1",
        "Item Description": "BREAD BUN HOT DOG FOOTLONG 48CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106631001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000404845",
        "Vendor": "CAMPAGNA-TURANO BAKERY INC",
        "Vendor Item Nb": "35755",
        "Last Price Paid": "$0.45",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106631",
        "Product Status": "Active"
    },
    {
        "Root": "10033873",
        "LV": "1",
        "Item Description": "SB MUFFIN BLUEBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033873001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11091159",
        "Last Price Paid": "$1.19",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033873",
        "Product Status": "Active"
    },
    {
        "Root": "10002141",
        "LV": "2",
        "Item Description": "BUTTER PRINT UNSALTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002141001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.31",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002141",
        "Product Status": "Active"
    },
    {
        "Root": "10002918",
        "LV": "1",
        "Item Description": "DRINK MONSTER LO CARB 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002918001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.04",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002918",
        "Product Status": "Active"
    },
    {
        "Root": "10010412",
        "LV": "1",
        "Item Description": "BEER UNIBREW LA FIN DU MONDE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "866195",
        "Last Price Paid": "$2.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010412",
        "Product Status": "Active"
    },
    {
        "Root": "10000355",
        "LV": "1",
        "Item Description": "LETTUCE FRISEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "403005",
        "Last Price Paid": "$17.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000355",
        "Product Status": "Active"
    },
    {
        "Root": "10000355",
        "LV": "1",
        "Item Description": "LETTUCE FRISEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETFRI",
        "Last Price Paid": "$17.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000355",
        "Product Status": "Active"
    },
    {
        "Root": "10000355",
        "LV": "1",
        "Item Description": "LETTUCE FRISEE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "21280",
        "Last Price Paid": "$17.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000355",
        "Product Status": "Active"
    },
    {
        "Root": "10030583",
        "LV": "1",
        "Item Description": "YOGURT PLAIN GREEK 6/32 OZ DANNON OIKOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030583001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2826725",
        "Last Price Paid": "$3.80",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030583",
        "Product Status": "Active"
    },
    {
        "Root": "10009070",
        "LV": "1",
        "Item Description": "CHAMP VEUVE CLIQ GRD DAME 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009070001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "570372",
        "Last Price Paid": "$116.25",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009070",
        "Product Status": "Active"
    },
    {
        "Root": "10003600",
        "LV": "1",
        "Item Description": "SHEET TRANS H BIRTHDAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003600001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.00",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003600",
        "Product Status": "Active"
    },
    {
        "Root": "10008286",
        "LV": "2",
        "Item Description": "BARBARESCO VIAS PRODUTTORI 750M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008286001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "581697",
        "Last Price Paid": "$34.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008286",
        "Product Status": "Active"
    },
    {
        "Root": "10019652",
        "LV": "1",
        "Item Description": "CHIP POTATO BAKED GF 64/1.125 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5585476",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019652",
        "Product Status": "Active"
    },
    {
        "Root": "10007099",
        "LV": "1",
        "Item Description": "BLEND ROSE ANDRE FIGUIERE MAGALI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007099001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "249800",
        "Last Price Paid": "$15.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007099",
        "Product Status": "Active"
    },
    {
        "Root": "10007099",
        "LV": "1",
        "Item Description": "BLEND ROSE ANDRE FIGUIERE MAGALI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007099001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "249800",
        "Last Price Paid": "$15.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007099",
        "Product Status": "Active"
    },
    {
        "Root": "10000489",
        "LV": "1",
        "Item Description": "DOUGH SHEET SUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.08",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000489",
        "Product Status": "Active"
    },
    {
        "Root": "10090604",
        "LV": "1",
        "Item Description": "PUFF PASTRY VOL-AU-VENT 1.97IN NEUTRAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090604001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "HA941",
        "Last Price Paid": "",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090604",
        "Product Status": "Active"
    },
    {
        "Root": "10093536",
        "LV": "1",
        "Item Description": "CONE 4\" WHT 4OZ CONEWICH 80 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "80",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093536",
        "Product Status": "Active"
    },
    {
        "Root": "10087825",
        "LV": "1",
        "Item Description": "LID PORTION CUP 4OZ SUGARCANE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087825001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11657066",
        "Last Price Paid": "",
        "Case Pack": "1800",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087825",
        "Product Status": "Active"
    },
    {
        "Root": "10042386",
        "LV": "1",
        "Item Description": "WHISKEY UNCLE NEAREST 1884 SMALL BATCH 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10042386001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$33.75",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10042386",
        "Product Status": "Active"
    },
    {
        "Root": "10104908",
        "LV": "1",
        "Item Description": "SYRUP PANCAKE 1.4OZ IND 100CT SUBQ3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.12",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104908",
        "Product Status": "Active"
    },
    {
        "Root": "10104908",
        "LV": "1",
        "Item Description": "SYRUP PANCAKE 1.4OZ IND 100CT SUBQ3",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104908001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "5150002283",
        "Last Price Paid": "$0.12",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104908",
        "Product Status": "Active"
    },
    {
        "Root": "10004564",
        "LV": "1",
        "Item Description": "BEAN GREAT NORTHERN #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004564001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4330130",
        "Last Price Paid": "$6.09",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004564",
        "Product Status": "Active"
    },
    {
        "Root": "10104009",
        "LV": "1",
        "Item Description": "LETTUCE ICEBERG SHRED 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104009001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.73",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104009",
        "Product Status": "Active"
    },
    {
        "Root": "10001840",
        "LV": "1",
        "Item Description": "CARAMBOLA/STAR FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001840001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001840",
        "Product Status": "Active"
    },
    {
        "Root": "10010759",
        "LV": "2",
        "Item Description": "WATER GEROLSTNER SPRK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010759001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.66",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010759",
        "Product Status": "Active"
    },
    {
        "Root": "10000748",
        "LV": "1",
        "Item Description": "DROP CHARGE GEIERS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000748001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": ".00{ MIN ORDER",
        "Last Price Paid": "$54.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000748",
        "Product Status": "Active"
    },
    {
        "Root": "10005130",
        "LV": "1",
        "Item Description": "CROQUANT MEDIUM CARMA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005130001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "5421",
        "Last Price Paid": "$26.92",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005130",
        "Product Status": "Active"
    },
    {
        "Root": "10034227",
        "LV": "1",
        "Item Description": "LID CLEAR 12-24OZ X-SLOT A626P",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034227001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034227",
        "Product Status": "Active"
    },
    {
        "Root": "10002336",
        "LV": "1",
        "Item Description": "CHEESE PECORINO ROMANO SHEEP ITALY QUARTER WHEEL 1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002336001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "1780",
        "Last Price Paid": "$10.26",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002336",
        "Product Status": "Active"
    },
    {
        "Root": "10010284",
        "LV": "2",
        "Item Description": "BEER GUINNESS DRAUG 14.9 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010284001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "10326",
        "Last Price Paid": "$1.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010284",
        "Product Status": "Active"
    },
    {
        "Root": "10010284",
        "LV": "2",
        "Item Description": "BEER GUINNESS DRAUG 14.9 CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010284001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10326",
        "Last Price Paid": "$1.83",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010284",
        "Product Status": "Active"
    },
    {
        "Root": "10003843",
        "LV": "1",
        "Item Description": "CHIPS RICE SEA SALT LUNDBERG 6Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003843001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "35304",
        "Last Price Paid": "$3.31",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003843",
        "Product Status": "Active"
    },
    {
        "Root": "10030383",
        "LV": "2",
        "Item Description": "BOX KRAFT CANDY APPLE BOTTOM W WINDOWS 4X4X4.625",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030383002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "450674",
        "Last Price Paid": "$0.52",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10030383",
        "Product Status": "Active"
    },
    {
        "Root": "10032844",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT LARGE 3.5Z WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032844001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032844",
        "Product Status": "Active"
    },
    {
        "Root": "10110136",
        "LV": "1",
        "Item Description": "CUP SLANTED WOOD 9.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "512103",
        "Last Price Paid": "$0.93",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110136",
        "Product Status": "Active"
    },
    {
        "Root": "10006652",
        "LV": "1",
        "Item Description": "FILM PVC CLING 5 INCH X6 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8113565",
        "Last Price Paid": "$9.38",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006652",
        "Product Status": "Active"
    },
    {
        "Root": "10004502",
        "LV": "1",
        "Item Description": "MUSTARD HOT GERMAN OSWALD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000316955",
        "Vendor": "GOURMET INTERNATIONAL INC",
        "Vendor Item Nb": "268053",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004502",
        "Product Status": "Active"
    },
    {
        "Root": "10004502",
        "LV": "1",
        "Item Description": "MUSTARD HOT GERMAN OSWALD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004502001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "503",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004502",
        "Product Status": "Active"
    },
    {
        "Root": "10002085",
        "LV": "1",
        "Item Description": "POTATO PEANUT FINGERLING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002085001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002085",
        "Product Status": "Active"
    },
    {
        "Root": "10004551",
        "LV": "1",
        "Item Description": "SYRUP CHOC HERSHEY 24Z SQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004551001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7000912",
        "Last Price Paid": "$2.73",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004551",
        "Product Status": "Active"
    },
    {
        "Root": "10036207",
        "LV": "1",
        "Item Description": "COCOA BUTTER DUSTY ROSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036207001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302105C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036207",
        "Product Status": "Active"
    },
    {
        "Root": "10000173",
        "LV": "2",
        "Item Description": "PINEAPPLE PREMIUM SELECT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000173002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "16102",
        "Last Price Paid": "$2.67",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10000173",
        "Product Status": "Active"
    },
    {
        "Root": "10010601",
        "LV": "2",
        "Item Description": "FIREFLY APPL PIE MNSHINE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010601001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9177786",
        "Last Price Paid": "$9.74",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010601",
        "Product Status": "Active"
    },
    {
        "Root": "10022230",
        "LV": "1",
        "Item Description": "BITTERS ANGOSTURA ORANGE 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "198448",
        "Last Price Paid": "$10.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022230",
        "Product Status": "Active"
    },
    {
        "Root": "10022230",
        "LV": "1",
        "Item Description": "BITTERS ANGOSTURA ORANGE 4 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022230001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "198448",
        "Last Price Paid": "$10.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022230",
        "Product Status": "Active"
    },
    {
        "Root": "10018475",
        "LV": "1",
        "Item Description": "CUCAMELON 1/2 PT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018475001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "04CM-12",
        "Last Price Paid": "$18.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018475",
        "Product Status": "Active"
    },
    {
        "Root": "10017550",
        "LV": "1",
        "Item Description": "CHESTNUT PEELED FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "380210",
        "Last Price Paid": "$179.03",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017550",
        "Product Status": "Active"
    },
    {
        "Root": "10000736",
        "LV": "1",
        "Item Description": "CAUL FAT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000736001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1430055",
        "Last Price Paid": "$3.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000736",
        "Product Status": "Active"
    },
    {
        "Root": "10003412",
        "LV": "1",
        "Item Description": "SYRUP FCB BANANA 2.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$53.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003412",
        "Product Status": "Active"
    },
    {
        "Root": "10010664",
        "LV": "2",
        "Item Description": "VODKA SMIRNOF WHIP CRM LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010664001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "370566",
        "Last Price Paid": "$13.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010664",
        "Product Status": "Active"
    },
    {
        "Root": "10010664",
        "LV": "2",
        "Item Description": "VODKA SMIRNOF WHIP CRM LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010664001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "370566",
        "Last Price Paid": "$13.09",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010664",
        "Product Status": "Active"
    },
    {
        "Root": "10093538",
        "LV": "1",
        "Item Description": "SAUCE TERIYAKI GLAZE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093538001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8003956",
        "Last Price Paid": "$8.71",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093538",
        "Product Status": "Active"
    },
    {
        "Root": "10008220",
        "LV": "1",
        "Item Description": "CAB SAUV HONIG MAGS 6/1.5LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008220001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9146010",
        "Last Price Paid": "$65.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008220",
        "Product Status": "Active"
    },
    {
        "Root": "10031877",
        "LV": "4",
        "Item Description": "CHIP CUP WHITE 2 X 2 1/4",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031877003004",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW17340",
        "Last Price Paid": "$0.95",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10031877",
        "Product Status": "Active"
    },
    {
        "Root": "10002040",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002040001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002040",
        "Product Status": "Active"
    },
    {
        "Root": "10002040",
        "LV": "1",
        "Item Description": "POTATO YUKON GOLD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002040001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002040",
        "Product Status": "Active"
    },
    {
        "Root": "10013045",
        "LV": "1",
        "Item Description": "FLOUR TEFF GLUTEN FREE BOB'S",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013045001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101391",
        "Last Price Paid": "$5.59",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013045",
        "Product Status": "Active"
    },
    {
        "Root": "10021026",
        "LV": "1",
        "Item Description": "GIN ST AUGUSTINE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9180671",
        "Last Price Paid": "$25.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021026",
        "Product Status": "Active"
    },
    {
        "Root": "10004999",
        "LV": "2",
        "Item Description": "POWDER O TENETIC ORIGINAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004999002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.05",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10004999",
        "Product Status": "Active"
    },
    {
        "Root": "10004393",
        "LV": "1",
        "Item Description": "SB TEAV JADE CITR MINT CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004393001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11057341",
        "Last Price Paid": "$0.20",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004393",
        "Product Status": "Active"
    },
    {
        "Root": "10031361",
        "LV": "1",
        "Item Description": "CLEMENTINES CUTIES BULK 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031361001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "400050",
        "Last Price Paid": "$0.29",
        "Case Pack": "125",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031361",
        "Product Status": "Active"
    },
    {
        "Root": "10107549",
        "LV": "1",
        "Item Description": "WINE WHITE DR LO REISLING  6/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069080",
        "Vendor": "STACOLE COMPANY INC",
        "Vendor Item Nb": "872920",
        "Last Price Paid": "$13.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107549",
        "Product Status": "Active"
    },
    {
        "Root": "10000482",
        "LV": "1",
        "Item Description": "PASTRY CROISSANT MINI 1OZ BAKED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000482001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.42",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000482",
        "Product Status": "Active"
    },
    {
        "Root": "10094775",
        "LV": "1",
        "Item Description": "DEC DISC 1.5IN PROGRESSIVE RAINBOW CIRCLE WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094775001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW20464",
        "Last Price Paid": "$0.65",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094775",
        "Product Status": "Active"
    },
    {
        "Root": "10009816",
        "LV": "2",
        "Item Description": "RUM MT GAY EXTRA OLD 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009816001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "294446",
        "Last Price Paid": "$42.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009816",
        "Product Status": "Active"
    },
    {
        "Root": "10012176",
        "LV": "1",
        "Item Description": "CHARDONNAY JACKSON ESTATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012176001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "598572",
        "Last Price Paid": "$12.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012176",
        "Product Status": "Active"
    },
    {
        "Root": "10007718",
        "LV": "2",
        "Item Description": "SPARK PINEAPPLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007718001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069784",
        "Vendor": "FLORIDA ORANGE GROVES INC",
        "Vendor Item Nb": "659663-26750",
        "Last Price Paid": "$14.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007718",
        "Product Status": "Active"
    },
    {
        "Root": "10007718",
        "LV": "2",
        "Item Description": "SPARK PINEAPPLE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007718001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "659663-26750",
        "Last Price Paid": "$14.17",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007718",
        "Product Status": "Active"
    },
    {
        "Root": "10002099",
        "LV": "1",
        "Item Description": "PARSNIPS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002099001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "ROOTPARSNLB",
        "Last Price Paid": "$1.45",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002099",
        "Product Status": "Active"
    },
    {
        "Root": "10005722",
        "LV": "1",
        "Item Description": "PASTRY DANISH RASBERRY LEAVES 1.25Z RTB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005722001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7061021",
        "Last Price Paid": "$0.52",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005722",
        "Product Status": "Active"
    },
    {
        "Root": "10003372",
        "LV": "2",
        "Item Description": "MARINADE MOROCCAN CHERMOU 5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003372002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$224.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003372",
        "Product Status": "Active"
    },
    {
        "Root": "10014741",
        "LV": "1",
        "Item Description": "CUBE LIGHT LOTUS DAK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014741001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.99",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014741",
        "Product Status": "Active"
    },
    {
        "Root": "10108337",
        "LV": "1",
        "Item Description": "GIN BAYAB ROSE 6/750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10108337001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "544801",
        "Last Price Paid": "$24.99",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10108337",
        "Product Status": "Active"
    },
    {
        "Root": "10000188",
        "LV": "1",
        "Item Description": "LETTUCE ICEBERG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482048",
        "Last Price Paid": "$1.39",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000188",
        "Product Status": "Active"
    },
    {
        "Root": "10109305",
        "LV": "1",
        "Item Description": "PASTA CALABRIAN PEPR TORT 1/10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109305001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "58-41067",
        "Last Price Paid": "$89.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109305",
        "Product Status": "Active"
    },
    {
        "Root": "10095410",
        "LV": "1",
        "Item Description": "WINE WHITE BLEND ROUSSET-MARTIN MEME MARIE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095410001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "562963",
        "Last Price Paid": "$50.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095410",
        "Product Status": "Active"
    },
    {
        "Root": "10035479",
        "LV": "1",
        "Item Description": "BOWL ROUND 24OZ ECO BAMBOO FIBER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035479001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11851119",
        "Last Price Paid": "$0.14",
        "Case Pack": "400",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035479",
        "Product Status": "Active"
    },
    {
        "Root": "10012669",
        "LV": "1",
        "Item Description": "PEAS SEA ISLAND RED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012669001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "FA125",
        "Last Price Paid": "$7.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012669",
        "Product Status": "Active"
    },
    {
        "Root": "10086543",
        "LV": "1",
        "Item Description": "BEEF TENDERLOIN TOURNEDOS 4Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086543001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2701232",
        "Last Price Paid": "$24.48",
        "Case Pack": "0.25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086543",
        "Product Status": "Active"
    },
    {
        "Root": "10000834",
        "LV": "4",
        "Item Description": "TURKEY WHLE FRZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000834003004",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.91",
        "Case Pack": "43",
        "Show Only Active Items": "Y",
        "Long LV": "004",
        "Long SV": "003",
        "NALA": "10000834",
        "Product Status": "Active"
    },
    {
        "Root": "10002324",
        "LV": "1",
        "Item Description": "CHEESE SOFT RIPENED VALENCAY GOAT FRANCE 6/7.8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002324001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "55786",
        "Last Price Paid": "$11.68",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002324",
        "Product Status": "Active"
    },
    {
        "Root": "10030190",
        "LV": "1",
        "Item Description": "CHIP TORTILLA COOL RANCH TRAINGLE SALTED SS 1.75Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030190001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5597810",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030190",
        "Product Status": "Active"
    },
    {
        "Root": "10036029",
        "LV": "1",
        "Item Description": "GIN MALFY ORIGINALE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036029001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "525747",
        "Last Price Paid": "$24.05",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036029",
        "Product Status": "Active"
    },
    {
        "Root": "10001390",
        "LV": "1",
        "Item Description": "WATERCRESS RED 1.5/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001390001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "450099",
        "Last Price Paid": "$13.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001390",
        "Product Status": "Active"
    },
    {
        "Root": "10023223",
        "LV": "1",
        "Item Description": "LETTUCE DAGGERED CRYSTAL PETITE 50 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023223001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09PDCL-33E",
        "Last Price Paid": "$39.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023223",
        "Product Status": "Active"
    },
    {
        "Root": "10093468",
        "LV": "1",
        "Item Description": "YOGURT VEGAN  HARMLESS HARVEST PLAIN PLANT BASED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10093468001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "205",
        "Last Price Paid": "$5.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10093468",
        "Product Status": "Active"
    },
    {
        "Root": "10023811",
        "LV": "2",
        "Item Description": "COFFEE LIQUID SHAKEN JAMAICAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023811002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$47.05",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10023811",
        "Product Status": "Active"
    },
    {
        "Root": "10030607",
        "LV": "1",
        "Item Description": "VIOGNER PENNER ASH 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030607001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "594521",
        "Last Price Paid": "$22.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030607",
        "Product Status": "Active"
    },
    {
        "Root": "10107409",
        "LV": "1",
        "Item Description": "CHICKEN BREAST FLATTENED 5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107409001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.17",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107409",
        "Product Status": "Active"
    },
    {
        "Root": "10004749",
        "LV": "1",
        "Item Description": "BEAN GREAT NORTHERN DRY B",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004749001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5841333",
        "Last Price Paid": "$32.72",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004749",
        "Product Status": "Active"
    },
    {
        "Root": "10003488",
        "LV": "2",
        "Item Description": "TEA EMPER JASMNE LSE 7.05",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003488001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W3055",
        "Last Price Paid": "$79.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003488",
        "Product Status": "Active"
    },
    {
        "Root": "10088658",
        "LV": "1",
        "Item Description": "BEER KEG BREW HUB SMORES 15.5 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088658001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000364514",
        "Vendor": "FLORIDA DISTRIBUTING COMPANY LL",
        "Vendor Item Nb": "54792",
        "Last Price Paid": "$193.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088658",
        "Product Status": "Active"
    },
    {
        "Root": "10091712",
        "LV": "1",
        "Item Description": "WHISKEY COCKTAIL PRIZEFIGHT SOC HR 250ML/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091712001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "SHC-PRIZEFIG-1",
        "Last Price Paid": "$3.25",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091712",
        "Product Status": "Active"
    },
    {
        "Root": "10009867",
        "LV": "1",
        "Item Description": "PORT TAYLOR TAWNY 10YR FLDGATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "273574",
        "Last Price Paid": "$22.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009867",
        "Product Status": "Active"
    },
    {
        "Root": "10040078",
        "LV": "1",
        "Item Description": "VENISON RACK FRENCHED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040078001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000499368",
        "Vendor": "DARTAGNAN COMPANY",
        "Vendor Item Nb": "VENNZE007",
        "Last Price Paid": "$19.99",
        "Case Pack": "2.75",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040078",
        "Product Status": "Active"
    },
    {
        "Root": "10010093",
        "LV": "1",
        "Item Description": "BEER MILLER LITE 12OZ LNNR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010093001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.17",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010093",
        "Product Status": "Active"
    },
    {
        "Root": "10095490",
        "LV": "1",
        "Item Description": "ROOT VEGETABLE SLICES 5#CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095490001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01RVS-5",
        "Last Price Paid": "$60.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095490",
        "Product Status": "Active"
    },
    {
        "Root": "10023595",
        "LV": "1",
        "Item Description": "MILK, ALMOND VAN, DAIRY FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023595001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2632107",
        "Last Price Paid": "$1.15",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023595",
        "Product Status": "Active"
    },
    {
        "Root": "10091211",
        "LV": "3",
        "Item Description": "STRAW PAPER WHITE WRAPPED 7.75IN/3200CS HM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091211002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10091211",
        "Product Status": "Active"
    },
    {
        "Root": "10017275",
        "LV": "1",
        "Item Description": "LUCA BOSIO BAROLO 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017275001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "217517",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017275",
        "Product Status": "Active"
    },
    {
        "Root": "10035394",
        "LV": "1",
        "Item Description": "POPCORN PREPOPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035394001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2030",
        "Last Price Paid": "",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035394",
        "Product Status": "Active"
    },
    {
        "Root": "10092212",
        "LV": "2",
        "Item Description": "TEA BAGS STARCRUISER ONLY REP DECAF LMN PEP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092212002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4964903",
        "Last Price Paid": "",
        "Case Pack": "1920",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10092212",
        "Product Status": "Active"
    },
    {
        "Root": "10003865",
        "LV": "2",
        "Item Description": "SAUCE SOY WHITE 330ML JAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003865002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10003865",
        "Product Status": "Active"
    },
    {
        "Root": "10013688",
        "LV": "1",
        "Item Description": "DEC ENGLISH IVY GREEN FILLER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013688001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "LBGR",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013688",
        "Product Status": "Active"
    },
    {
        "Root": "10022580",
        "LV": "1",
        "Item Description": "PEPSI DIET CAN 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1030238",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022580",
        "Product Status": "Active"
    },
    {
        "Root": "10034568",
        "LV": "1",
        "Item Description": "POP TART RASPBERRY TOY STORY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034568001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "PT-RB",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034568",
        "Product Status": "Active"
    },
    {
        "Root": "10102149",
        "LV": "1",
        "Item Description": "GIN BEEFEATER PINK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "914197",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102149",
        "Product Status": "Active"
    },
    {
        "Root": "10039967",
        "LV": "1",
        "Item Description": "CHEESE VEGAN MIYOKOS DRIED TOMATO 6/CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039967001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "177417",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039967",
        "Product Status": "Active"
    },
    {
        "Root": "10104859",
        "LV": "2",
        "Item Description": "LID CUP 32OZ PLASTIC SLOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104859002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10104859",
        "Product Status": "Active"
    },
    {
        "Root": "10105109",
        "LV": "1",
        "Item Description": "CUP CAN 12OZ CLEAR FOTA BLAIR CN0103000",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105109001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11013740",
        "Last Price Paid": "",
        "Case Pack": "252",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105109",
        "Product Status": "Active"
    },
    {
        "Root": "10107633",
        "LV": "1",
        "Item Description": "CRACKER, PEANUT BUTTER SANDWICH ROUND SLEEVE 96/1.",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "5326444",
        "Last Price Paid": "",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107633",
        "Product Status": "Active"
    },
    {
        "Root": "10003725",
        "LV": "1",
        "Item Description": "DECOR CHOC WHITE DISC 5 3/4IN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003725001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW2095",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003725",
        "Product Status": "Active"
    },
    {
        "Root": "10005135",
        "LV": "1",
        "Item Description": "COMPOUND ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005135",
        "Product Status": "Active"
    },
    {
        "Root": "10038416",
        "LV": "1",
        "Item Description": "DECO CHOC MILK CARAMEL TWIG 270/ CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038416001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "5180050000",
        "Last Price Paid": "$0.18",
        "Case Pack": "270",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038416",
        "Product Status": "Active"
    },
    {
        "Root": "10014050",
        "LV": "1",
        "Item Description": "CHOC CHIP SEMI SWT 2000 CT GHIRADELLI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014050001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9738937",
        "Last Price Paid": "$136.32",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014050",
        "Product Status": "Active"
    },
    {
        "Root": "10003776",
        "LV": "1",
        "Item Description": "ALMOND MARCONA SALTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003776001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "132070",
        "Last Price Paid": "$20.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003776",
        "Product Status": "Active"
    },
    {
        "Root": "10003776",
        "LV": "1",
        "Item Description": "ALMOND MARCONA SALTED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003776001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "132070",
        "Last Price Paid": "$20.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003776",
        "Product Status": "Active"
    },
    {
        "Root": "10044194",
        "LV": "1",
        "Item Description": "SEED CHIA BLACK ORGANIC SHELF STABLE 10 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10044194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3105569",
        "Last Price Paid": "$48.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10044194",
        "Product Status": "Active"
    },
    {
        "Root": "10027296",
        "LV": "1",
        "Item Description": "HOAGIE CONTAINER CLEAR HINGED SLP18D",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027296001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51357079",
        "Last Price Paid": "$0.33",
        "Case Pack": "300",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027296",
        "Product Status": "Active"
    },
    {
        "Root": "10016412",
        "LV": "1",
        "Item Description": "BREAD CORN BREAD SHEET PLAIN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016412001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "900:X",
        "Last Price Paid": "$23.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016412",
        "Product Status": "Active"
    },
    {
        "Root": "10095419",
        "LV": "1",
        "Item Description": "KEG BOULEVARD BERRY NOIR 15.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095419001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "15112",
        "Last Price Paid": "$204.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095419",
        "Product Status": "Active"
    },
    {
        "Root": "10105464",
        "LV": "1",
        "Item Description": "WHISKEY GREY COAST IRISH 700ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105464001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "272420",
        "Last Price Paid": "$26.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105464",
        "Product Status": "Active"
    },
    {
        "Root": "10002851",
        "LV": "1",
        "Item Description": "RELISH SWEET IND 9GM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002851001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.03",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002851",
        "Product Status": "Active"
    },
    {
        "Root": "10005249",
        "LV": "1",
        "Item Description": "MIX FREESTYLE PEACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "3964750",
        "Last Price Paid": "$16.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005249",
        "Product Status": "Active"
    },
    {
        "Root": "10000397",
        "LV": "1",
        "Item Description": "TOMATO GOURMET X-LRG 15#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000397001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481595",
        "Last Price Paid": "$20.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000397",
        "Product Status": "Active"
    },
    {
        "Root": "10035991",
        "LV": "1",
        "Item Description": "STAMP MILK CHOCOLATE MICKEY MOUSE 3CM 440 PC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035991001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6232660000",
        "Last Price Paid": "$0.15",
        "Case Pack": "440",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035991",
        "Product Status": "Active"
    },
    {
        "Root": "10002705",
        "LV": "1",
        "Item Description": "MIX WAFFLE CONE MALTED 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002705001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.88",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002705",
        "Product Status": "Active"
    },
    {
        "Root": "10004052",
        "LV": "1",
        "Item Description": "DEC PEARLS CRSPY DRK CHOC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004052001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118585",
        "Last Price Paid": "$8.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004052",
        "Product Status": "Active"
    },
    {
        "Root": "10012396",
        "LV": "1",
        "Item Description": "DEC DAISY ORANGE GERBER 3\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "10329",
        "Last Price Paid": "$1.91",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012396",
        "Product Status": "Active"
    },
    {
        "Root": "10005617",
        "LV": "2",
        "Item Description": "PRETZEL JALAPENO STUFFED 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005617002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.99",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005617",
        "Product Status": "Active"
    },
    {
        "Root": "10034786",
        "LV": "1",
        "Item Description": "BEPI TOSOLINI AMARETTO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034786001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11150",
        "Last Price Paid": "$22.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034786",
        "Product Status": "Active"
    },
    {
        "Root": "10039962",
        "LV": "1",
        "Item Description": "GELATO ALMOND HONEY TOASTED 5Z CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039962001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-TORR-02-CS24",
        "Last Price Paid": "$1.38",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039962",
        "Product Status": "Active"
    },
    {
        "Root": "10003900",
        "LV": "1",
        "Item Description": "CHOC GARNISH SHEET CNGRTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003900001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW 240103",
        "Last Price Paid": "$14.50",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003900",
        "Product Status": "Active"
    },
    {
        "Root": "10007765",
        "LV": "1",
        "Item Description": "CAB SAUV NATURA 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007765001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "345691",
        "Last Price Paid": "$3.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007765",
        "Product Status": "Active"
    },
    {
        "Root": "10003182",
        "LV": "1",
        "Item Description": "SUGAR SANDING GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003182001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "8782",
        "Last Price Paid": "$16.67",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003182",
        "Product Status": "Active"
    },
    {
        "Root": "10040762",
        "LV": "1",
        "Item Description": "CUP KIDS TSR ARIEL 16OZ 500/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040762001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.36",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040762",
        "Product Status": "Active"
    },
    {
        "Root": "10033811",
        "LV": "1",
        "Item Description": "CHEESE AMERICAN GRANA PARMESAN 18MO BELGIOIOSO 16#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033811001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6330504",
        "Last Price Paid": "$4.96",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033811",
        "Product Status": "Active"
    },
    {
        "Root": "10000832",
        "LV": "1",
        "Item Description": "CHICK SAUS SAGE & PEPR LINK 1 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000832001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.23",
        "Case Pack": "320",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000832",
        "Product Status": "Active"
    },
    {
        "Root": "10011720",
        "LV": "1",
        "Item Description": "DRAFT UNIBROUE LA FIN 15.5G",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011720001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "866198",
        "Last Price Paid": "$175.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10011720",
        "Product Status": "Active"
    },
    {
        "Root": "10041605",
        "LV": "1",
        "Item Description": "SMOOTHIE PINEAPPLE MANGO NON DAIRY REFRIG 12/11.5Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1996401",
        "Last Price Paid": "$2.26",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041605",
        "Product Status": "Active"
    },
    {
        "Root": "10041256",
        "LV": "1",
        "Item Description": "POTATO BLANCHED DICED 5/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "530030",
        "Last Price Paid": "$11.13",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041256",
        "Product Status": "Active"
    },
    {
        "Root": "10031550",
        "LV": "1",
        "Item Description": "SYRUP PASSION MANGO FLAVOR BURST 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031550001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "61-45634",
        "Last Price Paid": "$17.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031550",
        "Product Status": "Active"
    },
    {
        "Root": "10103937",
        "LV": "1",
        "Item Description": "IMPOSSIBLE GROUND PORK 2.2LB/6CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10103937001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "60-00028",
        "Last Price Paid": "$15.28",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10103937",
        "Product Status": "Active"
    },
    {
        "Root": "10005715",
        "LV": "1",
        "Item Description": "MUFFIN ENGLISH SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005715001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3017738",
        "Last Price Paid": "$0.37",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005715",
        "Product Status": "Active"
    },
    {
        "Root": "10003396",
        "LV": "1",
        "Item Description": "CREAM MARSHMALLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003396001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.06",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003396",
        "Product Status": "Active"
    },
    {
        "Root": "10041062",
        "LV": "1",
        "Item Description": "CHEESE BLUE ROQUEFORT SHEEP FRANCE 3 LB PIECE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10041062001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "99923",
        "Last Price Paid": "$15.50",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10041062",
        "Product Status": "Active"
    },
    {
        "Root": "10084614",
        "LV": "1",
        "Item Description": "SLEEVE BOX 6CT 1.32 X 2.56 X 3.33 50TH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084614001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "450057",
        "Last Price Paid": "$0.19",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10084614",
        "Product Status": "Active"
    },
    {
        "Root": "10007149",
        "LV": "1",
        "Item Description": "SAKE KIMINOI EMPEROR WELL 300ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007149001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "191990",
        "Last Price Paid": "$17.23",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007149",
        "Product Status": "Active"
    },
    {
        "Root": "10004624",
        "LV": "1",
        "Item Description": "VINEGAR MALT GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004624001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7166580",
        "Last Price Paid": "$8.75",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004624",
        "Product Status": "Active"
    },
    {
        "Root": "10010189",
        "LV": "1",
        "Item Description": "BEER ODOULS NON ALCOHLIC BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010189001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010189",
        "Product Status": "Active"
    },
    {
        "Root": "10039711",
        "LV": "1",
        "Item Description": "RADISH RAT TAIL BLOOMS 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039711001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "01RTRB-33-E",
        "Last Price Paid": "$29.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039711",
        "Product Status": "Active"
    },
    {
        "Root": "10033430",
        "LV": "1",
        "Item Description": "RUM PLANTATION XO 20TH ANNIVERSARY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033430001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "282534",
        "Last Price Paid": "$41.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033430",
        "Product Status": "Active"
    },
    {
        "Root": "10009890",
        "LV": "2",
        "Item Description": "WINE APERITIF BLANC LILLET 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009890001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "69427",
        "Last Price Paid": "$17.15",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009890",
        "Product Status": "Active"
    },
    {
        "Root": "10106608",
        "LV": "3",
        "Item Description": "LANGOUSTINE PIECES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106608002003",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6600006",
        "Last Price Paid": "$15.48",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10106608",
        "Product Status": "Active"
    },
    {
        "Root": "10002240",
        "LV": "2",
        "Item Description": "EGGS BROWN CAGE FREE 15 DOZEN TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002240001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "594040",
        "Last Price Paid": "$0.20",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002240",
        "Product Status": "Active"
    },
    {
        "Root": "10002240",
        "LV": "2",
        "Item Description": "EGGS BROWN CAGE FREE 15 DOZEN TRAY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002240001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "31351",
        "Last Price Paid": "$0.20",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10002240",
        "Product Status": "Active"
    },
    {
        "Root": "10017869",
        "LV": "1",
        "Item Description": "LETTUCE LOLA ROSA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017869001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "LETLOLO",
        "Last Price Paid": "$0.57",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017869",
        "Product Status": "Active"
    },
    {
        "Root": "10002805",
        "LV": "1",
        "Item Description": "SAUCE TEXAS PETAL 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002805001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7173701",
        "Last Price Paid": "$17.05",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002805",
        "Product Status": "Active"
    },
    {
        "Root": "10030267",
        "LV": "2",
        "Item Description": "SUGAR CANDY CANE ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030267001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "13443",
        "Last Price Paid": "$0.15",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10030267",
        "Product Status": "Active"
    },
    {
        "Root": "10016239",
        "LV": "1",
        "Item Description": "PUMPKIN MED 20-25 #",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016239001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "482109",
        "Last Price Paid": "$14.65",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016239",
        "Product Status": "Active"
    },
    {
        "Root": "10033956",
        "LV": "1",
        "Item Description": "PINOT GRIGIO LAGEDER PORER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033956001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "642804",
        "Last Price Paid": "$20.83",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033956",
        "Product Status": "Active"
    },
    {
        "Root": "10010143",
        "LV": "2",
        "Item Description": "WATER TONIC SEAGRAMS 10Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010143001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.77",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010143",
        "Product Status": "Active"
    },
    {
        "Root": "10002931",
        "LV": "1",
        "Item Description": "GEL SMART CHERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3188372",
        "Last Price Paid": "$0.40",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002931",
        "Product Status": "Active"
    },
    {
        "Root": "10033044",
        "LV": "1",
        "Item Description": "CHILI & LIME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033044001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.26",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033044",
        "Product Status": "Active"
    },
    {
        "Root": "10104605",
        "LV": "1",
        "Item Description": "PASSOVER KIT - TSU WITH SALMON CASSEROLE 3CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104605001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$55.92",
        "Case Pack": "3",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104605",
        "Product Status": "Active"
    },
    {
        "Root": "10029726",
        "LV": "1",
        "Item Description": "TEX VEG PROTEIN TVP BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029726001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "1545C104",
        "Last Price Paid": "$3.27",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029726",
        "Product Status": "Active"
    },
    {
        "Root": "10004584",
        "LV": "1",
        "Item Description": "COLORING FOOD YELLOW 16Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004584001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "761445",
        "Last Price Paid": "$8.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004584",
        "Product Status": "Active"
    },
    {
        "Root": "10018799",
        "LV": "1",
        "Item Description": "POWDER DECOR PINK LUSTER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018799001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "314024E",
        "Last Price Paid": "$36.64",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018799",
        "Product Status": "Active"
    },
    {
        "Root": "10003957",
        "LV": "1",
        "Item Description": "PICKLE CHIP BRD & BTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003957001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2127466",
        "Last Price Paid": "$11.60",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003957",
        "Product Status": "Active"
    },
    {
        "Root": "10012465",
        "LV": "1",
        "Item Description": "DEC CHERRY BLOSSOM SPRAY 5.5\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012465001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12358-A",
        "Last Price Paid": "$3.31",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012465",
        "Product Status": "Active"
    },
    {
        "Root": "10003615",
        "LV": "1",
        "Item Description": "SB 5LB DECAF ESPRSS ROAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003615001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "7339",
        "Last Price Paid": "$53.65",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003615",
        "Product Status": "Active"
    },
    {
        "Root": "10033964",
        "LV": "1",
        "Item Description": "FLOUR ALMOND 25LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033964001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "2041680000",
        "Last Price Paid": "$136.52",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033964",
        "Product Status": "Active"
    },
    {
        "Root": "10040267",
        "LV": "1",
        "Item Description": "RHONE RED BEAUCASTEL CDP 2009 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040267001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9127672",
        "Last Price Paid": "$100.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040267",
        "Product Status": "Active"
    },
    {
        "Root": "10005913",
        "LV": "2",
        "Item Description": "BREAD ROLL PRETZEL HOAGIE 5 OZ 6.5 INCH EACH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005913002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "36097D",
        "Last Price Paid": "$0.61",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10005913",
        "Product Status": "Active"
    },
    {
        "Root": "10004526",
        "LV": "1",
        "Item Description": "CHOC GLASS SLIPPER WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004526",
        "Product Status": "Active"
    },
    {
        "Root": "10017750",
        "LV": "1",
        "Item Description": "MUSHROOM CLAMSHELL BRN CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017750001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "26055",
        "Last Price Paid": "$39.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017750",
        "Product Status": "Active"
    },
    {
        "Root": "10017750",
        "LV": "1",
        "Item Description": "MUSHROOM CLAMSHELL BRN CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017750001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480746",
        "Last Price Paid": "$39.88",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017750",
        "Product Status": "Active"
    },
    {
        "Root": "10076695",
        "LV": "2",
        "Item Description": "MIX SHAKE CHOCOLATE 5% 9HG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10076695002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.98",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10076695",
        "Product Status": "Active"
    },
    {
        "Root": "10034623",
        "LV": "1",
        "Item Description": "BLEND SHAFER TD-9 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034623001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9634193",
        "Last Price Paid": "$49.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034623",
        "Product Status": "Active"
    },
    {
        "Root": "10102920",
        "LV": "1",
        "Item Description": "KOSHER BSC VEGETABLE SOUP 12CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102920001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.33",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102920",
        "Product Status": "Active"
    },
    {
        "Root": "10004751",
        "LV": "1",
        "Item Description": "PASTA DRY LASAGNA BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "2328656",
        "Last Price Paid": "$18.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004751",
        "Product Status": "Active"
    },
    {
        "Root": "10033502",
        "LV": "2",
        "Item Description": "CHEESE VEGAN SLICES DAIRY FREE PEPPER JACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033502002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "27027",
        "Last Price Paid": "$3.75",
        "Case Pack": "11",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033502",
        "Product Status": "Active"
    },
    {
        "Root": "10003259",
        "LV": "1",
        "Item Description": "SYRUP HAZELNUT LTR PET MO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003259001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003259",
        "Product Status": "Active"
    },
    {
        "Root": "10004804",
        "LV": "1",
        "Item Description": "PASTA DRY CAVATAPPI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004804",
        "Product Status": "Active"
    },
    {
        "Root": "10005820",
        "LV": "1",
        "Item Description": "MUFFIN HURRICANE MINI 2.5 OUNCE WRAPPED 12 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005820001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000068471",
        "Vendor": "BAGEL KING",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.69",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005820",
        "Product Status": "Active"
    },
    {
        "Root": "10009984",
        "LV": "1",
        "Item Description": "SCHNAPPS PEACH LTR BOLS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009984001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$9.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009984",
        "Product Status": "Active"
    },
    {
        "Root": "10000753",
        "LV": "1",
        "Item Description": "LAMB RACK FRENCH AUST 22-26 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000753001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1506000",
        "Last Price Paid": "$15.57",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000753",
        "Product Status": "Active"
    },
    {
        "Root": "10015331",
        "LV": "1",
        "Item Description": "PINOT NOIR MASO POLI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015331001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "548823",
        "Last Price Paid": "$25.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015331",
        "Product Status": "Active"
    },
    {
        "Root": "10029014",
        "LV": "1",
        "Item Description": "WATERMELON SEEDLESS 4-5 / 60# CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029014001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "249091",
        "Last Price Paid": "$7.54",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029014",
        "Product Status": "Active"
    },
    {
        "Root": "10003462",
        "LV": "1",
        "Item Description": "SAUCE HOISIN 5 LB CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003462001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1348176",
        "Last Price Paid": "$7.61",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003462",
        "Product Status": "Active"
    },
    {
        "Root": "10001843",
        "LV": "1",
        "Item Description": "PLANTAINS GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001843001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.75",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001843",
        "Product Status": "Active"
    },
    {
        "Root": "10002425",
        "LV": "2",
        "Item Description": "ICE CREAM CHOCOLATE EDY 3 GAL TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002425002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002425",
        "Product Status": "Active"
    },
    {
        "Root": "10002425",
        "LV": "2",
        "Item Description": "ICE CREAM CHOCOLATE EDY 3 GAL TUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002425002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8241226",
        "Last Price Paid": "$18.24",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002425",
        "Product Status": "Active"
    },
    {
        "Root": "10010480",
        "LV": "3",
        "Item Description": "J MOREAU VOUVRAY 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010480002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9176498",
        "Last Price Paid": "$11.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10010480",
        "Product Status": "Active"
    },
    {
        "Root": "10110928",
        "LV": "1",
        "Item Description": "SUGAR PEARLED #10 CALIBRE 22LB BAG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110928001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "PA6220",
        "Last Price Paid": "$64.46",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10110928",
        "Product Status": "Active"
    },
    {
        "Root": "10008284",
        "LV": "1",
        "Item Description": "CHARDONNAY JARVIS FINCH HOL 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008284001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "598405",
        "Last Price Paid": "$123.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008284",
        "Product Status": "Active"
    },
    {
        "Root": "10007960",
        "LV": "1",
        "Item Description": "MOSCATO CHIARLO NIVOL 375ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007960001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "150609",
        "Last Price Paid": "$8.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007960",
        "Product Status": "Active"
    },
    {
        "Root": "10001277",
        "LV": "1",
        "Item Description": "JUICE MM CRANBERRY 6/64 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001277001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "002500006331",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001277",
        "Product Status": "Active"
    },
    {
        "Root": "10020156",
        "LV": "1",
        "Item Description": "SPICE CHRISTOLLEN XMAS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020156001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "DD001",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020156",
        "Product Status": "Active"
    },
    {
        "Root": "10094636",
        "LV": "1",
        "Item Description": "SAUSG HOT ITAL RTL PK BEYOND 8/4CT PKG  3.5OZ EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10094636001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7103324",
        "Last Price Paid": "",
        "Case Pack": "32",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10094636",
        "Product Status": "Active"
    },
    {
        "Root": "10006703",
        "LV": "2",
        "Item Description": "STICK POPSICLE 10000/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006703002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "4155",
        "Last Price Paid": "",
        "Case Pack": "10000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006703",
        "Product Status": "Active"
    },
    {
        "Root": "10106151",
        "LV": "2",
        "Item Description": "CUP COLD 12OZ CARTE BLANC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106151002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "112352502",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10106151",
        "Product Status": "Active"
    },
    {
        "Root": "10009142",
        "LV": "1",
        "Item Description": "CHARD JADOT PULIGNY MONT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "195469",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009142",
        "Product Status": "Active"
    },
    {
        "Root": "10028764",
        "LV": "1",
        "Item Description": "WINE FRANK FAMILY CABERNET SAUVIGNON MAG 1.5L",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028764001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9005507",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028764",
        "Product Status": "Active"
    },
    {
        "Root": "10092553",
        "LV": "1",
        "Item Description": "SODA PET COKE ZERO CREATIONS LTO 20OZ/24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10092553001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "411427",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10092553",
        "Product Status": "Active"
    },
    {
        "Root": "10030736",
        "LV": "1",
        "Item Description": "GEL PAST COLOR ELECTRIC PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030736001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "COLOR GEL PAST",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030736",
        "Product Status": "Active"
    },
    {
        "Root": "10100231",
        "LV": "1",
        "Item Description": "CHOCOLATE RECT COCO 3\" X 2\"",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100231001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "WDW55743",
        "Last Price Paid": "",
        "Case Pack": "90",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100231",
        "Product Status": "Active"
    },
    {
        "Root": "10034363",
        "LV": "1",
        "Item Description": "COOKIE CHOCOLATE MINT GF SOY FREE VEGAN FREE KOSHE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034363001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "10002223",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034363",
        "Product Status": "Active"
    },
    {
        "Root": "10028042",
        "LV": "1",
        "Item Description": "BAYOU GOLD SELECT RUM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028042001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9204908",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028042",
        "Product Status": "Active"
    },
    {
        "Root": "10095633",
        "LV": "1",
        "Item Description": "WINE WHITE CHABLIS LOUIS MOREAU VAU LIGNEAU 750ML/",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095633001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "215485",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095633",
        "Product Status": "Active"
    },
    {
        "Root": "10024256",
        "LV": "1",
        "Item Description": "PINOT NOIR MONGEARD MUGNERET GR ECHEZEAUX 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10024256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9619706",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10024256",
        "Product Status": "Active"
    },
    {
        "Root": "10014147",
        "LV": "1",
        "Item Description": "BASIL OPAL MICRO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014147001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014147",
        "Product Status": "Active"
    },
    {
        "Root": "10102693",
        "LV": "1",
        "Item Description": "JUICE BOLTHOUSE GREEN GOODNESS 6/15 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102693001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "480505",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102693",
        "Product Status": "Active"
    },
    {
        "Root": "10036315",
        "LV": "1",
        "Item Description": "CANDY POP ROCKS STRAWBERRY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036315001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585204",
        "Last Price Paid": "$0.55",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036315",
        "Product Status": "Active"
    },
    {
        "Root": "10004142",
        "LV": "1",
        "Item Description": "TRUFFLE BLACKBERRY CHSCAK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004142001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000067785",
        "Vendor": "CHOCOLATE ACCENTS INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.08",
        "Case Pack": "108",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004142",
        "Product Status": "Active"
    },
    {
        "Root": "10003936",
        "LV": "2",
        "Item Description": "WATER ORANGE BLOSSOM 11.5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003936001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "110570",
        "Last Price Paid": "$4.91",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003936",
        "Product Status": "Active"
    },
    {
        "Root": "10039135",
        "LV": "1",
        "Item Description": "PUREE PASSION FRUIT NSA FROZEN 12/1KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039135001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6551320000",
        "Last Price Paid": "$13.94",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039135",
        "Product Status": "Active"
    },
    {
        "Root": "10001869",
        "LV": "1",
        "Item Description": "PEPPER SERRANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001869001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20660",
        "Last Price Paid": "$4.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001869",
        "Product Status": "Active"
    },
    {
        "Root": "10001869",
        "LV": "1",
        "Item Description": "PEPPER SERRANO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001869001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001869",
        "Product Status": "Active"
    },
    {
        "Root": "10087214",
        "LV": "2",
        "Item Description": "CUP WHITE PAPER UNPRINTED 12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087214001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$92.96",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10087214",
        "Product Status": "Active"
    },
    {
        "Root": "10022056",
        "LV": "1",
        "Item Description": "BAG WHT GOURMET 5 3/4 X 7 1/2 W/ HANDLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022056001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51589029",
        "Last Price Paid": "$0.17",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022056",
        "Product Status": "Active"
    },
    {
        "Root": "10003106",
        "LV": "1",
        "Item Description": "SAUCE COCKTAIL 4/1GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003106001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8056863",
        "Last Price Paid": "$13.98",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003106",
        "Product Status": "Active"
    },
    {
        "Root": "10002956",
        "LV": "2",
        "Item Description": "KETCHUP IND SIMPLY 9GR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002956002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$18.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002956",
        "Product Status": "Active"
    },
    {
        "Root": "10017906",
        "LV": "1",
        "Item Description": "MARTINI FUNKIN PASSION FRUIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017906001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "443797",
        "Last Price Paid": "$8.33",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017906",
        "Product Status": "Active"
    },
    {
        "Root": "10032611",
        "LV": "1",
        "Item Description": "PRETZEL SOFT PLAIN BRAUHAUS 10OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032611001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6852562",
        "Last Price Paid": "$2.40",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032611",
        "Product Status": "Active"
    },
    {
        "Root": "10107309",
        "LV": "1",
        "Item Description": "CHEESE BOURSIN FIG & BALSAMIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107309001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "28721",
        "Last Price Paid": "$4.80",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107309",
        "Product Status": "Active"
    },
    {
        "Root": "10100758",
        "LV": "1",
        "Item Description": "SAUCE SOY GLUTEN-FREE PLST BTL 6/1.9 LT SUB4 TAMA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10100758001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4930883",
        "Last Price Paid": "$7.76",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10100758",
        "Product Status": "Active"
    },
    {
        "Root": "10001253",
        "LV": "2",
        "Item Description": "CANTALOUPE WHOLE, USDA#1 SIZE, 12 MELON CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001253002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "161098",
        "Last Price Paid": "$2.67",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001253",
        "Product Status": "Active"
    },
    {
        "Root": "10000723",
        "LV": "1",
        "Item Description": "SAUSAGE FRSH UNCKD HUNGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000723001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.04",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000723",
        "Product Status": "Active"
    },
    {
        "Root": "10088882",
        "LV": "1",
        "Item Description": "POWDER RASPBERRY FRUIT 1 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088882001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "2041590000",
        "Last Price Paid": "$83.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088882",
        "Product Status": "Active"
    },
    {
        "Root": "10002184",
        "LV": "1",
        "Item Description": "BUTTERMILK HALF GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002184001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.51",
        "Case Pack": "9",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002184",
        "Product Status": "Active"
    },
    {
        "Root": "10021841",
        "LV": "1",
        "Item Description": "BAG SNOWFLAKE RED - MK ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021841001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51425005",
        "Last Price Paid": "$0.44",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021841",
        "Product Status": "Active"
    },
    {
        "Root": "10006346",
        "LV": "2",
        "Item Description": "LID 8 OZ CHILI CUP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006346002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "8929713",
        "Last Price Paid": "$0.09",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10006346",
        "Product Status": "Active"
    },
    {
        "Root": "10003511",
        "LV": "2",
        "Item Description": "TEA GENMAI CHA LS 14.1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003511001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W5002",
        "Last Price Paid": "$37.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003511",
        "Product Status": "Active"
    },
    {
        "Root": "10007683",
        "LV": "2",
        "Item Description": "CHARD GOGI GOLDIE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007683001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "580404",
        "Last Price Paid": "$30.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007683",
        "Product Status": "Active"
    },
    {
        "Root": "10105634",
        "LV": "1",
        "Item Description": "FYH DAIRY FREE FETA CRUMBLES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105634001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "3402",
        "Last Price Paid": "$12.24",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105634",
        "Product Status": "Active"
    },
    {
        "Root": "10005606",
        "LV": "2",
        "Item Description": "PRETZEL SWEET NORWAY 3 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005606001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.47",
        "Case Pack": "72",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10005606",
        "Product Status": "Active"
    },
    {
        "Root": "10043304",
        "LV": "2",
        "Item Description": "COCKTAIL CUTWATER WHISKEY MULE 12OZ CAN/24 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043304002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99429",
        "Last Price Paid": "$1.95",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10043304",
        "Product Status": "Active"
    },
    {
        "Root": "10043304",
        "LV": "2",
        "Item Description": "COCKTAIL CUTWATER WHISKEY MULE 12OZ CAN/24 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10043304002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "99429",
        "Last Price Paid": "$1.95",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10043304",
        "Product Status": "Active"
    },
    {
        "Root": "10095526",
        "LV": "1",
        "Item Description": "SPIRIT LIQUEUR BOYD & BLAIR GRAPEFRUIT LTR 12/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "594877",
        "Last Price Paid": "$22.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095526",
        "Product Status": "Active"
    },
    {
        "Root": "10001626",
        "LV": "1",
        "Item Description": "BANANA PLANTAIN SLICED IQ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001626001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7468168",
        "Last Price Paid": "$8.18",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001626",
        "Product Status": "Active"
    },
    {
        "Root": "10025593",
        "LV": "1",
        "Item Description": "CHARD DROUHIN CHABLIS 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025593001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "700638",
        "Last Price Paid": "$23.99",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025593",
        "Product Status": "Active"
    },
    {
        "Root": "10032145",
        "LV": "1",
        "Item Description": "SAUCE HOT MONARCH 4/1 GALLON",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032145001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5328182",
        "Last Price Paid": "$12.00",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032145",
        "Product Status": "Active"
    },
    {
        "Root": "10028187",
        "LV": "1",
        "Item Description": "BAKE CUP TULIP PURPLE MB78 2.4OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028187001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51391038",
        "Last Price Paid": "$0.17",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028187",
        "Product Status": "Active"
    },
    {
        "Root": "10004085",
        "LV": "1",
        "Item Description": "SAUCE SOY WHITE 1.8 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004085001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "3094",
        "Last Price Paid": "$26.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004085",
        "Product Status": "Active"
    },
    {
        "Root": "10016527",
        "LV": "1",
        "Item Description": "BREAD PULLMAN RYE MARBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016527001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069832",
        "Vendor": "OLDE HEARTH BREAD COMPANY",
        "Vendor Item Nb": "08123MD",
        "Last Price Paid": "$4.31",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016527",
        "Product Status": "Active"
    },
    {
        "Root": "10106071",
        "LV": "1",
        "Item Description": "BAG MEAL W/VENTS & TAC LABEL 8.25X5.25X12 ECOCRAFT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106071001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51301048",
        "Last Price Paid": "$0.59",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106071",
        "Product Status": "Active"
    },
    {
        "Root": "10002862",
        "LV": "1",
        "Item Description": "SPRITE 2LTR BT 8/CA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.22",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002862",
        "Product Status": "Active"
    },
    {
        "Root": "10016911",
        "LV": "1",
        "Item Description": "SODA MR PIBB EXTRA 20Z BTL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016911001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "049000029796",
        "Last Price Paid": "$1.26",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016911",
        "Product Status": "Active"
    },
    {
        "Root": "10032594",
        "LV": "1",
        "Item Description": "MICHELOB ULTRA PURE GOLD 12OZ BOTTLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032594001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "16322",
        "Last Price Paid": "$1.29",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10032594",
        "Product Status": "Active"
    },
    {
        "Root": "10006378",
        "LV": "1",
        "Item Description": "TRAY FOOD SERV TURRET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006378001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3289196",
        "Last Price Paid": "$0.47",
        "Case Pack": "250",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006378",
        "Product Status": "Active"
    },
    {
        "Root": "10031327",
        "LV": "1",
        "Item Description": "SYRAH CAYMUS SUISAN GRAND DURIF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031327001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "568559",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031327",
        "Product Status": "Active"
    },
    {
        "Root": "10107174",
        "LV": "1",
        "Item Description": "SPREAD GARLIC AND HERB 4LBS/4CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107174001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$10.13",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107174",
        "Product Status": "Active"
    },
    {
        "Root": "10009150",
        "LV": "1",
        "Item Description": "CHARD WENTE RIVA RSV 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009150001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "948519",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009150",
        "Product Status": "Active"
    },
    {
        "Root": "10034533",
        "LV": "1",
        "Item Description": "PAN FOIL FULL SIZE STEAMTABLE 3.37 DEEP 50EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034533001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9357559",
        "Last Price Paid": "$1.55",
        "Case Pack": "50",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034533",
        "Product Status": "Active"
    },
    {
        "Root": "10001550",
        "LV": "2",
        "Item Description": "ROOT HORSERADSIH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001550002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "481782",
        "Last Price Paid": "$32.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10001550",
        "Product Status": "Active"
    },
    {
        "Root": "10029930",
        "LV": "1",
        "Item Description": "GEL PASTE LEMON YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029930001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "AC LEMON YELLO",
        "Last Price Paid": "$32.77",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029930",
        "Product Status": "Active"
    },
    {
        "Root": "10012221",
        "LV": "1",
        "Item Description": "CHARDONNAY CASA LAPOSTOLLE CUVEE ALEXANDRE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "374568",
        "Last Price Paid": "$19.49",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012221",
        "Product Status": "Active"
    },
    {
        "Root": "10036309",
        "LV": "2",
        "Item Description": "CHOCOLATE MILK WORKHORSE 33.6% CALLEBAUT 2/22 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036309002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "C823NV",
        "Last Price Paid": "$122.32",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10036309",
        "Product Status": "Active"
    },
    {
        "Root": "10007121",
        "LV": "1",
        "Item Description": "CHARD HESS SHIRTAIL CRK 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007121001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9060352",
        "Last Price Paid": "$8.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007121",
        "Product Status": "Active"
    },
    {
        "Root": "10019587",
        "LV": "1",
        "Item Description": "BOURBON WHISKEY HUDSON BRIGHT LIGHTS 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019587001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9448421",
        "Last Price Paid": "$33.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019587",
        "Product Status": "Active"
    },
    {
        "Root": "10004542",
        "LV": "1",
        "Item Description": "SYRUP CORN LIGHT GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004542001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1373935",
        "Last Price Paid": "$14.84",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004542",
        "Product Status": "Active"
    },
    {
        "Root": "10007813",
        "LV": "1",
        "Item Description": "CHAMP RUINART BLC 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "383171",
        "Last Price Paid": "$55.50",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007813",
        "Product Status": "Active"
    },
    {
        "Root": "10102698",
        "LV": "1",
        "Item Description": "CROISSANT DOUGH SHEET BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102698001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "93042",
        "Last Price Paid": "$14.57",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102698",
        "Product Status": "Active"
    },
    {
        "Root": "10001121",
        "LV": "1",
        "Item Description": "SCALLOP U-10 DRY N. ATLANTIC FRESH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001121001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "66-50020",
        "Last Price Paid": "$24.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001121",
        "Product Status": "Active"
    },
    {
        "Root": "10003080",
        "LV": "1",
        "Item Description": "OIL OLIVE MIST 100% 14Z",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003080001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4922290",
        "Last Price Paid": "$5.41",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003080",
        "Product Status": "Active"
    },
    {
        "Root": "10003250",
        "LV": "1",
        "Item Description": "SYRUP BLUE CURACAO LTR MO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "78-5007",
        "Last Price Paid": "$7.30",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003250",
        "Product Status": "Active"
    },
    {
        "Root": "10001730",
        "LV": "1",
        "Item Description": "PEA SUGAR SNAP CLIPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$36.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001730",
        "Product Status": "Active"
    },
    {
        "Root": "10012179",
        "LV": "1",
        "Item Description": "GIN PLYMOUTH LITER",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012179001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "319201",
        "Last Price Paid": "$32.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012179",
        "Product Status": "Active"
    },
    {
        "Root": "10001186",
        "LV": "1",
        "Item Description": "DUCK CONFIT LEG & THIGH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001186001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "41480",
        "Last Price Paid": "$34.60",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001186",
        "Product Status": "Active"
    },
    {
        "Root": "10010763",
        "LV": "2",
        "Item Description": "WATER STILL SOLE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010763001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010763",
        "Product Status": "Active"
    },
    {
        "Root": "10007359",
        "LV": "1",
        "Item Description": "BLND BRUNIER TELEGRM RGE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "678568",
        "Last Price Paid": "$41.49",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007359",
        "Product Status": "Active"
    },
    {
        "Root": "10000831",
        "LV": "1",
        "Item Description": "CHICKEN THIGH BONELESS SKINLESS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000831001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.39",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000831",
        "Product Status": "Active"
    },
    {
        "Root": "10008219",
        "LV": "1",
        "Item Description": "CHARD MER SOLEI SILVER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008219001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "578261",
        "Last Price Paid": "$10.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008219",
        "Product Status": "Active"
    },
    {
        "Root": "10021355",
        "LV": "1",
        "Item Description": "RUM BACARDI DRAGON BERRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000418043",
        "Vendor": "SOUTHERN WINE AND SPIRITS",
        "Vendor Item Nb": "240793",
        "Last Price Paid": "$11.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021355",
        "Product Status": "Active"
    },
    {
        "Root": "10021355",
        "LV": "1",
        "Item Description": "RUM BACARDI DRAGON BERRY 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10021355001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "240793",
        "Last Price Paid": "$11.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10021355",
        "Product Status": "Active"
    },
    {
        "Root": "10036287",
        "LV": "1",
        "Item Description": "BELGIAN CAVIAR PLATINUM 125 GRAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036287001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "63520040",
        "Last Price Paid": "$185.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036287",
        "Product Status": "Active"
    },
    {
        "Root": "10082060",
        "LV": "1",
        "Item Description": "WINE BLEND CHATEAU DES CHARMES CABMERLOT 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082060001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2530541",
        "Last Price Paid": "$17.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082060",
        "Product Status": "Active"
    },
    {
        "Root": "10016359",
        "LV": "1",
        "Item Description": "WRAP DELI BURGER GREASE REST. 12X12",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016359001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "45659012",
        "Last Price Paid": "$0.02",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016359",
        "Product Status": "Active"
    },
    {
        "Root": "10090677",
        "LV": "1",
        "Item Description": "GIN BOMBAY SAPPHIRE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090677001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11292",
        "Last Price Paid": "$19.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090677",
        "Product Status": "Active"
    },
    {
        "Root": "10008317",
        "LV": "2",
        "Item Description": "PINOT NOIR DIERBERG 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008317001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9168080",
        "Last Price Paid": "$29.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008317",
        "Product Status": "Active"
    },
    {
        "Root": "10031745",
        "LV": "1",
        "Item Description": "CUP CAKE MINIRED VELVET PINK ICING",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10031745001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "1803",
        "Last Price Paid": "$0.48",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10031745",
        "Product Status": "Active"
    },
    {
        "Root": "10006139",
        "LV": "1",
        "Item Description": "SB WDW CUP 26Z PRT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006139001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "600",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006139",
        "Product Status": "Active"
    },
    {
        "Root": "10010068",
        "LV": "1",
        "Item Description": "BEER CORONA LIGHT BTL 24EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010068001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10163",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010068",
        "Product Status": "Active"
    },
    {
        "Root": "10001646",
        "LV": "1",
        "Item Description": "CARROT STIX 2.5 X 3.8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001646001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "512002",
        "Last Price Paid": "$21.04",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001646",
        "Product Status": "Active"
    },
    {
        "Root": "10010992",
        "LV": "1",
        "Item Description": "BOX DELI KRAFT WITH HANDLE 9.5X5X5",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010992001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "516200204",
        "Last Price Paid": "$0.82",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10010992",
        "Product Status": "Active"
    },
    {
        "Root": "10083496",
        "LV": "2",
        "Item Description": "MIX SOFT SERVE APPLE CINNAMON GELATO 6 JUG 1/2 GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083496002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "G-APCN-11-MX-C",
        "Last Price Paid": "$11.64",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10083496",
        "Product Status": "Active"
    },
    {
        "Root": "10030411",
        "LV": "1",
        "Item Description": "MUSTARD YELLOW SQUEEZE BOTTLE 16/9OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9160516",
        "Last Price Paid": "$1.39",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030411",
        "Product Status": "Active"
    },
    {
        "Root": "10001885",
        "LV": "1",
        "Item Description": "JICAMA JULIENNE 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000344125",
        "Vendor": "WORLD FOOD LLC",
        "Vendor Item Nb": "41-002-A",
        "Last Price Paid": "$12.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001885",
        "Product Status": "Active"
    },
    {
        "Root": "10001885",
        "LV": "1",
        "Item Description": "JICAMA JULIENNE 4/5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "511024",
        "Last Price Paid": "$12.50",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001885",
        "Product Status": "Active"
    },
    {
        "Root": "10015136",
        "LV": "1",
        "Item Description": "BREAD APRICOT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015136001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "CDO354",
        "Last Price Paid": "$1.24",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015136",
        "Product Status": "Active"
    },
    {
        "Root": "10028021",
        "LV": "1",
        "Item Description": "CORN DOG CHICKEN MINI .67OZ 2/5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10028021001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "4385861",
        "Last Price Paid": "$10.56",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10028021",
        "Product Status": "Active"
    },
    {
        "Root": "10035291",
        "LV": "3",
        "Item Description": "SPICE RIB RUB FLAME TREE 4/6.5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035291002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "033844-02093-4",
        "Last Price Paid": "$7.55",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10035291",
        "Product Status": "Active"
    },
    {
        "Root": "10035291",
        "LV": "3",
        "Item Description": "SPICE RIB RUB FLAME TREE 4/6.5 LBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035291002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.55",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10035291",
        "Product Status": "Active"
    },
    {
        "Root": "10003890",
        "LV": "1",
        "Item Description": "BONITO FLAKES",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003890001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000091284",
        "Vendor": "SANWA TRADING CO INC",
        "Vendor Item Nb": "8104",
        "Last Price Paid": "$25.60",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003890",
        "Product Status": "Active"
    },
    {
        "Root": "10039897",
        "LV": "1",
        "Item Description": "CUP SIPPER ORANGE BIRD2 16OZ 24 CASE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$5.08",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039897",
        "Product Status": "Active"
    },
    {
        "Root": "10107329",
        "LV": "1",
        "Item Description": "SHIRAZ CH TANUNDA GRND BAROSSA 12/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107329001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000277875",
        "Vendor": "REPUBLIC NATIONAL DISTRIBUTORS",
        "Vendor Item Nb": "345883",
        "Last Price Paid": "$12.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107329",
        "Product Status": "Active"
    },
    {
        "Root": "10033663",
        "LV": "2",
        "Item Description": "SUGAR PALM TREE ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033663002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "42401",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033663",
        "Product Status": "Active"
    },
    {
        "Root": "10034571",
        "LV": "1",
        "Item Description": "COOKIE SUGAR MINNIE VINTAGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034571001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "S-MNVTG",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034571",
        "Product Status": "Active"
    },
    {
        "Root": "10038179",
        "LV": "1",
        "Item Description": "LIQUEUR CHAREAU ALOE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10038179001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10038179",
        "Product Status": "Active"
    },
    {
        "Root": "10037411",
        "LV": "1",
        "Item Description": "CHEESE SLICES DAIRY FREE PEPPER JACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037411001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037411",
        "Product Status": "Active"
    },
    {
        "Root": "10002398",
        "LV": "1",
        "Item Description": "CHEESE BLUE STILTON ENGLAND 5 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002398001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002398",
        "Product Status": "Active"
    },
    {
        "Root": "10033650",
        "LV": "2",
        "Item Description": "SUGAR TURKEY AND CORNUCOPIA ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033650001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "16663",
        "Last Price Paid": "",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10033650",
        "Product Status": "Active"
    },
    {
        "Root": "10036703",
        "LV": "1",
        "Item Description": "CABERNET FRANC CHAPPELLET CF 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036703001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "689676",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036703",
        "Product Status": "Active"
    },
    {
        "Root": "10037878",
        "LV": "1",
        "Item Description": "COOKIES H2O GLOW NIGHTS INDIVIDUALLY WRAPPED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000540269",
        "Vendor": "BAKEHOUSE LLC",
        "Vendor Item Nb": "CK-GLOWIW",
        "Last Price Paid": "",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037878",
        "Product Status": "Active"
    },
    {
        "Root": "10039804",
        "LV": "1",
        "Item Description": "CHOC CHRISTMAS ORNAMENT 144/CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10039804001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "144",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10039804",
        "Product Status": "Active"
    },
    {
        "Root": "10033008",
        "LV": "2",
        "Item Description": "SUGAR BEACHCOMBER ASST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033008002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "50758",
        "Last Price Paid": "",
        "Case Pack": "58",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033008",
        "Product Status": "Active"
    },
    {
        "Root": "10004047",
        "LV": "1",
        "Item Description": "SPRAY PEARL CLRNG SLVR SH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004047001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "118754",
        "Last Price Paid": "",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004047",
        "Product Status": "Active"
    },
    {
        "Root": "10013217",
        "LV": "1",
        "Item Description": "CIDER REKORDERLIG PASSION FRUIT CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013217001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013217",
        "Product Status": "Active"
    },
    {
        "Root": "10023536",
        "LV": "1",
        "Item Description": "COCOA BUTTER PEARL BLUE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023536001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "630143",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023536",
        "Product Status": "Active"
    },
    {
        "Root": "10005294",
        "LV": "1",
        "Item Description": "PUREE FIG 1/KG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005294001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "582...{BOIRON{",
        "Last Price Paid": "$14.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005294",
        "Product Status": "Active"
    },
    {
        "Root": "10005730",
        "LV": "1",
        "Item Description": "DOUGHNUTS, CINN, VEGAN GLUTEN FREE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10005730001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000470130",
        "Vendor": "ERIN MCKENNAS BAKERY VALENTINE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.92",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10005730",
        "Product Status": "Active"
    },
    {
        "Root": "10034239",
        "LV": "2",
        "Item Description": "TEQUILA CORAZON BLANCO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034239002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9220212",
        "Last Price Paid": "$15.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034239",
        "Product Status": "Active"
    },
    {
        "Root": "10009536",
        "LV": "2",
        "Item Description": "LINIE AQUAVIT NORWAY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009536002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$24.41",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009536",
        "Product Status": "Active"
    },
    {
        "Root": "10007771",
        "LV": "1",
        "Item Description": "ZINF DIRECTOR CUT COPPOLA 750",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007771001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "309418",
        "Last Price Paid": "$19.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10007771",
        "Product Status": "Active"
    },
    {
        "Root": "10088245",
        "LV": "2",
        "Item Description": "GARNISH DEHYDRATED LIME WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088245002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "LIC-1",
        "Last Price Paid": "$0.12",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10088245",
        "Product Status": "Active"
    },
    {
        "Root": "10029408",
        "LV": "1",
        "Item Description": "COUS COUS MOROCCAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029408001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "163337",
        "Last Price Paid": "$20.28",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029408",
        "Product Status": "Active"
    },
    {
        "Root": "10023590",
        "LV": "1",
        "Item Description": "SPRAY COLOR GOLD PEARL SHEEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10023590001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ118755",
        "Last Price Paid": "$8.54",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10023590",
        "Product Status": "Active"
    },
    {
        "Root": "10009490",
        "LV": "2",
        "Item Description": "BOURBON JIM BEAM 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009490002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$15.73",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10009490",
        "Product Status": "Active"
    },
    {
        "Root": "10004785",
        "LV": "1",
        "Item Description": "NUT WALNUT HALVES ONLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004785001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "183052",
        "Last Price Paid": "$102.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004785",
        "Product Status": "Active"
    },
    {
        "Root": "10102751",
        "LV": "1",
        "Item Description": "PASTA MACARONI ELBOW 1\" COOKED FZN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102751001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9724949",
        "Last Price Paid": "$25.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102751",
        "Product Status": "Active"
    },
    {
        "Root": "10106168",
        "LV": "1",
        "Item Description": "LINER 12X12 SANDWICH WRAP KRAFT DUBL-SHIELD LW 30",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106168001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "51008040",
        "Last Price Paid": "$0.02",
        "Case Pack": "4000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106168",
        "Product Status": "Active"
    },
    {
        "Root": "10104129",
        "LV": "1",
        "Item Description": "BEEF BRISKET SMKD THK SLICED",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104129001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7003627",
        "Last Price Paid": "$9.80",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104129",
        "Product Status": "Active"
    },
    {
        "Root": "10020360",
        "LV": "1",
        "Item Description": "MESCLUN GOURMET BOWTIE PKG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10020360001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "09GBOTM-33",
        "Last Price Paid": "$29.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10020360",
        "Product Status": "Active"
    },
    {
        "Root": "10083977",
        "LV": "1",
        "Item Description": "LIID CLEAR 10.25 FOR PULP PLATE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083977001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "11303155",
        "Last Price Paid": "$0.79",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083977",
        "Product Status": "Active"
    },
    {
        "Root": "10012443",
        "LV": "1",
        "Item Description": "GUMPASTE-5 PETAL DIAMANTE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012443001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "5282",
        "Last Price Paid": "$0.44",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012443",
        "Product Status": "Active"
    },
    {
        "Root": "10011680",
        "LV": "3",
        "Item Description": "SB BUTTER CROISSANT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10011680001003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11083122",
        "Last Price Paid": "$0.91",
        "Case Pack": "27",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "001",
        "NALA": "10011680",
        "Product Status": "Active"
    },
    {
        "Root": "10003255",
        "LV": "1",
        "Item Description": "SYRUP WILD STRAWBRY LTR M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003255001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$6.70",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003255",
        "Product Status": "Active"
    },
    {
        "Root": "10010133",
        "LV": "2",
        "Item Description": "WATER SAN BENEDETTO STILL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010133001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000424468",
        "Vendor": "AQUA MAESTRO",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.86",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010133",
        "Product Status": "Active"
    },
    {
        "Root": "10086526",
        "LV": "1",
        "Item Description": "DECOR GOLD STAR QUINS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10086526001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "26381",
        "Last Price Paid": "$6.61",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10086526",
        "Product Status": "Active"
    },
    {
        "Root": "10004897",
        "LV": "1",
        "Item Description": "CANDY M&M 12/5.3 OZ PEG PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004897001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "6922",
        "Last Price Paid": "$3.06",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004897",
        "Product Status": "Active"
    },
    {
        "Root": "10009612",
        "LV": "1",
        "Item Description": "LIQ ST GERMAIN ELDER 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009612001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "175921",
        "Last Price Paid": "$27.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009612",
        "Product Status": "Active"
    },
    {
        "Root": "10033493",
        "LV": "1",
        "Item Description": "SPRAY COCOA BUTTER YELLOW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033493001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "586005",
        "Last Price Paid": "$51.36",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033493",
        "Product Status": "Active"
    },
    {
        "Root": "10037141",
        "LV": "1",
        "Item Description": "BUN GF JUMBO STANDARD BLOOMFIELD FARMS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037141001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "14447",
        "Last Price Paid": "$1.52",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037141",
        "Product Status": "Active"
    },
    {
        "Root": "10000632",
        "LV": "1",
        "Item Description": "PORK TROTTERS SPLIT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000632001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1481349",
        "Last Price Paid": "$1.58",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000632",
        "Product Status": "Active"
    },
    {
        "Root": "10002379",
        "LV": "3",
        "Item Description": "ICE CREAM SANDWICH TOLLHOUSE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002379003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.13",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10002379",
        "Product Status": "Active"
    },
    {
        "Root": "10002954",
        "LV": "1",
        "Item Description": "SAUCE BBQ 1OZ SIMPLY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002954001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "1300071460",
        "Last Price Paid": "$0.09",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002954",
        "Product Status": "Active"
    },
    {
        "Root": "10084131",
        "LV": "2",
        "Item Description": "BOX GABLE 7X5X3.9 PMS 7562U",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10084131002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510101",
        "Last Price Paid": "$1.16",
        "Case Pack": "175",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10084131",
        "Product Status": "Active"
    },
    {
        "Root": "10013442",
        "LV": "1",
        "Item Description": "ONION JUMBO 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013442001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$17.35",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013442",
        "Product Status": "Active"
    },
    {
        "Root": "10004966",
        "LV": "1",
        "Item Description": "PASTA FRZ RAVIOLI PORCINI",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004966001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7073110",
        "Last Price Paid": "$0.44",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004966",
        "Product Status": "Active"
    },
    {
        "Root": "10102652",
        "LV": "1",
        "Item Description": "POPCHIPS - SOUR CREAM & ONION .8OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102652001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000554499",
        "Vendor": "NASSAU CANDY",
        "Vendor Item Nb": "955006",
        "Last Price Paid": "$1.02",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10102652",
        "Product Status": "Active"
    },
    {
        "Root": "10030250",
        "LV": "1",
        "Item Description": "POPCORN MUSHROOM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030250001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "MAGIC",
        "Last Price Paid": "$30.23",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030250",
        "Product Status": "Active"
    },
    {
        "Root": "10009489",
        "LV": "1",
        "Item Description": "SCOTCH MACALLAN 25YR 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009489001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40459",
        "Last Price Paid": "$1,425.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009489",
        "Product Status": "Active"
    },
    {
        "Root": "10027732",
        "LV": "2",
        "Item Description": "BOX CREAM COLOR W/RIBBON PULL 2-COUNT 3X1.5X.687",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027732002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000622575",
        "Vendor": "SOUTHEASTERN PAPER GROUP",
        "Vendor Item Nb": "510600",
        "Last Price Paid": "$0.32",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10027732",
        "Product Status": "Active"
    },
    {
        "Root": "10003490",
        "LV": "2",
        "Item Description": "TEA FLRL JASMINE LSE 14.1",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003490001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "W5006",
        "Last Price Paid": "$35.40",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003490",
        "Product Status": "Active"
    },
    {
        "Root": "10004300",
        "LV": "1",
        "Item Description": "PEPPER CHILI GRN DICED PL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004300001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5053069",
        "Last Price Paid": "$2.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004300",
        "Product Status": "Active"
    },
    {
        "Root": "10016022",
        "LV": "1",
        "Item Description": "PEPPER RED FRESNO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20652",
        "Last Price Paid": "$47.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016022",
        "Product Status": "Active"
    },
    {
        "Root": "10016022",
        "LV": "1",
        "Item Description": "PEPPER RED FRESNO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016022001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "270654",
        "Last Price Paid": "$47.34",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10016022",
        "Product Status": "Active"
    },
    {
        "Root": "10106509",
        "LV": "1",
        "Item Description": "CIRCULAR CRISPY ON A STICK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106509001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000564089",
        "Vendor": "LINDLEY FOODS LLC DBA SELMAS COOKIE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.55",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106509",
        "Product Status": "Active"
    },
    {
        "Root": "10030148",
        "LV": "1",
        "Item Description": "DECORATION SEA CREATURES WHITE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030148001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "SCRE",
        "Last Price Paid": "$1.66",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030148",
        "Product Status": "Active"
    },
    {
        "Root": "10107894",
        "LV": "1",
        "Item Description": "BREAD FRENCH ROLL 200CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107894001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069695",
        "Vendor": "DOUCE FRANCE BAKERY INC",
        "Vendor Item Nb": "13:X",
        "Last Price Paid": "$0.24",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107894",
        "Product Status": "Active"
    },
    {
        "Root": "10105432",
        "LV": "1",
        "Item Description": "BUCKET POP PREM HLWN23 SKELETON MM 48OZ 12CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105432001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$7.22",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105432",
        "Product Status": "Active"
    },
    {
        "Root": "10095969",
        "LV": "1",
        "Item Description": "COD  SEA NUGGET BREADED 1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10095969001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6356510",
        "Last Price Paid": "$76.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10095969",
        "Product Status": "Active"
    },
    {
        "Root": "10002824",
        "LV": "2",
        "Item Description": "HONEY .5Z 200CT IND PLAST",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002824002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.11",
        "Case Pack": "200",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10002824",
        "Product Status": "Active"
    },
    {
        "Root": "10004458",
        "LV": "1",
        "Item Description": "MIX FREESTYLE LIME",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004458001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "7025793",
        "Last Price Paid": "$16.65",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004458",
        "Product Status": "Active"
    },
    {
        "Root": "10081172",
        "LV": "1",
        "Item Description": "ROLL SPRING LUMPIA PINEAPPLE CREAM CHEESE & SUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10081172001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.10",
        "Case Pack": "44",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10081172",
        "Product Status": "Active"
    },
    {
        "Root": "10003027",
        "LV": "1",
        "Item Description": "SUGAR SUB EQUAL 2000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003027001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.01",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003027",
        "Product Status": "Active"
    },
    {
        "Root": "10027290",
        "LV": "1",
        "Item Description": "CRACKER GRAHAM HONEY SQUARES IND WRAPPED 150/3 EA",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027290001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "7612351",
        "Last Price Paid": "$0.24",
        "Case Pack": "150",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027290",
        "Product Status": "Active"
    },
    {
        "Root": "10003001",
        "LV": "1",
        "Item Description": "CORNSTARCH 50#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003001001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$48.08",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003001",
        "Product Status": "Active"
    },
    {
        "Root": "10004400",
        "LV": "1",
        "Item Description": "PASTA PAPPARDELLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004400001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7070439",
        "Last Price Paid": "$50.11",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004400",
        "Product Status": "Active"
    },
    {
        "Root": "10000587",
        "LV": "2",
        "Item Description": "BACON PCS CKD 3/8 INCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000587001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$29.40",
        "Case Pack": "2",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10000587",
        "Product Status": "Active"
    },
    {
        "Root": "10106229",
        "LV": "1",
        "Item Description": "HIGH NOON CKTL PINEAPPLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10106229001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.50",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10106229",
        "Product Status": "Active"
    },
    {
        "Root": "10008252",
        "LV": "1",
        "Item Description": "DSRT ROYAL TOKAJI RED LAB 5 500",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008252001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "2228351",
        "Last Price Paid": "$42.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008252",
        "Product Status": "Active"
    },
    {
        "Root": "10008303",
        "LV": "1",
        "Item Description": "COOLER SEAGRAM WILDBERRY 355M",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008303001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "63930",
        "Last Price Paid": "$0.94",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008303",
        "Product Status": "Active"
    },
    {
        "Root": "10032904",
        "LV": "2",
        "Item Description": "SUGAR GROOVY ASSORTMENT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10032904002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "12420",
        "Last Price Paid": "$18.26",
        "Case Pack": "96",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10032904",
        "Product Status": "Active"
    },
    {
        "Root": "10003790",
        "LV": "2",
        "Item Description": "XANTHAN GUM GF BOBS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003790001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "P101460",
        "Last Price Paid": "$8.08",
        "Case Pack": "5",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10003790",
        "Product Status": "Active"
    },
    {
        "Root": "10004927",
        "LV": "1",
        "Item Description": "HORS POTSTICKER PORK & VE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004927001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "7042230",
        "Last Price Paid": "$0.25",
        "Case Pack": "180",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004927",
        "Product Status": "Active"
    },
    {
        "Root": "10010988",
        "LV": "2",
        "Item Description": "SB REFRESHER STRAWBERRY INCLUSION",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010988002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11047350",
        "Last Price Paid": "$7.89",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10010988",
        "Product Status": "Active"
    },
    {
        "Root": "10008218",
        "LV": "1",
        "Item Description": "CHARD FARNIENTE NAPA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008218001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "597760",
        "Last Price Paid": "$42.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008218",
        "Product Status": "Active"
    },
    {
        "Root": "10034256",
        "LV": "1",
        "Item Description": "SANGRIA LOLAILO 250ML 24CTEX",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034256001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "",
        "Last Price Paid": "$2.00",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034256",
        "Product Status": "Active"
    },
    {
        "Root": "10035965",
        "LV": "3",
        "Item Description": "POPCORN GLAZE BLUEBERRY LEMON DELOS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035965002003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000061059",
        "Vendor": "GOLD MEDAL PRODUCTS ORLANDO",
        "Vendor Item Nb": "2686",
        "Last Price Paid": "$2.45",
        "Case Pack": "18",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "002",
        "NALA": "10035965",
        "Product Status": "Active"
    },
    {
        "Root": "10018827",
        "LV": "1",
        "Item Description": "DECOR CARROT MED SUGAR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10018827001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069831",
        "Vendor": "DECO PAC",
        "Vendor Item Nb": "47138",
        "Last Price Paid": "$0.11",
        "Case Pack": "231",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10018827",
        "Product Status": "Active"
    },
    {
        "Root": "10001728",
        "LV": "1",
        "Item Description": "EGGPLANT JAPANESE 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "480049",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001728",
        "Product Status": "Active"
    },
    {
        "Root": "10001728",
        "LV": "1",
        "Item Description": "EGGPLANT JAPANESE 10#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001728001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000531174",
        "Vendor": "FLORIDA VEG INVESTMENTS, LLC",
        "Vendor Item Nb": "20450",
        "Last Price Paid": "$19.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001728",
        "Product Status": "Active"
    },
    {
        "Root": "10002738",
        "LV": "1",
        "Item Description": "SYRUP ROOT BEER BARQ 5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10002738001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$96.30",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10002738",
        "Product Status": "Active"
    },
    {
        "Root": "10091222",
        "LV": "1",
        "Item Description": "RUM SELVAREY CHOCOLATE 6/750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10091222001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "200452",
        "Last Price Paid": "$22.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10091222",
        "Product Status": "Active"
    },
    {
        "Root": "10004194",
        "LV": "1",
        "Item Description": "COMPOUND ORANGE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004194001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "R113",
        "Last Price Paid": "$18.84",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004194",
        "Product Status": "Active"
    },
    {
        "Root": "10034791",
        "LV": "1",
        "Item Description": "PORK CHEEK MEAT SKINLESS FROZEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034791001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "1440034",
        "Last Price Paid": "$3.63",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10034791",
        "Product Status": "Active"
    },
    {
        "Root": "10015382",
        "LV": "1",
        "Item Description": "SAUSAGE SWT HOT ITAL 6 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10015382001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "170A",
        "Last Price Paid": "$3.48",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10015382",
        "Product Status": "Active"
    },
    {
        "Root": "10029028",
        "LV": "1",
        "Item Description": "SPICES SEASONING OLD BAY 7.5 LB BULK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10029028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5043120",
        "Last Price Paid": "$43.93",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10029028",
        "Product Status": "Active"
    },
    {
        "Root": "10000048",
        "LV": "1",
        "Item Description": "PORK SAUSAGE BRATWURST RAW",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000048001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068473",
        "Vendor": "GEIERS SAUSAGE KITCHEN INC",
        "Vendor Item Nb": "193C",
        "Last Price Paid": "$3.15",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000048",
        "Product Status": "Active"
    },
    {
        "Root": "10035931",
        "LV": "1",
        "Item Description": "CIDER ANGRY ORCHARD ROSE 16OZ CAN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035931001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "40334",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035931",
        "Product Status": "Active"
    },
    {
        "Root": "10035995",
        "LV": "1",
        "Item Description": "POWDER PURE-COTE B790",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035995001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "DRY040",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035995",
        "Product Status": "Active"
    },
    {
        "Root": "10087188",
        "LV": "1",
        "Item Description": "JUICE BASE LEMONADE 10.5% 3 TO 1 6/61.5OZ TROPICS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10087188001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "9317614",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10087188",
        "Product Status": "Active"
    },
    {
        "Root": "10036028",
        "LV": "1",
        "Item Description": "CITADELLE GIN RSV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036028001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "906947",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036028",
        "Product Status": "Active"
    },
    {
        "Root": "10110949",
        "LV": "2",
        "Item Description": "ORA KING SALMON SK/OFF PBO",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10110949002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-50059",
        "Last Price Paid": "",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10110949",
        "Product Status": "Active"
    },
    {
        "Root": "10090556",
        "LV": "1",
        "Item Description": "DEMI GLACE DE VEAU CLASSIC",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10090556001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "",
        "Vendor": "",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "16",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10090556",
        "Product Status": "Active"
    },
    {
        "Root": "10022580",
        "LV": "1",
        "Item Description": "PEPSI DIET CAN 24/12OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10022580001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "",
        "Last Price Paid": "",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10022580",
        "Product Status": "Active"
    },
    {
        "Root": "10033802",
        "LV": "1",
        "Item Description": "HIBISCUS SPRAY LAVENDER ETM LV",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033802001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "ETM LV",
        "Last Price Paid": "",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10033802",
        "Product Status": "Active"
    },
    {
        "Root": "10013601",
        "LV": "1",
        "Item Description": "SB PEPPERMINT BROWNIE CAKE POP",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013601001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11066583",
        "Last Price Paid": "$1.18",
        "Case Pack": "36",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10013601",
        "Product Status": "Active"
    },
    {
        "Root": "10040455",
        "LV": "1",
        "Item Description": "HOT CUP LID PLA 10-20OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10040455001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.10",
        "Case Pack": "1000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10040455",
        "Product Status": "Active"
    },
    {
        "Root": "10033918",
        "LV": "2",
        "Item Description": "BEEF TOP SIRLOIN STEAK CENTER-CUT 6Z LO-PRO CHOICE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10033918002002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000375118",
        "Vendor": "BUCKHEAD BEEF COMPANY",
        "Vendor Item Nb": "2191505",
        "Last Price Paid": "$13.35",
        "Case Pack": "10",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10033918",
        "Product Status": "Active"
    },
    {
        "Root": "10012064",
        "LV": "1",
        "Item Description": "MINT NEPITELLA BLOOMS 50 COUNT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10012064001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069809",
        "Vendor": "CHEFS GARDEN INC",
        "Vendor Item Nb": "08NEPB-33-S",
        "Last Price Paid": "$36.50",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10012064",
        "Product Status": "Active"
    },
    {
        "Root": "10082484",
        "LV": "1",
        "Item Description": "SODA BIB CLUB COOL VIVA RASPBERRY 5GAL/1CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082484001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "37190010",
        "Last Price Paid": "$45.90",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082484",
        "Product Status": "Active"
    },
    {
        "Root": "10001464",
        "LV": "1",
        "Item Description": "GARLIC JUMBO 5#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001464001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "490139",
        "Last Price Paid": "$15.27",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001464",
        "Product Status": "Active"
    },
    {
        "Root": "10014920",
        "LV": "1",
        "Item Description": "CHEESE FETA SHEEP AND GOAT BARREL AGED GREEK 8 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014920001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "112",
        "Last Price Paid": "$10.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10014920",
        "Product Status": "Active"
    },
    {
        "Root": "10027343",
        "LV": "1",
        "Item Description": "CHARDONNAY BANSHEE KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027343001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "991636",
        "Last Price Paid": "$312.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027343",
        "Product Status": "Active"
    },
    {
        "Root": "10000922",
        "LV": "1",
        "Item Description": "FISH FRESH SABLEFISH FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000922001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000069688",
        "Vendor": "BROWNE TRADING",
        "Vendor Item Nb": "10119000",
        "Last Price Paid": "$26.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000922",
        "Product Status": "Active"
    },
    {
        "Root": "10000922",
        "LV": "1",
        "Item Description": "FISH FRESH SABLEFISH FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000922001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000445754",
        "Vendor": "OCEAN BEAUTY SEAFOODS LLC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$26.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000922",
        "Product Status": "Active"
    },
    {
        "Root": "10000922",
        "LV": "1",
        "Item Description": "FISH FRESH SABLEFISH FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000922001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6496351",
        "Last Price Paid": "$26.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000922",
        "Product Status": "Active"
    },
    {
        "Root": "10000922",
        "LV": "1",
        "Item Description": "FISH FRESH SABLEFISH FILLET",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000922001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "62-00750",
        "Last Price Paid": "$26.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000922",
        "Product Status": "Active"
    },
    {
        "Root": "10001399",
        "LV": "1",
        "Item Description": "WHEAT GRASS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "406054",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001399",
        "Product Status": "Active"
    },
    {
        "Root": "10001399",
        "LV": "1",
        "Item Description": "WHEAT GRASS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001399",
        "Product Status": "Active"
    },
    {
        "Root": "10001399",
        "LV": "1",
        "Item Description": "WHEAT GRASS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001399001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "406054",
        "Last Price Paid": "$31.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001399",
        "Product Status": "Active"
    },
    {
        "Root": "10107341",
        "LV": "1",
        "Item Description": "BEER GOOSE ISLANDO NEONBEER HUG 15.5GAL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107341001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069708",
        "Vendor": "CITY BEVERAGES LIMITED",
        "Vendor Item Nb": "99937",
        "Last Price Paid": "$212.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107341",
        "Product Status": "Active"
    },
    {
        "Root": "10000878",
        "LV": "1",
        "Item Description": "QUAIL SEMI BNLS 4-5 OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10000878001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069640",
        "Vendor": "CULINARY CLASSICS",
        "Vendor Item Nb": "QA06",
        "Last Price Paid": "$7.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10000878",
        "Product Status": "Active"
    },
    {
        "Root": "10004867",
        "LV": "1",
        "Item Description": "SPICE CARDAMON WHOLE POD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004867001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000609339",
        "Vendor": "DAIRYLAND PRODUCE LLC",
        "Vendor Item Nb": "108070",
        "Last Price Paid": "$37.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004867",
        "Product Status": "Active"
    },
    {
        "Root": "10019596",
        "LV": "1",
        "Item Description": "BOURBON ELIJAH CRAIG SM BTCH 12 YR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10019596001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "409268",
        "Last Price Paid": "$29.08",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10019596",
        "Product Status": "Active"
    },
    {
        "Root": "10089478",
        "LV": "1",
        "Item Description": "CANDY GUMMY SHARKS ASST 6/4.4 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10089478001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000491033",
        "Vendor": "TROPICAL NUT & FRUIT CO",
        "Vendor Item Nb": "585397",
        "Last Price Paid": "$17.07",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10089478",
        "Product Status": "Active"
    },
    {
        "Root": "10009822",
        "LV": "2",
        "Item Description": "RUM GOSLING 151 LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009822001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "178310",
        "Last Price Paid": "$21.25",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10009822",
        "Product Status": "Active"
    },
    {
        "Root": "10016088",
        "LV": "2",
        "Item Description": "CHICK WHOLE WOG 2.25-2.5 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10016088001002",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.22",
        "Case Pack": "40",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10016088",
        "Product Status": "Active"
    },
    {
        "Root": "10109609",
        "LV": "1",
        "Item Description": "PASTRY MINI CHOCOLATINE 1.2OZ RTB 210CT SUB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10109609001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.28",
        "Case Pack": "210",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10109609",
        "Product Status": "Active"
    },
    {
        "Root": "10001493",
        "LV": "1",
        "Item Description": "SQUASH YELLOW #2",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001493001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000412944",
        "Vendor": "HARVILLS PRODUCE CO INC",
        "Vendor Item Nb": "",
        "Last Price Paid": "$1.20",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001493",
        "Product Status": "Active"
    },
    {
        "Root": "10102831",
        "LV": "2",
        "Item Description": "GELATO SOFT SERVE PURPLE SKITTLES 6 HALF GAL JUGS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10102831002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000449119",
        "Vendor": "MUSE GELATO INC",
        "Vendor Item Nb": "P-SKIT-11-CS6",
        "Last Price Paid": "$11.95",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10102831",
        "Product Status": "Active"
    },
    {
        "Root": "10009555",
        "LV": "1",
        "Item Description": "GIN BOMBAY SAPPHIRE LTR",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009555001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "11291",
        "Last Price Paid": "$26.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009555",
        "Product Status": "Active"
    },
    {
        "Root": "10030862",
        "LV": "1",
        "Item Description": "BANANA BABY",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10030862001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000433409",
        "Vendor": "FRESH POINT CENTRAL FLORIDA INC",
        "Vendor Item Nb": "302012",
        "Last Price Paid": "$19.74",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10030862",
        "Product Status": "Active"
    },
    {
        "Root": "10008012",
        "LV": "1",
        "Item Description": "CHENIN BLC INDABA S.A.750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008012001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "436895",
        "Last Price Paid": "$7.35",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008012",
        "Product Status": "Active"
    },
    {
        "Root": "10027975",
        "LV": "1",
        "Item Description": "COCOA BUTTER PRINCESS PINK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10027975001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302125C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10027975",
        "Product Status": "Active"
    },
    {
        "Root": "10025955",
        "LV": "1",
        "Item Description": "DUST GOLD NON EDIBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10025955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000073442",
        "Vendor": "QZINA SPECIALTY FOODS INC",
        "Vendor Item Nb": "QZ105053",
        "Last Price Paid": "$15.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10025955",
        "Product Status": "Active"
    },
    {
        "Root": "10009845",
        "LV": "1",
        "Item Description": "COGNAC HENNESSY XO 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10009845001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "898651",
        "Last Price Paid": "$201.75",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10009845",
        "Product Status": "Active"
    },
    {
        "Root": "10001274",
        "LV": "1",
        "Item Description": "BLUEBERRY FROZEN WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "1382571",
        "Last Price Paid": "$64.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001274",
        "Product Status": "Active"
    },
    {
        "Root": "10001274",
        "LV": "1",
        "Item Description": "BLUEBERRY FROZEN WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$64.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001274",
        "Product Status": "Active"
    },
    {
        "Root": "10001274",
        "LV": "1",
        "Item Description": "BLUEBERRY FROZEN WHOLE IQF",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001274001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "1382571",
        "Last Price Paid": "$64.55",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001274",
        "Product Status": "Active"
    },
    {
        "Root": "10035876",
        "LV": "1",
        "Item Description": "CHEESE MANCHEGO 3 MONTH SHEEP SPAIN 7 LB WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035876001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Catch Weight",
        "Vendor Nb": "1000302746",
        "Vendor": "GOURMET FOODS INTERNATIONAL",
        "Vendor Item Nb": "45008",
        "Last Price Paid": "$12.96",
        "Case Pack": "6.26",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035876",
        "Product Status": "Active"
    },
    {
        "Root": "10014684",
        "LV": "3",
        "Item Description": "TRANSFER SHEET GUITAR 23.6X15.7 500 CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10014684003003",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000002102",
        "Vendor": "ALBERT USTER IMPORTS INC",
        "Vendor Item Nb": "6151000000",
        "Last Price Paid": "$0.40",
        "Case Pack": "500",
        "Show Only Active Items": "Y",
        "Long LV": "003",
        "Long SV": "003",
        "NALA": "10014684",
        "Product Status": "Active"
    },
    {
        "Root": "10004627",
        "LV": "1",
        "Item Description": "BEAN PINTO #10",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004627001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$3.60",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004627",
        "Product Status": "Active"
    },
    {
        "Root": "10035813",
        "LV": "1",
        "Item Description": "TONIC WATER FEVER TREE ELDERFLOWER 200ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035813001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-MISC",
        "Vendor": "DC-MISC",
        "Vendor Item Nb": "597613",
        "Last Price Paid": "$0.85",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035813",
        "Product Status": "Active"
    },
    {
        "Root": "10034292",
        "LV": "2",
        "Item Description": "BEEF TACO FILLING SEASONED COOKED FROZEN 8/4LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10034292002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6080901",
        "Last Price Paid": "$10.09",
        "Case Pack": "8",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10034292",
        "Product Status": "Active"
    },
    {
        "Root": "10004559",
        "LV": "1",
        "Item Description": "COOKIE OREO 2CT PACK",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004559001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "3208931",
        "Last Price Paid": "$0.23",
        "Case Pack": "120",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004559",
        "Product Status": "Active"
    },
    {
        "Root": "10107505",
        "LV": "1",
        "Item Description": "BEER VAN GULDEN DRAAK 1/2 KEG",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10107505001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000502673",
        "Vendor": "CAVALIER DISTRIBUTING",
        "Vendor Item Nb": "9436",
        "Last Price Paid": "$331.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10107505",
        "Product Status": "Active"
    },
    {
        "Root": "10004210",
        "LV": "1",
        "Item Description": "CHOCOLATE DARK 46% VAN LEER CHUNK 1/30 LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004210001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "VAN LEER",
        "Last Price Paid": "$109.80",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004210",
        "Product Status": "Active"
    },
    {
        "Root": "10037541",
        "LV": "1",
        "Item Description": "PINOT NERO SCARPETTA TIMIDO BRUT ROSE 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037541001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9222967",
        "Last Price Paid": "$10.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037541",
        "Product Status": "Active"
    },
    {
        "Root": "10037137",
        "LV": "1",
        "Item Description": "ROLL DINNER GF NON GMO SHELF STABLE BLOOMFIELD",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10037137001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000509873",
        "Vendor": "SUNBELT NATURAL",
        "Vendor Item Nb": "14445",
        "Last Price Paid": "$0.80",
        "Case Pack": "48",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10037137",
        "Product Status": "Active"
    },
    {
        "Root": "10105466",
        "LV": "1",
        "Item Description": "PORT CROFT TAWNY RESERVE 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10105466001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "10944",
        "Last Price Paid": "$14.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10105466",
        "Product Status": "Active"
    },
    {
        "Root": "10001351",
        "LV": "1",
        "Item Description": "SAUCE BBQ KENYA AA 12/34",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001351001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.50",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001351",
        "Product Status": "Active"
    },
    {
        "Root": "10082876",
        "LV": "1",
        "Item Description": "SPICE ONION DEHYDRATED CHOPPED 3LB",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10082876001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "6413298",
        "Last Price Paid": "$20.25",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10082876",
        "Product Status": "Active"
    },
    {
        "Root": "10003549",
        "LV": "1",
        "Item Description": "SPICE CAJUN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003549001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.12",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003549",
        "Product Status": "Active"
    },
    {
        "Root": "10007026",
        "LV": "2",
        "Item Description": "CAB SAUV SIMI LANDSLD 750 ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007026001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "539938",
        "Last Price Paid": "$27.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007026",
        "Product Status": "Active"
    },
    {
        "Root": "10013022",
        "LV": "2",
        "Item Description": "CHIP KETTLE SEA SALT & VINEGAR 55/1.5OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10013022002002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5602008",
        "Last Price Paid": "$0.55",
        "Case Pack": "64",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "002",
        "NALA": "10013022",
        "Product Status": "Active"
    },
    {
        "Root": "10035174",
        "LV": "1",
        "Item Description": "MULLET FINGER FZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035174001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000608601",
        "Vendor": "MCROBERTS SALES CO., INC.",
        "Vendor Item Nb": "50040",
        "Last Price Paid": "$1.97",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035174",
        "Product Status": "Active"
    },
    {
        "Root": "10035174",
        "LV": "1",
        "Item Description": "MULLET FINGER FZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10035174001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Fixed Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "6500110",
        "Last Price Paid": "$1.97",
        "Case Pack": "25",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10035174",
        "Product Status": "Active"
    },
    {
        "Root": "10104933",
        "LV": "1",
        "Item Description": "SHOI-MI W/CHICKEN SHITAKI MUSHROOM & ONION 1OZ",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10104933001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-FROZEN",
        "Vendor": "DC-FROZEN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.58",
        "Case Pack": "126",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10104933",
        "Product Status": "Active"
    },
    {
        "Root": "10083955",
        "LV": "1",
        "Item Description": "SB SYRUP SPICED APPLE 1 LITER 4-CS",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10083955001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000092675",
        "Vendor": "STARBUCKS CORPORATION",
        "Vendor Item Nb": "11128439",
        "Last Price Paid": "$13.88",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10083955",
        "Product Status": "Active"
    },
    {
        "Root": "10001261",
        "LV": "1",
        "Item Description": "POTATO DICD 5/8 INCH BLANCH",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001261001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-PRODUCE",
        "Vendor": "DC-PRODUCE",
        "Vendor Item Nb": "",
        "Last Price Paid": "$4.51",
        "Case Pack": "4",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001261",
        "Product Status": "Active"
    },
    {
        "Root": "10004801",
        "LV": "1",
        "Item Description": "BEAN LENTIL DRY 20#",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10004801001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000442950",
        "Vendor": "US FOODS",
        "Vendor Item Nb": "5844394",
        "Last Price Paid": "$32.95",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10004801",
        "Product Status": "Active"
    },
    {
        "Root": "10003978",
        "LV": "1",
        "Item Description": "NUT PECAN HALV RAW JR MAM",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003978001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000497210",
        "Vendor": "TNB, INC. / THE NUTTY BAVARIAN",
        "Vendor Item Nb": "",
        "Last Price Paid": "$255.00",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003978",
        "Product Status": "Active"
    },
    {
        "Root": "10088249",
        "LV": "1",
        "Item Description": "GARNISH DEHYDRATED BLOOD ORANGE WHEEL",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10088249001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000401556",
        "Vendor": "DRESS THE DRINK LLC",
        "Vendor Item Nb": "BOC-1",
        "Last Price Paid": "$0.25",
        "Case Pack": "100",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10088249",
        "Product Status": "Active"
    },
    {
        "Root": "10010868",
        "LV": "2",
        "Item Description": "BEER CAN BELLS OBERON SEASONAL 16OZ 24CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10010868001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "26573",
        "Last Price Paid": "$1.69",
        "Case Pack": "24",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10010868",
        "Product Status": "Active"
    },
    {
        "Root": "10003026",
        "LV": "1",
        "Item Description": "SUGAR SUB SWEET N LOW 2000CT",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10003026001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-DRY",
        "Vendor": "DC-DRY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$0.00",
        "Case Pack": "2000",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10003026",
        "Product Status": "Active"
    },
    {
        "Root": "10036201",
        "LV": "1",
        "Item Description": "COCOA BUTTER REEF GREEN",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10036201001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000545531",
        "Vendor": "THE CULINARY SOURCE, LLC",
        "Vendor Item Nb": "302040C",
        "Last Price Paid": "$23.99",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10036201",
        "Product Status": "Active"
    },
    {
        "Root": "10008342",
        "LV": "2",
        "Item Description": "CAB SAUV CH MONTELENA 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008342001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9636319",
        "Last Price Paid": "$60.00",
        "Case Pack": "6",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10008342",
        "Product Status": "Active"
    },
    {
        "Root": "10001129",
        "LV": "1",
        "Item Description": "SQUID RING FRSH 5/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001129001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000575901",
        "Vendor": "HALPERNS STEAK AND SEAFOOD COMPANY",
        "Vendor Item Nb": "66-60200",
        "Last Price Paid": "$9.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001129",
        "Product Status": "Active"
    },
    {
        "Root": "10001129",
        "LV": "1",
        "Item Description": "SQUID RING FRSH 5/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001129001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000068375",
        "Vendor": "BAR HARBOR LOBSTER",
        "Vendor Item Nb": "306041000",
        "Last Price Paid": "$9.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001129",
        "Product Status": "Active"
    },
    {
        "Root": "10001129",
        "LV": "1",
        "Item Description": "SQUID RING FRSH 5/8",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10001129001001",
        "Billing UOM": "Weight",
        "Stock UOM": "Weight",
        "Vendor Nb": "1000555504",
        "Vendor": "NORTH STAR SEAFOOD OF ORLANDO",
        "Vendor Item Nb": "3424292",
        "Last Price Paid": "$9.06",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10001129",
        "Product Status": "Active"
    },
    {
        "Root": "10006885",
        "LV": "1",
        "Item Description": "ROSSO CENTINE MONTL BANFI 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10006885001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "346354",
        "Last Price Paid": "$8.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10006885",
        "Product Status": "Active"
    },
    {
        "Root": "10008285",
        "LV": "1",
        "Item Description": "MERLOT DUCKHORN 375ML 07",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10008285001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "538033",
        "Last Price Paid": "$20.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10008285",
        "Product Status": "Active"
    },
    {
        "Root": "10017221",
        "LV": "1",
        "Item Description": "CAKE SPONGE REGULAR 10 INCH MARBLE",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10017221001001",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "1000069548",
        "Vendor": "LAKE MARY CHEESECAKE COMPANY",
        "Vendor Item Nb": "",
        "Last Price Paid": "$8.37",
        "Case Pack": "1",
        "Show Only Active Items": "Y",
        "Long LV": "001",
        "Long SV": "001",
        "NALA": "10017221",
        "Product Status": "Active"
    },
    {
        "Root": "10007539",
        "LV": "2",
        "Item Description": "CAB RAMEY 750ML",
        "NALA Status": "Active",
        "LV Status": "Active",
        "ARIEL Item Nbr": "10007539001002",
        "Billing UOM": "Unit",
        "Stock UOM": "Unit",
        "Vendor Nb": "DC-LIQUOR",
        "Vendor": "DC-LIQUOR",
        "Vendor Item Nb": "9673230",
        "Last Price Paid": "$45.00",
        "Case Pack": "12",
        "Show Only Active Items": "Y",
        "Long LV": "002",
        "Long SV": "001",
        "NALA": "10007539",
        "Product Status": "Active"
    }
]