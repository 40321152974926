
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import {Layout} from './components/Layout/Layout';
import Home from './components/Home/Home';

// Spoialge Routes
import {SpoilageIndex} from './components/Spoilage/SpoilageIndex/SpoilageIndex';
import {AddData} from './components/Spoilage/AddData/AddData'
import {SpoilageTable} from './components/Spoilage/SpoilageTable/SpoilageTable';
import {DailySpoilage} from './components/Spoilage/DailySpoilage/DailySpoilage.js';

// Login
import Login from './components/Login/Login';
import RequireLogin from './components/RequireLogin/RequireLogin';

// Food search
import USDASearch from './components/UsdaSearch/UsdaSearch.js'; // Not in use
import OpenFoodFactsSearch from './components/OpenFoodFactsSearch/OpenFoodFactsSearch';

// Recipe Creator
import {RecipeCreatorIndex} from './components/RecipeCreator/RecipeCreatorIndex/RecipeCreatorIndex.js';
import {RecipeCreator} from './components/RecipeCreator/RecipeCreator';

// Prep Tracker
import { PrepTrackerIndex } from './components/PrepTracker/PrepTrackerIndex/PrepTrackerIndex.js';
import {PrepList} from './components/PrepTracker/PrepList/PrepList.js';

import './App.css';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <RequireLogin>
            <Layout />
          </RequireLogin>
          }>
          <Route index element={<Home />} />

          <Route path="spoilage" element={<SpoilageIndex />}>
            <Route path="spoilagemaster" element={<SpoilageTable />} />
            <Route path="newdailyspoilage" element={<DailySpoilage />} />
            <Route path="additem" element={<AddData />} />
          </Route>
          <Route path="usdasearch" element={<USDASearch />} />
          <Route path="openfoodfactssearch" element={<OpenFoodFactsSearch />} />
          <Route path="recipecreator" element={<RecipeCreatorIndex />}>
            <Route path="createrecipe" element={<RecipeCreator/>}/>
            <Route path="managerecipes"/>
          </Route>
          <Route path="preptracker" element={<PrepTrackerIndex />}>
            <Route path="startcount" element={<PrepList/>}/>
            <Route path="managepreplist"/>
            <Route path="history"/>

          </Route>

        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
    </Router>
  );
}