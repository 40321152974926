import {initializeApp} from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: 'AIzaSyDAGdm_x-vT17D3K8vibDHQM4x8IAWAOCk',
  authDomain: 'portfolio-4db78.firebaseapp.com',
  projectId: 'portfolio-4db78',
  storageBucket: 'portfolio-4db78.appspot.com',
  messagingSenderId: '561367456783',
  appId: '1:561367456783:web:47b70623639e6382be977a',
  measurementId: 'G-N7456K7BRR',
};

// Initialize Firebase
const fire = initializeApp(firebaseConfig);
export const db = getFirestore(fire);
export const auth = getAuth(fire);
export default fire;