import React from 'react';
import style from './searchResults.module.css';

export default function SearchResults(props) {
  if(props.data){
    const listItems = props.data.map(data=> {
      return(
        <div key={data.code} className={style['product-card']}>
          <h4>{data.product_name}</h4>
          <a href={`https://world.openfoodfacts.org/api/v0/product/${data.code}.json`}>
            {data.image_small_url ? <img alt='' src={data.image_small_url}></img> : <p>Image not available...</p>}
          </a>
          <div>
            <h5>Ingredients</h5>
            <p>
              {data.ingredients_text}
            </p>
            
          </div>
        </div>
      )
    })
    return (
    <div id={style["product-card-container"]}>
      {listItems}
    </div>
    )
  }else{
    return <p>{props.data}</p>
  }
  }