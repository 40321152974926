import React, { } from 'react';
import { Link } from 'react-router-dom';
import style from '../../../General/SecondaryNav/Options/options.module.css';

export const Options = () => {
    
    return (
        <div id={style.optionsContainer}>
            <Link to="spoilagemaster">
                Master List
            </Link>
                
            <Link to="newdailyspoilage">
                New Daily
            </Link>
            <Link to="additem">
                Add Item
            </Link>
        </div>
    );
};
  