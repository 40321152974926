import React from 'react';
import style from './searchSpinner.module.css';


export default function SearchSpinner() {
    
    return (
    <div id={style["search-spinner-container"]}>
        <svg viewBox="0 0 100 100">
          <defs>
            <filter id={style["search-spinner-shadow"]}>
              <feDropShadow dx="0" dy="0" stdDeviation="1.5" 
                floodColor="#fc6767"/>
            </filter>
          </defs>
          <circle id={style["search-spinner-spinner"]}  cx="50" cy="50" r="45"/>
      </svg>
      </div>
    )
}