import React from 'react';

export const IngredientCard = ({data, handleSelectIngredients})=>{
    return(
        <div className='ingredient-card' onClick={e=>{handleSelectIngredients(e)} } data-id={data['ARIEL Item Nbr']}>
            <span><strong>ID: </strong>{data['ARIEL Item Nbr']}</span>

            <span>{data['Item Description']}</span>
            <span><strong>UOM: </strong>{data['Billing UOM']}</span>
            <span><strong>Case Pack: </strong>{data['Case Pack']}</span>
            <span><strong>Price/Unit: </strong>{data['Last Price Paid']}</span>
            <span><strong>{data['Billing UOM'] === 'Weight' && data['Case Pack'] === '1'? 'Per Pound: ':'Case Price: '}</strong>{
                (parseFloat(data['Last Price Paid'].substr(1)) * parseFloat(data['Case Pack'])).toFixed(2)
            
            }</span>

        </div>
    )
}