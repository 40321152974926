import React, { useState } from 'react';
import {IngredientCard} from './IngredientCard/IngredientCard';
import { ItemsSelected } from './ItemsSelected/ItemsSelected';
import { testData } from './testData';
import './recipe-creator.css';

export const RecipeCreator = ()=>{
    const [query, setQuery] = useState('');
    const [results, setResults] = useState();
    const [picked, setPicked] = useState([]);
    const [recipeName, setRecipeName] = useState();

    const searchData = (searchQuery)=>{
        if(searchQuery === ""){
            setResults(undefined);
        }else{
            const foundData = testData.filter((item)=>{
                return item['Item Description'].toLowerCase().includes(searchQuery) || item['ARIEL Item Nbr'].toString().toLowerCase().includes(searchQuery)
            })
            setResults(foundData)
        }
        
    }
    const handleSelectIngredients = (e)=>{
        const clickedItem = e.currentTarget.getAttribute('data-id');
        const currentIds = picked.map(item=>item['ARIEL Item Nbr'])
        if(!currentIds.includes(clickedItem)){
            const addedItem = testData.filter(item=>item['ARIEL Item Nbr'] === clickedItem)
            setPicked([...picked, addedItem[0]])
        }else{
            console.log('Item on list...')
        }
    }
    const handleRemoveIngredient = (e)=>{
        const clickedItem = e.currentTarget.getAttribute('data-id');
        const removedFromPicked = picked.filter(item=>item['ARIEL Item Nbr'] !== clickedItem)
        setPicked(removedFromPicked)
    }
    const handleChangeRecipeIngredientAmount = (changedField, changedAmount, ingredientId)=>{
        
        const updatedPicked = picked.map(item=>
            item['ARIEL Item Nbr'] === ingredientId ? {...item, [changedField]: changedAmount}:item
        )
        setPicked(updatedPicked);
  
    }
    const handleRecipeNameChange = (e)=>{
        setRecipeName(e.target.value);
    }
    const handleSaveRecipe = (e)=>{
        const savedRecipe = {}
        savedRecipe[recipeName] = picked
        console.log(savedRecipe)
    }


    return (
        <>
            <ItemsSelected 
                selections={picked} 
                handleRemoveIngredient={handleRemoveIngredient}
                handleSaveRecipe={handleSaveRecipe}
                handleRecipeNameChange={handleRecipeNameChange}
                handleChangeRecipeIngredientAmount={handleChangeRecipeIngredientAmount}
            />

            <div id='recipe-creator-search-results-container'>
                <div id='recipe-creator-search'>
                    <input type='text' onChange={(e)=>setQuery(e.target.value)} onKeyDown={(e)=>{if(e.key==='Enter')searchData(query)}}></input>
                    <button onClick={()=>{searchData(query)}}>Search</button>
                </div>
                <div id='ingredient-card-container'>
                    {results === undefined ? <div></div> : results.map((item, index)=>{
                        return <IngredientCard 
                                data={item} 
                                key={index} 
                                handleSelectIngredients={handleSelectIngredients} 
                                
                                />
                    })}
                </div>
            </div>
            
        </>
    )
}