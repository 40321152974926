import React from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import style from './login.module.css';

export default function Login() {
  let email = '';
  let password = '';
  const navigate = useNavigate();
  const auth = getAuth();

  const onLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate('/');
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (  
    <div>
      <h1>Login</h1>
      <form id={style['login-form']}>
          <label htmlFor="email-input">Email Address </label>
          <input
            type="email"
            name="email-input"
            onChange={(e) => {
              email = e.target.value;
            }}
          />

          <label htmlFor="password-input"> Password </label>
          <input
            type="password"
            name="password-input"
            onChange={(e) => {
              password = e.target.value;
            }}
          />

          <div>
            <button onClick={onLogin}>Submit</button>
          </div>
      </form>
      <div id="firebaseui-auth-container"></div>
    </div>
  ); 
};
