import React, { } from 'react';
import { Link } from 'react-router-dom';
import style from '../../../General/SecondaryNav/Options/options.module.css';
export const Options = () => {
    
    return (
        <div id={style.optionsContainer}>
            <Link to="startcount">
                Start Count
            </Link>
                
            <Link to="managepreplist">
                Manage Prep List
            </Link>

            <Link to="history">
                History
            </Link>
        </div>
    );
};
  