import React from 'react';
import './daily-spoilage-card.css';

export const DailySpoilageCard = ({data, handleAmountChange})=>{
    return(
        <>
            <div className='daily-spoilage-card'>
                
                <span><strong>Item</strong></span>
                <span><strong>Amount</strong></span>
                <span><strong>Unit</strong></span>
                

                <span>{data.name}</span>
                <span><input onChange={e=>handleAmountChange(e)} data-item-id={data.id}/></span>
                <span>{data.unit}</span>

            </div>
        </>
    )
}