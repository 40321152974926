import React, { } from 'react';
import { Link } from 'react-router-dom';
import style from '../../../General/SecondaryNav/Options/options.module.css'

export const Options = () => {
    
    return (
        <div id={style.optionsContainer}>
            <Link to="createrecipe">
                Create
            </Link>
                
            <Link to="managerecipes">
                Manage
            </Link>
    
        </div>
    );
};
  