const sampleData = [
        {
            "allergens": "en:celery",
            "code": "8715700407760",
            "image_nutrition_url": "https://images.openfoodfacts.org/images/products/871/570/040/7760/nutrition_en.158.400.jpg",
            "image_small_url": "https://images.openfoodfacts.org/images/products/871/570/040/7760/front_en.141.200.jpg",
            "image_thumb_url": "https://images.openfoodfacts.org/images/products/871/570/040/7760/front_en.141.100.jpg",
            "ingredients": [
                {
                    "has_sub_ingredients": "yes",
                    "id": "en:tomato",
                    "percent_estimate": 50,
                    "rank": 1,
                    "text": "Tomates",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:sugar",
                    "percent_estimate": 25,
                    "rank": 2,
                    "text": "sucre",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "ciqual_food_code": "11018",
                    "id": "en:vinegar",
                    "percent_estimate": "12.5",
                    "rank": 3,
                    "text": "vinaigre",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "ciqual_food_code": "11058",
                    "id": "en:salt",
                    "percent_estimate": "6.25",
                    "rank": 4,
                    "text": "sel",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "has_sub_ingredients": "yes",
                    "id": "en:spice",
                    "percent_estimate": "3.125",
                    "rank": 5,
                    "text": "épices",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "ciqual_food_code": "20034",
                    "id": "en:onion",
                    "percent_estimate": "1.5625",
                    "processing": "en:powder",
                    "rank": 6,
                    "text": "oignon",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "ciqual_food_code": "11000",
                    "id": "en:garlic",
                    "percent_estimate": "1.5625",
                    "processing": "en:powder",
                    "rank": 7,
                    "text": "ail",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "fr:contiennent-du-celeri",
                    "percent_estimate": "3.125",
                    "text": "contiennent du céleri"
                }
            ],
            "ingredients_text": "tomatoes (180 g per 100 g of ketchup), sugar, vinegar, salt, spices (contain celery), onion powder, garlic powder,",
            "labels": "Organic, EU Organic, Non-EU Agriculture, EG-Öko-Verordnung, EU Agriculture, Green Dot, HT-BIO-154, NL-BIO-01, AB Agriculture Biologique, fr:triman",
            "product_name": "Tomato Ketchup BIO"
        },
        {
            "allergens": "",
            "code": "20004125",
            "image_nutrition_url": "https://images.openfoodfacts.org/images/products/20004125/nutrition_de.129.400.jpg",
            "image_small_url": "https://images.openfoodfacts.org/images/products/20004125/front_en.124.200.jpg",
            "image_thumb_url": "https://images.openfoodfacts.org/images/products/20004125/front_en.124.100.jpg",
            "ingredients": [
                {
                    "ciqual_food_code": "20047",
                    "id": "en:tomato",
                    "percent": "99.4",
                    "percent_estimate": "99.4",
                    "percent_max": "99.4",
                    "percent_min": "99.4",
                    "rank": 1,
                    "text": "Tomaten",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "ciqual_food_code": "11058",
                    "id": "en:salt",
                    "percent_estimate": "0.449999999999996",
                    "percent_max": "0.599999999999994",
                    "percent_min": "0.299999999999997",
                    "rank": 2,
                    "text": "Speisesalz",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "has_sub_ingredients": "yes",
                    "id": "en:acidity-regulator",
                    "percent_estimate": "0.150000000000006",
                    "percent_max": "0.299999999999997",
                    "percent_min": 0,
                    "rank": 3,
                    "text": "Säureregulator"
                },
                {
                    "id": "en:e330",
                    "percent_estimate": "0.150000000000006",
                    "percent_max": "0.299999999999997",
                    "percent_min": 0,
                    "text": "Citronensäure",
                    "vegan": "yes",
                    "vegetarian": "yes"
                }
            ],
            "ingredients_text": "99,5% tomatoes, salt,",
            "labels": "Hergestellt in Italien,Nutriscore Note A",
            "product_name": "Tomatenmark"
        },
        {
            "allergens": "",
            "code": "8000320010118",
            "image_nutrition_url": "https://images.openfoodfacts.org/images/products/800/032/001/0118/nutrition_it.184.400.jpg",
            "image_small_url": "https://images.openfoodfacts.org/images/products/800/032/001/0118/front_en.152.200.jpg",
            "image_thumb_url": "https://images.openfoodfacts.org/images/products/800/032/001/0118/front_en.152.100.jpg",
            "ingredients": [
                {
                    "ciqual_food_code": "20047",
                    "id": "en:tomato",
                    "percent_estimate": "62.5",
                    "percent_max": 100,
                    "percent_min": 25,
                    "rank": 1,
                    "text": "Pomodori",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "ciqual_food_code": "20047",
                    "id": "en:tomato-juice",
                    "percent_estimate": "18.75",
                    "percent_max": 50,
                    "percent_min": 0,
                    "rank": 2,
                    "text": "succo di pomodoro",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:salt",
                    "percent_estimate": "9.375",
                    "percent_max": "33.3333333333333",
                    "percent_min": 0,
                    "rank": 3,
                    "text": "sale",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "has_sub_ingredients": "yes",
                    "id": "en:acidity-regulator",
                    "percent_estimate": "9.375",
                    "percent_max": 25,
                    "percent_min": 0,
                    "rank": 4,
                    "text": "correttore di acidità"
                },
                {
                    "id": "en:e330",
                    "percent_estimate": "9.375",
                    "percent_max": 25,
                    "percent_min": 0,
                    "text": "acido citrico",
                    "vegan": "yes",
                    "vegetarian": "yes"
                }
            ],
            "ingredients_text": "chopped tomatoes, tomato paste, salt, acidity regulator: citric acid.",
            "labels": "Made in Italy,No added sugar,Nutriscore,Nutriscore Grade A,fr:Saveurs de l'Année",
            "product_name": "Cirio Diced Tomatoes"
        },
        {
            "allergens": "",
            "code": "01346604",
            "image_nutrition_url": "https://images.openfoodfacts.org/images/products/01346604/nutrition_en.21.400.jpg",
            "image_small_url": "https://images.openfoodfacts.org/images/products/01346604/front_en.27.200.jpg",
            "image_thumb_url": "https://images.openfoodfacts.org/images/products/01346604/front_en.27.100.jpg",
            "ingredients": [
                {
                    "id": "en:tomato-concentrate-from-red-ripe-tomatoes",
                    "percent_estimate": "56.25",
                    "percent_max": 100,
                    "percent_min": "12.5",
                    "rank": 1,
                    "text": "tomato concentrate from red ripe tomatoes"
                },
                {
                    "id": "en:distilled-vinegar",
                    "percent_estimate": "21.875",
                    "percent_max": 50,
                    "percent_min": 0,
                    "rank": 2,
                    "text": "distilled vinegar",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:high-fructose-corn-syrup",
                    "percent_estimate": "10.9375",
                    "percent_max": "33.3333333333333",
                    "percent_min": 0,
                    "rank": 3,
                    "text": "high fructose corn syrup",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:corn-syrup",
                    "percent_estimate": "5.46875",
                    "percent_max": 25,
                    "percent_min": 0,
                    "rank": 4,
                    "text": "corn syrup",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:salt",
                    "percent_estimate": "2.734375",
                    "percent_max": 20,
                    "percent_min": 0,
                    "rank": 5,
                    "text": "salt",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:spice",
                    "percent_estimate": "1.3671875",
                    "percent_max": "16.6666666666667",
                    "percent_min": 0,
                    "rank": 6,
                    "text": "spice",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:onion",
                    "percent_estimate": "0.68359375",
                    "percent_max": "14.2857142857143",
                    "percent_min": 0,
                    "processing": "en:powder",
                    "rank": 7,
                    "text": "onion",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:natural-flavouring",
                    "percent_estimate": "0.68359375",
                    "percent_max": "5",
                    "percent_min": 0,
                    "rank": 8,
                    "text": "natural flavoring",
                    "vegan": "maybe",
                    "vegetarian": "maybe"
                }
            ],
            "ingredients_text": "tomato concentrate from red ripe tomatoes, distilled vinegar, high fructose corn syrup, corn syrup, salt, spice, onion powder, natural flavoring,",
            "labels": "Verified",
            "product_name": "Tomato ketchup, tomato"
        },
        {
            "allergens": "",
            "code": "5900500024368",
            "image_nutrition_url": "https://images.openfoodfacts.org/images/products/590/050/002/4368/nutrition_en.24.400.jpg",
            "image_small_url": "https://images.openfoodfacts.org/images/products/590/050/002/4368/front_en.22.200.jpg",
            "image_thumb_url": "https://images.openfoodfacts.org/images/products/590/050/002/4368/front_en.22.100.jpg",
            "ingredients": [
                {
                    "ciqual_food_code": "20047",
                    "id": "en:tomato-juice",
                    "percent": 100,
                    "percent_estimate": 100,
                    "percent_max": 100,
                    "percent_min": 100,
                    "rank": 1,
                    "text": "tomato juice",
                    "vegan": "yes",
                    "vegetarian": "yes"
                },
                {
                    "id": "en:of-the-concentrated-juice",
                    "percent_estimate": 0,
                    "percent_max": 0,
                    "percent_min": 0,
                    "rank": 2,
                    "text": "of the concentrated juice"
                }
            ],
            "ingredients_text": "tomato juice (100%) of the concentrated juice",
            "labels": "FSC",
            "product_name": "Hortex sok pomidorowy"
        },
        {
            "allergens": "",
            "code": "51000000118",
            "labels": "",
            "product_name": "Campbell Tomato Soup"
        }
    ]
    
export default sampleData;