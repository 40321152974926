import React, { useState } from 'react';
import SearchResults from './SearchResults.js';
import sampleData from './sampleData.js';
import SearchSpinner from '../General/SearchSpinner/SearchSpinner.js';
import style from './openFoodFactsSearch.module.css';


const OpenFoodFactsSearch = ()=>{
    const [results, setResults] = useState(sampleData);
    const [query, setQuery] = useState('');
    
    function handleClick(e) {
      setResults('Searching...');
      const params = new URLSearchParams({
          search_terms: query,
          search_simple: 1,
          sort_by: 'populatity_key',
          countries_tags_en: 'united-states',
          action: 'process',
          json: 1,
          page_size: 6,
          fields: 'code,product_name,labels,image_thumb_url,image_small_url,image_nutrition_url,allergens,ingredients,ingredients_text',
        });
      const url = 'https://us.openfoodfacts.org/cgi/search.pl?' + params;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          // Filter results with required data
          const filteredData = data.products.filter((data)=>{return data.hasOwnProperty('ingredients_text')})
          
          setResults(filteredData);
        })
        .catch((err) => {
          console.log('error', err.message);
        });
    }
    return (
    <div id={style["off-container"]}>
        <h1>Product Search</h1>
        <div id={style["off-search-bar"]}>

          <input onChange={(e)=>setQuery(e.target.value)} type="text"></input>
          <button onClick={(e)=>handleClick(e)}>Search</button>
  
        </div>
        
        <div id={style['results-container']}>
          {results === 'Searching...' ? <div id="spinner-wrapper"><SearchSpinner/></div> : <SearchResults data={results} />}
          
        </div>
        
    </div>
    )
}

export default OpenFoodFactsSearch;