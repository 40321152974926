import React, {useState} from 'react';
import {db} from '../../../firebase';
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 

import './addData.css';

const collectionInUse = 'testSpoilage';

export const AddData = ()=>{
    const [skuData, setSkuData] = useState('');
    const [nameData, setNameData] = useState('');
    const [unitData, setUnitData] = useState('');

    const addItem = async (data)=>{
        await addDoc(collection(db, collectionInUse),{
          sku: data.sku,
          name: data.name,
          unit: data.unit,
          locationId: '123456',//This will be a UID. Need to tie useres to the UID of location
          timestamp: serverTimestamp()
        })
        console.log('Item Added =>',data.name )
      }

    return(
        <div id="add-data-container">
            <h2>Add Item</h2>
            <div id="add-data-form">
                <div className="add-data-field">
                    <label>SKU</label>
                    <input onChange={(e)=>{setSkuData(e.target.value)}}/>
                </div>
                <div className="add-data-field">
                    <label>Name</label>
                    <input onChange={(e)=>{setNameData(e.target.value)}}/>
                </div>
                <div className="add-data-field">
                    <label>Unit</label>
                    <input onChange={(e)=>{setUnitData(e.target.value)}}/>
                </div>
            </div>
            <div>
                <button onClick={()=>{addItem({name: nameData, sku: skuData, unit: unitData})}}>Add</button>
            </div>
        </div>
    )
}