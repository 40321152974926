import React from 'react';
import { Outlet } from 'react-router-dom';
import {Options} from './Options/Options';

export const PrepTrackerIndex = ()=>{
    return(
        <div id="prep-tracker-container">
            <Options/>
            <Outlet/>
        </div>
    )
}