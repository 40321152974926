import React, { } from 'react';


export const DataRows = ({ data, deleteItem}) => {

    return (
        <>
        {data.map((data, index) => (
            <tr key={index}>
            <td>{data.sku}</td>
            <td>{data.name}</td>
            <td>{data.unit}</td>
            <td><button onClick={()=>{deleteItem(data.id)}}>x</button></td>
            </tr>
        ))}
        </>
    );
};
  