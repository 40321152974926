import React, { useEffect, useState } from 'react';
import {db} from '../../../firebase';
import { collection, getDocs, doc, deleteDoc, where, query,writeBatch } from "firebase/firestore"; 
import SearchSpinner from '../../General/SearchSpinner/SearchSpinner';
import {DataRows} from './DataRows'

import './table.css';
const collectionInUse = 'testSpoilage';

export const SpoilageTable = ()=> {
  const [dbData, setDbData] = useState();
  const [tableData, setTableData] = useState();
  
  // need to pull docs with UID of location
  const getSpoilage = async()=>{
    const q = query(collection(db, collectionInUse), where('locationId', '==', '123456'),where('type', '==', 'masterList'));
    
    // const q = query(collection(db, collectionInUse)); writeBatch,deleteField 
    const querySnapshot = await getDocs(q);
    // try{
    //   const batch = writeBatch(db);
    //   querySnapshot.docs.forEach(doc=>{
    //     batch.update(doc.ref, {'type': 'masterList'})
    //   })
    //   await batch.commit();
    //   console.log('batch commited')
    // }catch(error){
    //   console.log(error)
    // }
    
    const data = querySnapshot.docs.map(doc=>({...doc.data(), id:doc.id}))
    setDbData(data);
    setTableData(data);
  }
  useEffect(()=>{
    getSpoilage()
  }, [])

  const deleteItem = async (id)=>{
    const itemDoc = doc(db, collectionInUse, id);
    await deleteDoc(itemDoc);
    setTableData(tableData.filter(data=> data.id!==id))
    
  }
 

  const searchTable = (e)=>{
    const searchQuery = e.target.value;
    console.log('search ', searchQuery)
    const fd = dbData.filter(
        (item) =>
          item.name.toString().toLowerCase().includes(searchQuery.toString().toLowerCase()) ||
          item.sku.toString().toLowerCase().includes(searchQuery.toString().toLowerCase())
      )
    setTableData(fd);
  }

  return (
    <div id="table-container">
      {/* if tableData then render table else render spinner */}
      {tableData ? (<>
      <div id="search-container">
        <h2>Search</h2>
        <input onChange={(e) => searchTable(e)} type="text" />
      </div>
      <table id="spoilage-table">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Remove</th>

          </tr>
        </thead>
        <tbody>
          <DataRows
            data={tableData}
            deleteItem={deleteItem}
          />
        </tbody>
      </table>
      </>
      ):(<SearchSpinner/>)}
      
    </div>
  );
}
