import React from 'react';
import './usdaSearch.css'


export default function SearchResults(props) {
  const listItems = props.data.map(data=> {
    return(
      <div>
        <h3>{data.brandName}</h3>
        <div>
          <h5>Description</h5>
          {data.description}
        </div>
        <div>
          <h5>Ingredients</h5>
          {data.ingredients}
        </div>
      </div>
    )
  })
  return (
  <div>
    {listItems}
  </div>
  )
}