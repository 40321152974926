import React from 'react';
import './prep-ingredient-card.css';

export const IngredientCard = ({data})=>{
    return(
        <div className='prep-ingredient-card' data-id={data['recipeNumber']}>
            <div className="prep-info-item">
                <span><strong>Name</strong></span>
                <span>{data['name']}</span>
            </div>
            <div className="prep-info-item">
                <span><strong>Par </strong></span>
                <span>{data['par']}</span>
            </div>
            <div className="prep-info-item">
                <span><strong>Unit </strong></span>
                <span>{data['unit']}</span>
            </div>
            <div className='prep-info-item'>
                <span><strong>On Hand</strong></span>

                <input type="text"></input>
            </div>
            
        </div>
    )
}