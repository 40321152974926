import React, { useState } from 'react';
import SearchResults from './SearchResults.js';
import './usdaSearch.css'


const USDASearch = () => {

  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('');

  function handleClick(e) {
    const params = new URLSearchParams({
      api_key: 'DEMO_KEY',
      query: query,
    });

    const url = 'https://api.nal.usda.gov/fdc/v1/foods/search?' + params;

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // use filter and map
        const filteredData = data.foods
          .filter((food) => {
            return food.hasOwnProperty('ingredients');
          })
          .map((item) => {
            return {
              brandName: item.brandName,
              description: item.description,
              ingredients: item.ingredients,
              upcCode: item.gtinUpc,
              brandedFoodCategory: item.brandedFoodCategory,
              labelNutrients: item.labelNutrients,
            };
          });
        setResults(filteredData);
      })
      .catch((err) => {
        console.log('error', err.message);
      });
  }
  return (
    <div id="usda-page-container">
      <h1>USDA Search</h1>
      <p>Enter a barcode number from a consumer product. It must be for an individual item. Example: 028400598125</p>
      <div id="usda-search-container">
        <label htmlFor="query">Barcode </label>
        <div>
          <input onChange={(e)=>setQuery(e.target.value)} id="usda-search-query" type="text" name="query" />
          <button onClick={(e)=>handleClick(e, query)}><img alt='' src="/icon-search.png"></img></button>
        </div>
      </div>
      <div id="results-container">
        <SearchResults data={results} />
      </div>

      
    </div>
  );
}
export default USDASearch;