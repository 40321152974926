import React from 'react';
import { BiSolidTrash } from "react-icons/bi";
import './picked-ingredients.css';

export const PickedIngredients = ({data, handleRemoveIngredient,handleChangeRecipeIngredientAmount})=>{

    const handleChanges = (e)=>{
        const ingredientId = e.currentTarget.getAttribute('data-id');
        const changedField = e.currentTarget.getAttribute('data-fieldname');
        const changedAmount = e.target.value;
        
        handleChangeRecipeIngredientAmount(changedField, changedAmount, ingredientId)
    }
    return(
        <div id='picked-ingredient-container'>
            <div className='picked-ingredients'>
                <span><strong>Item</strong></span>
                <span><strong>Amount</strong></span>
                <span><strong>Unit</strong></span>

            </div>
            {data?data.map(item=>{
                return (
                    <div className='picked-ingredients' key={item['ARIEL Item Nbr']}>
                        <span>{item['Item Description']}</span>
                        <input onChange={e=>handleChanges(e)} data-id={item['ARIEL Item Nbr']} data-fieldname='Recipe Amount'/>
                        <select onChange={e=>handleChanges(e)} data-id={item['ARIEL Item Nbr']} data-fieldname='Selected Unit'>
                            <option>POUND</option>
                            <option>OUNCE</option>

                        </select>
                        <div className='picked-ingredients-remove-button' 
                                onClick={e=>{handleRemoveIngredient(e)}} 
                                data-id={item['ARIEL Item Nbr']}><BiSolidTrash size={25}/>
                                </div>
                    </div>)
            }):<span>Select items...</span>}
        </div>
    )
}