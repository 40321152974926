import React from 'react';
import { PickedIngredients } from './PickedIngredients/PickedIngredients';
import './items-selected.css';

export const ItemsSelected =(
    {
        selections, 
        handleRemoveIngredient, 
        handleSaveRecipe, 
        handleRecipeNameChange,
        handleChangeRecipeIngredientAmount})=>{

    return (
    <>
        <div id='recipe-creator-sidebar-container'>
            <div id='recipe-creator-sidebar-header'>
                <h2>Recipe Creator</h2>
                <div>Plate</div>
                <div>Batch</div>
            </div>
            <div id='recipe-creator-reciep-name-container'>
                <span>Recipe Name</span>
                <input onChange={e=>handleRecipeNameChange(e)}/>
            </div>
            
            <PickedIngredients 
                data={selections} 
                handleRemoveIngredient={handleRemoveIngredient}
                handleChangeRecipeIngredientAmount={handleChangeRecipeIngredientAmount}
            />
                    
            <div id='recipe-creator-save-recipe' className='save-btn' onClick={e=>handleSaveRecipe(e)}>Save</div>
        </div>
    
    </>)
}